import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OTPForm from "./OTPForm";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import AppLayout from "../../layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

let publicUrl = process.env.REACT_APP_URL + "/";

const OTPChangePhone = () => {
  const history = useNavigate();
  const { token, vendor, UserType } = useAuth();
  const [errors, setErrors] = useState({});
  let { newPhone, agentId, countryCode } = useParams();
  const [loadingButton, setLoadingButton] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState({
    userId: vendor === 'amakkn' ? token : agentId,
    code: "",
    countryCode: countryCode,
    newPhone: newPhone,
    language: "0",
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (vendor) {
      setUser({ ...user, userId: vendor === 'amakkn' ? token : agentId });
    }
  },
    // eslint-disable-next-line
    [vendor, token, agentId]);
  
  useEffect(() => {
    if (user.code.length === 6) handleSubmit();
  },
    // eslint-disable-next-line
    [user]);
  
  function handleChange(value) {
    setUser({
      ...user,
      code: value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!user.code) _errors.code = t("EnterTheCodeSentToYourPhone");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event?.preventDefault();

    if (!formIsValid()) return;
    setLoadingButton(true);
    userApi.changeMobileNumberConfirmCode(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (vendor === 'amakkn') {
          history("/");
        } else {
          if (location.state && location.state?.from === 'profile')
            history("/admin/profile");
          else {
            if (UserType === '5')
              history("/admin/link-with-whatsapp");
            else
              history("/admin/agents");
          }
        }
        if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
      } else {
        const _errors = {};
        _errors.code = _userInfo.resStr;
        setErrors(_errors);
      }
      setLoadingButton(false);
    }).catch(e => setLoadingButton(false));
  }

  return (
    <AppLayout pageTitle={t('ChangePhoneNumber')} withoutNav={vendor !== 'amakkn'}>
      <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width`}>
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-6 details-wrapper scrolled">
            <OTPForm
              errors={errors}
              user={user}
              name='code'
              label={t("ActivationCode")}
              value={user.code}
              errorName={errors.code}
              onChange={handleChange}
              onSubmit={handleSubmit}
              loading={loadingButton}
            />
          </div>
          <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="img-container">
              <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default OTPChangePhone;
