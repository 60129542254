import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as userApi from "../../api/userApi";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { preventString } from "../../helpers";
import AppLayout from "../../components/layouts/app-layout";
import OTPForm from "../../components/section-components/registration/OTPForm";
import { DARK_TEMPLATES } from "../../constants";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

let publicUrl = process.env.REACT_APP_URL + "/";

const WLOTPPassword = () => {
  const history = useNavigate();
  const [errors, setErrors] = useState({});
  let { countryCode } = useParams();
  let { phone } = useParams();
  const { templateId } = useAuth();
  const [user, setUser] = useState({
    id: null,
    code: "",
    countryCode: countryCode,
    phone: phone,
    language: "0",
  });
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (user.code.length === 6) handleSubmit();
  },
    // eslint-disable-next-line
    [user]);

  function handleChange(value) {
    setUser({
      ...user,
      code: value,
    });
  }

  function formIsValid() {
    const _errors = {};
    if (!user.code) _errors.code = t("EnterTheCodeSentToYourPhone");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event?.preventDefault();
    if (!formIsValid()) return;
    setLoadingButton(true);
    userApi.verifyUser(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        history("/admin/set-new-password/" + user.countryCode + "/" + preventString(user.phone));
        if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
      } else {
        const _errors = {};
        _errors.code = _userInfo.resStr;
        setErrors(_errors);
        setLoadingButton(false);
      }
    }).catch(e => setLoadingButton(false));
  }

  return (
    <AppLayout
      pageTitle={t("SetPassword")}>
       
      <div className="wl-login pd-top-90 mg-bottom-100 text-center"
        style={{ background: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : `url(${publicUrl}assets/img/pattern.png)` }}>
        <div className='login-modal' dir="ltr" style={{ maxWidth: '520px' }} >
          <div className='col-11 col-sm-9'>
            <form>
              <div className='row p-0  mx-0'>
                <div className='col-12'>
                  <OTPForm
                    errors={errors}
                    user={user}
                    name='code'
                    label={t("ActivationCode")}
                    admin={true}
                    value={user.code}
                    errorName={errors.code}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    loading={loadingButton}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default WLOTPPassword;
