import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ArrowBack } from "@mui/icons-material";
import LoadingButton from "../global-components/loading-btn";
import { getWLAboutUs, saveWLAboutUs } from "../../api/userApi";
import UploadImage from "../global-components/upload-image";
import { useAuth } from "../../helpers/context";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useTranslation } from "react-i18next";

const AboutUsSection = (props) => {
    const { vendor } = useAuth();
    const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    const [data, setData] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (vendor) {
            setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
            getWLAboutUs({ userName: vendor, language: "0" }).then(
                (_DefLocation) => {
                    if (_DefLocation.resCode === 0) {
                        setData(_DefLocation.response.aboutUs);
                    }
                }
            );
        }
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }, [vendor]);

    const [ImageTOView, setImageTOView] = useState(
        publicUrl + "assets/img/others/7.png?" + Math.random()
    );
    function changeImage() {
        setImageTOView(publicUrl + "assets/img/others/7.png?" + Math.random());
    }

    function onSave() {
        props.setLoading(true);
        saveWLAboutUs({
            userId: props.token,
            language: "0",
            content: data,
        })
            .then((response) => {
                if (response.resCode === 0) {
                    props.goNext();
                } else {
                    toast.error(response.resStr);
                }
                props.setLoading(false);
            })
            .catch((error) => {
                props.setLoading(false);
            });
    }

    return (
        <>
            <div className="cards-wrapper about-page-wrapper">
                <div className='custom-card'>
                    <div className="card-header">
                        <h4>من نحن</h4>
                        <h6>قم بكتابة لمحة عامّة حول نشاطك التجاري </h6>
                    </div>
                    <div className="card-body" dir="ltr" style={{ minHeight: 300 }}>
                        {/* <Editor
                            editorState={editorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            placeholder='أدخل النص هنا'
                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'] }}
                            onEditorStateChange={setEditorState}
                        /> */}
                        <div className={`toolbar-container`}></div>
                        <CKEditor
                            editor={DecoupledEditor}
                            config={{
                                language: {
                                    ui: 'ar',
                                    content: 'ar',
                                },
                                toolbar: {
                                    items: [
                                        'undo', 'redo',
                                        '|', 'heading',
                                        '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                        '|', 'bold', 'italic', 'strikethrough',
                                        '|', 'alignment',
                                        '|', 'link', 'blockQuote',
                                        '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                    ],
                                    shouldNotGroupWhenFull: false
                                },
                                mediaEmbed: false
                            }}
                                
                            data={data}
                            onReady={(editor) => {
                                const toolbarContainer = document.querySelector(`.toolbar-container`);
                                toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                            }}
                            onChange={(event, editor) => {
                                const content = editor.getData();
                                setData(content);
                            }}
                        />
                    </div>
                </div>
                <div className='custom-card'>
                    <div className="card-header">
                        <h4>{t("MainImage")}</h4>
                        <h6>قم باختيار صورة تعبّر عن رؤيتك</h6>
                    </div>
                    <div className="card-body" style={{ height: 300 }}>
                        <div className="m-auto d-flex flex-column justify-content-center align-items-center">
                            <img className='about-img mb-4' src={ImageTOView} alt='about-img' />
                            <UploadImage
                                token={props.token}
                                imgUrl='/assets/img/others/7.png'
                                imgSource='assets/img/others/others/'
                                goNext={(e) => changeImage()}
                            />
                        </div>
              
                    </div>
                </div>
                
            </div>
            {props.goNext && (
                <div className='col-12 mt-5 text-center next-back-btns'>
                    <LoadingButton
                        classes='btn primary-btn'
                        handleClick={onSave}
                        label={
                            props.loadingButton ? t("Next") : (
                                <>
                                    {t("Next")} <ArrowBack />
                                </>
                            )
                        }
                        loading={props.loadingButton}
                    />
                </div>
            )}
        </>
    );
};

export default AboutUsSection;