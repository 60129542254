import { Box } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import AppLayout from "../../components/layouts/app-layout";
import { ListArrow } from "../../constants/icons";
import { useAuth } from "../../helpers/context";
import PageHeader from "../../components/global-components/page-header";
import { Link } from "react-router-dom";
import { getWLProfile } from "../../api/userApi";
// import { getAboutUsAndTerms } from "../../api/generalAPI";

    // getAboutUsAndTerms("2").then((_DefLocation) => {
    //   if (_DefLocation.resCode === 0) {
    //     setContent(_DefLocation.response.extra.content);
    //   }
    // });

const TermsPage = () => {
  const { vendor } = useAuth();
  const [user, setUser] = useState();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (vendor !== 'amakkn') {
      getWLProfile({
        userName: vendor,
        language: "0",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setUser(_userInfo.response.wlUser);
        }
      });
    }
  }, [vendor]);
  
  return (
    <AppLayout pageTitle={vendor !== 'amakkn' ? 'اتفاقية استخدام خدمة أماكن برو' : 'اتفاقية استخدام منصة أماكن'} withFooter>
      {vendor !== 'amakkn' && <PageHeader name='اتفاقية استخدام خدمة أماكن برو' />}
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        {vendor === 'amakkn' &&
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000",
            }}>
            اتفاقية استخدام منصة أماكن
          </Box>}
        <div
          className={`row align-items-center justify-content-end mx-0 ${vendor === 'amakkn' ? 'pd-top-30 m-0' : ''} `}
          dir="rtl"
          style={{ marginTop: vendor === 'amakkn' ? '0' : '-50px !important' }}
        >
          {vendor === 'amakkn' ? (
            <>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>مقدمة</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        بالدخول إلى منصة أماكن، المملوكة والمداره من قبل شركة الأساليب المبتكرة للتسويق الإلكتروني، فإنك تقر، بلا أي استثناءات أو قيود، بموافقتك على شروط وأحكام هذه الاتفاقية وأية سياسات أخرى تحكم استخدام خدمات (منصة أماكن)، كما تكفل وتضمن أهليتك القانونية لإبرام العقود وفقًا لهذه الاتفاقية.
                      </li>
                      <li><ListArrow />
                        يتوجب عليك مراجعة اتفاقية استخدام منصة اماكن بشكل دوري لضمان اطلاعك على أي تعديلات تحديثات تطرأ على الاتفاقية المفعول بها. وعليه، يُفهم من استمرارك في استخدام منصة أماكن أنك تقبل بصورة ضمنية أية تعديلات على هذه الشروط والأحكام.
                      </li>
                      <li><ListArrow />
                        تم وضع اتفاقية الشروط والأحكام المنشورة على منصة أماكن بهدف حماية وصيانة حقوق كلاً من شركة الأساليب المبتكرة للتسويق الإلكتروني ومستخدمي منصة أماكن، سواء كانوا مسجلين أو غير مسجلين.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>التعريفات</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        <b>الشركة</b>: شركة الأساليب المبتكرة للتسويق الإلكتروني شركة قائمة بموجب القوانين المعمول بها في المملكة العربية السعودية، تتخذ من مدينة الرياض مقراً رئيسياً لها، معنية بإدارة وتشغيل منصة أماكن التي تقدم الخدمات التسويقية والدعائية العقاريّة.
                      </li>
                      <li><ListArrow />
                        <b>المنصة</b>: تشير إلى مجموعة المواقع والتطبيقات الإلكترونية التي تُقدم من خلال منصة اماكن، تشمل هذه المنصة على سبيل المثال لا الحصر:
                        <ul className="pt-3">
                          <li>تطبيق أماكن على نظام  iOS.</li>
                          <li>تطبيق أماكن على نظام أندرويد.</li>
                          <li>موقع أماكن الإلكتروني <Link to={'/'}>www.amakkn.com</Link> .</li>
                          <li>المواقع الصادرة عن طريق خدمة أماكن برو.</li>
                        </ul>
                      </li>
                      <li><ListArrow />
                        <b>المستخدم</b>: الفرد الطبيعي أو الكيان الاعتباري الذي يقوم بالدخول إلى أو استخدام المنصة، سواء كان ذلك مع التسجيل أو بدونه، للاستفادة من الخدمات المقدمة عبر المنصة.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الأولى: الأحكام العامة</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        <b>تطبيق القوانين المحلية</b>: تخضع جميع بنود هذه الاتفاقية للأنظمة والقوانين والتعليمات السارية في المملكة العربية السعودية.                      </li>
                      <li><ListArrow />
                        <b>سريان البنود</b>: في حالة تحديد أي بند من بنود هذه الاتفاقية كغير قانوني، باطل، أو غير نافذ الصلاحية بموجب أي تشريعات، قوانين، أو أحكام قضائية معمول بها، تظل البنود والشروط الأخرى قائمة، وقانونية وملزمة.                      </li>
                      <li><ListArrow />
                        <b>دقة المعلومات ومسؤولية الشركة</b>: تلتزم (الأساليب المبتكرة للتسويق الإلكتروني) ببذل جهود معقولة لضمان دقة وصحة المعلومات المقدمة عبر (منصة أماكن). ومع ذلك، قد تظهر أخطاء غير متوقعة ولن تتحمل الشركة المسؤولية عن أية أخطاء في المعلومات المقدمة عبر المنصة.                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الثانية: الاستخدام المقبول</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        باستخدامك (منصة أماكن)، تقر وتوافق على الالتزام بما يلي:
                        <ul className="pt-3">
                          <li>الامتثال لجميع الشروط والأحكام الواردة في هذه الاتفاقية.</li>
                          <li>استخدام المنصة لأغراضك الشخصية أو بالنيابة عن الأطراف التي تمثلها قانونيًا.</li>
                          <li>التأكد من دقة وصحة كافة المعلومات التي تُدخلها على المنصة.</li>
                          <li>الامتناع عن نشر أو مشاركة أي معلومات لا تمتلك حقوق نشرها أو توزيعها.</li>
                          <li>عدم نسخ أو إعادة نشر أي من المحتويات المقدمة على المنصة بدون الحصول على إذن مسبق من أصحاب الحقوق.</li>
                          <li>تحمل المسؤولية الكاملة عن التحقق من صحة ودقة المحتويات المنشورة على المنصة والاستعانة بالخبراء أو المستشارين القانونيين عند الضرورة.</li>
                          <li>دفع جميع الرسوم المترتبة على استخدام خدمات المنصة وفقًا للجداول الزمنية المحددة.</li>
                          <li>الالتزام بجميع الاشتراطات والمتطلبات القانونية والحكومية اللازمة للاستفادة من الخدمات المقدمة.</li>
                          <li>الامتناع عن التعدي على حقوق الآخرين، أو ارتكاب أفعال تضليلية، أو خداعية، أو ضارة.</li>
                          <li>عدم استخدام أدوات أو تقنيات لتجاوز القيود التقنية أو الأمنية المفروضة على المنصة مثل استخدام VPN أو Proxy.</li>
                          <li>الامتناع عن نشر محتوى قد يُعتبر تشهيريًا، مهينًا، مسيئًا، عنصريًا أو غير لائق أخلاقيًا.</li>
                          <li>الامتناع عن نشر أي محتوى وهمي، مضلل، غير دقيق أو خادع.</li>
                          <li>تجنب الانخراط في مناقشات أو نشاطات لا تتعلق بالخدمات المقدمة والتي قد تعتبر حساسة سياسيًا أو دوليًا.</li>
                          <li>عدم انتهاك حقوق الملكية الفكرية الخاصة بالشركة أو أي طرف ثالث.</li>
                          <li>عدم التظاهر بأنك تمثل (الأساليب المبتكرة للتسويق الإلكتروني) أو أي من ممثليها بدون إذن صريح.</li>
                          <li>تحمل المسؤولية عن تعويض (الأساليب المبتكرة للتسويق الإلكتروني) عن أية خسائر أو التزامات أو دعاوى قضائية ناشئة عن سوء استخدامك للمنصة.</li>
                          <li>تحمل المسؤولية القانونية والجنائية عن أي انتهاك لشروط وأحكام هذه الاتفاقية.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الثالثة: أحكام خدمة (الإعلانات العقاريّة)</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        عند نشرك لأي إعلان عقاري على (منصة أماكن)، تقر وتلتزم بالتزامات التالية:
                        <ul className="pt-3">
                          <li><b>الامتثال للتشريعات</b>: الالتزام بجميع القوانين والأنظمة المعمول بها، والحصول على جميع التراخيص اللازمة للعقار المعروض، مع ضمان قانونية الإعلان وتحمل المسؤولية الكاملة عن أية مخالفات قد تنشأ.</li>
                          <li><b>صحة المعلومات</b>: ضمان دقة وصحة المعلومات الواردة في الإعلان والتزام بتحديثها بشكل دوري على (منصة أماكن).</li>
                          <li><b>ثبات تفاصيل الإعلان</b>: الامتناع عن تعديل نوع الإعلان أو موقعه بعد نشره على المنصة.</li>
                          <li><b>التفاعل مع الاستفسارات</b>: التزام بالرد على استفسارات المستخدمين المتعلقة بالإعلان بشكل فعال ومهني.</li>
                          <li><b>الرسوم</b>: الالتزام بدفع العمولة المقررة على الإعلان في حالة إتمام التصرف العقاري، ما لم يتم الإعفاء من الرسوم.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الرابعة: خدمة أماكن برو</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        <b>إنشاء المواقع</b>: يمكن للمستخدمين عبر "أماكن برو" إنشاء مواقع عقارية تتوافق مع التشريعات السارية. تقدم هذه المواقع أدوات وميزات تساعد في تسهيل التجارة العقاريّة وإدارتها.
                      </li>
                      <li><ListArrow />
                        <b>ملكية المواقع</b>: جميع المواقع التي يتم إنشاؤها من خلال "أماكن برو" تبقى ملكية لـ"أماكن"، ويتم تأجيرها للمستخدمين بموجب شروط تحددها "أماكن".
                      </li>
                      <li><ListArrow />
                        <b>ملكية النطاقات</b>: النطاقات المستخدمة للمواقع المنشأة تحت خدمة "أماكن برو" تعتبر ملكًا حصريًا لـ"أماكن". "أماكن" تحتفظ بحقوق التحكم الكاملة في هذه النطاقات، بما في ذلك وليس محصورًا بحقوق التعديل، النقل، أو إلغاء التسجيل.
                      </li>
                      <li><ListArrow />
                        <b>الالتزامات التشريعية</b>: يجب على المستخدمين الامتثال لجميع القوانين والتشريعات السارية فيما يخص استخدام وإدارة المواقع العقاريّة ضمن "أماكن برو".
                      </li>
                      <li><ListArrow />
                        <b>التعديلات والتحديثات</b>: تحتفظ "أماكن" بالحق في إجراء أي تعديلات أو تحديثات على شروط الخدمة "أماكن برو"، وذلك وفقًا لتقديرها وبناءً على متطلبات السوق أو التغييرات التشريعية.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الخامسة: أحكام المدفوعات</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        عند قيامك بأي عملية دفع للخدمات المقدمة عبر (منصة أماكن)، تقر وتوافق على الشروط التالية:
                        <ul className="pt-3">
                          <li><b>حقوق (منصة أماكن) بشأن الاسترجاع</b>: تحتفظ (منصة أماكن) بالحق في رفض استرجاع أي مبلغ دُفع مقابل الخدمات، مثل الباقات، الترقيات، التمييز، الاشتراكات، والرسوم الا في حال توقف الخدمة عن العمل لمدة 30 يوم</li>
                          <li><b>رسوم الاسترجاع</b>: عند طلب استرجاع أي مبالغ، توافق على تحمل جميع الرسوم التي قد تتكبدها (الأساليب المبتكرة للتسويق الإلكتروني) نتيجة عملية الاسترجاع، مثل رسوم الحوالات المالية أو رسوم بوابات الدفع.</li>
                          <li><b>مبالغ الاشتراكات غير مستردة</b>: تقر بأن مبالغ الاشتراكات المدفوعة لاستخدام (منصة أماكن) او أي من خدماتها غير مستردة تحت أي ظروف.</li>
                          <li><b>الحسابات المخالفة</b>: لا يتم استرداد أية مبالغ للحسابات التي تم تعليقها أو إيقافها بسبب مخالفتها لاتفاقية الاستخدام.</li>
                          <li><b>المخالفات القانونية</b>: لا يتم استرداد أية مبالغ للحسابات أو الأشخاص الذين تمت مخالفتهم من قبل الهيئة العامة للعقار أو أي جهة اختصاص أخرى.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة السادسة: الإجراءات الوقائية</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        في حالة مخالفة أي من الشروط والأحكام الموضحة في هذه الاتفاقية، تحتفظ (الأساليب المبتكرة للتسويق الإلكتروني) بالحق في اتخاذ إجراءات وقائية لحماية مصالحها والحفاظ على نزاهة المنصة، وقد تشمل هذه الإجراءات ما يلي:
                        <ul className="pt-3">
                          <li><b>إيقاف العضوية</b>: التعليق المؤقت أو الدائم لعضوية المستخدم الذي يخالف الشروط والأحكام.</li>
                          <li><b>حذف المحتوى</b>: إزالة أي محتوى يتم نشره بشكل يخالف الاتفاقية.</li>
                          <li><b>التبليغ للسلطات المختصة</b>: إبلاغ الجهات القانونية المعنية في حالة وجود أنشطة غير قانونية أو مشتبه بها.</li>
                          <li><b>اتخاذ إجراءات قانونية</b>: مباشرة الإجراءات القانونية ضد المخالفين للشروط والأحكام لحماية حقوق ومصالح (الأساليب المبتكرة للتسويق الإلكتروني).</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة السابعة: حدود المسؤولية</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        (الأساليب المبتكرة للتسويق الإلكتروني) لا تقدم ضمانات ولا تتحمل أي مسؤولية قانونية بشأن:
                        <ul className="pt-3">
                          <li><b>دقة المعلومات</b>: صحة أو كمال المعلومات المنشورة على (منصة أماكن). تعتبر جميع المعلومات المقدمة كما هي، ويتحمل المستخدمون المسؤولية الكاملة عن التحقق منها.</li>
                          <li><b>أضرار الاستخدام</b>: أي أضرار مباشرة أو غير مباشرة، خاصة أو عرضية، قد تنجم عن استخدام أو العجز عن استخدام المنصة، بما في ذلك وليس محصورًا بالأضرار الناجمة عن الأرباح المفقودة، انقطاع الأعمال، أو فقدان المعلومات التجارية.</li>
                          <li><b>أعطال البرمجيات وأخطاء الإنترنت</b>: أية مشكلات تقنية أو أعطال في البرمجيات أو الشبكة قد تؤثر على توفر المنصة أو أدائها.</li>
                          <li><b>أسباب خارجة عن السيطرة</b>: أي خسائر أو أضرار ناجمة عن أحداث خارج نطاق السيطرة المعقولة لـ(الأساليب المبتكرة للتسويق الإلكتروني)، مثل الكوارث الطبيعية، الأعمال الإرهابية، الإضرابات، أو الأحداث السياسية.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة الثامنة: سياسة حقوق الملكية الفكرية</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        عند إسهامك بأي محتوى على (منصة أماكن)، تمنح بذلك (الأساليب المبتكرة للتسويق الإلكتروني) حقوقًا غير حصرية، قابلة للنقل والترخيص الفرعي، بدون تعويض، لاستخدام المحتوى الذي تشاركه. هذه الحقوق تشمل، دون حصر:
                        <ul className="pt-3">
                          <li><b>حق النسخ</b>: القدرة على نسخ المحتوى لأغراض التشغيل والنسخ الاحتياطي والأرشفة.</li>
                          <li><b>حق التعديل</b>: القدرة على تعديل، تغيير، أو إنشاء أعمال مشتقة من المحتوى المقدم، وذلك وفقًا للضروريات التشغيلية أو التحسينات أو التحديثات.</li>
                          <li><b>حق التوزيع</b>: القدرة على توزيع، بث، عرض، ترخيص، أو بيع المحتوى لأطراف ثالثة أو للجمهور بصورة عامة.</li>
                          <li>تأكيدًا على ذلك، تضمن بأنك تملك حقوق المحتوى المشارك أو لديك كافة الصلاحيات اللازمة لمنح هذه الحقوق. كما توافق على تعويض (الأساليب المبتكرة للتسويق الإلكتروني) عن أي دعاوى أو خسائر قد تنجم عن استخدام المحتوى الذي توفره.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة التاسعة: سياسة خصوصية البيانات</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        باستخدامك للمنصة تقر وتوافق صراحةً على أن منصة أماكن مخولة بجمع بياناتك الشخصية واستخدامها فيما يلي:
                        <ul className="pt-3">
                          <li><b>تحسين الخدمات</b>: استخدام البيانات لتعزيز وتحسين جودة الخدمات المقدمة عبر المنصة.</li>
                          <li><b>تحليل البيانات</b>: تحليل البيانات المجمعة لفهم أنماط الاستخدام وتطوير استراتيجيات أفضل للمستخدمين.</li>
                          <li><b>التسويق</b>: استخدام البيانات في أنشطة التسويق والإعلان التي قد تشمل إرسال تحديثات وعروض ترويجية تعتبر مفيدة أو ذات صلة بك.</li>
                          <li><b>التعاون مع السلطات القانونية</b>: مشاركة البيانات مع السلطات القانونية عند الضرورة وفقًا للمتطلبات القانونية أو في حالات التحقيقات القانونية أو الامتثال للقوانين.</li>
                          <li>تؤكد على تفهمك لحقك في الوصول إلى بياناتك الشخصية، تعديلها، وفي بعض الحالات، الطلب بحذفها، وفقًا للقوانين المعمول بها. كما توافق على تلقي الإخطارات المتعلقة بالتغييرات في سياسة الخصوصية أو شروط الاستخدام.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>المادة العاشرة: تعديل الاتفاقية</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        تحتفظ الشركة بالحق الكامل في تعديل شروط وأحكام هذه الاتفاقية في أي وقت حسب تقديرها الخاص، دون الحاجة إلى إشعار مسبق و تصبح التعديلات نافذة فور نشرها على المنصة ويعتبر استمرار استخدامك للمنصة بعد إجراء أي تعديلات بمثابة موافقتك على هذه التعديلات والتزامك بالعمل بها.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>التعريف بالخدمة</h4>
                  </div>
                  <div className="card-body">
                    <p>اماكن برو هي خدمة مقدمة من <b>منصة أماكن</b> تتيح لك إنشاء وإدارة موقع عقاري بالنطاق المخصص (<b>{user?.domain}</b>) للتسويق عن اعمالك وعرض اعلاناتك العقاريّة المرخصة.</p>
                  </div>
                </div>
              </div>
                
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>ملكية المواقع والنطاقات</h4>
                  </div>
                  <div className="card-body">
                    <p>
                      جميع المواقع والنطاقات التي تنشئ من خلال اماكن برو مملوكة لمنصة أماكن وتتيح هذه الاتفاقية للمستخدم الانتفاع منها ما دام مشتركاُ الا في حال الاخلال بشروط هذه الاتفاقية او اتفاقية استخدام منصة أماكن.
                    </p>
                  </div>
                </div>
              </div>
                
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>اشتراطات الهيئة العامة للعقار</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        هذه الخدمة مصرحة وخاضعة لرقابة الهيئة العامة للعقار.
                      </li>
                      <li><ListArrow />
                        تعديل المحتوى و إضافة الإعلانات العقاريّة المرخصة على الموقع (<b>{user?.domain}</b>) يكون متاح حصراً لـ (<b>{user?.name}</b>) المسجلة بالرقم الوطني الموحد\هوية وطنية (<b>{+user?.accountType === 2 ? user?.commercialRecordNumber : user?.advertiserNumber}</b>)
                      </li>
                      <li><ListArrow />
                        إضافة الإعلانات العقاريّة على الموقع تشترط وجود ترخيص إعلان عقاري فعال مربوط بالرقم الوطني الموحد\الهوية الوطنية (<b>{+user?.accountType === 2 ? user?.commercialRecordNumber : user?.advertiserNumber}</b>) وصادر عن طريق منصة الهيئة العامة للعقار.
                      </li>
                      <li><ListArrow />
                        تسجيل النطاق لدى المركز السعودي للأعمال خلال 14 يوم.
                      </li>
                      <li><ListArrow />
                        الامتثال لأي تشريعات قد تطرأ مستقبلاً من الجهات ذات العلاقة.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>الرسوم والتجديد</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        تُطبق رسوم اشتراك شهرية أو سنوية وفقًا للخطة المختارة.</li>
                      <li><ListArrow />
                        تجدد الاشتراكات تلقائيًا في نهاية كل فترة اشتراك او في حال انتهاء الفترة التجريبية ما لم يقوم المستخدم بإلغاء الاشتراك.</li>
                      <li><ListArrow />
                        يمكن إلغاء اشتراك "اماكن برو" في أي وقت، ولكن الرسوم المدفوعة مقدمًا غير قابلة للاسترداد.</li>
                    </ul>
                  </div>
                </div>
              </div>
                
              <div className="col-12 p-0 text-right mb-2">
                <div className='custom-card' style={{ minHeight: 'auto' }}>
                  <div className="card-header">
                    <h4 style={{ color: 'var(--main-color-one)' }}>التعديلات على الخدمة والشروط</h4>
                  </div>
                  <div className="card-body">
                    <ul className="custom-list">
                      <li><ListArrow />
                        تحتفظ "أماكن" بالحق في تعديل أو إنهاء خدمة "اماكن برو" أو تغيير الرسوم دون إشعار مسبق.</li>
                      <li><ListArrow />
                        أي تغييرات في شروط الخدمة تصبح سارية المفعول بمجرد نشرها في هذه الصفحة.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </AppLayout>
  );
}

export default TermsPage;
