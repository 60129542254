import React from "react";
import { toast } from "react-toastify";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import Save from "@mui/icons-material/SaveAlt";
import { uploadWlFileBase64 } from "../../api/userApi";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import { Check } from "@mui/icons-material";
import LoadingButton from "./loading-btn";
import SwiperCore, { Autoplay, Keyboard, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import DragAndDropFileUploader from "./drag-drop-file-uploader";
import { useAuth } from "../../helpers/context";
import { DARK_TEMPLATES, DEMO_VENDORS, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);

const UploadImage = (props) => {
  const { vendor, templateId } = useAuth();
  const [open, setOpen] = React.useState(false);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [ItemSelected, setItemSelected] = React.useState("");
  const [loadingSaveImage, setLoadingSaveImage] = React.useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = React.useState(false);
  let publicUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    "/" +
    props.imgSource;

  let imgUrl = props.imgUrl;
  // const [ImageTOView, setImageTOView] = React.useState(
  //   publicUrls + imgUrl + "?not-from-cache-please"
  // );

  const imageBaseUrl = `${window.location.protocol}//${window.location.host}/assets/img/`;
  const { t } = useTranslation();

  let images =
    props.from === "services"
      ? [
        [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? imageBaseUrl + "image-1.png" : publicUrl + "1.png",
        [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? imageBaseUrl + "image-2.png" : publicUrl + "2.png",
        [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? imageBaseUrl + "image-3.png" : publicUrl + "7.png",
        [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? imageBaseUrl + "image-4.png" : publicUrl + "8.png",
        publicUrl + "3.png",
        publicUrl + "4.png",
        publicUrl + "5.png",
        publicUrl + "6.png",
      ]
      : [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ?
        [
          props.section === 'about' ? imageBaseUrl + "image-1.png" : '',
          props.section === 'about' ? imageBaseUrl + "image-2.png" : '',
          props.section === 'about' ? imageBaseUrl + "image-3.png" : '',
          props.section === 'about' ? imageBaseUrl + "image-4.png" : '',
          imageBaseUrl + "banner-1.png",
          imageBaseUrl + "banner-2.png",
          imageBaseUrl + "banner-3.png",
          imageBaseUrl + "banner-4.png",
          publicUrl + "1.png",
        ] : [
          publicUrl + "1.png",
          publicUrl + "2.png",
          publicUrl + "3.png",
          publicUrl + "5.png",
        ];

  function uploadImage(file, type, path) {
    let reader = new FileReader();
    setLoadingUploadImage(true);
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: path,
        base64Data: reader.result,
        typeOfFile: type,
        userId: props.token,
        language: "0",
      };
      uploadWlFile(fileInfo);
    };
  }

  function uploadWlFile(fileInfo) {
    if (DEMO_VENDORS.includes(vendor)) {
      if (props.section === 'about') {
        const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
        localStorage.setItem(`about-${vendor}`, JSON.stringify({
          content: aboutStorage?.content || '',
          image: fileInfo.base64Data
        }));
        
      }
      setLoadingSaveImage(false);
      toast.success(t("ImageSavedSuccessfully"));
      setOpen(false);
      props?.goNext?.(fileInfo);

    } else {
      uploadWlFileBase64(fileInfo)
        .then((resp) => {
          if (resp.resCode === 0) {
            if (props.goNext) {
              props.goNext(fileInfo);
            }
            toast.success(t("ImageSavedSuccessfully"));
            // setImageTOView(publicUrls + imgUrl + "?" + Math.random());
            setOpen(false);
          } else {
            toast.error(resp.resStr);
          }
          setLoadingSaveImage(false);
          setLoadingUploadImage(false);
        })
        .catch((e) => {
          setLoadingUploadImage(false);
          setLoadingSaveImage(false);
        });
    }
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleSave = () => {
  //   setOpen(false);
  // };

  async function saveImage(imageURL) {
    let img = new Image();
    img.src = imageURL;
    img.crossOrigin = "Anonymous";
    setLoadingSaveImage(true);
    img.onload = function () {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);

      // Unfortunately, we cannot keep the original image type, so all images will be converted to PNG
      // For this reason, we cannot get the original Base64 string
      let uri = canvas.toDataURL("image/png"),
        b64 = uri;
      let fileInfo = {
        pathWithFileName:
          imgUrl +
          "" +
          (props.handleSave
            ? imageURL.substring(imageURL.lastIndexOf("/") + 1)
            : ""),
        base64Data: b64,
        typeOfFile: "jpg",
        userId: props.token,
        language: "0",
      };
      if (DEMO_VENDORS.includes(vendor)) {
        if (props.section === 'about') {
          const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
          localStorage.setItem(`about-${vendor}`, JSON.stringify({
            content: aboutStorage?.content || '',
            image: fileInfo.base64Data
          }));
          
        } else if (props.section === 'home') {
          localStorage.setItem(`home-${vendor}`, fileInfo.base64Data);
        }

        setLoadingSaveImage(false);
        toast.success(t("ImageSavedSuccessfully"));
        setOpen(false);
        props?.goNext?.(fileInfo);
        if (props.handleSave)
          props.handleSave(fileInfo);
      } else {
        if (props.handleSave)
          props.handleSave(fileInfo);
        else
          uploadWlFile(fileInfo);
      }
    };
  }

  return (
    <div>
      <button className='custom-btn' onClick={(e) => handleClickOpen()}>
        {t("ChangeImage")}
      </button>

      <Dialog
        onClose={handleClose}
        fullWidth={true}
        className='custom-dialog'
        style={{ direction: "rtl", textAlignLast: "right" }}
        maxWidth={"xl"}
        sx={{ "& .MuiPaper-root": { margin: 0, width: "calc(100% - 20px)" } }}
        open={open}>
        <DialogTitle>
          <div className='dialog-head'>
            <div>
              <h2>تخصيص الصورة</h2>
            </div>
            <div className='dialog-actions close-only'>
              <span onClick={handleClose} style={{ cursor: "pointer" }}>
                <Close />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='row justify-content-start'>
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <div style={{ flexGrow: 1, width: "100%" }}>
                <div
                  style={{
                    textAlignLast: "center",
                    direction: "rtl",
                  }}>
                  <h4 className='text-align-last-center mb-4'>
                    اختر صورة من الصور التالية أو قم برفع صورة من اختيارك
                  </h4>
                  <div className='container parent'>
                    <Swiper
                      className=''
                      modules={[Navigation, Keyboard]}
                      keyboard={{ enabled: true }}
                      spaceBetween={isLargeScreen ? 25 : 10}
                      slidesPerView={isLargeScreen ? 3 : 1}
                      navigation={{
                        nextEl: ".swiper-button-next2",
                        prevEl: ".swiper-button-prev2",
                      }}
                      // autoplay={{
                      //   delay: 2000,
                      //   pauseOnMouseEnter: true,
                      //   disableOnInteraction: false,
                      // }}
                      loop={true}
                    // speed={2500}
                    >
                      {images.map((e, i) => e !== '' && (
                        <SwiperSlide
                          key={i}
                          style={{
                            cursor: "pointer",
                            borderRadius: 10,
                            height: "220px",
                          }}>
                          <div
                            className={`custom-label ${e === ItemSelected ? "checked" : ""
                              }`}
                            onClick={() => setItemSelected(e)}>
                            <img
                              src={e}
                              alt={e}
                              style={{
                                height: "220px",
                                width: "100%",
                                cursor: "pointer",
                                transition: "transform 1s",
                                objectFit: "cover",
                                borderRadius: 10,
                              }}
                            />
                            <div className='tick_container'>
                              <div className='tick'>
                                <Check
                                  sx={{
                                    width: "26px !important",
                                    fontSize: "35px",
                                    fontWeight: 900,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className='swiper-button-next2'>
                      <ChevronRight />
                    </div>
                    <div className='swiper-button-prev2'>
                      <ChevronLeft />
                    </div>
                  </div>
                  <div
                    className='mt-4 d-flex w-100 align-items-center justify-content-center flex-wrap'
                    style={{ gap: 10 }}>
                    <LoadingButton
                      classes='btn primary-btn'
                      handleClick={(e) => saveImage(ItemSelected)}
                      label={
                        loadingSaveImage ? t("SaveImage") : (
                          <>
                            {t("SaveImage")} <Save />
                          </>
                        )
                      }
                      loading={loadingSaveImage}
                      OKdisabled={!ItemSelected}
                    />
                    <DragAndDropFileUploader
                      singleFile
                      ImageOnly
                      icon={<CloudUploadRoundedIcon />}
                      handleChange={(e) =>
                        props.handleChange
                          ? props.handleChange(e)
                          : uploadImage(e.target.files[0], "jpg", imgUrl)
                      }
                      loading={loadingUploadImage}
                      title='رفع صورة جديدة'
                      id='contained-button-file'
                      classes='btn outlined-btn m-0'
                      style={{
                        boxShadow: "none",
                        padding: "6px 20px",
                      }}
                      buttonMode
                      darkIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadImage;
