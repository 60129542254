import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  TableContainer,
  Paper,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { Trash } from "../../../constants/icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  saveCustomer,
  getCustomer,
} from "../../../api/userApi";
import { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, preventString, resolvePersianAndArabicNumbers, validateEmail } from "../../../helpers";
import EditInterestings from "./edit-interestings";
import { CITIES, DEMO_VENDORS, DISTRICTS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { countriesCode } from "../../../constants/countries-code";
// import Flag from 'react-world-flags'

const citiesOptions = CITIES.map((city) => ({
  id: +city.CITY_ID,
  label: city.CITYNAME_AR,
  regionId: +city.REGION_ID,
}));

const districtsOptions = DISTRICTS.map((district) => ({
  id: +district.DISTRICT_ID,
  label: district.DISTRICTNAME_AR,
  regionId: +district.REGION_ID,
  cityId: +district.CITY_ID,
}));

const AddOrEditCustomer = () => {
  const { token, vendor } = useAuth();
  const { id } = useParams();
  const isNew = window.location.href.indexOf("add") > -1;
  const [client, setClient] = useState({
    name: '',
    type: '',
    phone: '',
    email: '',
    notes: '',
    seriousness: '',
    interestingList: [],
    countryCode: '+966'
  });
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = React.useState({});
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(
    () => {
      if (id)
        getData();
      else
        setClient({
          name: '',
          type: '',
          phone: '',
          email: '',
          notes: '',
          seriousness: '',
          interestingList: [],
          countryCode: '+966'
        });
    }, // eslint-disable-next-line
    [id, vendor]
  );


  const getData = () => {
    getCustomer({
      "vendorName": vendor,
      "language": "0",
      "customerId": id
    }).then((res) => {
      if (res.resCode === 0) {
        setClient({ ...res.response, interestingList: res.response.interests });
      } else {
        toast.error(res.resStr);
      }
    });
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 15,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    }
  }));

  function handleDeleteInteresting(id) {
    setClient({ ...client, interestingList: client.interestingList?.filter(i => i.id !== id) });
    handleSave(client.interestingList?.filter(i => i.id !== id),true);
  }


  function formIsValid() {
    const _errors = {};
    if (!client.name)
      _errors.name = "الرجاء ادخال اسم العميل";

    if (!client.type)
      _errors.type = "الرجاء اختيار نوع العميل";

    if (!client.phone) _errors.phone = "الرجاء ادخال رقم الهاتف";
 
    if (client.email && !validateEmail(client.email))
      _errors.email = "الرجاء ادخال بريد إلكتروني صحيح";

    if (client.interestingList.length === 0)
      _errors.interestingList = "الرجاء إضافة اهتمام واحد على الأقل";


    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  const handleSave = (list,withoutRedirect=false) => {
    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      let temp = list.length > 0 ? list : client.interestingList;
      const interestingList = temp.map(i => {
        return {
          ...i,
          neighbourhoodIds: i.neighbourhood || i.neighbourhoodIds,
          priceFrom: i.priceFrom || 0,
          priceTo: i.priceTo || 999999999,
        
        }
      });
      saveCustomer({
        "userId": token,
        "language": "0",
        "customerId": isNew ? undefined : id,
        "name": client.name,
        "type": client.type,
        "phone": isNew ? (client.countryCode === '+966' ? client.phone : client.countryCode + client.phone) : client.phone,
        "email": client.email,
        "notes": client.notes,
        "seriousness": client.seriousness,
        "interestingList": interestingList
      }).then(res => {
        if (res.resCode === 0) {
          toast.success(isNew ? 'تمّت إضافة العميل بنجاح' : 'تمّ تعديل العميل بنجاح');
          if(!withoutRedirect)
          history('/admin/customers');
        } else {
          toast.error(res.resStr);
        }
      
      }).finally(() => setLoadingButton(false));
    }
  }

  return (
    <div>
      <SubHeader OKElement={<></>} />
    
      <div className='custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>{t("BasicInformation")}</h4>
          <h6>قم بادخال المعلومات الأساسيّة الخاصّة بهذا العميل</h6>
        </div>
        <div className='card-body'  >
          <div className='row'>
       
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl
                error={Boolean(errors.name)}
                className="col-12" dir="rtl"
              >
                <TextField
                  label={t("CustomerName")}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={client.name}
                  size='small'
                  onChange={(v) => {
                    setClient({
                      ...client,
                      name: resolvePersianAndArabicNumbers(v.currentTarget.value)
                    });
                  }}
                  error={Boolean(errors.name)}
                />
                {errors.name &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.name}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl
                error={Boolean(errors.type)}
                className="custom-select" dir="rtl">
                <InputLabel htmlFor='type' required>{t("CustomerType")}</InputLabel>
                <Select
                  value={client.type}
                  required
                  sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                  error={Boolean(errors.type)}
                  onChange={(event) => {
                    setClient({
                      ...client,
                      type: event.target.value,
                      interestingList: event.target.value === "مالك" || event.target.value === "باحث" ? [] : client.interestingList
                    });
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("CustomerType")}
                    id="type"
                  />}
                  label={t("CustomerType")}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}>
                  <MenuItem value='مالك'>مالك</MenuItem>
                  <MenuItem value='باحث'>باحث</MenuItem>
                  <MenuItem value='وسيط'>وسيط</MenuItem>
                </Select>
                {errors.type &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.type}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl className="custom-select" dir="rtl">
                <InputLabel htmlFor='seriousness'>{t("CustomerSeriousness")}</InputLabel>
                <Select
                  value={client.seriousness}
                  sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                  onChange={(event) => {
                    setClient({
                      ...client,
                      seriousness: event.target.value,
                    });
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("CustomerSeriousness")}
                    id="seriousness"
                  />}
                  label={t("CustomerSeriousness")}
                  inputProps={{
                    name: "seriousness",
                    id: "seriousness",
                  }}>
                  <MenuItem value='منخفضة'>{t("Low")}</MenuItem>
                  <MenuItem value='متوسطة'>{t("Medium")}</MenuItem>
                  <MenuItem value='مرتفعة'>{t("High")}</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-3' dir='ltr'>
              {isNew && (
                 <FormControl className="col-4" dir="ltr">
                 <Select size="small" value={client.countryCode} onChange={(event) => {
                   setClient({
                     ...client,
                     countryCode: event.target.value,
                   });
                 }}>
                   {countriesCode?.map((code) => (
                     <MenuItem value={code.dial_code} key={code.code} className="country-code">
                       {/* <Flag code={code.code} width={32} /> */}
                       {/* &nbsp; */}
                       {code.dial_code}
                     </MenuItem>
                   ))}
                 </Select>
               </FormControl>
             )}
              <FormControl
                error={Boolean(errors.phone)}
                className={`${isNew ? 'col-8' : 'col-12'}`} dir="ltr"
              >
                <TextField
                  type='text'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%", dir: 'ltr' }}
                  size='small'
                  required
                  label={t("PhoneNumber")}
                  onChange={(e) => {
                    setClient({
                      ...client,
                      phone: preventString(e.currentTarget.value),
                    });
                  }}
                  value={client.phone}
                  error={Boolean(errors.phone)}
                />
                {errors.phone &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.phone}
                    </Alert>
                  </FormHelperText>
                }
              </FormControl>
          
            </div>
            
            <div
              className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-3'
              dir='ltr'>
              <FormControl
                error={Boolean(errors.email)}
                className="col-12" dir="rtl"
              >
                <TextField
                  type='text'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%" }}
                  size='small'
                  label={t("Email")}
                  onChange={(e) => {
                    setClient({
                      ...client,
                      email: resolvePersianAndArabicNumbers(e.currentTarget.value),
                    });
                  }}
                  error={Boolean(errors.email)}
                  value={client.email}
                />
                {errors.email &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.email}
                    </Alert>
                  </FormHelperText>
                }
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <div>
            <h4>{t("ListOfInterests")}</h4>
            <h6>يمكنك إضافة, تعديل, وحذف الاهتمامات الخاصة بهذا العميل</h6>
          </div>
          <div>
            <EditInterestings
              isEdit={false}
              setClient={setClient}
              client={client}
              onOK={handleSave}
            />
          </div>

        </div>
        <div className='card-body'>
          {client.interestingList && client.interestingList?.length > 0 && (
            <TableContainer className="table-container responsive-table"
              component={Paper}>
              <Table sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='right'>{t("TypeOfInterest")}</StyledTableCell>
                    <StyledTableCell align='right'>{t("PropertyType")}</StyledTableCell>
                    <StyledTableCell align='right'>نطاق السعر (ريال)</StyledTableCell>
                    <StyledTableCell align='right'>{t("Area")} {t("m2")}</StyledTableCell>
                    <StyledTableCell align='right'>{t("Address")}</StyledTableCell>
                    <StyledTableCell align='right'>{t("Actions")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {client.interestingList.map((item) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell align='right'>{item.interestingType}</StyledTableCell>
                      <StyledTableCell align='right'>{item.propertyType}</StyledTableCell>
                      <StyledTableCell align='right'>
                        {Intl.NumberFormat("en").format(item.priceFrom) + ' - ' + Intl.NumberFormat("en").format(item.priceTo)}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {item.interestingType === "بيع" || item.interestingType === "تأجير" ? (item?.areaFrom ? Intl.NumberFormat("en").format(item.areaFrom) : t("NotAvailable")) :
                          (item?.areaFrom ? Intl.NumberFormat("en").format(item.areaFrom) : t("NotAvailable")) + ' - ' + (item?.areaTo ? Intl.NumberFormat("en").format(item.areaTo) : t("NotAvailable"))}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {citiesOptions.filter(
                          (i) => +i.id === +item?.city
                        )?.[0]?.label || null}{" - "}
                        {districtsOptions.filter(
                          (i) => item?.neighbourhood?.includes(+i.id) || item?.neighbourhoodIds?.includes(+i.id)
                        )?.map((i, index) => i.label + (index === (item?.neighbourhoodIds?.length || item?.neighbourhood?.length) - 1 ? '' : ', ')) || null}
                      </StyledTableCell>

                      <StyledTableCell align='right'>
                        <div className='actions-wrapper'>
                          <Tooltip title="تعديل الاهتمام" placement="top">
                            <EditInterestings
                              model={item}
                              isEdit={true}
                              onOK={handleSave}
                              setClient={setClient}
                              client={client}
                            />
                          </Tooltip>
                          <Tooltip title="حذف الاهتمام" placement="top">
                            <button
                              onClick={(e) => handleDeleteInteresting(item.id)}
                              className='custom-btn custom-btn2 delete-btn'
                            >
                              <Trash />
                            </button>
                          </Tooltip>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {errors?.interestingList && client.interestingList?.length === 0 &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.interestingList}
              </Alert>
            </FormHelperText>
          }
        </div>
      </div>

      
      <div className='custom-card'>
        <div className='card-header'>
          <h4>ملاحظات (اختياري)</h4>
          <h6>يمكنك إضافة ملاحظات حول هذا العميل</h6>
        </div>
        <div className='card-body'>
          <div className='fields-wrapper'>
            <TextField
              label={t("Notes")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={client.notes}
              size='small'
              multiline
              rows={5}
              onChange={(v) => {
                setClient({
                  ...client, notes: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign'>
              {client.notes
                ? parseInt(client?.notes?.length) + "/500"
                : "0/500"}
            </h6>
          </div>
        </div>
      </div>

      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <div className="text-center pb-5 mb-5">
        <LoadingButton
          style={{ minWidth: "250px", justifyContent: "center" }}
          label={t("Save")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default AddOrEditCustomer;
