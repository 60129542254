import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import LoadingButton from "../../global-components/loading-btn";
import { preventString } from "../../../helpers";
import { useTranslation } from "react-i18next";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SingleOTPInputComponent = (props) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <input ref={inputRef} {...rest} />;
}

const SingleOTPInput = memo(SingleOTPInputComponent);

const OTPInputComponent = (props) => {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    onSubmit,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(length).fill(''));

  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = preventString(otp.join(''));
      onChangeOTP(otpValue);
    },
    [onChangeOTP],
  );

  const getRightValue = useCallback(
    (str) => {
      let changedValue = preventString(str);

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput],
  );

  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues],
  );

  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
         onChangeOTP(preventString(updatedOTPValues.join('')));
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    // eslint-disable-next-line
    [activeInput, getRightValue, length, otpValues],
  );

  return (
    <div {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleOTPInput
            key={`SingleInput-${index}`}
            type={isNumberInput ? 'number' : 'text'}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            style={inputStyle}
            className={inputClassName}
            disabled={disabled}
          />
        ))}
    </div>
  );
}

const OTPInput = memo(OTPInputComponent);

const OTPForm = (props) => {
  const { t } = useTranslation();
  
  return (
    <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: props.admin ? 0:'20px 10% 70px' }}>
      <div className={props.admin ? '' : 'container'}>
        <h4 className={`page-heading ${props.admin?'text-center':""}`}>ادخل رمز التفعيل</h4>
        <p>تم إرسال رمز التفعيل إلى جوالّك, يرجى ادخاله ادناه</p>
    
        <form onSubmit={props.onSubmit}>
          <div className="col-12 mb-2 p-0" dir="ltr">
            <OTPInput
              autoFocus
              isNumberInput={false}
              length={6}
              className={props.admin ? "text-center otpContainer":"otpContainer"}
              onSubmit={props.onSubmit}
              inputClassName="otpInput"
              onChangeOTP={(otp) => props.onChange(otp)}
            />
          </div>
          <div className="col-12 mb-2 p-0" dir="rtl">
            {props.errors.code ?
              <Alert severity="error" className="custom-alert ">
                {props.errors.code}
              </Alert>
              : ''
            }
          </div>
          <div className="col-12 p-0">
            <LoadingButton
              classes={props.admin ? "mt-4 btn primary-btn px-3 w-100 justify-content-center" : "primary-btn px-5 py-2 d-flex justify-content-center"}
              label={props.admin ? t("Continue") : t("Next")}
              type="submit"
              loading={props.loading}
            />
          </div>
        
        </form>
      </div>
    </div>
  );
}

OTPForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default OTPForm;