import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import MySubscription from "./components/my-subscription";
import { getUserPlan, getUserProile } from "../../../api/userApi";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import Forbidden from "../../shared/403";
import { useTranslation } from "react-i18next";

const MySubscriptionPage = () => {
  const { token, UserType, setToken, setUserType, setUserId, setAccountType } = useAuth();
  let publicUrl = process.env.REACT_APP_URL + "/";
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [userVerified, setUserVerified] = useState(true);
  const [NormalPlanCanceled, setNormalPlanCanceled] = useState('1');
  let { userId } = useParams();
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const getUserPlanFunc = async () => {
    setLoadingData(true);
    getUserPlan({ userId: token, language: '0' }).then((result) => {
      if (result.resCode === 0) {
        setData(result.response);
      } else {
        toast.error(result.resStr)
      }
      setLoadingData(false);

    }).catch(e => {
      setLoadingData(false);
    });
  }

  useEffect(() => {
    if (token) {
      getUserPlanFunc();
    }
  }, // eslint-disable-next-line
    [token]);


  const getUserData = (id) => {
    getUserProile({
      userId: id ? id : token,
      language: "0",
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (id) {
          setToken(id);
          setAccountType(_userInfo.response.userProfile?.accountType);
          setUserId(_userInfo.response.userProfile?.userId);
          setUser(_userInfo.response.userProfile);
          setUserType(_userInfo.response.userProfile?.userType);
        }
        setNormalPlanCanceled(_userInfo.response.userProfile?.normalPlanCanceled);
        if (_userInfo.response.userProfile?.isUserVerified !== "3") {
          setUserVerified(false);
        }
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
      } else {
        getUserData();
      }
    }, // eslint-disable-next-line
    [userId]
  );

  return (
    <AppLayout needAuth={!userId} pageTitle="اشتراكي" withoutNav={userId}>
      {UserType !== '5' ? userVerified ? (
        <>
          {!loadingData ? (
            <div className={`page-wrapper page-width ${searchParams.get('dark') === "true" ? 'dark' : ""}`} style={{ top: userId ? 0 : '70px' }}>
              <div className="row page-wrapper-without-top">
                <div className="col-12 col-lg-6 details-wrapper scrolled">
                  {data && <MySubscription user={user} onOK={getUserPlanFunc} data={data} NormalPlanCanceled={NormalPlanCanceled} setNormalPlanCanceled={setNormalPlanCanceled} userId={userId} />}
                </div>
                <div className={`col-12 d-none d-lg-flex  col-lg-6 bg-cover py-5 py-lg-0 position-relative`} style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
                  <div className={`img-container`}>
                    <img src={publicUrl + 'assets/img/others/others/subscription.png'} style={{ objectFit: 'contain' }} alt="whitelabel" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={`add-new-property-area pd-top-90 mg-bottom-100 text-center ${searchParams.get('dark') === "true" ? 'dark' : ""}`}>
              <CircularProgress />
            </div>
          )}
        </>
      ) : <Forbidden btnLink={"/verify"} btnText={t("AccountVerification")}
        desc={"حسابك غير موثق، يرجى توثيق الحساب لكي تتمكن من الوصول لهذه الصفحة"} />
        : null}
    </AppLayout>
  );
};

export default MySubscriptionPage;
