import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
// import avatar2 from "../../../assets/media/avatar/2.png";
// import avatar6 from "../../../assets/media/avatar/6.png";
// import photo1 from "../../../assets/media/shared-photos/01.jpg";
// import photo2 from "../../../assets/media/shared-photos/02.jpg";
// import photo3 from "../../../assets/media/shared-photos/03.jpg";

// import { ReactComponent as SearchSvg } from "../../../assets/media/icons/search.svg";
// import { ReactComponent as CallNowSvg } from "../../../assets/media/icons/callnow.svg";
// import { ReactComponent as VerticalOptionDots } from "../../../assets/media/icons/verticaloptiondots.svg";
// import { ReactComponent as InfoSvg } from "../../../assets/media/icons/infosvg.svg";
// import { ReactComponent as MuteNotificationsSvg } from "../../../assets/media/icons/mutenotifications.svg";
// import { ReactComponent as WallpaperSvg } from "../../../assets/media/icons/wallpaper.svg";
// import { ReactComponent as ArchiveSvg } from "../../../assets/media/icons/archive.svg";
// import { ReactComponent as DeleteSvg } from "../../../assets/media/icons/delete.svg";
// import { ReactComponent as BlockSvg } from "../../../assets/media/icons/block.svg";
// import { ReactComponent as ChatDocFileSvg } from "../../../assets/media/icons/chatdocmessage.svg";

// import { ReactComponent as GallarySvg } from "../../../assets/media/icons/gallary.svg";
// import { ReactComponent as AudioSvg } from "../../../assets/media/icons/audio.svg";
// import { ReactComponent as ContactSvg } from "../../../assets/media/icons/contact.svg";
// import { ReactComponent as LocationSvg } from "../../../assets/media/icons/location.svg";
// import { ReactComponent as PollSvg } from "../../../assets/media/icons/poll.svg";
// import { ReactComponent as SendMessageSvg } from "../../../assets/media/icons/sendmessage.svg";
// import { ReactComponent as AddContactSvg } from "../../../assets/media/icons/addcontact.svg";
// import { ReactComponent as HeartSvg } from "../../../assets/media/icons/heart.svg";
// import { ReactComponent as CloseSvg } from "../../../assets/media/icons/close.svg";

import { ReactComponent as DocumentsSvg } from "../../../assets/media/icons/documents.svg";
// import { ReactComponent as ProfileDocumentsSvg } from "../../../assets/media/icons/profiledocuments.svg";
// import DocumentOptionDropdown from "./Dropdowns/DocumentOptionDropdown";

import {
  getTicketDetails,
  addCommentToATicket,
  takeUserFeedback,
  addFileToATicket,
} from "../../../api/userApi";

import Picker, { SKIN_TONE_MEDIUM_LIGHT } from "emoji-picker-react";

import { ThemeColor } from "../../../Config/Config";
import { toast } from "react-toastify";
import { ArrowBack, Check, ThumbDownAlt, ThumbUpAlt } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
} from "@mui/material";
import { useAuth } from "../../../helpers/context";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ImageModal from "../../global-components/image-modal";
import { getDemoSnakbar, validateURL } from "../../../helpers";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

// User  chat detail component - chat with users

const UserCSChat = (props) => {
  const isAdmin = window.location.href.includes("admin");
  const channelID = props.id;
  const { token, UserId, vendor } = useAuth();
  const [Chats, setChats] = useState([]);
  const [Property, setProperty] = useState();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  // const [Violation, setViolation] = useState({});
  const [Feedback, setFeedback] = useState({
    isUserHappy: "-1",
    feedbackText: "",
  });
  // const [Status, setStatus] = useState("");
  const [Update, setUpdate] = useState(false);
  const [File, setFile] = useState();
  const inputFile = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openEmojiSelector = Boolean(anchorEl);
  const emojiSelectorID = openEmojiSelector ? "emoji-popper" : undefined;
  const [selectedAttachment, setSelectedAttachment] = React.useState(undefined);
  const [imageModalOpened, setImageModalOpened] = React.useState(false);
  const { t } = useTranslation();
  
  const handleClickEmojiSelector = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  function handlePDFChange(e) {
    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length === files.length) {
          // Apply Callback function
          setFile(allFiles[0]);
        }
      }; // reader.onload
    } // for
  }
  const onButtonClick = () => {
    inputFile.current.click();
  };

  useEffect(() => {
    if (File) {
      addFileToATicket({
        userId: token,
        ticketId: channelID,
        language: "0",
        base64Data: File.base64,
        pathWithFileName: "Tickets/" + channelID + "/" + File.name,
        typeOfFile: File.type === "application/pdf" ? "PDF" : "image",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) setUpdate(!Update);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [File]);
  const [UserFeedback, setUserFeedback] = useState({
    userId: token,
    ticketId: channelID,
    language: "0",
    isUserHappy: "1",
    userFeedbackText: "",
  });

  const [Receiver, setReceiver] = useState("");
  const [Options, setOptions] = useState({
    showProfileDetail: false,
    chatSearchBox: false,
    showUserInformation: true,
    showLastMedia: true,
    showDocuments: true,
    emojiWidth: window.innerWidth / 16 + "rem",
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    message: "",
  });
  // const [Property, setProperty] = useState();
  useEffect(() => {
    getTicketDetails({ ticketId: channelID, userId: token, language: "0" })
      .then((chat) => {
        setChats(chat.response.details);
        setProperty(chat.response.property);
        // setViolation(chat.response.violation);
        setFeedback(chat.response.feedback);
        setUserFeedback({
          ...UserFeedback,
          isUserHappy: chat.response.feedback.isUserHappy,
          feedbackText: chat.response.feedback.feedbackText,
        });
        setReceiver(chat.response.csName);
      })
      .catch((error) => {
        throw error;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserId, channelID, token, Update]);

  window.onresize = () => {
    let width = window.innerWidth / 16;
    if (width >= 50) width = 50;
    setOptions({ emojiWidth: width + "rem" });
  };

  function onSubmitSendFeedback() {
    takeUserFeedback(UserFeedback).then((_FeedBackInfo) => {
      if (_FeedBackInfo.resCode === 0) {
        toast.success(_FeedBackInfo.resStr);
        setUpdate(!Update);
      } else toast.error(_FeedBackInfo.resStr);
    });
  }

  function SendMessage() {
    addCommentToATicket({
      userId: token,
      ticketId: channelID,
      language: "0",
      text: Options.message,
    }).then((_response) => {
      setUpdate(!Update);
    });

    setOptions({ message: "" });
  }

  function timeConverter(unix_timestamp) {
    if (unix_timestamp)
      return new Intl.DateTimeFormat("ar-SA", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(unix_timestamp));
  }

  function getDateName(unix_timestamp) {
    if (unix_timestamp) {
      var date = new Date(unix_timestamp);
      var todaysDate = new Date();
      var Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
      if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
        return "اليوم";
      else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
        return "الأمس";
      else
        return new Intl.DateTimeFormat("ar-SY", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date(unix_timestamp));
    }
  }

  function isSameDate(unix_timestamp1, unix_timestamp2) {
    var date1 = new Date(parseFloat(unix_timestamp1));
    var date2 = new Date(parseFloat(unix_timestamp2));
    if (date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)) return true;
    else return false;
  }

  const handleMessageChange = (event) => {
    setOptions({ message: event.target.value });
  };

  const handleEmojiSelect = (event, emojiObject) => {
    setOptions({
      message: Options.message
        ? Options.message + emojiObject.emoji
        : emojiObject.emoji,
    });
  };

  const isActiveStep = (statusEl, statusId) => {
    return statusEl.status === "5"
      ? statusId === Chats.length - 1
        ? statusEl.isActive === "-1"
          ? true
          : false
        : true
      : statusEl.status === "0"
        ? true
        : statusEl.isActive === "0"
          ? false
          : true;
  };

  return (
    <main className={`main main-visible ${isAdmin ? "admin radius-16 border shadow" : ""}`}>
      <div className='chats'>
        <div className='chat-body'>
          <div className='chat-header'>
            <Link className='contacts-link' to={isAdmin ? '/admin/ticket' : '/Tickets'}>
                <button
                  className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted  ml-3'
                  type='button'>
                  <ArrowBack style={{ transform: "rotate(180deg)" }} />
                </button>
              </Link>
            

            <div className='media chat-name text-truncate'>
              <div
                className='media-body align-self-center '
                style={{ textAlignLast: "center" }}>
                <h6 className='text-truncate mb-0'>تفاصيل الشكوى</h6>
                <small className='text-muted'>أسندت لـ: {Receiver}</small>
              </div>
            </div>
          </div>

          {Property && Property.propertyId && (
            <div className=' media chat-name text-truncate Rectangle1427'>

              {vendor !== 'amakkn' ?
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://amakkn${window.location.origin.includes('amakkncompany.com') || process.env.REACT_APP_API_URL?.includes('devsa2') ?
                    '.org' : '.com'}/property-details/${Property.propertyId}`}>
                  <div className='media chat-name text-truncate'>
                    <div className='d-sm-inline-block mr-3 ml-3'>
                      <Avatar
                        src={
                          Property.photosNew
                            ? Property.photosNew.split(",")[0]
                            : process.env.REACT_APP_URL +
                            "/assets/img/defimgs/" +
                            Property.propertyType +
                            ".svg"
                        }
                        variant='rounded'
                        sx={{ width: 96, height: 96 }}
                        alt='property img'
                      />
                    </div>

                    <div className='media-body align-self-center '>
                      <h6
                        className='chat-name'
                        style={{ fontWeight: 600, fontSize: "20px" }}>
                        {Property.propertyTypeName +
                          " " +
                          (Property.listedFor === "2" ? t("ForSale") : t("ForRent"))}
                      </h6>
                      {/* <div className="contacts-texts"> */}
                      <h6
                        className='chat-time'
                        style={{ fontWeight: 400, fontSize: "18px" }}>
                        {Property.listedFor === "2"
                          ? Intl.NumberFormat("en").format(
                            Property.defaultPrice
                          ) + " ريال "
                          : Intl.NumberFormat("en").format(
                            Property.defaultPrice
                          ) +
                          " ريال / " +
                          Property.defaultPriceType.name}
                      </h6>
                      <h6
                        className='chat-time mt-3'
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#565768",
                        }}>
                        {Property.address?.length > 46
                          ? Property.address.substring(0, 45)
                          : Property.address}
                      </h6>
                    </div>
                  </div>
                </a> :
                <Link
                  className=''
                  to={"/property-details/" + Property.propertyId}>
                  <div className='media chat-name text-truncate'>
                    <div className='d-sm-inline-block mr-3 ml-3'>
                      <Avatar
                        src={
                          Property.photosNew
                            ? Property.photosNew.split(",")[0]
                            : process.env.REACT_APP_URL +
                            "/assets/img/defimgs/" +
                            Property.propertyType +
                            ".svg"
                        }
                        variant='rounded'
                        sx={{ width: 96, height: 96 }}
                        alt='property img'
                      />
                    </div>

                    <div className='media-body align-self-center '>
                      <h6
                        className='chat-name'
                        style={{ fontWeight: 600, fontSize: "20px" }}>
                        {Property.propertyTypeName +
                          " " +
                          (Property.listedFor === "2" ? t("ForSale") : t("ForRent"))}
                      </h6>
                      {/* <div className="contacts-texts"> */}
                      <h6
                        className='chat-time'
                        style={{ fontWeight: 400, fontSize: "18px" }}>
                        {Property.listedFor === "2"
                          ? Intl.NumberFormat("en").format(
                            Property.defaultPrice
                          ) + " ريال "
                          : Intl.NumberFormat("en").format(
                            Property.defaultPrice
                          ) +
                          " ريال / " +
                          Property.defaultPriceType.name}
                      </h6>
                      <h6
                        className='chat-time mt-3'
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#565768",
                        }}>
                        {Property.address?.length > 46
                          ? Property.address.substring(0, 45)
                          : Property.address}
                      </h6>
                    </div>
                  </div>
                </Link>}
            </div>
          )}

          <div className='chat-content p-2'>
            <div className='mx-2 mt-2'>
              {Chats && Chats.length > 0 ? (
                Chats.map((statusEl, statusId) => (
                  <div key={statusId}>
                    <div
                      className='text-right d-flex align-items-center'
                      dir='rtl'>
                      <div
                        style={{
                          width: isActiveStep(statusEl, statusId)
                            ? "24px"
                            : "10px",
                          height: isActiveStep(statusEl, statusId)
                            ? "24px"
                            : "10px",
                          borderRadius: "100%",
                          marginRight: isActiveStep(statusEl, statusId)
                            ? "-10px"
                            : "-4px",
                          background: isActiveStep(statusEl, statusId)
                            ? "var(--main-color-one)"
                            : "#999",
                          display: "inline-block",
                          marginTop: isActiveStep(statusEl, statusId) ? 10 : 30,
                          marginBottom: 10,
                        }}
                        className='text-right d-flex justify-content-center align-items-center'>
                        {isActiveStep(statusEl, statusId) && (
                          <Check sx={{ fontSize: 19, color: "white" }} />
                        )}
                      </div>
                      <span
                        style={{
                          marginRight: "24px",
                          marginTop: isActiveStep(statusEl, statusId) ? 0 : 16,
                          color: isActiveStep(statusEl, statusId)
                            ? "var(--main-color-one)"
                            : "#999",
                          fontSize: isActiveStep(statusEl, statusId)
                            ? "20px"
                            : "16px",
                        }}>
                        {statusEl.statusName} {statusEl.createdAt}
                      </span>
                    </div>
                    {statusEl.combined.length > 0 &&
                      statusEl.combined.map((element, idx) => (
                        <div
                          key={idx}
                          style={{
                            borderRight: "2px solid var(--main-color-one)",
                          }}>
                          {idx === 0 ? (
                            <div
                              className='message-divider pb-2'
                              data-label={getDateName(element.createdAt)}></div>
                          ) : isSameDate(
                            statusEl.combined[idx].createdAt,
                            statusEl.combined[idx - 1].createdAt
                          ) ? (
                            <></>
                          ) : (
                            <div
                              className='message-divider pb-2'
                              data-label={getDateName(element.createdAt)}></div>
                          )}
                          <div
                            style={{
                              marginRight:
                                element.userId === UserId ? "28px" : "0px",
                            }}
                            className={`message ${element.userId === UserId ? "self" : ""}`}>
                            <div className='message-wrapper'>
                              <div className='message-content'>
                                {element.type === "image" ? (
                                  <div className='form-row col'>
                                    <img
                                      alt='photo1'
                                      src={element.value}
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        setSelectedAttachment(element.value);
                                        setImageModalOpened(true);
                                      }}
                                    />
                                  </div>
                                ) : element.type.toLowerCase() === "pdf" ? (
                                  <a
                                    className='chat-info-group-header'
                                    target='_blank'
                                    href={validateURL(element.value) ? element.value : ''}
                                    rel='noreferrer'>
                                    <DocumentsSvg className='' />
                                    <span className='mb-0'> PDF ملف</span>
                                  </a>
                                ) : (
                                  <span>{element.value}</span>
                                )}
                              </div>
                            </div>
                            <div className='message-options'>
                              {idx === Chats.length - 1 ? (
                                <span className='message-date'>
                                  {timeConverter(element.createdAt)}
                                </span>
                              ) : (
                                <span className='message-date'>
                                  {timeConverter(element.createdAt)}
                                </span>
                              )}
                              {/* <MessageDropdown /> */}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))
              ) : (
                <div className='message-divider pb-2' data-label=''></div>
              )}
            </div>

            <div className='chat-finished' id='chat-finished'></div>
          </div>
          {Chats?.length > 0 && Chats[Chats.length - 1].isActive === "-1" && UserFeedback.isUserHappy==='-1' ? (
            <div className={`chat-footer ${isAdmin ? "admin" : ""}`}>
              <div className='container'>
                <div className='row justify-content-center' dir='rtl'>
                  <div className='col-10 col-lg-4'>
                    <div className='contact-form-wrap contact-form-bg'>
                      <h4 style={{ textAlign: "-webkit-center" }}>
                        هل أنت راضٍ عن الخدمة؟
                      </h4>

                      <div className='rld-single-input'>
                        <div
                          className='row justify-content-center'
                          style={{
                            fontSize: "x-large",gap:20,
                          }}
                          dir='rtl'>
                           <ThumbUpAlt
                            sx={{
                               cursor:'pointer',
                              color:
                                UserFeedback.isUserHappy === "1"
                                ? "var(--main-color-one)"
                                : "black",
                            }}
                            onClick={() => {
                              if (Feedback.isUserHappy === "-1")
                                setUserFeedback({
                                  ...UserFeedback,
                                  isUserHappy: "1",
                                });
                            }}
                            />
                          <ThumbDownAlt
                              onClick={() => {
                                if (Feedback.isUserHappy === "-1")
                                  setUserFeedback({
                                    ...UserFeedback,
                                    isUserHappy: "0",
                                  });
                              }}
                              sx={{
                                cursor:'pointer',
                                color:
                                  UserFeedback.isUserHappy === "0"
                                    ? "var(--main-color-one)"
                                    : "black",
                              }}
                            />
                        </div>
                      </div>
                      {Feedback.isUserHappy === "-1" ? (
                        <div>
                          <div className='rld-single-input'>
                            <textarea
                              rows={4}
                              placeholder='رأيك يهمنا'
                              defaultValue={""}
                              style={{ height: "75px",resize:'none' }}
                              value={UserFeedback.userFeedbackText}
                              onChange={(e) =>
                                setUserFeedback({
                                  ...UserFeedback,
                                  userFeedbackText: e.currentTarget.value,
                                })
                              }
                            />
                          </div>
                          <div className='btn-wrap text-center'>
                            <button
                              className='primary-btn mb-3 mx-auto px-4 py-2'
                              onClick={(e) => onSubmitSendFeedback()}>
                              {t("Send")}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className='rld-single-input'>
                          <h4>{Feedback.feedbackText}</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) :Chats?.length > 0 && Chats[Chats.length - 1].isActive === "-1" && UserFeedback.isUserHappy !=='-1' ? (<></>): (
            <div className='chat-footer'>
              <div className='form-row align-items-center'>
                <div className='col'>
                  <div className='input-group'>
                    <div className='input-group-prepend mr-sm-2 mr-1'>
                      <Link
                        to='#'
                        style={{ marginLeft: "5px" }}
                        onClick={onButtonClick}>
                        <IconButton>
                          <ControlPointIcon sx={{ fontSize: "32px" }} />
                        </IconButton>
                      </Link>
                      <input
                        type='file'
                        id='file'
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={handlePDFChange.bind(this)}
                        multiple={false}
                      />
                    </div>
                    <input
                      type='text'
                      className='form-control transparent-bg border-0 no-resize hide-scrollbar'
                      placeholder='أكتب رسالتك...'
                      rows='1'
                      value={Options.message}
                      onKeyDown={(event) => {
                        if (
                          event.code === "Enter" ||
                          event.code === "NumpadEnter"
                        ) {
                          if (DEMO_VENDORS.includes(vendor)) {
                            setOpenDemoSnackBar(true);
                          } else {
                            SendMessage()
                          }
                        }
                      }}
                      onChange={handleMessageChange}></input>

                    <div className='input-group-prepend mr-sm-2 mr-1'>
                      <IconButton onClick={handleClickEmojiSelector}>
                        <InsertEmoticonIcon sx={{ fontSize: "32px" }} />
                      </IconButton>
                      <Popper
                        id={emojiSelectorID}
                        open={openEmojiSelector}
                        anchorEl={anchorEl}>
                        <ClickAwayListener
                          onClickAway={() => setAnchorEl(null)}>
                          <Box>
                            <Picker
                              onEmojiClick={handleEmojiSelect}
                              skinTone={SKIN_TONE_MEDIUM_LIGHT}
                            />
                          </Box>
                        </ClickAwayListener>
                      </Popper>
                    </div>
                  </div>
                </div>
                <div className='col-auto'>
                  <div
                    className='btn btn-primary btn-icon rounded-circle text-light'
                    role='button'
                      onClick={() => {
                        if (DEMO_VENDORS.includes(vendor)) {
                          setOpenDemoSnackBar(true);
                        } else {
                          SendMessage()
                        }
                    }}>
                    <ChevronLeftIcon
                      sx={{ fontSize: "28px", fontWeight: 700 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ImageModal
        src={selectedAttachment}
        open={imageModalOpened}
        handleClose={() => setImageModalOpened(false)}
      />
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

    </main>
  );
};

export default UserCSChat;
