import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Alert, Autocomplete, FormHelperText, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import { Pen, Plus } from "../../../constants/icons";
import LoadingButton from "../../../components/global-components/loading-btn";
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { useEffect } from "react";
import { preventString } from "../../../helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const regionsOptions = REGIONS.map((region) => ({
  id: +region.REGION_ID,
  label: region.REGIONNAME_AR,
}));

const citiesOptions = CITIES.map((city) => ({
  id: +city.CITY_ID,
  label: city.CITYNAME_AR,
  regionId: +city.REGION_ID,
}));

const districtsOptions = DISTRICTS.map((district) => ({
  id: +district.DISTRICT_ID,
  label: district.DISTRICTNAME_AR,
  regionId: +district.REGION_ID,
  cityId: +district.CITY_ID,
}));

export default function EditInterestings(props) {
  const [open, setOpen] = React.useState(false);
  const [Model, setModel] = React.useState(props.isEdit ? props.model : {
    'id': '',
    "neighbourhoodIds": [],
    "priceFrom": '',
    "priceTo": '',
    "areaFrom": '',
    "areaTo": '',
    "interestingType": "",
    "propertyType": "",
    "area": "",
    "city": ""
  });
  const [errors, setErrors] = React.useState({});
  const { client } = props;
  const { t } = useTranslation();

  const handleClickOpen = () => {
    if (client && client.type === "")
      toast.error('الرجاء تحديد نوع العميل أولاً');
    else
      setOpen(true);
  };

  function formIsValid() {
    const _errors = {};
    if (Model?.interestingType === '') {
      _errors.interestingType = "الرجاء اختيار نوع الاهتمام";
    }
    if (Model?.propertyType === '') {
      _errors.propertyType = "الرجاء اختيار نوع العقار";
    }
    if (Model?.area === '') {
      _errors.area = "الرجاء اختيار المنطقة";
    }
    if (Model?.city === '') {
      _errors.city = "الرجاء اختيار المدينة";
    }
    if (Model?.neighbourhoodIds?.length === 0) {
      _errors.neighbourhoodIds = "الرجاء اختيار الأحياء";
    }
    if (Model?.priceTo !== "" && Model?.priceFrom !== "") {
      if (+Model?.priceFrom?.toString().replace(/[,]+/g, "") > +Model?.priceTo?.toString().replace(/[,]+/g, ""))
        _errors.price = "الرجاء التحقق من الأسعار";
    } else {
      _errors.price = "الرجاء تحديد الأسعار";
    }

    if (Model?.areaFrom !== "" && Model?.areaTo !== "") {
      if (+Model?.areaFrom?.toString().replace(/[,]+/g, "") > +Model?.areaTo?.toString().replace(/[,]+/g, ""))
        _errors.areas = "الرجاء التحقق من المساحة";
    } else {
      _errors.areas = "الرجاء تحديد المساحة";
    }

   
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function getFormatedPrice(price) {
    let value = price?.toString()?.replace(/[,]+/g, "");
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  useEffect(() => {
    if (open) {
      setModel(props.isEdit ? props.model : {
        'id': '',
        "neighbourhoodIds": [],
        "priceFrom": '',
        "priceTo": '',
        "areaFrom": '',
        "areaTo": '',
        "interestingType": "",
        "propertyType": "",
        "area": "",
        "city": ""
      })
    }
  },// eslint-disable-next-line
    [open]);
  
  const handleClose = () => {
    setOpen(false);
    setModel({
      'id': '',
      "neighbourhoodIds": [],
      "priceFrom": '',
      "priceTo": '',
      "areaFrom": '',
      "areaTo": '',
      "interestingType": "",
      "propertyType": "",
      "area": "",
      "city": ""
    });
    setErrors({});
  };

  const handleSave = () => {
    if (!formIsValid()) return;
    props.setClient({
      ...props.client,
      interestingList: props.isEdit ?
        [...props.client.interestingList?.filter(i => +i.id !== +Model.id), {
          ...Model,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }]
        :
        [...props.client.interestingList, {
          ...Model,
          id: Math.floor(Math.random() * 10000) + props.client.interestingList?.length,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }]
    });
      props.onOK?.( props.isEdit ?
        [...props.client.interestingList?.filter(i => +i.id !== +Model.id), {
          ...Model,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }]
        :
        [...props.client.interestingList, {
          ...Model,
          id: Math.floor(Math.random() * 10000) + props.client.interestingList?.length,
          priceFrom: Model?.priceFrom?.toString().replace(/[,]+/g, ""),
          priceTo: Model?.priceTo?.toString().replace(/[,]+/g, ""),
          areaFrom: Model?.areaFrom?.toString().replace(/[,]+/g, ""),
          areaTo: Model?.areaTo?.toString().replace(/[,]+/g, "")
        }],true);
      handleClose();

  };



  return (
    <React.Fragment>
      <button
        className={`custom-btn ${props.isEdit ? 'custom-btn2' : ""}`}
        onClick={handleClickOpen}>
        {props.isEdit ? <Pen /> : <><Plus /><span>اضافة اهتمام</span></>}
      </button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        className="custom-dialog model"
        style={{ direction: "rtl", textAlignLast: "right" }}
        onClose={handleClose}>
        <DialogTitle>
          <div className="dialog-head close-only">
            <div>
              <h2>{props.isEdit ? "تعديل الاهتمام" : "اضف اهتمام"}</h2>
              {/* <p>{props.isEdit ? "يمكنك تعديل تفاصيل الاهتمام" : "قم بادخال تفاصيل الاهتمام"}</p> */}
            </div>
            <div className="dialog-actions">
              <LoadingButton label={t("Save")} classes="px-3 primary-btn" loading={false} handleClick={handleSave} />
              <button className="px-3 outlined-btn" onClick={handleClose}>
                {t("Cancel")}
              </button>
            </div>
          </div>
          
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component='form'
          >
            <div className="row mx-0 mt-4 w-100 flex-column mb-5">
              <div className="col-12 mb-4">
                <FormControl className="custom-select" error={Boolean(errors.interestingType)}>
                  <InputLabel htmlFor='interestingType' required>نوع الاهتمام</InputLabel>
                  <Select
                    autoFocus
                    value={Model.interestingType}
                    sx={{ width: "100%" }}
                    onChange={(event) => {
                      setModel({
                        ...Model,
                        interestingType: event.target.value,
                        neighbourhoodIds: [],
                        neighbourhood: [],
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("TypeOfInterest")}
                      id="interestingType"
                    />}
                    label={t("TypeOfInterest")}
                    inputProps={{
                      name: "interestingType",
                      id: "interestingType",
                    }}>
                    {client?.type === 'مالك' || client?.type === 'وسيط' ? <MenuItem value='بيع'>بيع</MenuItem> : null}
                    {client?.type === 'مالك' || client?.type === 'وسيط' ? <MenuItem value='تأجير'>تأجير</MenuItem> : null}
                    {client?.type === 'باحث' || client?.type === 'وسيط' ? <MenuItem value='شراء'>شراء</MenuItem> : null}
                    {client?.type === 'باحث' || client?.type === 'وسيط' ? <MenuItem value='استئجار'>استئجار</MenuItem> : null}
                  </Select>
                </FormControl>
                {errors.interestingType && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.interestingType}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                <FormControl className="custom-select" error={Boolean(errors.propertyType)}>
                  <InputLabel htmlFor='propertyType' required>{t("PropertyType")}</InputLabel>
                  <Select
                    value={Model.propertyType}
                    sx={{ width: "100%" }}

                    onChange={(event) => {
                      setModel({
                        ...Model,
                        propertyType: event.target.value,
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("PropertyType")}
                      id="propertyType"
                    />}
                    label={t("PropertyType")}
                    inputProps={{
                      name: "propertyType",
                      id: "propertyType",
                    }}>
                    <MenuItem value='أرض'>أرض</MenuItem>
                    <MenuItem value='دور'>دور</MenuItem>
                    <MenuItem value='شقة'>شقة</MenuItem>
                    <MenuItem value='فيلا'>فيلا</MenuItem>
                    <MenuItem value='استوديو'>استوديو</MenuItem>
                    <MenuItem value='غرفة'>غرفة</MenuItem>
                    <MenuItem value='استراحة'>استراحة</MenuItem>
                    <MenuItem value='معرض'>معرض</MenuItem>
                    <MenuItem value='مكتب'>مكتب</MenuItem>
                    <MenuItem value='مستودع'>مستودع</MenuItem>
                    <MenuItem value='مزرعة'>مزرعة</MenuItem>
                    <MenuItem value='عمارة'>عمارة</MenuItem>

                  </Select>
                </FormControl>
                {errors.propertyType && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.propertyType}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                {!Model.interestingType || Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? (
                  <TextField
                    label={t("Area")}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                      ),
                    }}
                    required
                    error={Boolean(errors.areas)}
                    value={getFormatedPrice((Model.areaFrom))}
                    size='small'
                    onChange={(v) => {
                      setModel({
                        ...Model,
                        areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                      });
                    }}
                  />
                ) :
                  (<>
                    <TextField
                      label={t("AreaFrom")}
                      sx={{ width: "50%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={Boolean(errors.areas)}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                        ),
                      }}
                      value={getFormatedPrice((Model.areaFrom))}
                      size='small'
                      onChange={(v) => {
                        setModel({
                          ...Model,
                          areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        });
                      }}
                    />
                    <TextField
                      label={t("AreaTo")}
                      sx={{ width: "50%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={Boolean(errors.areas)}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                        ),
                      }}
                      value={getFormatedPrice(Model.areaTo)}
                      size='small'
                      onChange={(v) => {
                        setModel({
                          ...Model,
                          areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                        });
                      }}
                    />
                  </>)}
                {errors.areas && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.areas}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
              <div className="col-12 mb-4">
                <TextField
                  label='السعر (من)'
                  sx={{ width: "50%" }}
                  required
                  error={Boolean(errors.price)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>ريال</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice((Model.priceFrom))}
                  size='small'
                  onChange={(v) => {
                    setModel({
                      ...Model,
                      priceFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                    });
                  }}
                />
                <TextField
                  label='السعر (إلى)'
                  required
                  error={Boolean(errors.price)}
                  sx={{ width: "50%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>ريال</InputAdornment>
                    ),
                  }}
                  value={getFormatedPrice(Model.priceTo)}
                  size='small'
                  onChange={(v) => {
                    setModel({
                      ...Model,
                      priceTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                    });
                  }}
                />
                {errors.price && (
                  <FormHelperText component={Box}>
                    <Alert severity='error' className='custom-alert'>
                      {errors.price}
                    </Alert>
                  </FormHelperText>
                )}
              </div>
             
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.area)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    required
                    slotProps={{
                      popper: {
                        sx: {
                          direction:'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    options={regionsOptions}
                    value={
                      regionsOptions.filter(
                        (i) => +i.id === +Model?.area
                      )?.[0] || null
                    }
                    sx={{ width: "100%" }}
                    onChange={(v, newValue) => {
                      setModel({
                        ...Model,
                        area: newValue?.id || "",
                        neighbourhoodIds: [],
                        neighbourhood: [],
                        city: ''
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.area)}
                        variant='outlined'
                        label={t("Region")}
                      />
                    )}
                  />
                  {errors.area && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.area}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.city)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    slotProps={{
                      popper: {
                        sx: {
                          direction:'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    required
                    disabled={!Model?.area}
                    options={citiesOptions.filter(
                      (i) => +i.regionId === +Model?.area
                    )}
                    value={
                      citiesOptions.filter(
                        (i) => +i.id === +Model?.city
                      )?.[0] || null
                    }
                    sx={{ width: "100%" }}
                    noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                    onChange={(event, newValue) => {
                      setModel({
                        ...Model,
                        city: newValue?.id || "",
                        neighbourhoodIds: [],
                        neighbourhood: []
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.city)}
                        variant='outlined'
                        label={t("City")}
                      />
                    )}
                  />
                  {errors.city && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.city}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-12 mb-4">
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.neighbourhoodIds)}>
                  <Autocomplete
                    // disablePortal
                    size='small'
                    slotProps={{
                      popper: {
                        sx: {
                          direction:'rtl',
                          zIndex: 9999999999999
                        }
                      }
                    }}
                    required
                    multiple={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? false : true}
                    disabled={!Model?.city}
                    options={districtsOptions.filter(
                      (i) =>
                        +i.cityId === +Model?.city &&
                        +i.regionId === +Model?.area
                    )}
                    value={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ?
                      (districtsOptions.filter(
                        (i) => Model?.neighbourhoodIds?.includes(+i.id) || Model?.neighbourhood?.includes(+i.id)
                      )?.[0] || undefined) :
                      (districtsOptions.filter(
                        (i) => Model?.neighbourhoodIds?.includes(+i.id) || Model?.neighbourhood?.includes(+i.id)
                      ) || [])
                    }
                    sx={{ width: "100%" }}
                    noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                    onChange={(event, newValue) => {
                      const ids = Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? (newValue ? [newValue?.id] : []) : newValue?.map(i => i.id);
                      setModel({
                        ...Model,
                        neighbourhoodIds: ids,
                        neighbourhood: ids
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%" }}
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(errors.neighbourhoodIds)}
                        variant='outlined'
                        label={Model.interestingType === "بيع" || Model.interestingType === "تأجير" ? "الحي" : 'الأحياء'}
                      />
                    )}
                  />
                  {errors.neighbourhoodIds && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.neighbourhoodIds}
                      </Alert>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
