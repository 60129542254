import React, { useState, useEffect} from "react";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useSearchParams } from "react-router-dom";
import { Alert, Box, ClickAwayListener, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grow, Paper, Popper, Radio, RadioGroup, TextField } from "@mui/material";
import { useAuth } from "../../helpers/context";
import LoadingButton from "./loading-btn";
import { createATicket, getTicketListSubjects } from "../../api/userApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ContactusPopover = () => {
    const { token } = useAuth();
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [step, setStep] = useState(1);
    const [TicketSubject, setTicketSubject] = useState([]);
    const [NewTicket, setNewTicket] = useState({
        userId: token,
        subjectId: "",
        createdBy: token,
        language: "0",
        screenshots: "",
        typeOfFile: "",
        notes: "",
        propertyId: '',
    });
    const [searchParams] = useSearchParams();
    const [isSubmittingTicket, setIsSubmittingTicket] = useState(false);
    const [errors, setErrors] = useState(false);
    const { t } = useTranslation();

    function handleSubjectChanges(e) {
        setNewTicket({
            ...NewTicket,
            subjectId: e.target.value,
        });
    }

    useEffect(() => {
        getTicketListSubjects({
            language: "0",
            isProperty: "0",
        }).then((_TicketSubject) =>
            setTicketSubject(_TicketSubject.response.ticketSubjectArray)
        );
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    let options = TicketSubject.map((item) => ({
        value: item.subjectId,
        label: item.subject,
    }));
    
    function onSubmitNewTicket() {
        if (NewTicket.subjectId === "" || NewTicket.notes === "") {
            setErrors(true);
        } else {
            setIsSubmittingTicket(true);
            createATicket(NewTicket).then((_FeedBackInfo) => {
                if (_FeedBackInfo.resCode === 0) {
                    toast.success(t("SentSuccessfully"));
                    handleClose();
                } else toast.error(_FeedBackInfo.resStr);
            }).finally(() => setIsSubmittingTicket(false));
        }
    }
    
    const handleClose = () => {
        setStep(1);
        setOpenPopper(false);
        setAnchorEl(null);
        setNewTicket({
            userId: token,
            subjectId: "",
            createdBy: token,
            language: "0",
            screenshots: "",
            typeOfFile: "",
            notes: "",
            propertyId: '',
        });
        setErrors(false);
    }

    return (
        <>
            <Popper
                className={`popover ${searchParams.get('dark') === "true" ? 'dark' : ''}`}
                sx={{ zIndex: 999, offset: '5px 5px 15px' }}
                open={openPopper}
                role={undefined}
                transition
                placement="top-start"
                anchorEl={anchorEl}
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'right top' : 'right top',
                        }}
                    >
                        <Paper sx={{
                            minWidth: '300px',
                            background: searchParams.get('dark') === "true" ? '#2e2e2e' : '#f6f6f6',
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                        }}>
                            <ClickAwayListener onClickAway={() => handleClose()}>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', padding: '15px 25px' }}>
                                    <h4 style={{ fontSize: '24px' }}>تواصل مع الدعم الفنّي</h4>
                                    {step === 1 ? (
                                        <FormControl className="mb-2 mt-4">
                                            <FormLabel
                                                dir="rtl"
                                                className="text-right"
                                                style={{ color: searchParams.get('dark') === "true" ? '#fff' : '#000' }}
                                            >
                                                اختر الموضوع
                                            </FormLabel>
                                            <RadioGroup
                                                sx={{
                                                    '& label': {
                                                        padding: '0 !important',
                                                        margin: '0 !important'
                                                    },
                                                    fontSize: '14px'
                                                }}
                                                style={{ color: searchParams.get('dark') === "true" ? '#fff' : '#000' }}
                                                value={NewTicket.subjectId}
                                                onChange={(e) => {
                                                    handleSubjectChanges(e);
                                                }}
                                                name="radio-buttons-group"
                                            >
                                                {options.map(opt => <FormControlLabel sx={{
                                                    '& .MuiTypography-root': { fontSize: '13px' }
                                                }} value={opt.value} key={opt.value} control={<Radio />} label={opt.label} />)}
                                            </RadioGroup>
                                            <FormHelperText dir="rtl">
                                                {errors && NewTicket.subjectId === '' ?
                                                    <Alert severity="error" className="custom-alert" style={{ width: 'fit-content' }}>
                                                       {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                  
                                    ) : (
                                        <FormControl className="mb-2 mt-4">

                                            <TextField
                                                label={t("Message")}
                                                required
                                                sx={{
                                                    width: "100%", direction: 'rtl',
                                                    background: searchParams.get('dark') === "true" ? '#000' : '#fff'
                                                }}
                                                className="text-right"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder='يرجى كتابة نص الشكوى/الاقتراح بالتفصيل'
                                                value={NewTicket.notes}
                                                size='small'
                                                multiline
                                                rows={6}
                                                onChange={(e) =>
                                                    setNewTicket({
                                                        ...NewTicket,
                                                        notes: e.currentTarget.value,
                                                    })
                                                }
                                            />
                                            <FormHelperText dir="rtl">
                                                {errors && !NewTicket.notes ?
                                                    <Alert severity="error" style={{ width: 'fit-content' }} className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                  
                      
                                    <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                        <LoadingButton
                                            classes='btn outlined-btn px-3 mb-3 w-100 d-flex justify-content-center'
                                            loading={false}
                                            handleClick={() => handleClose()}
                                            label={t("Cancel")}
                                        />
                                        <LoadingButton
                                            classes='btn primary-btn px-3 mb-3 w-100 d-flex justify-content-center'
                                            loading={isSubmittingTicket}
                                            handleClick={(e) => {
                                                if (step === 1) {
                                                    if (NewTicket.subjectId === "") {
                                                        setErrors(true);
                                                    } else {
                                                        setErrors(false);
                                                        setStep(2);
                                                    }
                                                } else
                                                    onSubmitNewTicket();
                                            }}
                                            label={step === 1 ? t("Continue") : t("Send")}
                                        />
                                    </div>
                                
                          
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Fab size="large" color="primary" onClick={(e) => {
                setOpenPopper(!openPopper);
                setAnchorEl(anchorEl ? null : e.target);
            }}
                style={{
                    position: 'sticky',
                    bottom: '30px',
                    left: '40px',
                    borderRadius: '100%',
                    marginBottom: '50px'
                }}>
                <SupportAgentIcon sx={{ fontSize: 30 }} />
            </Fab>
        </>
    );
}

export default ContactusPopover;
