import React, { useState, useEffect } from "react";
import Navbar from "./global-components/navbar";
import { getPropertiesOfOwnerNew } from "../api/propertyApi";
import BrokerProfile from "./section-components/user-profile";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../helpers/context";
import AmakknNavbar from "./global-components/amakkn-navbar";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const { token, vendor } = useAuth();
  let userId;
  let { id } = useParams();
  let location = useLocation();
  const { t } = useTranslation();

  if (id) userId = id;
  else if (location.state.userId) userId = location.state.userId;
  const [user] = useState({
    userId: userId,
    language: "0",
  });

  const [userInformation, setuserInformation] = useState();

  const [msg, setMsg] = useState("wait");

  // function isNumeric(str) {
  //   if (typeof str != "string") return false; // we only process strings!
  //   return (
  //     !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  //     !isNaN(parseFloat(str))
  //   ); // ...and ensure strings of whitespace fail
  // }
  useEffect(() => {
    if (token)
      getPropertiesOfOwnerNew({
        ownerId: user.userId,
        page: "1",
        sortBy: "updatedAt",
        sortOrder: "desc",
        pageSize: "99999",
        userId: token,
        language: user.language,
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setuserInformation(_userInfo.response);
          setMsg("Done");
        } else {
          toast.error(t("UserNotFound"));
          setMsg(t("UserNotFound"));
        }
      });
  },// eslint-disable-next-line
    [token, user]);

  return token ? (
    <div style={{ flex: 1 }}>
      {msg === "Done" ? (
        <BrokerProfile
          user={userInformation.hostInfo}
          properties={userInformation.propertyArray}
          totalCount={userInformation.totalCount}
          token={token}
        />
      ) : (
        <>
          {vendor === 'amakkn' ? <AmakknNavbar /> : <Navbar />}
          <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
            {msg === 'wait' ? <CircularProgress /> : <h4>{msg}</h4>}

          </div>
        </>
      )}
    </div>
  ) : (
    <>
      {vendor === 'amakkn' ? <AmakknNavbar /> : <Navbar />}
      <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
          <h4>{t("PleaseLogInToBeAbleToViewTheAdvertiserProfile")}</h4>
      </div>
    </>
  );
};

export default UserProfile;
