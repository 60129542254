import React from "react";
import { SuccessVerification } from "../../../constants/backgrounds";
import AppLayout from "../../layouts/app-layout";
import { useTranslation } from "react-i18next";

let publicUrl = process.env.REACT_APP_URL + "/";
const Success = () => {

  const { t } = useTranslation();

  return (
    <AppLayout pageTitle={t("AccountVerification")}>
      <div className="page-wrapper page-width" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
        <div className="row page-wrapper-without-top">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <h4 className='page-heading' style={{color:'rgba(0,0,0,0.8)'}}>تم توثيق الحساب بنجاح</h4>
              {/* <p className='my-3' style={{ fontSize: 16, fontWeight: 600 }}>
                تم توثيق الحساب بنجاح
              </p> */}
              <div className="my-4">
                <SuccessVerification />
              </div>
              <a
                href='/'
                className='primary-btn py-2 px-4 mt-3'
                style={{ width: "fit-content" }}>
                {t("GoToHomePage")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Success;
