import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Box, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { useTranslation } from "react-i18next";

function CompanyCRVerficationForm(props) {

  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const { t } = useTranslation();
  
  function yearOptions() {
    var arr = [];

    for (let i = 1445; i <= 1460; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  }

  function dayOptions() {
    var arr = [];

    for (let i = 1; i <= 30; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  }

  return (
    <div style={{ marginTop: -20 }}>
      <div className='col-12 p-0'>
        <Alert severity="info" dir="rtl" className="text-right">
          <AlertTitle>تنويه</AlertTitle>
          يتم توثيق حساب الأعمال عن طريق التآكد من السجل التجاري للشركة/المؤسسة. <br />
          في حال كنت مسوق ولا تملك سجل تجاري قم بتغير نوع الحساب لحساب أفراد في <Link to='/my-profile' style={{ textDecoration: 'underline', color: 'var(--main-color-one)' }}>صفحتي الشخصية</Link>.</Alert>
        <h5 className='my-4' dir="rtl">
          ادخل رقم وتاريخ انتهاء السجل التجاري  (هجري) 
          <IconButton
                  color='primary'
                  onClick={() => setOpenInfoPopup(true)}
                 >
                  <InfoIcon />
                </IconButton>
        </h5>

        <form>
          <div className='row m-0'>
            <div className='col-12 mb-4 p-0' dir="ltr">
              <FormControl
                error={props.errors.commercialRecordNumber && Boolean(props.errors.commercialRecordNumber)}
                className="col-12" dir="rtl"
              >
                <TextField
                  id='commercialRecordNumber'
                  label='رقم السجل التجاري'
                  onChange={props.onChange}
                  name='commercialRecordNumber'
                  value={props.user.commercialRecordNumber}
                  sx={{ width: '100%' }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={props.errors.commercialRecordNumber && Boolean(props.errors.commercialRecordNumber)}
                />
                {props.errors.commercialRecordNumber && Boolean(props.errors.commercialRecordNumber) &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {props.errors.commercialRecordNumber}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>
            <div className='col-12 p-0'>
              <div className='row m-0 p-0'>
               
                <div className='col col-lg-3 pr-0'>
                  <select
                    id='day'
                    name='day'
                    onChange={props.onDateChange}
                    value={props.date.day || ''}
                    className='form-control'
                    style={{ direction: "rtl" }}>
                    <option value="" disabled >اختر اليوم</option>
                    {dayOptions()}
                  </select>
                  {props.errors.day && Boolean(props.errors.day) &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {props.errors.day}
                      </Alert>
                    </FormHelperText>}
                </div>

                <div className='col col-lg-5'>
                  <select
                    id='month'
                    name='month'
                    onChange={props.onDateChange}
                    value={props.date.month || ""}
                    className='form-control'
                    style={{ direction: "rtl" }}>
                    <option value="" disabled >اختر الشهر</option>
                    <option value='1'>1.مُحرَّم</option>
                    <option value='2'>2.صفَر</option>
                    <option value='3'>3.ربيع الأول</option>
                    <option value='4'>4.ربيع الآخر</option>
                    <option value='5'>5.جمادي الأول</option>
                    <option value='6'>6.جمادي الآخر</option>
                    <option value='7'>7.رَجب</option>
                    <option value='8'>8.شَعبان</option>
                    <option value='9'>9.رَمضان</option>
                    <option value='10'>10.شوّال</option>
                    <option value='11'>11.ذو القِعدة</option>
                    <option value='12'>12.ذو الحِجّة</option>
                  </select>
                  {props.errors.month && Boolean(props.errors.month) &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {props.errors.month}
                      </Alert>
                    </FormHelperText>}
                </div>

                <div className='col col-lg-4 pl-0'>
                  <select
                    id='year'
                    name='year'
                    onChange={props.onDateChange}
                    value={props.date.year || ''}
                    className='form-control'
                    style={{ direction: "rtl" }}>
                    <option value="" disabled >اختر السنة</option>
                    {yearOptions()}
                  </select>
                  {props.errors.year && Boolean(props.errors.year) &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {props.errors.year}
                      </Alert>
                    </FormHelperText>}
                </div>
              
              </div>
            </div>
            <div className='col-12 p-0'>
              <LoadingButton
                classes="primary-btn py-2 px-4 my-3 d-flex justify-content-center"
                label={t("Next")}
                loading={props.disable}
                handleClick={props.onSubmit}
              />
            </div>
          </div>
        </form>

        <Dialog
        onClose={() => {
          setOpenInfoPopup(false);
        }}
        maxWidth={"md"}
        className='custom-dialog'
        style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openInfoPopup}>
        <DialogTitle>
          <div className='dialog-head mb-4'>
            <div className='dialog-actions close-only'>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setOpenInfoPopup(false);
                }}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <img src="/assets/img/cr.png" alt="CR" />
        </DialogContent>
        </Dialog>
        
      </div>
    </div>
  );
}

CompanyCRVerficationForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default CompanyCRVerficationForm;
