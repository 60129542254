import React, {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import { getUserProile } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import { toast } from "react-toastify";
import { ListArrow } from "../../constants/icons";
import { openDeleteModal } from "../../helpers";
import { Box } from "@mui/material";

const DeleteUsageInfo = () => {
  const { token, setToken, setUserType, setUserId, setAccountType } = useAuth();
  let { userId } = useParams();
  


  const getUserData = (id) => {
    getUserProile({
      userId: id ? id : token,
      language: "0",
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        if (id) {
          setToken(id);
          setAccountType(_userInfo.response.userProfile?.accountType);
          setUserId(_userInfo.response.userProfile?.userId);
          setUserType(_userInfo.response.userProfile?.userType);
        }
      } else {
        toast.error(_userInfo.resStr);
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
      } else {
        getUserData();
      }
    }, // eslint-disable-next-line
    [userId]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AppLayout needAuth={!userId} pageTitle="حذف حساب أماكن" withoutNav={userId}>
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        <Box
          sx={{
            marginBottom: "40px",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            color: "#000",
          }}>
          حذف معلومات الإستخدام
        </Box>
        <div
          className={`row align-items-center justify-content-end`}
          dir="rtl"
          style={{ marginTop: '-50px !important' }}
        >
          <div className="col-12 p-0 text-right mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>ضوابط عامة</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                
                  <li><ListArrow />
                    ستيم حذف جميع الإعلانات العقاريّة المنشورة من قبلك
                  </li>
                  <li><ListArrow />
                    ستيم حذف جميع تفضيلات الإعلانات العقاريّة التي تمّت من قبلك
                  </li>
                  <li><ListArrow />
                    ستيم حذف جميع عمليات البحث التي تمّت من قبلك
                  </li>
              
                </ul>
           
                <button
                  className="primary-btn delete-btn d-flex  justify-content-center mt-4 px-5 mr-2"
                  onClick={() => {
                 
                    openDeleteModal("حذف معلومات الاستخدام", "هل أنت متأكد من حذف معلومات الاستخدام الخاصّة بك؟ لا يمكنك التراجع عن هذه العملية", () => {
                      //call ep
                      // log out
                      // clear cookies
                      //redirect to home page
                    });
                  
                  }}>
                  حذف معلومات الاستخدام
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );

};

export default DeleteUsageInfo;
