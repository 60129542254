import { Paper, Table, TableContainer,TableRow,TableCell, TableHead, TableBody, TableFooter } from "@mui/material";
import React from "react";
import { GreenCheck, RedClose } from "../constants/icons";
import { plansFeatures } from "../constants";

const PlansTable = ({ data }) => {
    const features = [
        "موقع إلكتروني (example.amakkn.com)",
        "موقع إلكتروني بنطاق مستقل (example.com)",
        "خريطة الإعلانات العقاريّة",
        "المشاريع والاهتمامات",
        "نظام إدارة العملاء",
        "المدونة",
        "ظهور حساب تويتر",
        "مستخدمين فرعيين",
        "3 قوالب"
    ];
    
    return (
        <TableContainer component={Paper} dir="rtl">
            <Table sx={{ minWidth: '100%' }} >
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                            "& th": {
                                fontSize: "17px",
                                fontWeight: 600,
                                textAlign: 'center'
                            }
                        }}
                    >
                        <TableCell sx={{ minWidth: 160 }}>الميزات</TableCell>
                        {data?.map(p => ['5', '9', '11'].includes(p.id) && <TableCell sx={{ minWidth: 180 }} key={p.name}>{p.name}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            عدد الإعلانات العقاريّة المنشورة المسموح إضافتها
                        </TableCell>
                        <TableCell>{data[4]?.allowedPublishedProperties}</TableCell>
                        <TableCell>{data[8]?.allowedPublishedProperties}</TableCell>
                        <TableCell>{data[10]?.allowedPublishedProperties}</TableCell>
                    </TableRow>

                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            عدد الحسابات الفرعيّة المسموح إضافتها
                        </TableCell>
                        <TableCell>{data[4]?.allowedAgents}</TableCell>
                        <TableCell>{data[8]?.allowedAgents}</TableCell>
                        <TableCell>{data[10]?.allowedAgents}</TableCell>
                    </TableRow>

                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            عدد الإعلانات العقاريّة المسموح ترقيتها
                        </TableCell>
                        <TableCell>{data[4]?.permanentUpgradeToNumberOfAds}</TableCell>
                        <TableCell>{data[8]?.permanentUpgradeToNumberOfAds}</TableCell>
                        <TableCell>{data[10]?.permanentUpgradeToNumberOfAds}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            اولويّة دعم فنّي
                        </TableCell>
                        <TableCell>{data[4]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            إمكانيّة إضافة شعار المكتب على صور الإعلانات
                        </TableCell>
                        <TableCell>{data[4]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            إمكانيّة إضافة رقم الاتصال الموحد 9200 أو 8200
                        </TableCell>
                        <TableCell>{data[4]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            إمكانيّة إضافة الموقع على الخارطة في صفحة الشركة
                        </TableCell>
                        <TableCell>{data[4]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            إمكانية إضافة فيديوهات للإعلان العقاري
                        </TableCell>
                        <TableCell>{data[4]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            إمكانية إضافة إعلانات إضافية مقابل 20 ريال للإعلان
                        </TableCell>
                        <TableCell>{data[4]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                    
                    <TableRow
                        sx={{
                            '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell component="th" scope="row">
                            صورة قابلة للطباعة والمشاركة لتفاصيل الإعلان العقاري
                        </TableCell>
                        <TableCell>{data[4]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[8]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                        <TableCell>{data[10]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</TableCell>
                    </TableRow>
                   
                    {features?.map((row, i) => {
                        return (
                            <TableRow
                                key={i}
                                sx={{
                                    '& td, & th': { borderBottom: 0, textAlign: 'center', borderLeft: "1px solid rgba(0, 0, 0, 0.12)" },
                                    '& td:last-child, & th:last-child': { borderLeft: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {i === 8 ? 'عدد القوالب' : row}
                                </TableCell>
                                <TableCell><RedClose /></TableCell>
                                <TableCell>{i === 8 ? 3 : plansFeatures[7]?.[i].available === "1" ? <GreenCheck green /> : <RedClose />}</TableCell>
                                <TableCell>{i === 8 ? 10 : plansFeatures[9]?.[i].available === "1" ? <GreenCheck green /> : <RedClose />}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow
                        sx={{
                            '& td, & th': {
                                paddingTop: '20px',
                                paddingBottom: '35px',
                                borderBottom: 0,
                                textAlign: 'center',
                                borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
                            },
                            '& td:last-child, & th:last-child': { borderLeft: 0 }
                        }}
                    >
                        <TableCell></TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default PlansTable;
