import { Dialog, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import { getVideoId } from '../helpers'
import { useAuth } from '../helpers/context';
import { CLASSIC_TEMPLATES } from '../constants';
import { useTranslation } from 'react-i18next';

export default function YoutubeDialog({ position, youtubeLink, wl }) {
    const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);
    const { templateId } = useAuth();
    const { t } = useTranslation();

    return (
        <>
            {CLASSIC_TEMPLATES.includes(+templateId) || wl ?
                <div className='d-flex align-items-center cursor-pointer' style={{ gap: '6px', color:'var(--paragraph-color)' }} onClick={() => setOpenYoutubeDialog(true)}>
                    <div className='youtube-icon'>
                        <svg width="31" height="32" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.63861 10.764V6.70324C1.63861 1.66145 5.20893 -0.403178 9.57772 2.11772L13.1024 4.14812L16.6271 6.17853C20.9959 8.69942 20.9959 12.8287 16.6271 15.3496L13.1024 17.38L9.57772 19.4104C5.20893 21.9313 1.63861 19.8666 1.63861 14.8249V10.764Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                    {t("WatchTheVideo")}
                </div> :
                <div className={
                    {
                        0: "youtube-icon text-align-last-left mb-3",
                        1: "youtube-icon text-align-last-center mx-auto mb-3",
                        2: "youtube-icon text-align-last-right mb-3",
                    }[position]
                }
                    onClick={() => setOpenYoutubeDialog(true)}>
                    <svg width="31" height="32" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.63861 10.764V6.70324C1.63861 1.66145 5.20893 -0.403178 9.57772 2.11772L13.1024 4.14812L16.6271 6.17853C20.9959 8.69942 20.9959 12.8287 16.6271 15.3496L13.1024 17.38L9.57772 19.4104C5.20893 21.9313 1.63861 19.8666 1.63861 14.8249V10.764Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </div>
            }
          
            <Dialog
                onClose={() => setOpenYoutubeDialog(false)}
                maxWidth={"lg"}
                style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
                sx={{
                    "& .MuiPaper-root": {
                        margin: 0,
                        padding: 0,
                        zIndex: 9999999,
                        width: 'calc(100vw - 10%)',
                        borderRadius: '12px',
                        border: 0
                    },
                }}
                open={openYoutubeDialog}>
      
                <DialogContent sx={{ padding: 0, background: 'var(--main-color-one)', borderRadius: '12px', border: 0 }}>
                    <iframe style={{ height: '500px', width: '100%', border: 0 }}
                        src={`https://www.youtube.com/embed/${getVideoId(youtubeLink)}`}
                        title="youtube"
                    >
                    </iframe>
                </DialogContent>
            </Dialog>
        </>
    );
}
