import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Alert,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput
} from "@mui/material";
import { toast } from "react-toastify";
import {
  getAgentsForCompany,
  getUserProile,
  saveTask
} from "../../../api/userApi";
import { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, resolvePersianAndArabicNumbers } from "../../../helpers";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

const AddTask = () => {
  const { token, vendor, UserId, UserType, AccountType } = useAuth();
  const [task, setTask] = useState({
    summary: "",
    status: "0",
    assignedTo: "",
    description: ""
  });

  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = React.useState({});
  const history = useNavigate();
  const [agents, setAgents] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (token && AccountType === "2" && UserType !== "5") {
      getUserProile({ userId: token, language: "0" }).then(user => {
        if (user.resCode === 0) {
          setUserName(user.response.userProfile.name);
          setCompanyName(user.response.userProfile.companyName);
          setTask({ ...task, assignedTo: UserId })
        }
      });
      getAgentsForCompany({ superUserId: token }).then((users) => {
        setAgents(users.response.userArray);
      });
    } 
    if (UserType === "5") {
      setTask({ ...task, assignedTo: UserId })
    }
      
  },// eslint-disable-next-line
    [token, AccountType]);


  function formIsValid() {
    const _errors = {};
    if (!task.summary)
      _errors.summary = "الرجاء ادخال عنوان المهمّة";
    if (!task.description)
      _errors.description = "الرجاء ادخال وصف المهمّة";
      
    if (!task.status)
      _errors.status = "الرجاء اختيار حالة المهمّة";

    if (!task.assignedTo && UserType !== "5")
      _errors.assignedTo = "الرجاء اختيار الحساب"
      
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }



  const handleSave = () => {
    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);

      saveTask({
        language: "0",
        userId: token,
        status: task.status,
        summary: task.summary,
        description: task.description,
        assignedTo: task.assignedTo
      }).then(res => {
        if (res.resCode === 0) {
          history(`/admin/add-task-step2/${res.response.id}`);
          toast.success('تم إنشاء المهمّة بنجاح');
        } else {
          toast.error(res.resStr);
        }
      }).finally(() => setLoadingButton(false));
    }
  }

  return (
    <div>
      <SubHeader
        withSteps
        steps={["البيانات الأساسيّة", "البيانات الإضافيّة"]}
        OKElement={<></>}
        completedSteps={[]}
        currentStep={0}
      />

      <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>البيانات الأساسيّة</h4>
          <h6>يتوجب عليك ادخال عنوان و وصف للمهمة و اسنادها إليك أو لأحد حساباتك الفرعيّة</h6>
        </div>
        <div className='card-body' dir="rtl">
          <div className="row">

            <div className="col-12 col-md-6 mb-4 mb-lg-3 col-lg-4">
              <FormControl
                error={Boolean(errors.summary)}
                className="col-12" dir="rtl"
              >
                <TextField
                  label='عنوان المهمّة'
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  value={task.summary}
                  size='small'
                  onChange={(v) => {
                    setTask({
                      ...task,
                      summary: resolvePersianAndArabicNumbers(v.currentTarget.value)
                    });
                  }}
                  error={Boolean(errors.summary)}
                />
                {errors.summary &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.summary}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl
                error={Boolean(errors.status)}
                className="custom-select" dir="rtl">
                <InputLabel htmlFor='type' required>حالة المهمّة</InputLabel>
                <Select
                  value={task.status}
                  required
                  sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                  error={Boolean(errors.status)}
                  onChange={(event) => {
                    setTask({
                      ...task,
                      status: event.target.value,
                    });
                  }}
                  input={<OutlinedInput
                    notched
                    label='حالة المهمّة'
                    id="type"
                  />}
                  label='حالة المهمّة'
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}>
                  <MenuItem value='0'>{t("New")}</MenuItem>
                  <MenuItem value='1'>{t("InProgress")}</MenuItem>
                  <MenuItem value='2'>{t("Postponed")}</MenuItem>
                  <MenuItem value='3'>{t("Done")}</MenuItem>
                </Select>
                {errors.status &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.status}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>

            {UserType !== "5" && (
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl
                  error={Boolean(errors.assignedTo)}
                  className="custom-select" dir="rtl">
                  <InputLabel htmlFor='type' required>{t("AssignedTo")}</InputLabel>
                  <Select
                    value={task.assignedTo}
                    required
                    sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                    error={Boolean(errors.assignedTo)}
                    onChange={(event) => {
                      setTask({
                        ...task,
                        assignedTo: event.target.value,
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("AssignedTo")}
                      id="type"
                    />}
                    label={t("AssignedTo")}
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}>
                    <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount") : userName}</MenuItem>
                    {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                  </Select>
                  {errors.assignedTo &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.assignedTo}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>
            )}
         
            <div className="col-12 mb-4 mb-lg-3">
              <FormControl
                error={Boolean(errors.description)}
                className="col-12" dir="rtl"
              >
                <TextField
                  label='وصف المهمّة'
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  inputProps={{ maxLength: 500 }}
                  value={task.description}
                  size='small'
                  multiline
                  rows={6}
                  error={Boolean(errors.description)}
                  onChange={(v) => {
                    setTask({
                      ...task, description: resolvePersianAndArabicNumbers(
                        v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                      )
                    });
                  }}
                />
                <h6 className='rightalign mt-3'>
                  {task.description
                    ? parseInt(task.description.length) + "/500"
                    : "0/500"}
                </h6>
                {errors.description &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {errors.description}
                    </Alert>
                  </FormHelperText>}
              </FormControl>
            </div>

          </div>
        </div>
      </div>
      
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <div className="text-center pb-5 mb-5">
        <LoadingButton
          style={{ minWidth: "250px", justifyContent: "center" }}
          label={t("Next")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={loadingButton}
        />
      </div>
    </div>
  );
};

export default AddTask;
