import TextField from "@mui/material/TextField";
import React from "react";
import { useState } from "react";
import LoadingButton from "../global-components/loading-btn";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { InputAdornment, useMediaQuery } from "@mui/material";
import { parseUsername } from "../../helpers";
import { useTranslation } from "react-i18next";

const TwitterSection = (props) => {
  const [Data, setData] = useState(props.data);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  
  return (
    <div className={props.isEdit ? "services-area-admin" : "services-area"}>
      <div className='row'>
        <div className={`col-12 ${props.isEdit ? '' : 'p-0 m-0 text-center'}`} style={{ height: 'auto' }}>
          {props.isEdit ? (
            <>
              <TextField
                label='حساب منصّة X'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                className='text-align-last-left text-left mt-0 mb-3'
                value={Data.link}
                 InputProps={{
              startAdornment: (
                <InputAdornment position='start'>twitter.com/</InputAdornment>
              ),
            }}
                onChange={(v) => {
                  setData({ ...Data, link: parseUsername(v.currentTarget.value) });
                }}
              />
              {/* {Data.link && 
              <TwitterTimelineEmbed
              url={`https://twitter.com/${Data.link}`}
              sourceType="url"
              options={{ tweetLimit: '3' }}
              tweetLimit={3}
              lang="ar"
                />
              } */}
              <div className='mt-3 '>
                <LoadingButton
                  classes="primary-btn px-3 m-auto"
                  handleClick={(e) => props.saveDetails({ data: Data })}
                  label={t("SaveChanges")}
                  loading={props.loading}
                />
              </div>
            </>
          ) : (
              <>
                    <div className="services-area tweets-area" dir="rtl">
          <div className="row">

            <div className='col-12 justify-content-center align-items-center flex-column d-flex' style={{ marginBottom: '20px' }}>
              <span className="section-prefix">تغريدات منصّة X</span>
              <h2 className='sec-title'>
                استعرض آخر تغريداتنا على منصّة X
              </h2>
            </div>
            <div className="row col-12 service-wrapper m-0 w-100 tweets">
              <TwitterTimelineEmbed
                options={{ width: '100%', tweetLimit: '3' ,height:isLargeScreen ?600:455}}
                url={`https://twitter.com/${Data.link}`}
                sourceType="url"
                tweetLimit={3}
                lang="ar"
              />
            </div>
          </div>
        </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default TwitterSection;
