import { Autocomplete, Box, Chip, ClickAwayListener, Collapse, Divider, Fab, FormControl, Grow, IconButton, InputAdornment, MenuItem, Pagination, Paper, Popper, Select, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, debounce } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { useNavigate } from "react-router-dom";
import { getDemoSnakbar, openDeleteModal, preventString, trimPhone } from "../../../helpers";
import { Plus } from "../../../constants/icons";
import TuneIcon from '@mui/icons-material/Tune';
import { CITIES, DEMO_VENDORS, DISTRICTS, REGIONS } from "../../../constants";
import { deleteCustomer, getAgentsForCompany, getCustomers, getUserProile } from "../../../api/userApi";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import CustomerDetails, { CustomerDetailsContent } from "./customer-details";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ActionsPopper from "../../../components/global-components/actions-popper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";
import md5 from "md5";

const pageSize = 10;

const regionsOptions = REGIONS.map((region) => ({
  id: +region.REGION_ID,
  label: region.REGIONNAME_AR,
}));

const citiesOptions = CITIES.map((city) => ({
  id: +city.CITY_ID,
  label: city.CITYNAME_AR,
  regionId: +city.REGION_ID,
}));

const districtsOptions = DISTRICTS.map((district) => ({
  id: +district.DISTRICT_ID,
  label: district.DISTRICTNAME_AR,
  regionId: +district.REGION_ID,
  cityId: +district.CITY_ID,
}));

const propertiesOptions = [
  {
    id: 'الكل',
    label: 'الكل',
  },
  {
    id: 'أرض',
    label: 'أرض',
  },
   {
    id: 'دور',
    label: 'دور',
  },
  {
    id: 'شقة',
    label: 'شقة',
  },
  {
    id: 'فيلا',
    label: 'فيلا',
  },
  {
    id: 'استوديو',
    label: 'استوديو',
  },
  {
    id: 'غرفة',
    label: 'غرفة',
  },
  {
    id: 'استراحة',
    label: 'استراحة',
  },
  {
    id: 'معرض',
    label: 'معرض',
  },
  {
    id: 'مكتب',
    label: 'مكتب',
  },
  {
    id: 'مستودع',
    label: 'مستودع',
  },
   {
    id: 'مزرعة',
    label: 'مزرعة',
  },
   {
    id: 'عمارة',
    label: 'عمارة',
  },
];

const Row = ({ expendedRow, setExpendedRow, row, companyName, openPopper, setRow, anchorEl, setAnchorEl, setOpenPopper, superToken }) => {
  const { AccountType } = useAuth();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, background: row.id === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell align="right" className="small-col">
          <IconButton
            aria-label="expand row"
            size="small"
            className="custom-btn"
            sx={{ background: row.id === expendedRow ? 'var(--main-color-one)' : '#fff', borderRadius: '8px' }}
            onClick={() => setExpendedRow((old) => old === row.id ? 0 : row.id)}
          >
            {row.id === expendedRow ? <KeyboardArrowUpIcon sx={{ color: '#fff' }} /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" align="right">
          {row.name}
        </TableCell>
        <TableCell align="right"><a href={`tel:${trimPhone(row.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline',direction:'ltr',display:'inline-block' }}>{trimPhone(row.phone)}</a></TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          {row?.interests?.length > 0 ? <Stack direction="column" flexWrap={'wrap'} sx={{ gap: 1 }}>
            {row?.interests?.map((i, index) => <Chip key={index} label={`${i.interestingType} ${i.propertyType}${i.neighbourhood?.length > 0 ? ' -' : ''}${i.neighbourhood?.map(i => ' ' + districtsOptions.filter(
              (opt) => +i === +opt.id)?.[0]?.label)}`} variant="outlined" />)}
          </Stack>
            : t("Undefined")}
        </TableCell>
        {AccountType === '2' && <TableCell align="right">
          {row.owner === companyName ? t("MainAccount") : row.owner}
        </TableCell>}
        <TableCell align="right">
          <IconButton
            onClick={(e) => {
              setOpenPopper(!openPopper);
              setAnchorEl(anchorEl ? null : e.target);
              setRow(row);
            }}
            sx={{
              background: '#fff',
              zIndex: 99,
              color: 'var(--main-color-one)',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
              '&:hover,&:focus': {
                color: '#fff',
                background: 'var(--main-color-one)'
              }
            }}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>

      </TableRow>
      <TableRow sx={{ background: row.id === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell style={{ paddingBottom: row.id === expendedRow ? '40px' : 0, paddingTop: row.id === expendedRow ? '15px' : 0 }} colSpan={AccountType === '2' ? 7 : 6}>
          <Collapse in={row.id === expendedRow} timeout="auto" unmountOnExit>
            <CustomerDetailsContent superToken={superToken} client={row} companyName={companyName} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CustomersPage = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const { token, vendor, AccountType, UserType, UserId, setUserId } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [agents, setAgents] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    clientType: '',
    interestingType: '',
    priceFrom: '',
    priceTo: '',
    propertyType: [],
    area: '',
    city: '',
    neighbourhood: [],
    agentId: "all",
    updatedAt: 'all'
  });
  const [client, setClient] = useState({});
  const [expendedRow, setExpendedRow] = useState(0);
  const [row, setRow] = useState();
  const [openPopper2, setOpenPopper2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const { t } = useTranslation();
  const [superToken, setSuperToken] = useState('');

  useEffect(() => {
    getData(token, (res) => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
      } else {
        toast.error(res.resStr);
      }
      setLoadingData(false);
    });
  },
    // eslint-disable-next-line
    [token, page, search, filters, UserType]);
     
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = (id) => {
    deleteCustomer({
      "userId": token,
      "language": "0",
      "customerId": id
    }).then((res) => {
      if (res.resCode === 0) {
        toast.success(t("CustomerHasBeenDeletedSuccessfully"));
        getData();
      } else {
        toast.error(res.resStr);
      }
    })
  }

  const hasFilter = () => {
    return filters.updatedAt !== 'all' || filters.agentId !== "all" || filters.clientType || filters.interestingType || filters.priceFrom || filters.priceTo || filters.propertyType?.length > 0 || filters.area || filters.city || filters.neighbourhood?.length > 0;
  }
  
  useEffect(() => {
    if (token) {
      getUserProile({ userId: token, language: "0" }).then(user => {
        if (user.resCode === 0) {
          setUserName(user.response.userProfile.name);
          setCompanyName(user.response.userProfile.companyName);
          setUserId(user.response.userProfile.userId);
          if (UserType === "5") {
            setSuperToken(md5(user.response.userProfile.superUserCreatedAt + user.response.userProfile.superUserId?.[0]));
          }
        }
      });
      if (AccountType === "2" && UserType !== "5") {
        getAgentsForCompany({ superUserId: token }).then((users) => {
          setAgents(users.response.userArray);
        });
      }
    }
   
   
      
  },// eslint-disable-next-line
    [token, AccountType]);
  
  const renderFilterationBox = () => {
    return (
      <Box sx={{ backgroundColor: '#fff', width: '300px', direction: 'rtl', textAlign: 'right', display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
        <div className="filters-box">
          <h6 className="mt-2">{t("CustomerType")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.clientType}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "clientType",
                  id: "clientType",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    clientType: event.target.value,
                  });
                }}
              >
                <MenuItem value='الكل'>{t("All")}</MenuItem>
                <MenuItem value='مالك'>{t("Owner")}</MenuItem>
                <MenuItem value='باحث'>{t("Researcher")}</MenuItem>
                <MenuItem value='وسيط'>{t("Broker")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("TypeOfInterest")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.interestingType}
                inputProps={{
                  name: "interestingType",
                  id: "interestingType",
                }}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    interestingType: event.target.value,
                  });
                }}
              >
                <MenuItem value='الكل'>{t("All")}</MenuItem>
                <MenuItem value='شراء'>{t("Buying")}</MenuItem>
                <MenuItem value='تأجير'>{t("Renting")}</MenuItem>
                <MenuItem value='بيع'>{t("Sale")}</MenuItem>
                <MenuItem value='استئجار'>{t("Rent")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("PropertyType")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                // disablePortal
                size='small'
                multiple
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                options={propertiesOptions}
                value={
                  propertiesOptions.filter(
                    (i) => filters?.propertyType?.includes(i.id)
                  ) || []
                }
                sx={{ width: "100%" }}
                onChange={(event, newValue) => {
                  let ids = newValue?.map(i => i.id);
                  if (newValue?.[newValue?.length - 1]?.id === 'الكل')
                    ids = ['الكل'];
                  else
                    ids = ids.filter(i => i !== 'الكل');
                  setFilters({
                    ...filters,
                    propertyType: ids,
                  });
   
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    variant='outlined'
                  />
                )}
              />
 
            </FormControl>
          </div>

          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2 mb-3">{t("PriceRange")}</h6>
          <div className="col-12 mb-3 p-0">
            <TextField
              type="number"
              label={t("From")}
              sx={{ width: "50%" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={filters.priceFrom}
              size='small'
              onChange={(v) => {
                setFilters({
                  ...filters,
                  priceFrom: Math.abs(preventString(v.currentTarget.value)),
                });
              }}
            />
            <TextField
              type="number"
              label={t("To")}
              sx={{ width: "50%" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={filters.priceTo}
              size='small'
              onChange={(v) => {
                setFilters({
                  ...filters,
                  priceTo: Math.abs(preventString(v.currentTarget.value)),
                });
              }}
            />
          </div>
          {((AccountType === '2' && agents?.length > 0 && UserType !== "5") || UserType === "5") && (
            <>
              <Divider variant="inset" className="w-100" />
              <h6 className="mt-2">{t("AddedBy")}</h6>
              <div className="col-12 mb-3 p-0">
                <FormControl className="custom-select">
                  <Select
                    value={filters.agentId}
                    sx={{ width: "100%" }}
                    inputProps={{
                      name: "agentId",
                      id: "agentId",
                    }}
                    onChange={(event) => {
                      setFilters({
                        ...filters,
                        agentId: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value='all' key="all">{t("AllAccounts")}</MenuItem>
                    <MenuItem value={UserId} key="mother-account">{UserType !== '5' ? userName === companyName ? t("MainAccount") : userName : t("MyAccount")}</MenuItem>
                    {UserType !== '5' && agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("UpdatedAt")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.updatedAt}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    updatedAt: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='month'>{t("AMonthAgo")}</MenuItem>
                <MenuItem value='quarter'>{t("3MonthsAgo")}</MenuItem>
                <MenuItem value='year'>{t("AYearAgo")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("Location")}</h6>
          <div className="col-12 mb-3 mt-3 p-0">
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                options={regionsOptions}
                value={regionsOptions.filter(
                  (i) => +i.id === +filters?.area
                )?.[0] || null}
                sx={{ width: "100%" }}
                onChange={(v, newValue) => {
                  setFilters({
                    ...filters,
                    area: newValue?.id || "",
                    neighbourhood: [],
                    city: ''
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Region")}
                  />
                )}
              />
 
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                disabled={!filters?.area}
                options={citiesOptions.filter(
                  (i) => +i.regionId === +filters?.area
                )}
                value={
                  citiesOptions.filter(
                    (i) => +i.id === +filters?.city
                  )?.[0] || null
                }
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                onChange={(event, newValue) => {
                  setFilters({
                    ...filters,
                    city: newValue?.id || "",
                    neighbourhood: [],
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("City")}
                  />
                )}
              />
 
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                multiple
                disabled={!filters?.city}
                options={districtsOptions.filter(
                  (i) =>
                    +i.cityId === +filters?.city &&
                    +i.regionId === +filters?.area
                )}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                value={
                  districtsOptions.filter(
                    (i) => filters?.neighbourhood?.includes(+i.id)
                  ) || []
                }
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                onChange={(event, newValue) => {
                  const ids = newValue?.map(i => i.id);
                  setFilters({
                    ...filters,
                    neighbourhood: ids,
                  });
   
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Neighborhood")}
                  />
                )}
              />

            </FormControl>
          </div>
        </div>
        <div className="col-12 mb-3 p-0 mt-3 mx-0 row justify-content-center" style={{ gap: 15 }}>
         
          <button
            className={`btn action-btn primary-btn`}
            onClick={() => {
              setFilters({
                clientType: '',
                interestingType: '',
                priceFrom: '',
                priceTo: '',
                propertyType: [],
                area: '',
                city: '',
                neighbourhood: [],
                agentId: 'all',
                updatedAt: 'all'
              });
            }
            }
          >{t("Reset")}</button>
        </div>
      </Box>
    );
  }
  
  const getData = React.useMemo(
    () =>
      debounce((request, callback) => {
        setLoadingData(true);
        getCustomers({
          userId: token,
          page: page,
          search: search?.startsWith('05') ? search?.substring(1) : search,
          type: filters.clientType === 'الكل' ? '' : filters.clientType,
          interestingType: filters.interestingType === 'الكل' ? '' : filters.interestingType,
          propertyType: filters.propertyType?.includes('الكل') ? [] : filters.propertyType,
          priceFrom: filters.priceFrom,
          area: filters.area,
          city: filters.city,
          neighbourhood: filters.neighbourhood,
          priceTo: filters.priceTo,
          owner: filters.agentId === "all" ? undefined : filters.agentId,
          updatedAt: filters.updatedAt === "all" ? '' : filters.updatedAt
        }).then(callback);
      }, 500),
    [token, page, search, filters],
  );

  return (
    <>
      <SubHeader
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/add-customer')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>{t("NewCustomer")}</span>
          </Fab>
        }
      />
  
      <div style={{ display: 'flex', minHeight: '500px', width: '100%', flexDirection: 'column', marginBottom: '50px' }}>
        <div
          style={{ direction: "rtl", width: "100%", display: "flex", gap: 15, alignItems: 'center', marginBottom: 15 }}>
          <TextField
            id='standard-search'
            style={{ width: "80%", background: '#fff' }}
            placeholder={t("SearchByNameOrPhoneNumber")}
            type='search'
            size='small'
            value={search}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
               
          <button className={`btn ${hasFilter() ? 'primary-btn' : 'outlined-btn'} action-btn`} style={{ width: '20%', minWidth: 120 }} onClick={(e) => {
            setOpenPopper(!openPopper);
            setAnchorEl(anchorEl ? null : e.target);
          }}>
            {t("Filter")}   <TuneIcon />
          </button>
          <Popper
            sx={{
              zIndex: 100,
              offset: '55px 0 0 5px',
            }}
            open={openPopper}
            role={undefined}
            transition
            anchorEl={anchorEl}
            disablePortal={false}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => {
                    if (event.target.nodeName === 'BODY' && event.type === 'click') {
                      return;
                    } else {
                      setAnchorEl(null); setOpenPopper(false);
                    }
                       
                  }}>
                    {renderFilterationBox()}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
             
        </div>
        <TableContainer className="table-container" component={Paper}>
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell align="right" className="small-col" />
                <TableCell align="right">{t("Name")}</TableCell>
                <TableCell align="right">{t("PhoneNumber")}</TableCell>
                <TableCell align="right">{t("CustomerType")}</TableCell>
                <TableCell align="right">الاهتمامات</TableCell>
                {AccountType === '2' && <TableCell align="right">{t("AddedBy")}</TableCell>}
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingData ? <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              ><TableCell colSpan={AccountType === '2' ? 7 : 6}><Loadingdata /></TableCell></TableRow> :
                data?.length > 0 ? data.map((row) => (
                    
                  <Row
                    key={row.id}
                    superToken={superToken}
                    row={row}
                    expendedRow={expendedRow}
                    setExpendedRow={setExpendedRow}
                    companyName={companyName}
                    openPopper={openPopper2}
                    anchorEl={anchorEl2}
                    setAnchorEl={setAnchorEl2}
                    setOpenPopper={setOpenPopper2}
                    setRow={setRow} />
                )) :
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  ><TableCell colSpan={AccountType === '2' ? 7 : 6}><NoData msg="لم يتم العثور على عملاء" /></TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px', justifyContent: 'end' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </div>
       
      <CustomerDetails
        openCustomerDialog={openCustomerDialog}
        setOpenCustomerDialog={setOpenCustomerDialog}
        setClient={setClient}
        companyName={companyName}
        client={client} />
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <ActionsPopper
        openPopper={openPopper2}
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        setOpenPopper={setOpenPopper2}
        items={
          [
            userName !== row?.owner && UserType === "5" ? null : {
              title: t("Edit"),
              action: () => history(`/admin/edit-customer/${row.id}`)
            },
            userName !== row?.owner && UserType === "5" ? null : {
              title: t("Delete"),
              action: () => {
                if (DEMO_VENDORS.includes(vendor)) {
                  setOpenDemoSnackBar(true);
                } else {
                  openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisCustomer"),
                    () => handleDelete(row.id))
                }
              }
            }
          ]
        }
      />
    </>
  );
}

export default CustomersPage;
