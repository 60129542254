import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MenuItem, Select, TextField } from "@mui/material";
import { forgotPassword } from "../../api/userApi";
import AppLayout from "../../components/layouts/app-layout";
import LoadingButton from "../../components/global-components/loading-btn";
import { getDemoSnakbar, preventString, validatePhone } from "../../helpers";
import { DARK_TEMPLATES, DEMO_VENDORS, countryCodes } from "../../constants";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

function WLForgotPassword() {
    const [errors, setErrors] = useState({});
    const history = useNavigate();
    const [user, setUser] = useState({
        countryCode: "+966",
        phone: "",
        language: "0",
    });
    const { vendor, templateId } = useAuth();
    const [loadingButton, setLoadingButton] = useState(false);
    const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
    const { t } = useTranslation();

    function formIsValid() {
        const _errors = {};
        if (!user.countryCode) _errors.countryCode = t("ChooseCountryCode");
        if (!user.phone) _errors.phone = t("EnterPhoneNumber");
        else if (!validatePhone(user.phone)) _errors.phone = t("PhoneNumberIsIncorrect");

        setErrors(_errors);
        return Object.keys(_errors).length === 0;
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!formIsValid()) return;
        if (DEMO_VENDORS.includes(vendor)) {
            setOpenDemoSnackBar(true);
        } else {
            setLoadingButton(true);
            forgotPassword(user).then((_userInfo) => {
                if (_userInfo.resCode === 0) {
                    history("/admin/otp-change-password/" + user.countryCode + "/" + user.phone);
                    if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
                } else {
                    const _errors = {};
                    _errors.phone = _userInfo.resStr;
                    setErrors(_errors);
                }
                setLoadingButton(false);
            }).catch(e => setLoadingButton(false));
        }
    }

    return (
        <AppLayout pageTitle={t("SetPassword")}>
            <div className="wl-login pd-top-90 mg-bottom-100 text-center" onKeyDown={(event) => {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                    handleSubmit(event);
                }
            }}
                style={{ background: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : `url(${publicUrl}assets/img/pattern.png)` }}>
                <div className='login-modal' dir="ltr" >
                    <div className='col-10 col-sm-9'>
                        <form>
                            <h4>{t("SetPassword")}</h4>
                            <p>{t("PleaseEnterYourPhoneNumber")}</p>
                            <div className='row p-0 mt-4 mx-0'>
                                <div className='col-5 pl-0'>
                                    <div
                                        className='rld-single-input'
                                        dir='rtl'>
                                        <Select
                                            id='countryCode'
                                            name='countryCode'
                                            onChange={(e) =>
                                                setUser({
                                                    ...user,
                                                    countryCode: e.target.value,
                                                })
                                            }
                                            value={user.countryCode || ""}
                                            className='sortDDL withBg'>
                                            {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                                        </Select>
                                    </div>
                                </div>
     
                                <div
                                    className='col-7 pr-0'>
                                    <div className='rld-single-input' dir='ltr'>
                                        <TextField
                                            className="without-label"
                                            placeholder={t("EnterPhoneNumber1")}
                                            onChange={(e) =>
                                                setUser({
                                                    ...user,
                                                    phone: preventString(e.target.value),
                                                })
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ width: '100%', bgcolor: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#fff' }}
                                            value={user.phone}
                                            error={errors.phone ? true : false}
                                            helperText={errors.phone}
                                        />
                                    </div>
                                </div>
                            </div>
                            <LoadingButton
                                classes='mt-4 btn primary-btn px-3 w-100 justify-content-center'
                                label={t("Continue")}
                                loading={loadingButton}
                                handleClick={handleSubmit}
                            />
                        </form>
                    </div>
                </div>
                
            </div>
            {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
        </AppLayout>
    );
}

export default WLForgotPassword;
