import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserProile, getWLProfile } from "../../api/userApi";
import Header from "../admin/components/Header";
import {
  Box,
  IconButton,
  Skeleton,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../helpers/context";
import { toast } from "react-toastify";
import AccessDenied from "../../scenes/shared/access-denied";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { wlPlansAvailabilty } from "../../constants";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import data from "../admin/data";
import { useTranslation } from "react-i18next";

const AdminLayout = ({
  children,
  handleChangeNavDrawer,
  navDrawerOpen,
  menus,
  setNavDrawerOpen,
}) => {
  const {
    token,
    setToken,
    setUserId,
    vendor,
    setPlanEndDate,
    planEndDate,
    currentPlan,
    setUserType,
    setAccountType,
    AccountType,
    UserType,
    setTemplateId,
    loadingTemplate,
  } = useAuth();
  const history = useNavigate();
  const location = useLocation();
  // const [userProfile, setUserProfile] = useState(undefined);
  const [siteName, setSiteName] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  const [isDomainVerified, setIsDomainVerified] = useState("1");
  const [planExpirationWarning, setPlanExpirationWarning] = useState(false);
  const [planId, setPlanId] = useState("0");
  const [isUserVerified, setIsUserVerified] = useState("3");
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  
  const unblockedPage = useMemo(() => {
    const AgentsWhiteList = [
      "dashboard",
      "property",
      "customer",
      "ticket",
      'task',
      'deal',
      "chat",
      "link-with-whatsapp",
      "change-agent-phone",
      "otp-agent",
      "profile",
      'property/my-listings'
    ];
    return (
      AgentsWhiteList.filter((item) => location.pathname.includes(item))
        .length > 0
    );
  }, [location]);

  useEffect(
    () => {
      if (UserType === "5" && !unblockedPage) history("/admin/property/my-listings");
    }, // eslint-disable-next-line
    [unblockedPage]
  );

  const getActivePageTitle = () => {
    let pageTitle = "الإدارة";
    for (let item of menus) {
      if (window.location.href.indexOf(item.link) > -1) {
        pageTitle = t(item.text);
        return pageTitle;
      }
    }
    return pageTitle;
  };

  useEffect(
    () => {
      if (
        !token &&
        !window.location.href.includes("/forgot-password") &&
        !window.location.href.includes("set-new-password") &&
        !window.location.href.includes("success-reset-password") &&
        !window.location.href.includes("/otp-change-password")
      ) {
        history("/admin/login");
      }
      if (
        !window.location.href.includes("login") &&
        !window.location.href.includes("/forgot-password") &&
        !window.location.href.includes("set-new-password") &&
        !window.location.href.includes("success-reset-password") &&
        !window.location.href.includes("/otp-change-password") &&
        vendor
      ) {
        getWLProfile({ userName: vendor, language: "0" }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setSiteName(
              window.location.href.includes("amakkncompany.com")
                ? "مكتب أحمد للعقارات (مثال)"
                : _userInfo.response.wlUser.briefDescription
            );
            setPlanEndDate(_userInfo.response.wlUser.planEndDate);
            setPlanId(_userInfo.response.wlUser.planId);
            setIsDomainVerified(_userInfo.response.wlUser.saudiBusinessStatus);
            if (_userInfo.response.wlUser.saudiBusinessStatus !== "1")
              setPlanExpirationWarning(true);
            if (_userInfo.response.templateId === 0) {
              history(`/minasati-step2/${token}`, {
                state: { incomplete: true },
              });
            } else {
              setTemplateId(_userInfo.response.templateId);
            }
          }
        });
        if (token && vendor)
          getUserProile({
            userId: token,
            // userName: vendor,
            language: "0",
          })
            .then((_userInfo) => {
              if (_userInfo.resCode === 0) {
                setIsUserVerified(
                  _userInfo.response.userProfile.isUserVerified
                );
                setAccountType(_userInfo.response.userProfile.accountType);
                setUserType(_userInfo.response.userProfile.userType);
              } else {
                history("/");
                setToken(undefined);
                setUserId(undefined);
                document.cookie.split(";").forEach(function (c) {
                  if (!c.includes("AmmaknConsent"))
                    document.cookie = c
                      .replace(/^ +/, "")
                      .replace(
                        /=.*/,
                        "=;expires=" + new Date().toUTCString() + ";path=/"
                      );
                });
                toast.error(_userInfo.resStr);
                // history(`/401?returnURL=${window.location.pathname}`);
              }
            })
            .catch(() => {
              history(`/401?returnURL=${window.location.pathname}`);
            });
      }
    }, // eslint-disable-next-line
    [token, vendor]
  );

  useEffect(() => {
    if (
      vendor !== "amakkn" &&
      planEndDate !== undefined &&
      !location.pathname.includes("login") &&
      !window.location.href.includes("/forgot-password") &&
      !window.location.href.includes("set-new-password") &&
      !window.location.href.includes("success-reset-password") &&
      !window.location.href.includes("/otp-change-password")
    ) {
      if (
        moment(planEndDate).diff(moment(), "days") >= 0 &&
        moment(planEndDate).diff(moment(), "days") <= 7
      ) {
        setPlanExpirationWarning(true);
      } else if (
        moment(planEndDate).diff(moment(), "days") < 0 ||
        isUserVerified !== "3"
      ) {
        setAccessDenied(true);
      } else {
        setAccessDenied(false);
        setPlanExpirationWarning(false);
      }
    }
  }, [planEndDate, vendor, location, isUserVerified]);

  return (
    <>
      <Helmet>
        <title>
          {siteName
            ? `${siteName} | ${getActivePageTitle()}`
            : getActivePageTitle()}
        </title>
        <meta
          property='og:title'
          content={
            siteName
              ? `${siteName} | ${getActivePageTitle()}`
              : getActivePageTitle()
          }
        />
        <meta
          name='twitter:title'
          content={
            siteName
              ? `${siteName} | ${getActivePageTitle()}`
              : getActivePageTitle()
          }
        />
      </Helmet>
      {window.location.href.includes("/login") ||
      window.location.href.includes("/forgot-password") ||
      window.location.href.includes("set-new-password") ||
      window.location.href.includes("success-reset-password") ||
      window.location.href.includes("/otp-change-password") ? (
        <Box>{children}</Box>
      ) : token ? (
        loadingTemplate ? (
          <div className='loading-panel'>
            <div className='panel-menu'>
              <Skeleton variant='rectangular' height='100%' width='100%' />
            </div>
            <div className='panel-main'>
              <div className='panel-header'>
                <Skeleton variant='rectangular' height='100%' width='100%' />
              </div>
              <div className='panel-content'>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  width='100%'
                  sx={{ borderRadius: "12px" }}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <Header
              handleChangeNavDrawer={handleChangeNavDrawer}
              open={navDrawerOpen}
              menus={menus}
              setOpen={setNavDrawerOpen}
              vendorName={vendor}
              // userProfile={userProfile}
            />
            <Box
              component='main'
              onScroll={(e) => {
                if (e.target.scrollTop > 75) {
                  document
                    .querySelector(".main-header")
                    .classList.add("scrolled");
                } else {
                  document
                    .querySelector(".main-header")
                    .classList.remove("scrolled");
                }
              }}
              onClick={isLargeScreen ? () => {} : () => setNavDrawerOpen(false)}
              sx={{
                backgroundColor: window.location.href.includes("news")
                  ? "#FFF"
                  : "#F2F5F5",
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}>
              <div
                className={`admin-container ${navDrawerOpen ? "" : "opened"}`}>
                {data.menus.filter(
                  (i) =>
                    i.link === location.pathname &&
                    i?.needUpgrade?.includes(planId)
                )?.length > 0 ? (
                  <AccessDenied
                    btnLink='/admin/plans'
                    title='لا يمكنك استخدام هذه الميزّة'
                    btnText='ترقية الخطة'
                    desc='هذه الميزّة غير مدعومة ضمن خطتّك الحاليّة, يتوجب عليك ترقية خطتّك كي تتمكن من استخدام هذه الميزّة'
                  />
                ) : AccountType &&
                  (accessDenied || planId === "") &&
                  !location.pathname.includes("/ticket") &&
                  ((isUserVerified === "3" &&
                    !location.pathname.includes("/plans")) ||
                    isUserVerified !== "3") ? (
                  <AccessDenied
                    btnLink={
                      isUserVerified !== "3" || planId !== ""
                        ? "/verify"
                        : "/admin/plans"
                    }
                    btnText={
                      isUserVerified !== "3" || planId !== ""
                        ? t("AccountVerification")
                        : t("GoToSubscriptionPage")
                    }
                    desc={
                      isUserVerified !== "3"
                        ? "حسابك غير موّثق, للوصول للإدارة يرجى توثيق حسابك"
                        : wlPlansAvailabilty[AccountType].includes(
                            currentPlan?.plan.id
                          )
                        ? "صلاحيّة اشتراكك منتهيّة, يجب عليك تجديد الاشتراك أو الترقية لخطة آخرى"
                        : "خطتّك الحاليّة لا تدعم خدمة أماكن برو, للاستفادة من ميزات خدمة أماكن برو يتوجب علية ترقية الخطّة"
                    }
                    // "خطتّك الحاليّة موجهّة لحسابات الأفراد, لذا يجب عليك الإشتراك بإحدى الخطط الموجهّة للشركات"}
                  />
                ) : isDomainVerified === "-1" &&
                  !window.location.href.includes("amakkn.com") &&
                  !location.pathname.includes("/domain") &&
                  !location.pathname.includes("/ticket") ? (
                  <AccessDenied
                    btnLink='/admin/domain'
                    btnText='التحقق من توثيق النطاق'
                    desc='نطاقك غير موّثق, للوصول للإدارة يرجى توثيق نطاقك عبر المركز السعودي للأعمال, ثم التوجه لصفحة النطاق والتحقق من نجاح عملية التوثيق'
                  />
                ) : (
                  children
                )}
              </div>
            </Box>
          </>
        )
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={planExpirationWarning}
        onClose={() => setPlanExpirationWarning(false)}
        message={
          moment(planEndDate).diff(moment(), "days") <= 7 &&
          moment(planEndDate).diff(moment(), "days") >= 0
            ? `صلاحية اشتراكك سوف تنتهي بتاريخ ${planEndDate}, يرجي تجديد الاشتراك أو الترقيّة لخطة آخرى قبل هذا التاريخ`
            : moment(planEndDate).diff(moment(), "days") < 0
            ? `صلاحية اشتراكك قد انتهت بتاريخ ${planEndDate}, يرجي تجديد الاشتراك أو الترقيّة لخطة آخرى`
            : "نطاقك غير موّثق, يرجى توثيقه عبر المركز السعودي للأعمال, ثم التحقق من نجاح عملية التوثيق"
        }
        key={"planExpirationWarning"}
        action={
          <>
            {isDomainVerified !== "1" &&
              moment(planEndDate).diff(moment(), "days") > 7 && (
                <button
                  className='primary-btn py-0 px-2 mr-4'
                  style={{
                    fontSize: "12px !important",
                    fontWeight: 400,
                    height: "34px",
                  }}>
                  التحقق الآن
                </button>
              )}
            <IconButton
              size='small'
              color='inherit'
              sx={{
                marginLeft: 0,
                paddingRight: "16px",
                marginRight: 0,
              }}
              onClick={() => setPlanExpirationWarning(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default AdminLayout;
