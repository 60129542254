import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import md5 from "md5";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUser, setPushTokenForUserNew, verifyPhoneOwner } from "../../api/userApi";
import LoadingButton from "../../components/global-components/loading-btn";
import AppLayout from "../../components/layouts/app-layout";
import { getToken } from "../../Config/Firebase";
import { DARK_TEMPLATES, countryCodes } from "../../constants";
import { preventString, validatePassword } from "../../helpers";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";
  
const publicUrl = process.env.REACT_APP_URL + "/";

const WLLogin = () => {
    const [FireBaseToken, setFireBaseToken] = useState();
    const { token, UserType, setToken, setUserId, setUserType, setAccountType, vendor, templateId } = useAuth();
    const [credentials, setcredentials] = useState({
        password: "",
        phone: "",
        countryCode: "+966",
        language: "0",
    });
    const [errors, setErrors] = useState({});
    const [loadingButton, setLoadingButton] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const { name2, userId2, token2 } = useParams();
    const history = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    useEffect(() => {
        getToken(setFireBaseToken);
    }, []);
    
    useEffect(() => {
        if (searchParams.get('phone'))
            setcredentials({ ...credentials, phone: searchParams.get('phone') });
    },
        // eslint-disable-next-line
        [searchParams]);

    useEffect(() => {
        if (name2 && userId2 && token2) {
            setToken(token2);
            setUserId(userId2);
            history('/admin/dashboard');
        }
    },
        // eslint-disable-next-line
        [name2, userId2, token2]);
    
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!formIsValid()) return;
        setLoadingButton(true);
        if (currentStep === 1) {
            let phone = credentials.phone;
            if (phone.startsWith('00966')) {
                phone = phone.substring(5);
            } else if (phone.startsWith('0')) {
                phone = phone.substring(1);
            } else if (phone.startsWith('966')) {
                phone = phone.substring(3);
            }
            verifyPhoneOwner({ vendorName: vendor, phone: credentials.countryCode + phone, language: '0' }).then((_userInfo) => {
                if (_userInfo.resCode === 0) {
                    setLoadingButton(false);
                    setCurrentStep(2);
                } else {
                    toast.error(_userInfo.resStr);
                    setLoadingButton(false);
                }
            }).catch(e => setLoadingButton(false));
        } else if (currentStep === 2) {
            loginUser(credentials).then((_userInfo) => {
                if (_userInfo.resCode === 0) {
                    const userId = _userInfo.response.user.createdAt + _userInfo.response.user.userId;
                    let userHashed = md5(userId);
                    setToken(userHashed);
                    setUserId(_userInfo.response.user.userId);
                    setUserType(_userInfo.response.user.userType);
                    setAccountType(_userInfo.response.user.accountType);

                    if (FireBaseToken)
                        setPushTokenForUserNew({
                            userId: userHashed,
                            pushToken: FireBaseToken,
                            platform: "website",
                            language: "0",
                            identifier: "-1",
                        });
                    toast.success(t("WelcomeBack", { name: _userInfo.response.user.name }));
                    setLoadingButton(false);
                    history(_userInfo.response.user.userType === '5'? '/admin/property/my-listings':'/admin', { replace: true });
                } else {
                    const _errors = {};
                    _errors.password = _userInfo.resStr;
                    setErrors(_errors);
                    setLoadingButton(false);
                }
            });
        }
    };
    
   
    function formIsValid() {
        const _errors = {};
        if (currentStep === 2) {
            if (!credentials.password) _errors.password = t("EnterThePassword");
            else if (credentials.password && !validatePassword(credentials.password)) _errors.password = t("PasswordMustNotBeLessThan6Characters");
        } else if (currentStep === 1) {
            if (!credentials.phone) _errors.phone = t("EnterPhoneNumber1");
            if (!credentials.countryCode) _errors.phone = t("ChooseCountryCode");
        }
      
        setErrors(_errors);
        // Form is valid if the errors object has no properties
        return Object.keys(_errors).length === 0;
    }
    
    useEffect(() => {
        if (token) history(UserType === "5" ? '/admin/property/my-listings' : '/admin/dashboard');
    }, // eslint-disable-next-line
        [token, UserType]);

    return (
        <AppLayout pageTitle={t("Login")}>
            {
                token ? (
                    <div className="login-wrapper"><CircularProgress /></div>
                ) : (
                    <div className="wl-login pd-top-90 mg-bottom-100 text-center" onKeyDown={(event) => {
                        if (event.code === "Enter" || event.code === "NumpadEnter") {
                            handleSubmit(event);
                        }
                    }}
                        style={{ background: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : `url(${publicUrl}assets/img/pattern.png)` }}>
                        <div className='login-modal' dir="ltr" >
                            <div className='col-10 col-sm-9'>
                                <form>
                                    <h4>{t("Login")}</h4>
                                    <p>{currentStep === 1 ? t("EnterYourRegisteredPhoneNumberInAmakkn") : t("EnterYourPassword")}</p>
                                    {currentStep === 1 && (
                                        <div className='row p-0 mt-4 mx-0'>
                                            <div className='col-5 pl-0'>
                                                <div
                                                    className='rld-single-input'
                                                    dir='rtl'>
                                                    <Select
                                                        id='countryCode'
                                                        name='countryCode'
                                                        onChange={(e) =>
                                                            setcredentials({
                                                                ...credentials,
                                                                countryCode: e.target.value,
                                                            })
                                                        }
                                                        value={credentials.countryCode || ""}
                                                        className='sortDDL withBg'>
                                                        {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                                                    </Select>
                                                </div>
                                            </div>
     
                                            <div
                                                className='col-7 pr-0'>
                                                <div className='rld-single-input' dir='ltr'>
                                                    <TextField
                                                        className="without-label"
                                                        placeholder={t("EnterPhoneNumber1")}
                                                        onChange={(e) =>
                                                            setcredentials({
                                                                ...credentials,
                                                                phone: preventString(e.target.value),
                                                            })
                                                        }
                                                        InputLabelProps={{ shrink: true }}
                                                        sx={{ width: '100%', bgcolor: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#fff' }}
                                                        value={credentials.phone}
                                                        error={errors.phone ? true : false}
                                                        helperText={errors.phone}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-12 p-0">
                                                 {errors.phone && (
                                                     <div className='alert alert-danger'>{errors.phone}</div>
                                                 )}
                                             </div> */}
                     
                                        </div>
                                    )}
                                   
                                    {currentStep === 2 && (
                                        <>
                                            <div className='col-12 p-0 mt-4'>
                                                <div className='rld-single-input' dir='rtl'>
                                                    <TextField
                                                        type={showPassword ? 'text' : 'password'}
                                                        className="without-label"
                                                        InputLabelProps={{ shrink: true }}
                                                        sx={{ width: '100%', bgcolor: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : '#fff', '& input': { border: 'none', '&:hover': { border: 'none' }, '&:focus': { border: 'none' } } }}
                                                        value={credentials.password}
                                                        error={errors.password ? true : false}
                                                        helperText={errors.password}
                                                        placeholder={t("EnterThePassword")}
                                                        onChange={(e) =>
                                                            setcredentials({
                                                                ...credentials,
                                                                password: e.target.value,
                                                            })
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                {/* {errors.password && (
                                            <div className='alert alert-danger'>
                                                {errors.password}
                                            </div>
                                        )} */}
                                            </div>
                                            <div className={`col-12 p-0 text-right ${errors.password ? 'mt-4' : 'mt-2'}`}>
                                                <Link to="/admin/forgot-password" style={{ fontSize: '13px' }}>{t("ForgotYourPassword")}</Link>
                                            </div>
                                        </>
                                    )}
                                    <LoadingButton
                                        classes='mt-4 btn primary-btn px-3 w-100 justify-content-center'
                                        label={currentStep === 2 ? t("Login") : t("Continue")}
                                        loading={loadingButton}
                                        handleClick={handleSubmit}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </AppLayout>
    );
}

export default WLLogin;
