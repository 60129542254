import { Avatar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ProfileType, UserStatus } from "../../../../Config/Config";
import { useTranslation } from "react-i18next";

const ChatMessage = (props) => {
  const { t } = useTranslation();

  let profile = "";
  let status = null;
  switch (props.status) {
    case UserStatus.Online:
      status = "";
      break;
    case UserStatus.Offline:
      status = "";
      break;
    case UserStatus.Busy:
      status = "";
      break;
    case UserStatus.Away:
      status = "";
      break;
    default:
      break;
  }
  switch (props.profileType) {
    case ProfileType.Image:
      profile = (
        <div className={"avatar " + status}>
          <Avatar src={props.profile} alt='profile img' variant="rounded"
            sx={{
              width: 96,
              height: 96,
              bgcolor: props.profile ? '#fff' : 'var(--main-color-one)'
            }}
          />
        </div>
      );
      break;
    case ProfileType.Svg:
      profile = (
        <div className='avatar bg-success text-light'>
          <span>{props.profile}</span>
        </div>
      );
      break;
    case ProfileType.Text:
      profile = (
        <div className={"avatar bg-info text-light " + status}>
          <span>EW</span>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <li
      className={
        "contacts-item friends " +
        (props.isActive ? "active " : " ") +
        (props.badge ? "unread" : "") +
        (props.selected.selected && props.selected.id === props.ChatUserId ? 'bg-active':'')
      }>
      <Link
        className='contacts-link'
        to={props.to}
        onClick={props.handleChatClick}>
        {profile}
        <div className='contacts-content text-right mr-3'>
          <div className='contacts-info'>
            <h6 className='chat-name text-truncate'>
              {props.propertyTypeName +
                " " +
                (props.listedFor === "2" ? t("ForSale") : t("ForRent"))}
            </h6>
            <div className='chat-time'>{props.time}</div>
          </div>
          {/* <div className="contacts-texts"> */}
          <h6 className='chat-time' style={{color:'#565768',fontSize:'14px'}}>{props.name}</h6>
          <div className='contacts-texts'>
            <h6 className='chat-time' style={{color:'#565768',fontSize:'14px'}}>{props.message?.length>33 ? props.message.substring(0,33) + '..':props.message}</h6>
            {props.endIcon ? (
              <div className='d-inline-flex align-items-center ml-1'>
                {props.endIcon}
              </div>
            ) : null}
            {props.badge ? (
              <div className='badge badge-rounded badge-red'>
                {props.badge}
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    </li>
  );
};
export default ChatMessage;
