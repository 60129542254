import React, { useCallback, useEffect, useMemo, useState } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Fab, LinearProgress, TextField, Typography, useMediaQuery } from "@mui/material";
import { getDemoSnakbar, resolvePersianAndArabicNumbers } from "../../../helpers";
import DragAndDropFileUploader from "../../../components/global-components/drag-drop-file-uploader";
import { uploadWlFileBase64 } from "../../../api/userApi";
import { Plus, Trash } from "../../../constants/icons";
import AddElementsModal from "./components/add-elements-modal";
import ElementWrapper from "./components/element-wrapper";
import { createUpdateNews, getNewsDetail } from "../../../api/blogApi";
import LoadingButton from "../../../components/global-components/loading-btn";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar } from "swiper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper'
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from "react-dnd";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

const AddOrUpdateNews = () => {
  const { token, vendor } = useAuth();
  const history = useNavigate();
  const [newItem, setNewItem] = useState({
    language: "0",
    userId: token,
  });
  const [loadingImage, setLoadingImage] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  let { id } = useParams();
  const [expandedCard, setExpandedCard] = useState(-2);
  const { t } = useTranslation();

  const [openAddNewElementModal, setOpenAddNewElementModal] = useState(
    {
      visible: false,
      elements: [
        {
          id: 1,
          type: 'text',
          value: undefined,
          expand: true
        },
        {
          id: 2,
          type: 'image',
          value: undefined,
          expand: true
        }
      ],
    }
  );
  const [loadingImageButtons, setLoadingImageButtons] = useState({ id: 0 });
  const [uploadedImages, setUploadedImages] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const [uploadedPic, setuploadedPic] = useState({ id: 0 });
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);

  const uploadedProgress = useMemo(() => {
    if (totalImages === 0 || (totalImages > 0 && (uploadedImages - 1 === totalImages)))
      return 0;
    return uploadedImages * 100 / totalImages > 100 ? 100 : uploadedImages * 100 / totalImages;
  }, [uploadedImages, totalImages]);

  const [gallery, setGallery] = useState({ id: undefined, value: '', images: [] });

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setOpenAddNewElementModal((prevData) => {
      return {
        visible: false,
        elements: update(prevData.elements, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevData.elements[dragIndex]],
          ],
        })
      }
    }
    )
  }, // eslint-disable-next-line
    []);

  
  useEffect(() => {
    if (vendor && id) {
      getNewsDetail({
        userName: vendor,
        language: "0",
        id: id,
        userId: token
      }).then((_response) => {
        if (_response.resCode === 0) {
          setNewItem({
            ...newItem,
            id: id,
            subject: _response.response.subject,
            shortDescription: _response.response.shortDescription,
            image: _response.response.image
          });
          const temp = [];
          _response.response.sections?.map(item => {
            temp.push({
              id: item.id,
              type: item.type,
              value: item.value,
              images: item.type === 'gallery' ? item?.value && item?.value?.substring(0, item.value.length - 1)?.split(',') : [],
              expand: false
            });
           
            return null;
          });

          setOpenAddNewElementModal(
            {
              visible: false,
              elements: temp || [
                { id: 1, type: 'text', value: undefined },
                { id: 2, type: 'image', value: undefined }
              ]
            }
          );


        }
        else
          toast.error(_response.resStr);
      });
    }
  },
    // eslint-disable-next-line
    [id, vendor, token]);

  useEffect(() => {
    if (totalImages > 0 && uploadedImages - 1 === totalImages) {
      setTotalImages(0);
      setuploadedPic({ id: 0 });
      setUploadedImages(1);
      let temp = [];
      for (let i of openAddNewElementModal.elements) {
        if (i.id === gallery.id) {
          temp.push({ ...i, value: gallery.value });
        } else {
          temp.push(i);
        }
      }
      setGallery({ id: undefined, value: '', images: [] });
      setOpenAddNewElementModal((old) => { return { visible: old.visible, elements: temp } });
    }
  }, // eslint-disable-next-line
    [uploadedImages]);
  
  const checkIsValid = () => {
    if (
      newItem.subject &&
      newItem.shortDescription &&
      newItem.image
    ) {
      return true;
    } else {
      toast.error(t("PleaseFillInAllBasicInformation"));
      return false;
    }
  };

  function uploadNewsImage(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      let fileInfo = {
        pathWithFileName: "News/" + Date.now() + file[0]?.name?.substring(file[0]?.name?.lastIndexOf('.')),
        base64Data: reader.result,
        typeOfFile: file[0].type,
        userId: token,
        language: "0",
      };
      uploadWlSectionFile(fileInfo);
    };
  }

  function uploadWlSectionFile(fileInfo) {
    if (DEMO_VENDORS.includes(vendor)) {
      setNewItem({ ...newItem, image: fileInfo.base64Data });
    } else {
      setLoadingImage(true);
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          setNewItem({ ...newItem, image: resp.response.url });
        } else {
          toast.error(resp.resStr);
        }
        setLoadingImage(false);
      });
    }
  }

  const handleSave = () => {
    if (checkIsValid()) {
      if (DEMO_VENDORS.includes(vendor)) {
        setOpenDemoSnackBar(true);
      } else {
        setIsSubmittingData(true);
        createUpdateNews({
          ...newItem,
          sections: openAddNewElementModal.elements.filter(i => (i.type === 'line' || (i.type !== 'line' && i.value !== null && i.value !== "" && i.value !== undefined)))
        }).then(res => {
          if (res.resCode === 0) {
            toast.success(t("TheNewsHasBeenAddedSuccessfully"));
            history('/admin/blog')
          }
          else {
            toast.error(res.resStr);
          }
        }).finally(() => setIsSubmittingData(false));
      }
    }
   
  }

  const handleAddNewElement = () => {
    setOpenAddNewElementModal({ ...openAddNewElementModal, visible: true });
  }
  
  function uploadNewsContentImage(file, id) {
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      let fileInfo = {
        pathWithFileName: "News/" + Date.now() + file[0]?.name?.substring(file[0]?.name?.lastIndexOf('.')),
        base64Data: reader.result,
        typeOfFile: file[0].type,
        userId: token,
        language: "0",
      };
      uploadNewsContentImageFile(fileInfo, id);
    };
  }

  function uploadNewsContentImageFile(fileInfo, id) {
    if (DEMO_VENDORS.includes(vendor)) {
      setLoadingImageButtons({ id });
      let temp = [];
      for (let i of openAddNewElementModal.elements) {
        if (i.id === id) {
          temp.push({ ...i, value: fileInfo.base64Data })
        } else {
          temp.push(i);
        }
      }
      setOpenAddNewElementModal({ visible: false, elements: temp });
      setLoadingImageButtons({ id: 0 });

    } else {
      setLoadingImageButtons({ id });
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          let temp = [];
          for (let i of openAddNewElementModal.elements) {
            if (i.id === id) {
              temp.push({ ...i, value: resp.response.url })
            } else {
              temp.push(i);
            }
          }
          setOpenAddNewElementModal({ visible: false, elements: temp });
        } else {
          toast.error(resp.resStr);
        }
        setLoadingImageButtons({ id: 0 });
      });
    }
  }

  const getTitle = (image) => {
    let title = '';
    if (image.indexOf("#") > -1)
      title = image.substring(image.indexOf("#") + 1);
    return title.replace(/#/g, '');
  }

  function onTitleChange(target, img, id) {
    let value = resolvePersianAndArabicNumbers(target.target.value).replace(/#/g, '');
    let imageWithoutTitle = img.indexOf("#") > -1 ? img.substring(0, img.indexOf("#") + 1) : img;
    let newImage = imageWithoutTitle;
    if (value.length > 0)
      newImage = imageWithoutTitle + '#' + value;
    let temp = [];
    for (let i of openAddNewElementModal.elements) {
      if (i.id === id) {
        temp.push({ ...i, value: newImage })
      } else {
        temp.push(i);
      }
    }
    setOpenAddNewElementModal((e) => { return { visible: false, elements: temp } });
    
  }

  const getGalleryTitle = (image, index) => {
    let title = '';
    let images = image.split(',');
   
    if (images[index].indexOf("#") > -1)
      title = images[index].substring(images[index].indexOf("#") + 1);
    return title.replace(/#/g, '');
  }

  function onGalleryTitleChange(target, img, id, index) {
    let value = resolvePersianAndArabicNumbers(target.target.value).replace(/#/g, '');
    
    let photos = "";
    let images = img.split(',');
    let imageWithoutTitle = images[index].indexOf("#") > -1 ? images[index].substring(0, images[index].indexOf("#") + 1) : images[index];
    let newImage = imageWithoutTitle;
    if (value.length > 0)
      newImage = imageWithoutTitle + '#' + value;
    
    images[index] = newImage;

    images.forEach((element) => {
      photos = photos + element + ",";
    });

    photos = photos.substring(0, photos.length - 1);
  
    let temp = [];
    for (let i of openAddNewElementModal.elements) {
      if (i.id === id) {
        temp.push({ ...i, value: photos })
      } else {
        temp.push(i);
      }
    }
    setOpenAddNewElementModal((e) => { return { visible: false, elements: temp } });
    
  }

  const onDrop = async (files, id, item) => {
    if (DEMO_VENDORS.includes(vendor)) {
      setGallery({ ...gallery, id: id, images: item.images });
      for (let index = 0; index < files.length; index++) {
        let imag = files[index];
        let reader = new FileReader();
        reader.readAsDataURL(imag);
        reader.onloadend = async () => {
           
          let temp = [];
          for (let i of openAddNewElementModal.elements) {
            if (i.id === item.id) {
              temp.push({ ...i, images: [...i.images, reader.result] });
            } else {
              temp.push(i);
            }
          }
          setOpenAddNewElementModal((old) => { return { visible: old.visible, elements: temp } });
        };
        
      }
    } else {
      setGallery({ ...gallery, id: id });
      setTotalImages(files.length);
      const forLoop = async _ => {
        for (let index = 0; index < files.length; index++) {
          let imag = files[index];
          let reader = new FileReader();
          reader.readAsDataURL(imag);
          reader.onloadend = async () => {
            setuploadedPic({ id: id });
            uploadWlFileBase64({
              pathWithFileName: "News/" + Date.now() + imag?.name?.substring(imag?.name?.lastIndexOf('.')),
              userId: token,
              base64Data: reader.result,
              typeOfFile: imag.type,
              language: "0",
            }).then((resp) => {
              if (resp.resCode === 0) {
                setGallery((old) => {
                  return { ...old, value: (old.value + (resp.response.url + ',')) };
                });
                setUploadedImages((val) => val + 1);
              } else {
                toast.error(resp.resStr);
              }
              setLoadingImage(false);
            }).catch(e => {
              setuploadedPic({ id: 0 });
              // setUploadedProgress(0);
              setTotalImages(0);
            });
          };
        }
      }
      await forLoop();
    }
  }
  
  function handleDeleteImage(index, item, image) {
    let temp = [];
    if (DEMO_VENDORS.includes(vendor)) {
      for (let i of openAddNewElementModal.elements) {
        if (i.id === item.id) {
          temp.push({ ...i, images: item.images.filter(m => m !== image) });
        } else {
          temp.push(i);
        }
      }
      setOpenAddNewElementModal((old) => { return { visible: old.visible, elements: temp } });

    } else {
      let photos = "";
      let images = item.value?.split(',');
      images.splice(index, 1);
      images.forEach((element) => {
        photos = photos + element + ",";
      });
      photos = photos.substring(0, photos.length - 1);
      for (let i of openAddNewElementModal.elements) {
        if (i.id === item.id) {
          temp.push({ ...i, value: photos });
        } else {
          temp.push(i);
        }
      }
      setOpenAddNewElementModal((old) => { return { visible: old.visible, elements: temp } });
    }

  }

  return (
    <>
      <SubHeader
        pageTitle={id ? t("EditTheNews") : t("AddNewNews")}
        OKElement={<></>}
      />
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <div className='custom-card with-switcher' style={{ minHeight: 'auto' }}>
        <div className='card-header' style={{ cursor: 'pointer' }} onClick={() => {
          if (expandedCard === 0)
            setExpandedCard(-1)
          else
            setExpandedCard(expandedCard === -2 ? 1 : 0)
        }}>
          <div>
            <h4>{t("BasicInformation")}</h4>
            <h6>{t("EnterTheHeadlineOfTheNewsAndItsImageInAdditionToBriefDescription")}</h6>
          </div>
          <div>
            {expandedCard === 0 || expandedCard === -2 ? <ExpandLessIcon sx={{ color: 'var(--main-color-one)', fontSize: 30, cursor: 'pointer' }} onClick={() => setExpandedCard(expandedCard === -2 ? 1 : -1)} /> :
              <ExpandMoreIcon sx={{ color: 'var(--main-color-one)', fontSize: 30, cursor: 'pointer' }} onClick={() => setExpandedCard(0)} />
            }
          </div>
        </div>
        <div className='card-body pb-0' style={{ display: expandedCard === 0 || expandedCard === -2 ? 'block' : 'none' }}>
          <div className='fields-wrapper '>
            <div className="row col-12 justify-content-between">

              <div className='row p-0 m-0 col-12 col-md-7 flex-column align-items-center'>
                <div className='mb-4 w-100'>
                  <TextField
                    label={t("NewsHeadline")}
                    sx={{ width: "100%" }}
                    required={true}
                    autoComplete="false"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={newItem.subject}
                    size='small'
                    onChange={(v) => {
                      setNewItem({
                        ...newItem,
                        subject: resolvePersianAndArabicNumbers(v.currentTarget.value),
                      });
                    }}
                  />
                </div>
                <div className='mb-4 w-100'>
                  <TextField
                    label={t("BriefDescriptionOfTheNews")}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={true}
                    inputProps={{ maxLength: 300 }}
                    value={newItem.shortDescription}
                    size='small'
                    multiline
                    rows={8}
                    onChange={(v) => {
                      setNewItem({
                        ...newItem,
                        shortDescription: resolvePersianAndArabicNumbers(
                          v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value)
                      });
                    }}
                  />

                  <h6 className='rightalign mt-2'>
                    {newItem.shortDescription
                      ? parseInt(newItem.shortDescription.length) + "/300"
                      : "0/300"}
                  </h6>
                </div>

              </div>
              <div className='p-0 m-0 col-12 col-md-4 flex-column align-items-center'>
                <div className='col-12 mb-4'>
                  <div className='img-wrapper position-relative w-100 d-flex flex-column'>
                    <span style={{ color: 'var(--paragraph-color)', fontSize: 13, paddingBottom: 5 }} className="text-right">{t("NewsImage")} <span style={{ color: '#D00404', fontSize: 15, fontWeight: 700 }}>*</span></span>
                    {!newItem.image && (
                      <DragAndDropFileUploader
                        singleFile
                        ImageOnly
                        uploading={loadingImage}
                        OnOK={(file) => uploadNewsImage(file)} />
                    )}
                 
                    {!loadingImage && newItem.image && (
                      <>
                        <DragAndDropFileUploader
                          singleFile
                          ImageOnly
                          handleChange={(e) => uploadNewsImage(e.target.files)}
                          loading={loadingImage}
                          uploading={loadingImage}
                          title={t("ChangeImage")}
                          id='contained-button-file'
                          style={{
                            width: 'fit-content',
                            position: 'absolute',
                            top: '35px',
                            right: '10px'
                          }}
                          buttonMode
                        />
                        <img src={newItem.image} alt='news-img' style={{
                          height: '250px',
                          width: '100%',
                          borderRadius: 6,
                          objectFit: 'cover'
                        }} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='custom-card with-switcher' style={{ minHeight: 'auto' }} >
        <div className='card-header' style={{ cursor: 'pointer' }}
          onClick={() => {
            if (expandedCard === 1)
              setExpandedCard(-1)
            else
              setExpandedCard(expandedCard === -2 ? 0 : 1)
          }}>
          <div>
            <h4>{t("Content")}</h4>
            <h6>{t("YouCanAddManyElementsToHelpComposeTheNews")}</h6>
          </div>
          <div>
            {expandedCard === 1 || expandedCard === -2 ? <ExpandLessIcon sx={{ color: 'var(--main-color-one)', fontSize: 30, cursor: 'pointer' }} onClick={() => setExpandedCard(expandedCard === -2 ? 0 : -1)} /> :
              <ExpandMoreIcon sx={{ color: 'var(--main-color-one)', fontSize: 30, cursor: 'pointer' }} onClick={() => setExpandedCard(1)} />
            }
          </div>
        </div>
        <div className='card-body pb-0' style={{ display: expandedCard === 1 || expandedCard === -2 ? 'block' : 'none' }}>
          <div className='fields-wrapper'>
            <DndProvider backend={isLargeScreen ? HTML5Backend : TouchBackend}>
              <div className='row p-0 m-0 col-12 flex-column align-items-center'>
                {
                  openAddNewElementModal.elements.map((item, index) => {
                    if (item.type === 'text') {
                      return (
                        <ElementWrapper
                          key={item.id}
                          index={index}
                          openAddNewElementModal={openAddNewElementModal}
                          setOpenAddNewElementModal={setOpenAddNewElementModal}
                          element={item}
                          moveCard={moveCard}
                          childern={
                            <>
                              <div className={`toolbar-container toolbar-container-${item.id}`}></div>
                              <CKEditor
                                editor={DecoupledEditor}
                                config={{
                                  language: {
                                    ui: 'ar',
                                    content: 'ar',
                                  },
                                  toolbar: {
                                    items: [
                                      'undo', 'redo',
                                      '|', 'heading',
                                      '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                      '|', 'bold', 'italic', 'strikethrough',
                                      '|', 'alignment',
                                      '|', 'link', 'blockQuote',
                                      '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                    ],
                                    shouldNotGroupWhenFull: false
                                  },
                                  mediaEmbed: false
                                }}
                                
                                data={item.value}
                                onReady={(editor) => {
                                  const toolbarContainer = document.querySelector(`.toolbar-container-${item.id}`);
                                  toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  let temp = [];
                                  for (let i of openAddNewElementModal.elements) {
                                    if (i.id === item.id) {
                                      temp.push({ ...i, value: data })
                                    } else {
                                      temp.push(i);
                                    }
                                  }
                                  setOpenAddNewElementModal({ visible: false, elements: temp });
                                }}
                              />
                            </>
                          } />
                      );
                    } else if (item.type === 'image') {
                      return (
                        <ElementWrapper
                          key={item.id}
                          moveCard={moveCard}
                          index={index}
                          openAddNewElementModal={openAddNewElementModal} setOpenAddNewElementModal={setOpenAddNewElementModal} element={item} childern={
                            <>
                              <div className='img-wrapper position-relative w-100 d-flex flex-column'>
                                {!item.value && (
                                  <DragAndDropFileUploader
                                    singleFile
                                    ImageOnly
                                    uploading={loadingImageButtons.id === item.id}
                                    OnOK={(file) => uploadNewsContentImage(file, item.id)} />
                                )}
                
                                {loadingImageButtons.id !== item.id && item.value && (
                                  <>
                                    <DragAndDropFileUploader
                                      singleFile
                                      ImageOnly
                                      handleChange={(e) => uploadNewsContentImage(e.target.files, item.id)}
                                      loading={loadingImageButtons.id === item.id}
                                      uploading={loadingImageButtons.id === item.id}
                                      title={t("ChangeImage")}
                                      id={`contained-button-file${item.id}`}
                                      style={{
                                        width: 'fit-content',
                                        position: 'absolute',
                                        top: '30px',
                                        right: '10px'
                                      }}
                                      buttonMode
                                    />
                                    <img src={item.value} alt='news-img' style={{
                                      height: '340px',
                                      width: '100%',
                                      objectFit: 'cover'
                                    }} />
                                    <div className='col-12 mb-3 p-0 '>
                                      <TextField
                                        placeholder={t("ImageDescription")}
                                        sx={{ width: "100%" }}
                                        autoComplete="false"
                                        onChange={(e) => onTitleChange(e, item.value, item.id)}
                                        value={getTitle(item.value)}
                                        size='small'
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          } />
                      );
                    }
                    else if (item.type === 'gallery') {
                      return (
                        <ElementWrapper
                          key={item.id}
                          index={index}
                          moveCard={moveCard}
                          openAddNewElementModal={openAddNewElementModal} setOpenAddNewElementModal={setOpenAddNewElementModal} element={item} childern={
                            <div className='img-wrapper position-relative w-100 d-flex flex-column'>
                              <DragAndDropFileUploader
                                ImageOnly
                                singleFile={false}
                                OnOK={(e) => onDrop(e, item.id, item)}
                                uploading={uploadedPic.id === item.id}
                                id="project-imgs"
                              />
                              {uploadedPic.id === item.id && (
                                <Box sx={{ width: '100%', marginTop: '30px', position: 'relative', display: 'inline-flex' }}>
                                  <LinearProgress variant="determinate" value={uploadedProgress} sx={{ width: '100%', height: '20px' }} />
                                  <Box
                                    sx={{
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      position: 'absolute',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="caption" component="div" sx={{ fontWeight: 700, color: '#fff' }}>
                                      {`${Math.round(uploadedProgress)}%`}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              {(DEMO_VENDORS.includes(vendor) ? item?.images?.length > 0 : item.value && item.value?.split(',')?.length > 0) && (
                                <div className='uploaded-images-wrapper blog'>
                                  <h4>{t("UploadedImages", { count: DEMO_VENDORS.includes(vendor) ? item?.images?.length : item.value?.substring(0, item.value.length - 1).split(',').length })}</h4>
                                  <Swiper
                                    spaceBetween={32}
                                    slidesPerView={5}
                                    breakpoints={{
                                      320: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      450: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                      },
                                      680: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                      },
                                      1000: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                      },
                                      1250: {
                                        slidesPerView: 5,
                                        spaceBetween: 20,
                                      },
                                    }}
                                    scrollbar={{ draggable: true, el: ".swiper-scrollbar" }}
                                    modules={[Scrollbar, Keyboard]}
                                    keyboard={{ enabled: true }}
                                    speed={2600}>
                                    {(DEMO_VENDORS.includes(vendor) ? item?.images : item.value?.substring(0, item.value.length - 1)?.split(','))?.map((image, index) => (
                                      <SwiperSlide key={index} className='image-item'>
                                        <span
                                          className='del-icon'
                                          onClick={() => handleDeleteImage(index, item, image)}
                                        >
                                          <Trash />
                                        </span>
                                      
                                        <img src={image} alt={`imge${index}`} style={{ borderEndEndRadius: 0, borderEndStartRadius: 0 }} />
                                        {!DEMO_VENDORS.includes(vendor) && (
                                          <div className='col-12 mb-3 p-0 '>
                                            <TextField
                                              placeholder={t("ImageDescription")}
                                              sx={{ width: "100%", fontSize: '13px' }}
                                              autoComplete="false"
                                              onChange={(e) => onGalleryTitleChange(e, item.value, item.id, index)}
                                              value={getGalleryTitle(item.value, index)}
                                              size='small'
                                            />
                                          </div>
                                        )}
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  <div className='swiper-scrollbar'></div>
                                </div>
                              )}
                            </div>
                          } />
                      );
                    }
                    else if (item.type === 'line') {
                      return (
                        <ElementWrapper
                          key={item.id}
                          index={index}
                          moveCard={moveCard}
                          openAddNewElementModal={openAddNewElementModal}
                          setOpenAddNewElementModal={setOpenAddNewElementModal}
                          element={item}
                          childern={
                            <hr style={{ borderBottom: '2px solid var(--main-color-one)' }} />
                          }
                        />);
                    }
                    return null;
                  })
                }
              </div>
            </DndProvider>
          </div>
        </div>
         
      </div>

      <div className="text-center pb-5 mb-5">
        <LoadingButton
          label={t("Save")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={isSubmittingData}
        />
      </div>
      <AddElementsModal
        openAddNewElementModal={openAddNewElementModal}
        setOpenAddNewElementModal={setOpenAddNewElementModal}
      />
      <Fab size="large" color="primary" onClick={handleAddNewElement} aria-label={t("AddANewElement")}
        style={{
          position: 'sticky',
          bottom: '30px',
          left: '40px',
          borderRadius: '100%',
          marginBottom: '50px'
        }}>
        <Plus />
      </Fab>
    </>
  );
}

export default AddOrUpdateNews;
