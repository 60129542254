import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as userApi from "../../../api/userApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import AppLayout from "../../../components/layouts/app-layout";
import { Alert, Box, FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { preventString, resolvePersianAndArabicNumbers, validateEmail, validatePhone,getDemoSnakbar } from "../../../helpers";
import { countryCodes, DEMO_VENDORS } from "../../../constants";
import SubscriptionPopup from "../../../components/user-components/subscription-popup";
import { useTranslation } from "react-i18next";

function AddAgentForm(props) {
  const { openSubscriptionModal, setOpenSubscriptionModal } = props;
  const { t } = useTranslation();

  return (
    <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
      <form onSubmit={props.onSubmit}>
        <div className='container' >
          <h4 className="page-heading">{t("AddAgent")}</h4>
          <div className='col-12 col-md-6 col-lg-12 mb-4 p-0 mt-4'
            dir="rtl">
            <FormControl
              error={props.errors.name && Boolean(props.errors.name)}
              className="col-12" dir="rtl"
            >
              <TextField
                id='name'
                label={t("Name")}
                size="small"
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%' }}
                onChange={props.onChange}
                name='name'
                value={props.user.name}
                error={props.errors.name && Boolean(props.errors.name)}
              />
              {props.errors.name && Boolean(props.errors.name) &&
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {props.errors.name}
                  </Alert>
                </FormHelperText>
              }
            </FormControl>
          </div>
          <div className="col-12 col-md-6 col-lg-12 mb-4 p-0" dir="ltr">
            <FormControl
              error={props.errors.email && Boolean(props.errors.email)}
              className="col-12" dir="rtl"
            >
              <TextField
                id='email'
                label={t("Email")}
                onChange={props.onChange}
                name='email'
                size="small"
                InputLabelProps={{ shrink: true }}
                sx={{ width: '100%' }}
                value={props.user.email}
                error={props.errors.email}
              />
              {props.errors.email && Boolean(props.errors.email) &&
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {props.errors.email}
                  </Alert>
                </FormHelperText>
              }
            </FormControl>
          </div>
          <div className="row p-0 col-12 col-md-6 col-lg-12 mx-0 mb-4" dir="ltr">
            <div className='col-12 col-sm-3 pr-sm-2 pl-sm-0 p-0'>
              <div className='form-group'>
                <div className='field' dir='rtl'>
                  <Select
                    id='countryCode'
                    name='countryCode'
                    onChange={props.onChange}
                    value={props.user.countryCode || ""}
                    className='sortDDL withBg h-35'>
                    {countryCodes.map(item => (<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>))}
                  </Select>
                </div>
                {props.errors.countryCode && (
                  <div className='alert alert-danger'>
                    {props.errors.countryCode}
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-sm-9 pl-sm-2 pr-sm-0 p-0'>
              <FormControl
                error={props.errors.phone && Boolean(props.errors.phone)}
                className="col-12" dir="rtl"
              >
                <TextField
                  id='phone'
                  label={t("PhoneNumber")}
                  name='phone'
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{ width: '100%' }}
                  onChange={props.onChange}
                  value={props.user.phone}
                  error={props.errors.phone && Boolean(props.errors.phone)}
                />
                {props.errors.phone && Boolean(props.errors.phone) &&
                  <FormHelperText component={Box}>
                    <Alert severity="error" className="custom-alert">
                      {props.errors.phone}
                    </Alert>
                  </FormHelperText>
                }
              </FormControl>
            </div>
          </div>

          <div className='col-12 p-0'>
            <SubscriptionPopup
              setOpenSubscriptionModal={setOpenSubscriptionModal}
              openSubscriptionModal={openSubscriptionModal}
              desc={t("YouCannotAddANewAgent")}
              trigger={
                <LoadingButton
                  classes="primary-btn d-flex  justify-content-center py-2 mt-2 px-5"
                  label={t("AddAnAccount")}
                  style={{ width: "fit-content" }}
                  type="submit"
                  loading={props.loading}
                />
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}

AddAgentForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

function AddAgent() {
  const { token, vendor } = useAuth();
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  let publicUrl = process.env.REACT_APP_URL + "/";
  const [loadingButton, setLoadingButton] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const { t } = useTranslation();

  const [user, setUser] = useState({
    superUserId: token,
    countryCode: "+966",
    phone: "",
    name: "",
    email: "",
    language: "0",
  });

  function formIsValid() {
    const _errors = {};

    if (!user.countryCode) _errors.countryCode = t("ChooseCountryCode");
    if (!user.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(user.phone))
      _errors.phone = t("PhoneNumberIsIncorrect");
    if (!user.name) _errors.name = t("EnterName");
    if (user.email && !validateEmail(user.email))
      _errors.email = "الرجاء ادخال بريد إلكتروني صحيح";
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      userApi.canAddAgent({ userId: token, language: '0' }).then((result) => {
        if (result.resCode === 0) {
          if (result.response.result === '1') {
            userApi.addAgent({ ...user, phone: user.phone, email: user.email }).then((_userInfo) => {
              if (_userInfo.resCode === 0) {
                if (vendor === 'amakkn')
                  history("/otp-agent/" + user.countryCode + "/" + user.phone);
                else
                  history("/admin/otp-agent/" + user.countryCode + "/" + user.phone);
      
                if (_userInfo.resStr !== "success") toast.success(_userInfo.resStr);
              } else {
                const _errors = {};
                _errors.phone = _userInfo.resStr;
                setErrors(_errors);
              }
              setLoadingButton(false);
            }).catch(e => setLoadingButton(false));
          } else {
            setOpenSubscriptionModal(true);
            setLoadingButton(false);
          }
        } else {
          toast.error(result.resStr);
          setLoadingButton(false);
        }
      });
    }
  }

  function handleChange({ target }) {
    let value = target.value;
    if (target.name === 'phone') value = preventString(value);
    else {
      value = resolvePersianAndArabicNumbers(value);
    }
    setUser({
      ...user,
      [target.name]: value,
    });
  }

  return (
    <AppLayout needAuth pageTitle="إضافة حساب فرعي" withoutNav={vendor !== 'amakkn'}>

      {token &&
        <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width`}>
          <div className="row page-wrapper-without-top">
            <div className="col-12 col-lg-6 details-wrapper scrolled">
              <AddAgentForm
                errors={errors}
                user={user}
                onChange={handleChange}
                onSubmit={handleSubmit}
                loading={loadingButton}
                openSubscriptionModal={openSubscriptionModal}
                setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
            </div>
            <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
              <div className="img-container">
                <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
              </div>
            </div>
          </div>
          {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
        </div>
      }
    </AppLayout>
  );
}

export default AddAgent;
