import React, { useRef,useEffect, useState } from "react";
import TemplateChooser from "../../../components/template-chooser";
import { getWLBasicConfig } from "../../../api/userApi";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useTranslation } from "react-i18next";

const Templates = () => {
  const childRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [ItemSelected, setItemSelected] = useState("0");
  const { token, vendor } = useAuth();
  const [loadingButton, setLoadingButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (vendor) {
      getWLBasicConfig({ userName: vendor, userId: token, language: '1' }).then(res => {
        setItemSelected(res.response.templateId.toString());
      });
    }
  }, [vendor, token]);

  return (
    <>
      <SubHeader
        onOK={() => { childRef.current.onSubmit(); }}
        OKdisabled={ItemSelected === "0" || !editMode ? true : false}
        loading={loadingButton}
      />
      <TemplateChooser setLoading={setLoadingButton} ItemSelected={ItemSelected} setEditMode={setEditMode} setItemSelected={setItemSelected} ref={childRef} />
      <div className="py-5">
        <LoadingButton
          classes='primary-btn px-5 mx-auto mb-5'
          style={{ justifyContent: 'center' }}
          OKdisabled={ItemSelected === "0" || !editMode ? true : false}
          handleClick={() => { childRef.current.onSubmit(); }}
          label={t("SaveChanges")}
          loading={loadingButton}
        />
      </div>
    </>
  );
};

export default Templates;
