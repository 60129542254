import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import UploadImage from "../global-components/upload-image";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { resolvePersianAndArabicNumbers } from "../../helpers";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

const BannerV3 = (props) => {

  const { logo, token, vendor, templateId}=useAuth()
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +vendor +"/");
  const { t } = useTranslation();
  const [Data, setData] = useState(props.data);
  const [ImageTOView, setImageTOView] = useState(
    publicUrl + "assets/img/banner/1.jpg?" + Math.random()
  );
  
  useEffect(() => {
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }

    const homeStorage = localStorage.getItem(`home-${vendor}`);
    if (homeStorage) {
      setImageTOView(homeStorage || publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    }


  },// eslint-disable-next-line
    [vendor]);
  // const inlineStyle = {
  //   backgroundImage: "url(" + publicUrl + "assets/img/bg/3.png)",
  // };
  // const $ = window.$;
  // const { location, setLocation } = useLocation(
  //   sessionStorage.getItem("location")
  // );
  // if ($(".single-select").length) {
  //   $(".single-select").niceSelect();
  // }
  // let loc = [];

  // if (location) loc = location.split("//");

  // const [search, setSearch] = useState({
  //   propertyType: "all",
  //   listedFor: "2",
  //   lat: location ? loc[1] : "24.74772941029266",
  //   long: location ? loc[2] : "46.619290983645406",
  //   placeholder: location ? loc[0] : "",
  // });

  // useEffect(() => {
  //   if (!location)
  //     getLocation().then((_DefLocation) => {
  //       let city =
  //         _DefLocation.city === "Riyadh" ? t("Riyadh") : _DefLocation.city;
  //       setSearch({
  //         ...search,
  //         lat: _DefLocation.latitude,
  //         long: _DefLocation.longitude,
  //         placeholder: city,
  //       });

  //       setLocation(
  //         city + "//" + _DefLocation.latitude + "//" + _DefLocation.longitude
  //       );
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

 

  const inlineStyle = {
    background: "url(" + ImageTOView + ") center no-repeat",
    backgroundSize: "cover",
    // backgroundSize: ImageTOView.includes("home-01.svg")||ImageTOView.includes("home-02.svg")||ImageTOView.includes("home-03.svg")? 'contain':'cover',
    backgroundAttachment: "fixed",
  };

  function changeImage(img) {
    setImageTOView(img ? img : publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    // props.saveDetails({ imageUrl: img, data: Data });
  }

  return (
    <>
      <div
        className={`banner-area2 banner-area position-relative ${MODERN_TEMPLATES.includes(+templateId) ? 'template-3__banner': ''} ${props.isEdit ? "admin" : "banner-area3"}`}
        style={inlineStyle}>
        {props.isEdit && (
          // <UploadImage2
          //   className="upload-button"
          //   imgUrl='/assets/img/banner/home-01.svg'
          //   imgSource='/assets/img/banner/'
          //   goNext={(image) => {
          //     setData({ ...Data, imageUrl: image });
          //     setImageTOView(image);
          //     props.saveDetails({ data: { ...Data, imageUrl: image } });
          //    }}
          // />
          <UploadImage
            token={token}
            imgUrl='/assets/img/banner/1.jpg'
            imgSource='assets/img/banner/'
            section="home"
            goNext={(e) => changeImage(e.base64Data)}
          />
        )}
        {/* <div className="position-absolute w-100 h-100">
      
       {ImageTOView.indexOf('home-01.svg')>-1 ? <Home1/> : ImageTOView.indexOf('home-02.svg')>-1 ? <Home2/> :ImageTOView.indexOf('home-03.svg')>-1 ? <Home3/>:<></>}
       </div> */}
        <div className='container'>
          <div className='banner-inner-wrap'>
            <div className='row'>
              <div className='col-12'>
                <div className='banner-inner'>
                  {props.isEdit ? (
                    <div className='editSection'>
                      <div className='sub-title text-align-last-center'>
                        <TextField
                          label='العنوان الرئيسي'
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          sx={{ direction: "rtl", textAlign: "right" }}
                          variant='outlined'
                          value={Data.title1}
                          onChange={(v) => {
                            setData({
                              ...Data,
                              title1: resolvePersianAndArabicNumbers(
                                v.currentTarget.value
                              ),
                            });
                          }}
                        />
                      </div>
                      <div className='title text-align-last-center'>
                        <TextField
                          label='العنوان الثانوي'
                          variant='outlined'
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          multiline
                          sx={{ direction: "rtl", textAlign: "right" }}
                          rows={3}
                          value={Data.title2}
                          onChange={(v) => {
                            setData({
                              ...Data,
                              title2: resolvePersianAndArabicNumbers(
                                v.currentTarget.value
                              ),
                            });
                          }}
                        />
                      </div>
                      <div className='mt-4 w-100'>
                        <LoadingButton
                          classes='primary-btn px-3 m-auto'
                          handleClick={() => props.saveDetails({ data: Data })}
                          label={t("SaveChanges")}
                          loading={props.loading}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div data-aos='zoom-in' className='content'>
                        {MODERN_TEMPLATES.includes(+templateId) ? null : <img
                          src={logo}
                          alt='logo'
                          style={{ maxHeight: "65px" }}
                        />}
                        
                        <h3 className='sub-title text-align-last-center mt-3 mb-4'>
                          {Data.title1}
                        </h3>
                        <h1
                          className={`title ${MODERN_TEMPLATES.includes(+templateId) ? 'mb-5 text-center' : 'mb-3 pt-4 text-align-last-center'}`}
                          style={{
                            borderTop: MODERN_TEMPLATES.includes(+templateId) ? 0 : "1px solid var(--main-color-two)",
                          }}>
                          {Data.title2}
                        </h1>
                        <Link
                          to='/properties'
                          className={`${MODERN_TEMPLATES.includes(+templateId) ? 'primary-btn d-flex align-items-center' : 'px-4 py-2 primary-btn'}`}>
                          {MODERN_TEMPLATES.includes(+templateId) ? <ArrowRightAltIcon sx={{ transform: 'rotate(-180deg)',fontSize:30 }} /> : null}
                          تصفّح إعلاتنا العقاريّة
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerV3;
