import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {
  checkDomainAvailabilityWithAWS,
  editWLDomain,
  getUserProile,
  registerUserForWL,
  setUserPlan,
  upgradePlan
} from "../api/userApi";
import { confirmAlert } from "react-confirm-alert";
import Loadingdata from "./global-components/loading-data";
import { useAuth } from "../helpers/context";
import { acceptNumbersAndStrings, getDemoSnakbar } from "../helpers";
import { toast } from "react-toastify";
import LoadingButton from "./global-components/loading-btn";
import { ArrowBack, Check } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { checkPromo } from "../api/propertyApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveOrder } from "../api/generalAPI";
import {DEMO_VENDORS, blackListDomains } from "../constants";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { GreenCheck } from "../constants/icons";
import { useTranslation } from "react-i18next";
import ReservedDomainInfo from "./reserved-domain-info";

let publicUrl = process.env.REACT_APP_URL + "/";

const DomainChooser = (props) => {
  const { token, UserId, setPaymentData, paymentData, vendor } = useAuth();
  const [Domain, setDomain] = useState("");
  const [domainNameWithExt, setDomainNameWithExt] = useState("");
  const [DomainsList, setDomainsList] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(-1);
  const [loadingCouponButton, setLoadingCouponButton] = useState(false);
  // const [isSubmittingButton, setIsSubmittingButton] = useState(false);
  const [coupon, setCoupon] = useState({
    value: "",
    isValid: "",
    discount: "",
  });
  const [index, setIndex] = useState("");
  const isAdmin = window.location.href.includes("admin");
  const [openMoyasarForm, setOpenMoyasarForm] = useState(false);
  const [loadingUpgradePlan, setLoadingUpgradePlan] = useState(false);
  const [ignoredDomin, setIgnoredDomin] = useState("");
  const [searchParams] = useSearchParams();
  const isFromWebview = props.isFromWebview || false;
  const [openDemoSnackBar, setOpenDemoSnackBar] = useState(false);
  const { isTrial, currentPlan } = props;
  const [user, setUser] = useState();
  const history = useNavigate();
  const domainRef = useRef();
  const { t } = useTranslation();
  const [domainInfoDialog, setDomainInfoDialog] = useState({visible:false,domain:''});

  useEffect(() => {
    getUserProile({ userId: token, language: "0" }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setUser(_userInfo.response.userProfile);
      }
    });
  }, [token]);

  useEffect(
    () => {
      if (
        searchParams.get('id') &&
        searchParams.get('Domain') &&
        searchParams.get('domainNameWithExt')
      ) {
        if (paymentData?.Domain) setDomain(paymentData?.Domain);
        if (paymentData?.coupon) {
          setCoupon(paymentData?.coupon);
        }
        if (paymentData?.domainNameWithExt)
          setDomainNameWithExt(paymentData?.domainNameWithExt);
        if (paymentData?.index) setIndex(+paymentData?.index);
        setTimeout(() => {
          setPaymentData(undefined);
        }, 3000);
      }
    }, // eslint-disable-next-line
    [searchParams]
  );

  useEffect(() => {
    setError("");
    if (isLoading !== -1) {
      var elem = document.getElementById("timer"),
        timeout;

      clearTimeout(timeout);
      if (elem)
        elem.textContent = -1;

      var startTimer = function timer() {
        if (elem)
          elem.textContent++;
        timeout = setTimeout(timer, 1000);
        if (elem?.textContent === "2" && !searchParams.get('status') !== 'paid') checkDomain();
        if (elem?.textContent === "5") setisLoading(0);
      };

      startTimer();
    }
    setisLoading(1);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Domain, token]);

  function checkDomain() {
    if (Domain)
      checkDomainAvailabilityWithAWS({
        domainNameWithoutExtension: Domain,
        userId: token,
        language: "0",
      }).then((_items) => {
        if (_items.resCode === 0) {
          setDomainsList(_items.response);
          setError("");
        } else {
          setError(_items.resStr);
        }
      });
  }
  function registerDomain() {
    let body = {
      language: "0",
      userName: Domain,
      domainNameWithExtension: domainNameWithExt,
      userId: token,
      planId: props.selectedPlan?.id,
    };
    if (isAdmin) {
      editWLDomain(body).then((_items) => {
        if (_items.resCode === 0) {
          toast.success("تم تغيير النطاق بنجاح");
          setTimeout(() => {
            window.location.replace("https://" + Domain + ".amakkn.com");
          }, 700);
        } else {
          setError(_items.resStr);
        }
      });
    } else {
      registerUserForWL({ ...body, isTrialVersion: isTrial ? '1' : '0' }).then((_items) => {
        if (_items.resCode === 0) {
          let data = _items?.response?.wlUser;
          let path =
            "https://" +
            data?.userName +
            ".amakkn.com/minasati-step2/" +
            token +
            "?successModal";
          window.location.replace(path);
        } else {
          setError(_items.resStr);
        }
      });
    }
  }
  function registerAmakknDomain() {
    if (Domain) {
      let body = {
        language: "0",
        userName: Domain,
        domainNameWithExtension: "",
        userId: token,
        planId: props.selectedPlan?.id,
      };
      if (isAdmin) {
        editWLDomain(body).then((_items) => {
          if (_items.resCode === 0) {
            toast.success("تم تغيير النطاق بنجاح");
            setTimeout(() => {
              window.location.replace("https://" + Domain + ".amakkn.com");
            }, 700);
          } else {
            setError(_items.resStr);
          }
        });
      } else {
        registerUserForWL({ ...body, isTrialVersion: isTrial ? '1' : '0' }).then((_items) => {
          if (_items.resCode === 0) {
            let data = _items?.response?.wlUser;
            let path =
              "https://" +
              data?.userName +
              ".amakkn.com/minasati-step2/" +
              token;
            window.location.replace(path);
          } else {
            setError(_items.resStr);
          }
        });
      }
    } else {
      setError("يرجى ادخال اسم النطاق");
    }
  }

  const applyCouponCode = () => {
    if (coupon && coupon.value) {
      setLoadingCouponButton(true);
      checkPromo({
        userId: UserId,
        code: coupon.value,
      })
        .then((res) => {
          if (res.resCode === 0) {
            setCoupon({
              ...coupon,
              discount: res.response.discount,
              isValid: res.response.isValid,
            });
            if (res.response.isValid === "0")
              toast.error("كود الخصم المُدخل مُنتهي الصلاحيّة");
          } else {
            toast.error(res.resStr);
          }
          setLoadingCouponButton(false);
        })
        .catch((e) => setLoadingCouponButton(false));
    } else {
      toast.error("قم بادخال كود الخصم");
    }
  };

  useEffect(
    () => {
      if (openMoyasarForm)
        setTimeout(() => {
          window?.Moyasar?.init({
            element: ".mysr-form",
            language: "ar",
            amount: isTrial ? parseFloat(100).toFixed(2) :
              coupon.isValid
                ? (
                  ((+props?.selectedNormalPlan?.price || 0) -
                    ((+props?.selectedNormalPlan?.price || 0) *
                      parseFloat(coupon.discount)) /
                    100) *
                  100
                ).toFixed(2)
                : parseFloat((+props?.selectedNormalPlan?.price || 0) * 100).toFixed(2),
            currency: "SAR",
            description: `${props?.selectedNormalPlan?.name ? `اشتراك بخطّة ${props?.selectedNormalPlan?.name}` : ''}  | ${user?.name} | ${user?.phone} | ${user?.userId} | ${coupon?.value}`,
            publishable_api_key: process.env.REACT_APP_MOYASAR_KEY,
            // "pk_live_YyU1CG3AbLfLgAeMb4NetZzyFhYCFFPqVVBVe2wJ",
            callback_url: props.selectedNormalPlan ?
              `${publicUrl}${isFromWebview ? 'plan-registration' : 'plans-and-prices'}?Domain=${Domain}&wlPlanId=${props.selectedPlan?.id}&domainNameWithExt=${domainNameWithExt}&planId=${props.selectedNormalPlan?.id}&isTrial=${isTrial}${searchParams.get('dark') === "true" ? '?dark=true' : ""}` :
              `${publicUrl}${isFromWebview ? 'plan-registration' : 'plans-and-prices'}?Domain=${Domain}&wlPlanId=${props.selectedPlan?.id}&domainNameWithExt=${domainNameWithExt}&isTrial=${isTrial}${searchParams.get('dark') === "true" ? '?dark=true' : ""}`,
            methods: ["creditcard"], // 'applepay'],
            // apple_pay: {
            //   country: 'SA',
            //   label: `شراء الخطّة (${props.selectedPlan?.name}) مع النطاق (${domainNameWithExt})`,
            //   validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
            // },
            credit_card: {
              save_card: true,
            },
            on_completed: function (payment) {
              return new Promise(function (resolve, reject) {
                saveOrder({
                  // oldWlPlan: +currentPlan?.planId === 9 ? 7 : +currentPlan?.planId === 10 ? 6 : +currentPlan?.planId === 11 ? 9 : +currentPlan?.planId === 12 ? 8 : '',
                  // oldNormalPlan: currentPlan?.planId || '',
                  // oldWlDate: [9, 10, 11, 12].includes(+currentPlan?.planId) ? currentPlan?.endDate : '',
                  // oldNormalDate: currentPlan?.endDate || '',
                  // paymentAmoutWl: props?.selectedPlan ? (coupon.isValid
                  //   ? ((+props?.selectedPlan?.price - ((+props?.selectedPlan?.price) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2)
                  //   : parseFloat(+props?.selectedPlan?.price * 100).toFixed(2)) : '',
                  // paymentAmountBeforeDiscountWl: +props?.selectedPlan?.price ? +props?.selectedPlan?.price * 100 : '',
                  // discountAmountWl: coupon.isValid && props?.selectedPlan ? (((+props?.selectedPlan?.price * parseFloat(coupon.discount)) / 100) * 100).toFixed(2) : '',
                  // paymentAmoutNormal: props?.selectedNormalPlan ? (coupon.isValid
                  //   ? ((+props?.selectedNormalPlan?.price - ((+props?.selectedNormalPlan?.price) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2)
                  //   : parseFloat(+props?.selectedNormalPlan?.price * 100).toFixed(2)) : '',
                  // paymentAmountBeforeDiscountNormal: +props?.selectedNormalPlan?.price ? +props?.selectedNormalPlan?.price * 100 : '',
                  // discountAmountNormal: coupon.isValid && props?.selectedNormalPlan ? (((+props?.selectedNormalPlan?.price * parseFloat(coupon.discount)) / 100) * 100).toFixed(2) : '',
                  "paymentId": payment?.id,
                  "paymentToken": payment?.source?.token || "-1",
                  "paymentStatus": payment?.status,
                  "paymentAmount": payment?.amount,
                  "paymentMessage": " ",
                  "userId": token,
                  "domain": domainNameWithExt || "-1",
                  "status": "InProgress",
                  "language": "0",
                  "promoCodeId": coupon.isValid ? coupon.value : "-1",
                  "isTrialVersion": isTrial ? '1' : '0',
                  "paymentAmountBeforeDiscount": +props?.selectedNormalPlan?.price ? +props?.selectedNormalPlan?.price * 100 : 0,
                  "discountAmount": coupon.isValid ? ((((+props?.selectedNormalPlan?.price || 0) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2) : "-1",
                  "userPlanId": props.selectedNormalPlan?.id || undefined,
                  // "wlPlan": props.selectedPlan?.id || undefined
                })
                  .then((res) => {
                    resolve({ payment });
                  })
                  .catch((e) => {
                    toast.error("لم يتم الدفع, الرجاء المحاولة لاحقاً");
                    reject();
                  });
              });
            },
          });
          setPaymentData({
            selectedPlan: props.selectedPlan,
            selectedNormalPlan: props.selectedNormalPlan,
            coupon: coupon,
            domainNameWithExt: domainNameWithExt,
            Domain: Domain,
            index: index,
          });
        }, 200);
    }, // eslint-disable-next-line
    [openMoyasarForm]
  );

  const openConfirmPopup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui' style={props.from === "admin" ? {} : { maxHeight: '80vh', overflow: 'auto', direction: 'rtl' }}>
            {props.from === "admin" ? (
              <>
                <h3>
                  تأكيد حجز النطاق
                </h3>
                <h5 dir='rtl'>
                  هل أنت متأكد من حجز النطاق ({domainNameWithExt})
                </h5>
              </>
            ) : (
              <>
                <h3>الموافقة على اتفاقية استخدام اماكن برو</h3>
                <p className="my-4">شكرًا لاختيارك <b>أماكن برو</b>. من خلال الاشتراك في هذه الخدمة، أنت <b>توافق</b> على الالتزام بالشروط والأحكام الآتية:</p>
                <ul className='mt-3 pb-3'>
                  <li className='mb-3'>
                    <b>التعريف بالخدمة</b>: اماكن برو هي خدمة مقدمة من <b>منصة أماكن</b> تتيح لك إنشاء وإدارة موقع عقاري بالنطاق المخصص (<b>{domainNameWithExt}</b>) للتسويق عن اعمالك وعرض اعلاناتك العقاريّة المرخصة.
                  </li>
                  <li className='mb-3'>
                    <b>ملكية المواقع والنطاقات</b>: جميع المواقع والنطاقات التي تنشئ من خلال اماكن برو مملوكة لمنصة أماكن وتتيح هذه الاتفاقية للمستخدم الانتفاع منها ما دام مشتركاُ الا في حال الاخلال بشروط هذه الاتفاقية او اتفاقية استخدام منصة أماكن.
                  </li>
                  <li className='mb-3'>
                    <b>اشتراطات الهيئة العامة للعقار</b>
                    <ul className='mb-4 mt-1'>
                      <li>هذه الخدمة مصرحة وخاضعة لرقابة الهيئة العامة للعقار.</li>
                      <li>تعديل المحتوى و إضافة الإعلانات العقاريّة المرخصة على الموقع (<b>{domainNameWithExt}</b>) يكون متاح حصراً لـ (<b>{user.name}</b>) المسجلة بالرقم الوطني الموحد\هوية وطنية (<b>{user.accountType === "2" ? user.commercialRecordNumber : user.nationalid}</b>)</li>
                      <li>إضافة الإعلانات العقاريّة على الموقع تشترط وجود ترخيص إعلان عقاري فعال مربوط بالرقم الوطني الموحد\الهوية الوطنية (<b>{user.accountType === "2" ? user.commercialRecordNumber : user.nationalid}</b>) وصادر عن طريق منصة الهيئة العامة للعقار.</li>
                      <li>تسجيل النطاق لدى المركز السعودي للأعمال خلال 14 يوم.</li>
                      <li>الامتثال لأي تشريعات قد تطرأ مستقبلاً من الجهات ذات العلاقة.</li>
                    </ul>
                  </li>
                  <li className='mb-3'>
                    <b>الرسوم والتجديد</b>
                    <ul className='mb-4 mt-1'>
                      <li>تُطبق رسوم اشتراك شهرية أو سنوية وفقًا للخطة المختارة.</li>
                      <li>تجدد الاشتراكات تلقائيًا في نهاية كل فترة اشتراك او في حال انتهاء الفترة التجريبية ما لم يقوم المستخدم بإلغاء الاشتراك.</li>
                      <li>يمكن إلغاء اشتراك "اماكن برو" في أي وقت، ولكن الرسوم المدفوعة مقدمًا غير قابلة للاسترداد.</li>
                    </ul>
                  </li>
                  <li className='mb-3'>
                    <b>التعديلات على الخدمة والشروط</b>
                    <ul className='mb-4 mt-1'>
                      <li>تحتفظ "أماكن" بالحق في تعديل أو إنهاء خدمة "اماكن برو" أو تغيير الرسوم دون إشعار مسبق.</li>
                      <li>أي تغييرات في شروط الخدمة تصبح سارية المفعول بمجرد نشرها في هذه الصفحة.</li>
                    </ul>
                  </li>
             
                </ul>
                <hr className="mt-5 pt-4 mb-0 pb-0" />
                <p className="p-0 m-0" dir="rtl" style={{ color: '#d00404', fontWeight: 'bold' }}>
                  باستخدامك لخدمة "اماكن برو"، أنت تقر وتوافق على الشروط والأحكام المذكورة أعلاه و
                  <a
                    href='https://www.amakkn.com/Terms'
                    rel='noreferrer'
                    target='_blank'
                    style={{ textDecoration: "underline", marginRight: "4px" }}>
                    اتفاقية استخدام منصة اماكن
                  </a>.
                  <p className="p-0 mt-3 mb-4" dir="rtl" style={{ color: '#d00404', fontWeight: 'bold' }}>
                    و بضغطك على زر استمرار فأنت تؤكد على شراء الخطّة ({props.selectedNormalPlan?.name}) مع النطاق ({domainNameWithExt}).
                  </p>
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  نقدر ثقتك بأماكن ونعدك بتقديم خدمة استثنائية تلبي احتياجاتك العقاريّة.
                </p>
              </>
            )}
           
         
            <div className='btns-wrapper' dir="ltr">
              <LoadingButton
                loading={loadingUpgradePlan}
                classes='primary-btn px-4'
                handleClick={() => {
                  if (
                    !(
                      (props.from !== "admin" &&
                        (+props?.selectedNormalPlan?.price || 0) === 0) ||
                      props.from === "admin" ||
                      (coupon.isValid &&
                        (
                          (+props?.selectedNormalPlan?.price || 0) -
                          ((+props?.selectedNormalPlan?.price || 0) *
                            parseFloat(coupon.discount)) /
                          100
                        )
                          .toFixed(1)
                          .toString() === "0.0")
                    )
                  ) {
                    if ([5, 6].includes(+currentPlan?.planId)) {
                      setLoadingUpgradePlan(true);
                      upgradePlan({
                        userId: token,
                        planId: props?.selectedNormalPlan?.id,
                        domain: domainNameWithExt || "-1"
                      }).then(res => {
                        if (res.resCode === 0) {
                          if (+res.response.result !== 1) {
                            if (+res.response.result === 0)
                              toast.error(res.response.message);
                            setOpenMoyasarForm(true);
                          } else {
                            Swal.fire({
                              title: "تمّ الاشتراك بنجاح",
                              text: `تمّت ترقية الاشتراك بنجاح`,
                              icon: "success",
                              confirmButtonText: t("Close"),
                            });
                            history(`/my-subscription${isFromWebview ? `/${token}` : ''}`);
                          }
                        } else {
                          toast.error(res.resStr);
                          setOpenMoyasarForm(true);
                        }
                      }).finally(() => setLoadingUpgradePlan(false));
                    } else {
                      setOpenMoyasarForm(true);
                    }
                  } else {
                    if (+props?.selectedNormalPlan?.id) {
                      setUserPlan({
                        userId: token,
                        planId: props?.selectedNormalPlan?.id,
                        isTrialVersion: isTrial ? '1' : '0'
                      }).then((res) => {
                        if (res.resCode === 0) {
                          // subscribe({
                          //   userId: token,
                          //   planId: props.selectedPlan?.id,
                          //   startDate: moment().locale("en").format("YYYY-MM-D"),
                          //   endDate: moment()
                          //     .locale("en")
                          //     .add(isTrial ?
                          //       (props.selectedPlan?.trialDuration ? +props.selectedPlan?.trialDuration : +props.selectedPlan?.duration) :
                          //       +props.selectedPlan?.duration, "day")
                          //     .format("YYYY-MM-D"),
                          //   isTrialVersion: "0",
                          // }).then((result) => {
                          //   if (result.resCode === 0) {
                          //   }
                          // });
                          
                          if (index === 0)
                            registerAmakknDomain();
                          else
                            registerDomain();
                        } else {
                          toast.error(res.resStr);
                        }
                      });
                    } else {
                      if (index === 0)
                        registerAmakknDomain();
                      else
                        registerDomain();
                    }
                  }
                  onClose();
                }}
                label={t("Continue")}
              />
                
              <button
                className='outlined-btn px-3'
                onClick={() => {
                  onClose();
                }}>
                تغيير النطاق
              </button>
            </div>
          </div>
        );
      },
      overlayClassName: "custom-ui-width",
    });
  }

  const renderActions = () => {
    return <>
      {isTrial && domainNameWithExt && (
        <div className='col-12 text-right mt-3 px-0' dir='rtl'>
          <Alert
            variant='standard'
            sx={{
              width: "100%",
              "& .MuiAlert-icon": {
                marginRight: 0,
                marginLeft: "12px",
              },
            }}
            color='info'>
            {+props.selectedPlan?.trialDuration === 0 ? <>
              بضغطك على زر الدفع, سوف يتم حجز النطاق {DomainsList?.[0]?.domain} مؤقتاً لك ريثما يتنهي إعداد النطاق الخاص بك و سوف يتم الاشتراك بالخطّة
              لمدّة {props?.selectedNormalPlan?.duration} يوم.
              <br />
              ويتوجب عليك تجديد الاشتراك أو ترقيّة الخطة قبل إنقضاء هذه الفترة, أو في حال لم تقم بإلغاء خاصيّة التجديد التلقائي سيتم خصم المبلغ من بطاقتك البنكيّة عند انقضاء هذه الفترة
            </> :
              <>
                بضغطك على زر إبدأ بالفترة التجريبيّة, سوف يتم حجز النطاق {DomainsList?.[0]?.domain} مؤقتاً لك ريثما يتنهي إعداد النطاق الخاص بك و سوف يتم الاشتراك بالخطّة
                لمدّة {props?.selectedNormalPlan?.trialDuration} يوم.
                <br />
                ويتوجب عليك تجديد الاشتراك أو ترقيّة الخطة قبل إنقضاء هذه الفترة المجانية, أو في حال لم تقم بإلغاء خاصيّة التجديد التلقائي سيتم خصم المبلغ من بطاقتك البنكيّة عند انقضاء هذه الفترة
              </>}
          </Alert>
        </div>
      )}
      <div className='col-12 mt-5 text-center next-back-btns'>
        <LoadingButton
          classes='btn primary-btn'
          handleClick={() => {
            if (!domainNameWithExt) {
              domainRef?.current?.scrollIntoView({
                behavior: "smooth",
              });
              setError('الرجاء اختيار اسم النطاق الخاص بمنصتك الإلكترونيّة')
            } else {
              if (DEMO_VENDORS.includes(vendor)) {
                setOpenDemoSnackBar(true);
              } else {
                openConfirmPopup();
                // setIsSubmittingButton(true);
                // getBrokerRegaInfo({ licenseNumber: user?.advertiserNumber }).then((res) => {
                //   if (res.resCode === 0) {
                //     if (res.response.isActive === '1') {
                //       openConfirmPopup();
                //     } else {
                //       Swal.fire({
                //         title: "لا يمكن إتمام العمليّة",
                //         text: 'رخصة فال خاصتّك منتهيّة الصلاحيّة',
                //         icon: "error",
                //         confirmButtonText: t("Close"),
                //       });
                //     }
                //   }
                // }).catch(() => openConfirmPopup())
                //   .finally(() => setIsSubmittingButton(false));
              }
            }
         
          }}
          label={
            <>
              {
                (props.from !== "admin" && (+props?.selectedNormalPlan?.price || 0) === 0) ||
                  props.from === "admin" ||
                  (coupon.isValid &&
                    (
                      (+props?.selectedNormalPlan?.price || 0) -
                      ((+props?.selectedNormalPlan?.price || 0) *
                        parseFloat(coupon.discount)) /
                      100
                    )
                      .toFixed(1)
                      .toString() === "0.0")
                  ? t("Next")
                  : props.from === "admin" || +props?.selectedNormalPlan?.trialDuration === 0 ? "الدفع" : "إبدأ الفترة التجريبيّة"}
              <ArrowBack />
            </>
          }
          loading={false}
        />
        {props?.withBack && (
          <button
            className='btn outlined-btn py-4'
            onClick={() => {
              props?.setStep(1);
              setDomain("");
              setCoupon({
                value: "",
                isValid: "",
                discount: "",
              });
              setDomainNameWithExt("");
              setError("");
            }}>
            الخلف <ArrowBack sx={{ transform: "scale(-1)" }} />
          </button>
        )}
      </div>
    </>;
  }

  return (
    <div className='pb-5 domain-page' ref={domainRef}>
      <div className='row mx-0'>
        <div
          className={`col-12 ${props.from !== "admin" ?
            "col-lg-8 py-0 px-lg-2 px-0 mb-2 pr-lg-3 d-flex h-100 flex-column justify-content-between min-height-700"
            : ""
            }`}>
          <div>
            {props.from !== "admin" && <div className="text-right" dir="rtl">
              <h4>اختيار النطاق</h4>
              <p>قم بادخال اسم النطاق الذي تريده لمنصتك باللغة الإنكليزية</p>
            </div>}
            <Paper
              component='form'
              sx={{
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                p: "10px",
                display: "flex",
                alignItems: "center",
              }}>
              <SearchIcon aria-label='search' />
              <InputBase
                sx={{ flex: 1, border: "none !important",textAlign:'left',direction:'rtl',pl:1,pr:1 }}
                autoComplete="off"
                id="domain"
                placeholder='ادخل اسم النطاق'
                onKeyPress={(event) =>
                  (event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122)
                }
                inputProps={{ "aria-label": "ادخل اسم النطاق" }}
                onChange={(e) => {
                  setError('');
                  let value = acceptNumbersAndStrings(
                    e.currentTarget.value.toLowerCase()
                  );
                  if (
                    blackListDomains.filter((item) => item === value)?.length > 0
                  ) {
                    setIgnoredDomin(value);
                  } else {
                    setIgnoredDomin("");
                    setDomain(value);
                    // setCoupon({
                    //   value: "",
                    //   isValid: "",
                    //   discount: "",
                    // });
                    setDomainNameWithExt("");
                    // serDomainPrice(0);
                  }
                }}
                value={Domain}
              />
              {Domain && (
                <>
                  <Divider sx={{ height: 26 }} orientation='vertical' />
                  <IconButton
                    color='primary'
                    onClick={() => {
                      setDomain("");
                      // setCoupon({
                      //   value: "",
                      //   isValid: "",
                      //   discount: "",
                      // });
                      setDomainNameWithExt("");
                      // serDomainPrice(0);
                      setError("");
                      setIgnoredDomin("");
                    }}
                    aria-label='CloseIcon'>
                    <CloseIcon />
                  </IconButton>
                </>
              )}
            </Paper>

            <span style={{ display: "none" }} id='timer'>
              -1
            </span>
            {error && (
              <Alert
                severity='error'
                className='custom-alert w-100 d-flex align-items-center justify-content-between'
                dir='rtl'
                sx={{
                  "& .MuiAlert-message": {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  },
                }}>
                {error}
              </Alert>
            )}
            {ignoredDomin && (
              <Alert severity='error' className='custom-alert w-100' dir='rtl'>
                عذراً, لا يمكنك استخدام هذا النطاق ({ignoredDomin})
              </Alert>
            )}
            {isLoading === 0 ? (
              <>
                {Domain && !error && (
                  <div className='mt-4'>
                    {DomainsList.map((ele, indx) => (//indx !== 0 && 
                      <div
                        key={indx}
                        className={`domain-list ${index === indx && ele.domain === domainNameWithExt
                          ? "selected"
                          : ""
                          }`}>
                        <div className='info-section'>
                          <span style={{ fontSize: 16, fontWeight: 700 }}>
                            {ele.domain}
                          </span>
                          <span
                            style={{ direction: "rtl", fontSize: '14px', color: ele.status === "AVAILABLE" ? 'var(--main-color-one)' : '#d50000' }}
                            className={`${ele.status === "AVAILABLE" && ele.price === "Free"
                              ? "free-label"
                              : ""
                              }`}>
                            {ele.status !== "AVAILABLE" ? t("NotAvailable")
                              : (ele.status === "AVAILABLE" ?
                                (ele.price === "Free"
                                  ? "مجاني"
                                  : 'متوفر')
                                : '')
                            }
                          </span>
                        </div>
                        {ele.status === "AVAILABLE" ? (
                          <button
                            className='primary-btn'
                            onClick={(e) => {
                              if (ele.status === "AVAILABLE") {
                                // if (ele.price !== "Free" ) {
                                //   confirmAlert({
                                //     customUI: ({ onClose }) => {
                                //       return (
                                //         <div className='custom-ui'>
                                //           <h3>العمليّة غير متاحة</h3>
                                //           <h5 dir='rtl'>
                                //             {props.from !== "admin"
                                //               ? `لا يمكنك شراء نطاق مدفوع مع الخطّة  ${props.selectedPlan?.name}`
                                //               : `لا يمكنك اختيار نطاق مدفوع مع الخطّة  ${props.selectedPlan?.name} `}
                                //           </h5>
                                //           <div className='btns-wrapper'>
                                //             <button
                                //               className='outlined-btn px-4'
                                //               onClick={() => {
                                //                 onClose();
                                //               }}>
                                //               {t("Cancel")}
                                //             </button>
                                //             <button
                                //               className='primary-btn px-3'
                                //               onClick={() => {
                                //                 if (props.from !== "admin")
                                //                   props?.setStep(1);
                                //                 else history("/admin/plans");
                                //                 onClose();
                                //                 setDomain("");
                                //                 setCoupon({
                                //                   value: "",
                                //                   isValid: "",
                                //                   discount: "",
                                //                 });
                                //                 setDomainNameWithExt("");
                                //                 // serDomainPrice(0);
                                //                 setError("");
                                //               }}>
                                //               تغيير الخطة
                                //             </button>
                                //           </div>
                                //         </div>
                                //       );
                                //     },
                                //   });
                                // } else {
                                setDomainNameWithExt(ele.domain);
                                setIndex(indx);
                                // serDomainPrice(ele.price === 'Free' ? 0 : ele.price);
                                // }
                              }
                            }}>
                            {index === indx &&
                              ele.domain === domainNameWithExt ? (
                              <Check />
                            ) : (
                              "اختر"
                            )}
                          </button>
                        ) :
                          <></>
                          // <button
                          //   className='primary-btn'
                          //   onClick={(e) => {
                          //     setDomainNameWithExt(ele.domain);
                          //     setIndex(indx);
                          //     setDomainInfoDialog({ visible: true, domain: ele.domain });
                          //   }}>
                          //   {index === indx &&
                          //     ele.domain === domainNameWithExt ? (
                          //     <Check />
                          //   ) : (
                          //     "املك هذا النطاق"
                          //   )}
                          // </button>
                        }
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                {Domain && (
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "none",
                      borderRaduis: 0,
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      padding: "60px 10px",
                    }}>
                    <Table aria-label='customized table' sx={{ border: 0 }}>
                      <TableBody sx={{ textAlign: "center" }}>
                        <TableRow>
                          <TableCell sx={{ border: "0" }}>
                            <Loadingdata />
                            <p className="text-center" dir="rtl">
                              جاري البحث عن نطاق متوفّر...
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}
          </div>
          <div className="d-none d-lg-block">{renderActions()}</div>

        </div>
        {props.from !== "admin" && (
          <div
            className='col-12 col-lg-4'
            style={{
              padding: "14px",
              border: "1px solid #ddd",
              // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
            }}>
            <div className='coupon-box row px-2' dir='rtl'>
               
              <h5 className='col-12 text-right mb-2 pb-3' style={{ borderBottom: "2px solid #eee" }}>ملخص الطلب</h5>
              <p className='coupon-details-row'>
                <span>
                  الاشتراك بخطّة {props?.selectedNormalPlan?.name} / {+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}
                </span>
                {+props?.selectedNormalPlan?.trialDuration !== 0 && (
                  <span style={{ fontSize: '12px', fontWeight: 400 }} className="badge success-outlined">
                    {`${props?.selectedNormalPlan?.trialDuration}-أيام فترة تجريبيّة`}
                  </span>
                )}
              
              </p>
              <p className='coupon-details-row'>
                <span>سعر الاشتراك (شامل ضريبة القيمة المضافة)</span>
                <span
                  style={{ fontSize: 15, fontWeight: 700, textDecoration: coupon?.isValid ? 'line-through #d50000' : "none" }}>
                  {`${(+props?.selectedNormalPlan?.price || 0).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                </span>
              </p>
              <p
                className='my-0 px-2'
                style={{
                  color: 'var(--main-color-one)',
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: 'right',
                  userSelect: 'none'
                }}
              >
                ادخل كود خصم
              </p>
              <div className='col-12 coupon-form p-0'>
                <TextField
                  type='search'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ flex: 1 }}
                  id="promo"
                  autoComplete="off"
                  size='small'
                  label='كود الخصم'
                  onChange={(e) =>
                    setCoupon({ value: e.currentTarget.value })
                  }
                  value={coupon.value}
                />
                <LoadingButton
                  classes='btn primary-btn'
                  style={{ height: "40px" }}
                  handleClick={applyCouponCode}
                  label='تطبيق'
                  // OKdisabled={!domainNameWithExt}
                  loading={loadingCouponButton}
                />
              </div>
              {coupon?.isValid && (
                <Alert
                  variant='standard'
                  icon={false}
                  sx={{
                    width: "100%",
                    border: "1px solid #21B000",
                    '& .MuiAlert-message': {
                      width: '100%'
                    }
                  }}
                  color='success'>
                  <div className="d-flex flex-column text-right w-100" style={{ gap: '10px', direction: "rtl" }}>
                    <span style={{ fontSize: '16px', fontWeight: 600 }}>الخصم</span>
                    <div className="d-flex justify-content-between w-100">
                      <span style={{ fontSize: '13px' }}>{`%${(+coupon.discount).toFixed(0)} ${+props?.selectedNormalPlan?.duration === 365 ? 'لأول سنة' : 'لأول شهر'}`}</span>
                      <span style={{ fontSize: '13px' }}>{`- ${(((+props?.selectedNormalPlan?.price || 0) * parseFloat(coupon.discount)) / 100).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}</span>
                    </div>
                  </div>
                </Alert>
              )}
              <div className='col-12 coupon-details mt-3'>
                {props?.selectedNormalPlan && <p className='coupon-details-row'>
                  <span>المجموع الفرعي</span>
                  <span style={{ fontSize: 15, fontWeight: 700 }}>
                    {`${((((+props?.selectedNormalPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 100)).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                  </span>
                </p>}
                
                <p className='coupon-details-row'>
                  <span>ضريبة القيمة المضافة</span>
                  <span
                    style={{ fontSize: 15, fontWeight: 700 }}>
                    {`${(((+props?.selectedNormalPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 15).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                  </span>
                </p>
                <p className='coupon-details-row'>
                  <span style={{ fontSize: 15, fontWeight: 700 }}>المستحق الآن</span>
                  <span
                    style={{ fontSize: 15, fontWeight: 700 }}>
                    {`${(0).toFixed(2)} ر.س`}
                  </span>
                </p>
                {+props?.selectedNormalPlan?.trialDuration !== 0 && (
                  <p className='coupon-details-row mt-2'>
                    <span>المستحق بعد انتهاء الفترة التجريبيّة, في {moment().add(props?.selectedNormalPlan?.trialDuration || 0, 'days').format('Do MMMM YYYY')}</span>
                    <span
                      style={{ fontSize: 15, fontWeight: 700 }}>
                      {coupon.isValid
                        ? `${(
                          (+props?.selectedNormalPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                        ).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`
                        : `${(+props?.selectedNormalPlan?.price || 0).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                    </span>
                  </p>
                )}
               
                {coupon?.isValid && (
                  <p className='coupon-details-row'>
                    <span>المستحق بعد انتهاء صلاحية الخصم, في {moment().add(+props?.selectedNormalPlan?.duration === 365 ? 12 : 1, 'months').add(isTrial ? +props.selectedPlan?.trialDuration: 0, "day").format('Do MMMM YYYY')}</span>
                    <span
                      style={{ fontSize: 15, fontWeight: 700 }}>
                      {`${(+props?.selectedNormalPlan?.price || 0).toFixed(2)} ر.س/${+props?.selectedNormalPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                    </span>
                  </p>
                )}
              </div>
              {+props?.selectedNormalPlan?.trialDuration !== 0 && (
                <>
                  <h5 className='col-12 text-right mb-2 pb-3 mt-3' style={{ borderBottom: "2px solid #eee" }}>شروط الفترة التجريبيّة</h5>
                  <p className="pricing-card-feature mb-0">
                    <span className='icon'><GreenCheck green /></span>
                    <span>تبدأ عملية الفوترة تلقائيًا بعد انتهاء الفترة التجريبية المجانية</span>
                  </p>
                  <p className="pricing-card-feature mb-0">
                    <span className='icon'><GreenCheck green /></span>
                    <span>قم بالإلغاء قبل {moment().add(props?.selectedNormalPlan?.trialDuration || 0, 'days').format('Do MMMM')} لتجنب تحصيل الرسوم منك</span>
                  </p>
                </>
              )}
              {/* <div style={{ borderTop: "2px solid #ddd" }} />
                <p className='coupon-details-row px-2 text-right'>
                  <span style={{ fontSize: 20, fontWeight: 500 }}>
                    الإجمالي&nbsp;
                    <span style={{ fontSize: 12 }}>
                      (شامل ضريبة القيمة المضافة)
                    </span>
                  </span>
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                      textDecoration: isTrial ? 'line-through' : 'none'
                    }}>
                   
                    {coupon.isValid
                      ? `${(
                        (+props?.selectedNormalPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                      ).toFixed(2)} ر.س`
                      : `${(+props?.selectedNormalPlan?.price || 0).toFixed(2)} ر.س`}
                         
                  </span>
                </p> */}
            </div>
          </div>
        )}
        
        <div className="d-flex w-100 justify-content-center align-items-center d-lg-none flex-column" style={{height:'fit-content'}}>{renderActions()}</div>
      </div>
      <ReservedDomainInfo
        dialog={domainInfoDialog}
        setDialog={setDomainInfoDialog}
      />
      
      <Dialog
        onClose={() => {
          setOpenMoyasarForm(false);
        }}
        maxWidth={"sm"}
        className='custom-dialog'
        style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openMoyasarForm}>
        <DialogTitle>
          <div className='dialog-head mb-4'>
            <div className='dialog-actions close-only'>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setOpenMoyasarForm(false);
                }}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info" dir="rtl" className="text-right"
            style={{
              maxWidth: '340px',
              margin: '0 auto 20px',
            }}
            sx={{
              "& .MuiAlert-icon": {
                marginRight: 0,
                marginLeft: '5px'
              },
              '& .MuiAlert-message': {
                padding: '2px 0'
              }
            }}>
            سيتم خصم مبلغ بقدر 1.00 ريال من بطاقتك البنكيّة, و<b>استرداده</b> لك خلال دقائق
          </Alert>
          <div className='mysr-form'></div>
        </DialogContent>
      </Dialog>

      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
    </div>
  );
}

export default DomainChooser;
