import React, { useEffect, useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import SubHeader from "../../../../components/global-components/sub-header";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { useAuth } from "../../../../helpers/context";
import { toast } from "react-toastify";
import {
  createAd,
  saveLocation,
  validateProperty,
} from "../../../../api/propertyApi";
import { useNavigate } from "react-router-dom";
import ADConstraints from "../../../../components/ad-constraints";
import LoadingButton from "../../../../components/global-components/loading-btn";
import SubscriptionPopup from "../../../../components/user-components/subscription-popup";
import { canAddProperty } from "../../../../api/userApi";
import REGAPropertyInfo from "../../../../components/rega-property-info";
import GoogleMapsContainer from "../../../../components/section-components/registration/GoogleMapsContainer";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
  getDemoSnakbar,
  preventString,
  renderPropertyIcon2,
  renderPropertyTypeName,
  resolvePersianAndArabicNumbers
} from "../../../../helpers";
import {
  AD_CHANNELS,
  AD_PROPERTY_AGES,
  AD_PROPERTY_FACES,
  AD_PROPERTY_TYPES,
  AD_PROPERTY_USAGE,
  AD_PROPERTY_UTILITIES,
  DEMO_VENDORS,
} from "../../../../constants";
import { Building, User } from "../../../../constants/icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useTranslation } from "react-i18next";

  const tabStyle = {
    fontSize: "16px",
    "&.Mui-selected": {
      color: "var(--main-color-one)",
    },
  };

const AddNew = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [errors, setErrors] = useState({});
  const [ADNumber, setADNumber] = useState("");
  const [property, setProperty] = useState();
  const { token, UserType, vendor } = useAuth();
  const history = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingLocationButton, setLoadingLocationButton] = useState(false);
  const { user } = props;
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const { withoutNavbar } = props;
  const [errorMsg, setErrorMsg] = useState('');
  const [createingAD, setCreateingAD] = useState(false);
  const [ADdata, setADdata] = useState({
    advertiserType: "",
    attorneyCode: "",
    AdvertiserID: "",
    brokerageContractNumber: "",
    FullName: '',
    propertyPrice: "",
    advertisementType: "sell",
    channels: [],
    disclaimer: false,
    deedNumber: '',
    deedSerialNumber: "",
    adPhotos: [],
    attorneyFirstId: "",
    attorneySecondId: "",
    streetWidth: "",
    propertyArea: "",
    numberOfRooms: "",
    propertyType: "",
    propertyAge: "",
    propertyFace: "",
    planNumber: "",
    obligationsOnTheProperty: "",
    guaranteesAndTheirDuration: "",
    theBordersAndLengthsOfTheProperty: "",
    complianceWithTheSaudiBuildingCode: false,
    propertyUtilities: [],
    propertyUsages: []
  });
  const [subStep, setSubStep] = useState(1);
  const [ZoomLevel, setZoomLevel] = useState(14);
  const [Location, setLocation] = useState({
    id: null,
    userIdHashed: "",
    address: "",
    latitude: "24.74772941029266",
    longitude: "46.619290983645406",
    isLocationChanged: "0",
    language: "0",
    neighborhood: "",
  });
  let publicUrl = process.env.REACT_APP_URL + "/";
  const disclaimerRef = useRef();
  const channelsRef = useRef();
  const propertyInfoRef = useRef();
  const propertyUsagesRef = useRef();
  const additionalInfoRef = useRef();
  const { t } = useTranslation();

  function Step1IsValid() {
    const _errors = {};
    if (!ADNumber) _errors.ADNumber = t("ThisFieldIsMandatory");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function Step1Substep4IsValid() {
    const _errors = {};
    if (!ADdata.propertyType) _errors.propertyType = "يرجى اختيار نوع العقار";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }
  
  function handleNextClick() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (property.propertyArr?.latitude === '24' && property.propertyArr?.longitude === '24') {
      setCurrentStep(3);
      setCompletedSteps([0, 1, 2]);
    } else {
      let path = "";
      if (withoutNavbar) {
        path =
          "/add-property-step3/" +
          property.propertyArr.propertyId +
          "/" +
          token;
      } else {
        path = "/add-property-step3/" + property.propertyArr.propertyId;
      }
      if (props.isAdmin)
        path = "/admin/property/add-step3/" + property.propertyArr.propertyId;
      history(path);
    }
   
  }



  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document
      .querySelector(".admin-container")
      ?.scrollIntoView({ behavior: "smooth" });
  }, [currentStep, subStep]);

  function handleSaveLocation() {
    if (!Location.address) toast.error(t("PleaseSpecifyTheAddressCorrectly"));
    if (ZoomLevel < 17) {
      toast.error(t("PleaseZoomInForPreciseLocation"));
    } else {
      // confirmAlert(options);
      setLoadingLocationButton(true);
      // have AD Number
      saveLocation({
        userId: token,
        propertyId: property?.propertyArr.propertyId,
        latitude: Location.latitude,
        longitude: Location.longitude,
      }).then((res) => {
        setLoadingLocationButton(false);
        if (res.resCode === 0) {
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
          let path = "";
          if (withoutNavbar) {
            path =
              "/add-property-step3/" +
              property.propertyArr.propertyId +
              "/" +
              token;
          } else {
            path = "/add-property-step3/" + property.propertyArr.propertyId;
          }
          if (props.isAdmin)
            path =
              "/admin/property/add-step3/" + property.propertyArr.propertyId;
          history(path);
        } else {
          toast.error(res.resStr);
        }
      });
      
    }
  }

  const checkProperty = () => {
    if (!Step1IsValid()) return;
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    callAddPropertyStep1API();
  };

  function callAddPropertyStep1API() {
    setLoadingButton(true);
    canAddProperty({ userId: token, language: "0" }).then((result) => {
      if (result.resCode === 0) {
        if (result.response.result === "1") {
          validateProperty({
            language: "0",
            userId: token,
            adLicenseNumber: ADNumber,
            source: "3",
          })
            .then((res) => {
              if (res.resCode !== 0) setErrors({ ADNumber: res.resStr });
              else {
                setProperty(res.response);
                handlecontinueClick(2, [0, 1]);
              }
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setLoadingButton(false);
            });
        } else {
          setOpenSubscriptionModal(true);
          setErrorMsg(result.resStr);
          setLoadingButton(false);
        }
      } else {
        toast.error(result.resStr);
        setLoadingButton(false);
      }
    });
  }

  function handleZoomChange(NewZoom) {
    setZoomLevel(NewZoom);
  }

  function handleChange(NewLocation) {
    setLocation({ ...Location, NewLocation });
    setADdata({
      ...ADdata,
      location: {
        ...ADdata.location,
        latitude: NewLocation.latitude,
        longitude: NewLocation.longitude,
      },
    });
  }

  function handlecontinueClick(current, completed) {
    setCurrentStep(current);
    setCompletedSteps(completed);
  }

  function handleChangeAddress() { }

  function Step1Substep2IsValid() {
    const _errors = {};
    if (!ADdata.advertiserType) _errors.advertiserType = t("ThisFieldIsMandatory");
    if (!ADdata.AdvertiserID)
      _errors.AdvertiserID = t("ThisFieldIsMandatory");
    if (ADdata.advertiserType === "OwnerAgent" && !ADdata.attorneyCode)
      _errors.attorneyCode = t("ThisFieldIsMandatory");
    if (!ADdata.brokerageContractNumber)
      _errors.brokerageContractNumber = t("ThisFieldIsMandatory");
    if (!ADdata.FullName && ADdata.advertiserType === "BrokerOffice")
      _errors.FullName = t("ThisFieldIsMandatory");
      
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function Step2IsValid() {
    const _errors = {};
    if (ADdata.channels?.length === 0)
      _errors.channels = "يجب تحديد قناة إعلان واحدة على الأقل";

    if (!ADdata.propertyPrice) _errors.propertyPrice = t("ThisFieldIsMandatory");
    if (!ADdata.advertisementType) _errors.advertisementType = t("ThisFieldIsMandatory");
    if (!ADdata.disclaimer) _errors.disclaimer = t("ThisFieldIsMandatory");
    if (!ADdata.deedNumber) _errors.deedNumber = t("ThisFieldIsMandatory");
    if (!ADdata.propertyArea) _errors.propertyArea = t("ThisFieldIsMandatory");
    if (!ADdata.obligationsOnTheProperty)
      _errors.obligationsOnTheProperty = t("ThisFieldIsMandatory");
    if (
      !ADdata.numberOfRooms &&
      ["2", "3", "4", "5", "7", "11", "13", "15", "26", "20", "21"].includes(
        ADdata.propertyType
      )
    )
      _errors.numberOfRooms = t("ThisFieldIsMandatory");
    if (
      ADdata.streetWidth === "" &&
      !["2", "3", "5", "6", "11", "13"].includes(ADdata.propertyType)
    )
      _errors.streetWidth = t("ThisFieldIsMandatory");

    else if (
      ADdata.streetWidth <= 0 &&
      !["2", "3", "5", "6", "13"].includes(ADdata.propertyType)
    )
      _errors.streetWidth = "يجب أن يكون أكبر من الصفر";
    
    if (!ADdata.propertyAge && !["1", "13"].includes(ADdata.propertyType))
      _errors.propertyAge = t("ThisFieldIsMandatory");
    if (ADdata.propertyUsages?.length === 0)
      _errors.propertyUsages = "يجب تحديد طريقة استخدام العقار";
    if (
      !ADdata.propertyFace &&
      ["1", "4", "7", "10", "15", "17", "25", "26", "20", "21"].includes(
        ADdata.propertyType
      )
    )
      _errors.propertyFace = t("ThisFieldIsMandatory");
    if (_errors.channels) {
      channelsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (_errors.propertyPrice || ADdata.propertyAge || ADdata.propertyFace || _errors.advertisementType || _errors.numberOfRooms || _errors.propertyArea || _errors.deedNumber || _errors.streetWidth) {
      propertyInfoRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (_errors.propertyUsages) {
      propertyUsagesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (_errors.obligationsOnTheProperty) {
      additionalInfoRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (_errors.disclaimer) {
      disclaimerRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  return (
    <>
      <SubHeader
        withSteps
        steps={
          createingAD
            ? [
              "ضوابط الإعلان العقاري",
              "التحقق من الإعلان",
              t("BasicInformation"),
              "مراجعة المعلومات",
              "البيانات الإضافية"
            ]
            : [
              "ضوابط الإعلان العقاري",
              "التحقق من الإعلان",
              "مراجعة المعلومات",
              "تحديد موقع العقار",
              "البيانات الإضافية"
            ]
        }
        pageTitle={
          createingAD
            ? ADdata?.propertyType
              ? `إضافة ${AD_PROPERTY_TYPES.filter(
                (i) => i.id === ADdata?.propertyType
              )?.[0]?.label
              } ${ADdata?.advertisementType === "sell" ? t("ForSale") : t("ForRent")}`
              : t("AddRealEstateAD")
            : property?.propertyArr?.propertyType
              ? `إضافة ${renderPropertyTypeName(
                property?.propertyArr?.propertyType
              )} ${property?.propertyArr?.listedFor === "1" ? t("ForRent") : t("ForSale")
              }`
              : t("AddRealEstateAD")
        }
        OKElement={<></>}
        completedSteps={completedSteps}
        currentStep={currentStep}
      />

      <div className='add-new-property-area mg-bottom-100 mt-4 text-center'>
        {currentStep === 0 && (
          <ADConstraints onOK={() => handlecontinueClick(1, [0])} />
        )}
        {currentStep === 1 && (
          <div
            className='custom-card ad-constraints'
            style={{ minHeight: "auto", height: "auto" }}>
            <div className='card-header'>
              <h4>
                {subStep === 1 || subStep === 3
                  ? "التحقق من رقم ترخيص الإعلان العقاري"
                  : "تحديد معلومات المُعلن"}
              </h4>
              <h6>
                {subStep === 1 || subStep === 3
                  ? "قم بادخال رقم ترخيص الإعلان العقاري الخاص بالهيئة العامة للعقار أو إنشاء ترخيص جديد"
                  : "قم باختيار صفة المُعلن وادخال جميع المعلومات المطلوبة"
                }
              </h6>
            </div>
            <div className='card-body justify-content-center  align-items-center'>
              {subStep === 1 ? (
                <FormControl
                  className='col-12 col-md-11 p-0'
                  dir='rtl'
                  style={{ margin: "50px 0" }}>
                  <RadioGroup
                    row
                    style={{ justifyContent: "space-evenly" }}
                    value={createingAD}>
                    <div
                      className={`custom-radio col-12 col-md-5 mb-3 ${!createingAD ? "active" : ""
                        }`}
                      onClick={(e) => {
                        setCreateingAD(false);
                      }}>
                      <div
                        className='d-flex align-items-center'
                        style={{ gap: "10px" }}>
                        <Building />
                        <span>لدي ترخيص إعلان</span>
                      </div>
                      <div>
                        <FormControlLabel value={false} control={<Radio />} />
                      </div>
                    </div>
                    <div
                      className={`custom-radio col-12 col-md-5 mb-3 ${createingAD ? "active" : ""
                        }`}
                      onClick={(e) => {
                        setCreateingAD(true);
                      }}>
                      <div
                        className='d-flex align-items-center'
                        style={{ gap: "10px" }}>
                        <Building />
                        <span>إنشاء ترخيص إعلان</span>
                      </div>
                      <div>
                        <FormControlLabel value={true} control={<Radio />} />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              ) : subStep === 3 ? (
                <div className='justify-content-center d-flex flex-column align-items-center'>
                  <TextField
                    label={
                      user.accountType === "2"
                        ? (user?.commercialRecordNumber?.startsWith('7') ? 'الرقم الوطني الموحد للمنشأة' : 'رقم السجل التجاري')
                        : "رقم الهويّة"
                    }
                    sx={{
                      width: "300px",
                      mt: "30px",
                      mb: "15px",
                      display: "inline-flex",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    autoComplete='off'
                    value={
                      user.accountType === "2"
                        ? user.commercialRecordNumber
                        : user.nationalid
                    }
                  />
                  <TextField
                    label={"رقم ترخيص الإعلان العقاري"}
                    sx={{ width: "300px", mt: "30px", mb: "15px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={"ادخل رقم ترخيص الإعلان العقاري"}
                    required={true}
                    autoComplete='off'
                    value={ADNumber}
                    onChange={(e) => {
                      setADNumber(preventString(e.target.value));
                      setErrors({});
                    }}
                  />
                  {errors.ADNumber && (
                    <Alert
                      severity='error'
                      style={{
                        width: "300px",
                        margin: "auto",
                        textAlign: "right",
                      }}>
                      &nbsp;&nbsp;{errors.ADNumber}
                    </Alert>
                  )}
                  {/* <Alert
                      severity='info'
                      dir='rtl'
                      className='mt-4 mx-auto text-right'
                      style={{ width: "fit-content" }}>
                      يمكنك الحصول على رقم ترخيص إعلان عبر انشاء إعلان في منصة
                      <a
                        rel='noreferrer'
                        style={{ textDecoration: "underline", fontWeight: 500 }}
                        href='https://eservices.rega.gov.sa/'
                        target='_blank'>
                        {" "}
                        الخدمات الإلكترونية التابعة للهيئة العامة للعقار
                      </a>
                    </Alert> */}
                </div>
              ) :
                subStep === 2 ? (
                  <>
                    <div className='col-12 mb-3 text-right'>
                      <FormControl
                        className='col-12 p-0'
                        dir='rtl'
                        style={{ margin: "10px 0" }}>
                        <FormLabel required>
                          الرجاء تحديد صفة المُعلن
                        </FormLabel>
                        <RadioGroup
                          row
                          style={{ gap: 20, marginTop: 15 }}
                          value={ADdata.advertiserType}>
                          <div
                            className={`custom-radio col-12 col-md-5 col-lg-3 mb-3 ${ADdata.advertiserType === "BrokerOffice" ||
                              ADdata.advertiserType === "BrokerIndividual"
                              ? "active"
                              : ""
                              }`}
                            onClick={(event) => {
                              setADdata({
                                ...ADdata,
                                advertiserType:
                                  user.accountType === "2"
                                    ? "BrokerOffice"
                                    : "BrokerIndividual",
                              });
                            }}>
                            <div
                              className='d-flex align-items-center'
                              style={{ gap: "10px" }}>
                              <User />
                              <span>
                                {user.accountType === "2"
                                  ? "وسيط منشأة"
                                  : "وسيط فرد"}
                              </span>
                            </div>
                            <div>
                              <FormControlLabel
                                value={
                                  user.accountType === "2"
                                    ? "BrokerOffice"
                                    : "BrokerIndividual"
                                }
                                control={<Radio />}
                              />
                            </div>
                          </div>
                          <div
                            className={`custom-radio col-12 col-md-5 col-lg-3 mb-3 ${ADdata.advertiserType === "OwnerAgent"
                              ? "active"
                              : ""
                              }`}
                            onClick={(event) => {
                              setADdata({
                                ...ADdata,
                                advertiserType: "OwnerAgent",
                              });
                            }}>
                            <div
                              className='d-flex align-items-center'
                              style={{ gap: "10px" }}>
                              <User />
                              <span>وكيل المالك</span>
                            </div>
                            <div>
                              <FormControlLabel
                                value={"OwnerAgent"}
                                control={<Radio />}
                              />
                            </div>
                          </div>
                        </RadioGroup>
                        {errors.advertiserType && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.advertiserType}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="row mx-0">
                      {ADdata.advertiserType && (
                        <FormControl
                          className='col-12 p-0 text-right'
                          dir='rtl'
                          style={{ margin: "10px 0" }}>
                          <FormLabel required>
                            الرجاء ادخال المعلومات التاليّة
                          </FormLabel>
                        </FormControl>
                      )}
                      {ADdata.advertiserType === "OwnerAgent" && (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                          <FormControl
                            error={Boolean(errors.attorneyCode)}
                            className='col-12'
                            dir='rtl'>
                            <TextField
                              label='رقم الوكاله'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                              value={ADdata.attorneyCode}
                              size='small'
                              required
                              onChange={(v) => {
                                setADdata({
                                  ...ADdata,
                                  attorneyCode: preventString(
                                    v.currentTarget.value
                                  ),
                                });
                              }}
                              error={Boolean(errors.attorneyCode)}
                            />
                            {errors.attorneyCode && (
                              <FormHelperText component={Box}>
                                <Alert
                                  severity='error'
                                  className='custom-alert'>
                                  {errors.attorneyCode}
                                </Alert>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      )}
                            
                      {ADdata.advertiserType === 'BrokerOffice' && (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                          <FormControl
                            error={Boolean(errors.FullName)}
                            className='col-12'
                            dir='rtl'>
                            <TextField
                              label="اسم المستخدم"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                              sx={{ width: "100%" }}
                              value={ADdata.FullName}
                              size='small'
                              onChange={(v) => {
                                setADdata({
                                  ...ADdata,
                                  FullName: v.currentTarget.value
                                });
                              }}
                              error={Boolean(errors.FullName)}
                            />
                            {errors.FullName && (
                              <FormHelperText component={Box}>
                                <Alert
                                  severity='error'
                                  className='custom-alert'>
                                  {errors.FullName}
                                </Alert>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      )}
                      {ADdata.advertiserType && (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                          <FormControl
                            error={Boolean(errors.AdvertiserID)}
                            className='col-12'
                            dir='rtl'>
                            <TextField
                              label={ADdata.advertiserType === "BrokerOffice" ? 'رقم الاتصال الموّحد' : 'رقم هوية المعلن'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                              sx={{ width: "100%" }}
                              value={ADdata.AdvertiserID}
                              size='small'
                              onChange={(v) => {
                                setADdata({
                                  ...ADdata,
                                  AdvertiserID: preventString(
                                    v.currentTarget.value
                                  ),
                                });
                              }}
                              error={Boolean(errors.AdvertiserID)}
                            />
                            {errors.AdvertiserID && (
                              <FormHelperText component={Box}>
                                <Alert
                                  severity='error'
                                  className='custom-alert'>
                                  {errors.AdvertiserID}
                                </Alert>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      )}
                      {ADdata.advertiserType && (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                          <FormControl
                            error={Boolean(errors.brokerageContractNumber)}
                            className='col-12'
                            dir='rtl'>
                            <TextField
                              label='رقم عقد الوساطة'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                              sx={{ width: "100%" }}
                              value={ADdata.brokerageContractNumber}
                              size='small'
                              onChange={(v) => {
                                setADdata({
                                  ...ADdata,
                                  brokerageContractNumber: preventString(
                                    v.currentTarget.value
                                  ),
                                });
                              }}
                              error={Boolean(errors.brokerageContractNumber)}
                            />
                            {errors.brokerageContractNumber && (
                              <FormHelperText component={Box}>
                                <Alert
                                  severity='error'
                                  className='custom-alert'>
                                  {errors.brokerageContractNumber}
                                </Alert>
                              </FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </>
                )
                  :
                  subStep === 4 && (
                    <>
                      <div
                        className='dialog-head justify-content-center'
                        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        <Tabs
                          onChange={(event, newValue) => {
                            setADdata({ ...ADdata, advertisementType: newValue });
                          }}
                          centered
                          value={ADdata.advertisementType}
                          sx={{
                            width: "auto",
                          }}
                          TabIndicatorProps={{
                            style: { background: "var(--main-color-one)" },
                          }}>
                          <Tab value={"sell"} label={t("ForSale")} sx={tabStyle} />
                          <Tab value={"rent"} label={t("ForRent")} sx={tabStyle} />
                        </Tabs>
                      </div>
                      <div className='property-types-boxes'>
                        {AD_PROPERTY_TYPES.map((_PropertyType, index) => (
                          <span
                            onClick={() => {
                              setADdata({
                                ...ADdata,
                                propertyType: _PropertyType.id,
                              });
                            }}
                            className={`property-type-box ${_PropertyType.id === ADdata.propertyType
                              ? "selected"
                              : ""
                              }`}
                            key={index}>
                            {renderPropertyIcon2(_PropertyType.id)}
                            <span>{_PropertyType.label}</span>
                          </span>
                        ))}
                      </div>
                      {errors.propertyType && (
                        <FormHelperText component={Box}>
                          <Alert severity='error' className='custom-alert'>
                            {errors.propertyType}
                          </Alert>
                        </FormHelperText>
                      )}
                    </>
                  )}
            </div>
            <div className='card-footer'>
              {subStep !== 1 && (
                <>
                  <LoadingButton
                    classes='outlined-btn px-5 ml-3'
                    handleClick={() => {
                      if (subStep === 4) {
                        setSubStep(2);
                      } else
                        setSubStep(1);
                    }}
                    label='السابق'
                    loading={false}
                  />
                  <SubscriptionPopup
                    setOpenSubscriptionModal={setOpenSubscriptionModal}
                    openSubscriptionModal={openSubscriptionModal}
                    desc={
                      UserType === "5" ? (
                        <>
                          {errorMsg}
                          <br /> تواصل مع مدير الحساب لترقية الاشتراك
                        </>
                      ) : (
                        errorMsg
                      )
                    }
                    trigger={
                      <LoadingButton
                        classes='primary-btn px-5'
                        handleClick={() => {
                          // if (createingAD) {
                          //   if (subStep === 2) {
                          //     if (Step1Substep2IsValid()) {
                          //       setCurrentStep(2);
                          //       setCompletedSteps([0, 1]);
                          //     }
                          //   } else {
                          //     // if (DEMO_VENDORS.includes(vendor) && subStep === 3) {
                          //     //   setOpenDemoSnackBar(true);
                          //     // } else {
                          //     //   // if (Step1Substep2IsValid()) setCurrentStep(2);
                          //     //   setCompletedSteps([0, 1]);
                          //     // }
                          //   }
                          // } else {
                          //   if (DEMO_VENDORS.includes(vendor)) {
                          //     setOpenDemoSnackBar(true);
                          //   } else {
                          //     checkProperty();
                          //   }
                          // }
                          if (createingAD) {
                              
                            if (subStep === 2) {
                              if (Step1Substep2IsValid()) setSubStep(4);
                            } else {
                              if (DEMO_VENDORS.includes(vendor) && subStep === 4) {
                                setOpenDemoSnackBar(true);
                              } else {
                                if (Step1Substep4IsValid()) setCurrentStep(2);
                                setCompletedSteps([0, 1]);
                              }
                            }
                          } else {
                            if (DEMO_VENDORS.includes(vendor)) {
                              setOpenDemoSnackBar(true);
                            } else {
                              checkProperty();
                            }
                          }
                        }}
                        label={t("Next")}
                        loading={loadingButton}
                      />
                    }
                  />
                </>
              )}

              {subStep === 1 && (
                <LoadingButton
                  classes='primary-btn px-5'
                  handleClick={() => {
                    if (createingAD) setSubStep(2);
                    else setSubStep(3);
                  }}
                  label={t("Next")}
                  loading={false}
                />
              )}
            </div>
          </div>
        )}
        {((!createingAD && currentStep === 2) ||
          (createingAD && currentStep === 3)) && (
            <REGAPropertyInfo onOK={handleNextClick} property={property} />
          )}
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
        {(!createingAD && currentStep === 3) && (
          <div className='custom-card'>
            <div className='card-header'>
              <h4>حدد الموقع</h4>
              <h6>قمّ بتحديد موقع العقار بدقّة</h6>
            </div>
            <div className='card-body'>
              <div
                style={{
                  height: "400px",
                  width: "100%",
                  position: "relative",
                  marginTop: "16px",
                }}
                className='map-full-height'>
                <GoogleMapsContainer
                  lat={Location.latitude}
                  lng={Location.longitude}
                  onChange={handleChange}
                  onZoomChange={handleZoomChange}
                  user={Location}
                />
                <img
                  alt='pin'
                  src={publicUrl + "assets/img/icons/BluePin.svg"}
                  className='addPropPin'
                />
                <input
                  id='address'
                  name='address'
                  className='form-control py-3 map-input'
                  onChange={handleChangeAddress}
                  value={Location.address}
                />
              </div>

              <Alert
                severity='warning'
                style={{ width: "fit-content", marginTop: 20 }}>
                &nbsp;&nbsp;لن يكون تعديل موقع العقار في المستقبل ممكنًا. يرجى
                ذكر الموقع وفقًا لذلك
              </Alert>
             
            </div>
            <div className='card-footer'>
              <LoadingButton
                classes='outlined-btn px-5 ml-3'
                handleClick={() => {
                  setCurrentStep(!createingAD ? 2 : 2);
                  setCompletedSteps(!createingAD ? [0, 1] : [0, 1]);
                }}
                label='السابق'
                loading={false}
              />
              <LoadingButton
                classes='primary-btn px-5'
                handleClick={() => {
                  if (!createingAD) {
                    handleSaveLocation();
                  }
                }}
                label={t("Next")}
                loading={loadingLocationButton}
              />
            </div>
          </div>
        )}

        {createingAD && currentStep === 2 && (
          <div className='custom-card'>
            <div className='card-header'>
              <h4>{t("BasicInformation")}</h4>
              <h6>
                قمّ بادخال المعلومات التاليّة بدقّة
              </h6>
            </div>
            <div className='card-body text-right'>
              <div className='row'>

                <Divider
                  textAlign='left'
                  className='col-12 my-4 custom-divider with-top'
                  style={{ color: "var(--main-color-one)" }}>
                  خدمات العقار
                </Divider>
                <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                  {AD_PROPERTY_UTILITIES.map((item, i) => (
                    <div className='col' key={i}>
                      <Switch
                        value={item.value}
                        checked={
                          ADdata.propertyUtilities.filter(
                            (e) => e === item.value
                          ).length > 0
                        }
                        onChange={(target) => {
                          let value = target.currentTarget.value;
                          if (ADdata.propertyUtilities.includes(value)) {
                            setADdata({
                              ...ADdata,
                              propertyUtilities:
                                ADdata.propertyUtilities.filter(
                                  (element) => element !== value
                                ),
                            });
                          } else
                            setADdata({
                              ...ADdata,
                              propertyUtilities: [
                                ...ADdata.propertyUtilities,
                                value,
                              ],
                            });
                        }}
                      />
                      <span
                        style={{
                          textAlign: "center",
                          textAlignLast: "center",
                        }}>
                        {item.label}
                      </span>
                    </div>
                  ))}
                </div>

  
                <Divider
                  textAlign='left'
                  ref={channelsRef}
                  className='col-12 mb-4 custom-divider with-top'
                  style={{ color: "var(--main-color-one)" }}>
                  قنوات الإعلان
                </Divider>
                <div>
                  <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 text-right mb-5 justify-content-start'>
                    {AD_CHANNELS.map((item, i) => (
                      <div className='col' key={i}>
                        <Switch
                          value={item.value}
                          checked={
                            ADdata.channels.filter((e) => e === item.value)
                              .length > 0
                          }
                          onChange={(target) => {
                            let value = target.currentTarget.value;
                            if (ADdata.channels.includes(value)) {
                              setADdata({
                                ...ADdata,
                                channels: ADdata.channels.filter(
                                  (element) => element !== value
                                ),
                              });
                            } else
                              setADdata({
                                ...ADdata,
                                channels: [...ADdata.channels, value],
                              });
                          }}
                        />
                        <span
                          style={{
                            textAlign: "center",
                            textAlignLast: "center",
                          }}>
                          {item.label}
                        </span>
                      </div>
                    ))}
                  </div>
                  {errors.channels && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.channels}
                      </Alert>
                    </FormHelperText>
                  )}
                </div>
                
                <Divider
                  textAlign='left'
                  ref={propertyInfoRef}
                  className='col-12 my-4 custom-divider with-top'
                  style={{ color: "var(--main-color-one)" }}>
                  مواصفات العقار
                </Divider>
                {/* <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                      <FormControl
                        className='custom-select'
                        error={Boolean(errors.advertisementType)}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          select
                          value={ADdata.advertisementType}
                          sx={{ width: "100%" }}
                          onChange={(event) => {
                            setADdata({
                              ...ADdata,
                              advertisementType: event.target.value,
                            });
                          }}
                          error={Boolean(errors.advertisementType)}
                          label='غرض الإعلان'
                      required
                    >
                            <MenuItem value={'Sell'} key={'Sell'}>بيع</MenuItem>
                            <MenuItem value={'Rent'} key={'Rent'}>إيجار</MenuItem>
                        </TextField>
                        {errors.advertisementType && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.advertisementType}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div> */}
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.deedNumber)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='رقم وثيقة الملكية/ ملكية المنفعة'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      sx={{ width: "100%" }}
                      value={ADdata.deedNumber}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          deedNumber: preventString(v.currentTarget.value),
                        });
                      }}
                      error={Boolean(errors.deedNumber)}
                    />
                    {errors.deedNumber && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.deedNumber}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.deedSerialNumber)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label={<div className="d-flex align-items-center">الرقم المتسلسل لوثيقة الملكية في عقد الوساطة
                        <Tooltip title={<div style={{ direction: 'rtl', textAlign: 'right' }}>يجب إرسال هذا الرقم في حالة وجود سندات ملكية متعددة في عقد الوساطة.</div>}>
                          <HelpOutlineIcon sx={{ cursor: 'pointer', marginRight: '2px' }} />
                        </Tooltip> </div>}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                      value={ADdata.deedSerialNumber}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          deedSerialNumber: preventString(v.currentTarget.value),
                        });
                      }}
                      error={Boolean(errors.deedSerialNumber)}
                    />
                    {errors.deedSerialNumber && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.deedSerialNumber}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                 
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.propertyPrice)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label={<div className="d-flex align-items-center">
                        {ADdata.advertisementType === "Sell" ? "السعر" : "سعر الإيجار السنوي"}
                        <Tooltip title={<div style={{ direction: 'rtl', textAlign: 'right' }}>
                          <ul>
                            {ADdata.advertisementType === "Sell" ? <>
                              <li>في حال كان نوع العقار "أرض", فإن هذا السعر يمثل سعر المتر</li>
                              <li>في حال لم يكن نوع العقار "أرض", فإن هذا السعر يمثل السعر الإجمالي للعقار</li>
                            </> : <>
                              <li>في حال كان نوع العقار "أرض", فإن هذا السعر يمثل سعر الإيجار السنوي للمتر</li>
                              <li>في حال لم يكن نوع العقار "أرض", فإن هذا السعر يمثل سعر الإيجار السنوي للعقار</li>
                            </>}
                            <li>يجب ألّا يكون هذا السعر أقل من السعر المدخل في عقد الوساطة</li>
                          </ul>
                        </div>}>
                          <HelpOutlineIcon sx={{ cursor: 'pointer', marginRight: '2px' }} />
                        </Tooltip> </div>
                          
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // required
                      sx={{ width: "100%" }}
                      value={ADdata.propertyPrice}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          propertyPrice: preventString(v.currentTarget.value),
                        });
                      }}
                      error={Boolean(errors.propertyPrice)}
                    />
                    {errors.propertyPrice && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.propertyPrice}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.streetWidth)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='عرض الشارع'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={
                        !["2", "3", "5", "6", "11", "13"].includes(
                          ADdata.propertyType
                        )
                      }
                      sx={{ width: "100%" }}
                      value={ADdata.streetWidth}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          streetWidth: preventString(v.currentTarget.value),
                        });
                      }}
                      error={Boolean(errors.streetWidth)}
                    />
                    {errors.streetWidth && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.streetWidth}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.propertyArea)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='مساحة العقار'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      sx={{ width: "100%" }}
                      value={ADdata.propertyArea}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          propertyArea: preventString(v.currentTarget.value),
                        });
                      }}
                      error={Boolean(errors.propertyArea)}
                    />
                    {errors.propertyArea && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.propertyArea}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.numberOfRooms)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='عدد الغرف'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={[
                        "2",
                        "3",
                        "4",
                        "5",
                        "7",
                        "11",
                        "13",
                        "15",
                        "26",
                        "20",
                        "21",
                      ].includes(ADdata.propertyType)}
                      sx={{ width: "100%" }}
                      value={ADdata.numberOfRooms}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          numberOfRooms: preventString(
                            v.currentTarget.value
                          ),
                        });
                      }}
                      error={Boolean(errors.numberOfRooms)}
                    />
                    {errors.numberOfRooms && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.numberOfRooms}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    className='custom-select'
                    error={Boolean(errors.propertyAge)}>
                    <TextField
                      select
                      value={ADdata.propertyAge}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={ADdata.propertyType !== "13"}
                      onChange={(event) => {
                        setADdata({
                          ...ADdata,
                          propertyAge: event.target.value,
                        });
                      }}
                      label='عمر العقار'
                      error={Boolean(errors.propertyAge)}>
                      {AD_PROPERTY_AGES.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.propertyAge && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.propertyAge}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    className='custom-select'
                    error={Boolean(errors.propertyFace)}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      select
                      value={ADdata.propertyFace}
                      sx={{ width: "100%" }}
                      onChange={(event) => {
                        setADdata({
                          ...ADdata,
                          propertyFace: event.target.value,
                        });
                      }}
                      error={Boolean(errors.propertyFace)}
                      label='واجهة العقار'
                      required={[
                        "1",
                        "4",
                        "7",
                        "10",
                        "15",
                        "17",
                        "25",
                        "26",
                        "20",
                        "21",
                      ].includes(ADdata.propertyType)}>
                      {AD_PROPERTY_FACES.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.propertyFace && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.propertyFace}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <Divider
                  textAlign='left'
                  ref={propertyUsagesRef}
                  className='col-12 my-4 custom-divider with-top'
                  style={{ color: "var(--main-color-one)" }}>
                  استخدامات العقار
                </Divider>
                <div>
                  <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                    {AD_PROPERTY_USAGE.filter((i) =>
                      i.visibleFor.includes(ADdata.propertyType)
                    ).map((item, i) => (
                      <div className='col' key={i}>
                        <Switch
                          value={item.value}
                          checked={
                            ADdata.propertyUsages.filter(
                              (e) => e === item.value
                            ).length > 0
                          }
                          onChange={(target) => {
                            let value = target.currentTarget.value;
                            if (ADdata.propertyUsages.includes(value)) {
                              setADdata({
                                ...ADdata,
                                propertyUsages: ADdata.propertyUsages.filter(
                                  (element) => element !== value
                                ),
                              });
                            } else
                              setADdata({
                                ...ADdata,
                                propertyUsages: [
                                  ...ADdata.propertyUsages,
                                  value,
                                ],
                              });
                          }}
                        />
                        <span
                          style={{
                            textAlign: "center",
                            textAlignLast: "center",
                          }}>
                          {item.label}
                        </span>
                      </div>
                    ))}
                  </div>
                  {errors.propertyUsages && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.propertyUsages}
                      </Alert>
                    </FormHelperText>
                  )}
                </div>
                                    
                   
              
                <Divider
                  textAlign='left'
                  ref={additionalInfoRef}
                  className='col-12 my-4 custom-divider with-top'
                  style={{ color: "var(--main-color-one)" }}>
                  معلومات إضافيّة
                </Divider>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.obligationsOnTheProperty)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='الإلتزامات على العقار'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      sx={{ width: "100%" }}
                      value={ADdata.obligationsOnTheProperty}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          obligationsOnTheProperty:
                            resolvePersianAndArabicNumbers(
                              v.currentTarget.value
                            ),
                        });
                      }}
                      error={Boolean(errors.obligationsOnTheProperty)}
                    />
                    {errors.obligationsOnTheProperty && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.obligationsOnTheProperty}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl className='col-12' dir='rtl'>
                    <TextField
                      label='الضمانات ومدتها'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                      value={ADdata.guaranteesAndTheirDuration}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          guaranteesAndTheirDuration:
                            resolvePersianAndArabicNumbers(
                              v.currentTarget.value
                            ),
                        });
                      }}
                    />
                  </FormControl>
                </div>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl className='col-12' dir='rtl'>
                    <TextField
                      label='رقم المخطط'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                      value={ADdata.planNumber}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          planNumber: resolvePersianAndArabicNumbers(
                            v.currentTarget.value
                          ),
                        });
                      }}
                    />
                  </FormControl>
                </div>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl className='col-12' dir='rtl'>
                    <TextField
                      label='حدود وأطوال العقار'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                      value={ADdata.theBordersAndLengthsOfTheProperty}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          theBordersAndLengthsOfTheProperty:
                            resolvePersianAndArabicNumbers(
                              v.currentTarget.value
                            ),
                        });
                      }}
                    />
                  </FormControl>
                </div>
                <div className='col-12 text-right p-0 mt-2'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ADdata.complianceWithTheSaudiBuildingCode}
                        onChange={(v) => {
                          setADdata({
                            ...ADdata,
                            complianceWithTheSaudiBuildingCode:
                              v.target.checked,
                          });
                        }}
                      />
                    }
                    label='مطابقة كود البناء السعودي'
                  />
                </div>
                      
                <div className='col-12 text-right p-0 mt-2' ref={disclaimerRef}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        checked={ADdata.disclaimer}
                        onChange={(v) => {
                          setADdata({
                            ...ADdata,
                            disclaimer: v.target.checked,
                          });
                        }}
                      />
                    }
                    label='أقر بصحة المعلومات وبيانات الإعلان العقاري المدخلة أعلاه، وأتحمل كامل المسؤولية في حال ثبت عدم صحة هذه البيانات، ويحق للهيئة اتخاذ كافة الإجراءات النظامية'
                  />
                 
                  {errors.disclaimer && (
                    <FormHelperText component={Box}>
                      <Alert severity='error' className='custom-alert'>
                        {errors.disclaimer}
                      </Alert>
                    </FormHelperText>
                  )}
                </div>
              </div>
              
            </div>
            <div className='card-footer'>
              <LoadingButton
                classes='outlined-btn px-5 ml-3'
                handleClick={() => {
                  setCurrentStep(1);
                  setCompletedSteps([0]);
                }}
                label='السابق'
                loading={false}
              />
              <LoadingButton
                classes='primary-btn px-5'
                handleClick={() => {
                  if (Step2IsValid()) {
                    setLoadingLocationButton(true);
                    createAd({
                      ...ADdata,
                      advertiserType: ADdata.advertiserType,
                      theAdThrough: ADdata.advertiserType,
                      attorneyCode: ADdata.attorneyCode ? +ADdata.attorneyCode : 0,
                      advertiserID: ADdata.AdvertiserID,
                      propertyPrice: ADdata.propertyPrice ? +ADdata.propertyPrice : "",
                      brokerageContractNumber: ADdata.brokerageContractNumber,
                      fullName: ADdata.FullName ? ADdata.FullName : '',
                      userId: token,
                      advertisementType: ADdata.advertisementType,
                      language: "0",
                      source: "0",
                      deedNumber: ADdata.deedNumber,
                      deedSerialNumber: ADdata.deedSerialNumber || null,
                      disclaimer: ADdata.disclaimer,
                      streetWidth: ADdata.streetWidth ? +ADdata.streetWidth : 1,
                      numberOfRooms: ADdata.numberOfRooms ? +ADdata.numberOfRooms : 0,
                      propertyFace: ADdata.propertyFace ? ADdata.propertyFace : "Northern",
                      theBordersAndLengthsOfTheProperty: ADdata.theBordersAndLengthsOfTheProperty ? ADdata.theBordersAndLengthsOfTheProperty : "0",
                      guaranteesAndTheirDuration: ADdata.guaranteesAndTheirDuration ? ADdata.guaranteesAndTheirDuration : "0",
                      propertyUtilities: ADdata.propertyUtilities?.length === 0 ? ["NoServices"] : ADdata.propertyUtilities,
                      complianceWithTheSaudiBuildingCode: ADdata.complianceWithTheSaudiBuildingCode,
                      propertyType: AD_PROPERTY_TYPES.filter((i) => +i.id === +ADdata.propertyType)[0].value,
                      propertyArea: ADdata.propertyArea ? +ADdata.propertyArea : undefined,
                      attorneyFirstId: ADdata.attorneyFirstId ? +ADdata.attorneyFirstId : undefined,
                      attorneySecondId: ADdata.attorneySecondId ? +ADdata.attorneySecondId : undefined,
                      propertyAge: ADdata.propertyAge ? ADdata.propertyAge : "OneYear",
                      planNumber: ADdata.planNumber ? ADdata.planNumber : "0"
                    }).then((res) => {
                      if (res.resCode === 0) {
                        setProperty(res.response);
                        // saveLocation({
                        //   userId: token,
                        //   propertyId: res.response?.propertyArr.propertyId,
                        //   latitude: Location.latitude,
                        //   longitude: Location.longitude,
                        // }).then((res) => {
                        //   setLoadingLocationButton(false);
                        //   if (res.resCode === 0) {
                        //     handlecontinueClick(4, [0, 1, 2, 3]);
                        //   } else {
                        //     toast.error(res.resStr);
                        //   }
                        // });
                        setCurrentStep(3);
                        setCompletedSteps([0, 1, 2]);
                        setLoadingLocationButton(false);
                      } else {
                        setLoadingLocationButton(false);
                        Swal.fire({
                          title: "حدث خطأ",
                          text: res.resStr,
                          icon: "error",
                          confirmButtonText: "حسناً",
                        });
                      }
                    });
                  }
                }}
                label={t("Next")}
                loading={loadingLocationButton}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddNew;
