import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Close } from '@mui/icons-material';

const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: '100vh',
    bgcolor: '#000',
    border: '2px solid #000',
    boxShadow: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    p: 0
};

export default function ImageModal({ src, open, handleClose }) {

    return (
        <div>
            <Modal
                sx={{zIndex:99999999999}}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Close onClick={handleClose} style={{ zIndex: 999999999, position: 'absolute', top: '10px', left: '10px', color: '#000',padding:'2px',borderRadius:'100%',background:'#fff', fontWeight: 700, fontSize: 28, cursor: 'pointer' }} />
                        <img style={{ maxHeight: '100%', objectFit: 'contain', margin: 'auto', display: 'block' }} src={src} alt={src} />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}