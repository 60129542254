import React from "react";
import { Alert, Box, FormControl, FormHelperText, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword, changePasswordForAgent } from "../../../../api/userApi";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { useAuth } from "../../../../helpers/context";
import { getDemoSnakbar, validatePassword } from "../../../../helpers";
import { DEMO_VENDORS } from "../../../../constants";
import { useTranslation } from "react-i18next";

function ChangePasswordForm(props) {
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { token, UserId ,vendor} = useAuth();
  const history = useNavigate();
  const [user, setUser] = useState({
    userId: token,
    newPassword: "",
    confirmpassword: "",
    oldPassword: "",
    language: "0",
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const { agent, agentId } = props;
  const { t } = useTranslation();

  function handleChange({ target }) {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!agent && !user.oldPassword) _errors.oldPassword = t("EnterYourCurrentPassword");
    else if (!agent && !validatePassword(user.oldPassword)) {
      _errors.oldPassword = t("PasswordMustNotBeLessThan6Characters");
    }
    if (!user.newPassword) _errors.newPassword = t("EnterNewPassword");
    else if (!validatePassword(user.newPassword)) {
      _errors.newPassword = t("PasswordMustNotBeLessThan6Characters");
    }
    if (!user.confirmpassword)
      _errors.confirmpassword = t("ReEnterNewPassword");

    if (user.newPassword !== user.confirmpassword)
      _errors.confirmpassword = t("PasswordsDoNotMatch");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      if (agent) {
        changePasswordForAgent({
          "superUserId": UserId,
          "newPassword": user.newPassword,
          "agentId": agentId
        }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            toast.success("تم تغيير كلمة المرور بنجاح");
            props.setOpenChangePasswordModal(false);
          } else {
            const _errors = {};
            toast.error(_userInfo.resStr);
            setErrors(_errors);
          }
          setLoadingButton(false);
        }).catch(e => setLoadingButton(false));
      } else {
        changePassword(user).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            history("/");
            toast.success("تم تغيير كلمة المرور بنجاح");
          } else {
            const _errors = {};
            toast.error(_userInfo.resStr);
            setErrors(_errors);
          }
          setLoadingButton(false);
        }).catch(e => setLoadingButton(false));
      }
    }
   

  }

  const handleClickShowPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  return (
    <form onSubmit={handleSubmit}>
      {!agent && (
      <div className={`col-12 mb-4 p-0 ${props.type === "modal" ? "" : "col-md-6 col-lg-7 col-xl-6"}`} dir="rtl">
      <FormControl
        error={Boolean(errors.oldPassword)}
        className="col-12" dir="rtl"
      >
        <TextField
          id="oldPassword"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          type={showCurrentPassword ? 'text' : 'password'}
          value={user.oldPassword}
          name="oldPassword"
          error={Boolean(errors.oldPassword)}
          size="small"
          sx={{ width: "100%" }}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
         
          label="كلمة المرور الحالية"
        />
        {errors.oldPassword &&
          <FormHelperText component={Box}>
            <Alert severity="error" className="custom-alert">
              {errors.oldPassword}
            </Alert>
          </FormHelperText>
        }
      </FormControl>
    </div>
      )}

      <div className={`col-12 mb-4 p-0 mt-3 ${props.type === "modal" ? "" : "col-md-6 col-lg-7 col-xl-6 "}`} dir="rtl">
        <FormControl
          error={Boolean(errors.newPassword)}
          className="col-12" dir="rtl"
        >
          <TextField
            id="newPassword"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type={showNewPassword ? 'text' : 'password'}
            value={user.newPassword}
            error={Boolean(errors.newPassword)}
            name="newPassword"
            size="small"
            sx={{ width: "100%" }}

            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
           
            label="كلمة المرور الجديدة"
          />
          {errors.newPassword &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.newPassword}
              </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div className={`col-12 mb-4 p-0 ${props.type === "modal" ? "" : "col-md-6 col-lg-7 col-xl-6"}`} dir="rtl">
        <FormControl
          error={Boolean(errors.confirmpassword)}
          className="col-12" dir="rtl"
        >
          <TextField
            id="confirmpassword"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            type={showConfirmPassword ? 'text' : 'password'}
            value={user.confirmpassword}
            error={Boolean(errors.confirmpassword)}
            name="confirmpassword"
            size="small"
            sx={{ width: "100%" }}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
           
            label="تأكيد كلمة المرور الجديدة"
          />
          {errors.confirmpassword &&
            <FormHelperText component={Box}><Alert severity="error" className="custom-alert">
              {errors.confirmpassword}
            </Alert>
            </FormHelperText>
          }
        </FormControl>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          classes="primary-btn d-flex justify-content-center py-2 mt-2 px-5"
          label={t("Save")}
          type="submit"
          loading={loadingButton}
        />
      </div>
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

    </form>
  );
}

export default ChangePasswordForm;
