import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  uploadWlFileBase64,
  addWLModel,
  editWLModel,
} from "../../../api/userApi";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Alert, Chip, FormHelperText, Grid, InputAdornment, ListItem, TextField } from "@mui/material";
import LoadingButton from "../../../components/global-components/loading-btn";
import { preventString, resolvePersianAndArabicNumbers, validateNumber } from "../../../helpers";
import DragAndDropFileUploader from "../../../components/global-components/drag-drop-file-uploader";
import { useRef } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from "react";
import { DEMO_VENDORS } from "../../../constants";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const EditModels = ({ Project, setProject, isChange, projectId, editModel, setEditModel }) => {
  const { vendor, token } = useAuth();
  const [uploadedImageMsg, setUploadedImageMsg] = React.useState('');
  const [uploadedPlanMsg, setUploadedPlanMsg] = React.useState('');
  const [price, setPrice] = React.useState(undefined);
  const [loadingImageButton, setLoadingImageButton] = React.useState(false);
  const [loadingplanButton, setLoadingPlanButton] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [Model, setModel] = React.useState();
  const [errors, setErrors] = React.useState({});
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [amenities, setAmenities] = React.useState([]);
  const inputRef = useRef(null);
  const [areaFrom, setAreaFrom] = React.useState('');
  const [areaTo, setAreaTo] = React.useState('');

  const { t } = useTranslation();
  
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (editModel?.visible) {
      setModel(editModel?.model);
      setPrice(editModel?.model?.minimumPrice ? getFormatedPrice(editModel?.model?.minimumPrice?.toString()) : undefined);
    }
  }, [editModel]);

  React.useEffect(() => {
    if (amenities.length === 0 && Model?.amenities && editModel?.visible) {
      setAmenities(Model?.amenities?.includes('@') ? Model?.amenities?.split('@') : [Model?.amenities]);
    }
    if (Model?.area && areaFrom === "" && areaTo === "") {
      setAreaFrom(Model?.area?.includes('@') ? Model?.area?.substring(0, Model?.area?.indexOf('@')) : Model?.area)
      setAreaTo(Model?.area?.includes('@') ? Model?.area?.substring(Model?.area?.indexOf('@') + 1) : Model?.area)
    }
  },
    // eslint-disable-next-line
    [Model, editModel]);
  
  const handleDeleteAmenity = (removedAmenity) => {
    const newAmenities = amenities.filter((anenity) => anenity !== removedAmenity);
    setAmenities(newAmenities);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e) => {
    if (e.key === 'Enter') {
      if (inputValue && amenities.indexOf(inputValue) === -1) {
        setAmenities([...amenities, inputValue]);
      }
      setInputVisible(false);
      setInputValue('');
    }
  };

  const handleInputBlur = () => {
    if (inputValue && amenities.indexOf(inputValue) === -1) {
      setAmenities([...amenities, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  function formIsValid() {
    const _errors = {};
    if (Model?.name === undefined || Model?.name?.trim() === '') {
      _errors.name = "الرجاء ادخال اسم النموذج";
    }
    if (Model?.modelType !== 'أرض' && Model?.room && !validateNumber(Model?.room)) _errors.room = "الرجاء ادخال أرقام فقط";
    if (Model?.modelType !== 'أرض' && Model?.bathroom && !validateNumber(Model?.bathroom)) _errors.bathroom = "الرجاء ادخال أرقام فقط";
    if (Model?.numberOfUnits && !validateNumber(Model?.numberOfUnits)) {
      _errors.numberOfUnits = "الرجاء ادخال أرقام فقط";
    }
    if (Model?.availableUnits && !validateNumber(Model?.availableUnits)) {
      _errors.availableUnits = "الرجاء ادخال أرقام فقط";
    }
    if (Model?.minimumPrice && !validateNumber(Model?.minimumPrice)) {
      _errors.minimumPrice = "الرجاء ادخال أرقام فقط";
    }
    
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  const handleClose = () => {
    setEditModel({ ...editModel, model: { modelType: "فيلا" }, visible: false });
    setModel({ modelType: "فيلا" });
    setPrice(undefined);
    setUploadedPlanMsg('');
    setUploadedImageMsg('');
    setAmenities([]);
    setErrors({});
    setAreaFrom('');
    setAreaTo('');
  }

  const handleSave = () => {
    if (!formIsValid()) return;
    const ModelToSave = {
      ...Model,
      projectId: projectId,
      userId: token,
      language: "0",
      modelId: Model?.id ? Model?.id : "",
      area: `${areaFrom}@${areaTo}`,
      room: Model?.room,
      bathroom: Model?.bathroom,
      amenities: amenities.join('@'),
      numberOfUnits: Model?.numberOfUnits,
      availableUnits: Model?.availableUnits,
      minimumPrice: Model?.minimumPrice
    };

    if (DEMO_VENDORS.includes(vendor)) {
      if (!editModel?.isEdit) {
        setProject({
          ...Project,
          units: [
            ...Project.units,
            { ...ModelToSave, modelId: Math.floor(Math.random() * 100000000000), id: Math.floor(Math.random() * 100000000000) }
          ]
        });
      } else {
        setProject({
          ...Project,
          units: [
            ...Project.units?.filter(i => i.modelId !== Model?.id && i.id !== Model?.id),
            { ...ModelToSave }
          ]
        });
      }

      handleClose();

    } else {
      setLoadingButton(true);
      if (!editModel?.isEdit) {
        addWLModel({
          ...ModelToSave,
        }).then((respo) => {
          if (respo.resCode === 0) toast.success("تم انشاء النموذج بنجاح");
          else toast.error(respo.resStr);
          setLoadingButton(false);
          handleClose();
          isChange?.();
        }).catch(e => setLoadingButton(false));
      } else {
        editWLModel({
          ...ModelToSave,
        }).then((respo) => {
          if (respo.resCode === 0) toast.success("تم التعديل بنجاح");
          else toast.error(respo.resStr);
          setLoadingButton(false);
          handleClose();
          isChange?.();
        }).catch(e => setLoadingButton(false));
      }
    }
   
  };

  function uploadImage(file) {
    let reader = new FileReader();
    // Convert the file to base64 text

    reader.readAsDataURL(file[0]);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: "Project/" + projectId + "/Photos/Moedels/1/" + file[0].name,
        base64Data: reader.result,
        typeOfFile: file[0].type,
        userId: token,
        language: "0",
      };
      uploadWlFile(fileInfo, "1");
    };
  }

  function getFormatedPrice(price) {
    let value = price.replace(/[,]+/g, "");
    return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  
  function uploadPlan(file) {
    let reader = new FileReader();
    // Convert the file to base64 text

    reader.readAsDataURL(file[0]);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: "Project/" + projectId + "/Photos/Moedels/2/" + file[0].name,
        base64Data: reader.result,
        typeOfFile: file[0].type === 'application/pdf' ? 'pdf' : file[0].type,
        userId: token,
        language: "0",
      };
      uploadWlFile(fileInfo, "2");
    };
  }

  function upload(file, type, sectionNum) {
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        pathWithFileName: "Project/" + projectId + "/Photos/Moedels/" + sectionNum + "/" + file.name,
        base64Data: reader.result,
        typeOfFile: type,
        userId: token,
        language: "0",
      };
      uploadWlFile(fileInfo, sectionNum);
    };
  }

  function uploadWlFile(fileInfo, sectionNum) {
    if (DEMO_VENDORS.includes(vendor)) {
      if (sectionNum === "1") {
        setModel({
          ...Model,
          image: fileInfo.base64Data,
        });
      } else if (sectionNum === "2") {
        setModel({
          ...Model,
          floorPlan: fileInfo.base64Data,
        });
      }
    } else {
      if (sectionNum === '1') {
        setUploadedImageMsg("جاري رفع الصورة");
        setLoadingImageButton(true);
      } else {
        setUploadedPlanMsg("جاري رفع المخطط");
        setLoadingPlanButton(true);
      }
      uploadWlFileBase64(fileInfo).then((resp) => {
        if (resp.resCode === 0) {
          toast.success("تم الرفع بنجاح");
  
          if (sectionNum === "1") {
            setModel({
              ...Model,
              image: resp.response.url,
            });
            setUploadedImageMsg("");
            setLoadingImageButton(false);
          } else if (sectionNum === "2") {
            setModel({
              ...Model,
              floorPlan: resp.response.url,
            });
            setUploadedPlanMsg("");
            setLoadingPlanButton(false);
          }
        } else {
          if (sectionNum === "1") {
            setUploadedImageMsg("");
          } else if (sectionNum === "2") {
            setUploadedPlanMsg("");
          }
          toast.error(resp.resStr);
        }
      });
    }
  }

  return (
    <React.Fragment>
    
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={editModel?.visible}
        className="custom-dialog model"
        style={{ direction: "rtl", textAlignLast: "right" }}
        onClose={handleClose}>
        <DialogTitle>
          <div className="dialog-head">
            <div>
              <h2>{editModel?.isEdit ? "تعديل النموذج" : "اضف نموذج"}</h2>
              <p>{editModel?.isEdit ? "يمكنك تعديل تفاصيل النموذج" : "قم بادخال تفاصيل النموذج"}</p>
            </div>
            <div className="dialog-actions">
              <LoadingButton label={t("Save")} classes="px-3 primary-btn" loading={loadingButton} handleClick={handleSave}
              />
              
              <button className="px-3 outlined-btn" onClick={handleClose}>
                {t("Cancel")}
              </button>
            </div>
          </div>
          
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <div className='custom-card'>
                <div className="card-header">
                  <h4>تفاصيل النموذج</h4>
                  <h6>أضف اسم النموذج, نوعه, مساحته, وما إلى ذلك</h6>
                </div>
                <div className="card-body row model-details m-0">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <FormControl className="custom-select">
                        <InputLabel htmlFor='max-width'>نوع النموذج</InputLabel>
                        <Select
                          autoFocus
                          value={Model?.modelType}
                          sx={{ width: "100%" }}

                          onChange={(event) => {
                            setModel({
                              ...Model,
                              modelType: event.target.value,
                            });
                          }}
                          label='maxWidth'
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}>
                          <MenuItem value='فيلا'>فيلا</MenuItem>
                          <MenuItem value='شقة'>شقة</MenuItem>
                          <MenuItem value='أرض'>أرض</MenuItem>

                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <FormControl
                        error={Boolean(errors.name)}
                        className="col-12" dir="rtl"
                      >
                        <TextField
                          label='اسم النموذج'
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          value={Model?.name}
                          size='small'
                          onChange={(v) => {
                            setModel({
                              ...Model,
                              name: resolvePersianAndArabicNumbers(v.currentTarget.value)
                            });
                          }}
                          error={Boolean(errors.name)}
                        />
                        {errors.name &&
                          <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">
                              {errors.name}
                            </Alert>
                          </FormHelperText>}
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <TextField
                        type="number"
                        label={t("AreaFrom")}
                        sx={{ width: "50%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                          ),
                        }}
                        placeholder="100"
                        value={areaFrom}
                        size='small'
                        onChange={(v) => {
                          // setModel({
                          //   ...Model,
                          //   area: resolvePersianAndArabicNumbers(v.currentTarget.value)
                          // });
                          setAreaFrom(Math.abs(v.currentTarget.value));
                        }}
                      />
                      <TextField
                        type="number"
                        label={t("AreaTo")}
                        sx={{ width: "50%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                          ),
                        }}
                        placeholder="1000"
                        value={areaTo}
                        size='small'
                        onChange={(v) => {
                          // setModel({
                          //   ...Model,
                          //   area: resolvePersianAndArabicNumbers(v.currentTarget.value)
                          // });
                          setAreaTo(Math.abs(v.currentTarget.value));
                        }}
                      />
                    </div>
                    {Model?.modelType !== 'أرض' && (
                      <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <FormControl
                          error={Boolean(errors.room)}
                          className="col-12" dir="rtl"
                        >
                          <TextField
                            label='الغرف'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ width: "100%" }}
                            value={Model?.room}
                            size='small'
                            onChange={(v) => {
                              setModel({
                                ...Model,
                                room: preventString(v.currentTarget.value)
                              });
                            }}
                            error={Boolean(errors.room)}
                          />
                          {errors.room &&
                            <FormHelperText component={Box}>
                              <Alert severity="error" className="custom-alert">
                                {errors.room}
                              </Alert>
                            </FormHelperText>}
                        </FormControl>
                      </div>
                    )}
                    {Model?.modelType !== 'أرض' && (
                      <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <FormControl
                          error={Boolean(errors.bathroom)}
                          className="col-12" dir="rtl"
                        >
                          <TextField
                            label='الحمامات'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ width: "100%" }}
                            value={Model?.bathroom}
                            size='small'
                            onChange={(v) => {
                              setModel({
                                ...Model,
                                bathroom: preventString(v.currentTarget.value)
                              });
                            }}
                            error={Boolean(errors.bathroom)}
                          />
                          {errors.bathroom &&
                            <FormHelperText component={Box}>
                              <Alert severity="error" className="custom-alert">
                                {errors.bathroom}
                              </Alert>
                            </FormHelperText>}
                        </FormControl>
                      </div>
                    )}
                  
                    {/* <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <TextField
                    label='الميزات'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: "100%" }}
                    value={Model?.amenities}
                    size='small'
                    onChange={(v) => {
                      setModel({
                        ...Model,
                        amenities: resolvePersianAndArabicNumbers(v.currentTarget.value)
                      });
                    }}
                  />
                </div> */}
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <FormControl
                        error={Boolean(errors.numberOfUnits)}
                        className="col-12" dir="rtl"
                      >
                        <TextField
                          label='عدد الوحدات الكلي'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          value={Model?.numberOfUnits}
                          size='small'
                          onChange={(v) => {
                            setModel({
                              ...Model,
                              numberOfUnits: preventString(v.currentTarget.value)
                            });
                          }}
                          error={Boolean(errors.numberOfUnits)}
                        />
                        {errors.numberOfUnits &&
                          <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">
                              {errors.numberOfUnits}
                            </Alert>
                          </FormHelperText>}
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <FormControl
                        error={Boolean(errors.availableUnits)}
                        className="col-12" dir="rtl"
                      >
                        <TextField
                          label='عدد الوحدات المتاحة'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          value={Model?.availableUnits}
                          size='small'
                          onChange={(v) => {
                            setModel({
                              ...Model,
                              availableUnits: preventString(v.currentTarget.value)
                            });
                          }}
                          error={Boolean(errors.availableUnits)}
                        />
                        {errors.availableUnits &&
                          <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">
                              {errors.availableUnits}
                            </Alert>
                          </FormHelperText>}
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                      <FormControl
                        error={Boolean(errors.minimumPrice)}
                        className="col-12" dir="rtl"
                      >
                        <TextField
                          label='الأسعار تبدأ من'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>ريال</InputAdornment>
                            ),
                          }}
                          sx={{ width: "100%" }}
                          value={price}
                          size='small'
                          onChange={(v) => {
                            setPrice(
                              getFormatedPrice(preventString(v.currentTarget.value.toString()))
                            );
                            setModel({
                              ...Model,
                              minimumPrice: + preventString(v.currentTarget.value).replace(/[,]+/g, "")
                            });
                          }}
                          error={Boolean(errors.minimumPrice)}
                        />
                        {errors.minimumPrice &&
                          <FormHelperText component={Box}>
                            <Alert severity="error" className="custom-alert">
                              {errors.minimumPrice}
                            </Alert>
                          </FormHelperText>}
                      </FormControl>
                    </div>
                  
                    <div className="col-12 mb-4 mb-lg-0 mt-3">
                      <h5> الميزات </h5>
                 
                      <div className="mb-2">

                        {inputVisible ? (
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="اكتب اسم الميزة"
                            style={{ width: 160, fontSize: '14px', padding: '5px 10px', borderRadius: '4px', border: '1px solid #ccc' }}
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onKeyDown={handleInputConfirm}
                          />
                        ) : (
                          <Chip
                            icon={<AddIcon />} label="إضافة ميّزة" color="primary" variant="outlined"
                            onClick={showInput}
                            sx={{ padding: '0 7px 0 0 ' }}
                          />
                        )}
                      </div>

                      {amenities.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            padding: '10px',
                            m: 0,
                            boxShadow: 'none',
                            borderRadius: '4px',
                            border: '1px dashed #ccc',
                            backgroundColor: 'none',
                            gap: '15px'
                          }}
                        >
                          {amenities.map((data, index) => {
                            return (
                              <ListItem style={{ width: 'fit-content', padding: 0 }} key={index}>
                                <Chip
                                  label={data}
                                  sx={{ '& .MuiChip-deleteIcon': { color: "rgba(255, 0, 0, 0.7)" } }}
                                  onDelete={() => handleDeleteAmenity(data)}
                                />
                              </ListItem>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
             
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <div className='custom-card' style={{ minHeight: 'auto' }}>
                    <div className="card-header">
                      <h4>صورة النموذج</h4>
                      <h6>أضف صورة مميزة للنموذج</h6>
                    </div>
                    <div className="card-body position-relative d-flex align-items-center flex-column">
                      {!uploadedImageMsg && !Model?.image &&
                        <DragAndDropFileUploader
                          singleFile
                          ImageOnly
                          id={`model-img`}
                          OnOK={uploadImage} />
                      }
                  
                      {uploadedImageMsg && (
                        <img
                          className="loading-img"
                          src={publicUrl + "assets/img/loding.gif"}
                          alt='loading-model'></img>
                      )}
                      {!uploadedImageMsg && Model?.image && (<>
                        <img src={Model?.image} alt="model-img" className="model-img" style={{maxHeight:'200px'}} />
                        <DragAndDropFileUploader
                          singleFile
                          ImageOnly
                          handleChange={(e) => upload(e.target.files[0], "jpg", "1")}
                          loading={loadingImageButton}
                          title={t("ChangeImage")}
                          id="contained-button-file-1"
                          classes="mt-4"
                          buttonMode
                        />
                      </>)}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className='custom-card' style={{ minHeight: 'auto' }}>
                    <div className="card-header">
                      <h4>مخطط النموذج</h4>
                      <h6>أضف مخطط للنموذج</h6>
                    </div>
                    <div className="card-body position-relative d-flex align-items-center flex-column">
                      {!uploadedPlanMsg && !Model?.floorPlan &&
                        <DragAndDropFileUploader
                          singleFile
                          PDFOnly
                          id={`model-plan`}
                          OnOK={uploadPlan} />}
                  
                      {uploadedPlanMsg && (
                        <img
                          className="loading-img"
                          src={publicUrl + "assets/img/loding.gif"}
                          alt='loading-floor'></img>
                      )}

                      {!uploadedPlanMsg && Model?.floorPlan && (<>
                        {Model?.floorPlan.includes('.pdf') || Model?.floorPlan?.startsWith('data:application/pdf') ?
                          <img
                            src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                            alt={`floor-img`}
                            style={{maxHeight:"200px"}}
                            loading='lazy'
                            className="model-img"
                          />
                          :
                          <img src={Model?.floorPlan} alt="floor-img" className="model-img" />
                        }
                        <DragAndDropFileUploader
                          singleFile
                          PDFOnly
                          handleChange={(e) => upload(e.target.files[0], e.target.files[0].type, "2")}
                          loading={loadingplanButton}
                          title="تغيير المخطط"
                          id="contained-button-file-2"
                          classes="mt-4"
                          buttonMode
                        />
                      </>)}
                 
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default EditModels;
