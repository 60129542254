import React from 'react'
import AppLayout from '../../../components/layouts/app-layout';
import { useParams } from 'react-router-dom';
import { SuccessVerification } from '../../../constants/backgrounds';
import { useTranslation } from 'react-i18next';

let publicUrl = process.env.REACT_APP_URL + "/";

export default function SuccessRegistration() {
    let { name, wlName } = useParams();
    const { t } = useTranslation();

    return (
        <AppLayout pageTitle="الاشتراكات">
            <div className="page-wrapper page-width" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
                <div className="row page-wrapper-without-top">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <h5 className='page-heading text-center px-3' style={{ color: 'rgba(0,0,0,0.8)' }}>
                                {`تم الاشتراك بخطّة ${decodeURIComponent(name)?.replace(/\+/g, ' ')} ${wlName ? 
                                    ', وتفعيل إضافة '+decodeURIComponent(wlName)?.replace(/\+/g, ' '): ''} بنجاح`}
                            </h5>
                           
                            <div className="my-4">
                                <SuccessVerification />
                            </div>
                            <a
                                href='/'
                                className='primary-btn py-2 px-4 mt-3'
                                style={{ width: "fit-content" }}>
                                {t("GoToHomePage")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
