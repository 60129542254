import React, { useState } from "react";
import * as userApi from "../../api/userApi";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../components/layouts/app-layout";
import PasswordForm from "../../components/section-components/registration/PasswordForm";
import { DARK_TEMPLATES } from "../../constants";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";
 
const WLSetNewPassword = (props) => {
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  const [disable, setDisable] = React.useState(false);
  const { countryCode, phone } = useParams();
  const { templateId } = useAuth();
  const [user, setUser] = useState({
    id: null,
    password: "",
    confirmpassword: "",
    countryCode: countryCode,
    phone: phone,
    language: "0",
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { t } = useTranslation();

  function handleChange({ target }) {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  }

  function formIsValid() {
    const _errors = {};

    if (!user.password) _errors.password = t("EnterThePassword");
    if (!user.confirmpassword)
      _errors.confirmpassword = t("ReEnterThePassword");

    if (user.password !== user.confirmpassword)
      _errors.confirmpassword = t("PasswordsDoNotMatch");

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    if (!formIsValid()) return;
    setDisable(true);

    userApi.setPasswordForUser(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        history("/admin/success-reset-password/");
      } else {
        const _errors = {};
        _errors.password = _userInfo.resStr;
        setErrors(_errors);
        setDisable(false);
      }
    }).finally(() => setIsSubmitting(false));
  }

  return (
    <AppLayout pageTitle={t("SetPassword")} >
      <div className="wl-login pd-top-90 mg-bottom-100 text-center"
        style={{ background: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : `url(${publicUrl}assets/img/pattern.png)` }}>
        <div className='login-modal' dir="ltr" >
          <div className='col-12 col-sm-9'>
            <form>
              <div className='row p-0 mx-0 w-100'>
                <PasswordForm
                  errors={errors}
                  user={user}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  disable={disable}
                  isSubmitting={isSubmitting}
                  admin={true}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default WLSetNewPassword;
