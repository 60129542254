import React from 'react'
import Close from '@mui/icons-material/Close';
import { Dialog, DialogContent } from '@mui/material';
import PropertyDetailsSection from "../../../scenes/shared/properties/components/property-details";
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const PropertyDialog = ({ openPropertyModal, setOpenPropertyModal, itemClickedId, setitemClickedId }) => {

    const history = useNavigate();
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            sx={{
                '& .MuiPaper-root': {
                    margin: 0,
                    width:'calc(100% - 30px)'
            }}}
            open={openPropertyModal}
            className='custom-dialog property-details-dialog'
            style={{ direction: "rtl", textAlignLast: "right" }}
            onClose={() => setOpenPropertyModal(false)}>
            <DialogContent sx={{ overflowX: "hidden" }} >
                <span
                    onClick={() => setOpenPropertyModal(false)}
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        left: "7px",
                        top: "7px",
                        zIndex: 99999,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        background: '#FFF',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                    }}>
                    <Close />
                </span>
                <span
                    onClick={() => history(`/property-details/${itemClickedId}`)}
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        left: "45px",
                        top: "7px",
                        zIndex: 99999,
                        width: 30,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        background: '#FFF',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                    }}>
                    <OpenInNewIcon />
                </span>
                <div className='row justify-content-start mt-3 m-0'>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div
                            style={{
                                flexGrow: 1,
                                width: "100%",
                                padding: "0",
                                textAlign: "right",
                            }}>
                            <PropertyDetailsSection id={itemClickedId} isMap={true} onItemSelected={(selectedId) => {
                                document.querySelector('.MuiDialogContent-root')?.scroll({ top: 0, left: 0, behavior: "smooth" });
                                setitemClickedId(selectedId);
                            }} />
                        </div>
                    </div>
                </div>
                <div className="scroll-indicator"></div>
            </DialogContent>
        </Dialog>
    );
}

export default PropertyDialog;
