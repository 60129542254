import React from "react";
import { useEffect } from "react";
import AppLayout from "../../components/layouts/app-layout";
import { ListArrow } from "../../constants/icons";
import PageHeader from "../../components/global-components/page-header";
import { useTranslation } from "react-i18next";

const WLIntellectualPropertyRights = () => {

  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout pageTitle={t("IntellectualPropertyRights")} withFooter>
      <PageHeader name={t("IntellectualPropertyRights")} />
      <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
        <div
          className={`row align-items-center justify-content-end mx-0`}
          dir="rtl"
          style={{ marginTop: '-50px !important' }}
        >
          <div className="col-12 p-0 text-right mb-2">
            <div className='custom-card' style={{ minHeight: 'auto' }}>
              <div className="card-header">
                <h4 style={{ color: 'var(--main-color-one)' }}>سياسة حقوق الملكية الفكرية</h4>
              </div>
              <div className="card-body">
                <ul className="custom-list">
                  <li><ListArrow />
                    عند إسهامك بأي محتوى على (منصة أماكن)، تمنح بذلك (الأساليب المبتكرة للتسويق الإلكتروني) حقوقًا غير حصرية، قابلة للنقل والترخيص الفرعي، بدون تعويض، لاستخدام المحتوى الذي تشاركه. هذه الحقوق تشمل، دون حصر:
                    <ul className="pt-3">
                      <li><b>حق النسخ</b>: القدرة على نسخ المحتوى لأغراض التشغيل والنسخ الاحتياطي والأرشفة.</li>
                      <li><b>حق التعديل</b>: القدرة على تعديل، تغيير، أو إنشاء أعمال مشتقة من المحتوى المقدم، وذلك وفقًا للضروريات التشغيلية أو التحسينات أو التحديثات.</li>
                      <li><b>حق التوزيع</b>: القدرة على توزيع، بث، عرض، ترخيص، أو بيع المحتوى لأطراف ثالثة أو للجمهور بصورة عامة.</li>
                      <li>تأكيدًا على ذلك، تضمن بأنك تملك حقوق المحتوى المشارك أو لديك كافة الصلاحيات اللازمة لمنح هذه الحقوق. كما توافق على تعويض (الأساليب المبتكرة للتسويق الإلكتروني) عن أي دعاوى أو خسائر قد تنجم عن استخدام المحتوى الذي توفره.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default WLIntellectualPropertyRights;
