import React, { useState, useEffect } from "react";
import { saveWLAboutUs, getWLAboutUs } from "../../../api/userApi";
import { toast } from "react-toastify";
import SubHeader from "../../../components/global-components/sub-header";
import UploadImage from "../../../components/global-components/upload-image";
import { useAuth } from "../../../helpers/context";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const [loading, setLoading] = useState(false);
  const { token, vendor } = useAuth();
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
  const [data, setData] = useState('');
  const [ImageTOView, setImageTOView] = useState(publicUrl + "assets/img/others/7.png?" + Math.random());
  const { t } = useTranslation();
  
  useEffect(() => {
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }
    const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
    if (aboutStorage?.content) {
      setData(aboutStorage?.content);
      setImageTOView(aboutStorage?.image || publicUrl + "assets/img/others/7.png?" + Math.random());
    } else {
      getWLAboutUs({ userName: vendor, language: "0" }).then(
        (_DefLocation) => {
          if (_DefLocation.resCode === 0) {
            setData(_DefLocation.response.aboutUs);
          }
        }
      );
    }

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },// eslint-disable-next-line
    [vendor]);
 
  function changeImage(e) {
    setImageTOView(e ? e : (publicUrl + "assets/img/others/7.png?" + Math.random()));
  }

  function onSave() {
    if (DEMO_VENDORS.includes(vendor)) {
      setLoading(true);
      const aboutStorage = localStorage.getItem(`about-${vendor}`) ? JSON.parse(localStorage.getItem(`about-${vendor}`)) : undefined;
      localStorage.setItem(`about-${vendor}`, JSON.stringify({
        content: data,
        image: aboutStorage?.image || ''
      }));
      toast.success(t("SavedSuccessfully"));
      setLoading(false);

    } else {
      setLoading(true);
      saveWLAboutUs({
        userId: token,
        language: "0",
        content: data,
      })
        .then((response) => {
          if (response.resCode === 0) {
            toast.success(t("SavedSuccessfully"));
          } else {
            toast.error(response.resStr);
          }
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <SubHeader onOK={onSave} loading={loading} />
      <div className="cards-wrapper about-page-wrapper">
        <div className='custom-card'>
          <div className="card-header">
            <h4>{t("AboutUs")}</h4>
            <h6>{t("WriteAnOverviewOfYourCompany")}</h6>
          </div>
          <div className="card-body" dir="ltr">
            {/* <Editor
              editorState={editorState}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              placeholder='أدخل النص هنا'
              toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'] }}
              onEditorStateChange={setEditorState}
            /> */}
            <div className={`toolbar-container`}></div>
            <CKEditor
              editor={DecoupledEditor}
              config={{
                language: {
                  ui: 'ar',
                  content: 'ar',
                },
                toolbar: {
                  items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                    '|', 'bold', 'italic', 'strikethrough',
                    '|', 'alignment',
                    '|', 'link', 'blockQuote',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                  ],
                  shouldNotGroupWhenFull: false
                },
                mediaEmbed: false
              }}
                                
              data={data}
              onReady={(editor) => {
                const toolbarContainer = document.querySelector(`.toolbar-container`);
                toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
              }}
              onChange={(event, editor) => {
                const content = editor.getData();
                setData(content);
              }}
            />
            
          </div>
        </div>
        <div className='custom-card'>
          <div className="card-header">
            <h4>{t("MainImage")}</h4>
            <h6>{t("ChooseAnImageThatExpressesYourVision")}</h6>
          </div>
          <div className="card-body">
            <div className="m-auto d-flex flex-column justify-content-center align-items-center position-relative">
              <img className='about-img mb-4' src={ImageTOView} alt='about-img' />
              <UploadImage
                token={token}
                imgUrl='/assets/img/others/7.png'
                imgSource='assets/img/others/others/'
                section="about"
                goNext={(e) => changeImage(e.base64Data)}
              />
            </div>
              
          </div>
        </div>
      </div>

      {/* <div className='col-lg-6 align-self-center'>
        <div className='section-title pd-left mb-0'>
          <div style={{ direction: "ltr", background: "white" }}> */}
          
      {/* <textarea
                    disabled
                    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                  /> */}
      {/* </div> */}
      {/* <Button
            variant='contained'
            type='submit'
            color='primary'
            onClick={(e) => onSave()}>
            {t("Save")}
          </Button> */}
      {/* <div
            dangerouslySetInnerHTML={{
              __html: Content,
            }}></div> */}
      {/* </div>
      </div> */}
      {/* <div className='col-lg-6 mb-4 mb-lg-0'>
      
      </div> */}
   
      {/* </div> */}
    </>
  );
}
