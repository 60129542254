import React, { useEffect, useState } from "react";
import {
  getMyTickets,
  getTicketListSubjects,
  createATicket,
} from "../../../api/userApi";
// import { ReactComponent as BackToList } from "../../../assets/media/icons/backtolist.svg";
import { ProfileType } from "../../../Config/Config";
import ChatMessage from "./Message/ChatMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "@mui/material/Button";
import { Alert, CircularProgress, Dialog, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Add, ArrowBack } from "@mui/icons-material";
import { useAuth } from "../../../helpers/context";
import { DEMO_VENDORS } from "../../../constants";
import { getDemoSnakbar } from "../../../helpers";
import NoData from "../../global-components/no-data";
import LoadingButton from "../../global-components/loading-btn";
import { useTranslation } from "react-i18next";

// Chat list component - To display list
const ChatList = (props) => {
  let publicUrl = process.env.REACT_APP_URL + "/";
  const history = useNavigate();
  const { token, UserId, vendor } = useAuth();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [showProfileDetail, setshowProfileDetail] = useState(false);
  const [TicketSubject, setTicketSubject] = useState([]);
  const [SelectedTicketSubject, setSelectedTicketSubject] = useState("0");
  const [isSubmittingTicket, setIsSubmittingTicket] = useState(false);
  const [errors, setErrors] = useState(false);
  let { id } = useParams();
  const isAdmin = window.location.href.includes('admin');
  let propertyId = props.propertyId || "";
  const [NewTicket, setNewTicket] = useState({
    userId: token,
    subjectId: "",
    createdBy: token,
    language: "0",
    screenshots: "",
    typeOfFile: "",
    notes: "",
    propertyId: propertyId,
  });
  const { t } = useTranslation();

  useEffect(() => {
    getTicketListSubjects({
      language: "0",
      isProperty: isAdmin ? (propertyId !== "" ? '1' : '2') : (propertyId !== "" ? "1" : "0"),
    }).then((_TicketSubject) =>
      setTicketSubject(_TicketSubject.response.ticketSubjectArray)
    );
    setSelectedTicketSubject(
      props.SelectedTicketSubject ? props.SelectedTicketSubject : "0"
    );
    if (propertyId !== "") {
      setshowProfileDetail(!showProfileDetail);
      // document.body.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SelectedTicketSubject, props.propertyId]);

  let options = TicketSubject.map((__TicketSubject) => ({
    value: __TicketSubject.subjectId,
    label: __TicketSubject.subject,
  }));

  function handleSubjectChanges(e) {
    setSelectedTicketSubject(e.target.value);
    setNewTicket({
      ...NewTicket,
      subjectId: e.target.value,
    });
  }

  function onSubmitNewTicket() {
    if (NewTicket.subjectId === "" || NewTicket.notes === "") {
      setErrors(true);
    } else {
      setIsSubmittingTicket(true);
      createATicket(NewTicket).then((_FeedBackInfo) => {
        if (_FeedBackInfo.resCode === 0) {
          toast.success(t("SentSuccessfully"));
          toggleShowProfileDetail();
          getData();
        } else toast.error(_FeedBackInfo.resStr);
      }).finally(() => setIsSubmittingTicket(false));
    }
   
  }

  const handleClose = () => {
    setErrors(false);
    toggleShowProfileDetail();
    setSelectedTicketSubject("0");
    // setUser({
    //   userId: token,
    //   language: "0",
    //   page: "1",
    //   pageSize: "100",
    // });
  }

  const [user] = useState({
    userId: token,
    language: "0",
    page: "1",
    pageSize: "100",
  });
  const [Mesg, setMesg] = useState("wait");

  const [Tickets, setTickets] = useState([]);

  function getDateName(unix_timestamp) {
    var date = moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate();
    var todaysDate = new Date();
    var Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
    if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
      return new Intl.DateTimeFormat("ar-SY", {
        hour: "numeric",
        minute: "numeric",
      }).format(moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate());
    else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
      return "الأمس";
    else
      return new Intl.DateTimeFormat("ar-SY", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
  }

  const toggleShowProfileDetail = () => {
    setshowProfileDetail(!showProfileDetail);
    // document.body.click();
  };

  const getData = () => {
    getMyTickets(user).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        let ticket = [];
        ticket = _userInfo.response.ticketArray;
        if (ticket.length > 0) {
          let ticketArray = [];
          ticket.forEach((element) => {
            ticketArray.push({
              ChatUserId: element.id,
              isActive: false,
              to: (isAdmin ? "/admin/ticket/" : "/Tickets/") + element.id,
              name: element.title,
              profile: "",
              profileType: ProfileType.Image,
              time: getDateName(element.updatedAt),
              message: element.lastMessage,
              status: element.status,
              subject: element.violation.violationText ? "تم التبليغ" : "",
              badge: element.statusName,
            });
          });
          setTickets(ticketArray);
          if (!id)
            history(ticketArray[0].to);

        } else {
          setMesg("لم يتم العثور على رسائل سابقة");
        }
      } else {
        const _errors = {};
        _errors.phone = _userInfo.resStr;
        setMesg("لم يتم العثور على رسائل سابقة");
      }
    });
  }

  useEffect(() => {
    if (token) {
      getData();
    }
  },    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UserId, publicUrl, token, user]);
  // Get userid from parameter to load perticular user ticket history

  function handleChatClicks(id) {
    var newTickets = Tickets.map((el) => {
      if (el.ChatUserId === id)
        return Object.assign({}, el, { isActive: true });
      return el;
    });
    setTickets(newTickets);
    props.handleChatClick();
  }

  return token && !id && (
    <aside className={`sidebar ${isAdmin ? 'radius-16 border shadow' : ''}`}>
      <div className='tab-content'>
        <div className='tab-pane active' id='chats-content'>
          <div className='d-flex flex-column h-100'>
            <div
              className='h-100'
              id='chatContactsList'
              style={{ backgroundColor: "#fff" }}>
              <div
                className='sidebar-header sticky-top py-2 d-flex justify-content-between align-items-center'
                style={{
                  height: "66px",
                  paddingInline: '18px'
                }}>
                <div className='d-flex justify-content-between align-items-center'>
                  {!isAdmin && (
                    <Link className='contacts-link' to='/'>
                      <button
                        className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted'
                        style={{
                          paddingTop: "8px",
                          marginLeft: "8px",
                        }}
                        type='button'>
                        <ArrowBack style={{ transform: 'rotate(180deg)' }} />
                      </button>
                    </Link>
                  )}
                  
                  <h5
                    className='font-weight-semibold mb-0 w-100'
                    style={{
                      paddingTop: "8px",
                      textAlignLast: "right"
                    }}>
                    {isAdmin ? 'الدعم الفنّي' : t("HelpCenter")}
                  </h5>
                </div>
                {!id && <Button
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  className="primary-btn px-3 py-1"
                  onClick={toggleShowProfileDetail}>
                  <Add />
                  إضافة
                </Button>}
              </div>

              <ul
                className='contacts-list'
                id='chatContactTab'
                data-chat-list=''>
                {Tickets.length > 0 ? (
                  Tickets.map((ticket, index) => {
                    return (
                      <ChatMessage
                        key={"ticket" + index}
                        {...ticket}
                        handleChatClick={() => {
                          handleChatClicks(ticket.ChatUserId);
                        }}
                      />
                    );
                  })
                ) : (
                  <div>
                    <h6 className='text-align-last-center mt-3' dir='rtl'>
                      {Mesg === 'wait' ? <CircularProgress /> : <NoData msg={Mesg} />}
                    </h6>
                  </div>
                )}
              </ul>

              <Dialog
                onClose={handleClose}
                maxWidth="xs"
                className="custom-dialog "
                sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)', padding: 0 } }}
                open={showProfileDetail}
              >
                <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
                  <div className='modal2 '>
                    <button className='close' onClick={handleClose}>
                      &times;
                    </button>
                    <div className='login-modal' style={{ minWidth: '100%' }}>
                      <div className='col-10 col-sm-9'>
                        <h4 style={{ fontSize: '28px' }}>إضافة شكوى/اقتراح جديد</h4>
                        <FormControl className="mb-3 mt-4 col-12">
                          <InputLabel id="type-label">{t("Subject")}</InputLabel>
                          <Select
                            label={t("Subject")}
                            labelId="type-label"
                            name='name'
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size='small'
                            variant="outlined"
                            required
                            value={SelectedTicketSubject}
                            onChange={(e) => handleSubjectChanges(e)}
                          >
                            {options.map(opt => <MenuItem value={opt.value} key={opt.value}>{opt.label}</MenuItem>)}
                          </Select>
                          <FormHelperText dir="rtl">
                            {errors && SelectedTicketSubject === '0' ?
                              <Alert severity="error" className="custom-alert" style={{ width: 'fit-content' }}>
                                {t("ThisFieldIsMandatory")}
                              </Alert>
                              : ''
                            }
                          </FormHelperText>
                        </FormControl>
                      
                        <FormControl className="mb-3 col-12">

                          <TextField
                            label={t("Message")}
                            required
                            sx={{ width: "100%", direction: 'rtl' }}
                            className=" text-right"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder='يرجى كتابة نص الشكوى/الاقتراح بالتفصيل'
                            value={user.message}
                            size='small'
                            multiline
                            rows={6}
                            onChange={(e) =>
                              setNewTicket({
                                ...NewTicket,
                                notes: e.currentTarget.value,
                              })
                            }
                          />
                          <FormHelperText dir="rtl">
                            {errors && !user.message ?
                              <Alert severity="error" style={{ width: 'fit-content' }} className="custom-alert">
                                {t("ThisFieldIsMandatory")}
                              </Alert>
                              : ''
                            }
                          </FormHelperText>
                        </FormControl>
                        <LoadingButton
                          classes='btn primary-btn px-3 mb-3 w-100 d-flex justify-content-center'
                          loading={isSubmittingTicket}
                          handleClick={(e) => {
                            if (DEMO_VENDORS.includes(vendor)) {
                              setOpenDemoSnackBar(true);
                            } else {
                              onSubmitNewTicket();
                            }
                          }}
                          label={t("Send")}
                        />
                          
                      </div>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default ChatList;
