import React, { Component } from "react";
import { Link } from "react-router-dom";

class Error extends Component {
  render() {
    return (
        <div className='error-page'>
          <h2>404</h2>
          <p>أُووبس! هناك خطأ ما</p>
          <Link to='/'>انتقل إلى الصفحة الرئيسية</Link>       
          <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-1" />
          <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-2" />
          <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-3" />
          <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-4" />
          <img src="/assets/img/404.svg" alt="404" className="image-404"/>
        </div>
    );
  }
}

export default Error;
