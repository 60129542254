import React, { useState, useEffect } from "react";
import EditProperty from "./components/edit-property";
import { getUserProile } from "../../../api/userApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  getAllParamsForAddProperty,
  getPropertyDescription,
} from "../../../api/propertyApi";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import Forbidden from "../403";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const AddPropertyStep3 = (props) => {
  const { token, UserId } = useAuth();
  const isAdmin = window.location.href.includes("admin") ? true : false;
  const [user] = useState({
    userId: token,
    language: "0",
  });
  const [AllParams, setAllParams] = useState({});
  let { id, userId } = useParams();
  const [msg, setMsg] = useState("wait");
  const [UserVerified, setUserVerified] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        let cookiesWrapper = document.querySelector('.CookieConsent');
        if (cookiesWrapper)
          cookiesWrapper.style.display = 'none';
      }, 200)
    }
  },// eslint-disable-next-line
    [userId]);
  
  useEffect(() => {
    if (token)
      getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          if (_userInfo.response.userProfile.isUserVerified === "3") {
            getPropertyDescription({
              propertyId: id,
              language: "0",
              userId: UserId,
            })
              .then((property) => {
                if (property.resCode === 0) {
                  if (
                    _userInfo.response.userProfile.userId ===
                    property.response.userId
                  ) {
                    getAllParamsForAddProperty({
                      propertyType: property.response.propertyType,
                      userId: token,
                      language: "0",
                    })
                      .then((params) => {
                        if (params.resCode === 0) {
                          setAllParams(params.response);
                          setMsg("Done");
                        }
                      })
                      .catch((error) => {
                        throw error;
                      });
                  } else setMsg(t("YouHaveNoRightToThisRealEstateAD"));
                } else {
                  toast.error(property.resStr);
                }
              })
              .catch((error) => {
                throw error;
              });
          } else {
            setUserVerified(false);
            setMsg(t("YourAccountIsNotVerified"));
          }
        } else {
          const _errors = {};
          _errors.phone = _userInfo.resStr;
        }
      });
  }, // eslint-disable-next-line
    [UserId, id, token, user]);

  return (
    <AppLayout needAuth pageTitle={t("AddRealEstateAD")} withoutNav={isAdmin || userId}>
      {
        token && (
          <>
            {msg === "Done" ? (
              !isAdmin ? (
                <div
                  className='add-new-property-area px-4 pb-4'
                  // style={{ paddingTop: "70px" }}
                  style={{ marginTop: userId ? 0 : '75px' }}
                  dir='rtl'>
                  {" "}
                  <EditProperty
                    userId={user.userId}
                    propertyId={id}
                    AllParams={AllParams}
                    isEdit={false}
                    isAdmin={isAdmin}
                  />
                </div>
              ) : (
                <EditProperty
                  userId={user.userId}
                  propertyId={id}
                  AllParams={AllParams}
                  isEdit={false}
                  isAdmin={isAdmin}
                />
              )
            ) : (
              msg === 'wait' ? <CircularProgress />
                :
                UserVerified === false ?
                  <Forbidden btnLink={'/verify'} btnText={t("AccountVerification")} desc={msg} />
                  : <Forbidden btnLink={'/'} btnText={t("BackToHomePage")} desc={msg} />
            )}
          </>
        )
      }
    </AppLayout>
  );
};

export default AddPropertyStep3;
