
export const appVersion = '3.5.7';

export const DEFAULT_TEMPLATES = [1, 2];
export const MODERN_TEMPLATES = [3, 4];
export const DARK_TEMPLATES = [5, 6];
export const CLASSIC_TEMPLATES = [7, 8];

export const countryCodes = [
  { label: "SA +966", value: "+966" },
  { label: "+971", value: "UAE +971" },
  { label: "+973", value: "BH +973" },
  { label: "+965", value: "KW +965" },
  { label: "+212", value: "MO +212" },
  { label: "+213", value: "AL +213" },
  { label: "+90", value: "TU +90" },
  { label: "+1", value: "USA +1" },
  { label: "+91", value: "IN +91" },
];

export const plansFeatures = {
  6: [
    { name: "موقع إلكتروني (example.amakkn.com)", available: "1" },
    { name: "موقع إلكتروني بنطاق مستقل (example.com)", available: "1" },
    { name: "خريطة الإعلانات العقاريّة", available: "1" },
    { name: "المشاريع والاهتمامات", available: "0" },
    { name: "نظام إدارة العملاء", available: "0" },
    { name: "المدونة", available: "0" },
    { name: "ظهور حساب تويتر", available: "0" },
    { name: "مستخدمين فرعيين", available: "0" },
    { name: "3 قوالب", available: "1" }
  ],
  7: [
    { name: "موقع إلكتروني (example.amakkn.com)", available: "1" },
    { name: "موقع إلكتروني بنطاق مستقل (example.com)", available: "1" },
    { name: "خريطة الإعلانات العقاريّة", available: "1" },
    { name: "المشاريع والاهتمامات", available: "0" },
    { name: "نظام إدارة العملاء", available: "0" },
    { name: "المدونة", available: "0" },
    { name: "ظهور حساب تويتر", available: "0" },
    { name: "مستخدمين فرعيين", available: "0" },
    { name: "3 قوالب", available: "1" }
  ],
  8: [
    { name: "موقع إلكتروني (example.amakkn.com)", available: "1" },
    { name: "موقع إلكتروني بنطاق مستقل (example.com)", available: "1" },
    { name: "خريطة الإعلانات العقاريّة", available: "1" },
    { name: "المشاريع والاهتمامات", available: "1" },
    { name: "نظام إدارة العملاء", available: "1" },
    { name: "المدونة", available: "1" },
    { name: "ظهور حساب تويتر", available: "1" },
    { name: "مستخدمين فرعيين", available: "1" },
    { name: "10 قوالب", available: "1" }
  ],
  9: [
    { name: "موقع إلكتروني (example.amakkn.com)", available: "1" },
    { name: "موقع إلكتروني بنطاق مستقل (example.com)", available: "1" },
    { name: "خريطة الإعلانات العقاريّة", available: "1" },
    { name: "المشاريع والاهتمامات", available: "1" },
    { name: "نظام إدارة العملاء", available: "1" },
    { name: "المدونة", available: "1" },
    { name: "ظهور حساب تويتر", available: "1" },
    { name: "مستخدمين فرعيين", available: "1" },
    { name: "10 قوالب", available: "1" }
  ]
};

export const blackListDomains = ["www", "devsa2", "prodsa1"];

export const FREE_PLANS = [1, 4];
export const TRAIL_PLANS = [9, 10, 11, 12];
export const plansAvailabilty = {
  1: [],
  2: ["6", "7", "8", "9"],
};
export const NORMAL_PLANS_AVAILABILITY = {
  '1': ["2", "3"],
  '2': ["5", "6", '9', '10', '11', '12'],
}

export const wlPlansAvailabilty = {
  1: [],
  2: ['6', '7', "8", "9"],
}

export const premiumPlans = {
  1: ["3", "5"],
  2: ["7", "9"],
};

export const HIDDEN_FEATURES = [
  "1",
  "2",
  "3",
  "6",
  "7",
  "8",
  "9",
  "10",
  // "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "22",
  "23",
  "24",
  "26",
  "27",
  "29",
  "30",
  "32",
  "33",
  "34",
  "35",
  "36",
  "38",
  '42',
  '52',
  '39',
  '51',
  '40',
  '41',
  '50',
  '60'
];
export const HIDDEN_ROOMS = ["8", "12", "15",'27', '25', '43', '40', '60', '61'];
export const HIDDEN_ADITTIONAL_INFO_FOR_ADD = [
  "1",
  "4",
  "5",
  "6",
  "7",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  '22',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '50',
  '51',
  '52',
  '54',
  '55',
  '53',
  '8',
  '39',
  '56',
  '60'
];
export const HIDDEN_ADITTIONAL_INFO_FOR_PAPER = ["5", "6", "7", '50', '51', '52', '38', '56', '10', '23', '18', '21'];
export const HIDDEN_ADDITIONAL_INFO_IF_NO = ["19", "20", "14", "12", "13", "15", "30", "31", "33", "36", "37"];
export const IGNORED_VALIDATION_KEYS = ['1', '2', '3', '6', '7', '9', '10','12', '19', '23', '24', '26', '27', '29', '30', '32', '38', '39', '41', '42', '50', '51', '52', '60'];
export const PLANS_YEARLY_DISCOUNT = 10;

export const INDIVIDUAL_LICENSE_URI =
  "https://eservicesredp.rega.gov.sa/public/IndividualBroker/LicenseDetails/Bml/";
export const CORPORATE_LICENSE_URI =
  "https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bml/";

export const AD_THROUGH = [
  { id: 1, value: 'OwnerOffice', label: 'مالك - منشأة' },
  { id: 2, value: 'OwnerIndividual', label: 'مالك - فرد' },
  { id: 3, value: 'OwnerAgent', label: 'وكيل المالك' },
  { id: 4, value: 'BrokerIndividual', label: 'وسيط - فرد' },
  { id: 5, value: 'BrokerOffice', label: 'وسيط - منشأة' }
];

export const AD_CHANNELS = [
  { id:'1',value: 'LicensedPlatform', label: 'منصة مرخصة' },
  { id:'2',value: 'BulletinBoard', label: 'لوحة إعلانية' },
  { id:"3",value: 'SocialMediaPlatforms', label: 'منصات التواصل الإجتماعي' },
  { id:"4",value: 'Radio', label: 'الإذاعة' },
  { id:"5",value: 'Other', label: 'أخرى' },
];

export const AD_TYPES = [
  { id: "1", value: 'Sell', label: 'بيع' },
  { id: "2", value: 'Rent', label: 'ايجار' }
];

export const AD_PROPERTY_USAGE = [
  { id: "1", value: 'Agricultural', label: 'زراعي', visibleFor:['1','7','25'] },
  { id: "2", value: 'Residential', label: 'سكني' , visibleFor:['1','2','3','4','5','6','7','8','16','26'] },
  { id: "3", value: 'Commercial', label: 'تجاري', visibleFor:['1','7','8','9','10','11','12','13','14','15','16','17','18','20','21','24','26'] },
  { id: "4", value: 'Industrial', label: 'صناعي' , visibleFor:['1','12','16','17','19']},
  { id: "5", value: 'Healthy', label: 'صحي' , visibleFor:['1','21'] },
  { id: "6", value: 'Educational', label: 'تعليمي', visibleFor:['1','20'] }
];
export const AD_PROPERTY_UTILITIES = [
  { id: "1", value: 'Electricity', label: 'كهرباء' },
  { id: "2", value: 'Waters', label: 'مياه' },
  { id: "3", value: 'Sanitation', label: 'صرف صحي' },
  { id: "5", value: 'FixedPhone', label: 'هاتف' },
  { id: "6", value: 'FibreOptics', label: 'الياف ضوئية' },
  { id: "7", value: 'FloodDrainage', label: 'تصريف الفيضانات' }
  // “code”: “NoServices”,“name”: “لايوجد خدمات”
];
export const AD_PROPERTY_TYPES = [
  { id: "1", value: 'Land', label: 'أرض' },
  { id: "2", value: 'Floor', label: 'دور' },
  { id: "3", value: 'Apartment', label: 'شقة' },
  { id: "4", value: 'Villa', label: 'فيلا' },
  { id: "5", value: 'Studio', label: 'استوديو' },
  { id: "6", value: 'Room', label: 'غرفة' },
  { id: "7", value: 'RestHouse', label: 'استراحة' },
  // { id: "8", value: 'Compound', label: 'مجمع' },
  // { id: "9", value: 'Tower', label: 'برج' },
  { id: "10", value: 'Exhibition', label: 'معرض' },
  { id: "11", value: 'Office', label: 'مكتب' },
  { id: "12", value: 'Warehouses', label: 'مستودع' },
  // { id: "13", value: 'Booth', label: 'كشك' },
  // { id: "14", value: 'Cinema', label: 'سينما' },
  // { id: "15", value: 'Hotel', label: 'فندق' },
  // { id: "16", value: 'CarParking', label: 'مواقف سيارات' },
  // { id: "17", value: 'RepairShop', label: 'ورشة' },
  // { id: "18", value: 'Teller', label: 'صراف' },
  // { id: "19", value: 'Factory', label: 'مصنع' },
  // { id: "20", value: 'School', label: 'مدرسة' },
  // { id: "21", value: 'HospitalOrHealthCenter', label: 'مستشفى، مركز صحي' },
  // { id: "22", value: 'ElectricityStation', label: 'محطة كهرباء' },
  // { id: "23", value: 'TelecomTower', label: 'برج اتصالات' },
  // { id: "24", value: 'Station', label: 'محطة' },
  { id: "25", value: 'Farm', label: 'مزرعة' },
  { id: "26", value: 'Building', label: 'عمارة' }
];

export const AD_PROPERTY_FACES = [
  { id: "1", value: 'Eastern', label: 'شرقية' },
  { id: "2", value: 'Western', label: 'غربية' },
  { id: "3", value: 'Northern', label: 'شمالية' },
  { id: "4", value: 'Southern', label: 'جنوبية' },
  { id: "5", value: 'Northeast', label: 'شمالية شرقية' },
  { id: "6", value: 'Southeast', label: 'جنوبية شرقية' },
  { id: "7", value: 'Southwest', label: 'جنوبية غربية' },
  { id: "8", value: 'NorthWest', label: 'شمالية غربية' },
  { id: "9", value: 'ThreeStreets', label: 'ثلاثة شوارع' },
  { id: "10", value: 'FourStreets', label: 'اربعة شوارع' }
];

export const AD_PROPERTY_AGES = [
  { id: "1", value: 'New', label: 'جديد' },
  { id: "2", value: 'LessThanYear', label: 'اقل من سنة' },
  { id: "3", value: 'OneYear', label: 'سنة' },
  { id: "4", value: 'TwoYears', label: 'سنتين' },
  { id: "5", value: 'ThreeYears', label: 'ثلاث سنوات' },
  { id: "6", value: 'FourYears', label: 'اربع سنوات' },
  { id: "7", value: 'FiveYears', label: 'خمس سنوات' },
  { id: "8", value: 'SixYears', label: 'ست سنوات' },
  { id: "9", value: 'SevenYears', label: 'سبع سنوات' },
  { id: "10", value: 'EightYears', label: 'ثمان سنوات' },
  { id: "11", value: 'NineYears', label: 'تسع سنوات' },
  { id: "12", value: 'TenYears', label: 'عشر سنوات' },
  { id: "13", value: 'MoreThenTenYears', label: 'اكثر من عشر سنوات' },
];

export const REGIONS = [
  {
    "REGION_ID": "1",
    "REGIONNAME_AR": "الرياض",
    "REGIONNAME_EN": "Riyadh"
  },
  {
    "REGION_ID": "2",
    "REGIONNAME_AR": "مكة المكرمة",
    "REGIONNAME_EN": "Makkah Al Mukarramah"
  },
  {
    "REGION_ID": "3",
    "REGIONNAME_AR": "المدينة المنورة",
    "REGIONNAME_EN": "Al Madinah Al Munawwarah"
  },
  {
    "REGION_ID": "4",
    "REGIONNAME_AR": "القصيم",
    "REGIONNAME_EN": "Al Qassim"
  },
  {
    "REGION_ID": "5",
    "REGIONNAME_AR": "المنطقة الشرقية",
    "REGIONNAME_EN": "Eastern"
  },
  {
    "REGION_ID": "6",
    "REGIONNAME_AR": "عسير",
    "REGIONNAME_EN": "Asir"
  },
  {
    "REGION_ID": "7",
    "REGIONNAME_AR": "تبوك",
    "REGIONNAME_EN": "Tabuk"
  },
  {
    "REGION_ID": "8",
    "REGIONNAME_AR": "حائل",
    "REGIONNAME_EN": "Hail"
  },
  {
    "REGION_ID": "9",
    "REGIONNAME_AR": "الحدود الشماليه",
    "REGIONNAME_EN": "Northern Borders"
  },
  {
    "REGION_ID": "10",
    "REGIONNAME_AR": "جازان",
    "REGIONNAME_EN": "Jazan"
  },
  {
    "REGION_ID": "11",
    "REGIONNAME_AR": "نجران",
    "REGIONNAME_EN": "Najran"
  },
  {
    "REGION_ID": "12",
    "REGIONNAME_AR": "الباحة",
    "REGIONNAME_EN": "Al Bahah"
  },
  {
    "REGION_ID": "13",
    "REGIONNAME_AR": "الجوف",
    "REGIONNAME_EN": "Al Jawf"
  }
];

export const CITIES = [
   {
    "CITY_ID": "1",
    "CITYNAME_AR": "المنطرح وابو الحجر",
    "CITYNAME_EN": "Almantarih Wa'Abu Alhajar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "21282",
    "CITYNAME_AR": "الرياض",
    "CITYNAME_EN": "Riyadh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "2",
    "CITYNAME_AR": "ابو الخباريه",
    "CITYNAME_EN": "'Abu Alkhabarih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3",
    "CITYNAME_AR": "الاصنع",
    "CITYNAME_EN": "Alasnie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "4",
    "CITYNAME_AR": "السبتاء",
    "CITYNAME_EN": "Alsubta'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "5",
    "CITYNAME_AR": "عطفه المبروق",
    "CITYNAME_EN": "Eitfih Almabruq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Alkhalif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7",
    "CITYNAME_AR": "عقده عبس",
    "CITYNAME_EN": "Eaqadah Eabas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "8",
    "CITYNAME_AR": "الزهب",
    "CITYNAME_EN": "Alzahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9",
    "CITYNAME_AR": "مبركات",
    "CITYNAME_EN": "Mubrikat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10",
    "CITYNAME_AR": "الغصن",
    "CITYNAME_EN": "Alghasan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "11",
    "CITYNAME_AR": "الحشيفه",
    "CITYNAME_EN": "Alhashifuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12",
    "CITYNAME_AR": "ابو ذخيره",
    "CITYNAME_EN": "Abo Zakhirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13",
    "CITYNAME_AR": "صبياء",
    "CITYNAME_EN": "Sibya'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14",
    "CITYNAME_AR": "النعامية",
    "CITYNAME_EN": "Alnaeamia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15",
    "CITYNAME_AR": "الهجاريه",
    "CITYNAME_EN": "Alhajariah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "16",
    "CITYNAME_AR": "الخواجيه",
    "CITYNAME_EN": "Alkhawajih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "17",
    "CITYNAME_AR": "السوالمه",
    "CITYNAME_EN": "Alsawalimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "18",
    "CITYNAME_AR": "الباطنه",
    "CITYNAME_EN": "Albatinuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "19",
    "CITYNAME_AR": "الشمه",
    "CITYNAME_EN": "Alshamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "20",
    "CITYNAME_AR": "الرافعي",
    "CITYNAME_EN": "Alraafiei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "21",
    "CITYNAME_AR": "الطراشه",
    "CITYNAME_EN": "Altarashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "22",
    "CITYNAME_AR": "الفقره نجران",
    "CITYNAME_EN": "Alfaqruh Najran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "23",
    "CITYNAME_AR": "الفقره",
    "CITYNAME_EN": "Alfaqruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "24",
    "CITYNAME_AR": "عبدالله العيافي",
    "CITYNAME_EN": "Abdullah Aleiafiu (Aleyafi)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "25",
    "CITYNAME_AR": "الجعاونه",
    "CITYNAME_EN": "Aljaeawunah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "26",
    "CITYNAME_AR": "حله محسن المحاسنة",
    "CITYNAME_EN": "Halah Muhsin Almuhasana",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "27",
    "CITYNAME_AR": "الغراء",
    "CITYNAME_EN": "Alghara'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "28",
    "CITYNAME_AR": "الدواحشه",
    "CITYNAME_EN": "Aldawahishuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "29",
    "CITYNAME_AR": "تيهان",
    "CITYNAME_EN": "Tyhan (Hlat Tyhan)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "30",
    "CITYNAME_AR": "الخواره",
    "CITYNAME_EN": "Alkhawaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "31",
    "CITYNAME_AR": "الشواهية",
    "CITYNAME_EN": "Alshawahia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "32",
    "CITYNAME_AR": "الشعاره",
    "CITYNAME_EN": "Alshiearuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "33",
    "CITYNAME_AR": "القواعة",
    "CITYNAME_EN": "Alqawaea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "34",
    "CITYNAME_AR": "الحسينى",
    "CITYNAME_EN": "Alhusynaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "35",
    "CITYNAME_AR": "حلة الأحوس",
    "CITYNAME_EN": "Hulat Al'Uhus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "36",
    "CITYNAME_AR": "جخيره",
    "CITYNAME_EN": "Jakhirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "37",
    "CITYNAME_AR": "وتيشه",
    "CITYNAME_EN": "Watayshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "38",
    "CITYNAME_AR": "زباره الظاهر",
    "CITYNAME_EN": "Zabaruh Alzzahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "39",
    "CITYNAME_AR": "المحله",
    "CITYNAME_EN": "Almahaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "40",
    "CITYNAME_AR": "الملحاء الجديده",
    "CITYNAME_EN": "Almulaha' Aljadiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "41",
    "CITYNAME_AR": "فرشه الملحاء",
    "CITYNAME_EN": "Farashah Almilha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "42",
    "CITYNAME_AR": "حرف الحطب ابو الحطب",
    "CITYNAME_EN": "Harf Alhatab 'Abu Alhatab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "43",
    "CITYNAME_AR": "الرقيعيه",
    "CITYNAME_EN": "Alraqieih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "44",
    "CITYNAME_AR": "حله ابو الحصين حله المواسي",
    "CITYNAME_EN": "Halah 'Abu Alhasin Halh Almawasi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "45",
    "CITYNAME_AR": "غوان",
    "CITYNAME_EN": "Ghawan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "46",
    "CITYNAME_AR": "حله الشجن",
    "CITYNAME_EN": "Halh Alshajn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "47",
    "CITYNAME_AR": "فرشه المحله الجديده",
    "CITYNAME_EN": "Farashah Almahaluh Aljadiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "48",
    "CITYNAME_AR": "قايم الدش",
    "CITYNAME_EN": "Qayim Aldushi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "49",
    "CITYNAME_AR": "ام الخبر",
    "CITYNAME_EN": "Umm Alkhabar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "50",
    "CITYNAME_AR": "حله الزاهد",
    "CITYNAME_EN": "Halah Alzaahid Al Haydar (Alzahd)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "51",
    "CITYNAME_AR": "ام سعد",
    "CITYNAME_EN": "Umm Saed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "52",
    "CITYNAME_AR": "حله يحي جدع",
    "CITYNAME_EN": "Hulah Yahiu Jade",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "53",
    "CITYNAME_AR": "ام الثرث",
    "CITYNAME_EN": "Umm Althuruth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "54",
    "CITYNAME_AR": "ابو الطين",
    "CITYNAME_EN": "'Abu Altiyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "55",
    "CITYNAME_AR": "ام القضب",
    "CITYNAME_EN": "Umm Alqadb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "56",
    "CITYNAME_AR": "الجباليه",
    "CITYNAME_EN": "Aljabaliah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "57",
    "CITYNAME_AR": "حلة حسن بن موسى",
    "CITYNAME_EN": "Halah Hasan Bin Musaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "58",
    "CITYNAME_AR": "المعاين",
    "CITYNAME_EN": "Almaeayin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "59",
    "CITYNAME_AR": "ام القحفه المخلاف",
    "CITYNAME_EN": "Umm Alqahfuh Almikhlaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "60",
    "CITYNAME_AR": "حله الجحدلى",
    "CITYNAME_EN": "Halah Aljihudlaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "61",
    "CITYNAME_AR": "حله على حناف",
    "CITYNAME_EN": "Halah Ealaa Hinaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "62",
    "CITYNAME_AR": "ابو القعايد",
    "CITYNAME_EN": "'Abu Alqaeayid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "63",
    "CITYNAME_AR": "العطن",
    "CITYNAME_EN": "Aleutn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "64",
    "CITYNAME_AR": "نخلان",
    "CITYNAME_EN": "Nakhlan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "65",
    "CITYNAME_AR": "الهمامه",
    "CITYNAME_EN": "Alhimamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "66",
    "CITYNAME_AR": "العقيلى",
    "CITYNAME_EN": "Aleaqilaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "67",
    "CITYNAME_AR": "الظبيه",
    "CITYNAME_EN": "Alzabiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "68",
    "CITYNAME_AR": "القزع",
    "CITYNAME_EN": "Alqazae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "69",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Almuetarad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "70",
    "CITYNAME_AR": "العريش",
    "CITYNAME_EN": "Alearish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "71",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Alfarshuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "72",
    "CITYNAME_AR": "حله الشيخ حسن الذروي",
    "CITYNAME_EN": "Halah Alshaykh Hasan Aldhurawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "73",
    "CITYNAME_AR": "حلة البتاريه",
    "CITYNAME_EN": "Hulat Albitarih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "74",
    "CITYNAME_AR": "الحصامه",
    "CITYNAME_EN": "Alhisamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "75",
    "CITYNAME_AR": "الطمحه الحصامه العليا",
    "CITYNAME_EN": "Altamahuh Alhisamuh Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "76",
    "CITYNAME_AR": "ابوالسلع",
    "CITYNAME_EN": "Abwalsle",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "77",
    "CITYNAME_AR": "الشاخر",
    "CITYNAME_EN": "Alshaakhir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "78",
    "CITYNAME_AR": "ام الشباقاء",
    "CITYNAME_EN": "Umm Alshibaqa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "79",
    "CITYNAME_AR": "اللخبصية",
    "CITYNAME_EN": "Allakhabsia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "80",
    "CITYNAME_AR": "الجمالة",
    "CITYNAME_EN": "Aljamala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "81",
    "CITYNAME_AR": "الصامخ",
    "CITYNAME_EN": "Alssamikh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "82",
    "CITYNAME_AR": "الجاره القديمه",
    "CITYNAME_EN": "Aljaruh Alqadiumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "83",
    "CITYNAME_AR": "الزينيه",
    "CITYNAME_EN": "Alziyniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "84",
    "CITYNAME_AR": "حلة علي بن موسى",
    "CITYNAME_EN": "Hulat Eali Bin Musaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "85",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Aleashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "86",
    "CITYNAME_AR": "ام القحفه",
    "CITYNAME_EN": "Umm Alqahfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "87",
    "CITYNAME_AR": "العدايا",
    "CITYNAME_EN": "Aleadaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "88",
    "CITYNAME_AR": "حلة المكي",
    "CITYNAME_EN": "Hulat Almukii",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "89",
    "CITYNAME_AR": "حلة العرشي",
    "CITYNAME_EN": "Hulat Alearshi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "90",
    "CITYNAME_AR": "قبه",
    "CITYNAME_EN": "Qabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "91",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Alkhadra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "92",
    "CITYNAME_AR": "حله عطيه",
    "CITYNAME_EN": "Halah Eatih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "93",
    "CITYNAME_AR": "حله ابو مرعي",
    "CITYNAME_EN": "Halah 'Abu Marei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "94",
    "CITYNAME_AR": "الدحيقي",
    "CITYNAME_EN": "Aldahiqiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "95",
    "CITYNAME_AR": "الحشيبرية",
    "CITYNAME_EN": "Alhashibiria",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "96",
    "CITYNAME_AR": "الباحر",
    "CITYNAME_EN": "Albahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "97",
    "CITYNAME_AR": "الرونه",
    "CITYNAME_EN": "Alruwnuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "98",
    "CITYNAME_AR": "حله محمد عيسى",
    "CITYNAME_EN": "Halah Muhamad Eisaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "99",
    "CITYNAME_AR": "حله العقيبي",
    "CITYNAME_EN": "Halah Aleaqibiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "100",
    "CITYNAME_AR": "حله القميري",
    "CITYNAME_EN": "Halah Alqamiriu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "101",
    "CITYNAME_AR": "حله مشاري",
    "CITYNAME_EN": "Halh Mshary",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "102",
    "CITYNAME_AR": "حله الصهلولي",
    "CITYNAME_EN": "Halah Alsahluliu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "103",
    "CITYNAME_AR": "حله الحو تين",
    "CITYNAME_EN": "Halah Alhawa Tyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "104",
    "CITYNAME_AR": "حله بن علوان",
    "CITYNAME_EN": "Halah Bin Eilwaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "105",
    "CITYNAME_AR": "حله الحكمى",
    "CITYNAME_EN": "Halh Alhukmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "106",
    "CITYNAME_AR": "حله عبدالله قبع",
    "CITYNAME_EN": "Halah Eubdallah Qabe",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "107",
    "CITYNAME_AR": "حله مرعي بن شافي",
    "CITYNAME_EN": "Halah Mrwei Bin Shamy",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "108",
    "CITYNAME_AR": "حله الكلابيه",
    "CITYNAME_EN": "Halah Alkalabiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "109",
    "CITYNAME_AR": "ابو دنقور",
    "CITYNAME_EN": "'Abu Dnqur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "110",
    "CITYNAME_AR": "الجديين",
    "CITYNAME_EN": "Aljidiayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "111",
    "CITYNAME_AR": "زباره الجديين",
    "CITYNAME_EN": "Zubaruh Aljidiayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "112",
    "CITYNAME_AR": "شيبان",
    "CITYNAME_EN": "Shayban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "113",
    "CITYNAME_AR": "الحباتره",
    "CITYNAME_EN": "Alhabatiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "114",
    "CITYNAME_AR": "الجوابره",
    "CITYNAME_EN": "Qaryat Aljawabirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "115",
    "CITYNAME_AR": "الشواجرة والجرابية",
    "CITYNAME_EN": "Alshawajirat Waljarabia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "116",
    "CITYNAME_AR": "الكدمى",
    "CITYNAME_EN": "Alkudmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "117",
    "CITYNAME_AR": "نحس عليان",
    "CITYNAME_EN": "Nahs Eulyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "118",
    "CITYNAME_AR": "حرف عليان",
    "CITYNAME_EN": "Harf Eulyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "119",
    "CITYNAME_AR": "هيجه ام الرقع",
    "CITYNAME_EN": "Hayjah Umm Alraqe",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "120",
    "CITYNAME_AR": "امشلحه",
    "CITYNAME_EN": "Amshalahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "121",
    "CITYNAME_AR": "القنبره",
    "CITYNAME_EN": "Alqunbaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "122",
    "CITYNAME_AR": "الوجيه",
    "CITYNAME_EN": "Alwajih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "123",
    "CITYNAME_AR": "الضبيره",
    "CITYNAME_EN": "Aldabiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "124",
    "CITYNAME_AR": "طناطن",
    "CITYNAME_EN": "Tunatin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "125",
    "CITYNAME_AR": "عطفه وساع",
    "CITYNAME_EN": "Eitfih Wasae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "126",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "127",
    "CITYNAME_AR": "المقبص",
    "CITYNAME_EN": "Almuqabis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "128",
    "CITYNAME_AR": "الزبره",
    "CITYNAME_EN": "Alzabaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "129",
    "CITYNAME_AR": "حله راجح",
    "CITYNAME_EN": "Halah Rajih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "130",
    "CITYNAME_AR": "القحمه",
    "CITYNAME_EN": "Alqahmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "131",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Algharif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "132",
    "CITYNAME_AR": "الخوامج",
    "CITYNAME_EN": "Alkhurujuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "133",
    "CITYNAME_AR": "جر مسعود الجرالاعلي",
    "CITYNAME_EN": "Jara Maseud Aljralaely",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "134",
    "CITYNAME_AR": "جر ابوراسين الجر الاسفل",
    "CITYNAME_EN": "Jari Abwarasin Aljaru Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "135",
    "CITYNAME_AR": "المطلع",
    "CITYNAME_EN": "Almutalie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "136",
    "CITYNAME_AR": "الوحاشيه",
    "CITYNAME_EN": "Alwahashih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "137",
    "CITYNAME_AR": "المضويه",
    "CITYNAME_EN": "Almudawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "138",
    "CITYNAME_AR": "البزاره",
    "CITYNAME_EN": "Albizaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "139",
    "CITYNAME_AR": "حله محمد ضعافي النعمى",
    "CITYNAME_EN": "Halah Muhamad Daeafi Alnuemaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "140",
    "CITYNAME_AR": "حله احمد الناجعى",
    "CITYNAME_EN": "Halah 'Ahmad Alnaajieaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "141",
    "CITYNAME_AR": "الحسينيه",
    "CITYNAME_EN": "Alhusayniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "142",
    "CITYNAME_AR": "زباره الشيخ على بن حمود",
    "CITYNAME_EN": "Zibaruh Alshaykh Ealaa Bin Hamuwd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "143",
    "CITYNAME_AR": "الطوالبه",
    "CITYNAME_EN": "Altawalibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "144",
    "CITYNAME_AR": "حله الاشراف",
    "CITYNAME_EN": "Halah Al'Iishraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "145",
    "CITYNAME_AR": "السرين",
    "CITYNAME_EN": "Alsiriyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "146",
    "CITYNAME_AR": "حله البقاش",
    "CITYNAME_EN": "Halah Albiqash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "147",
    "CITYNAME_AR": "مردوسه",
    "CITYNAME_EN": "Maruduwsuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "148",
    "CITYNAME_AR": "ام سود",
    "CITYNAME_EN": "Umm Sud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "149",
    "CITYNAME_AR": "قايم حويس",
    "CITYNAME_EN": "Qayim Hawis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "150",
    "CITYNAME_AR": "الناجمه",
    "CITYNAME_EN": "Alnaajimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "151",
    "CITYNAME_AR": "ام البهم",
    "CITYNAME_EN": "Umm Albuhim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "152",
    "CITYNAME_AR": "الشقيبه",
    "CITYNAME_EN": "Alshaqibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "153",
    "CITYNAME_AR": "قائم المحروق الشرقي",
    "CITYNAME_EN": "Alkadruh (Qaayim Mahruq)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "154",
    "CITYNAME_AR": "المصياده",
    "CITYNAME_EN": "Almisiaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "155",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Alrayighuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "156",
    "CITYNAME_AR": "قمبره نخلان",
    "CITYNAME_EN": "Qamabruh Nakhlan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "157",
    "CITYNAME_AR": "باطنه امزعكه",
    "CITYNAME_EN": "Batin Alzaekah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "158",
    "CITYNAME_AR": "القفام",
    "CITYNAME_EN": "Alqafam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "159",
    "CITYNAME_AR": "قايم الجعفرى",
    "CITYNAME_EN": "Qaym Aljefra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "160",
    "CITYNAME_AR": "العزافى",
    "CITYNAME_EN": "Aleazafaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "161",
    "CITYNAME_AR": "الملعب",
    "CITYNAME_EN": "Almaleab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "162",
    "CITYNAME_AR": "المنقعره",
    "CITYNAME_EN": "Almunaqaeiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "163",
    "CITYNAME_AR": "قايم البصه",
    "CITYNAME_EN": "Qayim Albasih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "164",
    "CITYNAME_AR": "المعاسير",
    "CITYNAME_EN": "Almaeasir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "165",
    "CITYNAME_AR": "القحمه",
    "CITYNAME_EN": "Alqahmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "166",
    "CITYNAME_AR": "سر منيفه",
    "CITYNAME_EN": "Sirun Munifuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "167",
    "CITYNAME_AR": "المعسوم",
    "CITYNAME_EN": "Almaesum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "168",
    "CITYNAME_AR": "تربه",
    "CITYNAME_EN": "Tarabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "169",
    "CITYNAME_AR": "خالف محمد",
    "CITYNAME_EN": "Khalaf Muhamad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "170",
    "CITYNAME_AR": "ام الغلف",
    "CITYNAME_EN": "Umm Alghlf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "171",
    "CITYNAME_AR": "أم الغريف",
    "CITYNAME_EN": "Crusher Alfaqas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "172",
    "CITYNAME_AR": "الصافح",
    "CITYNAME_EN": "Alsaafih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "173",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almaruuh Wasae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "174",
    "CITYNAME_AR": "ام النصايب",
    "CITYNAME_EN": "Umm Alnasayib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "175",
    "CITYNAME_AR": "ام سرو",
    "CITYNAME_EN": "Umm Sru",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "176",
    "CITYNAME_AR": "ام سمر",
    "CITYNAME_EN": "Umm Samar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "177",
    "CITYNAME_AR": "العواديه",
    "CITYNAME_EN": "Aleawadiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "178",
    "CITYNAME_AR": "الصغيل",
    "CITYNAME_EN": "Alsaghil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "179",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Aldahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "180",
    "CITYNAME_AR": "مصم",
    "CITYNAME_EN": "Misam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "181",
    "CITYNAME_AR": "دغبج",
    "CITYNAME_EN": "Daghbij",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "182",
    "CITYNAME_AR": "الطفى",
    "CITYNAME_EN": "Altufaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "183",
    "CITYNAME_AR": "دهمين",
    "CITYNAME_EN": "Dahmeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "184",
    "CITYNAME_AR": "نقل المزامير",
    "CITYNAME_EN": "Naql Al Mazameer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "185",
    "CITYNAME_AR": "بلاد عافيه",
    "CITYNAME_EN": "Belad Afyeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "186",
    "CITYNAME_AR": "الحوايا",
    "CITYNAME_EN": "Al Huwaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "187",
    "CITYNAME_AR": "لحمه",
    "CITYNAME_EN": "Lahmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "188",
    "CITYNAME_AR": "حيد الحزنه",
    "CITYNAME_EN": "Hed Al Hazanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "189",
    "CITYNAME_AR": "ذوات القاعه وردحه الكعبي",
    "CITYNAME_EN": "Zawat Alqaa wa Rudhah Alqaabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "190",
    "CITYNAME_AR": "نيد الظالع",
    "CITYNAME_EN": "Ned At-Talei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "191",
    "CITYNAME_AR": "المطلعه",
    "CITYNAME_EN": "Al Matlaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "192",
    "CITYNAME_AR": "حيدان",
    "CITYNAME_EN": "Haydan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "193",
    "CITYNAME_AR": "المخشم والجوامع",
    "CITYNAME_EN": "Al Makhsham wa Aljawamei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "194",
    "CITYNAME_AR": "نيد ابار",
    "CITYNAME_EN": "Ned Abar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "195",
    "CITYNAME_AR": "الكشمة",
    "CITYNAME_EN": "Al Kashmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "196",
    "CITYNAME_AR": "القزعه الثويعى",
    "CITYNAME_EN": "Alquzaah Althawbei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "197",
    "CITYNAME_AR": "الرعان",
    "CITYNAME_EN": "Ar-Raan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "198",
    "CITYNAME_AR": "الاعواس",
    "CITYNAME_EN": "Al Aawas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "199",
    "CITYNAME_AR": "المرما وماحولها",
    "CITYNAME_EN": "Al Marma and surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "200",
    "CITYNAME_AR": "بقعة العلاج",
    "CITYNAME_EN": "Buqaat Al Elaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "201",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "As-Samadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "202",
    "CITYNAME_AR": "المشرف العبدلى",
    "CITYNAME_EN": "Al Mushref Al Abdeli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "203",
    "CITYNAME_AR": "فحيج",
    "CITYNAME_EN": "Faheej",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "204",
    "CITYNAME_AR": "المنهم والزور",
    "CITYNAME_EN": "Almunhem wa Az-Zur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "205",
    "CITYNAME_AR": "اللحب وذم شفاء",
    "CITYNAME_EN": "Allahab wa Zam Shefaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "206",
    "CITYNAME_AR": "المقبعى",
    "CITYNAME_EN": "Al Maqbei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "207",
    "CITYNAME_AR": "اللعثه العبدلى وماحولها",
    "CITYNAME_EN": "Allathah Alabdeli and surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "208",
    "CITYNAME_AR": "الشباب العبدلى",
    "CITYNAME_EN": "Ash-Shabab Alabdeli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "209",
    "CITYNAME_AR": "الغمر",
    "CITYNAME_EN": "Al Ghumur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "210",
    "CITYNAME_AR": "المرصده",
    "CITYNAME_EN": "Al Marsadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "211",
    "CITYNAME_AR": "المغاشي",
    "CITYNAME_EN": "Al Mughashi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "212",
    "CITYNAME_AR": "ذراع منفه",
    "CITYNAME_EN": "Zeraa Manafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "213",
    "CITYNAME_AR": "ال السلعي",
    "CITYNAME_EN": "Al Asalae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "214",
    "CITYNAME_AR": "آل اسلم",
    "CITYNAME_EN": "Al Salman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "215",
    "CITYNAME_AR": "بقعة مروح والعبسية",
    "CITYNAME_EN": "Buqaa Maruh wa Alabsyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "216",
    "CITYNAME_AR": "ال داوود",
    "CITYNAME_EN": "Al Daoud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "217",
    "CITYNAME_AR": "وادى ذبوب",
    "CITYNAME_EN": "Wadi Zabub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "218",
    "CITYNAME_AR": "ال روغه",
    "CITYNAME_EN": "Al Rawghah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "219",
    "CITYNAME_AR": "آل برقان",
    "CITYNAME_EN": "Al Burqan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "220",
    "CITYNAME_AR": "آل مقبل",
    "CITYNAME_EN": "Al Muqbel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "221",
    "CITYNAME_AR": "الشدنه",
    "CITYNAME_EN": "Ash-Shadanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "222",
    "CITYNAME_AR": "المجرم الداثري",
    "CITYNAME_EN": "Al Mugrem Ad-Datheri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "223",
    "CITYNAME_AR": "آل عجيبه",
    "CITYNAME_EN": "Al Ajeebah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "224",
    "CITYNAME_AR": "ذراع البحر",
    "CITYNAME_EN": "Zeraa Albahr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "225",
    "CITYNAME_AR": "آل حاوى والشباب",
    "CITYNAME_EN": "Al Hawi wa Ash-Shabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "226",
    "CITYNAME_AR": "البثنه",
    "CITYNAME_EN": "Albathanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "227",
    "CITYNAME_AR": "النفيعه",
    "CITYNAME_EN": "An-Nufayaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "228",
    "CITYNAME_AR": "الوعاله",
    "CITYNAME_EN": "Al Waalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "229",
    "CITYNAME_AR": "العذر ونيدالداره",
    "CITYNAME_EN": "Alauzr wa Ned Ad-Darah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "230",
    "CITYNAME_AR": "الجوه الشراحيلي وهيئة التطوير",
    "CITYNAME_EN": "Aljua Ash-Sharaheli wa Hayet At-Tatweer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "231",
    "CITYNAME_AR": "شكر وحزامران",
    "CITYNAME_EN": "Shukr wa Hazamran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "232",
    "CITYNAME_AR": "نيد قوع والمشتل",
    "CITYNAME_EN": "Ned Quaa wa Almashtal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "233",
    "CITYNAME_AR": "تغب وآل حنتيش وما حولها",
    "CITYNAME_EN": "Taghab wa Al Hantesh and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "234",
    "CITYNAME_AR": "ذراع العددين",
    "CITYNAME_EN": "Zeraa Aladween",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "235",
    "CITYNAME_AR": "الاشراف",
    "CITYNAME_EN": "Al Ashraaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "236",
    "CITYNAME_AR": "غره",
    "CITYNAME_EN": "Ghurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "237",
    "CITYNAME_AR": "الغاله ومزرعة الهيئة",
    "CITYNAME_EN": "Alghalah wa Farm of Alhayaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "238",
    "CITYNAME_AR": "الغميره",
    "CITYNAME_EN": "Al Ghumayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "239",
    "CITYNAME_AR": "جوه ال سلمان",
    "CITYNAME_EN": "Juah Al Salman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "240",
    "CITYNAME_AR": "صويقه والكساره",
    "CITYNAME_EN": "Suwayqah wa Alkasarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "241",
    "CITYNAME_AR": "نيد عقب",
    "CITYNAME_EN": "Ned Aqab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "242",
    "CITYNAME_AR": "نيد العادى والعمامي وما حولها",
    "CITYNAME_EN": "Ned Al Adi wa Alamami and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "243",
    "CITYNAME_AR": "الحليحل",
    "CITYNAME_EN": "Alhulayhel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "244",
    "CITYNAME_AR": "ذاري الوشاح",
    "CITYNAME_EN": "Zari Alweshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "245",
    "CITYNAME_AR": "الحسينة",
    "CITYNAME_EN": "Alhusaynah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "246",
    "CITYNAME_AR": "دحره الوشاح",
    "CITYNAME_EN": "Dahrah Alweshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "247",
    "CITYNAME_AR": "وادى البير",
    "CITYNAME_EN": "Wadi Albeir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "248",
    "CITYNAME_AR": "نيد الصدرولقعاد",
    "CITYNAME_EN": "Ned As-Sadrulqead",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "249",
    "CITYNAME_AR": "ال حسن قاسم",
    "CITYNAME_EN": "Al Hasan Qasim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "250",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "As-Safa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "251",
    "CITYNAME_AR": "القلاع",
    "CITYNAME_EN": "Al Qulaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "252",
    "CITYNAME_AR": "ال قاسم",
    "CITYNAME_EN": "Al Qasim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "253",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "As-Saryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "254",
    "CITYNAME_AR": "المفراوشطيةوماحولها",
    "CITYNAME_EN": "Almughri wazamshetyah and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "255",
    "CITYNAME_AR": "ال المقنع",
    "CITYNAME_EN": "Al Almuqnei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "256",
    "CITYNAME_AR": "المرويغه",
    "CITYNAME_EN": "Almurawbaghah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "257",
    "CITYNAME_AR": "الفرشة",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "258",
    "CITYNAME_AR": "المزام وماحولة",
    "CITYNAME_EN": "Almezam and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "259",
    "CITYNAME_AR": "وادى البير",
    "CITYNAME_EN": "Buqaa Beir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "260",
    "CITYNAME_AR": "الداثرى",
    "CITYNAME_EN": "Ad-Datheri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "261",
    "CITYNAME_AR": "حاذر",
    "CITYNAME_EN": "Hazer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "262",
    "CITYNAME_AR": "ال عياس",
    "CITYNAME_EN": "Al Ayyas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "263",
    "CITYNAME_AR": "ذراع اليحى على",
    "CITYNAME_EN": "Zeraa Aleehi Ali",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "264",
    "CITYNAME_AR": "القاضى",
    "CITYNAME_EN": "Alqadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "265",
    "CITYNAME_AR": "مرباء",
    "CITYNAME_EN": "Merbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "266",
    "CITYNAME_AR": "ال مساتر",
    "CITYNAME_EN": "Al Musater",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "267",
    "CITYNAME_AR": "الحدبان  بقعه ال سلمان",
    "CITYNAME_EN": "Alhudban Buqaa Al Salman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "268",
    "CITYNAME_AR": "ال مناور و ال ردة",
    "CITYNAME_EN": "Al Munawer wa Al Ruda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "269",
    "CITYNAME_AR": "الحشره",
    "CITYNAME_EN": "Alhasharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "270",
    "CITYNAME_AR": "الحوره",
    "CITYNAME_EN": "Alhawra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "271",
    "CITYNAME_AR": "المغروف",
    "CITYNAME_EN": "Almaghroof",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "272",
    "CITYNAME_AR": "خرقه",
    "CITYNAME_EN": "Kherqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "273",
    "CITYNAME_AR": "السلعيه",
    "CITYNAME_EN": "As-Seleiah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "274",
    "CITYNAME_AR": "نيد مجبا",
    "CITYNAME_EN": "Ned Majba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "275",
    "CITYNAME_AR": "المعرف",
    "CITYNAME_EN": "Almuaraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "276",
    "CITYNAME_AR": "ام عتمه",
    "CITYNAME_EN": "Om Atmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "277",
    "CITYNAME_AR": "جرفاء",
    "CITYNAME_EN": "Garfaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "278",
    "CITYNAME_AR": "القصبه والمديد",
    "CITYNAME_EN": "Alqasabah wa Almaded",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "279",
    "CITYNAME_AR": "غينه وادي النفق",
    "CITYNAME_EN": "Ghaynah Wadi An-Nafaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "280",
    "CITYNAME_AR": "آل سليمان جابر",
    "CITYNAME_EN": "Al Sulaiman Jaber",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "281",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "282",
    "CITYNAME_AR": "حرف الجعده",
    "CITYNAME_EN": "Harf Aljaadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "283",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Al Hafnah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "284",
    "CITYNAME_AR": "الهضمه",
    "CITYNAME_EN": "Alhudamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "285",
    "CITYNAME_AR": "ال محمد شريف",
    "CITYNAME_EN": "Al Mohamed Sherif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "286",
    "CITYNAME_AR": "الصومله",
    "CITYNAME_EN": "As-Sawmalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "287",
    "CITYNAME_AR": "الرجيف",
    "CITYNAME_EN": "Alrajeef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "288",
    "CITYNAME_AR": "الضحى العيدلي",
    "CITYNAME_EN": "Ad-Duha Alabdeli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "289",
    "CITYNAME_AR": "جبجبه",
    "CITYNAME_EN": "Gabgabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "290",
    "CITYNAME_AR": "الخشعه ونعيمه والغفو",
    "CITYNAME_EN": "Alkhushaah wa Nuaymah wa Alghafu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "291",
    "CITYNAME_AR": "المبتع",
    "CITYNAME_EN": "Almubtaghi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "292",
    "CITYNAME_AR": "الرقب وشميله",
    "CITYNAME_EN": "Ar-Ruqab wa Shumaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "293",
    "CITYNAME_AR": "ردحه وقيع والتؤامتان والصياخ",
    "CITYNAME_EN": "Rudhah Waqei wa At-Tawamatan wa As-Seyakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "294",
    "CITYNAME_AR": "شط البر",
    "CITYNAME_EN": "Shatr Albar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "295",
    "CITYNAME_AR": "العره",
    "CITYNAME_EN": "Alaurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "296",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "297",
    "CITYNAME_AR": "بقعه القريه",
    "CITYNAME_EN": "Buqaah Alqaryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "298",
    "CITYNAME_AR": "حبيل المسود",
    "CITYNAME_EN": "Hybail Almusawd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "299",
    "CITYNAME_AR": "الغليله",
    "CITYNAME_EN": "Alghulaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "300",
    "CITYNAME_AR": "النافيه والغليله",
    "CITYNAME_EN": "An-Nafyah wa Alghalylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "301",
    "CITYNAME_AR": "الضحى الحكمى",
    "CITYNAME_EN": "Ad-Duha Alhukami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "302",
    "CITYNAME_AR": "المخراقى والمجاهدين",
    "CITYNAME_EN": "Almekhrafi wa Almujahideen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "303",
    "CITYNAME_AR": "النوص وغابط",
    "CITYNAME_EN": "An-Nuwad wa Ghabet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "304",
    "CITYNAME_AR": "الشباب المشنوى بقعه الصره",
    "CITYNAME_EN": "Ash-Shabab Almashnawi Buqah As-Surah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "305",
    "CITYNAME_AR": "بقعة الضحى المشنوي",
    "CITYNAME_EN": "Buqah Ad-Duha Almashnawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "306",
    "CITYNAME_AR": "الوشر",
    "CITYNAME_EN": "Alwashar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "307",
    "CITYNAME_AR": "الذوير",
    "CITYNAME_EN": "Az-Duwayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "308",
    "CITYNAME_AR": "طالعه والمريع",
    "CITYNAME_EN": "Taleah wa Almurabaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "309",
    "CITYNAME_AR": "الغوله",
    "CITYNAME_EN": "Alghawlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "310",
    "CITYNAME_AR": "حيين",
    "CITYNAME_EN": "Hayeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "311",
    "CITYNAME_AR": "شمسيه",
    "CITYNAME_EN": "Shamsyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "312",
    "CITYNAME_AR": "الحواجب",
    "CITYNAME_EN": "Alhawajeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "313",
    "CITYNAME_AR": "الرمحه",
    "CITYNAME_EN": "Ar-Rumhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "314",
    "CITYNAME_AR": "الكرس",
    "CITYNAME_EN": "Alkaras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "315",
    "CITYNAME_AR": "النحت",
    "CITYNAME_EN": "An-Naht",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "316",
    "CITYNAME_AR": "حيد السلمين والمجاهدين وماحولها",
    "CITYNAME_EN": "Heed As-Salmeen wa Almujahideen and its surroundi*",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "317",
    "CITYNAME_AR": "بين مقرن والمجاهدين وماحولها",
    "CITYNAME_EN": "Ben Muqren wa Almujahideen and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "318",
    "CITYNAME_AR": "نقطة سلاح الحدود",
    "CITYNAME_EN": "Fara and border guards checkpoint",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "319",
    "CITYNAME_AR": "الهيجه",
    "CITYNAME_EN": "Al Hayjah Walashaush and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "320",
    "CITYNAME_AR": "الرصفه",
    "CITYNAME_EN": "Al Rasfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "321",
    "CITYNAME_AR": "ذبوب",
    "CITYNAME_EN": "Zaboob",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "322",
    "CITYNAME_AR": "ذراع الكبيره ومركز سلاح الحدود",
    "CITYNAME_EN": "Zeraa Alkaberah and border guards center",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "323",
    "CITYNAME_AR": "الحنا",
    "CITYNAME_EN": "Alhannah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "324",
    "CITYNAME_AR": "فرحه الدفرى",
    "CITYNAME_EN": "Farhet Ad-Dafri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "325",
    "CITYNAME_AR": "ثربه",
    "CITYNAME_EN": "Tharabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "326",
    "CITYNAME_AR": "مجرم الشراحيلي",
    "CITYNAME_EN": "Mujrem Ash-Sharaheli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "327",
    "CITYNAME_AR": "معشم",
    "CITYNAME_EN": "Muasham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "328",
    "CITYNAME_AR": "نيد اللمه",
    "CITYNAME_EN": "Ned Allammah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "329",
    "CITYNAME_AR": "السندر",
    "CITYNAME_EN": "As-Sandar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "330",
    "CITYNAME_AR": "المفلد",
    "CITYNAME_EN": "Almefled",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "331",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Alhashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "332",
    "CITYNAME_AR": "نيد المجزره",
    "CITYNAME_EN": "Ned Almagzarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "333",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "334",
    "CITYNAME_AR": "غمان",
    "CITYNAME_EN": "Ghuman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "335",
    "CITYNAME_AR": "بردان",
    "CITYNAME_EN": "Bardan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "336",
    "CITYNAME_AR": "ذراع المحماه",
    "CITYNAME_EN": "Zeraa Almahmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "337",
    "CITYNAME_AR": "اوباد",
    "CITYNAME_EN": "Awbad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "338",
    "CITYNAME_AR": "المشرف الدفرى",
    "CITYNAME_EN": "Almushref Ad-Dafri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "339",
    "CITYNAME_AR": "قرضه الحربي",
    "CITYNAME_EN": "Quradah Alharbi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "340",
    "CITYNAME_AR": "بقعة ذنبر",
    "CITYNAME_EN": "Buqet Zanbar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "341",
    "CITYNAME_AR": "الحذايا",
    "CITYNAME_EN": "Al Huzaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "342",
    "CITYNAME_AR": "الخيافين",
    "CITYNAME_EN": "Alkhayafeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "343",
    "CITYNAME_AR": "الشله",
    "CITYNAME_EN": "Ash-Shallah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "344",
    "CITYNAME_AR": "الطحله والسماويه وماحولها",
    "CITYNAME_EN": "Altalha wa As-Samawyah and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "345",
    "CITYNAME_AR": "سقام",
    "CITYNAME_EN": "Seqam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "346",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Ar-Raqabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "347",
    "CITYNAME_AR": "قمبورة وشط الصبايا",
    "CITYNAME_EN": "Qambura wa  Shat As-Sabaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "348",
    "CITYNAME_AR": "عنبوبه",
    "CITYNAME_EN": "Anbawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "349",
    "CITYNAME_AR": "العرضيه",
    "CITYNAME_EN": "Alaradyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "350",
    "CITYNAME_AR": "قزعه الحربي والحوية",
    "CITYNAME_EN": "Quzeit Alharbi wa Alhawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "351",
    "CITYNAME_AR": "النزلة",
    "CITYNAME_EN": "An-Nazalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "352",
    "CITYNAME_AR": "الحوبه وردحه غيلان",
    "CITYNAME_EN": "Alhawyah wa Radhet Ghaylan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "353",
    "CITYNAME_AR": "ريسان",
    "CITYNAME_EN": "Rebsan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "354",
    "CITYNAME_AR": "نقيل الجرباء",
    "CITYNAME_EN": "Naqeel Aljarbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "355",
    "CITYNAME_AR": "النصب والحلفا وماحولها",
    "CITYNAME_EN": "Alnusub wa Alhalfa and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "356",
    "CITYNAME_AR": "معزب خضر وماحولها",
    "CITYNAME_EN": "Maazeb Khedr and its surroundings",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "357",
    "CITYNAME_AR": "الزريبه",
    "CITYNAME_EN": "Az-Zaraybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "358",
    "CITYNAME_AR": "الكوابسة",
    "CITYNAME_EN": "Alkawabsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "359",
    "CITYNAME_AR": "فيفا",
    "CITYNAME_EN": "Fayfa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "360",
    "CITYNAME_AR": "ريده",
    "CITYNAME_EN": "Rydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "361",
    "CITYNAME_AR": "ربيعه",
    "CITYNAME_EN": "Rbyeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "362",
    "CITYNAME_AR": "ملحان",
    "CITYNAME_EN": "Malhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "363",
    "CITYNAME_AR": "دمشدان",
    "CITYNAME_EN": "Damashdan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "364",
    "CITYNAME_AR": "الشمله",
    "CITYNAME_EN": "Alshamluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "365",
    "CITYNAME_AR": "ذراع المجمعه",
    "CITYNAME_EN": "Dhirae Almujamaeih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "366",
    "CITYNAME_AR": "قبيس",
    "CITYNAME_EN": "Qabis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "367",
    "CITYNAME_AR": "نيد الوبا",
    "CITYNAME_EN": "Nayd Alwaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "368",
    "CITYNAME_AR": "ريده العزه",
    "CITYNAME_EN": "Riduh Aleazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "369",
    "CITYNAME_AR": "المداريب",
    "CITYNAME_EN": "Almadarib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "370",
    "CITYNAME_AR": "الفرحه",
    "CITYNAME_EN": "Alfarhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "371",
    "CITYNAME_AR": "الرخص",
    "CITYNAME_EN": "Alrafs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "372",
    "CITYNAME_AR": "وادى الحره",
    "CITYNAME_EN": "Wa'Adaa Alhuruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "373",
    "CITYNAME_AR": "الشمله",
    "CITYNAME_EN": "Alshamluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "374",
    "CITYNAME_AR": "دحره ال قوفع",
    "CITYNAME_EN": "Dahruh Al Qufae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "375",
    "CITYNAME_AR": "الخديع",
    "CITYNAME_EN": "Alkhadie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "376",
    "CITYNAME_AR": "الهرار",
    "CITYNAME_EN": "Alhirar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "377",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "378",
    "CITYNAME_AR": "حيد العقبه",
    "CITYNAME_EN": "Hyd Aleaqabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "379",
    "CITYNAME_AR": "حيد القريثى",
    "CITYNAME_EN": "Hyd Alqarithaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "380",
    "CITYNAME_AR": "المقومه",
    "CITYNAME_EN": "Almuqumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "381",
    "CITYNAME_AR": "ذم سلام",
    "CITYNAME_EN": "Dhum Salam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "382",
    "CITYNAME_AR": "شويره",
    "CITYNAME_EN": "Shawiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "383",
    "CITYNAME_AR": "محزومه",
    "CITYNAME_EN": "Mahzumah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "384",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Alrukabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "385",
    "CITYNAME_AR": "قصيره",
    "CITYNAME_EN": "Qsyrh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "386",
    "CITYNAME_AR": "المرقوع",
    "CITYNAME_EN": "Almarque",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "387",
    "CITYNAME_AR": "عفج",
    "CITYNAME_EN": "Eafaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "388",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Alqazeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "389",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Allasabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "390",
    "CITYNAME_AR": "كريت",
    "CITYNAME_EN": "Kuriyat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "391",
    "CITYNAME_AR": "حبيل نقعه",
    "CITYNAME_EN": "Habil Naqeih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "392",
    "CITYNAME_AR": "وادى خدور",
    "CITYNAME_EN": "Wa'Adaa Khudur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "393",
    "CITYNAME_AR": "خدور",
    "CITYNAME_EN": "Khudur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "394",
    "CITYNAME_AR": "الغميره",
    "CITYNAME_EN": "Alghamiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "395",
    "CITYNAME_AR": "حبيل قاسم",
    "CITYNAME_EN": "Habil Qasim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "396",
    "CITYNAME_AR": "المليحيه",
    "CITYNAME_EN": "Almalyhih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "397",
    "CITYNAME_AR": "نقيل القافله",
    "CITYNAME_EN": "Nuqil Alqafilah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "398",
    "CITYNAME_AR": "المقعد",
    "CITYNAME_EN": "Almaqead",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "399",
    "CITYNAME_AR": "قماح",
    "CITYNAME_EN": "Qamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "400",
    "CITYNAME_AR": "الشاخر",
    "CITYNAME_EN": "Alshaakhir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "401",
    "CITYNAME_AR": "الجرداء",
    "CITYNAME_EN": "Aljurada'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "402",
    "CITYNAME_AR": "الخايع",
    "CITYNAME_EN": "Alkhayie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "403",
    "CITYNAME_AR": "السهية",
    "CITYNAME_EN": "Alsahia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "404",
    "CITYNAME_AR": "شط مط",
    "CITYNAME_EN": "Shat Matun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "405",
    "CITYNAME_AR": "الباطن",
    "CITYNAME_EN": "Albatn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "406",
    "CITYNAME_AR": "الوغره",
    "CITYNAME_EN": "Alwaghruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "407",
    "CITYNAME_AR": "قله ال قطيل",
    "CITYNAME_EN": "Qulh Al Qatil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "408",
    "CITYNAME_AR": "الشملاء",
    "CITYNAME_EN": "Alshumala'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "409",
    "CITYNAME_AR": "العرض",
    "CITYNAME_EN": "Aleard",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "410",
    "CITYNAME_AR": "الجهويين",
    "CITYNAME_EN": "Aljihwiiyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "411",
    "CITYNAME_AR": "شرق الصيد",
    "CITYNAME_EN": "Shrq Alsayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "412",
    "CITYNAME_AR": "الميجاه",
    "CITYNAME_EN": "Almijah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "413",
    "CITYNAME_AR": "القري",
    "CITYNAME_EN": "Alqari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "414",
    "CITYNAME_AR": "نيد عوف",
    "CITYNAME_EN": "Nyd Euf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "415",
    "CITYNAME_AR": "العاهر",
    "CITYNAME_EN": "Aleahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "416",
    "CITYNAME_AR": "المثامل",
    "CITYNAME_EN": "Almathamil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "417",
    "CITYNAME_AR": "الضحيان",
    "CITYNAME_EN": "Aldahyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "418",
    "CITYNAME_AR": "الواغله",
    "CITYNAME_EN": "Alwaghiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "419",
    "CITYNAME_AR": "الهضه",
    "CITYNAME_EN": "Alhadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "420",
    "CITYNAME_AR": "جعشم",
    "CITYNAME_EN": "Jeshm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "421",
    "CITYNAME_AR": "البرده",
    "CITYNAME_EN": "Albarduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "422",
    "CITYNAME_AR": "المحتصى",
    "CITYNAME_EN": "Almuhtasaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "423",
    "CITYNAME_AR": "دعيتم",
    "CITYNAME_EN": "Dueitum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "424",
    "CITYNAME_AR": "حبيل محط",
    "CITYNAME_EN": "Habil Station",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "425",
    "CITYNAME_AR": "الكربه",
    "CITYNAME_EN": "Alkarabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "426",
    "CITYNAME_AR": "قعاد القرضه",
    "CITYNAME_EN": "Qaead Alqardih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "427",
    "CITYNAME_AR": "الصماء",
    "CITYNAME_EN": "Alsama'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "428",
    "CITYNAME_AR": "قله سليمان",
    "CITYNAME_EN": "Qulh Sulayman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "429",
    "CITYNAME_AR": "المجدع",
    "CITYNAME_EN": "Almajdae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "430",
    "CITYNAME_AR": "الغشوه",
    "CITYNAME_EN": "Alghushuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "431",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Alhijafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "432",
    "CITYNAME_AR": "المعزب",
    "CITYNAME_EN": "Almuezib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "433",
    "CITYNAME_AR": "الوابلة",
    "CITYNAME_EN": "Alwabila",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "434",
    "CITYNAME_AR": "الملزوز",
    "CITYNAME_EN": "Almalzuz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "435",
    "CITYNAME_AR": "المحصي",
    "CITYNAME_EN": "Almahsi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "436",
    "CITYNAME_AR": "القائد",
    "CITYNAME_EN": "Alqayid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "437",
    "CITYNAME_AR": "الحرثاء",
    "CITYNAME_EN": "Alhuratha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "438",
    "CITYNAME_AR": "حبيل الريينه",
    "CITYNAME_EN": "Habil Alriyynh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "439",
    "CITYNAME_AR": "حبيل الشث",
    "CITYNAME_EN": "Habil Alshathi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "440",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Alwaljuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "441",
    "CITYNAME_AR": "ذارى القضايا",
    "CITYNAME_EN": "Dharaa Alqadaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "442",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "443",
    "CITYNAME_AR": "الناذيه",
    "CITYNAME_EN": "Alnaadhih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "444",
    "CITYNAME_AR": "القزعة",
    "CITYNAME_EN": "Alqazeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "445",
    "CITYNAME_AR": "الراس",
    "CITYNAME_EN": "Alraas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "446",
    "CITYNAME_AR": "الغرزه",
    "CITYNAME_EN": "Algharzuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "447",
    "CITYNAME_AR": "المشاغل",
    "CITYNAME_EN": "Almashaghil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "448",
    "CITYNAME_AR": "الكدحه",
    "CITYNAME_EN": "Alkadahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "449",
    "CITYNAME_AR": "عطينه",
    "CITYNAME_EN": "Eutinh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "450",
    "CITYNAME_AR": "جعيره",
    "CITYNAME_EN": "Jaeirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "451",
    "CITYNAME_AR": "قعاد عنبه",
    "CITYNAME_EN": "Qaead Einbasih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "452",
    "CITYNAME_AR": "عدنه",
    "CITYNAME_EN": "Eadanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "453",
    "CITYNAME_AR": "الحاج",
    "CITYNAME_EN": "Wadi Allihaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "454",
    "CITYNAME_AR": "نيد الثاهره",
    "CITYNAME_EN": "Nayd Alththahirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "455",
    "CITYNAME_AR": "الملطه",
    "CITYNAME_EN": "Almilatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "456",
    "CITYNAME_AR": "صيحانه",
    "CITYNAME_EN": "Sihanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "457",
    "CITYNAME_AR": "العقد",
    "CITYNAME_EN": "Aleuqad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "458",
    "CITYNAME_AR": "العصر",
    "CITYNAME_EN": "Aleasr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "459",
    "CITYNAME_AR": "ذراع الحثيره",
    "CITYNAME_EN": "Dhirae Alhuthirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "460",
    "CITYNAME_AR": "الردم",
    "CITYNAME_EN": "Alradm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "461",
    "CITYNAME_AR": "جو العار",
    "CITYNAME_EN": "Jawin Alear",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "462",
    "CITYNAME_AR": "الغريفه",
    "CITYNAME_EN": "Algharifuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "463",
    "CITYNAME_AR": "نيد القرب",
    "CITYNAME_EN": "Nyd Alqrb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "464",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Alkharmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "465",
    "CITYNAME_AR": "الغافر",
    "CITYNAME_EN": "Alghafir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "466",
    "CITYNAME_AR": "الكرانه",
    "CITYNAME_EN": "Alkiranuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "467",
    "CITYNAME_AR": "السري",
    "CITYNAME_EN": "Alsiriyu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "468",
    "CITYNAME_AR": "الفريضه",
    "CITYNAME_EN": "Alfariduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "469",
    "CITYNAME_AR": "الثهير",
    "CITYNAME_EN": "Althahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "470",
    "CITYNAME_AR": "نيد الانفه",
    "CITYNAME_EN": "Nayd Alainfih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "471",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Aldaaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "472",
    "CITYNAME_AR": "المسيول",
    "CITYNAME_EN": "Almasyul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "473",
    "CITYNAME_AR": "وصيله",
    "CITYNAME_EN": "Wasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "474",
    "CITYNAME_AR": "غابط",
    "CITYNAME_EN": "Ghabit",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "475",
    "CITYNAME_AR": "العصيمه",
    "CITYNAME_EN": "Aleasimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "476",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Alsuwduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "477",
    "CITYNAME_AR": "برده",
    "CITYNAME_EN": "Baradah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "478",
    "CITYNAME_AR": "الخناديق",
    "CITYNAME_EN": "Alkhanadiq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "479",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "480",
    "CITYNAME_AR": "الحلمه",
    "CITYNAME_EN": "Alhilmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "481",
    "CITYNAME_AR": "المعينه",
    "CITYNAME_EN": "Almueianuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "482",
    "CITYNAME_AR": "نيد المخوقه",
    "CITYNAME_EN": "Nayd Almukhuqih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "483",
    "CITYNAME_AR": "المحموم",
    "CITYNAME_EN": "Almahmum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "484",
    "CITYNAME_AR": "ذارى  مغبر",
    "CITYNAME_EN": "Dharaa Mughbir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "485",
    "CITYNAME_AR": "القعاد",
    "CITYNAME_EN": "Alqaead",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "486",
    "CITYNAME_AR": "الخربه",
    "CITYNAME_EN": "Alkhurban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "487",
    "CITYNAME_AR": "الروحه",
    "CITYNAME_EN": "Alruwhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "488",
    "CITYNAME_AR": "الشباب",
    "CITYNAME_EN": "Alshabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "489",
    "CITYNAME_AR": "الفدنه",
    "CITYNAME_EN": "Alfadanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "490",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Aleurqub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "491",
    "CITYNAME_AR": "الحجراف",
    "CITYNAME_EN": "Alhijraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "492",
    "CITYNAME_AR": "المقوع",
    "CITYNAME_EN": "Almaquae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "493",
    "CITYNAME_AR": "عقم شيعى",
    "CITYNAME_EN": "Eaqim Alhinaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "494",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "495",
    "CITYNAME_AR": "لحج زايد",
    "CITYNAME_EN": "Lihajin Zayid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "496",
    "CITYNAME_AR": "اطول",
    "CITYNAME_EN": "'Atwal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "497",
    "CITYNAME_AR": "المقرث",
    "CITYNAME_EN": "Almuqarath",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "498",
    "CITYNAME_AR": "الناض",
    "CITYNAME_EN": "Alnnad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "499",
    "CITYNAME_AR": "الجعله",
    "CITYNAME_EN": "Aljaealuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "500",
    "CITYNAME_AR": "حبيل الثاهر",
    "CITYNAME_EN": "Habil Alththahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "501",
    "CITYNAME_AR": "جو العير",
    "CITYNAME_EN": "Jawin Aleir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "502",
    "CITYNAME_AR": "المحامه",
    "CITYNAME_EN": "Almahamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "503",
    "CITYNAME_AR": "المعطاف",
    "CITYNAME_EN": "Almietaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "504",
    "CITYNAME_AR": "الثهيره",
    "CITYNAME_EN": "Althahiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "505",
    "CITYNAME_AR": "السطيحه",
    "CITYNAME_EN": "Alsatihuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "506",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "507",
    "CITYNAME_AR": "المحمور",
    "CITYNAME_EN": "Almahmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "508",
    "CITYNAME_AR": "شريج",
    "CITYNAME_EN": "Sharij",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "509",
    "CITYNAME_AR": "المرجم",
    "CITYNAME_EN": "Almarjim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "510",
    "CITYNAME_AR": "الموعره",
    "CITYNAME_EN": "Almaweiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "511",
    "CITYNAME_AR": "القواعى",
    "CITYNAME_EN": "Alquaeaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "512",
    "CITYNAME_AR": "الثهره",
    "CITYNAME_EN": "Althahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "513",
    "CITYNAME_AR": "نيد رحب",
    "CITYNAME_EN": "Nayd Rahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "514",
    "CITYNAME_AR": "الوغره",
    "CITYNAME_EN": "Alwaghruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "515",
    "CITYNAME_AR": "قله العبيسى",
    "CITYNAME_EN": "Qalh Aleabisaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "516",
    "CITYNAME_AR": "الطحله",
    "CITYNAME_EN": "Altahuluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "517",
    "CITYNAME_AR": "المجلس",
    "CITYNAME_EN": "Almajlis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "518",
    "CITYNAME_AR": "الضاجع",
    "CITYNAME_EN": "Aldaajie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "519",
    "CITYNAME_AR": "الرجيف",
    "CITYNAME_EN": "Alrajif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "520",
    "CITYNAME_AR": "الثهية",
    "CITYNAME_EN": "Althahia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "521",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Alkhayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "522",
    "CITYNAME_AR": "الخربة",
    "CITYNAME_EN": "Alkharba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "523",
    "CITYNAME_AR": "المكبس",
    "CITYNAME_EN": "Almukbis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "524",
    "CITYNAME_AR": "خضاضه",
    "CITYNAME_EN": "Khadaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "525",
    "CITYNAME_AR": "قحدة",
    "CITYNAME_EN": "Qahda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "526",
    "CITYNAME_AR": "لثابير",
    "CITYNAME_EN": "Lethaber",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "527",
    "CITYNAME_AR": "الشقر",
    "CITYNAME_EN": "Al Shaqar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "528",
    "CITYNAME_AR": "الدويخل",
    "CITYNAME_EN": "Ad-Duwekhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "529",
    "CITYNAME_AR": "لصبه المارب",
    "CITYNAME_EN": "Lasbet Al Mareb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "530",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badiah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "531",
    "CITYNAME_AR": "تغادر",
    "CITYNAME_EN": "Taghadur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "532",
    "CITYNAME_AR": "القريثاء",
    "CITYNAME_EN": "Al Quraytha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "533",
    "CITYNAME_AR": "المقيق",
    "CITYNAME_EN": "Al Muqeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "534",
    "CITYNAME_AR": "الحاشر",
    "CITYNAME_EN": "Al Hasher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "535",
    "CITYNAME_AR": "وادي عرب",
    "CITYNAME_EN": "Wadi Arab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "536",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al Ghurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "537",
    "CITYNAME_AR": "غيمه",
    "CITYNAME_EN": "Ghaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "538",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Al Lasbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "539",
    "CITYNAME_AR": "السفنه",
    "CITYNAME_EN": "As-Safnah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "540",
    "CITYNAME_AR": "قله قاعه",
    "CITYNAME_EN": "Qulat Qaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "541",
    "CITYNAME_AR": "الجحصه",
    "CITYNAME_EN": "Al Gaasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "542",
    "CITYNAME_AR": "الشرياني",
    "CITYNAME_EN": "Ash-Shuryani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "543",
    "CITYNAME_AR": "حبيل الوحله",
    "CITYNAME_EN": "Hubayel Al Wahlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "544",
    "CITYNAME_AR": "الخورمه",
    "CITYNAME_EN": "Al Khawramah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "545",
    "CITYNAME_AR": "الاصلاب",
    "CITYNAME_EN": "Al Aslab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "546",
    "CITYNAME_AR": "القعيد",
    "CITYNAME_EN": "Al Qaeed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "547",
    "CITYNAME_AR": "الخوايع",
    "CITYNAME_EN": "Al Khawaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "548",
    "CITYNAME_AR": "شدنه",
    "CITYNAME_EN": "Shandah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "549",
    "CITYNAME_AR": "ضرفان",
    "CITYNAME_EN": "Darfan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "550",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "551",
    "CITYNAME_AR": "القلاع",
    "CITYNAME_EN": "Al Qulaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "552",
    "CITYNAME_AR": "المنزلة",
    "CITYNAME_EN": "Al Manzalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "553",
    "CITYNAME_AR": "الشرافي",
    "CITYNAME_EN": "Ash-Shurafi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "554",
    "CITYNAME_AR": "عثوان",
    "CITYNAME_EN": "Athwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "555",
    "CITYNAME_AR": "حرف الزرب",
    "CITYNAME_EN": "Harf Az-Zarb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "556",
    "CITYNAME_AR": "المسيجد",
    "CITYNAME_EN": "Al Musaijed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "557",
    "CITYNAME_AR": "مخاورة الشعراء",
    "CITYNAME_EN": "Mukhawret Ash-Shuara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "558",
    "CITYNAME_AR": "حبيل الصرفح",
    "CITYNAME_EN": "Lubad As-Sarfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "559",
    "CITYNAME_AR": "الردمان",
    "CITYNAME_EN": "Al Radman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "560",
    "CITYNAME_AR": "المعقب",
    "CITYNAME_EN": "Al Muaqeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "561",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Al Ghubayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "562",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Hubayel As-Sarfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "563",
    "CITYNAME_AR": "وادى ضمد",
    "CITYNAME_EN": "Wadi Damad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "564",
    "CITYNAME_AR": "جزومة",
    "CITYNAME_EN": "Gazomah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "565",
    "CITYNAME_AR": "المعقم",
    "CITYNAME_EN": "Al Muaqem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "566",
    "CITYNAME_AR": "حبيل فحليل",
    "CITYNAME_EN": "Hubayel Fahleel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "567",
    "CITYNAME_AR": "خطوة القرية",
    "CITYNAME_EN": "Ghatwet Al Qaryeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "568",
    "CITYNAME_AR": "لجايرة",
    "CITYNAME_EN": "Lugayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "569",
    "CITYNAME_AR": "حبيل السلب",
    "CITYNAME_EN": "Hubayel As-Salab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "570",
    "CITYNAME_AR": "الهرمه",
    "CITYNAME_EN": "Al Hermah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "571",
    "CITYNAME_AR": "الميجاح",
    "CITYNAME_EN": "Al Maygah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "572",
    "CITYNAME_AR": "منمه",
    "CITYNAME_EN": "Manamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "573",
    "CITYNAME_AR": "الحجابين",
    "CITYNAME_EN": "Al Hagabeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "574",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "575",
    "CITYNAME_AR": "دحره قانيه",
    "CITYNAME_EN": "Dahrat Qaniyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "576",
    "CITYNAME_AR": "نيد حردان",
    "CITYNAME_EN": "Ned Herdan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "577",
    "CITYNAME_AR": "الشباب",
    "CITYNAME_EN": "Ash-Shabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "578",
    "CITYNAME_AR": "السارة",
    "CITYNAME_EN": "As-Sarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "579",
    "CITYNAME_AR": "حبيل الواردات",
    "CITYNAME_EN": "Hubayel Al Waredat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "580",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "581",
    "CITYNAME_AR": "غابط",
    "CITYNAME_EN": "Ghabet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "582",
    "CITYNAME_AR": "قعاد آل شيحه",
    "CITYNAME_EN": "Quad Al Shiha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "583",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al Barek",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "584",
    "CITYNAME_AR": "البهره",
    "CITYNAME_EN": "Al Baharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "585",
    "CITYNAME_AR": "القريثه",
    "CITYNAME_EN": "Al Quraythah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "586",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "An-Nabaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "587",
    "CITYNAME_AR": "عزان",
    "CITYNAME_EN": "Azzan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "588",
    "CITYNAME_AR": "الخطم الغربي",
    "CITYNAME_EN": "Al Khatm Al Gharbi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "589",
    "CITYNAME_AR": "الثاهره",
    "CITYNAME_EN": "Ath-Thaherah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "590",
    "CITYNAME_AR": "الخطم الشرقي",
    "CITYNAME_EN": "Al Khatm Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "591",
    "CITYNAME_AR": "الخطم الشرقي",
    "CITYNAME_EN": "Al Majlis Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "592",
    "CITYNAME_AR": "القاعه",
    "CITYNAME_EN": "Al Qaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "593",
    "CITYNAME_AR": "المعينه",
    "CITYNAME_EN": "Zari Al Maaniyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "594",
    "CITYNAME_AR": "نعامه",
    "CITYNAME_EN": "Naamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "595",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Ar-Raqabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "596",
    "CITYNAME_AR": "جامعه",
    "CITYNAME_EN": "Jamaeah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "597",
    "CITYNAME_AR": "الفيدل",
    "CITYNAME_EN": "Al Fayadal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "598",
    "CITYNAME_AR": "ال شامر",
    "CITYNAME_EN": "Al Shamer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "599",
    "CITYNAME_AR": "الفسيح",
    "CITYNAME_EN": "Al Fasih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "600",
    "CITYNAME_AR": "وادى الحجفه",
    "CITYNAME_EN": "Wadi Al Jahafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "601",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al Oqdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "602",
    "CITYNAME_AR": "منفى",
    "CITYNAME_EN": "Manfa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "603",
    "CITYNAME_AR": "النشمه",
    "CITYNAME_EN": "An-Nashamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "604",
    "CITYNAME_AR": "عجام",
    "CITYNAME_EN": "Ajam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "605",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Al Hajafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "606",
    "CITYNAME_AR": "الشلال",
    "CITYNAME_EN": "Ash-Shallal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "607",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Al Zanabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "608",
    "CITYNAME_AR": "نيد الغير",
    "CITYNAME_EN": "Ned Al Gheir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "609",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "610",
    "CITYNAME_AR": "الصد",
    "CITYNAME_EN": "As-Sad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "611",
    "CITYNAME_AR": "الروبة",
    "CITYNAME_EN": "Ar-Rawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "612",
    "CITYNAME_AR": "الشعراء",
    "CITYNAME_EN": "Ash-Shara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "613",
    "CITYNAME_AR": "القلل",
    "CITYNAME_EN": "Al Qullal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "614",
    "CITYNAME_AR": "الدائر",
    "CITYNAME_EN": "Alddayir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "615",
    "CITYNAME_AR": "وادى الكرمه",
    "CITYNAME_EN": "Wa'Adaa Alkaramuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "616",
    "CITYNAME_AR": "لحج الضبره",
    "CITYNAME_EN": "Lihaji Aldabrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "617",
    "CITYNAME_AR": "بير الشار",
    "CITYNAME_EN": "Bayr Alshshari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "618",
    "CITYNAME_AR": "المزافله",
    "CITYNAME_EN": "Almazafiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "619",
    "CITYNAME_AR": "القما",
    "CITYNAME_EN": "Alqamma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "620",
    "CITYNAME_AR": "المغسل",
    "CITYNAME_EN": "Almaghasil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "621",
    "CITYNAME_AR": "المصف",
    "CITYNAME_EN": "Almisafu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "622",
    "CITYNAME_AR": "السليته",
    "CITYNAME_EN": "Alsiliyatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "623",
    "CITYNAME_AR": "وادى قير",
    "CITYNAME_EN": "Wa'Adaa Qir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "624",
    "CITYNAME_AR": "ذارى القهبه",
    "CITYNAME_EN": "Dhara Alqahbih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "625",
    "CITYNAME_AR": "السنطه",
    "CITYNAME_EN": "Alsintuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "626",
    "CITYNAME_AR": "قله المشربه",
    "CITYNAME_EN": "Qulh Almashrabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "627",
    "CITYNAME_AR": "المعشره",
    "CITYNAME_EN": "Almueshiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "628",
    "CITYNAME_AR": "القعقاع",
    "CITYNAME_EN": "Alqieqae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "629",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "Alsiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "630",
    "CITYNAME_AR": "الملوا حراز",
    "CITYNAME_EN": "Almaluu Hiraz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "631",
    "CITYNAME_AR": "نيد الخطوه",
    "CITYNAME_EN": "Nyd Alkhutuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "632",
    "CITYNAME_AR": "ملحاب",
    "CITYNAME_EN": "Milhab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "633",
    "CITYNAME_AR": "عطينه",
    "CITYNAME_EN": "Eutinh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "634",
    "CITYNAME_AR": "حراز",
    "CITYNAME_EN": "Hiraz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "635",
    "CITYNAME_AR": "المقمور",
    "CITYNAME_EN": "Almaqmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "636",
    "CITYNAME_AR": "جبل روحان",
    "CITYNAME_EN": "Jabal Ruhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "637",
    "CITYNAME_AR": "الذناب",
    "CITYNAME_EN": "Aldhinab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "638",
    "CITYNAME_AR": "الغادى",
    "CITYNAME_EN": "Alghadaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "639",
    "CITYNAME_AR": "خاشر",
    "CITYNAME_EN": "Khashir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "640",
    "CITYNAME_AR": "نيد الذراع",
    "CITYNAME_EN": "Nyd Aldhirae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "641",
    "CITYNAME_AR": "خطيبه",
    "CITYNAME_EN": "Khtybh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "642",
    "CITYNAME_AR": "الثاهر",
    "CITYNAME_EN": "Alththahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "643",
    "CITYNAME_AR": "مشيب",
    "CITYNAME_EN": "Mashib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "644",
    "CITYNAME_AR": "شدنه",
    "CITYNAME_EN": "Shadanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "645",
    "CITYNAME_AR": "ذراع البارك",
    "CITYNAME_EN": "Dhirae Albark",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "646",
    "CITYNAME_AR": "ذات الخلفين",
    "CITYNAME_EN": "Dhat Alkhalafin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "647",
    "CITYNAME_AR": "القويعات",
    "CITYNAME_EN": "Alquayeat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "648",
    "CITYNAME_AR": "نيد الشق",
    "CITYNAME_EN": "Nayd Alshiqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "649",
    "CITYNAME_AR": "قعادين",
    "CITYNAME_EN": "Qaeadin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "650",
    "CITYNAME_AR": "العوارض",
    "CITYNAME_EN": "Aleawarid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "651",
    "CITYNAME_AR": "طابن",
    "CITYNAME_EN": "Tabin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "652",
    "CITYNAME_AR": "الخبيه",
    "CITYNAME_EN": "Alkhabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "653",
    "CITYNAME_AR": "رغله",
    "CITYNAME_EN": "Raghalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "654",
    "CITYNAME_AR": "الشلال",
    "CITYNAME_EN": "Alshilal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "655",
    "CITYNAME_AR": "العدوه و الصفا",
    "CITYNAME_EN": "Aleaduh and Alsafa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "656",
    "CITYNAME_AR": "قلة مغن",
    "CITYNAME_EN": "Qlt Maghn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "657",
    "CITYNAME_AR": "ذراع القمع",
    "CITYNAME_EN": "Dhirae Alqame",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "658",
    "CITYNAME_AR": "قريه وادى اود",
    "CITYNAME_EN": "Qarih Wa'Adaa 'Awadu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "659",
    "CITYNAME_AR": "ذراع المخيله",
    "CITYNAME_EN": "Dhirae Almukhilih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "660",
    "CITYNAME_AR": "الدفين",
    "CITYNAME_EN": "Aldafin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "661",
    "CITYNAME_AR": "نيد الحقو",
    "CITYNAME_EN": "Nyd Alhaqu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "662",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Alqahbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "663",
    "CITYNAME_AR": "المقندر",
    "CITYNAME_EN": "Almuqandar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "664",
    "CITYNAME_AR": "وادى كعاع",
    "CITYNAME_EN": "Wa'Adaa Kieae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "665",
    "CITYNAME_AR": "حيد الحمره",
    "CITYNAME_EN": "Hyd Alhamrih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "666",
    "CITYNAME_AR": "الهرار",
    "CITYNAME_EN": "Alhirar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "667",
    "CITYNAME_AR": "الشعيث",
    "CITYNAME_EN": "Alshaeith",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "668",
    "CITYNAME_AR": "الشعيب",
    "CITYNAME_EN": "Alshaeib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "669",
    "CITYNAME_AR": "حبيل الشقره",
    "CITYNAME_EN": "Habil Alshaqrih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "670",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Almataean",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "671",
    "CITYNAME_AR": "القاسى",
    "CITYNAME_EN": "Alqasaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "672",
    "CITYNAME_AR": "قلة مغين",
    "CITYNAME_EN": "Qlt Mughayin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "673",
    "CITYNAME_AR": "حبيل معين",
    "CITYNAME_EN": "Habil Maein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "674",
    "CITYNAME_AR": "البازخاء",
    "CITYNAME_EN": "Albazikha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "675",
    "CITYNAME_AR": "المقعد",
    "CITYNAME_EN": "Almaqead",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "676",
    "CITYNAME_AR": "قهد",
    "CITYNAME_EN": "Qahd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "677",
    "CITYNAME_AR": "ذراع الذناب",
    "CITYNAME_EN": "Dhirae Aldhanab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "678",
    "CITYNAME_AR": "لحج محنيه",
    "CITYNAME_EN": "Lihaj Mahanih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "679",
    "CITYNAME_AR": "ذراع الهجر",
    "CITYNAME_EN": "Dhirae Alhijar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "680",
    "CITYNAME_AR": "صحصح",
    "CITYNAME_EN": "Sahsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "681",
    "CITYNAME_AR": "ملعبه",
    "CITYNAME_EN": "Maleabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "682",
    "CITYNAME_AR": "بهران",
    "CITYNAME_EN": "Bahran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "683",
    "CITYNAME_AR": "حيد الحلحله",
    "CITYNAME_EN": "Hyd Alhalhalh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "684",
    "CITYNAME_AR": "قله النباخ",
    "CITYNAME_EN": "Qalh Alnibakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "685",
    "CITYNAME_AR": "حبيل الفصيح",
    "CITYNAME_EN": "Habil Alfasih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "686",
    "CITYNAME_AR": "الظلامتين",
    "CITYNAME_EN": "Alzilamitayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "687",
    "CITYNAME_AR": "منفه",
    "CITYNAME_EN": "Manfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "688",
    "CITYNAME_AR": "العاصى",
    "CITYNAME_EN": "Aleasaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "689",
    "CITYNAME_AR": "الثواهر",
    "CITYNAME_EN": "Althawahur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "690",
    "CITYNAME_AR": "مثراره",
    "CITYNAME_EN": "Mithraruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "691",
    "CITYNAME_AR": "النفيش",
    "CITYNAME_EN": "Alnafish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "692",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Alghamaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "693",
    "CITYNAME_AR": "حفنه",
    "CITYNAME_EN": "Hafnah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "694",
    "CITYNAME_AR": "حرف السهى",
    "CITYNAME_EN": "Harf Alsuhaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "695",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Aleunquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "696",
    "CITYNAME_AR": "حياف ال مروح",
    "CITYNAME_EN": "Hiaaf Al Muruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "697",
    "CITYNAME_AR": "الثنيه",
    "CITYNAME_EN": "Althanih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "698",
    "CITYNAME_AR": "الدخول",
    "CITYNAME_EN": "Aldukhul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "699",
    "CITYNAME_AR": "الشجاج",
    "CITYNAME_EN": "Alshijaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "700",
    "CITYNAME_AR": "ال سلمى",
    "CITYNAME_EN": "Al Salamaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "701",
    "CITYNAME_AR": "المبدأ",
    "CITYNAME_EN": "Almabda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "702",
    "CITYNAME_AR": "القحيز",
    "CITYNAME_EN": "Alqahiz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "703",
    "CITYNAME_AR": "نيد العادى",
    "CITYNAME_EN": "Nyd Aleada",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "704",
    "CITYNAME_AR": "بين مقرن",
    "CITYNAME_EN": "Bayn Muqaran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "705",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Alqalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "706",
    "CITYNAME_AR": "نيد الشقره",
    "CITYNAME_EN": "Nayd Alshaqrih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "707",
    "CITYNAME_AR": "المعزبه",
    "CITYNAME_EN": "Almueazibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "708",
    "CITYNAME_AR": "المقروث",
    "CITYNAME_EN": "Almaqaruwth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "709",
    "CITYNAME_AR": "المحمور",
    "CITYNAME_EN": "Almahmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "710",
    "CITYNAME_AR": "الخزام",
    "CITYNAME_EN": "Alkhizam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "711",
    "CITYNAME_AR": "ذارى الاغبر",
    "CITYNAME_EN": "Dharaa Alaghbir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "712",
    "CITYNAME_AR": "بين مقر",
    "CITYNAME_EN": "Bayn Maqarin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "713",
    "CITYNAME_AR": "السريه",
    "CITYNAME_EN": "Alsirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "714",
    "CITYNAME_AR": "الضباع",
    "CITYNAME_EN": "Aldibae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "715",
    "CITYNAME_AR": "المعاين",
    "CITYNAME_EN": "Almaeayin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "716",
    "CITYNAME_AR": "قعاد الهرامه",
    "CITYNAME_EN": "Qaead Alhiramih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "717",
    "CITYNAME_AR": "قعاد الاغبر",
    "CITYNAME_EN": "Qaead Alaghbir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "718",
    "CITYNAME_AR": "الجرنه",
    "CITYNAME_EN": "Aljuranah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "719",
    "CITYNAME_AR": "ذارى المحمى",
    "CITYNAME_EN": "Dhara Almuhamaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "720",
    "CITYNAME_AR": "الهنجاره",
    "CITYNAME_EN": "Alhinjaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "721",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "722",
    "CITYNAME_AR": "ذارى تلمص",
    "CITYNAME_EN": "Dharaa Talamus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "723",
    "CITYNAME_AR": "الحلحل",
    "CITYNAME_EN": "Alhilhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "724",
    "CITYNAME_AR": "العصا",
    "CITYNAME_EN": "Aleasa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "725",
    "CITYNAME_AR": "شط الدخس",
    "CITYNAME_EN": "Shata Aldakhs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "726",
    "CITYNAME_AR": "زغفه",
    "CITYNAME_EN": "Zaghfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "727",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Almusbih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "728",
    "CITYNAME_AR": "الحريسي",
    "CITYNAME_EN": "Alharisi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "729",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Alhijafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "730",
    "CITYNAME_AR": "البازخ",
    "CITYNAME_EN": "Albazikh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "731",
    "CITYNAME_AR": "رقيع",
    "CITYNAME_EN": "Raqie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "732",
    "CITYNAME_AR": "ذراع الثوعه",
    "CITYNAME_EN": "Dhirae Althaweih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "733",
    "CITYNAME_AR": "شويحط",
    "CITYNAME_EN": "Shuyhit",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "734",
    "CITYNAME_AR": "ادم",
    "CITYNAME_EN": "Adam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "735",
    "CITYNAME_AR": "الرزنه",
    "CITYNAME_EN": "Alruzunuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "736",
    "CITYNAME_AR": "السعد",
    "CITYNAME_EN": "Alsaed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "737",
    "CITYNAME_AR": "المسنه",
    "CITYNAME_EN": "Almisanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "738",
    "CITYNAME_AR": "نيد جلمان",
    "CITYNAME_EN": "Nayd Jilman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "739",
    "CITYNAME_AR": "القرنه",
    "CITYNAME_EN": "Alqaranah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "740",
    "CITYNAME_AR": "النقيل",
    "CITYNAME_EN": "Alnaqil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "741",
    "CITYNAME_AR": "البجراء",
    "CITYNAME_EN": "Albijra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "742",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "743",
    "CITYNAME_AR": "مرواغ",
    "CITYNAME_EN": "Mirwagh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "744",
    "CITYNAME_AR": "الكعمه",
    "CITYNAME_EN": "Alkaeumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "745",
    "CITYNAME_AR": "الرجح",
    "CITYNAME_EN": "Alrajh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "746",
    "CITYNAME_AR": "الحضن حبس",
    "CITYNAME_EN": "Alhudn Habas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "747",
    "CITYNAME_AR": "لحج المهشم",
    "CITYNAME_EN": "Lihaji Almahsham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "748",
    "CITYNAME_AR": "ثاهره القعاد",
    "CITYNAME_EN": "Thahirah Alqaead",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "749",
    "CITYNAME_AR": "المهشم",
    "CITYNAME_EN": "Almahsham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "750",
    "CITYNAME_AR": "ذراع الطرائف",
    "CITYNAME_EN": "Dhirae Altarayif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "751",
    "CITYNAME_AR": "الغمره حبس",
    "CITYNAME_EN": "Alghamaruh Habs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "752",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "753",
    "CITYNAME_AR": "المدحدح",
    "CITYNAME_EN": "Almudhadih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "754",
    "CITYNAME_AR": "البامه",
    "CITYNAME_EN": "Albamih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "755",
    "CITYNAME_AR": "العصر",
    "CITYNAME_EN": "Aleasr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "756",
    "CITYNAME_AR": "نيد العشه",
    "CITYNAME_EN": "Nyd Aleshh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "757",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "758",
    "CITYNAME_AR": "الشضايمه",
    "CITYNAME_EN": "Alshadayumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "759",
    "CITYNAME_AR": "ثاهر راشد",
    "CITYNAME_EN": "Thahir Rashid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "760",
    "CITYNAME_AR": "لحج المطرد",
    "CITYNAME_EN": "Lihaj Almutrad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "761",
    "CITYNAME_AR": "ذراع قطل",
    "CITYNAME_EN": "Dhirae Qatal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "762",
    "CITYNAME_AR": "كدح",
    "CITYNAME_EN": "Kadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "763",
    "CITYNAME_AR": "النقيل",
    "CITYNAME_EN": "Alnaqil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "764",
    "CITYNAME_AR": "القميع",
    "CITYNAME_EN": "Alqamie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "765",
    "CITYNAME_AR": "العليبه",
    "CITYNAME_EN": "Alealibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "766",
    "CITYNAME_AR": "العدو",
    "CITYNAME_EN": "Aleadui",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "767",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Alsuruw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "768",
    "CITYNAME_AR": "فيد قاعين",
    "CITYNAME_EN": "Fyd Qaein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "769",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Aldahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "770",
    "CITYNAME_AR": "ذراع ال الشريف",
    "CITYNAME_EN": "Dhirae Al Alsharif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "771",
    "CITYNAME_AR": "الطهف",
    "CITYNAME_EN": "Altahaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "772",
    "CITYNAME_AR": "شفهوي",
    "CITYNAME_EN": "Shafahawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "773",
    "CITYNAME_AR": "الحشره",
    "CITYNAME_EN": "Alhashruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "774",
    "CITYNAME_AR": "مقمر",
    "CITYNAME_EN": "Muqamar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "775",
    "CITYNAME_AR": "قابل المعطرة",
    "CITYNAME_EN": "Qabil Almuetara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "776",
    "CITYNAME_AR": "ذاري القرنه",
    "CITYNAME_EN": "Dhari Alfrtmh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "777",
    "CITYNAME_AR": "المزدرب",
    "CITYNAME_EN": "Almuzadarib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "778",
    "CITYNAME_AR": "نيد العادي",
    "CITYNAME_EN": "Nyd Aleadii",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "779",
    "CITYNAME_AR": "دار القويعه",
    "CITYNAME_EN": "Dar Alqawayeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "780",
    "CITYNAME_AR": "الشدان",
    "CITYNAME_EN": "Alshadaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "781",
    "CITYNAME_AR": "طيره",
    "CITYNAME_EN": "Tyrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "782",
    "CITYNAME_AR": "الوقيط",
    "CITYNAME_EN": "Alwaqit",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "783",
    "CITYNAME_AR": "القابل",
    "CITYNAME_EN": "Alqabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "784",
    "CITYNAME_AR": "غرار",
    "CITYNAME_EN": "Ghirar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "785",
    "CITYNAME_AR": "عرق المروي",
    "CITYNAME_EN": "Earaq Almarawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "786",
    "CITYNAME_AR": "مديره",
    "CITYNAME_EN": "Mudiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "787",
    "CITYNAME_AR": "السفنه",
    "CITYNAME_EN": "Alsufunuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "788",
    "CITYNAME_AR": "منغس",
    "CITYNAME_EN": "Manqas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "789",
    "CITYNAME_AR": "صرمان",
    "CITYNAME_EN": "Sarman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "790",
    "CITYNAME_AR": "مدخال",
    "CITYNAME_EN": "Midkhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "791",
    "CITYNAME_AR": "العدي",
    "CITYNAME_EN": "Aleadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "792",
    "CITYNAME_AR": "القعيد",
    "CITYNAME_EN": "Alqaeid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "793",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Alghamaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "794",
    "CITYNAME_AR": "سمايه",
    "CITYNAME_EN": "Samayh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "795",
    "CITYNAME_AR": "الثبيت",
    "CITYNAME_EN": "Althabiat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "796",
    "CITYNAME_AR": "الشخيث",
    "CITYNAME_EN": "Alshakhayth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "797",
    "CITYNAME_AR": "مسحل",
    "CITYNAME_EN": "Musahal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "798",
    "CITYNAME_AR": "نيد صرمان",
    "CITYNAME_EN": "Nayd Sarman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "799",
    "CITYNAME_AR": "الذراى",
    "CITYNAME_EN": "Aldhuraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "800",
    "CITYNAME_AR": "الخديلة و الشاق",
    "CITYNAME_EN": "Alkhadilat and Alshshaqu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "801",
    "CITYNAME_AR": "الشرقى",
    "CITYNAME_EN": "Alsharqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "802",
    "CITYNAME_AR": "بقعه رحه",
    "CITYNAME_EN": "Baqeah Rahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "803",
    "CITYNAME_AR": "الروغه",
    "CITYNAME_EN": "Alruwghuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "804",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Allahyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "805",
    "CITYNAME_AR": "حيد الشدان",
    "CITYNAME_EN": "Hyd Alshaddan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "806",
    "CITYNAME_AR": "محط  مخر",
    "CITYNAME_EN": "Makharin Station",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "807",
    "CITYNAME_AR": "حيد الحمره",
    "CITYNAME_EN": "Hyd Alhamrih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "808",
    "CITYNAME_AR": "الجنيب",
    "CITYNAME_EN": "Aljanib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "809",
    "CITYNAME_AR": "المشرف",
    "CITYNAME_EN": "Almashrif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "810",
    "CITYNAME_AR": "مجناه",
    "CITYNAME_EN": "Majnah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "811",
    "CITYNAME_AR": "الضواحى",
    "CITYNAME_EN": "Aldawahaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "812",
    "CITYNAME_AR": "مضايا",
    "CITYNAME_EN": "Madaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "813",
    "CITYNAME_AR": "المحشره",
    "CITYNAME_EN": "Almuhshiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "814",
    "CITYNAME_AR": "حبيل الدخيس",
    "CITYNAME_EN": "Habil Aldakhis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "815",
    "CITYNAME_AR": "المحنيه",
    "CITYNAME_EN": "Almahanih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "816",
    "CITYNAME_AR": "القران",
    "CITYNAME_EN": "Alquran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "817",
    "CITYNAME_AR": "القرحان",
    "CITYNAME_EN": "Alqarhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "818",
    "CITYNAME_AR": "نيد الجرنه",
    "CITYNAME_EN": "Nayd Aljaranh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "819",
    "CITYNAME_AR": "دليله",
    "CITYNAME_EN": "Dililah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "820",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Aleusm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "821",
    "CITYNAME_AR": "وقيعن",
    "CITYNAME_EN": "Waqien",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "822",
    "CITYNAME_AR": "مكلس",
    "CITYNAME_EN": "Muklis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "823",
    "CITYNAME_AR": "الفرحه",
    "CITYNAME_EN": "Alfarhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "824",
    "CITYNAME_AR": "العدنه",
    "CITYNAME_EN": "Aleadanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "825",
    "CITYNAME_AR": "حبيل المقشوب",
    "CITYNAME_EN": "Habil Almaqshub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "826",
    "CITYNAME_AR": "الصخرا",
    "CITYNAME_EN": "Alsakhra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "827",
    "CITYNAME_AR": "القويعه",
    "CITYNAME_EN": "Alquayeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "828",
    "CITYNAME_AR": "فحجله",
    "CITYNAME_EN": "Fahajlh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "829",
    "CITYNAME_AR": "الجازر",
    "CITYNAME_EN": "Aljazir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "830",
    "CITYNAME_AR": "الذياب",
    "CITYNAME_EN": "Aldhiyab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "831",
    "CITYNAME_AR": "العشة",
    "CITYNAME_EN": "Aleisha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "832",
    "CITYNAME_AR": "الرميح",
    "CITYNAME_EN": "Alramih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "833",
    "CITYNAME_AR": "النصع",
    "CITYNAME_EN": "Alnase",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "834",
    "CITYNAME_AR": "فوق النيد",
    "CITYNAME_EN": "Fawq Alnayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "835",
    "CITYNAME_AR": "نيد حديد",
    "CITYNAME_EN": "Nayd Hadid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "836",
    "CITYNAME_AR": "وادي آل جابر",
    "CITYNAME_EN": "Wadi Al Jabir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "837",
    "CITYNAME_AR": "القنا",
    "CITYNAME_EN": "Alqana",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "838",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "839",
    "CITYNAME_AR": "الفصعاء",
    "CITYNAME_EN": "Alfusea'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "840",
    "CITYNAME_AR": "العرين الحرقه",
    "CITYNAME_EN": "Alearin Alharaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "841",
    "CITYNAME_AR": "الحرف  النوص",
    "CITYNAME_EN": "Alharaf Alnuws",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "842",
    "CITYNAME_AR": "الغربى",
    "CITYNAME_EN": "Algharbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "843",
    "CITYNAME_AR": "الغربيه",
    "CITYNAME_EN": "Algharbiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "844",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Alquraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "845",
    "CITYNAME_AR": "مشبعه",
    "CITYNAME_EN": "Mushabieh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "846",
    "CITYNAME_AR": "الشرقى الزرايب",
    "CITYNAME_EN": "Alsharqaa Alzarayib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "847",
    "CITYNAME_AR": "نيد المسلم",
    "CITYNAME_EN": "Nayd Almuslim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "848",
    "CITYNAME_AR": "المترادف",
    "CITYNAME_EN": "Almutaradif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "849",
    "CITYNAME_AR": "السراب",
    "CITYNAME_EN": "Alsarab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "850",
    "CITYNAME_AR": "الثوعه",
    "CITYNAME_EN": "Althaweuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "851",
    "CITYNAME_AR": "عماية",
    "CITYNAME_EN": "Eimaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "852",
    "CITYNAME_AR": "حلوان",
    "CITYNAME_EN": "Hilwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "853",
    "CITYNAME_AR": "الشنعه الغربيه",
    "CITYNAME_EN": "Alshanaeuh Algharbiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "854",
    "CITYNAME_AR": "الخنتبه",
    "CITYNAME_EN": "Alkhantbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "855",
    "CITYNAME_AR": "قسيمه",
    "CITYNAME_EN": "Qasymh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "856",
    "CITYNAME_AR": "نيد الميثه",
    "CITYNAME_EN": "Nayd Almithih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "857",
    "CITYNAME_AR": "غافله",
    "CITYNAME_EN": "Ghafilah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "858",
    "CITYNAME_AR": "البلد",
    "CITYNAME_EN": "Albalad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "859",
    "CITYNAME_AR": "نيد ام سعد",
    "CITYNAME_EN": "Nyd Umm Saed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "860",
    "CITYNAME_AR": "الرفاص",
    "CITYNAME_EN": "Alrafas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "861",
    "CITYNAME_AR": "جوجة",
    "CITYNAME_EN": "Jawja",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "862",
    "CITYNAME_AR": "بين العينين",
    "CITYNAME_EN": "Bayn Aleaynayn",
    "REGION_ID": "10"
   },

   {
    "CITY_ID": "863",
    "CITYNAME_AR": "عطف",
    "CITYNAME_EN": "Eutf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "864",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Alkhutuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "865",
    "CITYNAME_AR": "مقعده",
    "CITYNAME_EN": "Maqeaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "866",
    "CITYNAME_AR": "القمه",
    "CITYNAME_EN": "Alqamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "867",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Albaqeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "868",
    "CITYNAME_AR": "البطان",
    "CITYNAME_EN": "Albitan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "869",
    "CITYNAME_AR": "مريواء",
    "CITYNAME_EN": "Mariwa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "870",
    "CITYNAME_AR": "الراهز",
    "CITYNAME_EN": "Alrrahiz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "871",
    "CITYNAME_AR": "الخرفش",
    "CITYNAME_EN": "Alkharfsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "872",
    "CITYNAME_AR": "وادى الجوه",
    "CITYNAME_EN": "Wa'Adaa Aljuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "873",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Alqaebuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "874",
    "CITYNAME_AR": "الهجره",
    "CITYNAME_EN": "Alhijaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "875",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "876",
    "CITYNAME_AR": "ام سهوة",
    "CITYNAME_EN": "Umm Sahwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "877",
    "CITYNAME_AR": "ذو الحراج",
    "CITYNAME_EN": "Dhu Alhiraj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "878",
    "CITYNAME_AR": "الحمره",
    "CITYNAME_EN": "Alhamruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "879",
    "CITYNAME_AR": "العاصويين",
    "CITYNAME_EN": "Aleasuiiyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "880",
    "CITYNAME_AR": "مثعله",
    "CITYNAME_EN": "Mathealuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "881",
    "CITYNAME_AR": "المرفق",
    "CITYNAME_EN": "Almurafaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "882",
    "CITYNAME_AR": "جلال",
    "CITYNAME_EN": "Jalal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "883",
    "CITYNAME_AR": "السيالة",
    "CITYNAME_EN": "Alsiyala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "884",
    "CITYNAME_AR": "الثاهر القرى",
    "CITYNAME_EN": "Alththahir Alqra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "885",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Alqarih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "886",
    "CITYNAME_AR": "الموطاه",
    "CITYNAME_EN": "Almutah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "887",
    "CITYNAME_AR": "الظفير",
    "CITYNAME_EN": "Alzafir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "888",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Almadaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "889",
    "CITYNAME_AR": "الشضايمه",
    "CITYNAME_EN": "Alshadayumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "890",
    "CITYNAME_AR": "قعاد مرابخ",
    "CITYNAME_EN": "Qaead Murabikh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "891",
    "CITYNAME_AR": "نيد العديه",
    "CITYNAME_EN": "Nyd Aledyh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "892",
    "CITYNAME_AR": "نيد صحراء",
    "CITYNAME_EN": "Nyd Sahra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "893",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Algharafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "894",
    "CITYNAME_AR": "الموهر",
    "CITYNAME_EN": "Almawhir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "895",
    "CITYNAME_AR": "المنحته",
    "CITYNAME_EN": "Almunihatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "896",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Alhishah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "897",
    "CITYNAME_AR": "الخالفه",
    "CITYNAME_EN": "Alkhalifuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "898",
    "CITYNAME_AR": "النصايب",
    "CITYNAME_EN": "Alnasayib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "899",
    "CITYNAME_AR": "الخطماء",
    "CITYNAME_EN": "Alkhatama'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "900",
    "CITYNAME_AR": "ذي حمر بين الحمر",
    "CITYNAME_EN": "Dhi Humur Bayn Alhumur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "901",
    "CITYNAME_AR": "العرابة",
    "CITYNAME_EN": "Aleiraba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "902",
    "CITYNAME_AR": "الخاض",
    "CITYNAME_EN": "Alkhadu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "903",
    "CITYNAME_AR": "قرن حراز",
    "CITYNAME_EN": "Qarn Hiraz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "904",
    "CITYNAME_AR": "الأثبه",
    "CITYNAME_EN": "Al'Athbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "905",
    "CITYNAME_AR": "حدبه احمر",
    "CITYNAME_EN": "Hadabuh Ahmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "906",
    "CITYNAME_AR": "المذنب",
    "CITYNAME_EN": "Almudhanib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "907",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Aldahluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "908",
    "CITYNAME_AR": "الوهده",
    "CITYNAME_EN": "Alwahduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "909",
    "CITYNAME_AR": "المحامي",
    "CITYNAME_EN": "Almuhami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "910",
    "CITYNAME_AR": "الشكره",
    "CITYNAME_EN": "Alshakruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "911",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "912",
    "CITYNAME_AR": "الغمرين",
    "CITYNAME_EN": "Alghamarin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "913",
    "CITYNAME_AR": "سلمن",
    "CITYNAME_EN": "Salmun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "914",
    "CITYNAME_AR": "الشجب",
    "CITYNAME_EN": "Alshajab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "915",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "Alsiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "916",
    "CITYNAME_AR": "الربوض",
    "CITYNAME_EN": "Alrubud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "917",
    "CITYNAME_AR": "وادي دفا الجزء الشمالي ـ مركز إماره دفاء",
    "CITYNAME_EN": "Wadi Dafa The Northern Region   'Iimarih Dafa Cen*",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "918",
    "CITYNAME_AR": "الغلال",
    "CITYNAME_EN": "Alghilal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "919",
    "CITYNAME_AR": "مقرو",
    "CITYNAME_EN": "Maqru",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "920",
    "CITYNAME_AR": "النخبه",
    "CITYNAME_EN": "Alnakhbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "921",
    "CITYNAME_AR": "ثنيبر",
    "CITYNAME_EN": "Thanibir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "922",
    "CITYNAME_AR": "المخلفه",
    "CITYNAME_EN": "Almukhalafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "923",
    "CITYNAME_AR": "المهتزم",
    "CITYNAME_EN": "Almuhtazam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "924",
    "CITYNAME_AR": "الحزينه",
    "CITYNAME_EN": "Alhazinuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "925",
    "CITYNAME_AR": "صدر مراكه",
    "CITYNAME_EN": "Sadar Marakuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "926",
    "CITYNAME_AR": "المدرتين",
    "CITYNAME_EN": "Almudratayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "927",
    "CITYNAME_AR": "صويحا",
    "CITYNAME_EN": "Sawihaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "928",
    "CITYNAME_AR": "الجور",
    "CITYNAME_EN": "Aljur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "929",
    "CITYNAME_AR": "جحيدم",
    "CITYNAME_EN": "Jhydm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "930",
    "CITYNAME_AR": "الرحه",
    "CITYNAME_EN": "Alrahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "931",
    "CITYNAME_AR": "الحرضاء",
    "CITYNAME_EN": "Alharda'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "932",
    "CITYNAME_AR": "خو مسعود",
    "CITYNAME_EN": "Khu Maseud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "933",
    "CITYNAME_AR": "الهمله",
    "CITYNAME_EN": "Alhamaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "934",
    "CITYNAME_AR": "مخرم",
    "CITYNAME_EN": "Makhrim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "935",
    "CITYNAME_AR": "المعسر",
    "CITYNAME_EN": "Almaesir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "936",
    "CITYNAME_AR": "المشيحذه",
    "CITYNAME_EN": "Almushyhadhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "937",
    "CITYNAME_AR": "المحببه",
    "CITYNAME_EN": "Almuhabibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "938",
    "CITYNAME_AR": "العارض",
    "CITYNAME_EN": "Alearid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "939",
    "CITYNAME_AR": "الخنقاء",
    "CITYNAME_EN": "Alkhunqa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "940",
    "CITYNAME_AR": "الحويصه",
    "CITYNAME_EN": "Alhawaysuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "941",
    "CITYNAME_AR": "الملبا",
    "CITYNAME_EN": "Almalaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "942",
    "CITYNAME_AR": "اللعساء",
    "CITYNAME_EN": "Allaeisa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "943",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Aleurud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "944",
    "CITYNAME_AR": "دايان",
    "CITYNAME_EN": "Dayan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "945",
    "CITYNAME_AR": "صباخ",
    "CITYNAME_EN": "Sabakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "946",
    "CITYNAME_AR": "الغرة",
    "CITYNAME_EN": "Alghara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "947",
    "CITYNAME_AR": "تربان",
    "CITYNAME_EN": "Tarbaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "948",
    "CITYNAME_AR": "أسبخه",
    "CITYNAME_EN": "Asbkhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "949",
    "CITYNAME_AR": "قرن حلوان",
    "CITYNAME_EN": "Qarn Hulwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "950",
    "CITYNAME_AR": "الوجيمه",
    "CITYNAME_EN": "Alwajimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "951",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Alraqabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "952",
    "CITYNAME_AR": "المظروف",
    "CITYNAME_EN": "Almazruf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "953",
    "CITYNAME_AR": "وادى منخرا",
    "CITYNAME_EN": "Wa'Adaa Munakharanaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "954",
    "CITYNAME_AR": "منمور",
    "CITYNAME_EN": "Manmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "955",
    "CITYNAME_AR": "العصيمه",
    "CITYNAME_EN": "Aleasimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "956",
    "CITYNAME_AR": "وادي عرب حمر",
    "CITYNAME_EN": "Wadi Arab Humur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "957",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "958",
    "CITYNAME_AR": "الخشعه",
    "CITYNAME_EN": "Khashaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "959",
    "CITYNAME_AR": "عدينه",
    "CITYNAME_EN": "Udayna",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "960",
    "CITYNAME_AR": "البرد",
    "CITYNAME_EN": "Al Barad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "961",
    "CITYNAME_AR": "عارض القفل",
    "CITYNAME_EN": "Ared Al Quful",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "962",
    "CITYNAME_AR": "شيبان",
    "CITYNAME_EN": "Shebyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "963",
    "CITYNAME_AR": "المره",
    "CITYNAME_EN": "Al Murah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "964",
    "CITYNAME_AR": "مثبيره",
    "CITYNAME_EN": "Muthbayra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "965",
    "CITYNAME_AR": "ذمرداء",
    "CITYNAME_EN": "Zamrada",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "966",
    "CITYNAME_AR": "قميشه",
    "CITYNAME_EN": "Qumaysha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "967",
    "CITYNAME_AR": "ثهران",
    "CITYNAME_EN": "Thehran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "968",
    "CITYNAME_AR": "شط صميل",
    "CITYNAME_EN": "Shat Sumayel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "969",
    "CITYNAME_AR": "قهيداء",
    "CITYNAME_EN": "Quhaydaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "970",
    "CITYNAME_AR": "الظهياء",
    "CITYNAME_EN": "Al Dhahyaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "971",
    "CITYNAME_AR": "المرمعه",
    "CITYNAME_EN": "Al Marmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "972",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "973",
    "CITYNAME_AR": "اللعيثه",
    "CITYNAME_EN": "Al Luaythah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "974",
    "CITYNAME_AR": "المروي",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "975",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Al Shatabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "976",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Al Mudarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "977",
    "CITYNAME_AR": "رخد",
    "CITYNAME_EN": "Rakhd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "978",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Jowar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "979",
    "CITYNAME_AR": "مجحه",
    "CITYNAME_EN": "Mojehah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "980",
    "CITYNAME_AR": "البطينه",
    "CITYNAME_EN": "Al Butaynah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "981",
    "CITYNAME_AR": "شريحه",
    "CITYNAME_EN": "Shurayhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "982",
    "CITYNAME_AR": "الكتيفه",
    "CITYNAME_EN": "Al Kutayfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "983",
    "CITYNAME_AR": "مزرعة القريه",
    "CITYNAME_EN": "Farm of Al Qariyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "984",
    "CITYNAME_AR": "لبراء",
    "CITYNAME_EN": "Lebraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "985",
    "CITYNAME_AR": "الوقيع",
    "CITYNAME_EN": "Al Waqee",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "986",
    "CITYNAME_AR": "شعب لغياء",
    "CITYNAME_EN": "Shuaab Laghyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "987",
    "CITYNAME_AR": "وادي عراب حمر",
    "CITYNAME_EN": "Wadi Arab Humur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "988",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Al Qariyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "989",
    "CITYNAME_AR": "الأثبه",
    "CITYNAME_EN": "Al-Athiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "990",
    "CITYNAME_AR": "السنام",
    "CITYNAME_EN": "Al Senam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "991",
    "CITYNAME_AR": "القاني",
    "CITYNAME_EN": "Al-Qani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "992",
    "CITYNAME_AR": "المضاف",
    "CITYNAME_EN": "Al Mudaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "993",
    "CITYNAME_AR": "الحيفتين",
    "CITYNAME_EN": "Al Hefatayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "994",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Jowar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "995",
    "CITYNAME_AR": "المدرة",
    "CITYNAME_EN": "Al Mudarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "996",
    "CITYNAME_AR": "المسكه",
    "CITYNAME_EN": "Al Masaqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "997",
    "CITYNAME_AR": "الشدان",
    "CITYNAME_EN": "Al Shaddan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "998",
    "CITYNAME_AR": "المثبات",
    "CITYNAME_EN": "Al Muthbat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "999",
    "CITYNAME_AR": "المغدي",
    "CITYNAME_EN": "Al Mughedi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1000",
    "CITYNAME_AR": "وادي حمر",
    "CITYNAME_EN": "Wadi Humur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1001",
    "CITYNAME_AR": "وعال",
    "CITYNAME_EN": "Waal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1002",
    "CITYNAME_AR": "حراشف",
    "CITYNAME_EN": "Harashef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1003",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Usum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1004",
    "CITYNAME_AR": "العنبه",
    "CITYNAME_EN": "Al Enabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1005",
    "CITYNAME_AR": "المسابه",
    "CITYNAME_EN": "Al Masabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1006",
    "CITYNAME_AR": "حنفجه",
    "CITYNAME_EN": "Hanfajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1007",
    "CITYNAME_AR": "سولا",
    "CITYNAME_EN": "Sola",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1008",
    "CITYNAME_AR": "لثين",
    "CITYNAME_EN": "Luthayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1009",
    "CITYNAME_AR": "المسنه",
    "CITYNAME_EN": "Al Musanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1010",
    "CITYNAME_AR": "قشبه",
    "CITYNAME_EN": "Qashabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1011",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Al Khulayef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1012",
    "CITYNAME_AR": "صوبا",
    "CITYNAME_EN": "Sawbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1013",
    "CITYNAME_AR": "المجاوزه",
    "CITYNAME_EN": "Al Mujawazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1014",
    "CITYNAME_AR": "القطع",
    "CITYNAME_EN": "Al Qataa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1015",
    "CITYNAME_AR": "الشهاده",
    "CITYNAME_EN": "Al Shehadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1016",
    "CITYNAME_AR": "الحاثره",
    "CITYNAME_EN": "Al Hatherah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1017",
    "CITYNAME_AR": "الخبط",
    "CITYNAME_EN": "Al Khobat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1018",
    "CITYNAME_AR": "شعب لبن",
    "CITYNAME_EN": "Shuaab Laban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1019",
    "CITYNAME_AR": "صلاخه",
    "CITYNAME_EN": "Salakhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1020",
    "CITYNAME_AR": "حدلان",
    "CITYNAME_EN": "Hadlan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1021",
    "CITYNAME_AR": "المقوس",
    "CITYNAME_EN": "Al Muqawas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1022",
    "CITYNAME_AR": "عماقيه",
    "CITYNAME_EN": "Amaqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1023",
    "CITYNAME_AR": "جغمه",
    "CITYNAME_EN": "Goghmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1024",
    "CITYNAME_AR": "قرثه",
    "CITYNAME_EN": "Qarathah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1025",
    "CITYNAME_AR": "المارب",
    "CITYNAME_EN": "Al Marab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1026",
    "CITYNAME_AR": "فغره",
    "CITYNAME_EN": "Faghrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1027",
    "CITYNAME_AR": "الجابيه",
    "CITYNAME_EN": "Al Gabiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1028",
    "CITYNAME_AR": "الوقيره",
    "CITYNAME_EN": "Al Waqerah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1029",
    "CITYNAME_AR": "الداير",
    "CITYNAME_EN": "Addayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1030",
    "CITYNAME_AR": "الرديهه",
    "CITYNAME_EN": "Al Rudayhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1031",
    "CITYNAME_AR": "الصمد",
    "CITYNAME_EN": "Al Samad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1032",
    "CITYNAME_AR": "اللبخه",
    "CITYNAME_EN": "Al Labkhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1033",
    "CITYNAME_AR": "الشيح",
    "CITYNAME_EN": "Al Shayh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1034",
    "CITYNAME_AR": "انصبه",
    "CITYNAME_EN": "Ansabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1035",
    "CITYNAME_AR": "قمايع",
    "CITYNAME_EN": "Qamayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1036",
    "CITYNAME_AR": "خبا",
    "CITYNAME_EN": "Khaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1037",
    "CITYNAME_AR": "المدمونه",
    "CITYNAME_EN": "Al Madmounah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1038",
    "CITYNAME_AR": "المنقعر",
    "CITYNAME_EN": "Al Monqara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1039",
    "CITYNAME_AR": "اللجين",
    "CITYNAME_EN": "Al Lujayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1040",
    "CITYNAME_AR": "دبس",
    "CITYNAME_EN": "Dabas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1041",
    "CITYNAME_AR": "حلط القعود",
    "CITYNAME_EN": "Halt Al Qaood",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1042",
    "CITYNAME_AR": "معشار",
    "CITYNAME_EN": "Meashar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1043",
    "CITYNAME_AR": "المشراقه",
    "CITYNAME_EN": "Al Meshraqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1044",
    "CITYNAME_AR": "العيده",
    "CITYNAME_EN": "Al Aydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1045",
    "CITYNAME_AR": "قلة العيده",
    "CITYNAME_EN": "Qulat Al Aydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1046",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "Tor Al Salaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1047",
    "CITYNAME_AR": "جفنين",
    "CITYNAME_EN": "Gafneen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1048",
    "CITYNAME_AR": "قرن عذاب",
    "CITYNAME_EN": "Wadi Arrab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1049",
    "CITYNAME_AR": "قرن عذاب",
    "CITYNAME_EN": "Qarn Azab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1050",
    "CITYNAME_AR": "عرمان",
    "CITYNAME_EN": "Erman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1051",
    "CITYNAME_AR": "الفرسه",
    "CITYNAME_EN": "Al Farasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1052",
    "CITYNAME_AR": "جعار",
    "CITYNAME_EN": "Gaar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1053",
    "CITYNAME_AR": "الجبيل ",
    "CITYNAME_EN": "Jubail",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1054",
    "CITYNAME_AR": "زواه",
    "CITYNAME_EN": "Zawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1055",
    "CITYNAME_AR": "قرن الدويشاء",
    "CITYNAME_EN": "Qarn Al Duwaisha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1056",
    "CITYNAME_AR": "الجردمه",
    "CITYNAME_EN": "Al Gardamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1057",
    "CITYNAME_AR": "الحليق",
    "CITYNAME_EN": "Al Haleeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1058",
    "CITYNAME_AR": "القشباء",
    "CITYNAME_EN": "Al Qashbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1059",
    "CITYNAME_AR": "مصبح",
    "CITYNAME_EN": "Mosabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1060",
    "CITYNAME_AR": "حبيل الشامخ",
    "CITYNAME_EN": "Hubayel Al Shamekh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1061",
    "CITYNAME_AR": "بادية   عراب",
    "CITYNAME_EN": "Badiat Arrab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1062",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1063",
    "CITYNAME_AR": "حبيل القشباء",
    "CITYNAME_EN": "Hubayel Al Qashbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1064",
    "CITYNAME_AR": "الجريف",
    "CITYNAME_EN": "Al Gareef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1065",
    "CITYNAME_AR": "الوعره",
    "CITYNAME_EN": "Al Waarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1066",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "As-Sulaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1067",
    "CITYNAME_AR": "عوجبه",
    "CITYNAME_EN": "Awjabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1068",
    "CITYNAME_AR": "طاوي",
    "CITYNAME_EN": "Tawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1069",
    "CITYNAME_AR": "الثهيره",
    "CITYNAME_EN": "Al Thuhayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1070",
    "CITYNAME_AR": "الخشب",
    "CITYNAME_EN": "Al Khasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1071",
    "CITYNAME_AR": "الشطور",
    "CITYNAME_EN": "Al Shatoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1072",
    "CITYNAME_AR": "ثواهرعاصي",
    "CITYNAME_EN": "Thwaherasi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1073",
    "CITYNAME_AR": "المشراقه",
    "CITYNAME_EN": "Al Meshraqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1074",
    "CITYNAME_AR": "وتيه",
    "CITYNAME_EN": "Wateeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1075",
    "CITYNAME_AR": "الحلاله",
    "CITYNAME_EN": "Al Halalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1076",
    "CITYNAME_AR": "الغمور",
    "CITYNAME_EN": "Al Ghamor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1077",
    "CITYNAME_AR": "حبيل العين",
    "CITYNAME_EN": "Hubayel Al Ain",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1078",
    "CITYNAME_AR": "جعيره",
    "CITYNAME_EN": "Guayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1079",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1080",
    "CITYNAME_AR": "قزاع",
    "CITYNAME_EN": "Qazaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1081",
    "CITYNAME_AR": "العاطف",
    "CITYNAME_EN": "Al Atef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1082",
    "CITYNAME_AR": "اسفل الرجوع",
    "CITYNAME_EN": "Asfal Ar-Rujua",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1083",
    "CITYNAME_AR": "الحمرات",
    "CITYNAME_EN": "Al Hamrat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1084",
    "CITYNAME_AR": "العفاره",
    "CITYNAME_EN": "Al Afarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1085",
    "CITYNAME_AR": "خطوة الرجوع",
    "CITYNAME_EN": "Ghatwet Ar-Rujua",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1086",
    "CITYNAME_AR": "الحلحله",
    "CITYNAME_EN": "Al Halalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1087",
    "CITYNAME_AR": "الزج",
    "CITYNAME_EN": "Qaryet Az-Zag",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1088",
    "CITYNAME_AR": "القنه",
    "CITYNAME_EN": "Al-Qunah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1089",
    "CITYNAME_AR": "العدوه",
    "CITYNAME_EN": "Al Adawa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1090",
    "CITYNAME_AR": "الجرانه",
    "CITYNAME_EN": "Al Garanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1091",
    "CITYNAME_AR": "عمق",
    "CITYNAME_EN": "Amaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1092",
    "CITYNAME_AR": "قرية وادي دفا",
    "CITYNAME_EN": "Wadi Dafa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1093",
    "CITYNAME_AR": "الساقيه",
    "CITYNAME_EN": "As-Saqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1094",
    "CITYNAME_AR": "الثاج",
    "CITYNAME_EN": "Al Fag",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1095",
    "CITYNAME_AR": "مضايه",
    "CITYNAME_EN": "Mudayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1096",
    "CITYNAME_AR": "القريثات",
    "CITYNAME_EN": "Al Quraythat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1097",
    "CITYNAME_AR": "خبا",
    "CITYNAME_EN": "Khaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1098",
    "CITYNAME_AR": "الفرحه",
    "CITYNAME_EN": "Al Farha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1099",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "As-Sulaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1100",
    "CITYNAME_AR": "المارب",
    "CITYNAME_EN": "Al Marab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1101",
    "CITYNAME_AR": "العميده",
    "CITYNAME_EN": "Al Umaydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1102",
    "CITYNAME_AR": "دحانض",
    "CITYNAME_EN": "Dahaned",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1103",
    "CITYNAME_AR": "الحجمه",
    "CITYNAME_EN": "Al Hagmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1104",
    "CITYNAME_AR": "دفايا",
    "CITYNAME_EN": "Dafayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1105",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1106",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "As-Sud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1107",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1108",
    "CITYNAME_AR": "غزره",
    "CITYNAME_EN": "Ghazarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1109",
    "CITYNAME_AR": "الحدمه",
    "CITYNAME_EN": "Al Hadamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1110",
    "CITYNAME_AR": "الحمض",
    "CITYNAME_EN": "Al Homs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1111",
    "CITYNAME_AR": "الكراش",
    "CITYNAME_EN": "Al Karrash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1112",
    "CITYNAME_AR": "البوانه",
    "CITYNAME_EN": "Al Bawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1113",
    "CITYNAME_AR": "الكرش",
    "CITYNAME_EN": "Al Karash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1114",
    "CITYNAME_AR": "ضلفع",
    "CITYNAME_EN": "Dalfaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1115",
    "CITYNAME_AR": "العمده",
    "CITYNAME_EN": "Al Umdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1116",
    "CITYNAME_AR": "وادي نتاور",
    "CITYNAME_EN": "Wadi Natawer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1117",
    "CITYNAME_AR": "المره",
    "CITYNAME_EN": "Al Murah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1118",
    "CITYNAME_AR": "دقاق",
    "CITYNAME_EN": "Daqaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1119",
    "CITYNAME_AR": "المحشره",
    "CITYNAME_EN": "Al Mahsharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1120",
    "CITYNAME_AR": "الثوعه",
    "CITYNAME_EN": "Al Thawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1121",
    "CITYNAME_AR": "المحنيه",
    "CITYNAME_EN": "Al Mahniyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1122",
    "CITYNAME_AR": "وادي خشى",
    "CITYNAME_EN": "Wadi Khushi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1123",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "As-Salam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1124",
    "CITYNAME_AR": "الفارق",
    "CITYNAME_EN": "Al Mufareq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1125",
    "CITYNAME_AR": "القيشبان",
    "CITYNAME_EN": "Al Qayshaban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1126",
    "CITYNAME_AR": "مهارج",
    "CITYNAME_EN": "Muharej",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1127",
    "CITYNAME_AR": "برده",
    "CITYNAME_EN": "Bardah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1128",
    "CITYNAME_AR": "الصداره",
    "CITYNAME_EN": "As-Sadarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1129",
    "CITYNAME_AR": "قطار",
    "CITYNAME_EN": "Qetar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1130",
    "CITYNAME_AR": "الصويمله",
    "CITYNAME_EN": "As-Sumbulah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1131",
    "CITYNAME_AR": "ملزوز",
    "CITYNAME_EN": "Malzooz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1132",
    "CITYNAME_AR": "النفايش",
    "CITYNAME_EN": "An-Nafayesh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1133",
    "CITYNAME_AR": "القفل",
    "CITYNAME_EN": "Al Gofol",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1134",
    "CITYNAME_AR": "أعجم",
    "CITYNAME_EN": "Aajam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1135",
    "CITYNAME_AR": "غرفين",
    "CITYNAME_EN": "Gharqeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1136",
    "CITYNAME_AR": "قفله",
    "CITYNAME_EN": "Qaflah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1137",
    "CITYNAME_AR": "مزرحه",
    "CITYNAME_EN": "Mazrahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1138",
    "CITYNAME_AR": "الحنوة",
    "CITYNAME_EN": "Al Honwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1139",
    "CITYNAME_AR": "امعصام",
    "CITYNAME_EN": "Amesam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1140",
    "CITYNAME_AR": "طور السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1141",
    "CITYNAME_AR": "الجانبه",
    "CITYNAME_EN": "Al Janbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1142",
    "CITYNAME_AR": "الشجعة",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1144",
    "CITYNAME_AR": "الجشة",
    "CITYNAME_EN": "Wa'Adaa Aljashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1145",
    "CITYNAME_AR": "ذم مض",
    "CITYNAME_EN": "Dhum Mada",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1146",
    "CITYNAME_AR": "قله الحرث",
    "CITYNAME_EN": "Qulh Alharth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1147",
    "CITYNAME_AR": "القحز",
    "CITYNAME_EN": "Alqahz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1148",
    "CITYNAME_AR": "المبقم",
    "CITYNAME_EN": "Almubaqam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1149",
    "CITYNAME_AR": "الجبيل",
    "CITYNAME_EN": "Aljabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1150",
    "CITYNAME_AR": "الجفو",
    "CITYNAME_EN": "Aljafu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1151",
    "CITYNAME_AR": "قعاد الفرحه",
    "CITYNAME_EN": "Qaead Alfirhih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1152",
    "CITYNAME_AR": "حبيل الواسط",
    "CITYNAME_EN": "Habil Alwast",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1153",
    "CITYNAME_AR": "الاقعده",
    "CITYNAME_EN": "Alaqedh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1154",
    "CITYNAME_AR": "ذات المسك",
    "CITYNAME_EN": "Dhat Almisak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1155",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Aleurud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1156",
    "CITYNAME_AR": "ذراع المناهر",
    "CITYNAME_EN": "Dhirae Almanahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1157",
    "CITYNAME_AR": "الخرشوم",
    "CITYNAME_EN": "Alkharshum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1158",
    "CITYNAME_AR": "الجنيب",
    "CITYNAME_EN": "Aljanib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1159",
    "CITYNAME_AR": "حبيل الجرنه",
    "CITYNAME_EN": "Habil Aljaranih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1160",
    "CITYNAME_AR": "الثاهر",
    "CITYNAME_EN": "Alththahir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1161",
    "CITYNAME_AR": "المديره",
    "CITYNAME_EN": "Almudiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1162",
    "CITYNAME_AR": "الجامعه",
    "CITYNAME_EN": "Aljamieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1163",
    "CITYNAME_AR": "الخورمه",
    "CITYNAME_EN": "Alkhawrmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1164",
    "CITYNAME_AR": "نيد قطبه",
    "CITYNAME_EN": "Nyd Qatbih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1165",
    "CITYNAME_AR": "حبيل المنشح",
    "CITYNAME_EN": "Habil Almunashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1166",
    "CITYNAME_AR": "روه",
    "CITYNAME_EN": "Ruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1167",
    "CITYNAME_AR": "الجفينه",
    "CITYNAME_EN": "Aljafinah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1168",
    "CITYNAME_AR": "غيل المجنب",
    "CITYNAME_EN": "Ghyl Almujnib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1169",
    "CITYNAME_AR": "اللعثه",
    "CITYNAME_EN": "Allaeathuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1170",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Alhamatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1171",
    "CITYNAME_AR": "حيد المشربه",
    "CITYNAME_EN": "Hyd Almushribih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1172",
    "CITYNAME_AR": "الردحة",
    "CITYNAME_EN": "Alrudha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1173",
    "CITYNAME_AR": "مناخل",
    "CITYNAME_EN": "Manakhil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1174",
    "CITYNAME_AR": "المكمن",
    "CITYNAME_EN": "Almukaman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1175",
    "CITYNAME_AR": "الغلث",
    "CITYNAME_EN": "Alghalath",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1176",
    "CITYNAME_AR": "المرباح",
    "CITYNAME_EN": "Almarbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1177",
    "CITYNAME_AR": "هبيبه",
    "CITYNAME_EN": "Habibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1178",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Aleusm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1179",
    "CITYNAME_AR": "الخورم",
    "CITYNAME_EN": "Alkhawram",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1180",
    "CITYNAME_AR": "ربعان",
    "CITYNAME_EN": "Rbean",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1181",
    "CITYNAME_AR": "حيد الضبره",
    "CITYNAME_EN": "Hyd Aldibrih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1182",
    "CITYNAME_AR": "حدبه المجرد",
    "CITYNAME_EN": "Hadabuh Almujrid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1183",
    "CITYNAME_AR": "لحوج الغنيه",
    "CITYNAME_EN": "Lihuj Alghanih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1184",
    "CITYNAME_AR": "الزقيله",
    "CITYNAME_EN": "Alzaqiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1185",
    "CITYNAME_AR": "عفره",
    "CITYNAME_EN": "Eafrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1186",
    "CITYNAME_AR": "الغفله",
    "CITYNAME_EN": "Alghafluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1187",
    "CITYNAME_AR": "المداور",
    "CITYNAME_EN": "Almadawir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1188",
    "CITYNAME_AR": "مفتح ضهيا",
    "CITYNAME_EN": "Muftah Dahiaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1189",
    "CITYNAME_AR": "مبهمة",
    "CITYNAME_EN": "Mubhama",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1190",
    "CITYNAME_AR": "ظهاية",
    "CITYNAME_EN": "Zihaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1191",
    "CITYNAME_AR": "خوعمه",
    "CITYNAME_EN": "Khaweamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1192",
    "CITYNAME_AR": "المفتح",
    "CITYNAME_EN": "Almufatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1193",
    "CITYNAME_AR": "حبيل بردان",
    "CITYNAME_EN": "Habil Burdan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1194",
    "CITYNAME_AR": "ثعابه",
    "CITYNAME_EN": "Thuabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1195",
    "CITYNAME_AR": "المضايا",
    "CITYNAME_EN": "Al Madaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1196",
    "CITYNAME_AR": "ابو كرش",
    "CITYNAME_EN": "Abo Karash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1197",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al Oqdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1198",
    "CITYNAME_AR": "بالهول",
    "CITYNAME_EN": "Balhool",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1199",
    "CITYNAME_AR": "مزهره",
    "CITYNAME_EN": "Mazhara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1200",
    "CITYNAME_AR": "الوحله",
    "CITYNAME_EN": "Al Wahla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1201",
    "CITYNAME_AR": "السهليه",
    "CITYNAME_EN": "As-Suhaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1202",
    "CITYNAME_AR": "المناقيز",
    "CITYNAME_EN": "Al Manaqeez",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1203",
    "CITYNAME_AR": "الواسط",
    "CITYNAME_EN": "Al Waset",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1204",
    "CITYNAME_AR": "الحواسبه الشبيكه",
    "CITYNAME_EN": "Ash-Shabakyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1205",
    "CITYNAME_AR": "الفضيه",
    "CITYNAME_EN": "Al Faddyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1206",
    "CITYNAME_AR": "الخمسيه",
    "CITYNAME_EN": "Al Khamsyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1207",
    "CITYNAME_AR": "الخمس",
    "CITYNAME_EN": "Al Khomos",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1208",
    "CITYNAME_AR": "طنان",
    "CITYNAME_EN": "Tannan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1209",
    "CITYNAME_AR": "العمارية",
    "CITYNAME_EN": "Al Omaryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1210",
    "CITYNAME_AR": "جنيده",
    "CITYNAME_EN": "Junaidah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1211",
    "CITYNAME_AR": "العروج",
    "CITYNAME_EN": "Al Arooj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1212",
    "CITYNAME_AR": "الطماميح",
    "CITYNAME_EN": "At-Tamameeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1213",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Al Khulfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1214",
    "CITYNAME_AR": "المرخ",
    "CITYNAME_EN": "Al Markh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1215",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1216",
    "CITYNAME_AR": "الزهبين",
    "CITYNAME_EN": "Az-Zahabayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1217",
    "CITYNAME_AR": "حمد صغير",
    "CITYNAME_EN": "Hamad Sagheer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1218",
    "CITYNAME_AR": "الغورة",
    "CITYNAME_EN": "Al Ghorah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1219",
    "CITYNAME_AR": "البعاجيه والصوارمه",
    "CITYNAME_EN": "Al Baajeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1220",
    "CITYNAME_AR": "شيبانه وال عاتي",
    "CITYNAME_EN": "Shebanet Wal Ati",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1221",
    "CITYNAME_AR": "الطاهريه",
    "CITYNAME_EN": "At-Taheryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1222",
    "CITYNAME_AR": "السويديه",
    "CITYNAME_EN": "As-Suwaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1223",
    "CITYNAME_AR": "المرابى",
    "CITYNAME_EN": "Al Marabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1224",
    "CITYNAME_AR": "القضب",
    "CITYNAME_EN": "Al Qadab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1225",
    "CITYNAME_AR": "الشواكية بنى شوك",
    "CITYNAME_EN": "Ash-Shawaqyat Bani Shouk",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1226",
    "CITYNAME_AR": "المقدره",
    "CITYNAME_EN": "Almuqadaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1227",
    "CITYNAME_AR": "الصوارمه",
    "CITYNAME_EN": "Alsawarimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "1235",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Al-Qa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1236",
    "CITYNAME_AR": "دحله بن سرور",
    "CITYNAME_EN": "Bahlah bin Sorur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1237",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Al-Shaqrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1238",
    "CITYNAME_AR": "الشل",
    "CITYNAME_EN": "Al-Shel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1239",
    "CITYNAME_AR": "الرشداء",
    "CITYNAME_EN": "Al-Rashdaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1240",
    "CITYNAME_AR": "الخشم الاسفل",
    "CITYNAME_EN": "Al-Khashm the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1241",
    "CITYNAME_AR": "الخشم الاعلى",
    "CITYNAME_EN": "Al-Khashm the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1242",
    "CITYNAME_AR": "لبوه",
    "CITYNAME_EN": "Labwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1243",
    "CITYNAME_AR": "اثب",
    "CITYNAME_EN": "Athb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1244",
    "CITYNAME_AR": "الخريص",
    "CITYNAME_EN": "Al-Kharis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1245",
    "CITYNAME_AR": "ابو نظاره",
    "CITYNAME_EN": "Abu Nazarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1246",
    "CITYNAME_AR": "المعامل",
    "CITYNAME_EN": "Al-Ma'amel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1247",
    "CITYNAME_AR": "ريم",
    "CITYNAME_EN": "Reem",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1248",
    "CITYNAME_AR": "الثفراء",
    "CITYNAME_EN": "Al-Thafraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1249",
    "CITYNAME_AR": "الطريف الاسفل",
    "CITYNAME_EN": "Al-Tarif the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1250",
    "CITYNAME_AR": "الطريف الاعلى",
    "CITYNAME_EN": "Al-Tarif the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1251",
    "CITYNAME_AR": "المضه",
    "CITYNAME_EN": "Al-Madha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1252",
    "CITYNAME_AR": "الحيفه",
    "CITYNAME_EN": "Al-Hifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1253",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Al-Dayqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1254",
    "CITYNAME_AR": "الحصاد",
    "CITYNAME_EN": "Al-Hasad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1255",
    "CITYNAME_AR": "الحميطه",
    "CITYNAME_EN": "Al-Hamitah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1256",
    "CITYNAME_AR": "قن",
    "CITYNAME_EN": "Qen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1257",
    "CITYNAME_AR": "ال دحباش",
    "CITYNAME_EN": "Al Dehbash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1258",
    "CITYNAME_AR": "اصلاب",
    "CITYNAME_EN": "Islab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1259",
    "CITYNAME_AR": "ال منيع الصدع",
    "CITYNAME_EN": "Al Mani' Al-Sad' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1260",
    "CITYNAME_AR": "حضن العاده",
    "CITYNAME_EN": "Hodn Al-Adah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1261",
    "CITYNAME_AR": "المجرى",
    "CITYNAME_EN": "Al-Magry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1262",
    "CITYNAME_AR": "الخرب",
    "CITYNAME_EN": "Al-Khrib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1263",
    "CITYNAME_AR": "ال صقر (السوسيه)",
    "CITYNAME_EN": "Al-Saqr (Al-Susiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1264",
    "CITYNAME_AR": "خضيراء",
    "CITYNAME_EN": "Khudayra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1265",
    "CITYNAME_AR": "الضغابيس",
    "CITYNAME_EN": "Al-Dghayis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1266",
    "CITYNAME_AR": "العرفط الاعلى",
    "CITYNAME_EN": "Al-Arfat the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1267",
    "CITYNAME_AR": "العرفط الاسفل",
    "CITYNAME_EN": "Al-Arfat the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1268",
    "CITYNAME_AR": "ام خياله (روضان السليل)",
    "CITYNAME_EN": "Umm Khialah (Rawdan Al-Salil)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1269",
    "CITYNAME_AR": "الطلحه",
    "CITYNAME_EN": "Al-Talhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1270",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "As-Sulayyil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1271",
    "CITYNAME_AR": "المنتزه (الحرف)",
    "CITYNAME_EN": "Al-Muntazah (Al-Harf) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1272",
    "CITYNAME_AR": "دحله بن حريشه",
    "CITYNAME_EN": "Bahlah bin Sharihah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1273",
    "CITYNAME_AR": "الحامض",
    "CITYNAME_EN": "Al-Hamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1274",
    "CITYNAME_AR": "خيبر الجنوب",
    "CITYNAME_EN": "Khaiber Al-Janoub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1275",
    "CITYNAME_AR": "بريم السليل",
    "CITYNAME_EN": "Barim Al-Salil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1276",
    "CITYNAME_AR": "بطنه الحبيب",
    "CITYNAME_EN": "Batnah Al-Habib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1277",
    "CITYNAME_AR": "بطنة الحثدة (الحدده)",
    "CITYNAME_EN": "Batnah Al-Hathdah (Al-Haddah) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1278",
    "CITYNAME_AR": "واسط",
    "CITYNAME_EN": "Waset",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1279",
    "CITYNAME_AR": "الطلاح",
    "CITYNAME_EN": "Al-Talah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1280",
    "CITYNAME_AR": "الحامض",
    "CITYNAME_EN": "Al Hamid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1281",
    "CITYNAME_AR": "القضه (المباريش)",
    "CITYNAME_EN": "Al-Qaddah (Al-Mabarish) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1282",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "Al-Salam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1283",
    "CITYNAME_AR": "الجهراء",
    "CITYNAME_EN": "Al Jahra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1284",
    "CITYNAME_AR": "الشكاع",
    "CITYNAME_EN": "Al-Shika' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1285",
    "CITYNAME_AR": "اثب",
    "CITYNAME_EN": "Athb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1286",
    "CITYNAME_AR": "الاخضرين",
    "CITYNAME_EN": "Al-Akhdarayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1287",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1288",
    "CITYNAME_AR": "عطفه الحنفه",
    "CITYNAME_EN": "Atfah Al-Hanfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1289",
    "CITYNAME_AR": "الثعيلبه",
    "CITYNAME_EN": "Al-Thu'ailibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1290",
    "CITYNAME_AR": "بنى هميص",
    "CITYNAME_EN": "Bani Hamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1291",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1292",
    "CITYNAME_AR": "الطلحه",
    "CITYNAME_EN": "Al-Talhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1293",
    "CITYNAME_AR": "الصفا الملبد",
    "CITYNAME_EN": "Al-Safa Al-Molabid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1294",
    "CITYNAME_AR": "الهوين",
    "CITYNAME_EN": "Al-Huwain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1295",
    "CITYNAME_AR": "الواسطه",
    "CITYNAME_EN": "Al-Wastah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1296",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al-Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1297",
    "CITYNAME_AR": "البغث",
    "CITYNAME_EN": "Al-Baghth ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1298",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1299",
    "CITYNAME_AR": "المريفق",
    "CITYNAME_EN": "Al-Moraifiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1300",
    "CITYNAME_AR": "جلجل (راس القاع)",
    "CITYNAME_EN": "jaljal (Ras-Al-Qa')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1301",
    "CITYNAME_AR": "الشويه",
    "CITYNAME_EN": "Al-Shubah  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1302",
    "CITYNAME_AR": "مهدرويه",
    "CITYNAME_EN": "Mahderwiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1303",
    "CITYNAME_AR": "قريه ال بلقرب",
    "CITYNAME_EN": "Al belqerb Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1304",
    "CITYNAME_AR": "ال بغاش",
    "CITYNAME_EN": "Al Beghash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1305",
    "CITYNAME_AR": "بل لفيع",
    "CITYNAME_EN": "Bel Lafi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1306",
    "CITYNAME_AR": "الحرفين",
    "CITYNAME_EN": "Al-Harfayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1307",
    "CITYNAME_AR": "داليان",
    "CITYNAME_EN": "Dalyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1308",
    "CITYNAME_AR": "الحيمه",
    "CITYNAME_EN": "Al-Himah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1309",
    "CITYNAME_AR": "القضوى",
    "CITYNAME_EN": "Al-Fadwaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1310",
    "CITYNAME_AR": "خضار",
    "CITYNAME_EN": "Khedar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1311",
    "CITYNAME_AR": "شمسيه",
    "CITYNAME_EN": "Shamsiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1312",
    "CITYNAME_AR": "الغلول",
    "CITYNAME_EN": "Al-Ghalul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1313",
    "CITYNAME_AR": "صلحلح",
    "CITYNAME_EN": "Salahlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1314",
    "CITYNAME_AR": "حبكان",
    "CITYNAME_EN": "Habkan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1315",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Al Jafr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1316",
    "CITYNAME_AR": "تباشعه",
    "CITYNAME_EN": "Tbash'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1317",
    "CITYNAME_AR": "طعمه",
    "CITYNAME_EN": "Ta'mah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1318",
    "CITYNAME_AR": "ام خيسه والملاحه",
    "CITYNAME_EN": "Umm Khisah, and Al-Melahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1319",
    "CITYNAME_AR": "المعمله",
    "CITYNAME_EN": "Al-Ma'malah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1320",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1321",
    "CITYNAME_AR": "الخنقه",
    "CITYNAME_EN": "Khanqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1322",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al-Khatwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1323",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Al-Ghabib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1324",
    "CITYNAME_AR": "الحفاه",
    "CITYNAME_EN": "Al-Hufah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1325",
    "CITYNAME_AR": "بنى قاهاه",
    "CITYNAME_EN": "Bani Qahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1326",
    "CITYNAME_AR": "ام سلمه (ام سلم)",
    "CITYNAME_EN": "Umm Salmah (Umm Selm)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1327",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Al Raudah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1328",
    "CITYNAME_AR": "صر",
    "CITYNAME_EN": "Sor",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1329",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1330",
    "CITYNAME_AR": "الجزيره",
    "CITYNAME_EN": "Al-Jazirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1331",
    "CITYNAME_AR": "العجمة و وادي حكا",
    "CITYNAME_EN": "Al Ajmah and Wadi Haka ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1332",
    "CITYNAME_AR": "الدحله والرهوه",
    "CITYNAME_EN": "Al Dahlah and Al Rahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1333",
    "CITYNAME_AR": "مزارع بن صالح",
    "CITYNAME_EN": "Bin Saleh Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1334",
    "CITYNAME_AR": "الوفيزه والمهور",
    "CITYNAME_EN": "Al Wafizah, and Al Mohur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1335",
    "CITYNAME_AR": "القضي",
    "CITYNAME_EN": "Al Qdy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1336",
    "CITYNAME_AR": "مربض",
    "CITYNAME_EN": "Marbd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1337",
    "CITYNAME_AR": "مزارع الدحله",
    "CITYNAME_EN": "Al Dahlah Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1338",
    "CITYNAME_AR": "وادى بن هشبل",
    "CITYNAME_EN": "Wadi Ibn Hashbal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1339",
    "CITYNAME_AR": "خميس مشيط",
    "CITYNAME_EN": "Khamis Mushait",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1340",
    "CITYNAME_AR": "البتيراء الجنوبية",
    "CITYNAME_EN": "Al-Botayraa (the southern)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1341",
    "CITYNAME_AR": "البتيراء الشماليه",
    "CITYNAME_EN": "Al-Botayraa (the northern)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1342",
    "CITYNAME_AR": "شعب بالجرذان",
    "CITYNAME_EN": "She'b in Al-Jerzan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1343",
    "CITYNAME_AR": "القطوعة",
    "CITYNAME_EN": "Al-Qatu'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1344",
    "CITYNAME_AR": "أسرة ال ظفران",
    "CITYNAME_EN": "Al Zafran Family ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1345",
    "CITYNAME_AR": "أسرة ال شطف",
    "CITYNAME_EN": "Al Shazaf Family ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1346",
    "CITYNAME_AR": "أسرة ال مشراف",
    "CITYNAME_EN": "Al Meshraf Family ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1347",
    "CITYNAME_AR": "الخطوة",
    "CITYNAME_EN": "Al-Khatwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1348",
    "CITYNAME_AR": "عرائص الجنوبيه",
    "CITYNAME_EN": "Araed Al-Janubiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1349",
    "CITYNAME_AR": "أسرة آل هقاش",
    "CITYNAME_EN": "Al Haqash Family ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1350",
    "CITYNAME_AR": "تانه",
    "CITYNAME_EN": "Tanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1351",
    "CITYNAME_AR": "شعب اللزان",
    "CITYNAME_EN": "She'b Al-Lazan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1352",
    "CITYNAME_AR": "شعب ابو شب",
    "CITYNAME_EN": "She'bah Abu Sheb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1353",
    "CITYNAME_AR": "أسرة آل سعد",
    "CITYNAME_EN": "Al Sa'd Family ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1354",
    "CITYNAME_AR": "ال يعلى (ال يعلا)",
    "CITYNAME_EN": "Al Yi'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1355",
    "CITYNAME_AR": "المزارقه",
    "CITYNAME_EN": "Al-mazariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1356",
    "CITYNAME_AR": "ال الذيب العليا",
    "CITYNAME_EN": "Al Zeib (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1357",
    "CITYNAME_AR": "ال الذيب السفلى",
    "CITYNAME_EN": "Al Zeib (the lower))",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1358",
    "CITYNAME_AR": "غيثان",
    "CITYNAME_EN": "Ghithan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1359",
    "CITYNAME_AR": "القنه",
    "CITYNAME_EN": "Al-Qanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1360",
    "CITYNAME_AR": "الحنجور",
    "CITYNAME_EN": "Al-Hanjour",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1361",
    "CITYNAME_AR": "ال هماس",
    "CITYNAME_EN": "Al Hemas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1362",
    "CITYNAME_AR": "ال قاعد",
    "CITYNAME_EN": "Al Qa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1363",
    "CITYNAME_AR": "الضور",
    "CITYNAME_EN": "Al-Dur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1364",
    "CITYNAME_AR": "آل حسان",
    "CITYNAME_EN": "Al Hassan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1365",
    "CITYNAME_AR": "شعب دحوان",
    "CITYNAME_EN": "Shi'b dahwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1366",
    "CITYNAME_AR": "ال قطار",
    "CITYNAME_EN": "Al Qetar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1367",
    "CITYNAME_AR": "ال عصبه",
    "CITYNAME_EN": "Al Asbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1368",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1369",
    "CITYNAME_AR": "ال الصعاق",
    "CITYNAME_EN": "Al Al-Seqa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1370",
    "CITYNAME_AR": "الجازع ال مستنير",
    "CITYNAME_EN": "Al-Jazi' Al Mostanir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1371",
    "CITYNAME_AR": "الثنيه ال مستنير",
    "CITYNAME_EN": "Al-Thaniyah  Al Mostanir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1372",
    "CITYNAME_AR": "ال مانع ال مستنير",
    "CITYNAME_EN": "Al Mani'  Al Mostanir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1373",
    "CITYNAME_AR": "الجرف والعرق",
    "CITYNAME_EN": "Al-jarf and Al-Irq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1374",
    "CITYNAME_AR": "الزلال",
    "CITYNAME_EN": "Al-Zulal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1375",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1376",
    "CITYNAME_AR": "ال دبابه",
    "CITYNAME_EN": "Al debabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1377",
    "CITYNAME_AR": "ال ملفى (ال الشريفي)",
    "CITYNAME_EN": "Al Malfi (Al Al-Sherify)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1378",
    "CITYNAME_AR": "ال حجاج",
    "CITYNAME_EN": "Al hajaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1379",
    "CITYNAME_AR": "ال جميل",
    "CITYNAME_EN": "Al Jamil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1380",
    "CITYNAME_AR": "ال التوم",
    "CITYNAME_EN": "Al Al-Tum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1381",
    "CITYNAME_AR": "ال بذبان",
    "CITYNAME_EN": "Al Bezban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1382",
    "CITYNAME_AR": "ال جبير",
    "CITYNAME_EN": "Al Jubair ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1383",
    "CITYNAME_AR": "ال بو فرزعه",
    "CITYNAME_EN": "Al Bu Farza'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1384",
    "CITYNAME_AR": "ال عطيط",
    "CITYNAME_EN": "Al Atit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1385",
    "CITYNAME_AR": "ال زاحمه",
    "CITYNAME_EN": "Al Zahmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1386",
    "CITYNAME_AR": "ال سويد والهضبه",
    "CITYNAME_EN": "Al Suwaid and Al-Hadbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1387",
    "CITYNAME_AR": "ال عياش وال نويهض",
    "CITYNAME_EN": "Al Ayash and Al Nuwihd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1388",
    "CITYNAME_AR": "الاصفر (آل نويهض)",
    "CITYNAME_EN": "Al-Asfar (Al Nuwihd) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1389",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "Al-Samdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1390",
    "CITYNAME_AR": "الشعيثاء",
    "CITYNAME_EN": "Al Sha'ithaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1391",
    "CITYNAME_AR": "ال محى",
    "CITYNAME_EN": "Al Mohie ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1392",
    "CITYNAME_AR": "ال مشهور",
    "CITYNAME_EN": "Al Maghhur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1393",
    "CITYNAME_AR": "ال غوير",
    "CITYNAME_EN": "Umm Ghuair",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1394",
    "CITYNAME_AR": "الفيض",
    "CITYNAME_EN": "Al Faydh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1395",
    "CITYNAME_AR": "الحوطه العليا",
    "CITYNAME_EN": "Al-Hutah the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1396",
    "CITYNAME_AR": "الحوطه السفلى",
    "CITYNAME_EN": "Al-Hutah the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1397",
    "CITYNAME_AR": "ال مريح",
    "CITYNAME_EN": "Al Merbih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1398",
    "CITYNAME_AR": "ال مهياع",
    "CITYNAME_EN": "Al Mihya' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1399",
    "CITYNAME_AR": "الغروس",
    "CITYNAME_EN": "Al-Gharus ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1400",
    "CITYNAME_AR": "الملاص",
    "CITYNAME_EN": "Al-Melas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1401",
    "CITYNAME_AR": "الحمر",
    "CITYNAME_EN": "Al-Hamr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1402",
    "CITYNAME_AR": "القضمه",
    "CITYNAME_EN": "Al-Qadmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1403",
    "CITYNAME_AR": "الميثاء الغربية",
    "CITYNAME_EN": "Al-mithaa Al-Gharbiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1404",
    "CITYNAME_AR": "المحشر (المجعل الجنوبي)",
    "CITYNAME_EN": "Al-Mahshar (Al-Maj'al the southern)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1405",
    "CITYNAME_AR": "ال مليح",
    "CITYNAME_EN": "Al Maleeh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1406",
    "CITYNAME_AR": "المليح",
    "CITYNAME_EN": "Al-Malih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1407",
    "CITYNAME_AR": "سنعبره",
    "CITYNAME_EN": "San'abrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1408",
    "CITYNAME_AR": "السوق",
    "CITYNAME_EN": "Al-Souq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1409",
    "CITYNAME_AR": "مشروع الغول",
    "CITYNAME_EN": "Al-Ghul Project ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1410",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1411",
    "CITYNAME_AR": "مشحذ",
    "CITYNAME_EN": "Mashhaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1412",
    "CITYNAME_AR": "الخضاير",
    "CITYNAME_EN": "Al-Khadayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1413",
    "CITYNAME_AR": "شحمان",
    "CITYNAME_EN": "Shahman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1414",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al-Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1415",
    "CITYNAME_AR": "صحيفان",
    "CITYNAME_EN": "Sahifan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1416",
    "CITYNAME_AR": "المسياب",
    "CITYNAME_EN": "Al-Mesyab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1417",
    "CITYNAME_AR": "تبشع",
    "CITYNAME_EN": "Tabshu' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1418",
    "CITYNAME_AR": "السابله",
    "CITYNAME_EN": "Al-Sabelah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1419",
    "CITYNAME_AR": "هداجه",
    "CITYNAME_EN": "Hdajah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1420",
    "CITYNAME_AR": "الضريسه",
    "CITYNAME_EN": "Al-Darisah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1421",
    "CITYNAME_AR": "الجبيب",
    "CITYNAME_EN": "Al-Jabib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1422",
    "CITYNAME_AR": "النخله",
    "CITYNAME_EN": "Al-Nakhlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1423",
    "CITYNAME_AR": "المراره",
    "CITYNAME_EN": "Al-Mrarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1424",
    "CITYNAME_AR": "مشروع بوضبيان",
    "CITYNAME_EN": "Bu Debyan Project ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1425",
    "CITYNAME_AR": "مشروع بوصواوين",
    "CITYNAME_EN": "Busuwasin Project ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1426",
    "CITYNAME_AR": "بئر جليح",
    "CITYNAME_EN": "Bir Jalih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1427",
    "CITYNAME_AR": "الحلوه",
    "CITYNAME_EN": "Al Holwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1428",
    "CITYNAME_AR": "الحفاير",
    "CITYNAME_EN": "Al Hufayr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1429",
    "CITYNAME_AR": "الخليج",
    "CITYNAME_EN": "Al-Khalij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1430",
    "CITYNAME_AR": "الصفيه",
    "CITYNAME_EN": "Al-Safiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1431",
    "CITYNAME_AR": "جراف",
    "CITYNAME_EN": "Jeraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1432",
    "CITYNAME_AR": "بئر جراف",
    "CITYNAME_EN": "Bir Jeraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1433",
    "CITYNAME_AR": "رثعا",
    "CITYNAME_EN": "Rath'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1434",
    "CITYNAME_AR": "العرقه",
    "CITYNAME_EN": "Al-Irqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1435",
    "CITYNAME_AR": "رغوه",
    "CITYNAME_EN": "Reghwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1436",
    "CITYNAME_AR": "البردان",
    "CITYNAME_EN": "Al-Bardan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1437",
    "CITYNAME_AR": "الجحفه",
    "CITYNAME_EN": "Al Jehfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1438",
    "CITYNAME_AR": "الكظر",
    "CITYNAME_EN": "Al-Kezr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1439",
    "CITYNAME_AR": "ال بيصاع",
    "CITYNAME_EN": "Al Bisa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1440",
    "CITYNAME_AR": "مجعل الشمالى",
    "CITYNAME_EN": "Maj'al the northern ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1441",
    "CITYNAME_AR": "وادى الاخضر",
    "CITYNAME_EN": "Wadi Al-Akhdar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1442",
    "CITYNAME_AR": "الحناه",
    "CITYNAME_EN": "Al Hinnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1443",
    "CITYNAME_AR": "النقعاء",
    "CITYNAME_EN": "Al-Naq'aa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1444",
    "CITYNAME_AR": "وادي النقع",
    "CITYNAME_EN": "Wadi Al-Naq' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1445",
    "CITYNAME_AR": "اللضاه",
    "CITYNAME_EN": "Alladah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1446",
    "CITYNAME_AR": "الشبك",
    "CITYNAME_EN": "Al-Shabk ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1447",
    "CITYNAME_AR": "النغره",
    "CITYNAME_EN": "Al-Naghrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1448",
    "CITYNAME_AR": "الميثاء الشرقية",
    "CITYNAME_EN": "Al-Mithaa Al-Sharqiyah  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1449",
    "CITYNAME_AR": "جليله",
    "CITYNAME_EN": "Jalilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1450",
    "CITYNAME_AR": "الشيق",
    "CITYNAME_EN": "Al-Shiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1451",
    "CITYNAME_AR": "شري القعمه",
    "CITYNAME_EN": "Shary Al-Qa'mah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1452",
    "CITYNAME_AR": "شري النباع",
    "CITYNAME_EN": "Shary Al-Neba' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1453",
    "CITYNAME_AR": "الظليف",
    "CITYNAME_EN": "Al-Zalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1454",
    "CITYNAME_AR": "الحيه",
    "CITYNAME_EN": "Al-Hayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1455",
    "CITYNAME_AR": "العمار",
    "CITYNAME_EN": "Al Emar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1456",
    "CITYNAME_AR": "الجنفور",
    "CITYNAME_EN": "Al Junfour ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1457",
    "CITYNAME_AR": "المقطاع",
    "CITYNAME_EN": "Al Miqta' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1458",
    "CITYNAME_AR": "مهره",
    "CITYNAME_EN": "Muhrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1459",
    "CITYNAME_AR": "مقيد",
    "CITYNAME_EN": "Muqid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1460",
    "CITYNAME_AR": "الصحن",
    "CITYNAME_EN": "Alsahn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1463",
    "CITYNAME_AR": "قلوه",
    "CITYNAME_EN": "Qilwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1758",
    "CITYNAME_AR": "نبعان",
    "CITYNAME_EN": "Ban'an ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1759",
    "CITYNAME_AR": "المقطر",
    "CITYNAME_EN": "Al-Maqter ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1760",
    "CITYNAME_AR": "الناصفه",
    "CITYNAME_EN": "Al Nasefa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1761",
    "CITYNAME_AR": "قرن الدمث",
    "CITYNAME_EN": "Qarn Al-Demth ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1762",
    "CITYNAME_AR": "الدشه",
    "CITYNAME_EN": "Al-Doshah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1763",
    "CITYNAME_AR": "الثعبان",
    "CITYNAME_EN": "Al-Tho'ban ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1764",
    "CITYNAME_AR": "دهو",
    "CITYNAME_EN": "Dahw ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1765",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "Al-Sahwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1766",
    "CITYNAME_AR": "وادي ريم",
    "CITYNAME_EN": "Wadi Reem",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1767",
    "CITYNAME_AR": "سند العوجاء ",
    "CITYNAME_EN": "Snd Al ʻWjāʼ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1768",
    "CITYNAME_AR": "ال دكنان ",
    "CITYNAME_EN": "Al Dknān",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1769",
    "CITYNAME_AR": "القواعد",
    "CITYNAME_EN": "Al Qwāʻd",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1770",
    "CITYNAME_AR": "الرهوة ",
    "CITYNAME_EN": "Ar Rhwh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1771",
    "CITYNAME_AR": "ال عطيه ",
    "CITYNAME_EN": "Al ʻṮyh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1772",
    "CITYNAME_AR": "ام غربان ",
    "CITYNAME_EN": "Umm Ghrbān",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1773",
    "CITYNAME_AR": "حدبة ابو حبوان",
    "CITYNAME_EN": "H̱dbh Abū H̱bwān",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1774",
    "CITYNAME_AR": "مليقطه ",
    "CITYNAME_EN": "Mlyqṯh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1775",
    "CITYNAME_AR": "مورد بن دويهم",
    "CITYNAME_EN": "Murd Bin Dawayuhim",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1776",
    "CITYNAME_AR": "طلعه التمياط",
    "CITYNAME_EN": "Talaeah Altamyat",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1777",
    "CITYNAME_AR": "هجرة الرغلية",
    "CITYNAME_EN": "Hijrat Alraghlia",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1778",
    "CITYNAME_AR": "عرعر",
    "CITYNAME_EN": "Earear",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1779",
    "CITYNAME_AR": "الشاظي مناحي بن بكر",
    "CITYNAME_EN": "Alshazi Manahy Bin Bikr",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1780",
    "CITYNAME_AR": "الهلالي",
    "CITYNAME_EN": "Aldayadib",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1781",
    "CITYNAME_AR": "جديده عرعر",
    "CITYNAME_EN": "Jadiduh Earear",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1782",
    "CITYNAME_AR": "حزم الجلاميد",
    "CITYNAME_EN": "Huzam Aljalamid",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1796",
    "CITYNAME_AR": "الذيابات",
    "CITYNAME_EN": "Aldhiyabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1797",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Alqae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1798",
    "CITYNAME_AR": "المهارسه",
    "CITYNAME_EN": "Almuharisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1799",
    "CITYNAME_AR": "العرفاء",
    "CITYNAME_EN": "Aleurafa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1800",
    "CITYNAME_AR": "محطه الجبوب",
    "CITYNAME_EN": "Station of Aljubub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1801",
    "CITYNAME_AR": "غزايل",
    "CITYNAME_EN": "Ghazayil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1802",
    "CITYNAME_AR": "دخانه",
    "CITYNAME_EN": "Dakhanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1803",
    "CITYNAME_AR": "ملحات",
    "CITYNAME_EN": "Malahat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1804",
    "CITYNAME_AR": "الجبوب (سماره)",
    "CITYNAME_EN": "Aljubub (Smarh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1805",
    "CITYNAME_AR": "الرحيا",
    "CITYNAME_EN": "Alrahya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1806",
    "CITYNAME_AR": "ام الارباع",
    "CITYNAME_EN": "Umm Alarbae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1807",
    "CITYNAME_AR": "قياء",
    "CITYNAME_EN": "Qiaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "1808",
    "CITYNAME_AR": "الشملى",
    "CITYNAME_EN": "Alshumlaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1809",
    "CITYNAME_AR": "هجرة الرفدي",
    "CITYNAME_EN": "Hijrat Alrafdii",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1810",
    "CITYNAME_AR": "هجرة الخمشه",
    "CITYNAME_EN": "Hijrat Alkhumshih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1811",
    "CITYNAME_AR": "البردان",
    "CITYNAME_EN": "Alburdan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1812",
    "CITYNAME_AR": "هجره اللبيدى",
    "CITYNAME_EN": "Hajarah Allubaydaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1813",
    "CITYNAME_AR": "الحنيه",
    "CITYNAME_EN": "Bir Alhaniyh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1814",
    "CITYNAME_AR": "بيضاء نثيل",
    "CITYNAME_EN": "Bayda' Nathil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1815",
    "CITYNAME_AR": "الابيتر",
    "CITYNAME_EN": "Alabytr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1816",
    "CITYNAME_AR": "قليب الاطرم",
    "CITYNAME_EN": "Qalib Alatrim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1817",
    "CITYNAME_AR": "بدائع بن شتيوى",
    "CITYNAME_EN": "Bdayie Bin Shatiwaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1818",
    "CITYNAME_AR": "بيضاء نثيل الجنوبيه",
    "CITYNAME_EN": "Bayda' Nathil Aljanubiih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1819",
    "CITYNAME_AR": "روضة التنهاة",
    "CITYNAME_EN": "Rawdat Altinha",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1820",
    "CITYNAME_AR": "فيضة بن سويلم",
    "CITYNAME_EN": "Faydat Bin Swylm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1821",
    "CITYNAME_AR": "ابار طفحان",
    "CITYNAME_EN": "Abar Tafhan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1822",
    "CITYNAME_AR": "ذراف",
    "CITYNAME_EN": "Dhiraf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1823",
    "CITYNAME_AR": "عمائر بن صنعاء",
    "CITYNAME_EN": "Eamayir Bin Sanea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1824",
    "CITYNAME_AR": "نعيمين",
    "CITYNAME_EN": "Neymyn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1825",
    "CITYNAME_AR": "بئر بن منوه",
    "CITYNAME_EN": "Biir Bin Manuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1826",
    "CITYNAME_AR": "مبرز",
    "CITYNAME_EN": "Mubriz",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1827",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Masharifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1828",
    "CITYNAME_AR": "اسبطر",
    "CITYNAME_EN": "Asbatar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1829",
    "CITYNAME_AR": "الدفاين",
    "CITYNAME_EN": "Aldafaayin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1830",
    "CITYNAME_AR": "بئر الرفدى",
    "CITYNAME_EN": "Biir Alrafdaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1831",
    "CITYNAME_AR": "دفاين الضبا",
    "CITYNAME_EN": "Dafayin Aldabba",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1832",
    "CITYNAME_AR": "محير ذويره",
    "CITYNAME_EN": "Muhir Dhurih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1833",
    "CITYNAME_AR": "البويطن",
    "CITYNAME_EN": "Albuytnu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1834",
    "CITYNAME_AR": "حفائر العهين",
    "CITYNAME_EN": "Hafayir Aleahin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1835",
    "CITYNAME_AR": "لبده",
    "CITYNAME_EN": "Labdah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1836",
    "CITYNAME_AR": "ضاحية الشملي الغربية",
    "CITYNAME_EN": "Dahiat Alshamlii Algharbia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1837",
    "CITYNAME_AR": "حفيرة بن سويلم",
    "CITYNAME_EN": "Hafirat Bin Swylm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1838",
    "CITYNAME_AR": "حفيرة السبعه",
    "CITYNAME_EN": "Hafirat Alsabeih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1839",
    "CITYNAME_AR": "مخطط بن سويلم",
    "CITYNAME_EN": "Mukhatat Bin Swylm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1840",
    "CITYNAME_AR": "غزلانه",
    "CITYNAME_EN": "Ghazlanah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1841",
    "CITYNAME_AR": "قريه ام نخله",
    "CITYNAME_EN": "Qurih Umm Nakhuluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1842",
    "CITYNAME_AR": "الكهفه",
    "CITYNAME_EN": "Alkahfuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1843",
    "CITYNAME_AR": "مشاش الطرفا",
    "CITYNAME_EN": "Mashash Altarafa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1844",
    "CITYNAME_AR": "مخطط روضه الطليحه",
    "CITYNAME_EN": "Mukhatat Ruduh Altalihah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1845",
    "CITYNAME_AR": "الكهيفيه",
    "CITYNAME_EN": "Alkahifih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1846",
    "CITYNAME_AR": "السعيره",
    "CITYNAME_EN": "Alsaeiruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1847",
    "CITYNAME_AR": "ودي الحزم   الودي",
    "CITYNAME_EN": "Wadi Alhizm Alwidiyi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1848",
    "CITYNAME_AR": "مخطط الدهيثم",
    "CITYNAME_EN": "Mukhatat Aldahithim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1849",
    "CITYNAME_AR": "مخطط الكتاديات",
    "CITYNAME_EN": "Mukhatat Alkitadiat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1850",
    "CITYNAME_AR": "مخطط الشفاء",
    "CITYNAME_EN": "Mukhatat Alshifa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1851",
    "CITYNAME_AR": "مخطط ام عمائر",
    "CITYNAME_EN": "Mukhatat Umm Eamayir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1852",
    "CITYNAME_AR": "المضابيع",
    "CITYNAME_EN": "Almadabie",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1853",
    "CITYNAME_AR": "حمض",
    "CITYNAME_EN": "Himad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1854",
    "CITYNAME_AR": "المعازل",
    "CITYNAME_EN": "Almaeazil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1855",
    "CITYNAME_AR": "البير",
    "CITYNAME_EN": "Albir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1856",
    "CITYNAME_AR": "جنفاء",
    "CITYNAME_EN": "Janfa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1857",
    "CITYNAME_AR": "بدايع الدهمشيه",
    "CITYNAME_EN": "Badayie Aldahmashiih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1858",
    "CITYNAME_AR": "الحوراء",
    "CITYNAME_EN": "Alhuara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1859",
    "CITYNAME_AR": "المضيح",
    "CITYNAME_EN": "Almudih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1860",
    "CITYNAME_AR": "ريع تقريب",
    "CITYNAME_EN": "Raye Taqrib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1861",
    "CITYNAME_AR": "دغنون",
    "CITYNAME_EN": "Daghnun",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1862",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "Alsafara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1863",
    "CITYNAME_AR": "سميراء",
    "CITYNAME_EN": "Samira'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1864",
    "CITYNAME_AR": "بدايع القعساء",
    "CITYNAME_EN": "Badayie Alquesa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1865",
    "CITYNAME_AR": "صفاق",
    "CITYNAME_EN": "Safaq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1866",
    "CITYNAME_AR": "الديماسه",
    "CITYNAME_EN": "Aldiymasuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1867",
    "CITYNAME_AR": "عقلة اللبن",
    "CITYNAME_EN": "Euqlat Allabn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1868",
    "CITYNAME_AR": "الجفاليه",
    "CITYNAME_EN": "Aljafaliuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1869",
    "CITYNAME_AR": "القعساء",
    "CITYNAME_EN": "Alquesa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1870",
    "CITYNAME_AR": "حمراء القعساء",
    "CITYNAME_EN": "Hamra' Alquesa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1871",
    "CITYNAME_AR": "الصفران",
    "CITYNAME_EN": "Alsifran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1872",
    "CITYNAME_AR": "مشاش  بن جازي",
    "CITYNAME_EN": "Mshash Bin Jazi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1873",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Alrafayie",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1874",
    "CITYNAME_AR": "حريد",
    "CITYNAME_EN": "Harid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1875",
    "CITYNAME_AR": "الغانميه",
    "CITYNAME_EN": "Alghanimih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1876",
    "CITYNAME_AR": "وسيط",
    "CITYNAME_EN": "Wasit",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1877",
    "CITYNAME_AR": "وتده",
    "CITYNAME_EN": "Watadah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1878",
    "CITYNAME_AR": "الحموديه",
    "CITYNAME_EN": "Alhumudiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1879",
    "CITYNAME_AR": "العظيم",
    "CITYNAME_EN": "Aleazim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1880",
    "CITYNAME_AR": "بدايع المكحول",
    "CITYNAME_EN": "Badayie Almakhul",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1881",
    "CITYNAME_AR": "الخوه",
    "CITYNAME_EN": "Alkhawh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1882",
    "CITYNAME_AR": "المرير",
    "CITYNAME_EN": "Almarir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1883",
    "CITYNAME_AR": "عويشز",
    "CITYNAME_EN": "Euyushz",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1884",
    "CITYNAME_AR": "سميراء الضباع",
    "CITYNAME_EN": "Samira' Aldibae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1885",
    "CITYNAME_AR": "مزارع حبشي",
    "CITYNAME_EN": "  Habashiun Farms",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1886",
    "CITYNAME_AR": "مزارع الأسيمر",
    "CITYNAME_EN": "  Alasymr Farms",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1887",
    "CITYNAME_AR": "المكحول",
    "CITYNAME_EN": "Almakhul",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1888",
    "CITYNAME_AR": "بدايع العظيم",
    "CITYNAME_EN": "Badayie Aleazim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1889",
    "CITYNAME_AR": "حارة العرادين",
    "CITYNAME_EN": "Harat Alearadin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1890",
    "CITYNAME_AR": "عقله بن طواله",
    "CITYNAME_EN": "Eaqilah Bin Tawalih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1891",
    "CITYNAME_AR": "البركه",
    "CITYNAME_EN": "Albarkuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1892",
    "CITYNAME_AR": "مشرفة",
    "CITYNAME_EN": "Mushrifa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1893",
    "CITYNAME_AR": "عقلة مشعان",
    "CITYNAME_EN": "Euqlat Mashean",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1894",
    "CITYNAME_AR": "عقله بن دانى",
    "CITYNAME_EN": "Eaqilah Bin Daanaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1895",
    "CITYNAME_AR": "قدر",
    "CITYNAME_EN": "Qadar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1896",
    "CITYNAME_AR": "الخنقه الرماحي",
    "CITYNAME_EN": "Alkhanquh Alrimahiu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1897",
    "CITYNAME_AR": "بدائع عقلة بن داني",
    "CITYNAME_EN": "Bdayie Euqlat Bin Dany",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1898",
    "CITYNAME_AR": "بدائع عقلة جديد",
    "CITYNAME_EN": "Bdayie Euqlat Jadid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1899",
    "CITYNAME_AR": "غمره",
    "CITYNAME_EN": "Ghamirah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1900",
    "CITYNAME_AR": "بدايع غمره",
    "CITYNAME_EN": "Bidayie Ghamarih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1901",
    "CITYNAME_AR": "الجعراء",
    "CITYNAME_EN": "Aljuera'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1902",
    "CITYNAME_AR": "غسل",
    "CITYNAME_EN": "Ghasil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1903",
    "CITYNAME_AR": "كتيفه",
    "CITYNAME_EN": "Katifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1904",
    "CITYNAME_AR": "الجابرية",
    "CITYNAME_EN": "Aljabiria",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1905",
    "CITYNAME_AR": "القصير بن متروك",
    "CITYNAME_EN": "Alqasir Bin Matruk",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1906",
    "CITYNAME_AR": "دريعه",
    "CITYNAME_EN": "Dryʻh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1907",
    "CITYNAME_AR": "قريه العليا",
    "CITYNAME_EN": "Qaryat al-Ulya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "1923",
    "CITYNAME_AR": "القوينيه",
    "CITYNAME_EN": "Alquayniuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1924",
    "CITYNAME_AR": "المبرز",
    "CITYNAME_EN": "Almubriz",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1925",
    "CITYNAME_AR": "المعرش",
    "CITYNAME_EN": "Almaerish",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1926",
    "CITYNAME_AR": "ثمايل الشقران",
    "CITYNAME_EN": "Thamayil Alshuqran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1927",
    "CITYNAME_AR": "الزبنيه",
    "CITYNAME_EN": "Alzabniuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1928",
    "CITYNAME_AR": "بدائع بن نماء",
    "CITYNAME_EN": "Bdayie Bin Nama'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1929",
    "CITYNAME_AR": "العيثمه",
    "CITYNAME_EN": "Aleaythamuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1930",
    "CITYNAME_AR": "الحليفه السفلى",
    "CITYNAME_EN": "Alhalifuh Alsuflaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1931",
    "CITYNAME_AR": "فياض  الحامريه",
    "CITYNAME_EN": "Fiad Alhamirih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1932",
    "CITYNAME_AR": "طلوح",
    "CITYNAME_EN": "Tuluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1933",
    "CITYNAME_AR": "الحارات الثلاث",
    "CITYNAME_EN": "Alharat Althalath",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1934",
    "CITYNAME_AR": "الحليفه العليا",
    "CITYNAME_EN": "Alhalifuh Aleulya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1935",
    "CITYNAME_AR": "الوسيطاء",
    "CITYNAME_EN": "Alwasita'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1936",
    "CITYNAME_AR": "القسمه",
    "CITYNAME_EN": "Alqismuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1937",
    "CITYNAME_AR": "العليا",
    "CITYNAME_EN": "Aleulya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1938",
    "CITYNAME_AR": "الدوادمى",
    "CITYNAME_EN": "Aldawadumaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1939",
    "CITYNAME_AR": "هجرة المخروق",
    "CITYNAME_EN": "Hijrat Almakhruq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1940",
    "CITYNAME_AR": "الملاح",
    "CITYNAME_EN": "Almalah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1941",
    "CITYNAME_AR": "صفيط",
    "CITYNAME_EN": "Safit",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1942",
    "CITYNAME_AR": "القراره",
    "CITYNAME_EN": "Alqararuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1943",
    "CITYNAME_AR": "المكظم",
    "CITYNAME_EN": "Almukazam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1944",
    "CITYNAME_AR": "ال طلوحه",
    "CITYNAME_EN": "Al Tuluhuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1945",
    "CITYNAME_AR": "ام روشن",
    "CITYNAME_EN": "Umm Rushn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1946",
    "CITYNAME_AR": "الحماده",
    "CITYNAME_EN": "Alhimaduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1947",
    "CITYNAME_AR": "الخفيج",
    "CITYNAME_EN": "Alkhafij",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1948",
    "CITYNAME_AR": "ام هشيم",
    "CITYNAME_EN": "Umm Hashim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1949",
    "CITYNAME_AR": "عقله المخروق",
    "CITYNAME_EN": "Eaqalah Almakhruq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1950",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "Alsafara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1951",
    "CITYNAME_AR": "قسمه بن فضلون",
    "CITYNAME_EN": "Qasimah Bin Fadilun",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1952",
    "CITYNAME_AR": "الدابيه",
    "CITYNAME_EN": "Aldaabih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1953",
    "CITYNAME_AR": "البويب",
    "CITYNAME_EN": "Albawib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1954",
    "CITYNAME_AR": "عمائر المرير",
    "CITYNAME_EN": "Eamayir Almurir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1955",
    "CITYNAME_AR": "الملوى",
    "CITYNAME_EN": "Almalwaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1956",
    "CITYNAME_AR": "عيال عبيد",
    "CITYNAME_EN": "Eial Eubayd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1957",
    "CITYNAME_AR": "المغار",
    "CITYNAME_EN": "Almaghar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1958",
    "CITYNAME_AR": "امشاش الثلابيت",
    "CITYNAME_EN": "Amshash Althalabiat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1959",
    "CITYNAME_AR": "الشويلعيه",
    "CITYNAME_EN": "Alshuwayleiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1960",
    "CITYNAME_AR": "الرقب",
    "CITYNAME_EN": "Alraqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1961",
    "CITYNAME_AR": "الطـليحات",
    "CITYNAME_EN": "Alttlihat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1962",
    "CITYNAME_AR": "الطولاء",
    "CITYNAME_EN": "Altuwla'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1963",
    "CITYNAME_AR": "المضبعه",
    "CITYNAME_EN": "Almudabaeuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1964",
    "CITYNAME_AR": "نخله",
    "CITYNAME_EN": "Nakhluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1965",
    "CITYNAME_AR": "ملحا",
    "CITYNAME_EN": "Malahaan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1966",
    "CITYNAME_AR": "قصيباء",
    "CITYNAME_EN": "Qsyba'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1967",
    "CITYNAME_AR": "فرشة نجب",
    "CITYNAME_EN": "Farshat Nujib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1968",
    "CITYNAME_AR": "فيضه العلم",
    "CITYNAME_EN": "Fiduh Aleilm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1969",
    "CITYNAME_AR": "قسمه الرقب",
    "CITYNAME_EN": "Qasamah Alraqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1970",
    "CITYNAME_AR": "شعيب الصقعاء",
    "CITYNAME_EN": "Shueayb Alsuqea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1971",
    "CITYNAME_AR": "ام  زريبه الشرقيه والغربيه",
    "CITYNAME_EN": "Umm Zaribuh Alsharaqiuh Walgharbiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1972",
    "CITYNAME_AR": "ابو سواسي",
    "CITYNAME_EN": "'Abu Swasi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1973",
    "CITYNAME_AR": "الحواره",
    "CITYNAME_EN": "Alhiwaruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1974",
    "CITYNAME_AR": "النميره",
    "CITYNAME_EN": "Alnamiruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1975",
    "CITYNAME_AR": "دبسا",
    "CITYNAME_EN": "Dabsa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1976",
    "CITYNAME_AR": "آبارعوض",
    "CITYNAME_EN": "Abareud",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1977",
    "CITYNAME_AR": "مغيراء",
    "CITYNAME_EN": "Mogayra",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1978",
    "CITYNAME_AR": "ابو زرائب",
    "CITYNAME_EN": "Abo  Zaraeb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1979",
    "CITYNAME_AR": "الخشيبه",
    "CITYNAME_EN": "Al Khushaybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1980",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sedrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1981",
    "CITYNAME_AR": "الوزب",
    "CITYNAME_EN": "Al Wazb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1982",
    "CITYNAME_AR": "المرمى",
    "CITYNAME_EN": "Al Marma",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1983",
    "CITYNAME_AR": "مغيطه",
    "CITYNAME_EN": "Mughaytah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1984",
    "CITYNAME_AR": "القطار",
    "CITYNAME_EN": "Al Qetar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1985",
    "CITYNAME_AR": "ام الوهم",
    "CITYNAME_EN": "Om Al Wahm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1986",
    "CITYNAME_AR": "ضاعا",
    "CITYNAME_EN": "Daah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1987",
    "CITYNAME_AR": "واقصه",
    "CITYNAME_EN": "Waqsah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1988",
    "CITYNAME_AR": "صوير",
    "CITYNAME_EN": "Suwayr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1989",
    "CITYNAME_AR": "شرث",
    "CITYNAME_EN": "Sharth",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1990",
    "CITYNAME_AR": "قصيب ابو سيال",
    "CITYNAME_EN": "Qusayeb Abo Seyal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1991",
    "CITYNAME_AR": "قصيب الاعوج",
    "CITYNAME_EN": "Qusayeb Al Awaj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1992",
    "CITYNAME_AR": "قصيب ابو خريط",
    "CITYNAME_EN": "Qusayeb Abo Kharet",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1993",
    "CITYNAME_AR": "الاريفح",
    "CITYNAME_EN": "Al Arefah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1994",
    "CITYNAME_AR": "ابو زبيره",
    "CITYNAME_EN": "Abo Zubayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1995",
    "CITYNAME_AR": "كتيفه",
    "CITYNAME_EN": "Kutayfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1996",
    "CITYNAME_AR": "مصادر",
    "CITYNAME_EN": "Masader",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1997",
    "CITYNAME_AR": "جثيوث",
    "CITYNAME_EN": "Gathyuth",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1998",
    "CITYNAME_AR": "ام هدم",
    "CITYNAME_EN": "Om Hadm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "1999",
    "CITYNAME_AR": "المتفاشق",
    "CITYNAME_EN": "Al Mutafasheq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2000",
    "CITYNAME_AR": "دعامه",
    "CITYNAME_EN": "Duamah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2001",
    "CITYNAME_AR": "امول",
    "CITYNAME_EN": "Amwal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2002",
    "CITYNAME_AR": "مراخ",
    "CITYNAME_EN": "Merakh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2003",
    "CITYNAME_AR": "وقير",
    "CITYNAME_EN": "Waqeer",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2004",
    "CITYNAME_AR": "العين الجديده",
    "CITYNAME_EN": "Al-Ayn Al-Jadidah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2005",
    "CITYNAME_AR": "العين القديمه",
    "CITYNAME_EN": "Al Ayn Al Qadymah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2006",
    "CITYNAME_AR": "الجديده",
    "CITYNAME_EN": "Al Jadidah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2007",
    "CITYNAME_AR": "عرعر",
    "CITYNAME_EN": "Arar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2008",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Malh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2009",
    "CITYNAME_AR": "ام سيال",
    "CITYNAME_EN": "Om Sayal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2010",
    "CITYNAME_AR": "بئر الاراك",
    "CITYNAME_EN": "Al Arak well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2011",
    "CITYNAME_AR": "بئر الخربه",
    "CITYNAME_EN": "Al Kharabah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2012",
    "CITYNAME_AR": "بئرخصلف",
    "CITYNAME_EN": "Khalsaf well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2013",
    "CITYNAME_AR": "ريام",
    "CITYNAME_EN": "Riam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2014",
    "CITYNAME_AR": "بئر المشروع",
    "CITYNAME_EN": "Biir Almashrue (Project Well)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2015",
    "CITYNAME_AR": "قرية المثلث",
    "CITYNAME_EN": "Qaryat Almuthlath",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2016",
    "CITYNAME_AR": "احامر",
    "CITYNAME_EN": "Ahamr",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2017",
    "CITYNAME_AR": "العلا",
    "CITYNAME_EN": "Al-Ula",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2018",
    "CITYNAME_AR": "المعتدل",
    "CITYNAME_EN": "Al Muatadel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2019",
    "CITYNAME_AR": "بئر بن سلطان",
    "CITYNAME_EN": "Bin Sultan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2020",
    "CITYNAME_AR": "قاع الحاج",
    "CITYNAME_EN": "Qai Al Haj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2021",
    "CITYNAME_AR": "الرفيعه",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2022",
    "CITYNAME_AR": "حلاوه",
    "CITYNAME_EN": "Halawah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2023",
    "CITYNAME_AR": "الحجر (مدائن صالح)",
    "CITYNAME_EN": "Al Hajr (Saleh Madaen)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2024",
    "CITYNAME_AR": "الحصاة(حصان الداب)",
    "CITYNAME_EN": "Al Husah (Husan Ad-Dab)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2025",
    "CITYNAME_AR": "ام صور",
    "CITYNAME_EN": "Om Sur",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2026",
    "CITYNAME_AR": "العذيب",
    "CITYNAME_EN": "Al Atheeb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2027",
    "CITYNAME_AR": "قاع ترك",
    "CITYNAME_EN": "Qai Turk",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2028",
    "CITYNAME_AR": "قراقر",
    "CITYNAME_EN": "Qaraqer",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2029",
    "CITYNAME_AR": "الملسن",
    "CITYNAME_EN": "Al Malsan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2030",
    "CITYNAME_AR": "الوسيطاء",
    "CITYNAME_EN": "Al Wusayta",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2031",
    "CITYNAME_AR": "ام طليحه",
    "CITYNAME_EN": "Om Tulayhah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2032",
    "CITYNAME_AR": "الشراعبه",
    "CITYNAME_EN": "Ash-Shura'yah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2033",
    "CITYNAME_AR": "قراره",
    "CITYNAME_EN": "Qararah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2034",
    "CITYNAME_AR": "الطرطحيه",
    "CITYNAME_EN": "At-Turtahyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2035",
    "CITYNAME_AR": "شلال",
    "CITYNAME_EN": "Shelal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2036",
    "CITYNAME_AR": "ثربه",
    "CITYNAME_EN": "Thurayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2037",
    "CITYNAME_AR": "المنقا",
    "CITYNAME_EN": "Al Manqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2038",
    "CITYNAME_AR": "البريكه",
    "CITYNAME_EN": "Al Bureikah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2039",
    "CITYNAME_AR": "النجيل",
    "CITYNAME_EN": "Al Najeel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2040",
    "CITYNAME_AR": "العرف",
    "CITYNAME_EN": "Al Aurf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2041",
    "CITYNAME_AR": "وادى الجزل",
    "CITYNAME_EN": "Wadi Al Jazal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2042",
    "CITYNAME_AR": "البلاطه",
    "CITYNAME_EN": "Al Balatah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2043",
    "CITYNAME_AR": "عرن",
    "CITYNAME_EN": "Aran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2044",
    "CITYNAME_AR": "احر",
    "CITYNAME_EN": "Ahar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2045",
    "CITYNAME_AR": "شوساء",
    "CITYNAME_EN": "Shawsaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2046",
    "CITYNAME_AR": "عورش",
    "CITYNAME_EN": "Ourash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2047",
    "CITYNAME_AR": "الخرقه",
    "CITYNAME_EN": "Al Kharqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2048",
    "CITYNAME_AR": "الناطف",
    "CITYNAME_EN": "An-Natef",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2049",
    "CITYNAME_AR": "المقرح",
    "CITYNAME_EN": "Al Muqreh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2050",
    "CITYNAME_AR": "قناوه",
    "CITYNAME_EN": "Qenawah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2051",
    "CITYNAME_AR": "النحيته",
    "CITYNAME_EN": "An-Nuhaytah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2052",
    "CITYNAME_AR": "البديعه",
    "CITYNAME_EN": "Al Badi'ah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2053",
    "CITYNAME_AR": "افق",
    "CITYNAME_EN": "Ofoq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2054",
    "CITYNAME_AR": "قطية",
    "CITYNAME_EN": "Qutaya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2055",
    "CITYNAME_AR": "الورد",
    "CITYNAME_EN": "Al Ward",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2056",
    "CITYNAME_AR": "عكاف",
    "CITYNAME_EN": "Okaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2057",
    "CITYNAME_AR": "جيله",
    "CITYNAME_EN": "Jaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2058",
    "CITYNAME_AR": "سرار",
    "CITYNAME_EN": "Serar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2059",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Al Farsh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2060",
    "CITYNAME_AR": "وادى قرم",
    "CITYNAME_EN": "Wadi Qerm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2061",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2062",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Al Jafr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2063",
    "CITYNAME_AR": "جراب",
    "CITYNAME_EN": "Jerab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2064",
    "CITYNAME_AR": "الوفض",
    "CITYNAME_EN": "Al Wafd",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2065",
    "CITYNAME_AR": "عكبر",
    "CITYNAME_EN": "Akbar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2066",
    "CITYNAME_AR": "قميله",
    "CITYNAME_EN": "Qumaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2067",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Ar-Rayghah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2068",
    "CITYNAME_AR": "شرحوط",
    "CITYNAME_EN": "Sharhoot",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2069",
    "CITYNAME_AR": "طيب اسم الجنوب",
    "CITYNAME_EN": "Teeb Esm Al Janub",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2070",
    "CITYNAME_AR": "يمينه",
    "CITYNAME_EN": "Yumaynah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2071",
    "CITYNAME_AR": "وجه",
    "CITYNAME_EN": "Wajah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2072",
    "CITYNAME_AR": "الملقا",
    "CITYNAME_EN": "Al Malqa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2073",
    "CITYNAME_AR": "فضلا",
    "CITYNAME_EN": "Fadhla",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2074",
    "CITYNAME_AR": "جبل الكويره",
    "CITYNAME_EN": "Al Quwayrah mountain",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2075",
    "CITYNAME_AR": "ابو طينه",
    "CITYNAME_EN": "Abo Taynah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2076",
    "CITYNAME_AR": "السطيح",
    "CITYNAME_EN": "As-Sutayeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2077",
    "CITYNAME_AR": "دف رحال",
    "CITYNAME_EN": "Daf Rehal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2078",
    "CITYNAME_AR": "الرقيبه",
    "CITYNAME_EN": "Ar-Raqeebah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2079",
    "CITYNAME_AR": "اجمار",
    "CITYNAME_EN": "Ajmar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2080",
    "CITYNAME_AR": "الفلقه",
    "CITYNAME_EN": "Al Falaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2081",
    "CITYNAME_AR": "الراشده",
    "CITYNAME_EN": "Alrrashiduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2082",
    "CITYNAME_AR": "النشيفه",
    "CITYNAME_EN": "Alnashifuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2083",
    "CITYNAME_AR": "مغيره النشيفه",
    "CITYNAME_EN": "Maghiruh Alnashifuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2084",
    "CITYNAME_AR": "وادى اميغر",
    "CITYNAME_EN": "Wa'Adaa Amyghar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2085",
    "CITYNAME_AR": "الصبيخه",
    "CITYNAME_EN": "Alsabykhuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2086",
    "CITYNAME_AR": "وادى النخيبير",
    "CITYNAME_EN": "Wa'Adaa Alnakhibir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2087",
    "CITYNAME_AR": "اللحى",
    "CITYNAME_EN": "Allhaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2088",
    "CITYNAME_AR": "الذييل",
    "CITYNAME_EN": "Aldhyyl",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2089",
    "CITYNAME_AR": "الهضبه ام عصا",
    "CITYNAME_EN": "Alhadbuh Umm Easa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2090",
    "CITYNAME_AR": "ام سدره",
    "CITYNAME_EN": "Umm Sadarah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2091",
    "CITYNAME_AR": "ام ضباع",
    "CITYNAME_EN": "Umm Dubae",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2092",
    "CITYNAME_AR": "المحاش",
    "CITYNAME_EN": "Almahash",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2093",
    "CITYNAME_AR": "غريبين",
    "CITYNAME_EN": "Ghuribin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2094",
    "CITYNAME_AR": "ضريه",
    "CITYNAME_EN": "Darih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "2198",
    "CITYNAME_AR": "مغيرى الطبيق",
    "CITYNAME_EN": "Mughiraa Altabiq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2199",
    "CITYNAME_AR": "الهوج",
    "CITYNAME_EN": "Alhuj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2200",
    "CITYNAME_AR": "القليبه",
    "CITYNAME_EN": "Alqalibuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2201",
    "CITYNAME_AR": "المعظم",
    "CITYNAME_EN": "Almezm",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2202",
    "CITYNAME_AR": "فجر",
    "CITYNAME_EN": "Fajar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2203",
    "CITYNAME_AR": "الحوي",
    "CITYNAME_EN": "Alhaway",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2205",
    "CITYNAME_AR": "المائيين",
    "CITYNAME_EN": "Almayiyin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2206",
    "CITYNAME_AR": "ارتواز السعلوه",
    "CITYNAME_EN": "Airtiwaz Alsaealuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2207",
    "CITYNAME_AR": "وادى سلمي",
    "CITYNAME_EN": "Wa'Adaa Silmiin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2208",
    "CITYNAME_AR": "اشواق",
    "CITYNAME_EN": "Ashwaq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2209",
    "CITYNAME_AR": "الشبيكه",
    "CITYNAME_EN": "Alshabikuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2210",
    "CITYNAME_AR": "الديسه",
    "CITYNAME_EN": "Aldaysuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2211",
    "CITYNAME_AR": "قراقر",
    "CITYNAME_EN": "Qaraqir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2212",
    "CITYNAME_AR": "ابا العجاج",
    "CITYNAME_EN": "'Abana Aleijaj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2213",
    "CITYNAME_AR": "مدان",
    "CITYNAME_EN": "Madan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2214",
    "CITYNAME_AR": "تربان",
    "CITYNAME_EN": "Tarbaan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2215",
    "CITYNAME_AR": "انقد",
    "CITYNAME_EN": "Ainqad",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2216",
    "CITYNAME_AR": "صخين",
    "CITYNAME_EN": "Sikhin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2217",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Alhijruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2218",
    "CITYNAME_AR": "راكان",
    "CITYNAME_EN": "Rakan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2219",
    "CITYNAME_AR": "شغب",
    "CITYNAME_EN": "Shaghab",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2220",
    "CITYNAME_AR": "قامره",
    "CITYNAME_EN": "Qamirah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2221",
    "CITYNAME_AR": "مدسوس",
    "CITYNAME_EN": "Madasus",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2222",
    "CITYNAME_AR": "نابع داما",
    "CITYNAME_EN": "Nabie Dama",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2223",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Albadie",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2224",
    "CITYNAME_AR": "الخنتقى",
    "CITYNAME_EN": "Alkhuntuqaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2225",
    "CITYNAME_AR": "المعو",
    "CITYNAME_EN": "Almaeu",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2226",
    "CITYNAME_AR": "حقيل",
    "CITYNAME_EN": "Haqil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2227",
    "CITYNAME_AR": "السعلوه",
    "CITYNAME_EN": "Alsaealuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2228",
    "CITYNAME_AR": "صدير",
    "CITYNAME_EN": "Sadir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2229",
    "CITYNAME_AR": "قبقاب",
    "CITYNAME_EN": "Qabqab",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2230",
    "CITYNAME_AR": "ابو القزاز",
    "CITYNAME_EN": "'Abu Alqizaz",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2231",
    "CITYNAME_AR": "ابو دومه",
    "CITYNAME_EN": "'Abu Dumih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2232",
    "CITYNAME_AR": "بداء",
    "CITYNAME_EN": "Bida'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2233",
    "CITYNAME_AR": "سحوب",
    "CITYNAME_EN": "Sahub",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2234",
    "CITYNAME_AR": "بئر الجنينه",
    "CITYNAME_EN": "Biir Aljaninuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2235",
    "CITYNAME_AR": "لصيفان",
    "CITYNAME_EN": "Lisifan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2236",
    "CITYNAME_AR": "بئر بحار",
    "CITYNAME_EN": "Biir Bihaar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2237",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Aljirf",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2238",
    "CITYNAME_AR": "العريفات",
    "CITYNAME_EN": "Alearyfat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2239",
    "CITYNAME_AR": "الاسكان الخيري",
    "CITYNAME_EN": "Al'Iiskan Alkhayriu",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2240",
    "CITYNAME_AR": "أم جرفان",
    "CITYNAME_EN": "Umm Jarfan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2241",
    "CITYNAME_AR": "النابع",
    "CITYNAME_EN": "Alnaabie",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2242",
    "CITYNAME_AR": "سحبان",
    "CITYNAME_EN": "Sahaban",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2243",
    "CITYNAME_AR": "الشظوه",
    "CITYNAME_EN": "Alshuzuwh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2244",
    "CITYNAME_AR": "ليلى",
    "CITYNAME_EN": "Laylaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2245",
    "CITYNAME_AR": "الكر",
    "CITYNAME_EN": "Alkuru",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2246",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Alhafiruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2247",
    "CITYNAME_AR": "المخاظه",
    "CITYNAME_EN": "Almakhazih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "2248",
    "CITYNAME_AR": "المبادع",
    "CITYNAME_EN": "Almubadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2249",
    "CITYNAME_AR": "ظفرة",
    "CITYNAME_EN": "Zafira",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2250",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Alruwduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2251",
    "CITYNAME_AR": "السعده",
    "CITYNAME_EN": "Alsaeduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2252",
    "CITYNAME_AR": "الضربه",
    "CITYNAME_EN": "Aldarbuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2253",
    "CITYNAME_AR": "العش",
    "CITYNAME_EN": "Aleash",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2254",
    "CITYNAME_AR": "المويكر",
    "CITYNAME_EN": "Almawikir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2255",
    "CITYNAME_AR": "الحامريه",
    "CITYNAME_EN": "Alhamiriuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2256",
    "CITYNAME_AR": "قليب ذياب ",
    "CITYNAME_EN": "Alqalib Dhiab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2257",
    "CITYNAME_AR": "الشبيكه",
    "CITYNAME_EN": "Alshabikuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2258",
    "CITYNAME_AR": "المبعوثه",
    "CITYNAME_EN": "Almabeuthuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2259",
    "CITYNAME_AR": "سراء",
    "CITYNAME_EN": "Sara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2260",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Aldaaruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2261",
    "CITYNAME_AR": "المكظم",
    "CITYNAME_EN": "Almukazam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2262",
    "CITYNAME_AR": "بدائع العش",
    "CITYNAME_EN": "Bdayie Aleash",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2263",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Aljafr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2264",
    "CITYNAME_AR": "وسيطا الحفن",
    "CITYNAME_EN": "Wasayta Alhafn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2265",
    "CITYNAME_AR": "هجره القعقاع",
    "CITYNAME_EN": "Hajaruh Alqaeqae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2266",
    "CITYNAME_AR": "العوشزيه",
    "CITYNAME_EN": "Aleushaziuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2267",
    "CITYNAME_AR": "ارينبه",
    "CITYNAME_EN": "Arynabah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2268",
    "CITYNAME_AR": "الحفن",
    "CITYNAME_EN": "Alhafn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "2269",
    "CITYNAME_AR": "شواء",
    "CITYNAME_EN": "Shawaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2270",
    "CITYNAME_AR": "المصه",
    "CITYNAME_EN": "Al Masah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2271",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Aj-Jowar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2272",
    "CITYNAME_AR": "الغول",
    "CITYNAME_EN": "Al Ghoul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2273",
    "CITYNAME_AR": "الربوعه",
    "CITYNAME_EN": "Al Rabua'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2274",
    "CITYNAME_AR": "سهوه",
    "CITYNAME_EN": "Sahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2275",
    "CITYNAME_AR": "الجور",
    "CITYNAME_EN": "Al Jur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2276",
    "CITYNAME_AR": "الأعوج",
    "CITYNAME_EN": "Al A'waj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2277",
    "CITYNAME_AR": "بادية الربوعه",
    "CITYNAME_EN": "Badiyah Al Rabu'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2278",
    "CITYNAME_AR": "الأقواع",
    "CITYNAME_EN": "Al Aqwa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2279",
    "CITYNAME_AR": "الفقه",
    "CITYNAME_EN": "Al Fiqh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2280",
    "CITYNAME_AR": "الناقز",
    "CITYNAME_EN": "Al Nazqiz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2281",
    "CITYNAME_AR": "الحر",
    "CITYNAME_EN": "Al Hurr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2282",
    "CITYNAME_AR": "القنان",
    "CITYNAME_EN": "Al-Qinan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2283",
    "CITYNAME_AR": "الخارمه",
    "CITYNAME_EN": "Al-Kharmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2284",
    "CITYNAME_AR": "كليب",
    "CITYNAME_EN": "Kolayb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2285",
    "CITYNAME_AR": "الشطيبه",
    "CITYNAME_EN": "Al-Shabitah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2286",
    "CITYNAME_AR": "سعنب",
    "CITYNAME_EN": "Sa'nab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2287",
    "CITYNAME_AR": "الثويهر",
    "CITYNAME_EN": "Al-Thuwiher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2288",
    "CITYNAME_AR": "الركع",
    "CITYNAME_EN": "Al-Rak' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2289",
    "CITYNAME_AR": "الشقيده",
    "CITYNAME_EN": "Al-Shaqidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2290",
    "CITYNAME_AR": "الحبيس",
    "CITYNAME_EN": "Al-Habis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2291",
    "CITYNAME_AR": "الربوعه",
    "CITYNAME_EN": "Al-Rabua'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2292",
    "CITYNAME_AR": "العش",
    "CITYNAME_EN": "Al-Ush",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2293",
    "CITYNAME_AR": "الجماء",
    "CITYNAME_EN": "Al-Jmaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2294",
    "CITYNAME_AR": "معراض",
    "CITYNAME_EN": "Mi'rad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2295",
    "CITYNAME_AR": "قريه تشوية",
    "CITYNAME_EN": "Qaryet Tashwyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2296",
    "CITYNAME_AR": "زرمعه",
    "CITYNAME_EN": "Zarmaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2297",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Usum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2298",
    "CITYNAME_AR": "روحان",
    "CITYNAME_EN": "Rawhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2299",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2300",
    "CITYNAME_AR": "محروس",
    "CITYNAME_EN": "Mahroos",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2301",
    "CITYNAME_AR": "الميدعه",
    "CITYNAME_EN": "Al Maydaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2302",
    "CITYNAME_AR": "العراب",
    "CITYNAME_EN": "Al Araab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2303",
    "CITYNAME_AR": "الملصه",
    "CITYNAME_EN": "Al Malsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2304",
    "CITYNAME_AR": "المعصوم",
    "CITYNAME_EN": "Al Masoom",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2305",
    "CITYNAME_AR": "النورين",
    "CITYNAME_EN": "An-Nurayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2306",
    "CITYNAME_AR": "معشاش",
    "CITYNAME_EN": "Meashash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2307",
    "CITYNAME_AR": "الوغره",
    "CITYNAME_EN": "Al Waghrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2308",
    "CITYNAME_AR": "المهيع",
    "CITYNAME_EN": "Al Muhayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2309",
    "CITYNAME_AR": "ذمران",
    "CITYNAME_EN": "Zamran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2310",
    "CITYNAME_AR": "الشرياني",
    "CITYNAME_EN": "Ash-Shuryani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2311",
    "CITYNAME_AR": "شوحط",
    "CITYNAME_EN": "Shawhat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2312",
    "CITYNAME_AR": "الثعبان",
    "CITYNAME_EN": "Ath-Thuaban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2313",
    "CITYNAME_AR": "جمران",
    "CITYNAME_EN": "Jamran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2314",
    "CITYNAME_AR": "الوجاء",
    "CITYNAME_EN": "Al Wajaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2315",
    "CITYNAME_AR": "ضميان",
    "CITYNAME_EN": "Damyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2316",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2317",
    "CITYNAME_AR": "الأنياد",
    "CITYNAME_EN": "Al-Aniad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2318",
    "CITYNAME_AR": "محذآ",
    "CITYNAME_EN": "Mihza ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2319",
    "CITYNAME_AR": "المدفاء",
    "CITYNAME_EN": "Al-Medfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2320",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2321",
    "CITYNAME_AR": "القيرثيه",
    "CITYNAME_EN": "Al-Qirthiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2322",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2323",
    "CITYNAME_AR": "وادى فرعات",
    "CITYNAME_EN": "Wadi fara'at ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2324",
    "CITYNAME_AR": "العاليه",
    "CITYNAME_EN": "Al-Alyha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2325",
    "CITYNAME_AR": "الغبره",
    "CITYNAME_EN": "Al-Ghabrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2326",
    "CITYNAME_AR": "الصويمله",
    "CITYNAME_EN": "Al-Suwaimilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2327",
    "CITYNAME_AR": "الصومله",
    "CITYNAME_EN": "Al-Sumlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2328",
    "CITYNAME_AR": "وعترض",
    "CITYNAME_EN": "Wa'tarid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2329",
    "CITYNAME_AR": "الفجم",
    "CITYNAME_EN": "Al-Fajm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2330",
    "CITYNAME_AR": "الجنه",
    "CITYNAME_EN": "Al-Jannah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2331",
    "CITYNAME_AR": "بادية (فرعات)",
    "CITYNAME_EN": "Badiyah (Far'at) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2332",
    "CITYNAME_AR": "جبل روق",
    "CITYNAME_EN": "Ruq Mountain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2333",
    "CITYNAME_AR": "قمع وقيش",
    "CITYNAME_EN": "Qam' and Qaysh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2334",
    "CITYNAME_AR": "الثبجه",
    "CITYNAME_EN": "Al-Thabjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2335",
    "CITYNAME_AR": "قوز بردان",
    "CITYNAME_EN": "Quz Berdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2336",
    "CITYNAME_AR": "المعصوم",
    "CITYNAME_EN": "Al-Ma'sum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2337",
    "CITYNAME_AR": "الثجه",
    "CITYNAME_EN": "Al-Thajah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2338",
    "CITYNAME_AR": "السهبه",
    "CITYNAME_EN": "Al-Sahbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2339",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Al-Dahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2340",
    "CITYNAME_AR": "جوالمعين",
    "CITYNAME_EN": "Jaw Al-Ma'in ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2341",
    "CITYNAME_AR": "الحتار",
    "CITYNAME_EN": "Al-Hitar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2342",
    "CITYNAME_AR": "قوز الأبيض",
    "CITYNAME_EN": "Quz Al-Abiad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2343",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Al-Rahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2344",
    "CITYNAME_AR": "الجاسر",
    "CITYNAME_EN": "Al-Jaser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2345",
    "CITYNAME_AR": "الصده",
    "CITYNAME_EN": "Al-Sadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2346",
    "CITYNAME_AR": "ماجان",
    "CITYNAME_EN": "Magan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2347",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al-Baq'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2348",
    "CITYNAME_AR": "العفاره",
    "CITYNAME_EN": "Al-Afarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2349",
    "CITYNAME_AR": "البطحاء",
    "CITYNAME_EN": "Al Bathaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2350",
    "CITYNAME_AR": "الغافله",
    "CITYNAME_EN": "Al-Ghaflah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2351",
    "CITYNAME_AR": "القمراء",
    "CITYNAME_EN": "Al-Qamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2352",
    "CITYNAME_AR": "العوارض",
    "CITYNAME_EN": "Al-Awarid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2353",
    "CITYNAME_AR": "البدعه",
    "CITYNAME_EN": "Al-Bad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2354",
    "CITYNAME_AR": "وادي الجو",
    "CITYNAME_EN": "Wadi Al-Jaw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2355",
    "CITYNAME_AR": "اللج",
    "CITYNAME_EN": "Allaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2356",
    "CITYNAME_AR": "العراب",
    "CITYNAME_EN": "Al-Arrab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2357",
    "CITYNAME_AR": "النوب",
    "CITYNAME_EN": "Al-Nawb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2358",
    "CITYNAME_AR": "القتادة",
    "CITYNAME_EN": "Al-Qitadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2359",
    "CITYNAME_AR": "الريه",
    "CITYNAME_EN": "Al-Rubah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2360",
    "CITYNAME_AR": "الظلامه",
    "CITYNAME_EN": "Al-Zalamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2361",
    "CITYNAME_AR": "المعش",
    "CITYNAME_EN": "Al-Ma'sh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2362",
    "CITYNAME_AR": "البسيط",
    "CITYNAME_EN": "Al-Basit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2363",
    "CITYNAME_AR": "العارض",
    "CITYNAME_EN": "Al-Arid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2364",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2365",
    "CITYNAME_AR": "القفله",
    "CITYNAME_EN": "Al-Qaflah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2366",
    "CITYNAME_AR": "الشطين",
    "CITYNAME_EN": "Al-Shatin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2367",
    "CITYNAME_AR": "قوز النجيف",
    "CITYNAME_EN": "Quz Al-Najif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2368",
    "CITYNAME_AR": "المسنه",
    "CITYNAME_EN": "Al-Masnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2369",
    "CITYNAME_AR": "القظمه",
    "CITYNAME_EN": "AlQazmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2370",
    "CITYNAME_AR": "جعار",
    "CITYNAME_EN": "Je'ar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2371",
    "CITYNAME_AR": "مخدومه",
    "CITYNAME_EN": "Makhdumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2372",
    "CITYNAME_AR": "نخران",
    "CITYNAME_EN": "Nakhran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2373",
    "CITYNAME_AR": "ذياب",
    "CITYNAME_EN": "Zeyab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2374",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Al-Qaryah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2375",
    "CITYNAME_AR": "الشريجه",
    "CITYNAME_EN": "Al-Sharijah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2376",
    "CITYNAME_AR": "الشجاج",
    "CITYNAME_EN": "Al-Shejaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2377",
    "CITYNAME_AR": "مثايب",
    "CITYNAME_EN": "Mathayib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2378",
    "CITYNAME_AR": "جعرانات",
    "CITYNAME_EN": "Jo'ranat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2379",
    "CITYNAME_AR": "السوار",
    "CITYNAME_EN": "Al-Sewar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2380",
    "CITYNAME_AR": "الخطامه",
    "CITYNAME_EN": "Al Khutamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2381",
    "CITYNAME_AR": "الخضير",
    "CITYNAME_EN": "Al-Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2382",
    "CITYNAME_AR": "الشرجه",
    "CITYNAME_EN": "Al-Sharjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2383",
    "CITYNAME_AR": "المعرض",
    "CITYNAME_EN": "Al-Mehrid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2384",
    "CITYNAME_AR": "الظبيه",
    "CITYNAME_EN": "Al Dhabyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2385",
    "CITYNAME_AR": "حباله",
    "CITYNAME_EN": "Habalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2386",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2387",
    "CITYNAME_AR": "المطراق",
    "CITYNAME_EN": "Al-Metraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2388",
    "CITYNAME_AR": "عزالنيد",
    "CITYNAME_EN": "Azalnid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2389",
    "CITYNAME_AR": "المديره",
    "CITYNAME_EN": "Al-Modirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2390",
    "CITYNAME_AR": "متاوم",
    "CITYNAME_EN": "Matawim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2391",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2392",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2393",
    "CITYNAME_AR": "العمشه",
    "CITYNAME_EN": "Al-Ashmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2394",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghwan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2395",
    "CITYNAME_AR": "حذا",
    "CITYNAME_EN": "Haza ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2396",
    "CITYNAME_AR": "ذساد",
    "CITYNAME_EN": "Zasad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2397",
    "CITYNAME_AR": "العكابره",
    "CITYNAME_EN": "Al-Akabirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2398",
    "CITYNAME_AR": "الصداير",
    "CITYNAME_EN": "Al-Sadayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2399",
    "CITYNAME_AR": "الفريق",
    "CITYNAME_EN": "Al-Fariq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2400",
    "CITYNAME_AR": "جناب شكر",
    "CITYNAME_EN": "Janab Shokr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "2401",
    "CITYNAME_AR": "البشائر",
    "CITYNAME_EN": "Al Bashayer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2402",
    "CITYNAME_AR": "الشجنه",
    "CITYNAME_EN": "Al-Shejnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2403",
    "CITYNAME_AR": "حى الشعب (منتزه ال قادم)",
    "CITYNAME_EN": "Al-Sha'b District ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2404",
    "CITYNAME_AR": "ال عظيه",
    "CITYNAME_EN": "Al Aziyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2405",
    "CITYNAME_AR": "ال عاصم",
    "CITYNAME_EN": "Al Asem",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2406",
    "CITYNAME_AR": "ال قادم",
    "CITYNAME_EN": "Al Qadim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2407",
    "CITYNAME_AR": "ال رحمه",
    "CITYNAME_EN": "Al Rahmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2408",
    "CITYNAME_AR": "ال تواب (ال الرحمه)",
    "CITYNAME_EN": "Al Tawab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2409",
    "CITYNAME_AR": "ال سعدان (عليان)",
    "CITYNAME_EN": "Al Saadan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2410",
    "CITYNAME_AR": "الحنشه",
    "CITYNAME_EN": "Al-Hanshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2411",
    "CITYNAME_AR": "الحصير",
    "CITYNAME_EN": "Al-Hasir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2412",
    "CITYNAME_AR": "ال الصائد",
    "CITYNAME_EN": "Al Al-Saed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2413",
    "CITYNAME_AR": "شطيبه",
    "CITYNAME_EN": "Shatibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2414",
    "CITYNAME_AR": "ال صفيه",
    "CITYNAME_EN": "Al Safiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2415",
    "CITYNAME_AR": "باديه الحلفات (الفوهه)",
    "CITYNAME_EN": "Badiat Al-Halfat (Al-Fuhah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2416",
    "CITYNAME_AR": "حى الشعبه",
    "CITYNAME_EN": "Al-Shi'bah District ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2417",
    "CITYNAME_AR": "رافعه",
    "CITYNAME_EN": "Raf'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2418",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Aqrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2419",
    "CITYNAME_AR": "المعاصره",
    "CITYNAME_EN": "Al-Mu'asirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2420",
    "CITYNAME_AR": "ال جراده",
    "CITYNAME_EN": "Al Jaradah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2421",
    "CITYNAME_AR": "النجاجير",
    "CITYNAME_EN": "Al-Najajeer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2422",
    "CITYNAME_AR": "ال غريبه",
    "CITYNAME_EN": "Al Gharibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2423",
    "CITYNAME_AR": "ظهابه",
    "CITYNAME_EN": "Zahabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2424",
    "CITYNAME_AR": "ال بقيله",
    "CITYNAME_EN": "Al Baqilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2425",
    "CITYNAME_AR": "الدف",
    "CITYNAME_EN": "Al-Dof",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2426",
    "CITYNAME_AR": "ال حبيل (عليان)",
    "CITYNAME_EN": "Al Habil (Alyan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2427",
    "CITYNAME_AR": "ال ملحه",
    "CITYNAME_EN": "Al Melhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2428",
    "CITYNAME_AR": "قرن عجبه",
    "CITYNAME_EN": "Qar Ajibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2429",
    "CITYNAME_AR": "الذنوب (المنتزه)",
    "CITYNAME_EN": "Al-Zanub (Al-Montazah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2430",
    "CITYNAME_AR": "عليانه",
    "CITYNAME_EN": "Alyanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2431",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2432",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2433",
    "CITYNAME_AR": "حصن عليان",
    "CITYNAME_EN": "Alyan Fortress ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2434",
    "CITYNAME_AR": "الدحو",
    "CITYNAME_EN": "Al Dahw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2435",
    "CITYNAME_AR": "بريم",
    "CITYNAME_EN": "Berim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2436",
    "CITYNAME_AR": "الغرسه",
    "CITYNAME_EN": "Al-Gharsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2437",
    "CITYNAME_AR": "شافعه",
    "CITYNAME_EN": "Shaf'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2438",
    "CITYNAME_AR": "العجلان",
    "CITYNAME_EN": "Al-Ajlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2439",
    "CITYNAME_AR": "المكابس",
    "CITYNAME_EN": "Al Makabes ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2440",
    "CITYNAME_AR": "ال مسلم",
    "CITYNAME_EN": "Al Muslim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2441",
    "CITYNAME_AR": "العون الله",
    "CITYNAME_EN": "Al-Awn Lillah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2442",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Asm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2443",
    "CITYNAME_AR": "الهمله",
    "CITYNAME_EN": "Al Hamlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2444",
    "CITYNAME_AR": "ال صالح",
    "CITYNAME_EN": " Al Saleh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2445",
    "CITYNAME_AR": "المبنى",
    "CITYNAME_EN": "Building",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2446",
    "CITYNAME_AR": "ال شعبان",
    "CITYNAME_EN": "Al Sha'ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2447",
    "CITYNAME_AR": "الجوماء",
    "CITYNAME_EN": "Al-Jawmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2448",
    "CITYNAME_AR": "ال معيض",
    "CITYNAME_EN": "Al Ma'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2449",
    "CITYNAME_AR": "الاطرقه",
    "CITYNAME_EN": "Al Atraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2450",
    "CITYNAME_AR": "ملاله",
    "CITYNAME_EN": "Malalh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2451",
    "CITYNAME_AR": "زمره",
    "CITYNAME_EN": "Zumrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2452",
    "CITYNAME_AR": "قريه",
    "CITYNAME_EN": "Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2453",
    "CITYNAME_AR": "ال سعدان خثعم",
    "CITYNAME_EN": "Al Saadan Khathm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2454",
    "CITYNAME_AR": "الاحمر",
    "CITYNAME_EN": "Al-Ahmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2455",
    "CITYNAME_AR": "دار الحصن",
    "CITYNAME_EN": "Dar Al-Hisn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2456",
    "CITYNAME_AR": "ال عرعره",
    "CITYNAME_EN": "Al Ararah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2457",
    "CITYNAME_AR": "ال شهوان",
    "CITYNAME_EN": "Al Shahwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2458",
    "CITYNAME_AR": "الرياحين",
    "CITYNAME_EN": "Al-Rayahin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2459",
    "CITYNAME_AR": "الجهوم الرهطيه",
    "CITYNAME_EN": "Al-Jahum Al-Rahitah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2460",
    "CITYNAME_AR": "ال سكن",
    "CITYNAME_EN": "Al Sakan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2461",
    "CITYNAME_AR": "السد (السر)",
    "CITYNAME_EN": "Al-Sed (Al-Ser)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2462",
    "CITYNAME_AR": "الشعبان",
    "CITYNAME_EN": "Al-Sha'ban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2463",
    "CITYNAME_AR": "ميي",
    "CITYNAME_EN": "Mayi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2464",
    "CITYNAME_AR": "الوهاد",
    "CITYNAME_EN": "Al-Wehad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2465",
    "CITYNAME_AR": "جهوم البلس",
    "CITYNAME_EN": "Jahum Al-Bels ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2466",
    "CITYNAME_AR": "الندبه",
    "CITYNAME_EN": "Al-Nadbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2467",
    "CITYNAME_AR": "بشامه الحمدات",
    "CITYNAME_EN": "Bashamah Al-Hamdat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2468",
    "CITYNAME_AR": "الجعده",
    "CITYNAME_EN": "Al-Jad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2469",
    "CITYNAME_AR": "الحسن (ال حسن)",
    "CITYNAME_EN": " Al Hassan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2470",
    "CITYNAME_AR": "الفوقاء (الدار)",
    "CITYNAME_EN": "Al-Fawqaa (Al-Dar)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2471",
    "CITYNAME_AR": "مخطط ال زايده",
    "CITYNAME_EN": "Mukhatat Al Zeyadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2472",
    "CITYNAME_AR": "بادية حليل",
    "CITYNAME_EN": "Badia Halil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2473",
    "CITYNAME_AR": "شعب الجعده",
    "CITYNAME_EN": "Shi'b Al-Jad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2474",
    "CITYNAME_AR": "شعيب الحمدات",
    "CITYNAME_EN": "Sho'ayb Al-Hamdat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2475",
    "CITYNAME_AR": "الودفه",
    "CITYNAME_EN": "Al Wafda ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2476",
    "CITYNAME_AR": "وادي الصدر",
    "CITYNAME_EN": "Wadi As Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2477",
    "CITYNAME_AR": "مركز شواص",
    "CITYNAME_EN": "Shewas Center ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2478",
    "CITYNAME_AR": "الغوازى",
    "CITYNAME_EN": "Al-Ghawazy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2479",
    "CITYNAME_AR": "قرن ظبي",
    "CITYNAME_EN": "Qarn Zaby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2480",
    "CITYNAME_AR": "الظبيه",
    "CITYNAME_EN": "Al Dhabyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2481",
    "CITYNAME_AR": "الحجف",
    "CITYNAME_EN": "Al-Hajhf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2482",
    "CITYNAME_AR": "الصره العلياوالسفلى",
    "CITYNAME_EN": "Al-Surrah (The upper and the Lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2483",
    "CITYNAME_AR": "الحميطه",
    "CITYNAME_EN": "Al-Hamitah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2484",
    "CITYNAME_AR": "العثوان(قرى راشد)",
    "CITYNAME_EN": "Al-Athwan (Rashed Village)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2485",
    "CITYNAME_AR": "زاعمه والسند",
    "CITYNAME_EN": "Za'mah and Al-Sanad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2486",
    "CITYNAME_AR": "القصباء العليا والسفلى",
    "CITYNAME_EN": "Al-Qasbaa (the upper and the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2487",
    "CITYNAME_AR": "الحفيه والسويسيه",
    "CITYNAME_EN": "Al-Hefyah and Al-Suwaysiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2488",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2489",
    "CITYNAME_AR": "النجفه",
    "CITYNAME_EN": "Al-Najfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2490",
    "CITYNAME_AR": "انبغاء",
    "CITYNAME_EN": "Anbighaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2491",
    "CITYNAME_AR": "شعبه الرحى(ودفرالهندي والجاني)",
    "CITYNAME_EN": "Shi'bah Al-Raha (Wadfer Al-Hindy and Al-Jany)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2492",
    "CITYNAME_AR": "شرك(سلام)",
    "CITYNAME_EN": "Sherk (Salam)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2493",
    "CITYNAME_AR": "مرقص(والعبب)",
    "CITYNAME_EN": "Marqas (And Al-Eib)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2494",
    "CITYNAME_AR": "البهمه",
    "CITYNAME_EN": "Al Bahamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "2495",
    "CITYNAME_AR": "سبطن (حويه)",
    "CITYNAME_EN": "Sabtan (Hawbah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "2496",
    "CITYNAME_AR": "البريقاء",
    "CITYNAME_EN": "Al Burayqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "2497",
    "CITYNAME_AR": "الاحفاء",
    "CITYNAME_EN": "Al Ahfaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "2498",
    "CITYNAME_AR": "صرخه",
    "CITYNAME_EN": "Sarkhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "2499",
    "CITYNAME_AR": "النماص",
    "CITYNAME_EN": "Al-Namas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2500",
    "CITYNAME_AR": "الدحض",
    "CITYNAME_EN": "Al-Dahid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2501",
    "CITYNAME_AR": "صروم الاسفل",
    "CITYNAME_EN": "The lower Sarum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2502",
    "CITYNAME_AR": "حله القفاز",
    "CITYNAME_EN": "Halah Al-Qefaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2503",
    "CITYNAME_AR": "حله العامشه",
    "CITYNAME_EN": "Halah Al-Amshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2504",
    "CITYNAME_AR": "صروم الاعلى",
    "CITYNAME_EN": "The upper Sarum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2505",
    "CITYNAME_AR": "الحلقه",
    "CITYNAME_EN": "Al-Halqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2506",
    "CITYNAME_AR": "القيم",
    "CITYNAME_EN": "Al-Qayim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2507",
    "CITYNAME_AR": "ال غواله",
    "CITYNAME_EN": "Al Ghawalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2508",
    "CITYNAME_AR": "المليصه",
    "CITYNAME_EN": "Al-Malisah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2509",
    "CITYNAME_AR": "ال غيهب",
    "CITYNAME_EN": "Al Ghayhab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2510",
    "CITYNAME_AR": "ال قفعه",
    "CITYNAME_EN": "Al Qaf'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2511",
    "CITYNAME_AR": "الريامه",
    "CITYNAME_EN": "Al-Reyamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2512",
    "CITYNAME_AR": "ال ساعد",
    "CITYNAME_EN": "Al Sa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2513",
    "CITYNAME_AR": "ال هلاله",
    "CITYNAME_EN": "Al Helalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2514",
    "CITYNAME_AR": "شعب سويد",
    "CITYNAME_EN": "Shi'b Suwayd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2515",
    "CITYNAME_AR": "ال طارق وال ذا المضر",
    "CITYNAME_EN": "Al Tarek and Al Za Al-Modar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2516",
    "CITYNAME_AR": "ال مستور",
    "CITYNAME_EN": "Al Mastour ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2517",
    "CITYNAME_AR": "المدانه",
    "CITYNAME_EN": "Al-Medanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2518",
    "CITYNAME_AR": "صقره",
    "CITYNAME_EN": "Saqrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2519",
    "CITYNAME_AR": "عازب",
    "CITYNAME_EN": "Azib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2520",
    "CITYNAME_AR": "العرق (خميس العرق)",
    "CITYNAME_EN": "Al-Erq (Khamis Al-Erq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2521",
    "CITYNAME_AR": "ال ناشر",
    "CITYNAME_EN": "Al Nasher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2522",
    "CITYNAME_AR": "ال رحمه",
    "CITYNAME_EN": "Al Rahmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2523",
    "CITYNAME_AR": "خشرم",
    "CITYNAME_EN": "Khashram ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2524",
    "CITYNAME_AR": "خازم",
    "CITYNAME_EN": "Khazim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2525",
    "CITYNAME_AR": "ال طوير",
    "CITYNAME_EN": "Al Tuwer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2526",
    "CITYNAME_AR": "المرحب",
    "CITYNAME_EN": "Al-Marhab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2527",
    "CITYNAME_AR": "الغفار",
    "CITYNAME_EN": "Al-Ghafar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2528",
    "CITYNAME_AR": "القرن والشرف",
    "CITYNAME_EN": "Al-Qarn and Al-Sharaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2529",
    "CITYNAME_AR": "ال غران",
    "CITYNAME_EN": "Al Gharan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2530",
    "CITYNAME_AR": "بنى مليح (الذنوب)",
    "CITYNAME_EN": "Bani Malih (Al-Zanub)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2531",
    "CITYNAME_AR": "ال حبشى (شبرقه)",
    "CITYNAME_EN": "Al Habashi (Shabraqah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2532",
    "CITYNAME_AR": "الافراع",
    "CITYNAME_EN": "Al-Afra' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2533",
    "CITYNAME_AR": "ال قحطان",
    "CITYNAME_EN": "Al Qahtan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2534",
    "CITYNAME_AR": "لحبى",
    "CITYNAME_EN": "Lahba ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2535",
    "CITYNAME_AR": "ال السلطان",
    "CITYNAME_EN": "Al Al-Sultan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2536",
    "CITYNAME_AR": "ال حشيش",
    "CITYNAME_EN": "Al-Hashish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2537",
    "CITYNAME_AR": "ال عظيه",
    "CITYNAME_EN": "Al Aziyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2538",
    "CITYNAME_AR": "فرعه الكلاثمه",
    "CITYNAME_EN": "Far'ah Al-Klathemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2539",
    "CITYNAME_AR": "الهبيطه (عبيله)",
    "CITYNAME_EN": "Al-Habitah (Abilah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2540",
    "CITYNAME_AR": "الفرعه الشماليه",
    "CITYNAME_EN": "Al-Fr'ah Al-Shamaliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2541",
    "CITYNAME_AR": "الحصون",
    "CITYNAME_EN": "Al-Hasoun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2542",
    "CITYNAME_AR": "مروان",
    "CITYNAME_EN": "Marwan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2543",
    "CITYNAME_AR": "السويدا",
    "CITYNAME_EN": "Al-Swida ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2544",
    "CITYNAME_AR": "شعبان",
    "CITYNAME_EN": "Sha'ban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2545",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2546",
    "CITYNAME_AR": "الدائنه",
    "CITYNAME_EN": "Al-Daenah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2547",
    "CITYNAME_AR": "مسلمه",
    "CITYNAME_EN": "Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2548",
    "CITYNAME_AR": "قواني غرفه",
    "CITYNAME_EN": "Qawany Gharfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2549",
    "CITYNAME_AR": "ال رحال",
    "CITYNAME_EN": "Al Rehal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2550",
    "CITYNAME_AR": "ال ابو قبيس",
    "CITYNAME_EN": "Al Abu Qabis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2551",
    "CITYNAME_AR": "ال غفيف",
    "CITYNAME_EN": "Al Ghafif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2552",
    "CITYNAME_AR": "بدوه",
    "CITYNAME_EN": "Badwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2553",
    "CITYNAME_AR": "اسفل النقب",
    "CITYNAME_EN": "Asfal Al-Naqb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2554",
    "CITYNAME_AR": "حضن الأصفة",
    "CITYNAME_EN": "Hodn Al-Asfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2555",
    "CITYNAME_AR": "حضن المرتفعه",
    "CITYNAME_EN": "Hodn Al-Mortafi'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2556",
    "CITYNAME_AR": "ابو قرنين",
    "CITYNAME_EN": "Abu Qarnin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2557",
    "CITYNAME_AR": "القنيب",
    "CITYNAME_EN": "Al-Qanib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2558",
    "CITYNAME_AR": "ابو شوك",
    "CITYNAME_EN": "Abu Shouk ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2559",
    "CITYNAME_AR": "حضن المقعد",
    "CITYNAME_EN": "Hodn Al-Maq'ad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2560",
    "CITYNAME_AR": "شعب ال يعلى",
    "CITYNAME_EN": "Shi'b Al Ya'ly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2561",
    "CITYNAME_AR": "الحفاه",
    "CITYNAME_EN": "Al-Hufah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2562",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Al-Farsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2563",
    "CITYNAME_AR": "اليوز",
    "CITYNAME_EN": "Al-Yuz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2564",
    "CITYNAME_AR": "ظاهر خميس",
    "CITYNAME_EN": "Zaher Khamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2565",
    "CITYNAME_AR": "الربطان",
    "CITYNAME_EN": "Al-Ritan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2566",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al-Jaz'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2567",
    "CITYNAME_AR": "شعبه البطحاء",
    "CITYNAME_EN": "Shi'bah Al-Abthaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2568",
    "CITYNAME_AR": "اللباه",
    "CITYNAME_EN": "Allabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2569",
    "CITYNAME_AR": "الليمه",
    "CITYNAME_EN": "Allimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2570",
    "CITYNAME_AR": "النمصه",
    "CITYNAME_EN": "Al-Namsah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2571",
    "CITYNAME_AR": "وادى نحيان",
    "CITYNAME_EN": "Wadi Nihyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2572",
    "CITYNAME_AR": "نقب خضراء",
    "CITYNAME_EN": "Naqb Khadraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2573",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Al-Qaryah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2574",
    "CITYNAME_AR": "جندله (الحائط)",
    "CITYNAME_EN": "Jandalah (Al-Haet)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2575",
    "CITYNAME_AR": "ال زهراء",
    "CITYNAME_EN": "Al-Zahraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2576",
    "CITYNAME_AR": "ال صيافه وال قبان",
    "CITYNAME_EN": "Al-Sayafah and Al Qiban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2577",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Al-Rakbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2578",
    "CITYNAME_AR": "فاقع",
    "CITYNAME_EN": "Faqi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2579",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2580",
    "CITYNAME_AR": "شريان",
    "CITYNAME_EN": "Sheryan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2581",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2582",
    "CITYNAME_AR": "جبل صعيب",
    "CITYNAME_EN": "Su'ayb Mountain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2583",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2584",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Al-Einah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2585",
    "CITYNAME_AR": "صدر ال رقاد وال مرزوق",
    "CITYNAME_EN": "Sadr Al Riqad and Al Marzuq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2586",
    "CITYNAME_AR": "ال مرزوق",
    "CITYNAME_EN": "Al Marzouq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2587",
    "CITYNAME_AR": "سحبان",
    "CITYNAME_EN": "Sahban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2588",
    "CITYNAME_AR": "مخرقه",
    "CITYNAME_EN": "Makhraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2589",
    "CITYNAME_AR": "حضن البر",
    "CITYNAME_EN": "Hodn Al-Ber ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2590",
    "CITYNAME_AR": "الانصب",
    "CITYNAME_EN": "Al-Ansob ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2591",
    "CITYNAME_AR": "الشذنه والشعبه",
    "CITYNAME_EN": "Al-Shaznah and Al-Sha'bah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2592",
    "CITYNAME_AR": "المحلب",
    "CITYNAME_EN": "Al-Mehlib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2593",
    "CITYNAME_AR": "الجراعه",
    "CITYNAME_EN": "Al-Jara'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2594",
    "CITYNAME_AR": "مقنا",
    "CITYNAME_EN": "Magna",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2595",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al-Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2596",
    "CITYNAME_AR": "حضر(خضر)",
    "CITYNAME_EN": "Hedr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2597",
    "CITYNAME_AR": "ضواء",
    "CITYNAME_EN": "Dawaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2598",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2599",
    "CITYNAME_AR": "البيريه",
    "CITYNAME_EN": "Al-Biriyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2600",
    "CITYNAME_AR": "القزعه والنقبه",
    "CITYNAME_EN": "Al-Qaz'ah and Al-Nqbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2601",
    "CITYNAME_AR": "الرهوين وبدره",
    "CITYNAME_EN": "Al-Rahwin and Badrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2602",
    "CITYNAME_AR": "الذنوب",
    "CITYNAME_EN": "Al-Zanub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2603",
    "CITYNAME_AR": "شعب حماط",
    "CITYNAME_EN": "Shi'b Hemat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2604",
    "CITYNAME_AR": "ابرق نعام",
    "CITYNAME_EN": "Ibriq NaUmm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2605",
    "CITYNAME_AR": "يهره",
    "CITYNAME_EN": "Bahrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2606",
    "CITYNAME_AR": "وادى يهره",
    "CITYNAME_EN": "Wadi Yahrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2607",
    "CITYNAME_AR": "تثليث",
    "CITYNAME_EN": "Tathlith",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2608",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Al-Harf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2609",
    "CITYNAME_AR": "العضاض الاعلى",
    "CITYNAME_EN": "Al-Adad Al-A'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2610",
    "CITYNAME_AR": "العضاض الاسفل",
    "CITYNAME_EN": "Al-Adad Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2611",
    "CITYNAME_AR": "آل قبره والقوزيه",
    "CITYNAME_EN": "Al-Qubrah and Al-Quziyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2612",
    "CITYNAME_AR": "ام نشيفه",
    "CITYNAME_EN": "Umm nashifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2613",
    "CITYNAME_AR": "الاسامله",
    "CITYNAME_EN": "Al-Asamilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2614",
    "CITYNAME_AR": "الروغ (الفاضل)",
    "CITYNAME_EN": "Al-Rawgh (Al-Fadil)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2615",
    "CITYNAME_AR": "الخميسه",
    "CITYNAME_EN": "Al-Khamisah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2616",
    "CITYNAME_AR": "ال ريحان",
    "CITYNAME_EN": "Al Rihan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2617",
    "CITYNAME_AR": "البديعه",
    "CITYNAME_EN": "Al Badi'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2618",
    "CITYNAME_AR": "ام غضراء",
    "CITYNAME_EN": "Umm Ghadraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2619",
    "CITYNAME_AR": "ربه",
    "CITYNAME_EN": "Riyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2620",
    "CITYNAME_AR": "ختلان",
    "CITYNAME_EN": "Khatlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2621",
    "CITYNAME_AR": "لاعس",
    "CITYNAME_EN": "Lai's ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2622",
    "CITYNAME_AR": "البجاده",
    "CITYNAME_EN": "Al Bajadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2623",
    "CITYNAME_AR": "النطير",
    "CITYNAME_EN": "Al-Natir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2624",
    "CITYNAME_AR": "ابجاد",
    "CITYNAME_EN": "Abjad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2625",
    "CITYNAME_AR": "اسفل  ثرار",
    "CITYNAME_EN": "Asfal Therar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2626",
    "CITYNAME_AR": "الوغله",
    "CITYNAME_EN": "Al Waghlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2627",
    "CITYNAME_AR": "الراكه",
    "CITYNAME_EN": "Al-Rakah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2628",
    "CITYNAME_AR": "حي المنصوره",
    "CITYNAME_EN": "Hay Al Mansourah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2629",
    "CITYNAME_AR": "فحيثه",
    "CITYNAME_EN": "Fahitha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2630",
    "CITYNAME_AR": "روغ حضنان",
    "CITYNAME_EN": "Rugh Hednan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2631",
    "CITYNAME_AR": "العنبرية",
    "CITYNAME_EN": "Al-Anbariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2632",
    "CITYNAME_AR": "الروضة",
    "CITYNAME_EN": "Al-Rawdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2633",
    "CITYNAME_AR": "الغرايس",
    "CITYNAME_EN": "Al-Gharayis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2634",
    "CITYNAME_AR": "ال سويدان",
    "CITYNAME_EN": "Al Swedan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2635",
    "CITYNAME_AR": "ال مصبح",
    "CITYNAME_EN": "Al Misbih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2636",
    "CITYNAME_AR": "ال عيفان",
    "CITYNAME_EN": "Al Eifan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2637",
    "CITYNAME_AR": "ال مخثله(ال محثله)",
    "CITYNAME_EN": "Al Makhthalah (Al Mahthalah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2638",
    "CITYNAME_AR": "ال شبوه",
    "CITYNAME_EN": " Al Shebwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2639",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2640",
    "CITYNAME_AR": "المستوية",
    "CITYNAME_EN": "Al-Mustawiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2641",
    "CITYNAME_AR": "العبيله",
    "CITYNAME_EN": "Al-Abilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2642",
    "CITYNAME_AR": "القيره",
    "CITYNAME_EN": "Al Qirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2643",
    "CITYNAME_AR": "ابار القيره",
    "CITYNAME_EN": "Abar Al-Qirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2644",
    "CITYNAME_AR": "جرير",
    "CITYNAME_EN": "Jarir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2645",
    "CITYNAME_AR": "حبيه",
    "CITYNAME_EN": "Habiya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2646",
    "CITYNAME_AR": "عقلان (كحلان)",
    "CITYNAME_EN": "Aqlan (Kahlan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2647",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghwan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2648",
    "CITYNAME_AR": "المبيت",
    "CITYNAME_EN": "Al-Mabit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2649",
    "CITYNAME_AR": "دبساء",
    "CITYNAME_EN": "Dabsaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2650",
    "CITYNAME_AR": "قائمه ام ضباع",
    "CITYNAME_EN": "Qaemah Umm Deba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2651",
    "CITYNAME_AR": "عنيزه",
    "CITYNAME_EN": "Unaizah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2652",
    "CITYNAME_AR": "الرانه",
    "CITYNAME_EN": "Al-Ranah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2653",
    "CITYNAME_AR": "مرابخ الشبهني",
    "CITYNAME_EN": "Marabikh Al-Shebhany ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2654",
    "CITYNAME_AR": "الهدود",
    "CITYNAME_EN": "Al-hadud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2655",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2656",
    "CITYNAME_AR": "الغابه",
    "CITYNAME_EN": "Al-Ghabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2657",
    "CITYNAME_AR": "بئر قرا",
    "CITYNAME_EN": "Bir Qara",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2658",
    "CITYNAME_AR": "خلايل",
    "CITYNAME_EN": "Khlayil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2659",
    "CITYNAME_AR": "ثرته",
    "CITYNAME_EN": "Thartah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2660",
    "CITYNAME_AR": "الزرق",
    "CITYNAME_EN": "Al Zerq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2661",
    "CITYNAME_AR": "الخالديه",
    "CITYNAME_EN": "Al Khalidyyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2662",
    "CITYNAME_AR": "الرفيعه",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2663",
    "CITYNAME_AR": "مريغان",
    "CITYNAME_EN": "Mreghan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2664",
    "CITYNAME_AR": "انشيفه",
    "CITYNAME_EN": "Anshifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2665",
    "CITYNAME_AR": "نعام",
    "CITYNAME_EN": "NeiUmm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2666",
    "CITYNAME_AR": "جال النخيل",
    "CITYNAME_EN": "Jal Al-Nakhil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2667",
    "CITYNAME_AR": "ام لبان",
    "CITYNAME_EN": "Umm Leban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2668",
    "CITYNAME_AR": "باقم",
    "CITYNAME_EN": "Baqim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2669",
    "CITYNAME_AR": "قينه",
    "CITYNAME_EN": "Qinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2670",
    "CITYNAME_AR": "مخش",
    "CITYNAME_EN": "Makhsh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2671",
    "CITYNAME_AR": "الخيالة",
    "CITYNAME_EN": "Al-Khayalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2672",
    "CITYNAME_AR": "رخيمان",
    "CITYNAME_EN": "Rakhiman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2673",
    "CITYNAME_AR": "الضليف",
    "CITYNAME_EN": "Al-Dhalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2674",
    "CITYNAME_AR": "الصرارة",
    "CITYNAME_EN": "Al-Serarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2675",
    "CITYNAME_AR": "الجفال",
    "CITYNAME_EN": "Al-Jefal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2676",
    "CITYNAME_AR": "المستويه",
    "CITYNAME_EN": "Al-Mustawiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2677",
    "CITYNAME_AR": "العاصد",
    "CITYNAME_EN": "Al-Asid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2678",
    "CITYNAME_AR": "راكة آل مبارك",
    "CITYNAME_EN": "Rakah Al Mubarak ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2679",
    "CITYNAME_AR": "الحرفين",
    "CITYNAME_EN": "Al-Harfayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2680",
    "CITYNAME_AR": "مشاش",
    "CITYNAME_EN": "Mashash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2681",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Al Raudah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2682",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Al Rofaya'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2683",
    "CITYNAME_AR": "المعتلا وال جمل",
    "CITYNAME_EN": "Al-Mo'talah and Al Jamal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2684",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Al-Barqaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2685",
    "CITYNAME_AR": "السفح",
    "CITYNAME_EN": "Al-Safh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2686",
    "CITYNAME_AR": "اللشق(آل لشدق)",
    "CITYNAME_EN": "Allashq ( Al lashq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2687",
    "CITYNAME_AR": "عتود الاسفل",
    "CITYNAME_EN": "Atud Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2688",
    "CITYNAME_AR": "عتود الاعلى",
    "CITYNAME_EN": "Atud Al-A'la",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2689",
    "CITYNAME_AR": "الشهيد",
    "CITYNAME_EN": "Al-Shahid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2690",
    "CITYNAME_AR": "طيبة الأسم (كحلان)",
    "CITYNAME_EN": "Tibah Al-Ism (Kahlan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2691",
    "CITYNAME_AR": "شعب الخبزتين",
    "CITYNAME_EN": "Shi'b Al-Khabzatain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2692",
    "CITYNAME_AR": "رنه النجد بالرس",
    "CITYNAME_EN": "Ranh Al-Najd in Al-Rras ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2693",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Al-Hamdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2694",
    "CITYNAME_AR": "بيشه",
    "CITYNAME_EN": "Bisha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2695",
    "CITYNAME_AR": "المدراء",
    "CITYNAME_EN": "Managers",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2696",
    "CITYNAME_AR": "حرجة المدراء",
    "CITYNAME_EN": "harjah Al-Madraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2697",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2698",
    "CITYNAME_AR": "الرقيطاء",
    "CITYNAME_EN": "Al-Rutaytaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2699",
    "CITYNAME_AR": "بئر العبساء",
    "CITYNAME_EN": "Bir Al-Absaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2700",
    "CITYNAME_AR": "عبيلان",
    "CITYNAME_EN": "Abilan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2701",
    "CITYNAME_AR": "قرير الحيفه",
    "CITYNAME_EN": "Qarir Al-Hifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2702",
    "CITYNAME_AR": "شعب الحيفه",
    "CITYNAME_EN": "Shi'b Al-Hifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2703",
    "CITYNAME_AR": "الحيفه",
    "CITYNAME_EN": "Al-Hifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2704",
    "CITYNAME_AR": "المعلاه بالحيفه",
    "CITYNAME_EN": "Al-Mi'lah Bel Hifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2705",
    "CITYNAME_AR": "بامه",
    "CITYNAME_EN": "bama ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2706",
    "CITYNAME_AR": "بعطان",
    "CITYNAME_EN": "Ba'tan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2707",
    "CITYNAME_AR": "الجنينه",
    "CITYNAME_EN": "Al Junainah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2708",
    "CITYNAME_AR": "بالذياب",
    "CITYNAME_EN": "Al-Zyab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2709",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2710",
    "CITYNAME_AR": "القديحى",
    "CITYNAME_EN": "Al-Qadihy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2711",
    "CITYNAME_AR": "قرية جبار",
    "CITYNAME_EN": "Jabar Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2712",
    "CITYNAME_AR": "العبساء",
    "CITYNAME_EN": "Al-Absaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2713",
    "CITYNAME_AR": "ابوعمار",
    "CITYNAME_EN": "Abu Ammar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2714",
    "CITYNAME_AR": "قتران",
    "CITYNAME_EN": "Qatran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2715",
    "CITYNAME_AR": "القتره السفلى",
    "CITYNAME_EN": "Al-Qatrah (the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2716",
    "CITYNAME_AR": "القتره العليا",
    "CITYNAME_EN": "Al-Qatrah (the upper) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2717",
    "CITYNAME_AR": "الشنيف",
    "CITYNAME_EN": "Al-Shanif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2718",
    "CITYNAME_AR": "عرمان",
    "CITYNAME_EN": "Arman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2719",
    "CITYNAME_AR": "غديقان",
    "CITYNAME_EN": "Ghadiqan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2720",
    "CITYNAME_AR": "ارقبان",
    "CITYNAME_EN": "Arqeban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2721",
    "CITYNAME_AR": "الذريات",
    "CITYNAME_EN": "Al-Zuriyat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2722",
    "CITYNAME_AR": "سحام",
    "CITYNAME_EN": "Seham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2723",
    "CITYNAME_AR": "بثينه الخنادق",
    "CITYNAME_EN": "Bothaynah Al-Khanidiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2724",
    "CITYNAME_AR": "مليحان والرماده",
    "CITYNAME_EN": "Malihan and Al-Ramadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2725",
    "CITYNAME_AR": "العقيليه",
    "CITYNAME_EN": "Al-Aqiliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2726",
    "CITYNAME_AR": "ام الفروع",
    "CITYNAME_EN": "Umm Al-Foru' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2727",
    "CITYNAME_AR": "سنام",
    "CITYNAME_EN": "Sonam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2728",
    "CITYNAME_AR": "الاكتان",
    "CITYNAME_EN": "Al Aktan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2729",
    "CITYNAME_AR": "الدحو",
    "CITYNAME_EN": "Al Dahw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2730",
    "CITYNAME_AR": "الحريره",
    "CITYNAME_EN": "Al-Harirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2731",
    "CITYNAME_AR": "واعر",
    "CITYNAME_EN": "Wa'er",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2732",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2733",
    "CITYNAME_AR": "الديلمي",
    "CITYNAME_EN": "Al-Daylami ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2734",
    "CITYNAME_AR": "السقيفه",
    "CITYNAME_EN": "Al-Saqifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2735",
    "CITYNAME_AR": "العبيديه",
    "CITYNAME_EN": "Al-Obaydiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2736",
    "CITYNAME_AR": "الشميسي",
    "CITYNAME_EN": "Al-Shemisy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2737",
    "CITYNAME_AR": "الخزان",
    "CITYNAME_EN": "Al-Khazan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2738",
    "CITYNAME_AR": "الدوار",
    "CITYNAME_EN": "Al-Dwar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2739",
    "CITYNAME_AR": "الدقوق",
    "CITYNAME_EN": "Al-Daquq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2740",
    "CITYNAME_AR": "الفيصلية",
    "CITYNAME_EN": "Al-Faisaliyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2741",
    "CITYNAME_AR": "الأبرق",
    "CITYNAME_EN": "Al-Abraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2742",
    "CITYNAME_AR": "آل خزام",
    "CITYNAME_EN": "Al Khozam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2743",
    "CITYNAME_AR": "آل سمرة",
    "CITYNAME_EN": "Al Samrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2744",
    "CITYNAME_AR": "مخطط الديلمي الشرقي والغربي",
    "CITYNAME_EN": "Al-Daylamah Eastern and Western Scheme ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2745",
    "CITYNAME_AR": "آل عيفه",
    "CITYNAME_EN": "Al Eifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2746",
    "CITYNAME_AR": "شيبه مسوره",
    "CITYNAME_EN": "Sheibah Masurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2747",
    "CITYNAME_AR": "المذرح",
    "CITYNAME_EN": "Al-Mazreh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2748",
    "CITYNAME_AR": "آل درع ( آل وثله)",
    "CITYNAME_EN": "Al Der' (Al-Wathlah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2749",
    "CITYNAME_AR": "الشعب الابيض",
    "CITYNAME_EN": "Al-Shi'b Al-Abyed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2750",
    "CITYNAME_AR": "القرا البرطان",
    "CITYNAME_EN": "Al-Qara Al-Bertan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2751",
    "CITYNAME_AR": "عرق الحارث",
    "CITYNAME_EN": "Irq Al-Harith ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2752",
    "CITYNAME_AR": "عفار بئر الرحمن",
    "CITYNAME_EN": "Afar Bir Al-Rahman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2753",
    "CITYNAME_AR": "الهجله",
    "CITYNAME_EN": "Al Hajlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2754",
    "CITYNAME_AR": "المنشر",
    "CITYNAME_EN": "Al-Mansaher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2755",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Far'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2756",
    "CITYNAME_AR": "النعضاء",
    "CITYNAME_EN": "Alnedaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2757",
    "CITYNAME_AR": "فضلان",
    "CITYNAME_EN": "Fadlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2758",
    "CITYNAME_AR": "الرفغه",
    "CITYNAME_EN": "Al Refgha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2759",
    "CITYNAME_AR": "ال سارى",
    "CITYNAME_EN": "Al Sary ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2760",
    "CITYNAME_AR": "ال جشمه ال زاهر",
    "CITYNAME_EN": "Al Jashmah Al Zaher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2761",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2762",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2763",
    "CITYNAME_AR": "ال جحالى",
    "CITYNAME_EN": "Al hajaly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2764",
    "CITYNAME_AR": "الغرس",
    "CITYNAME_EN": "Al-Ghars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2765",
    "CITYNAME_AR": "العرف",
    "CITYNAME_EN": "Al-Arf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2766",
    "CITYNAME_AR": "ال زبيرى",
    "CITYNAME_EN": "Al Zobiry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2767",
    "CITYNAME_AR": "ال عشبه",
    "CITYNAME_EN": "Al Oshbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2768",
    "CITYNAME_AR": "ال يحمد",
    "CITYNAME_EN": "Al Yehmid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2769",
    "CITYNAME_AR": "ال لهوى",
    "CITYNAME_EN": "Al lahwa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2770",
    "CITYNAME_AR": "عرق ابن مسلم",
    "CITYNAME_EN": "Irq ibn Moslim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2771",
    "CITYNAME_AR": "المجاعبه",
    "CITYNAME_EN": "Al-Ma'ajbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2772",
    "CITYNAME_AR": "آل جحيش",
    "CITYNAME_EN": "Al Juhaysh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2773",
    "CITYNAME_AR": "هجره بن صقيع",
    "CITYNAME_EN": "Hijrah bin Saqi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2774",
    "CITYNAME_AR": "ال حازب",
    "CITYNAME_EN": "Al Hazib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2775",
    "CITYNAME_AR": "ال قناع",
    "CITYNAME_EN": "Al Qena' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2776",
    "CITYNAME_AR": "آل راقع",
    "CITYNAME_EN": "Al Raqi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2777",
    "CITYNAME_AR": "شويحط ال حيان",
    "CITYNAME_EN": "Shuwayhit Al Heban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2778",
    "CITYNAME_AR": "ال مصلح",
    "CITYNAME_EN": "Al Meslih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2779",
    "CITYNAME_AR": "الحشه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2780",
    "CITYNAME_AR": "ال هويج",
    "CITYNAME_EN": "Al Howayj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2781",
    "CITYNAME_AR": "ال ناجم",
    "CITYNAME_EN": "Al Najem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2782",
    "CITYNAME_AR": "العيفه ال مانع",
    "CITYNAME_EN": "Al Efah Al Mani'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2783",
    "CITYNAME_AR": "شامره",
    "CITYNAME_EN": "Shamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2784",
    "CITYNAME_AR": "هجره البغبغ",
    "CITYNAME_EN": "Hijrah Al-Baghbagh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2785",
    "CITYNAME_AR": "فرع السوق",
    "CITYNAME_EN": "Far' Al-Souq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2786",
    "CITYNAME_AR": "هجره البياض",
    "CITYNAME_EN": " Hijrah Al-Bayad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2787",
    "CITYNAME_AR": "هجره النوره",
    "CITYNAME_EN": "Hijrah Al-Nurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2788",
    "CITYNAME_AR": "محمضين",
    "CITYNAME_EN": "Mahmadin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2789",
    "CITYNAME_AR": "هجره الواديه",
    "CITYNAME_EN": "Hijrah Al-Wadiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2790",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Ghorab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2791",
    "CITYNAME_AR": "بئر الاجباب",
    "CITYNAME_EN": "Bir Al-Ajbab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2792",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2793",
    "CITYNAME_AR": "الصلعان",
    "CITYNAME_EN": "Al-Sal'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2794",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2795",
    "CITYNAME_AR": "آل ربيعى",
    "CITYNAME_EN": "Al Rabi'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2796",
    "CITYNAME_AR": "آل ابوسالم",
    "CITYNAME_EN": "Al Abu Salem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2797",
    "CITYNAME_AR": "آل غانم",
    "CITYNAME_EN": "Al Ghanem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2798",
    "CITYNAME_AR": "العشره",
    "CITYNAME_EN": "Al-Oshrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2799",
    "CITYNAME_AR": "المراث",
    "CITYNAME_EN": "Al-Merath",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2800",
    "CITYNAME_AR": "الزرقان",
    "CITYNAME_EN": "Al-Zarqan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2801",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Al-Rajmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2802",
    "CITYNAME_AR": "آل سفران",
    "CITYNAME_EN": "Al-Sefran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2803",
    "CITYNAME_AR": "الهايض",
    "CITYNAME_EN": "Al-Hayid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2804",
    "CITYNAME_AR": "آل حميد",
    "CITYNAME_EN": "Al Hamid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2805",
    "CITYNAME_AR": "المراوحه",
    "CITYNAME_EN": "Al-Marawhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2806",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al-Baq'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2807",
    "CITYNAME_AR": "آل الشقى",
    "CITYNAME_EN": "Al Al-Shaqi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2808",
    "CITYNAME_AR": "آل صبحاء والـحميد",
    "CITYNAME_EN": "Al Sabhaa and Al-Hamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2809",
    "CITYNAME_AR": "آل الواقدى (آل عامر)",
    "CITYNAME_EN": "Al Al-Waqdy (Al Amer)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2810",
    "CITYNAME_AR": "آل جوير",
    "CITYNAME_EN": "Al Juweir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2811",
    "CITYNAME_AR": "آل مصلح",
    "CITYNAME_EN": "Al Meslih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2812",
    "CITYNAME_AR": "العرعره",
    "CITYNAME_EN": "Al-Ararah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2813",
    "CITYNAME_AR": "آل قباص (العاند)",
    "CITYNAME_EN": "Al Qebas (Al-Aned)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2814",
    "CITYNAME_AR": "ال عداويه",
    "CITYNAME_EN": "Al Adawiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2815",
    "CITYNAME_AR": "المحجر",
    "CITYNAME_EN": "Al-Mehjar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2816",
    "CITYNAME_AR": "آل شارد",
    "CITYNAME_EN": "Al Sharid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2817",
    "CITYNAME_AR": "آل عباس",
    "CITYNAME_EN": "Al Abbas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2818",
    "CITYNAME_AR": "المواسمه",
    "CITYNAME_EN": "Al-Mawasmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2819",
    "CITYNAME_AR": "آل مسلمه",
    "CITYNAME_EN": "Al Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2820",
    "CITYNAME_AR": "آل شامر",
    "CITYNAME_EN": "Al Shamer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2821",
    "CITYNAME_AR": "آل ماحش(آل حريش)",
    "CITYNAME_EN": "Al Mahish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2822",
    "CITYNAME_AR": "نقعه",
    "CITYNAME_EN": "Naq'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2823",
    "CITYNAME_AR": "آل مزهر",
    "CITYNAME_EN": "Al Mezhir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2824",
    "CITYNAME_AR": "آل حريش",
    "CITYNAME_EN": "Al Harish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2825",
    "CITYNAME_AR": "آل بو نصير",
    "CITYNAME_EN": "Al Bu Nasir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2826",
    "CITYNAME_AR": "آل درهم",
    "CITYNAME_EN": "Al Derham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2827",
    "CITYNAME_AR": "آل مغدي",
    "CITYNAME_EN": "Al Maghdy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2828",
    "CITYNAME_AR": "آل رغا",
    "CITYNAME_EN": "Al Ragha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2829",
    "CITYNAME_AR": "الصدر آل حلمه",
    "CITYNAME_EN": "Al Sadr Al Halmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2830",
    "CITYNAME_AR": "العقده (الحرقان)",
    "CITYNAME_EN": "Al Qa'dah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2831",
    "CITYNAME_AR": "آل على",
    "CITYNAME_EN": "Al Ali ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2832",
    "CITYNAME_AR": "آل زيدان",
    "CITYNAME_EN": "Al Zeidan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2833",
    "CITYNAME_AR": "آل عجور",
    "CITYNAME_EN": "Al Ajur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2834",
    "CITYNAME_AR": "النجد",
    "CITYNAME_EN": "Al-Nejd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2835",
    "CITYNAME_AR": "ضبعه",
    "CITYNAME_EN": "Dab'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2836",
    "CITYNAME_AR": "آل ابو قثرد",
    "CITYNAME_EN": "Al Abu Qithred ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2837",
    "CITYNAME_AR": "آل غراف",
    "CITYNAME_EN": "Al Gheraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2838",
    "CITYNAME_AR": "آل شريه (الوقيره)",
    "CITYNAME_EN": "Al Shurayh (Al-Waqirah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2839",
    "CITYNAME_AR": "آل مغامر",
    "CITYNAME_EN": "Al Moghamer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2840",
    "CITYNAME_AR": "الغبران",
    "CITYNAME_EN": "Al-Ghabran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2841",
    "CITYNAME_AR": "آل لاحق ( آل مهدي)",
    "CITYNAME_EN": "Al Lahiq (Al Mahdy)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2842",
    "CITYNAME_AR": "آل طلحان",
    "CITYNAME_EN": "Al Talhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2843",
    "CITYNAME_AR": "آل عتيق (العطفه)",
    "CITYNAME_EN": "Al Atiq (Al-Atfah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2844",
    "CITYNAME_AR": "الهفلان (آل حجور)",
    "CITYNAME_EN": "Al-Haflan (Al Hajur)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2845",
    "CITYNAME_AR": "آل جازعه",
    "CITYNAME_EN": "Al jaz'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2846",
    "CITYNAME_AR": "آل رفيع",
    "CITYNAME_EN": "Al rafi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2847",
    "CITYNAME_AR": "آل داوود",
    "CITYNAME_EN": "Al Dawud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2848",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2849",
    "CITYNAME_AR": "مضارح (آل سويد)",
    "CITYNAME_EN": "Madareh (Al Sued)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2850",
    "CITYNAME_AR": "آل مصلح ( الشعب)",
    "CITYNAME_EN": "Al Meslih (Al-Sha'b)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2851",
    "CITYNAME_AR": "آل دابش",
    "CITYNAME_EN": "Al Dabish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2852",
    "CITYNAME_AR": "آل دليم",
    "CITYNAME_EN": "Al Dalim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2853",
    "CITYNAME_AR": "الشرمان",
    "CITYNAME_EN": "Al-Sharman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2854",
    "CITYNAME_AR": "الخشناء",
    "CITYNAME_EN": "Al-Khashnaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2855",
    "CITYNAME_AR": "آل عطاف",
    "CITYNAME_EN": "Al-Etaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2856",
    "CITYNAME_AR": "آل سيف",
    "CITYNAME_EN": "Al-Seif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2857",
    "CITYNAME_AR": "آل الميز",
    "CITYNAME_EN": "Al-Miz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2858",
    "CITYNAME_AR": "البصره",
    "CITYNAME_EN": "Al-Basrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2859",
    "CITYNAME_AR": "النجايب",
    "CITYNAME_EN": "Al-Njayib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2860",
    "CITYNAME_AR": "آل بحير",
    "CITYNAME_EN": "Al Bahir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2861",
    "CITYNAME_AR": "آل فطيس",
    "CITYNAME_EN": "Al fatis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2862",
    "CITYNAME_AR": "عقيقه",
    "CITYNAME_EN": "Aqiqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2863",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2864",
    "CITYNAME_AR": "المحجر",
    "CITYNAME_EN": "Al-Mehjar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2865",
    "CITYNAME_AR": "آل مونس",
    "CITYNAME_EN": "Al Munis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2866",
    "CITYNAME_AR": "آل الطويل",
    "CITYNAME_EN": "Al-Tawil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2867",
    "CITYNAME_AR": "ابن خميس",
    "CITYNAME_EN": "Ibn Khamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2868",
    "CITYNAME_AR": "آل شماخ",
    "CITYNAME_EN": "Al Shemakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2869",
    "CITYNAME_AR": "آل حريز",
    "CITYNAME_EN": "Al Hariz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2870",
    "CITYNAME_AR": "آل مطير",
    "CITYNAME_EN": "Al Matir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2871",
    "CITYNAME_AR": "آل جهيم",
    "CITYNAME_EN": "Al Jahim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2872",
    "CITYNAME_AR": "آل جعدنه",
    "CITYNAME_EN": "Al Ja'danah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2873",
    "CITYNAME_AR": "آل هيف (الوسط)",
    "CITYNAME_EN": "Al hif (Al-Wasat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2874",
    "CITYNAME_AR": "آل مجلى",
    "CITYNAME_EN": "Al Majla ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2875",
    "CITYNAME_AR": "الصمعه (ال عبدالله)",
    "CITYNAME_EN": "Al Sam'ah (Al Abdullah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2876",
    "CITYNAME_AR": "آل مسفوه",
    "CITYNAME_EN": "Al Mesfawah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2877",
    "CITYNAME_AR": "آل خميس",
    "CITYNAME_EN": "Al Khamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2878",
    "CITYNAME_AR": "القروات",
    "CITYNAME_EN": "Al-Qarwat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2879",
    "CITYNAME_AR": "الحوط",
    "CITYNAME_EN": "Al Hut",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2880",
    "CITYNAME_AR": "آل لوصه",
    "CITYNAME_EN": "Al Lusah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2881",
    "CITYNAME_AR": "آل بحير",
    "CITYNAME_EN": "Al Bahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2882",
    "CITYNAME_AR": "آل عاطف",
    "CITYNAME_EN": "Al Atif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2883",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al-Jaz'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2884",
    "CITYNAME_AR": "آل فاضل (آل زهره)",
    "CITYNAME_EN": "Al fadel (Al Zohrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2885",
    "CITYNAME_AR": "آل دشان",
    "CITYNAME_EN": "Al deshan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2886",
    "CITYNAME_AR": "آل مصلح (آل عمران)",
    "CITYNAME_EN": "Al Meslih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2887",
    "CITYNAME_AR": "آل مفلح (آل عمران)",
    "CITYNAME_EN": "Al Meflih (Al Emran)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2888",
    "CITYNAME_AR": "آل بو هيشه",
    "CITYNAME_EN": "Al Bu Hisha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2889",
    "CITYNAME_AR": "آل يصباء",
    "CITYNAME_EN": "Al Basbaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2890",
    "CITYNAME_AR": "آل صالح بن راقع",
    "CITYNAME_EN": "Al Saleh bin Raqi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2891",
    "CITYNAME_AR": "آل صلام",
    "CITYNAME_EN": "Al Salam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2892",
    "CITYNAME_AR": "آل عاصى",
    "CITYNAME_EN": "Al Asy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2893",
    "CITYNAME_AR": "آل جراد",
    "CITYNAME_EN": "Al Jerad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2894",
    "CITYNAME_AR": "العوران",
    "CITYNAME_EN": "Al-Oran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2895",
    "CITYNAME_AR": "آل الخرج",
    "CITYNAME_EN": "Al-Kharj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2896",
    "CITYNAME_AR": "آل مسعود",
    "CITYNAME_EN": "Al Mas'ud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2897",
    "CITYNAME_AR": "آل خضيره",
    "CITYNAME_EN": "Al Khadirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2898",
    "CITYNAME_AR": "آل مجلب",
    "CITYNAME_EN": "Al Mejlib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2899",
    "CITYNAME_AR": "آل علي بن علي (آل سوداء)",
    "CITYNAME_EN": "Al Ali bin Ali (Al Sawdaa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2900",
    "CITYNAME_AR": "آل جحلان",
    "CITYNAME_EN": "Al jahlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2901",
    "CITYNAME_AR": "آل البقعاء",
    "CITYNAME_EN": "Al Al-Baq'aa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2902",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "As Safraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2903",
    "CITYNAME_AR": "آل الحمري",
    "CITYNAME_EN": "Al Al-Hemry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2904",
    "CITYNAME_AR": "آل هويج",
    "CITYNAME_EN": "Al Howayj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2905",
    "CITYNAME_AR": "آل شامخ",
    "CITYNAME_EN": "Al Shamekh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2906",
    "CITYNAME_AR": "الدافعه",
    "CITYNAME_EN": "Al-Dafi'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2907",
    "CITYNAME_AR": "مرحض",
    "CITYNAME_EN": "Merhid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2908",
    "CITYNAME_AR": "الجميد (جبل الجميد)",
    "CITYNAME_EN": "Al-Jamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2909",
    "CITYNAME_AR": "العقيبه",
    "CITYNAME_EN": "Al-Aqibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2910",
    "CITYNAME_AR": "الضارب",
    "CITYNAME_EN": "Al-Darib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2911",
    "CITYNAME_AR": "المنامه",
    "CITYNAME_EN": "Al-Manamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2912",
    "CITYNAME_AR": "شيبة السر",
    "CITYNAME_EN": "Shibah Al-Ser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2913",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2914",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rahwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2915",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dab'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2916",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al-Ma'qer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2917",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "Al-Sud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2918",
    "CITYNAME_AR": "الحلوات",
    "CITYNAME_EN": "Al-Halwat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2919",
    "CITYNAME_AR": "غمان",
    "CITYNAME_EN": "Ghaman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2920",
    "CITYNAME_AR": "الخنق",
    "CITYNAME_EN": "Al Khanq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2921",
    "CITYNAME_AR": "الرصد",
    "CITYNAME_EN": "Al Rasd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2922",
    "CITYNAME_AR": "الصرار",
    "CITYNAME_EN": "As Sarrar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2923",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Al-Jarb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2924",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "Al-Selah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2925",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2926",
    "CITYNAME_AR": "الساقيه",
    "CITYNAME_EN": "Al-Saqyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2927",
    "CITYNAME_AR": "الواغره",
    "CITYNAME_EN": "Al-Wagherah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2928",
    "CITYNAME_AR": "الاشاعره",
    "CITYNAME_EN": "Al-Sha'irah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2929",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2930",
    "CITYNAME_AR": "الحنى",
    "CITYNAME_EN": "Al-Hany",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2931",
    "CITYNAME_AR": "آل عايشه (آل سعيده)",
    "CITYNAME_EN": "Al-Ayshah (Al-Sai'dah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2932",
    "CITYNAME_AR": "السدر",
    "CITYNAME_EN": "Al-Sedr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2933",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2934",
    "CITYNAME_AR": "آل فارع",
    "CITYNAME_EN": "Al Fari'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2935",
    "CITYNAME_AR": "قرض",
    "CITYNAME_EN": "Qard",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2936",
    "CITYNAME_AR": "مبرتة",
    "CITYNAME_EN": "Mabterah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2937",
    "CITYNAME_AR": "ضعن",
    "CITYNAME_EN": "Da'n ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2938",
    "CITYNAME_AR": "الرياعة",
    "CITYNAME_EN": "Al-Raba'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2939",
    "CITYNAME_AR": "محجر النجايب",
    "CITYNAME_EN": "Mahjer Al-Najayib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2940",
    "CITYNAME_AR": "ارنوا",
    "CITYNAME_EN": "Arnawa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2941",
    "CITYNAME_AR": "سمنه مضارح",
    "CITYNAME_EN": "Samnah Madarih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2942",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Al-Dayqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2943",
    "CITYNAME_AR": "الذافره",
    "CITYNAME_EN": "Al-Zafrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2944",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2945",
    "CITYNAME_AR": "جفجف",
    "CITYNAME_EN": "Jefjef ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2946",
    "CITYNAME_AR": "الخضراء (العنابس)",
    "CITYNAME_EN": "Al-Khadraa (Al-Anabis)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2947",
    "CITYNAME_AR": "ال غراء",
    "CITYNAME_EN": "Al Gheraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2948",
    "CITYNAME_AR": "دهر",
    "CITYNAME_EN": "Dahr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2949",
    "CITYNAME_AR": "القطع",
    "CITYNAME_EN": "Al Qat'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2950",
    "CITYNAME_AR": "السمان",
    "CITYNAME_EN": "Al-Seman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2951",
    "CITYNAME_AR": "ال بقار",
    "CITYNAME_EN": "Al-Beqar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2952",
    "CITYNAME_AR": "ال جحا (ال رباش)",
    "CITYNAME_EN": "Al-Jaha (Al-Reyash)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2953",
    "CITYNAME_AR": "آل السعن",
    "CITYNAME_EN": "Al-Alsa'n ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2954",
    "CITYNAME_AR": "الشقحه (المصاعبه)",
    "CITYNAME_EN": "Al=Shaqhah (Al-Masay'ah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2955",
    "CITYNAME_AR": "ال نميش وال دبيس",
    "CITYNAME_EN": "Al-Namish and Al Dabis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2956",
    "CITYNAME_AR": "المثناه",
    "CITYNAME_EN": "Al  Mathnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2957",
    "CITYNAME_AR": "الرجل",
    "CITYNAME_EN": "Al-Rajl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2958",
    "CITYNAME_AR": "مهد السربه",
    "CITYNAME_EN": "Mahd Al-Sariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2959",
    "CITYNAME_AR": "المغنيات",
    "CITYNAME_EN": "Al-Maghniyat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2960",
    "CITYNAME_AR": "الرجيله",
    "CITYNAME_EN": "Al-Rajilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2961",
    "CITYNAME_AR": "العان جرب القضب",
    "CITYNAME_EN": "Al-An Jarb Al-Qadb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2962",
    "CITYNAME_AR": "القونس",
    "CITYNAME_EN": "Al-Qunas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2963",
    "CITYNAME_AR": "الجعران",
    "CITYNAME_EN": "Al-Ju'ran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2964",
    "CITYNAME_AR": "الجرشه",
    "CITYNAME_EN": "Al-Jarshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2965",
    "CITYNAME_AR": "المعقل",
    "CITYNAME_EN": "Al-Ma'qil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2966",
    "CITYNAME_AR": "مهد الفقوعه",
    "CITYNAME_EN": "Mahd Al-Faqu'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2967",
    "CITYNAME_AR": "الغمير",
    "CITYNAME_EN": "Al-Ghamir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2968",
    "CITYNAME_AR": "الخمجات",
    "CITYNAME_EN": "Al-Khamjat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2969",
    "CITYNAME_AR": "البازم (العطف)",
    "CITYNAME_EN": "Al-Bazim (Al-Atf)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2970",
    "CITYNAME_AR": "ال مطر",
    "CITYNAME_EN": "Al-Matar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2971",
    "CITYNAME_AR": "علاف والهملة",
    "CITYNAME_EN": "Elaf and Al-Hamlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2972",
    "CITYNAME_AR": "ال يعلا ال شبيب",
    "CITYNAME_EN": "Al Yi'la Al Shabib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2973",
    "CITYNAME_AR": "ال مرتفع",
    "CITYNAME_EN": "Al Mertafi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2974",
    "CITYNAME_AR": "ال زايد",
    "CITYNAME_EN": "Al Zayid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2975",
    "CITYNAME_AR": "الجهوات",
    "CITYNAME_EN": "Al-Jahwat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2976",
    "CITYNAME_AR": "السلاطين",
    "CITYNAME_EN": "Al-Salatin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2977",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2978",
    "CITYNAME_AR": "القناء",
    "CITYNAME_EN": "Al-Qenaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2979",
    "CITYNAME_AR": "ال عياش والجرشان",
    "CITYNAME_EN": "Al Eyash and Al-Garshan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2980",
    "CITYNAME_AR": "ال شعيب",
    "CITYNAME_EN": "Al Sho'ayb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2981",
    "CITYNAME_AR": "ال هبالة",
    "CITYNAME_EN": "Al Habalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2982",
    "CITYNAME_AR": "الجافش",
    "CITYNAME_EN": "Al-Jafish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2983",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Al Hazm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2984",
    "CITYNAME_AR": "الحصيصه",
    "CITYNAME_EN": "Al-Hasisah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2985",
    "CITYNAME_AR": "مفلط",
    "CITYNAME_EN": "Meflit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2986",
    "CITYNAME_AR": "هجره حمران",
    "CITYNAME_EN": "Hijrah Hemran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2987",
    "CITYNAME_AR": "هجره بنى تود",
    "CITYNAME_EN": "Hijrah Bni Tud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2988",
    "CITYNAME_AR": "بئر هبشه",
    "CITYNAME_EN": "Bir Habishah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2989",
    "CITYNAME_AR": "الحصم",
    "CITYNAME_EN": "Al Hasm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2990",
    "CITYNAME_AR": "اللبيده",
    "CITYNAME_EN": "Al Bayda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2991",
    "CITYNAME_AR": "الغنيمه",
    "CITYNAME_EN": "Al Ghunaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2992",
    "CITYNAME_AR": "يفه",
    "CITYNAME_EN": "Yafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2993",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al Muqaar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2994",
    "CITYNAME_AR": "جرايز",
    "CITYNAME_EN": "Gazayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2995",
    "CITYNAME_AR": "الرصفه",
    "CITYNAME_EN": "Al Rasfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2996",
    "CITYNAME_AR": "القرنين",
    "CITYNAME_EN": "Al Qarnayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2997",
    "CITYNAME_AR": "المجزعه",
    "CITYNAME_EN": "Al Magzaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2998",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Al Hubayel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "2999",
    "CITYNAME_AR": "الاثوار",
    "CITYNAME_EN": "Al Athwar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3000",
    "CITYNAME_AR": "عار",
    "CITYNAME_EN": "Aar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3001",
    "CITYNAME_AR": "الطليله",
    "CITYNAME_EN": "At-Tulaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3002",
    "CITYNAME_AR": "الحاشي",
    "CITYNAME_EN": "Al Hashi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3003",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3004",
    "CITYNAME_AR": "الخراشي",
    "CITYNAME_EN": "Al Khurashi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3005",
    "CITYNAME_AR": "صهبان",
    "CITYNAME_EN": "Sahban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3006",
    "CITYNAME_AR": "احفان",
    "CITYNAME_EN": "Ahfan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3007",
    "CITYNAME_AR": "مرغه",
    "CITYNAME_EN": "Margha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3008",
    "CITYNAME_AR": "قرية محرش",
    "CITYNAME_EN": "Qaryet Muhresh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3009",
    "CITYNAME_AR": "شيابه",
    "CITYNAME_EN": "Sheyabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3010",
    "CITYNAME_AR": "غلف العليا  والسفلى",
    "CITYNAME_EN": "Ghulf Aleulya Walsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "3011",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3012",
    "CITYNAME_AR": "مناحر",
    "CITYNAME_EN": "Manaher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3013",
    "CITYNAME_AR": "الوقيره",
    "CITYNAME_EN": "Al-Waqirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3014",
    "CITYNAME_AR": "شوحط",
    "CITYNAME_EN": "Shuhat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3015",
    "CITYNAME_AR": "النخيله",
    "CITYNAME_EN": "Al-Nakhilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3016",
    "CITYNAME_AR": "الحيد",
    "CITYNAME_EN": "Al Heed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3017",
    "CITYNAME_AR": "اللحجه (اليمنى)",
    "CITYNAME_EN": "Allahjah (Al-Yomna)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3018",
    "CITYNAME_AR": "وادي شيني",
    "CITYNAME_EN": "Wadi Shiny ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3019",
    "CITYNAME_AR": "الغريفاء",
    "CITYNAME_EN": "Al-Ghorayfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3020",
    "CITYNAME_AR": "اللحجه اليسرى",
    "CITYNAME_EN": "Allahjah Al-Yosra ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3021",
    "CITYNAME_AR": "اهل البلاد",
    "CITYNAME_EN": "Ahl Al-Bilad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3022",
    "CITYNAME_AR": "جلد",
    "CITYNAME_EN": "Jald ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3023",
    "CITYNAME_AR": "القائمه",
    "CITYNAME_EN": "Al-Qaemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3024",
    "CITYNAME_AR": "اسفل الوعره",
    "CITYNAME_EN": "Asfel Al-Wa'rah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3025",
    "CITYNAME_AR": "العتاب",
    "CITYNAME_EN": "Al-Etab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3026",
    "CITYNAME_AR": "حبره الجنوبيه",
    "CITYNAME_EN": "Habrah Al-Janubiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3027",
    "CITYNAME_AR": "الحيمه",
    "CITYNAME_EN": "Al-Himah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3028",
    "CITYNAME_AR": "المحله",
    "CITYNAME_EN": "Al-Mahalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3029",
    "CITYNAME_AR": "حبره الشماليه",
    "CITYNAME_EN": "Habrah Al-Shamaliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3030",
    "CITYNAME_AR": "ام قمع (القمع)",
    "CITYNAME_EN": "Umm Qom' (Al-Qom')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3031",
    "CITYNAME_AR": "موحاء",
    "CITYNAME_EN": "Mohaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3032",
    "CITYNAME_AR": "حميات",
    "CITYNAME_EN": "Homyaat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3033",
    "CITYNAME_AR": "تيس",
    "CITYNAME_EN": "Tis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3034",
    "CITYNAME_AR": "الواضح",
    "CITYNAME_EN": "Al-Wadeh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3035",
    "CITYNAME_AR": "سبله",
    "CITYNAME_EN": "Sablah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3036",
    "CITYNAME_AR": "سائل الاعلى",
    "CITYNAME_EN": "Sael Al-A'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3037",
    "CITYNAME_AR": "ام عدن",
    "CITYNAME_EN": "Umm Adn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3038",
    "CITYNAME_AR": "ام رقعه",
    "CITYNAME_EN": "Umm raq'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3039",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3040",
    "CITYNAME_AR": "خبه",
    "CITYNAME_EN": "Khabbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3041",
    "CITYNAME_AR": "ام حجفه (الحجفة)",
    "CITYNAME_EN": "Umm Hjfah (Al-Hajfah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3042",
    "CITYNAME_AR": "بطومه",
    "CITYNAME_EN": "Botumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3043",
    "CITYNAME_AR": "الثافره",
    "CITYNAME_EN": "Al-Thafrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3044",
    "CITYNAME_AR": "ضموة جنب",
    "CITYNAME_EN": "Damwah Janb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3045",
    "CITYNAME_AR": "وادي مهرة",
    "CITYNAME_EN": "Wadi Mohrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3046",
    "CITYNAME_AR": "ذا حباب",
    "CITYNAME_EN": "Za Hebab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3047",
    "CITYNAME_AR": "المخورمة",
    "CITYNAME_EN": "Al-Makhormah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3048",
    "CITYNAME_AR": "وادي ناعم",
    "CITYNAME_EN": "Wadi Na'm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3049",
    "CITYNAME_AR": "وادي ضبي",
    "CITYNAME_EN": "Wadi Daby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3050",
    "CITYNAME_AR": "المعتقه",
    "CITYNAME_EN": "Al-Ma'taqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3051",
    "CITYNAME_AR": "المقبره",
    "CITYNAME_EN": "Al-Maqbarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3052",
    "CITYNAME_AR": "حمه",
    "CITYNAME_EN": "Hammah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3053",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3054",
    "CITYNAME_AR": "معشاش",
    "CITYNAME_EN": "Mi'shash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3055",
    "CITYNAME_AR": "وادى الحياة",
    "CITYNAME_EN": "Wadi Al Haya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3056",
    "CITYNAME_AR": "وادي العوص",
    "CITYNAME_EN": "Wadi Al-Aws ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3057",
    "CITYNAME_AR": "طور ال الحسن",
    "CITYNAME_EN": "Tawr Al Al-Hasan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3058",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Al Qahbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3059",
    "CITYNAME_AR": "عين القاعه",
    "CITYNAME_EN": "Ein Al-Qa'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3060",
    "CITYNAME_AR": "القوارى",
    "CITYNAME_EN": "Al-Qwary ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3061",
    "CITYNAME_AR": "المواسط",
    "CITYNAME_EN": "Al-Mwaset ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3062",
    "CITYNAME_AR": "شيبه",
    "CITYNAME_EN": "Shebah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3063",
    "CITYNAME_AR": "وادى يرخاز",
    "CITYNAME_EN": "Wadi yerkhaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3064",
    "CITYNAME_AR": "وادي راحه",
    "CITYNAME_EN": "Wadi Rahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3065",
    "CITYNAME_AR": "وادى متيه",
    "CITYNAME_EN": "Waldi Matyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3066",
    "CITYNAME_AR": "الحريجه",
    "CITYNAME_EN": "Al-Harijah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3067",
    "CITYNAME_AR": "الحصبه",
    "CITYNAME_EN": "Al-Hasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3068",
    "CITYNAME_AR": "او باخ",
    "CITYNAME_EN": "Awbakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3069",
    "CITYNAME_AR": "ثربه",
    "CITYNAME_EN": "Therba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3070",
    "CITYNAME_AR": "وادي بوان",
    "CITYNAME_EN": "Wadi Bawan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3071",
    "CITYNAME_AR": "عنقار",
    "CITYNAME_EN": "Anqar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3072",
    "CITYNAME_AR": "خشم عنقار",
    "CITYNAME_EN": "Khasm Anqar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3073",
    "CITYNAME_AR": "كلب",
    "CITYNAME_EN": "Kalb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3074",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3075",
    "CITYNAME_AR": "حمرة الجنونية",
    "CITYNAME_EN": "Homrah Al-Janubiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3076",
    "CITYNAME_AR": "النشمات",
    "CITYNAME_EN": "Al Heed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3077",
    "CITYNAME_AR": "سحب",
    "CITYNAME_EN": "Sahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3078",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Al Raudah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3079",
    "CITYNAME_AR": "النوله",
    "CITYNAME_EN": "Al-Nulah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3080",
    "CITYNAME_AR": "الضربة",
    "CITYNAME_EN": "Al-Darbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3081",
    "CITYNAME_AR": "ضموة ذبح",
    "CITYNAME_EN": "Damwah Zebh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3082",
    "CITYNAME_AR": "القائمه ( الشبهاني )",
    "CITYNAME_EN": "Al-Qaemah (Al-Shabhany)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3083",
    "CITYNAME_AR": "القماقم",
    "CITYNAME_EN": "Al-Qamaqim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3084",
    "CITYNAME_AR": "ام نقض",
    "CITYNAME_EN": "Umm Naqd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3085",
    "CITYNAME_AR": "الفايجه",
    "CITYNAME_EN": "Al-Fayjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3086",
    "CITYNAME_AR": "سراة عبيدة",
    "CITYNAME_EN": "Sarat Ubaida",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3087",
    "CITYNAME_AR": "ال غانم (المشرب)",
    "CITYNAME_EN": "Al-Ghanim (Al-Mashrab)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3088",
    "CITYNAME_AR": "الجرده",
    "CITYNAME_EN": "Al-Jerdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3089",
    "CITYNAME_AR": "الصنبور",
    "CITYNAME_EN": "Al Sonbur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3090",
    "CITYNAME_AR": "العرف (العين)",
    "CITYNAME_EN": "Al-Arf (Al-Ein)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3091",
    "CITYNAME_AR": "ال جلده (آل عايذ)",
    "CITYNAME_EN": "Al-GJeldah (Al-Ayiz)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3092",
    "CITYNAME_AR": "ال قنبه",
    "CITYNAME_EN": "Al-Qanbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3093",
    "CITYNAME_AR": "الوهابه",
    "CITYNAME_EN": "Al Wahabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3094",
    "CITYNAME_AR": "ال سلمان واالحومه",
    "CITYNAME_EN": "Al-Salman and Al-Humah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3095",
    "CITYNAME_AR": "ال محاصر",
    "CITYNAME_EN": "Al-Mohasir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3096",
    "CITYNAME_AR": "ال مهروى",
    "CITYNAME_EN": "Al-Mahrawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3097",
    "CITYNAME_AR": "القعم",
    "CITYNAME_EN": "Al-Qa'm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3098",
    "CITYNAME_AR": "الربعه",
    "CITYNAME_EN": "Al-Rob'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3099",
    "CITYNAME_AR": "ال كليب",
    "CITYNAME_EN": "Al kalib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3100",
    "CITYNAME_AR": "آل الصياح",
    "CITYNAME_EN": "Al Al-Sayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3101",
    "CITYNAME_AR": "آل الهتلان",
    "CITYNAME_EN": "Al Al-Hatlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3102",
    "CITYNAME_AR": "الحرامله",
    "CITYNAME_EN": "Al-Haramlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3103",
    "CITYNAME_AR": "ال قوشع",
    "CITYNAME_EN": "Al Qusha' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3104",
    "CITYNAME_AR": "العجمه",
    "CITYNAME_EN": "Al-Ajmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3105",
    "CITYNAME_AR": "المذعان",
    "CITYNAME_EN": "Al-Mez'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3106",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al-Hesn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3107",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3108",
    "CITYNAME_AR": "ال سليمان",
    "CITYNAME_EN": "Al Solayman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3109",
    "CITYNAME_AR": "ال دكين",
    "CITYNAME_EN": "Al dakin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3110",
    "CITYNAME_AR": "ال مفرج",
    "CITYNAME_EN": "Al Mefrij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3111",
    "CITYNAME_AR": "ال مذعان (القدان)",
    "CITYNAME_EN": "Al Mez'an (Al-Qadan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3112",
    "CITYNAME_AR": "ال  لعمى",
    "CITYNAME_EN": "Al La'ma ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3113",
    "CITYNAME_AR": "الذيبه",
    "CITYNAME_EN": "Al-Zeibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3114",
    "CITYNAME_AR": "المناديه",
    "CITYNAME_EN": "Al-Monadiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3115",
    "CITYNAME_AR": "آل الحامض",
    "CITYNAME_EN": "Al Al-Hamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3116",
    "CITYNAME_AR": "وادى الرهط",
    "CITYNAME_EN": "Wadi Al-Raht ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3117",
    "CITYNAME_AR": "البونخر والوبران",
    "CITYNAME_EN": "Al-Bonkher and Al-Wayran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3118",
    "CITYNAME_AR": "الفقاعيس",
    "CITYNAME_EN": "Al-Faqa'is ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3119",
    "CITYNAME_AR": "الدبه (ال مقبل)",
    "CITYNAME_EN": "Al-Debbah (Al Meqbil)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3120",
    "CITYNAME_AR": "آل جخران",
    "CITYNAME_EN": "Al Jekhran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3121",
    "CITYNAME_AR": "بيت الحنون",
    "CITYNAME_EN": "Bayt Al-Hanun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3122",
    "CITYNAME_AR": "الوسط",
    "CITYNAME_EN": "Al Wasat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3123",
    "CITYNAME_AR": "آل قنينه",
    "CITYNAME_EN": "Al Qaninah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3124",
    "CITYNAME_AR": "ال مطير",
    "CITYNAME_EN": "Al Matir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3125",
    "CITYNAME_AR": "ال منيعه",
    "CITYNAME_EN": "Al mani'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3126",
    "CITYNAME_AR": "ال عبود ( الجرو)",
    "CITYNAME_EN": "Al Abud (Al-Jarw)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3127",
    "CITYNAME_AR": "وادى العمل زهير",
    "CITYNAME_EN": "Wadi Al-Amal Zohayr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3128",
    "CITYNAME_AR": "الجثلاء",
    "CITYNAME_EN": "Al Jathlaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3129",
    "CITYNAME_AR": "ال مرفاع",
    "CITYNAME_EN": "Al Merfa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3130",
    "CITYNAME_AR": "ال مرزوق",
    "CITYNAME_EN": "Al Marzouq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3131",
    "CITYNAME_AR": "ال سعيده",
    "CITYNAME_EN": "Al Sa'idah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3132",
    "CITYNAME_AR": "ال جليد",
    "CITYNAME_EN": "Al Jalid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3133",
    "CITYNAME_AR": "ال كشمه",
    "CITYNAME_EN": "Al Kashmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3134",
    "CITYNAME_AR": "ال صميع (بن عريج)",
    "CITYNAME_EN": "Al Sami' (bin Arij)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3135",
    "CITYNAME_AR": "ال نجيم",
    "CITYNAME_EN": "Al najim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3136",
    "CITYNAME_AR": "ال قاسم",
    "CITYNAME_EN": "Al Qasim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3137",
    "CITYNAME_AR": "ال جليد (البصره)",
    "CITYNAME_EN": "Al Jalid (Al-Basrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3138",
    "CITYNAME_AR": "ال معوضه (وابو عزيزه)",
    "CITYNAME_EN": "Al Me'wadah (Abu Azizah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3139",
    "CITYNAME_AR": "ال قريش",
    "CITYNAME_EN": "Al Quraysh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3140",
    "CITYNAME_AR": "ال كشيم",
    "CITYNAME_EN": "Al Kashim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3141",
    "CITYNAME_AR": "آل دوشه",
    "CITYNAME_EN": "Al Dushah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3142",
    "CITYNAME_AR": "ال خشمع",
    "CITYNAME_EN": "Al Khashma'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3143",
    "CITYNAME_AR": "آل عوير",
    "CITYNAME_EN": "Al Oweir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3144",
    "CITYNAME_AR": "الكوله وعلو الجوف",
    "CITYNAME_EN": "Al-Kulah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3145",
    "CITYNAME_AR": "آل عزبه (الفردان)",
    "CITYNAME_EN": "Al Ezbah (Al-fardan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3146",
    "CITYNAME_AR": "ضرب الضيق",
    "CITYNAME_EN": "Darb Al-Diq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3147",
    "CITYNAME_AR": "الركاب (اهل الحرث)",
    "CITYNAME_EN": "Al-Rekab (Ahl Al-Harth)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3148",
    "CITYNAME_AR": "ال جلال والحدباء العلياء",
    "CITYNAME_EN": "Al-jelal and Al-hadbaa (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3149",
    "CITYNAME_AR": "جوف ال معمر (  الحضيره )",
    "CITYNAME_EN": "Al-Hadirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3150",
    "CITYNAME_AR": "ال محى",
    "CITYNAME_EN": "Al Mohie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3151",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Al Hadbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3152",
    "CITYNAME_AR": "آل جلده",
    "CITYNAME_EN": "Al Jeldah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3153",
    "CITYNAME_AR": "ال مجرى",
    "CITYNAME_EN": "Al Majra ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3154",
    "CITYNAME_AR": "الحنجور",
    "CITYNAME_EN": "Al-Hanjour",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3155",
    "CITYNAME_AR": "آل الربايع",
    "CITYNAME_EN": "Al Al-Reba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3156",
    "CITYNAME_AR": "القطرين",
    "CITYNAME_EN": "Al-Qetrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3157",
    "CITYNAME_AR": "سحيبان",
    "CITYNAME_EN": "Sahiban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3158",
    "CITYNAME_AR": "العماره",
    "CITYNAME_EN": "Al Emara",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3159",
    "CITYNAME_AR": "ال قرعه (الصحن)",
    "CITYNAME_EN": "Al Qar'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3160",
    "CITYNAME_AR": "ال قريش",
    "CITYNAME_EN": "Al Quraysh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3161",
    "CITYNAME_AR": "وادى خضار",
    "CITYNAME_EN": "Wadi Khedar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3162",
    "CITYNAME_AR": "ال جحيفه",
    "CITYNAME_EN": "Al Jahifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3163",
    "CITYNAME_AR": "ال مالح",
    "CITYNAME_EN": "Al Malih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3164",
    "CITYNAME_AR": "ال عثمان وال فايع",
    "CITYNAME_EN": "Al Othamn  (Al fayi')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3165",
    "CITYNAME_AR": "المحزمه",
    "CITYNAME_EN": "Al-Mehzemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3166",
    "CITYNAME_AR": "ال حريد",
    "CITYNAME_EN": "Al Harid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3167",
    "CITYNAME_AR": "ال حنيف",
    "CITYNAME_EN": "Al Hanif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3168",
    "CITYNAME_AR": "آل مجرى آل مسعود",
    "CITYNAME_EN": "Al Mejra Al Masoud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3169",
    "CITYNAME_AR": "ال بوقفره",
    "CITYNAME_EN": "Al Buqafrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3170",
    "CITYNAME_AR": "ال عامر",
    "CITYNAME_EN": "Al Amer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3171",
    "CITYNAME_AR": "آل حمران",
    "CITYNAME_EN": "Al Hemran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3172",
    "CITYNAME_AR": "آل زليق (المصلح والعتمة)",
    "CITYNAME_EN": "Al Zaliq (Al-Meslih and Al-Atmah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3173",
    "CITYNAME_AR": "عين الخضره",
    "CITYNAME_EN": "Ain Al-Khedrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3174",
    "CITYNAME_AR": "عين البيضاء",
    "CITYNAME_EN": "Ain Al-Baydaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3175",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Ar-Rass",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3176",
    "CITYNAME_AR": "العقيقه",
    "CITYNAME_EN": "Al-Aqiqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3177",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3178",
    "CITYNAME_AR": "عين الملحاء",
    "CITYNAME_EN": "Ein Al-Malhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3179",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3180",
    "CITYNAME_AR": "ال مهمل",
    "CITYNAME_EN": "Al Mohmil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3181",
    "CITYNAME_AR": "ال بوضاوي",
    "CITYNAME_EN": "Al Bawdawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3182",
    "CITYNAME_AR": "ال حجل",
    "CITYNAME_EN": "Al Hijl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3183",
    "CITYNAME_AR": "الصبيغاء",
    "CITYNAME_EN": "Al-Sobayghaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3184",
    "CITYNAME_AR": "العنب",
    "CITYNAME_EN": "Al-Enab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3185",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3186",
    "CITYNAME_AR": "المسحاء (الغمرة)",
    "CITYNAME_EN": "Al-Mashaa (Al-Ghamrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3187",
    "CITYNAME_AR": "العقاره",
    "CITYNAME_EN": "Al-Aqarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3188",
    "CITYNAME_AR": "الكردم",
    "CITYNAME_EN": "Al Kardem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3189",
    "CITYNAME_AR": "ال عينه",
    "CITYNAME_EN": "Al-Einah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3190",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3191",
    "CITYNAME_AR": "الشرجه",
    "CITYNAME_EN": "Al-Sharjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3192",
    "CITYNAME_AR": "ال معقل",
    "CITYNAME_EN": "Al-Mi'qel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3193",
    "CITYNAME_AR": "المالطه",
    "CITYNAME_EN": "Al-Maltah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3194",
    "CITYNAME_AR": "الزاويه",
    "CITYNAME_EN": "Al-Zawyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3195",
    "CITYNAME_AR": "ال حـمود",
    "CITYNAME_EN": "Al-Hamud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3196",
    "CITYNAME_AR": "ال جمان",
    "CITYNAME_EN": "Al-Gaman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3197",
    "CITYNAME_AR": "ال زارب",
    "CITYNAME_EN": "Al-Zareb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3198",
    "CITYNAME_AR": "ال حابي",
    "CITYNAME_EN": "Al-Habi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3199",
    "CITYNAME_AR": "ال جحدل",
    "CITYNAME_EN": "Al Jahdal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3200",
    "CITYNAME_AR": "ال عطيف وال ظالم",
    "CITYNAME_EN": "Al-Atif and Al Zalem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3201",
    "CITYNAME_AR": "ال مانع",
    "CITYNAME_EN": "Al mani' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3202",
    "CITYNAME_AR": "زهره بني بشر",
    "CITYNAME_EN": "Zahrah Bni Beshr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3203",
    "CITYNAME_AR": "الرقة",
    "CITYNAME_EN": "Al-Raqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3204",
    "CITYNAME_AR": "خراص",
    "CITYNAME_EN": "Kheras ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3205",
    "CITYNAME_AR": "خبه",
    "CITYNAME_EN": "Khabbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3206",
    "CITYNAME_AR": "سلف مساري",
    "CITYNAME_EN": "Salaf Masary ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3207",
    "CITYNAME_AR": "مسحاء",
    "CITYNAME_EN": "Mashaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3208",
    "CITYNAME_AR": "الصبر",
    "CITYNAME_EN": "Al-Sabr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3209",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3210",
    "CITYNAME_AR": "هجره المناديه",
    "CITYNAME_EN": "Mirah Al-Monadiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3211",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al-Hariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3212",
    "CITYNAME_AR": "الخريص",
    "CITYNAME_EN": "Al-Kharis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3213",
    "CITYNAME_AR": "روينه",
    "CITYNAME_EN": "Ruwaynah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3214",
    "CITYNAME_AR": "وادى بشران",
    "CITYNAME_EN": "Wadi Bshran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3215",
    "CITYNAME_AR": "الجمار",
    "CITYNAME_EN": "Al-Jmaar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3216",
    "CITYNAME_AR": "تكامه",
    "CITYNAME_EN": "Tokamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3217",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al-Hankah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3218",
    "CITYNAME_AR": "المدب",
    "CITYNAME_EN": "Al-Madeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3219",
    "CITYNAME_AR": "الجارتين",
    "CITYNAME_EN": "Al-Jaratayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3220",
    "CITYNAME_AR": "شعب السو ده",
    "CITYNAME_EN": "Shi'b Al-Sudah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3221",
    "CITYNAME_AR": "بنى الحوارى",
    "CITYNAME_EN": "Bni Al-Hawary ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3222",
    "CITYNAME_AR": "المنطاس",
    "CITYNAME_EN": "Al-Mentas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3223",
    "CITYNAME_AR": "المليح",
    "CITYNAME_EN": "Al-Malih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3224",
    "CITYNAME_AR": "مصبح",
    "CITYNAME_EN": "Misbih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3225",
    "CITYNAME_AR": "ام قرض",
    "CITYNAME_EN": "Umm Qard ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3226",
    "CITYNAME_AR": "ام عطفه",
    "CITYNAME_EN": "Umm Atfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3227",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3228",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3229",
    "CITYNAME_AR": "النميص",
    "CITYNAME_EN": "Al-Names",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3230",
    "CITYNAME_AR": "ام راكه",
    "CITYNAME_EN": "Umm Rakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3231",
    "CITYNAME_AR": "ابوعوشزه",
    "CITYNAME_EN": "Abu Oshzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3232",
    "CITYNAME_AR": "الحضيره (حضر وادى بن نشوان)",
    "CITYNAME_EN": "Al-Hadirah (Hadr Wadi bin Nashwan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3233",
    "CITYNAME_AR": "العرجه",
    "CITYNAME_EN": "Al-Arjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3234",
    "CITYNAME_AR": "محضه",
    "CITYNAME_EN": "Mahdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3235",
    "CITYNAME_AR": "نجد الحيد",
    "CITYNAME_EN": "Najd Al-Hied ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3236",
    "CITYNAME_AR": "رغد",
    "CITYNAME_EN": "Raghd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3237",
    "CITYNAME_AR": "الاجباب",
    "CITYNAME_EN": "Al-Ajbab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3238",
    "CITYNAME_AR": "مخطط العسران",
    "CITYNAME_EN": "Mukhatt Al-Asran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3239",
    "CITYNAME_AR": "ال بوعريج",
    "CITYNAME_EN": "Al Bu'erij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3240",
    "CITYNAME_AR": "ال قير",
    "CITYNAME_EN": "Al Qeer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3241",
    "CITYNAME_AR": "ال سرار",
    "CITYNAME_EN": "Al-Serar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3242",
    "CITYNAME_AR": "ال هميل",
    "CITYNAME_EN": "Al-Hamil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3243",
    "CITYNAME_AR": "ال عذيبه",
    "CITYNAME_EN": "Al-Ozaybah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3244",
    "CITYNAME_AR": "ال عواه",
    "CITYNAME_EN": "Al-Awah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3245",
    "CITYNAME_AR": "آل بي حبيب",
    "CITYNAME_EN": "Al Bi Habib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3246",
    "CITYNAME_AR": "ال بلحى",
    "CITYNAME_EN": "Al Belhy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3247",
    "CITYNAME_AR": "مخطط ال بلحي",
    "CITYNAME_EN": "Mukhatat Al Belhy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3248",
    "CITYNAME_AR": "الحرقان",
    "CITYNAME_EN": "Al-Harqan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3249",
    "CITYNAME_AR": "آل قراش",
    "CITYNAME_EN": "Al-Qerash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3250",
    "CITYNAME_AR": "الحقباء (الجعامله)",
    "CITYNAME_EN": "Al-Haqbaa (Al-Ja'amelah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3251",
    "CITYNAME_AR": "المثناه",
    "CITYNAME_EN": "Al  Mathnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3252",
    "CITYNAME_AR": "ال صعيب",
    "CITYNAME_EN": "Al-Sa'ib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3253",
    "CITYNAME_AR": "الثعلبه",
    "CITYNAME_EN": "Al-Tha'labah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3254",
    "CITYNAME_AR": "المطاوقه",
    "CITYNAME_EN": "Al-Matawiqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3255",
    "CITYNAME_AR": "ال درويش",
    "CITYNAME_EN": "Al-Darwish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3256",
    "CITYNAME_AR": "المسافره",
    "CITYNAME_EN": "Al-Mosaferah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3257",
    "CITYNAME_AR": "المحافيظ",
    "CITYNAME_EN": "Al-Mahafiz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3258",
    "CITYNAME_AR": "ال عازب",
    "CITYNAME_EN": "Al Azib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3259",
    "CITYNAME_AR": "ال سارعه (ال فايع)",
    "CITYNAME_EN": "Al Sare'ah (Al fayi')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3260",
    "CITYNAME_AR": "حماله",
    "CITYNAME_EN": "Hamalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3261",
    "CITYNAME_AR": "سبت بنى بشر",
    "CITYNAME_EN": "Sabt Bani Beshr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3262",
    "CITYNAME_AR": "ال فرحان",
    "CITYNAME_EN": "Al Farhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3263",
    "CITYNAME_AR": "ال الظهر",
    "CITYNAME_EN": "Al Al-Zahir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3264",
    "CITYNAME_AR": "ال الشتاء",
    "CITYNAME_EN": "Al Al-Shetaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3265",
    "CITYNAME_AR": "شبيرمه",
    "CITYNAME_EN": "Shubayrimah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3266",
    "CITYNAME_AR": "مثاب",
    "CITYNAME_EN": "Mathab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3267",
    "CITYNAME_AR": "درامه",
    "CITYNAME_EN": "Deramah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3268",
    "CITYNAME_AR": "آل عبيديه",
    "CITYNAME_EN": "Al Obaydiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3269",
    "CITYNAME_AR": "ال مشرف",
    "CITYNAME_EN": "Al Meshrif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3270",
    "CITYNAME_AR": "الفرع ( جابر ال حراشي)",
    "CITYNAME_EN": "Al Harashy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3271",
    "CITYNAME_AR": "ال حراشي",
    "CITYNAME_EN": "Al-far' (Jaber Al Herashi)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3272",
    "CITYNAME_AR": "ال عاطف",
    "CITYNAME_EN": "Al Atif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3273",
    "CITYNAME_AR": "الوسط",
    "CITYNAME_EN": "Al Wasat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3274",
    "CITYNAME_AR": "ال عبيس",
    "CITYNAME_EN": "Al Abis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3275",
    "CITYNAME_AR": "ال طفيله وال شيبان",
    "CITYNAME_EN": "Al tofaylah and Al Shayban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3276",
    "CITYNAME_AR": "ال حافظ  (ال الجلد)",
    "CITYNAME_EN": "Al hafiz (Al Al-Jeld)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3277",
    "CITYNAME_AR": "الحشي والقطعاء",
    "CITYNAME_EN": "Al-Heshy and Al-Qat'aa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3278",
    "CITYNAME_AR": "العرابه",
    "CITYNAME_EN": "Al-Arabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3279",
    "CITYNAME_AR": "ال حليفه",
    "CITYNAME_EN": "Al-Halifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3280",
    "CITYNAME_AR": "ال الخلف",
    "CITYNAME_EN": "Al Al-Khalf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3281",
    "CITYNAME_AR": "الجوبه",
    "CITYNAME_EN": "Al-Jawiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3282",
    "CITYNAME_AR": "ال نمر",
    "CITYNAME_EN": "Al Nemr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3283",
    "CITYNAME_AR": "الجهمه",
    "CITYNAME_EN": "Al-Jahmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3284",
    "CITYNAME_AR": "السره",
    "CITYNAME_EN": "Al-Serah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3285",
    "CITYNAME_AR": "ال الخام وال لزرق",
    "CITYNAME_EN": "Al-Kham and Al Lzraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3286",
    "CITYNAME_AR": "الدرب",
    "CITYNAME_EN": "Ad Darb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3287",
    "CITYNAME_AR": "مركز عين اللوي (العرقوب)",
    "CITYNAME_EN": "Al-Arqub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3288",
    "CITYNAME_AR": "الحامض",
    "CITYNAME_EN": "Alhamid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3289",
    "CITYNAME_AR": "راحة آل ثواب",
    "CITYNAME_EN": "Raht Al Thawab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3295",
    "CITYNAME_AR": "عقله الصقور",
    "CITYNAME_EN": "Eaqalah Alsuqur",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "3336",
    "CITYNAME_AR": "فيد",
    "CITYNAME_EN": "Fyd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3337",
    "CITYNAME_AR": "الثعيلبي",
    "CITYNAME_EN": "Althaeaylabiu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3338",
    "CITYNAME_AR": "بدايع ام هروج",
    "CITYNAME_EN": "Bdayie Umm Hruj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3339",
    "CITYNAME_AR": "تخاييل",
    "CITYNAME_EN": "Takhayil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3340",
    "CITYNAME_AR": "الابيتر",
    "CITYNAME_EN": "Alabytr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3341",
    "CITYNAME_AR": "الصفياء",
    "CITYNAME_EN": "Alsifya'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3342",
    "CITYNAME_AR": "الصغوى",
    "CITYNAME_EN": "Alsaghwaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3343",
    "CITYNAME_AR": "بدايع الصغوى",
    "CITYNAME_EN": "Badayie Alsaghwaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3344",
    "CITYNAME_AR": "الشرثه",
    "CITYNAME_EN": "Alsharathuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3345",
    "CITYNAME_AR": "رميثان   شريثان",
    "CITYNAME_EN": "Ramithan Shrithan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3346",
    "CITYNAME_AR": "الدويه",
    "CITYNAME_EN": "Aldawayuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3375",
    "CITYNAME_AR": "الشنان",
    "CITYNAME_EN": "Alshannan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3376",
    "CITYNAME_AR": "المنصورية",
    "CITYNAME_EN": "Almansuria",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3377",
    "CITYNAME_AR": "طابه",
    "CITYNAME_EN": "Tabah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3378",
    "CITYNAME_AR": "ابضه",
    "CITYNAME_EN": "Abdih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3379",
    "CITYNAME_AR": "بدايع ابضه",
    "CITYNAME_EN": "Badayie Abdh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3380",
    "CITYNAME_AR": "العدوه",
    "CITYNAME_EN": "Aleuduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3381",
    "CITYNAME_AR": "اللويش",
    "CITYNAME_EN": "Alluwaysh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3382",
    "CITYNAME_AR": "الساقيه",
    "CITYNAME_EN": "Alsaaqih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3383",
    "CITYNAME_AR": "رك",
    "CITYNAME_EN": "Rak",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3384",
    "CITYNAME_AR": "علقة",
    "CITYNAME_EN": "Ealaqa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3385",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Alraahuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3386",
    "CITYNAME_AR": "سبيعه",
    "CITYNAME_EN": "Sbyeh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3387",
    "CITYNAME_AR": "البطين",
    "CITYNAME_EN": "Albatin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3388",
    "CITYNAME_AR": "الخوى",
    "CITYNAME_EN": "Alkhuaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3389",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "Alsalil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3390",
    "CITYNAME_AR": "الخلصه",
    "CITYNAME_EN": "Alkhalasuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3391",
    "CITYNAME_AR": "مبرز",
    "CITYNAME_EN": "Mubriz",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3392",
    "CITYNAME_AR": "النعى",
    "CITYNAME_EN": "Alnueaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3393",
    "CITYNAME_AR": "الجحفه",
    "CITYNAME_EN": "Aljahfuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3394",
    "CITYNAME_AR": "البياضي",
    "CITYNAME_EN": "Albiadi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3395",
    "CITYNAME_AR": "ثرل",
    "CITYNAME_EN": "Thurl",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3396",
    "CITYNAME_AR": "الشقيق",
    "CITYNAME_EN": "Alshaqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3397",
    "CITYNAME_AR": "فرشه الشقيق",
    "CITYNAME_EN": "Farashah Alshaqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3398",
    "CITYNAME_AR": "قاع الهلالى",
    "CITYNAME_EN": "Qae Alhlala",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3399",
    "CITYNAME_AR": "السبعان",
    "CITYNAME_EN": "Alsbean",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3400",
    "CITYNAME_AR": "اكبره",
    "CITYNAME_EN": "Akbrh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3401",
    "CITYNAME_AR": "ام حبوس",
    "CITYNAME_EN": "Umm Hubus",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3402",
    "CITYNAME_AR": "القدهي",
    "CITYNAME_EN": "Aldhibabuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3403",
    "CITYNAME_AR": "بدايع السبعان",
    "CITYNAME_EN": "Badayie Alsbean",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3404",
    "CITYNAME_AR": "ذيخين",
    "CITYNAME_EN": "Dhikhin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3405",
    "CITYNAME_AR": "المطرفيه",
    "CITYNAME_EN": "Almutrifih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3406",
    "CITYNAME_AR": "الارشاويه",
    "CITYNAME_EN": "Alarshawyh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3407",
    "CITYNAME_AR": "القاعيه",
    "CITYNAME_EN": "Alqaeih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3408",
    "CITYNAME_AR": "اشبيريه الصفراء",
    "CITYNAME_EN": "Ashbirih Alsafra'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3409",
    "CITYNAME_AR": "اشبيريه الحمراء",
    "CITYNAME_EN": "Ashbirih Alhamra'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3410",
    "CITYNAME_AR": "البلازيه",
    "CITYNAME_EN": "Albalaziuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3411",
    "CITYNAME_AR": "ام نشاب",
    "CITYNAME_EN": "Umm Nashab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3412",
    "CITYNAME_AR": "رس  جبرين",
    "CITYNAME_EN": "Ras Jabarin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3413",
    "CITYNAME_AR": "بدائع أول",
    "CITYNAME_EN": "Bdayie 'Awal",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3414",
    "CITYNAME_AR": "الغلفا",
    "CITYNAME_EN": "Alghalafa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3415",
    "CITYNAME_AR": "الاشهب",
    "CITYNAME_EN": "Alashhab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3416",
    "CITYNAME_AR": "الغزاله",
    "CITYNAME_EN": "Alghizaluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3417",
    "CITYNAME_AR": "ابو مزحامه",
    "CITYNAME_EN": "'Abu Mizhamah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3418",
    "CITYNAME_AR": "البرديه",
    "CITYNAME_EN": "Albardiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3419",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Alrayighuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3420",
    "CITYNAME_AR": "البنانه",
    "CITYNAME_EN": "Albananuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3421",
    "CITYNAME_AR": "الضرسى",
    "CITYNAME_EN": "Aldursaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3422",
    "CITYNAME_AR": "الصلعاء",
    "CITYNAME_EN": "Alsulea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3423",
    "CITYNAME_AR": "المهاش",
    "CITYNAME_EN": "Almahash",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3424",
    "CITYNAME_AR": "القصير",
    "CITYNAME_EN": "Alqasir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3425",
    "CITYNAME_AR": "ريع البكر",
    "CITYNAME_EN": "Raye Albikr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3426",
    "CITYNAME_AR": "فرشه الكان",
    "CITYNAME_EN": "Farashah Alkan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3427",
    "CITYNAME_AR": "بدائع البكر",
    "CITYNAME_EN": "Badayie Al'Abyad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3428",
    "CITYNAME_AR": "الصداعيه",
    "CITYNAME_EN": "Alsadaeiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3429",
    "CITYNAME_AR": "بدائع العليا",
    "CITYNAME_EN": "Badayie Aleulya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3430",
    "CITYNAME_AR": "بدائع المحمى",
    "CITYNAME_EN": "Bdayie Almuhamaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3431",
    "CITYNAME_AR": "المحمى   ريع المحمي",
    "CITYNAME_EN": "Almahmaa Raye Almahamiy",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3432",
    "CITYNAME_AR": "المعترضه",
    "CITYNAME_EN": "Almuetaraduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3433",
    "CITYNAME_AR": "احيمرات الجبا",
    "CITYNAME_EN": "Ahymarat Aljabba",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3434",
    "CITYNAME_AR": "الهويدى",
    "CITYNAME_EN": "Alhuaydaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3435",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Aleaqiluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3436",
    "CITYNAME_AR": "صفيان",
    "CITYNAME_EN": "Sifyan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3437",
    "CITYNAME_AR": "المبيدع",
    "CITYNAME_EN": "Almubydae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3438",
    "CITYNAME_AR": "ام ارطاه",
    "CITYNAME_EN": "'Am Artah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3439",
    "CITYNAME_AR": "سقف",
    "CITYNAME_EN": "Saqf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3440",
    "CITYNAME_AR": "بدائع سقف",
    "CITYNAME_EN": "Badayie Saqf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3441",
    "CITYNAME_AR": "ارطاوى",
    "CITYNAME_EN": "Artawaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3442",
    "CITYNAME_AR": "المسجد",
    "CITYNAME_EN": "Almasjid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3443",
    "CITYNAME_AR": "رويع الخبار",
    "CITYNAME_EN": "Rawaye Alkhabar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3444",
    "CITYNAME_AR": "القصيصه والقصة",
    "CITYNAME_EN": "Alqasisuh Walqisa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3445",
    "CITYNAME_AR": "بدائع الصباحه",
    "CITYNAME_EN": "Badayie Alsabahih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3446",
    "CITYNAME_AR": "الركيه",
    "CITYNAME_EN": "Alrakih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3447",
    "CITYNAME_AR": "الشغوه",
    "CITYNAME_EN": "Alshughuwh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3448",
    "CITYNAME_AR": "حويان",
    "CITYNAME_EN": "Huayan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3449",
    "CITYNAME_AR": "هجره فرتاج",
    "CITYNAME_EN": "Hajaruh Firtaj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3450",
    "CITYNAME_AR": "الوهيبيه",
    "CITYNAME_EN": "Alwahibayuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3451",
    "CITYNAME_AR": "المستجده",
    "CITYNAME_EN": "Almustajiduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3452",
    "CITYNAME_AR": "التايع",
    "CITYNAME_EN": "Alttayie",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3453",
    "CITYNAME_AR": "دقيه",
    "CITYNAME_EN": "Daqih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3454",
    "CITYNAME_AR": "شعيب المستجده",
    "CITYNAME_EN": "Shueayb Almustajidih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3455",
    "CITYNAME_AR": "بدع بن حويط",
    "CITYNAME_EN": "Badae Bin Hwayt",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3456",
    "CITYNAME_AR": "الجفره",
    "CITYNAME_EN": "Aljifruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3457",
    "CITYNAME_AR": "الابيتر",
    "CITYNAME_EN": "Alabytr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3458",
    "CITYNAME_AR": "السليمي",
    "CITYNAME_EN": "Alsalimi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3459",
    "CITYNAME_AR": "القحصيه",
    "CITYNAME_EN": "Alqahsayuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3460",
    "CITYNAME_AR": "مزعله",
    "CITYNAME_EN": "Mazealuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3461",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "Alsafara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3462",
    "CITYNAME_AR": "المنجوري",
    "CITYNAME_EN": "Almanjuriu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3463",
    "CITYNAME_AR": "ام عماير",
    "CITYNAME_EN": "Umm Eamayr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3464",
    "CITYNAME_AR": "بدائع السليمى",
    "CITYNAME_EN": "Bdayie Alsulimaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3465",
    "CITYNAME_AR": "بدائع قني",
    "CITYNAME_EN": "Bdayie Qani",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3466",
    "CITYNAME_AR": "البعايث",
    "CITYNAME_EN": "Albeayth",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3467",
    "CITYNAME_AR": "الكسر",
    "CITYNAME_EN": "Alkasr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3468",
    "CITYNAME_AR": "بدائع البعايث",
    "CITYNAME_EN": "Bdayie Albeayth",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3469",
    "CITYNAME_AR": "طلقه",
    "CITYNAME_EN": "Talaqah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3470",
    "CITYNAME_AR": "الزغيبيه",
    "CITYNAME_EN": "Alzughibiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3471",
    "CITYNAME_AR": "فيضه العبل",
    "CITYNAME_EN": "Fiduh Aleabl",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3472",
    "CITYNAME_AR": "الحموديه",
    "CITYNAME_EN": "Alhumudiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3473",
    "CITYNAME_AR": "فيضة السمار",
    "CITYNAME_EN": "Fidat Alsamar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3474",
    "CITYNAME_AR": "مران",
    "CITYNAME_EN": "Maran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3475",
    "CITYNAME_AR": "ثعلب",
    "CITYNAME_EN": "Thaelab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3476",
    "CITYNAME_AR": "بطيحان",
    "CITYNAME_EN": "Batihan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3477",
    "CITYNAME_AR": "النباء",
    "CITYNAME_EN": "Alnaba'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3478",
    "CITYNAME_AR": "النحيتيه",
    "CITYNAME_EN": "Alnahiatih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3479",
    "CITYNAME_AR": "بدائع المسمى",
    "CITYNAME_EN": "Bdayie Almusamaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3480",
    "CITYNAME_AR": "ابو زريبه",
    "CITYNAME_EN": "'Abu Zaribh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3481",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Masharifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3482",
    "CITYNAME_AR": "مطلق",
    "CITYNAME_EN": "Mutlaq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3483",
    "CITYNAME_AR": "مراغان الجديد",
    "CITYNAME_EN": "Maraghan Aljadid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3484",
    "CITYNAME_AR": "الاسمره",
    "CITYNAME_EN": "Alasmiruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3485",
    "CITYNAME_AR": "القاسميه",
    "CITYNAME_EN": "Alqasimih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3486",
    "CITYNAME_AR": "بدائع السواويد",
    "CITYNAME_EN": "Badayie Alswawyd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3487",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Alsaalihih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3488",
    "CITYNAME_AR": "السويعدى",
    "CITYNAME_EN": "Alsuwyuedaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3489",
    "CITYNAME_AR": "الدهيماء",
    "CITYNAME_EN": "Aldhima'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3490",
    "CITYNAME_AR": "العميرية",
    "CITYNAME_EN": "Aleamiria",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3491",
    "CITYNAME_AR": "مراغان القديم",
    "CITYNAME_EN": "Muraghan Alqadim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3492",
    "CITYNAME_AR": "دبى",
    "CITYNAME_EN": "Dabaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3493",
    "CITYNAME_AR": "الهمجه",
    "CITYNAME_EN": "Alhamajuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3494",
    "CITYNAME_AR": "المعاذب",
    "CITYNAME_EN": "Almaeadhib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3495",
    "CITYNAME_AR": "الهديه",
    "CITYNAME_EN": "Alhadih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3496",
    "CITYNAME_AR": "دحله الجوازى",
    "CITYNAME_EN": "Dahlah Aljwaza",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3497",
    "CITYNAME_AR": "بدائع الحامريه   الحامريه",
    "CITYNAME_EN": "Badayie Alhamirih Alhamirih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3498",
    "CITYNAME_AR": "ابار وسمه",
    "CITYNAME_EN": "Abar Wasamah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3499",
    "CITYNAME_AR": "الوسيطاء",
    "CITYNAME_EN": "Alwasita'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3500",
    "CITYNAME_AR": "بدائع فايد بن سالم المثواةالجديده",
    "CITYNAME_EN": "Bdayie Fayd Bin Salim Almthwataljdydh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3501",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Alquayeiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3502",
    "CITYNAME_AR": "بدائع مراغان",
    "CITYNAME_EN": "Badayie Maraghan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3503",
    "CITYNAME_AR": "الناصفة",
    "CITYNAME_EN": "Alnaasifa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3504",
    "CITYNAME_AR": "قاع حجلاء",
    "CITYNAME_EN": "Qae Hujala'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3505",
    "CITYNAME_AR": "العجاجة",
    "CITYNAME_EN": "Aleijaja",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3506",
    "CITYNAME_AR": "ذيبان",
    "CITYNAME_EN": "Dhiban",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3507",
    "CITYNAME_AR": "مشاش ريحان",
    "CITYNAME_EN": "Mashash Rayhan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3508",
    "CITYNAME_AR": "ابو ريه",
    "CITYNAME_EN": "'Abu Ryh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3509",
    "CITYNAME_AR": "مشاش جاوان",
    "CITYNAME_EN": "Mashash Jawan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3510",
    "CITYNAME_AR": "الغريض",
    "CITYNAME_EN": "Algharid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3511",
    "CITYNAME_AR": "الثماميه",
    "CITYNAME_EN": "Althamamih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3512",
    "CITYNAME_AR": "بسيطاء",
    "CITYNAME_EN": "Basita'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3513",
    "CITYNAME_AR": "البركه",
    "CITYNAME_EN": "Albarkuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3514",
    "CITYNAME_AR": "ابو رضام",
    "CITYNAME_EN": "'Abu Radam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3515",
    "CITYNAME_AR": "الذكرى",
    "CITYNAME_EN": "Aldhikraa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3516",
    "CITYNAME_AR": "روضه البعايث",
    "CITYNAME_EN": "Ruduh Albieayith",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3517",
    "CITYNAME_AR": "الحماد",
    "CITYNAME_EN": "Alhamad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3518",
    "CITYNAME_AR": "الطرفاوى",
    "CITYNAME_EN": "Altrfawa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3519",
    "CITYNAME_AR": "الحامر",
    "CITYNAME_EN": "Al-Hamer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3520",
    "CITYNAME_AR": "الغبر الاسفل",
    "CITYNAME_EN": "Al-Ghabr Al-Asfal ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3521",
    "CITYNAME_AR": "الحبيس",
    "CITYNAME_EN": "Al-Habis",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3522",
    "CITYNAME_AR": "الزاويه",
    "CITYNAME_EN": "Al-Zawyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3523",
    "CITYNAME_AR": "الظفير",
    "CITYNAME_EN": "Al-Zafir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3524",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadb",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3525",
    "CITYNAME_AR": "بني والبة",
    "CITYNAME_EN": "Bni Walbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3526",
    "CITYNAME_AR": "الدهامشه",
    "CITYNAME_EN": "Al-Dahamshah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3527",
    "CITYNAME_AR": "العبادل",
    "CITYNAME_EN": "Al-Abadel ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3528",
    "CITYNAME_AR": "القليته",
    "CITYNAME_EN": "Al-Qalitah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3529",
    "CITYNAME_AR": "المسفر",
    "CITYNAME_EN": "Al-Mesfer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3530",
    "CITYNAME_AR": "الحيض",
    "CITYNAME_EN": "Al-Hayd",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3531",
    "CITYNAME_AR": "الكدفه",
    "CITYNAME_EN": "Al-Kadfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3532",
    "CITYNAME_AR": "المشاطيه",
    "CITYNAME_EN": "Al-Mashaytah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3533",
    "CITYNAME_AR": "السيار",
    "CITYNAME_EN": "Al-Seyar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3534",
    "CITYNAME_AR": "الغران",
    "CITYNAME_EN": "Al-Gharan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3535",
    "CITYNAME_AR": "الاعراش",
    "CITYNAME_EN": "Al-A'rash ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3536",
    "CITYNAME_AR": "الفلاح",
    "CITYNAME_EN": "Al-Fallah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3537",
    "CITYNAME_AR": "العصفره وآل مضحي",
    "CITYNAME_EN": "Al-Osforah and Al-Madhi",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3538",
    "CITYNAME_AR": "المزرعه",
    "CITYNAME_EN": "Al-Mazra'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3539",
    "CITYNAME_AR": "النعيم",
    "CITYNAME_EN": "Al-Na'im ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3540",
    "CITYNAME_AR": "ال مرزوق",
    "CITYNAME_EN": "Al Marzouq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3541",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzil",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3542",
    "CITYNAME_AR": "الساق والغربه",
    "CITYNAME_EN": "Al-Saq and AL-Ghorbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3543",
    "CITYNAME_AR": "صخوان",
    "CITYNAME_EN": "Sakhwan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3544",
    "CITYNAME_AR": "الرهوه والسحيمه",
    "CITYNAME_EN": "Al-Rahwah and Al-Sahimah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3545",
    "CITYNAME_AR": "جناب ال داهش",
    "CITYNAME_EN": "Janab Al Dahesh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3546",
    "CITYNAME_AR": "مشروع اسكان الوليد",
    "CITYNAME_EN": "Al-Walid Housing Project ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3547",
    "CITYNAME_AR": "جرد",
    "CITYNAME_EN": " Jerd",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3548",
    "CITYNAME_AR": "الظليف وضواحيها",
    "CITYNAME_EN": "Al-Zalif and the surrounding districts ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3549",
    "CITYNAME_AR": "كحلة",
    "CITYNAME_EN": "Kahla",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3550",
    "CITYNAME_AR": "الفرزة",
    "CITYNAME_EN": "Al-Farzah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3551",
    "CITYNAME_AR": "حلود",
    "CITYNAME_EN": "halud ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3552",
    "CITYNAME_AR": "الجنابين",
    "CITYNAME_EN": "Al Janabin",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3553",
    "CITYNAME_AR": "بني كبير",
    "CITYNAME_EN": "Bani Kabir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3554",
    "CITYNAME_AR": "بلجرشى",
    "CITYNAME_EN": "Baljurashi",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3555",
    "CITYNAME_AR": "الغبر الاعلى",
    "CITYNAME_EN": "Al-Ghabr Al-A'la ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3556",
    "CITYNAME_AR": "ال سالم",
    "CITYNAME_EN": "Al Salem",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3557",
    "CITYNAME_AR": "ال سرور",
    "CITYNAME_EN": "Al Sorour ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3558",
    "CITYNAME_AR": "ال حميد (باعج حمام)",
    "CITYNAME_EN": "Al Hamid (Ba'ij Hamam)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3559",
    "CITYNAME_AR": "ال زارع",
    "CITYNAME_EN": "Al Zari'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3560",
    "CITYNAME_AR": "المرباه",
    "CITYNAME_EN": "Al-Merbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3561",
    "CITYNAME_AR": "الجحافين",
    "CITYNAME_EN": "Al-Jahafin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3562",
    "CITYNAME_AR": "الفريه",
    "CITYNAME_EN": "Al-Feryah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3563",
    "CITYNAME_AR": "الفرح",
    "CITYNAME_EN": "Al-Farah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3564",
    "CITYNAME_AR": "القمع",
    "CITYNAME_EN": "Al-Qom'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3565",
    "CITYNAME_AR": "بنى دكه",
    "CITYNAME_EN": "Bni Dekkah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3566",
    "CITYNAME_AR": "الازاهره",
    "CITYNAME_EN": "Al-Zaherah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3567",
    "CITYNAME_AR": "قذانه",
    "CITYNAME_EN": "Qazanah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3568",
    "CITYNAME_AR": "حواله",
    "CITYNAME_EN": "Hawalah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3569",
    "CITYNAME_AR": "دارالسوق",
    "CITYNAME_EN": "Dar Al-Souq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3570",
    "CITYNAME_AR": "حادله",
    "CITYNAME_EN": "Hadlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3571",
    "CITYNAME_AR": "الحبقه",
    "CITYNAME_EN": "Al-Habqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3572",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3573",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3574",
    "CITYNAME_AR": "قمهده",
    "CITYNAME_EN": "Qamhadah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3575",
    "CITYNAME_AR": "قرى",
    "CITYNAME_EN": "Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3576",
    "CITYNAME_AR": "حله قلتان",
    "CITYNAME_EN": "Hellah Qaltan  ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3577",
    "CITYNAME_AR": "حله الصله",
    "CITYNAME_EN": "Hellah Al-Selah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3578",
    "CITYNAME_AR": "حلةالثبي",
    "CITYNAME_EN": "Hellah Al-Thaby ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3579",
    "CITYNAME_AR": "ماطوه",
    "CITYNAME_EN": "Matwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3580",
    "CITYNAME_AR": "العجيجة والاسنام",
    "CITYNAME_EN": "Al-Ajijah and Al-Asnam ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3581",
    "CITYNAME_AR": "البطين",
    "CITYNAME_EN": "Albatin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "3582",
    "CITYNAME_AR": "الطرفية الشرقية",
    "CITYNAME_EN": "Altarafiat Alsharqia",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "3583",
    "CITYNAME_AR": "الرهوان",
    "CITYNAME_EN": "Ar Rahwan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3584",
    "CITYNAME_AR": "المليحه السفلي",
    "CITYNAME_EN": "Al-Maliha (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3585",
    "CITYNAME_AR": "المصانعه",
    "CITYNAME_EN": "Al-Masan'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3586",
    "CITYNAME_AR": "ال عاطف وقرى جمان والغانم وهوشه",
    "CITYNAME_EN": "Al Atef and Jaman, Al-Ghanim and Hushah Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3587",
    "CITYNAME_AR": "السلته وال بن غالية",
    "CITYNAME_EN": "Al-Seltah and Al bin Ghalyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3588",
    "CITYNAME_AR": "المعامره والعرق والصعب وال فرحان",
    "CITYNAME_EN": "Al-Ma'amrah, Al-Irq, Al'Asb and Al farhan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3589",
    "CITYNAME_AR": "منشله",
    "CITYNAME_EN": "Manshelah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3590",
    "CITYNAME_AR": "جبيله والمقعده",
    "CITYNAME_EN": "Jabilah and Al-Maq'adah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3591",
    "CITYNAME_AR": "ضهايا وال سعيدان",
    "CITYNAME_EN": "Dahayah and Al-Sa'idan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3592",
    "CITYNAME_AR": "الفقهاء والرتوع",
    "CITYNAME_EN": "Al-Foqhaa and Al-Ratu'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3593",
    "CITYNAME_AR": "القنفذه والقرانه",
    "CITYNAME_EN": "Al-Qonfozah and AL-Qaranah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3594",
    "CITYNAME_AR": "الترس",
    "CITYNAME_EN": "Al-Ters ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3595",
    "CITYNAME_AR": "الحواه",
    "CITYNAME_EN": "Al-Huwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3596",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Al-Qa'bah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3597",
    "CITYNAME_AR": "الهتافره وقحزه",
    "CITYNAME_EN": "Al-Hatafrah and Qahzah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3598",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3599",
    "CITYNAME_AR": "حماده والموبل",
    "CITYNAME_EN": "hamdah and Al-Muel ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3600",
    "CITYNAME_AR": "ال سحاب",
    "CITYNAME_EN": "Al Sahab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3601",
    "CITYNAME_AR": "المليحه العليا",
    "CITYNAME_EN": "Al-Malihah (the Upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3602",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Al-Rahwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3603",
    "CITYNAME_AR": "الحشو",
    "CITYNAME_EN": "Al-Hashw",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3604",
    "CITYNAME_AR": "المخواه",
    "CITYNAME_EN": "Al-Makhwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3605",
    "CITYNAME_AR": "السحره والمجرد والقمره",
    "CITYNAME_EN": "Al-Sehrah, Al-Mejred and Al-Qomrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3606",
    "CITYNAME_AR": "الشعبه والتوبه",
    "CITYNAME_EN": "Al-She'bah and Al-Tubah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3607",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Al-Hajrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3608",
    "CITYNAME_AR": "مندسه",
    "CITYNAME_EN": "Mandasah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3609",
    "CITYNAME_AR": "المهاديه",
    "CITYNAME_EN": "Al-Mahadyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3610",
    "CITYNAME_AR": "سلطانه",
    "CITYNAME_EN": "Sultana",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3611",
    "CITYNAME_AR": "الطنف",
    "CITYNAME_EN": "Al-Tanf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3612",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badiyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3613",
    "CITYNAME_AR": "غامد الزناد",
    "CITYNAME_EN": "Ghamid Alzinad",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3614",
    "CITYNAME_AR": "الوريفح",
    "CITYNAME_EN": "Al Warifeh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3615",
    "CITYNAME_AR": "الحوايا الحداب",
    "CITYNAME_EN": "Al-Hawaya Al-Hedab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3616",
    "CITYNAME_AR": "الاحد",
    "CITYNAME_EN": "Al-Ahd ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3617",
    "CITYNAME_AR": "الشقيب",
    "CITYNAME_EN": "Al-Shaqib",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3618",
    "CITYNAME_AR": "ريعان",
    "CITYNAME_EN": "Ri'an ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3619",
    "CITYNAME_AR": "الجديفه (الثلاثاء)",
    "CITYNAME_EN": "Al-Jadifah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3620",
    "CITYNAME_AR": "شعب خلف",
    "CITYNAME_EN": "She'b Khalaf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3621",
    "CITYNAME_AR": "الظليف",
    "CITYNAME_EN": "Al-Zalif",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3622",
    "CITYNAME_AR": "الضبر الاعلى",
    "CITYNAME_EN": "Al-Dabr (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3623",
    "CITYNAME_AR": "افه",
    "CITYNAME_EN": "Afa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3624",
    "CITYNAME_AR": "حلحله السفلى",
    "CITYNAME_EN": "Halhalah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3625",
    "CITYNAME_AR": "وادى اعماق(موقع عام)",
    "CITYNAME_EN": "Wadi A'm aq(Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3626",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3627",
    "CITYNAME_AR": "السمير",
    "CITYNAME_EN": "Al-Samir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3628",
    "CITYNAME_AR": "قرى مجذوم",
    "CITYNAME_EN": "Qora Majzum ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3629",
    "CITYNAME_AR": "اعماق",
    "CITYNAME_EN": "A'maq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3630",
    "CITYNAME_AR": "حتى",
    "CITYNAME_EN": "Hattah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3631",
    "CITYNAME_AR": "الزبده",
    "CITYNAME_EN": "Al-Zidah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3632",
    "CITYNAME_AR": "وادى لبوه",
    "CITYNAME_EN": "Wadi labwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3633",
    "CITYNAME_AR": "لبوة السفلى",
    "CITYNAME_EN": "Labwah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3634",
    "CITYNAME_AR": "سيلات",
    "CITYNAME_EN": "Sielat",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3635",
    "CITYNAME_AR": "سيال",
    "CITYNAME_EN": "Sayal ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3636",
    "CITYNAME_AR": "ماطوه",
    "CITYNAME_EN": "Matwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3637",
    "CITYNAME_AR": "ضبيعه",
    "CITYNAME_EN": "Debyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3638",
    "CITYNAME_AR": "مثيب",
    "CITYNAME_EN": "Mathib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3639",
    "CITYNAME_AR": "الطوق",
    "CITYNAME_EN": "Al-Tawq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3640",
    "CITYNAME_AR": "قراشع",
    "CITYNAME_EN": "Qarashi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3641",
    "CITYNAME_AR": "الفيض",
    "CITYNAME_EN": "Al Faydh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3642",
    "CITYNAME_AR": "وادي نصبه",
    "CITYNAME_EN": "Wadi nasbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3643",
    "CITYNAME_AR": "مركوب",
    "CITYNAME_EN": "Markub ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3644",
    "CITYNAME_AR": "الايكه",
    "CITYNAME_EN": "Al-Aykah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3645",
    "CITYNAME_AR": "الجرازة",
    "CITYNAME_EN": "Al-Jarazah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3646",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Qa'rah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3647",
    "CITYNAME_AR": "القري",
    "CITYNAME_EN": "Al-Qari'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3648",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Al Badi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3649",
    "CITYNAME_AR": "الرهي",
    "CITYNAME_EN": "Al-Rahy",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3650",
    "CITYNAME_AR": "الزغل",
    "CITYNAME_EN": "Al Zaghl",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3651",
    "CITYNAME_AR": "الوزر",
    "CITYNAME_EN": "Al-Wazir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3652",
    "CITYNAME_AR": "خبه",
    "CITYNAME_EN": "Khabbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3653",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3654",
    "CITYNAME_AR": "الكري",
    "CITYNAME_EN": "Al Kary",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3655",
    "CITYNAME_AR": "واسعه",
    "CITYNAME_EN": "Was'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3656",
    "CITYNAME_AR": "الستار",
    "CITYNAME_EN": "Al-Setar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3657",
    "CITYNAME_AR": "حلحله",
    "CITYNAME_EN": "Halhalah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3658",
    "CITYNAME_AR": "الحمرات",
    "CITYNAME_EN": "Al-Hamrat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3659",
    "CITYNAME_AR": "القتيباء",
    "CITYNAME_EN": "Al-Qutaybaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3660",
    "CITYNAME_AR": "القصيباء",
    "CITYNAME_EN": "Al-Qusaybaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3661",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3662",
    "CITYNAME_AR": "المتلوى",
    "CITYNAME_EN": "Al-Matlawy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3663",
    "CITYNAME_AR": "البياضه (ام الهارب)",
    "CITYNAME_EN": "Al-Bayadah (Umm Al-Harib)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3664",
    "CITYNAME_AR": "سهل المعدان",
    "CITYNAME_EN": "Sahl Al-Ma'dan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3665",
    "CITYNAME_AR": "المديسات",
    "CITYNAME_EN": "Al-Madisat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3666",
    "CITYNAME_AR": "العشيره",
    "CITYNAME_EN": "Al-Ashirah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3667",
    "CITYNAME_AR": "غريبات والحفياء",
    "CITYNAME_EN": "Gharibat and Al-Hafyaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3668",
    "CITYNAME_AR": "العريش",
    "CITYNAME_EN": "Al-Arish",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3669",
    "CITYNAME_AR": "حراب",
    "CITYNAME_EN": "Herab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3670",
    "CITYNAME_AR": "قرية الصدرة",
    "CITYNAME_EN": "Al-Sadrah Village ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3671",
    "CITYNAME_AR": "المضهي",
    "CITYNAME_EN": "Al-Madha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3672",
    "CITYNAME_AR": "وادى فروحه(موقع عام)",
    "CITYNAME_EN": "Wadi Faruhah (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3673",
    "CITYNAME_AR": "الرصعه",
    "CITYNAME_EN": "Al Ras'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3674",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al-Oqdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3675",
    "CITYNAME_AR": "مروان",
    "CITYNAME_EN": "Marwan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3676",
    "CITYNAME_AR": "وادى لومه(موقع عام)",
    "CITYNAME_EN": "Wadi Lumah (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3677",
    "CITYNAME_AR": "الثقفيه",
    "CITYNAME_EN": "Al-Thaqfiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3678",
    "CITYNAME_AR": "الهجيج",
    "CITYNAME_EN": "Al Hajij ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3679",
    "CITYNAME_AR": "بلوعر",
    "CITYNAME_EN": "Balo'er ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3680",
    "CITYNAME_AR": "حقو المعين",
    "CITYNAME_EN": "Haqw Al-Ma'in ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3681",
    "CITYNAME_AR": "الطمعه",
    "CITYNAME_EN": "Al-Tam'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3682",
    "CITYNAME_AR": "العشوه",
    "CITYNAME_EN": "Al-Ashwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3683",
    "CITYNAME_AR": "كربه ثاله",
    "CITYNAME_EN": "Korbah Thalah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3684",
    "CITYNAME_AR": "كشاد",
    "CITYNAME_EN": "Kashad ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3685",
    "CITYNAME_AR": "بريده",
    "CITYNAME_EN": "Buraydah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3686",
    "CITYNAME_AR": "الفجره",
    "CITYNAME_EN": "Al-Fajrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3687",
    "CITYNAME_AR": "وادى طفاله(موقع عام)",
    "CITYNAME_EN": "Wadi tafalah (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3688",
    "CITYNAME_AR": "الحنوين",
    "CITYNAME_EN": "Al-Hanwin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3689",
    "CITYNAME_AR": "الموبره",
    "CITYNAME_EN": "Al-Mowirah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3690",
    "CITYNAME_AR": "النشيم",
    "CITYNAME_EN": "Al-Nashim",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3691",
    "CITYNAME_AR": "الصدره",
    "CITYNAME_EN": "Al-Sadrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3692",
    "CITYNAME_AR": "الكربه",
    "CITYNAME_EN": "Al Karbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3693",
    "CITYNAME_AR": "الغرف",
    "CITYNAME_EN": "Al-Gharf",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3694",
    "CITYNAME_AR": "ضاجع",
    "CITYNAME_EN": "Daje' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3695",
    "CITYNAME_AR": "حداب الحبيل",
    "CITYNAME_EN": "Hadab Alhabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "3696",
    "CITYNAME_AR": "بني حسن",
    "CITYNAME_EN": "Al-Tho'ban",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3697",
    "CITYNAME_AR": "براج",
    "CITYNAME_EN": "Baraj ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3698",
    "CITYNAME_AR": "الجوفاء",
    "CITYNAME_EN": "Al-Jawfaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3699",
    "CITYNAME_AR": "زعره",
    "CITYNAME_EN": "Za'rah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3700",
    "CITYNAME_AR": "ذيب",
    "CITYNAME_EN": "Zeib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3701",
    "CITYNAME_AR": "بنى هريره (الحصنين)",
    "CITYNAME_EN": "Bni Horayrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3702",
    "CITYNAME_AR": "صدر مساعد",
    "CITYNAME_EN": "Sadr Mesa'id ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3703",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al-Helah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3704",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3705",
    "CITYNAME_AR": "جدر",
    "CITYNAME_EN": "Jadr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3706",
    "CITYNAME_AR": "الوسيط",
    "CITYNAME_EN": "Al-Wasit ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3707",
    "CITYNAME_AR": "القرعاء",
    "CITYNAME_EN": "Al Qaraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3708",
    "CITYNAME_AR": "الحناديد",
    "CITYNAME_EN": "Al-Hanayid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3709",
    "CITYNAME_AR": "الدارين",
    "CITYNAME_EN": "Al-Darin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3710",
    "CITYNAME_AR": "الجهمه",
    "CITYNAME_EN": "Al-Jahmah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3711",
    "CITYNAME_AR": "خيره",
    "CITYNAME_EN": "Khirah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3712",
    "CITYNAME_AR": "المصاقير",
    "CITYNAME_EN": "Al-Masaqir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3713",
    "CITYNAME_AR": "الفقهاء",
    "CITYNAME_EN": "Al-Foqahaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3714",
    "CITYNAME_AR": "حديد",
    "CITYNAME_EN": "Hadid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3715",
    "CITYNAME_AR": "اريمه",
    "CITYNAME_EN": "Arimah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3716",
    "CITYNAME_AR": "ال زاهي",
    "CITYNAME_EN": "Al Zahy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3717",
    "CITYNAME_AR": "ال عافيه",
    "CITYNAME_EN": "Al Afyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3718",
    "CITYNAME_AR": "ال مثناه",
    "CITYNAME_EN": "Al Methnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3719",
    "CITYNAME_AR": "ال طاهر",
    "CITYNAME_EN": "Al Taher ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3720",
    "CITYNAME_AR": "الغرباء والاثمه",
    "CITYNAME_EN": "Al-Ghorabaa and Al-Athmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3721",
    "CITYNAME_AR": "مراوه",
    "CITYNAME_EN": "Marawah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3722",
    "CITYNAME_AR": "جافان",
    "CITYNAME_EN": "Jafan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3723",
    "CITYNAME_AR": "المشايعه",
    "CITYNAME_EN": "Al-Mashay'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3724",
    "CITYNAME_AR": "القمده",
    "CITYNAME_EN": "Al-Qomdah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3725",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3726",
    "CITYNAME_AR": "الشريق",
    "CITYNAME_EN": "Al-Shariq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3727",
    "CITYNAME_AR": "ال صقاعه",
    "CITYNAME_EN": "Al Saqa'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3728",
    "CITYNAME_AR": "السهساه",
    "CITYNAME_EN": "Al-Sahsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3729",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3730",
    "CITYNAME_AR": "معمر الصمصام",
    "CITYNAME_EN": "Ma'mer Al-Samsam ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3731",
    "CITYNAME_AR": "نجران",
    "CITYNAME_EN": "Najran",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3732",
    "CITYNAME_AR": "خشم العان",
    "CITYNAME_EN": "Khashm Al An",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3733",
    "CITYNAME_AR": "الخضراء الوسطى",
    "CITYNAME_EN": "Al-Khadraa Al-Wosta ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3734",
    "CITYNAME_AR": "ام الوهط",
    "CITYNAME_EN": "Umm Al Waht",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3735",
    "CITYNAME_AR": "المنخلي",
    "CITYNAME_EN": "Al-Mankhali",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3736",
    "CITYNAME_AR": "شروره",
    "CITYNAME_EN": "Sharurah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3737",
    "CITYNAME_AR": "قلمه البتراء",
    "CITYNAME_EN": "Qalamah Albatraa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3738",
    "CITYNAME_AR": "بئر بن لادن",
    "CITYNAME_EN": "Beir bin Laden",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3739",
    "CITYNAME_AR": "تماني",
    "CITYNAME_EN": "Tamani",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3740",
    "CITYNAME_AR": "حمراء نثيل",
    "CITYNAME_EN": "Hamra' Nathil",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3741",
    "CITYNAME_AR": "قلمه خجيم",
    "CITYNAME_EN": "Qalma Khajeem",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3742",
    "CITYNAME_AR": "الاخاشيم",
    "CITYNAME_EN": "Al Akhashim",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3743",
    "CITYNAME_AR": "المعاطيف ",
    "CITYNAME_EN": "Almateef (Om Alqarayen)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3744",
    "CITYNAME_AR": "ام غارب",
    "CITYNAME_EN": "Umm Gharib",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3745",
    "CITYNAME_AR": "البتراء",
    "CITYNAME_EN": "Albatraa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3746",
    "CITYNAME_AR": "مجه",
    "CITYNAME_EN": "Majja",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3747",
    "CITYNAME_AR": "الابرق",
    "CITYNAME_EN": "Al Abraq",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3748",
    "CITYNAME_AR": "بهجه",
    "CITYNAME_EN": "Bahjah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3749",
    "CITYNAME_AR": "الحايرة",
    "CITYNAME_EN": "Al Hayrah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3750",
    "CITYNAME_AR": "سرداب",
    "CITYNAME_EN": "Serdab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3751",
    "CITYNAME_AR": "ام الملح",
    "CITYNAME_EN": "Umm Al Malh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3752",
    "CITYNAME_AR": "السطحية",
    "CITYNAME_EN": "Al Sathiya",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3753",
    "CITYNAME_AR": "المرطاء",
    "CITYNAME_EN": "Al Mertaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3754",
    "CITYNAME_AR": "الخرخير",
    "CITYNAME_EN": "Al-Kharkhir",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3755",
    "CITYNAME_AR": "يدمه",
    "CITYNAME_EN": "Yadamah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3756",
    "CITYNAME_AR": "الجبله الوسطى",
    "CITYNAME_EN": "Al Jabalah Al Wustah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3757",
    "CITYNAME_AR": "عرق فليح",
    "CITYNAME_EN": "Areq Falih",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3758",
    "CITYNAME_AR": "عرق الشارعه",
    "CITYNAME_EN": "Areq Al-Sharea",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3759",
    "CITYNAME_AR": "الادغم (الصليعاء)",
    "CITYNAME_EN": "Al-Adgham (Al-Sulaiya)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3760",
    "CITYNAME_AR": "الابرقيه",
    "CITYNAME_EN": "Al-Abreqiyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3761",
    "CITYNAME_AR": "ذنبه",
    "CITYNAME_EN": "Zanbah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3762",
    "CITYNAME_AR": "الزمله",
    "CITYNAME_EN": "Al-Zamlah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3763",
    "CITYNAME_AR": "المصداره (الصالحيه)",
    "CITYNAME_EN": "Al-Musdarah (Al-Salihiyah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3764",
    "CITYNAME_AR": "العرف",
    "CITYNAME_EN": "Al-Orf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3765",
    "CITYNAME_AR": "بني عهين",
    "CITYNAME_EN": "Bani Hayaan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3766",
    "CITYNAME_AR": "عشاره",
    "CITYNAME_EN": "Usharah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3767",
    "CITYNAME_AR": "الجفره",
    "CITYNAME_EN": "Al Jafra",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3768",
    "CITYNAME_AR": "الكوكب",
    "CITYNAME_EN": "Al-Kawkab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3769",
    "CITYNAME_AR": "هجر وادي القرى",
    "CITYNAME_EN": "Hajr Wadi Al-Qura",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3770",
    "CITYNAME_AR": "عبالم",
    "CITYNAME_EN": "Abalem",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3771",
    "CITYNAME_AR": "المشقح",
    "CITYNAME_EN": "Al-Mashqah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3772",
    "CITYNAME_AR": "هجره الرفاع",
    "CITYNAME_EN": "Hajret Al-Refaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3773",
    "CITYNAME_AR": "الوهلان",
    "CITYNAME_EN": "Al-Wahlan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3774",
    "CITYNAME_AR": "اباالرخم وسط",
    "CITYNAME_EN": "Aba Al-Rakham Wasat",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3775",
    "CITYNAME_AR": "الرفيعه",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3776",
    "CITYNAME_AR": "النقحه",
    "CITYNAME_EN": "Al-Naqha",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3777",
    "CITYNAME_AR": "المعيقل",
    "CITYNAME_EN": "Al-Muayqal",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3778",
    "CITYNAME_AR": "القايمه",
    "CITYNAME_EN": "Al-Qayma",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3779",
    "CITYNAME_AR": "الجعب",
    "CITYNAME_EN": "Al-Joaab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3780",
    "CITYNAME_AR": "هجر نجد النشم",
    "CITYNAME_EN": "Hajr Najd Al-Nasham",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3781",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahet",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3782",
    "CITYNAME_AR": "منقذه",
    "CITYNAME_EN": "Monqaza",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3783",
    "CITYNAME_AR": "غاب",
    "CITYNAME_EN": "Ghab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3784",
    "CITYNAME_AR": "راسان",
    "CITYNAME_EN": "Rasan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3785",
    "CITYNAME_AR": "ابا الحرجان",
    "CITYNAME_EN": "Aba Al-Hurjan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3786",
    "CITYNAME_AR": "ذبوب",
    "CITYNAME_EN": "Zaboob",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3787",
    "CITYNAME_AR": "هجر طلحام",
    "CITYNAME_EN": "Hajr Talham",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3788",
    "CITYNAME_AR": "اباالنخل",
    "CITYNAME_EN": "Aba Al-Nakhl",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3789",
    "CITYNAME_AR": "ابرق شرقه",
    "CITYNAME_EN": "Abrak Sharqa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3790",
    "CITYNAME_AR": "الوجيد",
    "CITYNAME_EN": "Al Wajeed",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3791",
    "CITYNAME_AR": "السبيل",
    "CITYNAME_EN": "Al Sabeel",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3792",
    "CITYNAME_AR": "نجد سهى",
    "CITYNAME_EN": "Najd Soha",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3793",
    "CITYNAME_AR": "هجر وادي الحبط العزيزيه",
    "CITYNAME_EN": "Hajr Wadi Al-Het Al-Azizya",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3794",
    "CITYNAME_AR": "جبل الطائرة",
    "CITYNAME_EN": "Al-taerah Mountain ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3795",
    "CITYNAME_AR": "حبونا",
    "CITYNAME_EN": "Hubuna",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3796",
    "CITYNAME_AR": "المنتشر",
    "CITYNAME_EN": "Almuntasher",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3797",
    "CITYNAME_AR": "النقعاء",
    "CITYNAME_EN": "An-Naqaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3798",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Ad-Dayqah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3799",
    "CITYNAME_AR": "الجفجف",
    "CITYNAME_EN": "Algafgaf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3800",
    "CITYNAME_AR": "تريمه",
    "CITYNAME_EN": "Turaymah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3801",
    "CITYNAME_AR": "الحبابه",
    "CITYNAME_EN": "Al Hababah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3802",
    "CITYNAME_AR": "حشوه الفيض",
    "CITYNAME_EN": "Hashwah Alfayd",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3803",
    "CITYNAME_AR": "الرفزه",
    "CITYNAME_EN": "Ar-Rafazah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3804",
    "CITYNAME_AR": "الظفره",
    "CITYNAME_EN": "Az-Zafarah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3805",
    "CITYNAME_AR": "صخى",
    "CITYNAME_EN": "Sakhi",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3806",
    "CITYNAME_AR": "الخنق",
    "CITYNAME_EN": "Alkhunuq",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3807",
    "CITYNAME_AR": "المجمع",
    "CITYNAME_EN": "Al Majma'",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3808",
    "CITYNAME_AR": "نقعاء حتروش",
    "CITYNAME_EN": "Nuqaa Hatrush",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3809",
    "CITYNAME_AR": "نقعاء منيع ابا الطحين",
    "CITYNAME_EN": "Nuqaa Manei Aba Altaheen",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3810",
    "CITYNAME_AR": "السليمه",
    "CITYNAME_EN": "As-Sulaymah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3811",
    "CITYNAME_AR": "لزم",
    "CITYNAME_EN": "Lazam",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3812",
    "CITYNAME_AR": "دوح",
    "CITYNAME_EN": "Dawh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3813",
    "CITYNAME_AR": "المخمره",
    "CITYNAME_EN": "Almakhmarah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3814",
    "CITYNAME_AR": "الحزقاء",
    "CITYNAME_EN": "Alhazqaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3815",
    "CITYNAME_AR": "النوش",
    "CITYNAME_EN": "Alnush",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3816",
    "CITYNAME_AR": "الملاحه (المملح)",
    "CITYNAME_EN": "Almelaha (Almumalah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3817",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3818",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Ad-Dayqah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3819",
    "CITYNAME_AR": "سلوه",
    "CITYNAME_EN": "Salouh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3820",
    "CITYNAME_AR": "الجفه",
    "CITYNAME_EN": "Aljafah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3821",
    "CITYNAME_AR": "الحرشف",
    "CITYNAME_EN": "Alharshaf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3822",
    "CITYNAME_AR": "ام الحوض",
    "CITYNAME_EN": "Om Alhawd",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3823",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashaab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3824",
    "CITYNAME_AR": "قرى",
    "CITYNAME_EN": "Qura",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3825",
    "CITYNAME_AR": "سلطانه",
    "CITYNAME_EN": "Sultana",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3826",
    "CITYNAME_AR": "السيافية",
    "CITYNAME_EN": "Al-Sayafiyyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3827",
    "CITYNAME_AR": "الخالديه",
    "CITYNAME_EN": "Al-Khalidyyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3828",
    "CITYNAME_AR": "لصيفا",
    "CITYNAME_EN": "Lusayfa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3829",
    "CITYNAME_AR": "المندفن",
    "CITYNAME_EN": "Al-mandafin",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3830",
    "CITYNAME_AR": "منادي",
    "CITYNAME_EN": "Monadi",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3831",
    "CITYNAME_AR": "النمصه",
    "CITYNAME_EN": "An-Namasah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3832",
    "CITYNAME_AR": "بدر الجنوب",
    "CITYNAME_EN": "Badr Al Janoub",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3833",
    "CITYNAME_AR": "المجزعه",
    "CITYNAME_EN": "Al Magzaah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3834",
    "CITYNAME_AR": "سحامه",
    "CITYNAME_EN": "Suhamah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3835",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Alaushah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3836",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Almaliha",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3837",
    "CITYNAME_AR": "السوايل",
    "CITYNAME_EN": "As-Sawayel",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3838",
    "CITYNAME_AR": "الحقي",
    "CITYNAME_EN": "Alhaqi",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3839",
    "CITYNAME_AR": "الدوكر",
    "CITYNAME_EN": "Aldukar",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3840",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Alshat",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3841",
    "CITYNAME_AR": "المختلف",
    "CITYNAME_EN": "Almukhtalef",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3842",
    "CITYNAME_AR": "المرقب",
    "CITYNAME_EN": "Almarqeb",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3843",
    "CITYNAME_AR": "الحضيره",
    "CITYNAME_EN": "Alkhudayrah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3844",
    "CITYNAME_AR": "ال ربيع",
    "CITYNAME_EN": "Al Rabei",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3845",
    "CITYNAME_AR": "الحجمه",
    "CITYNAME_EN": "Alhajmah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3846",
    "CITYNAME_AR": "كريثه",
    "CITYNAME_EN": "Kuraythah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3847",
    "CITYNAME_AR": "قبور الدوله",
    "CITYNAME_EN": "Qubur Ad-Dawlah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3848",
    "CITYNAME_AR": "السويده",
    "CITYNAME_EN": "As-Suwaidah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3849",
    "CITYNAME_AR": "اللقطه",
    "CITYNAME_EN": "Alluqtah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3850",
    "CITYNAME_AR": "المذنب",
    "CITYNAME_EN": "Almuthanab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3851",
    "CITYNAME_AR": "شكلاله",
    "CITYNAME_EN": "Shaqlalah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3852",
    "CITYNAME_AR": "كهلان",
    "CITYNAME_EN": "Kahlan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3853",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3854",
    "CITYNAME_AR": "زهقه",
    "CITYNAME_EN": "Zahaqah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3855",
    "CITYNAME_AR": "مطاره",
    "CITYNAME_EN": "Mutarah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3856",
    "CITYNAME_AR": "غثيمه هجره قانيه",
    "CITYNAME_EN": "Ghutaymah Hijrah Qanyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3857",
    "CITYNAME_AR": "طليله",
    "CITYNAME_EN": "Tulaylah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3858",
    "CITYNAME_AR": "نزغه",
    "CITYNAME_EN": "Nazaghah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3859",
    "CITYNAME_AR": "علفوت",
    "CITYNAME_EN": "Alfoot",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3860",
    "CITYNAME_AR": "خيره",
    "CITYNAME_EN": "Khayruh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3861",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Al Raudah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3862",
    "CITYNAME_AR": "الرغيب",
    "CITYNAME_EN": "Al Raghib",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3863",
    "CITYNAME_AR": "فرع الجبل",
    "CITYNAME_EN": "Fara' Aljabal",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3864",
    "CITYNAME_AR": "رضيه غوبر",
    "CITYNAME_EN": "Radyah Ghuwyer",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3865",
    "CITYNAME_AR": "ام العرقين",
    "CITYNAME_EN": "Om Alarqeen",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3866",
    "CITYNAME_AR": "الجديده",
    "CITYNAME_EN": "Al Jadidah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3867",
    "CITYNAME_AR": "العباله",
    "CITYNAME_EN": "Al Abalah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3868",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Alatf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3869",
    "CITYNAME_AR": "عشر",
    "CITYNAME_EN": "Ashr",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3870",
    "CITYNAME_AR": "الجراز",
    "CITYNAME_EN": "Al Jaraz",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3871",
    "CITYNAME_AR": "الفرحه",
    "CITYNAME_EN": "Al Farha",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3872",
    "CITYNAME_AR": "بير العطف",
    "CITYNAME_EN": "Ber Al Atef",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3873",
    "CITYNAME_AR": "الجهوش",
    "CITYNAME_EN": "Al Gahoosh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3874",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al Shat",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3875",
    "CITYNAME_AR": "الظاهره",
    "CITYNAME_EN": "Al Zahera",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3876",
    "CITYNAME_AR": "الرون",
    "CITYNAME_EN": "Al Rawn",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3877",
    "CITYNAME_AR": "المشنه",
    "CITYNAME_EN": "Al-Mashnah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3878",
    "CITYNAME_AR": "السامك",
    "CITYNAME_EN": "Al Samek",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3879",
    "CITYNAME_AR": "صله",
    "CITYNAME_EN": "Selah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3880",
    "CITYNAME_AR": "المرخ",
    "CITYNAME_EN": "Al Markh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3881",
    "CITYNAME_AR": "وادي اوال",
    "CITYNAME_EN": "Wadi Awal",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3882",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bahra",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3883",
    "CITYNAME_AR": "موعاه",
    "CITYNAME_EN": "Mowaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3884",
    "CITYNAME_AR": "الصمع",
    "CITYNAME_EN": "Al Samaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3885",
    "CITYNAME_AR": "الصماء",
    "CITYNAME_EN": "Al Samaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3886",
    "CITYNAME_AR": "الصفق",
    "CITYNAME_EN": "Al Safaq",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3887",
    "CITYNAME_AR": "الشدقاء",
    "CITYNAME_EN": "Al Shadqaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3888",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3889",
    "CITYNAME_AR": "الرحاب",
    "CITYNAME_EN": "Al Rehab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3890",
    "CITYNAME_AR": "خضيران",
    "CITYNAME_EN": "Khodyran",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3891",
    "CITYNAME_AR": "حميران",
    "CITYNAME_EN": "Humayran",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3892",
    "CITYNAME_AR": "لدمه",
    "CITYNAME_EN": "Ladamah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3893",
    "CITYNAME_AR": "كحيل",
    "CITYNAME_EN": "Kaheel",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3894",
    "CITYNAME_AR": "الحجف",
    "CITYNAME_EN": "Al Hajaf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3895",
    "CITYNAME_AR": "المرتجم",
    "CITYNAME_EN": "Al Murtagam",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3896",
    "CITYNAME_AR": "نهماء",
    "CITYNAME_EN": "Nahmaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3897",
    "CITYNAME_AR": "الخضره",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3898",
    "CITYNAME_AR": "النضاره",
    "CITYNAME_EN": "Al Nadarah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3899",
    "CITYNAME_AR": "الشبكه",
    "CITYNAME_EN": "Al Shabakah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3900",
    "CITYNAME_AR": "ابو رجلين",
    "CITYNAME_EN": "Abu Rejelayn",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3901",
    "CITYNAME_AR": "العرج",
    "CITYNAME_EN": "Alaraj",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3902",
    "CITYNAME_AR": "بئر الجوف",
    "CITYNAME_EN": "Bir Al-Jawf ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3903",
    "CITYNAME_AR": "الغميس (الغمبيس)",
    "CITYNAME_EN": "لإاشق",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3904",
    "CITYNAME_AR": "نجد المملح غراء",
    "CITYNAME_EN": "Najd Al-Mamlah Gharaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3905",
    "CITYNAME_AR": "الرابيه",
    "CITYNAME_EN": "Al Rabia",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3906",
    "CITYNAME_AR": "الدريعاء",
    "CITYNAME_EN": "Al Duraya",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3907",
    "CITYNAME_AR": "زور الاثله",
    "CITYNAME_EN": "Zor Al Athalah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3908",
    "CITYNAME_AR": "الوهاب",
    "CITYNAME_EN": "Al-Wahab ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3909",
    "CITYNAME_AR": "بني حدد",
    "CITYNAME_EN": "Bni Hadd ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3910",
    "CITYNAME_AR": "زور العقارب",
    "CITYNAME_EN": "Zur Al-Aqarib ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3911",
    "CITYNAME_AR": "وشيل الذيب",
    "CITYNAME_EN": "Washil Al-Zieb ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3912",
    "CITYNAME_AR": "الحرشاء",
    "CITYNAME_EN": "Al-Harshaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3913",
    "CITYNAME_AR": "الحلقوم",
    "CITYNAME_EN": "Al-Halqum ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3914",
    "CITYNAME_AR": "بئر بن فيحان بئر بن ذيبان",
    "CITYNAME_EN": "Bir Bni Fayhan bin Ziban ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3915",
    "CITYNAME_AR": "بصر",
    "CITYNAME_EN": "Basr ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3916",
    "CITYNAME_AR": "الحنكان",
    "CITYNAME_EN": "Al-Hankan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3917",
    "CITYNAME_AR": "لحيفه",
    "CITYNAME_EN": "Lahifah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3918",
    "CITYNAME_AR": "المريفق",
    "CITYNAME_EN": "Al-Moraifiq",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3919",
    "CITYNAME_AR": "الجنفور",
    "CITYNAME_EN": "Al Junfour",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3920",
    "CITYNAME_AR": "تلاع",
    "CITYNAME_EN": "Tela' ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3921",
    "CITYNAME_AR": "عيان",
    "CITYNAME_EN": "Ayan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3922",
    "CITYNAME_AR": "الحزمه",
    "CITYNAME_EN": "Al-Hazmah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3923",
    "CITYNAME_AR": "بني حواره",
    "CITYNAME_EN": "Bni Hawarah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3924",
    "CITYNAME_AR": "بني ثرار",
    "CITYNAME_EN": "Bni Therar ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3925",
    "CITYNAME_AR": "كتان",
    "CITYNAME_EN": "Kattan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3926",
    "CITYNAME_AR": "رهوه نصب",
    "CITYNAME_EN": "Rahwah Nasb ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3927",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "Al-Sriyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3928",
    "CITYNAME_AR": "صناخه",
    "CITYNAME_EN": "Sanakhah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3929",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Al Jarieh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3930",
    "CITYNAME_AR": "نجد الحصان",
    "CITYNAME_EN": "Najd Al-Hesan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3931",
    "CITYNAME_AR": "الحقو قدقده",
    "CITYNAME_EN": "Al-Haqw Qadqadah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3932",
    "CITYNAME_AR": "الحبشيه",
    "CITYNAME_EN": "Al-Habishah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3933",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Al Rahbah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3934",
    "CITYNAME_AR": "الحاضنه",
    "CITYNAME_EN": "Al-Hadinah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3935",
    "CITYNAME_AR": "بئر ال زبيره (الزباره)",
    "CITYNAME_EN": "Bir Al Zubayrah (Al-Zabarah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3936",
    "CITYNAME_AR": "غرمله",
    "CITYNAME_EN": "Gharmalah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3937",
    "CITYNAME_AR": "الخرماء",
    "CITYNAME_EN": "Al-Kharmaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3938",
    "CITYNAME_AR": "الحضن الاصفر",
    "CITYNAME_EN": "Al-Hodn Al-Asfar ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3939",
    "CITYNAME_AR": "بني قارض",
    "CITYNAME_EN": "Bni Qarid ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3940",
    "CITYNAME_AR": "عرفطه",
    "CITYNAME_EN": "Arfatah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3941",
    "CITYNAME_AR": "القافيه",
    "CITYNAME_EN": "Al-Qafiyah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3942",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Al-Nab'ah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3943",
    "CITYNAME_AR": "الواتد",
    "CITYNAME_EN": "Al-Watid ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3944",
    "CITYNAME_AR": "قرضه الحق",
    "CITYNAME_EN": "Qardah Al-Haq",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3945",
    "CITYNAME_AR": "بئر العضبات",
    "CITYNAME_EN": "Bir Al-Adbat ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3946",
    "CITYNAME_AR": "سلف مشعل الخضراء",
    "CITYNAME_EN": "Salaf Mish'al Al-Khadraa ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3947",
    "CITYNAME_AR": "فجيح",
    "CITYNAME_EN": "Fajih ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3948",
    "CITYNAME_AR": "نجد لجعه",
    "CITYNAME_EN": "Najd Laj'ah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3949",
    "CITYNAME_AR": "المدره (المداره)",
    "CITYNAME_EN": "Al-Madrah (Al-Madarah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3950",
    "CITYNAME_AR": "ام الزهر (زهره)",
    "CITYNAME_EN": "Umm Al-Zahr (Zahrah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3951",
    "CITYNAME_AR": "الضبط",
    "CITYNAME_EN": "Al-Dabt ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3952",
    "CITYNAME_AR": "بني عباره",
    "CITYNAME_EN": "Bni Ibarah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3953",
    "CITYNAME_AR": "بني راه",
    "CITYNAME_EN": "Bni Rah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3954",
    "CITYNAME_AR": "الجوف",
    "CITYNAME_EN": "Al-Jouf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3955",
    "CITYNAME_AR": "صخاء",
    "CITYNAME_EN": "Sakhaa ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3956",
    "CITYNAME_AR": "الفطحه",
    "CITYNAME_EN": "Al-Fathah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3957",
    "CITYNAME_AR": "عويل",
    "CITYNAME_EN": "Awil ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3958",
    "CITYNAME_AR": "النهوم",
    "CITYNAME_EN": "Al-Nahum ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3959",
    "CITYNAME_AR": "المشراق",
    "CITYNAME_EN": "Al-Meshraq ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3960",
    "CITYNAME_AR": "الصفران (الروساء)",
    "CITYNAME_EN": "Al-Safran (Al-Rawsaa)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3961",
    "CITYNAME_AR": "الملح",
    "CITYNAME_EN": "Al-Melh",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3962",
    "CITYNAME_AR": "المنسرقه",
    "CITYNAME_EN": "Al-Monsariqah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3963",
    "CITYNAME_AR": "العين والقرين",
    "CITYNAME_EN": "Al-Ain and Al-Qarin ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3964",
    "CITYNAME_AR": "نجد معين",
    "CITYNAME_EN": "Najd Ma'in ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3965",
    "CITYNAME_AR": "المنصلخه",
    "CITYNAME_EN": "Al-Monsalekhah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3966",
    "CITYNAME_AR": "بئر عكذباء",
    "CITYNAME_EN": "Bir Akzaa ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3967",
    "CITYNAME_AR": "بئر المبعوث (ابار المبعوث)",
    "CITYNAME_EN": "Bir Al-Mab'uth (Abar Al-Mab'uth)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3968",
    "CITYNAME_AR": "عنص",
    "CITYNAME_EN": "Ans ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3969",
    "CITYNAME_AR": "العرقان",
    "CITYNAME_EN": "Al-Arqan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3970",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al-Khatwah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3971",
    "CITYNAME_AR": "السريحه",
    "CITYNAME_EN": "Al-Sarihah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3972",
    "CITYNAME_AR": "حمى",
    "CITYNAME_EN": "Hema",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3973",
    "CITYNAME_AR": "النظيم",
    "CITYNAME_EN": "Al Nadhim",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3974",
    "CITYNAME_AR": "نعوان",
    "CITYNAME_EN": "Na'wan ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3975",
    "CITYNAME_AR": "الارجاه",
    "CITYNAME_EN": "Al-Arjah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3976",
    "CITYNAME_AR": "لبو",
    "CITYNAME_EN": "Labw",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3977",
    "CITYNAME_AR": "جفره سعد",
    "CITYNAME_EN": "Jafrah Saad ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3978",
    "CITYNAME_AR": "الدمنه",
    "CITYNAME_EN": "Al Demnah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3979",
    "CITYNAME_AR": "اثب",
    "CITYNAME_EN": "Athb",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3980",
    "CITYNAME_AR": "الصفاح",
    "CITYNAME_EN": "As Safah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3981",
    "CITYNAME_AR": "سواس",
    "CITYNAME_EN": "Sawas ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3982",
    "CITYNAME_AR": "الرفغه",
    "CITYNAME_EN": "Al Refgha",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3983",
    "CITYNAME_AR": "ابا القير",
    "CITYNAME_EN": "Abar Al-Qir",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3984",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3985",
    "CITYNAME_AR": "المغوى",
    "CITYNAME_EN": "Al-Maghwa ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3986",
    "CITYNAME_AR": "المسيفير",
    "CITYNAME_EN": "Al-Mosaifir ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3987",
    "CITYNAME_AR": "ارتاد",
    "CITYNAME_EN": "Artad ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3988",
    "CITYNAME_AR": "شصره",
    "CITYNAME_EN": "Shasrah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3989",
    "CITYNAME_AR": "ابا السلع طلحام وبني عكس",
    "CITYNAME_EN": "Aba Al-Sela' Talham and Bni Aks ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3990",
    "CITYNAME_AR": "البياض",
    "CITYNAME_EN": "Al-Byad",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3991",
    "CITYNAME_AR": "وادي يعرف",
    "CITYNAME_EN": "Wadi Ya'rif ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3992",
    "CITYNAME_AR": "سحامه",
    "CITYNAME_EN": "Sahamah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3993",
    "CITYNAME_AR": "بئر شايق العضبه",
    "CITYNAME_EN": "Bir Shayiq Al-Adbah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3994",
    "CITYNAME_AR": "سادة والشن",
    "CITYNAME_EN": "Sadah walshin ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3995",
    "CITYNAME_AR": "يتم",
    "CITYNAME_EN": "Yatm",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3996",
    "CITYNAME_AR": "ثجر",
    "CITYNAME_EN": "Thajir",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3997",
    "CITYNAME_AR": "الجوشن",
    "CITYNAME_EN": "Al Jawshan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3998",
    "CITYNAME_AR": "المحمديه",
    "CITYNAME_EN": "Hirah Hasin Al-Mahmadiyah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3999",
    "CITYNAME_AR": "الغييظه",
    "CITYNAME_EN": "Al-Ghubayzah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4000",
    "CITYNAME_AR": "ثار",
    "CITYNAME_EN": "Thar",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4001",
    "CITYNAME_AR": "خباش",
    "CITYNAME_EN": "Khbash",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4002",
    "CITYNAME_AR": "هويمل",
    "CITYNAME_EN": "Howaiml",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4003",
    "CITYNAME_AR": "النقيحاء",
    "CITYNAME_EN": "Nouqayhaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4004",
    "CITYNAME_AR": "النقيحاء مورد",
    "CITYNAME_EN": "Al-Noqayhaa Mawrd ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4005",
    "CITYNAME_AR": "ابو شداد",
    "CITYNAME_EN": "Abu Shedad",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4006",
    "CITYNAME_AR": "المحياش",
    "CITYNAME_EN": "Mehyash",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4007",
    "CITYNAME_AR": "شقه الكناور",
    "CITYNAME_EN": "Shaqa Al-Kanawer ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4008",
    "CITYNAME_AR": "الوديعه",
    "CITYNAME_EN": "Al Wadiah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4009",
    "CITYNAME_AR": "برقا اعاره",
    "CITYNAME_EN": "Barqa E'arah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4010",
    "CITYNAME_AR": "نسر (آبار حمى الأثرية)",
    "CITYNAME_EN": "Nesr (ِAntique Abar Hamy) ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4011",
    "CITYNAME_AR": "صدعه",
    "CITYNAME_EN": "Sa'dah ",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4012",
    "CITYNAME_AR": "المنتزه",
    "CITYNAME_EN": "Al-Montazah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4013",
    "CITYNAME_AR": "المشعليه",
    "CITYNAME_EN": "Al Mishaliah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4014",
    "CITYNAME_AR": "الحصينية",
    "CITYNAME_EN": "Al Husayniyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4015",
    "CITYNAME_AR": "محير حصين",
    "CITYNAME_EN": "Muhyer Husayn",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4016",
    "CITYNAME_AR": "سائلة غزال",
    "CITYNAME_EN": "Saleit Ghazal",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4017",
    "CITYNAME_AR": "العميريه",
    "CITYNAME_EN": "Al Omairyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4018",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Alhadbaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4019",
    "CITYNAME_AR": "نعمان",
    "CITYNAME_EN": "Nuaman",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4020",
    "CITYNAME_AR": "السويداء",
    "CITYNAME_EN": "As-Suwaidaa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4021",
    "CITYNAME_AR": "مزارع الحصينية للدواجن",
    "CITYNAME_EN": "Alhusaynyah poultry farms",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4022",
    "CITYNAME_AR": "الوحشه (المصراخ)",
    "CITYNAME_EN": "Alwahashah (Almesrakh)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4023",
    "CITYNAME_AR": "الصناعيه الجديده",
    "CITYNAME_EN": "As-Senaiah Aljadidah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4024",
    "CITYNAME_AR": "الصفح الاجرد",
    "CITYNAME_EN": "Al-Safh Al-Ajrad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4025",
    "CITYNAME_AR": "القريضة",
    "CITYNAME_EN": "Al-Qaridah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4026",
    "CITYNAME_AR": "النقبة",
    "CITYNAME_EN": "Al-Naqbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4027",
    "CITYNAME_AR": "العلاية",
    "CITYNAME_EN": "Al-Ulya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4028",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Al-Rakbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4029",
    "CITYNAME_AR": "طلالا",
    "CITYNAME_EN": "Talala",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4030",
    "CITYNAME_AR": "العظه",
    "CITYNAME_EN": "Al Ezah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4031",
    "CITYNAME_AR": "الاكرار",
    "CITYNAME_EN": "Al Akrar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4032",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4033",
    "CITYNAME_AR": "المراء",
    "CITYNAME_EN": "Al-Meraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4034",
    "CITYNAME_AR": "ريع بويجر",
    "CITYNAME_EN": "Rab' Buwejer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4035",
    "CITYNAME_AR": "شعبه الضيفان العليا والسفلى",
    "CITYNAME_EN": "Shi'bah Al-Difan (The upper and the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4036",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4037",
    "CITYNAME_AR": "الجثبه",
    "CITYNAME_EN": "Al Jathbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4038",
    "CITYNAME_AR": "العلب",
    "CITYNAME_EN": "Al-Alb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4039",
    "CITYNAME_AR": "عطف مسلط",
    "CITYNAME_EN": "Atf Maslet ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4040",
    "CITYNAME_AR": "الحليفه",
    "CITYNAME_EN": "Al-Halifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4041",
    "CITYNAME_AR": "زميم (السلف)",
    "CITYNAME_EN": "Zamim (Al-Salaf)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4042",
    "CITYNAME_AR": "مجدعان",
    "CITYNAME_EN": "Megd'an",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4043",
    "CITYNAME_AR": "سلف عواض",
    "CITYNAME_EN": "Salaf Awad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4044",
    "CITYNAME_AR": "سلف الحبال",
    "CITYNAME_EN": "Salaf Al-Hebal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4045",
    "CITYNAME_AR": "عطف العود",
    "CITYNAME_EN": "Atf Al-Ud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4046",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al-Mesbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4047",
    "CITYNAME_AR": "العزيلاء السفلى والعليا",
    "CITYNAME_EN": "Al-Ozaylaa (the lower and the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4048",
    "CITYNAME_AR": "مغبن والصرف",
    "CITYNAME_EN": "Meghbir and Al-Sarf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4049",
    "CITYNAME_AR": "الجلابه (الشرقيه والغربيه)",
    "CITYNAME_EN": "Al-Jalayah (The eastern and the western)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4050",
    "CITYNAME_AR": "المجتورين",
    "CITYNAME_EN": "Al-Majturin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4051",
    "CITYNAME_AR": "الدائسه",
    "CITYNAME_EN": "Al-Daesah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4052",
    "CITYNAME_AR": "المجدره والمقحوزه",
    "CITYNAME_EN": "Al-Majdarah and Al-Maqhuzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4053",
    "CITYNAME_AR": "عرعرة",
    "CITYNAME_EN": "Ararah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4054",
    "CITYNAME_AR": "الخطم (المطلى)",
    "CITYNAME_EN": "Al-Khatm (Al-Matly)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4055",
    "CITYNAME_AR": "الصفق(الكحل)",
    "CITYNAME_EN": "Al-Safq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4056",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Al-Arqub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4057",
    "CITYNAME_AR": "قرن بن ساهر",
    "CITYNAME_EN": "Qarn bin Saher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4058",
    "CITYNAME_AR": "حبيل الملك",
    "CITYNAME_EN": "Habil Al-Malek ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4059",
    "CITYNAME_AR": "ال سعاد الملك",
    "CITYNAME_EN": "Al Se'ad Al-Malek ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4060",
    "CITYNAME_AR": "آل عمارن",
    "CITYNAME_EN": "Al Amaren ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4061",
    "CITYNAME_AR": "ال جبران",
    "CITYNAME_EN": "Al Jebran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4062",
    "CITYNAME_AR": "المهده",
    "CITYNAME_EN": "Al-Mahdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4063",
    "CITYNAME_AR": "الحديب",
    "CITYNAME_EN": "Al Hadib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4064",
    "CITYNAME_AR": "حبيل شقيق",
    "CITYNAME_EN": "Habil Shaqiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4065",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4066",
    "CITYNAME_AR": "ال غفيل",
    "CITYNAME_EN": "Al Ghafil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4067",
    "CITYNAME_AR": "السقيفه",
    "CITYNAME_EN": "Al-Saqifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4068",
    "CITYNAME_AR": "المعدونه",
    "CITYNAME_EN": "Al-Ma'dunah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4069",
    "CITYNAME_AR": "ال عامر (دارعامر)",
    "CITYNAME_EN": "Al Amer (Dar Amer)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4070",
    "CITYNAME_AR": "سلمه",
    "CITYNAME_EN": "Salmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4071",
    "CITYNAME_AR": "الحبيل (دارعامر)",
    "CITYNAME_EN": "Al-Habil (Dar Amer)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4072",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al-Hariqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4073",
    "CITYNAME_AR": "ذاعليسن",
    "CITYNAME_EN": "Za Alisen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4074",
    "CITYNAME_AR": "الحصن (ال عامر)",
    "CITYNAME_EN": "Al-Hisn (Al Amer0 ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4075",
    "CITYNAME_AR": "عذيبه (ال عامر)",
    "CITYNAME_EN": "Azibah (Al Amer)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4076",
    "CITYNAME_AR": "ال قيسم",
    "CITYNAME_EN": "Al Qaysem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4077",
    "CITYNAME_AR": "حله الفروه (الفروه)",
    "CITYNAME_EN": "Hellah Al-Farwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4078",
    "CITYNAME_AR": "مشرفه(ال سلمان)",
    "CITYNAME_EN": "Mashrafah (Al-Salman)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4079",
    "CITYNAME_AR": "حدباءعاليه",
    "CITYNAME_EN": "Hadbaa Aliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4080",
    "CITYNAME_AR": "شويحط",
    "CITYNAME_EN": "Shuwayhit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4081",
    "CITYNAME_AR": "عميم ال سلمه",
    "CITYNAME_EN": "Amim Al Salamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4082",
    "CITYNAME_AR": "المنتزه (سوق الاثنين)",
    "CITYNAME_EN": "Al-Montazah (Monday Market)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4083",
    "CITYNAME_AR": "ال بيسالم",
    "CITYNAME_EN": "Al-Bisalem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4084",
    "CITYNAME_AR": "ال رايح",
    "CITYNAME_EN": "Al Rayih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4085",
    "CITYNAME_AR": "الشعبان",
    "CITYNAME_EN": "Al-Sha'ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4086",
    "CITYNAME_AR": "حاره السلام",
    "CITYNAME_EN": "Haret Al-Salam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4087",
    "CITYNAME_AR": "شعب فاضل",
    "CITYNAME_EN": "Shi'b Fadel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4088",
    "CITYNAME_AR": "شعب مفتاح",
    "CITYNAME_EN": "Shi'b Meftah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4089",
    "CITYNAME_AR": "معراه",
    "CITYNAME_EN": "Mi'rah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4090",
    "CITYNAME_AR": "الرسيس",
    "CITYNAME_EN": "Al-Rasis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4091",
    "CITYNAME_AR": "مركز شعف بلقرن",
    "CITYNAME_EN": "Shaaf Blqarn Center ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4092",
    "CITYNAME_AR": "الحصنين والزوه والعرسه",
    "CITYNAME_EN": "Al-Hesnayn Al-Zurah and Al-Orsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4093",
    "CITYNAME_AR": "الوركاء",
    "CITYNAME_EN": "Al Warkaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4094",
    "CITYNAME_AR": "النزله والنزهه",
    "CITYNAME_EN": "Al-Nazlah and Al-Nozhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4095",
    "CITYNAME_AR": "صامطه",
    "CITYNAME_EN": "Samtah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4096",
    "CITYNAME_AR": "الصعاق",
    "CITYNAME_EN": "Al-Se'aq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4097",
    "CITYNAME_AR": "ناصحه",
    "CITYNAME_EN": "Nashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4098",
    "CITYNAME_AR": "قرى راشد",
    "CITYNAME_EN": "Rashid Villages ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4099",
    "CITYNAME_AR": "ال هلجام والنقبه",
    "CITYNAME_EN": "Al Heljam and Al-Naqbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4100",
    "CITYNAME_AR": "راس المهلل ( وصفح باليه)",
    "CITYNAME_EN": "Ras AL-Mohall (Safh Baliyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4101",
    "CITYNAME_AR": "القابل",
    "CITYNAME_EN": "Al-Qabil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4102",
    "CITYNAME_AR": "ال مسليه",
    "CITYNAME_EN": "Masliyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4103",
    "CITYNAME_AR": "الشوقان والمحاط",
    "CITYNAME_EN": "Al-Shuqan and Al-Mehat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4104",
    "CITYNAME_AR": "الاقرع",
    "CITYNAME_EN": "Al-Aqra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4105",
    "CITYNAME_AR": "نواله ال غنيه و الحطمه",
    "CITYNAME_EN": "Nawalah Al Ghaniyah and Al-Hotmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4106",
    "CITYNAME_AR": "الطوف والحبيل",
    "CITYNAME_EN": "Al-Tawf and Al-Habil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4107",
    "CITYNAME_AR": "شويحط (النصب)",
    "CITYNAME_EN": "Shuwayhit (Al-Nasb)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4108",
    "CITYNAME_AR": "شويحط",
    "CITYNAME_EN": "Shuwayhit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4109",
    "CITYNAME_AR": "الاطواف",
    "CITYNAME_EN": "Al-Atwaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4110",
    "CITYNAME_AR": "الرديفين",
    "CITYNAME_EN": "Al-Radifayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4111",
    "CITYNAME_AR": "ذي اللحاين والفراع وذي الحمايط",
    "CITYNAME_EN": "Zi Al-Lahayin, Al-Fera', and Zi Al-Hamayit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4112",
    "CITYNAME_AR": "ذى العصيمه",
    "CITYNAME_EN": "Zi Al-Usaymah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4113",
    "CITYNAME_AR": "نومه",
    "CITYNAME_EN": "Nomah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4114",
    "CITYNAME_AR": "الصلايه",
    "CITYNAME_EN": "Al-Salayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4115",
    "CITYNAME_AR": "الرابخ",
    "CITYNAME_EN": "Al-Rabikh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4116",
    "CITYNAME_AR": "الباصمه",
    "CITYNAME_EN": "Al-Basmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4117",
    "CITYNAME_AR": "اشعر",
    "CITYNAME_EN": "Ash'ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4118",
    "CITYNAME_AR": "الناله",
    "CITYNAME_EN": "Al-Nalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4119",
    "CITYNAME_AR": "الظهره (الغمار)",
    "CITYNAME_EN": "Al-Zohrah (Al-Ghemar)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4120",
    "CITYNAME_AR": "الحجمه",
    "CITYNAME_EN": "Al-Hajmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4121",
    "CITYNAME_AR": "الوذان",
    "CITYNAME_EN": "Al-Wazan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4122",
    "CITYNAME_AR": "الظهار",
    "CITYNAME_EN": "Al-Zehar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4123",
    "CITYNAME_AR": "صيتع",
    "CITYNAME_EN": "Sayta' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4124",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4125",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Al-Harf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4126",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Al-Madrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4127",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4128",
    "CITYNAME_AR": "ال يزيد",
    "CITYNAME_EN": "Al Yazid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4129",
    "CITYNAME_AR": "ال خثيم",
    "CITYNAME_EN": "Al Khathim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4130",
    "CITYNAME_AR": "الحتار",
    "CITYNAME_EN": "Al-Hitar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4131",
    "CITYNAME_AR": "ظهورالسربه",
    "CITYNAME_EN": "Zuhur Al-Sariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4132",
    "CITYNAME_AR": "ال حزام",
    "CITYNAME_EN": "Al Hezam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4133",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4134",
    "CITYNAME_AR": "قرى حسان",
    "CITYNAME_EN": "Hassan Villages ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4135",
    "CITYNAME_AR": "ترهم",
    "CITYNAME_EN": "Tarhem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4136",
    "CITYNAME_AR": "سبت حجاب",
    "CITYNAME_EN": "Sabt Hejab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4137",
    "CITYNAME_AR": "دار الصفا",
    "CITYNAME_EN": "Dar Al-Safa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4138",
    "CITYNAME_AR": "ال عليان",
    "CITYNAME_EN": "Al Ilyan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4139",
    "CITYNAME_AR": "ظهور الحمراء",
    "CITYNAME_EN": "Zohur Al-Hamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4140",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4141",
    "CITYNAME_AR": "الصفق",
    "CITYNAME_EN": "Al-Safq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4142",
    "CITYNAME_AR": "ال قابل",
    "CITYNAME_EN": "Al Qabil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4143",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4144",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Al Malhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4145",
    "CITYNAME_AR": "خليده",
    "CITYNAME_EN": "Khalidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4146",
    "CITYNAME_AR": "جافعه",
    "CITYNAME_EN": "Jaf'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4147",
    "CITYNAME_AR": "الحصن ال سليمان",
    "CITYNAME_EN": "Al-Hisn (Al Solayman)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4148",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4149",
    "CITYNAME_AR": "الحتار",
    "CITYNAME_EN": "Al-Hitar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4150",
    "CITYNAME_AR": "ال دلال",
    "CITYNAME_EN": "Al Delal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4151",
    "CITYNAME_AR": "ال بيحسن",
    "CITYNAME_EN": "Al Yihsen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4152",
    "CITYNAME_AR": "آل مسلى والشعب",
    "CITYNAME_EN": "Al Masla and Al-Shi'b ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4153",
    "CITYNAME_AR": "الشعب والصره",
    "CITYNAME_EN": "Al-Shi'b and Al-Surrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4154",
    "CITYNAME_AR": "ال مكدم (ال سوداء)",
    "CITYNAME_EN": "Al Mekdim (Al Sawdaa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4155",
    "CITYNAME_AR": "ال شملان",
    "CITYNAME_EN": "Al Shamlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4156",
    "CITYNAME_AR": "شعبة الملح",
    "CITYNAME_EN": "Shi'bah Al-Malh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4157",
    "CITYNAME_AR": "الغيج",
    "CITYNAME_EN": "Al-Ghij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4158",
    "CITYNAME_AR": "ال مختال",
    "CITYNAME_EN": "Al-Mekhtal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4159",
    "CITYNAME_AR": "ثعنبه",
    "CITYNAME_EN": "Tha'nabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4160",
    "CITYNAME_AR": "ال شعران",
    "CITYNAME_EN": "Al She'ran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4161",
    "CITYNAME_AR": "موجعه",
    "CITYNAME_EN": "Muja'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4162",
    "CITYNAME_AR": "المراشده وال محمود",
    "CITYNAME_EN": "Al-Marashdah and Al mahmoud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4163",
    "CITYNAME_AR": "ال شعيفه",
    "CITYNAME_EN": "Al Sha'ifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4164",
    "CITYNAME_AR": "ال معسبل",
    "CITYNAME_EN": "Al Ma'sil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4165",
    "CITYNAME_AR": "ال محدل",
    "CITYNAME_EN": "Al Mehdel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4166",
    "CITYNAME_AR": "ال فلتان وال حترش",
    "CITYNAME_EN": "Al Faltan and Al Hetrish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4167",
    "CITYNAME_AR": "آل نعيمه",
    "CITYNAME_EN": "Al Na'imah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4168",
    "CITYNAME_AR": "ال حزيب",
    "CITYNAME_EN": "Al Hozayb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4169",
    "CITYNAME_AR": "ال سعد",
    "CITYNAME_EN": "Al Sa'd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4170",
    "CITYNAME_AR": "المخاظه",
    "CITYNAME_EN": "Al-Makhazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4171",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "Al-Sriyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4172",
    "CITYNAME_AR": "رهوه الجعاده وظهايه",
    "CITYNAME_EN": "Rahwah, Al-Ja'danah and Zahayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4173",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4174",
    "CITYNAME_AR": "الزبيري",
    "CITYNAME_EN": "Az-Zubairi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4175",
    "CITYNAME_AR": "مكلاه",
    "CITYNAME_EN": "Maklah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4176",
    "CITYNAME_AR": "وادي النظر",
    "CITYNAME_EN": "Wadi An-Nazar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4177",
    "CITYNAME_AR": "بلقرن",
    "CITYNAME_EN": "Balqarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4178",
    "CITYNAME_AR": "باشوت",
    "CITYNAME_EN": "Bashout",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4179",
    "CITYNAME_AR": "ابو عروق",
    "CITYNAME_EN": "Abu Oruq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4180",
    "CITYNAME_AR": "ذايبرم",
    "CITYNAME_EN": "Za Yebrim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4181",
    "CITYNAME_AR": "الغول",
    "CITYNAME_EN": "Al Ghoul",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4182",
    "CITYNAME_AR": "المفجر",
    "CITYNAME_EN": "Al Mafjer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4183",
    "CITYNAME_AR": "الحوطه",
    "CITYNAME_EN": "Al Hawata",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4184",
    "CITYNAME_AR": "العلوبى",
    "CITYNAME_EN": "Al-Aluby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4185",
    "CITYNAME_AR": "ظرافه",
    "CITYNAME_EN": "Zarafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4186",
    "CITYNAME_AR": "منح الشرف",
    "CITYNAME_EN": "Manh Al-Sharaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4187",
    "CITYNAME_AR": "الحكيمه",
    "CITYNAME_EN": "Al-Hakimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4188",
    "CITYNAME_AR": "الغرس",
    "CITYNAME_EN": "Al-Ghars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4189",
    "CITYNAME_AR": "البقله",
    "CITYNAME_EN": "Al-Baqlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4190",
    "CITYNAME_AR": "حدباء القضوى",
    "CITYNAME_EN": "Hadbaa Al-Qadwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4191",
    "CITYNAME_AR": "الهرامس",
    "CITYNAME_EN": "Al Haramis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4192",
    "CITYNAME_AR": "ويدان",
    "CITYNAME_EN": "Widan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4193",
    "CITYNAME_AR": "حضن الزبد",
    "CITYNAME_EN": "Hodn Al-Zebd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4194",
    "CITYNAME_AR": "مزرعه المليحه",
    "CITYNAME_EN": "Al-malihah Farm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4195",
    "CITYNAME_AR": "حيله",
    "CITYNAME_EN": "Hilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4196",
    "CITYNAME_AR": "هدره",
    "CITYNAME_EN": "Hadrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4197",
    "CITYNAME_AR": "جليله السفلى",
    "CITYNAME_EN": "Jalilah (the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4198",
    "CITYNAME_AR": "روضه بن نورة",
    "CITYNAME_EN": "Rawad bin Nurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4199",
    "CITYNAME_AR": "المفجر",
    "CITYNAME_EN": "Al Mafjer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4200",
    "CITYNAME_AR": "الظيقة",
    "CITYNAME_EN": "Al-Ziqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4201",
    "CITYNAME_AR": "منح المحيا",
    "CITYNAME_EN": "Manh Al-Mahya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4202",
    "CITYNAME_AR": "الطليحه",
    "CITYNAME_EN": "Al-Taliha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4203",
    "CITYNAME_AR": "قريه صياده",
    "CITYNAME_EN": "Sayadah Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4204",
    "CITYNAME_AR": "بئر جبل",
    "CITYNAME_EN": "Bir Jabel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4205",
    "CITYNAME_AR": "طريب",
    "CITYNAME_EN": "Tarib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4206",
    "CITYNAME_AR": "العطيفه",
    "CITYNAME_EN": "Aleatifuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4207",
    "CITYNAME_AR": "المقراع",
    "CITYNAME_EN": "Almiqrae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4208",
    "CITYNAME_AR": "الجهراء",
    "CITYNAME_EN": "Aljuhara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4209",
    "CITYNAME_AR": "الحيه والمجراد",
    "CITYNAME_EN": "Alhayh Walmijrad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4210",
    "CITYNAME_AR": "الخناق",
    "CITYNAME_EN": "Alkhinaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4211",
    "CITYNAME_AR": "خابره",
    "CITYNAME_EN": "Khabirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4212",
    "CITYNAME_AR": "زهرة والمنح الزراعية",
    "CITYNAME_EN": "Zahrat Walmanh Alziraeia",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4213",
    "CITYNAME_AR": "وادي كرويل",
    "CITYNAME_EN": "Wadi Krwil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4214",
    "CITYNAME_AR": "المصبخ",
    "CITYNAME_EN": "Almusabikh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4215",
    "CITYNAME_AR": "بئر فرعه",
    "CITYNAME_EN": "Biir Fareah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4216",
    "CITYNAME_AR": "الحصيصة",
    "CITYNAME_EN": "Alhasisa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4217",
    "CITYNAME_AR": "المجزعه",
    "CITYNAME_EN": "Almujzieuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4218",
    "CITYNAME_AR": "احيمر",
    "CITYNAME_EN": "Ahymr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4219",
    "CITYNAME_AR": "لطيف",
    "CITYNAME_EN": "Latif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4220",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Alghabib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4221",
    "CITYNAME_AR": "حرشفه العليا (مجيراشة)",
    "CITYNAME_EN": "Harshifuh Aleulya (Mjirashat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4222",
    "CITYNAME_AR": "حرشفه السفلى (الحماد)",
    "CITYNAME_EN": "Harshafah Alsuflaa (Alahamad)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4223",
    "CITYNAME_AR": "البتيراء",
    "CITYNAME_EN": "Albtira'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4224",
    "CITYNAME_AR": "نمران",
    "CITYNAME_EN": "Namran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4225",
    "CITYNAME_AR": "نعاض",
    "CITYNAME_EN": "Naead",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4226",
    "CITYNAME_AR": "نحوت",
    "CITYNAME_EN": "Nahwt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4227",
    "CITYNAME_AR": "وبره",
    "CITYNAME_EN": "Wabarh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4228",
    "CITYNAME_AR": "وسط مرمى",
    "CITYNAME_EN": "Wasatun Marmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4229",
    "CITYNAME_AR": "الشباك",
    "CITYNAME_EN": "Alshibak",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4230",
    "CITYNAME_AR": "ملحة الاحباب",
    "CITYNAME_EN": "Mulihat Alaihbab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4231",
    "CITYNAME_AR": "البلس",
    "CITYNAME_EN": "Albals",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4232",
    "CITYNAME_AR": "حجان",
    "CITYNAME_EN": "Hajjan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4233",
    "CITYNAME_AR": "خراف",
    "CITYNAME_EN": "Kharraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4234",
    "CITYNAME_AR": "الوشيجه",
    "CITYNAME_EN": "Alwashijih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4235",
    "CITYNAME_AR": "السريحه والرشادا",
    "CITYNAME_EN": "Alsarihuh Walrashada",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4236",
    "CITYNAME_AR": "وادي الأثاء",
    "CITYNAME_EN": "Wadi Al'Atha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4237",
    "CITYNAME_AR": "المضه",
    "CITYNAME_EN": "Almaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4238",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Aljarabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4239",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Alrabuwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4240",
    "CITYNAME_AR": "المعيقل",
    "CITYNAME_EN": "Almueiqil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4241",
    "CITYNAME_AR": "ال جحرشه",
    "CITYNAME_EN": "Al Jahrashuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4242",
    "CITYNAME_AR": "الكوادر",
    "CITYNAME_EN": "Alkawadir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4243",
    "CITYNAME_AR": "ال معمر",
    "CITYNAME_EN": "Al Mueamar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4244",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Alfarshuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4245",
    "CITYNAME_AR": "الحشروج",
    "CITYNAME_EN": "Alhashruj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4246",
    "CITYNAME_AR": "العمود",
    "CITYNAME_EN": "Aleumud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4247",
    "CITYNAME_AR": "السلع",
    "CITYNAME_EN": "Alsilae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4248",
    "CITYNAME_AR": "الحفاير",
    "CITYNAME_EN": "Alhafayir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4249",
    "CITYNAME_AR": "الرويكبه",
    "CITYNAME_EN": "Alrwykibuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4250",
    "CITYNAME_AR": "صبيحاء",
    "CITYNAME_EN": "Sabiha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4251",
    "CITYNAME_AR": "ثفره",
    "CITYNAME_EN": "Thafarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4252",
    "CITYNAME_AR": "مهد الواديين",
    "CITYNAME_EN": "Mahd Alwadiin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4253",
    "CITYNAME_AR": "مصب وادي مراء",
    "CITYNAME_EN": "Masab Wadi Mira'An",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4254",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Alrayighuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4255",
    "CITYNAME_AR": "لسس",
    "CITYNAME_EN": "Lasus",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4256",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Alshifa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4257",
    "CITYNAME_AR": "المذيعير",
    "CITYNAME_EN": "Almadhieir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4258",
    "CITYNAME_AR": "الشارعه",
    "CITYNAME_EN": "Alshsharieuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4259",
    "CITYNAME_AR": "ملاح",
    "CITYNAME_EN": "Mlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4260",
    "CITYNAME_AR": "جوجا",
    "CITYNAME_EN": "Jawjanaan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4261",
    "CITYNAME_AR": "العدابة",
    "CITYNAME_EN": "Aleadaba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4262",
    "CITYNAME_AR": "عرقة ال سليمان",
    "CITYNAME_EN": "Earaqat Al Sulayman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4263",
    "CITYNAME_AR": "الغضاه",
    "CITYNAME_EN": "Alghadah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4264",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Alfireuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4265",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Alburqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4266",
    "CITYNAME_AR": "الخالدية",
    "CITYNAME_EN": "Alkhalidia",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4267",
    "CITYNAME_AR": "الشعله",
    "CITYNAME_EN": "Alshaeluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4268",
    "CITYNAME_AR": "الروضة",
    "CITYNAME_EN": "Alruwda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4269",
    "CITYNAME_AR": "آل خجيم",
    "CITYNAME_EN": "Al Khajim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4270",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Alrafayie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4271",
    "CITYNAME_AR": "الخزيفاء",
    "CITYNAME_EN": "Alkhazifa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4272",
    "CITYNAME_AR": "ال جفون",
    "CITYNAME_EN": "Al Jafun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4273",
    "CITYNAME_AR": "الزبره",
    "CITYNAME_EN": "Al-Zebrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4274",
    "CITYNAME_AR": "الطوى",
    "CITYNAME_EN": "Altua",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4275",
    "CITYNAME_AR": "الاقور",
    "CITYNAME_EN": "Alawqar",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4276",
    "CITYNAME_AR": "الخشوب",
    "CITYNAME_EN": "Alkhashub",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4277",
    "CITYNAME_AR": "الثرماله",
    "CITYNAME_EN": "Alrethmalah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4278",
    "CITYNAME_AR": "بئر عسكر",
    "CITYNAME_EN": "Beir Askar",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4279",
    "CITYNAME_AR": "بظا",
    "CITYNAME_EN": "Baza",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4280",
    "CITYNAME_AR": "بني حويه",
    "CITYNAME_EN": "Bani Hawbah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4281",
    "CITYNAME_AR": "عشه الاجرف",
    "CITYNAME_EN": "Ishah Alajraf",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4282",
    "CITYNAME_AR": "عشه الرخم",
    "CITYNAME_EN": "Ishah Alrakham",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4283",
    "CITYNAME_AR": "عشه الساره (ساره)",
    "CITYNAME_EN": "Ishah As-Sarah (Sarah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4284",
    "CITYNAME_AR": "بني مثيب",
    "CITYNAME_EN": "Bani Matheb",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4285",
    "CITYNAME_AR": "الغثمه",
    "CITYNAME_EN": "Alghathmah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4286",
    "CITYNAME_AR": "باديه ساره",
    "CITYNAME_EN": "Badyah Sarah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4287",
    "CITYNAME_AR": "هجره النميص",
    "CITYNAME_EN": "Hijrah An-Names",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4288",
    "CITYNAME_AR": "باديه ذى حباب",
    "CITYNAME_EN": "Badyah Ze Habab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4289",
    "CITYNAME_AR": "بئر الصاله",
    "CITYNAME_EN": "Beir As-Salah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4290",
    "CITYNAME_AR": "اللحمتين",
    "CITYNAME_EN": "Alluhmatayn",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4291",
    "CITYNAME_AR": "عاكفه المعاين",
    "CITYNAME_EN": "Akefah Almuayen",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4292",
    "CITYNAME_AR": "السقامه",
    "CITYNAME_EN": "As-Suqamah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4293",
    "CITYNAME_AR": "الحموم",
    "CITYNAME_EN": "Alhumum",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4294",
    "CITYNAME_AR": "ذات المزن",
    "CITYNAME_EN": "Zat Almuzun",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4295",
    "CITYNAME_AR": "ذات الضب",
    "CITYNAME_EN": "Zat Aldab",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4296",
    "CITYNAME_AR": "السواد",
    "CITYNAME_EN": "As-Suwad",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4297",
    "CITYNAME_AR": "مفرق عاكفه (الشطيبه)",
    "CITYNAME_EN": "Mefreq Aukafah (Ash-Shutaybah)",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4298",
    "CITYNAME_AR": "العوافي",
    "CITYNAME_EN": "Alawafi",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4299",
    "CITYNAME_AR": "الاقرح",
    "CITYNAME_EN": "Alaqrah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4300",
    "CITYNAME_AR": "الثعيلبه",
    "CITYNAME_EN": "Ath-Thuaylabah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4301",
    "CITYNAME_AR": "ابا الرشاش",
    "CITYNAME_EN": "Aba Alrashash",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4302",
    "CITYNAME_AR": "بادية الرغام",
    "CITYNAME_EN": "Badyah Ar-Rugham",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4303",
    "CITYNAME_AR": "الصفه",
    "CITYNAME_EN": "Alsafuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4304",
    "CITYNAME_AR": "الخيع والمثلث",
    "CITYNAME_EN": "Alkhaye Walmathlath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4305",
    "CITYNAME_AR": "الحوش",
    "CITYNAME_EN": "Alhawsh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4306",
    "CITYNAME_AR": "الغوانمه",
    "CITYNAME_EN": "Alghawanimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4307",
    "CITYNAME_AR": "غبيشه",
    "CITYNAME_EN": "Ghbishh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4308",
    "CITYNAME_AR": "المشاريف",
    "CITYNAME_EN": "Almasharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4309",
    "CITYNAME_AR": "الرميضه",
    "CITYNAME_EN": "Alramiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4310",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Alshaeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4311",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4312",
    "CITYNAME_AR": "المحاريف",
    "CITYNAME_EN": "Almaharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4313",
    "CITYNAME_AR": "الحداب",
    "CITYNAME_EN": "Alhidab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4314",
    "CITYNAME_AR": "قوز ال عاصب",
    "CITYNAME_EN": "Quz Al Easib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4315",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Aldaras",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4316",
    "CITYNAME_AR": "حدبه عنتر",
    "CITYNAME_EN": "Hadabuh Eantar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4317",
    "CITYNAME_AR": "السبطه",
    "CITYNAME_EN": "Alsabtuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4318",
    "CITYNAME_AR": "الحيل",
    "CITYNAME_EN": "Alhayl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4319",
    "CITYNAME_AR": "الرقيبه",
    "CITYNAME_EN": "Alraqibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4320",
    "CITYNAME_AR": "الملحه والردوم",
    "CITYNAME_EN": "Almalhuh Walrudum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4321",
    "CITYNAME_AR": "الجندل",
    "CITYNAME_EN": "Aljundal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4322",
    "CITYNAME_AR": "الشاجن",
    "CITYNAME_EN": "Alshaajin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4323",
    "CITYNAME_AR": "الخلفاء وشعيب العرج",
    "CITYNAME_EN": "Alkhulafa' Washaeayb Alearaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4324",
    "CITYNAME_AR": "البعوض",
    "CITYNAME_EN": "Albueud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4325",
    "CITYNAME_AR": "السحيه",
    "CITYNAME_EN": "Alsahih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4326",
    "CITYNAME_AR": "قرن حجرى والحمر",
    "CITYNAME_EN": "Qarn Hujrana Walhumr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4327",
    "CITYNAME_AR": "الوصيل",
    "CITYNAME_EN": "Alwasil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4328",
    "CITYNAME_AR": "بئر مرسال",
    "CITYNAME_EN": "Biir Marsal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4329",
    "CITYNAME_AR": "بئر مقره",
    "CITYNAME_EN": "Biir Maqarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4330",
    "CITYNAME_AR": "المقفى",
    "CITYNAME_EN": "Almaqfaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4331",
    "CITYNAME_AR": "بئر صعوه",
    "CITYNAME_EN": "Bayir Saeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4332",
    "CITYNAME_AR": "نوفه",
    "CITYNAME_EN": "Nufh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4333",
    "CITYNAME_AR": "بئر زلبان",
    "CITYNAME_EN": "Biir Zulban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4334",
    "CITYNAME_AR": "النوافعه",
    "CITYNAME_EN": "Alnawafieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4335",
    "CITYNAME_AR": "قرن الاعظم",
    "CITYNAME_EN": "Qarn Alaezm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4336",
    "CITYNAME_AR": "القيطه",
    "CITYNAME_EN": "Alqituh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4337",
    "CITYNAME_AR": "الاشظاف",
    "CITYNAME_EN": "Alaishzaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4338",
    "CITYNAME_AR": "كياد",
    "CITYNAME_EN": "Kiad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4339",
    "CITYNAME_AR": "قوز الشاهد",
    "CITYNAME_EN": "Quz Alshshahid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4340",
    "CITYNAME_AR": "زباره عنتر",
    "CITYNAME_EN": "Zubaruh Eantar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4341",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Aleaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4342",
    "CITYNAME_AR": "الصوالحه",
    "CITYNAME_EN": "Alsawalihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4343",
    "CITYNAME_AR": "كدوه الاعوج (الكدوة)",
    "CITYNAME_EN": "Kuduh Alaewuj (Alkaduat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4344",
    "CITYNAME_AR": "الاشراف (بئر الحجر)",
    "CITYNAME_EN": "Al'Iishraf (Byir Alhujr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4345",
    "CITYNAME_AR": "ام الحاضر",
    "CITYNAME_EN": "Umm Alhadir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4346",
    "CITYNAME_AR": "ابو الرين",
    "CITYNAME_EN": "'Abu Alryn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4347",
    "CITYNAME_AR": "بئر مشعابه",
    "CITYNAME_EN": "Biir Misheabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4348",
    "CITYNAME_AR": "العفاء",
    "CITYNAME_EN": "Aleafa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4349",
    "CITYNAME_AR": "الفنحه والقتاد",
    "CITYNAME_EN": "Alfanhuh Walqitad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4350",
    "CITYNAME_AR": "قعموصه",
    "CITYNAME_EN": "Qaemusih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4351",
    "CITYNAME_AR": "دقيشه",
    "CITYNAME_EN": "Daqishuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4352",
    "CITYNAME_AR": "بدره",
    "CITYNAME_EN": "Badarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4353",
    "CITYNAME_AR": "الخوالده",
    "CITYNAME_EN": "Alkhawaliduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4354",
    "CITYNAME_AR": "الهيجه",
    "CITYNAME_EN": "Alhayjuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4355",
    "CITYNAME_AR": "الدبش",
    "CITYNAME_EN": "Aldabash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4356",
    "CITYNAME_AR": "الدبيسيه",
    "CITYNAME_EN": "Aldabisiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4357",
    "CITYNAME_AR": "باشوت",
    "CITYNAME_EN": "Bashut",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4358",
    "CITYNAME_AR": "البيضين",
    "CITYNAME_EN": "Albaydin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4359",
    "CITYNAME_AR": "الفريق",
    "CITYNAME_EN": "Alfariq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4360",
    "CITYNAME_AR": "السلامه",
    "CITYNAME_EN": "Alsalamuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4361",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Alkhadra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4362",
    "CITYNAME_AR": "الحص",
    "CITYNAME_EN": "Alhsu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4363",
    "CITYNAME_AR": "عظمه",
    "CITYNAME_EN": "Eazamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4364",
    "CITYNAME_AR": "مخطط شيع بالعمور",
    "CITYNAME_EN": "Mukhatat Shiae Bialeumur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4365",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4366",
    "CITYNAME_AR": "المعاشيه",
    "CITYNAME_EN": "Almaeashih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4367",
    "CITYNAME_AR": "فصله",
    "CITYNAME_EN": "Fasalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4368",
    "CITYNAME_AR": "الشيعه",
    "CITYNAME_EN": "Alshiyeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4369",
    "CITYNAME_AR": "الساده (حلى القديم)",
    "CITYNAME_EN": "Alsaaduh (Hlaa Alqadiym)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4370",
    "CITYNAME_AR": "الساده ال مهاب",
    "CITYNAME_EN": "Alssaduh Al Mahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4371",
    "CITYNAME_AR": "ربيعه الهجره",
    "CITYNAME_EN": "Rabieuh Alhijaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4372",
    "CITYNAME_AR": "الخشيعى",
    "CITYNAME_EN": "Alkhushieaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4373",
    "CITYNAME_AR": "الصلب",
    "CITYNAME_EN": "Alsulb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4374",
    "CITYNAME_AR": "وينة حومان (الجندب)",
    "CITYNAME_EN": "Wayinat Hawman (Aljndab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4375",
    "CITYNAME_AR": "العصامى",
    "CITYNAME_EN": "Aleasama",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4376",
    "CITYNAME_AR": "الفلحه",
    "CITYNAME_EN": "Alfalhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4377",
    "CITYNAME_AR": "الوسقه",
    "CITYNAME_EN": "Alwasqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4378",
    "CITYNAME_AR": "الفاهمه",
    "CITYNAME_EN": "Alfahimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4379",
    "CITYNAME_AR": "عاشوره",
    "CITYNAME_EN": "Eashuruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4380",
    "CITYNAME_AR": "الكدوه",
    "CITYNAME_EN": "Alkuduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4381",
    "CITYNAME_AR": "ابوالجزعه",
    "CITYNAME_EN": "Abwaljizeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4382",
    "CITYNAME_AR": "ابوالغرفه",
    "CITYNAME_EN": "Abwalghrfh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4383",
    "CITYNAME_AR": "الرون",
    "CITYNAME_EN": "Alrun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4384",
    "CITYNAME_AR": "العذير",
    "CITYNAME_EN": "Aleadhir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4385",
    "CITYNAME_AR": "حلي",
    "CITYNAME_EN": "Haly",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4386",
    "CITYNAME_AR": "الصرير",
    "CITYNAME_EN": "Alsarir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4387",
    "CITYNAME_AR": "اشقاب",
    "CITYNAME_EN": "Ashqa",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4388",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghawan",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4389",
    "CITYNAME_AR": "الحاجب الحمر",
    "CITYNAME_EN": "Alhajeb Alhumur",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4390",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Ar-Rahbah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4391",
    "CITYNAME_AR": "اوطيده",
    "CITYNAME_EN": "Awtaydah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4392",
    "CITYNAME_AR": "علو السواد",
    "CITYNAME_EN": "Aulu As-Sawad",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4393",
    "CITYNAME_AR": "ظهران الجنوب",
    "CITYNAME_EN": "Dhahran Al Janub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4394",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4395",
    "CITYNAME_AR": "ال رشيد",
    "CITYNAME_EN": "Al Rashied",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4396",
    "CITYNAME_AR": "نصب جاذن",
    "CITYNAME_EN": "Nasb Jazin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4397",
    "CITYNAME_AR": "ال سـيار",
    "CITYNAME_EN": "Al Seyar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4398",
    "CITYNAME_AR": "ال سـحامي",
    "CITYNAME_EN": "Al Sahamy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4399",
    "CITYNAME_AR": "ردين الاسفل",
    "CITYNAME_EN": "Radin Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4400",
    "CITYNAME_AR": "عين الضبع",
    "CITYNAME_EN": "Ain Al-Dab'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4401",
    "CITYNAME_AR": "الولاج (الدرب)",
    "CITYNAME_EN": "Al-Walaj (Al-darb)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4402",
    "CITYNAME_AR": "الحجفاء",
    "CITYNAME_EN": "Al-Hajfaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4403",
    "CITYNAME_AR": "المسيل",
    "CITYNAME_EN": "Al-Masil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4404",
    "CITYNAME_AR": "المسيال",
    "CITYNAME_EN": "Al-Mesyal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4405",
    "CITYNAME_AR": "الصخيره",
    "CITYNAME_EN": "Al-Sakhirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4406",
    "CITYNAME_AR": "منتزه العشة",
    "CITYNAME_EN": "Al-Oshah Park ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4407",
    "CITYNAME_AR": "عشة الخنق",
    "CITYNAME_EN": "Oshah Al-Khanq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4408",
    "CITYNAME_AR": "العرج",
    "CITYNAME_EN": "Alaraj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4409",
    "CITYNAME_AR": "قرى وادي المجازة",
    "CITYNAME_EN": "Wadi Al-Majazah Villages",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4410",
    "CITYNAME_AR": "جعار",
    "CITYNAME_EN": "Je'ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4411",
    "CITYNAME_AR": "قرى وادي ملاح",
    "CITYNAME_EN": "Wadi Melah Villages",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4412",
    "CITYNAME_AR": "ثال",
    "CITYNAME_EN": "Thal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4413",
    "CITYNAME_AR": "قرى وادي رشاد",
    "CITYNAME_EN": "Wadi Rashad Villages",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4414",
    "CITYNAME_AR": "ثلاه",
    "CITYNAME_EN": "Thelah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4415",
    "CITYNAME_AR": "بئر عقق",
    "CITYNAME_EN": "Bir Aqq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4416",
    "CITYNAME_AR": "الخايس",
    "CITYNAME_EN": "Al-Khayis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4417",
    "CITYNAME_AR": "علب",
    "CITYNAME_EN": "Ulab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4418",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Al-Dahrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4419",
    "CITYNAME_AR": "الزج",
    "CITYNAME_EN": "Al-Zaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4420",
    "CITYNAME_AR": "ظهره الغرزه",
    "CITYNAME_EN": "Zohrah Al-Gharzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4421",
    "CITYNAME_AR": "القانى",
    "CITYNAME_EN": "Al-Qany",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4422",
    "CITYNAME_AR": "نجد الحطب",
    "CITYNAME_EN": "Najd Al-Hatab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4423",
    "CITYNAME_AR": "حظن لثب",
    "CITYNAME_EN": "Hodn Lethb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4424",
    "CITYNAME_AR": "حظن القمشع",
    "CITYNAME_EN": "Hezn Al-Qamshe'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4425",
    "CITYNAME_AR": "حظن جرذان",
    "CITYNAME_EN": "Hezn Jerzan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4426",
    "CITYNAME_AR": "عرابه",
    "CITYNAME_EN": "Arabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4427",
    "CITYNAME_AR": "الشطين",
    "CITYNAME_EN": "Al-Shatin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4428",
    "CITYNAME_AR": "الحضار",
    "CITYNAME_EN": "Al-Hedar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4429",
    "CITYNAME_AR": "عيون الثورين",
    "CITYNAME_EN": "Oyun Al-Thurin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4430",
    "CITYNAME_AR": "الصويح",
    "CITYNAME_EN": "Al-Suwaih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4431",
    "CITYNAME_AR": "ال محظي",
    "CITYNAME_EN": "Al Mahzy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4432",
    "CITYNAME_AR": "رهوه ال محظي",
    "CITYNAME_EN": "Rahwah Al Mahzy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4433",
    "CITYNAME_AR": "الفرش جوه ال محظي",
    "CITYNAME_EN": "Al-Farsh Juwah Al Mahzy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4434",
    "CITYNAME_AR": "الحيفه",
    "CITYNAME_EN": "Al-Hifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4435",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Far'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4436",
    "CITYNAME_AR": "اللج",
    "CITYNAME_EN": "Allaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4437",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Al-Shatbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4438",
    "CITYNAME_AR": "الملتويه",
    "CITYNAME_EN": "Al-Multawiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4439",
    "CITYNAME_AR": "الرفيق",
    "CITYNAME_EN": "Al-Rafiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4440",
    "CITYNAME_AR": "المنجب",
    "CITYNAME_EN": "Al-Manjeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4441",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Al Rahbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4442",
    "CITYNAME_AR": "الصايف",
    "CITYNAME_EN": "Al-Sayif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4443",
    "CITYNAME_AR": "الصوح",
    "CITYNAME_EN": "Al Sahou",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4444",
    "CITYNAME_AR": "العفاره",
    "CITYNAME_EN": "Al-Afarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4445",
    "CITYNAME_AR": "وادى رواء",
    "CITYNAME_EN": "Wadi Ruwaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4446",
    "CITYNAME_AR": "وادى العين",
    "CITYNAME_EN": "Wadi Al-Ein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4447",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4448",
    "CITYNAME_AR": "الحمره (القراره)",
    "CITYNAME_EN": "Al-Hamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4449",
    "CITYNAME_AR": "الحاجر",
    "CITYNAME_EN": "Al Hajer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4450",
    "CITYNAME_AR": "المسوح",
    "CITYNAME_EN": "Al-Masuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4451",
    "CITYNAME_AR": "العذار",
    "CITYNAME_EN": "Al-Ezar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4452",
    "CITYNAME_AR": "هجرة العبب",
    "CITYNAME_EN": "Hijrah Al-Abb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4453",
    "CITYNAME_AR": "هجرة الحبابة",
    "CITYNAME_EN": "Hijrah Al-Hababah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4454",
    "CITYNAME_AR": "زيانه",
    "CITYNAME_EN": "Zabanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4455",
    "CITYNAME_AR": "المقام",
    "CITYNAME_EN": "Al-Muqam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4456",
    "CITYNAME_AR": "مكة العذار",
    "CITYNAME_EN": "Mekkah Al-Azar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4457",
    "CITYNAME_AR": "الغول",
    "CITYNAME_EN": "Al Ghoul",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4458",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al-Baq'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4459",
    "CITYNAME_AR": "عدن",
    "CITYNAME_EN": "Adn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4460",
    "CITYNAME_AR": "وادى رحب",
    "CITYNAME_EN": "Wadi Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4461",
    "CITYNAME_AR": "الغايل",
    "CITYNAME_EN": "Al-Ghayil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4462",
    "CITYNAME_AR": "عينه ال مفتاح",
    "CITYNAME_EN": "Einah Ak Meftah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4463",
    "CITYNAME_AR": "سلف دغمه",
    "CITYNAME_EN": "Salf Daghmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4464",
    "CITYNAME_AR": "وادى الغايل",
    "CITYNAME_EN": "Wadi Al-Ghayil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4465",
    "CITYNAME_AR": "وادى قاعه",
    "CITYNAME_EN": "Wadi Qa'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4466",
    "CITYNAME_AR": "وادي طريان",
    "CITYNAME_EN": "Wadi Taryan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4467",
    "CITYNAME_AR": "وادى المسناء",
    "CITYNAME_EN": "Wadi Al-Masnaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4468",
    "CITYNAME_AR": "مريصعه",
    "CITYNAME_EN": "Morayse'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4469",
    "CITYNAME_AR": "رفده",
    "CITYNAME_EN": "Rafdah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4470",
    "CITYNAME_AR": "ابوالغشوه",
    "CITYNAME_EN": "Abu Al-Ghashwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4471",
    "CITYNAME_AR": "فاعه",
    "CITYNAME_EN": "Fa'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4472",
    "CITYNAME_AR": "ابوقبرين",
    "CITYNAME_EN": "Abu Qabrin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4473",
    "CITYNAME_AR": "المضباع الاعلى",
    "CITYNAME_EN": "Al-Medba' (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4474",
    "CITYNAME_AR": "السراق",
    "CITYNAME_EN": "Al-Soraq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4475",
    "CITYNAME_AR": "خومان",
    "CITYNAME_EN": "Khuman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4476",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al-Helah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4477",
    "CITYNAME_AR": "صوبه",
    "CITYNAME_EN": "Subah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4478",
    "CITYNAME_AR": "دقم زهير",
    "CITYNAME_EN": "Daqm Zohayr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4479",
    "CITYNAME_AR": "ابن رزيقه",
    "CITYNAME_EN": "Ibn Raziqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4480",
    "CITYNAME_AR": "الخدره",
    "CITYNAME_EN": "Al-Khedrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4481",
    "CITYNAME_AR": "حبس معجب",
    "CITYNAME_EN": "Habs Me'jeb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4482",
    "CITYNAME_AR": "دقم الحزيز",
    "CITYNAME_EN": "Doqm Al-Haziz",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4483",
    "CITYNAME_AR": "القتب",
    "CITYNAME_EN": "Al-Qatb",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "4484",
    "CITYNAME_AR": "شعب صالح",
    "CITYNAME_EN": "Shuab Saleh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4485",
    "CITYNAME_AR": "القرو",
    "CITYNAME_EN": "Al Qarou",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4486",
    "CITYNAME_AR": "الكظامه",
    "CITYNAME_EN": "Al Kazamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4487",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4488",
    "CITYNAME_AR": "قزعه راشد",
    "CITYNAME_EN": "Qazeit Rashid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4489",
    "CITYNAME_AR": "حمران",
    "CITYNAME_EN": "Hamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4490",
    "CITYNAME_AR": "الكماحين",
    "CITYNAME_EN": "Al Kamaheen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4491",
    "CITYNAME_AR": "المحرق (بتلياني)",
    "CITYNAME_EN": "Al Mahreq (Betalyani)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4492",
    "CITYNAME_AR": "أضم",
    "CITYNAME_EN": "Adham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4493",
    "CITYNAME_AR": "ابوالهضبه",
    "CITYNAME_EN": "Abo Al Hadabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4494",
    "CITYNAME_AR": "حبوا اللامه",
    "CITYNAME_EN": "Habwa Al Lamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4495",
    "CITYNAME_AR": "شعب رابعه",
    "CITYNAME_EN": "Shuab Rabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4496",
    "CITYNAME_AR": "حدبه الشغيفه",
    "CITYNAME_EN": "Hadabah Ash-Shuqayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4497",
    "CITYNAME_AR": "الدحيله",
    "CITYNAME_EN": "Ad-Duhailah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4498",
    "CITYNAME_AR": "ام الراكه",
    "CITYNAME_EN": "Om Al-Rakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4499",
    "CITYNAME_AR": "الجرف الاحمر",
    "CITYNAME_EN": "Al Jarf Al Ahmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4500",
    "CITYNAME_AR": "الأثايب",
    "CITYNAME_EN": "Al Athayeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4501",
    "CITYNAME_AR": "المتعكر الاعلى والاسفل",
    "CITYNAME_EN": "Al Mutaaqer Al Aala wa Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4502",
    "CITYNAME_AR": "حدبه روضان",
    "CITYNAME_EN": "Hadabah Radwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4503",
    "CITYNAME_AR": "قزعه القرنين",
    "CITYNAME_EN": "Qazaet Al Qarnayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4504",
    "CITYNAME_AR": "حبوا الصديره",
    "CITYNAME_EN": "Habwa As-Sudayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4505",
    "CITYNAME_AR": "خريق أندح",
    "CITYNAME_EN": "Khareq Andah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4506",
    "CITYNAME_AR": "العريض",
    "CITYNAME_EN": "Al Areed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4507",
    "CITYNAME_AR": "حبوا قراض وأم العهد",
    "CITYNAME_EN": "Habwa Qurad wa Om Al Ahad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4508",
    "CITYNAME_AR": "قرن موسى",
    "CITYNAME_EN": "Qarn Moussa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4509",
    "CITYNAME_AR": "الضبير والشهراني",
    "CITYNAME_EN": "Ad-Dubayer wa Ash-Shahrani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4510",
    "CITYNAME_AR": "فرعه سلعه",
    "CITYNAME_EN": "Fara'h Selaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4511",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4512",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4513",
    "CITYNAME_AR": "فراع النعمه",
    "CITYNAME_EN": "Feraa An-Neamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4514",
    "CITYNAME_AR": "الأوريكه والوقور",
    "CITYNAME_EN": "Al Awreqyah wa Al Waqor",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4515",
    "CITYNAME_AR": "السحا وهيفات والسلام",
    "CITYNAME_EN": "As-Saha wa Hayfat wa As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4516",
    "CITYNAME_AR": "القفله",
    "CITYNAME_EN": "Al Qaflah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4517",
    "CITYNAME_AR": "الفاتح والحنو",
    "CITYNAME_EN": "Al Fateh wa Al Hanou",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4518",
    "CITYNAME_AR": "كساب",
    "CITYNAME_EN": "Kassab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4519",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4520",
    "CITYNAME_AR": "الوقبه والمريبي والأصول",
    "CITYNAME_EN": "Al Waqbah wa Al Muraybi wa Al Asool",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4521",
    "CITYNAME_AR": "حراض الاعلى",
    "CITYNAME_EN": "Herad Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4522",
    "CITYNAME_AR": "حراض الاسفل",
    "CITYNAME_EN": "Herad Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4523",
    "CITYNAME_AR": "قرن ابو العشه",
    "CITYNAME_EN": "Qarn Abo Al Oshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4524",
    "CITYNAME_AR": "السوقه",
    "CITYNAME_EN": "As-Sawqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4525",
    "CITYNAME_AR": "الضبع",
    "CITYNAME_EN": "Ad-Dabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4526",
    "CITYNAME_AR": "الشهباء",
    "CITYNAME_EN": "Ash-Shahbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4527",
    "CITYNAME_AR": "صحاح",
    "CITYNAME_EN": "Sehah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4528",
    "CITYNAME_AR": "ابو الحلاة",
    "CITYNAME_EN": "Abo Al Halah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4529",
    "CITYNAME_AR": "الاعلى",
    "CITYNAME_EN": "Al-A'la",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4530",
    "CITYNAME_AR": "الحبواء",
    "CITYNAME_EN": "Al Hawbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4531",
    "CITYNAME_AR": "الصار",
    "CITYNAME_EN": "As-Sar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4532",
    "CITYNAME_AR": "زايزاة",
    "CITYNAME_EN": "Zayzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4533",
    "CITYNAME_AR": "ام الحجر",
    "CITYNAME_EN": "Om Al Hajar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4534",
    "CITYNAME_AR": "الصفيح",
    "CITYNAME_EN": "As-Safeeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4535",
    "CITYNAME_AR": "صوان",
    "CITYNAME_EN": "Sawan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4536",
    "CITYNAME_AR": "أم حطب",
    "CITYNAME_EN": "Om Hatab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4537",
    "CITYNAME_AR": "صهوان",
    "CITYNAME_EN": "Sahwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4538",
    "CITYNAME_AR": "دحال علياء",
    "CITYNAME_EN": "Dahal Alyaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4539",
    "CITYNAME_AR": "دحله العبد",
    "CITYNAME_EN": "Dahal Al Abd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4540",
    "CITYNAME_AR": "الاحفاء الاعلى",
    "CITYNAME_EN": "Al Ahfaa Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4541",
    "CITYNAME_AR": "الأحفاء الأسفل",
    "CITYNAME_EN": "Al Ahfaa Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4542",
    "CITYNAME_AR": "المحوله",
    "CITYNAME_EN": "Al Muhawalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4543",
    "CITYNAME_AR": "القرشوع بالكفو",
    "CITYNAME_EN": "Al Qarshua, Al Kafou",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4544",
    "CITYNAME_AR": "عريض القريات",
    "CITYNAME_EN": "Ared Al Qaryat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4545",
    "CITYNAME_AR": "القرشوع بملحه",
    "CITYNAME_EN": "Al Qarshua, Malahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4546",
    "CITYNAME_AR": "قرينه الجرب",
    "CITYNAME_EN": "Quraynah Al Jarab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4547",
    "CITYNAME_AR": "الدثينه العجرم",
    "CITYNAME_EN": "Ad-Duthaynah Al Ajram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4548",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Al Malhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4549",
    "CITYNAME_AR": "الحجيره",
    "CITYNAME_EN": "Al Hujayra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4550",
    "CITYNAME_AR": "حبو الحبس",
    "CITYNAME_EN": "Habwa Al Habs",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4551",
    "CITYNAME_AR": "الحويحه",
    "CITYNAME_EN": "Al Huwayhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4552",
    "CITYNAME_AR": "كعشم والفرعه",
    "CITYNAME_EN": "Kaasham wa Al Faraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4553",
    "CITYNAME_AR": "القهب",
    "CITYNAME_EN": "Al Qahb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4554",
    "CITYNAME_AR": "حتيرش (الحريته)",
    "CITYNAME_EN": "Hutayrash (Al Haretah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4555",
    "CITYNAME_AR": "قهب ساتر",
    "CITYNAME_EN": "Qahb Sater",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4556",
    "CITYNAME_AR": "قهب الدمينه",
    "CITYNAME_EN": "Qahb Ad-Dumaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4557",
    "CITYNAME_AR": "المثلان",
    "CITYNAME_EN": "Al Mathlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4558",
    "CITYNAME_AR": "شعيثه",
    "CITYNAME_EN": "Shuaythah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4559",
    "CITYNAME_AR": "حيصل الاسفل",
    "CITYNAME_EN": "Haysal Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4560",
    "CITYNAME_AR": "حيصل الاعلى",
    "CITYNAME_EN": "Haysal Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4561",
    "CITYNAME_AR": "حجفه مقنع",
    "CITYNAME_EN": "Hajfah Muqnei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4562",
    "CITYNAME_AR": "الأعيبل",
    "CITYNAME_EN": "Al Oaybal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4563",
    "CITYNAME_AR": "الجرثوم",
    "CITYNAME_EN": "Al Garthoum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4564",
    "CITYNAME_AR": "المحال",
    "CITYNAME_EN": "Al Muhal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4565",
    "CITYNAME_AR": "قرن أثبه",
    "CITYNAME_EN": "Qarn Athabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4566",
    "CITYNAME_AR": "أم الخياله",
    "CITYNAME_EN": "Om Al Khayyalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4567",
    "CITYNAME_AR": "أم الباب",
    "CITYNAME_EN": "Om Al Bab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4568",
    "CITYNAME_AR": "قزعه البير",
    "CITYNAME_EN": "Qazeit Al Beir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4569",
    "CITYNAME_AR": "حبواء البير",
    "CITYNAME_EN": "Habwaa Al Beir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4570",
    "CITYNAME_AR": "المنسب",
    "CITYNAME_EN": "Al Mansab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4571",
    "CITYNAME_AR": "اللغيسيم",
    "CITYNAME_EN": "Al Lughaytham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4572",
    "CITYNAME_AR": "القريع",
    "CITYNAME_EN": "Al Quraya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4573",
    "CITYNAME_AR": "قزعه ابوالرخيم",
    "CITYNAME_EN": "Qazaet Abo Al-Rakhem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4574",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al Mesbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4575",
    "CITYNAME_AR": "اللصيفاء",
    "CITYNAME_EN": "Al Lusayqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4576",
    "CITYNAME_AR": "العشيه",
    "CITYNAME_EN": "Al Ashyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4577",
    "CITYNAME_AR": "السدين",
    "CITYNAME_EN": "As-Saden",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4578",
    "CITYNAME_AR": "جبل الصلعاء",
    "CITYNAME_EN": "As-Salaa mountain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4579",
    "CITYNAME_AR": "دقم الجبهه",
    "CITYNAME_EN": "Daqam Al Jabhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4580",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Al Maeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4581",
    "CITYNAME_AR": "الستاره",
    "CITYNAME_EN": "As-Setarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4582",
    "CITYNAME_AR": "جابر",
    "CITYNAME_EN": "Jaber",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4583",
    "CITYNAME_AR": "المكدور",
    "CITYNAME_EN": "Al Makdour",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4584",
    "CITYNAME_AR": "المبداه",
    "CITYNAME_EN": "Al Mubdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4585",
    "CITYNAME_AR": "سوق الاثنين",
    "CITYNAME_EN": "Souq Al Ethnayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4586",
    "CITYNAME_AR": "أوجر",
    "CITYNAME_EN": "Oujar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4587",
    "CITYNAME_AR": "القريع",
    "CITYNAME_EN": "Al Quraya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4588",
    "CITYNAME_AR": "الوقرين",
    "CITYNAME_EN": "Al Waqrayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4589",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4590",
    "CITYNAME_AR": "الرزنه",
    "CITYNAME_EN": "Ar-Razanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4591",
    "CITYNAME_AR": "الطايسه",
    "CITYNAME_EN": "At-Taysah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4592",
    "CITYNAME_AR": "المظيحيه",
    "CITYNAME_EN": "Al Muzayhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4593",
    "CITYNAME_AR": "اللصيق",
    "CITYNAME_EN": "Al Laseeq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4594",
    "CITYNAME_AR": "الوجره",
    "CITYNAME_EN": "Al Wagarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4595",
    "CITYNAME_AR": "ابونصاب",
    "CITYNAME_EN": "Abo Nasab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4596",
    "CITYNAME_AR": "شقيره",
    "CITYNAME_EN": "Shuqayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4597",
    "CITYNAME_AR": "دحله اللخفة",
    "CITYNAME_EN": "Dahlah Al Lakhfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4598",
    "CITYNAME_AR": "الحليات",
    "CITYNAME_EN": "Al Hulayat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4599",
    "CITYNAME_AR": "شعيب مرعه",
    "CITYNAME_EN": "Shuab Maraah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4600",
    "CITYNAME_AR": "الرزاع",
    "CITYNAME_EN": "Ar-Razaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4601",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4602",
    "CITYNAME_AR": "أضبار",
    "CITYNAME_EN": "Adbar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4603",
    "CITYNAME_AR": "الحلق",
    "CITYNAME_EN": "Al Halaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4604",
    "CITYNAME_AR": "صعيبه",
    "CITYNAME_EN": "Suaibah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4605",
    "CITYNAME_AR": "الباثه",
    "CITYNAME_EN": "Al Bathah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4606",
    "CITYNAME_AR": "دحال الصرحه",
    "CITYNAME_EN": "Dehal As-Sarahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4607",
    "CITYNAME_AR": "الجايز",
    "CITYNAME_EN": "Al Jayer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4608",
    "CITYNAME_AR": "الهطيبللى",
    "CITYNAME_EN": "Al Hutaybeli",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4609",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qazaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4610",
    "CITYNAME_AR": "النخل",
    "CITYNAME_EN": "An-Nakhl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4611",
    "CITYNAME_AR": "عويه",
    "CITYNAME_EN": "Awayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4612",
    "CITYNAME_AR": "اللهموم",
    "CITYNAME_EN": "Al Lahmoum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4613",
    "CITYNAME_AR": "ريدة",
    "CITYNAME_EN": "Raydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4614",
    "CITYNAME_AR": "البويرك",
    "CITYNAME_EN": "Al Buwayrek",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4615",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Ad-Dabar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4616",
    "CITYNAME_AR": "الحصاحص",
    "CITYNAME_EN": "Al Hasahes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4617",
    "CITYNAME_AR": "الخلاص",
    "CITYNAME_EN": "Al Khalas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4618",
    "CITYNAME_AR": "شعب الفرث",
    "CITYNAME_EN": "Shuab Al Farth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4619",
    "CITYNAME_AR": "أم الخياله",
    "CITYNAME_EN": "Om Al Khayyalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4620",
    "CITYNAME_AR": "الفرث",
    "CITYNAME_EN": "Al Farth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4621",
    "CITYNAME_AR": "فرعه جارالله",
    "CITYNAME_EN": "Fareit Jarallah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4622",
    "CITYNAME_AR": "خل الشعراء",
    "CITYNAME_EN": "Khal Ash-Shuara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4623",
    "CITYNAME_AR": "دقم الذنيب",
    "CITYNAME_EN": "Daqam Az-Zunayeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4624",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4625",
    "CITYNAME_AR": "المولجة",
    "CITYNAME_EN": "Al Mawlajah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4626",
    "CITYNAME_AR": "دحله الحليفه",
    "CITYNAME_EN": "Dahlah Al Hulayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4627",
    "CITYNAME_AR": "القبه",
    "CITYNAME_EN": "Al Qubah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4628",
    "CITYNAME_AR": "الأسدان",
    "CITYNAME_EN": "Al Asadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4629",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Ad-Daraj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4630",
    "CITYNAME_AR": "الخشم (النعيمات)",
    "CITYNAME_EN": "Al Khasham (An-Nuaymat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4631",
    "CITYNAME_AR": "أم الحجر",
    "CITYNAME_EN": "Om Al Hajr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4632",
    "CITYNAME_AR": "دحله الذيب",
    "CITYNAME_EN": "Dahlah Az-Zeeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4633",
    "CITYNAME_AR": "دقم الغبراء",
    "CITYNAME_EN": "Daqm Al Ghabraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4634",
    "CITYNAME_AR": "اللحاب",
    "CITYNAME_EN": "Al Luhab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4635",
    "CITYNAME_AR": "عجارم",
    "CITYNAME_EN": "Ajarem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4636",
    "CITYNAME_AR": "قرن ابوالعشه",
    "CITYNAME_EN": "Qarn Abo Al Ayshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4637",
    "CITYNAME_AR": "خالف القبر",
    "CITYNAME_EN": "Khalef Al Qabr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4638",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4639",
    "CITYNAME_AR": "ذى لراك",
    "CITYNAME_EN": "The Larak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4640",
    "CITYNAME_AR": "المميت",
    "CITYNAME_EN": "Al Momeet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4641",
    "CITYNAME_AR": "حبو اللبن",
    "CITYNAME_EN": "Habwa Al laban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4642",
    "CITYNAME_AR": "حبو الرمادى",
    "CITYNAME_EN": "Haba Ar-Ramadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4643",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Ad-Dars",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4644",
    "CITYNAME_AR": "المريبى",
    "CITYNAME_EN": "Al Muraybe",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4645",
    "CITYNAME_AR": "المجرمه",
    "CITYNAME_EN": "Al Mujremah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4646",
    "CITYNAME_AR": "الشاجن",
    "CITYNAME_EN": "Ash-Shajen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4647",
    "CITYNAME_AR": "عرن والنملان",
    "CITYNAME_EN": "Aran wa An-Namlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4648",
    "CITYNAME_AR": "فرظام",
    "CITYNAME_EN": "Ferzam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4649",
    "CITYNAME_AR": "ربل",
    "CITYNAME_EN": "Rabal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4650",
    "CITYNAME_AR": "خمران",
    "CITYNAME_EN": "Khamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4651",
    "CITYNAME_AR": "الحفيص",
    "CITYNAME_EN": "Al Hufayes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4652",
    "CITYNAME_AR": "المدير",
    "CITYNAME_EN": "Al Mudeer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4653",
    "CITYNAME_AR": "محيط",
    "CITYNAME_EN": "Muheet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4654",
    "CITYNAME_AR": "أم العقارب",
    "CITYNAME_EN": "Om Al Aqareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4655",
    "CITYNAME_AR": "المزروبه",
    "CITYNAME_EN": "Al Mazrawyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4656",
    "CITYNAME_AR": "الغمر",
    "CITYNAME_EN": "Al Ghamr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4657",
    "CITYNAME_AR": "الذبط",
    "CITYNAME_EN": "Az-Zabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4658",
    "CITYNAME_AR": "العصيره",
    "CITYNAME_EN": "Al Usayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4659",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4660",
    "CITYNAME_AR": "العرين",
    "CITYNAME_EN": "Alerain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4661",
    "CITYNAME_AR": "القداحه",
    "CITYNAME_EN": "Al Qadahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4662",
    "CITYNAME_AR": "مفرج",
    "CITYNAME_EN": "Mefrej",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4663",
    "CITYNAME_AR": "شعب الأوجام",
    "CITYNAME_EN": "Shuab Al Awjam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4664",
    "CITYNAME_AR": "أبوحبال",
    "CITYNAME_EN": "Abo Hebal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4665",
    "CITYNAME_AR": "المليصه",
    "CITYNAME_EN": "Al Mulaysah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4666",
    "CITYNAME_AR": "عنبه",
    "CITYNAME_EN": "Enabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4667",
    "CITYNAME_AR": "الدثينه",
    "CITYNAME_EN": "Ad-Duthaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4668",
    "CITYNAME_AR": "أم المض",
    "CITYNAME_EN": "Om Al Mad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4669",
    "CITYNAME_AR": "المريه العليا",
    "CITYNAME_EN": "Al Marayah Al Olya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4670",
    "CITYNAME_AR": "الجويني",
    "CITYNAME_EN": "Al Juwaine",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4671",
    "CITYNAME_AR": "شناظيف",
    "CITYNAME_EN": "Shanazef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4672",
    "CITYNAME_AR": "قرن البقر",
    "CITYNAME_EN": "Qarn Al Baqar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4673",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4674",
    "CITYNAME_AR": "الريع الأحمر",
    "CITYNAME_EN": "Ar-Ruba' Al Ahmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4675",
    "CITYNAME_AR": "القرانه",
    "CITYNAME_EN": "Al-Qaranah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4676",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4677",
    "CITYNAME_AR": "المنجمه",
    "CITYNAME_EN": "Al Manjamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4678",
    "CITYNAME_AR": "مخضور",
    "CITYNAME_EN": "Makhdour",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4679",
    "CITYNAME_AR": "ابوالرنفه",
    "CITYNAME_EN": "Abo Ar-Ranaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4680",
    "CITYNAME_AR": "الطوى",
    "CITYNAME_EN": "At-Tawe",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4681",
    "CITYNAME_AR": "سريه",
    "CITYNAME_EN": "Sarayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4682",
    "CITYNAME_AR": "قنبر",
    "CITYNAME_EN": "Qanbar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4683",
    "CITYNAME_AR": "ريده",
    "CITYNAME_EN": "Redah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4684",
    "CITYNAME_AR": "الهضيبة",
    "CITYNAME_EN": "Al Hudayba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4685",
    "CITYNAME_AR": "عاجل",
    "CITYNAME_EN": "Ajel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4686",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Al Lasabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4687",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4688",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Al Meshash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4689",
    "CITYNAME_AR": "القاعده",
    "CITYNAME_EN": "Al Qaedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4690",
    "CITYNAME_AR": "الطبقه",
    "CITYNAME_EN": "At-Tabaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4691",
    "CITYNAME_AR": "ليكه",
    "CITYNAME_EN": "Leikah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4692",
    "CITYNAME_AR": "رنا",
    "CITYNAME_EN": "Rana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4693",
    "CITYNAME_AR": "ابوالكعر",
    "CITYNAME_EN": "Abo Alkaar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4694",
    "CITYNAME_AR": "النبع",
    "CITYNAME_EN": "An-Nabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4695",
    "CITYNAME_AR": "لقفه",
    "CITYNAME_EN": "Laqfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4696",
    "CITYNAME_AR": "خبه (المرو)",
    "CITYNAME_EN": "Khabbah (Al Marw)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4697",
    "CITYNAME_AR": "الحويط",
    "CITYNAME_EN": "Al Hayout",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4698",
    "CITYNAME_AR": "الصلامه (الاشفاع)",
    "CITYNAME_EN": "As-Salamah (Al Ashfaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4699",
    "CITYNAME_AR": "الفخذ (الفخذين)",
    "CITYNAME_EN": "Al Fakhaz (Al Fakhazayn)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4700",
    "CITYNAME_AR": "موال",
    "CITYNAME_EN": "Mawwal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4701",
    "CITYNAME_AR": "القذف (الغبيراء)",
    "CITYNAME_EN": "Al Qazf (Al Ghubayraa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4702",
    "CITYNAME_AR": "الحميراء",
    "CITYNAME_EN": "Humayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4703",
    "CITYNAME_AR": "الاثايب (القعدا)",
    "CITYNAME_EN": "Al Athayeb (Al Qaada)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4704",
    "CITYNAME_AR": "دمه",
    "CITYNAME_EN": "Damah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4705",
    "CITYNAME_AR": "الاجلح",
    "CITYNAME_EN": "Alajlh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4706",
    "CITYNAME_AR": "الثميلات",
    "CITYNAME_EN": "Althamilat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4707",
    "CITYNAME_AR": "الاجراف",
    "CITYNAME_EN": "Alajraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4708",
    "CITYNAME_AR": "الرضمه",
    "CITYNAME_EN": "Alradamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4709",
    "CITYNAME_AR": "قفا السد",
    "CITYNAME_EN": "Qufa Alsadu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4710",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Alqae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4711",
    "CITYNAME_AR": "المديراء",
    "CITYNAME_EN": "Almadira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4712",
    "CITYNAME_AR": "نقمه",
    "CITYNAME_EN": "Naqamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4713",
    "CITYNAME_AR": "الضحياء",
    "CITYNAME_EN": "Aldahya'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4714",
    "CITYNAME_AR": "المشذا",
    "CITYNAME_EN": "Almashadha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4715",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Alriye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4716",
    "CITYNAME_AR": "الضحي",
    "CITYNAME_EN": "Ad-Duha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4717",
    "CITYNAME_AR": "الحاجر والنخلة",
    "CITYNAME_EN": "Al Hajir wa An-Nakhlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4718",
    "CITYNAME_AR": "مشاقيب",
    "CITYNAME_EN": "Mashaqeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4719",
    "CITYNAME_AR": "رزع البهيمه",
    "CITYNAME_EN": "Zaraa Al Bahemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4720",
    "CITYNAME_AR": "خضير",
    "CITYNAME_EN": "Khudair",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4721",
    "CITYNAME_AR": "دقم المروه",
    "CITYNAME_EN": "Daqam Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4722",
    "CITYNAME_AR": "محيقن",
    "CITYNAME_EN": "Muhayqen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4723",
    "CITYNAME_AR": "عدنه",
    "CITYNAME_EN": "Adanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4724",
    "CITYNAME_AR": "دور البكره وشعب سرقه",
    "CITYNAME_EN": "Dor Al Bakarah wa Shuab Sareqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4725",
    "CITYNAME_AR": "قرن سرقه",
    "CITYNAME_EN": "Qarn Sareqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4726",
    "CITYNAME_AR": "أم الأوباد",
    "CITYNAME_EN": "Om Al Awbad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4727",
    "CITYNAME_AR": "المضباع",
    "CITYNAME_EN": "Al Medbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4728",
    "CITYNAME_AR": "لبيط",
    "CITYNAME_EN": "Labeet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4729",
    "CITYNAME_AR": "الدعمان",
    "CITYNAME_EN": "Ad-Daaman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4730",
    "CITYNAME_AR": "الأصعده",
    "CITYNAME_EN": "Al-Asadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4731",
    "CITYNAME_AR": "أم خزيم",
    "CITYNAME_EN": "Om Khuzaim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4732",
    "CITYNAME_AR": "قرن الظبيه وأم الحقين وآل صفيه",
    "CITYNAME_EN": "Qarn Az-Zabyah wa Om Al Huqayn wa Al Safiyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4733",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4734",
    "CITYNAME_AR": "خراص (حزامى)",
    "CITYNAME_EN": "Kuras (Hozami)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4735",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4736",
    "CITYNAME_AR": "سحبان",
    "CITYNAME_EN": "Sabhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4737",
    "CITYNAME_AR": "ابو كبير",
    "CITYNAME_EN": "Abo Kabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4738",
    "CITYNAME_AR": "قرن حامد",
    "CITYNAME_EN": "Qarn Hamed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4739",
    "CITYNAME_AR": "ريع العجلان",
    "CITYNAME_EN": "Rei Al Ajlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4740",
    "CITYNAME_AR": "قاعس وعشيم",
    "CITYNAME_EN": "Qaes wa Asheem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4741",
    "CITYNAME_AR": "كر",
    "CITYNAME_EN": "Kar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4742",
    "CITYNAME_AR": "الشعب الأسود",
    "CITYNAME_EN": "Ash-Shuab Al Aswad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4743",
    "CITYNAME_AR": "دقم العشيه والعلطة",
    "CITYNAME_EN": "Daqm Al Ashyah wa Al Olatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4744",
    "CITYNAME_AR": "الفصيم",
    "CITYNAME_EN": "Al Faseem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4745",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Al Qaabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4746",
    "CITYNAME_AR": "بخيان",
    "CITYNAME_EN": "Bakhyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4747",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Al Jarieh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4748",
    "CITYNAME_AR": "الجعده",
    "CITYNAME_EN": "Al Jaadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4749",
    "CITYNAME_AR": "الضبير",
    "CITYNAME_EN": "Ad-Dabeer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4750",
    "CITYNAME_AR": "قرنوه",
    "CITYNAME_EN": "Qarnoh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4751",
    "CITYNAME_AR": "قفيلان",
    "CITYNAME_EN": "Qufaylan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4752",
    "CITYNAME_AR": "الصلايه",
    "CITYNAME_EN": "As-Salayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4753",
    "CITYNAME_AR": "المهد",
    "CITYNAME_EN": "Al-Mahd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4754",
    "CITYNAME_AR": "فراع بنى عفيف",
    "CITYNAME_EN": "Feraa bani Afif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4755",
    "CITYNAME_AR": "الشروج وأحصره",
    "CITYNAME_EN": "Ash-Sharouj wa Ahsarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4756",
    "CITYNAME_AR": "ام الغار والعفل",
    "CITYNAME_EN": "Om Al Ghar wa Al Afal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4757",
    "CITYNAME_AR": "الرخمه والفيفا",
    "CITYNAME_EN": "Al Rakhmah wa Al Fifa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4758",
    "CITYNAME_AR": "المحضر",
    "CITYNAME_EN": "Al Mahdar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4759",
    "CITYNAME_AR": "الدحله (قريه النعاثله)",
    "CITYNAME_EN": "Ad-Duhalah (An-Naathelah village)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4760",
    "CITYNAME_AR": "السباع",
    "CITYNAME_EN": "As-Sebaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4761",
    "CITYNAME_AR": "رقبه",
    "CITYNAME_EN": "Raqabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4762",
    "CITYNAME_AR": "لخبه",
    "CITYNAME_EN": "Lakhbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4763",
    "CITYNAME_AR": "الرفده",
    "CITYNAME_EN": "Ar-Rafdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4764",
    "CITYNAME_AR": "المضحاه",
    "CITYNAME_EN": "Al Mudhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4765",
    "CITYNAME_AR": "القاعده",
    "CITYNAME_EN": "Al Qaedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4766",
    "CITYNAME_AR": "البرادى",
    "CITYNAME_EN": "Al Baradi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4767",
    "CITYNAME_AR": "اللامه",
    "CITYNAME_EN": "Al Lamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4768",
    "CITYNAME_AR": "نطع",
    "CITYNAME_EN": "Nataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4769",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4770",
    "CITYNAME_AR": "النحره",
    "CITYNAME_EN": "An-Nahrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4771",
    "CITYNAME_AR": "خذارق",
    "CITYNAME_EN": "Khazareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4772",
    "CITYNAME_AR": "الحجرين",
    "CITYNAME_EN": "Al Hajarayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4773",
    "CITYNAME_AR": "سوق العين",
    "CITYNAME_EN": "Alaean Market",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4774",
    "CITYNAME_AR": "اسيود",
    "CITYNAME_EN": "Asud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4775",
    "CITYNAME_AR": "ابورماد",
    "CITYNAME_EN": "Abo Ramad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4776",
    "CITYNAME_AR": "وادى صرنان",
    "CITYNAME_EN": "Wadi Sarnan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4777",
    "CITYNAME_AR": "السمير",
    "CITYNAME_EN": "Al Sumayer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4778",
    "CITYNAME_AR": "ذى حلص",
    "CITYNAME_EN": "The Halas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4779",
    "CITYNAME_AR": "القاعده",
    "CITYNAME_EN": "Al Qaedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4780",
    "CITYNAME_AR": "الصفح",
    "CITYNAME_EN": "As-Safh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4781",
    "CITYNAME_AR": "السهيه (أبو مدر)",
    "CITYNAME_EN": "As-Suhyah (Abo Ramad)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4782",
    "CITYNAME_AR": "الخيام",
    "CITYNAME_EN": "Al Kheyam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4783",
    "CITYNAME_AR": "زمره",
    "CITYNAME_EN": "Zamrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4784",
    "CITYNAME_AR": "شعب رصنه",
    "CITYNAME_EN": "Shuab Rasnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4785",
    "CITYNAME_AR": "المعترضه",
    "CITYNAME_EN": "Al Muataredah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4786",
    "CITYNAME_AR": "ذا الاقدر",
    "CITYNAME_EN": "Tha Al Aqdar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4787",
    "CITYNAME_AR": "وادى السدر",
    "CITYNAME_EN": "Wadi As-Sedr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4788",
    "CITYNAME_AR": "الاقحاف",
    "CITYNAME_EN": "Al Aqhaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4789",
    "CITYNAME_AR": "ضلعه (اللج)",
    "CITYNAME_EN": "Dalaah (Al Laj)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4790",
    "CITYNAME_AR": "الفالقه",
    "CITYNAME_EN": "Al Faleqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4791",
    "CITYNAME_AR": "الصالف",
    "CITYNAME_EN": "As-Salef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4792",
    "CITYNAME_AR": "النشفه",
    "CITYNAME_EN": "An-Nashfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4793",
    "CITYNAME_AR": "المعصب (الممرقه)",
    "CITYNAME_EN": "Al Muasab (Al Mamraqah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4794",
    "CITYNAME_AR": "الحفوز",
    "CITYNAME_EN": "Al Hafuz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4795",
    "CITYNAME_AR": "الاكحل",
    "CITYNAME_EN": "Al Akhal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4796",
    "CITYNAME_AR": "الشرق (الجلاب)",
    "CITYNAME_EN": "Ash-Sharq (Al Jalab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4797",
    "CITYNAME_AR": "المشروك (الخويصره)",
    "CITYNAME_EN": "Al Mashruq (Al Khuwayserah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4798",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Arq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4799",
    "CITYNAME_AR": "صفح النصب",
    "CITYNAME_EN": "Safh An-Nusub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4800",
    "CITYNAME_AR": "شعب نمره وجله الجين",
    "CITYNAME_EN": "Shuab Namerah wa Julah Al Jeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4801",
    "CITYNAME_AR": "النصيب",
    "CITYNAME_EN": "An-Naseeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4802",
    "CITYNAME_AR": "العشره",
    "CITYNAME_EN": "Al Eshrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4803",
    "CITYNAME_AR": "ابو شبرق",
    "CITYNAME_EN": "Abo Shabraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4804",
    "CITYNAME_AR": "المتن",
    "CITYNAME_EN": "Al Matn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4805",
    "CITYNAME_AR": "شعب الوراق",
    "CITYNAME_EN": "Shuab Al Warraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4806",
    "CITYNAME_AR": "شعب المعين",
    "CITYNAME_EN": "Shuab Al Maen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4807",
    "CITYNAME_AR": "الشعب السود",
    "CITYNAME_EN": "Al Shuab As-Soud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4808",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Al Hajafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4809",
    "CITYNAME_AR": "يباسه (الطفه)",
    "CITYNAME_EN": "Bayasah (At-Tafah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4810",
    "CITYNAME_AR": "اللويه",
    "CITYNAME_EN": "Al Lawyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4811",
    "CITYNAME_AR": "وادى عولا",
    "CITYNAME_EN": "Wadi Aoula",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4812",
    "CITYNAME_AR": "ابوالاثبه (البيدة)",
    "CITYNAME_EN": "Abo Al Athyah (Al Baydah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4813",
    "CITYNAME_AR": "الشريقى",
    "CITYNAME_EN": "Ash-Shareqi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4814",
    "CITYNAME_AR": "الغصن",
    "CITYNAME_EN": "Al Ghosn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4815",
    "CITYNAME_AR": "الحوير",
    "CITYNAME_EN": "Al Huwayer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4816",
    "CITYNAME_AR": "البواطن",
    "CITYNAME_EN": "Al Bawaten",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4817",
    "CITYNAME_AR": "عازبه",
    "CITYNAME_EN": "Azebah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4818",
    "CITYNAME_AR": "المقاتل",
    "CITYNAME_EN": "Al Muqatel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4819",
    "CITYNAME_AR": "الدحال",
    "CITYNAME_EN": "Ad-Dahal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4820",
    "CITYNAME_AR": "سفر",
    "CITYNAME_EN": "Safar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4821",
    "CITYNAME_AR": "المسمع",
    "CITYNAME_EN": "Al Musmaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4822",
    "CITYNAME_AR": "المحوله والحمه",
    "CITYNAME_EN": "Al Muhawalah wa Alhemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4823",
    "CITYNAME_AR": "الجندله",
    "CITYNAME_EN": "Al Jandalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4824",
    "CITYNAME_AR": "دثن",
    "CITYNAME_EN": "Dathan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4825",
    "CITYNAME_AR": "وادى ذهب",
    "CITYNAME_EN": "Wadi Zahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4826",
    "CITYNAME_AR": "المقعب والحره",
    "CITYNAME_EN": "Al Muaqeb wa Al Hurah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4827",
    "CITYNAME_AR": "البوينه والشنوير",
    "CITYNAME_EN": "Al Buwaynah wa Ash-Shunwer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4828",
    "CITYNAME_AR": "العلطه وشمروخ",
    "CITYNAME_EN": "Al Alatah wa Shamroukh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4829",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khasrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4830",
    "CITYNAME_AR": "الجهوه",
    "CITYNAME_EN": "Al Jahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4831",
    "CITYNAME_AR": "دقام وريع الاسود",
    "CITYNAME_EN": "Deqam Warei Al Aswad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4832",
    "CITYNAME_AR": "اللستنه",
    "CITYNAME_EN": "Al Lastanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4833",
    "CITYNAME_AR": "الرهوق والرماده",
    "CITYNAME_EN": "Ar-Rahooq wa Ar-Ramadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4834",
    "CITYNAME_AR": "الاخاضرة",
    "CITYNAME_EN": "Al Akhaderah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4835",
    "CITYNAME_AR": "حبو الظاهر",
    "CITYNAME_EN": "Habwa Az-Zaher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4836",
    "CITYNAME_AR": "وادى منساء",
    "CITYNAME_EN": "Wadi Mansaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4837",
    "CITYNAME_AR": "الاطباق",
    "CITYNAME_EN": "Al Atbaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4838",
    "CITYNAME_AR": "المعتق",
    "CITYNAME_EN": "Al Muataq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4839",
    "CITYNAME_AR": "السكى  والحشى",
    "CITYNAME_EN": "As-Saki wa Al Hasha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4840",
    "CITYNAME_AR": "العيبه والمديره والجهيات",
    "CITYNAME_EN": "Al Aybah wa Al Muderah wa Al Jahyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4841",
    "CITYNAME_AR": "الدبيل",
    "CITYNAME_EN": "Ad-Dubail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4842",
    "CITYNAME_AR": "الاساليب",
    "CITYNAME_EN": "Al Asaleb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4843",
    "CITYNAME_AR": "الاحياء (القعب)",
    "CITYNAME_EN": "Al Ahyaa (Al Qaab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4844",
    "CITYNAME_AR": "الفحو",
    "CITYNAME_EN": "Al Fahwa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4845",
    "CITYNAME_AR": "جبل جلجل",
    "CITYNAME_EN": "Jaljal mountain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4846",
    "CITYNAME_AR": "الخشع",
    "CITYNAME_EN": "Al Khashaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4847",
    "CITYNAME_AR": "الوجره",
    "CITYNAME_EN": "Al Wagarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4848",
    "CITYNAME_AR": "الاجرب",
    "CITYNAME_EN": "Al Agrab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4849",
    "CITYNAME_AR": "الغوقه",
    "CITYNAME_EN": "Al Ghawqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4850",
    "CITYNAME_AR": "السهى",
    "CITYNAME_EN": "As-Suha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4851",
    "CITYNAME_AR": "الخرطوم وجناب الشيخ",
    "CITYNAME_EN": "Al Khartoum wa Janabl al-Sheikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4852",
    "CITYNAME_AR": "المجازه",
    "CITYNAME_EN": "Al Majazah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4853",
    "CITYNAME_AR": "محنى الساق",
    "CITYNAME_EN": "Mahni As-Saq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4854",
    "CITYNAME_AR": "النصباء",
    "CITYNAME_EN": "An-Nasbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4855",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Ash-Shaqrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4856",
    "CITYNAME_AR": "ريع الفوج",
    "CITYNAME_EN": "Rei' Al Fouj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4857",
    "CITYNAME_AR": "الفريع",
    "CITYNAME_EN": "Al Fura'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4858",
    "CITYNAME_AR": "الركب السود",
    "CITYNAME_EN": "Al Rukab As-Soud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4859",
    "CITYNAME_AR": "حي السليمانيه",
    "CITYNAME_EN": "Hay As-Sulaymanyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4860",
    "CITYNAME_AR": "الحيران (ام الثمام)",
    "CITYNAME_EN": "Al Hayran (Om Al Thamam)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4861",
    "CITYNAME_AR": "ضبى",
    "CITYNAME_EN": "Dhabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4862",
    "CITYNAME_AR": "وادى السدره",
    "CITYNAME_EN": "Wadi As-Sedrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4863",
    "CITYNAME_AR": "الدعمه والشخوص",
    "CITYNAME_EN": "Ad-Daamah wa Ash-Shukhus",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4864",
    "CITYNAME_AR": "المروه والبراحه",
    "CITYNAME_EN": "Al Marwah wa Al Barahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4865",
    "CITYNAME_AR": "النبعه والمثل",
    "CITYNAME_EN": "An-Nayaah wa Al Mathal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4866",
    "CITYNAME_AR": "ابوالصاب (الحضن)",
    "CITYNAME_EN": "Abo Al Sab (Al Hodn)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4867",
    "CITYNAME_AR": "اللحيدان",
    "CITYNAME_EN": "Al Luhaydan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4868",
    "CITYNAME_AR": "قحوان",
    "CITYNAME_EN": "Qahwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4869",
    "CITYNAME_AR": "الاحداده",
    "CITYNAME_EN": "Al Ahdadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4870",
    "CITYNAME_AR": "وادى العرج",
    "CITYNAME_EN": "Wadi Al Araj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4871",
    "CITYNAME_AR": "المفجر",
    "CITYNAME_EN": "Al Mufajer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4872",
    "CITYNAME_AR": "الدهشه",
    "CITYNAME_EN": "Ad-Dahshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4873",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khasrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4874",
    "CITYNAME_AR": "الزرايب",
    "CITYNAME_EN": "Az-Zarayeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4875",
    "CITYNAME_AR": "الشريعة",
    "CITYNAME_EN": "Shari'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4876",
    "CITYNAME_AR": "لفقة",
    "CITYNAME_EN": "Lafqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4877",
    "CITYNAME_AR": "المرقبان",
    "CITYNAME_EN": "Almargaban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4878",
    "CITYNAME_AR": "مخرق (قزعه ودعان)",
    "CITYNAME_EN": "Makhraq (Qazaah Wadaan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4879",
    "CITYNAME_AR": "الهيشره",
    "CITYNAME_EN": "Al Haysharah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4880",
    "CITYNAME_AR": "الخاصره والقطيط",
    "CITYNAME_EN": "Al Khaserah wa Al Qateet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4881",
    "CITYNAME_AR": "الدركه والمعقد",
    "CITYNAME_EN": "Ad-Darakah wa Al Muaqad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4882",
    "CITYNAME_AR": "فرعة الحشابره",
    "CITYNAME_EN": "Fara'h Al Hashaberah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4883",
    "CITYNAME_AR": "المقسبه",
    "CITYNAME_EN": "Al Maqsabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4884",
    "CITYNAME_AR": "المعلاة والدحله",
    "CITYNAME_EN": "Al Mualah wa Ad-Dahlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4885",
    "CITYNAME_AR": "شراقب",
    "CITYNAME_EN": "Sharaqeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4886",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4887",
    "CITYNAME_AR": "المشقر",
    "CITYNAME_EN": "Al Mushqer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4888",
    "CITYNAME_AR": "الفزازه",
    "CITYNAME_EN": "Al Fazazah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4889",
    "CITYNAME_AR": "الفحيجاء",
    "CITYNAME_EN": "Al Fuhayhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4890",
    "CITYNAME_AR": "انحو",
    "CITYNAME_EN": "Anhu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4891",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4892",
    "CITYNAME_AR": "الدفداف",
    "CITYNAME_EN": "Ad-Dafdaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4893",
    "CITYNAME_AR": "الشقيب",
    "CITYNAME_EN": "Ash-Shaqeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4894",
    "CITYNAME_AR": "الصلاصل",
    "CITYNAME_EN": "As-Salasel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4895",
    "CITYNAME_AR": "الصريب",
    "CITYNAME_EN": "As-Sareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4896",
    "CITYNAME_AR": "الحضيض",
    "CITYNAME_EN": "Al Haded",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4897",
    "CITYNAME_AR": "العشره",
    "CITYNAME_EN": "Al Eshrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4898",
    "CITYNAME_AR": "الموطأ",
    "CITYNAME_EN": "Al Muwataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4899",
    "CITYNAME_AR": "الساعد",
    "CITYNAME_EN": "As-Saed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4900",
    "CITYNAME_AR": "جذل",
    "CITYNAME_EN": "Hazal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4901",
    "CITYNAME_AR": "الثعوب",
    "CITYNAME_EN": "Ath-Thoub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4902",
    "CITYNAME_AR": "القريعاء",
    "CITYNAME_EN": "Al Qurayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4903",
    "CITYNAME_AR": "الصدعاء",
    "CITYNAME_EN": "As-Sadaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4904",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4905",
    "CITYNAME_AR": "الحصاحص",
    "CITYNAME_EN": "Al Hasahes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4906",
    "CITYNAME_AR": "معرك",
    "CITYNAME_EN": "Maaraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4907",
    "CITYNAME_AR": "صبح",
    "CITYNAME_EN": "Subh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4908",
    "CITYNAME_AR": "وبلات",
    "CITYNAME_EN": "Waylat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4909",
    "CITYNAME_AR": "الأحيمر واللجيج",
    "CITYNAME_EN": "Al Auhaymar wa Al Lajej",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4910",
    "CITYNAME_AR": "ربوان",
    "CITYNAME_EN": "Rabwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4911",
    "CITYNAME_AR": "الضحياء",
    "CITYNAME_EN": "Ad-Dahyaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4912",
    "CITYNAME_AR": "خبه",
    "CITYNAME_EN": "Khabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4913",
    "CITYNAME_AR": "الخراره",
    "CITYNAME_EN": "Al Kharrarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4914",
    "CITYNAME_AR": "القرانا",
    "CITYNAME_EN": "Al Feranah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4915",
    "CITYNAME_AR": "بني ذبيان",
    "CITYNAME_EN": "Bani Thebyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4916",
    "CITYNAME_AR": "الحبي",
    "CITYNAME_EN": "Al Heba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4917",
    "CITYNAME_AR": "الهنود",
    "CITYNAME_EN": "Al Honood",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4918",
    "CITYNAME_AR": "جبل أيا",
    "CITYNAME_EN": "Jibel Aiya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4919",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4920",
    "CITYNAME_AR": "المعرض",
    "CITYNAME_EN": "Al Maarad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4921",
    "CITYNAME_AR": "الرتقه والخشعه",
    "CITYNAME_EN": "Ar-Ratqah wa Al Khashaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4922",
    "CITYNAME_AR": "جبل بزع",
    "CITYNAME_EN": "Bazagh mountain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4923",
    "CITYNAME_AR": "المردم",
    "CITYNAME_EN": "Al Mardam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4924",
    "CITYNAME_AR": "الوصله",
    "CITYNAME_EN": "Al Waslah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4925",
    "CITYNAME_AR": "المضيحيه",
    "CITYNAME_EN": "Al Mudayhyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4926",
    "CITYNAME_AR": "جبل الضحى",
    "CITYNAME_EN": "Ad-Duha mountain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4927",
    "CITYNAME_AR": "المصنعه",
    "CITYNAME_EN": "Al Masnaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4928",
    "CITYNAME_AR": "حقال",
    "CITYNAME_EN": "Kaqal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4929",
    "CITYNAME_AR": "الساعى",
    "CITYNAME_EN": "As-Saei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4930",
    "CITYNAME_AR": "البيداء",
    "CITYNAME_EN": "Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4931",
    "CITYNAME_AR": "الهديه و فهيد",
    "CITYNAME_EN": "Al Hadyah wa Fahed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4932",
    "CITYNAME_AR": "زعرة",
    "CITYNAME_EN": "Zaghrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4933",
    "CITYNAME_AR": "الاقحاف",
    "CITYNAME_EN": "Al Aqhaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4934",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4935",
    "CITYNAME_AR": "الزلف",
    "CITYNAME_EN": "Az-Zalaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4936",
    "CITYNAME_AR": "المعيوف (طبق)",
    "CITYNAME_EN": "Al Mayouf (Tabaq)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4937",
    "CITYNAME_AR": "جناب الصيف والهميلي",
    "CITYNAME_EN": "Janab As-Sayf wa Al Humayle",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4938",
    "CITYNAME_AR": "المدق",
    "CITYNAME_EN": "Al Madaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4939",
    "CITYNAME_AR": "الحليه والظبير",
    "CITYNAME_EN": "Al Halyah wa Az-Zubair",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4940",
    "CITYNAME_AR": "الاقر",
    "CITYNAME_EN": "Al Aqar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4941",
    "CITYNAME_AR": "الجهوه",
    "CITYNAME_EN": "Al Jahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4942",
    "CITYNAME_AR": "قريضه",
    "CITYNAME_EN": "Quraydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4943",
    "CITYNAME_AR": "الفالق ولفقه",
    "CITYNAME_EN": "Al Faleq Walafqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4944",
    "CITYNAME_AR": "الحويه",
    "CITYNAME_EN": "Al Huwaya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4945",
    "CITYNAME_AR": "هين",
    "CITYNAME_EN": "Hayen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4946",
    "CITYNAME_AR": "المواصل",
    "CITYNAME_EN": "Al Muwasel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4947",
    "CITYNAME_AR": "ريع الرحى والعباسيه",
    "CITYNAME_EN": "Rei Ar-Ruha wa Al Abassiyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4948",
    "CITYNAME_AR": "سيحان",
    "CITYNAME_EN": "Sayhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4949",
    "CITYNAME_AR": "العرش والشخر",
    "CITYNAME_EN": "Al Arsh wa Ash-Shakhr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4950",
    "CITYNAME_AR": "عصره",
    "CITYNAME_EN": "Asarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4951",
    "CITYNAME_AR": "ام السد",
    "CITYNAME_EN": "Om As-Sad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4952",
    "CITYNAME_AR": "المقيرى وام على",
    "CITYNAME_EN": "Al Muqayri wa Om Ali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4953",
    "CITYNAME_AR": "القريات",
    "CITYNAME_EN": "Qurayyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4954",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4955",
    "CITYNAME_AR": "الدفداف",
    "CITYNAME_EN": "Ad-Dafdaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4956",
    "CITYNAME_AR": "الزقاق",
    "CITYNAME_EN": "Az-Zuqaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4957",
    "CITYNAME_AR": "ابو مقاطر",
    "CITYNAME_EN": "Abo Muqater",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4958",
    "CITYNAME_AR": "شعب الحصار",
    "CITYNAME_EN": "Shuab Al Hesar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4959",
    "CITYNAME_AR": "الحجيفة",
    "CITYNAME_EN": "Al Hujaifah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4960",
    "CITYNAME_AR": "شعب ذنبا",
    "CITYNAME_EN": "Shuab Zanba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4961",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4962",
    "CITYNAME_AR": "شعب المريخ",
    "CITYNAME_EN": "Shuab Al Marekh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4963",
    "CITYNAME_AR": "ام مطيب",
    "CITYNAME_EN": "Om Mateeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4964",
    "CITYNAME_AR": "الراكبة",
    "CITYNAME_EN": "Ar-Rakhebah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4965",
    "CITYNAME_AR": "الرهط",
    "CITYNAME_EN": "Ar-Raht",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4966",
    "CITYNAME_AR": "خليص والحقيب",
    "CITYNAME_EN": "Khulays wa Al Huqaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4967",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khasrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4968",
    "CITYNAME_AR": "شعيب عرعر",
    "CITYNAME_EN": "Shuab Arar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4969",
    "CITYNAME_AR": "القشعة",
    "CITYNAME_EN": "Al Qashaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4970",
    "CITYNAME_AR": "دفين واللبوط",
    "CITYNAME_EN": "Dafen wa Al Labout",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4971",
    "CITYNAME_AR": "المنجف",
    "CITYNAME_EN": "Al Manjaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4972",
    "CITYNAME_AR": "النشفه",
    "CITYNAME_EN": "An-Nashfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4973",
    "CITYNAME_AR": "المسليحه والجندل",
    "CITYNAME_EN": "Al Musalyahah wa Al Jandal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4974",
    "CITYNAME_AR": "المذيبع",
    "CITYNAME_EN": "Al Muzei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4975",
    "CITYNAME_AR": "الدحال",
    "CITYNAME_EN": "Ad-Dahal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4976",
    "CITYNAME_AR": "الطيف والذنبه",
    "CITYNAME_EN": "At-Taif wa Az-Zanabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4977",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4978",
    "CITYNAME_AR": "شعيب القنة",
    "CITYNAME_EN": "Shuab Al Qenah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4979",
    "CITYNAME_AR": "الرضيم",
    "CITYNAME_EN": "Ar-Radeem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4980",
    "CITYNAME_AR": "مسيلحه والمروه",
    "CITYNAME_EN": "Musaylahah wa Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4981",
    "CITYNAME_AR": "العطفه وريع الرضيم",
    "CITYNAME_EN": "Al Atfah wa Rubei Ar-Radeem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4982",
    "CITYNAME_AR": "قرية الجحيرة",
    "CITYNAME_EN": "Al Hjayrah village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4983",
    "CITYNAME_AR": "حمقه",
    "CITYNAME_EN": "Hamqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4984",
    "CITYNAME_AR": "الخرمه وفسيان",
    "CITYNAME_EN": "Al Khurmah wa Fusyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4985",
    "CITYNAME_AR": "نصب النبع",
    "CITYNAME_EN": "Nasab Al Nabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4986",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Aldahluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4987",
    "CITYNAME_AR": "شغنين",
    "CITYNAME_EN": "Shghnin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4988",
    "CITYNAME_AR": "الغريب",
    "CITYNAME_EN": "Algharib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4989",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "Alsadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4990",
    "CITYNAME_AR": "ابويديه",
    "CITYNAME_EN": "Abwaydih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4991",
    "CITYNAME_AR": "جبل عفف",
    "CITYNAME_EN": "Jabal Eaff",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4992",
    "CITYNAME_AR": "المحولة",
    "CITYNAME_EN": "Almahawla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4993",
    "CITYNAME_AR": "الصنقه",
    "CITYNAME_EN": "Alsinquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4994",
    "CITYNAME_AR": "صلاق",
    "CITYNAME_EN": "Silaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4995",
    "CITYNAME_AR": "أثله وملحة",
    "CITYNAME_EN": "Athalah wa Malhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4996",
    "CITYNAME_AR": "خذى",
    "CITYNAME_EN": "Khazi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4997",
    "CITYNAME_AR": "المنسله",
    "CITYNAME_EN": "Al Mansalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4998",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al Halah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "4999",
    "CITYNAME_AR": "دحال مسفر",
    "CITYNAME_EN": "Dahal Mesfer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5000",
    "CITYNAME_AR": "الجنيدل",
    "CITYNAME_EN": "Al Junaydal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5001",
    "CITYNAME_AR": "ام خشب",
    "CITYNAME_EN": "Umm Khashab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5002",
    "CITYNAME_AR": "ذا العلب",
    "CITYNAME_EN": "Dha Alealab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5003",
    "CITYNAME_AR": "ام على",
    "CITYNAME_EN": "Om Ali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5004",
    "CITYNAME_AR": "قرى وادى مروه",
    "CITYNAME_EN": "Wadi Marwah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5005",
    "CITYNAME_AR": "قرى وادى سقم",
    "CITYNAME_EN": "Wadi Saqeem villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5006",
    "CITYNAME_AR": "قرى وادى مقسى",
    "CITYNAME_EN": "Wadi Muqsee villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5007",
    "CITYNAME_AR": "قرى وادي الخايع",
    "CITYNAME_EN": "Wadi Al Khayaa villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5008",
    "CITYNAME_AR": "قرى وادي رمحه",
    "CITYNAME_EN": "Wadi Rumhah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5009",
    "CITYNAME_AR": "قرى وادى فلح",
    "CITYNAME_EN": "Wadi Falah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5010",
    "CITYNAME_AR": "قرى وادى اراك",
    "CITYNAME_EN": "Wadi Falah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5011",
    "CITYNAME_AR": "قرى وادى الاخشب",
    "CITYNAME_EN": "Wadi Al Akhshab villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5012",
    "CITYNAME_AR": "قرى وادي فرظام",
    "CITYNAME_EN": "Wadi Ferzam villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5013",
    "CITYNAME_AR": "قرى وادى ثواوه",
    "CITYNAME_EN": "Wadi Thuwawah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5014",
    "CITYNAME_AR": "قرى وادى عظمان",
    "CITYNAME_EN": "Wadi Azman villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5015",
    "CITYNAME_AR": "قرى وادىالعشر",
    "CITYNAME_EN": "Wadi Al Oshr villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5016",
    "CITYNAME_AR": "قرى وادي رايه",
    "CITYNAME_EN": "Wadi Rayah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5017",
    "CITYNAME_AR": "المرزوز",
    "CITYNAME_EN": "Almarzuz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5018",
    "CITYNAME_AR": "المسافي",
    "CITYNAME_EN": "Almasafi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5019",
    "CITYNAME_AR": "العيينات",
    "CITYNAME_EN": "Aleyynat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5020",
    "CITYNAME_AR": "منيخل",
    "CITYNAME_EN": "Munyakhalat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5021",
    "CITYNAME_AR": "الاصعاد",
    "CITYNAME_EN": "Alaisiead",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5022",
    "CITYNAME_AR": "ام الركيب",
    "CITYNAME_EN": "Umm Alrakib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5023",
    "CITYNAME_AR": "الضمو",
    "CITYNAME_EN": "Aldamuw",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5024",
    "CITYNAME_AR": "النيله",
    "CITYNAME_EN": "Alnayluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5025",
    "CITYNAME_AR": "برقطان",
    "CITYNAME_EN": "Baraqtan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5026",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Almilaha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5027",
    "CITYNAME_AR": "مطيبيخ",
    "CITYNAME_EN": "Mutibikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5028",
    "CITYNAME_AR": "المسحق",
    "CITYNAME_EN": "Almusahaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5029",
    "CITYNAME_AR": "الحصون",
    "CITYNAME_EN": "Alhusuwn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5030",
    "CITYNAME_AR": "الماء الحار",
    "CITYNAME_EN": "Alma' Alharu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5031",
    "CITYNAME_AR": "الجزءالمحصور بين الماء الحار والمركز",
    "CITYNAME_EN": "Part Between Alma' Alhari and the Center",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5032",
    "CITYNAME_AR": "المديراء",
    "CITYNAME_EN": "Almadira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5033",
    "CITYNAME_AR": "الوريمه",
    "CITYNAME_EN": "Alwarimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5034",
    "CITYNAME_AR": "بطحان",
    "CITYNAME_EN": "Batahan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5035",
    "CITYNAME_AR": "الحفيا",
    "CITYNAME_EN": "Alhafia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5036",
    "CITYNAME_AR": "الأشيهب",
    "CITYNAME_EN": "Alashyhib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5037",
    "CITYNAME_AR": "العنيقه",
    "CITYNAME_EN": "Aleaniquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5038",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5039",
    "CITYNAME_AR": "مستنقع",
    "CITYNAME_EN": "Mustanqae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5040",
    "CITYNAME_AR": "الفراع",
    "CITYNAME_EN": "Alfirae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5041",
    "CITYNAME_AR": "ذهب",
    "CITYNAME_EN": "Dhahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5042",
    "CITYNAME_AR": "دمادم",
    "CITYNAME_EN": "Damadum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5043",
    "CITYNAME_AR": "الرصف",
    "CITYNAME_EN": "Alrisf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5044",
    "CITYNAME_AR": "الباتل",
    "CITYNAME_EN": "Albatil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5045",
    "CITYNAME_AR": "الوطيات",
    "CITYNAME_EN": "Alwatiat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5046",
    "CITYNAME_AR": "شبوه",
    "CITYNAME_EN": "Shubuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5047",
    "CITYNAME_AR": "أم جريف",
    "CITYNAME_EN": "Umm Jarif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5048",
    "CITYNAME_AR": "القراره (الصوامله)",
    "CITYNAME_EN": "Alqararuh (Alasawamulh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5049",
    "CITYNAME_AR": "ابوحويرك",
    "CITYNAME_EN": "Abwhwirk",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5050",
    "CITYNAME_AR": "جاوان",
    "CITYNAME_EN": "Jawan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5051",
    "CITYNAME_AR": "الرماده",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5052",
    "CITYNAME_AR": "بريده",
    "CITYNAME_EN": "Bariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5053",
    "CITYNAME_AR": "المساقه",
    "CITYNAME_EN": "Almasaquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5054",
    "CITYNAME_AR": "الديه",
    "CITYNAME_EN": "Aldiyh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5055",
    "CITYNAME_AR": "الاصم",
    "CITYNAME_EN": "Alasm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5056",
    "CITYNAME_AR": "أبوشجيرة",
    "CITYNAME_EN": "'Abushajira",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5057",
    "CITYNAME_AR": "نثيل",
    "CITYNAME_EN": "Nthil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5058",
    "CITYNAME_AR": "الأريفح",
    "CITYNAME_EN": "Al'Arifih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5059",
    "CITYNAME_AR": "الصديه",
    "CITYNAME_EN": "Alsidiyuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5060",
    "CITYNAME_AR": "الجهوه",
    "CITYNAME_EN": "Aljuhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5061",
    "CITYNAME_AR": "قرن البقر",
    "CITYNAME_EN": "Qarn Albaqar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5062",
    "CITYNAME_AR": "صنيدل",
    "CITYNAME_EN": "Sunidl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5063",
    "CITYNAME_AR": "الشبيكه",
    "CITYNAME_EN": "Alshabikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5064",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Almashash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5065",
    "CITYNAME_AR": "قرين الرابعه",
    "CITYNAME_EN": "Qarin Alraabieih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5066",
    "CITYNAME_AR": "المظلف",
    "CITYNAME_EN": "Almuzalaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5067",
    "CITYNAME_AR": "المريخ",
    "CITYNAME_EN": "Almiriykh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5068",
    "CITYNAME_AR": "شفاء",
    "CITYNAME_EN": "Shifa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5069",
    "CITYNAME_AR": "منسا",
    "CITYNAME_EN": "Mansa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5070",
    "CITYNAME_AR": "ضهياء",
    "CITYNAME_EN": "Dahia'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5071",
    "CITYNAME_AR": "الغميم",
    "CITYNAME_EN": "Alghamim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5072",
    "CITYNAME_AR": "الرفيحا",
    "CITYNAME_EN": "Alrafiha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5073",
    "CITYNAME_AR": "شال",
    "CITYNAME_EN": "Shal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5074",
    "CITYNAME_AR": "الجمعه",
    "CITYNAME_EN": "Aljameuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5075",
    "CITYNAME_AR": "فراع",
    "CITYNAME_EN": "Firae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5076",
    "CITYNAME_AR": "النضح",
    "CITYNAME_EN": "Alnadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5077",
    "CITYNAME_AR": "الخشب",
    "CITYNAME_EN": "Alkhashb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5078",
    "CITYNAME_AR": "دحله الشرقى",
    "CITYNAME_EN": "Dahlah Alsharqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5079",
    "CITYNAME_AR": "وادى اعين",
    "CITYNAME_EN": "Wa'Adaa 'Aeyun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5080",
    "CITYNAME_AR": "القيف الاعلى والاسفل",
    "CITYNAME_EN": "Alqayf Al'Aelaa Walasfil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5081",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "Alsadaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5082",
    "CITYNAME_AR": "يفاعه",
    "CITYNAME_EN": "Yufaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5083",
    "CITYNAME_AR": "لحب الكبش",
    "CITYNAME_EN": "Lihubi Alkabsh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5084",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Almaein",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5085",
    "CITYNAME_AR": "وادى صهده السفلى",
    "CITYNAME_EN": "Wa'Adaa Sahduh Alsuflaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5086",
    "CITYNAME_AR": "نعجان",
    "CITYNAME_EN": "Naejan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5087",
    "CITYNAME_AR": "فاحس",
    "CITYNAME_EN": "Fahis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5088",
    "CITYNAME_AR": "مدسوس",
    "CITYNAME_EN": "Madasus",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5089",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Aldiyquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5090",
    "CITYNAME_AR": "وادى صهده العليا",
    "CITYNAME_EN": "Wa'Adaa Sahduh Aleulya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5091",
    "CITYNAME_AR": "ابوجخره",
    "CITYNAME_EN": "Abwjkhruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5092",
    "CITYNAME_AR": "ابوالشوك",
    "CITYNAME_EN": "Abwalshuk",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5093",
    "CITYNAME_AR": "ابوبحثره",
    "CITYNAME_EN": "Abwbhthrh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5094",
    "CITYNAME_AR": "الثمام",
    "CITYNAME_EN": "Althamam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5095",
    "CITYNAME_AR": "وادى نيتان",
    "CITYNAME_EN": "Wa'Adaa Nayatan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5096",
    "CITYNAME_AR": "السمره",
    "CITYNAME_EN": "Alsamaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5097",
    "CITYNAME_AR": "الرخيلة",
    "CITYNAME_EN": "Alrakhila",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5098",
    "CITYNAME_AR": "الضبره",
    "CITYNAME_EN": "Aldabruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5099",
    "CITYNAME_AR": "وادى الصدر",
    "CITYNAME_EN": "Wa'Adaa Alsadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5100",
    "CITYNAME_AR": "المعقر (البان)",
    "CITYNAME_EN": "Almaeqir (Alban)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5101",
    "CITYNAME_AR": "الصمار",
    "CITYNAME_EN": "Alsamar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5102",
    "CITYNAME_AR": "أم غربان",
    "CITYNAME_EN": "Umm Ghurban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5103",
    "CITYNAME_AR": "الحسينية",
    "CITYNAME_EN": "Alhusaynia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5104",
    "CITYNAME_AR": "اتانه",
    "CITYNAME_EN": "Atanh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5105",
    "CITYNAME_AR": "حميض",
    "CITYNAME_EN": "Hamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5106",
    "CITYNAME_AR": "اثرار",
    "CITYNAME_EN": "Athrar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5107",
    "CITYNAME_AR": "جراره",
    "CITYNAME_EN": "Jararuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5108",
    "CITYNAME_AR": "المفش",
    "CITYNAME_EN": "Almafashu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5109",
    "CITYNAME_AR": "الصبغاء",
    "CITYNAME_EN": "Alsabgha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5110",
    "CITYNAME_AR": "ذا الرباع",
    "CITYNAME_EN": "Dha Alribae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5111",
    "CITYNAME_AR": "عنيدله",
    "CITYNAME_EN": "Eunidilh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5112",
    "CITYNAME_AR": "صبيه",
    "CITYNAME_EN": "Sabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5113",
    "CITYNAME_AR": "عندل والعظام",
    "CITYNAME_EN": "Eindal Waleizam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5114",
    "CITYNAME_AR": "الرفغ والخاصره",
    "CITYNAME_EN": "Alrafgh Walkhasiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5115",
    "CITYNAME_AR": "المهيليل",
    "CITYNAME_EN": "Almuhilil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5116",
    "CITYNAME_AR": "المهلال",
    "CITYNAME_EN": "Almihlal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5117",
    "CITYNAME_AR": "ذيب",
    "CITYNAME_EN": "Dhib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5118",
    "CITYNAME_AR": "المخباه والجعد",
    "CITYNAME_EN": "Almakhabaah Waljaed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5119",
    "CITYNAME_AR": "يسرى",
    "CITYNAME_EN": "Yusraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5120",
    "CITYNAME_AR": "التظب",
    "CITYNAME_EN": "Altazabu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5121",
    "CITYNAME_AR": "الجحر",
    "CITYNAME_EN": "Aljahr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5122",
    "CITYNAME_AR": "التلعين",
    "CITYNAME_EN": "Altalaeiyn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5123",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Alruwduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5124",
    "CITYNAME_AR": "الرنيفه",
    "CITYNAME_EN": "Alranifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5125",
    "CITYNAME_AR": "الجبيل  ",
    "CITYNAME_EN": "Aljabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5126",
    "CITYNAME_AR": "ضها الردى",
    "CITYNAME_EN": "Daha Alradaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5127",
    "CITYNAME_AR": "عقدان",
    "CITYNAME_EN": "Eaqdan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5128",
    "CITYNAME_AR": "المطبق (المغيبيه)",
    "CITYNAME_EN": "Almutbaq (Almaghybih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5129",
    "CITYNAME_AR": "مجدة",
    "CITYNAME_EN": "Mujada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5130",
    "CITYNAME_AR": "غمره",
    "CITYNAME_EN": "Ghamirah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5131",
    "CITYNAME_AR": "سوق بنى يزيد",
    "CITYNAME_EN": "Banaa Yazid Market",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5132",
    "CITYNAME_AR": "وادى قطنا",
    "CITYNAME_EN": "Wa'Adaa Qitana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5133",
    "CITYNAME_AR": "كرش",
    "CITYNAME_EN": "Karash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5134",
    "CITYNAME_AR": "المقعب",
    "CITYNAME_EN": "Almaqeab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5135",
    "CITYNAME_AR": "جناب الحجر",
    "CITYNAME_EN": "Janab Alhajar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5136",
    "CITYNAME_AR": "نمر",
    "CITYNAME_EN": "Namur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5137",
    "CITYNAME_AR": "مراوه",
    "CITYNAME_EN": "Marawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5138",
    "CITYNAME_AR": "البرد",
    "CITYNAME_EN": "Albard",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5139",
    "CITYNAME_AR": "الهديه",
    "CITYNAME_EN": "Alhadih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5140",
    "CITYNAME_AR": "الملحه بقطنا",
    "CITYNAME_EN": "Almalihuh Biqatna",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5141",
    "CITYNAME_AR": "الجرفين والحشيباء",
    "CITYNAME_EN": "Aljarafayn Walhashiba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5142",
    "CITYNAME_AR": "المرخه والشرطه",
    "CITYNAME_EN": "Almarakhuh Walshartuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5143",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Alzzahir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5144",
    "CITYNAME_AR": "دحضه",
    "CITYNAME_EN": "Dahdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5145",
    "CITYNAME_AR": "شعب نشمه فى قطنا",
    "CITYNAME_EN": "Shaeb Nashmah Fa Qitana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5146",
    "CITYNAME_AR": "الاوشح",
    "CITYNAME_EN": "Alawshh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5147",
    "CITYNAME_AR": "النمراء",
    "CITYNAME_EN": "Alnumara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5148",
    "CITYNAME_AR": "ايوب",
    "CITYNAME_EN": "'Ayuwb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5149",
    "CITYNAME_AR": "العلو",
    "CITYNAME_EN": "Aleulu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5150",
    "CITYNAME_AR": "هرشاء",
    "CITYNAME_EN": "Hrsha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5151",
    "CITYNAME_AR": "شعب جمسان",
    "CITYNAME_EN": "Shaeb Jamsan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5152",
    "CITYNAME_AR": "القفله",
    "CITYNAME_EN": "Alqifluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5153",
    "CITYNAME_AR": "الجبيه",
    "CITYNAME_EN": "Aljabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5154",
    "CITYNAME_AR": "شعب الذنوب",
    "CITYNAME_EN": "Shaeb Aldhunub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5155",
    "CITYNAME_AR": "خريص",
    "CITYNAME_EN": "Kharis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5156",
    "CITYNAME_AR": "القاعده",
    "CITYNAME_EN": "Alqaeiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5157",
    "CITYNAME_AR": "رياض (الربض)",
    "CITYNAME_EN": "Riad (Alrbd)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5158",
    "CITYNAME_AR": "الرخمه",
    "CITYNAME_EN": "Alrakhmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5159",
    "CITYNAME_AR": "وادى كثلاء",
    "CITYNAME_EN": "Wa'Adaa Kuthla'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5160",
    "CITYNAME_AR": "جناب النعجه",
    "CITYNAME_EN": "Junab Alnaejih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5161",
    "CITYNAME_AR": "الحنه وذا الرمث",
    "CITYNAME_EN": "Alhanah Wadha Alramath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5162",
    "CITYNAME_AR": "القديره",
    "CITYNAME_EN": "Alqadiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5163",
    "CITYNAME_AR": "الرخم",
    "CITYNAME_EN": "Alrakhum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5164",
    "CITYNAME_AR": "عرشب",
    "CITYNAME_EN": "Earshib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5165",
    "CITYNAME_AR": "عشره",
    "CITYNAME_EN": "Eshrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5166",
    "CITYNAME_AR": "الذليل",
    "CITYNAME_EN": "Aldhalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5167",
    "CITYNAME_AR": "الابيريه",
    "CITYNAME_EN": "Alabyrih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5168",
    "CITYNAME_AR": "نطيع",
    "CITYNAME_EN": "Nutie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5169",
    "CITYNAME_AR": "شعب النخل",
    "CITYNAME_EN": "Shaeb Alnakhl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5170",
    "CITYNAME_AR": "الجحنه",
    "CITYNAME_EN": "Aljahnuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5171",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Alkhalif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5172",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Almuslimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5173",
    "CITYNAME_AR": "الراكه",
    "CITYNAME_EN": "Alraakih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5174",
    "CITYNAME_AR": "الحديب",
    "CITYNAME_EN": "Alhadib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5175",
    "CITYNAME_AR": "وادى الليث",
    "CITYNAME_EN": "Wa'Adaa Allayth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5176",
    "CITYNAME_AR": "الحمساء",
    "CITYNAME_EN": "Alhamsa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5177",
    "CITYNAME_AR": "الضباء",
    "CITYNAME_EN": "Aldabba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5178",
    "CITYNAME_AR": "صعيب",
    "CITYNAME_EN": "Saeib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5179",
    "CITYNAME_AR": "قاع خميسه",
    "CITYNAME_EN": "Qae Khamisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5180",
    "CITYNAME_AR": "اللهب",
    "CITYNAME_EN": "Allahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5181",
    "CITYNAME_AR": "الضبرة",
    "CITYNAME_EN": "Aldabara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5182",
    "CITYNAME_AR": "صايف",
    "CITYNAME_EN": "Sayif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5183",
    "CITYNAME_AR": "المراء",
    "CITYNAME_EN": "Almara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5184",
    "CITYNAME_AR": "يتور والسيلانية",
    "CITYNAME_EN": "Yatur Walsiylania",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5185",
    "CITYNAME_AR": "وادى سلبه",
    "CITYNAME_EN": "Wa'Adaa Salabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5186",
    "CITYNAME_AR": "الثكامه السفلى",
    "CITYNAME_EN": "Althakamuh Alsuflaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5187",
    "CITYNAME_AR": "ام بقر",
    "CITYNAME_EN": "Umm Bqr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5188",
    "CITYNAME_AR": "ابوالغار",
    "CITYNAME_EN": "Abwalghar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5189",
    "CITYNAME_AR": "الصفائح",
    "CITYNAME_EN": "Alsafayih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5190",
    "CITYNAME_AR": "مراضع",
    "CITYNAME_EN": "Maradie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5191",
    "CITYNAME_AR": "قرن الترع",
    "CITYNAME_EN": "Qarn Altarae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5192",
    "CITYNAME_AR": "العارض  والمليح",
    "CITYNAME_EN": "Alearid Walmalih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5193",
    "CITYNAME_AR": "ابوميجان",
    "CITYNAME_EN": "Abwamijan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5194",
    "CITYNAME_AR": "اللبيه",
    "CITYNAME_EN": "Allabayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5195",
    "CITYNAME_AR": "البويره",
    "CITYNAME_EN": "Albawayruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5196",
    "CITYNAME_AR": "ذا النبيت",
    "CITYNAME_EN": "Dha Alnabiat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5197",
    "CITYNAME_AR": "سبيع",
    "CITYNAME_EN": "Sabie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5198",
    "CITYNAME_AR": "جناب الراقب",
    "CITYNAME_EN": "Junab Alraaqib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5199",
    "CITYNAME_AR": "نمر",
    "CITYNAME_EN": "Namur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5200",
    "CITYNAME_AR": "الصدع (السر)",
    "CITYNAME_EN": "Alsade (Alsra)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5201",
    "CITYNAME_AR": "شعب عشر (الجناب الاحمر)",
    "CITYNAME_EN": "Shaeb Eshr (Alijanab Alahmar)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5202",
    "CITYNAME_AR": "الارك",
    "CITYNAME_EN": "Alark",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5203",
    "CITYNAME_AR": "الظهى",
    "CITYNAME_EN": "Alzuhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5204",
    "CITYNAME_AR": "قاضان",
    "CITYNAME_EN": "Qadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5205",
    "CITYNAME_AR": "الحويرك",
    "CITYNAME_EN": "Alhawyrak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5206",
    "CITYNAME_AR": "الحصاره",
    "CITYNAME_EN": "Alhisaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5207",
    "CITYNAME_AR": "نبع والهيله",
    "CITYNAME_EN": "Nabie Walhilh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5208",
    "CITYNAME_AR": "ذا الغمره",
    "CITYNAME_EN": "Dha Alghamaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5209",
    "CITYNAME_AR": "اوقيع",
    "CITYNAME_EN": "Awqye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5210",
    "CITYNAME_AR": "الضهياه",
    "CITYNAME_EN": "Aldahiah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5211",
    "CITYNAME_AR": "وادى تيثان",
    "CITYNAME_EN": "Wa'Adaa Tithan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5212",
    "CITYNAME_AR": "ابوالذابح",
    "CITYNAME_EN": "Abwaldhabh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5213",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5214",
    "CITYNAME_AR": "الصريحاء السفلى",
    "CITYNAME_EN": "Alsiriha' Alsuflaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5215",
    "CITYNAME_AR": "ابوغزلان",
    "CITYNAME_EN": "Abwghzlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5216",
    "CITYNAME_AR": "جله الصرح",
    "CITYNAME_EN": "Jalah Alsarh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5217",
    "CITYNAME_AR": "الجرده",
    "CITYNAME_EN": "Aljaraduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5218",
    "CITYNAME_AR": "الجراء",
    "CITYNAME_EN": "Aljara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5219",
    "CITYNAME_AR": "الاسلوب",
    "CITYNAME_EN": "Al'Uslub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5220",
    "CITYNAME_AR": "المشقر",
    "CITYNAME_EN": "Almushaqir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5221",
    "CITYNAME_AR": "جله السدره (ذي شعاره)",
    "CITYNAME_EN": "Jalah Alsadaruh (Dhy Shaeariha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5222",
    "CITYNAME_AR": "الثمالى",
    "CITYNAME_EN": "Althamalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5223",
    "CITYNAME_AR": "القريضه",
    "CITYNAME_EN": "Alqariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5224",
    "CITYNAME_AR": "الرويحه",
    "CITYNAME_EN": "Alrawayhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5225",
    "CITYNAME_AR": "الرحيه",
    "CITYNAME_EN": "Alrahiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5226",
    "CITYNAME_AR": "جناب القبور",
    "CITYNAME_EN": "Janab Alqubur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5227",
    "CITYNAME_AR": "ذا العلق",
    "CITYNAME_EN": "Dha Alealaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5228",
    "CITYNAME_AR": "الاراكه (الفاتح)",
    "CITYNAME_EN": "Alarakh (Alafatah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5229",
    "CITYNAME_AR": "ذا الوقيعه",
    "CITYNAME_EN": "Dha Alwaqieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5230",
    "CITYNAME_AR": "القضيب",
    "CITYNAME_EN": "Alqadib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5231",
    "CITYNAME_AR": "جناب جميع",
    "CITYNAME_EN": "Janab Jmye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5232",
    "CITYNAME_AR": "شعب امول",
    "CITYNAME_EN": "Shaeb Amwl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5233",
    "CITYNAME_AR": "الفرابه (الغرابه)",
    "CITYNAME_EN": "Alfarabuh (Alghrabh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5234",
    "CITYNAME_AR": "الحاضر وقرضه",
    "CITYNAME_EN": "Alhadir Waqardah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5235",
    "CITYNAME_AR": "المعروض (العاروضى)",
    "CITYNAME_EN": "Almaerud (Alearudaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5236",
    "CITYNAME_AR": "ذا الرضمه",
    "CITYNAME_EN": "Dha Alradamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5237",
    "CITYNAME_AR": "القاعده والقريضه",
    "CITYNAME_EN": "Alqaeiduh Walqariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5238",
    "CITYNAME_AR": "المقعب الاسفل (الاثياب)",
    "CITYNAME_EN": "Almaqeab Al'Asfal (Alathiab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5239",
    "CITYNAME_AR": "السبطاء",
    "CITYNAME_EN": "Alsubta'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5240",
    "CITYNAME_AR": "الخرامه (الخرمه)",
    "CITYNAME_EN": "Alkharamuh (Alkhurmaha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5241",
    "CITYNAME_AR": "المهلال والمخيبر",
    "CITYNAME_EN": "Almuhlal Walmukhaybir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5242",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Alkhaymuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5243",
    "CITYNAME_AR": "المقدم",
    "CITYNAME_EN": "Almuqadam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5244",
    "CITYNAME_AR": "شعب نصوى",
    "CITYNAME_EN": "Shaeb Naswaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5245",
    "CITYNAME_AR": "الاراكه",
    "CITYNAME_EN": "Alarakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5246",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Almadaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5247",
    "CITYNAME_AR": "نشمه واعين",
    "CITYNAME_EN": "Nshmuh Waein",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5248",
    "CITYNAME_AR": "المصيبر والمصبر",
    "CITYNAME_EN": "Almusaybir Walmusbir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5249",
    "CITYNAME_AR": "جناب المروه",
    "CITYNAME_EN": "Janab Almuruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5250",
    "CITYNAME_AR": "القفله",
    "CITYNAME_EN": "Alqifluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5251",
    "CITYNAME_AR": "الرفقه (الرفعه)",
    "CITYNAME_EN": "Alrafaquh (Alrafeah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5252",
    "CITYNAME_AR": "شعب بيطه",
    "CITYNAME_EN": "Shaeb Baytah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5253",
    "CITYNAME_AR": "ذا اللوبه",
    "CITYNAME_EN": "Dha Allawbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5254",
    "CITYNAME_AR": "براق",
    "CITYNAME_EN": "Biraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5255",
    "CITYNAME_AR": "ذى ضبر",
    "CITYNAME_EN": "Dhaa Dubr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5256",
    "CITYNAME_AR": "القعيره",
    "CITYNAME_EN": "Alqaeiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5257",
    "CITYNAME_AR": "الثعوب",
    "CITYNAME_EN": "Althueub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5258",
    "CITYNAME_AR": "المعرض",
    "CITYNAME_EN": "Almaerid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5259",
    "CITYNAME_AR": "الحمما",
    "CITYNAME_EN": "Alhumama",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5260",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Alkhasiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5261",
    "CITYNAME_AR": "الباطن",
    "CITYNAME_EN": "Albatn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5262",
    "CITYNAME_AR": "ذا السهى",
    "CITYNAME_EN": "Dha Alsahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5263",
    "CITYNAME_AR": "الجويذيه",
    "CITYNAME_EN": "Aljawidhiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5264",
    "CITYNAME_AR": "الميثب",
    "CITYNAME_EN": "Almythib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5265",
    "CITYNAME_AR": "شعب شعاره",
    "CITYNAME_EN": "Shaeb Shaearuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5266",
    "CITYNAME_AR": "المحوى",
    "CITYNAME_EN": "Almahwaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5267",
    "CITYNAME_AR": "المقراء",
    "CITYNAME_EN": "Almuqara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5268",
    "CITYNAME_AR": "شعب ثرب الاسفل",
    "CITYNAME_EN": "Shaeb Thurb Al'Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5269",
    "CITYNAME_AR": "ذى لصف",
    "CITYNAME_EN": "Dhaa Lasf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5270",
    "CITYNAME_AR": "الرنيفه",
    "CITYNAME_EN": "Alranifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5271",
    "CITYNAME_AR": "شعب ثرب الاعلى",
    "CITYNAME_EN": "Shaeb Tharb Al'Aelaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5272",
    "CITYNAME_AR": "ام العقال",
    "CITYNAME_EN": "Umm Aleiqal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5273",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "Alsafara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5274",
    "CITYNAME_AR": "شعب نشاق",
    "CITYNAME_EN": "Shaeb Nashaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5275",
    "CITYNAME_AR": "ذى السلع",
    "CITYNAME_EN": "Dhaa Alsilae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5276",
    "CITYNAME_AR": "المخط",
    "CITYNAME_EN": "Almakhatu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5277",
    "CITYNAME_AR": "الظبير",
    "CITYNAME_EN": "Alzabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5278",
    "CITYNAME_AR": "شعب رخام",
    "CITYNAME_EN": "Shaeb Rakham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5279",
    "CITYNAME_AR": "السويدى",
    "CITYNAME_EN": "Alsuwydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5280",
    "CITYNAME_AR": "اصرم",
    "CITYNAME_EN": "Asrim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5281",
    "CITYNAME_AR": "ذا الراحه",
    "CITYNAME_EN": "Dha Alraahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5282",
    "CITYNAME_AR": "الرتقه",
    "CITYNAME_EN": "Alratquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5283",
    "CITYNAME_AR": "شعب الفارعه",
    "CITYNAME_EN": "Shaeb Alfarieah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5284",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Alshawahtuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5285",
    "CITYNAME_AR": "مثيبه",
    "CITYNAME_EN": "Muthibah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5286",
    "CITYNAME_AR": "شعب الره بسلبه",
    "CITYNAME_EN": "Shaeb Alrah Bisalbih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5287",
    "CITYNAME_AR": "الره",
    "CITYNAME_EN": "Alrh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5288",
    "CITYNAME_AR": "السليعاء",
    "CITYNAME_EN": "Alsilyea'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5289",
    "CITYNAME_AR": "شعب الصرح",
    "CITYNAME_EN": "Shaeb Alsarh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5290",
    "CITYNAME_AR": "الخرب",
    "CITYNAME_EN": "Alkharb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5291",
    "CITYNAME_AR": "الواشله",
    "CITYNAME_EN": "Alwashiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5292",
    "CITYNAME_AR": "شعب الهيله",
    "CITYNAME_EN": "Shaeb Alhilh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5293",
    "CITYNAME_AR": "ابوعصيده",
    "CITYNAME_EN": "Abwesydh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5294",
    "CITYNAME_AR": "السمير الاسفل",
    "CITYNAME_EN": "Alsamir Al'Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5295",
    "CITYNAME_AR": "ضحوه بالروضه",
    "CITYNAME_EN": "Dahuh Bialruwdih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5296",
    "CITYNAME_AR": "الصريحه",
    "CITYNAME_EN": "Alsarihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5297",
    "CITYNAME_AR": "براق والدميغ",
    "CITYNAME_EN": "Biraq Waldamigh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5298",
    "CITYNAME_AR": "المضباع",
    "CITYNAME_EN": "Almidbae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5299",
    "CITYNAME_AR": "الخشن",
    "CITYNAME_EN": "Alkhashn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5300",
    "CITYNAME_AR": "جذم",
    "CITYNAME_EN": "Jadham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5301",
    "CITYNAME_AR": "الوصله",
    "CITYNAME_EN": "Alwasaluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5302",
    "CITYNAME_AR": "الحصبه",
    "CITYNAME_EN": "Alhasbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5303",
    "CITYNAME_AR": "البلوعه",
    "CITYNAME_EN": "Albulueuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5304",
    "CITYNAME_AR": "ذوالنقره",
    "CITYNAME_EN": "Dhualnaqrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5305",
    "CITYNAME_AR": "الجدر",
    "CITYNAME_EN": "Aljadar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5306",
    "CITYNAME_AR": "الحشيفه",
    "CITYNAME_EN": "Alhashifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5307",
    "CITYNAME_AR": "الصريب",
    "CITYNAME_EN": "Alsarib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5308",
    "CITYNAME_AR": "غرزه",
    "CITYNAME_EN": "Gharzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5309",
    "CITYNAME_AR": "المحال",
    "CITYNAME_EN": "Almihal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5310",
    "CITYNAME_AR": "ذى النحل",
    "CITYNAME_EN": "Dha Alnahl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5311",
    "CITYNAME_AR": "اثرار",
    "CITYNAME_EN": "Athrar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5312",
    "CITYNAME_AR": "قرساء",
    "CITYNAME_EN": "Qarsa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5313",
    "CITYNAME_AR": "حراض",
    "CITYNAME_EN": "Harad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5314",
    "CITYNAME_AR": "الرديهات والحلاه",
    "CITYNAME_EN": "Alradiihat Walhalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5315",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Almusbih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5316",
    "CITYNAME_AR": "جله المسلوم",
    "CITYNAME_EN": "Jalah Almaslum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5317",
    "CITYNAME_AR": "جله القطا",
    "CITYNAME_EN": "Jalah Alqitaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5318",
    "CITYNAME_AR": "وادى تبشع",
    "CITYNAME_EN": "Wa'Adaa Tubshie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5319",
    "CITYNAME_AR": "الكبه",
    "CITYNAME_EN": "Alkabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5320",
    "CITYNAME_AR": "جرفين",
    "CITYNAME_EN": "Jrfayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5321",
    "CITYNAME_AR": "ذى القرنين",
    "CITYNAME_EN": "Dhaa Alqarnayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5322",
    "CITYNAME_AR": "سنانه",
    "CITYNAME_EN": "Sananh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5323",
    "CITYNAME_AR": "السرحا",
    "CITYNAME_EN": "Alsuraha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5324",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Aleurud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5325",
    "CITYNAME_AR": "عسر",
    "CITYNAME_EN": "Easir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5326",
    "CITYNAME_AR": "ام الخرمه",
    "CITYNAME_EN": "Umm Alkharmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5327",
    "CITYNAME_AR": "كراث",
    "CITYNAME_EN": "Kirath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5328",
    "CITYNAME_AR": "وادى الخصر",
    "CITYNAME_EN": "Wa'Adaa Alkhasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5329",
    "CITYNAME_AR": "جناب",
    "CITYNAME_EN": "Janab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5330",
    "CITYNAME_AR": "نهران",
    "CITYNAME_EN": "Nahran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5331",
    "CITYNAME_AR": "شعراء والصفيحه",
    "CITYNAME_EN": "Shueara' Walsafihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5332",
    "CITYNAME_AR": "التلعه",
    "CITYNAME_EN": "Altalaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5333",
    "CITYNAME_AR": "خار",
    "CITYNAME_EN": "Khar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5334",
    "CITYNAME_AR": "سبه",
    "CITYNAME_EN": "Sbh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5335",
    "CITYNAME_AR": "ام الحكم",
    "CITYNAME_EN": "Umm Alhukm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5336",
    "CITYNAME_AR": "فراع البيض",
    "CITYNAME_EN": "Farae Albyd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5337",
    "CITYNAME_AR": "الاسود",
    "CITYNAME_EN": "Al'Aswad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5338",
    "CITYNAME_AR": "عيان",
    "CITYNAME_EN": "Eian",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5339",
    "CITYNAME_AR": "وركه",
    "CITYNAME_EN": "Warakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5340",
    "CITYNAME_AR": "شعيب طده",
    "CITYNAME_EN": "Shueayb Tadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5341",
    "CITYNAME_AR": "وادى اظلمه",
    "CITYNAME_EN": "Wa'Adaa Azlamh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5342",
    "CITYNAME_AR": "أم الحمير",
    "CITYNAME_EN": "'Ama Alhamir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5343",
    "CITYNAME_AR": "القفله",
    "CITYNAME_EN": "Alqifluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5344",
    "CITYNAME_AR": "فريقد",
    "CITYNAME_EN": "Friqid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5345",
    "CITYNAME_AR": "مراضع",
    "CITYNAME_EN": "Maradie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5346",
    "CITYNAME_AR": "المخباه",
    "CITYNAME_EN": "Almakhabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5347",
    "CITYNAME_AR": "سلعه",
    "CITYNAME_EN": "Salaeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5348",
    "CITYNAME_AR": "ذوالخرق",
    "CITYNAME_EN": "Dhualkharaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5349",
    "CITYNAME_AR": "شجى",
    "CITYNAME_EN": "Shujaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5350",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5351",
    "CITYNAME_AR": "ذا الاوجام",
    "CITYNAME_EN": "Dha Alawjam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5352",
    "CITYNAME_AR": "الفروخيه",
    "CITYNAME_EN": "Alfurukhayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5353",
    "CITYNAME_AR": "المتعه",
    "CITYNAME_EN": "Almutaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5354",
    "CITYNAME_AR": "الشعبتين",
    "CITYNAME_EN": "Alshuebatayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5355",
    "CITYNAME_AR": "الاراكه",
    "CITYNAME_EN": "Alarakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5356",
    "CITYNAME_AR": "وادى الصدره",
    "CITYNAME_EN": "Wa'Adaa Alsadrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5357",
    "CITYNAME_AR": "الجميليه",
    "CITYNAME_EN": "Aljamiliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5358",
    "CITYNAME_AR": "الطويل",
    "CITYNAME_EN": "Altawil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5359",
    "CITYNAME_AR": "ضها",
    "CITYNAME_EN": "Daha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5360",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5361",
    "CITYNAME_AR": "المنور",
    "CITYNAME_EN": "Almunur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5362",
    "CITYNAME_AR": "القامه",
    "CITYNAME_EN": "Alqamih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5363",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Aljizeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5364",
    "CITYNAME_AR": "شعيب حراض",
    "CITYNAME_EN": "Shueayb Harad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5365",
    "CITYNAME_AR": "عواص",
    "CITYNAME_EN": "Eawas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5366",
    "CITYNAME_AR": "اعشار",
    "CITYNAME_EN": "Aeshar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5367",
    "CITYNAME_AR": "شعيب ظلمان",
    "CITYNAME_EN": "Shueayb Zilman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5368",
    "CITYNAME_AR": "اللصفه",
    "CITYNAME_EN": "Allasfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5369",
    "CITYNAME_AR": "اسلع",
    "CITYNAME_EN": "Aslae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5370",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5371",
    "CITYNAME_AR": "وادى بيرين",
    "CITYNAME_EN": "Wa'Adaa Birin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5372",
    "CITYNAME_AR": "ذقنين",
    "CITYNAME_EN": "Dhaqinin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5373",
    "CITYNAME_AR": "الخفج",
    "CITYNAME_EN": "Alkhafj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5374",
    "CITYNAME_AR": "الشقيب",
    "CITYNAME_EN": "Alshaqib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5375",
    "CITYNAME_AR": "المخباه البيضاء",
    "CITYNAME_EN": "Almukhabbah Albayda'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5376",
    "CITYNAME_AR": "نمره",
    "CITYNAME_EN": "Namarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5377",
    "CITYNAME_AR": "صعيد",
    "CITYNAME_EN": "Saeid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5378",
    "CITYNAME_AR": "الصريحه",
    "CITYNAME_EN": "Alsarihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5379",
    "CITYNAME_AR": "الدميجى",
    "CITYNAME_EN": "Aldamijaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5380",
    "CITYNAME_AR": "المحفر",
    "CITYNAME_EN": "Almuhafir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5381",
    "CITYNAME_AR": "وادى جمه",
    "CITYNAME_EN": "Wa'Adaa Jmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5382",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Almadaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5383",
    "CITYNAME_AR": "شبرقه",
    "CITYNAME_EN": "Shabaraqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5384",
    "CITYNAME_AR": "ام عظام",
    "CITYNAME_EN": "Umm Eizam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5385",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Alfare",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5386",
    "CITYNAME_AR": "عضو الاعلى والاسفل",
    "CITYNAME_EN": "Eudw Al'Aelaa Walasfil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5387",
    "CITYNAME_AR": "الاقصر",
    "CITYNAME_EN": "Alaqsir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5388",
    "CITYNAME_AR": "حمك",
    "CITYNAME_EN": "Humak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5389",
    "CITYNAME_AR": "ام السدره",
    "CITYNAME_EN": "Umm Alsadaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5390",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Algharabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5391",
    "CITYNAME_AR": "ام الدرير",
    "CITYNAME_EN": "Umm Aldrir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5392",
    "CITYNAME_AR": "الفروخيه الجديده",
    "CITYNAME_EN": "Alfurukhayuh Aljadiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5393",
    "CITYNAME_AR": "المعقب",
    "CITYNAME_EN": "Almueaqib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5394",
    "CITYNAME_AR": "المبشع",
    "CITYNAME_EN": "Almubshie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5395",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Alruhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5396",
    "CITYNAME_AR": "البادى (الصريح)",
    "CITYNAME_EN": "Al Badi (As-Sareh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5397",
    "CITYNAME_AR": "غميقه",
    "CITYNAME_EN": "Ghamiqa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5398",
    "CITYNAME_AR": "مارده (تربه)",
    "CITYNAME_EN": "Mardah (Turbah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5399",
    "CITYNAME_AR": "البردى",
    "CITYNAME_EN": "Al-Bardy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "5400",
    "CITYNAME_AR": "كتنه",
    "CITYNAME_EN": "Katanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5401",
    "CITYNAME_AR": "ميسان",
    "CITYNAME_EN": "Maysan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5402",
    "CITYNAME_AR": "اللصيفه",
    "CITYNAME_EN": "Al Lasefah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5403",
    "CITYNAME_AR": "الحراء",
    "CITYNAME_EN": "Al Haraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5404",
    "CITYNAME_AR": "مرقنه",
    "CITYNAME_EN": "Marqanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5405",
    "CITYNAME_AR": "جضه",
    "CITYNAME_EN": "Hedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5406",
    "CITYNAME_AR": "الضربه",
    "CITYNAME_EN": "Ad-Darbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5407",
    "CITYNAME_AR": "مرتد",
    "CITYNAME_EN": "Murtad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5408",
    "CITYNAME_AR": "الشواحط",
    "CITYNAME_EN": "Ash-Shawahet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5409",
    "CITYNAME_AR": "الجريبيعه",
    "CITYNAME_EN": "Al Guraybayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5410",
    "CITYNAME_AR": "وادى مثان",
    "CITYNAME_EN": "Wadi Mathan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5411",
    "CITYNAME_AR": "وثال",
    "CITYNAME_EN": "Wethal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5412",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khaserah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5413",
    "CITYNAME_AR": "المريفق",
    "CITYNAME_EN": "Al Murayfaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5414",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5415",
    "CITYNAME_AR": "العجالطه",
    "CITYNAME_EN": "Al Agaletah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5416",
    "CITYNAME_AR": "السوداء (الغوقه)",
    "CITYNAME_EN": "As-Sawdaa (Al Ghawqah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5417",
    "CITYNAME_AR": "شدانه",
    "CITYNAME_EN": "Shedanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5418",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5419",
    "CITYNAME_AR": "المقبوله",
    "CITYNAME_EN": "Al Maqbolah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5420",
    "CITYNAME_AR": "العلثاء",
    "CITYNAME_EN": "Al Althaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5421",
    "CITYNAME_AR": "الشراء (المحارزه)",
    "CITYNAME_EN": "Ash-Sheraa (Al Muharezah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5422",
    "CITYNAME_AR": "جويه",
    "CITYNAME_EN": "Jawayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5423",
    "CITYNAME_AR": "الموذحه",
    "CITYNAME_EN": "Al Muzhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5424",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "As-Sad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5425",
    "CITYNAME_AR": "الدار البيضاء",
    "CITYNAME_EN": "Ad-Dar Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5426",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5427",
    "CITYNAME_AR": "الدهامشه",
    "CITYNAME_EN": "Ad-Dahamshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5428",
    "CITYNAME_AR": "الشباشبه",
    "CITYNAME_EN": "Ash-Shabashbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5429",
    "CITYNAME_AR": "مشرق",
    "CITYNAME_EN": "Mashreq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5430",
    "CITYNAME_AR": "اليحى (الشعره)",
    "CITYNAME_EN": "Alehi (Ash-Shaarah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5431",
    "CITYNAME_AR": "ذى عبال",
    "CITYNAME_EN": "The Abal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5432",
    "CITYNAME_AR": "الجناب",
    "CITYNAME_EN": "Al Janabh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5433",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5434",
    "CITYNAME_AR": "الصوفه",
    "CITYNAME_EN": "As-Sawfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5435",
    "CITYNAME_AR": "المدار (المروه)",
    "CITYNAME_EN": "Al Madar (Al Marwah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5436",
    "CITYNAME_AR": "الطفحه",
    "CITYNAME_EN": "At-Tafha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5437",
    "CITYNAME_AR": "ال محمد (المرميه)",
    "CITYNAME_EN": "Al Mohamed (Al Marbyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5438",
    "CITYNAME_AR": "الاعمق (الشعب)",
    "CITYNAME_EN": "Al Amaq (Ash-Shaab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5439",
    "CITYNAME_AR": "البزه",
    "CITYNAME_EN": "Al Bezah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5440",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5441",
    "CITYNAME_AR": "قهوه عجلان",
    "CITYNAME_EN": "Ajlan cafe",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5442",
    "CITYNAME_AR": "مرزوقه",
    "CITYNAME_EN": "Marzouqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5443",
    "CITYNAME_AR": "ذى حماط",
    "CITYNAME_EN": "The Hamat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5444",
    "CITYNAME_AR": "الثغره",
    "CITYNAME_EN": "Ath-Thaghrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5445",
    "CITYNAME_AR": "الاشرق",
    "CITYNAME_EN": "Al Ashraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5446",
    "CITYNAME_AR": "السواء",
    "CITYNAME_EN": "As-Sawaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5447",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5448",
    "CITYNAME_AR": "العبدالله",
    "CITYNAME_EN": "Al Abdullah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5449",
    "CITYNAME_AR": "ابوصاع",
    "CITYNAME_EN": "Abo Saa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5450",
    "CITYNAME_AR": "قرن مطر",
    "CITYNAME_EN": "Qarn Matar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5451",
    "CITYNAME_AR": "الريعان",
    "CITYNAME_EN": "Ar-Rayaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5452",
    "CITYNAME_AR": "المحطه",
    "CITYNAME_EN": "Al Mahattah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5453",
    "CITYNAME_AR": "الكراش",
    "CITYNAME_EN": "Al Karrash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5454",
    "CITYNAME_AR": "الشفيان",
    "CITYNAME_EN": "Ash-Shafyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5455",
    "CITYNAME_AR": "الركبة",
    "CITYNAME_EN": "Ar-Rukbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5456",
    "CITYNAME_AR": "حريزة (الحلصه)",
    "CITYNAME_EN": "Hurayzah (Al Halasah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5457",
    "CITYNAME_AR": "الهده",
    "CITYNAME_EN": "Al Hadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5458",
    "CITYNAME_AR": "الدمكه",
    "CITYNAME_EN": "Ad-Damakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5459",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5460",
    "CITYNAME_AR": "قرى وادي خرفى",
    "CITYNAME_EN": "Wadi Khurafi villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5461",
    "CITYNAME_AR": "قرى وادى غارب",
    "CITYNAME_EN": "Wadi Ghareb villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5462",
    "CITYNAME_AR": "قرى وادي جوار",
    "CITYNAME_EN": "Wadi Juwar villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5463",
    "CITYNAME_AR": "قرى وادى الحواره",
    "CITYNAME_EN": "Wadi Al Hawarah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5464",
    "CITYNAME_AR": "قرى وادى الوالج",
    "CITYNAME_EN": "Wadi Al Walej villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5465",
    "CITYNAME_AR": "المناضح",
    "CITYNAME_EN": "Al Munadeh ",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5466",
    "CITYNAME_AR": "رضوان",
    "CITYNAME_EN": "Radwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5467",
    "CITYNAME_AR": "ظفر (العجمه)",
    "CITYNAME_EN": "Zafr (Al Ajamah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5468",
    "CITYNAME_AR": "الجناب",
    "CITYNAME_EN": "Al Janab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5469",
    "CITYNAME_AR": "صلب",
    "CITYNAME_EN": "Salb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5470",
    "CITYNAME_AR": "المعلاه (جويه)",
    "CITYNAME_EN": "Al Mualah (Jaweyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5471",
    "CITYNAME_AR": "السنيد",
    "CITYNAME_EN": "As-Saneed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5472",
    "CITYNAME_AR": "داماالسوداء",
    "CITYNAME_EN": "Dama As-Sawdaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5473",
    "CITYNAME_AR": "العوجب",
    "CITYNAME_EN": "Al Awjab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5474",
    "CITYNAME_AR": "الدحل",
    "CITYNAME_EN": "Ad-Duhul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5475",
    "CITYNAME_AR": "اغر",
    "CITYNAME_EN": "Aghar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5476",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sedrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5477",
    "CITYNAME_AR": "الشعبه (الشعيبة)",
    "CITYNAME_EN": "Ash-Shuabah (Ash-Shuaibah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5478",
    "CITYNAME_AR": "المكره",
    "CITYNAME_EN": "Al Mukrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5479",
    "CITYNAME_AR": "الصخره",
    "CITYNAME_EN": "As-Sakhrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5480",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5481",
    "CITYNAME_AR": "السلاء",
    "CITYNAME_EN": "As-Salaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5482",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5483",
    "CITYNAME_AR": "شعب بسان",
    "CITYNAME_EN": "Shuab Basan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5484",
    "CITYNAME_AR": "ثعيلبان",
    "CITYNAME_EN": "Thuaylaban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5485",
    "CITYNAME_AR": "معر",
    "CITYNAME_EN": "Maar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5486",
    "CITYNAME_AR": "الجناب الابيض",
    "CITYNAME_EN": "Al Janab Al Abyad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5487",
    "CITYNAME_AR": "المصرخ",
    "CITYNAME_EN": "Al Musarrakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5488",
    "CITYNAME_AR": "الشاحطه",
    "CITYNAME_EN": "Ash-Shahetah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5489",
    "CITYNAME_AR": "الملح",
    "CITYNAME_EN": "Al Malh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5490",
    "CITYNAME_AR": "السدين",
    "CITYNAME_EN": "As-Saden",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5491",
    "CITYNAME_AR": "رخمات (الكتيه)",
    "CITYNAME_EN": "Rakhmat (Al Katyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5492",
    "CITYNAME_AR": "الاشرق",
    "CITYNAME_EN": "Al Ashraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5493",
    "CITYNAME_AR": "انصح",
    "CITYNAME_EN": "Ansah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5494",
    "CITYNAME_AR": "(القان) ابوضروه",
    "CITYNAME_EN": "(Al Qan) Abo Daruah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5495",
    "CITYNAME_AR": "المضحاه",
    "CITYNAME_EN": "Al Mudhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5496",
    "CITYNAME_AR": "الحلقه (ام حريش)",
    "CITYNAME_EN": "Al Halaqah (Om Haresh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5497",
    "CITYNAME_AR": "المريخه",
    "CITYNAME_EN": "Al Marekhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5498",
    "CITYNAME_AR": "مكشمه",
    "CITYNAME_EN": "Makmashah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5499",
    "CITYNAME_AR": "العبب",
    "CITYNAME_EN": "Al Abab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5500",
    "CITYNAME_AR": "مقرح نعض",
    "CITYNAME_EN": "Muqreh Naad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5501",
    "CITYNAME_AR": "ترعه ثقيف",
    "CITYNAME_EN": "Tareuh Thaqif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5502",
    "CITYNAME_AR": "الشعايب",
    "CITYNAME_EN": "Alshaeayib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5503",
    "CITYNAME_AR": "حثواء",
    "CITYNAME_EN": "Hathwa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5504",
    "CITYNAME_AR": "البردة",
    "CITYNAME_EN": "Albarda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5505",
    "CITYNAME_AR": "اللصب",
    "CITYNAME_EN": "Al lasab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5506",
    "CITYNAME_AR": "الغبيراء",
    "CITYNAME_EN": "Al Ghubayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5507",
    "CITYNAME_AR": "حماده",
    "CITYNAME_EN": "Hamadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5508",
    "CITYNAME_AR": "ريع الغنم",
    "CITYNAME_EN": "Rei Al Ghanam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5509",
    "CITYNAME_AR": "شعب الحجر",
    "CITYNAME_EN": "Shuab Al Hajar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5510",
    "CITYNAME_AR": "شبانه",
    "CITYNAME_EN": "Shabanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5511",
    "CITYNAME_AR": "صرار",
    "CITYNAME_EN": "Serar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5512",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar-Rawh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5513",
    "CITYNAME_AR": "الشهباء(الحشابره)",
    "CITYNAME_EN": "Ash-Shahbaa (Al Hashaberah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5514",
    "CITYNAME_AR": "الوحشه",
    "CITYNAME_EN": "Al Wahshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5515",
    "CITYNAME_AR": "المجارده",
    "CITYNAME_EN": "Almjardh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5516",
    "CITYNAME_AR": "تالقي",
    "CITYNAME_EN": "Taleqi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5517",
    "CITYNAME_AR": "الجداد",
    "CITYNAME_EN": "Al Judad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5518",
    "CITYNAME_AR": "العسله",
    "CITYNAME_EN": "Al Asalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5519",
    "CITYNAME_AR": "الشرمان",
    "CITYNAME_EN": "Ash-Sherman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5520",
    "CITYNAME_AR": "شكر",
    "CITYNAME_EN": "Shukr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5521",
    "CITYNAME_AR": "العاصد",
    "CITYNAME_EN": "Al Ased",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5522",
    "CITYNAME_AR": "الفرعين",
    "CITYNAME_EN": "Alfaraaien",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5523",
    "CITYNAME_AR": "المختبى",
    "CITYNAME_EN": "Al Mukhtabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5524",
    "CITYNAME_AR": "الزوه",
    "CITYNAME_EN": "Az-Zawwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5525",
    "CITYNAME_AR": "البقمه",
    "CITYNAME_EN": "Al Baqmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5526",
    "CITYNAME_AR": "مدغل",
    "CITYNAME_EN": "Mudghel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5527",
    "CITYNAME_AR": "بن عاطى",
    "CITYNAME_EN": "Bin Atti",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5528",
    "CITYNAME_AR": "الصار",
    "CITYNAME_EN": "As-Sar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5529",
    "CITYNAME_AR": "خضيره",
    "CITYNAME_EN": "Khudayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5530",
    "CITYNAME_AR": " الجبيل",
    "CITYNAME_EN": "Jubail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5531",
    "CITYNAME_AR": "الخلص",
    "CITYNAME_EN": "Al Khalas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5532",
    "CITYNAME_AR": "الحصنه",
    "CITYNAME_EN": "Al Hasanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5533",
    "CITYNAME_AR": "الشطبات",
    "CITYNAME_EN": "Ash-Shatbat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5534",
    "CITYNAME_AR": "الحران",
    "CITYNAME_EN": "Al Haran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5535",
    "CITYNAME_AR": "زبى",
    "CITYNAME_EN": "Zeye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5536",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Al Qulah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5537",
    "CITYNAME_AR": "الهدى",
    "CITYNAME_EN": "Al Huda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5538",
    "CITYNAME_AR": "مدخله (الظهره)",
    "CITYNAME_EN": "Mudkhalah (Az-Zuhrah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5539",
    "CITYNAME_AR": "موبل",
    "CITYNAME_EN": "Muweil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5540",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "As-Sawda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5541",
    "CITYNAME_AR": "المنيكب",
    "CITYNAME_EN": "Al Munaykeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5542",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al Halah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5543",
    "CITYNAME_AR": "العشمه",
    "CITYNAME_EN": "Al Ashamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5544",
    "CITYNAME_AR": "الجناحين",
    "CITYNAME_EN": "Al Jenahayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5545",
    "CITYNAME_AR": "قها والشباعين",
    "CITYNAME_EN": "Qaha wa Ash-Shabaeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5546",
    "CITYNAME_AR": "المدان",
    "CITYNAME_EN": "Al Mudan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5547",
    "CITYNAME_AR": "ابوالجرن",
    "CITYNAME_EN": "Abo Al Jarn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5548",
    "CITYNAME_AR": "المدانه",
    "CITYNAME_EN": "Al Mudanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5549",
    "CITYNAME_AR": "العمار",
    "CITYNAME_EN": "Al Emar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5550",
    "CITYNAME_AR": "المحوط",
    "CITYNAME_EN": "Al Muhawat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5551",
    "CITYNAME_AR": "هضاب الدار",
    "CITYNAME_EN": "Hedab Ad-Dar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5552",
    "CITYNAME_AR": "اللهام",
    "CITYNAME_EN": "Al Leham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5553",
    "CITYNAME_AR": "السدين",
    "CITYNAME_EN": "As-Saden",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5554",
    "CITYNAME_AR": "العريش",
    "CITYNAME_EN": "Al Arish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5555",
    "CITYNAME_AR": "شعب التوار",
    "CITYNAME_EN": "Shuab Al Tuwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5556",
    "CITYNAME_AR": "ابوراكه",
    "CITYNAME_EN": "Abu Rakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5557",
    "CITYNAME_AR": "جليل",
    "CITYNAME_EN": "Jalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5558",
    "CITYNAME_AR": "ابورميث",
    "CITYNAME_EN": "Abo Rumaith",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5559",
    "CITYNAME_AR": "ناهيه",
    "CITYNAME_EN": "Nahyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5560",
    "CITYNAME_AR": "وادى ضرا",
    "CITYNAME_EN": "Wadi Dera",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5561",
    "CITYNAME_AR": "تلعه غزيل",
    "CITYNAME_EN": "Talaah Ghuzayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5562",
    "CITYNAME_AR": "قسط",
    "CITYNAME_EN": "Qest",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5563",
    "CITYNAME_AR": "ام موسى",
    "CITYNAME_EN": "Om Moussa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5564",
    "CITYNAME_AR": "جريب",
    "CITYNAME_EN": "Jareeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5565",
    "CITYNAME_AR": "المزيرعه",
    "CITYNAME_EN": "Al Mozera'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5566",
    "CITYNAME_AR": "تناضب",
    "CITYNAME_EN": "Tanadub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5567",
    "CITYNAME_AR": "عنا",
    "CITYNAME_EN": "Ana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5568",
    "CITYNAME_AR": "المفجر",
    "CITYNAME_EN": "Al Mufajer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5569",
    "CITYNAME_AR": "المعلاه",
    "CITYNAME_EN": "Al Moalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5570",
    "CITYNAME_AR": "الخمره",
    "CITYNAME_EN": "Al Khamrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5571",
    "CITYNAME_AR": "محطه اهره للبنزين",
    "CITYNAME_EN": "Ahrah oil station",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5572",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Al Hazem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5573",
    "CITYNAME_AR": "ربخان",
    "CITYNAME_EN": "Rabkhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5574",
    "CITYNAME_AR": "جبل الخياله",
    "CITYNAME_EN": "Al Khayalah mountain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5575",
    "CITYNAME_AR": "الخياله",
    "CITYNAME_EN": "Al Khayalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5576",
    "CITYNAME_AR": "وادي بوا",
    "CITYNAME_EN": "Wadi Bawa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5577",
    "CITYNAME_AR": "السليم الاعلى",
    "CITYNAME_EN": "As-Salem Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5578",
    "CITYNAME_AR": "معشورا",
    "CITYNAME_EN": "Maashoura",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5579",
    "CITYNAME_AR": "الحرث",
    "CITYNAME_EN": "Al-Harth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5580",
    "CITYNAME_AR": "القرده",
    "CITYNAME_EN": "Al Qeradah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5581",
    "CITYNAME_AR": "ام الزويبنات",
    "CITYNAME_EN": "Om Al Zubenat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5582",
    "CITYNAME_AR": "الدلاعسيه",
    "CITYNAME_EN": "Ad-Dalaasyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5583",
    "CITYNAME_AR": "الامره",
    "CITYNAME_EN": "Al Amarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5584",
    "CITYNAME_AR": "تلعه عجب",
    "CITYNAME_EN": "Talaah Ajab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5585",
    "CITYNAME_AR": "السليم الاسفل (الحصن)",
    "CITYNAME_EN": "As-Salim Al Asfal (Al Hosn)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5586",
    "CITYNAME_AR": "قرضا",
    "CITYNAME_EN": "Qerda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5587",
    "CITYNAME_AR": "الحشانه",
    "CITYNAME_EN": "Al Hashanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5588",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5589",
    "CITYNAME_AR": "وادى الجبلات",
    "CITYNAME_EN": "Wadi Al Jabalat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5590",
    "CITYNAME_AR": "الشدقا",
    "CITYNAME_EN": "Ash-Shadaqa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5591",
    "CITYNAME_AR": "وادى نشران",
    "CITYNAME_EN": "Wadi Nashran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5592",
    "CITYNAME_AR": "شوقب",
    "CITYNAME_EN": "Shawqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5593",
    "CITYNAME_AR": "الرشاه",
    "CITYNAME_EN": "Ar-Rashah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5594",
    "CITYNAME_AR": "العقله",
    "CITYNAME_EN": "Al Uqlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5595",
    "CITYNAME_AR": "الجبلات",
    "CITYNAME_EN": "Al Jabalat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5596",
    "CITYNAME_AR": "نشران",
    "CITYNAME_EN": "Nashran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5597",
    "CITYNAME_AR": "مره",
    "CITYNAME_EN": "Murrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5598",
    "CITYNAME_AR": "العشيريه",
    "CITYNAME_EN": "Al Oshayryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5599",
    "CITYNAME_AR": "ظهى",
    "CITYNAME_EN": "Zaha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5600",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Al Sour",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5601",
    "CITYNAME_AR": "السوادين",
    "CITYNAME_EN": "As-Sawadayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5602",
    "CITYNAME_AR": "الاضحكان وراس السدره",
    "CITYNAME_EN": "Al Edhakat waras As-Sidrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5603",
    "CITYNAME_AR": "الجبيان",
    "CITYNAME_EN": "Al Jubyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5604",
    "CITYNAME_AR": "ام الغيران",
    "CITYNAME_EN": "Om Al Gheran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5605",
    "CITYNAME_AR": "وجره وام السعود",
    "CITYNAME_EN": "Wajrah wa Om As-Saud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5606",
    "CITYNAME_AR": "النحيلات",
    "CITYNAME_EN": "An-Nuhaylat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5607",
    "CITYNAME_AR": "ريشان والريع",
    "CITYNAME_EN": "Reshan wa Ar-Rubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5608",
    "CITYNAME_AR": "الدمجاء",
    "CITYNAME_EN": "Ad-Damjaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5609",
    "CITYNAME_AR": "الحفاه",
    "CITYNAME_EN": "Al Hufah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5610",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al Hesn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5611",
    "CITYNAME_AR": "القبسان",
    "CITYNAME_EN": "Al Qobsan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5612",
    "CITYNAME_AR": "السفح",
    "CITYNAME_EN": "As-Safah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5613",
    "CITYNAME_AR": "القبيسى",
    "CITYNAME_EN": "Al Qobaisi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5614",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Almilaha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5615",
    "CITYNAME_AR": "العريش",
    "CITYNAME_EN": "Alearish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5616",
    "CITYNAME_AR": "الرضيم",
    "CITYNAME_EN": "Ar-Radeem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5617",
    "CITYNAME_AR": "الصاعده والصدر",
    "CITYNAME_EN": "As-Saedah wa As-Sadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5618",
    "CITYNAME_AR": "الورخه",
    "CITYNAME_EN": "Al Warkhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5619",
    "CITYNAME_AR": "السحن",
    "CITYNAME_EN": "As-Sahan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5620",
    "CITYNAME_AR": "النيمه",
    "CITYNAME_EN": "An-Naymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5621",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5622",
    "CITYNAME_AR": "الثرمان",
    "CITYNAME_EN": "Ath-Tharman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5623",
    "CITYNAME_AR": "الخياله (بن سارى)",
    "CITYNAME_EN": "Al Khayalah (bin Sari)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5624",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5625",
    "CITYNAME_AR": "صور بن شكوان",
    "CITYNAME_EN": "Sur bin Shawkan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5626",
    "CITYNAME_AR": "الغراب",
    "CITYNAME_EN": "Al Ghurab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5627",
    "CITYNAME_AR": "الرصفه",
    "CITYNAME_EN": "Al Rasfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5628",
    "CITYNAME_AR": "صعيد السوق",
    "CITYNAME_EN": "Saeed As-Sawq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5629",
    "CITYNAME_AR": "الخشاشه",
    "CITYNAME_EN": "Al Khashashah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5630",
    "CITYNAME_AR": "الكلاده",
    "CITYNAME_EN": "Al Kaladah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5631",
    "CITYNAME_AR": "الدار الحمراء",
    "CITYNAME_EN": "Ad-Dar Al Hamraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5632",
    "CITYNAME_AR": "الشعبه (الغثه)",
    "CITYNAME_EN": "Ash-Shuabah (Al Ghathah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5633",
    "CITYNAME_AR": "البصلان",
    "CITYNAME_EN": "Al Baslan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5634",
    "CITYNAME_AR": "الدهامين",
    "CITYNAME_EN": "Ad-Duhameen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5635",
    "CITYNAME_AR": "المحارث",
    "CITYNAME_EN": "Al Muhareth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5636",
    "CITYNAME_AR": "مخلد",
    "CITYNAME_EN": "Mukhled",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5637",
    "CITYNAME_AR": "لغب",
    "CITYNAME_EN": "Laghab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5638",
    "CITYNAME_AR": "البراريق",
    "CITYNAME_EN": "Al Barareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5639",
    "CITYNAME_AR": "الشروط",
    "CITYNAME_EN": "Ash-Shurut",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5640",
    "CITYNAME_AR": "الرحى",
    "CITYNAME_EN": "Ar-Ruha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5641",
    "CITYNAME_AR": "الطلحه",
    "CITYNAME_EN": "At-Talhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5642",
    "CITYNAME_AR": "ذنيب الرحى",
    "CITYNAME_EN": "Zaneeb Ar-Ruha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5643",
    "CITYNAME_AR": "شعب الفراش",
    "CITYNAME_EN": "Shuaib Al Ferash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5644",
    "CITYNAME_AR": "اطلح",
    "CITYNAME_EN": "Atlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5645",
    "CITYNAME_AR": "الضباعين",
    "CITYNAME_EN": "Ad-Dabaeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5646",
    "CITYNAME_AR": "الشعب الاحمر وبن عكيم",
    "CITYNAME_EN": "Ash-Shuab Al Ahmar wa bin Akeem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5647",
    "CITYNAME_AR": "العيسى",
    "CITYNAME_EN": "Al Ayse",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5648",
    "CITYNAME_AR": "العوصاء (الشهبه)",
    "CITYNAME_EN": "Al Awsaa (Ash-Shuhabah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5649",
    "CITYNAME_AR": "العروشيه",
    "CITYNAME_EN": "Al Aroshyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5650",
    "CITYNAME_AR": "المغره",
    "CITYNAME_EN": "Al Mughrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5651",
    "CITYNAME_AR": "وادى الشعبه",
    "CITYNAME_EN": "Wadi Ash-Shuabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5652",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5653",
    "CITYNAME_AR": "المريكبه",
    "CITYNAME_EN": "Al Marbakbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5654",
    "CITYNAME_AR": "المريفق",
    "CITYNAME_EN": "Al Murayfaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5655",
    "CITYNAME_AR": "الحسى",
    "CITYNAME_EN": "Al Hasi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5656",
    "CITYNAME_AR": "المديد",
    "CITYNAME_EN": "Al Maded",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5657",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5658",
    "CITYNAME_AR": "الصبخه",
    "CITYNAME_EN": "As-Sabkhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5659",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5660",
    "CITYNAME_AR": "الاساله",
    "CITYNAME_EN": "Al Esalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5661",
    "CITYNAME_AR": "الرميده",
    "CITYNAME_EN": "Ar-Rumaydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5662",
    "CITYNAME_AR": "ابورومى",
    "CITYNAME_EN": "Abo Rumi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5663",
    "CITYNAME_AR": "خفاش",
    "CITYNAME_EN": "Khuffash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5664",
    "CITYNAME_AR": "صعبه",
    "CITYNAME_EN": "Saabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5665",
    "CITYNAME_AR": "الدهاسين والقرين",
    "CITYNAME_EN": "Ad-Dahaseen wa Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5666",
    "CITYNAME_AR": "الخلط",
    "CITYNAME_EN": "Al Khalt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5667",
    "CITYNAME_AR": "دار الريع (الصبيخه)",
    "CITYNAME_EN": "Dar Ar-Rubi' (As-Subaykhah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5668",
    "CITYNAME_AR": "الصرف",
    "CITYNAME_EN": "As-Sarf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5669",
    "CITYNAME_AR": "المجمعه والجناب",
    "CITYNAME_EN": "Al Mujmaah wa Al Janab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5670",
    "CITYNAME_AR": "الثني",
    "CITYNAME_EN": "Ath-Thani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5671",
    "CITYNAME_AR": "بلاد بريك",
    "CITYNAME_EN": "Belad Berek",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5672",
    "CITYNAME_AR": "الطواعن",
    "CITYNAME_EN": "At-Tawaen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5673",
    "CITYNAME_AR": "الطفيحاء",
    "CITYNAME_EN": "At-Tufaihaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5674",
    "CITYNAME_AR": "الصنيع",
    "CITYNAME_EN": "As-Sanei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5675",
    "CITYNAME_AR": "الخربه",
    "CITYNAME_EN": "Al Kharabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5676",
    "CITYNAME_AR": "المغبيه",
    "CITYNAME_EN": "Al Mughbyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5677",
    "CITYNAME_AR": "الشثه",
    "CITYNAME_EN": "Ash-Shatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5678",
    "CITYNAME_AR": "الدحيض",
    "CITYNAME_EN": "Ad-Daheed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5679",
    "CITYNAME_AR": "البركه",
    "CITYNAME_EN": "Albarakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5680",
    "CITYNAME_AR": "الضارب",
    "CITYNAME_EN": "Ad-Dareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5681",
    "CITYNAME_AR": "الذبانيه",
    "CITYNAME_EN": "Az-Zabanyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5682",
    "CITYNAME_AR": "الجرادحه",
    "CITYNAME_EN": "Al Jaradhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5683",
    "CITYNAME_AR": "البراريق",
    "CITYNAME_EN": "Al Barareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5684",
    "CITYNAME_AR": "الفقها",
    "CITYNAME_EN": "Al Fuqhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5685",
    "CITYNAME_AR": "دار الطحاحين",
    "CITYNAME_EN": "Dar At-Tahaheen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5686",
    "CITYNAME_AR": "الدار الحمراء (المناجيم)",
    "CITYNAME_EN": "Ad-Dar Al Hamraa (Al Manajeem)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5687",
    "CITYNAME_AR": "الحديب",
    "CITYNAME_EN": "Al Hudayeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5688",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Usum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5689",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Al Asm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5690",
    "CITYNAME_AR": "المطيريه",
    "CITYNAME_EN": "Al Mutayryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5691",
    "CITYNAME_AR": "دفاف الاعلى",
    "CITYNAME_EN": "Defaf Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5692",
    "CITYNAME_AR": "دفاف الاسفل",
    "CITYNAME_EN": "Defaf Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5693",
    "CITYNAME_AR": "الدومه",
    "CITYNAME_EN": "Ad-Doumah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5694",
    "CITYNAME_AR": "الحوتيه",
    "CITYNAME_EN": "Al Hawtyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5695",
    "CITYNAME_AR": "القصر",
    "CITYNAME_EN": "Al Qasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5696",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "As-Sad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5697",
    "CITYNAME_AR": "الثنيه",
    "CITYNAME_EN": "Al Thenieah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5698",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5699",
    "CITYNAME_AR": "نايف",
    "CITYNAME_EN": "Nayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5700",
    "CITYNAME_AR": "عريفج والشواحط",
    "CITYNAME_EN": "Arefaj wa Ash-Shawahet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5701",
    "CITYNAME_AR": "العقده وبن صويلح",
    "CITYNAME_EN": "Al Oqdah wa bin Sweilah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5702",
    "CITYNAME_AR": "الغريب",
    "CITYNAME_EN": "Al Ghareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5703",
    "CITYNAME_AR": "الجميعات",
    "CITYNAME_EN": "Al Gameyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5704",
    "CITYNAME_AR": "جيوش",
    "CITYNAME_EN": "Juwesh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5705",
    "CITYNAME_AR": "العصاميه (القراحين)",
    "CITYNAME_EN": "Al Osamyah (Al Qaraheen)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5706",
    "CITYNAME_AR": "القحوم",
    "CITYNAME_EN": "Al Qohom",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5707",
    "CITYNAME_AR": "السبيحه",
    "CITYNAME_EN": "As-Subaihah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5708",
    "CITYNAME_AR": "شعب زايد",
    "CITYNAME_EN": "Shuab Zayed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5709",
    "CITYNAME_AR": "المعيدن",
    "CITYNAME_EN": "Al Muaydan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5710",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Al Khulayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5711",
    "CITYNAME_AR": "اللبه",
    "CITYNAME_EN": "Al Labbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5712",
    "CITYNAME_AR": "عقيب",
    "CITYNAME_EN": "Oqaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5713",
    "CITYNAME_AR": "خشوه",
    "CITYNAME_EN": "Khashwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5714",
    "CITYNAME_AR": "الذيبه",
    "CITYNAME_EN": "Az-Zaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5715",
    "CITYNAME_AR": "يعقوب",
    "CITYNAME_EN": "Yaaqub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5716",
    "CITYNAME_AR": "الربيقه",
    "CITYNAME_EN": "Ar-Rubayqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5717",
    "CITYNAME_AR": "الحماسين",
    "CITYNAME_EN": "Al Hamaseen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5718",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5719",
    "CITYNAME_AR": "عقيبه",
    "CITYNAME_EN": "Oqaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5720",
    "CITYNAME_AR": "العلنصاء",
    "CITYNAME_EN": "Al Alansaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5721",
    "CITYNAME_AR": "عرفج",
    "CITYNAME_EN": "Arfaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5722",
    "CITYNAME_AR": "شعب الفار",
    "CITYNAME_EN": "Shuab Al Far",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5723",
    "CITYNAME_AR": "الشعبه ( بن غريب)",
    "CITYNAME_EN": "Ash-Shuabah (bin Gharib)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5724",
    "CITYNAME_AR": "غديقه",
    "CITYNAME_EN": "Ghudayqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5725",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "As-Sahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5726",
    "CITYNAME_AR": "الخرجه",
    "CITYNAME_EN": "Al Kharjah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5727",
    "CITYNAME_AR": "المهضم",
    "CITYNAME_EN": "Al Muhdem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5728",
    "CITYNAME_AR": "المغرز",
    "CITYNAME_EN": "Al Maghraz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5729",
    "CITYNAME_AR": "الخوقاء",
    "CITYNAME_EN": "Al Khawqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5730",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Ash-Sharaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5731",
    "CITYNAME_AR": "العذبه",
    "CITYNAME_EN": "Al Azabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5732",
    "CITYNAME_AR": "القنه (الكلاحيت)",
    "CITYNAME_EN": "Al Qenah (Al Kalaheet)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5733",
    "CITYNAME_AR": "المثناه (النجيمات)",
    "CITYNAME_EN": "Al Muthnah (An-Nujaimat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5734",
    "CITYNAME_AR": "القرين (ذوى يزيد)",
    "CITYNAME_EN": "Al Qorin (Thawi Yazid)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5735",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al Jazaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5736",
    "CITYNAME_AR": "العبل",
    "CITYNAME_EN": "Al Obal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5737",
    "CITYNAME_AR": "الفريعه",
    "CITYNAME_EN": "Al Furayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5738",
    "CITYNAME_AR": "سواس",
    "CITYNAME_EN": "Sawas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5739",
    "CITYNAME_AR": "القهب",
    "CITYNAME_EN": "Al Qahb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5740",
    "CITYNAME_AR": "المشيان (العريج)",
    "CITYNAME_EN": "Al Mashyan (Al Areej)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5741",
    "CITYNAME_AR": "منيفه",
    "CITYNAME_EN": "Munayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5742",
    "CITYNAME_AR": "الاجوف (ام سعن)",
    "CITYNAME_EN": "Al Ajwaf (Om Saan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5743",
    "CITYNAME_AR": "الزبيه",
    "CITYNAME_EN": "Az-Zubayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5744",
    "CITYNAME_AR": "الشعاريه",
    "CITYNAME_EN": "Ash-Shaaryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5745",
    "CITYNAME_AR": "المحاسنه",
    "CITYNAME_EN": "Al Muhasenah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5746",
    "CITYNAME_AR": "الغدران",
    "CITYNAME_EN": "Al Ghadran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5747",
    "CITYNAME_AR": "اليعاقيب",
    "CITYNAME_EN": "Al Yaaqeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5748",
    "CITYNAME_AR": "القلايا",
    "CITYNAME_EN": "Al Qalaya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5749",
    "CITYNAME_AR": "المغاوره",
    "CITYNAME_EN": "Al Mughawerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5750",
    "CITYNAME_AR": "عريم",
    "CITYNAME_EN": "Areem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5751",
    "CITYNAME_AR": "مرزوق",
    "CITYNAME_EN": "Marzouq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5752",
    "CITYNAME_AR": "الفيراع",
    "CITYNAME_EN": "Al Fayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5753",
    "CITYNAME_AR": "الخبيب",
    "CITYNAME_EN": "As-Sunaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5754",
    "CITYNAME_AR": "شعبه الذيب",
    "CITYNAME_EN": "Shuabah Az-Zaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5755",
    "CITYNAME_AR": "النهج",
    "CITYNAME_EN": "An-Nahj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5756",
    "CITYNAME_AR": "الصفاه",
    "CITYNAME_EN": "As-Safah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5757",
    "CITYNAME_AR": "الشتيات",
    "CITYNAME_EN": "Ash-Shutyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5758",
    "CITYNAME_AR": "الهدف",
    "CITYNAME_EN": "Al Hadaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5759",
    "CITYNAME_AR": "الخيف",
    "CITYNAME_EN": "Al Kheef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5760",
    "CITYNAME_AR": "الطفلان",
    "CITYNAME_EN": "At-Taflan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5761",
    "CITYNAME_AR": "الوكف",
    "CITYNAME_EN": "Al Wakaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5762",
    "CITYNAME_AR": "العطاء",
    "CITYNAME_EN": "Al Ataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5763",
    "CITYNAME_AR": "شعب العطاء",
    "CITYNAME_EN": "Shuab Al Ataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5764",
    "CITYNAME_AR": "العنمه",
    "CITYNAME_EN": "Al Enmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5765",
    "CITYNAME_AR": "الشعبه البيضاء",
    "CITYNAME_EN": "Ash-Shuabah Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5766",
    "CITYNAME_AR": "الاقر",
    "CITYNAME_EN": "Al Aqar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5767",
    "CITYNAME_AR": "الشعيراء",
    "CITYNAME_EN": "Ash-Shuayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5768",
    "CITYNAME_AR": "الجمالين",
    "CITYNAME_EN": "Al Jamaleen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5769",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5770",
    "CITYNAME_AR": "حسين",
    "CITYNAME_EN": "Hussain ",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5771",
    "CITYNAME_AR": "شعب بقره",
    "CITYNAME_EN": "Shuab Baqarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5772",
    "CITYNAME_AR": "المشقر",
    "CITYNAME_EN": "Al Mushqer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5773",
    "CITYNAME_AR": "الابله والسلع",
    "CITYNAME_EN": "Al Ablah wa As-Selaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5774",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5775",
    "CITYNAME_AR": "الملحج",
    "CITYNAME_EN": "Al Mulhaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5776",
    "CITYNAME_AR": "مهد ايان",
    "CITYNAME_EN": "Mahd Ayan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5777",
    "CITYNAME_AR": "المتنه",
    "CITYNAME_EN": "Al Matnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5778",
    "CITYNAME_AR": "العلطه",
    "CITYNAME_EN": "Al Altah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5779",
    "CITYNAME_AR": "البنيا",
    "CITYNAME_EN": "Al Bunya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5780",
    "CITYNAME_AR": "الجدير",
    "CITYNAME_EN": "Al Judayer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5781",
    "CITYNAME_AR": "مسجد جمعه",
    "CITYNAME_EN": "Jomaa mosque",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5782",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Al Jarieh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5783",
    "CITYNAME_AR": "حلف رمل",
    "CITYNAME_EN": "Halaf Raml",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5784",
    "CITYNAME_AR": "الكهفه",
    "CITYNAME_EN": "Al Kahfa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5785",
    "CITYNAME_AR": "جناب النهج",
    "CITYNAME_EN": "Junab An-Nahj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5786",
    "CITYNAME_AR": "الصرف",
    "CITYNAME_EN": "As-Sarf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5787",
    "CITYNAME_AR": "اوثال",
    "CITYNAME_EN": "Awthal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5788",
    "CITYNAME_AR": "القطبين",
    "CITYNAME_EN": "Al Qutbayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5789",
    "CITYNAME_AR": "كحله",
    "CITYNAME_EN": "Kahla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5790",
    "CITYNAME_AR": "الودنه",
    "CITYNAME_EN": "Al Wednah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5791",
    "CITYNAME_AR": "المعداه",
    "CITYNAME_EN": "Al Muadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5792",
    "CITYNAME_AR": "خرفي",
    "CITYNAME_EN": "Kherfi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5793",
    "CITYNAME_AR": "السويداء",
    "CITYNAME_EN": "As-Suwaidaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5794",
    "CITYNAME_AR": "حمر",
    "CITYNAME_EN": "Hamra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5795",
    "CITYNAME_AR": "الركوه",
    "CITYNAME_EN": "Ar-Rakwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5796",
    "CITYNAME_AR": "الكشمه",
    "CITYNAME_EN": "Al Kashamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5797",
    "CITYNAME_AR": "ام العش",
    "CITYNAME_EN": "Om Al Osh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5798",
    "CITYNAME_AR": "الحويمض",
    "CITYNAME_EN": "Al Huwaymed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5799",
    "CITYNAME_AR": "بني سعد",
    "CITYNAME_EN": "Bani Saad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5800",
    "CITYNAME_AR": "الحريجه (قريه حمد)",
    "CITYNAME_EN": "Alharijuh (Qrih Hmd)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5801",
    "CITYNAME_AR": "الضحياء",
    "CITYNAME_EN": "Aldahya'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5802",
    "CITYNAME_AR": "القصر",
    "CITYNAME_EN": "Alqasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5803",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Alzibaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5804",
    "CITYNAME_AR": "الخضر",
    "CITYNAME_EN": "Alkhudar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5805",
    "CITYNAME_AR": "البردى",
    "CITYNAME_EN": "Alburdaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5806",
    "CITYNAME_AR": "الدجمان",
    "CITYNAME_EN": "Aldijman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5807",
    "CITYNAME_AR": "صنعا",
    "CITYNAME_EN": "Suneanaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5808",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Almujamaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5809",
    "CITYNAME_AR": "الحامض",
    "CITYNAME_EN": "Alhamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5810",
    "CITYNAME_AR": "القراره",
    "CITYNAME_EN": "Alqararuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5811",
    "CITYNAME_AR": "خباب",
    "CITYNAME_EN": "Khabab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5812",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5813",
    "CITYNAME_AR": "بيضاء مقيد",
    "CITYNAME_EN": "Bayda' Muqid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5814",
    "CITYNAME_AR": "قصاص",
    "CITYNAME_EN": "Qisas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5815",
    "CITYNAME_AR": "بيضاء مكتن",
    "CITYNAME_EN": "Bayda' Maktan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5816",
    "CITYNAME_AR": "ابوحبل",
    "CITYNAME_EN": "Abwhbl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5817",
    "CITYNAME_AR": "الحساوه",
    "CITYNAME_EN": "Alhasawuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5818",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Alsaalihih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5819",
    "CITYNAME_AR": "الكديه",
    "CITYNAME_EN": "Alkadih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5820",
    "CITYNAME_AR": "منازل ناجم (القاهرة)",
    "CITYNAME_EN": "Manazil Najim (Alqahrat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5821",
    "CITYNAME_AR": "عشيره مكتن",
    "CITYNAME_EN": "Eashiruh Maktan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5822",
    "CITYNAME_AR": "رفايع عشيره",
    "CITYNAME_EN": "Rafayie Eashirh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5823",
    "CITYNAME_AR": "الودنه",
    "CITYNAME_EN": "Alwadnh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5824",
    "CITYNAME_AR": "فيضه مكتن",
    "CITYNAME_EN": "Fiduh Maktan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5825",
    "CITYNAME_AR": "البويره",
    "CITYNAME_EN": "Albawayruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5826",
    "CITYNAME_AR": "السويد (قريه الصقور) ومزارعها",
    "CITYNAME_EN": "Alsuwid (Qryh Alsqwr) and Farmsa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5827",
    "CITYNAME_AR": "الروزه وكتيد",
    "CITYNAME_EN": "Alruwzuh Wakatid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5828",
    "CITYNAME_AR": "الغدير",
    "CITYNAME_EN": "Alghadir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5829",
    "CITYNAME_AR": "القاحه",
    "CITYNAME_EN": "Alqahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5830",
    "CITYNAME_AR": "المشوار",
    "CITYNAME_EN": "Almishwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5831",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Alhafiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5832",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "Alsalam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5833",
    "CITYNAME_AR": "الرشاده",
    "CITYNAME_EN": "Alrashaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5834",
    "CITYNAME_AR": "مهيضه",
    "CITYNAME_EN": "Muhidih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5835",
    "CITYNAME_AR": "حي آل سرور",
    "CITYNAME_EN": "Hayi Al Surur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5836",
    "CITYNAME_AR": "الثميد",
    "CITYNAME_EN": "Althamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5837",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Masharifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5838",
    "CITYNAME_AR": "فيضه المسلح",
    "CITYNAME_EN": "Fiduh Almusalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5839",
    "CITYNAME_AR": "جزل",
    "CITYNAME_EN": "Juzl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5840",
    "CITYNAME_AR": "المسلح",
    "CITYNAME_EN": "Almusalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5841",
    "CITYNAME_AR": "الخبيراء",
    "CITYNAME_EN": "Alkhabira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5842",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Alrafayie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5843",
    "CITYNAME_AR": "الشقان",
    "CITYNAME_EN": "Alshuqqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5844",
    "CITYNAME_AR": "الحفاير",
    "CITYNAME_EN": "Alhafayir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5845",
    "CITYNAME_AR": "منازل عون الله",
    "CITYNAME_EN": "Manazil Eawn Allah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5846",
    "CITYNAME_AR": "حفاير سعد",
    "CITYNAME_EN": "Hafayr Saed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5847",
    "CITYNAME_AR": "الكراع",
    "CITYNAME_EN": "Alkirae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5848",
    "CITYNAME_AR": "الرجع",
    "CITYNAME_EN": "Alraje",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5849",
    "CITYNAME_AR": "الصعنون ال فالح",
    "CITYNAME_EN": "Alsaenun Al Falh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5850",
    "CITYNAME_AR": "المرقب",
    "CITYNAME_EN": "Almuraqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5851",
    "CITYNAME_AR": "حريدبان",
    "CITYNAME_EN": "Haridban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5852",
    "CITYNAME_AR": "الحاجر",
    "CITYNAME_EN": "Alhajir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5853",
    "CITYNAME_AR": "المزرع",
    "CITYNAME_EN": "Almazarae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5854",
    "CITYNAME_AR": "دار السلام",
    "CITYNAME_EN": "Dar Alsalam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5855",
    "CITYNAME_AR": "أم نضيل",
    "CITYNAME_EN": "Umm Nadil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5856",
    "CITYNAME_AR": "الحقنة الشرقية",
    "CITYNAME_EN": "Alhuqnat Alsharqia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5857",
    "CITYNAME_AR": "الحقنة الغربية",
    "CITYNAME_EN": "Alhiqnat Algharbia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5858",
    "CITYNAME_AR": "السالم",
    "CITYNAME_EN": "Alssalim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5859",
    "CITYNAME_AR": "الخفيج",
    "CITYNAME_EN": "Alkhafij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5860",
    "CITYNAME_AR": "الحاوي",
    "CITYNAME_EN": "Alhawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5861",
    "CITYNAME_AR": "السنانية",
    "CITYNAME_EN": "Alsnania",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5862",
    "CITYNAME_AR": "العبله",
    "CITYNAME_EN": "Aleablah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5863",
    "CITYNAME_AR": "السالميه",
    "CITYNAME_EN": "Alsaalimih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5864",
    "CITYNAME_AR": "النزهه",
    "CITYNAME_EN": "Alnazhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5865",
    "CITYNAME_AR": "السرار",
    "CITYNAME_EN": "Alsarar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5866",
    "CITYNAME_AR": "العدل",
    "CITYNAME_EN": "Aleadl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5867",
    "CITYNAME_AR": "العوالي",
    "CITYNAME_EN": "Aleawali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5868",
    "CITYNAME_AR": "بركه الخرابه",
    "CITYNAME_EN": "Barkih Alkharabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5869",
    "CITYNAME_AR": "البدائع",
    "CITYNAME_EN": "Albadayie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5870",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Aleaqiq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5871",
    "CITYNAME_AR": "دغبج",
    "CITYNAME_EN": "Daghbij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5872",
    "CITYNAME_AR": "المحاني",
    "CITYNAME_EN": "Al Mehani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "5873",
    "CITYNAME_AR": "الحجف",
    "CITYNAME_EN": "Al-Hajfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5874",
    "CITYNAME_AR": "مزيله ( مزله)",
    "CITYNAME_EN": "Malilah (Mazlah) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5875",
    "CITYNAME_AR": "العطوف (صبح)",
    "CITYNAME_EN": "Al Atuf (Sabh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5876",
    "CITYNAME_AR": "ال جحدل",
    "CITYNAME_EN": "Al Jahdal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5877",
    "CITYNAME_AR": "ال نامس",
    "CITYNAME_EN": "Al Namis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5878",
    "CITYNAME_AR": "السوجب",
    "CITYNAME_EN": "Al Sawjab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5879",
    "CITYNAME_AR": "القزعه ومخشوش",
    "CITYNAME_EN": "Al Qaz'a and Makhshush ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5880",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5881",
    "CITYNAME_AR": "القرى (ال قحاط)",
    "CITYNAME_EN": "Al Qary (Al Qihat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5882",
    "CITYNAME_AR": "رحبان",
    "CITYNAME_EN": "Rahban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5883",
    "CITYNAME_AR": "ال اسحاق وال الدهيس",
    "CITYNAME_EN": "Al Ishaq and Al Duhais ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5884",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5885",
    "CITYNAME_AR": "ذم نيمه",
    "CITYNAME_EN": "Zam Nimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5886",
    "CITYNAME_AR": "ذم تالب",
    "CITYNAME_EN": "Zam Talib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5887",
    "CITYNAME_AR": "ال الشاعر",
    "CITYNAME_EN": "Al lshaer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5888",
    "CITYNAME_AR": "ال كامل",
    "CITYNAME_EN": "Al kamel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5889",
    "CITYNAME_AR": "ال عامر",
    "CITYNAME_EN": "Al Amer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5890",
    "CITYNAME_AR": "المجامحه",
    "CITYNAME_EN": "Al Majamihah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5891",
    "CITYNAME_AR": "ال عزه",
    "CITYNAME_EN": "Al Azzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5892",
    "CITYNAME_AR": "ال جعفله",
    "CITYNAME_EN": "Al Ja'falah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5893",
    "CITYNAME_AR": "ال طليب",
    "CITYNAME_EN": "Al Tolayb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5894",
    "CITYNAME_AR": "ال رشيد",
    "CITYNAME_EN": "Al Rashied ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5895",
    "CITYNAME_AR": "عرعره",
    "CITYNAME_EN": "Ararah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5896",
    "CITYNAME_AR": "الجنب",
    "CITYNAME_EN": "Al-Janb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5897",
    "CITYNAME_AR": "قرانيه",
    "CITYNAME_EN": "Qraniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5898",
    "CITYNAME_AR": "ال الشنيف",
    "CITYNAME_EN": "Al Al-Shanif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5899",
    "CITYNAME_AR": "ال دقاقه",
    "CITYNAME_EN": "Al Dekakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5900",
    "CITYNAME_AR": "ال معلوى",
    "CITYNAME_EN": "Al Ma'lawy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5901",
    "CITYNAME_AR": "الزبيه",
    "CITYNAME_EN": "Al-Zbiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5902",
    "CITYNAME_AR": "القريه وال الزرعى",
    "CITYNAME_EN": "Al-Qaryah, and Al Al-Zar'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5903",
    "CITYNAME_AR": "صولا",
    "CITYNAME_EN": "Sola ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5904",
    "CITYNAME_AR": "العيص",
    "CITYNAME_EN": "Al-Ais",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5905",
    "CITYNAME_AR": "حومان",
    "CITYNAME_EN": "Human ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5906",
    "CITYNAME_AR": "ال مبارك",
    "CITYNAME_EN": "Al Mubarak ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5907",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5908",
    "CITYNAME_AR": "ال صافيه",
    "CITYNAME_EN": "Al Safia ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5909",
    "CITYNAME_AR": "الوان",
    "CITYNAME_EN": "Al-Wan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5910",
    "CITYNAME_AR": "العطف (بشظى)",
    "CITYNAME_EN": "Al-Atf (Bashza)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5911",
    "CITYNAME_AR": "ال مرايع",
    "CITYNAME_EN": "Al Mraie' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5912",
    "CITYNAME_AR": "رزيقه",
    "CITYNAME_EN": "Raziqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5913",
    "CITYNAME_AR": "ذات يومين",
    "CITYNAME_EN": "Zat Yomin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5914",
    "CITYNAME_AR": "الفركسه",
    "CITYNAME_EN": "Al Farkasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5915",
    "CITYNAME_AR": "الاحضان",
    "CITYNAME_EN": "Al Ahdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5916",
    "CITYNAME_AR": "الصره",
    "CITYNAME_EN": "Al Sarrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5917",
    "CITYNAME_AR": "الحفاه (ال زيان)",
    "CITYNAME_EN": "Al Hufah (Al Zayan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5918",
    "CITYNAME_AR": "لزمه",
    "CITYNAME_EN": "Lazmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5919",
    "CITYNAME_AR": "ال حرس",
    "CITYNAME_EN": "Al Hars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5920",
    "CITYNAME_AR": "ال زايد",
    "CITYNAME_EN": "Al Zayid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5921",
    "CITYNAME_AR": "ال عبيد",
    "CITYNAME_EN": "Al Abid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5922",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Al Hazem",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5923",
    "CITYNAME_AR": "النقيل والغرابه",
    "CITYNAME_EN": "Al Naqil and Al Ghrabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5924",
    "CITYNAME_AR": "العقده والضحيه",
    "CITYNAME_EN": "Al Okdah and Al Dahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5925",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Al Farsh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5926",
    "CITYNAME_AR": "ال غلفق",
    "CITYNAME_EN": "Al Ghalfaq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5927",
    "CITYNAME_AR": "مسفره",
    "CITYNAME_EN": "Masfarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5928",
    "CITYNAME_AR": "النمصه",
    "CITYNAME_EN": "Al Namsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5929",
    "CITYNAME_AR": "شعب ظلمه",
    "CITYNAME_EN": "Sha'b Zulmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5930",
    "CITYNAME_AR": "خباب",
    "CITYNAME_EN": "Khbab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5931",
    "CITYNAME_AR": "الركيب",
    "CITYNAME_EN": "Al Rakib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5932",
    "CITYNAME_AR": "فراسه",
    "CITYNAME_EN": "Farasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5933",
    "CITYNAME_AR": "المسله (بيت ابو كرش)",
    "CITYNAME_EN": "Al Maslah (Abu Kersh House)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5934",
    "CITYNAME_AR": "الركيب الاسفل (ابو جلة)",
    "CITYNAME_EN": "Al Rakib the lower (Abu Gillah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5935",
    "CITYNAME_AR": "الخلله والطرقه",
    "CITYNAME_EN": "Al Khalalah and Al Taraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5936",
    "CITYNAME_AR": "الرانوق والكربة الحمراء",
    "CITYNAME_EN": "Al Ranuq and Al Kurbah Al Hamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5937",
    "CITYNAME_AR": "وادى هبهبه",
    "CITYNAME_EN": "Wadi Habhabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5938",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al Zahir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5939",
    "CITYNAME_AR": "عقبه الجعده",
    "CITYNAME_EN": "Aqbah Al-Jad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5940",
    "CITYNAME_AR": "مزيله ويعده",
    "CITYNAME_EN": "Mazilah, and Yad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5941",
    "CITYNAME_AR": "حدب ال عايف",
    "CITYNAME_EN": "Hadb Al Ayif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5942",
    "CITYNAME_AR": "وادى هريان",
    "CITYNAME_EN": "Wadi Herban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5943",
    "CITYNAME_AR": "حدب ال عطيف",
    "CITYNAME_EN": "Hadb Al Atif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5944",
    "CITYNAME_AR": "وادى عنم",
    "CITYNAME_EN": "Wadi Unm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5945",
    "CITYNAME_AR": "الهيله(العرين)",
    "CITYNAME_EN": "Al Hilah (Al Arin)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5946",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5947",
    "CITYNAME_AR": "بئر بن شوكان",
    "CITYNAME_EN": "Bir Ibn Shawkan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5948",
    "CITYNAME_AR": "الاطراق",
    "CITYNAME_EN": "Al Atraq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5949",
    "CITYNAME_AR": "ذى كتين",
    "CITYNAME_EN": "Zi Katin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5950",
    "CITYNAME_AR": "بئر حامد",
    "CITYNAME_EN": "Bir Hamed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5951",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Al Shuhtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5952",
    "CITYNAME_AR": "وقر السفير",
    "CITYNAME_EN": "Waqr Al Safir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5953",
    "CITYNAME_AR": "الحبوه والخشعه",
    "CITYNAME_EN": "Al Habwah and Al Khash'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5954",
    "CITYNAME_AR": "ال سخيطه والغال",
    "CITYNAME_EN": "Al Sekhitah and Al Ghal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5955",
    "CITYNAME_AR": "ال محيط",
    "CITYNAME_EN": "Al Mohit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5956",
    "CITYNAME_AR": "القاريه",
    "CITYNAME_EN": "Al Qarbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5957",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5958",
    "CITYNAME_AR": "محضان واقفا",
    "CITYNAME_EN": "Mehdan and Aqfa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5959",
    "CITYNAME_AR": "مصفى وام ظهره",
    "CITYNAME_EN": "Masfa, and Umm Zagrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5960",
    "CITYNAME_AR": "المنقل ومويه",
    "CITYNAME_EN": "Al-Manqel and Mueh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5961",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Al Shuhtah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5962",
    "CITYNAME_AR": "الوادي الاخضر",
    "CITYNAME_EN": "Al Wadeen",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5963",
    "CITYNAME_AR": "معتق (النماص)",
    "CITYNAME_EN": "Me'tiq (Al-Nammas) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5964",
    "CITYNAME_AR": "وادى البهره",
    "CITYNAME_EN": "Wadi Al-Bahrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5965",
    "CITYNAME_AR": "الفضاء",
    "CITYNAME_EN": "Al-Fadaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5966",
    "CITYNAME_AR": "الضاربين",
    "CITYNAME_EN": "Al-Darbin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5967",
    "CITYNAME_AR": "الكظامه",
    "CITYNAME_EN": "Al-Kezamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5968",
    "CITYNAME_AR": "الحفاة (بال محمد)",
    "CITYNAME_EN": "Al-Hufah (Bal Mohamed)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5969",
    "CITYNAME_AR": "ال تمام",
    "CITYNAME_EN": "Al Tamam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5970",
    "CITYNAME_AR": "مضره",
    "CITYNAME_EN": "Madarrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5971",
    "CITYNAME_AR": "آل عباس",
    "CITYNAME_EN": "Al Abbas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5972",
    "CITYNAME_AR": "آل مخلد",
    "CITYNAME_EN": "Al Mekhlid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5973",
    "CITYNAME_AR": "وادى الظهارة",
    "CITYNAME_EN": "Wadi Al Ziharah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5974",
    "CITYNAME_AR": "جذم",
    "CITYNAME_EN": "Jazm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5975",
    "CITYNAME_AR": "الاثب",
    "CITYNAME_EN": "Al-Athb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5976",
    "CITYNAME_AR": "محازبه",
    "CITYNAME_EN": "Mhazibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5977",
    "CITYNAME_AR": "طاد",
    "CITYNAME_EN": "Tad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5978",
    "CITYNAME_AR": "بهوان",
    "CITYNAME_EN": "Bahwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5979",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5980",
    "CITYNAME_AR": "صروم",
    "CITYNAME_EN": "Sroum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5981",
    "CITYNAME_AR": "بئر بن قيار",
    "CITYNAME_EN": "Bir Ibn Qiyar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5982",
    "CITYNAME_AR": "الحفاه (بالبهشة)",
    "CITYNAME_EN": "Al-Hfah (Balbahshah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5983",
    "CITYNAME_AR": "الاقفيه",
    "CITYNAME_EN": "Al-Aqfiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5984",
    "CITYNAME_AR": "ابوراكه والصفاالحمراء",
    "CITYNAME_EN": "Abu Rakah, and Al-Safa Al-Hamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5985",
    "CITYNAME_AR": "العرفج",
    "CITYNAME_EN": "Al-Arfaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5986",
    "CITYNAME_AR": "الحبتانيه والطلحه",
    "CITYNAME_EN": "Al-Habtaniyah, and Al-Talhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5987",
    "CITYNAME_AR": "ال مدق",
    "CITYNAME_EN": "Al Mdq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5988",
    "CITYNAME_AR": "المعلاه",
    "CITYNAME_EN": "Al Mʻlāh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5989",
    "CITYNAME_AR": "ال رافع",
    "CITYNAME_EN": "Al Rāfʻ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5990",
    "CITYNAME_AR": "شعب عزيز",
    "CITYNAME_EN": "Shʻb ʻZyz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5991",
    "CITYNAME_AR": "الزبره",
    "CITYNAME_EN": "Az Zbrh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5992",
    "CITYNAME_AR": "ريع المقطر",
    "CITYNAME_EN": "Ryʻ Al Mqṯr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5993",
    "CITYNAME_AR": "عباله",
    "CITYNAME_EN": "ʻBālh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5994",
    "CITYNAME_AR": "بوربط",
    "CITYNAME_EN": "Bwrbṯ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5995",
    "CITYNAME_AR": "الفاجه",
    "CITYNAME_EN": "Al Fājh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5996",
    "CITYNAME_AR": "زاعب",
    "CITYNAME_EN": "Zāʻb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5997",
    "CITYNAME_AR": "السليمات",
    "CITYNAME_EN": "As Slymāt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5998",
    "CITYNAME_AR": "مخطط الظهاره",
    "CITYNAME_EN": "Mkhṯṯ Ad͟h d͟hhārh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "5999",
    "CITYNAME_AR": "المبرر",
    "CITYNAME_EN": "Al Mbrr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6000",
    "CITYNAME_AR": "شعب حراش",
    "CITYNAME_EN": "Shʻb H̱rāsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6001",
    "CITYNAME_AR": "فلاح",
    "CITYNAME_EN": "Flāẖ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6002",
    "CITYNAME_AR": "الخفج",
    "CITYNAME_EN": "Al Khfj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6003",
    "CITYNAME_AR": "الكدسه",
    "CITYNAME_EN": "Al Kdsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6004",
    "CITYNAME_AR": "المربد",
    "CITYNAME_EN": "Al Mrbd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6005",
    "CITYNAME_AR": "العطيفة بخضار",
    "CITYNAME_EN": "Al ʻṮyfh Bkhḏār",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6006",
    "CITYNAME_AR": "العجمه",
    "CITYNAME_EN": "Al ʻJmh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6007",
    "CITYNAME_AR": "شعب الفهد",
    "CITYNAME_EN": "Shʻb Al Fhd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6008",
    "CITYNAME_AR": "سوق حشحش",
    "CITYNAME_EN": "Swq H̱shẖsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6009",
    "CITYNAME_AR": "الجاور",
    "CITYNAME_EN": "Al Jāwr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6010",
    "CITYNAME_AR": "ال مجس",
    "CITYNAME_EN": "Al Mjs",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6011",
    "CITYNAME_AR": "صبح بلحمر",
    "CITYNAME_EN": "Sabah Billahmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6012",
    "CITYNAME_AR": "وادى ذهب",
    "CITYNAME_EN": "Wadi Zahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6013",
    "CITYNAME_AR": "الواديين",
    "CITYNAME_EN": "Al-Wadyin Station ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6014",
    "CITYNAME_AR": "ال عامر",
    "CITYNAME_EN": "Al Amer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6015",
    "CITYNAME_AR": "ال بشر",
    "CITYNAME_EN": "Al Beshr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6016",
    "CITYNAME_AR": "ال سرحان (الفراعه)",
    "CITYNAME_EN": "Al Sarhan (Al-Fara'ah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6017",
    "CITYNAME_AR": "ال بحاث",
    "CITYNAME_EN": "Al Behath ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6018",
    "CITYNAME_AR": "ال بايع",
    "CITYNAME_EN": "Al Bayi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6019",
    "CITYNAME_AR": "السر ال الشاعر",
    "CITYNAME_EN": "Al-Ser  Al-Sha'er",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6020",
    "CITYNAME_AR": "ال حمران (المثناه)",
    "CITYNAME_EN": "Al-Hemran (Al-Mathnah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6021",
    "CITYNAME_AR": "الوسط (بني وهب)",
    "CITYNAME_EN": "Al-Wasat (Bni Wahb)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6022",
    "CITYNAME_AR": "الصمخيه",
    "CITYNAME_EN": "Al-Samikhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6023",
    "CITYNAME_AR": "المناقع",
    "CITYNAME_EN": "Al-Manaqi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6024",
    "CITYNAME_AR": "ال مرير",
    "CITYNAME_EN": "Al Marir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6025",
    "CITYNAME_AR": "المخلوطه",
    "CITYNAME_EN": "Al-Makhlutah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6026",
    "CITYNAME_AR": "الجازع",
    "CITYNAME_EN": "Al-Jazi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6027",
    "CITYNAME_AR": "المعازيب",
    "CITYNAME_EN": "Al-ma'azib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6028",
    "CITYNAME_AR": "ال لوط (القعمه)",
    "CITYNAME_EN": "Al-Lut (Al-Qa'mah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6029",
    "CITYNAME_AR": "المربع ال لوط",
    "CITYNAME_EN": "Al-Morab' Al lut ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6030",
    "CITYNAME_AR": "العقاله",
    "CITYNAME_EN": "Al Aqalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6031",
    "CITYNAME_AR": "قريه الملك فيصل الخيريه",
    "CITYNAME_EN": "King Faysal Charity Town",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6032",
    "CITYNAME_AR": "اللحي",
    "CITYNAME_EN": "Allahy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6033",
    "CITYNAME_AR": "الداخول",
    "CITYNAME_EN": "Al-Dakhul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6034",
    "CITYNAME_AR": "ال البطحاء",
    "CITYNAME_EN": "Al Al-Bathaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6035",
    "CITYNAME_AR": "ال زقوم",
    "CITYNAME_EN": "Al Zaqum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6036",
    "CITYNAME_AR": "الـحديله",
    "CITYNAME_EN": "Al Hadilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6037",
    "CITYNAME_AR": "ال حلامي",
    "CITYNAME_EN": "Al Halamy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6038",
    "CITYNAME_AR": "الميين",
    "CITYNAME_EN": "Al-Mabin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6039",
    "CITYNAME_AR": "صفحان",
    "CITYNAME_EN": "Safhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6040",
    "CITYNAME_AR": "ال الدمام",
    "CITYNAME_EN": "Al Al-Dammam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6041",
    "CITYNAME_AR": "ال ناجح",
    "CITYNAME_EN": "Al Najeh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6042",
    "CITYNAME_AR": "ال بنحي",
    "CITYNAME_EN": "Al Banhy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6043",
    "CITYNAME_AR": "ال عزب",
    "CITYNAME_EN": "Al Azb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6044",
    "CITYNAME_AR": "المجره (وادي خاران)",
    "CITYNAME_EN": "Al Majrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6045",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "As Safraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6046",
    "CITYNAME_AR": "الفزر ال الجحل",
    "CITYNAME_EN": "Al-Farz Al Al-Hejl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6047",
    "CITYNAME_AR": "وجيهه ال الجحل",
    "CITYNAME_EN": "Wajiha Al Al-Hejl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6048",
    "CITYNAME_AR": "المسحقه",
    "CITYNAME_EN": "Al-Mashaqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6049",
    "CITYNAME_AR": "الفقا عيس ال جحل اسفل عتود",
    "CITYNAME_EN": "Al-Faqa Eis Al Hejl Asfal Atud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6050",
    "CITYNAME_AR": "المجمع",
    "CITYNAME_EN": "Al Majma'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6051",
    "CITYNAME_AR": "ذيبه ال الجحل",
    "CITYNAME_EN": "Zeibah Al Al-Jehl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6052",
    "CITYNAME_AR": "آل فارح",
    "CITYNAME_EN": "Al Farih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6053",
    "CITYNAME_AR": "احد رفيده",
    "CITYNAME_EN": "Ahad Rafidah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6054",
    "CITYNAME_AR": "القرحاء",
    "CITYNAME_EN": "Al-Qarhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6055",
    "CITYNAME_AR": "عنقره",
    "CITYNAME_EN": "Anqarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6056",
    "CITYNAME_AR": "الجمعة",
    "CITYNAME_EN": "Al-Jom'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6057",
    "CITYNAME_AR": "آل راقع",
    "CITYNAME_EN": "Al Raqi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6058",
    "CITYNAME_AR": "آل حمرير",
    "CITYNAME_EN": "Al-Hamrir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6059",
    "CITYNAME_AR": "ال لغر",
    "CITYNAME_EN": "Al leghr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6060",
    "CITYNAME_AR": "ال سليمان (ال مرضي)",
    "CITYNAME_EN": "Al Sulayman (Al Merda)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6061",
    "CITYNAME_AR": "ال شويه",
    "CITYNAME_EN": "Al Shubah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6062",
    "CITYNAME_AR": "ال نادر",
    "CITYNAME_EN": "Al Nader ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6063",
    "CITYNAME_AR": "ال سهيل",
    "CITYNAME_EN": "Al Suhail",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6064",
    "CITYNAME_AR": "صلبان الحنش",
    "CITYNAME_EN": "Al Sahil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6065",
    "CITYNAME_AR": "ال عرينه",
    "CITYNAME_EN": "Al Arinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6066",
    "CITYNAME_AR": "ال رميح",
    "CITYNAME_EN": "Al Ramih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6067",
    "CITYNAME_AR": "ال عمره",
    "CITYNAME_EN": "Al Omrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6068",
    "CITYNAME_AR": "ال السواد",
    "CITYNAME_EN": "Al Al-Sawad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6069",
    "CITYNAME_AR": "المسمع",
    "CITYNAME_EN": "Al-Masma'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6070",
    "CITYNAME_AR": "ال العضباء",
    "CITYNAME_EN": "Al Al-Adbaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6071",
    "CITYNAME_AR": "ال الداحس",
    "CITYNAME_EN": "Al Al-Dahis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6072",
    "CITYNAME_AR": "ال سعيا",
    "CITYNAME_EN": "Al Sa'ya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6073",
    "CITYNAME_AR": "ال مكر",
    "CITYNAME_EN": "Al Mekr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6074",
    "CITYNAME_AR": "ضور الجراعه",
    "CITYNAME_EN": "Dur Al-Jara'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6075",
    "CITYNAME_AR": "ال ناطش (ال هتلان)",
    "CITYNAME_EN": "Al natish (Al Hetlan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6076",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Al-Dars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6077",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6078",
    "CITYNAME_AR": "ال مشهور",
    "CITYNAME_EN": "Al Maghhur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6079",
    "CITYNAME_AR": "صرايم وال عجيبه",
    "CITYNAME_EN": "Sarayim and Al Ajibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6080",
    "CITYNAME_AR": "ال عدرج الجرو",
    "CITYNAME_EN": "Al Adraj Al-Jarw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6081",
    "CITYNAME_AR": "نجدالهجله",
    "CITYNAME_EN": "Najd Al-Hajlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6082",
    "CITYNAME_AR": "الخنق",
    "CITYNAME_EN": "Al Khanq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6083",
    "CITYNAME_AR": "الحبقه",
    "CITYNAME_EN": "Al-Habqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6084",
    "CITYNAME_AR": "ضور الطحين",
    "CITYNAME_EN": "Dur Al-Tahin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6085",
    "CITYNAME_AR": "ال غيلان",
    "CITYNAME_EN": "Al Ghilan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6086",
    "CITYNAME_AR": "سر ذياب",
    "CITYNAME_EN": "Ser Zyab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6087",
    "CITYNAME_AR": "النمصه",
    "CITYNAME_EN": "Al-Namsah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6088",
    "CITYNAME_AR": "ال دلهم",
    "CITYNAME_EN": "Al Delhim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6089",
    "CITYNAME_AR": "وادى زيد",
    "CITYNAME_EN": "Wadi Zayd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6090",
    "CITYNAME_AR": "ال سلمه",
    "CITYNAME_EN": "Al Salamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6091",
    "CITYNAME_AR": "ال الطويل",
    "CITYNAME_EN": "Al Al-Tawil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6092",
    "CITYNAME_AR": "الهضبه",
    "CITYNAME_EN": "Al-Hadbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6093",
    "CITYNAME_AR": "ال هيصم",
    "CITYNAME_EN": "Al Hasim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6094",
    "CITYNAME_AR": "ال شبيرين",
    "CITYNAME_EN": "Al Shebrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6095",
    "CITYNAME_AR": "عراب وباديتها",
    "CITYNAME_EN": "Erab and its Badia ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6096",
    "CITYNAME_AR": "ال زهير",
    "CITYNAME_EN": "Al Zoheir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6097",
    "CITYNAME_AR": "ال الشيخ",
    "CITYNAME_EN": "Al Al-Sheikh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6098",
    "CITYNAME_AR": "ال عباس",
    "CITYNAME_EN": "Al Abbas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6099",
    "CITYNAME_AR": "فرسان",
    "CITYNAME_EN": "Farsan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6100",
    "CITYNAME_AR": "المسيله",
    "CITYNAME_EN": "Almasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6101",
    "CITYNAME_AR": "المشراف",
    "CITYNAME_EN": "Almishraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6102",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Almuharaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6103",
    "CITYNAME_AR": "الحسين",
    "CITYNAME_EN": "Alhusayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6104",
    "CITYNAME_AR": "صير",
    "CITYNAME_EN": "Sir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6105",
    "CITYNAME_AR": "السقيد",
    "CITYNAME_EN": "Alsaqid Walmahsur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6106",
    "CITYNAME_AR": "ابو طوق",
    "CITYNAME_EN": "'Abu Tuq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6107",
    "CITYNAME_AR": "الدومات",
    "CITYNAME_EN": "Aldawamat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6108",
    "CITYNAME_AR": "ختب",
    "CITYNAME_EN": "Khatib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6109",
    "CITYNAME_AR": "خوله",
    "CITYNAME_EN": "Khwlh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6110",
    "CITYNAME_AR": "جزيره قماح",
    "CITYNAME_EN": "Jaziruh Qimah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6111",
    "CITYNAME_AR": "القصار",
    "CITYNAME_EN": "Alqasar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6112",
    "CITYNAME_AR": "حصيص",
    "CITYNAME_EN": "Hasis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6113",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Alsaalihih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6114",
    "CITYNAME_AR": "عنيزه",
    "CITYNAME_EN": "Eanizah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6115",
    "CITYNAME_AR": "الروغانى",
    "CITYNAME_EN": "Alruwghanaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6116",
    "CITYNAME_AR": "وادي الجناح",
    "CITYNAME_EN": "Wadi Aljanah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6117",
    "CITYNAME_AR": "وادي ابوعلي",
    "CITYNAME_EN": "Wadi Abwaelii",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6118",
    "CITYNAME_AR": "العوشزيه",
    "CITYNAME_EN": "Aleushaziuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6119",
    "CITYNAME_AR": "الرفيعه ",
    "CITYNAME_EN": "Alrafieuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6120",
    "CITYNAME_AR": "العماش",
    "CITYNAME_EN": "Aleimash",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6121",
    "CITYNAME_AR": "القطيف",
    "CITYNAME_EN": "Qatif",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "6145",
    "CITYNAME_AR": "البدائع",
    "CITYNAME_EN": "Albadayie",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6146",
    "CITYNAME_AR": "الدحلة ومزارعها",
    "CITYNAME_EN": "Aldihlat and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6147",
    "CITYNAME_AR": "الأبرق",
    "CITYNAME_EN": "Al'Abraq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6148",
    "CITYNAME_AR": "دهيماء القديمه",
    "CITYNAME_EN": "Dahima' Alqdymih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6149",
    "CITYNAME_AR": "السمار",
    "CITYNAME_EN": "Alsamar",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6150",
    "CITYNAME_AR": "علباء",
    "CITYNAME_EN": "Ealba'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6151",
    "CITYNAME_AR": "الاحمدية",
    "CITYNAME_EN": "Alahmdy",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6152",
    "CITYNAME_AR": "المحصله",
    "CITYNAME_EN": "Almuhsaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6153",
    "CITYNAME_AR": "وقيع",
    "CITYNAME_EN": "Waqie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6154",
    "CITYNAME_AR": "وادى الملح",
    "CITYNAME_EN": "Wa'Adaa Almulihu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6155",
    "CITYNAME_AR": "امصليله",
    "CITYNAME_EN": "Amsililh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6156",
    "CITYNAME_AR": "وادي لعل",
    "CITYNAME_EN": "Wadi Lel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6157",
    "CITYNAME_AR": "الحمدة",
    "CITYNAME_EN": "Alhamda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6158",
    "CITYNAME_AR": "وادى حباب",
    "CITYNAME_EN": "Wa'Adaa Hibab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6159",
    "CITYNAME_AR": "بوعان العين الحاره",
    "CITYNAME_EN": "Buean Aleayn Alharih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6160",
    "CITYNAME_AR": "المكسر",
    "CITYNAME_EN": "Almukasar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6161",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Alrayighuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6162",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Alfasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6163",
    "CITYNAME_AR": "قنبى",
    "CITYNAME_EN": "Qunbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6164",
    "CITYNAME_AR": "اسده",
    "CITYNAME_EN": "Asdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6165",
    "CITYNAME_AR": "الجرفه",
    "CITYNAME_EN": "Al-Jarfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6166",
    "CITYNAME_AR": "ابها",
    "CITYNAME_EN": "Abha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6167",
    "CITYNAME_AR": "العكاس",
    "CITYNAME_EN": "Al Ekas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6168",
    "CITYNAME_AR": "المساره",
    "CITYNAME_EN": "Al Masarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6169",
    "CITYNAME_AR": "البادى",
    "CITYNAME_EN": "Al Bady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6170",
    "CITYNAME_AR": "السحراء",
    "CITYNAME_EN": "Al Sahraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6171",
    "CITYNAME_AR": "وادى البيح وآل طرفه",
    "CITYNAME_EN": "Wadi Al Bih and Al Tarfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6172",
    "CITYNAME_AR": "المغمر",
    "CITYNAME_EN": "Al Maghmar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6173",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Mazmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6174",
    "CITYNAME_AR": "عين بن مصافح",
    "CITYNAME_EN": "Ain bin Musafih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6175",
    "CITYNAME_AR": "الشطاط",
    "CITYNAME_EN": "Al Shatat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6176",
    "CITYNAME_AR": "ثوم",
    "CITYNAME_EN": "Thum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6177",
    "CITYNAME_AR": "الوسط",
    "CITYNAME_EN": "Al Wasat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6178",
    "CITYNAME_AR": "العطف وحنابه",
    "CITYNAME_EN": "Al Atf and Hanabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6179",
    "CITYNAME_AR": "الحظيره",
    "CITYNAME_EN": "Al Hazirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6180",
    "CITYNAME_AR": "آل ثروان",
    "CITYNAME_EN": "Al Tharwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6181",
    "CITYNAME_AR": "آل شريح والذروه",
    "CITYNAME_EN": "Al Shurayh and Al Zurwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6182",
    "CITYNAME_AR": "حضن الخميس",
    "CITYNAME_EN": "Hudn Al Khamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6183",
    "CITYNAME_AR": "المصنعه والذروه",
    "CITYNAME_EN": "Al masnaah and Al Zurwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6184",
    "CITYNAME_AR": "ناجح",
    "CITYNAME_EN": "Najih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6185",
    "CITYNAME_AR": "آل مقارم",
    "CITYNAME_EN": "Al Muqarim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6186",
    "CITYNAME_AR": "آل القحيم",
    "CITYNAME_EN": "Al Qahim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6187",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6188",
    "CITYNAME_AR": "ذامسنون",
    "CITYNAME_EN": "Zamsnun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6189",
    "CITYNAME_AR": "الميراد والمقرفه",
    "CITYNAME_EN": "Al Mirad and Al Muqrifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6190",
    "CITYNAME_AR": "النجيل (ال نجيم)",
    "CITYNAME_EN": "Al Najil (Al Najim) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6191",
    "CITYNAME_AR": "الحصون والمفارش",
    "CITYNAME_EN": "Al Hasun and Al Mafarish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6192",
    "CITYNAME_AR": "القرن وآل عميري وحصن الضحيه",
    "CITYNAME_EN": "Al Qarn, Al Umairy, and Hisn Al dahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6193",
    "CITYNAME_AR": "خيمه",
    "CITYNAME_EN": "Khaimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6194",
    "CITYNAME_AR": "الرتق",
    "CITYNAME_EN": "Al Ratq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6195",
    "CITYNAME_AR": "البحصه وذ المرو",
    "CITYNAME_EN": "Al Bahsah and al Marw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6196",
    "CITYNAME_AR": "عتمه",
    "CITYNAME_EN": "Atmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6197",
    "CITYNAME_AR": "قرضه وصبري",
    "CITYNAME_EN": "Qardah and Sabry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6198",
    "CITYNAME_AR": "المغيديين",
    "CITYNAME_EN": "Al Mughaidien ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6199",
    "CITYNAME_AR": "العفاير",
    "CITYNAME_EN": "Al Afayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6200",
    "CITYNAME_AR": "سر آل سرحي",
    "CITYNAME_EN": "Ser Al Sirhy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6201",
    "CITYNAME_AR": "الشبارقة",
    "CITYNAME_EN": "Al Shabariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6202",
    "CITYNAME_AR": "ال مجثل",
    "CITYNAME_EN": "Al Mithjel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6203",
    "CITYNAME_AR": "سر الشبارقه",
    "CITYNAME_EN": "Ser Al Shabariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6204",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6205",
    "CITYNAME_AR": "آل العلاء ولجوه وسر البدله",
    "CITYNAME_EN": "Al Alaa, Legwah, and Ser Al Badlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6206",
    "CITYNAME_AR": "ال يزيديين",
    "CITYNAME_EN": "Al Yazidien ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6207",
    "CITYNAME_AR": "المخض",
    "CITYNAME_EN": "Al Makhd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6208",
    "CITYNAME_AR": "الكوثرين",
    "CITYNAME_EN": "Al Kawthrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6209",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Al Malhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6210",
    "CITYNAME_AR": "الملصة",
    "CITYNAME_EN": "Al Malsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6211",
    "CITYNAME_AR": "العقاله",
    "CITYNAME_EN": "Al Aqalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6212",
    "CITYNAME_AR": "برحو",
    "CITYNAME_EN": "Barhu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6213",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6214",
    "CITYNAME_AR": "لولاه",
    "CITYNAME_EN": "Lolah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6215",
    "CITYNAME_AR": "عثارب",
    "CITYNAME_EN": "Atharib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6216",
    "CITYNAME_AR": "المعادي",
    "CITYNAME_EN": "Al Maadi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6217",
    "CITYNAME_AR": "العرق (طرف العرق)",
    "CITYNAME_EN": "Al Arqab (Tarf Al Irq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6218",
    "CITYNAME_AR": "الأقواز",
    "CITYNAME_EN": "Al Aqwaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6219",
    "CITYNAME_AR": "الباطنه",
    "CITYNAME_EN": "Al Batinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6220",
    "CITYNAME_AR": "آل أم سعيد",
    "CITYNAME_EN": "Al Umm Saed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6221",
    "CITYNAME_AR": "الوهط",
    "CITYNAME_EN": "Al Waht",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6222",
    "CITYNAME_AR": "عدوان",
    "CITYNAME_EN": "Adwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6223",
    "CITYNAME_AR": "عين بن يعلا",
    "CITYNAME_EN": "Ain bin Ya'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6224",
    "CITYNAME_AR": "سر الفتيحا",
    "CITYNAME_EN": "Ser Al fatiha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6225",
    "CITYNAME_AR": "سر بن رزام",
    "CITYNAME_EN": "Ser bin Rezam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6226",
    "CITYNAME_AR": "القريات والقهوه والمحطه",
    "CITYNAME_EN": "Al Qaryat, the Cafe, and the Station ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6227",
    "CITYNAME_AR": "الجندى وال مخلد",
    "CITYNAME_EN": "Al Gendy and Al Makhlad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6228",
    "CITYNAME_AR": "ال بالكباش",
    "CITYNAME_EN": "Al Balkbash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6229",
    "CITYNAME_AR": "المجارده",
    "CITYNAME_EN": "Almjardh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6230",
    "CITYNAME_AR": "المصرمه",
    "CITYNAME_EN": "Al Masrimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6231",
    "CITYNAME_AR": "ذماله",
    "CITYNAME_EN": "Zumalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6232",
    "CITYNAME_AR": "صياد الملاحه والقريه",
    "CITYNAME_EN": "Sayad Al Milahah and the Villlage  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6233",
    "CITYNAME_AR": "ال الصليحى",
    "CITYNAME_EN": "Al Al-Selihy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6234",
    "CITYNAME_AR": "ال الزكي",
    "CITYNAME_EN": "Al Al-Zaki ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6235",
    "CITYNAME_AR": "ال قايم",
    "CITYNAME_EN": "Al Qayim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6236",
    "CITYNAME_AR": "العطفات",
    "CITYNAME_EN": "Al Attifat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6237",
    "CITYNAME_AR": "ال عادى",
    "CITYNAME_EN": "Al Ady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6238",
    "CITYNAME_AR": "ال ام رعينى",
    "CITYNAME_EN": "Al Umm Ra'iny ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6239",
    "CITYNAME_AR": "صعرور",
    "CITYNAME_EN": "Sa'rur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6240",
    "CITYNAME_AR": "الفيه ومسلت",
    "CITYNAME_EN": "Alfiuah and Moslet ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6241",
    "CITYNAME_AR": "قشله شعار",
    "CITYNAME_EN": "Qahlah Shi'ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6242",
    "CITYNAME_AR": "مركز شعار (نقطه تفتيش)",
    "CITYNAME_EN": "Shi'ar Center (Checkpoint)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6243",
    "CITYNAME_AR": "المجزعه والشعب",
    "CITYNAME_EN": "Al Majz'a and Al Shaab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6244",
    "CITYNAME_AR": "ال جاهل ( النور)",
    "CITYNAME_EN": "Al Jahel (Al Nour)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6245",
    "CITYNAME_AR": "الطحل",
    "CITYNAME_EN": "Al Tahl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6246",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sa'ban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6247",
    "CITYNAME_AR": "ال الشلفاء",
    "CITYNAME_EN": "Al Alshalfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6248",
    "CITYNAME_AR": "ال الاصبح",
    "CITYNAME_EN": "Al Alasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6249",
    "CITYNAME_AR": "ال هتان",
    "CITYNAME_EN": "Al Hatan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6250",
    "CITYNAME_AR": "الحصير والقعوه",
    "CITYNAME_EN": "Al Hasir and Al Qa'wah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6251",
    "CITYNAME_AR": "المثناه والسوق",
    "CITYNAME_EN": "Al Mathnah and Al Suq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6252",
    "CITYNAME_AR": "العين ومنادر العين",
    "CITYNAME_EN": "Al Ain and Manader Al Ain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6253",
    "CITYNAME_AR": "الاجرف",
    "CITYNAME_EN": "Al Ajraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6254",
    "CITYNAME_AR": "المسقطه",
    "CITYNAME_EN": "Al Masqatah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6255",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al Attifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6256",
    "CITYNAME_AR": "العرفج",
    "CITYNAME_EN": "Al Arfaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6257",
    "CITYNAME_AR": "الموسطه والنبعه",
    "CITYNAME_EN": "Al Mostah and Al Nab'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6258",
    "CITYNAME_AR": "الخنق",
    "CITYNAME_EN": "Al Khanq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6259",
    "CITYNAME_AR": "الخارجه",
    "CITYNAME_EN": "Al Kharijah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6260",
    "CITYNAME_AR": "ال بوشحطه",
    "CITYNAME_EN": "Al Boshahtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6261",
    "CITYNAME_AR": "العرق وشعب الجمل",
    "CITYNAME_EN": "Al Irq and Shi'b Al Jamal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6262",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6263",
    "CITYNAME_AR": "قرين",
    "CITYNAME_EN": "Qarin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6264",
    "CITYNAME_AR": "ال جمعه",
    "CITYNAME_EN": "Al Gom'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6265",
    "CITYNAME_AR": "ال سهيل",
    "CITYNAME_EN": "Al Suhail ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6266",
    "CITYNAME_AR": "ال معدى",
    "CITYNAME_EN": "Al Ma'dy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6267",
    "CITYNAME_AR": "ال قحزل",
    "CITYNAME_EN": "Al Qahzl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6268",
    "CITYNAME_AR": "ال عراد",
    "CITYNAME_EN": "Al Erad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6269",
    "CITYNAME_AR": "المضيق",
    "CITYNAME_EN": "Al Madeeq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6270",
    "CITYNAME_AR": "الفارعه",
    "CITYNAME_EN": "Al Fara'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6271",
    "CITYNAME_AR": "المرفق",
    "CITYNAME_EN": "Al Mirfaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6272",
    "CITYNAME_AR": "المسان",
    "CITYNAME_EN": "Al Masan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6273",
    "CITYNAME_AR": "الدبدبه",
    "CITYNAME_EN": "Al Dabdabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6274",
    "CITYNAME_AR": "الكحلة",
    "CITYNAME_EN": "Al Kahlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6275",
    "CITYNAME_AR": "مسوح",
    "CITYNAME_EN": "Masouh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6276",
    "CITYNAME_AR": "الكحله",
    "CITYNAME_EN": "Al Kahlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6277",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Al Duha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6278",
    "CITYNAME_AR": "السلايل",
    "CITYNAME_EN": "Al Salayel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6279",
    "CITYNAME_AR": "الهرار",
    "CITYNAME_EN": "Al Harar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6280",
    "CITYNAME_AR": "السوده والضحيه",
    "CITYNAME_EN": "Al Sudah and Al Dahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6281",
    "CITYNAME_AR": "شرف العوص",
    "CITYNAME_EN": "Sharaf Al Aws ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6282",
    "CITYNAME_AR": "المشرق وحربه والمعجز",
    "CITYNAME_EN": "Al Mashraq, Harbah, and Al Ma'jaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6283",
    "CITYNAME_AR": "عين الجوفاء",
    "CITYNAME_EN": "Ain Al Jawfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6284",
    "CITYNAME_AR": "عين الذيبه",
    "CITYNAME_EN": "Ain Al Zibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6285",
    "CITYNAME_AR": "محصان والداخله",
    "CITYNAME_EN": "Mehsan and Al Dakhlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6286",
    "CITYNAME_AR": "غاوه وحصن الجرين",
    "CITYNAME_EN": "Hgawah and Hisn Al Garien ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6287",
    "CITYNAME_AR": "بساط غاوه",
    "CITYNAME_EN": "Bisat Ghawah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6288",
    "CITYNAME_AR": "راس السر والجامه",
    "CITYNAME_EN": "Ras Al Ser and al Jamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6289",
    "CITYNAME_AR": "السوق وبارك الجهاريه",
    "CITYNAME_EN": "Al Souq and Bark Al Jahariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6290",
    "CITYNAME_AR": "الروح والصعيد",
    "CITYNAME_EN": "Al Rouh and Al Sa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6291",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6292",
    "CITYNAME_AR": "مقهب السقا",
    "CITYNAME_EN": "Maqhab Al Saqa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6293",
    "CITYNAME_AR": "السقا",
    "CITYNAME_EN": "Al Saqa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6294",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al Waljah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6295",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Far'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6296",
    "CITYNAME_AR": "المسراب والفرع",
    "CITYNAME_EN": "Al Misrab and Al Far'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6297",
    "CITYNAME_AR": "قعوه السقا",
    "CITYNAME_EN": "Qa'qah Al Saqa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6298",
    "CITYNAME_AR": "شط الخضراء",
    "CITYNAME_EN": "Shat Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6299",
    "CITYNAME_AR": "الحبوه",
    "CITYNAME_EN": "Al Habwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6300",
    "CITYNAME_AR": "المقضى وآل اسماعيل",
    "CITYNAME_EN": "Al Maqdi and Al Ismael ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6301",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6302",
    "CITYNAME_AR": "قريه شيخه السياحيه",
    "CITYNAME_EN": "Sheihka Tourist Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6303",
    "CITYNAME_AR": "سرالبدله والحبوه",
    "CITYNAME_EN": "Ser Albadlah and Al Habwah  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6304",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6305",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6306",
    "CITYNAME_AR": "الشط الاعلى والاسفل",
    "CITYNAME_EN": "Shat  the upper and the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6307",
    "CITYNAME_AR": "النجاد والبارك",
    "CITYNAME_EN": "Al Nijad and Al Bark ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6308",
    "CITYNAME_AR": "ال بواح",
    "CITYNAME_EN": "Al Bawah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6309",
    "CITYNAME_AR": "ال مرزن",
    "CITYNAME_EN": "Al Merzin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6310",
    "CITYNAME_AR": "الحوزه والخارجه",
    "CITYNAME_EN": "Al Huzah and Al Kharjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6311",
    "CITYNAME_AR": "الضحيه (العزيزه)",
    "CITYNAME_EN": "Al Dahiyah (Al Azizah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6312",
    "CITYNAME_AR": "المقافى",
    "CITYNAME_EN": "Al Maqafy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6313",
    "CITYNAME_AR": "القده ( ال تمام)",
    "CITYNAME_EN": "Al Qidah (Al Tamam)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6314",
    "CITYNAME_AR": "مجدعان",
    "CITYNAME_EN": "Megd'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6315",
    "CITYNAME_AR": "قده ال امشيبه",
    "CITYNAME_EN": "Qidah Al Imshibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6316",
    "CITYNAME_AR": "قده ال بو فايده",
    "CITYNAME_EN": "Qidah Al bu Faidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6317",
    "CITYNAME_AR": "ال مفرح",
    "CITYNAME_EN": "Al Mefrih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6318",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "Alsamdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6319",
    "CITYNAME_AR": "ال سكران",
    "CITYNAME_EN": "Al Sakran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6320",
    "CITYNAME_AR": "راس الفيه",
    "CITYNAME_EN": "Ras Alfih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6321",
    "CITYNAME_AR": "ذنب السر",
    "CITYNAME_EN": "Zanb Al Ser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6322",
    "CITYNAME_AR": "ال يتيم",
    "CITYNAME_EN": "Al Yatim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6323",
    "CITYNAME_AR": "ذنب القائمه",
    "CITYNAME_EN": "Zanb Al Qaemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6324",
    "CITYNAME_AR": "راعيه البيبان",
    "CITYNAME_EN": "Ra'iyah Al Biban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6325",
    "CITYNAME_AR": "المجاز",
    "CITYNAME_EN": "Al Majaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6326",
    "CITYNAME_AR": "رهمه المهلل",
    "CITYNAME_EN": "Rahmah Al Mohalal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6327",
    "CITYNAME_AR": "النغره والمرازه",
    "CITYNAME_EN": "Al Naghrah and Al Merazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6328",
    "CITYNAME_AR": "البوخه",
    "CITYNAME_EN": "Al Bukhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6329",
    "CITYNAME_AR": "قعوه ال فرحان والعثراء",
    "CITYNAME_EN": "Qa'wah Al farhan, and Al athraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6330",
    "CITYNAME_AR": "سر آل عبيد والسقايف",
    "CITYNAME_EN": "Ser Al Abid, and Al Saqayif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6331",
    "CITYNAME_AR": "الشارقه",
    "CITYNAME_EN": "Al Shariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6332",
    "CITYNAME_AR": "آل محرق",
    "CITYNAME_EN": "Al Mehriq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6333",
    "CITYNAME_AR": "جال ال عامر",
    "CITYNAME_EN": "Jal Al Amer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6334",
    "CITYNAME_AR": "ال امحاج",
    "CITYNAME_EN": "Al Imhaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6335",
    "CITYNAME_AR": "ال القبيعى",
    "CITYNAME_EN": "Al Alqabi'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6336",
    "CITYNAME_AR": "حقبه ريده",
    "CITYNAME_EN": "Hiqbah Ribdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6337",
    "CITYNAME_AR": "جديان",
    "CITYNAME_EN": "Gadyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6338",
    "CITYNAME_AR": "غيثان",
    "CITYNAME_EN": "Ghithan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6339",
    "CITYNAME_AR": "وادى الظهار",
    "CITYNAME_EN": "Wadi Al Zihar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6340",
    "CITYNAME_AR": "ريده",
    "CITYNAME_EN": "Ribdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6341",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al Ghamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6342",
    "CITYNAME_AR": "مسلمه وشط خبيب",
    "CITYNAME_EN": "Maslamah and Shat Khabib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6343",
    "CITYNAME_AR": "حبيل ال يسعد",
    "CITYNAME_EN": "Habil Al Yas'ad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6344",
    "CITYNAME_AR": "عرفه ام رقو",
    "CITYNAME_EN": "Arfah Umm Raqw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6345",
    "CITYNAME_AR": "الظهره والمهيا",
    "CITYNAME_EN": "Al Zuhrah and Al Mahya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6346",
    "CITYNAME_AR": "الفطيحه",
    "CITYNAME_EN": "Al Fatiha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6347",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Al Hadbaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6348",
    "CITYNAME_AR": "الدوخه",
    "CITYNAME_EN": "Aldukhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6349",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al Ghamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6350",
    "CITYNAME_AR": "الخلفاء",
    "CITYNAME_EN": "Al Khulafaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6351",
    "CITYNAME_AR": "الحاجره",
    "CITYNAME_EN": "Al Hajirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6352",
    "CITYNAME_AR": "ال مجاهر والجامه",
    "CITYNAME_EN": "Al Mahajir And Al Jamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6353",
    "CITYNAME_AR": "الشرقي",
    "CITYNAME_EN": "The Eastern ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6354",
    "CITYNAME_AR": "قوره",
    "CITYNAME_EN": "Qurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6355",
    "CITYNAME_AR": "موجمه",
    "CITYNAME_EN": "Mogmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6356",
    "CITYNAME_AR": "ليتوي",
    "CITYNAME_EN": "Litwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6357",
    "CITYNAME_AR": "وادى حبو والطرقه",
    "CITYNAME_EN": "Wadi Habw and Al Tarqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6358",
    "CITYNAME_AR": "المحرث",
    "CITYNAME_EN": "Al Mihreth ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6359",
    "CITYNAME_AR": "محورو",
    "CITYNAME_EN": "Mahuru ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6360",
    "CITYNAME_AR": "شوطه",
    "CITYNAME_EN": "Shutah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6361",
    "CITYNAME_AR": "الرايه وذنب الرايه",
    "CITYNAME_EN": "Al Rayah and Zanb Al Rayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6362",
    "CITYNAME_AR": "مسقام والنوابى",
    "CITYNAME_EN": "Misqam and Al Nawaby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6363",
    "CITYNAME_AR": "الغمده",
    "CITYNAME_EN": "Al Ghamdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6364",
    "CITYNAME_AR": "ذنب احمرين والسرب",
    "CITYNAME_EN": "Zanb Ahmreen and Al Serb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6365",
    "CITYNAME_AR": "القضى",
    "CITYNAME_EN": "Al Qady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6366",
    "CITYNAME_AR": "الحرمله",
    "CITYNAME_EN": "Al Harmalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6367",
    "CITYNAME_AR": "حبيل ال تمام",
    "CITYNAME_EN": "Habil Al Tamam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6368",
    "CITYNAME_AR": "حبيل محزاه ال تمام",
    "CITYNAME_EN": "Habil Mihzah Al Tamam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6369",
    "CITYNAME_AR": "عنقه ال محاج",
    "CITYNAME_EN": "Anqah Al Mihaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6370",
    "CITYNAME_AR": "لثبه",
    "CITYNAME_EN": "Lathbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6371",
    "CITYNAME_AR": "ذم عدن",
    "CITYNAME_EN": "Zam Adn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6372",
    "CITYNAME_AR": "ذراه",
    "CITYNAME_EN": "Zerah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6373",
    "CITYNAME_AR": "رديان",
    "CITYNAME_EN": "Rodyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6374",
    "CITYNAME_AR": "نجد قرضه",
    "CITYNAME_EN": "Najd Qardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6375",
    "CITYNAME_AR": "جمان",
    "CITYNAME_EN": "Gaman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6376",
    "CITYNAME_AR": "جبل حلوان",
    "CITYNAME_EN": "Helwan Mountain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6377",
    "CITYNAME_AR": "زعرا",
    "CITYNAME_EN": "Za'rah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6378",
    "CITYNAME_AR": "خهيان والخلل",
    "CITYNAME_EN": "Khahyan and Al Khall",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6379",
    "CITYNAME_AR": "الجهايف (آل مبره)",
    "CITYNAME_EN": "Al Jahayif (Al Mabarah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6380",
    "CITYNAME_AR": "المسقى",
    "CITYNAME_EN": "Al Masqa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6381",
    "CITYNAME_AR": "ال قزع",
    "CITYNAME_EN": "Al Qaz' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6382",
    "CITYNAME_AR": "العتله",
    "CITYNAME_EN": "Al Atlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6383",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As Sirr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6384",
    "CITYNAME_AR": "الدحيض",
    "CITYNAME_EN": "Al Dahid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6385",
    "CITYNAME_AR": "الجنيبه",
    "CITYNAME_EN": "Al Janibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6386",
    "CITYNAME_AR": "الرهوه ورحبان",
    "CITYNAME_EN": "Al Rahwah and Rahban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6387",
    "CITYNAME_AR": "ال مفتى",
    "CITYNAME_EN": "Al Mufti ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6388",
    "CITYNAME_AR": "القلت",
    "CITYNAME_EN": "Al Qalt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6389",
    "CITYNAME_AR": "الجر الاعلى",
    "CITYNAME_EN": "Al Gar the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6390",
    "CITYNAME_AR": "الجر الاسفل",
    "CITYNAME_EN": "Al Gar the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6391",
    "CITYNAME_AR": "البهيمه والفايح",
    "CITYNAME_EN": "Al Bahimah and Al Fayih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6392",
    "CITYNAME_AR": "الصيحانى",
    "CITYNAME_EN": "Al Sayhany ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6393",
    "CITYNAME_AR": "ال رمضان والقصر والمنتزه",
    "CITYNAME_EN": "Al Ramdan, Al Qusair, and Al Muntazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6394",
    "CITYNAME_AR": "ال بالعلاء",
    "CITYNAME_EN": "Al Balalaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6395",
    "CITYNAME_AR": "ال ميهوم وال محشوش",
    "CITYNAME_EN": "Al Maihum, and Al Mahshush ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6396",
    "CITYNAME_AR": "ال فرزعه",
    "CITYNAME_EN": "Al Farza'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6397",
    "CITYNAME_AR": "آل مطير والقابل",
    "CITYNAME_EN": "Al Matir, and Al Qabil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6398",
    "CITYNAME_AR": "الصليحى",
    "CITYNAME_EN": "Al Seleihy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6399",
    "CITYNAME_AR": "ال محيا والمحجر والبحره",
    "CITYNAME_EN": "Al Mahya, Al Mahjer, and Al Bahrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6400",
    "CITYNAME_AR": "القعره (السر)",
    "CITYNAME_EN": "Al Qa'rah (Al Ser)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6401",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6402",
    "CITYNAME_AR": "ال الدويح",
    "CITYNAME_EN": "Al Alduwayh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6403",
    "CITYNAME_AR": "قريه السوق وال مجاهر",
    "CITYNAME_EN": "Al Suoq Village, and Al Mejaher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6404",
    "CITYNAME_AR": "الزرقان ال دودف",
    "CITYNAME_EN": "Al Zarqan Al Dfouf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6405",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6406",
    "CITYNAME_AR": "ال فاين",
    "CITYNAME_EN": "Al Fayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6407",
    "CITYNAME_AR": "ال مشرب",
    "CITYNAME_EN": "Al Meshrib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6408",
    "CITYNAME_AR": "البيثاء والقبوله",
    "CITYNAME_EN": "Al Bithaa and Al Qabulah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6409",
    "CITYNAME_AR": "المراغه",
    "CITYNAME_EN": "Al Maraghah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6410",
    "CITYNAME_AR": "ال صقر",
    "CITYNAME_EN": "Al Saqr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6411",
    "CITYNAME_AR": "ال القحم",
    "CITYNAME_EN": "Al Qahm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6412",
    "CITYNAME_AR": "ال جدعه",
    "CITYNAME_EN": "Al Gad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6413",
    "CITYNAME_AR": "مهد السر",
    "CITYNAME_EN": "Mahd Al Ser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6414",
    "CITYNAME_AR": "ابو جرشه والعريج و بئرالعبد",
    "CITYNAME_EN": "Abu Gersha, Al Arig, and Bir Al Abd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6415",
    "CITYNAME_AR": "الحوايا",
    "CITYNAME_EN": "Al Hawaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6416",
    "CITYNAME_AR": "روغ العاند",
    "CITYNAME_EN": "Rawgh Alanid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6417",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Al Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6418",
    "CITYNAME_AR": "المحش",
    "CITYNAME_EN": "Al Mahsh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6419",
    "CITYNAME_AR": "الخشم",
    "CITYNAME_EN": "Al Khashm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6420",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6421",
    "CITYNAME_AR": "السويداء",
    "CITYNAME_EN": "Al Suwaidaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6422",
    "CITYNAME_AR": "الراكبه",
    "CITYNAME_EN": "Al Rakibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6423",
    "CITYNAME_AR": "لحف",
    "CITYNAME_EN": "Lahf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6424",
    "CITYNAME_AR": "ذم غضار",
    "CITYNAME_EN": "Zam Ghidar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6425",
    "CITYNAME_AR": "المشراف",
    "CITYNAME_EN": "Al Masharif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6426",
    "CITYNAME_AR": "المعدل",
    "CITYNAME_EN": "Al Ma'del ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6427",
    "CITYNAME_AR": "المصلمة",
    "CITYNAME_EN": "Al Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6428",
    "CITYNAME_AR": "وادي الصدر",
    "CITYNAME_EN": "Wadi As Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6429",
    "CITYNAME_AR": "بعاره",
    "CITYNAME_EN": "B'arah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6430",
    "CITYNAME_AR": "رزام والمتشابهات وال سطح وشدنه",
    "CITYNAME_EN": "Rezam, Al Mutashabihat, Al Sath, and Shednah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6431",
    "CITYNAME_AR": "المثناه",
    "CITYNAME_EN": "Al  Mathnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6432",
    "CITYNAME_AR": "الحلقوم والخضراء",
    "CITYNAME_EN": "Al Halqoum and Al Khadraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6433",
    "CITYNAME_AR": "حضن السوق",
    "CITYNAME_EN": "Hodn Al Souq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6434",
    "CITYNAME_AR": "عين الفرس",
    "CITYNAME_EN": "Ain Al Faras ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6435",
    "CITYNAME_AR": "شعب النام",
    "CITYNAME_EN": "Shi'b Al Nam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6436",
    "CITYNAME_AR": "المريش",
    "CITYNAME_EN": "Al Morish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6437",
    "CITYNAME_AR": "مندرمربة",
    "CITYNAME_EN": "Mandermariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6438",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "Al Sel'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6439",
    "CITYNAME_AR": "شرحان",
    "CITYNAME_EN": "Sharhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6440",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Al Hajfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6441",
    "CITYNAME_AR": "وادي رخمه",
    "CITYNAME_EN": "Wadi Rakhmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6442",
    "CITYNAME_AR": "المرفي",
    "CITYNAME_EN": "Al Marfa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6443",
    "CITYNAME_AR": "رخائم",
    "CITYNAME_EN": "Rakhaem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6444",
    "CITYNAME_AR": "اسفل وادي احلالي",
    "CITYNAME_EN": "Lower part of Wadi Ihlaly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6445",
    "CITYNAME_AR": "وادي كرمان",
    "CITYNAME_EN": "Wadi karman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6446",
    "CITYNAME_AR": "وادي لنك",
    "CITYNAME_EN": "Wadi Link ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6447",
    "CITYNAME_AR": "وادي وطده",
    "CITYNAME_EN": "Wadi Watdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6448",
    "CITYNAME_AR": "شعيب رحب",
    "CITYNAME_EN": "Shu'aib Rahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6449",
    "CITYNAME_AR": "صندل",
    "CITYNAME_EN": "Sandel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6450",
    "CITYNAME_AR": "المخاضه",
    "CITYNAME_EN": "Al Makhadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6451",
    "CITYNAME_AR": "شعيب ظلام",
    "CITYNAME_EN": "Shu'aib Zalam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6452",
    "CITYNAME_AR": "وادي رملان",
    "CITYNAME_EN": "Wadi Ramlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6453",
    "CITYNAME_AR": "وادي عبلا",
    "CITYNAME_EN": "Wadi Ablah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6454",
    "CITYNAME_AR": "المفجر",
    "CITYNAME_EN": "Al Mafjer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6455",
    "CITYNAME_AR": "سيه",
    "CITYNAME_EN": "Seyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6456",
    "CITYNAME_AR": "خفنه",
    "CITYNAME_EN": "Khafnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6457",
    "CITYNAME_AR": "رحب عتود",
    "CITYNAME_EN": "Rahb Atud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6458",
    "CITYNAME_AR": "حبيب عتود",
    "CITYNAME_EN": "Habib Atud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6459",
    "CITYNAME_AR": "الجاعره",
    "CITYNAME_EN": "Al Ja'rah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6460",
    "CITYNAME_AR": "بطحان عتود",
    "CITYNAME_EN": "Bathan Atud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6461",
    "CITYNAME_AR": "وادي امحا",
    "CITYNAME_EN": "Wadi Amha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6462",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Al Qufailah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6463",
    "CITYNAME_AR": "وادي قبل",
    "CITYNAME_EN": "Wadi Qabl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6464",
    "CITYNAME_AR": "حليان",
    "CITYNAME_EN": "Helian ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6465",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Al Fasilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6466",
    "CITYNAME_AR": "علاس",
    "CITYNAME_EN": "Allas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6467",
    "CITYNAME_AR": "وثيثو",
    "CITYNAME_EN": "Wathithu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6468",
    "CITYNAME_AR": "خلل الواجهات",
    "CITYNAME_EN": "Khall Al Wajhat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6469",
    "CITYNAME_AR": "الفقيحه",
    "CITYNAME_EN": "Al Faqihah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6470",
    "CITYNAME_AR": "كثم",
    "CITYNAME_EN": "Kathm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6471",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6472",
    "CITYNAME_AR": "ذا المثيبه",
    "CITYNAME_EN": "Za Al Mathibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6473",
    "CITYNAME_AR": "العراض السلامه",
    "CITYNAME_EN": "Al Erad Al Salamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6474",
    "CITYNAME_AR": "وادي رحب",
    "CITYNAME_EN": "Wadi Rahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6475",
    "CITYNAME_AR": "الغال و علاء",
    "CITYNAME_EN": "Al Ghal and Alaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6476",
    "CITYNAME_AR": "رهوه الغيناء",
    "CITYNAME_EN": "Rahwah Al Ghainaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6477",
    "CITYNAME_AR": "قرن الميثاء",
    "CITYNAME_EN": "Qarn Al Mithaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6478",
    "CITYNAME_AR": "خلل الزهيره",
    "CITYNAME_EN": "Khell Al Zuhairah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6479",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Allasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6480",
    "CITYNAME_AR": "وادي جلال",
    "CITYNAME_EN": "Wadi Galal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6481",
    "CITYNAME_AR": "وادي امرو",
    "CITYNAME_EN": "Wadi Imro ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6482",
    "CITYNAME_AR": "ام قاد",
    "CITYNAME_EN": "Umm Qad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6483",
    "CITYNAME_AR": "وادي نمره",
    "CITYNAME_EN": "Wadi Nemrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6484",
    "CITYNAME_AR": "الفسف ومرجومه",
    "CITYNAME_EN": "Al Fasf and Marghumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6485",
    "CITYNAME_AR": "ذات سحلو",
    "CITYNAME_EN": "Zat Sehlo ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6486",
    "CITYNAME_AR": "مهرو",
    "CITYNAME_EN": "Mahru ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6487",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6488",
    "CITYNAME_AR": "ذمبل",
    "CITYNAME_EN": "Zembel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6489",
    "CITYNAME_AR": "آل آم صدامي",
    "CITYNAME_EN": "Al Umm Sdamy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6490",
    "CITYNAME_AR": "آل آم جيش",
    "CITYNAME_EN": "Al Umm Gaish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6491",
    "CITYNAME_AR": "الطلحه (آل الرعيني وآل سرحان)",
    "CITYNAME_EN": "Al Talhah (Al Alru'iny, and Al Sarhan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6492",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6493",
    "CITYNAME_AR": "آل كايد",
    "CITYNAME_EN": "Al Kabid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6494",
    "CITYNAME_AR": "الغال",
    "CITYNAME_EN": "Al Ghal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6495",
    "CITYNAME_AR": "آل بجاد",
    "CITYNAME_EN": "Al Begad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6496",
    "CITYNAME_AR": "تكليلا (آل حنيش)",
    "CITYNAME_EN": "Taklilah (Al Hanish)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6497",
    "CITYNAME_AR": "آل غازي والمقزعه",
    "CITYNAME_EN": "Al Ghazy, and Al Maqza'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6498",
    "CITYNAME_AR": "الزهراء",
    "CITYNAME_EN": "Al Zahraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6499",
    "CITYNAME_AR": "البكره والقلفا",
    "CITYNAME_EN": "Al Bakrah, and Al Qalfa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6500",
    "CITYNAME_AR": "آل بالعلاء",
    "CITYNAME_EN": "Al Balalaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6501",
    "CITYNAME_AR": "آل جديعي",
    "CITYNAME_EN": "Al Godi'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6502",
    "CITYNAME_AR": "المسقوى",
    "CITYNAME_EN": "Al Masqawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6503",
    "CITYNAME_AR": "سر بن ام شهير",
    "CITYNAME_EN": "Ser bin Umm Shahir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6504",
    "CITYNAME_AR": "رحبان",
    "CITYNAME_EN": "Rahban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6505",
    "CITYNAME_AR": "اليمانيه وضيوعي",
    "CITYNAME_EN": "Al Yamaniyah and Diu'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6506",
    "CITYNAME_AR": "التري",
    "CITYNAME_EN": "Al terry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6507",
    "CITYNAME_AR": "الوادي",
    "CITYNAME_EN": "Al Wadi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6508",
    "CITYNAME_AR": "المرمده",
    "CITYNAME_EN": "Al Marmadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6509",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Mazmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6510",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6511",
    "CITYNAME_AR": "الشعاب",
    "CITYNAME_EN": "Al Shi'ab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6512",
    "CITYNAME_AR": "آل الشراع الاعلى والاسفل",
    "CITYNAME_EN": "Al Al-Shira' the upper and the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6513",
    "CITYNAME_AR": "الوغل",
    "CITYNAME_EN": "Al Waghl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6514",
    "CITYNAME_AR": "مصاولي",
    "CITYNAME_EN": "Masawly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6515",
    "CITYNAME_AR": "العلايه",
    "CITYNAME_EN": "Al Alayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6516",
    "CITYNAME_AR": "الصفاح",
    "CITYNAME_EN": "As Safah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6517",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6518",
    "CITYNAME_AR": "تيهان",
    "CITYNAME_EN": "Tihan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6519",
    "CITYNAME_AR": "مزهر",
    "CITYNAME_EN": "Mezhir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6520",
    "CITYNAME_AR": "صعبان وزبنه",
    "CITYNAME_EN": "Sa'ban and Zinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6521",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Al-Khaimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6522",
    "CITYNAME_AR": "آل ام سعلي والقلت",
    "CITYNAME_EN": "Al Umm Sa'ly and Al Qalt ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6523",
    "CITYNAME_AR": "الجو والدهنا",
    "CITYNAME_EN": "Algaw and Aldehna ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6524",
    "CITYNAME_AR": "ال محمود وشرمه",
    "CITYNAME_EN": "Al Mahmoud and Sharmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6525",
    "CITYNAME_AR": "ال الجمل",
    "CITYNAME_EN": "Al Algamal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6526",
    "CITYNAME_AR": "المحنيه",
    "CITYNAME_EN": "Al Mahniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6527",
    "CITYNAME_AR": "ال الزرقاء والعطف",
    "CITYNAME_EN": "Al Alzarqaa and Al Atf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6528",
    "CITYNAME_AR": "المفصله",
    "CITYNAME_EN": "Al Mafsalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6529",
    "CITYNAME_AR": "الباحه (باحة ربيعه)",
    "CITYNAME_EN": "Al Bahah (Bahat Rabi'a) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6530",
    "CITYNAME_AR": "المهلل",
    "CITYNAME_EN": "Al-Mahll ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6531",
    "CITYNAME_AR": "دار عثمان",
    "CITYNAME_EN": "Dar Othman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6532",
    "CITYNAME_AR": "ال دهمش",
    "CITYNAME_EN": "Al Dahmash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6533",
    "CITYNAME_AR": "ال ينفع",
    "CITYNAME_EN": "Al Yanfi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6534",
    "CITYNAME_AR": "الغوله",
    "CITYNAME_EN": "Al-Ghoulah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6535",
    "CITYNAME_AR": "الشرحه",
    "CITYNAME_EN": "Al-Sharhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6536",
    "CITYNAME_AR": "المعرس",
    "CITYNAME_EN": "Al-Ma'ris",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6537",
    "CITYNAME_AR": "ال على والخليق",
    "CITYNAME_EN": "Al Ali and Al-Khaliq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6538",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6539",
    "CITYNAME_AR": "القاريه",
    "CITYNAME_EN": "Al-Qarbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6540",
    "CITYNAME_AR": "لحيف وال بعول",
    "CITYNAME_EN": "Lahif and Al Ba'ul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6541",
    "CITYNAME_AR": "المخله",
    "CITYNAME_EN": "Al-Makhlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6542",
    "CITYNAME_AR": "الطلحا أركبه البر",
    "CITYNAME_EN": "Al-Talah Arkah Al-Ber ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6543",
    "CITYNAME_AR": "أمشله",
    "CITYNAME_EN": "Amshalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6544",
    "CITYNAME_AR": "هيمان",
    "CITYNAME_EN": "Himan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6545",
    "CITYNAME_AR": "الميراد",
    "CITYNAME_EN": "Al-Mirad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6546",
    "CITYNAME_AR": "وادي ريده",
    "CITYNAME_EN": "Wadi Ridah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6547",
    "CITYNAME_AR": "الموبل",
    "CITYNAME_EN": "Al-Muel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6548",
    "CITYNAME_AR": "المعدى",
    "CITYNAME_EN": "Al-Ma'di ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6549",
    "CITYNAME_AR": "الشقراء",
    "CITYNAME_EN": "Al-Shaqraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6550",
    "CITYNAME_AR": "الحمارات",
    "CITYNAME_EN": "Al-Hamarat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6551",
    "CITYNAME_AR": "الرتق",
    "CITYNAME_EN": "Al-Ratq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6552",
    "CITYNAME_AR": "المرة",
    "CITYNAME_EN": "Al-Marrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6553",
    "CITYNAME_AR": "الضحي",
    "CITYNAME_EN": "Al-Dahiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6554",
    "CITYNAME_AR": "المليح (الملاح)",
    "CITYNAME_EN": "Al-Malih (Al-Mallah) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6555",
    "CITYNAME_AR": "جفير",
    "CITYNAME_EN": "Jafir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6556",
    "CITYNAME_AR": "صدر وائلة (المجمع)",
    "CITYNAME_EN": "Sadr Waelah (Al-Majma')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6557",
    "CITYNAME_AR": "رثمة وائله",
    "CITYNAME_EN": "Rathmah Waelah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6558",
    "CITYNAME_AR": " سكار (الريم)",
    "CITYNAME_EN": " Skar (Al-Riem)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6559",
    "CITYNAME_AR": "رغفه(جبل عيسى ال حامد)",
    "CITYNAME_EN": "Raghfah (Eisa Al Hamed Mountain)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6560",
    "CITYNAME_AR": "عرق",
    "CITYNAME_EN": "Irq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6561",
    "CITYNAME_AR": "رثمة بني ماجور",
    "CITYNAME_EN": "Rathmah Bani Majur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6562",
    "CITYNAME_AR": "ناسب",
    "CITYNAME_EN": "Nasib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6563",
    "CITYNAME_AR": "الروغ",
    "CITYNAME_EN": "Al-Rawgh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6564",
    "CITYNAME_AR": "البيح بن ماجور",
    "CITYNAME_EN": "Al-Bih bin Majur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6565",
    "CITYNAME_AR": "الجهيفه",
    "CITYNAME_EN": "Al-Jahifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6566",
    "CITYNAME_AR": "الضاح الغربي",
    "CITYNAME_EN": "The wsetern Al-Dah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6567",
    "CITYNAME_AR": "القرية",
    "CITYNAME_EN": "Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6568",
    "CITYNAME_AR": "الساقط",
    "CITYNAME_EN": "Al-Saqit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6569",
    "CITYNAME_AR": "الخورمة",
    "CITYNAME_EN": "Al-Khormah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6570",
    "CITYNAME_AR": "الروغ",
    "CITYNAME_EN": "Al-Rawgh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6571",
    "CITYNAME_AR": "البطح والرهوه",
    "CITYNAME_EN": "Al-Bath and Al-Rahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6572",
    "CITYNAME_AR": "وادي حضرا",
    "CITYNAME_EN": "Wadi Hadra ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6573",
    "CITYNAME_AR": "المعتق",
    "CITYNAME_EN": "Al-Ma'tiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6574",
    "CITYNAME_AR": "المعقورة",
    "CITYNAME_EN": "Al-Ma'qurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6575",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Al Hano",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6576",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "Al-Sriyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6577",
    "CITYNAME_AR": "لصب الحنو",
    "CITYNAME_EN": "Lasb Al-Hanw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6578",
    "CITYNAME_AR": "مفتح وراخ ورحب",
    "CITYNAME_EN": "Meftih, Rakh and Rahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6579",
    "CITYNAME_AR": "وادى احبره",
    "CITYNAME_EN": "Wadi Ahbarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6580",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Al Fasilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6581",
    "CITYNAME_AR": "الغال",
    "CITYNAME_EN": "Al Ghal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6582",
    "CITYNAME_AR": "الضاح الاعلى",
    "CITYNAME_EN": "Al-Dah (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6583",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6584",
    "CITYNAME_AR": "الرهز",
    "CITYNAME_EN": "Al-Rahz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6585",
    "CITYNAME_AR": "ذم خلفاء",
    "CITYNAME_EN": "Zem khalfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6586",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6587",
    "CITYNAME_AR": "الرصيد",
    "CITYNAME_EN": "Al Rasid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6588",
    "CITYNAME_AR": "مفتح وادي الحر",
    "CITYNAME_EN": "Meftih Wadi Al-Herr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6589",
    "CITYNAME_AR": "لحج أمهيجه",
    "CITYNAME_EN": "Lahj Amhijah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6590",
    "CITYNAME_AR": "وادى ضموه وتضراع",
    "CITYNAME_EN": "Wadi Damwah and Tedra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6591",
    "CITYNAME_AR": "الفيض",
    "CITYNAME_EN": "Radoum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6592",
    "CITYNAME_AR": "ظهران والكوع",
    "CITYNAME_EN": "Radum  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6593",
    "CITYNAME_AR": "العينه (عشه)",
    "CITYNAME_EN": "Al-Einah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6594",
    "CITYNAME_AR": "حمامه",
    "CITYNAME_EN": "Hamamh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6595",
    "CITYNAME_AR": "مفطح",
    "CITYNAME_EN": "Meftih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6596",
    "CITYNAME_AR": "يرعرع",
    "CITYNAME_EN": "Yer're'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6597",
    "CITYNAME_AR": "مفتح العوراء",
    "CITYNAME_EN": "Meftih Al-Awraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6598",
    "CITYNAME_AR": "العتله",
    "CITYNAME_EN": "Al Atlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6599",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6600",
    "CITYNAME_AR": "ذنقور",
    "CITYNAME_EN": "Zanqur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6601",
    "CITYNAME_AR": "ثلثين",
    "CITYNAME_EN": "Thalthin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6602",
    "CITYNAME_AR": "مفتح يرعرع",
    "CITYNAME_EN": "Meftih Yer're'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6603",
    "CITYNAME_AR": "العوراء",
    "CITYNAME_EN": "Al-Awraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6604",
    "CITYNAME_AR": "النجفه",
    "CITYNAME_EN": "Al-Najfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6605",
    "CITYNAME_AR": "يبرين",
    "CITYNAME_EN": "Yabrin",
    "REGION_ID": "5"
   },
  
   {
    "CITY_ID": "6661",
    "CITYNAME_AR": "ريع ذو النقم",
    "CITYNAME_EN": "Raye Dhu Alnaqm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6662",
    "CITYNAME_AR": "وادى الج",
    "CITYNAME_EN": "Wadi Alaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6663",
    "CITYNAME_AR": "المشبه",
    "CITYNAME_EN": "Al Mashbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6664",
    "CITYNAME_AR": "امباقم الاعلى",
    "CITYNAME_EN": "Ambaqem Al Aalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6665",
    "CITYNAME_AR": "المزعفط",
    "CITYNAME_EN": "Al Mezaafat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6666",
    "CITYNAME_AR": "مفطاح",
    "CITYNAME_EN": "Meftah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6667",
    "CITYNAME_AR": "الحيد",
    "CITYNAME_EN": "Al Heed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6668",
    "CITYNAME_AR": "القهده   ثاهر القهده",
    "CITYNAME_EN": "Al Qahdah Thaher Al Qahdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6669",
    "CITYNAME_AR": "القواسى",
    "CITYNAME_EN": "Al Quwasi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6670",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "An-Nasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6671",
    "CITYNAME_AR": "الشجن",
    "CITYNAME_EN": "Ash-Shagan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6672",
    "CITYNAME_AR": "الوجره",
    "CITYNAME_EN": "Al Wagrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6673",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "As-Selah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6674",
    "CITYNAME_AR": "ثاهر ام جذم",
    "CITYNAME_EN": "Thaher Om Gatham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6675",
    "CITYNAME_AR": "المخلسيه",
    "CITYNAME_EN": "Al Makhlasyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6676",
    "CITYNAME_AR": "وادى غاويه",
    "CITYNAME_EN": "Wadi Ghawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6677",
    "CITYNAME_AR": "الغاويه",
    "CITYNAME_EN": "Al Ghawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6678",
    "CITYNAME_AR": "ثاهر ام محشره",
    "CITYNAME_EN": "Thaher Om Mahsharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6679",
    "CITYNAME_AR": "خصاصة",
    "CITYNAME_EN": "Khasasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6680",
    "CITYNAME_AR": "المحرص",
    "CITYNAME_EN": "Al Mehras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6681",
    "CITYNAME_AR": "الازرق",
    "CITYNAME_EN": "Al Mezraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6682",
    "CITYNAME_AR": "وادى جبحه",
    "CITYNAME_EN": "Wadi Gabhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6683",
    "CITYNAME_AR": "ذا سوامى",
    "CITYNAME_EN": "Tha Sawami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6684",
    "CITYNAME_AR": "لحج المنتدل",
    "CITYNAME_EN": "Lahj Al Muntadal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6685",
    "CITYNAME_AR": "حافظ",
    "CITYNAME_EN": "Hafez",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6686",
    "CITYNAME_AR": "ثاهر المنتدل",
    "CITYNAME_EN": "Thaher Al Muntadal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6687",
    "CITYNAME_AR": "حديد",
    "CITYNAME_EN": "Hadeed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6688",
    "CITYNAME_AR": "حيد العرجا",
    "CITYNAME_EN": "Hed Al Arja",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6689",
    "CITYNAME_AR": "ذراع الثبرة",
    "CITYNAME_EN": "Zeraa Ath-Thabarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6690",
    "CITYNAME_AR": "نيد مناف",
    "CITYNAME_EN": "Ned Manaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6691",
    "CITYNAME_AR": "القشباء",
    "CITYNAME_EN": "Al Qashbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6692",
    "CITYNAME_AR": "الكمباء",
    "CITYNAME_EN": "Al Kambaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6693",
    "CITYNAME_AR": "القندر",
    "CITYNAME_EN": "Al Qandar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6694",
    "CITYNAME_AR": "شعاطيط",
    "CITYNAME_EN": "Shaatet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6695",
    "CITYNAME_AR": "نيد معلك",
    "CITYNAME_EN": "Ned Maalak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6696",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6697",
    "CITYNAME_AR": "مشاحر",
    "CITYNAME_EN": "Mashaher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6698",
    "CITYNAME_AR": "طلان",
    "CITYNAME_EN": "Talan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6699",
    "CITYNAME_AR": "معشى",
    "CITYNAME_EN": "Maashi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6700",
    "CITYNAME_AR": "عشبان",
    "CITYNAME_EN": "Ushban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6701",
    "CITYNAME_AR": "الغبريات",
    "CITYNAME_EN": "Al Ghubryat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6702",
    "CITYNAME_AR": "أملج",
    "CITYNAME_EN": "Amlaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6703",
    "CITYNAME_AR": "نيد ذيبان",
    "CITYNAME_EN": "Ned Zeban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6704",
    "CITYNAME_AR": "قله قشع",
    "CITYNAME_EN": "Qulah Qashaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6705",
    "CITYNAME_AR": "حبيل حسنه",
    "CITYNAME_EN": "Hubayel Hasanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6706",
    "CITYNAME_AR": "الشقراء",
    "CITYNAME_EN": "Ash-Shaqraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6707",
    "CITYNAME_AR": "بني فراح",
    "CITYNAME_EN": "Bani Farrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6708",
    "CITYNAME_AR": "الفقر",
    "CITYNAME_EN": "Al Faqr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6709",
    "CITYNAME_AR": "شثا",
    "CITYNAME_EN": "Shata",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6710",
    "CITYNAME_AR": "الخوارم",
    "CITYNAME_EN": "Al Khawarem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6711",
    "CITYNAME_AR": "المطارق",
    "CITYNAME_EN": "Al Matareq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6712",
    "CITYNAME_AR": "ذراع القذال",
    "CITYNAME_EN": "Zeraa Al-Qazal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6713",
    "CITYNAME_AR": "سر المخزومه",
    "CITYNAME_EN": "Ser Al Makhzumah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6714",
    "CITYNAME_AR": "بيت الحجوجم",
    "CITYNAME_EN": "Beit Al Hagogom",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6715",
    "CITYNAME_AR": "مثل",
    "CITYNAME_EN": "Mathal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6716",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Al Jarieh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6717",
    "CITYNAME_AR": "الوقلاء",
    "CITYNAME_EN": "Al Waqlaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6718",
    "CITYNAME_AR": "الهربجه",
    "CITYNAME_EN": "Al Hurayjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6719",
    "CITYNAME_AR": "الزوزاء",
    "CITYNAME_EN": "Az-Zawzaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6720",
    "CITYNAME_AR": "الحميره   الحمراره",
    "CITYNAME_EN": "Al Humayrah Al Humrarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6721",
    "CITYNAME_AR": "المنشح",
    "CITYNAME_EN": "Al Manshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6722",
    "CITYNAME_AR": "ذا موباد",
    "CITYNAME_EN": "Za Muyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6723",
    "CITYNAME_AR": "اللصب",
    "CITYNAME_EN": "Al Lasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6724",
    "CITYNAME_AR": "المورقه",
    "CITYNAME_EN": "Al Mawreqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6725",
    "CITYNAME_AR": "ثاهر المحاس",
    "CITYNAME_EN": "Thaher Al Muhas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6726",
    "CITYNAME_AR": "مفتح المخشا",
    "CITYNAME_EN": "Maftah Al Makhsha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6727",
    "CITYNAME_AR": "الميثب",
    "CITYNAME_EN": "Al Maythab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6728",
    "CITYNAME_AR": "مطلأ",
    "CITYNAME_EN": "Matlaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6729",
    "CITYNAME_AR": "خطوه وزا",
    "CITYNAME_EN": "Khutwah Waza",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6730",
    "CITYNAME_AR": "لحج الضباع",
    "CITYNAME_EN": "Lahj Ad-Debaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6731",
    "CITYNAME_AR": "حميده",
    "CITYNAME_EN": "Humaydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6732",
    "CITYNAME_AR": "السحا",
    "CITYNAME_EN": "As-Saha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6733",
    "CITYNAME_AR": "القشبه",
    "CITYNAME_EN": "Al Qashabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6734",
    "CITYNAME_AR": "حبيل مسنه",
    "CITYNAME_EN": "Hubayel Masanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6735",
    "CITYNAME_AR": "الغمار",
    "CITYNAME_EN": "Al Ghumar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6736",
    "CITYNAME_AR": "الداغره",
    "CITYNAME_EN": "Ad-Dagherah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6737",
    "CITYNAME_AR": "الشرحا",
    "CITYNAME_EN": "Ash-Sharha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6738",
    "CITYNAME_AR": "الفرضه",
    "CITYNAME_EN": "Al Fardah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6739",
    "CITYNAME_AR": "المحله",
    "CITYNAME_EN": "Al Muhalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6740",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Ar-Rass",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6741",
    "CITYNAME_AR": "منياد",
    "CITYNAME_EN": "Manyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6742",
    "CITYNAME_AR": "العريف",
    "CITYNAME_EN": "Al Areef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6743",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Al Garab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6744",
    "CITYNAME_AR": "المرح",
    "CITYNAME_EN": "Al Marah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6745",
    "CITYNAME_AR": "المحصور",
    "CITYNAME_EN": "Al Mahsoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6746",
    "CITYNAME_AR": "عفور",
    "CITYNAME_EN": "Afoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6747",
    "CITYNAME_AR": "غطط",
    "CITYNAME_EN": "Ghatat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6748",
    "CITYNAME_AR": "ضيره",
    "CITYNAME_EN": "Dayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6749",
    "CITYNAME_AR": "القطب",
    "CITYNAME_EN": "Al-Qutb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6750",
    "CITYNAME_AR": "حيد قباره",
    "CITYNAME_EN": "Hayd Qabarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6751",
    "CITYNAME_AR": "حيد خضران",
    "CITYNAME_EN": "Hayd Khadran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6752",
    "CITYNAME_AR": "الاعراس",
    "CITYNAME_EN": "Al Aaras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6753",
    "CITYNAME_AR": "البغوم",
    "CITYNAME_EN": "Al Boghom",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6754",
    "CITYNAME_AR": "سرالبراء",
    "CITYNAME_EN": "Ser Al Baraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6755",
    "CITYNAME_AR": "باديه",
    "CITYNAME_EN": "Badiah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6756",
    "CITYNAME_AR": "الشباب",
    "CITYNAME_EN": "Ash-Shabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6757",
    "CITYNAME_AR": "لحب قحيف",
    "CITYNAME_EN": "Lahb Qaheef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6758",
    "CITYNAME_AR": "حبيل الرزم",
    "CITYNAME_EN": "Hubayel Ar-Razm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6759",
    "CITYNAME_AR": "ظاهر الحدبه",
    "CITYNAME_EN": "Zaher Al Haddabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6760",
    "CITYNAME_AR": "الشريانه",
    "CITYNAME_EN": "Ash-Shuryanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6761",
    "CITYNAME_AR": "الركيس",
    "CITYNAME_EN": "Ar-Rakees",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6762",
    "CITYNAME_AR": "الحجفه السفلى",
    "CITYNAME_EN": "Al Gahfa As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6763",
    "CITYNAME_AR": "الحجفه العليا",
    "CITYNAME_EN": "Al Gahfa Al Olyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6764",
    "CITYNAME_AR": "ثاهر برقان",
    "CITYNAME_EN": "Thaher Barqan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6765",
    "CITYNAME_AR": "السعى",
    "CITYNAME_EN": "As-Sai",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6766",
    "CITYNAME_AR": "ظاهره الشقراء",
    "CITYNAME_EN": "Zaheret Ash-Shaqraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6767",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6768",
    "CITYNAME_AR": "الهدره",
    "CITYNAME_EN": "Al-Hadrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6769",
    "CITYNAME_AR": "نيد الحضن الغمار",
    "CITYNAME_EN": "Ned Al Hudn Al-Ghamar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6770",
    "CITYNAME_AR": "مده",
    "CITYNAME_EN": "Mudah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6771",
    "CITYNAME_AR": "منقلا",
    "CITYNAME_EN": "Manqala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6772",
    "CITYNAME_AR": "الحيين",
    "CITYNAME_EN": "Al Jabeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6773",
    "CITYNAME_AR": "المقرث",
    "CITYNAME_EN": "Al Muqreth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6774",
    "CITYNAME_AR": "نيد الوبد",
    "CITYNAME_EN": "Ned Al Weed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6775",
    "CITYNAME_AR": "الثركه",
    "CITYNAME_EN": "Ath-Tharakah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6776",
    "CITYNAME_AR": "الموهبه",
    "CITYNAME_EN": "Al Mawhebah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6777",
    "CITYNAME_AR": "عاس  السر",
    "CITYNAME_EN": "As Sirr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6778",
    "CITYNAME_AR": "نيد النفق",
    "CITYNAME_EN": "Ned An-Nafaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6779",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Al Arqoob",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6780",
    "CITYNAME_AR": "الزغيب",
    "CITYNAME_EN": "Az-Zagheeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6781",
    "CITYNAME_AR": "المغامر",
    "CITYNAME_EN": "Al Mughamer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6782",
    "CITYNAME_AR": "الوجيف",
    "CITYNAME_EN": "Al Wajeef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6783",
    "CITYNAME_AR": "ضبوعه",
    "CITYNAME_EN": "Dabwaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6784",
    "CITYNAME_AR": "بيت العرق",
    "CITYNAME_EN": "Beit Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6785",
    "CITYNAME_AR": "الكباس",
    "CITYNAME_EN": "Al Kabaas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6786",
    "CITYNAME_AR": "نيد الجدره",
    "CITYNAME_EN": "Ned Al Jedrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6787",
    "CITYNAME_AR": "محرق الجب",
    "CITYNAME_EN": "Mahreq Al Jub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6788",
    "CITYNAME_AR": "قعاد الوجار",
    "CITYNAME_EN": "Quaad Al Marwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6789",
    "CITYNAME_AR": "الصلاله",
    "CITYNAME_EN": "As-Salalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6790",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "As-Sahlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6791",
    "CITYNAME_AR": "البلقه",
    "CITYNAME_EN": "Al Balqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6792",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Ad-Dahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6793",
    "CITYNAME_AR": "الخطو",
    "CITYNAME_EN": "Al Khatw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6794",
    "CITYNAME_AR": "قعاد ظلمه",
    "CITYNAME_EN": "Quaad Zalmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6795",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Ash-Shawhatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6796",
    "CITYNAME_AR": "الجعره",
    "CITYNAME_EN": "Al Gaarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6797",
    "CITYNAME_AR": "القنادر",
    "CITYNAME_EN": "Al Qanadir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6798",
    "CITYNAME_AR": "الحنوا",
    "CITYNAME_EN": "Al Hunwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6799",
    "CITYNAME_AR": "قله الغمرا",
    "CITYNAME_EN": "Qulah Al Ghamra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6800",
    "CITYNAME_AR": "القاسيه",
    "CITYNAME_EN": "Al Qasiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6801",
    "CITYNAME_AR": "القويد",
    "CITYNAME_EN": "Al Quwad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6802",
    "CITYNAME_AR": "الحرفان السحا والسيار",
    "CITYNAME_EN": "Al Herfan As-Saha w As-Sayyar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6803",
    "CITYNAME_AR": "الظهار",
    "CITYNAME_EN": "Ath-Thahhar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6804",
    "CITYNAME_AR": "القتاده",
    "CITYNAME_EN": "Al Qatadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6805",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Al Khulayef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6806",
    "CITYNAME_AR": "ظاهر العادى",
    "CITYNAME_EN": "Thaher Al Ady",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6807",
    "CITYNAME_AR": "الحره الغربى",
    "CITYNAME_EN": "Al Hurrah Al Gharbi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6808",
    "CITYNAME_AR": "جغره",
    "CITYNAME_EN": "Gaghrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6809",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Al Muraba'a",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6810",
    "CITYNAME_AR": "الحظن",
    "CITYNAME_EN": "Al Huthun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6811",
    "CITYNAME_AR": "عكوه باالمهيد",
    "CITYNAME_EN": "Aqwa Balmaheed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6812",
    "CITYNAME_AR": "ذى القرث",
    "CITYNAME_EN": "Ze Al Qarth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6813",
    "CITYNAME_AR": "الثجاجه",
    "CITYNAME_EN": "Ath-Thujajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6814",
    "CITYNAME_AR": "الصلع",
    "CITYNAME_EN": "As-Salaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6815",
    "CITYNAME_AR": "نيد اثناين",
    "CITYNAME_EN": "Ned Athnayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6816",
    "CITYNAME_AR": "المجازم",
    "CITYNAME_EN": "Al Mujazem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6817",
    "CITYNAME_AR": "الصمخه",
    "CITYNAME_EN": "As-Samkhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6818",
    "CITYNAME_AR": "الحبط",
    "CITYNAME_EN": "Al Habat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6819",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al Meshbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6820",
    "CITYNAME_AR": "البحر",
    "CITYNAME_EN": "Al Bahr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6821",
    "CITYNAME_AR": "هشيم",
    "CITYNAME_EN": "Hasheem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6822",
    "CITYNAME_AR": "ذراع اقيرن",
    "CITYNAME_EN": "Zeraa Aqayran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6823",
    "CITYNAME_AR": "امرن",
    "CITYNAME_EN": "Mur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6824",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Az-Zarwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6825",
    "CITYNAME_AR": "قعاد التيمهرار",
    "CITYNAME_EN": "Quaad At-Temherar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6826",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6827",
    "CITYNAME_AR": "البدج",
    "CITYNAME_EN": "Al Badj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6828",
    "CITYNAME_AR": "السنان",
    "CITYNAME_EN": "As-Senan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6829",
    "CITYNAME_AR": "الخذله",
    "CITYNAME_EN": "Al Khazalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6830",
    "CITYNAME_AR": "اللواء",
    "CITYNAME_EN": "Al Lewaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6831",
    "CITYNAME_AR": "قعاد الوجار",
    "CITYNAME_EN": "Quaad Al Wajjar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6832",
    "CITYNAME_AR": "قعاد النفش",
    "CITYNAME_EN": "Quaad Al Nafash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6833",
    "CITYNAME_AR": "عكوه",
    "CITYNAME_EN": "Aqwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6834",
    "CITYNAME_AR": "البرده",
    "CITYNAME_EN": "Al Baradah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6835",
    "CITYNAME_AR": "قله القضيه القثيبة",
    "CITYNAME_EN": "Qulah Al Qudaybah Al Quthaybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6836",
    "CITYNAME_AR": "الصقع",
    "CITYNAME_EN": "As Saqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6837",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Al Zanabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6838",
    "CITYNAME_AR": "الحذير",
    "CITYNAME_EN": "Al Huzyer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6839",
    "CITYNAME_AR": "حمرن ذي حمرا",
    "CITYNAME_EN": "Ze Ahmar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6840",
    "CITYNAME_AR": "خطوه الهشيم",
    "CITYNAME_EN": "Khutwah Al Hasheem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6841",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "As-Sadr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6842",
    "CITYNAME_AR": "المزرعه",
    "CITYNAME_EN": "Al Mazraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6843",
    "CITYNAME_AR": "القمعان",
    "CITYNAME_EN": "Al Qumaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6844",
    "CITYNAME_AR": "الحبق",
    "CITYNAME_EN": "Al Habaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6845",
    "CITYNAME_AR": "قله العش",
    "CITYNAME_EN": "Qulet Al Osh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6846",
    "CITYNAME_AR": "المجزعه",
    "CITYNAME_EN": "Al Magzaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6847",
    "CITYNAME_AR": "الحجا الاسفل",
    "CITYNAME_EN": "Al Haja Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6848",
    "CITYNAME_AR": "ريع مصيدة",
    "CITYNAME_EN": "Ree Massyadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6849",
    "CITYNAME_AR": "دخل الثوعه",
    "CITYNAME_EN": "Dakhl Ath-Thawaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6850",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "As-Sulaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6851",
    "CITYNAME_AR": "العيدابي",
    "CITYNAME_EN": "Al-Aydabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6852",
    "CITYNAME_AR": "ابوالخليه",
    "CITYNAME_EN": "Abo Al Khaleyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6853",
    "CITYNAME_AR": "عيبان",
    "CITYNAME_EN": "Ayban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6854",
    "CITYNAME_AR": "ثوار",
    "CITYNAME_EN": "Thuwar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6855",
    "CITYNAME_AR": "حراز",
    "CITYNAME_EN": "Heraz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6856",
    "CITYNAME_AR": "ثاهر اولاد مسفر",
    "CITYNAME_EN": "Thaher Awlad Mesfer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6857",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6858",
    "CITYNAME_AR": "الشرج",
    "CITYNAME_EN": "Ash-Sharaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6859",
    "CITYNAME_AR": "المخرف",
    "CITYNAME_EN": "Al Makhraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6860",
    "CITYNAME_AR": "سر القرع",
    "CITYNAME_EN": "Ser Al Qarai",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6861",
    "CITYNAME_AR": "المخراجه",
    "CITYNAME_EN": "Al Mekhrajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6862",
    "CITYNAME_AR": "المرمى الطلعه",
    "CITYNAME_EN": "Al Marma At-Talaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6863",
    "CITYNAME_AR": "ثاهر العدن",
    "CITYNAME_EN": "Thaher Al Adan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6864",
    "CITYNAME_AR": "السياله",
    "CITYNAME_EN": "As-Sayalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6865",
    "CITYNAME_AR": "الفلوق",
    "CITYNAME_EN": "Al Folooq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6866",
    "CITYNAME_AR": "الشعراء",
    "CITYNAME_EN": "Ash-Shuaraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6867",
    "CITYNAME_AR": "ذم نشم",
    "CITYNAME_EN": "Zam Nushum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6868",
    "CITYNAME_AR": "دمنه المعش",
    "CITYNAME_EN": "Damnet Al Maish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6869",
    "CITYNAME_AR": "قواحى",
    "CITYNAME_EN": "Qawahi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6870",
    "CITYNAME_AR": "سر حسين",
    "CITYNAME_EN": "Ser Hussein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6871",
    "CITYNAME_AR": "الحجوه والمدور",
    "CITYNAME_EN": "Al Hagwa and Al Modoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6872",
    "CITYNAME_AR": "المهدم",
    "CITYNAME_EN": "Al Muhaddam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6873",
    "CITYNAME_AR": "الحنايه",
    "CITYNAME_EN": "Al Hunayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6874",
    "CITYNAME_AR": "المخبزه",
    "CITYNAME_EN": "Al Makhbazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6875",
    "CITYNAME_AR": "سر سويدى",
    "CITYNAME_EN": "Ser Swedi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6876",
    "CITYNAME_AR": "البحر",
    "CITYNAME_EN": "Al Bahr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6877",
    "CITYNAME_AR": "الثلابه",
    "CITYNAME_EN": "Ath-Thalabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6878",
    "CITYNAME_AR": "المزومه المقابلات",
    "CITYNAME_EN": "Al Mazomah Al Muqablat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6879",
    "CITYNAME_AR": "مسيله",
    "CITYNAME_EN": "Musaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6880",
    "CITYNAME_AR": "الطايف",
    "CITYNAME_EN": "Taif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6881",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6882",
    "CITYNAME_AR": "سر الرمادى",
    "CITYNAME_EN": "Ser Ar-Ramadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6883",
    "CITYNAME_AR": "ثاهر دشيش",
    "CITYNAME_EN": "Thaher Dushaysh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6884",
    "CITYNAME_AR": "المجدر",
    "CITYNAME_EN": "Al Majedar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6885",
    "CITYNAME_AR": "ثاهر القفله",
    "CITYNAME_EN": "Thaher Al Qaflah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6886",
    "CITYNAME_AR": "ابوقليه",
    "CITYNAME_EN": "Abo Qalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6887",
    "CITYNAME_AR": "ام النصايب",
    "CITYNAME_EN": "Om An-Nasayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6888",
    "CITYNAME_AR": "المراوغ",
    "CITYNAME_EN": "Al-Murawegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6889",
    "CITYNAME_AR": "ضمراء",
    "CITYNAME_EN": "Damraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6890",
    "CITYNAME_AR": "سر جبريل",
    "CITYNAME_EN": "Ser Jebreel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6891",
    "CITYNAME_AR": "ام الدراهم وسكن حرس الحدود",
    "CITYNAME_EN": "Om Ad-Darahem and Border Guard Housing",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6892",
    "CITYNAME_AR": "المدردب",
    "CITYNAME_EN": "Al Mudardab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6893",
    "CITYNAME_AR": "المروى",
    "CITYNAME_EN": "Al Marwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6894",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6895",
    "CITYNAME_AR": "مندر",
    "CITYNAME_EN": "Mandar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6896",
    "CITYNAME_AR": "المدمن",
    "CITYNAME_EN": "Al Mudmen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6897",
    "CITYNAME_AR": "المدافن",
    "CITYNAME_EN": "Al Madafen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6898",
    "CITYNAME_AR": "الرقة",
    "CITYNAME_EN": "Ar-Raqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6899",
    "CITYNAME_AR": "الجبانه",
    "CITYNAME_EN": "Al Gabanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6900",
    "CITYNAME_AR": "سر الدبع",
    "CITYNAME_EN": "Ser Ad-Daba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6901",
    "CITYNAME_AR": "الغلفه",
    "CITYNAME_EN": "Al Ghalfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6902",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Al Mahraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6903",
    "CITYNAME_AR": "عردود",
    "CITYNAME_EN": "Ardood",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6904",
    "CITYNAME_AR": "المرماد",
    "CITYNAME_EN": "Al Mermad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6905",
    "CITYNAME_AR": "الخليه",
    "CITYNAME_EN": "Al Khaleyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6906",
    "CITYNAME_AR": "ثاهر جنون",
    "CITYNAME_EN": "Thaher Gonoon",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6907",
    "CITYNAME_AR": "الوشايا",
    "CITYNAME_EN": "Al Washaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6908",
    "CITYNAME_AR": "المعصور",
    "CITYNAME_EN": "Al Maasoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6909",
    "CITYNAME_AR": "المراويغ",
    "CITYNAME_EN": "Al Maraweegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6910",
    "CITYNAME_AR": "صليبه",
    "CITYNAME_EN": "Sulaybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6911",
    "CITYNAME_AR": "السيمران",
    "CITYNAME_EN": "As-Saymaran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6912",
    "CITYNAME_AR": "الضلع",
    "CITYNAME_EN": "Ad-Dula",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6913",
    "CITYNAME_AR": "ام مصلوم",
    "CITYNAME_EN": "Om Masloom",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6914",
    "CITYNAME_AR": "ابو النكر",
    "CITYNAME_EN": "Abo An-Nakr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6915",
    "CITYNAME_AR": "اللخص",
    "CITYNAME_EN": "Al-Lakhas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6916",
    "CITYNAME_AR": "ابوالربى",
    "CITYNAME_EN": "Abo Ar-Ruba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6917",
    "CITYNAME_AR": "خلف السلب جميمه",
    "CITYNAME_EN": "Khalaf As-Salab Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6918",
    "CITYNAME_AR": "السقيه خالف السلب",
    "CITYNAME_EN": "As-Suqyah Khalef As-Salab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6919",
    "CITYNAME_AR": "هيجه براك",
    "CITYNAME_EN": "Hayjet Barrak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6920",
    "CITYNAME_AR": "ابو ام عوده",
    "CITYNAME_EN": "Abo Om Oudah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6921",
    "CITYNAME_AR": "مجرب عيلان",
    "CITYNAME_EN": "Mugarab Aylan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6922",
    "CITYNAME_AR": "خالفت السيال",
    "CITYNAME_EN": "Khalfet As-Sayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6923",
    "CITYNAME_AR": "ابو الحشافه",
    "CITYNAME_EN": "Abo Al Hashafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6924",
    "CITYNAME_AR": "ام هليبان (الخباعي)",
    "CITYNAME_EN": "Om Hulayban (Al Khabaei)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6925",
    "CITYNAME_AR": "الدفيف",
    "CITYNAME_EN": "Ad-Dafeef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6926",
    "CITYNAME_AR": "الفطحه",
    "CITYNAME_EN": "Al Fataha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6927",
    "CITYNAME_AR": "الباطنه",
    "CITYNAME_EN": "Al Batenah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6928",
    "CITYNAME_AR": "الباديه",
    "CITYNAME_EN": "Al Badiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6929",
    "CITYNAME_AR": "قائم العرب",
    "CITYNAME_EN": "Qaem Al Arab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6930",
    "CITYNAME_AR": "متاعه",
    "CITYNAME_EN": "Mataah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6931",
    "CITYNAME_AR": "مشعرين",
    "CITYNAME_EN": "Mashaereen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6932",
    "CITYNAME_AR": "بخشه",
    "CITYNAME_EN": "Bakhshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6933",
    "CITYNAME_AR": "السرورى (الصرويين)",
    "CITYNAME_EN": "As-Sururi (As-Sarween)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6934",
    "CITYNAME_AR": "الطليحى",
    "CITYNAME_EN": "At-Tulayhi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6935",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6936",
    "CITYNAME_AR": "جوشب",
    "CITYNAME_EN": "Gawshab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6937",
    "CITYNAME_AR": "رغله   عطوان",
    "CITYNAME_EN": "Raghlet Atwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6938",
    "CITYNAME_AR": "العروق",
    "CITYNAME_EN": "Al Orooq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6939",
    "CITYNAME_AR": "المصياده",
    "CITYNAME_EN": "Al Mesyadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6940",
    "CITYNAME_AR": "قرن ام ليلى",
    "CITYNAME_EN": "Qarn Om Laila",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6941",
    "CITYNAME_AR": "البويره",
    "CITYNAME_EN": "Al Buwayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6942",
    "CITYNAME_AR": "ردحه البشماء",
    "CITYNAME_EN": "Radhet Al Bashmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6943",
    "CITYNAME_AR": "العشبه",
    "CITYNAME_EN": "Al Oshbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6944",
    "CITYNAME_AR": "الخطو",
    "CITYNAME_EN": "Al Khatw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6945",
    "CITYNAME_AR": "المظيلف",
    "CITYNAME_EN": "Al Muzaylif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6946",
    "CITYNAME_AR": "لحج حثول",
    "CITYNAME_EN": "Lahj Hathol",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6947",
    "CITYNAME_AR": "لحج العرام",
    "CITYNAME_EN": "Lahj Al Arram",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6948",
    "CITYNAME_AR": "لحج مسهيه",
    "CITYNAME_EN": "Lahj Mashyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6949",
    "CITYNAME_AR": "لحج خنجر",
    "CITYNAME_EN": "Lahj Khanjar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6950",
    "CITYNAME_AR": "باطن معشره",
    "CITYNAME_EN": "Baten Maasharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6951",
    "CITYNAME_AR": "المقطعه",
    "CITYNAME_EN": "Al Maqtaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6952",
    "CITYNAME_AR": "خالف السيال",
    "CITYNAME_EN": "Khalef As-Sayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6953",
    "CITYNAME_AR": "منكره",
    "CITYNAME_EN": "Monkarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6954",
    "CITYNAME_AR": "ام الممجار",
    "CITYNAME_EN": "Om Al Mamgar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6955",
    "CITYNAME_AR": "المحفر",
    "CITYNAME_EN": "Al Mehfar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6956",
    "CITYNAME_AR": "الغليف",
    "CITYNAME_EN": "Al Ghaleef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6957",
    "CITYNAME_AR": "الحنو العليا",
    "CITYNAME_EN": "Al Honaw Al Olyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6958",
    "CITYNAME_AR": "باطن كلب",
    "CITYNAME_EN": "Batten Kalab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6959",
    "CITYNAME_AR": "ارضى الزامله",
    "CITYNAME_EN": "Ardi Az-Zamelah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6960",
    "CITYNAME_AR": "الرخه",
    "CITYNAME_EN": "Ar-Rukha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6961",
    "CITYNAME_AR": "الملاحى",
    "CITYNAME_EN": "Al Malahi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6962",
    "CITYNAME_AR": "قابر ابوه",
    "CITYNAME_EN": "Qaber Abuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6963",
    "CITYNAME_AR": "ابو ضهيا",
    "CITYNAME_EN": "Abo Dahya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6964",
    "CITYNAME_AR": "ثاهر الغدير",
    "CITYNAME_EN": "Thaher Al Ghadir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6965",
    "CITYNAME_AR": "ثاهر علي القبي",
    "CITYNAME_EN": "Thaher Ali Al Qabbi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6966",
    "CITYNAME_AR": "حبيل مسير",
    "CITYNAME_EN": "Hubayel Maseer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6967",
    "CITYNAME_AR": "زهب ام تغلب",
    "CITYNAME_EN": "Zahab Om Taghlab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6968",
    "CITYNAME_AR": "سر شعتر",
    "CITYNAME_EN": "Ser Shaatar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6969",
    "CITYNAME_AR": "خيران",
    "CITYNAME_EN": "Kheran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6970",
    "CITYNAME_AR": "جربه المذاوبه",
    "CITYNAME_EN": "Garbah Al Muzawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6971",
    "CITYNAME_AR": "النموش",
    "CITYNAME_EN": "An-Numush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6972",
    "CITYNAME_AR": "سر القرية",
    "CITYNAME_EN": "Ser Al Qaryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6973",
    "CITYNAME_AR": "المثرثر",
    "CITYNAME_EN": "Al Mutharthar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6974",
    "CITYNAME_AR": "المخدر",
    "CITYNAME_EN": "Al Mukhader",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6975",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Al Badi'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6976",
    "CITYNAME_AR": "ابو القلعه",
    "CITYNAME_EN": "Abo Al Qalaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6977",
    "CITYNAME_AR": "الجناه",
    "CITYNAME_EN": "Al Gunah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6978",
    "CITYNAME_AR": "قله السطيح",
    "CITYNAME_EN": "Qulet As-Sateeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6979",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Al Jarieh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6980",
    "CITYNAME_AR": "الكدرايا",
    "CITYNAME_EN": "Al Kedraya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6981",
    "CITYNAME_AR": "اليمانيه",
    "CITYNAME_EN": "Al Yamanyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6982",
    "CITYNAME_AR": "الجبل الاسفل   الشرحا",
    "CITYNAME_EN": "Al Jabal Al Asfal Ash-Sharha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6983",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6984",
    "CITYNAME_AR": "رصفات",
    "CITYNAME_EN": "Rasfat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6985",
    "CITYNAME_AR": "نيد خماش",
    "CITYNAME_EN": "Ned Khamash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6986",
    "CITYNAME_AR": "الحصد",
    "CITYNAME_EN": "Al Hasad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6987",
    "CITYNAME_AR": "المحواة",
    "CITYNAME_EN": "Al Mahwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6988",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Al Qaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6989",
    "CITYNAME_AR": "ثاهر رفعان",
    "CITYNAME_EN": "Thaher Rafaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6990",
    "CITYNAME_AR": "دمنه زهب الحتر",
    "CITYNAME_EN": "Damnah Zahab Al Hatr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6991",
    "CITYNAME_AR": "المجداره",
    "CITYNAME_EN": "Al Megdarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6992",
    "CITYNAME_AR": "ثاهر عطوان",
    "CITYNAME_EN": "Thaher Atwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6993",
    "CITYNAME_AR": "رماده",
    "CITYNAME_EN": "Ramadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6994",
    "CITYNAME_AR": "المتواشقات",
    "CITYNAME_EN": "Al Mutawasheqat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6995",
    "CITYNAME_AR": "ام الراوى",
    "CITYNAME_EN": "Om Ar-Rawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6996",
    "CITYNAME_AR": "ثاهر درعان",
    "CITYNAME_EN": "Thaher Daraan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6997",
    "CITYNAME_AR": "عرث مذياب",
    "CITYNAME_EN": "Arth Mezyab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6998",
    "CITYNAME_AR": "الملحه بقصى",
    "CITYNAME_EN": "Al Malha Baqassi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6999",
    "CITYNAME_AR": "الزمر",
    "CITYNAME_EN": "Az-Zumur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7000",
    "CITYNAME_AR": "الخرشه",
    "CITYNAME_EN": "Al Kharashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7001",
    "CITYNAME_AR": "دمنه ام رجع",
    "CITYNAME_EN": "Damnah Om Rajaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7002",
    "CITYNAME_AR": "المرتجم",
    "CITYNAME_EN": "Al Murtagam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7003",
    "CITYNAME_AR": "ام رفغه",
    "CITYNAME_EN": "Om Rafghah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7004",
    "CITYNAME_AR": "ذا الجندل",
    "CITYNAME_EN": "Za Al Jandal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7005",
    "CITYNAME_AR": "قله الشرف",
    "CITYNAME_EN": "Qulet Ash-Sharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7006",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7007",
    "CITYNAME_AR": "الجعارين",
    "CITYNAME_EN": "Al Gaareen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7008",
    "CITYNAME_AR": "الفالق",
    "CITYNAME_EN": "Al Faleq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7009",
    "CITYNAME_AR": "ثاهر الراخى",
    "CITYNAME_EN": "Thaher Ar-Rakhi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7010",
    "CITYNAME_AR": "الخرقاء",
    "CITYNAME_EN": "Al Kharqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7011",
    "CITYNAME_AR": "مواقع",
    "CITYNAME_EN": "Mawaqei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7012",
    "CITYNAME_AR": "المجازيع",
    "CITYNAME_EN": "Al Magazee",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7013",
    "CITYNAME_AR": "المغمر",
    "CITYNAME_EN": "Al Mughmer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7014",
    "CITYNAME_AR": "المحنب",
    "CITYNAME_EN": "Al Muhannab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7015",
    "CITYNAME_AR": "قرن ابو عدنه",
    "CITYNAME_EN": "Qarn Abo Adanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7016",
    "CITYNAME_AR": "ثاهر شطيه",
    "CITYNAME_EN": "Thaher Shatyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7017",
    "CITYNAME_AR": "مفتح الطوال",
    "CITYNAME_EN": "Maftah At-Tuwal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7018",
    "CITYNAME_AR": "قرن ام السلب",
    "CITYNAME_EN": "Qarn Om As-Salab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7019",
    "CITYNAME_AR": "المصنف",
    "CITYNAME_EN": "Al Musanaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7020",
    "CITYNAME_AR": "قرن صبيخه",
    "CITYNAME_EN": "Qarn Subaykha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7021",
    "CITYNAME_AR": "ام دومه السفلى",
    "CITYNAME_EN": "Om Duma As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7022",
    "CITYNAME_AR": "مفتح لحمد",
    "CITYNAME_EN": "Maftah Lahma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7023",
    "CITYNAME_AR": "ثاهر مقوبع",
    "CITYNAME_EN": "Thaher Muqawya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7024",
    "CITYNAME_AR": "اللحوج",
    "CITYNAME_EN": "Al Lahuj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7025",
    "CITYNAME_AR": "حيد مرجع",
    "CITYNAME_EN": "Hayd Marjaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7026",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Ash-Shatba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7027",
    "CITYNAME_AR": "صوله",
    "CITYNAME_EN": "Wadi Sawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7028",
    "CITYNAME_AR": "مفتح صولة",
    "CITYNAME_EN": "Sawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7029",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7030",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Wadi At-Tewal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7031",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "At-Tewal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7032",
    "CITYNAME_AR": "ذات شبر",
    "CITYNAME_EN": "Zat Shebr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7033",
    "CITYNAME_AR": "الصابر",
    "CITYNAME_EN": "As-Saber",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7034",
    "CITYNAME_AR": "الغمار",
    "CITYNAME_EN": "Al Ghumar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7035",
    "CITYNAME_AR": "ثاهر مشهور",
    "CITYNAME_EN": "Thaher Mashhoor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7036",
    "CITYNAME_AR": "مخشوس",
    "CITYNAME_EN": "Makhshosh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7037",
    "CITYNAME_AR": "الشدانه",
    "CITYNAME_EN": "Ash-Shudanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7038",
    "CITYNAME_AR": "امباقم الاسفل",
    "CITYNAME_EN": "Ambaqem Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7039",
    "CITYNAME_AR": "جشر",
    "CITYNAME_EN": "Gashar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7040",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "As-Safa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7041",
    "CITYNAME_AR": "لحج القرعه",
    "CITYNAME_EN": "Lahj Al Qaraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7042",
    "CITYNAME_AR": "النمايش",
    "CITYNAME_EN": "An-Namayesh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7043",
    "CITYNAME_AR": "ثاهر محنه",
    "CITYNAME_EN": "Thaher Muhnah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7044",
    "CITYNAME_AR": "ثاهر الكهف",
    "CITYNAME_EN": "Thaher Al Khaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7045",
    "CITYNAME_AR": "الاوراك",
    "CITYNAME_EN": "Al Awrak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7046",
    "CITYNAME_AR": "الولجه السفلى",
    "CITYNAME_EN": "Al Waljah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7047",
    "CITYNAME_AR": "الموهر",
    "CITYNAME_EN": "Al Muher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7048",
    "CITYNAME_AR": "المجداره",
    "CITYNAME_EN": "Al Megdarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7049",
    "CITYNAME_AR": "السطيحه",
    "CITYNAME_EN": "As-Sutayha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7050",
    "CITYNAME_AR": "رأس المنحته",
    "CITYNAME_EN": "Ras Al Manhatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7051",
    "CITYNAME_AR": "الحرف والقايم",
    "CITYNAME_EN": "Al Harf w Al Qayem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7052",
    "CITYNAME_AR": "قله الحاجب",
    "CITYNAME_EN": "Qulet Al Hajeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7053",
    "CITYNAME_AR": "راس  المدافن",
    "CITYNAME_EN": "Ras Al Madafen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7054",
    "CITYNAME_AR": "الخبار",
    "CITYNAME_EN": "Al Khabbar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7055",
    "CITYNAME_AR": "خطوه الجزايم",
    "CITYNAME_EN": "Khatwet Al Gazayem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7056",
    "CITYNAME_AR": "لحج الجباب",
    "CITYNAME_EN": "Lahj Al Gab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7057",
    "CITYNAME_AR": "الصلبه",
    "CITYNAME_EN": "As-Salabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7058",
    "CITYNAME_AR": "ذراع الصلبه",
    "CITYNAME_EN": "Zeraa As-Salabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7059",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Al Ghubayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7060",
    "CITYNAME_AR": "الحلحله",
    "CITYNAME_EN": "Al Halalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7061",
    "CITYNAME_AR": "الغثايا",
    "CITYNAME_EN": "Al Ghuthaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7062",
    "CITYNAME_AR": "السحيه",
    "CITYNAME_EN": "As-Sahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7063",
    "CITYNAME_AR": "القزاع",
    "CITYNAME_EN": "Al Quzaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7064",
    "CITYNAME_AR": "المظلم",
    "CITYNAME_EN": "Al Muzlem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7065",
    "CITYNAME_AR": "خطوه عافيه",
    "CITYNAME_EN": "Khutwet Afyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7066",
    "CITYNAME_AR": "قرى رامح",
    "CITYNAME_EN": "Qura Rameh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7067",
    "CITYNAME_AR": "خطوه الحاجب",
    "CITYNAME_EN": "Khutwet Al Hajeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7068",
    "CITYNAME_AR": "الدوائر",
    "CITYNAME_EN": "Ad-Dawaer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7069",
    "CITYNAME_AR": "ظاهر الحوف",
    "CITYNAME_EN": "Zaher Al Hawf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7070",
    "CITYNAME_AR": "الحرش",
    "CITYNAME_EN": "Al Harash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7071",
    "CITYNAME_AR": "المحرقه",
    "CITYNAME_EN": "Al Mahraqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7072",
    "CITYNAME_AR": "ضاجع",
    "CITYNAME_EN": "Dajei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7073",
    "CITYNAME_AR": "غوالي البركه",
    "CITYNAME_EN": "Ghawali Al Barakah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7074",
    "CITYNAME_AR": "القله البقعه",
    "CITYNAME_EN": "Al Qulah Al Buqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7075",
    "CITYNAME_AR": "ذراع",
    "CITYNAME_EN": "Zeraa Al Khayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7076",
    "CITYNAME_AR": "نيد القرعه",
    "CITYNAME_EN": "Ner Al Quraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7077",
    "CITYNAME_AR": "نيد الثبير",
    "CITYNAME_EN": "Ned Al-Thubayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7078",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Quzaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7079",
    "CITYNAME_AR": "اللهازم",
    "CITYNAME_EN": "Al Luhazem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7080",
    "CITYNAME_AR": "نيد النغوب",
    "CITYNAME_EN": "Ned An-Nughob",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7081",
    "CITYNAME_AR": "العيدر",
    "CITYNAME_EN": "Al Aydar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7082",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Ad-Dahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7083",
    "CITYNAME_AR": "لحج الكرس",
    "CITYNAME_EN": "Lahj Al Kurs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7084",
    "CITYNAME_AR": "قله مرار",
    "CITYNAME_EN": "Qulah Merar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7085",
    "CITYNAME_AR": "الغافر",
    "CITYNAME_EN": "Al Ghafer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7086",
    "CITYNAME_AR": "الصحصح",
    "CITYNAME_EN": "As-Sahsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7087",
    "CITYNAME_AR": "المقسم",
    "CITYNAME_EN": "Al Muqasem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7088",
    "CITYNAME_AR": "بين القعادين",
    "CITYNAME_EN": "Bin Al Qaadeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7089",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Ad-Dahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7090",
    "CITYNAME_AR": "حبيل هزف",
    "CITYNAME_EN": "Hubayel Hazaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7091",
    "CITYNAME_AR": "روبان",
    "CITYNAME_EN": "Rowyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7092",
    "CITYNAME_AR": "الصده  الطرف",
    "CITYNAME_EN": "As-Sadah At-Tarraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7093",
    "CITYNAME_AR": "المخبر",
    "CITYNAME_EN": "Al Makhbar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7094",
    "CITYNAME_AR": "القويد",
    "CITYNAME_EN": "Al Quwad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7095",
    "CITYNAME_AR": "ام فهرة",
    "CITYNAME_EN": "Om Thahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7096",
    "CITYNAME_AR": "القعقاع",
    "CITYNAME_EN": "Al Qeaqea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7097",
    "CITYNAME_AR": "ثاهر القعقاع",
    "CITYNAME_EN": "Thaher Al Qeaqea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7098",
    "CITYNAME_AR": "الصوح",
    "CITYNAME_EN": "As-Sawh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7099",
    "CITYNAME_AR": "حيد وغره",
    "CITYNAME_EN": "Hed Waghrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7100",
    "CITYNAME_AR": "المجبا",
    "CITYNAME_EN": "Al Magba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7101",
    "CITYNAME_AR": "خطوه ام نصر",
    "CITYNAME_EN": "Khatwet Om Nasr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7102",
    "CITYNAME_AR": "الخرقا",
    "CITYNAME_EN": "Al Kharqa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7103",
    "CITYNAME_AR": "المحط",
    "CITYNAME_EN": "Al Maht",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7104",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Al Garab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7105",
    "CITYNAME_AR": "الحرايص",
    "CITYNAME_EN": "Al Harayes",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7106",
    "CITYNAME_AR": "المهرش",
    "CITYNAME_EN": "Al Mahrash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7107",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghawan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7108",
    "CITYNAME_AR": "الصرو",
    "CITYNAME_EN": "Al Saru",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7109",
    "CITYNAME_AR": "مسلوعة",
    "CITYNAME_EN": "Maslueuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7110",
    "CITYNAME_AR": "المشاف",
    "CITYNAME_EN": "Almashaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7111",
    "CITYNAME_AR": "قعاس",
    "CITYNAME_EN": "Qaeas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7112",
    "CITYNAME_AR": "الارواق",
    "CITYNAME_EN": "Alarwaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7113",
    "CITYNAME_AR": "الحلالة",
    "CITYNAME_EN": "Al Halalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7114",
    "CITYNAME_AR": "الحيد",
    "CITYNAME_EN": "Al Heed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7115",
    "CITYNAME_AR": "ضهايا",
    "CITYNAME_EN": "Dahaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7116",
    "CITYNAME_AR": "ريدان",
    "CITYNAME_EN": "Redan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7117",
    "CITYNAME_AR": "الضلع",
    "CITYNAME_EN": "Aldalae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7118",
    "CITYNAME_AR": "الغاويه",
    "CITYNAME_EN": "Alghawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7119",
    "CITYNAME_AR": "البروق",
    "CITYNAME_EN": "Alburuq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7120",
    "CITYNAME_AR": "الخشم (الوجد)",
    "CITYNAME_EN": "Alkhushm (Alwujda)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7121",
    "CITYNAME_AR": "الحمره",
    "CITYNAME_EN": "Alhamruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7122",
    "CITYNAME_AR": "السلاطه",
    "CITYNAME_EN": "Alsilatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7123",
    "CITYNAME_AR": "حجن",
    "CITYNAME_EN": "Hajn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7124",
    "CITYNAME_AR": "المشرف",
    "CITYNAME_EN": "Al Mushref",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7125",
    "CITYNAME_AR": "الخربه",
    "CITYNAME_EN": "Al Kharabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7126",
    "CITYNAME_AR": "النصايب",
    "CITYNAME_EN": "An-Nasayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7127",
    "CITYNAME_AR": "السران",
    "CITYNAME_EN": "As-Sarran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7128",
    "CITYNAME_AR": "المطحل",
    "CITYNAME_EN": "Al Mathal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7129",
    "CITYNAME_AR": "الشوراء",
    "CITYNAME_EN": "Ash-Shawraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7130",
    "CITYNAME_AR": "المطلع",
    "CITYNAME_EN": "Al Mutlei (Ad-Duqah)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7131",
    "CITYNAME_AR": "المهزل",
    "CITYNAME_EN": "Al Mahzal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7132",
    "CITYNAME_AR": "مراوه",
    "CITYNAME_EN": "Murawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7133",
    "CITYNAME_AR": "القاسى",
    "CITYNAME_EN": "Al Qasi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7134",
    "CITYNAME_AR": "مدوس",
    "CITYNAME_EN": "Madus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7135",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Ad-Duha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7136",
    "CITYNAME_AR": "الشقفه",
    "CITYNAME_EN": "Ash-Shuqafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7137",
    "CITYNAME_AR": "الرقاد",
    "CITYNAME_EN": "Ar-Ruqad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7138",
    "CITYNAME_AR": "بطحان",
    "CITYNAME_EN": "Bathan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7139",
    "CITYNAME_AR": "الحدمه",
    "CITYNAME_EN": "Al Hadamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7140",
    "CITYNAME_AR": "الجوف الاعلى",
    "CITYNAME_EN": "Al Jawf Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7141",
    "CITYNAME_AR": "الجوف الاسفل",
    "CITYNAME_EN": "Al Jawf Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7142",
    "CITYNAME_AR": "الحطبايه",
    "CITYNAME_EN": "Al Hatabayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7143",
    "CITYNAME_AR": "المشاف",
    "CITYNAME_EN": "Al Mashaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7144",
    "CITYNAME_AR": "الفزره",
    "CITYNAME_EN": "Al Fazarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7145",
    "CITYNAME_AR": "الظاجع",
    "CITYNAME_EN": "Al Thajei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7146",
    "CITYNAME_AR": "غبط الرباح",
    "CITYNAME_EN": "Ghabat Ar-Reyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7147",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7148",
    "CITYNAME_AR": "القطباء",
    "CITYNAME_EN": "Al Qatbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7149",
    "CITYNAME_AR": "المزروقه",
    "CITYNAME_EN": "Al Mazrouqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7150",
    "CITYNAME_AR": "باطن الردحه",
    "CITYNAME_EN": "Baten Ar-Radha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7151",
    "CITYNAME_AR": "الاحواش",
    "CITYNAME_EN": "Al Ahwash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7152",
    "CITYNAME_AR": "الوجد",
    "CITYNAME_EN": "Al Wajad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7153",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7154",
    "CITYNAME_AR": "معمق",
    "CITYNAME_EN": "Muamaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7155",
    "CITYNAME_AR": "نيد الضبر",
    "CITYNAME_EN": "Ned Al Dabar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7156",
    "CITYNAME_AR": "المراوى",
    "CITYNAME_EN": "Al Marawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7157",
    "CITYNAME_AR": "ملعق",
    "CITYNAME_EN": "Mualaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7158",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Al Jarab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7159",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "An-Nasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7160",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7161",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Ad-Duha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7162",
    "CITYNAME_AR": "خسبه",
    "CITYNAME_EN": "Khasabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7163",
    "CITYNAME_AR": "الجبيره",
    "CITYNAME_EN": "Hubayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7164",
    "CITYNAME_AR": "العرقة",
    "CITYNAME_EN": "Al Araqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7165",
    "CITYNAME_AR": "الخورمى",
    "CITYNAME_EN": "Al Khurmy",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7166",
    "CITYNAME_AR": "السفار",
    "CITYNAME_EN": "As-Safar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7167",
    "CITYNAME_AR": "الحفنه (ملقطه)",
    "CITYNAME_EN": "Al Hefnah (Malqatah)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7168",
    "CITYNAME_AR": "المزرب",
    "CITYNAME_EN": "Al Mazreb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7169",
    "CITYNAME_AR": "المسلب",
    "CITYNAME_EN": "As-Salbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7170",
    "CITYNAME_AR": "المعاين",
    "CITYNAME_EN": "Al Muayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7171",
    "CITYNAME_AR": "الثغن",
    "CITYNAME_EN": "Al Thaghan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7172",
    "CITYNAME_AR": "البازخ",
    "CITYNAME_EN": "Al Bazikh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7173",
    "CITYNAME_AR": "صيهد",
    "CITYNAME_EN": "Suhayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7174",
    "CITYNAME_AR": "الجوفاء",
    "CITYNAME_EN": "Al Jawfaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7175",
    "CITYNAME_AR": "الشريانه",
    "CITYNAME_EN": "Ash-Shuryanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7176",
    "CITYNAME_AR": "نامره",
    "CITYNAME_EN": "Namerah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7177",
    "CITYNAME_AR": "الفقر",
    "CITYNAME_EN": "Al Faqr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7178",
    "CITYNAME_AR": "نعمه",
    "CITYNAME_EN": "Naemah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7179",
    "CITYNAME_AR": "الخليله",
    "CITYNAME_EN": "Al Khalilah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7180",
    "CITYNAME_AR": "الثاهرين",
    "CITYNAME_EN": "Ath-Thaheren",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7181",
    "CITYNAME_AR": "حسيد",
    "CITYNAME_EN": "Husayyed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7182",
    "CITYNAME_AR": "خوجره",
    "CITYNAME_EN": "Khawjarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7183",
    "CITYNAME_AR": "الجشاء",
    "CITYNAME_EN": "Al Jishaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7184",
    "CITYNAME_AR": "المعرض",
    "CITYNAME_EN": "Al Maarad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7185",
    "CITYNAME_AR": "المزار",
    "CITYNAME_EN": "Al Mazar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7186",
    "CITYNAME_AR": "عاديه",
    "CITYNAME_EN": "Adiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7187",
    "CITYNAME_AR": "النخبه",
    "CITYNAME_EN": "An-Nukhbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7188",
    "CITYNAME_AR": "قله آل يزيد",
    "CITYNAME_EN": "Qelat Al Yazeed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7189",
    "CITYNAME_AR": "القري",
    "CITYNAME_EN": "Al Qurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7190",
    "CITYNAME_AR": "انعام",
    "CITYNAME_EN": "Anaam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7191",
    "CITYNAME_AR": "ضيفان",
    "CITYNAME_EN": "Dayfan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7192",
    "CITYNAME_AR": "الضيعه",
    "CITYNAME_EN": "Al Dayaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7193",
    "CITYNAME_AR": "دخل البير",
    "CITYNAME_EN": "Dakhl Al Beer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7194",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Al Zanabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7195",
    "CITYNAME_AR": "الرمايد",
    "CITYNAME_EN": "Ar-Ramayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7196",
    "CITYNAME_AR": "الشدين",
    "CITYNAME_EN": "Ash-Shadayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7197",
    "CITYNAME_AR": "القاطره",
    "CITYNAME_EN": "Al Qaterah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7198",
    "CITYNAME_AR": "الرفقات",
    "CITYNAME_EN": "Al Refqat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7199",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Ath-Thaher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7200",
    "CITYNAME_AR": "الهياج",
    "CITYNAME_EN": "Al Hayaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7201",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7202",
    "CITYNAME_AR": "الرخم",
    "CITYNAME_EN": "Ar-Rakham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7203",
    "CITYNAME_AR": "الخالفه",
    "CITYNAME_EN": "Al Khalefah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7204",
    "CITYNAME_AR": "المطلى",
    "CITYNAME_EN": "Al Matle",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7205",
    "CITYNAME_AR": "الحلق",
    "CITYNAME_EN": "Al Halaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7206",
    "CITYNAME_AR": "الضهاية",
    "CITYNAME_EN": "Ad-Dahaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7207",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Al Ghubayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7208",
    "CITYNAME_AR": "الحشاش",
    "CITYNAME_EN": "Al Hashash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7209",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Al Qufaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7210",
    "CITYNAME_AR": "ردا",
    "CITYNAME_EN": "Rada",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7211",
    "CITYNAME_AR": "الشطباء",
    "CITYNAME_EN": "Ash-Shatbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7212",
    "CITYNAME_AR": "الغمله",
    "CITYNAME_EN": "Al Ghumalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7213",
    "CITYNAME_AR": "مرعن",
    "CITYNAME_EN": "Maran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7214",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al Buqaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7215",
    "CITYNAME_AR": "مخايع",
    "CITYNAME_EN": "Mukhayaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7216",
    "CITYNAME_AR": "المجعاره",
    "CITYNAME_EN": "Al Megaarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7217",
    "CITYNAME_AR": "القطعاء",
    "CITYNAME_EN": "Al Qataa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7218",
    "CITYNAME_AR": "شعثم",
    "CITYNAME_EN": "Shaatham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7219",
    "CITYNAME_AR": "مشعود",
    "CITYNAME_EN": "Mushawad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7220",
    "CITYNAME_AR": "حبيل الواديين",
    "CITYNAME_EN": "Hubayel Al Wadeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7221",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Al Qufaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7222",
    "CITYNAME_AR": "منفه",
    "CITYNAME_EN": "Manfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7223",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7224",
    "CITYNAME_AR": "المجراع",
    "CITYNAME_EN": "Al Megraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7225",
    "CITYNAME_AR": "شوقب",
    "CITYNAME_EN": "Shawqab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7226",
    "CITYNAME_AR": "الذيلاء",
    "CITYNAME_EN": "Az-Zaylaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7227",
    "CITYNAME_AR": "اللكيت",
    "CITYNAME_EN": "Al Lukayt",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7228",
    "CITYNAME_AR": "النشمه",
    "CITYNAME_EN": "An-Nashamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7229",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al Walajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7230",
    "CITYNAME_AR": "الجعده",
    "CITYNAME_EN": "Al Jaadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7231",
    "CITYNAME_AR": "الطالعه",
    "CITYNAME_EN": "At-Taleah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7232",
    "CITYNAME_AR": "القويره",
    "CITYNAME_EN": "Al Quwayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7233",
    "CITYNAME_AR": "القليله",
    "CITYNAME_EN": "Al Qulaylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7234",
    "CITYNAME_AR": "الصباب",
    "CITYNAME_EN": "As-Sabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7235",
    "CITYNAME_AR": "يبشه",
    "CITYNAME_EN": "Yabeshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7236",
    "CITYNAME_AR": "الخاظ",
    "CITYNAME_EN": "Al Khaz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7237",
    "CITYNAME_AR": "الردهه",
    "CITYNAME_EN": "Ar-Rudhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7238",
    "CITYNAME_AR": "الشواحط",
    "CITYNAME_EN": "Ash-Shawahet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7239",
    "CITYNAME_AR": "الفقاره العليا",
    "CITYNAME_EN": "Al Fuqarah Al Olya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7240",
    "CITYNAME_AR": "الفقاره السفلى",
    "CITYNAME_EN": "Al Fuqarah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7241",
    "CITYNAME_AR": "الغمار",
    "CITYNAME_EN": "Al Ghumar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7242",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7243",
    "CITYNAME_AR": "المقطع والمخدر",
    "CITYNAME_EN": "Al Maqtaa wa Al Mukhader",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7244",
    "CITYNAME_AR": "الشعيث",
    "CITYNAME_EN": "Ash-Shuaith",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7245",
    "CITYNAME_AR": "العمه",
    "CITYNAME_EN": "Al Ammah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7246",
    "CITYNAME_AR": "المزراء",
    "CITYNAME_EN": "Al Muzaraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7247",
    "CITYNAME_AR": "شعب العين",
    "CITYNAME_EN": "Shuab Al Ein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7248",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "As-Selah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7249",
    "CITYNAME_AR": "الخرق",
    "CITYNAME_EN": "Al Kharq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7250",
    "CITYNAME_AR": "القيدل",
    "CITYNAME_EN": "Al Qaydal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7251",
    "CITYNAME_AR": "شعب الصلاء",
    "CITYNAME_EN": "Shuab As-Selaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7252",
    "CITYNAME_AR": "سوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7253",
    "CITYNAME_AR": "مغر",
    "CITYNAME_EN": "Mugher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7254",
    "CITYNAME_AR": "القمله",
    "CITYNAME_EN": "Al Qumalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7255",
    "CITYNAME_AR": "الطينه",
    "CITYNAME_EN": "At-Taynah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7256",
    "CITYNAME_AR": "المجنب",
    "CITYNAME_EN": "Al Muganab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7257",
    "CITYNAME_AR": "القلة",
    "CITYNAME_EN": "Al Qulah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7258",
    "CITYNAME_AR": "السيار",
    "CITYNAME_EN": "As-Sayyar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7259",
    "CITYNAME_AR": "الربيض",
    "CITYNAME_EN": "Ar-Rubayed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7260",
    "CITYNAME_AR": "منبار",
    "CITYNAME_EN": "Menbar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7261",
    "CITYNAME_AR": "الثمينه",
    "CITYNAME_EN": "Ath-Thaminah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7262",
    "CITYNAME_AR": "أم قرى",
    "CITYNAME_EN": "Om Qura",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7263",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Ad-Dahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7264",
    "CITYNAME_AR": "الشيباء",
    "CITYNAME_EN": "As-Shaybaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7265",
    "CITYNAME_AR": "الزريبه",
    "CITYNAME_EN": "Az-Zaraybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7266",
    "CITYNAME_AR": "البياض",
    "CITYNAME_EN": "Al Bayad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7267",
    "CITYNAME_AR": "منازع",
    "CITYNAME_EN": "Munazei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7268",
    "CITYNAME_AR": "المعسه",
    "CITYNAME_EN": "Al Muasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7269",
    "CITYNAME_AR": "اللحجين",
    "CITYNAME_EN": "Al Lahjayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7270",
    "CITYNAME_AR": "القمحله",
    "CITYNAME_EN": "Al Qamhalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7271",
    "CITYNAME_AR": "نيد القمه",
    "CITYNAME_EN": "Ned Al Qumah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7272",
    "CITYNAME_AR": "الطارف والمطرف",
    "CITYNAME_EN": "At-Taref wa Al-Mutraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7273",
    "CITYNAME_AR": "شوحط",
    "CITYNAME_EN": "Shawhat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7274",
    "CITYNAME_AR": "الرحرح",
    "CITYNAME_EN": "Ar-Rahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7275",
    "CITYNAME_AR": "الشراء",
    "CITYNAME_EN": "Ash-Sheraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7276",
    "CITYNAME_AR": "مباري",
    "CITYNAME_EN": "Mubari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7277",
    "CITYNAME_AR": "الزروع",
    "CITYNAME_EN": "Az-Zarua",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7278",
    "CITYNAME_AR": "المدرجه",
    "CITYNAME_EN": "Al Mudrajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7279",
    "CITYNAME_AR": "الشرى",
    "CITYNAME_EN": "Ash-Shara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7280",
    "CITYNAME_AR": "الكبنه",
    "CITYNAME_EN": "Al Kabanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7281",
    "CITYNAME_AR": "رغوان",
    "CITYNAME_EN": "Raghawan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7282",
    "CITYNAME_AR": "القرنه",
    "CITYNAME_EN": "Al Quranah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7283",
    "CITYNAME_AR": "المرتزح",
    "CITYNAME_EN": "Al Murtazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7284",
    "CITYNAME_AR": "شخثاء",
    "CITYNAME_EN": "Shakhthaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7285",
    "CITYNAME_AR": "متهياء",
    "CITYNAME_EN": "Muthayaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7286",
    "CITYNAME_AR": "الكديه",
    "CITYNAME_EN": "Al Kedyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7287",
    "CITYNAME_AR": "ضيفان",
    "CITYNAME_EN": "Dayfan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7288",
    "CITYNAME_AR": "العلقه",
    "CITYNAME_EN": "Al Olaqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7289",
    "CITYNAME_AR": "ضحى المبان",
    "CITYNAME_EN": "Duha Al Muban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7290",
    "CITYNAME_AR": "عرضه",
    "CITYNAME_EN": "Aurdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7291",
    "CITYNAME_AR": "نيد مغدان",
    "CITYNAME_EN": "Ned Meghdan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7292",
    "CITYNAME_AR": "المكحومة",
    "CITYNAME_EN": "Al Makhoumah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7293",
    "CITYNAME_AR": "الضائعة",
    "CITYNAME_EN": "Al Dayaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7294",
    "CITYNAME_AR": "نيد الحره",
    "CITYNAME_EN": "Ned Al Hurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7295",
    "CITYNAME_AR": "السرب",
    "CITYNAME_EN": "As-Serb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7296",
    "CITYNAME_AR": "الدهماء",
    "CITYNAME_EN": "Ad-Duhamaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7297",
    "CITYNAME_AR": "الزرقه",
    "CITYNAME_EN": "Az-Zurqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7298",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Usum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7299",
    "CITYNAME_AR": "النيد الجنوبى",
    "CITYNAME_EN": "Alned Al Janubi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7300",
    "CITYNAME_AR": "الفقع",
    "CITYNAME_EN": "Al Faqei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7301",
    "CITYNAME_AR": "مجايره",
    "CITYNAME_EN": "Mugayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7302",
    "CITYNAME_AR": "الشوكه",
    "CITYNAME_EN": "Ash-Shawkah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7303",
    "CITYNAME_AR": "نيد اللفج",
    "CITYNAME_EN": "Ned Al Lafj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7304",
    "CITYNAME_AR": "جوه شهدان",
    "CITYNAME_EN": "Jua Shahdan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7305",
    "CITYNAME_AR": "الحدق",
    "CITYNAME_EN": "Al Hedq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7306",
    "CITYNAME_AR": "القرنه",
    "CITYNAME_EN": "Al Quranah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7307",
    "CITYNAME_AR": "وساع",
    "CITYNAME_EN": "Wase'a",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7308",
    "CITYNAME_AR": "الرباعه",
    "CITYNAME_EN": "Ar-Rubaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7309",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al Jazaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7310",
    "CITYNAME_AR": "الخصره",
    "CITYNAME_EN": "Al Khesrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7311",
    "CITYNAME_AR": "البليقات",
    "CITYNAME_EN": "Al Bulayqat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7312",
    "CITYNAME_AR": "حلبان",
    "CITYNAME_EN": "Halpan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7313",
    "CITYNAME_AR": "عوارا",
    "CITYNAME_EN": "Awraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7314",
    "CITYNAME_AR": "منهبه",
    "CITYNAME_EN": "Manhabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7315",
    "CITYNAME_AR": "قرى الاودية",
    "CITYNAME_EN": "Al Owdiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7316",
    "CITYNAME_AR": "الرفصه",
    "CITYNAME_EN": "Ar-Rafasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7317",
    "CITYNAME_AR": "القنه",
    "CITYNAME_EN": "Al-Qunah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7318",
    "CITYNAME_AR": "الطيار",
    "CITYNAME_EN": "At-Tayar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7319",
    "CITYNAME_AR": "ضيعة الصهاليل",
    "CITYNAME_EN": "Al Qumeine",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7320",
    "CITYNAME_AR": "قنه العشه",
    "CITYNAME_EN": "Qenah Al Oshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7321",
    "CITYNAME_AR": "باجر",
    "CITYNAME_EN": "Bager",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7322",
    "CITYNAME_AR": "المخنجر",
    "CITYNAME_EN": "Al Mukhanjar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7323",
    "CITYNAME_AR": "الصرب",
    "CITYNAME_EN": "Sareily",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7324",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7325",
    "CITYNAME_AR": "الشعراء",
    "CITYNAME_EN": "Ash-Shuara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7326",
    "CITYNAME_AR": "ضيعة العزيين",
    "CITYNAME_EN": "Ad-Dayaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7327",
    "CITYNAME_AR": "القضابه",
    "CITYNAME_EN": "Al Qudayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7328",
    "CITYNAME_AR": "الخطيم",
    "CITYNAME_EN": "Al Khatim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7329",
    "CITYNAME_AR": "السهوه",
    "CITYNAME_EN": "As-Sahua",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7330",
    "CITYNAME_AR": "عصيم ويشمل",
    "CITYNAME_EN": "Ausaym",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7331",
    "CITYNAME_AR": "القعس",
    "CITYNAME_EN": "Al Qaas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7332",
    "CITYNAME_AR": "اللص",
    "CITYNAME_EN": "Al Les",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7333",
    "CITYNAME_AR": "الزهنعه الحرف",
    "CITYNAME_EN": "Az-Zanaah Al Harf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7334",
    "CITYNAME_AR": "نيد برام",
    "CITYNAME_EN": "Ned Beram",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7335",
    "CITYNAME_AR": "رحبان",
    "CITYNAME_EN": "Rahban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7336",
    "CITYNAME_AR": "الخوير",
    "CITYNAME_EN": "Al Khouair",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7337",
    "CITYNAME_AR": "الكروس",
    "CITYNAME_EN": "Al Karrus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7338",
    "CITYNAME_AR": "غمار",
    "CITYNAME_EN": "Ghumar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7339",
    "CITYNAME_AR": "مروس",
    "CITYNAME_EN": "Marus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7340",
    "CITYNAME_AR": "اعلى وادي وعال",
    "CITYNAME_EN": "Aala Wadi Weaal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7341",
    "CITYNAME_AR": "المعراض",
    "CITYNAME_EN": "Al Mearad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7342",
    "CITYNAME_AR": "ام العقل وتشمل",
    "CITYNAME_EN": "Om Al Aql",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7343",
    "CITYNAME_AR": "الردومه",
    "CITYNAME_EN": "Ar-Radumah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7344",
    "CITYNAME_AR": "مجاعير الطرقه",
    "CITYNAME_EN": "Magaeer At-Turaqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7345",
    "CITYNAME_AR": "صفه",
    "CITYNAME_EN": "Sefah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7346",
    "CITYNAME_AR": "اوعال الشامي",
    "CITYNAME_EN": "Awaal Ash-Shami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7347",
    "CITYNAME_AR": "ملقطه",
    "CITYNAME_EN": "Malqatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7348",
    "CITYNAME_AR": "الجوين",
    "CITYNAME_EN": "Al Jaween",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7349",
    "CITYNAME_AR": "هروب",
    "CITYNAME_EN": "Harub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7350",
    "CITYNAME_AR": "الغجماء",
    "CITYNAME_EN": "Al Ghagmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "7351",
    "CITYNAME_AR": "ابو راكه",
    "CITYNAME_EN": "'Abu Rakh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7352",
    "CITYNAME_AR": "ارتاما",
    "CITYNAME_EN": "Artamaan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7353",
    "CITYNAME_AR": "الشهيباء",
    "CITYNAME_EN": "Alshahiba'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7354",
    "CITYNAME_AR": "السييله",
    "CITYNAME_EN": "Alsyylh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7355",
    "CITYNAME_AR": "وادي ضمر",
    "CITYNAME_EN": "Wadi Damr",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7356",
    "CITYNAME_AR": "ام حليفيات",
    "CITYNAME_EN": "Umm Halifiat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7357",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Ghurab",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7358",
    "CITYNAME_AR": "المذنب (سليع)",
    "CITYNAME_EN": "Almudhinib (Slie)",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7359",
    "CITYNAME_AR": "حريمل",
    "CITYNAME_EN": "Harimil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7360",
    "CITYNAME_AR": "الرضيم",
    "CITYNAME_EN": "Alradim",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7361",
    "CITYNAME_AR": "اوان",
    "CITYNAME_EN": "Awan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7362",
    "CITYNAME_AR": "مشاش حماطه",
    "CITYNAME_EN": "Mashash Hamath",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7363",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7364",
    "CITYNAME_AR": "قليب مفلح",
    "CITYNAME_EN": "Qlyb Muflah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7365",
    "CITYNAME_AR": "القرصه",
    "CITYNAME_EN": "Alqarsuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7366",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "Alsadaruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7367",
    "CITYNAME_AR": "الفارعه",
    "CITYNAME_EN": "Alfarieuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7368",
    "CITYNAME_AR": "القليب",
    "CITYNAME_EN": "Alqalib",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7369",
    "CITYNAME_AR": "الجحر",
    "CITYNAME_EN": "Aljahr",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7370",
    "CITYNAME_AR": "البليطيح",
    "CITYNAME_EN": "Alblytih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7371",
    "CITYNAME_AR": "ابوطينه",
    "CITYNAME_EN": "Abwtinh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7372",
    "CITYNAME_AR": "ام قف",
    "CITYNAME_EN": "Umm Qaf",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7373",
    "CITYNAME_AR": "مشاش كديه",
    "CITYNAME_EN": "Mashash Kdiyh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7374",
    "CITYNAME_AR": "القويع",
    "CITYNAME_EN": "Alqawaye",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7375",
    "CITYNAME_AR": "بيض",
    "CITYNAME_EN": "Bid",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7376",
    "CITYNAME_AR": "عرقين",
    "CITYNAME_EN": "Eariqin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7377",
    "CITYNAME_AR": "سد الميس",
    "CITYNAME_EN": "Sada Almays",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7378",
    "CITYNAME_AR": "الحشيه",
    "CITYNAME_EN": "Alhashih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7379",
    "CITYNAME_AR": "المعروقه",
    "CITYNAME_EN": "Almaeruquh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7380",
    "CITYNAME_AR": "مشاش خشيرمه",
    "CITYNAME_EN": "Mashash Khshirimh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7381",
    "CITYNAME_AR": "ام الرقاع",
    "CITYNAME_EN": "Umm Alriqae",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7382",
    "CITYNAME_AR": "البييره",
    "CITYNAME_EN": "Albayiruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7383",
    "CITYNAME_AR": "مكرى",
    "CITYNAME_EN": "Makraa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7384",
    "CITYNAME_AR": "ابار الخلاص",
    "CITYNAME_EN": "Abar Alkhalas",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7385",
    "CITYNAME_AR": "ابيار الرص",
    "CITYNAME_EN": "Abyar Alras",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7386",
    "CITYNAME_AR": "بئر هذيل",
    "CITYNAME_EN": "Bir Hadhil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7387",
    "CITYNAME_AR": "الجو",
    "CITYNAME_EN": "Aljawi",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "7390",
    "CITYNAME_AR": "مليجه",
    "CITYNAME_EN": "Mulayjah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "7423",
    "CITYNAME_AR": "وادى سيال",
    "CITYNAME_EN": "Wa'Adaa Sayal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7424",
    "CITYNAME_AR": "مسحق",
    "CITYNAME_EN": "Mushaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7425",
    "CITYNAME_AR": "العربه",
    "CITYNAME_EN": "Alearabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7426",
    "CITYNAME_AR": "المقوس",
    "CITYNAME_EN": "Almaqus",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7427",
    "CITYNAME_AR": "المخاضه",
    "CITYNAME_EN": "Almakhaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7428",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Almafraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7429",
    "CITYNAME_AR": "المخصر",
    "CITYNAME_EN": "Almukhasar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7430",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Aldaraj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7431",
    "CITYNAME_AR": "علوان",
    "CITYNAME_EN": "Eilwan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7432",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Almanzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7433",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Alraahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7434",
    "CITYNAME_AR": "الردم",
    "CITYNAME_EN": "Alradm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7435",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Aleaynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7436",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Algharabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7437",
    "CITYNAME_AR": "الرابخ",
    "CITYNAME_EN": "Alrrabikh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7438",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Almeshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7439",
    "CITYNAME_AR": "الشعبه ( أل عجلان)",
    "CITYNAME_EN": "Alshaebuh ( Ala Eajlan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7440",
    "CITYNAME_AR": "الوجده",
    "CITYNAME_EN": "Alwajduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7441",
    "CITYNAME_AR": "المعيود",
    "CITYNAME_EN": "Almaeyud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7442",
    "CITYNAME_AR": "ام دبعه ( حشه ام نخله)",
    "CITYNAME_EN": "Umm Dabaeuh ( Hashuh Umm Nakhlh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7443",
    "CITYNAME_AR": "الدش",
    "CITYNAME_EN": "Aldushu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7444",
    "CITYNAME_AR": "تريبه الشرقيه",
    "CITYNAME_EN": "Turibuh Alsharaqiuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7445",
    "CITYNAME_AR": "تريبه الغربيه",
    "CITYNAME_EN": "Turibuh Algharbayuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7446",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Alshaqruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7447",
    "CITYNAME_AR": "الشديده",
    "CITYNAME_EN": "Alshadiduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7448",
    "CITYNAME_AR": "نعب",
    "CITYNAME_EN": "Neb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7449",
    "CITYNAME_AR": "ال عايض",
    "CITYNAME_EN": "Al Eayd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7450",
    "CITYNAME_AR": "المارد",
    "CITYNAME_EN": "Almarid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7451",
    "CITYNAME_AR": "نعيب",
    "CITYNAME_EN": "Neib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7452",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Alzibaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7453",
    "CITYNAME_AR": "المجارده",
    "CITYNAME_EN": "Almjardh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7454",
    "CITYNAME_AR": "قرن الماء",
    "CITYNAME_EN": "Qern Al-Maa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7455",
    "CITYNAME_AR": "الحباوه",
    "CITYNAME_EN": "Al-Habawah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7456",
    "CITYNAME_AR": "عماره",
    "CITYNAME_EN": "Imarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7457",
    "CITYNAME_AR": "قرن ثريبان",
    "CITYNAME_EN": "Qarn Threban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7458",
    "CITYNAME_AR": "درب عيناء",
    "CITYNAME_EN": "Darb Aynaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7459",
    "CITYNAME_AR": "السوداء",
    "CITYNAME_EN": "Al-Sawdaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7460",
    "CITYNAME_AR": "السواعد",
    "CITYNAME_EN": "Al-Swa'Id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7461",
    "CITYNAME_AR": "المسحم",
    "CITYNAME_EN": "Al-Moshm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7462",
    "CITYNAME_AR": "حدبه نواجى",
    "CITYNAME_EN": "Hadbah Nawajy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7463",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'Bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7464",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Al-Rajmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7465",
    "CITYNAME_AR": "العصيده",
    "CITYNAME_EN": "Al-As'Asedah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7466",
    "CITYNAME_AR": "الذنوب",
    "CITYNAME_EN": "Al-Zanub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7467",
    "CITYNAME_AR": "الليط",
    "CITYNAME_EN": "Allayt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7468",
    "CITYNAME_AR": "ال خليفه",
    "CITYNAME_EN": "Al-Khalifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7469",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7470",
    "CITYNAME_AR": "كحيله ال كميت",
    "CITYNAME_EN": "Kohelah Al Kmeet",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7471",
    "CITYNAME_AR": "كدا",
    "CITYNAME_EN": "Kda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7472",
    "CITYNAME_AR": "قرن مخلد",
    "CITYNAME_EN": "Qarn Mkhld",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7473",
    "CITYNAME_AR": "عرق المخرم",
    "CITYNAME_EN": "'Arq Al-Mkhrm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7474",
    "CITYNAME_AR": "ال مفتى",
    "CITYNAME_EN": "Al Mufti",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7475",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7476",
    "CITYNAME_AR": "المشرقه (ال صعب)",
    "CITYNAME_EN": "Al-Mashraqah ( Al Sa'B)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7477",
    "CITYNAME_AR": "الجندل",
    "CITYNAME_EN": "Al-Jandal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7478",
    "CITYNAME_AR": "الحدمات",
    "CITYNAME_EN": "Al Hadmat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7479",
    "CITYNAME_AR": "القضبه",
    "CITYNAME_EN": "Al-Qadia",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7480",
    "CITYNAME_AR": "الدولج",
    "CITYNAME_EN": "Al-Doulj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7481",
    "CITYNAME_AR": "العيز",
    "CITYNAME_EN": "Al-'Ys",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7482",
    "CITYNAME_AR": "الموسى",
    "CITYNAME_EN": "Al-Musa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7483",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7484",
    "CITYNAME_AR": "ال هلال",
    "CITYNAME_EN": "Al-Hlal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7485",
    "CITYNAME_AR": "الملحاء",
    "CITYNAME_EN": "Al Malhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7486",
    "CITYNAME_AR": "الصلال",
    "CITYNAME_EN": "Al Selal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7487",
    "CITYNAME_AR": "الحدبين",
    "CITYNAME_EN": "Al Hedben",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7488",
    "CITYNAME_AR": "رهو الجماء",
    "CITYNAME_EN": "Rhu Al-Jamaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7489",
    "CITYNAME_AR": "اللدائد",
    "CITYNAME_EN": "Alldaed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7490",
    "CITYNAME_AR": "العساكر",
    "CITYNAME_EN": "Al Asaker",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7491",
    "CITYNAME_AR": "السمرات",
    "CITYNAME_EN": "Al-Samrat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7492",
    "CITYNAME_AR": "ال شغيب الحدباء",
    "CITYNAME_EN": "Al-Shugheb Al-Hadbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7493",
    "CITYNAME_AR": "الوحال",
    "CITYNAME_EN": "Al-Wehal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7494",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7495",
    "CITYNAME_AR": "ال صفحان",
    "CITYNAME_EN": "Al-Safhan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7496",
    "CITYNAME_AR": "مودان",
    "CITYNAME_EN": "Mudan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7497",
    "CITYNAME_AR": "فرش الذنبه",
    "CITYNAME_EN": "Farsh Al-Zanbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7498",
    "CITYNAME_AR": "عرق عصيمه",
    "CITYNAME_EN": "'Rq 'Osymah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7499",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al-Hesn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7500",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Al-Shaqrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7501",
    "CITYNAME_AR": "المغلوله",
    "CITYNAME_EN": "Al-Maghlolah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7502",
    "CITYNAME_AR": "ال محرز",
    "CITYNAME_EN": "Al Mehrez",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7503",
    "CITYNAME_AR": "حي الجامع",
    "CITYNAME_EN": "Hai Al-Jameh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7504",
    "CITYNAME_AR": "حدبه المنحش",
    "CITYNAME_EN": "Hadbah  Al-Menhesh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7505",
    "CITYNAME_AR": "ال شغيب القف",
    "CITYNAME_EN": "Al-Shogheb Al-Qaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7506",
    "CITYNAME_AR": "الحريشا",
    "CITYNAME_EN": "Al-Hresha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7507",
    "CITYNAME_AR": "الصفي",
    "CITYNAME_EN": "Al-Safa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7508",
    "CITYNAME_AR": "قناع بارده",
    "CITYNAME_EN": "Qna' Bardah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7509",
    "CITYNAME_AR": "شعب جوفان",
    "CITYNAME_EN": "Sha'B Jofan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7510",
    "CITYNAME_AR": "ال راعى",
    "CITYNAME_EN": "Al-Ra'Y",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7511",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7512",
    "CITYNAME_AR": "النقعه",
    "CITYNAME_EN": "Al-Naq'Ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7513",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al-Hankah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7514",
    "CITYNAME_AR": "القراء",
    "CITYNAME_EN": "Al-Qaraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7515",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7516",
    "CITYNAME_AR": "ال دهمان",
    "CITYNAME_EN": "Al-Dahman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7517",
    "CITYNAME_AR": "الرتق",
    "CITYNAME_EN": "Al-Ratq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7518",
    "CITYNAME_AR": "ال شقى",
    "CITYNAME_EN": "Al-Shaqy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7519",
    "CITYNAME_AR": "العصراء",
    "CITYNAME_EN": "Al-Asraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7520",
    "CITYNAME_AR": "الصفاء",
    "CITYNAME_EN": "Al-Safaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7521",
    "CITYNAME_AR": "مكيله",
    "CITYNAME_EN": "Makilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7522",
    "CITYNAME_AR": "الخبطه",
    "CITYNAME_EN": "Al-Khabtah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7523",
    "CITYNAME_AR": "البسس (السليل)",
    "CITYNAME_EN": "Al-Bass(Al-Saleel)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7524",
    "CITYNAME_AR": "فلع الحضن",
    "CITYNAME_EN": "Fal' Al Hodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7525",
    "CITYNAME_AR": "الظفيره",
    "CITYNAME_EN": "Al-Zafirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7526",
    "CITYNAME_AR": "ال عائض",
    "CITYNAME_EN": "Al-'Aed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7527",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7528",
    "CITYNAME_AR": "حضن المشائخ (الحضن)",
    "CITYNAME_EN": "Hodn Al-Mashaekh(Al-Hodn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7529",
    "CITYNAME_AR": "الشعبين (ال خشيل)",
    "CITYNAME_EN": "Al-Sh'Ben(Al Khshel)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7530",
    "CITYNAME_AR": "الهبيطه (بنى قيس )",
    "CITYNAME_EN": "Al-Habetah(Bni Qays)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7531",
    "CITYNAME_AR": "عضيده",
    "CITYNAME_EN": "'Adedah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7532",
    "CITYNAME_AR": "مطواف (بلاد ال شعثاء)",
    "CITYNAME_EN": "Metwaf (Al-Sha'Naa Countries) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7533",
    "CITYNAME_AR": "العساكر",
    "CITYNAME_EN": "Al Asaker",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7534",
    "CITYNAME_AR": "شعب القرضه",
    "CITYNAME_EN": "Sha'B Al-Qrdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7535",
    "CITYNAME_AR": "ال سبتي",
    "CITYNAME_EN": "Al-Sebty",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7536",
    "CITYNAME_AR": "العريض",
    "CITYNAME_EN": "Al-Arid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7537",
    "CITYNAME_AR": "الطوف",
    "CITYNAME_EN": "Al-Tawf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7538",
    "CITYNAME_AR": "شعب نثله",
    "CITYNAME_EN": "Sha'B Nethlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7539",
    "CITYNAME_AR": "قراء لحيان",
    "CITYNAME_EN": "Qaraa Lhyan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7540",
    "CITYNAME_AR": "حدابير",
    "CITYNAME_EN": "Hdabeer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7541",
    "CITYNAME_AR": "القابل",
    "CITYNAME_EN": "Al-Qabil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7542",
    "CITYNAME_AR": "الذنوب",
    "CITYNAME_EN": "Al-Zanub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7543",
    "CITYNAME_AR": "الحضنين",
    "CITYNAME_EN": "Al-Hedneen",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7544",
    "CITYNAME_AR": "المحاضر",
    "CITYNAME_EN": "Al-Mahader",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7545",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7546",
    "CITYNAME_AR": "تنوره",
    "CITYNAME_EN": "Tanorah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7547",
    "CITYNAME_AR": "المحله",
    "CITYNAME_EN": "Al-Mahalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7548",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Al-Madrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7549",
    "CITYNAME_AR": "عنقان",
    "CITYNAME_EN": "Anqan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7550",
    "CITYNAME_AR": "المصاغ",
    "CITYNAME_EN": "Al-Masagh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7551",
    "CITYNAME_AR": "ال مشارب",
    "CITYNAME_EN": "Al_Mashareb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7552",
    "CITYNAME_AR": "عثق",
    "CITYNAME_EN": "'Athaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7553",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Al-Reqbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7554",
    "CITYNAME_AR": "الشعبه (ال ماشي  بنى عمرو)",
    "CITYNAME_EN": "Al-Sha'Bah (Al Mashy Bni Amr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7555",
    "CITYNAME_AR": "العساكر",
    "CITYNAME_EN": "Al Asaker",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7556",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Al-Khayal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7557",
    "CITYNAME_AR": "الديه",
    "CITYNAME_EN": "Al-Deeh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7558",
    "CITYNAME_AR": "الظاهره",
    "CITYNAME_EN": "Al-Zahirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7559",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7560",
    "CITYNAME_AR": "بلاد فاحم",
    "CITYNAME_EN": "Belad Fahem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7561",
    "CITYNAME_AR": "سنامه",
    "CITYNAME_EN": "Senamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7562",
    "CITYNAME_AR": "المصانع",
    "CITYNAME_EN": "Al-Masane'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7563",
    "CITYNAME_AR": "العفره",
    "CITYNAME_EN": "Al-Afarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7564",
    "CITYNAME_AR": "المقالع",
    "CITYNAME_EN": "Al-Maqale'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7565",
    "CITYNAME_AR": "مخانقه",
    "CITYNAME_EN": "Makhanqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7566",
    "CITYNAME_AR": "الحراء",
    "CITYNAME_EN": "Al-Haraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7567",
    "CITYNAME_AR": "المحشور(مغنية)",
    "CITYNAME_EN": "Al-Mahshor(Mghniah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7568",
    "CITYNAME_AR": "رهوه ال صميد (الظهره)",
    "CITYNAME_EN": "Rahwah Al-Someed(Al-Zahrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7569",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7570",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7571",
    "CITYNAME_AR": "شوكان",
    "CITYNAME_EN": "Shokan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7572",
    "CITYNAME_AR": "الدولج",
    "CITYNAME_EN": "Al-Doulj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7573",
    "CITYNAME_AR": "موعره (ال واصل)",
    "CITYNAME_EN": "Mo'Erah(Al-Wasel)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7574",
    "CITYNAME_AR": "ال واصل",
    "CITYNAME_EN": "Al-Wasel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7575",
    "CITYNAME_AR": "غيه (ال غيه)",
    "CITYNAME_EN": "Gheh (Al Gheh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7576",
    "CITYNAME_AR": "شعب المعمل",
    "CITYNAME_EN": "Sha'B Al Ma'Mal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7577",
    "CITYNAME_AR": "الحضيره",
    "CITYNAME_EN": "Al-Hedeirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7578",
    "CITYNAME_AR": "المرصد ( منزل الاحد الاعلى)",
    "CITYNAME_EN": "Al Marsad ( Manzel Al Ahad Al-A'La)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7579",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7580",
    "CITYNAME_AR": "الخزنه",
    "CITYNAME_EN": "Al-Khaznah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7581",
    "CITYNAME_AR": "بوصان",
    "CITYNAME_EN": "Bosan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7582",
    "CITYNAME_AR": "المعال (العال)",
    "CITYNAME_EN": "Al-M'Al(Al'Al)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7583",
    "CITYNAME_AR": "الغار",
    "CITYNAME_EN": "Al-Ghar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7584",
    "CITYNAME_AR": "شعب أحمد (المروه)",
    "CITYNAME_EN": "Sha'B Ahmed (Al-Marwah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7585",
    "CITYNAME_AR": "حجاله",
    "CITYNAME_EN": "Hajalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7586",
    "CITYNAME_AR": "شعبه نغفات",
    "CITYNAME_EN": "Sho'Bah Naghfat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7587",
    "CITYNAME_AR": "الخوش",
    "CITYNAME_EN": "Al-Khosh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7588",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7589",
    "CITYNAME_AR": "الكبيشات",
    "CITYNAME_EN": "Al-Kabeshat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7590",
    "CITYNAME_AR": "الاطرف (الافاقمه)",
    "CITYNAME_EN": "Al-Atrf(Al_Afaqemah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7591",
    "CITYNAME_AR": "المقاضي",
    "CITYNAME_EN": "Al-Maqady",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7592",
    "CITYNAME_AR": "الملازم",
    "CITYNAME_EN": "Al-Malazem",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7593",
    "CITYNAME_AR": "الحيد",
    "CITYNAME_EN": "Al Heed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7594",
    "CITYNAME_AR": "الصحنه",
    "CITYNAME_EN": "Al-Sahnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7595",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7596",
    "CITYNAME_AR": "مليحه",
    "CITYNAME_EN": "Maliha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7597",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7598",
    "CITYNAME_AR": "الحائط (ال ثيبه)",
    "CITYNAME_EN": "The Wall (Al Thebah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7599",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al-Hesn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7600",
    "CITYNAME_AR": "الرازنه",
    "CITYNAME_EN": "Al-Rasnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7601",
    "CITYNAME_AR": "شعب الحليفه",
    "CITYNAME_EN": "Sha'B Al-Halefah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7602",
    "CITYNAME_AR": "الحله",
    "CITYNAME_EN": "Al-Helah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7603",
    "CITYNAME_AR": "حداب الحاق",
    "CITYNAME_EN": "Hadab Al-Haaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7604",
    "CITYNAME_AR": "الحبيل (الوجده)",
    "CITYNAME_EN": "Al-Hubeel(Al-Wajdah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7605",
    "CITYNAME_AR": "شعب اللبن",
    "CITYNAME_EN": "Sha'B Al-Labn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7606",
    "CITYNAME_AR": "المخصر",
    "CITYNAME_EN": "Al-Makhsar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7607",
    "CITYNAME_AR": "حدبه العصمه",
    "CITYNAME_EN": "Hadbah-'Esma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7608",
    "CITYNAME_AR": "القفلات",
    "CITYNAME_EN": "Al-Qaflat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7609",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7610",
    "CITYNAME_AR": "ملقطه",
    "CITYNAME_EN": "Malkatah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7611",
    "CITYNAME_AR": "الفوارع",
    "CITYNAME_EN": "Al-Fuwari'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7612",
    "CITYNAME_AR": "صدغ الذيب",
    "CITYNAME_EN": "Sadgh Al-Zeeb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7613",
    "CITYNAME_AR": "شعبه ال صقران",
    "CITYNAME_EN": "Sh'Bah L- Saqran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7614",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Al-Hamatah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7615",
    "CITYNAME_AR": "جوف دريب",
    "CITYNAME_EN": "Jawf Dreeb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7616",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7617",
    "CITYNAME_AR": "الجرد",
    "CITYNAME_EN": "Al-Jard",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7618",
    "CITYNAME_AR": "قرن السد",
    "CITYNAME_EN": "Qarn Al-Sad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7619",
    "CITYNAME_AR": "المعطره",
    "CITYNAME_EN": "Al-Ma'Trah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7620",
    "CITYNAME_AR": "مسعوده",
    "CITYNAME_EN": "Mas'Odah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7621",
    "CITYNAME_AR": "الفيض",
    "CITYNAME_EN": "Al Faydh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7622",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Al-Rahah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7623",
    "CITYNAME_AR": "رحبه",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7624",
    "CITYNAME_AR": "الشاره",
    "CITYNAME_EN": "Al-Sharah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7625",
    "CITYNAME_AR": "المليسا",
    "CITYNAME_EN": "Al-Malesa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7626",
    "CITYNAME_AR": "الصدره",
    "CITYNAME_EN": "Al-Sadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7627",
    "CITYNAME_AR": "دحيمه",
    "CITYNAME_EN": "Dhema",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7628",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7629",
    "CITYNAME_AR": "الحسا (السكيبات)",
    "CITYNAME_EN": "Al-Hasa(Al-Sakebat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7630",
    "CITYNAME_AR": "الداهيه",
    "CITYNAME_EN": "Al-Dahiah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7631",
    "CITYNAME_AR": "حقو الرادية (بدوه)",
    "CITYNAME_EN": "Haqw Al-Radiah(Bdwah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7632",
    "CITYNAME_AR": "المشعر",
    "CITYNAME_EN": "Al-Mash'Ar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7633",
    "CITYNAME_AR": "المنقبه",
    "CITYNAME_EN": "Al-Monaqabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7634",
    "CITYNAME_AR": "شاطى عطيفه",
    "CITYNAME_EN": "Otayfah Peach ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7635",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'Bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7636",
    "CITYNAME_AR": "السند",
    "CITYNAME_EN": "Al-Sanad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7637",
    "CITYNAME_AR": "حدبه رقابه",
    "CITYNAME_EN": "Hadbah Rqabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7638",
    "CITYNAME_AR": "مرتاق",
    "CITYNAME_EN": "Mrtaaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7639",
    "CITYNAME_AR": "المحجه",
    "CITYNAME_EN": "Al-Mehjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7640",
    "CITYNAME_AR": "القعساء",
    "CITYNAME_EN": "Al-Qa'Saa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7641",
    "CITYNAME_AR": "الدماء",
    "CITYNAME_EN": "Al Demaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7642",
    "CITYNAME_AR": "الملح",
    "CITYNAME_EN": "Al-Melh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7643",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Al Darj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7644",
    "CITYNAME_AR": "الرده",
    "CITYNAME_EN": "Al Redah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7645",
    "CITYNAME_AR": "معشوقه",
    "CITYNAME_EN": "Ma'Shouq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7646",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'A",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7647",
    "CITYNAME_AR": "شعب الحنا",
    "CITYNAME_EN": "Sha'B Al-Hana",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7648",
    "CITYNAME_AR": "المثمل",
    "CITYNAME_EN": "Al-Mathml",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7649",
    "CITYNAME_AR": "الرافده",
    "CITYNAME_EN": "Al-Rafedah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7650",
    "CITYNAME_AR": "الحجنه",
    "CITYNAME_EN": "Al-Hajnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7651",
    "CITYNAME_AR": "القوفز (الجو)",
    "CITYNAME_EN": "Al-Qofez(Al-Jaw)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7652",
    "CITYNAME_AR": "راع المروه",
    "CITYNAME_EN": "Ra' Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7653",
    "CITYNAME_AR": "شعب الاسد (الزهوه)",
    "CITYNAME_EN": "Sha'B Al-Asad ( Al-Zahwah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7654",
    "CITYNAME_AR": "بين الجبلين",
    "CITYNAME_EN": "Bayn Al-Jabaleen",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7655",
    "CITYNAME_AR": "ال عاطف (اهل القناه)",
    "CITYNAME_EN": "Al-'Atef(Ahl Alqanah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7656",
    "CITYNAME_AR": "ال حيمه",
    "CITYNAME_EN": "Al Hemah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7657",
    "CITYNAME_AR": "ال عريف",
    "CITYNAME_EN": "Al 'Reef",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7658",
    "CITYNAME_AR": "ال سفل الديره",
    "CITYNAME_EN": "Al Safl Al-Dedah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7659",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7660",
    "CITYNAME_AR": "باطن العيد",
    "CITYNAME_EN": "Baten Al-'Ed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7661",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7662",
    "CITYNAME_AR": "النصيله",
    "CITYNAME_EN": "Al-Naselah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7663",
    "CITYNAME_AR": "الرخماء",
    "CITYNAME_EN": "Al-Rakhmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7664",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sa'Ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7665",
    "CITYNAME_AR": "الفج",
    "CITYNAME_EN": "Al-Faj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7666",
    "CITYNAME_AR": "ام الرجال",
    "CITYNAME_EN": "Umm Al-Rejal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7667",
    "CITYNAME_AR": "الاحسر",
    "CITYNAME_EN": "Al-Ahser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7668",
    "CITYNAME_AR": "الدويمه",
    "CITYNAME_EN": "Al-Duemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7669",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Al Darj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7670",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al-Oqdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7671",
    "CITYNAME_AR": "متلول",
    "CITYNAME_EN": "Matlul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7672",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7673",
    "CITYNAME_AR": "الجماء",
    "CITYNAME_EN": "Al-Jmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7674",
    "CITYNAME_AR": "شوطان",
    "CITYNAME_EN": "Shutan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7675",
    "CITYNAME_AR": "البدله",
    "CITYNAME_EN": "Al-Badlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7676",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7677",
    "CITYNAME_AR": "ال عشه",
    "CITYNAME_EN": "Al Oshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7678",
    "CITYNAME_AR": "ال جعرود",
    "CITYNAME_EN": "Al Ja'Rud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7679",
    "CITYNAME_AR": "السقيفه",
    "CITYNAME_EN": "Al-Saqifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7680",
    "CITYNAME_AR": "ال شويه",
    "CITYNAME_EN": "Al Shewayyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7681",
    "CITYNAME_AR": "العيباء",
    "CITYNAME_EN": "Al-Ibaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7682",
    "CITYNAME_AR": "ال قليل",
    "CITYNAME_EN": "Al Qalil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7683",
    "CITYNAME_AR": "فرعه ريمان",
    "CITYNAME_EN": "Far'Ah Riman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7684",
    "CITYNAME_AR": "الموقعه",
    "CITYNAME_EN": "Al-Mawqi'Ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7685",
    "CITYNAME_AR": "السحاريات",
    "CITYNAME_EN": "Al-Sahariyat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7686",
    "CITYNAME_AR": "الحشاه (ال مروح)",
    "CITYNAME_EN": "Al-Hshah (Al Maruh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7687",
    "CITYNAME_AR": "ال غنيه",
    "CITYNAME_EN": "Al Ghaniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7688",
    "CITYNAME_AR": "مودان",
    "CITYNAME_EN": "Mudan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7689",
    "CITYNAME_AR": "ال قرين",
    "CITYNAME_EN": "Al Qarin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7690",
    "CITYNAME_AR": "ال عطف",
    "CITYNAME_EN": "Al Atf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7691",
    "CITYNAME_AR": "الظهرة (ظهرة ختبة)",
    "CITYNAME_EN": "Al-Zuhrah (Zuhrah Khatbah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7692",
    "CITYNAME_AR": "ال مشنى",
    "CITYNAME_EN": "Al Meshna",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7693",
    "CITYNAME_AR": "المحمه",
    "CITYNAME_EN": "Al-Mehmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7694",
    "CITYNAME_AR": "الحشاتين",
    "CITYNAME_EN": "Al-Hashatin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7695",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al-Hesn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7696",
    "CITYNAME_AR": "العاتق",
    "CITYNAME_EN": "Al-Atiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7697",
    "CITYNAME_AR": "ال فريه",
    "CITYNAME_EN": "Al Feryah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7698",
    "CITYNAME_AR": "وينه",
    "CITYNAME_EN": "Weinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7699",
    "CITYNAME_AR": "الغراء",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7700",
    "CITYNAME_AR": "السحيره",
    "CITYNAME_EN": "Al-Sahirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7701",
    "CITYNAME_AR": "الكوله",
    "CITYNAME_EN": "Al-Kulah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7702",
    "CITYNAME_AR": "ذا العيان",
    "CITYNAME_EN": "Za Al-Ayan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7703",
    "CITYNAME_AR": "التوامه",
    "CITYNAME_EN": "Al Tuwamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7704",
    "CITYNAME_AR": "الشطان",
    "CITYNAME_EN": "Al-Shattan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7705",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7706",
    "CITYNAME_AR": "الوسط",
    "CITYNAME_EN": "Al Wasat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7707",
    "CITYNAME_AR": "المحضر",
    "CITYNAME_EN": "Almuhdar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7708",
    "CITYNAME_AR": "الرحبتين",
    "CITYNAME_EN": "Alruhbatayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7709",
    "CITYNAME_AR": "الحدبه (حدبه ال غنيه)",
    "CITYNAME_EN": "Alhadabuh (Hdibuh Al Ghaniha)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7710",
    "CITYNAME_AR": "الكاربه",
    "CITYNAME_EN": "Alkaribuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7711",
    "CITYNAME_AR": "الملسن",
    "CITYNAME_EN": "Almilsun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7712",
    "CITYNAME_AR": "شعب سعدان",
    "CITYNAME_EN": "Shaeb Suedan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7713",
    "CITYNAME_AR": "ال  جغاغه",
    "CITYNAME_EN": "Al Jaghaghh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7714",
    "CITYNAME_AR": "الشطوط",
    "CITYNAME_EN": "Alshutut",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7715",
    "CITYNAME_AR": "بياضه",
    "CITYNAME_EN": "Biadih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7716",
    "CITYNAME_AR": "العامره",
    "CITYNAME_EN": "Aleamiruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7717",
    "CITYNAME_AR": "عباده",
    "CITYNAME_EN": "Eibadih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7718",
    "CITYNAME_AR": "الشعراء",
    "CITYNAME_EN": "Alshueara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7719",
    "CITYNAME_AR": "الرهاوين",
    "CITYNAME_EN": "Alrahawin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7720",
    "CITYNAME_AR": "المراض",
    "CITYNAME_EN": "Almarad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7721",
    "CITYNAME_AR": "المربد",
    "CITYNAME_EN": "Almarabad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7722",
    "CITYNAME_AR": "القناع",
    "CITYNAME_EN": "Alqinae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7723",
    "CITYNAME_AR": "رهو السياله",
    "CITYNAME_EN": "Rahu Alsiyaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7724",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Alhumuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7725",
    "CITYNAME_AR": "شعب جابر",
    "CITYNAME_EN": "Shaeb Jabir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7726",
    "CITYNAME_AR": "قرن الرفيص",
    "CITYNAME_EN": "Qarn Alrafis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7727",
    "CITYNAME_AR": "الوقرين",
    "CITYNAME_EN": "Alwaqrayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7728",
    "CITYNAME_AR": "سميعة",
    "CITYNAME_EN": "Samiea",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7729",
    "CITYNAME_AR": "آل سعد",
    "CITYNAME_EN": "Al Saed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7730",
    "CITYNAME_AR": "ال باكر",
    "CITYNAME_EN": "Al Bakur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7731",
    "CITYNAME_AR": "ال طيران",
    "CITYNAME_EN": "Al Tayaran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7732",
    "CITYNAME_AR": "الحرشاء",
    "CITYNAME_EN": "Alharsha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7733",
    "CITYNAME_AR": "ال سعيدة",
    "CITYNAME_EN": "Al Saeida",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7734",
    "CITYNAME_AR": "الظهران",
    "CITYNAME_EN": "Alzahran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7735",
    "CITYNAME_AR": "القصباء",
    "CITYNAME_EN": "Alqusaba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7736",
    "CITYNAME_AR": "الرهوة",
    "CITYNAME_EN": "Alrahua",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7737",
    "CITYNAME_AR": "مودان جبل ريمان",
    "CITYNAME_EN": "Mudan Jabal Ryman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7738",
    "CITYNAME_AR": "ال حسني",
    "CITYNAME_EN": "Al Husni",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7739",
    "CITYNAME_AR": "الدخله",
    "CITYNAME_EN": "Aldakhluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7740",
    "CITYNAME_AR": "ال مبارك",
    "CITYNAME_EN": "Al Mubarak",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7741",
    "CITYNAME_AR": "ال الشميلة",
    "CITYNAME_EN": "Al Alshmyla",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7742",
    "CITYNAME_AR": "ال كامله",
    "CITYNAME_EN": "Al Kamiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7743",
    "CITYNAME_AR": "القرا(ال ياس)",
    "CITYNAME_EN": "Alqira(Al Yas)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7744",
    "CITYNAME_AR": "حبوه",
    "CITYNAME_EN": "Hubuwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7745",
    "CITYNAME_AR": "ال حراش",
    "CITYNAME_EN": "Al Hirash",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7746",
    "CITYNAME_AR": "ال حجاج",
    "CITYNAME_EN": "Al Hujaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7747",
    "CITYNAME_AR": "القاريه",
    "CITYNAME_EN": "Alqarih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7748",
    "CITYNAME_AR": "الفوقاء",
    "CITYNAME_EN": "Alfawaqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7749",
    "CITYNAME_AR": "ذا مصاب",
    "CITYNAME_EN": "Dha Musab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7750",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Aljuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7751",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Alhusan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7752",
    "CITYNAME_AR": "قشبة",
    "CITYNAME_EN": "Qashaba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7753",
    "CITYNAME_AR": "الحروف",
    "CITYNAME_EN": "Alhuruf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7754",
    "CITYNAME_AR": "الشاطى",
    "CITYNAME_EN": "Alshshataa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7755",
    "CITYNAME_AR": "الحقنتين",
    "CITYNAME_EN": "Alhuqnatayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7756",
    "CITYNAME_AR": "شط مفتاح",
    "CITYNAME_EN": "Shata Miftah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7757",
    "CITYNAME_AR": "ذا النمله",
    "CITYNAME_EN": "Dha Alnamaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7758",
    "CITYNAME_AR": "الرهوين",
    "CITYNAME_EN": "Alrahwyn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7759",
    "CITYNAME_AR": "ال سلمان(الحبشي)",
    "CITYNAME_EN": "Al Sulmani(Alhabshi)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7760",
    "CITYNAME_AR": "ال قعوان",
    "CITYNAME_EN": "Al Qaewan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7761",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7762",
    "CITYNAME_AR": "ال عزيز",
    "CITYNAME_EN": "Al Eaziz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7763",
    "CITYNAME_AR": "شط خشعه",
    "CITYNAME_EN": "Shati Khasheah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7764",
    "CITYNAME_AR": "ال شيعه",
    "CITYNAME_EN": "Al Shieuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7765",
    "CITYNAME_AR": "ال هادى",
    "CITYNAME_EN": "Al Hadaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7766",
    "CITYNAME_AR": "ال فرزان",
    "CITYNAME_EN": "Al Farzan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7767",
    "CITYNAME_AR": "ال عطيفه",
    "CITYNAME_EN": "Al Eatifuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7768",
    "CITYNAME_AR": "ال عطفه",
    "CITYNAME_EN": "Al Eitfih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7769",
    "CITYNAME_AR": "البخيت",
    "CITYNAME_EN": "Albakhit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7770",
    "CITYNAME_AR": "المظروف",
    "CITYNAME_EN": "Almazruf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7771",
    "CITYNAME_AR": "ال غازى",
    "CITYNAME_EN": "Al Ghazaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7772",
    "CITYNAME_AR": "ال لشعر",
    "CITYNAME_EN": "Al Lishaer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7773",
    "CITYNAME_AR": "ال حسكه",
    "CITYNAME_EN": "Al Haskuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7774",
    "CITYNAME_AR": "ال دريمه",
    "CITYNAME_EN": "Al Darimuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7775",
    "CITYNAME_AR": "خاط",
    "CITYNAME_EN": "Khat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7776",
    "CITYNAME_AR": "كتفه",
    "CITYNAME_EN": "Katfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7777",
    "CITYNAME_AR": "المتنه",
    "CITYNAME_EN": "Al Matnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "7778",
    "CITYNAME_AR": "السقايف",
    "CITYNAME_EN": "As-Saqayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "7779",
    "CITYNAME_AR": "القصيبه",
    "CITYNAME_EN": "Al Qusaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "7780",
    "CITYNAME_AR": "الشعبات",
    "CITYNAME_EN": "Al-Shi'bat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7781",
    "CITYNAME_AR": "المقراه",
    "CITYNAME_EN": "Al-Meqrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7782",
    "CITYNAME_AR": "الشاميه",
    "CITYNAME_EN": "Ash Shamiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7783",
    "CITYNAME_AR": "شعيب ال حسن",
    "CITYNAME_EN": "Sho'ayb Al Hassan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7784",
    "CITYNAME_AR": "الجوفاء",
    "CITYNAME_EN": "Al-Jawfaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7785",
    "CITYNAME_AR": "شعيب عمر",
    "CITYNAME_EN": "Sho'Eeb Omar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7786",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7787",
    "CITYNAME_AR": "السبطل",
    "CITYNAME_EN": "Al-Sabtal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7788",
    "CITYNAME_AR": "الشقرا",
    "CITYNAME_EN": "Al-Shaqra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7789",
    "CITYNAME_AR": "ملقطه",
    "CITYNAME_EN": "Malkatah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7790",
    "CITYNAME_AR": "دلوه",
    "CITYNAME_EN": "Dalwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7791",
    "CITYNAME_AR": "كلبان",
    "CITYNAME_EN": "Kalban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7792",
    "CITYNAME_AR": "البلقعه",
    "CITYNAME_EN": "Al-Balqa'Ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7793",
    "CITYNAME_AR": "السحليله",
    "CITYNAME_EN": "Al-Sahlelah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7794",
    "CITYNAME_AR": "مغوار",
    "CITYNAME_EN": "Meghwar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7795",
    "CITYNAME_AR": "طلاع",
    "CITYNAME_EN": "Tala'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7796",
    "CITYNAME_AR": "المبره",
    "CITYNAME_EN": "Al-Mabarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7797",
    "CITYNAME_AR": "بسن",
    "CITYNAME_EN": "Bsn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7798",
    "CITYNAME_AR": "الشاره",
    "CITYNAME_EN": "Al-Sharah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7799",
    "CITYNAME_AR": "القطبه",
    "CITYNAME_EN": "Al-Qatbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7800",
    "CITYNAME_AR": "عثر",
    "CITYNAME_EN": "'Athar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7801",
    "CITYNAME_AR": "الحناء",
    "CITYNAME_EN": "Al-Hanaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7802",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7803",
    "CITYNAME_AR": "القطيبة",
    "CITYNAME_EN": "Al-Qatbiah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7804",
    "CITYNAME_AR": "عيينه",
    "CITYNAME_EN": "'Eenah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7805",
    "CITYNAME_AR": "المقاطر",
    "CITYNAME_EN": "Al-Maqater",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7806",
    "CITYNAME_AR": "الطرقه",
    "CITYNAME_EN": "Al Tarqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7807",
    "CITYNAME_AR": "بطح الاثر",
    "CITYNAME_EN": "Batah Al-Athar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7808",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7809",
    "CITYNAME_AR": "الرينه",
    "CITYNAME_EN": "Al-Ryanah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7810",
    "CITYNAME_AR": "الكاربه",
    "CITYNAME_EN": "Al-Karbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7811",
    "CITYNAME_AR": "حرف لبراه",
    "CITYNAME_EN": "Harf Lbrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7812",
    "CITYNAME_AR": "ذمل",
    "CITYNAME_EN": "Zml",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7813",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Al Darj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7814",
    "CITYNAME_AR": "الهبطه",
    "CITYNAME_EN": "Al-Habtah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7815",
    "CITYNAME_AR": "الغليفاء",
    "CITYNAME_EN": "Al-Ghalifaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7816",
    "CITYNAME_AR": "الداهنه",
    "CITYNAME_EN": "Al-Dahnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7817",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7818",
    "CITYNAME_AR": "جميل",
    "CITYNAME_EN": " Jamil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7819",
    "CITYNAME_AR": "الدخله",
    "CITYNAME_EN": "Al-Dakhlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7820",
    "CITYNAME_AR": "النبله",
    "CITYNAME_EN": "Al-Nablah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7821",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Al-Dabr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7822",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Al-Gellah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7823",
    "CITYNAME_AR": "حضن القلالات",
    "CITYNAME_EN": "Hodn Al-Qolalat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7824",
    "CITYNAME_AR": "حضن حديد (حله)",
    "CITYNAME_EN": "Hodn Hadeed(Helah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7825",
    "CITYNAME_AR": "كروان",
    "CITYNAME_EN": "Karawan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7826",
    "CITYNAME_AR": "وادى مذبحن",
    "CITYNAME_EN": "Wadi Mezbahn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7827",
    "CITYNAME_AR": "حيد عبس",
    "CITYNAME_EN": "Heid Abs ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7828",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sa'Ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7829",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Sa'Ban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7830",
    "CITYNAME_AR": "ظهير",
    "CITYNAME_EN": "Zahir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7831",
    "CITYNAME_AR": "القيسه",
    "CITYNAME_EN": "Al-Qesah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7832",
    "CITYNAME_AR": "المهري",
    "CITYNAME_EN": "Al-Mahry ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7833",
    "CITYNAME_AR": "آل شواف",
    "CITYNAME_EN": "Al Shawaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7834",
    "CITYNAME_AR": "بنى غزوان",
    "CITYNAME_EN": "Bni Ghazwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7835",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Al-Khaimah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7836",
    "CITYNAME_AR": "مركب",
    "CITYNAME_EN": "Markeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7837",
    "CITYNAME_AR": "الحدبه (حدبه عساف)",
    "CITYNAME_EN": "Al-Hadbah (Hadbah Esaf)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7838",
    "CITYNAME_AR": "الفقهاء (لحمار)",
    "CITYNAME_EN": "Al-Foqahaa (Lahmar)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7839",
    "CITYNAME_AR": "آل عمار",
    "CITYNAME_EN": "Al Ammar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7840",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Mashrafah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7841",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al-Khatwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7842",
    "CITYNAME_AR": "حقو الصياب",
    "CITYNAME_EN": "Haqw Al-Sayab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7843",
    "CITYNAME_AR": "قرن الضباح",
    "CITYNAME_EN": "Qarn Al-Debah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7844",
    "CITYNAME_AR": "قرن شملان",
    "CITYNAME_EN": "Qarn Shamlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7845",
    "CITYNAME_AR": "القري",
    "CITYNAME_EN": "Al-Qari",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7846",
    "CITYNAME_AR": "صادعه",
    "CITYNAME_EN": "Sad'Ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7847",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "Al-Salam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7848",
    "CITYNAME_AR": "خيره",
    "CITYNAME_EN": "Khirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7849",
    "CITYNAME_AR": "الطحله",
    "CITYNAME_EN": "Al Tahlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7850",
    "CITYNAME_AR": "الوشعه",
    "CITYNAME_EN": "Al-Wash'Ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7851",
    "CITYNAME_AR": "السرب",
    "CITYNAME_EN": "Al-Serb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7852",
    "CITYNAME_AR": "المسيد",
    "CITYNAME_EN": "Al-Masid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7853",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7854",
    "CITYNAME_AR": "الحدبات",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7855",
    "CITYNAME_AR": "مقرن الربض",
    "CITYNAME_EN": "Meqren Al-Rid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7856",
    "CITYNAME_AR": "الذفراء",
    "CITYNAME_EN": "Al-Zafraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7857",
    "CITYNAME_AR": "الدخول",
    "CITYNAME_EN": "Al-Dakhul",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7858",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7859",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7860",
    "CITYNAME_AR": "الحرده",
    "CITYNAME_EN": "Al-Hardah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7861",
    "CITYNAME_AR": "الميازه والملحان",
    "CITYNAME_EN": "Al-Mayazah And Al-Malhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7862",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Al Bidha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7863",
    "CITYNAME_AR": "الأحد",
    "CITYNAME_EN": "Al-Ahad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7864",
    "CITYNAME_AR": "العين الحاره",
    "CITYNAME_EN": "Al-Ain Al-Harah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7865",
    "CITYNAME_AR": "وادى شحدن",
    "CITYNAME_EN": "Wadi Shehdin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7866",
    "CITYNAME_AR": "الكسره",
    "CITYNAME_EN": "Al-Kesrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7867",
    "CITYNAME_AR": "العصيره",
    "CITYNAME_EN": "Al-Asera",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7868",
    "CITYNAME_AR": "حدبه الحاج",
    "CITYNAME_EN": "Hadbah Al-Haj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7869",
    "CITYNAME_AR": "القيذه",
    "CITYNAME_EN": "Al-Qezah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7870",
    "CITYNAME_AR": "العضبه والواسعه",
    "CITYNAME_EN": "Al-Adbah And Al-Was'Ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7871",
    "CITYNAME_AR": "شرفه عشار",
    "CITYNAME_EN": "Shorfah Eshar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7872",
    "CITYNAME_AR": "خضيره",
    "CITYNAME_EN": "Khadirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7873",
    "CITYNAME_AR": "شعب ال مرعي",
    "CITYNAME_EN": "Shi'B Al Mar'Y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7874",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Al-Dayqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7875",
    "CITYNAME_AR": "الصرير",
    "CITYNAME_EN": "Al-Sarir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7876",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Al-Dars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7877",
    "CITYNAME_AR": "شعب خريب",
    "CITYNAME_EN": "She'B Kharib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7878",
    "CITYNAME_AR": "شعب حسين",
    "CITYNAME_EN": "She'B Hussein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7879",
    "CITYNAME_AR": "وادى ابو عشره",
    "CITYNAME_EN": "Wadi Abu Ashrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7880",
    "CITYNAME_AR": "جراده",
    "CITYNAME_EN": "Al Jaradah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7881",
    "CITYNAME_AR": "القلاله",
    "CITYNAME_EN": "Al-Qalalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7882",
    "CITYNAME_AR": "مصقعه ابو عشره",
    "CITYNAME_EN": "Mesqa'Ah Abu Ashrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7883",
    "CITYNAME_AR": "العاقر",
    "CITYNAME_EN": "Al-Aqer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7884",
    "CITYNAME_AR": "النقبه الغربيه",
    "CITYNAME_EN": "Al-Naqbah (The Western)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7885",
    "CITYNAME_AR": "حفصن",
    "CITYNAME_EN": "Hafsan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7886",
    "CITYNAME_AR": "الباب",
    "CITYNAME_EN": "Chapter",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7887",
    "CITYNAME_AR": "النقبه الشرقيه",
    "CITYNAME_EN": "Al-Naqbah (The Eastern)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7888",
    "CITYNAME_AR": "الشق",
    "CITYNAME_EN": "Al-Shiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7889",
    "CITYNAME_AR": "حداب القرى",
    "CITYNAME_EN": "Hodab Al-Qary ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7890",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al-Oqdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7891",
    "CITYNAME_AR": "الفليق",
    "CITYNAME_EN": "Al-Faliq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7892",
    "CITYNAME_AR": "الهجعه",
    "CITYNAME_EN": "Al Haj'Ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7893",
    "CITYNAME_AR": "الحداب",
    "CITYNAME_EN": "Al Hodab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7894",
    "CITYNAME_AR": "الخرار",
    "CITYNAME_EN": "Al-Khrar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7895",
    "CITYNAME_AR": "البطحاء",
    "CITYNAME_EN": "Al Bathaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7896",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7897",
    "CITYNAME_AR": "حقو الحرشاء",
    "CITYNAME_EN": "Haqw Al-Harshaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7898",
    "CITYNAME_AR": "قلها",
    "CITYNAME_EN": "Qalha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7899",
    "CITYNAME_AR": "القعمه",
    "CITYNAME_EN": "Al-Qa'Mah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7900",
    "CITYNAME_AR": "شعب الظفير",
    "CITYNAME_EN": "She'B Al-Zafir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7901",
    "CITYNAME_AR": "مغلوث",
    "CITYNAME_EN": "Maghluth ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7902",
    "CITYNAME_AR": "صفاء",
    "CITYNAME_EN": "Sefaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7903",
    "CITYNAME_AR": "المنداه",
    "CITYNAME_EN": "Al-Mandah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7904",
    "CITYNAME_AR": "فرواع",
    "CITYNAME_EN": "Ferwa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7905",
    "CITYNAME_AR": "الدبر",
    "CITYNAME_EN": "Al Dobr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7906",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Mazmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7907",
    "CITYNAME_AR": "قعمه ال خليف",
    "CITYNAME_EN": "Qa'Mah Al Khalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7908",
    "CITYNAME_AR": "السطه",
    "CITYNAME_EN": "Al-Settah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7909",
    "CITYNAME_AR": "الكري",
    "CITYNAME_EN": "Al Kary",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7910",
    "CITYNAME_AR": "سلمه",
    "CITYNAME_EN": "Salmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7911",
    "CITYNAME_AR": "اللوي",
    "CITYNAME_EN": "Alleweh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7912",
    "CITYNAME_AR": "خليعه",
    "CITYNAME_EN": "Khali'Ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7913",
    "CITYNAME_AR": "شيبان",
    "CITYNAME_EN": "Shiban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7914",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Mazmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7915",
    "CITYNAME_AR": "الزهيراء",
    "CITYNAME_EN": "Az Zuhairiyaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7916",
    "CITYNAME_AR": "الرحوب",
    "CITYNAME_EN": "Al Rahub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7917",
    "CITYNAME_AR": "الفجه",
    "CITYNAME_EN": "Al-Fajah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7918",
    "CITYNAME_AR": "العش",
    "CITYNAME_EN": "Al Ush",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7919",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7920",
    "CITYNAME_AR": "الحلف",
    "CITYNAME_EN": "Al-Helf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7921",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Al Fasilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7922",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7923",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7924",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Mazmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7925",
    "CITYNAME_AR": "الصدره",
    "CITYNAME_EN": "Al-Sadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7926",
    "CITYNAME_AR": "الحدق",
    "CITYNAME_EN": "Al Hedq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7927",
    "CITYNAME_AR": "هده",
    "CITYNAME_EN": "Hodah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7928",
    "CITYNAME_AR": "رهوه الزميتيه",
    "CITYNAME_EN": "Rahwah Al-Zamitah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7929",
    "CITYNAME_AR": "الصليت",
    "CITYNAME_EN": "Al-Salit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7930",
    "CITYNAME_AR": "شجنن",
    "CITYNAME_EN": "Shajnn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7931",
    "CITYNAME_AR": "الوهطه",
    "CITYNAME_EN": "Al-Wahtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7932",
    "CITYNAME_AR": "المشرف",
    "CITYNAME_EN": "Supervisor",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7933",
    "CITYNAME_AR": "صيوي",
    "CITYNAME_EN": "Siwa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7934",
    "CITYNAME_AR": "قنن",
    "CITYNAME_EN": "Qann",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7935",
    "CITYNAME_AR": "حدبه وادى مجوف",
    "CITYNAME_EN": "Hadbah Wadi Majuf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7936",
    "CITYNAME_AR": "الرهوات",
    "CITYNAME_EN": "Al-Rahwat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7937",
    "CITYNAME_AR": "مليحه",
    "CITYNAME_EN": "Maliha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7938",
    "CITYNAME_AR": "قنن",
    "CITYNAME_EN": "Qann",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7939",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7940",
    "CITYNAME_AR": "حطمن",
    "CITYNAME_EN": "Hatman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7941",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7942",
    "CITYNAME_AR": "شعب الحديد",
    "CITYNAME_EN": "She'B Al-Hadid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7943",
    "CITYNAME_AR": "حدبه المساكه",
    "CITYNAME_EN": "Hadabuh Almasakuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7944",
    "CITYNAME_AR": "المصانع",
    "CITYNAME_EN": "Almasanie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7977",
    "CITYNAME_AR": "هجرة الجيشيه",
    "CITYNAME_EN": "Hijrah Al Gaishiyah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "7978",
    "CITYNAME_AR": "الاطاوله",
    "CITYNAME_EN": "Al-Atawilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7979",
    "CITYNAME_AR": "القهاد",
    "CITYNAME_EN": "Al Qehad",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7980",
    "CITYNAME_AR": "الحسن",
    "CITYNAME_EN": "Al-Hasan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7981",
    "CITYNAME_AR": "بيوت عصيدان",
    "CITYNAME_EN": "Beyut Asidan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7982",
    "CITYNAME_AR": "ام المحال",
    "CITYNAME_EN": "Umm Al-Mehal ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7983",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7984",
    "CITYNAME_AR": "منضحه العليا",
    "CITYNAME_EN": "Mandaha (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7985",
    "CITYNAME_AR": "منضحه السفلى",
    "CITYNAME_EN": "Mandaha (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7986",
    "CITYNAME_AR": "الحمود",
    "CITYNAME_EN": "Al-Hamud ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7987",
    "CITYNAME_AR": "المحاطبه",
    "CITYNAME_EN": "Al-Mahatah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7988",
    "CITYNAME_AR": "الثراوين",
    "CITYNAME_EN": "Al-Tharawin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7989",
    "CITYNAME_AR": "القسمه",
    "CITYNAME_EN": "Al-Qesmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7990",
    "CITYNAME_AR": "الثويمات",
    "CITYNAME_EN": "Al-Thowaymat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7991",
    "CITYNAME_AR": "منحل الاعلى",
    "CITYNAME_EN": "Menhel (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7992",
    "CITYNAME_AR": "منحل الاسفل",
    "CITYNAME_EN": "Menhel (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7993",
    "CITYNAME_AR": "محويه",
    "CITYNAME_EN": "Mahwiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7994",
    "CITYNAME_AR": "العفيف",
    "CITYNAME_EN": "Al-Afif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7995",
    "CITYNAME_AR": "بنى محمد",
    "CITYNAME_EN": "Bni Mohamed ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7996",
    "CITYNAME_AR": "العديه",
    "CITYNAME_EN": "Al-Adiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7997",
    "CITYNAME_AR": "ماطوه",
    "CITYNAME_EN": "Matwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7998",
    "CITYNAME_AR": "الحميدان",
    "CITYNAME_EN": "Al-Homaydan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "7999",
    "CITYNAME_AR": "الحكمان",
    "CITYNAME_EN": "Al-Hakman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8000",
    "CITYNAME_AR": "الهدوان والقهبان",
    "CITYNAME_EN": "Al-Hadwan and Al-Qahban ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8001",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Malh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8002",
    "CITYNAME_AR": "الرهوتين",
    "CITYNAME_EN": "Al-Rahwatin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8003",
    "CITYNAME_AR": "القعصه",
    "CITYNAME_EN": "Al-Qa'sah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8004",
    "CITYNAME_AR": "الدكان",
    "CITYNAME_EN": "Al-Dokan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8005",
    "CITYNAME_AR": "المكاتيم",
    "CITYNAME_EN": "Al-Makatim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8006",
    "CITYNAME_AR": "العقله",
    "CITYNAME_EN": "Al-Aqlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8007",
    "CITYNAME_AR": "ال سرور",
    "CITYNAME_EN": "Al Sorour",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8008",
    "CITYNAME_AR": "الزير",
    "CITYNAME_EN": "Al Zeir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8009",
    "CITYNAME_AR": "جعاله",
    "CITYNAME_EN": "Ja'alah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8010",
    "CITYNAME_AR": "القوارير",
    "CITYNAME_EN": "Al-Qawarir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8011",
    "CITYNAME_AR": "ملف القوارير",
    "CITYNAME_EN": "Malf Al-Qawarir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8012",
    "CITYNAME_AR": "ال سلمان",
    "CITYNAME_EN": "Al-Salman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8013",
    "CITYNAME_AR": "المعزه",
    "CITYNAME_EN": "Al-Ma'zah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8014",
    "CITYNAME_AR": "الاشتاء",
    "CITYNAME_EN": "Al-Ashtaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8015",
    "CITYNAME_AR": "الوهده",
    "CITYNAME_EN": "Al Wahdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8016",
    "CITYNAME_AR": "القامره",
    "CITYNAME_EN": "Al-Qamrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8017",
    "CITYNAME_AR": "الشطه",
    "CITYNAME_EN": "Al-Shattah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8018",
    "CITYNAME_AR": "حظى",
    "CITYNAME_EN": "Hazy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8019",
    "CITYNAME_AR": "المداهره",
    "CITYNAME_EN": "Al-Madahrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8020",
    "CITYNAME_AR": "اليمنه",
    "CITYNAME_EN": "Al Yamnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8021",
    "CITYNAME_AR": "الشمله",
    "CITYNAME_EN": "Al-Shamlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8022",
    "CITYNAME_AR": "ال بسيس",
    "CITYNAME_EN": "Al Basis ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8023",
    "CITYNAME_AR": "العباريه",
    "CITYNAME_EN": "Al-Abariyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8024",
    "CITYNAME_AR": "القعود",
    "CITYNAME_EN": "Al-Qa'ud",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8025",
    "CITYNAME_AR": "الكلبه",
    "CITYNAME_EN": "Al Kalbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8026",
    "CITYNAME_AR": "حازم الكلبه",
    "CITYNAME_EN": "Hazim Al-Kalbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8027",
    "CITYNAME_AR": "الجريره",
    "CITYNAME_EN": "Al-Jarirah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8028",
    "CITYNAME_AR": "الضحوات",
    "CITYNAME_EN": "Al-Dahwat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8029",
    "CITYNAME_AR": "الكرادسه",
    "CITYNAME_EN": "Al-Kradsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8030",
    "CITYNAME_AR": "دار الوادى",
    "CITYNAME_EN": "Dar Al-Wadi ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8031",
    "CITYNAME_AR": "الشعبه العليا",
    "CITYNAME_EN": "Al-She'bah (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8032",
    "CITYNAME_AR": "الشعبه السفلى",
    "CITYNAME_EN": "Al-She'bah (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8033",
    "CITYNAME_AR": "بيت على صالح المالكي",
    "CITYNAME_EN": "Ali Saleh Al-Malky House ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8034",
    "CITYNAME_AR": "القعره بني عدوان",
    "CITYNAME_EN": "Al-Qa'rah bni Edwan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8035",
    "CITYNAME_AR": "سبيحه السفلى",
    "CITYNAME_EN": "Sabihah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8036",
    "CITYNAME_AR": "سبيحه العليا",
    "CITYNAME_EN": "Sabiha (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8037",
    "CITYNAME_AR": "الصعدان",
    "CITYNAME_EN": "Al-Sa'dan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8038",
    "CITYNAME_AR": "بيت ابن عازب (المضاحي)",
    "CITYNAME_EN": "Ibn Azeb House ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8039",
    "CITYNAME_AR": "القوابره",
    "CITYNAME_EN": "Al-Qawabrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8040",
    "CITYNAME_AR": "ال سعيدان",
    "CITYNAME_EN": "Al Su'aydan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8041",
    "CITYNAME_AR": "ال سعيدان الجنوبيه",
    "CITYNAME_EN": "Al Su'aydan (the southern)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8042",
    "CITYNAME_AR": "الحطاوره",
    "CITYNAME_EN": "Al-Hatawrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8043",
    "CITYNAME_AR": "الدعبه",
    "CITYNAME_EN": "Al-Da'bah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8044",
    "CITYNAME_AR": "المثيله",
    "CITYNAME_EN": "Al-Mathilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8045",
    "CITYNAME_AR": "المشارق",
    "CITYNAME_EN": "Al-Mashariq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8046",
    "CITYNAME_AR": "بيوت الاصقع",
    "CITYNAME_EN": "Al-Asqa' Houses ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8047",
    "CITYNAME_AR": "الدهامين",
    "CITYNAME_EN": "Al-Dahamin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8048",
    "CITYNAME_AR": "الحبشه",
    "CITYNAME_EN": "Al-Habshah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8049",
    "CITYNAME_AR": "ابوعريضه",
    "CITYNAME_EN": "Abu Aridah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8050",
    "CITYNAME_AR": "الريعه",
    "CITYNAME_EN": "Al-Rib'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8051",
    "CITYNAME_AR": "المروه الشماليه",
    "CITYNAME_EN": "Al-Marwah (the Northern)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8052",
    "CITYNAME_AR": "الفقار",
    "CITYNAME_EN": "Al Fiqar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8053",
    "CITYNAME_AR": "المروه الجنوبيه",
    "CITYNAME_EN": "Al-Marwah (the southern)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8054",
    "CITYNAME_AR": "ابن دوخى وابن ساعد",
    "CITYNAME_EN": "Ibn Dokha and Ibn Sa'id ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8055",
    "CITYNAME_AR": "القحمان",
    "CITYNAME_EN": "Al-Qahman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8056",
    "CITYNAME_AR": "خباب",
    "CITYNAME_EN": "Khbab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8057",
    "CITYNAME_AR": "ترم غل",
    "CITYNAME_EN": "Term Ghel ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8058",
    "CITYNAME_AR": "مهد عشره",
    "CITYNAME_EN": "Mehd Eshrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8059",
    "CITYNAME_AR": "النهضه",
    "CITYNAME_EN": "Al-Nahdah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8060",
    "CITYNAME_AR": "ابومدابغ",
    "CITYNAME_EN": "Abu Madabigh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8061",
    "CITYNAME_AR": "النباعه",
    "CITYNAME_EN": "Al-Nab'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8062",
    "CITYNAME_AR": "المخاضه العليا",
    "CITYNAME_EN": "Al-Makhadah (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8063",
    "CITYNAME_AR": "شرهان",
    "CITYNAME_EN": "Sherhan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8064",
    "CITYNAME_AR": "المخاضه السفلى",
    "CITYNAME_EN": "Al-Makhdah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8065",
    "CITYNAME_AR": "ابوصفيح",
    "CITYNAME_EN": "Abu Safih ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8066",
    "CITYNAME_AR": "الشعب الاعلى",
    "CITYNAME_EN": "Ak-She'b (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8067",
    "CITYNAME_AR": "البراقه",
    "CITYNAME_EN": "Al-Baraqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8068",
    "CITYNAME_AR": "ساق عامر",
    "CITYNAME_EN": "Saq Amer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8069",
    "CITYNAME_AR": "برمه",
    "CITYNAME_EN": "Bermah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8070",
    "CITYNAME_AR": "وادي تربه",
    "CITYNAME_EN": "Wadi Terbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8071",
    "CITYNAME_AR": "ثوله",
    "CITYNAME_EN": "Thulah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8072",
    "CITYNAME_AR": "رويعات قراد",
    "CITYNAME_EN": "Rowai'at Qerad ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8073",
    "CITYNAME_AR": "النتر",
    "CITYNAME_EN": "Al-Netr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8074",
    "CITYNAME_AR": "ظهى واستن",
    "CITYNAME_EN": "Zahy Wastin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8075",
    "CITYNAME_AR": "سيالة والسليم",
    "CITYNAME_EN": "Siaalat Walsalim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8076",
    "CITYNAME_AR": "ساحل",
    "CITYNAME_EN": "Sahil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8077",
    "CITYNAME_AR": "القلب",
    "CITYNAME_EN": "Alqalb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8078",
    "CITYNAME_AR": "مستور",
    "CITYNAME_EN": "Mustur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8079",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Alshaeb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8080",
    "CITYNAME_AR": "الخطيم",
    "CITYNAME_EN": "Alkhatim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8081",
    "CITYNAME_AR": "حدبه ال زهير (مفيف)",
    "CITYNAME_EN": "Hadbuh Al Zahir (Mfif)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8082",
    "CITYNAME_AR": "ال مصبح",
    "CITYNAME_EN": "Al Masbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8083",
    "CITYNAME_AR": "الخاده",
    "CITYNAME_EN": "Alkhaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8084",
    "CITYNAME_AR": "حابس",
    "CITYNAME_EN": "Habis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8085",
    "CITYNAME_AR": "النصباء",
    "CITYNAME_EN": "Alnusaba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8086",
    "CITYNAME_AR": "حدبه الاشراف",
    "CITYNAME_EN": "Hadabuh Al'Iishraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8087",
    "CITYNAME_AR": "الحقو (ال مناع)",
    "CITYNAME_EN": "Alhuquu (Al Manae)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8088",
    "CITYNAME_AR": "ام خبيان",
    "CITYNAME_EN": "Umm Khubian",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8089",
    "CITYNAME_AR": "الحصنين",
    "CITYNAME_EN": "Alhisinin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8090",
    "CITYNAME_AR": "الخرباء (الحوراء)",
    "CITYNAME_EN": "Alkhuraba' (Alhwara'A)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8091",
    "CITYNAME_AR": "المدمن (المدسن)",
    "CITYNAME_EN": "Almudamin (Almadasuna)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8092",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Alburqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8093",
    "CITYNAME_AR": "الجب",
    "CITYNAME_EN": "Aljubu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8094",
    "CITYNAME_AR": "بن عطيفه",
    "CITYNAME_EN": "Bin Eatifih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8095",
    "CITYNAME_AR": "الربده",
    "CITYNAME_EN": "Alrubaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8096",
    "CITYNAME_AR": "حباب",
    "CITYNAME_EN": "Habab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8097",
    "CITYNAME_AR": "عقاله",
    "CITYNAME_EN": "Eaqalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8098",
    "CITYNAME_AR": "بن سعيدان",
    "CITYNAME_EN": "Bin Saeidan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8099",
    "CITYNAME_AR": "العدن والقهباء",
    "CITYNAME_EN": "Aleadn Walquhaba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8100",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8101",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Alfare",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8102",
    "CITYNAME_AR": "مكداس",
    "CITYNAME_EN": "Mikdas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8103",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8104",
    "CITYNAME_AR": "محارير",
    "CITYNAME_EN": "Maharir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8105",
    "CITYNAME_AR": "ابو رخم",
    "CITYNAME_EN": "'Abu Rkhm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8106",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Alnusub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8107",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Almashabaah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8108",
    "CITYNAME_AR": "ام عسره",
    "CITYNAME_EN": "Umm Easruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8109",
    "CITYNAME_AR": "العيريه (المعيريه)",
    "CITYNAME_EN": "Aleiriuh (Almaeirih)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8110",
    "CITYNAME_AR": "ام خرقه",
    "CITYNAME_EN": "Umm Kharqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8111",
    "CITYNAME_AR": "المسمور",
    "CITYNAME_EN": "Almasmur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8112",
    "CITYNAME_AR": "شعب علوان",
    "CITYNAME_EN": "Shaeb Eilwan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8113",
    "CITYNAME_AR": "الرماده",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8114",
    "CITYNAME_AR": "القفيل",
    "CITYNAME_EN": "Alqafil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8115",
    "CITYNAME_AR": "العاينه",
    "CITYNAME_EN": "Aleayinuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8116",
    "CITYNAME_AR": "القسويه",
    "CITYNAME_EN": "Alqasawayh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8117",
    "CITYNAME_AR": "الميفاء",
    "CITYNAME_EN": "Almifa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8118",
    "CITYNAME_AR": "شعب عين",
    "CITYNAME_EN": "Shaeb Ein",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8119",
    "CITYNAME_AR": "بادى",
    "CITYNAME_EN": "Badaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8120",
    "CITYNAME_AR": "الجعله",
    "CITYNAME_EN": "Aljaealuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8121",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "Alsiru",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8122",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Alruhuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8123",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Almashabaah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8124",
    "CITYNAME_AR": "القابل",
    "CITYNAME_EN": "Alqabil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8125",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Alsharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8126",
    "CITYNAME_AR": "ريده",
    "CITYNAME_EN": "Rydah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8127",
    "CITYNAME_AR": "السوداء",
    "CITYNAME_EN": "Alsawda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8128",
    "CITYNAME_AR": "العنقاء",
    "CITYNAME_EN": "Aleanqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8129",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8130",
    "CITYNAME_AR": "المرتمض",
    "CITYNAME_EN": "Almurtamad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8131",
    "CITYNAME_AR": "مجرنه",
    "CITYNAME_EN": "Mujrinuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8132",
    "CITYNAME_AR": "مسعى",
    "CITYNAME_EN": "Maseaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8133",
    "CITYNAME_AR": "الباشه",
    "CITYNAME_EN": "Albashuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8134",
    "CITYNAME_AR": "الضبره (الحبيل)",
    "CITYNAME_EN": "Aldabruh (Alhabil)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8135",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Alfireuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8136",
    "CITYNAME_AR": "الكاربه",
    "CITYNAME_EN": "Alkaribuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8137",
    "CITYNAME_AR": "شعب الحلف",
    "CITYNAME_EN": "Shaeb Alhilf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8138",
    "CITYNAME_AR": "ابى العشر",
    "CITYNAME_EN": "Aba Aleashr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8139",
    "CITYNAME_AR": "مبطح",
    "CITYNAME_EN": "Mubtih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8140",
    "CITYNAME_AR": "الرص",
    "CITYNAME_EN": "Alrus",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8141",
    "CITYNAME_AR": "غضاريق",
    "CITYNAME_EN": "Ghadariq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8142",
    "CITYNAME_AR": "حدق",
    "CITYNAME_EN": "Hadaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8143",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "Alsuwd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8144",
    "CITYNAME_AR": "القريحاء",
    "CITYNAME_EN": "Alqariha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8145",
    "CITYNAME_AR": "الحدايا",
    "CITYNAME_EN": "Alhadaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8146",
    "CITYNAME_AR": "الاخدع",
    "CITYNAME_EN": "Alakhde",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8147",
    "CITYNAME_AR": "مريضه",
    "CITYNAME_EN": "Mariduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8148",
    "CITYNAME_AR": "الحباطه",
    "CITYNAME_EN": "Alhibatuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8149",
    "CITYNAME_AR": "الجماء والفطيمه",
    "CITYNAME_EN": "Aljama' Walfatimuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8150",
    "CITYNAME_AR": "الشجاء",
    "CITYNAME_EN": "Alshija'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8151",
    "CITYNAME_AR": "العسف",
    "CITYNAME_EN": "Aleusf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8152",
    "CITYNAME_AR": "فقعه",
    "CITYNAME_EN": "Faqaeah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8153",
    "CITYNAME_AR": "الحصنين",
    "CITYNAME_EN": "Alhisinin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8154",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Alzahruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8155",
    "CITYNAME_AR": "المبنى",
    "CITYNAME_EN": "Almabnaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8156",
    "CITYNAME_AR": "الشقيص",
    "CITYNAME_EN": "Alshaqis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8157",
    "CITYNAME_AR": "القلب",
    "CITYNAME_EN": "Alqalb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8158",
    "CITYNAME_AR": "الحشاء",
    "CITYNAME_EN": "Alhasha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8159",
    "CITYNAME_AR": "الرجع",
    "CITYNAME_EN": "Alraje",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8160",
    "CITYNAME_AR": "القلباء",
    "CITYNAME_EN": "Alqulba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8161",
    "CITYNAME_AR": "البشامه",
    "CITYNAME_EN": "Albashamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8162",
    "CITYNAME_AR": "الرهو (الحباب)",
    "CITYNAME_EN": "Alrahuu (Alhabab)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8163",
    "CITYNAME_AR": "المراره",
    "CITYNAME_EN": "Almararuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8164",
    "CITYNAME_AR": "القهباء",
    "CITYNAME_EN": "Alquhaba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8165",
    "CITYNAME_AR": "حدبه المراره",
    "CITYNAME_EN": "Hadabuh Almararuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8166",
    "CITYNAME_AR": "الواديين",
    "CITYNAME_EN": "Alwadiin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8167",
    "CITYNAME_AR": "حدبه السر",
    "CITYNAME_EN": "Hadabuh Alsiru",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8168",
    "CITYNAME_AR": "السده",
    "CITYNAME_EN": "Alsaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8169",
    "CITYNAME_AR": "سر ال عيشي",
    "CITYNAME_EN": "Siri Al Eishi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8170",
    "CITYNAME_AR": "حقو فقعه",
    "CITYNAME_EN": "Huqu Faqeih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8171",
    "CITYNAME_AR": "حوض نابى (حقونابي)",
    "CITYNAME_EN": "Hawd Nabaa (Hqunaby)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8172",
    "CITYNAME_AR": "الفراشه",
    "CITYNAME_EN": "Alfarashuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8173",
    "CITYNAME_AR": "الواتده",
    "CITYNAME_EN": "Alwatiduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8174",
    "CITYNAME_AR": "الثماله",
    "CITYNAME_EN": "Althamaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8175",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Alhamatuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8176",
    "CITYNAME_AR": "الزريبه",
    "CITYNAME_EN": "Alzaribuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8177",
    "CITYNAME_AR": "دلهم",
    "CITYNAME_EN": "Dalahum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8178",
    "CITYNAME_AR": "تريبان",
    "CITYNAME_EN": "Tariban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8179",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Alnusub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8180",
    "CITYNAME_AR": "ام شوكه",
    "CITYNAME_EN": "Umm Shawkh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8181",
    "CITYNAME_AR": "الردم",
    "CITYNAME_EN": "Alradm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8182",
    "CITYNAME_AR": "قفيل الفقهاء",
    "CITYNAME_EN": "Qfyl Alfuqaha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8183",
    "CITYNAME_AR": "الشاميه",
    "CITYNAME_EN": "Alshaamiyh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8184",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Alshifa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8185",
    "CITYNAME_AR": "الوجاء",
    "CITYNAME_EN": "Alwaja'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8186",
    "CITYNAME_AR": "الخرباء",
    "CITYNAME_EN": "Alkhuraba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8187",
    "CITYNAME_AR": "سوق الربوع",
    "CITYNAME_EN": "Alrubue Market",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8188",
    "CITYNAME_AR": "حدبه الساده",
    "CITYNAME_EN": "Hadabuh Alssaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8189",
    "CITYNAME_AR": "وادى ثعيب",
    "CITYNAME_EN": "Wa'Adaa Thaeib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8190",
    "CITYNAME_AR": "المحاثه",
    "CITYNAME_EN": "Almahatah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8191",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8192",
    "CITYNAME_AR": "حداب مشرف",
    "CITYNAME_EN": "Hadab Musharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8193",
    "CITYNAME_AR": "عاطف",
    "CITYNAME_EN": "Eatif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8194",
    "CITYNAME_AR": "حقو العبيد والشرف",
    "CITYNAME_EN": "Haqu Aleubayd Walsharf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8195",
    "CITYNAME_AR": "وادى بن مقيه",
    "CITYNAME_EN": "Wa'Adaa Bin Maqih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8196",
    "CITYNAME_AR": "حدبه الحفنه",
    "CITYNAME_EN": "Hadabuh Alhafnuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8197",
    "CITYNAME_AR": "مروح",
    "CITYNAME_EN": "Muruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8198",
    "CITYNAME_AR": "حقو بن جوده",
    "CITYNAME_EN": "Huqu Bin Judah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8199",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8200",
    "CITYNAME_AR": "قرن سحر",
    "CITYNAME_EN": "Qarn Sihr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8201",
    "CITYNAME_AR": "الطارفه",
    "CITYNAME_EN": "Alttarifuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8202",
    "CITYNAME_AR": "المخصر (شعب عويد)",
    "CITYNAME_EN": "Almukhasir (Sheab Euayd)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8203",
    "CITYNAME_AR": "المجمعه ( الجماعه)",
    "CITYNAME_EN": "Almujmaeuh ( Aljimaeuh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8204",
    "CITYNAME_AR": "مكهاف",
    "CITYNAME_EN": "Mukahaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8205",
    "CITYNAME_AR": "القويد",
    "CITYNAME_EN": "Alqawayd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8206",
    "CITYNAME_AR": "الجربوع",
    "CITYNAME_EN": "Aljarbue",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8207",
    "CITYNAME_AR": "شعب عضاهى",
    "CITYNAME_EN": "Shaeb Eadahaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8208",
    "CITYNAME_AR": "ال مــروان",
    "CITYNAME_EN": "Al Marawan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8209",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8210",
    "CITYNAME_AR": "خرزات ( حقو متروى)",
    "CITYNAME_EN": "Kharazat ( Haquun Mutarawaa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8211",
    "CITYNAME_AR": "القهباء",
    "CITYNAME_EN": "Alquhaba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8212",
    "CITYNAME_AR": "المريبعه",
    "CITYNAME_EN": "Almuribieuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8213",
    "CITYNAME_AR": "القوز (قنتي)",
    "CITYNAME_EN": "Alqawz (Qnti)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8214",
    "CITYNAME_AR": "المدوره",
    "CITYNAME_EN": "Almudawruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8215",
    "CITYNAME_AR": "الفايج",
    "CITYNAME_EN": "Alfayij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8216",
    "CITYNAME_AR": "القظيف",
    "CITYNAME_EN": "Alqazif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8217",
    "CITYNAME_AR": "الغيوار",
    "CITYNAME_EN": "Alghaywar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8218",
    "CITYNAME_AR": "الكديفه",
    "CITYNAME_EN": "Alkadifuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8219",
    "CITYNAME_AR": "الخرباء",
    "CITYNAME_EN": "Alkhuraba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8220",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Alhuquw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8221",
    "CITYNAME_AR": "الخريقين",
    "CITYNAME_EN": "Alkhariqayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8222",
    "CITYNAME_AR": "قرن ال شعفى",
    "CITYNAME_EN": "Qarn Al Shaefaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8223",
    "CITYNAME_AR": "الخماصيه",
    "CITYNAME_EN": "Alkhimasih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8224",
    "CITYNAME_AR": "قرن الصاب",
    "CITYNAME_EN": "Qarn Alsaabu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8225",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8226",
    "CITYNAME_AR": "قرن ابو سن",
    "CITYNAME_EN": "Qarn 'Abu Sini",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8227",
    "CITYNAME_AR": "قرن مخلد",
    "CITYNAME_EN": "Qarn Mukhalad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8228",
    "CITYNAME_AR": "سر الخشب",
    "CITYNAME_EN": "Siri Alkhashb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8229",
    "CITYNAME_AR": "الحمض",
    "CITYNAME_EN": "Alhamd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8230",
    "CITYNAME_AR": "كندى والاثاني",
    "CITYNAME_EN": "Kandaa Walathany",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8231",
    "CITYNAME_AR": "البيداء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8232",
    "CITYNAME_AR": "القرص",
    "CITYNAME_EN": "Alqurs",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8233",
    "CITYNAME_AR": "المزبوره",
    "CITYNAME_EN": "Almazburuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8234",
    "CITYNAME_AR": "الحداب ( عنيكر)",
    "CITYNAME_EN": "Alhadab ( Eanikr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8235",
    "CITYNAME_AR": "حداب ( المنيظر)",
    "CITYNAME_EN": "Hadab ( Almunayzar)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8236",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8237",
    "CITYNAME_AR": "السلم",
    "CITYNAME_EN": "Alsilm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8238",
    "CITYNAME_AR": "حدبه شيبان",
    "CITYNAME_EN": "Hadbuh Shayban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8239",
    "CITYNAME_AR": "القطفه",
    "CITYNAME_EN": "Alqatfuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8240",
    "CITYNAME_AR": "الفوهه",
    "CITYNAME_EN": "Alfawhuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8241",
    "CITYNAME_AR": "المشعر",
    "CITYNAME_EN": "Almashear",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8242",
    "CITYNAME_AR": "السلم ( الجبيهه)",
    "CITYNAME_EN": "Alsilm ( Aljabiuhuh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8243",
    "CITYNAME_AR": "عفاس",
    "CITYNAME_EN": "Eafaas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8244",
    "CITYNAME_AR": "الكنبي",
    "CITYNAME_EN": "Alkanabiu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8245",
    "CITYNAME_AR": "الشراديه",
    "CITYNAME_EN": "Alsharadiuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8246",
    "CITYNAME_AR": "الخوش",
    "CITYNAME_EN": "Alkhawsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8247",
    "CITYNAME_AR": "السلم",
    "CITYNAME_EN": "Alsilm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8248",
    "CITYNAME_AR": "الوعاير",
    "CITYNAME_EN": "Alwaeayir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8249",
    "CITYNAME_AR": "الحداب",
    "CITYNAME_EN": "Alhidab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8250",
    "CITYNAME_AR": "حقو هتمان",
    "CITYNAME_EN": "Haqu Htman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8251",
    "CITYNAME_AR": "الكاشحه",
    "CITYNAME_EN": "Alkashihuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8252",
    "CITYNAME_AR": "الحفيه",
    "CITYNAME_EN": "Alhafih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8253",
    "CITYNAME_AR": "العلكه",
    "CITYNAME_EN": "Alealakuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8254",
    "CITYNAME_AR": "الحفر",
    "CITYNAME_EN": "Alhafr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8255",
    "CITYNAME_AR": "هتمان",
    "CITYNAME_EN": "Hatman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8256",
    "CITYNAME_AR": "كعيث",
    "CITYNAME_EN": "Kaeith",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8257",
    "CITYNAME_AR": "الصلال",
    "CITYNAME_EN": "Alsilal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8258",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8259",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Almueaqir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8260",
    "CITYNAME_AR": "الحصمه",
    "CITYNAME_EN": "Alhusamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8261",
    "CITYNAME_AR": "الرص",
    "CITYNAME_EN": "Alrus",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8262",
    "CITYNAME_AR": "القفيل",
    "CITYNAME_EN": "Alqafil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8263",
    "CITYNAME_AR": "قرن ام ثليث",
    "CITYNAME_EN": "Qarn Umm Thalith",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8264",
    "CITYNAME_AR": "قهبه ال عيشى",
    "CITYNAME_EN": "Qhbh Al Eayshaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8265",
    "CITYNAME_AR": "الخرباء",
    "CITYNAME_EN": "Alkhuraba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8266",
    "CITYNAME_AR": "ثلوث المنظر",
    "CITYNAME_EN": "Thuluth Almanzar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8267",
    "CITYNAME_AR": "دوقه",
    "CITYNAME_EN": "Dawqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8268",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Alhuquw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8269",
    "CITYNAME_AR": "عرق شكوه",
    "CITYNAME_EN": "Erq Shukuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8270",
    "CITYNAME_AR": "قرن حمده",
    "CITYNAME_EN": "Qarn Hamdh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8271",
    "CITYNAME_AR": "الشعبين",
    "CITYNAME_EN": "Alshaebayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8272",
    "CITYNAME_AR": "سد بالحوته",
    "CITYNAME_EN": "Sada Bialhuatih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8273",
    "CITYNAME_AR": "ام قفيل",
    "CITYNAME_EN": "Umm Qafil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8274",
    "CITYNAME_AR": "حقو صعبان",
    "CITYNAME_EN": "Haqu Sueban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8275",
    "CITYNAME_AR": "قشبه",
    "CITYNAME_EN": "Qashbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8276",
    "CITYNAME_AR": "آل وحيش",
    "CITYNAME_EN": "Al Wahish",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8277",
    "CITYNAME_AR": "ام ريع",
    "CITYNAME_EN": "Umm Raye",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8278",
    "CITYNAME_AR": "الكيلة (الحصاحص)",
    "CITYNAME_EN": "Alkila (Alhasahs)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8279",
    "CITYNAME_AR": "ام طوال",
    "CITYNAME_EN": "Umm Tawal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8280",
    "CITYNAME_AR": "ام خلفه",
    "CITYNAME_EN": "Umm Khalfih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8281",
    "CITYNAME_AR": "عمساء",
    "CITYNAME_EN": "Eamsa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8282",
    "CITYNAME_AR": "الفجور",
    "CITYNAME_EN": "Alfujur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8283",
    "CITYNAME_AR": "ام دغر",
    "CITYNAME_EN": "Umm Daghar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8284",
    "CITYNAME_AR": "غوافل",
    "CITYNAME_EN": "Ghawafil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8285",
    "CITYNAME_AR": "عرف",
    "CITYNAME_EN": "Eurif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8286",
    "CITYNAME_AR": "المروى",
    "CITYNAME_EN": "Almarwaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8287",
    "CITYNAME_AR": "ام عدينه",
    "CITYNAME_EN": "Umm Eadinh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8288",
    "CITYNAME_AR": "الضلعه",
    "CITYNAME_EN": "Aldalaeuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8289",
    "CITYNAME_AR": "بلاد ال هيفاء",
    "CITYNAME_EN": "Bilad Al Hayfa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8290",
    "CITYNAME_AR": "الظهر",
    "CITYNAME_EN": "Alzuhr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8291",
    "CITYNAME_AR": "النقفات",
    "CITYNAME_EN": "Alnaqafat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8292",
    "CITYNAME_AR": "شعب جحيش",
    "CITYNAME_EN": "Shaeb Jahish",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8293",
    "CITYNAME_AR": "الرويحاء",
    "CITYNAME_EN": "Alruwyha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8294",
    "CITYNAME_AR": "دروب القعدان",
    "CITYNAME_EN": "Durub Alqaedan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8295",
    "CITYNAME_AR": "ام نقعه",
    "CITYNAME_EN": "Umm Naqeuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8296",
    "CITYNAME_AR": "المطابق",
    "CITYNAME_EN": "Almatabiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8297",
    "CITYNAME_AR": "المقاضي",
    "CITYNAME_EN": "Almaqadi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8298",
    "CITYNAME_AR": "الخانقة",
    "CITYNAME_EN": "Alkhaniqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8299",
    "CITYNAME_AR": "الخربان الاعلى",
    "CITYNAME_EN": "Alkhurban Al'Aelaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8300",
    "CITYNAME_AR": "الخربان الاسفل",
    "CITYNAME_EN": "Alkhurban Al'Asfal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8301",
    "CITYNAME_AR": "النتره",
    "CITYNAME_EN": "Alnatruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8302",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Alkhalfuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8303",
    "CITYNAME_AR": "النقله",
    "CITYNAME_EN": "Alnaqluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8304",
    "CITYNAME_AR": "المعترضه",
    "CITYNAME_EN": "Almuetaraduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8305",
    "CITYNAME_AR": "المدرم",
    "CITYNAME_EN": "Almudaram",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8306",
    "CITYNAME_AR": "الردحه",
    "CITYNAME_EN": "Alradahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8307",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Almanzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8308",
    "CITYNAME_AR": "السفله",
    "CITYNAME_EN": "Alsafaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8309",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8310",
    "CITYNAME_AR": "آل حسن",
    "CITYNAME_EN": "Al Hasan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8311",
    "CITYNAME_AR": "المقلي",
    "CITYNAME_EN": "Almaqali",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8312",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8313",
    "CITYNAME_AR": "الفصمه",
    "CITYNAME_EN": "Alfasamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8314",
    "CITYNAME_AR": "الطبقات",
    "CITYNAME_EN": "Altabaqat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8315",
    "CITYNAME_AR": "شعب ام سد",
    "CITYNAME_EN": "Shaeb Umm Sada",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8316",
    "CITYNAME_AR": "مخشوشه",
    "CITYNAME_EN": "Makhshushh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8317",
    "CITYNAME_AR": "الثوده (مكيله)",
    "CITYNAME_EN": "Althawduh (Mkilah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8318",
    "CITYNAME_AR": "البحريه",
    "CITYNAME_EN": "Albihrayuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8319",
    "CITYNAME_AR": "مهسه",
    "CITYNAME_EN": "Muhsah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8320",
    "CITYNAME_AR": "حبيل معدى",
    "CITYNAME_EN": "Habil Maedaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8321",
    "CITYNAME_AR": "الحرائق",
    "CITYNAME_EN": "Alharayiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8322",
    "CITYNAME_AR": "البعضاء",
    "CITYNAME_EN": "Albaeda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8323",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8324",
    "CITYNAME_AR": "عمقه",
    "CITYNAME_EN": "Eamaqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8325",
    "CITYNAME_AR": "ام صومعه",
    "CITYNAME_EN": "Umm Sawmaeuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8326",
    "CITYNAME_AR": "العديف",
    "CITYNAME_EN": "Aleadif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8327",
    "CITYNAME_AR": "ابديعه",
    "CITYNAME_EN": "Abdieah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8328",
    "CITYNAME_AR": "أم قوبرة",
    "CITYNAME_EN": "Umm Qawbira",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8329",
    "CITYNAME_AR": "مهجاج",
    "CITYNAME_EN": "Muhjaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8330",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Almanzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8331",
    "CITYNAME_AR": "المترازات",
    "CITYNAME_EN": "Almutarazat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8332",
    "CITYNAME_AR": "الخرق",
    "CITYNAME_EN": "Alkharq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8333",
    "CITYNAME_AR": "المدان",
    "CITYNAME_EN": "Almadan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8334",
    "CITYNAME_AR": "وقفه",
    "CITYNAME_EN": "Waqfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8335",
    "CITYNAME_AR": "القراه",
    "CITYNAME_EN": "Alqaraah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8336",
    "CITYNAME_AR": "ام شفاء",
    "CITYNAME_EN": "Umm Shifa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8337",
    "CITYNAME_AR": "ام موجح",
    "CITYNAME_EN": "Umm Mujah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8338",
    "CITYNAME_AR": "ام عطف",
    "CITYNAME_EN": "Umm Eataf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8339",
    "CITYNAME_AR": "الرهوه (الثبت)",
    "CITYNAME_EN": "Alruhuh (Althbta)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8340",
    "CITYNAME_AR": "الخرق",
    "CITYNAME_EN": "Alkharq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8341",
    "CITYNAME_AR": "المثل",
    "CITYNAME_EN": "Almathalu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8342",
    "CITYNAME_AR": "سمنا",
    "CITYNAME_EN": "Samanaan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8343",
    "CITYNAME_AR": "الرهاء",
    "CITYNAME_EN": "Alriha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8344",
    "CITYNAME_AR": "ذم قره",
    "CITYNAME_EN": "Dhum Qarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8345",
    "CITYNAME_AR": "المحضر",
    "CITYNAME_EN": "Almuhdar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8346",
    "CITYNAME_AR": "الحثمه العلياء",
    "CITYNAME_EN": "Alhuthmuh Alealya'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8347",
    "CITYNAME_AR": "ام رساء والعينه",
    "CITYNAME_EN": "Umm Rasa' Waleaynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8348",
    "CITYNAME_AR": "ام طباق",
    "CITYNAME_EN": "Umm Tabaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8349",
    "CITYNAME_AR": "ام ليساء",
    "CITYNAME_EN": "Umm Laysa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8350",
    "CITYNAME_AR": "الحسلين",
    "CITYNAME_EN": "Alhasalayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8351",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Aljarab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8352",
    "CITYNAME_AR": "الجاره",
    "CITYNAME_EN": "Aljaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8353",
    "CITYNAME_AR": "الصدره",
    "CITYNAME_EN": "Alsadruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8354",
    "CITYNAME_AR": "ام صعيد",
    "CITYNAME_EN": "Umm Saeid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8355",
    "CITYNAME_AR": "ام خربان",
    "CITYNAME_EN": "Umm Khurban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8356",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "Alsiru",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8357",
    "CITYNAME_AR": "حياد",
    "CITYNAME_EN": "Hiad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8358",
    "CITYNAME_AR": "حذان (غار عضمن)",
    "CITYNAME_EN": "Hadhan (Ghar Eadmun)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8359",
    "CITYNAME_AR": "ام انحابه",
    "CITYNAME_EN": "Umm Ainhabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8360",
    "CITYNAME_AR": "حمات",
    "CITYNAME_EN": "Hammat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8361",
    "CITYNAME_AR": "العابدين",
    "CITYNAME_EN": "Aleabidin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8362",
    "CITYNAME_AR": "هده",
    "CITYNAME_EN": "Hadah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8363",
    "CITYNAME_AR": "ام ذخره",
    "CITYNAME_EN": "Umm Dhakharah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8364",
    "CITYNAME_AR": "السنان",
    "CITYNAME_EN": "Alsanan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8365",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8366",
    "CITYNAME_AR": "المرداء",
    "CITYNAME_EN": "Almirda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8367",
    "CITYNAME_AR": "الحفيه",
    "CITYNAME_EN": "Alhafih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8368",
    "CITYNAME_AR": "سلاته",
    "CITYNAME_EN": "Salatuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8369",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Albark",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8370",
    "CITYNAME_AR": "السده",
    "CITYNAME_EN": "Alsaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8371",
    "CITYNAME_AR": "الصريف",
    "CITYNAME_EN": "Alsarif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8372",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Alzahruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8373",
    "CITYNAME_AR": "القنعات",
    "CITYNAME_EN": "Alqaneat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8374",
    "CITYNAME_AR": "محمر",
    "CITYNAME_EN": "Muhamar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8375",
    "CITYNAME_AR": "فاقع",
    "CITYNAME_EN": "Faqie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8376",
    "CITYNAME_AR": "الوصيل",
    "CITYNAME_EN": "Alwasil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8377",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Alrajamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8378",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8379",
    "CITYNAME_AR": "الرصعه (ال حلوه)",
    "CITYNAME_EN": "Alraseuh (Al Haluha)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8380",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8381",
    "CITYNAME_AR": "الظهرات",
    "CITYNAME_EN": "Alzaharat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8382",
    "CITYNAME_AR": "الشطوه",
    "CITYNAME_EN": "Alshutuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8383",
    "CITYNAME_AR": "المحثل",
    "CITYNAME_EN": "Almuhathil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8384",
    "CITYNAME_AR": "الغصه (محساك)",
    "CITYNAME_EN": "Alghasuh (Mhasak)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8385",
    "CITYNAME_AR": "الحدبور",
    "CITYNAME_EN": "Alhadbur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8386",
    "CITYNAME_AR": "المتنه",
    "CITYNAME_EN": "Almatnuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8387",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Alhadba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8388",
    "CITYNAME_AR": "فيض المخبر",
    "CITYNAME_EN": "Fayad Almukhbir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8389",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8390",
    "CITYNAME_AR": "حضن صيده",
    "CITYNAME_EN": "Hadn Siduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8391",
    "CITYNAME_AR": "كهلان",
    "CITYNAME_EN": "Kahlan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8392",
    "CITYNAME_AR": "الحلوى",
    "CITYNAME_EN": "Alhulwaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8393",
    "CITYNAME_AR": "السعيده",
    "CITYNAME_EN": "Alsaeiduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8394",
    "CITYNAME_AR": "الخلف",
    "CITYNAME_EN": "Alkhlf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8395",
    "CITYNAME_AR": "محضان (الاعلى والاسفل)",
    "CITYNAME_EN": "Mhdan (Alaela Walasfal)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8396",
    "CITYNAME_AR": "منصاب",
    "CITYNAME_EN": "Minsab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8397",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Alzahruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8398",
    "CITYNAME_AR": "الابيض",
    "CITYNAME_EN": "Al'Abyad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8399",
    "CITYNAME_AR": "الثوع",
    "CITYNAME_EN": "Althawe",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8400",
    "CITYNAME_AR": "السلبات",
    "CITYNAME_EN": "Alsalabat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8401",
    "CITYNAME_AR": "قفيله",
    "CITYNAME_EN": "Qfylh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8402",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Aldaaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8403",
    "CITYNAME_AR": "الصومل",
    "CITYNAME_EN": "Alsuwmil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8404",
    "CITYNAME_AR": "القطعات",
    "CITYNAME_EN": "Alqataeat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8405",
    "CITYNAME_AR": "المقاطر",
    "CITYNAME_EN": "Almaqatir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8406",
    "CITYNAME_AR": "الخشيباء",
    "CITYNAME_EN": "Alkhashiba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8407",
    "CITYNAME_AR": "اللج",
    "CITYNAME_EN": "Allaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8408",
    "CITYNAME_AR": "شعب المروه",
    "CITYNAME_EN": "Shaeb Almuruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8409",
    "CITYNAME_AR": "الدغره",
    "CITYNAME_EN": "Aldaghruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8410",
    "CITYNAME_AR": "المقوعات",
    "CITYNAME_EN": "Almuqawaeat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8411",
    "CITYNAME_AR": "ام غبرات",
    "CITYNAME_EN": "Umm Ghabarat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8412",
    "CITYNAME_AR": "البحور",
    "CITYNAME_EN": "Albuhur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8413",
    "CITYNAME_AR": "الخطان (الغراه)",
    "CITYNAME_EN": "Alkhutan (Alghrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8414",
    "CITYNAME_AR": "الصفح",
    "CITYNAME_EN": "Alsafh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8415",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Aldabar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8416",
    "CITYNAME_AR": "العنقه (القلباء)",
    "CITYNAME_EN": "Aleinquh (Alqlba')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8417",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8418",
    "CITYNAME_AR": "المحال",
    "CITYNAME_EN": "Almihal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8419",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8420",
    "CITYNAME_AR": "رهوه الخوط",
    "CITYNAME_EN": "Ruhuh Alkhawt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8421",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Alrukabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8422",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Alhishah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8423",
    "CITYNAME_AR": "الشثه",
    "CITYNAME_EN": "Alshuthuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8424",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8425",
    "CITYNAME_AR": "العارضه",
    "CITYNAME_EN": "Aleariduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8426",
    "CITYNAME_AR": "المنسله",
    "CITYNAME_EN": "Almansiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8427",
    "CITYNAME_AR": "ام قريع",
    "CITYNAME_EN": "Umm Qarie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8428",
    "CITYNAME_AR": "خلفه المرباح",
    "CITYNAME_EN": "Khalfih Almirbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8429",
    "CITYNAME_AR": "مجمع الأخوين",
    "CITYNAME_EN": "Al'Akhawayn Group",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8430",
    "CITYNAME_AR": "ظهار المصلى (القهبه)",
    "CITYNAME_EN": "Zahar Almaslaa (Alqahbuh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8431",
    "CITYNAME_AR": "سد عامر",
    "CITYNAME_EN": "Sadi Eamir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8432",
    "CITYNAME_AR": "وادى حمر (المداره)",
    "CITYNAME_EN": "Wa'Adaa Humur (Almdarh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8433",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8434",
    "CITYNAME_AR": "الوجرا",
    "CITYNAME_EN": "Alwijra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8435",
    "CITYNAME_AR": "الشرقي",
    "CITYNAME_EN": "Alsharqi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8436",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Aldaras",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8437",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Alfireuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8438",
    "CITYNAME_AR": "ظهره الحصن",
    "CITYNAME_EN": "Zahrih Alhasn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8439",
    "CITYNAME_AR": "الدشه",
    "CITYNAME_EN": "Aldushuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8440",
    "CITYNAME_AR": "هيزعه",
    "CITYNAME_EN": "Hayzaeah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8441",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Almurabae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8442",
    "CITYNAME_AR": "حديده",
    "CITYNAME_EN": "Hadiduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8443",
    "CITYNAME_AR": "الشاجن",
    "CITYNAME_EN": "Alshaajin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8444",
    "CITYNAME_AR": "شعب الدويمه",
    "CITYNAME_EN": "Shaeb Aldawimuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8445",
    "CITYNAME_AR": "وادى قحفه",
    "CITYNAME_EN": "Wa'Adaa Qahfuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8446",
    "CITYNAME_AR": "الضباع",
    "CITYNAME_EN": "Aldibae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8447",
    "CITYNAME_AR": "خلصه نصبه",
    "CITYNAME_EN": "Khalasah Nasbuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8448",
    "CITYNAME_AR": "الباطن",
    "CITYNAME_EN": "Albatn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8449",
    "CITYNAME_AR": "ساحل نصبه",
    "CITYNAME_EN": "Sahil Nasbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8450",
    "CITYNAME_AR": "خلوفه",
    "CITYNAME_EN": "Khulufuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8451",
    "CITYNAME_AR": "شعب اسره",
    "CITYNAME_EN": "Shaeb Asrh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8452",
    "CITYNAME_AR": "وادى سهول",
    "CITYNAME_EN": "Wa'Adaa Suhul",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8453",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Aldabar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8454",
    "CITYNAME_AR": "الضبير",
    "CITYNAME_EN": "Aldabir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8455",
    "CITYNAME_AR": "وادي المزاخ",
    "CITYNAME_EN": "Wadi Almazakh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8456",
    "CITYNAME_AR": "المتعه",
    "CITYNAME_EN": "Almutaeuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8457",
    "CITYNAME_AR": "وادى بارق",
    "CITYNAME_EN": "Wa'Adaa Bariq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8458",
    "CITYNAME_AR": "وادى سيال",
    "CITYNAME_EN": "Wa'Adaa Sayal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8459",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8460",
    "CITYNAME_AR": "الهيجه",
    "CITYNAME_EN": "Alhayjuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8461",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Alqafiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8462",
    "CITYNAME_AR": "غليفه الشماليه",
    "CITYNAME_EN": "Ghalifuh Alshamaliuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8463",
    "CITYNAME_AR": "غليفه الجنوبيه",
    "CITYNAME_EN": "Ghalifuh Aljanubiuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8464",
    "CITYNAME_AR": "الميلس",
    "CITYNAME_EN": "Almilis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8465",
    "CITYNAME_AR": "شعب اخفه",
    "CITYNAME_EN": "Shaeb Akhfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8466",
    "CITYNAME_AR": "شعب حلفن",
    "CITYNAME_EN": "Shaeb Hilfan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8467",
    "CITYNAME_AR": "سياله",
    "CITYNAME_EN": "Sialuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8468",
    "CITYNAME_AR": "المهيد",
    "CITYNAME_EN": "Almuhid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8469",
    "CITYNAME_AR": "وادى شيع",
    "CITYNAME_EN": "Wa'Adaa Shiae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8470",
    "CITYNAME_AR": "الفايج",
    "CITYNAME_EN": "Alfayij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8471",
    "CITYNAME_AR": "الوضحين",
    "CITYNAME_EN": "Alwadihin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8472",
    "CITYNAME_AR": "القذل",
    "CITYNAME_EN": "Alqadhl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8473",
    "CITYNAME_AR": "كاربه",
    "CITYNAME_EN": "Karbh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8474",
    "CITYNAME_AR": "المقعاء",
    "CITYNAME_EN": "Almuqea'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8475",
    "CITYNAME_AR": "المرسال",
    "CITYNAME_EN": "Almirsal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8476",
    "CITYNAME_AR": "شعب حماره",
    "CITYNAME_EN": "Shaeb Hamarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8477",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8478",
    "CITYNAME_AR": "شعب الحويض",
    "CITYNAME_EN": "Shaeb Alhawayd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8479",
    "CITYNAME_AR": "السمن",
    "CITYNAME_EN": "Alsaman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8480",
    "CITYNAME_AR": "المدابغ",
    "CITYNAME_EN": "Almadabigh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8481",
    "CITYNAME_AR": "شعب السلم",
    "CITYNAME_EN": "Shaeb Alsilm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8482",
    "CITYNAME_AR": "القشيشي",
    "CITYNAME_EN": "Alqshyshi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8483",
    "CITYNAME_AR": "الحفايل",
    "CITYNAME_EN": "Alhafayil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8484",
    "CITYNAME_AR": "الحمايل",
    "CITYNAME_EN": "Alhamayil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8485",
    "CITYNAME_AR": "وادى القضيه",
    "CITYNAME_EN": "Wa'Adaa Alqadih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8486",
    "CITYNAME_AR": "الشليله",
    "CITYNAME_EN": "Alshaliluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8487",
    "CITYNAME_AR": "الظليف",
    "CITYNAME_EN": "Alzalif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8488",
    "CITYNAME_AR": "القلت وشافه",
    "CITYNAME_EN": "Alqult Washafah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8489",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Alraahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8490",
    "CITYNAME_AR": "مقرن",
    "CITYNAME_EN": "Muqaran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8491",
    "CITYNAME_AR": "الغمر",
    "CITYNAME_EN": "Alghamar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8492",
    "CITYNAME_AR": "الدوبلى",
    "CITYNAME_EN": "Alduwbalaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8493",
    "CITYNAME_AR": "وادى هيام",
    "CITYNAME_EN": "Wa'Adaa Hiam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8494",
    "CITYNAME_AR": "ملواه",
    "CITYNAME_EN": "Malwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8495",
    "CITYNAME_AR": "المروات",
    "CITYNAME_EN": "Almarawat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8496",
    "CITYNAME_AR": "الفزرا",
    "CITYNAME_EN": "Alfizra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8497",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Alfasiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8498",
    "CITYNAME_AR": "الدار",
    "CITYNAME_EN": "Aldaar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8499",
    "CITYNAME_AR": "الحصحص ونور",
    "CITYNAME_EN": "Alhashas Wanur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8500",
    "CITYNAME_AR": "الصلبة",
    "CITYNAME_EN": "Al-Salbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8501",
    "CITYNAME_AR": "ام رازه",
    "CITYNAME_EN": "Umm Razah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8502",
    "CITYNAME_AR": "شعيب نغيماء",
    "CITYNAME_EN": "Sho'ayb Naghimaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8503",
    "CITYNAME_AR": "الجميرا والسبك",
    "CITYNAME_EN": "Al-Jemira and Al-Sabk ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8504",
    "CITYNAME_AR": "بتير",
    "CITYNAME_EN": "Batir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8505",
    "CITYNAME_AR": "الملوه",
    "CITYNAME_EN": "Al-Malwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8506",
    "CITYNAME_AR": "راعى  مبرايه",
    "CITYNAME_EN": "Ra'y Mibrayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8507",
    "CITYNAME_AR": "شرفه العلي",
    "CITYNAME_EN": "Shorfah Al-Aly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8508",
    "CITYNAME_AR": "الوشع",
    "CITYNAME_EN": "Al-Wash'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8509",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8510",
    "CITYNAME_AR": "وادى حمير",
    "CITYNAME_EN": "Wadi Hemier ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8511",
    "CITYNAME_AR": "وادى غرغره",
    "CITYNAME_EN": "Wadi Ghrgharah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8512",
    "CITYNAME_AR": "وادى نغما",
    "CITYNAME_EN": "Wadi Naghman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8513",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8514",
    "CITYNAME_AR": "ابن الحارث",
    "CITYNAME_EN": "Abn Alharith",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8515",
    "CITYNAME_AR": "حدبه عسيله",
    "CITYNAME_EN": "Hadabuh Easiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8516",
    "CITYNAME_AR": "القراء",
    "CITYNAME_EN": "Al-Qaraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8517",
    "CITYNAME_AR": "ظهره العرصه (العرصه)",
    "CITYNAME_EN": "Zahrah Al-'Arsah(Al-'Arsah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8518",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Allasbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8519",
    "CITYNAME_AR": "الشوحط",
    "CITYNAME_EN": "Al-Shawhat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8520",
    "CITYNAME_AR": "الوجحه (ال وحيش)",
    "CITYNAME_EN": "Al-Wajhah(Al-Whesh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8521",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Al Darj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8522",
    "CITYNAME_AR": "الهوشه",
    "CITYNAME_EN": "Al-Huwshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8523",
    "CITYNAME_AR": "المراض",
    "CITYNAME_EN": "Al-Merahd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8524",
    "CITYNAME_AR": "حدبة الرماده",
    "CITYNAME_EN": "Hadbah Al-Ramadah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8525",
    "CITYNAME_AR": "القرعاء (الحضن)",
    "CITYNAME_EN": "Al-Qar'Aa(Al-Hodn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8526",
    "CITYNAME_AR": "الصفوح",
    "CITYNAME_EN": "Al-Sofoh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8527",
    "CITYNAME_AR": "الحمه (حمه الحدب)",
    "CITYNAME_EN": "Al-Hemah(Hemah Al-Hadb)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8528",
    "CITYNAME_AR": "الحدبه ( رهوة الحدبه)",
    "CITYNAME_EN": "Al-Hadbah(Rahwah Al-Hadbah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8529",
    "CITYNAME_AR": "آل قديم ( القديم )",
    "CITYNAME_EN": "Al-Qadeem (Al-Qadeem)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8530",
    "CITYNAME_AR": "ذا الخيمه",
    "CITYNAME_EN": "Za Khaimah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8531",
    "CITYNAME_AR": "عطف الظهير",
    "CITYNAME_EN": "Atf Al-Zaheer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8532",
    "CITYNAME_AR": "حجن",
    "CITYNAME_EN": "Hajn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8533",
    "CITYNAME_AR": "المغراء",
    "CITYNAME_EN": "Al-Maghraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8534",
    "CITYNAME_AR": "الطفيفه",
    "CITYNAME_EN": "Al-Tufifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8535",
    "CITYNAME_AR": "جونات",
    "CITYNAME_EN": "Jonat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8536",
    "CITYNAME_AR": "الجلابه",
    "CITYNAME_EN": "Al-Galabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8537",
    "CITYNAME_AR": "الخشعه",
    "CITYNAME_EN": "Al-Khash'Ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8538",
    "CITYNAME_AR": "المراغه",
    "CITYNAME_EN": "Al Maraghah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8539",
    "CITYNAME_AR": "المعاين",
    "CITYNAME_EN": "Al-M'Ayin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8540",
    "CITYNAME_AR": "السلعا",
    "CITYNAME_EN": "Al-Sal'A",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8541",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8542",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Al-Rakbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8543",
    "CITYNAME_AR": "محلمه",
    "CITYNAME_EN": "Malhamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8544",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8545",
    "CITYNAME_AR": "القاحله",
    "CITYNAME_EN": "Al-Qahelah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8546",
    "CITYNAME_AR": "الميفا",
    "CITYNAME_EN": "Al-Mifa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8547",
    "CITYNAME_AR": "الجحوش",
    "CITYNAME_EN": "Al-Gohosh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8548",
    "CITYNAME_AR": "القصباء",
    "CITYNAME_EN": "Al Qasbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8549",
    "CITYNAME_AR": "الجو",
    "CITYNAME_EN": "Al Jou",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8550",
    "CITYNAME_AR": "القرنه (ال وحيش)",
    "CITYNAME_EN": "Al Qarnah(Al Weheesh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8551",
    "CITYNAME_AR": "القرا",
    "CITYNAME_EN": "Al-Qara",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8552",
    "CITYNAME_AR": "المصيف (المحصنه)",
    "CITYNAME_EN": "Al-Masef(Al-Mohsenah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8553",
    "CITYNAME_AR": "شعب جعار",
    "CITYNAME_EN": "Sha'B J'Ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8554",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8555",
    "CITYNAME_AR": "المبانى",
    "CITYNAME_EN": "Al-Mabany",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8556",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8557",
    "CITYNAME_AR": "العصيره",
    "CITYNAME_EN": "Al-'Asera",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8558",
    "CITYNAME_AR": "الدرج",
    "CITYNAME_EN": "Al Darj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8559",
    "CITYNAME_AR": "الخياله",
    "CITYNAME_EN": "Al-Khayalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8560",
    "CITYNAME_AR": "القراه (راجح )",
    "CITYNAME_EN": "Al-Qaraah(Rajeh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8561",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8562",
    "CITYNAME_AR": "المغراء",
    "CITYNAME_EN": "Al-Maghraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8563",
    "CITYNAME_AR": "راحه ال هميل",
    "CITYNAME_EN": "Raht Al Hmel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8564",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'Bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8565",
    "CITYNAME_AR": "الشعابنه",
    "CITYNAME_EN": "Al-Sh'Abnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8566",
    "CITYNAME_AR": "ال مليح",
    "CITYNAME_EN": "Al Maleeh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8567",
    "CITYNAME_AR": "الراحه العليا",
    "CITYNAME_EN": "Al-Rahah Al-'Liah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8568",
    "CITYNAME_AR": "الراحه السفلى",
    "CITYNAME_EN": "Al-Raha  Al-Soflah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8569",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8570",
    "CITYNAME_AR": "الالهاب",
    "CITYNAME_EN": "Al-Alhab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8571",
    "CITYNAME_AR": "الركب",
    "CITYNAME_EN": "Al-Rakab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8572",
    "CITYNAME_AR": "حقو ال سخيطه",
    "CITYNAME_EN": "Haqw Al Skhetah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8573",
    "CITYNAME_AR": "الحقاف (ال قرينين)",
    "CITYNAME_EN": "Al-Haqaf (Al Qarnen)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8574",
    "CITYNAME_AR": "المحيرف",
    "CITYNAME_EN": "Al-Maherf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8575",
    "CITYNAME_AR": "الفصيله (جمعه اثرب)",
    "CITYNAME_EN": "Al-Faselah( Gom'Ah Athrab)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8576",
    "CITYNAME_AR": "رهوه ال محجوبه",
    "CITYNAME_EN": "Zahwah Al Mahgobah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8577",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Al-Hamdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8578",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8579",
    "CITYNAME_AR": "الشعب (حداب جرد)",
    "CITYNAME_EN": "Al-Sha'B ( Hdab Gard)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8580",
    "CITYNAME_AR": "العسر (اليسر)",
    "CITYNAME_EN": "Al-'Usr ( Al-Yosr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8581",
    "CITYNAME_AR": "الخلفه (الواديين)",
    "CITYNAME_EN": "Al-Khalfah ( Al-Whdeen)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8582",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Al-Ma'In",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8583",
    "CITYNAME_AR": "القضايا",
    "CITYNAME_EN": "Al-Qadaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8584",
    "CITYNAME_AR": "بارق",
    "CITYNAME_EN": "Bariq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8585",
    "CITYNAME_AR": "جمعه ربيعه",
    "CITYNAME_EN": "Jameah Rabyeuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8586",
    "CITYNAME_AR": "وادى رشاه",
    "CITYNAME_EN": "Wa'Adaa Rashshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8587",
    "CITYNAME_AR": "ام حبيل",
    "CITYNAME_EN": "Umm Habil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8588",
    "CITYNAME_AR": "قرن الفطيح",
    "CITYNAME_EN": "Qarn Alfatih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8589",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8590",
    "CITYNAME_AR": "جندله ال سالم",
    "CITYNAME_EN": "Junduluh Al Salim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8591",
    "CITYNAME_AR": "القرن الاسود",
    "CITYNAME_EN": "Alqarn Al'Aswad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8592",
    "CITYNAME_AR": "الوعيره",
    "CITYNAME_EN": "Alwaeiruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8593",
    "CITYNAME_AR": "غريب والمعشى",
    "CITYNAME_EN": "Ghurayb Walmaeshaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8594",
    "CITYNAME_AR": "وادى سييله",
    "CITYNAME_EN": "Wa'Adaa Siilh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8595",
    "CITYNAME_AR": "جندله",
    "CITYNAME_EN": "Jundulah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8596",
    "CITYNAME_AR": "القفيل",
    "CITYNAME_EN": "Alqafil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8597",
    "CITYNAME_AR": "وادى جوفله",
    "CITYNAME_EN": "Wa'Adaa Jawflah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8598",
    "CITYNAME_AR": "الحجفار",
    "CITYNAME_EN": "Alhajfar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8599",
    "CITYNAME_AR": "جوف النبع",
    "CITYNAME_EN": "Juf Alnabae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8600",
    "CITYNAME_AR": "النشيم",
    "CITYNAME_EN": "Alnashim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8601",
    "CITYNAME_AR": "الدويمه",
    "CITYNAME_EN": "Aldawaymuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8602",
    "CITYNAME_AR": "وادى صيفر",
    "CITYNAME_EN": "Wa'Adaa Syfr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8603",
    "CITYNAME_AR": "الدخول الاعلى والاسفل",
    "CITYNAME_EN": "Aldukhul Al'Aelaa Walasfil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8604",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8605",
    "CITYNAME_AR": "المرخه",
    "CITYNAME_EN": "Almarakhuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "8606",
    "CITYNAME_AR": "السعى",
    "CITYNAME_EN": "Alsueaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8607",
    "CITYNAME_AR": "ام الخشب",
    "CITYNAME_EN": "Umm Alkhashb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8608",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "Al-Sahwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8609",
    "CITYNAME_AR": "مكبب",
    "CITYNAME_EN": "Makbeb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8610",
    "CITYNAME_AR": "الصفوق",
    "CITYNAME_EN": "Al-Safuq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8611",
    "CITYNAME_AR": "عقده ال سعد",
    "CITYNAME_EN": "Okdah Al Sa'd ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8612",
    "CITYNAME_AR": "الشعب الشامى(موقع عام)",
    "CITYNAME_EN": "Al-She'b Al-Shamy (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8613",
    "CITYNAME_AR": "الحيد (الصهوه)",
    "CITYNAME_EN": "Al-Heed (Al-Sahwah)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8614",
    "CITYNAME_AR": "المرابيح",
    "CITYNAME_EN": "Al-Marabih ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8615",
    "CITYNAME_AR": "البضعه",
    "CITYNAME_EN": "Al-Bad'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8616",
    "CITYNAME_AR": "المظيلف",
    "CITYNAME_EN": "Almuzaylaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8617",
    "CITYNAME_AR": "المهدى",
    "CITYNAME_EN": "Almahdaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8618",
    "CITYNAME_AR": "المشاييخ",
    "CITYNAME_EN": "Almashayikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8619",
    "CITYNAME_AR": "الشهاوين",
    "CITYNAME_EN": "Alshahawin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8620",
    "CITYNAME_AR": "النجيعات",
    "CITYNAME_EN": "Alnajayeat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8621",
    "CITYNAME_AR": "خريه",
    "CITYNAME_EN": "Kharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8622",
    "CITYNAME_AR": "الفضل",
    "CITYNAME_EN": "Alfadl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8623",
    "CITYNAME_AR": "الظبايه",
    "CITYNAME_EN": "Alzabayih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8624",
    "CITYNAME_AR": "الحماميد",
    "CITYNAME_EN": "Alhamamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8625",
    "CITYNAME_AR": "الشعيره",
    "CITYNAME_EN": "Alshaeiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8626",
    "CITYNAME_AR": "مزرعه العبدلى",
    "CITYNAME_EN": "Farm of Aleubdlaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8627",
    "CITYNAME_AR": "الصمدان (الشعيره)",
    "CITYNAME_EN": "Alsamdan (Alshaeirh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8628",
    "CITYNAME_AR": "النيله",
    "CITYNAME_EN": "Alnayluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8629",
    "CITYNAME_AR": "زمبح",
    "CITYNAME_EN": "Zamabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8630",
    "CITYNAME_AR": "مثيبه (بئر مثيبه)",
    "CITYNAME_EN": "Mathibuh (Byir Muthayubah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8631",
    "CITYNAME_AR": "صمدان",
    "CITYNAME_EN": "Samdan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8632",
    "CITYNAME_AR": "مزرعه بن زاهر",
    "CITYNAME_EN": "Farm of Bin Zahir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8633",
    "CITYNAME_AR": "الجرم",
    "CITYNAME_EN": "Aljuram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8634",
    "CITYNAME_AR": "الجرشى",
    "CITYNAME_EN": "Aljurshaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8635",
    "CITYNAME_AR": "ام الخشب",
    "CITYNAME_EN": "Umm Alkhashb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8636",
    "CITYNAME_AR": "النشيرى",
    "CITYNAME_EN": "Alnashiraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8637",
    "CITYNAME_AR": "العمار",
    "CITYNAME_EN": "Aleamar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8638",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Alkhutuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8639",
    "CITYNAME_AR": "الخريص",
    "CITYNAME_EN": "Alkharis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8640",
    "CITYNAME_AR": "النغيه (سالمينه)",
    "CITYNAME_EN": "Alnaghih (Salminh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8641",
    "CITYNAME_AR": "القرن (يبس)",
    "CITYNAME_EN": "Alqarn (Ybs)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8642",
    "CITYNAME_AR": "الملاقيح (اللواحقه)",
    "CITYNAME_EN": "Almalaqih (Allawahiquh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8643",
    "CITYNAME_AR": "الناصريه (الغبارنه)",
    "CITYNAME_EN": "Alnaasiriuh (Alghabarunh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8644",
    "CITYNAME_AR": "المريبى",
    "CITYNAME_EN": "Almuribaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8645",
    "CITYNAME_AR": "ثوعه",
    "CITYNAME_EN": "Thaweah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8646",
    "CITYNAME_AR": "المظاهره",
    "CITYNAME_EN": "Almazahiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8647",
    "CITYNAME_AR": "الفاتيه",
    "CITYNAME_EN": "Alfatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8648",
    "CITYNAME_AR": "صبيا",
    "CITYNAME_EN": "Sabianaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8649",
    "CITYNAME_AR": "الساحل",
    "CITYNAME_EN": "Alssahil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8650",
    "CITYNAME_AR": "سبت لومه",
    "CITYNAME_EN": "Sabat Lawmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8651",
    "CITYNAME_AR": "العودات",
    "CITYNAME_EN": "Aleawdat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8652",
    "CITYNAME_AR": "الغزويه القديمه",
    "CITYNAME_EN": "Alghazawiuh Alqadiumuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8653",
    "CITYNAME_AR": "الخمجان",
    "CITYNAME_EN": "Alkhamjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8654",
    "CITYNAME_AR": "المانع",
    "CITYNAME_EN": "Almanie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8655",
    "CITYNAME_AR": "القضب",
    "CITYNAME_EN": "Alqadb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8656",
    "CITYNAME_AR": "عقره",
    "CITYNAME_EN": "Eaqarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8657",
    "CITYNAME_AR": "الحويطيه",
    "CITYNAME_EN": "Alhwytyh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8658",
    "CITYNAME_AR": "الظهره (المساهير)",
    "CITYNAME_EN": "Alzahruh (Almsahir)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8659",
    "CITYNAME_AR": "الجبنون",
    "CITYNAME_EN": "Aljabnun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8660",
    "CITYNAME_AR": "مضرب شاره",
    "CITYNAME_EN": "Midrab Sharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8661",
    "CITYNAME_AR": "المطوع (المطاعن)",
    "CITYNAME_EN": "Almutue (Almataeun)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8662",
    "CITYNAME_AR": "مزقه",
    "CITYNAME_EN": "Mizqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8663",
    "CITYNAME_AR": "البحير (المندسه)",
    "CITYNAME_EN": "Albuhayr (Almundasah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8664",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "Alsiru",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8665",
    "CITYNAME_AR": "دار الساده",
    "CITYNAME_EN": "Dar Alssaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8666",
    "CITYNAME_AR": "البوازله",
    "CITYNAME_EN": "Albawazilih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8667",
    "CITYNAME_AR": "الشوارده",
    "CITYNAME_EN": "Alshawariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8668",
    "CITYNAME_AR": "ال عطيه",
    "CITYNAME_EN": "Al Eatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8669",
    "CITYNAME_AR": "العوادى",
    "CITYNAME_EN": "Aleawadaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8670",
    "CITYNAME_AR": "المشاييخ",
    "CITYNAME_EN": "Almashayikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8671",
    "CITYNAME_AR": "الفاغيه",
    "CITYNAME_EN": "Alfaghih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8672",
    "CITYNAME_AR": "السروان",
    "CITYNAME_EN": "Alsirwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8673",
    "CITYNAME_AR": "ال عوض",
    "CITYNAME_EN": "Al Eiwad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8674",
    "CITYNAME_AR": "دار الصقاعنه (العمده)",
    "CITYNAME_EN": "Dar Alsaqaeinuh (Alieamudah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8675",
    "CITYNAME_AR": "ال بن جوهره",
    "CITYNAME_EN": "Al Bin Jawharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8676",
    "CITYNAME_AR": "ال بن مشنية",
    "CITYNAME_EN": "Al Bin Mashania",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8677",
    "CITYNAME_AR": "صبح (دار الصباحنه)",
    "CITYNAME_EN": "Sabh (Dar Alsabahinh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8678",
    "CITYNAME_AR": "القرنيه",
    "CITYNAME_EN": "Alqurniuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8679",
    "CITYNAME_AR": "الصم",
    "CITYNAME_EN": "Alsumu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8680",
    "CITYNAME_AR": "العموش",
    "CITYNAME_EN": "Aleumush",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8681",
    "CITYNAME_AR": "ابن راشد (الهبيرة)",
    "CITYNAME_EN": "Abn Rashid (Alhbyr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8682",
    "CITYNAME_AR": "القايمه",
    "CITYNAME_EN": "Alqayimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8683",
    "CITYNAME_AR": "ام الشعير",
    "CITYNAME_EN": "Umm Alshaeir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8684",
    "CITYNAME_AR": "مغنيه",
    "CITYNAME_EN": "Maghnih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8685",
    "CITYNAME_AR": "الصافى",
    "CITYNAME_EN": "Alsafa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8686",
    "CITYNAME_AR": "عذيبه",
    "CITYNAME_EN": "Eadhibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8687",
    "CITYNAME_AR": "الشمله",
    "CITYNAME_EN": "Alshamluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8688",
    "CITYNAME_AR": "الوطاه",
    "CITYNAME_EN": "Alwatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8689",
    "CITYNAME_AR": "مديده",
    "CITYNAME_EN": "Mudiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8690",
    "CITYNAME_AR": "النفاذ",
    "CITYNAME_EN": "Alnafadh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8691",
    "CITYNAME_AR": "مقشر (خراوع)",
    "CITYNAME_EN": "Muqashar (Khrawe)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8692",
    "CITYNAME_AR": "ابوعويدين",
    "CITYNAME_EN": "Abwewydyn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8693",
    "CITYNAME_AR": "الزرابيه",
    "CITYNAME_EN": "Alzirabiah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8694",
    "CITYNAME_AR": "العقم",
    "CITYNAME_EN": "Aleuqm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8695",
    "CITYNAME_AR": "الجهو",
    "CITYNAME_EN": "Aljahu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8696",
    "CITYNAME_AR": "العوامره",
    "CITYNAME_EN": "Aleawamiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8697",
    "CITYNAME_AR": "الكراميح",
    "CITYNAME_EN": "Alkaramih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8698",
    "CITYNAME_AR": "المطالقه",
    "CITYNAME_EN": "Almataliquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8699",
    "CITYNAME_AR": "الجما",
    "CITYNAME_EN": "Aljamma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8700",
    "CITYNAME_AR": "الخريصيه",
    "CITYNAME_EN": "Alkharisiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8701",
    "CITYNAME_AR": "البقاقير",
    "CITYNAME_EN": "Albaqaqir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8702",
    "CITYNAME_AR": "السنبل",
    "CITYNAME_EN": "Alsunbl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8703",
    "CITYNAME_AR": "الوقته",
    "CITYNAME_EN": "Alwaqtuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8704",
    "CITYNAME_AR": "الأحلية",
    "CITYNAME_EN": "Al'Ahaliya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8705",
    "CITYNAME_AR": "الردفه",
    "CITYNAME_EN": "Alradafuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8706",
    "CITYNAME_AR": "الحجاريف",
    "CITYNAME_EN": "Alhajarif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8707",
    "CITYNAME_AR": "العنقة",
    "CITYNAME_EN": "Aleunqa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8708",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8709",
    "CITYNAME_AR": "الكندوف",
    "CITYNAME_EN": "Alkunduf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8710",
    "CITYNAME_AR": "أم السكب",
    "CITYNAME_EN": "'Ama Alsakab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8711",
    "CITYNAME_AR": "الزاهريه",
    "CITYNAME_EN": "Alzaahirih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8712",
    "CITYNAME_AR": "الهبيره",
    "CITYNAME_EN": "Alhabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8713",
    "CITYNAME_AR": "القشعه",
    "CITYNAME_EN": "Alqasheuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8714",
    "CITYNAME_AR": "سلم القرص",
    "CITYNAME_EN": "Salam Alqurs",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8715",
    "CITYNAME_AR": "الرفده",
    "CITYNAME_EN": "Alrifduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8716",
    "CITYNAME_AR": "المروة",
    "CITYNAME_EN": "Almarawa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8717",
    "CITYNAME_AR": "النسلة",
    "CITYNAME_EN": "Alnusla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8718",
    "CITYNAME_AR": "عذية",
    "CITYNAME_EN": "Eadhia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8719",
    "CITYNAME_AR": "بحرات المزاريع",
    "CITYNAME_EN": "Bihrat Almazarie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8720",
    "CITYNAME_AR": "مكبب",
    "CITYNAME_EN": "Mukabib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8721",
    "CITYNAME_AR": "الجبيرة بالتومان",
    "CITYNAME_EN": "Aljabirat Bialtawman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8722",
    "CITYNAME_AR": "المسيليم",
    "CITYNAME_EN": "Almasilim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8723",
    "CITYNAME_AR": "الدبه",
    "CITYNAME_EN": "Aldabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8724",
    "CITYNAME_AR": "الكندره",
    "CITYNAME_EN": "Alkandaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8725",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Alhamduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8726",
    "CITYNAME_AR": "الطينه",
    "CITYNAME_EN": "Altiynuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8727",
    "CITYNAME_AR": "العقد",
    "CITYNAME_EN": "Aleuqad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8728",
    "CITYNAME_AR": "شعيره",
    "CITYNAME_EN": "Shaeiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8729",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8730",
    "CITYNAME_AR": "الحدبه الشرقيه",
    "CITYNAME_EN": "Alhadabuh Alsharqiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8731",
    "CITYNAME_AR": "قريه القرى",
    "CITYNAME_EN": "Qarih Alqra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8732",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8733",
    "CITYNAME_AR": "مثيبه",
    "CITYNAME_EN": "Muthibah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8734",
    "CITYNAME_AR": "الماضع",
    "CITYNAME_EN": "Almadie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8735",
    "CITYNAME_AR": "ترابه",
    "CITYNAME_EN": "Turabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8736",
    "CITYNAME_AR": "ابوحرابى",
    "CITYNAME_EN": "Abwhraba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8737",
    "CITYNAME_AR": "عسيله",
    "CITYNAME_EN": "Easilh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8738",
    "CITYNAME_AR": "القعاقيع",
    "CITYNAME_EN": "Alqaeaqie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8739",
    "CITYNAME_AR": "الطراحيه",
    "CITYNAME_EN": "Altarahiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8740",
    "CITYNAME_AR": "المساعيد",
    "CITYNAME_EN": "Almasaeid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8741",
    "CITYNAME_AR": "المرادم",
    "CITYNAME_EN": "Almuradim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8742",
    "CITYNAME_AR": "المرابي",
    "CITYNAME_EN": "Almurabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8743",
    "CITYNAME_AR": "دوقه الأولى",
    "CITYNAME_EN": "Dawquh Al'Uwlaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8744",
    "CITYNAME_AR": "الجربه الشرقيه",
    "CITYNAME_EN": "Aljarabuh Alsharqiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8745",
    "CITYNAME_AR": "قعموصه",
    "CITYNAME_EN": "Qaemusih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8746",
    "CITYNAME_AR": "الرايقيه",
    "CITYNAME_EN": "Alraayqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8747",
    "CITYNAME_AR": "الجنبيه",
    "CITYNAME_EN": "Aljinabayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8748",
    "CITYNAME_AR": "ام البعر",
    "CITYNAME_EN": "Umm Albaer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8749",
    "CITYNAME_AR": "الكرادمه",
    "CITYNAME_EN": "Alkaradimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8750",
    "CITYNAME_AR": "ام الحاذق (ام الحالي)",
    "CITYNAME_EN": "Umm Alhadhiq (Ama Alhali)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8751",
    "CITYNAME_AR": "مدسوسه",
    "CITYNAME_EN": "Madasusuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8752",
    "CITYNAME_AR": "كدوه الحوسان",
    "CITYNAME_EN": "Kuduh Alhusan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8753",
    "CITYNAME_AR": "الجربه الغربيه",
    "CITYNAME_EN": "Aljarabuh Algharbayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8754",
    "CITYNAME_AR": "الثعلبيه",
    "CITYNAME_EN": "Althaelabiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8755",
    "CITYNAME_AR": "الهاجريه",
    "CITYNAME_EN": "Alhajirih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8756",
    "CITYNAME_AR": "المحجاره",
    "CITYNAME_EN": "Almihjaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8757",
    "CITYNAME_AR": "كياد",
    "CITYNAME_EN": "Kiad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8758",
    "CITYNAME_AR": "كدوه العلاوين",
    "CITYNAME_EN": "Kuduh Alealawin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8759",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8760",
    "CITYNAME_AR": "الكدوه",
    "CITYNAME_EN": "Alkuduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8761",
    "CITYNAME_AR": "المساعديه",
    "CITYNAME_EN": "Almusaeidiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8762",
    "CITYNAME_AR": "الخيره",
    "CITYNAME_EN": "Alkhayruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8763",
    "CITYNAME_AR": "ابوالضرم",
    "CITYNAME_EN": "Abwaldrm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8764",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Musharaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8765",
    "CITYNAME_AR": "البراديه",
    "CITYNAME_EN": "Albaradiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8766",
    "CITYNAME_AR": "القديح",
    "CITYNAME_EN": "Alqadih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8767",
    "CITYNAME_AR": "الحسينات",
    "CITYNAME_EN": "Alhusaynat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "8768",
    "CITYNAME_AR": "المخواه",
    "CITYNAME_EN": "Al-Makhwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8769",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al-Khalif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8770",
    "CITYNAME_AR": "الجماء",
    "CITYNAME_EN": "Al-Jmaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8771",
    "CITYNAME_AR": "المضحاه",
    "CITYNAME_EN": "Al-Madhah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8772",
    "CITYNAME_AR": "سلامه",
    "CITYNAME_EN": "Salama",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8773",
    "CITYNAME_AR": "الفجة والقماريه",
    "CITYNAME_EN": "Al-Fajah and Al-Qamrayah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8774",
    "CITYNAME_AR": "المقنعه",
    "CITYNAME_EN": "Al-Meqn'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8775",
    "CITYNAME_AR": "العشيره",
    "CITYNAME_EN": "Al-Ashirah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8776",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8777",
    "CITYNAME_AR": "الدخول",
    "CITYNAME_EN": "Al-Dakhul ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8778",
    "CITYNAME_AR": "ال باخ الاسفل والاعلى",
    "CITYNAME_EN": "Al Bakh (the upper and the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8779",
    "CITYNAME_AR": "البيطيره",
    "CITYNAME_EN": "Al-Baytariyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8780",
    "CITYNAME_AR": "خبه والعجاريد",
    "CITYNAME_EN": "Khabah and Al-Ajarid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8781",
    "CITYNAME_AR": "العجار الاعلى",
    "CITYNAME_EN": "Al-Ejar (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8782",
    "CITYNAME_AR": "العجار الاسفل",
    "CITYNAME_EN": "Al-Ejar Poultry Farm",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8783",
    "CITYNAME_AR": "مديره",
    "CITYNAME_EN": "Madirah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8784",
    "CITYNAME_AR": "العذا",
    "CITYNAME_EN": "Al-Aza ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8785",
    "CITYNAME_AR": "الحليمه",
    "CITYNAME_EN": "Al-Halimah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8786",
    "CITYNAME_AR": "الميدان والغصنة",
    "CITYNAME_EN": "Al-Maydan and Al-Ghasnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8787",
    "CITYNAME_AR": "حنظلا",
    "CITYNAME_EN": "Hanzala ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8788",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8789",
    "CITYNAME_AR": "الحلسه",
    "CITYNAME_EN": "Al-Helsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8790",
    "CITYNAME_AR": "الرشده",
    "CITYNAME_EN": "Al-Roshdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8791",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Al-Shaqrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8792",
    "CITYNAME_AR": "بنى يحمد",
    "CITYNAME_EN": "Bni Yahmid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8793",
    "CITYNAME_AR": "عرفه",
    "CITYNAME_EN": "Arafah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8794",
    "CITYNAME_AR": "قحيش",
    "CITYNAME_EN": "Qahish ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8795",
    "CITYNAME_AR": "المباعيث",
    "CITYNAME_EN": "Al-Mab'ith ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8796",
    "CITYNAME_AR": "الغصه والجوفاء",
    "CITYNAME_EN": "Al-ghosah and Al-Jawfaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8797",
    "CITYNAME_AR": "البدوان والمضحاة",
    "CITYNAME_EN": "Al-Badwan and Al-Medhah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8798",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Juwar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8799",
    "CITYNAME_AR": "الرششه",
    "CITYNAME_EN": "Al-Rashashah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8800",
    "CITYNAME_AR": "ضجه",
    "CITYNAME_EN": "Dajah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8801",
    "CITYNAME_AR": "الشراحيين",
    "CITYNAME_EN": "Al-Sharahin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8802",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Al Bidha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8803",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Al-Hamdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8804",
    "CITYNAME_AR": "قدران",
    "CITYNAME_EN": "Qadran",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8805",
    "CITYNAME_AR": "مليل",
    "CITYNAME_EN": "Maleel",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8806",
    "CITYNAME_AR": "حواز",
    "CITYNAME_EN": "Hawaz ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8807",
    "CITYNAME_AR": "قرن النثله",
    "CITYNAME_EN": "Qarn Al-Nathlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8808",
    "CITYNAME_AR": "حصينى",
    "CITYNAME_EN": "Hasiny ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8809",
    "CITYNAME_AR": "المزبن",
    "CITYNAME_EN": "Al-Mazin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8810",
    "CITYNAME_AR": "مغايض",
    "CITYNAME_EN": "Maghyid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8811",
    "CITYNAME_AR": "سهاوه",
    "CITYNAME_EN": "Sahawah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8812",
    "CITYNAME_AR": "الشعره",
    "CITYNAME_EN": "Al-Sha'rah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8813",
    "CITYNAME_AR": "ضهايا",
    "CITYNAME_EN": "Dahayah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8814",
    "CITYNAME_AR": "العكس",
    "CITYNAME_EN": "Al Ekas",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8815",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8816",
    "CITYNAME_AR": "الخراره",
    "CITYNAME_EN": "Al-Khararah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8817",
    "CITYNAME_AR": "الريعه",
    "CITYNAME_EN": "Al-Rib'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8818",
    "CITYNAME_AR": "المجازه",
    "CITYNAME_EN": "Al-Majazah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8819",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al-Quz",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8820",
    "CITYNAME_AR": "دار الاعرق",
    "CITYNAME_EN": "Dar Al-A'rq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8821",
    "CITYNAME_AR": "المحجره",
    "CITYNAME_EN": "Al-Mehjarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8822",
    "CITYNAME_AR": "الظهار",
    "CITYNAME_EN": "Al-Zehar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8823",
    "CITYNAME_AR": "مصادم",
    "CITYNAME_EN": "Masadem ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8824",
    "CITYNAME_AR": "خاتمه",
    "CITYNAME_EN": "Khatimah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8825",
    "CITYNAME_AR": "المبنى",
    "CITYNAME_EN": "Building",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8826",
    "CITYNAME_AR": "ناب المنشقر",
    "CITYNAME_EN": "Nab Al-Monshaqer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8827",
    "CITYNAME_AR": "قرى المزروبه",
    "CITYNAME_EN": "Al-Mazroubah Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8828",
    "CITYNAME_AR": "الحراء والشريقه",
    "CITYNAME_EN": "Al-Heraa and Al-Shrabaqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8829",
    "CITYNAME_AR": "الصعبه",
    "CITYNAME_EN": "Al-Sa'bah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8830",
    "CITYNAME_AR": "الحارث",
    "CITYNAME_EN": "Al-Harith ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8831",
    "CITYNAME_AR": "حصيني الاعلى والاسفل",
    "CITYNAME_EN": "Hasiny (the upper and the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8832",
    "CITYNAME_AR": "غبيشه",
    "CITYNAME_EN": "Ghbishah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8833",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Qa'rah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8834",
    "CITYNAME_AR": "البطحاء",
    "CITYNAME_EN": "Al Bathaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8835",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Al Sour",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8836",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Juwar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8837",
    "CITYNAME_AR": "الحشف",
    "CITYNAME_EN": "Al-Hashf",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8838",
    "CITYNAME_AR": "المشباب",
    "CITYNAME_EN": "Al-Meshbab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8839",
    "CITYNAME_AR": "قرى الحويه",
    "CITYNAME_EN": "Al-Hubah Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8840",
    "CITYNAME_AR": "قرى المعمر",
    "CITYNAME_EN": "Al-Ma'mer Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8841",
    "CITYNAME_AR": "فاجه",
    "CITYNAME_EN": "Fajah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8842",
    "CITYNAME_AR": "حداب النجيم",
    "CITYNAME_EN": "Hedab Al-Najim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8843",
    "CITYNAME_AR": "حتار",
    "CITYNAME_EN": "Hetar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8844",
    "CITYNAME_AR": "قرى المشروك",
    "CITYNAME_EN": "Al-Mashrouq Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8845",
    "CITYNAME_AR": "حنف",
    "CITYNAME_EN": "Hanf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8846",
    "CITYNAME_AR": "الشوق",
    "CITYNAME_EN": "Al-Shawq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8847",
    "CITYNAME_AR": "رهوه الشراء",
    "CITYNAME_EN": "Rwah Al-Shraa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8848",
    "CITYNAME_AR": "الحناظى",
    "CITYNAME_EN": "Al-Hanazy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8849",
    "CITYNAME_AR": "البلينيه",
    "CITYNAME_EN": "Al-Baliniyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8850",
    "CITYNAME_AR": "ظها",
    "CITYNAME_EN": "Zaha ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8851",
    "CITYNAME_AR": "المراث",
    "CITYNAME_EN": "Al-Merath",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8852",
    "CITYNAME_AR": "العصداء",
    "CITYNAME_EN": "Al-Asdaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8853",
    "CITYNAME_AR": "القتيباء",
    "CITYNAME_EN": "Al-Qutaybaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8854",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "Al-Sahwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8855",
    "CITYNAME_AR": "القضايا",
    "CITYNAME_EN": "Al-Qadaya ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8856",
    "CITYNAME_AR": "المقشع",
    "CITYNAME_EN": "Al-Meqsha' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8857",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8858",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8859",
    "CITYNAME_AR": "الروس",
    "CITYNAME_EN": "Al-Rus ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8860",
    "CITYNAME_AR": "النمره",
    "CITYNAME_EN": "Al-Namrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8861",
    "CITYNAME_AR": "صفاق",
    "CITYNAME_EN": "Sefaq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8862",
    "CITYNAME_AR": "الدهنه",
    "CITYNAME_EN": "Al Dohnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8863",
    "CITYNAME_AR": "الحلوق",
    "CITYNAME_EN": "Al-Haluq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8864",
    "CITYNAME_AR": "رحبان",
    "CITYNAME_EN": "Rahban",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8865",
    "CITYNAME_AR": "الاشراف",
    "CITYNAME_EN": "Al-Ashraf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8866",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8867",
    "CITYNAME_AR": "ال ساحه",
    "CITYNAME_EN": "Al Sahah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8868",
    "CITYNAME_AR": "الشعبين",
    "CITYNAME_EN": "Al-She'bayn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8869",
    "CITYNAME_AR": "ثعلب",
    "CITYNAME_EN": "Tha'lab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8870",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8871",
    "CITYNAME_AR": "حضاه",
    "CITYNAME_EN": "hadarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8872",
    "CITYNAME_AR": "بركاء",
    "CITYNAME_EN": "Barkaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8873",
    "CITYNAME_AR": "الملاقي",
    "CITYNAME_EN": "Al-Malaqy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8874",
    "CITYNAME_AR": "ناوان",
    "CITYNAME_EN": "Nawan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8875",
    "CITYNAME_AR": "المقارى",
    "CITYNAME_EN": "Al-Maqary ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8876",
    "CITYNAME_AR": "الحائط",
    "CITYNAME_EN": "Al Ha'et",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8877",
    "CITYNAME_AR": "المبارك",
    "CITYNAME_EN": "Al-Mubarak ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8878",
    "CITYNAME_AR": "الحجروف",
    "CITYNAME_EN": "Al-Hajrouf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8879",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Al-Zyarah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8880",
    "CITYNAME_AR": "المساقه",
    "CITYNAME_EN": "Al-Masaqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8881",
    "CITYNAME_AR": "صباب",
    "CITYNAME_EN": "Sabab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8882",
    "CITYNAME_AR": "وادى ناوان(موقع عام)",
    "CITYNAME_EN": "Wadi nawan (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8883",
    "CITYNAME_AR": "الزروق",
    "CITYNAME_EN": "Al-Zaruq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8884",
    "CITYNAME_AR": "المعيوده",
    "CITYNAME_EN": "Al-Ma'yudah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8885",
    "CITYNAME_AR": "العقيصاء",
    "CITYNAME_EN": "Al-Oqaysaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8886",
    "CITYNAME_AR": "شقص",
    "CITYNAME_EN": "Shaqs ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8887",
    "CITYNAME_AR": "القرن الاخضر والمرابي",
    "CITYNAME_EN": "Al-Qarn Al-Akhdar and Al-Maraby ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8888",
    "CITYNAME_AR": "وادى رام",
    "CITYNAME_EN": "Wadi Ram",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8889",
    "CITYNAME_AR": "القعر",
    "CITYNAME_EN": "Al-Qa'r",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8890",
    "CITYNAME_AR": "الحميراء (المضباع)",
    "CITYNAME_EN": "Al-Monairaa (Al-Medba')",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8891",
    "CITYNAME_AR": "الفظاظه",
    "CITYNAME_EN": "Al-Fazazah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8892",
    "CITYNAME_AR": "المساعيد",
    "CITYNAME_EN": "Al-Masa'id ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8893",
    "CITYNAME_AR": "المقرر",
    "CITYNAME_EN": "Al-Moqrer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8894",
    "CITYNAME_AR": "القريعاء",
    "CITYNAME_EN": "Al-Qoray'aa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8895",
    "CITYNAME_AR": "الشعب اليمانى",
    "CITYNAME_EN": "Al-She'b Al-Yamany ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8896",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8897",
    "CITYNAME_AR": "الصغواء",
    "CITYNAME_EN": "Al Sawghaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8898",
    "CITYNAME_AR": "دبيان",
    "CITYNAME_EN": "Debyan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8899",
    "CITYNAME_AR": "زغفه",
    "CITYNAME_EN": "Zaghfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8900",
    "CITYNAME_AR": "جمان",
    "CITYNAME_EN": "Gaman",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8901",
    "CITYNAME_AR": "الحاره",
    "CITYNAME_EN": "Al Harah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8902",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Al-Dabr",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8903",
    "CITYNAME_AR": "المزيرعه",
    "CITYNAME_EN": "Al Mozera'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8904",
    "CITYNAME_AR": "الكبسه",
    "CITYNAME_EN": "Al-Kabsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8905",
    "CITYNAME_AR": "المالك",
    "CITYNAME_EN": "Al-Malik ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8906",
    "CITYNAME_AR": "الصقران",
    "CITYNAME_EN": "Al-Saqran ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8907",
    "CITYNAME_AR": "الفيرع",
    "CITYNAME_EN": "Al-Fayra'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8908",
    "CITYNAME_AR": "حضاه",
    "CITYNAME_EN": "Hada",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8909",
    "CITYNAME_AR": "الجوف",
    "CITYNAME_EN": "Al-Jouf",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8910",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Al Sour",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8911",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8912",
    "CITYNAME_AR": "فرعه السلاطين",
    "CITYNAME_EN": "Far'ah Al-Salatin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8913",
    "CITYNAME_AR": "الغمار",
    "CITYNAME_EN": "Al-Ghamar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8914",
    "CITYNAME_AR": "الملاليح",
    "CITYNAME_EN": "Al-malalih ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8915",
    "CITYNAME_AR": "قرن الجرفه",
    "CITYNAME_EN": "Qarn Al-Jarfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8916",
    "CITYNAME_AR": "المساعده",
    "CITYNAME_EN": "Al-Masa'idah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8917",
    "CITYNAME_AR": "لهن",
    "CITYNAME_EN": "Lahn ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8918",
    "CITYNAME_AR": "آل عياش",
    "CITYNAME_EN": "Al Ayash ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8919",
    "CITYNAME_AR": "ال طارق",
    "CITYNAME_EN": "Al Tariq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8920",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8921",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8922",
    "CITYNAME_AR": "الريانه",
    "CITYNAME_EN": "Al-Ryanah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8923",
    "CITYNAME_AR": "ال سكران",
    "CITYNAME_EN": "Al Sakran",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8924",
    "CITYNAME_AR": "المسعود",
    "CITYNAME_EN": "Al-Masoud ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8925",
    "CITYNAME_AR": "ذي عين",
    "CITYNAME_EN": "Zi Ein",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8926",
    "CITYNAME_AR": "حصن القرعه",
    "CITYNAME_EN": "Hisn Al-Qar'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8927",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8928",
    "CITYNAME_AR": "شعب المغره",
    "CITYNAME_EN": "She'b Al-Magharrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8929",
    "CITYNAME_AR": "حصن الجناب",
    "CITYNAME_EN": "Hisn Al-Janab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8930",
    "CITYNAME_AR": "الكفته",
    "CITYNAME_EN": "Al Keftah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8931",
    "CITYNAME_AR": "الحصون",
    "CITYNAME_EN": "Al Hasoun",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8932",
    "CITYNAME_AR": "الحقوين",
    "CITYNAME_EN": "Al Haqwin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8933",
    "CITYNAME_AR": "غياض",
    "CITYNAME_EN": "Gheyad",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8934",
    "CITYNAME_AR": "المحني",
    "CITYNAME_EN": "Al-Mehny ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8935",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8936",
    "CITYNAME_AR": "الجهلان",
    "CITYNAME_EN": "Al-Jahlan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8937",
    "CITYNAME_AR": "دار ال سعيد",
    "CITYNAME_EN": "Dar Al Saed",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8938",
    "CITYNAME_AR": "غافله",
    "CITYNAME_EN": "Ghaflah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8939",
    "CITYNAME_AR": "العراونه",
    "CITYNAME_EN": "Al-Arawnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8940",
    "CITYNAME_AR": "العصده والصقره",
    "CITYNAME_EN": "Al-Sa'dah and Al-Saqrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8941",
    "CITYNAME_AR": "الميلح",
    "CITYNAME_EN": "Al-Maylh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8942",
    "CITYNAME_AR": "الخليفه",
    "CITYNAME_EN": "Al-Khalifa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8943",
    "CITYNAME_AR": "الحنجور",
    "CITYNAME_EN": "Al-Hanjour",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8944",
    "CITYNAME_AR": "سند القهب",
    "CITYNAME_EN": "Sand Al-Qahb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8945",
    "CITYNAME_AR": "الحليمه",
    "CITYNAME_EN": "Al-Halimah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8946",
    "CITYNAME_AR": "المضحاه والحشف",
    "CITYNAME_EN": "Al-Madhah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8947",
    "CITYNAME_AR": "ال سعدان العليا",
    "CITYNAME_EN": "Al Saadan (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8948",
    "CITYNAME_AR": "صلا",
    "CITYNAME_EN": "Sala ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8949",
    "CITYNAME_AR": "ال سعدان السفلى",
    "CITYNAME_EN": "Al Saadan (the lower))",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8950",
    "CITYNAME_AR": "بالنعمان",
    "CITYNAME_EN": "Al-No'man ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8951",
    "CITYNAME_AR": "الانصب",
    "CITYNAME_EN": "Al-Ansob",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8952",
    "CITYNAME_AR": "النسور",
    "CITYNAME_EN": "Al-Nosur ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8953",
    "CITYNAME_AR": "الطويله",
    "CITYNAME_EN": "Al-Tawilah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8954",
    "CITYNAME_AR": "القياس",
    "CITYNAME_EN": "Al-Qeyas ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8955",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8956",
    "CITYNAME_AR": "عذيبه",
    "CITYNAME_EN": "Azibah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8957",
    "CITYNAME_AR": "الحمره",
    "CITYNAME_EN": "Al Hamrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8958",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Al Hafnah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8959",
    "CITYNAME_AR": "السحر",
    "CITYNAME_EN": "Al-Sehr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8960",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Mashrafah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8961",
    "CITYNAME_AR": "الوسطه",
    "CITYNAME_EN": "Al-Wastah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8962",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8963",
    "CITYNAME_AR": "المساعده",
    "CITYNAME_EN": "Al-Masa'idah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8964",
    "CITYNAME_AR": "الراسين",
    "CITYNAME_EN": "Al-Rasin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8965",
    "CITYNAME_AR": "الشوال",
    "CITYNAME_EN": "Al-Shewal ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8966",
    "CITYNAME_AR": "فطيس",
    "CITYNAME_EN": "Fatis",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8967",
    "CITYNAME_AR": "جبل قراما",
    "CITYNAME_EN": "Qarmah Mountain ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8968",
    "CITYNAME_AR": "العطارده",
    "CITYNAME_EN": "Al-Ataridah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8969",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Al-Hamatah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8970",
    "CITYNAME_AR": "العمير",
    "CITYNAME_EN": "Al-Aomair ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8971",
    "CITYNAME_AR": "الصخره",
    "CITYNAME_EN": "Al-Sakhrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8972",
    "CITYNAME_AR": "عبيد",
    "CITYNAME_EN": "Abid",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8973",
    "CITYNAME_AR": "المغميق",
    "CITYNAME_EN": "Al-Maghmiq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8974",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8975",
    "CITYNAME_AR": "القريده",
    "CITYNAME_EN": "Al-Qaridah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8976",
    "CITYNAME_AR": "المرتباء",
    "CITYNAME_EN": "Al-Martbaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8977",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "Al-Safaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8978",
    "CITYNAME_AR": "الشتفه",
    "CITYNAME_EN": "Al-Shetfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8979",
    "CITYNAME_AR": "الشراقيه",
    "CITYNAME_EN": "Al-Sharaqyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8980",
    "CITYNAME_AR": "الأخليله",
    "CITYNAME_EN": "Al-Akhlilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8981",
    "CITYNAME_AR": "المزرعه",
    "CITYNAME_EN": "Al-Mazra'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8982",
    "CITYNAME_AR": "العرباء",
    "CITYNAME_EN": "Al-Arbaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8983",
    "CITYNAME_AR": "قرين",
    "CITYNAME_EN": "Qarin",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8984",
    "CITYNAME_AR": "ام الحمام",
    "CITYNAME_EN": "Umm Al Hamam",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8985",
    "CITYNAME_AR": "غبار",
    "CITYNAME_EN": "Qarin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8986",
    "CITYNAME_AR": "الدحض",
    "CITYNAME_EN": "Al-Dahd",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8987",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Al-Merbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8988",
    "CITYNAME_AR": "عصيبه",
    "CITYNAME_EN": "Asibah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8989",
    "CITYNAME_AR": "احد القفره",
    "CITYNAME_EN": "Ahd Al-Qafrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8990",
    "CITYNAME_AR": "الصفيراء",
    "CITYNAME_EN": "Al-Sufayraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8991",
    "CITYNAME_AR": "العنبه",
    "CITYNAME_EN": "Al-Enbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8992",
    "CITYNAME_AR": "الخشبه",
    "CITYNAME_EN": "Al-Khashbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8993",
    "CITYNAME_AR": "قوران",
    "CITYNAME_EN": "Quran ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8994",
    "CITYNAME_AR": "الطبيقه",
    "CITYNAME_EN": "Al-Tabiqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8995",
    "CITYNAME_AR": "الجلب",
    "CITYNAME_EN": "Al-Galb",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8996",
    "CITYNAME_AR": "معاتيب",
    "CITYNAME_EN": "Ma'atib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8997",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "Al-Safaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8998",
    "CITYNAME_AR": "الدراويش",
    "CITYNAME_EN": "Al-Drawish ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "8999",
    "CITYNAME_AR": "حليس",
    "CITYNAME_EN": "Halis ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9000",
    "CITYNAME_AR": "الدركه",
    "CITYNAME_EN": "Al Darkah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9001",
    "CITYNAME_AR": "المقصره",
    "CITYNAME_EN": "Al-Maqsarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9002",
    "CITYNAME_AR": "الحلفه",
    "CITYNAME_EN": "Al-Halifah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9003",
    "CITYNAME_AR": "وادي ممنا",
    "CITYNAME_EN": "Wadi Mamna",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9004",
    "CITYNAME_AR": "وادي الأحسبة",
    "CITYNAME_EN": "Wadi Al Ahsabah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9005",
    "CITYNAME_AR": "الضبر الاسفل",
    "CITYNAME_EN": "Al-Dabr (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9006",
    "CITYNAME_AR": "الدهيناء",
    "CITYNAME_EN": "Al-Dahnaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9007",
    "CITYNAME_AR": "نشمه",
    "CITYNAME_EN": "Nashmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9008",
    "CITYNAME_AR": "الحاج",
    "CITYNAME_EN": "Alhaju",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "9009",
    "CITYNAME_AR": "الدعارمة",
    "CITYNAME_EN": "Aldaearima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "9010",
    "CITYNAME_AR": "النقيع",
    "CITYNAME_EN": "Al Negea'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9011",
    "CITYNAME_AR": "صدع الضريس",
    "CITYNAME_EN": "Sad' Al-Daris ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9012",
    "CITYNAME_AR": "حليبه ومخطط النقيع الزراعي رقم (1)",
    "CITYNAME_EN": "Halibah, and Al-Naqi' Agricultural Scheme No. 1",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9013",
    "CITYNAME_AR": "النجادى",
    "CITYNAME_EN": "Al-Najady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9014",
    "CITYNAME_AR": "الخرسعه",
    "CITYNAME_EN": "Al-Kharsa'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9015",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Al Rofaya'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9016",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9017",
    "CITYNAME_AR": "مخطط ال شبوه",
    "CITYNAME_EN": "Mukhatat Al Shebwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9018",
    "CITYNAME_AR": "شريفه",
    "CITYNAME_EN": "Sharifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9019",
    "CITYNAME_AR": "المحتجبه",
    "CITYNAME_EN": "Al-Mohtajibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9020",
    "CITYNAME_AR": "الشقيقه",
    "CITYNAME_EN": "Al-Shaqiqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9021",
    "CITYNAME_AR": "العميدات",
    "CITYNAME_EN": "Al-Omaidat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9022",
    "CITYNAME_AR": "الحشرج",
    "CITYNAME_EN": "Al Hashraj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9023",
    "CITYNAME_AR": "الثنو",
    "CITYNAME_EN": "Al-Thenw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9024",
    "CITYNAME_AR": "خيبر",
    "CITYNAME_EN": "Khaybar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9025",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Al-Barqaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9026",
    "CITYNAME_AR": "الجنينه",
    "CITYNAME_EN": "Al Junainah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9027",
    "CITYNAME_AR": "ندوان",
    "CITYNAME_EN": "Nadwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9028",
    "CITYNAME_AR": "عيدان",
    "CITYNAME_EN": "Eidan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9029",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Al Raudah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9030",
    "CITYNAME_AR": "عصلان",
    "CITYNAME_EN": "Aslan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9031",
    "CITYNAME_AR": "مخطط القاع",
    "CITYNAME_EN": "Al-Qa' Scheme ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9032",
    "CITYNAME_AR": "دويرج ( تعيلان )",
    "CITYNAME_EN": "Dowayrij (Ta'lan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9033",
    "CITYNAME_AR": "الجريب",
    "CITYNAME_EN": "Al-Jarib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9034",
    "CITYNAME_AR": "بئر بن سرح",
    "CITYNAME_EN": "Bir Ibn Serh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9035",
    "CITYNAME_AR": "الحازمى",
    "CITYNAME_EN": "Al Hazemi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9036",
    "CITYNAME_AR": "صدع",
    "CITYNAME_EN": "Sad' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9037",
    "CITYNAME_AR": "وفاء",
    "CITYNAME_EN": "Wafaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9038",
    "CITYNAME_AR": "العليب",
    "CITYNAME_EN": "Al-Alib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9039",
    "CITYNAME_AR": "النهقه",
    "CITYNAME_EN": "Al-Nahqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9040",
    "CITYNAME_AR": "البعيريه",
    "CITYNAME_EN": "Al-Bo'ayriyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9041",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9042",
    "CITYNAME_AR": "الميراد (الهوين)",
    "CITYNAME_EN": "Al-Mirad (Al-Huwayn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9043",
    "CITYNAME_AR": "القصبانه",
    "CITYNAME_EN": "Al-Qasbanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9044",
    "CITYNAME_AR": "خيبر",
    "CITYNAME_EN": "Khaybar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9045",
    "CITYNAME_AR": "الضبعانه (شركه الخضير)",
    "CITYNAME_EN": "Al-Dab'anah (Al-Khadir Company)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9046",
    "CITYNAME_AR": "المنتزه",
    "CITYNAME_EN": "Al-Montazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9047",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9048",
    "CITYNAME_AR": "الاصيهبي",
    "CITYNAME_EN": "Al-Asihby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9049",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9050",
    "CITYNAME_AR": "العبل",
    "CITYNAME_EN": "Al Abl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9051",
    "CITYNAME_AR": "مهيوب (الجحر)",
    "CITYNAME_EN": "Mahyub (Al-Hejr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9052",
    "CITYNAME_AR": "اجراف",
    "CITYNAME_EN": "Ajraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9053",
    "CITYNAME_AR": "الباقره",
    "CITYNAME_EN": "Al-Baqirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9054",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9055",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9056",
    "CITYNAME_AR": "حصن المسهر",
    "CITYNAME_EN": "Hisn Al-Misher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9057",
    "CITYNAME_AR": "طوقه",
    "CITYNAME_EN": "Tuqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9058",
    "CITYNAME_AR": "شعب النجود",
    "CITYNAME_EN": "She'b Al-Najud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9059",
    "CITYNAME_AR": "حقب",
    "CITYNAME_EN": "Haqb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9060",
    "CITYNAME_AR": "القلقله",
    "CITYNAME_EN": "Al-Qalqalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9061",
    "CITYNAME_AR": "السلوليات",
    "CITYNAME_EN": "Al-Saluliyat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9062",
    "CITYNAME_AR": "البهيم (الحصن)",
    "CITYNAME_EN": "Al-Bahim (Al-Hisn) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9063",
    "CITYNAME_AR": "ارمك",
    "CITYNAME_EN": "Irmek ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9064",
    "CITYNAME_AR": "خنيزة",
    "CITYNAME_EN": "Khanizah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9065",
    "CITYNAME_AR": "الظهي",
    "CITYNAME_EN": "Al-Zaha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9066",
    "CITYNAME_AR": "سنومه الحجيرات",
    "CITYNAME_EN": "Sanumah Al-Hujayrat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9067",
    "CITYNAME_AR": "الصفقين",
    "CITYNAME_EN": "Al-Safqin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9068",
    "CITYNAME_AR": "الظهيه",
    "CITYNAME_EN": "Al-Zahya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9069",
    "CITYNAME_AR": "الفطحه",
    "CITYNAME_EN": "Al-Fathah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9070",
    "CITYNAME_AR": "الجوه ( العليا والسفلى)",
    "CITYNAME_EN": "Al-Juwah (the upper and the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9071",
    "CITYNAME_AR": "سنومة ال زياد",
    "CITYNAME_EN": "Sanumah Al Zyad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9072",
    "CITYNAME_AR": "النظارة",
    "CITYNAME_EN": "Al-Nezarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9073",
    "CITYNAME_AR": "العريجاء",
    "CITYNAME_EN": "Al-Orayjaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9074",
    "CITYNAME_AR": "الرجايز",
    "CITYNAME_EN": "Al-Rjeyiz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9075",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al-Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9076",
    "CITYNAME_AR": "الملاقى",
    "CITYNAME_EN": "Al-Malaqi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9077",
    "CITYNAME_AR": "مهد شمسين",
    "CITYNAME_EN": "Mahd Shamsin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9078",
    "CITYNAME_AR": "دفر خرشان",
    "CITYNAME_EN": "Dafr Kharshan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9079",
    "CITYNAME_AR": "ابو عشره",
    "CITYNAME_EN": "Abu Ashrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9080",
    "CITYNAME_AR": "مشنيه",
    "CITYNAME_EN": "Mashinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9081",
    "CITYNAME_AR": "نابط والملح",
    "CITYNAME_EN": "Nabit and Al-Malh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9082",
    "CITYNAME_AR": "العضبه",
    "CITYNAME_EN": "Al-Adbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9083",
    "CITYNAME_AR": "الطريف الاسفل",
    "CITYNAME_EN": "Al-Tarif the lower",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9084",
    "CITYNAME_AR": "الطريف الأعلى",
    "CITYNAME_EN": "Al-Tarif the upper",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9085",
    "CITYNAME_AR": "الحجيلاء",
    "CITYNAME_EN": "Al-Hujaylaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9086",
    "CITYNAME_AR": "بئر قوقاء (قرية شرق البئر)",
    "CITYNAME_EN": "Bir Quqaa (Shark Al-Bir Village) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9087",
    "CITYNAME_AR": "قرية قوقاء",
    "CITYNAME_EN": "Quqaa Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9088",
    "CITYNAME_AR": "جلان",
    "CITYNAME_EN": "Jalan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9089",
    "CITYNAME_AR": "ظهي الحجيرات",
    "CITYNAME_EN": "Zaha Al-Hujayrat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9090",
    "CITYNAME_AR": "القوباء (النجد)",
    "CITYNAME_EN": "Al-Qubaa (Al-Najd)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9091",
    "CITYNAME_AR": "السافح",
    "CITYNAME_EN": "Al-Safih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9092",
    "CITYNAME_AR": "الخميسات والفوار",
    "CITYNAME_EN": "Al-Khamisat and Al-Fawar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9093",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9094",
    "CITYNAME_AR": "ناث",
    "CITYNAME_EN": "Nath ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9095",
    "CITYNAME_AR": "شعبه دغسان",
    "CITYNAME_EN": "She'bah Daghsan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9096",
    "CITYNAME_AR": "الفاجه",
    "CITYNAME_EN": "Al-Fajah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9097",
    "CITYNAME_AR": "الحمضه والحميضه",
    "CITYNAME_EN": "Al-Hemdah and Al-Hamidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9098",
    "CITYNAME_AR": "مزارع السريحه",
    "CITYNAME_EN": "Al-Sarihah Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9099",
    "CITYNAME_AR": "بغبغ الاعلى",
    "CITYNAME_EN": "Baghbagh (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9100",
    "CITYNAME_AR": "بغبغ الاسفل",
    "CITYNAME_EN": "Baghbagh (the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9101",
    "CITYNAME_AR": "مزارع مشاة والمربد",
    "CITYNAME_EN": "mashat and Al-marid Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9102",
    "CITYNAME_AR": "المحاله",
    "CITYNAME_EN": "Al-Mehalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9103",
    "CITYNAME_AR": "الطبقات",
    "CITYNAME_EN": "Al-Tabaqat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9104",
    "CITYNAME_AR": "قرا",
    "CITYNAME_EN": "Qarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9105",
    "CITYNAME_AR": "شعبه القريه",
    "CITYNAME_EN": "She'bah Al-Qorbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9106",
    "CITYNAME_AR": "اضعان",
    "CITYNAME_EN": "Ad'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9107",
    "CITYNAME_AR": "ميخان",
    "CITYNAME_EN": "Mikhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9108",
    "CITYNAME_AR": "البهيماالسفلى",
    "CITYNAME_EN": "Al-Bahima (the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9109",
    "CITYNAME_AR": "البهيماالعليا",
    "CITYNAME_EN": "Al-Bahima (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9110",
    "CITYNAME_AR": "قطبه",
    "CITYNAME_EN": "Qatbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9111",
    "CITYNAME_AR": "الحشه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9112",
    "CITYNAME_AR": "مظلل الاسفل",
    "CITYNAME_EN": "Mazll (the lower)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9113",
    "CITYNAME_AR": "مظلل الاعلى",
    "CITYNAME_EN": "Mazll (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9114",
    "CITYNAME_AR": "الموطن",
    "CITYNAME_EN": "Al-Mawtin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9115",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Al-Hamatah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9116",
    "CITYNAME_AR": "الجاره",
    "CITYNAME_EN": "Al-Jarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9117",
    "CITYNAME_AR": "ذى عشار",
    "CITYNAME_EN": "Zi Eshar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9118",
    "CITYNAME_AR": "شعبة فيصل",
    "CITYNAME_EN": "She'bah Faysal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9119",
    "CITYNAME_AR": "النقرة",
    "CITYNAME_EN": "Al-Naqrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9120",
    "CITYNAME_AR": "ارنب",
    "CITYNAME_EN": "Arnab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9121",
    "CITYNAME_AR": "ال حريش",
    "CITYNAME_EN": "Al Harish",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9122",
    "CITYNAME_AR": "الميراد",
    "CITYNAME_EN": "Al-Mirad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9123",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9124",
    "CITYNAME_AR": "القوباء القديمه",
    "CITYNAME_EN": "Al-Qubaa Al-Qadimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9125",
    "CITYNAME_AR": "الضفارة",
    "CITYNAME_EN": "Al-Dafarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9126",
    "CITYNAME_AR": "بئر ملحه ترث",
    "CITYNAME_EN": "Bir Melhah Terth ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9127",
    "CITYNAME_AR": "مهر",
    "CITYNAME_EN": "Mahr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9128",
    "CITYNAME_AR": "المؤامرة",
    "CITYNAME_EN": "Al-Mowamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9129",
    "CITYNAME_AR": "مهد الفيض",
    "CITYNAME_EN": "Mahd Al-Fayd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9130",
    "CITYNAME_AR": "الجفرة",
    "CITYNAME_EN": "Al-Jafrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9131",
    "CITYNAME_AR": "العياش",
    "CITYNAME_EN": "Al-Ayash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9132",
    "CITYNAME_AR": "الروقيه",
    "CITYNAME_EN": "Al-Ruqiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9133",
    "CITYNAME_AR": "الجليفيه",
    "CITYNAME_EN": "Al-Jalifiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9134",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9135",
    "CITYNAME_AR": "المسيلفه",
    "CITYNAME_EN": "Al-Masilfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9136",
    "CITYNAME_AR": "ظها",
    "CITYNAME_EN": "Zaha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9137",
    "CITYNAME_AR": "وردان",
    "CITYNAME_EN": "Wardan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9138",
    "CITYNAME_AR": "الميراد وقرن ظبى",
    "CITYNAME_EN": "Al-Mirad and Qarn Zaby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9139",
    "CITYNAME_AR": "جبوب الغفرات (الرجيفه)",
    "CITYNAME_EN": "Juyub Al-Ghafarat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9140",
    "CITYNAME_AR": "الركيه",
    "CITYNAME_EN": "Al-Rakbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9141",
    "CITYNAME_AR": "المعقل",
    "CITYNAME_EN": "Al-Ma'qil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9142",
    "CITYNAME_AR": "الاطيفح",
    "CITYNAME_EN": "Al-Otayfih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9143",
    "CITYNAME_AR": "البشامه",
    "CITYNAME_EN": "Al Bashma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9144",
    "CITYNAME_AR": "الصبيخه",
    "CITYNAME_EN": "Al Subaykha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9145",
    "CITYNAME_AR": "معاذ",
    "CITYNAME_EN": "Mo'az ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9146",
    "CITYNAME_AR": "الشعب الاسود",
    "CITYNAME_EN": "Al-She'b Al-Aswad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9147",
    "CITYNAME_AR": "لقفله",
    "CITYNAME_EN": "Laqfalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9148",
    "CITYNAME_AR": "المسمى",
    "CITYNAME_EN": "Al-Mosamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9149",
    "CITYNAME_AR": "قرية خالد",
    "CITYNAME_EN": "Khalid Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9150",
    "CITYNAME_AR": "العقيلين",
    "CITYNAME_EN": "Al-Aqilin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9151",
    "CITYNAME_AR": "الصيهيد",
    "CITYNAME_EN": "Al-Sihid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9152",
    "CITYNAME_AR": "الاساحلة",
    "CITYNAME_EN": "Al-Asahilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9153",
    "CITYNAME_AR": "النسعي",
    "CITYNAME_EN": "Al-Nas'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9154",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Al Sour",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9155",
    "CITYNAME_AR": "المضابعيه (الرقبه)",
    "CITYNAME_EN": "Al-Madabi'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9156",
    "CITYNAME_AR": "الحجابة",
    "CITYNAME_EN": "Al-Hajabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9157",
    "CITYNAME_AR": "لبرة العلياء",
    "CITYNAME_EN": "Librah (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9158",
    "CITYNAME_AR": "لبرة السفلى",
    "CITYNAME_EN": "Librah (the lower) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9159",
    "CITYNAME_AR": "أم شريم",
    "CITYNAME_EN": "Umm Shuraym",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9160",
    "CITYNAME_AR": "شغبة فروان",
    "CITYNAME_EN": "Shaghbah Ferwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9161",
    "CITYNAME_AR": "جليجل الطويل",
    "CITYNAME_EN": "Jalijel Al-Tawil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9162",
    "CITYNAME_AR": "جليجل القصير",
    "CITYNAME_EN": "Jolayjil Al-Qasir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9163",
    "CITYNAME_AR": "مطبق",
    "CITYNAME_EN": "Metbiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9164",
    "CITYNAME_AR": "خولان",
    "CITYNAME_EN": "Khulan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9165",
    "CITYNAME_AR": "مرشد طويل",
    "CITYNAME_EN": "Mershid Tawil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9166",
    "CITYNAME_AR": "مرشد القصير",
    "CITYNAME_EN": "Morshid Al-Qasir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9167",
    "CITYNAME_AR": "الجعيده",
    "CITYNAME_EN": "Al-Ju'aydah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9168",
    "CITYNAME_AR": "شعبة محمد { المهامل }",
    "CITYNAME_EN": "Shi'bah Mohamed (Al-Mhamil) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9169",
    "CITYNAME_AR": "العوص",
    "CITYNAME_EN": "Al-Aws",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9170",
    "CITYNAME_AR": "الشارب",
    "CITYNAME_EN": "Al-Sharib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9171",
    "CITYNAME_AR": "الدحيمي والكين",
    "CITYNAME_EN": "Al-Dehimy and Al-Kein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9172",
    "CITYNAME_AR": "لقمان الطويل",
    "CITYNAME_EN": "Loqman Al-Tawil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9173",
    "CITYNAME_AR": "بالحطيط (لقمان القصير)",
    "CITYNAME_EN": "Belhatit (Loqman Al-Qasir) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9174",
    "CITYNAME_AR": "القهيب والدجاحه",
    "CITYNAME_EN": "Al-Qhib and Al-Dajahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9175",
    "CITYNAME_AR": "البيضاء (الابره)",
    "CITYNAME_EN": "Al-Baydaa (Al-Ibrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9176",
    "CITYNAME_AR": "فرحه",
    "CITYNAME_EN": "Farhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9177",
    "CITYNAME_AR": "ام شحمان",
    "CITYNAME_EN": "Umm Shahman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9178",
    "CITYNAME_AR": "الورديه وخريصان",
    "CITYNAME_EN": "Al-Wardiyah and Kharisan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9179",
    "CITYNAME_AR": "بال عجاج",
    "CITYNAME_EN": "Bal Ajaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9180",
    "CITYNAME_AR": "الهشيمه",
    "CITYNAME_EN": "Al-Hashimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9181",
    "CITYNAME_AR": "الصميماء",
    "CITYNAME_EN": "Al-Samimaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9182",
    "CITYNAME_AR": "المرواني",
    "CITYNAME_EN": "Al-Marwany ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9183",
    "CITYNAME_AR": "الصعلية",
    "CITYNAME_EN": "Al-Sa'liyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9184",
    "CITYNAME_AR": "السلمة",
    "CITYNAME_EN": "Al-Salmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9185",
    "CITYNAME_AR": "الربوة",
    "CITYNAME_EN": "Ar Rabwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9186",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Al-Farsh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9187",
    "CITYNAME_AR": "وادي اثلة",
    "CITYNAME_EN": "Wadi Athlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9188",
    "CITYNAME_AR": "المعقد",
    "CITYNAME_EN": "Al-Ma'qad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9189",
    "CITYNAME_AR": "مشعوب",
    "CITYNAME_EN": "Mash'ub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9190",
    "CITYNAME_AR": "وجران",
    "CITYNAME_EN": "Wajran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9191",
    "CITYNAME_AR": "الراكه",
    "CITYNAME_EN": "Al-Rakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9192",
    "CITYNAME_AR": "البرك",
    "CITYNAME_EN": "Albarik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9193",
    "CITYNAME_AR": "المره",
    "CITYNAME_EN": "Almarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9194",
    "CITYNAME_AR": "المطلاه",
    "CITYNAME_EN": "Almatallah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9195",
    "CITYNAME_AR": "الداهن",
    "CITYNAME_EN": "Aldaahin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9196",
    "CITYNAME_AR": "الحشا",
    "CITYNAME_EN": "Alhashsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9197",
    "CITYNAME_AR": "البيض",
    "CITYNAME_EN": "Albyd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9198",
    "CITYNAME_AR": "الصحين",
    "CITYNAME_EN": "Alsahin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9199",
    "CITYNAME_AR": "الحثيل",
    "CITYNAME_EN": "Alhathil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9200",
    "CITYNAME_AR": "منغل",
    "CITYNAME_EN": "Manghal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9201",
    "CITYNAME_AR": "المنشب",
    "CITYNAME_EN": "Almunashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9202",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "Alsuwd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9203",
    "CITYNAME_AR": "دبساء",
    "CITYNAME_EN": "Dabsa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9204",
    "CITYNAME_AR": "الفصله",
    "CITYNAME_EN": "Alfasluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9205",
    "CITYNAME_AR": "المرصوفه",
    "CITYNAME_EN": "Almarsufuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9206",
    "CITYNAME_AR": "الصغوي",
    "CITYNAME_EN": "Alsaghuiu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9207",
    "CITYNAME_AR": "الصفقه",
    "CITYNAME_EN": "Alsafquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9208",
    "CITYNAME_AR": "الشرفه ( كرش)",
    "CITYNAME_EN": "Alsharfuh ( Kursh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9209",
    "CITYNAME_AR": "المربا",
    "CITYNAME_EN": "Almaraba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9210",
    "CITYNAME_AR": "سحر المظ",
    "CITYNAME_EN": "Sahar Almiza",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9211",
    "CITYNAME_AR": "المجدر",
    "CITYNAME_EN": "Almajdar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9212",
    "CITYNAME_AR": "الجلد",
    "CITYNAME_EN": "Aljulud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9213",
    "CITYNAME_AR": "الرماده",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9214",
    "CITYNAME_AR": "العذبه",
    "CITYNAME_EN": "Aleadhabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9215",
    "CITYNAME_AR": "المشغله",
    "CITYNAME_EN": "Almashghaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9216",
    "CITYNAME_AR": "السحله",
    "CITYNAME_EN": "Alsihaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9217",
    "CITYNAME_AR": "الحصاحص",
    "CITYNAME_EN": "Alhasahis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9218",
    "CITYNAME_AR": "صمات",
    "CITYNAME_EN": "Samat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9219",
    "CITYNAME_AR": "الطرقه (الشجنه)",
    "CITYNAME_EN": "Altaruquh (Alshajnaha)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9220",
    "CITYNAME_AR": "العمقه والزينه",
    "CITYNAME_EN": "Aleumquh Walziynuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9221",
    "CITYNAME_AR": "الحناذه",
    "CITYNAME_EN": "Alhinadhuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9222",
    "CITYNAME_AR": "الطويله",
    "CITYNAME_EN": "Altawiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9223",
    "CITYNAME_AR": "قرن البشابشه (الحقاويه)",
    "CITYNAME_EN": "Qarn Albashabishih (Alhaqawih)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9224",
    "CITYNAME_AR": "المربا",
    "CITYNAME_EN": "Almaraba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9225",
    "CITYNAME_AR": "المهكر (خنق الجوع)",
    "CITYNAME_EN": "Almuhkir (Khnuqu Aljawe)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9226",
    "CITYNAME_AR": "قرن الخربه",
    "CITYNAME_EN": "Qarn Alkharbuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9227",
    "CITYNAME_AR": "الصلامه",
    "CITYNAME_EN": "Alsalamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9228",
    "CITYNAME_AR": "القرن الابيض",
    "CITYNAME_EN": "Alqarn Al'Abyad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9229",
    "CITYNAME_AR": "معرقه",
    "CITYNAME_EN": "Muearaqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9230",
    "CITYNAME_AR": "الحثيثاء",
    "CITYNAME_EN": "Alhuthitha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9231",
    "CITYNAME_AR": "القرن الابيض",
    "CITYNAME_EN": "Alqarn Al'Abyad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9232",
    "CITYNAME_AR": "القمره",
    "CITYNAME_EN": "Alqamaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9233",
    "CITYNAME_AR": "الخاوي",
    "CITYNAME_EN": "Alkhawi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9234",
    "CITYNAME_AR": "صدمه العدن",
    "CITYNAME_EN": "Sadamuh Aleadan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9235",
    "CITYNAME_AR": "المجدر",
    "CITYNAME_EN": "Almajdar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9236",
    "CITYNAME_AR": "مقضاف (مريف)",
    "CITYNAME_EN": "Miqdaf (Mrif)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9237",
    "CITYNAME_AR": "قرن الغدير",
    "CITYNAME_EN": "Qarn Alghadir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9238",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Alqahbuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9239",
    "CITYNAME_AR": "الخرقاء (ريحانه)",
    "CITYNAME_EN": "Alkhuraqa' (Ryahanah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9240",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Almataean",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9241",
    "CITYNAME_AR": "الجرجره",
    "CITYNAME_EN": "Aljarjaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9242",
    "CITYNAME_AR": "مملح",
    "CITYNAME_EN": "Mumlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9243",
    "CITYNAME_AR": "شعب الملب",
    "CITYNAME_EN": "Shaeb Almulib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9244",
    "CITYNAME_AR": "العود",
    "CITYNAME_EN": "Aleawd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9245",
    "CITYNAME_AR": "جوف أم الثعالب",
    "CITYNAME_EN": "Jawf 'Ama Althaealib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9246",
    "CITYNAME_AR": "الصفقه",
    "CITYNAME_EN": "Alsafquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9247",
    "CITYNAME_AR": "الرافع",
    "CITYNAME_EN": "Alraafie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9248",
    "CITYNAME_AR": "راعيه أم السلام",
    "CITYNAME_EN": "Raeih Umm Alsalam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9249",
    "CITYNAME_AR": "هالة",
    "CITYNAME_EN": "Hala",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9250",
    "CITYNAME_AR": "ابو حجر",
    "CITYNAME_EN": "'Abu Hijr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9251",
    "CITYNAME_AR": "عمق",
    "CITYNAME_EN": "Eumq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9252",
    "CITYNAME_AR": "النخل",
    "CITYNAME_EN": "Alnakhl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9253",
    "CITYNAME_AR": "القشله",
    "CITYNAME_EN": "Alqashluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9254",
    "CITYNAME_AR": "العشر",
    "CITYNAME_EN": "Aleashr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9255",
    "CITYNAME_AR": "الهدمات ( قيله)",
    "CITYNAME_EN": "Services (Qilah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9256",
    "CITYNAME_AR": "الهزم",
    "CITYNAME_EN": "Alhazam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9257",
    "CITYNAME_AR": "الحشافه (القزيعات)",
    "CITYNAME_EN": "Alhishafuh (Alqazieat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9258",
    "CITYNAME_AR": "الحلفه",
    "CITYNAME_EN": "Alhilfuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9259",
    "CITYNAME_AR": "الحباسه",
    "CITYNAME_EN": "Alhabasuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9260",
    "CITYNAME_AR": "حفير الحلفه",
    "CITYNAME_EN": "Hafir Alhilfih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9261",
    "CITYNAME_AR": "حثام القحمه",
    "CITYNAME_EN": "Hutham Alqahmih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9262",
    "CITYNAME_AR": "الدخال",
    "CITYNAME_EN": "Aldikhal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9263",
    "CITYNAME_AR": "الجعره",
    "CITYNAME_EN": "Aljieruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9264",
    "CITYNAME_AR": "الحفره",
    "CITYNAME_EN": "Alhafruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9265",
    "CITYNAME_AR": "الفلاك",
    "CITYNAME_EN": "Alfalak",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9266",
    "CITYNAME_AR": "الزغباء",
    "CITYNAME_EN": "Alzughba'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9267",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Almaein",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9268",
    "CITYNAME_AR": "السواد",
    "CITYNAME_EN": "Alsawad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9269",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9270",
    "CITYNAME_AR": "الكتيله",
    "CITYNAME_EN": "Alkatiluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9271",
    "CITYNAME_AR": "حثام القضاض",
    "CITYNAME_EN": "Hutham Alqadad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9272",
    "CITYNAME_AR": "حثام المدرسه",
    "CITYNAME_EN": "Hutham Almadrasih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9273",
    "CITYNAME_AR": "حقو بران",
    "CITYNAME_EN": "Haqu Bran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9274",
    "CITYNAME_AR": "المجروم",
    "CITYNAME_EN": "Almajrum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9275",
    "CITYNAME_AR": "الاثعال",
    "CITYNAME_EN": "Alaithieal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9276",
    "CITYNAME_AR": "ابط سعيده",
    "CITYNAME_EN": "Abt Saeidah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9277",
    "CITYNAME_AR": "فيصل",
    "CITYNAME_EN": "Faysal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9278",
    "CITYNAME_AR": "غار الجحرين",
    "CITYNAME_EN": "Ghar Aljahrayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9279",
    "CITYNAME_AR": "المجنب",
    "CITYNAME_EN": "Almujnib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9280",
    "CITYNAME_AR": "عرقوب الشيبان",
    "CITYNAME_EN": "Earuqub Alshayban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9281",
    "CITYNAME_AR": "المجدره",
    "CITYNAME_EN": "Almujdaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9282",
    "CITYNAME_AR": "الغرانيق",
    "CITYNAME_EN": "Algharaniq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9283",
    "CITYNAME_AR": "حاجب بنى ذيب",
    "CITYNAME_EN": "Hajib Banaa Dhib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9284",
    "CITYNAME_AR": "الجلات",
    "CITYNAME_EN": "Aljallat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9285",
    "CITYNAME_AR": "حثام الفريده",
    "CITYNAME_EN": "Hutham Alfaridih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9286",
    "CITYNAME_AR": "عاليه",
    "CITYNAME_EN": "Ealyh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9287",
    "CITYNAME_AR": "قلب شاره",
    "CITYNAME_EN": "Qalb Sharah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9288",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9289",
    "CITYNAME_AR": "فارعة الأجرع",
    "CITYNAME_EN": "Farieat Al'Ajrae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9290",
    "CITYNAME_AR": "المتلوى",
    "CITYNAME_EN": "Almutalawaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9291",
    "CITYNAME_AR": "الغارب",
    "CITYNAME_EN": "Algharib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9292",
    "CITYNAME_AR": "وادى عمق",
    "CITYNAME_EN": "Wa'Adaa Eumq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9293",
    "CITYNAME_AR": "الجلمين",
    "CITYNAME_EN": "Aljilmayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9294",
    "CITYNAME_AR": "المدخن",
    "CITYNAME_EN": "Almadkhun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9295",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Alhariquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9296",
    "CITYNAME_AR": "الخراوع",
    "CITYNAME_EN": "Alkharawue",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9297",
    "CITYNAME_AR": "المعصبه",
    "CITYNAME_EN": "Almueasibah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9298",
    "CITYNAME_AR": "المخلفيه",
    "CITYNAME_EN": "Almukhalafih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9299",
    "CITYNAME_AR": "الأطراق",
    "CITYNAME_EN": "Al'Atraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9300",
    "CITYNAME_AR": "الرفيغ",
    "CITYNAME_EN": "Alrafigh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9301",
    "CITYNAME_AR": "الجنيدله",
    "CITYNAME_EN": "Aljnidilh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9302",
    "CITYNAME_AR": "الطرق",
    "CITYNAME_EN": "Alturuq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9303",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Alshatu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9304",
    "CITYNAME_AR": "جرولة ذهبان",
    "CITYNAME_EN": "Jurulat Dhahban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9305",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Aljawar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9306",
    "CITYNAME_AR": "النحس",
    "CITYNAME_EN": "Alnahs",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9307",
    "CITYNAME_AR": "الحره",
    "CITYNAME_EN": "Alhuruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9308",
    "CITYNAME_AR": "العضروط",
    "CITYNAME_EN": "Aleadrut",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9309",
    "CITYNAME_AR": "الفيض الساحلي",
    "CITYNAME_EN": "Hafir Almuqtar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9310",
    "CITYNAME_AR": "السال",
    "CITYNAME_EN": "Alssal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9311",
    "CITYNAME_AR": "المقص",
    "CITYNAME_EN": "Almaqasu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9312",
    "CITYNAME_AR": "مغلف",
    "CITYNAME_EN": "Mughlaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9313",
    "CITYNAME_AR": "المقحفه",
    "CITYNAME_EN": "Almaqhafuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9314",
    "CITYNAME_AR": "الخطوه (أثلة)",
    "CITYNAME_EN": "Alkhutuh (Athl)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9315",
    "CITYNAME_AR": "جرولة الباخن",
    "CITYNAME_EN": "Jurulat Albakhin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9316",
    "CITYNAME_AR": "مقشقش",
    "CITYNAME_EN": "Muqshaqish",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9317",
    "CITYNAME_AR": "الدفنه",
    "CITYNAME_EN": "Aldafnuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9318",
    "CITYNAME_AR": "لنكه",
    "CITYNAME_EN": "Lankih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9319",
    "CITYNAME_AR": "أبوحشره",
    "CITYNAME_EN": "'Abuhashruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9320",
    "CITYNAME_AR": "علايا الفيض",
    "CITYNAME_EN": "Ealaya Alfid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9321",
    "CITYNAME_AR": "الحيره (الطعنة)",
    "CITYNAME_EN": "Alhiruh (Alatenat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9322",
    "CITYNAME_AR": "المرباح",
    "CITYNAME_EN": "Almarbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9323",
    "CITYNAME_AR": "الحشافه",
    "CITYNAME_EN": "Alhishafuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9324",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9325",
    "CITYNAME_AR": "المخصر",
    "CITYNAME_EN": "Almukhasar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9326",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9327",
    "CITYNAME_AR": "المدرجه",
    "CITYNAME_EN": "Almudarijuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9328",
    "CITYNAME_AR": "الخثل",
    "CITYNAME_EN": "Alkhathl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9329",
    "CITYNAME_AR": "حليفاء الباخن",
    "CITYNAME_EN": "Halifa' Albakhin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9330",
    "CITYNAME_AR": "الحليفاء (الفلكة)",
    "CITYNAME_EN": "Alhilifa' (Alflakat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9331",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Alkhadra'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9332",
    "CITYNAME_AR": "مرتاق",
    "CITYNAME_EN": "Murtaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9333",
    "CITYNAME_AR": "الجمه",
    "CITYNAME_EN": "Aljumuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9334",
    "CITYNAME_AR": "الاجرع الشمالي",
    "CITYNAME_EN": "Alajre Alshamaliu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9335",
    "CITYNAME_AR": "الاجرع الجنوبى",
    "CITYNAME_EN": "Alajre Aljunubaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9336",
    "CITYNAME_AR": "الشقيق",
    "CITYNAME_EN": "Alshaqiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9337",
    "CITYNAME_AR": "الخرشه",
    "CITYNAME_EN": "Alkharshuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9338",
    "CITYNAME_AR": "المقيف الأشرف",
    "CITYNAME_EN": "Almuqif Al'Ashraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9339",
    "CITYNAME_AR": "المرسال",
    "CITYNAME_EN": "Almirsal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9340",
    "CITYNAME_AR": "الحثمه",
    "CITYNAME_EN": "Alhuthamuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9341",
    "CITYNAME_AR": "الطائره",
    "CITYNAME_EN": "Alttayiruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9342",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9343",
    "CITYNAME_AR": "الهاله",
    "CITYNAME_EN": "Alhaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9344",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Aleurqub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9345",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Alraqabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9346",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Alfarsh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9347",
    "CITYNAME_AR": "رافعه",
    "CITYNAME_EN": "Rafieah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9348",
    "CITYNAME_AR": "الضبع",
    "CITYNAME_EN": "Aldabae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9349",
    "CITYNAME_AR": "تريس",
    "CITYNAME_EN": "Taris",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9350",
    "CITYNAME_AR": "المستدرج",
    "CITYNAME_EN": "Almustadrij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9351",
    "CITYNAME_AR": "راعي معزة",
    "CITYNAME_EN": "Raei Mueiza",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9352",
    "CITYNAME_AR": "السدر",
    "CITYNAME_EN": "Alsadar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9353",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9354",
    "CITYNAME_AR": "القرن الأصحر",
    "CITYNAME_EN": "Alqarn Al'Ashar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9355",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Alhuquw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9356",
    "CITYNAME_AR": "الفنين",
    "CITYNAME_EN": "Alfanin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9357",
    "CITYNAME_AR": "راعى النبع",
    "CITYNAME_EN": "Raeaa Alnabae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9358",
    "CITYNAME_AR": "الحمامير",
    "CITYNAME_EN": "Alhamamir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9359",
    "CITYNAME_AR": "الجوشه",
    "CITYNAME_EN": "Aljawshuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9360",
    "CITYNAME_AR": "الحوساء",
    "CITYNAME_EN": "Alhusa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9361",
    "CITYNAME_AR": "الجشه",
    "CITYNAME_EN": "Aljushuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9362",
    "CITYNAME_AR": "قعلوله",
    "CITYNAME_EN": "Qelulah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9363",
    "CITYNAME_AR": "الكداء",
    "CITYNAME_EN": "Alkida'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9364",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9365",
    "CITYNAME_AR": "العاتق",
    "CITYNAME_EN": "Aleatiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9366",
    "CITYNAME_AR": "عبيد",
    "CITYNAME_EN": "Eubayd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9367",
    "CITYNAME_AR": "العرقوه",
    "CITYNAME_EN": "Alearuquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9368",
    "CITYNAME_AR": "راعية ام سلام",
    "CITYNAME_EN": "Raeyt Umm Salam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9369",
    "CITYNAME_AR": "الأصعر",
    "CITYNAME_EN": "Al'Asear",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9370",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Algharafuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9371",
    "CITYNAME_AR": "المكسرات",
    "CITYNAME_EN": "Almukasarat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9372",
    "CITYNAME_AR": "الجوف الشمالي",
    "CITYNAME_EN": "Aljawf Alshamaliu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9373",
    "CITYNAME_AR": "جفرير",
    "CITYNAME_EN": "Jafarir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9374",
    "CITYNAME_AR": "رهوه العشه",
    "CITYNAME_EN": "Ruhuh Aleshh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9375",
    "CITYNAME_AR": "النقيل",
    "CITYNAME_EN": "Alnaqil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9376",
    "CITYNAME_AR": "شعب بن سعد",
    "CITYNAME_EN": "Shaeb Bin Saed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9377",
    "CITYNAME_AR": "فارعة الهاد",
    "CITYNAME_EN": "Farieat Alhad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9378",
    "CITYNAME_AR": "زهب المحطة",
    "CITYNAME_EN": "Zahib Station",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9379",
    "CITYNAME_AR": "الطرقه",
    "CITYNAME_EN": "Altaruquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9380",
    "CITYNAME_AR": "الصفواء",
    "CITYNAME_EN": "Alsifwa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9381",
    "CITYNAME_AR": "المنشب",
    "CITYNAME_EN": "Almunashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9382",
    "CITYNAME_AR": "البطحاء",
    "CITYNAME_EN": "Albitaha'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9383",
    "CITYNAME_AR": "مزنقل",
    "CITYNAME_EN": "Muzanaqil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9384",
    "CITYNAME_AR": "الجوف الجنوبي",
    "CITYNAME_EN": "Aljawf Aljanubiu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9385",
    "CITYNAME_AR": "هدال",
    "CITYNAME_EN": "Hdal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9386",
    "CITYNAME_AR": "المديد",
    "CITYNAME_EN": "Almudid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9387",
    "CITYNAME_AR": "الحميضة",
    "CITYNAME_EN": "Alhamida",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9388",
    "CITYNAME_AR": "كحيلين",
    "CITYNAME_EN": "Kahilin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9389",
    "CITYNAME_AR": "كربة",
    "CITYNAME_EN": "Karaba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9390",
    "CITYNAME_AR": "لصب حمزه",
    "CITYNAME_EN": "Lsab Hamzih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9391",
    "CITYNAME_AR": "راع الجوار",
    "CITYNAME_EN": "Rae Aljiwar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9392",
    "CITYNAME_AR": "المفار",
    "CITYNAME_EN": "Almafar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9393",
    "CITYNAME_AR": "القليته",
    "CITYNAME_EN": "Alqilyatuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9394",
    "CITYNAME_AR": "زبنه",
    "CITYNAME_EN": "Zabnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9395",
    "CITYNAME_AR": "القمقة",
    "CITYNAME_EN": "Alqamqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9396",
    "CITYNAME_AR": "الاعرقة",
    "CITYNAME_EN": "Alaeriqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9397",
    "CITYNAME_AR": "مثعال",
    "CITYNAME_EN": "Mitheal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9398",
    "CITYNAME_AR": "القرون",
    "CITYNAME_EN": "Al-Qorun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9399",
    "CITYNAME_AR": "مكيحلات",
    "CITYNAME_EN": "Mokaihilat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9400",
    "CITYNAME_AR": "الخاوي",
    "CITYNAME_EN": "Al-Khawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9401",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al-Oqdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9402",
    "CITYNAME_AR": "هيجة عشار",
    "CITYNAME_EN": "Hijah Ishar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9403",
    "CITYNAME_AR": "المغرفه",
    "CITYNAME_EN": "Al-Maghrafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9404",
    "CITYNAME_AR": "الخنانه",
    "CITYNAME_EN": "Al-Khananah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9405",
    "CITYNAME_AR": "القايم",
    "CITYNAME_EN": "Al-Qayim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9406",
    "CITYNAME_AR": "الذينه",
    "CITYNAME_EN": "Al-Zinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9407",
    "CITYNAME_AR": "لبوانه",
    "CITYNAME_EN": "Libwanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9408",
    "CITYNAME_AR": "القرمل",
    "CITYNAME_EN": "Al-Qarmal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9409",
    "CITYNAME_AR": "الخفق",
    "CITYNAME_EN": "Al-Khafq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9410",
    "CITYNAME_AR": "الجنية والـمفجر",
    "CITYNAME_EN": "Al-Jeniyah and Al-Mafjer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9411",
    "CITYNAME_AR": "المزيرعه",
    "CITYNAME_EN": "Al Mozera'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9412",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Juwar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9413",
    "CITYNAME_AR": "قرن الحمام",
    "CITYNAME_EN": "Qarn Al-Hmam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9414",
    "CITYNAME_AR": "مجرد",
    "CITYNAME_EN": "Megrid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9415",
    "CITYNAME_AR": "حبيل الصوامع",
    "CITYNAME_EN": "Habil Al-Sawami' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9416",
    "CITYNAME_AR": "مراقه",
    "CITYNAME_EN": "Meraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9417",
    "CITYNAME_AR": "كوانب",
    "CITYNAME_EN": "Kwanib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9418",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Al-Zanbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9419",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9420",
    "CITYNAME_AR": "محفلي",
    "CITYNAME_EN": "Mahfala ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9421",
    "CITYNAME_AR": "النوايف",
    "CITYNAME_EN": "Al-Nawayif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9422",
    "CITYNAME_AR": "مزارع التلعه",
    "CITYNAME_EN": "Al-Tal'ah Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9423",
    "CITYNAME_AR": "مزارع ربع احمد كان (ديربي مدشوش)",
    "CITYNAME_EN": "Rab' Ahmed Kan Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9424",
    "CITYNAME_AR": "المخرم",
    "CITYNAME_EN": "Al-Makhram",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9425",
    "CITYNAME_AR": "البردان",
    "CITYNAME_EN": "Al-Bardan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9426",
    "CITYNAME_AR": "ضريب السوق",
    "CITYNAME_EN": "Darib Al-Souq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9427",
    "CITYNAME_AR": "الاحيمر",
    "CITYNAME_EN": "Al-Ohaimer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9428",
    "CITYNAME_AR": "الغدير",
    "CITYNAME_EN": "Al-Ghadir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9429",
    "CITYNAME_AR": "المطويه",
    "CITYNAME_EN": "Al-Matwiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9430",
    "CITYNAME_AR": "الحرجه (المقطر)",
    "CITYNAME_EN": "Al-Harjah (Al-Miqter) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9431",
    "CITYNAME_AR": "قوز الشريف",
    "CITYNAME_EN": "Quz Al-Sharif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9432",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9433",
    "CITYNAME_AR": "الرطرطيه",
    "CITYNAME_EN": "Al-Ratriyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9434",
    "CITYNAME_AR": "الباطن (الخرقان)",
    "CITYNAME_EN": "Al-Batin (Al-Kharqan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9435",
    "CITYNAME_AR": "مخطط شديق الجديد",
    "CITYNAME_EN": "Shadiq New Scheme ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9436",
    "CITYNAME_AR": "الخليج",
    "CITYNAME_EN": "Al-Khalij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9437",
    "CITYNAME_AR": "شديق القديم",
    "CITYNAME_EN": "Shadiq the Old  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9438",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Al Shafaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9439",
    "CITYNAME_AR": "النصباء",
    "CITYNAME_EN": "Al-Nasbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9440",
    "CITYNAME_AR": "مصر",
    "CITYNAME_EN": "Misr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9441",
    "CITYNAME_AR": "الكحيليه",
    "CITYNAME_EN": "Al-Kohailiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9442",
    "CITYNAME_AR": "قوزيه النشاوا",
    "CITYNAME_EN": "Quziyah Al-Nashawa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9443",
    "CITYNAME_AR": "الحريش",
    "CITYNAME_EN": "Al-Harish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9444",
    "CITYNAME_AR": "الجهليه",
    "CITYNAME_EN": "Al-Jahliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9445",
    "CITYNAME_AR": "الغدنه الجديده",
    "CITYNAME_EN": "The New Al-Ghadnah  ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9446",
    "CITYNAME_AR": "الغدنه القديمه",
    "CITYNAME_EN": "The Old Al-Ghadnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9447",
    "CITYNAME_AR": "هجره الاعامشه",
    "CITYNAME_EN": "Hirah A-A'amishah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9448",
    "CITYNAME_AR": "الجبارين",
    "CITYNAME_EN": "Al-Jabbarin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9449",
    "CITYNAME_AR": "حرجه المخرم",
    "CITYNAME_EN": "Herjah Al-Makhram ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9450",
    "CITYNAME_AR": "تواثن",
    "CITYNAME_EN": "Tawathin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9451",
    "CITYNAME_AR": "قوزيه المخرم",
    "CITYNAME_EN": "Quziyah Al-Mekhrim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9452",
    "CITYNAME_AR": "القوز (المخرم)",
    "CITYNAME_EN": "Al-Quz (Al-Mekhrim)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9453",
    "CITYNAME_AR": "الحرمل",
    "CITYNAME_EN": "Al-Harmal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9454",
    "CITYNAME_AR": "الدويسيه",
    "CITYNAME_EN": "Al-Duwaysiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9455",
    "CITYNAME_AR": "الأخيضر",
    "CITYNAME_EN": "Al-okhaider",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9456",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9457",
    "CITYNAME_AR": "الغراء",
    "CITYNAME_EN": "Al-Gharaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9458",
    "CITYNAME_AR": "أبو خيال بالخليج",
    "CITYNAME_EN": "Abu Khayal in Al-Khalij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9459",
    "CITYNAME_AR": "تباله",
    "CITYNAME_EN": "Tabalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9460",
    "CITYNAME_AR": "السعريه",
    "CITYNAME_EN": "Al-Si'riyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9461",
    "CITYNAME_AR": "المورده",
    "CITYNAME_EN": "Al-Mawrdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9462",
    "CITYNAME_AR": "ارناح",
    "CITYNAME_EN": "Arnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9463",
    "CITYNAME_AR": "المصدره(المسدره)",
    "CITYNAME_EN": "Al-Masdarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9464",
    "CITYNAME_AR": "الكيعين",
    "CITYNAME_EN": "Al-Ke'in ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9465",
    "CITYNAME_AR": "مسفره",
    "CITYNAME_EN": "Masfarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9466",
    "CITYNAME_AR": "الحفيا القديمه",
    "CITYNAME_EN": "The Old Al-Hifya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9467",
    "CITYNAME_AR": "الحفيا الجديده",
    "CITYNAME_EN": "The New Al-Hifya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9468",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Moshref",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9469",
    "CITYNAME_AR": "اضمينان",
    "CITYNAME_EN": "Adminan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9470",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9471",
    "CITYNAME_AR": "نحاص القديم",
    "CITYNAME_EN": "The Old Nehas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9472",
    "CITYNAME_AR": "نحاص الجديد",
    "CITYNAME_EN": "The New Nehas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9473",
    "CITYNAME_AR": "الشارب",
    "CITYNAME_EN": "Al-Sharib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9474",
    "CITYNAME_AR": "بريم",
    "CITYNAME_EN": "Berim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9475",
    "CITYNAME_AR": "البصيره",
    "CITYNAME_EN": "Al-Bosayrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9476",
    "CITYNAME_AR": "الجعبه",
    "CITYNAME_EN": "Al-JU'BAH ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9477",
    "CITYNAME_AR": "مبايع",
    "CITYNAME_EN": "Mabayi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9478",
    "CITYNAME_AR": "منيع",
    "CITYNAME_EN": "Mani' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9479",
    "CITYNAME_AR": "شعب جمل",
    "CITYNAME_EN": "Shi'b Jamal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9480",
    "CITYNAME_AR": "الشقيريه",
    "CITYNAME_EN": "Al-Shuqairiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9481",
    "CITYNAME_AR": "الخربه ومرزوقه",
    "CITYNAME_EN": "Al-Kharbah and Marzuqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9482",
    "CITYNAME_AR": "الحشا",
    "CITYNAME_EN": "Al-Hasha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9483",
    "CITYNAME_AR": "الهتيمى",
    "CITYNAME_EN": "Al-Hatimy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9484",
    "CITYNAME_AR": "الدغماء",
    "CITYNAME_EN": "Al-Daghmaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9485",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al-Ma'qer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9486",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9487",
    "CITYNAME_AR": "الفيصليه",
    "CITYNAME_EN": "Al Faisaliyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9488",
    "CITYNAME_AR": "بينا والحميمه",
    "CITYNAME_EN": "Bina and Al-Hamimiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9489",
    "CITYNAME_AR": "ابو سريحه",
    "CITYNAME_EN": "Abu Srehah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9490",
    "CITYNAME_AR": "القريحاء",
    "CITYNAME_EN": "Al-Qurayhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9491",
    "CITYNAME_AR": "الظبيه والدويمه",
    "CITYNAME_EN": "Al-Zabiyah and Al-Dewimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9492",
    "CITYNAME_AR": "الشريفى",
    "CITYNAME_EN": "Al-Sherify",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9493",
    "CITYNAME_AR": "البرنيه",
    "CITYNAME_EN": "Al-Barniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9494",
    "CITYNAME_AR": "الحميمه وابو خضيبه",
    "CITYNAME_EN": "Al-Hamimah and Abu Khadibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9495",
    "CITYNAME_AR": "الناصريه",
    "CITYNAME_EN": "Al-Naseriyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9496",
    "CITYNAME_AR": "شرس",
    "CITYNAME_EN": "Sharis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9497",
    "CITYNAME_AR": "عرق معاويه",
    "CITYNAME_EN": "Irq Mo'awyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9498",
    "CITYNAME_AR": "معشر",
    "CITYNAME_EN": "Mi'shar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9499",
    "CITYNAME_AR": "معيشير",
    "CITYNAME_EN": "Mo'ayshir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9500",
    "CITYNAME_AR": "الجليبات",
    "CITYNAME_EN": "Al-Jalibat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9501",
    "CITYNAME_AR": "العبلاء",
    "CITYNAME_EN": "Al Abla'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9502",
    "CITYNAME_AR": "الصبيخه",
    "CITYNAME_EN": "Al Subaykha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9503",
    "CITYNAME_AR": "الفيصليه",
    "CITYNAME_EN": "Alfayasaliuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9504",
    "CITYNAME_AR": "ام الهشيم",
    "CITYNAME_EN": "Umm Alhashim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9505",
    "CITYNAME_AR": "الوهطات",
    "CITYNAME_EN": "Alwahatat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9506",
    "CITYNAME_AR": "ال حارث(الفرعة)",
    "CITYNAME_EN": "Al Harthi(Alfreat)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9507",
    "CITYNAME_AR": "ساموده",
    "CITYNAME_EN": "Samudh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9508",
    "CITYNAME_AR": "ال غنيمه (ام سمرة)",
    "CITYNAME_EN": "Al Ghanimuh (Ama Samara)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9509",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Alrafayie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9510",
    "CITYNAME_AR": "بينه",
    "CITYNAME_EN": "Baynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9511",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Alrabuwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9512",
    "CITYNAME_AR": "الحرف الجازع",
    "CITYNAME_EN": "Alharaf Aljazie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9513",
    "CITYNAME_AR": "المبعوث",
    "CITYNAME_EN": "Almabeuth",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9514",
    "CITYNAME_AR": "الفاخريه",
    "CITYNAME_EN": "Alfakhirih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9515",
    "CITYNAME_AR": "الجمد",
    "CITYNAME_EN": "Aljamad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9516",
    "CITYNAME_AR": "صبيخه النخل القديمه",
    "CITYNAME_EN": "Sabikhuh Alnakhl Alqadiumuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9517",
    "CITYNAME_AR": "غليله الغدرى",
    "CITYNAME_EN": "Ghalilh Alghadraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9518",
    "CITYNAME_AR": "كتنه",
    "CITYNAME_EN": "Katanah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9519",
    "CITYNAME_AR": "النخيل",
    "CITYNAME_EN": "Alnakhil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9520",
    "CITYNAME_AR": "الجثوه",
    "CITYNAME_EN": "Aljuthuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9521",
    "CITYNAME_AR": "ابار الجفر",
    "CITYNAME_EN": "Abar Aljafr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9522",
    "CITYNAME_AR": "الحفار",
    "CITYNAME_EN": "Alhifar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9523",
    "CITYNAME_AR": "البردان",
    "CITYNAME_EN": "Alburdan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9524",
    "CITYNAME_AR": "دريع",
    "CITYNAME_EN": "Darie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9525",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Alsharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9526",
    "CITYNAME_AR": "آل غصاب",
    "CITYNAME_EN": "Al Ghasab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9527",
    "CITYNAME_AR": "الشميط",
    "CITYNAME_EN": "Alshamit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9528",
    "CITYNAME_AR": "السلام",
    "CITYNAME_EN": "Alsalam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9529",
    "CITYNAME_AR": "ابومهاريس",
    "CITYNAME_EN": "Abwmharis",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9530",
    "CITYNAME_AR": "أم جمادة",
    "CITYNAME_EN": "Umm Jamada",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9531",
    "CITYNAME_AR": "المسيل",
    "CITYNAME_EN": "Almusayil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9532",
    "CITYNAME_AR": "الفدعر",
    "CITYNAME_EN": "Alfadear",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9533",
    "CITYNAME_AR": "ال صينة",
    "CITYNAME_EN": "Al Sina",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9534",
    "CITYNAME_AR": "ال قريش",
    "CITYNAME_EN": "Al Quraysh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9535",
    "CITYNAME_AR": "ابو خيال",
    "CITYNAME_EN": "'Abu Khial",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9536",
    "CITYNAME_AR": "الجحفة",
    "CITYNAME_EN": "Aljahfa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9537",
    "CITYNAME_AR": "الشذبة",
    "CITYNAME_EN": "Alshadhaba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9538",
    "CITYNAME_AR": "ام السريحة",
    "CITYNAME_EN": "Umm Alsryh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9539",
    "CITYNAME_AR": "الخالدية",
    "CITYNAME_EN": "Alkhalidia",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9540",
    "CITYNAME_AR": "الوشيجة",
    "CITYNAME_EN": "Alwashija",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9541",
    "CITYNAME_AR": "الصفية",
    "CITYNAME_EN": "Alsifia",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9542",
    "CITYNAME_AR": "المضيبيع",
    "CITYNAME_EN": "Almudibie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9543",
    "CITYNAME_AR": "الدومة",
    "CITYNAME_EN": "Aldawma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9544",
    "CITYNAME_AR": "الرمرام",
    "CITYNAME_EN": "Alramram",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9545",
    "CITYNAME_AR": "الشميطة",
    "CITYNAME_EN": "Alshamita",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9546",
    "CITYNAME_AR": "الرمرام",
    "CITYNAME_EN": "Alramram",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9547",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Alhuzm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9548",
    "CITYNAME_AR": "الدومة",
    "CITYNAME_EN": "Aldawma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9549",
    "CITYNAME_AR": "الشميطه",
    "CITYNAME_EN": "Alshamituh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9550",
    "CITYNAME_AR": "دريع",
    "CITYNAME_EN": "Darie",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9551",
    "CITYNAME_AR": "صمخ",
    "CITYNAME_EN": "Samkh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9552",
    "CITYNAME_AR": "المجعره (الروضه)",
    "CITYNAME_EN": "Al-Maj'arah (Al-Rawdah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9553",
    "CITYNAME_AR": "بطحان",
    "CITYNAME_EN": "Bathan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9554",
    "CITYNAME_AR": "حرف شعيان",
    "CITYNAME_EN": "Harf Shi'yan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9555",
    "CITYNAME_AR": "حرف الهتوم(الجحفا)",
    "CITYNAME_EN": "Harf Al-Hatum (Al-Jahfa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9556",
    "CITYNAME_AR": "سابه",
    "CITYNAME_EN": "Sabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9557",
    "CITYNAME_AR": "حرف ومخطط ال عموده",
    "CITYNAME_EN": "Harf, and Mukhatt Al Amudah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9558",
    "CITYNAME_AR": "حرف الحلسه",
    "CITYNAME_EN": "Harf Al-Helsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9559",
    "CITYNAME_AR": "مجحم",
    "CITYNAME_EN": "Mejhim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9560",
    "CITYNAME_AR": "حرف ال غانم",
    "CITYNAME_EN": "Harf Al Ghanim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9561",
    "CITYNAME_AR": "حرف الجرباء",
    "CITYNAME_EN": "Harf Al-Jerbaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9562",
    "CITYNAME_AR": "المذايير",
    "CITYNAME_EN": "Al-Mazayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9563",
    "CITYNAME_AR": "الموسطه (آل مطيع)",
    "CITYNAME_EN": "Al-Mositah (Al Moti')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9564",
    "CITYNAME_AR": "الهجره (الحفيره)",
    "CITYNAME_EN": "Al-Jehrah (Al-Hafirah) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9565",
    "CITYNAME_AR": "الكحيليه",
    "CITYNAME_EN": "Al-Kohailiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9566",
    "CITYNAME_AR": "ربوعه",
    "CITYNAME_EN": "Rabu'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9567",
    "CITYNAME_AR": "بن مره",
    "CITYNAME_EN": "Bin Morrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9568",
    "CITYNAME_AR": "الحاجون",
    "CITYNAME_EN": "Al-Hajun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9569",
    "CITYNAME_AR": "كتنه",
    "CITYNAME_EN": "Kantah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9570",
    "CITYNAME_AR": "ال جروان",
    "CITYNAME_EN": "Al-Jarwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9571",
    "CITYNAME_AR": "حرف الطور (نجد الطور)",
    "CITYNAME_EN": "Harf Al-Tur (Najd Al-Tur)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9572",
    "CITYNAME_AR": "أظها",
    "CITYNAME_EN": "Azha ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9573",
    "CITYNAME_AR": "القاع (هبه)",
    "CITYNAME_EN": "Al-Qa' (Hebah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9574",
    "CITYNAME_AR": "الغياض",
    "CITYNAME_EN": "Al-Ghyad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9575",
    "CITYNAME_AR": "حرف طوير",
    "CITYNAME_EN": "Harf Tuwir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9576",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9577",
    "CITYNAME_AR": "الحجف",
    "CITYNAME_EN": "Al-Hajhf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9578",
    "CITYNAME_AR": "الجفجفه",
    "CITYNAME_EN": "Al-Jafjafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9579",
    "CITYNAME_AR": "خرص",
    "CITYNAME_EN": "Khars ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9580",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9581",
    "CITYNAME_AR": "الحرج",
    "CITYNAME_EN": "Al-Herj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9582",
    "CITYNAME_AR": "الحييفة",
    "CITYNAME_EN": "Al-Heyifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9583",
    "CITYNAME_AR": "رويه بن حقله",
    "CITYNAME_EN": "Ruwiyah bin Haqlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9584",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9585",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9586",
    "CITYNAME_AR": "رويه بن حامل",
    "CITYNAME_EN": "Ruwiyah bin Hamel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9587",
    "CITYNAME_AR": "المرفق",
    "CITYNAME_EN": "Al-Mirfaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9588",
    "CITYNAME_AR": "الدريبية",
    "CITYNAME_EN": "Al-Daribiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9589",
    "CITYNAME_AR": "ال جلاف بالسويدى",
    "CITYNAME_EN": "Al Jelaf Belswidy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9590",
    "CITYNAME_AR": "المعدن",
    "CITYNAME_EN": "Al-Ma'den ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9591",
    "CITYNAME_AR": "بئر بن سرار",
    "CITYNAME_EN": "Bir Ibn Serar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9592",
    "CITYNAME_AR": "الجربا",
    "CITYNAME_EN": "Al-Jaria ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9593",
    "CITYNAME_AR": "منتزه سابه",
    "CITYNAME_EN": "Sabah Park ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9594",
    "CITYNAME_AR": "وادى الخو",
    "CITYNAME_EN": "Wadi Al-Khaw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9595",
    "CITYNAME_AR": "ال زفين",
    "CITYNAME_EN": "Al Zafin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9596",
    "CITYNAME_AR": "الشبارق",
    "CITYNAME_EN": "Al-Shabariq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9597",
    "CITYNAME_AR": "ال خميسه",
    "CITYNAME_EN": "Al Khamisah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9598",
    "CITYNAME_AR": "الضروه",
    "CITYNAME_EN": "Al-Darorah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9599",
    "CITYNAME_AR": "الميد",
    "CITYNAME_EN": "Al-Mid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9600",
    "CITYNAME_AR": "الصواح",
    "CITYNAME_EN": "Al-Suwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9601",
    "CITYNAME_AR": "الشعبه السوداء",
    "CITYNAME_EN": "Al-Ashbah Al-Sawdaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9602",
    "CITYNAME_AR": "النقب وقحفه",
    "CITYNAME_EN": "Al-Naqb and Qahfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9603",
    "CITYNAME_AR": "وعل",
    "CITYNAME_EN": "Wa'l ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9604",
    "CITYNAME_AR": "الحظه",
    "CITYNAME_EN": "Al-Hazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9605",
    "CITYNAME_AR": "آل الشيخيين وآل جرار",
    "CITYNAME_EN": "Al Al-Shaykhein and Al Jirar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9606",
    "CITYNAME_AR": "الاعاسره وآل سليم",
    "CITYNAME_EN": "Al-Aaserah and Al Selim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9607",
    "CITYNAME_AR": "نمران",
    "CITYNAME_EN": "Nemran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9608",
    "CITYNAME_AR": "ال الصناع",
    "CITYNAME_EN": "Al Al-Sena' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9609",
    "CITYNAME_AR": "الرسليه",
    "CITYNAME_EN": "Al-Rasilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9610",
    "CITYNAME_AR": "ال غزاله",
    "CITYNAME_EN": "Al-Ghazalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9611",
    "CITYNAME_AR": "ال شيبان",
    "CITYNAME_EN": "Al-Shiban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9612",
    "CITYNAME_AR": "فارعه",
    "CITYNAME_EN": "Far'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9613",
    "CITYNAME_AR": "مشنيه وال بسام",
    "CITYNAME_EN": "Mashinah and Al Bassam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9614",
    "CITYNAME_AR": "ال السحيم",
    "CITYNAME_EN": "Al Al-Sahim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9615",
    "CITYNAME_AR": "الجعادبه",
    "CITYNAME_EN": "Al-J'adibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9616",
    "CITYNAME_AR": "اليوره",
    "CITYNAME_EN": "Al-Yorah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9617",
    "CITYNAME_AR": "اللصيم",
    "CITYNAME_EN": "Allasim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9618",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9619",
    "CITYNAME_AR": "الطوف",
    "CITYNAME_EN": "Al-Tawf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9620",
    "CITYNAME_AR": "ال طلحه",
    "CITYNAME_EN": "Al Talhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9621",
    "CITYNAME_AR": "ال سمله",
    "CITYNAME_EN": "Al-Samlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9622",
    "CITYNAME_AR": "ال مفرج",
    "CITYNAME_EN": "Al Mefrij",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9623",
    "CITYNAME_AR": "ال روضان",
    "CITYNAME_EN": "Al Rawdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9624",
    "CITYNAME_AR": "ذات العلب",
    "CITYNAME_EN": "Zat Al-Olab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9625",
    "CITYNAME_AR": "ال غثران والخيشه",
    "CITYNAME_EN": "Al Ghathran and Al-Khishah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9626",
    "CITYNAME_AR": "الرزه",
    "CITYNAME_EN": "Al-Rozah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9627",
    "CITYNAME_AR": "حبقه",
    "CITYNAME_EN": "Habqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9628",
    "CITYNAME_AR": "الشعبه والمندى والمنزل (ال حسيكه)",
    "CITYNAME_EN": "Al-Shi'bah, Al-Mondy, and Al-Manzil (Al Hasikah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9629",
    "CITYNAME_AR": "ال حطام",
    "CITYNAME_EN": "Al-Hitam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9630",
    "CITYNAME_AR": "ال سعد",
    "CITYNAME_EN": "Al Sa'd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9631",
    "CITYNAME_AR": "ال الشاعر",
    "CITYNAME_EN": "Al Al-Sha'er",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9632",
    "CITYNAME_AR": "ال لهيفه",
    "CITYNAME_EN": "Al Lohayfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9633",
    "CITYNAME_AR": "ال عطيفه",
    "CITYNAME_EN": "Al Otayfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9634",
    "CITYNAME_AR": "ال محفوظ",
    "CITYNAME_EN": "Al Mahfouz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9635",
    "CITYNAME_AR": "ال الاصفاء",
    "CITYNAME_EN": "Al Al-Asfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9636",
    "CITYNAME_AR": "ال جعيد",
    "CITYNAME_EN": "Al Joayd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9637",
    "CITYNAME_AR": "ساقين",
    "CITYNAME_EN": "Saqin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9638",
    "CITYNAME_AR": "القاعد",
    "CITYNAME_EN": "Al Qaid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9639",
    "CITYNAME_AR": "حترش",
    "CITYNAME_EN": "Hatrash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9640",
    "CITYNAME_AR": "رس مسعود",
    "CITYNAME_EN": "Ras Masoud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9641",
    "CITYNAME_AR": "رهو المركب",
    "CITYNAME_EN": "Rahw Al-Markib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9642",
    "CITYNAME_AR": "المربعه",
    "CITYNAME_EN": "Al-Mari'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9643",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9644",
    "CITYNAME_AR": "رهو الخرق",
    "CITYNAME_EN": "Rahw Al-Kharq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9645",
    "CITYNAME_AR": "الأثبات",
    "CITYNAME_EN": "Al-Athbat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9646",
    "CITYNAME_AR": "المنقلع",
    "CITYNAME_EN": "Al-Monqali' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9647",
    "CITYNAME_AR": "خارف",
    "CITYNAME_EN": "Khazif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9648",
    "CITYNAME_AR": "كتفه",
    "CITYNAME_EN": "Katfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9649",
    "CITYNAME_AR": "الخياله",
    "CITYNAME_EN": "Al-Khayalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9650",
    "CITYNAME_AR": "الحينه",
    "CITYNAME_EN": "Al-Hinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9651",
    "CITYNAME_AR": "الاثبات",
    "CITYNAME_EN": "Al-Athbat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9652",
    "CITYNAME_AR": "المليبات",
    "CITYNAME_EN": "Al-Malibat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9653",
    "CITYNAME_AR": "الطوف",
    "CITYNAME_EN": "Al-Tawf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9654",
    "CITYNAME_AR": "ذات خلفين",
    "CITYNAME_EN": "Zat Khalfin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9655",
    "CITYNAME_AR": "القرون",
    "CITYNAME_EN": "Al-Qorun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9656",
    "CITYNAME_AR": "المحظر",
    "CITYNAME_EN": "Al-Mahzar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9657",
    "CITYNAME_AR": "المنكب كرفوس",
    "CITYNAME_EN": "Al-Mankab Karfous ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9658",
    "CITYNAME_AR": "الامرح",
    "CITYNAME_EN": "Al-Amrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9659",
    "CITYNAME_AR": "ال صره",
    "CITYNAME_EN": "Al Serah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9660",
    "CITYNAME_AR": "لزمه",
    "CITYNAME_EN": "Lazmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9661",
    "CITYNAME_AR": "لفت",
    "CITYNAME_EN": "Laft ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9662",
    "CITYNAME_AR": "ال جاهل ( ال عارف )",
    "CITYNAME_EN": "Al Jahel (Al Aref)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9663",
    "CITYNAME_AR": "الجحور",
    "CITYNAME_EN": "Al Johur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9664",
    "CITYNAME_AR": "القرن وال عمار وال شميله",
    "CITYNAME_EN": "Al-Qarn, Al Ammar, and Al Shamilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9665",
    "CITYNAME_AR": "ذى عتيم",
    "CITYNAME_EN": "Zi Atim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9666",
    "CITYNAME_AR": "ال الطبق",
    "CITYNAME_EN": "Al Al-Tabq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9667",
    "CITYNAME_AR": "ال ساحه والنجفه",
    "CITYNAME_EN": "Al Sahah and Al-Najfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9668",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al-Farsha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9669",
    "CITYNAME_AR": "ال حله (لتيده)",
    "CITYNAME_EN": "Al Helah (Latidah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9670",
    "CITYNAME_AR": "ال خزيم",
    "CITYNAME_EN": "Al Khazim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9671",
    "CITYNAME_AR": "وادى العين وال سلطان",
    "CITYNAME_EN": "Wadi Al-Ein and Al Sultan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9672",
    "CITYNAME_AR": "ال خريم",
    "CITYNAME_EN": "Al Kharim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9673",
    "CITYNAME_AR": "ال سودان والقرن",
    "CITYNAME_EN": "Al Sudan and Al-Qarn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9674",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9675",
    "CITYNAME_AR": "حلة الفقير",
    "CITYNAME_EN": "Helah Al-Faqir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9676",
    "CITYNAME_AR": "حلة الدومة",
    "CITYNAME_EN": "Helah Al-Domah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9677",
    "CITYNAME_AR": "حلة شعبة الجمل",
    "CITYNAME_EN": "Helah Shi'bah Al-Jamal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9678",
    "CITYNAME_AR": "حلة شعبة السدرة",
    "CITYNAME_EN": "Helah Shi'bah Al-Sedrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9679",
    "CITYNAME_AR": "محلة وضاعة",
    "CITYNAME_EN": "Mahlah Wad'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9680",
    "CITYNAME_AR": "الوفيزه (حلة شرفية)",
    "CITYNAME_EN": "Al-Wafizah (Hellah Sharafiyah) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9681",
    "CITYNAME_AR": "الوليه (حلة شرفية)",
    "CITYNAME_EN": "Al-Walyah (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9682",
    "CITYNAME_AR": "عكنان (حلة شرفية)",
    "CITYNAME_EN": "Aknan (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9683",
    "CITYNAME_AR": "بومدر (حلة شرفية)",
    "CITYNAME_EN": "Bomader (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9684",
    "CITYNAME_AR": "شبارق (حلة شرفية)",
    "CITYNAME_EN": "Shabariq (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9685",
    "CITYNAME_AR": "الهداة (حلة شرفية)",
    "CITYNAME_EN": "Al-Hodah (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9686",
    "CITYNAME_AR": "الموبل (حلة شرفية)",
    "CITYNAME_EN": "Al-Moel (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9687",
    "CITYNAME_AR": "جرادة حلة شرفية)",
    "CITYNAME_EN": "Jaradah (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9688",
    "CITYNAME_AR": "حظا (حلة شرفية)",
    "CITYNAME_EN": "Haza (Hellah Sharafiyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9689",
    "CITYNAME_AR": "ال ميسري وال عريف",
    "CITYNAME_EN": "Al Mesry and Al Arif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9690",
    "CITYNAME_AR": "الحيره وال نشوان",
    "CITYNAME_EN": "Al-Hirah and Al Nashwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9691",
    "CITYNAME_AR": "ال نبهان",
    "CITYNAME_EN": "Al Nabhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9692",
    "CITYNAME_AR": "ال عليان",
    "CITYNAME_EN": "Al Ilyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9693",
    "CITYNAME_AR": "ال مكيمل",
    "CITYNAME_EN": "Al Mokaymel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9694",
    "CITYNAME_AR": "الجاره",
    "CITYNAME_EN": "Al-Jarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9695",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al-Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9696",
    "CITYNAME_AR": "السحل",
    "CITYNAME_EN": "Al-Sahl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9697",
    "CITYNAME_AR": "العدوه",
    "CITYNAME_EN": "Al-Adawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9698",
    "CITYNAME_AR": "العرش",
    "CITYNAME_EN": "Al-Arsh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9699",
    "CITYNAME_AR": "اكرم",
    "CITYNAME_EN": "Akram ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9700",
    "CITYNAME_AR": "وادى ال مكيمل",
    "CITYNAME_EN": "Wadi Al Mokaymel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9701",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9702",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "Al-Samdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9703",
    "CITYNAME_AR": "روهب",
    "CITYNAME_EN": "Rohab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9704",
    "CITYNAME_AR": "شعيب بشما",
    "CITYNAME_EN": "Sho'ayb Bashma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9705",
    "CITYNAME_AR": "بن سند",
    "CITYNAME_EN": "Bin Sanad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9706",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9707",
    "CITYNAME_AR": "الحلاله",
    "CITYNAME_EN": "Al-Halalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9708",
    "CITYNAME_AR": "قرى علي",
    "CITYNAME_EN": "Qura Ali ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9709",
    "CITYNAME_AR": "العدوه",
    "CITYNAME_EN": "Al Adawa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9710",
    "CITYNAME_AR": "النصبه",
    "CITYNAME_EN": "Al-Nasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9711",
    "CITYNAME_AR": "الأسده",
    "CITYNAME_EN": "Al-Asdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9712",
    "CITYNAME_AR": "خارف",
    "CITYNAME_EN": "Khazif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9713",
    "CITYNAME_AR": "المعاين",
    "CITYNAME_EN": "Al-M'ayin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9714",
    "CITYNAME_AR": "القطعاء",
    "CITYNAME_EN": "Al-Qat'aa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9715",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9716",
    "CITYNAME_AR": "الحجمه",
    "CITYNAME_EN": "Al-Hajmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9717",
    "CITYNAME_AR": "حفظاء",
    "CITYNAME_EN": "Hafzaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9718",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9719",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9720",
    "CITYNAME_AR": "الدقايق",
    "CITYNAME_EN": "Al-Daqayiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9721",
    "CITYNAME_AR": "ال جميل",
    "CITYNAME_EN": "Al Jamil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9722",
    "CITYNAME_AR": "القبل",
    "CITYNAME_EN": "Al-Qobal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9723",
    "CITYNAME_AR": "ذرنا",
    "CITYNAME_EN": "Zarna ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9724",
    "CITYNAME_AR": "يانف",
    "CITYNAME_EN": "Yanif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9725",
    "CITYNAME_AR": "السهوه",
    "CITYNAME_EN": "Al-Sahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9726",
    "CITYNAME_AR": "الطلحه",
    "CITYNAME_EN": "Al-Talhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9727",
    "CITYNAME_AR": "العثارب",
    "CITYNAME_EN": "Al-Atharib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9728",
    "CITYNAME_AR": "المنبذ",
    "CITYNAME_EN": "Al-Manbaz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9729",
    "CITYNAME_AR": "العيمه (القطاره)",
    "CITYNAME_EN": "Al-Eimah (Al-Qatrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9730",
    "CITYNAME_AR": "ريمه",
    "CITYNAME_EN": "Reemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9731",
    "CITYNAME_AR": "الرخيمه",
    "CITYNAME_EN": "Al-Rakhimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9732",
    "CITYNAME_AR": "شعب اخيم",
    "CITYNAME_EN": "Shi'b Akhim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9733",
    "CITYNAME_AR": "ملحان",
    "CITYNAME_EN": "Malhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9734",
    "CITYNAME_AR": "القطاره",
    "CITYNAME_EN": "Al-Qatarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9735",
    "CITYNAME_AR": "العببه",
    "CITYNAME_EN": "Al-Eibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9736",
    "CITYNAME_AR": "ال زامل  (الكدوه)",
    "CITYNAME_EN": "Al Zamel (AL-Kadwah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9737",
    "CITYNAME_AR": "عرق مناع (ال مجمل)",
    "CITYNAME_EN": "Irq Mana' (Al Mejmel)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9738",
    "CITYNAME_AR": "المرتفع (ال مجمل)",
    "CITYNAME_EN": "Al-Mortafi' (Al-Mejmel)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9739",
    "CITYNAME_AR": "ال غبيش",
    "CITYNAME_EN": "Al Ghabish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9740",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9741",
    "CITYNAME_AR": "بني عمرو",
    "CITYNAME_EN": "Bani Amr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9742",
    "CITYNAME_AR": "لسخه",
    "CITYNAME_EN": "Laskhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9743",
    "CITYNAME_AR": "المحتطبه والفازر",
    "CITYNAME_EN": "Al-Mohtatibah and Al-fazer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9744",
    "CITYNAME_AR": "الحريضه",
    "CITYNAME_EN": "Al Haridhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9745",
    "CITYNAME_AR": "المثلث بالحريضه",
    "CITYNAME_EN": "Al-Muthalth in Al Haridhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9746",
    "CITYNAME_AR": "شط ابن شاره",
    "CITYNAME_EN": "Shat Ibn Sharah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9747",
    "CITYNAME_AR": "المصمور",
    "CITYNAME_EN": "Al-Masmour ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9748",
    "CITYNAME_AR": "العثعثه",
    "CITYNAME_EN": "Al-Athathah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9749",
    "CITYNAME_AR": "غيشانه",
    "CITYNAME_EN": "Ghishanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9750",
    "CITYNAME_AR": "المسلوم",
    "CITYNAME_EN": "Al-Maslum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9751",
    "CITYNAME_AR": "الملح",
    "CITYNAME_EN": "Al-Melh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9752",
    "CITYNAME_AR": "دغبج",
    "CITYNAME_EN": "Daghbag",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9753",
    "CITYNAME_AR": "وادي فريقه",
    "CITYNAME_EN": "Wadi Fariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9754",
    "CITYNAME_AR": "وادي الحصبه",
    "CITYNAME_EN": "Wadi AL-Hasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9755",
    "CITYNAME_AR": "الرقه",
    "CITYNAME_EN": "Al-Reqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9756",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Al-Zyarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9757",
    "CITYNAME_AR": "البدوه",
    "CITYNAME_EN": "Al-Badwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9758",
    "CITYNAME_AR": "مصنع ساحل عسير",
    "CITYNAME_EN": "Asir Coast Factory ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9759",
    "CITYNAME_AR": "الجر",
    "CITYNAME_EN": "Al-Jarr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9760",
    "CITYNAME_AR": "المفرغ",
    "CITYNAME_EN": "Al Mafragh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9761",
    "CITYNAME_AR": "المخيل",
    "CITYNAME_EN": "Al-Makhil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9762",
    "CITYNAME_AR": "القصيبا",
    "CITYNAME_EN": "Al Qasibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9763",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9764",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9765",
    "CITYNAME_AR": "الملعب",
    "CITYNAME_EN": "Al-Mal'ab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9766",
    "CITYNAME_AR": "حضن الحشف",
    "CITYNAME_EN": "Hodn Al-Hashif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9767",
    "CITYNAME_AR": "لصبه قحمه",
    "CITYNAME_EN": "Lasbah Qahmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9768",
    "CITYNAME_AR": "وادى الربغه",
    "CITYNAME_EN": "Wadi Al-Righah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9769",
    "CITYNAME_AR": "جزعه دثان",
    "CITYNAME_EN": "Jaz'ah Dethan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9770",
    "CITYNAME_AR": "الخريقه",
    "CITYNAME_EN": "Al-Khariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9771",
    "CITYNAME_AR": "الظلامه",
    "CITYNAME_EN": "Al-Zalamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9772",
    "CITYNAME_AR": "العقل",
    "CITYNAME_EN": "Al-Aql",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9773",
    "CITYNAME_AR": "طارفه",
    "CITYNAME_EN": "Tarfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9774",
    "CITYNAME_AR": "المسمع",
    "CITYNAME_EN": "Al-Masma'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9775",
    "CITYNAME_AR": "الساقر",
    "CITYNAME_EN": "Al-Saqir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9776",
    "CITYNAME_AR": "وادي عرمرم (الاغراف)",
    "CITYNAME_EN": "Wadi Armrem (Al-Aghraf)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9777",
    "CITYNAME_AR": "وادي مطيرة",
    "CITYNAME_EN": "Wadi matirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9778",
    "CITYNAME_AR": "وادي افكا",
    "CITYNAME_EN": "Wadi Afka ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9779",
    "CITYNAME_AR": "شط امبرايه",
    "CITYNAME_EN": "Shat Amrayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9780",
    "CITYNAME_AR": "العاين",
    "CITYNAME_EN": "Al-Ayin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9781",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Al-Reqbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9782",
    "CITYNAME_AR": "مدينة الملك فيصل الخيرية",
    "CITYNAME_EN": "King Faysal Charity Town ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9783",
    "CITYNAME_AR": "رونه السداد (الداحس)",
    "CITYNAME_EN": "Runah Al-sdad (Al-Dahis)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9784",
    "CITYNAME_AR": "النميش",
    "CITYNAME_EN": "Al-Namish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9785",
    "CITYNAME_AR": "قرية صالح",
    "CITYNAME_EN": "Saleh Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9786",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Al Mafraq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9787",
    "CITYNAME_AR": "زوره العشير",
    "CITYNAME_EN": "Zurah Al-Ashir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9788",
    "CITYNAME_AR": "مصره",
    "CITYNAME_EN": "Misra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9789",
    "CITYNAME_AR": "المطلعه",
    "CITYNAME_EN": "Al-Matla'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9790",
    "CITYNAME_AR": "القضيه",
    "CITYNAME_EN": "Al-Qadiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9791",
    "CITYNAME_AR": "النشم",
    "CITYNAME_EN": "Al-Nashm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9792",
    "CITYNAME_AR": "قهاوي الحزحز",
    "CITYNAME_EN": "Al-Hazhaz Cafes ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9793",
    "CITYNAME_AR": "الصفقه والسحور",
    "CITYNAME_EN": "Al-Safqah and Al-sahur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9794",
    "CITYNAME_AR": "القحمه",
    "CITYNAME_EN": "Al Qahma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9795",
    "CITYNAME_AR": "مجلده",
    "CITYNAME_EN": "Magladah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9796",
    "CITYNAME_AR": "الحومة",
    "CITYNAME_EN": "Al Hawmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9797",
    "CITYNAME_AR": "المرداء",
    "CITYNAME_EN": "Al-Mardaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9798",
    "CITYNAME_AR": "مغزل",
    "CITYNAME_EN": "Maghzal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9799",
    "CITYNAME_AR": "العاليه",
    "CITYNAME_EN": "Al-Alyha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9800",
    "CITYNAME_AR": "العيرج",
    "CITYNAME_EN": "Al-Airaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9801",
    "CITYNAME_AR": "المقطوع",
    "CITYNAME_EN": "Al-Maqtu' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9802",
    "CITYNAME_AR": "الشروم",
    "CITYNAME_EN": "Al-Shorum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9803",
    "CITYNAME_AR": "النفاح",
    "CITYNAME_EN": "Al-Nifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9804",
    "CITYNAME_AR": "الطرق",
    "CITYNAME_EN": "Al Tarq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9805",
    "CITYNAME_AR": "السرير",
    "CITYNAME_EN": "Al-Sarir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9806",
    "CITYNAME_AR": "الجب",
    "CITYNAME_EN": "Al-Jeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9807",
    "CITYNAME_AR": "الغريف وسيهبه",
    "CITYNAME_EN": "Al-Gharif, and Saihabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9808",
    "CITYNAME_AR": "رقة الصفرا ء",
    "CITYNAME_EN": "Raqah Al-Safraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9809",
    "CITYNAME_AR": "فارعة العشير",
    "CITYNAME_EN": "Far'ah Al-Ashir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9810",
    "CITYNAME_AR": "خلاوي",
    "CITYNAME_EN": "Khalawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9811",
    "CITYNAME_AR": "الخفجة",
    "CITYNAME_EN": "Al-Khafjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9812",
    "CITYNAME_AR": "المراوغ",
    "CITYNAME_EN": "Al-Merawigh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9813",
    "CITYNAME_AR": "الجرمه السفلى",
    "CITYNAME_EN": "Al-Jarmah the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9814",
    "CITYNAME_AR": "العريض",
    "CITYNAME_EN": "Al-Arid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9815",
    "CITYNAME_AR": "قرن الزبده",
    "CITYNAME_EN": "Qarn Al-Zibdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9816",
    "CITYNAME_AR": "عصيره",
    "CITYNAME_EN": "Asirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9817",
    "CITYNAME_AR": "الخشب",
    "CITYNAME_EN": "Al-Khashab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9818",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Al-Harf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9819",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Al-Mahreq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9820",
    "CITYNAME_AR": "المليحة والشوال",
    "CITYNAME_EN": "Al-Malihah, and Al-Shewal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9821",
    "CITYNAME_AR": "الدفنه والمروه",
    "CITYNAME_EN": "Al-Dafnah, and Al-Marwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9822",
    "CITYNAME_AR": "مدينة الامير سلطان الخيرية",
    "CITYNAME_EN": "Prince Sultan Charity City ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9823",
    "CITYNAME_AR": "البراق",
    "CITYNAME_EN": "Al-Boraq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9824",
    "CITYNAME_AR": "المرتب",
    "CITYNAME_EN": "Al-Martab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9825",
    "CITYNAME_AR": "الحشاف",
    "CITYNAME_EN": "Al-Heshaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9826",
    "CITYNAME_AR": "الجرمة العليا",
    "CITYNAME_EN": "Al-Jarmah the upper ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9827",
    "CITYNAME_AR": "ام الصلقه",
    "CITYNAME_EN": "Umm Alsilaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9828",
    "CITYNAME_AR": "ابو عريش",
    "CITYNAME_EN": "'Abu Earish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9829",
    "CITYNAME_AR": "المرماد الدلاكه",
    "CITYNAME_EN": "Almirmad Aldilakuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9830",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Alraahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9831",
    "CITYNAME_AR": "ام الحصم",
    "CITYNAME_EN": "Umm Alhisam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9832",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Aljamimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9833",
    "CITYNAME_AR": "شرقان",
    "CITYNAME_EN": "Shurqan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9834",
    "CITYNAME_AR": "المجديره",
    "CITYNAME_EN": "Almajdiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9835",
    "CITYNAME_AR": "صلاصل",
    "CITYNAME_EN": "Salasil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9836",
    "CITYNAME_AR": "قمبوره",
    "CITYNAME_EN": "Qamburuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9837",
    "CITYNAME_AR": "الجرفه",
    "CITYNAME_EN": "Aljarfuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9838",
    "CITYNAME_AR": "الصوان",
    "CITYNAME_EN": "Alsawan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9839",
    "CITYNAME_AR": "عشة عقيله",
    "CITYNAME_EN": "Esht Eaqiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9840",
    "CITYNAME_AR": "ام الحجل",
    "CITYNAME_EN": "Umm Alhajl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9841",
    "CITYNAME_AR": "المقومه",
    "CITYNAME_EN": "Almuqumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9842",
    "CITYNAME_AR": "عشة سبيع",
    "CITYNAME_EN": "Esht Sabie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9843",
    "CITYNAME_AR": "المعرم",
    "CITYNAME_EN": "Almaearam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9844",
    "CITYNAME_AR": "حرايق",
    "CITYNAME_EN": "Harayiq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9845",
    "CITYNAME_AR": "المحطه",
    "CITYNAME_EN": "Alstation",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9846",
    "CITYNAME_AR": "الكنابيش",
    "CITYNAME_EN": "Alkanabish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9847",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "Alsiru",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9848",
    "CITYNAME_AR": "الضلعه",
    "CITYNAME_EN": "Aldalaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9849",
    "CITYNAME_AR": "ام القروش",
    "CITYNAME_EN": "Umm Alqurush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9850",
    "CITYNAME_AR": "وبره السفلى",
    "CITYNAME_EN": "Wabarh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9851",
    "CITYNAME_AR": "القصيبات العليا",
    "CITYNAME_EN": "Alqasibat Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9852",
    "CITYNAME_AR": "القصب",
    "CITYNAME_EN": "Alqasb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9853",
    "CITYNAME_AR": "القصيبات السفلى",
    "CITYNAME_EN": "Alqasibat Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9854",
    "CITYNAME_AR": "المخيم",
    "CITYNAME_EN": "Almukhayam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9855",
    "CITYNAME_AR": "أم العدن",
    "CITYNAME_EN": "'Ama Aleadn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9856",
    "CITYNAME_AR": "الخوبة",
    "CITYNAME_EN": "Alkhawbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9857",
    "CITYNAME_AR": "الموقد",
    "CITYNAME_EN": "Almuqid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9858",
    "CITYNAME_AR": "المضبر الاعلى والاسفل",
    "CITYNAME_EN": "Almadbir Al'Aelaa Walasfil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9859",
    "CITYNAME_AR": "الشانق",
    "CITYNAME_EN": "Alshaaniq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9860",
    "CITYNAME_AR": "سر المقتلي",
    "CITYNAME_EN": "Qaer Sralmuqtalaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9861",
    "CITYNAME_AR": "البحطيط",
    "CITYNAME_EN": "Albihtayt",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9862",
    "CITYNAME_AR": "مصفوقه",
    "CITYNAME_EN": "Masfuquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9863",
    "CITYNAME_AR": "الجيبه",
    "CITYNAME_EN": "Aljybuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9864",
    "CITYNAME_AR": "الرحمانية",
    "CITYNAME_EN": "Umm Almatahin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9865",
    "CITYNAME_AR": "المنقله",
    "CITYNAME_EN": "Almanqaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9866",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9867",
    "CITYNAME_AR": "زقزقه",
    "CITYNAME_EN": "Zaqazaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9868",
    "CITYNAME_AR": "الدماح",
    "CITYNAME_EN": "Aldamaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9869",
    "CITYNAME_AR": "النوافله",
    "CITYNAME_EN": "Alnawafiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9870",
    "CITYNAME_AR": "الغاليه",
    "CITYNAME_EN": "Alghalih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9871",
    "CITYNAME_AR": "المخلف",
    "CITYNAME_EN": "Almukhalaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9872",
    "CITYNAME_AR": "المغيره",
    "CITYNAME_EN": "Almughiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9873",
    "CITYNAME_AR": "ام الدبا",
    "CITYNAME_EN": "Umm Aldabba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9874",
    "CITYNAME_AR": "المحصاره",
    "CITYNAME_EN": "Almihsaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9875",
    "CITYNAME_AR": "الشبقه",
    "CITYNAME_EN": "Alshabaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9876",
    "CITYNAME_AR": "السودي",
    "CITYNAME_EN": "Alsuwdiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9877",
    "CITYNAME_AR": "صميل",
    "CITYNAME_EN": "Samil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9878",
    "CITYNAME_AR": "مرشوحه",
    "CITYNAME_EN": "Marshuhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9879",
    "CITYNAME_AR": "العين الحاره",
    "CITYNAME_EN": "Aleayn Alharuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9880",
    "CITYNAME_AR": "ابو النوره",
    "CITYNAME_EN": "'Abu Alnuwruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9881",
    "CITYNAME_AR": "سلعه",
    "CITYNAME_EN": "Salaeah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9882",
    "CITYNAME_AR": "ام النخيل",
    "CITYNAME_EN": "Umm Alshifar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9883",
    "CITYNAME_AR": "ام الدرق",
    "CITYNAME_EN": "Umm Aldaraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9884",
    "CITYNAME_AR": "ام الصقور",
    "CITYNAME_EN": "Umm Alsuqur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9885",
    "CITYNAME_AR": "موشره",
    "CITYNAME_EN": "Mawshiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9886",
    "CITYNAME_AR": "الذهليات",
    "CITYNAME_EN": "Aldhahliat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9887",
    "CITYNAME_AR": "المشباح  الاعلى",
    "CITYNAME_EN": "Almishbah Alraeiliu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9888",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9889",
    "CITYNAME_AR": "الدفينيه",
    "CITYNAME_EN": "Aldafiniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9890",
    "CITYNAME_AR": "الكرس",
    "CITYNAME_EN": "Alkaras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9891",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Almuetarad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9892",
    "CITYNAME_AR": "سودانه",
    "CITYNAME_EN": "Sudanh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9893",
    "CITYNAME_AR": "المقتل",
    "CITYNAME_EN": "Almaqtal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9894",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9895",
    "CITYNAME_AR": "المشعر",
    "CITYNAME_EN": "Almashear",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9896",
    "CITYNAME_AR": "لحج ضبري",
    "CITYNAME_EN": "Lihajin Dabriun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9897",
    "CITYNAME_AR": "المزبرات السفلى",
    "CITYNAME_EN": "Almzabarat(Alelya)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9898",
    "CITYNAME_AR": "المدفن",
    "CITYNAME_EN": "Almudfun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9899",
    "CITYNAME_AR": "الوجعه",
    "CITYNAME_EN": "Alwajeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9900",
    "CITYNAME_AR": "الجابري",
    "CITYNAME_EN": "Aljabiriu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9901",
    "CITYNAME_AR": "الربيعي",
    "CITYNAME_EN": "Alrabiei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9902",
    "CITYNAME_AR": "أم النصايب",
    "CITYNAME_EN": "'Ama Alnasayib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9903",
    "CITYNAME_AR": "قائم المروه",
    "CITYNAME_EN": "Qayim Almuruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9904",
    "CITYNAME_AR": "الخقاقه",
    "CITYNAME_EN": "Alkhaqaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9905",
    "CITYNAME_AR": "العباديه",
    "CITYNAME_EN": "Aleabadiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9906",
    "CITYNAME_AR": "الصيابه",
    "CITYNAME_EN": "Alsiyabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9907",
    "CITYNAME_AR": " المعنق",
    "CITYNAME_EN": "Almanaeaq Almueanaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9908",
    "CITYNAME_AR": "مشباح اللبيني",
    "CITYNAME_EN": "Mishbah Allubaynii",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9909",
    "CITYNAME_AR": "قائم الكعوب",
    "CITYNAME_EN": "Qayim Alkueub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9910",
    "CITYNAME_AR": "قوا",
    "CITYNAME_EN": "Quu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9911",
    "CITYNAME_AR": "الظهر",
    "CITYNAME_EN": "Alzuhr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9912",
    "CITYNAME_AR": "الرزمه",
    "CITYNAME_EN": "Alrazamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9913",
    "CITYNAME_AR": "المقبص",
    "CITYNAME_EN": "Almuqabis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9914",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Aldahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9915",
    "CITYNAME_AR": "قمر",
    "CITYNAME_EN": "Qamar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9916",
    "CITYNAME_AR": "قائم الصياب",
    "CITYNAME_EN": "Qayim Alsiyab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9917",
    "CITYNAME_AR": "المعترض تويلق",
    "CITYNAME_EN": "Almuetarad Tawyliq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9918",
    "CITYNAME_AR": "الحامضه",
    "CITYNAME_EN": "Alhamiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9919",
    "CITYNAME_AR": "قائم زبيد",
    "CITYNAME_EN": "Qayim Zabid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9920",
    "CITYNAME_AR": "الكرعمى",
    "CITYNAME_EN": "Alkareamaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9921",
    "CITYNAME_AR": "جاضع نمير",
    "CITYNAME_EN": "Jadie Namir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9922",
    "CITYNAME_AR": "الخل",
    "CITYNAME_EN": "Alkhalu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9923",
    "CITYNAME_AR": "دار النصر",
    "CITYNAME_EN": "Dar Alnasr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9924",
    "CITYNAME_AR": "رعشه",
    "CITYNAME_EN": "Reshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9925",
    "CITYNAME_AR": "البرنيه",
    "CITYNAME_EN": "Albarniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9926",
    "CITYNAME_AR": "القرقاعي",
    "CITYNAME_EN": "Alqirqaeiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9927",
    "CITYNAME_AR": "غارب شوعي",
    "CITYNAME_EN": "Gharb Shuei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9928",
    "CITYNAME_AR": "طياش",
    "CITYNAME_EN": "Tiash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9929",
    "CITYNAME_AR": "الدخانيه",
    "CITYNAME_EN": "Aldakhaniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9930",
    "CITYNAME_AR": "البتول",
    "CITYNAME_EN": "Albutul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9931",
    "CITYNAME_AR": "العره",
    "CITYNAME_EN": "Alearah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9932",
    "CITYNAME_AR": "خمران",
    "CITYNAME_EN": "Khamran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9933",
    "CITYNAME_AR": "العقم",
    "CITYNAME_EN": "Aleuqm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9934",
    "CITYNAME_AR": "السبخايه",
    "CITYNAME_EN": "Alsabakhayih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9935",
    "CITYNAME_AR": "غارب القصب",
    "CITYNAME_EN": "Gharib Alqasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9936",
    "CITYNAME_AR": "المعرسه",
    "CITYNAME_EN": "Almaerisuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9937",
    "CITYNAME_AR": "القفعه",
    "CITYNAME_EN": "Alqafeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9938",
    "CITYNAME_AR": "المنقلب",
    "CITYNAME_EN": "Almunqalib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9939",
    "CITYNAME_AR": "سمسرة المغياله",
    "CITYNAME_EN": "Alsamasra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9940",
    "CITYNAME_AR": "المكندرات",
    "CITYNAME_EN": "Almukandarat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9941",
    "CITYNAME_AR": "الكدري",
    "CITYNAME_EN": "Alkuduri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9942",
    "CITYNAME_AR": "مخشوشة",
    "CITYNAME_EN": "Makhshusha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9943",
    "CITYNAME_AR": "مجدعه",
    "CITYNAME_EN": "Mujdaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9944",
    "CITYNAME_AR": "مدبع",
    "CITYNAME_EN": "Mudabie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9945",
    "CITYNAME_AR": "المزبرات (السفلى)",
    "CITYNAME_EN": "Almuzabirat (Alsufalaa)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9946",
    "CITYNAME_AR": "ضبيره",
    "CITYNAME_EN": "Dabiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9947",
    "CITYNAME_AR": "جلاح",
    "CITYNAME_EN": "Jalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9948",
    "CITYNAME_AR": "قائم الساحه",
    "CITYNAME_EN": "Qayim Alsaahih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9949",
    "CITYNAME_AR": "القيمه",
    "CITYNAME_EN": "Alqimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9950",
    "CITYNAME_AR": "سر الحمره",
    "CITYNAME_EN": "Sira Alhamruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9951",
    "CITYNAME_AR": "المشبوبي",
    "CITYNAME_EN": "Almashbubi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9952",
    "CITYNAME_AR": "الرصفه",
    "CITYNAME_EN": "Alrasfuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9953",
    "CITYNAME_AR": "وادي رحيم",
    "CITYNAME_EN": "Wadi Rahim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9954",
    "CITYNAME_AR": "مشربة",
    "CITYNAME_EN": "Wa'Adaa Jalah Umm Musharibah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9955",
    "CITYNAME_AR": "ام الجمنه",
    "CITYNAME_EN": "Umm Aljimnuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9956",
    "CITYNAME_AR": "المخاريق",
    "CITYNAME_EN": "Almakhariq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9957",
    "CITYNAME_AR": "ام الخشب",
    "CITYNAME_EN": "Umm Alkhashb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9958",
    "CITYNAME_AR": "ظهر الجمل",
    "CITYNAME_EN": "Zahar Aljamal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9959",
    "CITYNAME_AR": "العك",
    "CITYNAME_EN": "Aleak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9960",
    "CITYNAME_AR": "الجوف",
    "CITYNAME_EN": "Aljawf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9961",
    "CITYNAME_AR": "العقم",
    "CITYNAME_EN": "Aleuqm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9962",
    "CITYNAME_AR": "الرباحه",
    "CITYNAME_EN": "Alrabahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9963",
    "CITYNAME_AR": "سيلان",
    "CITYNAME_EN": "Sayalan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9964",
    "CITYNAME_AR": "جهيزه",
    "CITYNAME_EN": "Jahizuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9965",
    "CITYNAME_AR": "العشه الحمراء",
    "CITYNAME_EN": "Aleashuh Alhamra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9966",
    "CITYNAME_AR": "الجلل المسيار",
    "CITYNAME_EN": "Aljalal Almusyar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9967",
    "CITYNAME_AR": "المسيله",
    "CITYNAME_EN": "Almasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9968",
    "CITYNAME_AR": "المروي",
    "CITYNAME_EN": "Almarawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9969",
    "CITYNAME_AR": "قماده",
    "CITYNAME_EN": "Qamadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9970",
    "CITYNAME_AR": "وادى قعموص",
    "CITYNAME_EN": "Wa'Adaa Qaemus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9971",
    "CITYNAME_AR": "ابومسياله",
    "CITYNAME_EN": "Abwimsialuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9972",
    "CITYNAME_AR": "وبره العليا",
    "CITYNAME_EN": "Wabarh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9973",
    "CITYNAME_AR": "كامله",
    "CITYNAME_EN": "Kamilh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9974",
    "CITYNAME_AR": "سر قنوع",
    "CITYNAME_EN": "Sira Qunue",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9975",
    "CITYNAME_AR": "المسياله",
    "CITYNAME_EN": "Almusyaaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9976",
    "CITYNAME_AR": "ردحة احمد بن علي الامير",
    "CITYNAME_EN": "Radahat Alsharif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9977",
    "CITYNAME_AR": "سر حديد",
    "CITYNAME_EN": "Sirun Hadid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9978",
    "CITYNAME_AR": "الصيابه",
    "CITYNAME_EN": "Alsiyabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9979",
    "CITYNAME_AR": "الفليقه",
    "CITYNAME_EN": "Alfaliquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9980",
    "CITYNAME_AR": "عنقصان",
    "CITYNAME_EN": "Eanaqsan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9981",
    "CITYNAME_AR": "الخشل",
    "CITYNAME_EN": "Alkhashal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9982",
    "CITYNAME_AR": "السلب",
    "CITYNAME_EN": "Alsalab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9983",
    "CITYNAME_AR": "مخشوشه",
    "CITYNAME_EN": "Makhshushh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9984",
    "CITYNAME_AR": "المصهفه",
    "CITYNAME_EN": "Almushafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9985",
    "CITYNAME_AR": "الكبشيه",
    "CITYNAME_EN": "Alkabshiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9986",
    "CITYNAME_AR": "المورعة",
    "CITYNAME_EN": "Almuraea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9987",
    "CITYNAME_AR": "المخرج",
    "CITYNAME_EN": "Almakhraj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9988",
    "CITYNAME_AR": "الحباطه",
    "CITYNAME_EN": "Alhibatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9989",
    "CITYNAME_AR": "سودي الحباطه",
    "CITYNAME_EN": "Swdi Alhibatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9990",
    "CITYNAME_AR": "جوف المصيدى",
    "CITYNAME_EN": "Juf Almusaydaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9991",
    "CITYNAME_AR": "القارشيه",
    "CITYNAME_EN": "Alqarishih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9992",
    "CITYNAME_AR": "ام القمع",
    "CITYNAME_EN": "Umm Alqame",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9993",
    "CITYNAME_AR": "المقطابه",
    "CITYNAME_EN": "Almiqtabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9994",
    "CITYNAME_AR": "باره",
    "CITYNAME_EN": "Barh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9995",
    "CITYNAME_AR": "الدقه",
    "CITYNAME_EN": "Aldaquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9996",
    "CITYNAME_AR": "السودي",
    "CITYNAME_EN": "Alsuwdiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9997",
    "CITYNAME_AR": "المعطن",
    "CITYNAME_EN": "Almaetin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9998",
    "CITYNAME_AR": "المقذف",
    "CITYNAME_EN": "Almaqdhaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "9999",
    "CITYNAME_AR": "الكربوس",
    "CITYNAME_EN": "Alkarbus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10000",
    "CITYNAME_AR": "القراعه الشرقيه",
    "CITYNAME_EN": "Alqiraeuh Alsharqiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10001",
    "CITYNAME_AR": "الجحائر",
    "CITYNAME_EN": "Aljahayir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10002",
    "CITYNAME_AR": "القروانيه",
    "CITYNAME_EN": "Alqarwaniah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10003",
    "CITYNAME_AR": "ابوسياله",
    "CITYNAME_EN": "Abwsialuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10004",
    "CITYNAME_AR": "مركوزه",
    "CITYNAME_EN": "Markuzah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10005",
    "CITYNAME_AR": "ابولحمه",
    "CITYNAME_EN": "Abwlhmh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10006",
    "CITYNAME_AR": "ام القضب",
    "CITYNAME_EN": "Umm Alqadb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10007",
    "CITYNAME_AR": "ابوالكثاث",
    "CITYNAME_EN": "Abwalkuthath",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10008",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Almafraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10009",
    "CITYNAME_AR": "المديراه",
    "CITYNAME_EN": "Almudirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10010",
    "CITYNAME_AR": "المقلوع",
    "CITYNAME_EN": "Almaqlue",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10011",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Aleatfuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10012",
    "CITYNAME_AR": "المضرب  الاعلى",
    "CITYNAME_EN": "Almidrab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10013",
    "CITYNAME_AR": "المتلاصقه",
    "CITYNAME_EN": "Almutalasiq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10014",
    "CITYNAME_AR": "المجرد",
    "CITYNAME_EN": "Almajrd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10015",
    "CITYNAME_AR": "بحريه",
    "CITYNAME_EN": "Biharih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10016",
    "CITYNAME_AR": "ام اللوي",
    "CITYNAME_EN": "Umm Allwy",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10017",
    "CITYNAME_AR": "ام السوادة",
    "CITYNAME_EN": "Umm Alsawwada",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10018",
    "CITYNAME_AR": "ترابه",
    "CITYNAME_EN": "Turabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10019",
    "CITYNAME_AR": "القراعه الشمالية",
    "CITYNAME_EN": "Alqaraeitalshmalia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10020",
    "CITYNAME_AR": "ام شعنون",
    "CITYNAME_EN": "Umm Shaenun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10021",
    "CITYNAME_AR": "الداسه",
    "CITYNAME_EN": "Alddasuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10022",
    "CITYNAME_AR": "شهرين",
    "CITYNAME_EN": "Shahrayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10023",
    "CITYNAME_AR": "ام العكاش",
    "CITYNAME_EN": "Umm Aleakash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10024",
    "CITYNAME_AR": "سودي سيال",
    "CITYNAME_EN": "Sudi Sayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10025",
    "CITYNAME_AR": "المطلع",
    "CITYNAME_EN": "Almutalie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10026",
    "CITYNAME_AR": "أم الخشب",
    "CITYNAME_EN": "'Ama Alkhashb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10027",
    "CITYNAME_AR": "الجراشب",
    "CITYNAME_EN": "Aljarashib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10028",
    "CITYNAME_AR": "الزبره",
    "CITYNAME_EN": "Alzabaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10029",
    "CITYNAME_AR": "ام الفصي",
    "CITYNAME_EN": "Umm Alfisii",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10030",
    "CITYNAME_AR": "ديحمه",
    "CITYNAME_EN": "Dihimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10031",
    "CITYNAME_AR": "المقالى",
    "CITYNAME_EN": "Almaqalaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10032",
    "CITYNAME_AR": "السهي",
    "CITYNAME_EN": "Alsahiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10033",
    "CITYNAME_AR": "الغاوى الكبير البحرين",
    "CITYNAME_EN": "Alghawaa Alkabir Albahrayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10034",
    "CITYNAME_AR": "الغافل",
    "CITYNAME_EN": "Alghafil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10035",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10036",
    "CITYNAME_AR": "الرونه",
    "CITYNAME_EN": "Alruwnuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10037",
    "CITYNAME_AR": "المعبوج",
    "CITYNAME_EN": "Almaebuj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10038",
    "CITYNAME_AR": "عببه",
    "CITYNAME_EN": "Eababah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10039",
    "CITYNAME_AR": "المحمل",
    "CITYNAME_EN": "Almuhmil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10040",
    "CITYNAME_AR": "الغويدى",
    "CITYNAME_EN": "Alghuydaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10041",
    "CITYNAME_AR": "الحجفار",
    "CITYNAME_EN": "Alhajfar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10042",
    "CITYNAME_AR": "الواسط",
    "CITYNAME_EN": "Alwasit",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10043",
    "CITYNAME_AR": "الخلايف",
    "CITYNAME_EN": "Alkhalayif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10044",
    "CITYNAME_AR": "صهيفاء",
    "CITYNAME_EN": "Sahifa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10045",
    "CITYNAME_AR": "الجروب",
    "CITYNAME_EN": "Aljurub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10046",
    "CITYNAME_AR": "حله عبدالله حسين",
    "CITYNAME_EN": "Halah Eubdallah Husayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10047",
    "CITYNAME_AR": "قمعه",
    "CITYNAME_EN": "Qamaeah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10048",
    "CITYNAME_AR": "الحامضه",
    "CITYNAME_EN": "Alhamiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10049",
    "CITYNAME_AR": "حندود",
    "CITYNAME_EN": "Handud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10050",
    "CITYNAME_AR": "البيسرى",
    "CITYNAME_EN": "Albaysraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10051",
    "CITYNAME_AR": "الزوالعه",
    "CITYNAME_EN": "Alzawalieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10052",
    "CITYNAME_AR": "العروج",
    "CITYNAME_EN": "Aleuruj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10053",
    "CITYNAME_AR": "الشنامره",
    "CITYNAME_EN": "Alshanamiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10054",
    "CITYNAME_AR": "الحنينى",
    "CITYNAME_EN": "Alhuninaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10055",
    "CITYNAME_AR": "الوحش",
    "CITYNAME_EN": "Alwahsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10056",
    "CITYNAME_AR": "الجرب",
    "CITYNAME_EN": "Aljarab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10057",
    "CITYNAME_AR": "الصياد",
    "CITYNAME_EN": "Alsiyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10058",
    "CITYNAME_AR": "السباك",
    "CITYNAME_EN": "Alsabak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10059",
    "CITYNAME_AR": "الحنشيه",
    "CITYNAME_EN": "Alhinshiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10060",
    "CITYNAME_AR": "القضب",
    "CITYNAME_EN": "Alqadb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10061",
    "CITYNAME_AR": "بقعاء",
    "CITYNAME_EN": "Biqaea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10062",
    "CITYNAME_AR": "الخضاب",
    "CITYNAME_EN": "Alkhidab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10063",
    "CITYNAME_AR": "العلب",
    "CITYNAME_EN": "Alealab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10064",
    "CITYNAME_AR": "القصيفان    القصيفه",
    "CITYNAME_EN": "Alqasifan Alqasifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10065",
    "CITYNAME_AR": "الجبريه",
    "CITYNAME_EN": "Aljabrih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10066",
    "CITYNAME_AR": "العرفجيه- بن بطر -",
    "CITYNAME_EN": "Alerfjyh- Bin Batar -",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10067",
    "CITYNAME_AR": "القليبة",
    "CITYNAME_EN": "Alqaliba",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10068",
    "CITYNAME_AR": "النظيم",
    "CITYNAME_EN": "Alnazim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10069",
    "CITYNAME_AR": "ضبيعه",
    "CITYNAME_EN": "Dabieuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10070",
    "CITYNAME_AR": "أم مرقاتين",
    "CITYNAME_EN": "Umm Raqatayn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10071",
    "CITYNAME_AR": "خبراء شمر الشيفان",
    "CITYNAME_EN": "Khubara' Shamr Alshiyfan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10072",
    "CITYNAME_AR": "خبراء شمر الشريهه",
    "CITYNAME_EN": "Khubara' Shamr Alsharihih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10073",
    "CITYNAME_AR": "ام طرفاء",
    "CITYNAME_EN": "Umm Tarafa'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10074",
    "CITYNAME_AR": "الحليله",
    "CITYNAME_EN": "Alhaliluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10075",
    "CITYNAME_AR": "كمب الثنيان",
    "CITYNAME_EN": "Althnyan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10076",
    "CITYNAME_AR": "الوعيلى",
    "CITYNAME_EN": "Alwaeilaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10077",
    "CITYNAME_AR": "الجديده",
    "CITYNAME_EN": "Aljadiduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10078",
    "CITYNAME_AR": "قدر",
    "CITYNAME_EN": "Qadar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10079",
    "CITYNAME_AR": "المياه",
    "CITYNAME_EN": "Almiah (Water)",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10080",
    "CITYNAME_AR": "جريعاء غنم",
    "CITYNAME_EN": "Juriea' Ghanam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10081",
    "CITYNAME_AR": "الشيحيه",
    "CITYNAME_EN": "Alshiyhiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10082",
    "CITYNAME_AR": "حنو الشيحية",
    "CITYNAME_EN": "Hanu Alshiyhia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10083",
    "CITYNAME_AR": "الرخيصيه",
    "CITYNAME_EN": "Alrakhisiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10084",
    "CITYNAME_AR": "الشريهيه",
    "CITYNAME_EN": "Alsharihih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10085",
    "CITYNAME_AR": "المحير",
    "CITYNAME_EN": "Almuhir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10086",
    "CITYNAME_AR": "الناصريه",
    "CITYNAME_EN": "Alnaasirih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10087",
    "CITYNAME_AR": "القصعه",
    "CITYNAME_EN": "Alqaseuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10088",
    "CITYNAME_AR": "الفويلق رقم 2",
    "CITYNAME_EN": "Alfwylq No. 2",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10089",
    "CITYNAME_AR": "الفويلق رقم 1",
    "CITYNAME_EN": "Alfwylq No. 1",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10090",
    "CITYNAME_AR": "مخطط الهباج",
    "CITYNAME_EN": "Mukhatat Alhibaj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10091",
    "CITYNAME_AR": "الشعلانيه",
    "CITYNAME_EN": "Alshaelaniuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10092",
    "CITYNAME_AR": "الجثياثه",
    "CITYNAME_EN": "Aljathiathuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10093",
    "CITYNAME_AR": "رجامه",
    "CITYNAME_EN": "Rajamuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10094",
    "CITYNAME_AR": "المندسه الشرقيه",
    "CITYNAME_EN": "Almundisuh Alsharqiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10095",
    "CITYNAME_AR": "خب المقنع",
    "CITYNAME_EN": "Khab Almuqanae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10096",
    "CITYNAME_AR": "المعباء",
    "CITYNAME_EN": "Almueba'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10097",
    "CITYNAME_AR": "الفريهيديه",
    "CITYNAME_EN": "Alfrihidiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10098",
    "CITYNAME_AR": "اشيقر",
    "CITYNAME_EN": "Ashyqr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10099",
    "CITYNAME_AR": "تربة",
    "CITYNAME_EN": "Turba",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10100",
    "CITYNAME_AR": "الدحيلانيات مشاش الدحيلات شرق تربه",
    "CITYNAME_EN": "Aldahilaniat Mashash Aldahilat Shrq Tarubih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10101",
    "CITYNAME_AR": "قليب الشلقان",
    "CITYNAME_EN": "Qalib Alshulqan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10102",
    "CITYNAME_AR": "الحيانية",
    "CITYNAME_EN": "Alhiania",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10103",
    "CITYNAME_AR": "جبله",
    "CITYNAME_EN": "Jablah",
    "REGION_ID": "8"
   },
  //  {
  //   "CITY_ID": "10104",
  //   "CITYNAME_AR": "العبله",
  //   "CITYNAME_EN": "Al Abalah",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "10105",
    "CITYNAME_AR": "تمير",
    "CITYNAME_EN": "Tameer",
    "REGION_ID": "1"
   },
  //  {
  //   "CITY_ID": "10106",
  //   "CITYNAME_AR": "تمريه",
  //   "CITYNAME_EN": "Tamryah",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10107",
  //   "CITYNAME_AR": "وسيعه تمير",
  //   "CITYNAME_EN": "Waseah Tamer",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10108",
  //   "CITYNAME_AR": "ابو خسيفه",
  //   "CITYNAME_EN": "Abo Khusayfah",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10109",
  //   "CITYNAME_AR": "مبايض",
  //   "CITYNAME_EN": "Mabayid",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10110",
  //   "CITYNAME_AR": "بوضاء بسدير",
  //   "CITYNAME_EN": "Baudha, Sudair",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10111",
  //   "CITYNAME_AR": "رويضه بوضاء",
  //   "CITYNAME_EN": "Ruwaida, Wadaa",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "10112",
  //   "CITYNAME_AR": "الشعب",
  //   "CITYNAME_EN": "Ash-Shaab",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "10113",
    "CITYNAME_AR": "الصمد",
    "CITYNAME_EN": "Alsamad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10114",
    "CITYNAME_AR": "النخيل القديم",
    "CITYNAME_EN": "Alnakhil Alqadim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10115",
    "CITYNAME_AR": "المخروقه الشماليه",
    "CITYNAME_EN": "Almakhruquh Alshamaliuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10116",
    "CITYNAME_AR": "المخروقه الجنوبيه",
    "CITYNAME_EN": "Almakhruquh Aljanubiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10117",
    "CITYNAME_AR": "قصيباء",
    "CITYNAME_EN": "Qsyba'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10118",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10119",
    "CITYNAME_AR": "ابو غشاه (ام المملح)",
    "CITYNAME_EN": "Abu Ghashah (Am Almumalah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10120",
    "CITYNAME_AR": "سويقه",
    "CITYNAME_EN": "Sawiquh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10121",
    "CITYNAME_AR": "ذاره",
    "CITYNAME_EN": "Dharh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10122",
    "CITYNAME_AR": "دعب السقو",
    "CITYNAME_EN": "Daeib Alsaqu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10123",
    "CITYNAME_AR": "ابو هشيمات",
    "CITYNAME_EN": "Abu Hashaymat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10124",
    "CITYNAME_AR": "المرموثه",
    "CITYNAME_EN": "Almarmuthuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10125",
    "CITYNAME_AR": "كحله",
    "CITYNAME_EN": "Kahalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10126",
    "CITYNAME_AR": "قاع جثليه",
    "CITYNAME_EN": "Qae Jithulayh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10127",
    "CITYNAME_AR": "رحبة علوش",
    "CITYNAME_EN": "Rahbat Eulush",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10128",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10129",
    "CITYNAME_AR": "ابو هشيم",
    "CITYNAME_EN": "Abu Hashim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10130",
    "CITYNAME_AR": "السنفاء",
    "CITYNAME_EN": "Alsanafa'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10131",
    "CITYNAME_AR": "الرصفاء",
    "CITYNAME_EN": "Alrisafa'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10132",
    "CITYNAME_AR": "المقرن",
    "CITYNAME_EN": "Almuqaran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10133",
    "CITYNAME_AR": "دعيب حمدي",
    "CITYNAME_EN": "Deib Hamdi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "10134",
    "CITYNAME_AR": "قدس",
    "CITYNAME_EN": "Quds",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "10135",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10136",
    "CITYNAME_AR": "الحاير",
    "CITYNAME_EN": "Al-Hayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10137",
    "CITYNAME_AR": "رهنه",
    "CITYNAME_EN": "Rahnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10138",
    "CITYNAME_AR": "قمشان",
    "CITYNAME_EN": "Qamshan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10139",
    "CITYNAME_AR": "خيور",
    "CITYNAME_EN": "Khabur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10140",
    "CITYNAME_AR": "هجرة المجد",
    "CITYNAME_EN": "Hijrah Al-Majd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10141",
    "CITYNAME_AR": "ثجر الاسفل",
    "CITYNAME_EN": "Thajr Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10142",
    "CITYNAME_AR": "ثجر الاعلى",
    "CITYNAME_EN": "Thajr Al-A'la",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10143",
    "CITYNAME_AR": "هجره مرر",
    "CITYNAME_EN": "Hijra Marr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10144",
    "CITYNAME_AR": "درع (الصيريه)",
    "CITYNAME_EN": "Der' (Al-Sayriyah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10145",
    "CITYNAME_AR": "الأسودة",
    "CITYNAME_EN": "Al-Aswdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10146",
    "CITYNAME_AR": "العلب",
    "CITYNAME_EN": "Al-Alb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10147",
    "CITYNAME_AR": "الفرع (بئر محظه)",
    "CITYNAME_EN": "Al-Far' (Bir Mahzah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10148",
    "CITYNAME_AR": "مزارع اكبر",
    "CITYNAME_EN": "Akber Farms ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10149",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al-Hankah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10150",
    "CITYNAME_AR": "السرمدانه",
    "CITYNAME_EN": "Al-Sermadanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10151",
    "CITYNAME_AR": "ثجر سويدان",
    "CITYNAME_EN": "Theqar Swidan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10152",
    "CITYNAME_AR": "حيدره",
    "CITYNAME_EN": "Hedrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10153",
    "CITYNAME_AR": "شدا",
    "CITYNAME_EN": "Shada ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10154",
    "CITYNAME_AR": "ايساف",
    "CITYNAME_EN": "Eisaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10155",
    "CITYNAME_AR": "رحوب",
    "CITYNAME_EN": "Rahub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10156",
    "CITYNAME_AR": "العادية",
    "CITYNAME_EN": "Al-Adiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10157",
    "CITYNAME_AR": "النهدين",
    "CITYNAME_EN": "Al Nahdein",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10158",
    "CITYNAME_AR": "النهيدين",
    "CITYNAME_EN": "Al-Nahdayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10159",
    "CITYNAME_AR": "عين",
    "CITYNAME_EN": "Ein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10160",
    "CITYNAME_AR": "هجره حجيلا",
    "CITYNAME_EN": "Hijrah Hajilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10161",
    "CITYNAME_AR": "الرهوه (عجاجه)",
    "CITYNAME_EN": "Al-Rahwah (Ajajah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10162",
    "CITYNAME_AR": "المقارح (الأفرع)",
    "CITYNAME_EN": "Al-Maqarih (Al-Afra')",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10163",
    "CITYNAME_AR": "الزوايد",
    "CITYNAME_EN": "Al-Zawayid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10164",
    "CITYNAME_AR": "بيضان",
    "CITYNAME_EN": "Baidhan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10165",
    "CITYNAME_AR": "الجحر",
    "CITYNAME_EN": "Al-Jehr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10166",
    "CITYNAME_AR": "الشعران",
    "CITYNAME_EN": "Al-Sha'ran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10167",
    "CITYNAME_AR": "الديره",
    "CITYNAME_EN": "Al-Dirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10168",
    "CITYNAME_AR": "الا مواه",
    "CITYNAME_EN": "Al Amoah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10169",
    "CITYNAME_AR": "المستويه",
    "CITYNAME_EN": "Al-Mustawiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10170",
    "CITYNAME_AR": "ابو نخيش",
    "CITYNAME_EN": "Abu Nakhish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10171",
    "CITYNAME_AR": "ابو مرخه",
    "CITYNAME_EN": "Abu Merkhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10172",
    "CITYNAME_AR": "الجنادل",
    "CITYNAME_EN": "Al-Jandel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10173",
    "CITYNAME_AR": "ابن مسلي",
    "CITYNAME_EN": "Ibn Masla ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10174",
    "CITYNAME_AR": "حمد بن علي",
    "CITYNAME_EN": "Hamad bin Ali ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10175",
    "CITYNAME_AR": "الكباش (قوز بالعير)",
    "CITYNAME_EN": "A;-Kabbash (Quz in Al-eir)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10176",
    "CITYNAME_AR": "المصاريع",
    "CITYNAME_EN": "Al Masari' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10177",
    "CITYNAME_AR": "الحبلين",
    "CITYNAME_EN": "Al-Hablin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10178",
    "CITYNAME_AR": "هجره السايل والمستظل",
    "CITYNAME_EN": "Hijrah Al-Sayel and Al-Mostazil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10179",
    "CITYNAME_AR": "مركز مجاهدى الشبيكه",
    "CITYNAME_EN": "Mojahdin Al-Shebikah Center",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10180",
    "CITYNAME_AR": "الجلال",
    "CITYNAME_EN": "Al-Galal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10181",
    "CITYNAME_AR": "الصبية",
    "CITYNAME_EN": "Al-Sebyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10182",
    "CITYNAME_AR": "السوسي",
    "CITYNAME_EN": "Al-Susi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10183",
    "CITYNAME_AR": "المنحر",
    "CITYNAME_EN": "Al-Menhar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10184",
    "CITYNAME_AR": "العمائر",
    "CITYNAME_EN": "Amaer Aal Humaidan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10185",
    "CITYNAME_AR": "اللجمه الجنوبيه",
    "CITYNAME_EN": "Allajmah Al-Janubiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10186",
    "CITYNAME_AR": "اللجمه الشماليه",
    "CITYNAME_EN": "Allajmah Al-Shamaliyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10187",
    "CITYNAME_AR": "العطفه (الحمراء المشاعله)",
    "CITYNAME_EN": "Al-Atfah (Al-Hamraa Al-Masha'ilah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10188",
    "CITYNAME_AR": "ال غراب",
    "CITYNAME_EN": "Al Ghorab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10189",
    "CITYNAME_AR": "ال حسناء",
    "CITYNAME_EN": "Al Hasnaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10190",
    "CITYNAME_AR": "ذيب بن فنيس",
    "CITYNAME_EN": "Zib bin Fanis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10191",
    "CITYNAME_AR": "مزرعه ذيب بن حصين",
    "CITYNAME_EN": "Zib bin Hasin Farm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10192",
    "CITYNAME_AR": "الصفيراء",
    "CITYNAME_EN": "Al-Sufayraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10193",
    "CITYNAME_AR": "ابن هطيل",
    "CITYNAME_EN": "Ibn Hatil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10194",
    "CITYNAME_AR": "النقعه",
    "CITYNAME_EN": "Al-Naq'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10195",
    "CITYNAME_AR": "الحجر",
    "CITYNAME_EN": "Al Hajr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10196",
    "CITYNAME_AR": "الجعيفره",
    "CITYNAME_EN": "Al-Ju'ayfirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10197",
    "CITYNAME_AR": "لهو",
    "CITYNAME_EN": "Lahw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10198",
    "CITYNAME_AR": "راخ",
    "CITYNAME_EN": "Rakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10199",
    "CITYNAME_AR": "العزيزيه",
    "CITYNAME_EN": "Al Aziziyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10200",
    "CITYNAME_AR": "الوهلان",
    "CITYNAME_EN": "Al Wahlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10201",
    "CITYNAME_AR": "النحى (بئر  بن عوير)",
    "CITYNAME_EN": "Al-Nahy (Bir bin Owayr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10202",
    "CITYNAME_AR": "الرشاده",
    "CITYNAME_EN": "Al-Rashadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10203",
    "CITYNAME_AR": "الجبوب",
    "CITYNAME_EN": "Al-Jabub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10204",
    "CITYNAME_AR": "عشبان",
    "CITYNAME_EN": "Ashban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10205",
    "CITYNAME_AR": "ام شريم",
    "CITYNAME_EN": "Umm Shuraym ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10206",
    "CITYNAME_AR": "الغميس",
    "CITYNAME_EN": "Al-Ghamis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10207",
    "CITYNAME_AR": "الخالديه (الموافيع)",
    "CITYNAME_EN": "Al-Khalidiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10208",
    "CITYNAME_AR": "القلت",
    "CITYNAME_EN": "Al Qalt",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10209",
    "CITYNAME_AR": "المسناه",
    "CITYNAME_EN": "Al-Masnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10210",
    "CITYNAME_AR": "الرهـــوه",
    "CITYNAME_EN": "Ar-Rahwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10211",
    "CITYNAME_AR": "الباخن",
    "CITYNAME_EN": "Al Bakhen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "10212",
    "CITYNAME_AR": "الشعبين",
    "CITYNAME_EN": "Al-She'bayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10213",
    "CITYNAME_AR": "لقع",
    "CITYNAME_EN": "Laq'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10214",
    "CITYNAME_AR": "الاصدار",
    "CITYNAME_EN": "Al-Asdar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10215",
    "CITYNAME_AR": "ذات مساك",
    "CITYNAME_EN": "Zat Mesak ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10216",
    "CITYNAME_AR": "ظهره ال صالح",
    "CITYNAME_EN": "Zahret Al Saleh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10217",
    "CITYNAME_AR": "ال مزاح",
    "CITYNAME_EN": "Al Mezah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10218",
    "CITYNAME_AR": "ال خيره",
    "CITYNAME_EN": "Al Khirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10219",
    "CITYNAME_AR": "الوجيف",
    "CITYNAME_EN": "Al Wajeef",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10220",
    "CITYNAME_AR": "الشقه",
    "CITYNAME_EN": "Al-Shaqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10221",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10222",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10223",
    "CITYNAME_AR": "الوهده",
    "CITYNAME_EN": "Al Wahdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10224",
    "CITYNAME_AR": "وسالع",
    "CITYNAME_EN": "Wasli' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10225",
    "CITYNAME_AR": "محجان",
    "CITYNAME_EN": "Mehjan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10226",
    "CITYNAME_AR": "صوله",
    "CITYNAME_EN": "Sola",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10227",
    "CITYNAME_AR": "مندر العوص",
    "CITYNAME_EN": "Mender Al-Awad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10228",
    "CITYNAME_AR": "ضاحيه",
    "CITYNAME_EN": "Dahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10229",
    "CITYNAME_AR": "حبيل الاحلاج",
    "CITYNAME_EN": "Habil Al-Ahlaj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10230",
    "CITYNAME_AR": "ظهره المصدمه",
    "CITYNAME_EN": "Zohrah Al-Masdamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10231",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10232",
    "CITYNAME_AR": "جزعه العوص",
    "CITYNAME_EN": "Jaz'ah Al-Aws ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10233",
    "CITYNAME_AR": "غنمه",
    "CITYNAME_EN": "Ghanmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10234",
    "CITYNAME_AR": "جثل ال مصم",
    "CITYNAME_EN": "Jithl Al Masm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10235",
    "CITYNAME_AR": "ال مشابع",
    "CITYNAME_EN": "Al Meshabi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10236",
    "CITYNAME_AR": "ال مبيوع",
    "CITYNAME_EN": "Al Mabyu'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10237",
    "CITYNAME_AR": "عثالف",
    "CITYNAME_EN": "Athalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10238",
    "CITYNAME_AR": "قوه",
    "CITYNAME_EN": "Quwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10239",
    "CITYNAME_AR": "الرصعه",
    "CITYNAME_EN": "Al Ra'sah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10240",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10241",
    "CITYNAME_AR": "العثربه",
    "CITYNAME_EN": "Al-Atharabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10242",
    "CITYNAME_AR": "ثعابه",
    "CITYNAME_EN": "Tho'abah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10243",
    "CITYNAME_AR": "ظهر النواشره",
    "CITYNAME_EN": "Zuhr Al-Nawashrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10244",
    "CITYNAME_AR": "عمقه (ال علايم)",
    "CITYNAME_EN": "Amqah (Al Alayim)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10245",
    "CITYNAME_AR": "الممر",
    "CITYNAME_EN": "Al-Mamer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10246",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10247",
    "CITYNAME_AR": "لحج بن زافله",
    "CITYNAME_EN": "Lohj bin Zaflah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10248",
    "CITYNAME_AR": "وادى ظرافه",
    "CITYNAME_EN": "Wadi Zarafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10249",
    "CITYNAME_AR": "يدامان",
    "CITYNAME_EN": "Yadman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10250",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10251",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10252",
    "CITYNAME_AR": "وادى كريم",
    "CITYNAME_EN": "Wadi Kareem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10253",
    "CITYNAME_AR": "وطن الرديف",
    "CITYNAME_EN": "Watn Al-Radif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10254",
    "CITYNAME_AR": "الحريزه",
    "CITYNAME_EN": "Al-Harizah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10255",
    "CITYNAME_AR": "ثفقى",
    "CITYNAME_EN": "Thafqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10256",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10257",
    "CITYNAME_AR": "الذره",
    "CITYNAME_EN": "Al-Zurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10258",
    "CITYNAME_AR": "خلف مجوف",
    "CITYNAME_EN": "Khalf Majuf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10259",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10260",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10261",
    "CITYNAME_AR": "الوهده",
    "CITYNAME_EN": "Al Wahdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10262",
    "CITYNAME_AR": "القران",
    "CITYNAME_EN": "Al-Qeran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10263",
    "CITYNAME_AR": "سمنان",
    "CITYNAME_EN": "Semnan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10264",
    "CITYNAME_AR": "الجوز الاسفل",
    "CITYNAME_EN": "Al-Juz Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10265",
    "CITYNAME_AR": "الجوز الاعلى",
    "CITYNAME_EN": "Al-juz Al-A'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10266",
    "CITYNAME_AR": "وجه العرق",
    "CITYNAME_EN": "Wajh Al-Irq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10267",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10268",
    "CITYNAME_AR": "الصعداى",
    "CITYNAME_EN": "Al-Saaday ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10269",
    "CITYNAME_AR": "الشافيه",
    "CITYNAME_EN": "Al-Shafiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10270",
    "CITYNAME_AR": "الخو",
    "CITYNAME_EN": "Al-Khaw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10271",
    "CITYNAME_AR": "المعقم",
    "CITYNAME_EN": "Al-Ma'qam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10272",
    "CITYNAME_AR": "ذات الحلى",
    "CITYNAME_EN": "Zat Al-Hely ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10273",
    "CITYNAME_AR": "ظهره ال بريد",
    "CITYNAME_EN": "Zahrah Al Barid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10274",
    "CITYNAME_AR": "معينه",
    "CITYNAME_EN": "Ma'inah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10275",
    "CITYNAME_AR": "ملتقى الصدرين",
    "CITYNAME_EN": "Moltqa Al-Sadreen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10276",
    "CITYNAME_AR": "ظهران",
    "CITYNAME_EN": "Zahran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10277",
    "CITYNAME_AR": "معتق ال هبه",
    "CITYNAME_EN": "Me'tiq Al Heba ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10278",
    "CITYNAME_AR": "الجرف القديم",
    "CITYNAME_EN": "Al-Jarf Al-Qadim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10279",
    "CITYNAME_AR": "عرضه",
    "CITYNAME_EN": "Ardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10280",
    "CITYNAME_AR": "الجرف الجديد (منقش)",
    "CITYNAME_EN": "Al-Jarf Al-jadid (manqash)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10281",
    "CITYNAME_AR": "ظاهر القماش",
    "CITYNAME_EN": "Zahir Al-Qomash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10282",
    "CITYNAME_AR": "اسفل فو",
    "CITYNAME_EN": "Asfal Faw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10283",
    "CITYNAME_AR": "الفصم",
    "CITYNAME_EN": "Al Fasm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10284",
    "CITYNAME_AR": "طوقن",
    "CITYNAME_EN": "Tuqn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10285",
    "CITYNAME_AR": "القحام",
    "CITYNAME_EN": "Al-Qeham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10286",
    "CITYNAME_AR": "الرهي",
    "CITYNAME_EN": "Al-Rahy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10287",
    "CITYNAME_AR": "البتيله",
    "CITYNAME_EN": "Al-Batilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10288",
    "CITYNAME_AR": "المعاملات",
    "CITYNAME_EN": "Al-Mo'amalat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10289",
    "CITYNAME_AR": "ميمن",
    "CITYNAME_EN": "Maymen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10290",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10291",
    "CITYNAME_AR": "ظهره ال مهدى",
    "CITYNAME_EN": "Zahrah Al mahdi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10292",
    "CITYNAME_AR": "ظهره ال حسن",
    "CITYNAME_EN": "Zahrah Al Hassan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10293",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10294",
    "CITYNAME_AR": "بتيله ال مداوى",
    "CITYNAME_EN": "Batilah Al Madawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10295",
    "CITYNAME_AR": "جزعه الرقاق",
    "CITYNAME_EN": "Jaz'ah Al-Reqaq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10296",
    "CITYNAME_AR": "يسره",
    "CITYNAME_EN": "Yasrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10297",
    "CITYNAME_AR": "لبخ",
    "CITYNAME_EN": "Labkh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10298",
    "CITYNAME_AR": "جزعه المشط",
    "CITYNAME_EN": "Jaz'ah Al-Mesht ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10299",
    "CITYNAME_AR": "وطن عانين",
    "CITYNAME_EN": "Watan Anin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10300",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10301",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10302",
    "CITYNAME_AR": "شط المعد",
    "CITYNAME_EN": "Shat Al-Ma'd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10303",
    "CITYNAME_AR": "الرقعه",
    "CITYNAME_EN": "Al-Raqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10304",
    "CITYNAME_AR": "جابو",
    "CITYNAME_EN": "Jabu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10305",
    "CITYNAME_AR": "دريما",
    "CITYNAME_EN": "Drima ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10306",
    "CITYNAME_AR": "الغره",
    "CITYNAME_EN": "Al-Gharaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10307",
    "CITYNAME_AR": "صران",
    "CITYNAME_EN": "Sarran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10308",
    "CITYNAME_AR": "ظهره سنومه",
    "CITYNAME_EN": "Zahrah Sanumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10309",
    "CITYNAME_AR": "اللحج",
    "CITYNAME_EN": "Allahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10310",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10311",
    "CITYNAME_AR": "جرف السلع",
    "CITYNAME_EN": "Jarf Al-Sel'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10312",
    "CITYNAME_AR": "الربوع",
    "CITYNAME_EN": "Al-Robu'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10313",
    "CITYNAME_AR": "بئر ملحه",
    "CITYNAME_EN": "Bir Malhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10314",
    "CITYNAME_AR": "ضبيان",
    "CITYNAME_EN": "Dabyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10315",
    "CITYNAME_AR": "المنافش",
    "CITYNAME_EN": "Al-Mnafish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10316",
    "CITYNAME_AR": "مقحومه",
    "CITYNAME_EN": "Maqhumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10317",
    "CITYNAME_AR": "ضدحاء",
    "CITYNAME_EN": "Dadhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10318",
    "CITYNAME_AR": "ظهران المشوزه (الخانق)",
    "CITYNAME_EN": "Zahran Al-Mashuzah (Al-Khaniq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10319",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Mashrafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10320",
    "CITYNAME_AR": "وجه العسف",
    "CITYNAME_EN": "Wajh Al-Asf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10321",
    "CITYNAME_AR": "الاحقاب",
    "CITYNAME_EN": "Al-Ahqab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10322",
    "CITYNAME_AR": "الوطن",
    "CITYNAME_EN": "Al-Watan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10323",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10324",
    "CITYNAME_AR": "الشرار",
    "CITYNAME_EN": "Al-Sherar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10325",
    "CITYNAME_AR": "الاذناب",
    "CITYNAME_EN": "Al-Aznab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10326",
    "CITYNAME_AR": "الصدعه",
    "CITYNAME_EN": "Al-Sad'aa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10327",
    "CITYNAME_AR": "الراتخ",
    "CITYNAME_EN": "Al-Ratikh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10328",
    "CITYNAME_AR": "مدر",
    "CITYNAME_EN": "Madr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10329",
    "CITYNAME_AR": "الكرى (العشه)",
    "CITYNAME_EN": "Al-Kary (Al-Oshah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10330",
    "CITYNAME_AR": "ضحيان",
    "CITYNAME_EN": "Dahyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10331",
    "CITYNAME_AR": "القضي",
    "CITYNAME_EN": "Al Qdy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10332",
    "CITYNAME_AR": "امصلحاء",
    "CITYNAME_EN": "Umsalhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10333",
    "CITYNAME_AR": "معتق محالى",
    "CITYNAME_EN": "Me'tiq Mahaly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10334",
    "CITYNAME_AR": "الشطين",
    "CITYNAME_EN": "Al-Shatin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10335",
    "CITYNAME_AR": "سمعى (الجندلة)",
    "CITYNAME_EN": "Sam'a (Al-Jandalah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10336",
    "CITYNAME_AR": "الرجوع",
    "CITYNAME_EN": "Al-Raju'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10337",
    "CITYNAME_AR": "نعام",
    "CITYNAME_EN": "Ostriches",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10338",
    "CITYNAME_AR": "الحرد",
    "CITYNAME_EN": "Al-Hard",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10339",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10340",
    "CITYNAME_AR": "القران",
    "CITYNAME_EN": "Al-Qeran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10341",
    "CITYNAME_AR": "جزعه وسانب",
    "CITYNAME_EN": "Jaz'ah and Saneb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10342",
    "CITYNAME_AR": "الغبطه",
    "CITYNAME_EN": "Al-Ghebtah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10343",
    "CITYNAME_AR": "الفصم",
    "CITYNAME_EN": "Al Fasm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10344",
    "CITYNAME_AR": "ظهره قهده",
    "CITYNAME_EN": "Zohra Qahdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10345",
    "CITYNAME_AR": "ذى حصيم",
    "CITYNAME_EN": "Zi Hasim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10346",
    "CITYNAME_AR": "الميمن",
    "CITYNAME_EN": "Al-Maymin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10347",
    "CITYNAME_AR": "وطن وسانب",
    "CITYNAME_EN": "Watan and Sanib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10348",
    "CITYNAME_AR": "عطف الربط",
    "CITYNAME_EN": "Atf Al-Rit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10349",
    "CITYNAME_AR": "العوده",
    "CITYNAME_EN": "Al-Odah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10350",
    "CITYNAME_AR": "حماظن",
    "CITYNAME_EN": "Hamazin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10351",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10352",
    "CITYNAME_AR": "الحثران",
    "CITYNAME_EN": "Al-Hathran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10353",
    "CITYNAME_AR": "الحصياء",
    "CITYNAME_EN": "Al-Hasyaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10354",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10355",
    "CITYNAME_AR": "حثمه رقعاء",
    "CITYNAME_EN": "Hathmah Riqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10356",
    "CITYNAME_AR": "سولا",
    "CITYNAME_EN": "Sula",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10357",
    "CITYNAME_AR": "حقو مدق",
    "CITYNAME_EN": "Haqw Medaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10358",
    "CITYNAME_AR": "لحج اللزه",
    "CITYNAME_EN": "Lahj Allezah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10359",
    "CITYNAME_AR": "اورق",
    "CITYNAME_EN": "Awraq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10360",
    "CITYNAME_AR": "شبحه",
    "CITYNAME_EN": "Shabhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10361",
    "CITYNAME_AR": "خب الشرم",
    "CITYNAME_EN": "Kheb Al-Sharm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10362",
    "CITYNAME_AR": "حقو الرياح",
    "CITYNAME_EN": "Haqw Al-Reyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10363",
    "CITYNAME_AR": "المختن المعقر",
    "CITYNAME_EN": "Al-Makhten Al-Ma'qer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10364",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Al Rahbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10365",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Al-Mahreq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10366",
    "CITYNAME_AR": "هميان الاعلى",
    "CITYNAME_EN": "Hemyan Al-A'la",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10367",
    "CITYNAME_AR": "هميان الاسفل",
    "CITYNAME_EN": "Hemyan Al-Asfal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10368",
    "CITYNAME_AR": "باطنه الاثله",
    "CITYNAME_EN": "Batnah Al-Athlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10369",
    "CITYNAME_AR": "الغبطه",
    "CITYNAME_EN": "Al-Ghebtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10370",
    "CITYNAME_AR": "فعاه",
    "CITYNAME_EN": "Fa'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10371",
    "CITYNAME_AR": "حقو عدينات",
    "CITYNAME_EN": "Haqw Adinat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10372",
    "CITYNAME_AR": "الفانيه",
    "CITYNAME_EN": "Al-Faniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10373",
    "CITYNAME_AR": "الاحابيش",
    "CITYNAME_EN": "Al-Ahabish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10374",
    "CITYNAME_AR": "شط ابوغربان",
    "CITYNAME_EN": "Shat Abu Gherban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10375",
    "CITYNAME_AR": "حويه",
    "CITYNAME_EN": "Huba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10376",
    "CITYNAME_AR": "شط ابن جازعه",
    "CITYNAME_EN": "Shat Ibn Jaz'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10377",
    "CITYNAME_AR": "حمر",
    "CITYNAME_EN": "Hemr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10378",
    "CITYNAME_AR": "شعب البرام",
    "CITYNAME_EN": "Shi'b Al-Beram ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10379",
    "CITYNAME_AR": "وجه منتوف",
    "CITYNAME_EN": "Wajh Mantuf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10380",
    "CITYNAME_AR": "نخر الثوعه",
    "CITYNAME_EN": "Nakhr Al-Thaw'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10381",
    "CITYNAME_AR": "بئر رقعاء",
    "CITYNAME_EN": "Bir Reqa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10382",
    "CITYNAME_AR": "وجه قرن الحجله",
    "CITYNAME_EN": "Wajh Qern Al-Hajlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10383",
    "CITYNAME_AR": "منقش",
    "CITYNAME_EN": "Manqesh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10384",
    "CITYNAME_AR": "الراكبه (صدر رقعاء)",
    "CITYNAME_EN": "Al-Rakbah (Sadr Raq'aa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10385",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al-Mesbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10386",
    "CITYNAME_AR": "الخانقه",
    "CITYNAME_EN": "Al-Khanqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10387",
    "CITYNAME_AR": "الحصماء",
    "CITYNAME_EN": "Al-Hasmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10388",
    "CITYNAME_AR": "نخر الودن الاعلى",
    "CITYNAME_EN": "Nakhr Al-Wedn Al-A'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10389",
    "CITYNAME_AR": "باطنه الكحلاء",
    "CITYNAME_EN": "Batnah Al-Kahlaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10390",
    "CITYNAME_AR": "ظهره الروحاء",
    "CITYNAME_EN": "Zahrah Al-Rawhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10391",
    "CITYNAME_AR": "ضرس الاحابيش (المزرق)",
    "CITYNAME_EN": "Ders Al-Ahabish (Al-Merziq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10392",
    "CITYNAME_AR": "الداغره السفلى",
    "CITYNAME_EN": "Al-Dagherah Al-Sofla",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10393",
    "CITYNAME_AR": "الداغره العليا",
    "CITYNAME_EN": "Al-Dagherah Al-Olya ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10394",
    "CITYNAME_AR": "البشما",
    "CITYNAME_EN": "Al Bashma ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10395",
    "CITYNAME_AR": "لحج الظور",
    "CITYNAME_EN": "Lahj Al-Zur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10396",
    "CITYNAME_AR": "صدر دالج",
    "CITYNAME_EN": "Sadr Dalej",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10397",
    "CITYNAME_AR": "اللاصقه",
    "CITYNAME_EN": "Allaseqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10398",
    "CITYNAME_AR": "الحثمه",
    "CITYNAME_EN": "Al-Hathmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10399",
    "CITYNAME_AR": "لحج الصلال",
    "CITYNAME_EN": "Lahj Al-Selal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10400",
    "CITYNAME_AR": "القذن",
    "CITYNAME_EN": "Al-Qazn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10401",
    "CITYNAME_AR": "المطلق والمقاطر",
    "CITYNAME_EN": "Al-Motlaq and Al-Maqater ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10402",
    "CITYNAME_AR": "الصفق",
    "CITYNAME_EN": "Al-Safq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10403",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Al Qahbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10404",
    "CITYNAME_AR": "النشيبه",
    "CITYNAME_EN": "Al-Nashibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10405",
    "CITYNAME_AR": "الجثيم",
    "CITYNAME_EN": "Al Jathim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10406",
    "CITYNAME_AR": "قايم جيران",
    "CITYNAME_EN": "Qayim Jiran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10407",
    "CITYNAME_AR": "حرد القواسه",
    "CITYNAME_EN": "Hard Al-Qawasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10408",
    "CITYNAME_AR": "عفيجه الشاميه",
    "CITYNAME_EN": "Afijah Al-Shamiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10409",
    "CITYNAME_AR": "نحاير القتاد",
    "CITYNAME_EN": "Nahayir Al-Qatad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10410",
    "CITYNAME_AR": "عفيجه الجنوبيه",
    "CITYNAME_EN": "Afijah Al-Janubiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10411",
    "CITYNAME_AR": "خايع اشواط",
    "CITYNAME_EN": "Khayi' Ashwat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10412",
    "CITYNAME_AR": "الاكراس",
    "CITYNAME_EN": "Al Akras ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10413",
    "CITYNAME_AR": "الجبهه",
    "CITYNAME_EN": "Al-Jabhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10414",
    "CITYNAME_AR": "عطف الغدير",
    "CITYNAME_EN": "Atf Al-Ghadir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10415",
    "CITYNAME_AR": "مروه الساده",
    "CITYNAME_EN": "Marwah Al-Sadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10416",
    "CITYNAME_AR": "النمايش",
    "CITYNAME_EN": "Al-Namayish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10417",
    "CITYNAME_AR": "الحرده",
    "CITYNAME_EN": "Al-Hardah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10418",
    "CITYNAME_AR": "النضوح",
    "CITYNAME_EN": "Al-Naduh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10419",
    "CITYNAME_AR": "الساهد",
    "CITYNAME_EN": "Al-Sahid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10420",
    "CITYNAME_AR": "الصلب",
    "CITYNAME_EN": "Al-Salb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10421",
    "CITYNAME_AR": "مروه الشيبه",
    "CITYNAME_EN": "Marwah Al-Shibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10422",
    "CITYNAME_AR": "النخر",
    "CITYNAME_EN": "Al-Nakhr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10423",
    "CITYNAME_AR": "القصفه",
    "CITYNAME_EN": "Al Qasfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10424",
    "CITYNAME_AR": "المضاف",
    "CITYNAME_EN": "Al-Modaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10425",
    "CITYNAME_AR": "الموهبه",
    "CITYNAME_EN": "Al-Mawhibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10426",
    "CITYNAME_AR": "الضاجع",
    "CITYNAME_EN": "Al-Daji'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10427",
    "CITYNAME_AR": "هجانه",
    "CITYNAME_EN": "Hajanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10428",
    "CITYNAME_AR": "رهوه البير",
    "CITYNAME_EN": "Rahwa Al-Bir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10429",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10430",
    "CITYNAME_AR": "الحثمه",
    "CITYNAME_EN": "Al-Hathmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10431",
    "CITYNAME_AR": "اللحج",
    "CITYNAME_EN": "Allahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10432",
    "CITYNAME_AR": "المبده",
    "CITYNAME_EN": "Al-Mabdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10433",
    "CITYNAME_AR": "العطفان",
    "CITYNAME_EN": "Al-Atfan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10434",
    "CITYNAME_AR": "الصدرين",
    "CITYNAME_EN": "Al-Sadrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10435",
    "CITYNAME_AR": "قرا",
    "CITYNAME_EN": "Qarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10436",
    "CITYNAME_AR": "عجاور",
    "CITYNAME_EN": "Ajawer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10437",
    "CITYNAME_AR": "شبحه",
    "CITYNAME_EN": "Shabhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10438",
    "CITYNAME_AR": "الزلاله",
    "CITYNAME_EN": "Al-Zulalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10439",
    "CITYNAME_AR": "المصلب",
    "CITYNAME_EN": "Al-Meslab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10440",
    "CITYNAME_AR": "الموطاه",
    "CITYNAME_EN": "Al-Mutah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10441",
    "CITYNAME_AR": "الدماغ",
    "CITYNAME_EN": "Al Demagh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10442",
    "CITYNAME_AR": "ذات خلفين",
    "CITYNAME_EN": "Zat Khalfin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10443",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10444",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10445",
    "CITYNAME_AR": "ذم قمره",
    "CITYNAME_EN": "Zem Qumrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10446",
    "CITYNAME_AR": "ذا الريق",
    "CITYNAME_EN": "Za Al-Riq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10447",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al-Hesn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10448",
    "CITYNAME_AR": "ذات جرب",
    "CITYNAME_EN": "Zat Jerb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10449",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al-Jaz'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10450",
    "CITYNAME_AR": "الاعساف",
    "CITYNAME_EN": "Al-A'saf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10451",
    "CITYNAME_AR": "بجراء",
    "CITYNAME_EN": "Bajraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10452",
    "CITYNAME_AR": "الصخير والشطان",
    "CITYNAME_EN": "Al-Sakhir and Al-Shatan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10453",
    "CITYNAME_AR": "براء",
    "CITYNAME_EN": "Baraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10454",
    "CITYNAME_AR": "لحج",
    "CITYNAME_EN": "Jahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10455",
    "CITYNAME_AR": "جزعه البير",
    "CITYNAME_EN": "Jez'ah Al-Bir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10456",
    "CITYNAME_AR": "الفايطة",
    "CITYNAME_EN": "Al-Faytah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10457",
    "CITYNAME_AR": "الفيطن",
    "CITYNAME_EN": "Al-Faytan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10458",
    "CITYNAME_AR": "رهوه نطع",
    "CITYNAME_EN": "Rahwa Nat'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10459",
    "CITYNAME_AR": "المفظا",
    "CITYNAME_EN": "Al Mafza ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10460",
    "CITYNAME_AR": "معداه",
    "CITYNAME_EN": "Ma'dah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10461",
    "CITYNAME_AR": "ذا الترايم",
    "CITYNAME_EN": "Za Al-Tarayim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10462",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10463",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10464",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10465",
    "CITYNAME_AR": "المصاعيد",
    "CITYNAME_EN": "Al-Msa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10466",
    "CITYNAME_AR": "العرضه",
    "CITYNAME_EN": "Al-Ardah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10467",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Al Hadbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10468",
    "CITYNAME_AR": "مطباه",
    "CITYNAME_EN": "Matbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10469",
    "CITYNAME_AR": "الخطل",
    "CITYNAME_EN": "Al-Khetl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10470",
    "CITYNAME_AR": "الميمنه",
    "CITYNAME_EN": "Al-Maymnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10471",
    "CITYNAME_AR": "الحومه",
    "CITYNAME_EN": "Al Humah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10472",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10473",
    "CITYNAME_AR": "الملبح",
    "CITYNAME_EN": "Al-Malbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10474",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Al-Qa'bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10475",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10476",
    "CITYNAME_AR": "الجزعه ( ضرماي)",
    "CITYNAME_EN": "Al-Jaz'ah (Dermay)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10477",
    "CITYNAME_AR": "ضرماى",
    "CITYNAME_EN": "Dermay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10478",
    "CITYNAME_AR": "حثمه المشبه",
    "CITYNAME_EN": "hathmah Al-Meshbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10479",
    "CITYNAME_AR": "الحجار",
    "CITYNAME_EN": "Al-Hajar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10480",
    "CITYNAME_AR": "اللحج",
    "CITYNAME_EN": "Allahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10481",
    "CITYNAME_AR": "الاعساف",
    "CITYNAME_EN": "Al-A'saf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10482",
    "CITYNAME_AR": "شصران",
    "CITYNAME_EN": "Shasran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10483",
    "CITYNAME_AR": "الهريج",
    "CITYNAME_EN": "Al Harij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10484",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Al-Rahah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10485",
    "CITYNAME_AR": "اللواء",
    "CITYNAME_EN": "Allewaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10486",
    "CITYNAME_AR": "وطن ال مضواح",
    "CITYNAME_EN": "Watn Al Medwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10487",
    "CITYNAME_AR": "ضحى اللواء",
    "CITYNAME_EN": "Duha Allewaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10488",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10489",
    "CITYNAME_AR": "وطن الذروه",
    "CITYNAME_EN": "Watn Al-Zurwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10490",
    "CITYNAME_AR": "الموفا",
    "CITYNAME_EN": "Al-Mufa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10491",
    "CITYNAME_AR": "مودان",
    "CITYNAME_EN": "Mudan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10492",
    "CITYNAME_AR": "الركس (الجن)",
    "CITYNAME_EN": "Al-Reks (Al-Jen) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10493",
    "CITYNAME_AR": "الضجاجه",
    "CITYNAME_EN": "Al-Dajajah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10494",
    "CITYNAME_AR": "قاعه غمره",
    "CITYNAME_EN": "Qa'h Ghamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10495",
    "CITYNAME_AR": "المهلل",
    "CITYNAME_EN": "Al-Mahll",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10496",
    "CITYNAME_AR": "الفوارع",
    "CITYNAME_EN": "Al-Fuwari'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10497",
    "CITYNAME_AR": "المراوح",
    "CITYNAME_EN": "Al-Marawih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10498",
    "CITYNAME_AR": "المواهب",
    "CITYNAME_EN": "Al-Mawahib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10499",
    "CITYNAME_AR": "اللحج",
    "CITYNAME_EN": "Allahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10500",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al-Baq'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10501",
    "CITYNAME_AR": "الدماغ",
    "CITYNAME_EN": "Al Demagh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10502",
    "CITYNAME_AR": "قاريه ال قديمه",
    "CITYNAME_EN": "Qariyah Al Qadimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10503",
    "CITYNAME_AR": "البزخ",
    "CITYNAME_EN": "Al-Bazakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10504",
    "CITYNAME_AR": "المجزاع",
    "CITYNAME_EN": "Al-Mejza'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10505",
    "CITYNAME_AR": "ذى تالق",
    "CITYNAME_EN": "Zi Taliq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10506",
    "CITYNAME_AR": "موياح",
    "CITYNAME_EN": "Mubah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10507",
    "CITYNAME_AR": "حرمات",
    "CITYNAME_EN": "Harmat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10508",
    "CITYNAME_AR": "السحايه",
    "CITYNAME_EN": "Al-Sahabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10509",
    "CITYNAME_AR": "الغزول",
    "CITYNAME_EN": "Al Ghazul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10510",
    "CITYNAME_AR": "ذم سنون",
    "CITYNAME_EN": "Zem Sanun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10511",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10512",
    "CITYNAME_AR": "قاريه غمره",
    "CITYNAME_EN": "Qarbah Ghamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10513",
    "CITYNAME_AR": "قاريه عوال على",
    "CITYNAME_EN": "Qarbah Awal Ali",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10514",
    "CITYNAME_AR": "الجنبه",
    "CITYNAME_EN": "Al-Janbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10515",
    "CITYNAME_AR": "الحريزه",
    "CITYNAME_EN": "Al-Harizah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10516",
    "CITYNAME_AR": "المعرض",
    "CITYNAME_EN": "Al-Mehrid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10517",
    "CITYNAME_AR": "القفلاء",
    "CITYNAME_EN": "Al-Qaflaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10518",
    "CITYNAME_AR": "القعاليل",
    "CITYNAME_EN": "Al-Qa'alil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10519",
    "CITYNAME_AR": "كدمه",
    "CITYNAME_EN": "Kedmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10520",
    "CITYNAME_AR": "ال حرمات",
    "CITYNAME_EN": "Al Harmat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10521",
    "CITYNAME_AR": "الجلل",
    "CITYNAME_EN": "Al-Jall",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10522",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10523",
    "CITYNAME_AR": "جنب نجد",
    "CITYNAME_EN": "Janb Najd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10524",
    "CITYNAME_AR": "قويد اللحج",
    "CITYNAME_EN": "Qued Allahj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10525",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10526",
    "CITYNAME_AR": "اللفج",
    "CITYNAME_EN": "Allafj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10527",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10528",
    "CITYNAME_AR": "الخارجه",
    "CITYNAME_EN": "Al Kharijah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10529",
    "CITYNAME_AR": "لحج غلق",
    "CITYNAME_EN": "Lahj Ghalq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10530",
    "CITYNAME_AR": "الكهفين",
    "CITYNAME_EN": "Al Kahfayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10531",
    "CITYNAME_AR": "شعاب جوهر",
    "CITYNAME_EN": "Shi'ab Gohar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10532",
    "CITYNAME_AR": "السياله",
    "CITYNAME_EN": "Al-Syalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10533",
    "CITYNAME_AR": "المحاكيل",
    "CITYNAME_EN": "Al-Mahakil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10534",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10535",
    "CITYNAME_AR": "هجوم",
    "CITYNAME_EN": "Hojum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10536",
    "CITYNAME_AR": "العانزه",
    "CITYNAME_EN": "Al-Anzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10537",
    "CITYNAME_AR": "المرواح",
    "CITYNAME_EN": "Al-Marawih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10538",
    "CITYNAME_AR": "جو غمره",
    "CITYNAME_EN": "Jaw Ghamra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10539",
    "CITYNAME_AR": "لحج العين",
    "CITYNAME_EN": "Lahj Al-Ain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10540",
    "CITYNAME_AR": "شط الصليف",
    "CITYNAME_EN": "Shat Al-Salif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10541",
    "CITYNAME_AR": "القويد",
    "CITYNAME_EN": "Al-Qawid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10542",
    "CITYNAME_AR": "الرفده",
    "CITYNAME_EN": "Al-Rafdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10543",
    "CITYNAME_AR": "هتاين",
    "CITYNAME_EN": "Htayin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10544",
    "CITYNAME_AR": "القاربه",
    "CITYNAME_EN": "Al-Qaryah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10545",
    "CITYNAME_AR": "سرحه",
    "CITYNAME_EN": "Sarha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10546",
    "CITYNAME_AR": "المصلى (شعول)",
    "CITYNAME_EN": "Al-Masla (Sha'ul) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10547",
    "CITYNAME_AR": "شفا الغراب",
    "CITYNAME_EN": "Shafa Al-Ghorab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10548",
    "CITYNAME_AR": "محضان",
    "CITYNAME_EN": "Mehdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10549",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10550",
    "CITYNAME_AR": "ضحى شوقب",
    "CITYNAME_EN": "Duha Shoqab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10551",
    "CITYNAME_AR": "الشاخص",
    "CITYNAME_EN": "Al-Shakhis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10552",
    "CITYNAME_AR": "البير ال زياد",
    "CITYNAME_EN": "Al-Bir Al Zyad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10553",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Al Duha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10554",
    "CITYNAME_AR": "الصرفه",
    "CITYNAME_EN": "Al-Serfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10555",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10556",
    "CITYNAME_AR": "الصعد",
    "CITYNAME_EN": "Al-Saad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10557",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10558",
    "CITYNAME_AR": "الداره والعانز",
    "CITYNAME_EN": "Al-darah and Al-Aniz ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10559",
    "CITYNAME_AR": "العوده",
    "CITYNAME_EN": "Al-Odah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10560",
    "CITYNAME_AR": "الغبه",
    "CITYNAME_EN": "Al-Ghabbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10561",
    "CITYNAME_AR": "الظفير",
    "CITYNAME_EN": "Al-Zafir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10562",
    "CITYNAME_AR": "الشرقى",
    "CITYNAME_EN": "Al-Shaqa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10563",
    "CITYNAME_AR": "القرعاء",
    "CITYNAME_EN": "Al Qaraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10564",
    "CITYNAME_AR": "ال مجم",
    "CITYNAME_EN": "Al-mejm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10565",
    "CITYNAME_AR": "المجزعه",
    "CITYNAME_EN": "Al-Mejza'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10566",
    "CITYNAME_AR": "البدله",
    "CITYNAME_EN": "Al-Badlah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10567",
    "CITYNAME_AR": "لغبه",
    "CITYNAME_EN": "Laghbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10568",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al-Hamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10569",
    "CITYNAME_AR": "العارضه",
    "CITYNAME_EN": "Al-Ardha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10570",
    "CITYNAME_AR": "الحجار",
    "CITYNAME_EN": "Al-Hajar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10571",
    "CITYNAME_AR": "بلقه ال مغرم",
    "CITYNAME_EN": "Balqah Al Meghrim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10572",
    "CITYNAME_AR": "الوجيف",
    "CITYNAME_EN": "Al Wajeef",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10573",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al-Khatwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10574",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10575",
    "CITYNAME_AR": "بلقه المعمله",
    "CITYNAME_EN": "Balqah Al-Ma'malah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10576",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10577",
    "CITYNAME_AR": "القاريه",
    "CITYNAME_EN": "Al-Qarbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10578",
    "CITYNAME_AR": "الموتد",
    "CITYNAME_EN": "Al-Mutid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10579",
    "CITYNAME_AR": "غمره ال مروح",
    "CITYNAME_EN": "Ghmarah Al Merwih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10580",
    "CITYNAME_AR": "السوده والمعدا",
    "CITYNAME_EN": "Al-Sudah and Al-Me'da",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10581",
    "CITYNAME_AR": "حمراى والابهر",
    "CITYNAME_EN": "Hamray and Al-Abher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10582",
    "CITYNAME_AR": "ركس المروه والنصب",
    "CITYNAME_EN": "Raks Al-Marwah and Al-Nasb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10583",
    "CITYNAME_AR": "الوجيه",
    "CITYNAME_EN": "Al Wajeeh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10584",
    "CITYNAME_AR": "المسور",
    "CITYNAME_EN": "Al-Masur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10585",
    "CITYNAME_AR": "آل عقيله",
    "CITYNAME_EN": "Al-Aqilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10586",
    "CITYNAME_AR": "الفاله",
    "CITYNAME_EN": "Al-Falah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10587",
    "CITYNAME_AR": "ذم حجم",
    "CITYNAME_EN": "Zem Hajm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10588",
    "CITYNAME_AR": "الجهراء",
    "CITYNAME_EN": "Al Jahra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10589",
    "CITYNAME_AR": "شناكه",
    "CITYNAME_EN": "Shanakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10590",
    "CITYNAME_AR": "ملحج",
    "CITYNAME_EN": "Malhaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10591",
    "CITYNAME_AR": "الخورمه",
    "CITYNAME_EN": "Al-Khormah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10592",
    "CITYNAME_AR": "خرار",
    "CITYNAME_EN": "Kherar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10593",
    "CITYNAME_AR": "الصليل",
    "CITYNAME_EN": "Al-Salil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10594",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10595",
    "CITYNAME_AR": "عطف المسكته",
    "CITYNAME_EN": "Atf Al-maskanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10596",
    "CITYNAME_AR": "خميس كسان",
    "CITYNAME_EN": "khamis Kasan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10597",
    "CITYNAME_AR": "عدا لرس",
    "CITYNAME_EN": "Ada Lars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10598",
    "CITYNAME_AR": "سقامه",
    "CITYNAME_EN": "Saqamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10599",
    "CITYNAME_AR": "البتيله",
    "CITYNAME_EN": "Al-Batilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10600",
    "CITYNAME_AR": "وطن الرجال",
    "CITYNAME_EN": "Watn Al-Rejal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10601",
    "CITYNAME_AR": "المرواح",
    "CITYNAME_EN": "Al-Marawih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10602",
    "CITYNAME_AR": "الكدس",
    "CITYNAME_EN": "Al-Keds",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10603",
    "CITYNAME_AR": "مكشف",
    "CITYNAME_EN": "Makshif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10604",
    "CITYNAME_AR": "الشفا",
    "CITYNAME_EN": "Al Shefa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10605",
    "CITYNAME_AR": "الكدحه",
    "CITYNAME_EN": "Al-Kadhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10606",
    "CITYNAME_AR": "الوطن",
    "CITYNAME_EN": "Al-Watan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10607",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10608",
    "CITYNAME_AR": "حباى",
    "CITYNAME_EN": "Habay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10609",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10610",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "Al-Sad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10611",
    "CITYNAME_AR": "الصليف والفصيله",
    "CITYNAME_EN": "Al-Salif and Al-Fasilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10612",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10613",
    "CITYNAME_AR": "الجزعه والافقه",
    "CITYNAME_EN": "Al-Jaz'ah and Al-Afqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10614",
    "CITYNAME_AR": "عطفه غمره",
    "CITYNAME_EN": "Atfah Ghamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10615",
    "CITYNAME_AR": "الجدره",
    "CITYNAME_EN": "Al Jadrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10616",
    "CITYNAME_AR": "الجدره",
    "CITYNAME_EN": "Al Jadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10617",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Al-Gellah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10618",
    "CITYNAME_AR": "سد فقوه",
    "CITYNAME_EN": "Sad faqwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10619",
    "CITYNAME_AR": "الظهرى",
    "CITYNAME_EN": "Al-Zohra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10620",
    "CITYNAME_AR": "القتب",
    "CITYNAME_EN": "Al-Qatb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10621",
    "CITYNAME_AR": "الاطراف",
    "CITYNAME_EN": "Al Atraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10622",
    "CITYNAME_AR": "الوزير",
    "CITYNAME_EN": "Al-Wazir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10623",
    "CITYNAME_AR": "المعدا",
    "CITYNAME_EN": "Al-Me'da",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10624",
    "CITYNAME_AR": "معياس",
    "CITYNAME_EN": "Me'yas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10625",
    "CITYNAME_AR": "امسنه والمقتعد",
    "CITYNAME_EN": "Amsenah and Al-Moqta'ed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10626",
    "CITYNAME_AR": "الحجف (صدره قس)",
    "CITYNAME_EN": "Al-Hajf (Sadrah Qes)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10627",
    "CITYNAME_AR": "مصدار",
    "CITYNAME_EN": "Mesdar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10628",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10629",
    "CITYNAME_AR": "اللحجن",
    "CITYNAME_EN": "Allahjen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10630",
    "CITYNAME_AR": "قرضه",
    "CITYNAME_EN": "Qardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10631",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10632",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "Al-Safaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10633",
    "CITYNAME_AR": "امقفيل والمهلل",
    "CITYNAME_EN": "Amqafil and Al-Mohall",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10634",
    "CITYNAME_AR": "حلا",
    "CITYNAME_EN": "Hala",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10635",
    "CITYNAME_AR": "امقضى والفصايل",
    "CITYNAME_EN": "Amqadi and Al-Fasyil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10636",
    "CITYNAME_AR": "هبا",
    "CITYNAME_EN": "Hoba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10637",
    "CITYNAME_AR": "الركس والمليجه والعارضن",
    "CITYNAME_EN": "Al-Reks, Al-meligah, and Al-Ardin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10638",
    "CITYNAME_AR": "امهذوب",
    "CITYNAME_EN": "Amhzub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10639",
    "CITYNAME_AR": "لحج الماء",
    "CITYNAME_EN": "Lahj Al-Maa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10640",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Al Duha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10641",
    "CITYNAME_AR": "البيح",
    "CITYNAME_EN": "Al-Beeh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10642",
    "CITYNAME_AR": "ذنب سنام",
    "CITYNAME_EN": "Zanb Senam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10643",
    "CITYNAME_AR": "ثلوث حسوه",
    "CITYNAME_EN": "Thaluth Haswah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10644",
    "CITYNAME_AR": "عطفه السوق",
    "CITYNAME_EN": "Atfah Sl-Souq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10645",
    "CITYNAME_AR": "جله شصعه",
    "CITYNAME_EN": "Jellah Shas'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10646",
    "CITYNAME_AR": "العوده (ال سعيد)",
    "CITYNAME_EN": "Al-Odah (Al Saed)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10647",
    "CITYNAME_AR": "أريمن",
    "CITYNAME_EN": "Arimen ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10648",
    "CITYNAME_AR": "النحره",
    "CITYNAME_EN": "Al-Nehrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10649",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Qa'rah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10650",
    "CITYNAME_AR": "ظرايم",
    "CITYNAME_EN": "Zrayim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10651",
    "CITYNAME_AR": "القشبه",
    "CITYNAME_EN": "Al-Qashbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10652",
    "CITYNAME_AR": "بضيع",
    "CITYNAME_EN": "Badi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10653",
    "CITYNAME_AR": "الغليل",
    "CITYNAME_EN": "Al-Ghalil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10654",
    "CITYNAME_AR": "العقصه",
    "CITYNAME_EN": "Al-Aqasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10655",
    "CITYNAME_AR": "قريه الجرف",
    "CITYNAME_EN": "al-Jarf Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10656",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10657",
    "CITYNAME_AR": "وطن ال سالم",
    "CITYNAME_EN": "Watn Al Salem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10658",
    "CITYNAME_AR": "ذنب الوقيع",
    "CITYNAME_EN": "Zanb Al-Waqi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10659",
    "CITYNAME_AR": "المقراء",
    "CITYNAME_EN": "Al-Maqraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10660",
    "CITYNAME_AR": "سرال حجيل",
    "CITYNAME_EN": "Seral Hajil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10661",
    "CITYNAME_AR": "سلل",
    "CITYNAME_EN": "Sall",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10662",
    "CITYNAME_AR": "شرف الزعابل",
    "CITYNAME_EN": "Sharaf Al-Za'abel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10663",
    "CITYNAME_AR": "المكبه",
    "CITYNAME_EN": "Al Makbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10664",
    "CITYNAME_AR": "الاجوال",
    "CITYNAME_EN": "Al-Ajwal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10665",
    "CITYNAME_AR": "المغرده",
    "CITYNAME_EN": "Al-Maghradah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10666",
    "CITYNAME_AR": "الجمامه",
    "CITYNAME_EN": "Al-Jmamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10667",
    "CITYNAME_AR": "قريه ال مشقي",
    "CITYNAME_EN": "Al Meshqa Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10668",
    "CITYNAME_AR": "الحدمة",
    "CITYNAME_EN": "Al Hadmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10669",
    "CITYNAME_AR": "المشبله",
    "CITYNAME_EN": "Al-Mashbalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10670",
    "CITYNAME_AR": "ال مفرح",
    "CITYNAME_EN": "Al Mefrih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10671",
    "CITYNAME_AR": "راجح (قاريه بنى جونه)",
    "CITYNAME_EN": "rajih (Bni Junah Village)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10672",
    "CITYNAME_AR": "سرال محروس",
    "CITYNAME_EN": "Seral Mahrous",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10673",
    "CITYNAME_AR": "المنظر",
    "CITYNAME_EN": "Al-Manzer",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10674",
    "CITYNAME_AR": "قرا اسفل الجر",
    "CITYNAME_EN": "Qara Asfal Al-Jar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10675",
    "CITYNAME_AR": "ال سعدان",
    "CITYNAME_EN": "Al Se'dan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10676",
    "CITYNAME_AR": "المخلفه",
    "CITYNAME_EN": "Al-Makhlafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10677",
    "CITYNAME_AR": "الحذرور",
    "CITYNAME_EN": "Al-Hazrur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10678",
    "CITYNAME_AR": "الخشعه",
    "CITYNAME_EN": "Al-Khash'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10679",
    "CITYNAME_AR": "ال جرو",
    "CITYNAME_EN": "Al-Jarw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10680",
    "CITYNAME_AR": "حرف ال عائض جابر",
    "CITYNAME_EN": "Harf Al Aed Jaber ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10681",
    "CITYNAME_AR": "حرف ال مسروي",
    "CITYNAME_EN": "Harf Al Masrwa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10682",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10683",
    "CITYNAME_AR": "مسكتة الحجله",
    "CITYNAME_EN": "Maskatah Al-Hejlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10684",
    "CITYNAME_AR": "نوخان",
    "CITYNAME_EN": "Noukhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10685",
    "CITYNAME_AR": "جمره",
    "CITYNAME_EN": "jamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10686",
    "CITYNAME_AR": "حضن العره",
    "CITYNAME_EN": "Hodn Al-Errah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10687",
    "CITYNAME_AR": "حضن ال فحمان",
    "CITYNAME_EN": "Hodn Al fahman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10688",
    "CITYNAME_AR": "ال جديع",
    "CITYNAME_EN": "Al Jadi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10689",
    "CITYNAME_AR": "البادية",
    "CITYNAME_EN": "Al-Badiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10690",
    "CITYNAME_AR": "ال سعيد",
    "CITYNAME_EN": "Al Saed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10691",
    "CITYNAME_AR": "المسلب والحرف",
    "CITYNAME_EN": "Al-Maslab and Al-Harf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10692",
    "CITYNAME_AR": "ذم سوده",
    "CITYNAME_EN": "Zem Sudah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10693",
    "CITYNAME_AR": "لباخ",
    "CITYNAME_EN": "Libakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10694",
    "CITYNAME_AR": "المعشره",
    "CITYNAME_EN": "Al-Ma'sharah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10695",
    "CITYNAME_AR": "ام السعى",
    "CITYNAME_EN": "Umm Al-Sa'y",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10696",
    "CITYNAME_AR": "مندر ذهبان",
    "CITYNAME_EN": "Mander Rahban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10697",
    "CITYNAME_AR": "المسابله",
    "CITYNAME_EN": "Al-Masabelah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10698",
    "CITYNAME_AR": "الرديف",
    "CITYNAME_EN": "Al Radifah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10699",
    "CITYNAME_AR": "الرقى",
    "CITYNAME_EN": "Al-Raqay",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10700",
    "CITYNAME_AR": "حوره",
    "CITYNAME_EN": "Hurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10701",
    "CITYNAME_AR": "العنقه",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10702",
    "CITYNAME_AR": "الصفيح",
    "CITYNAME_EN": "Al-Safih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10703",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Al-Qalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10704",
    "CITYNAME_AR": "الغبيره",
    "CITYNAME_EN": "Al-Ghabirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10705",
    "CITYNAME_AR": "ام لصوب",
    "CITYNAME_EN": "Umm Lasub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10706",
    "CITYNAME_AR": "اللواء",
    "CITYNAME_EN": "Allewaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10707",
    "CITYNAME_AR": "ذنب الغليل",
    "CITYNAME_EN": "Zanb Al-Ghalil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10708",
    "CITYNAME_AR": "الغيناء",
    "CITYNAME_EN": "Al-Ghynaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10709",
    "CITYNAME_AR": "سرو المرار",
    "CITYNAME_EN": "Sarw Al-Merar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10710",
    "CITYNAME_AR": "الضاجعه",
    "CITYNAME_EN": "Al-Daji'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10711",
    "CITYNAME_AR": "وجه السرو",
    "CITYNAME_EN": "Wajh Al-Sarw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10712",
    "CITYNAME_AR": "المطلعه",
    "CITYNAME_EN": "Al-Matla'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10713",
    "CITYNAME_AR": "حيد الصدر",
    "CITYNAME_EN": "Hidr Al-Sadr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10714",
    "CITYNAME_AR": "حيد الحلل",
    "CITYNAME_EN": "Hid Al-Helel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10715",
    "CITYNAME_AR": "حيد الخلوه",
    "CITYNAME_EN": "Hid Al-Kholwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10716",
    "CITYNAME_AR": "مويركة (ام ويركة)",
    "CITYNAME_EN": "Mobrakah (Umm Wabrakah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10717",
    "CITYNAME_AR": "قريه الصحبه والقاريه",
    "CITYNAME_EN": "Al-Suhbah and Al-Qaribah Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10718",
    "CITYNAME_AR": "الجرين",
    "CITYNAME_EN": "Al Jurain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10719",
    "CITYNAME_AR": "المنسله",
    "CITYNAME_EN": "Al-Mansalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10720",
    "CITYNAME_AR": "المجمع",
    "CITYNAME_EN": "Al Majma'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10721",
    "CITYNAME_AR": "المكبشه",
    "CITYNAME_EN": "Al Makbashah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10722",
    "CITYNAME_AR": "ال الرويعى",
    "CITYNAME_EN": "Al-Rowi'y",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10723",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10724",
    "CITYNAME_AR": "الرصعه",
    "CITYNAME_EN": "Al Ras'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10725",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Al-Dahiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10726",
    "CITYNAME_AR": "السرو الاسفل",
    "CITYNAME_EN": "Al-Sarw Al-Asfel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10727",
    "CITYNAME_AR": "سرو المسلمه (الأعلى)",
    "CITYNAME_EN": "Sarw Al-Maslamah (Al-A'la)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10728",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10729",
    "CITYNAME_AR": "المعتق",
    "CITYNAME_EN": "Al-Ma'tiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10730",
    "CITYNAME_AR": "القاريه",
    "CITYNAME_EN": "Al-Qarbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10731",
    "CITYNAME_AR": "راس الجبل",
    "CITYNAME_EN": "ras Al-Jabl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10732",
    "CITYNAME_AR": "الشرقيه",
    "CITYNAME_EN": "Al-Sharqiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10733",
    "CITYNAME_AR": "المقعر",
    "CITYNAME_EN": "Al-Maq'ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10734",
    "CITYNAME_AR": "جله المشمطه",
    "CITYNAME_EN": "Jelah Al-Mashmatah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10735",
    "CITYNAME_AR": "جله المحرقه",
    "CITYNAME_EN": "Jelah Al-Mahraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10736",
    "CITYNAME_AR": "قوعان",
    "CITYNAME_EN": "Qu'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10737",
    "CITYNAME_AR": "الرحوب",
    "CITYNAME_EN": "Al Rahub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10738",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10739",
    "CITYNAME_AR": "صدر المغلبة بحسوه",
    "CITYNAME_EN": "Sadr Al-Maghlabah in Haswah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10740",
    "CITYNAME_AR": "شوكان الاعلى والاسفل",
    "CITYNAME_EN": "Shukan Al-A'la and Al-Asfal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10741",
    "CITYNAME_AR": "ذهبان (صمع)",
    "CITYNAME_EN": "Zahban (Samgh)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10742",
    "CITYNAME_AR": "ال ابوعلبه",
    "CITYNAME_EN": "Al Abu Elbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10743",
    "CITYNAME_AR": "نشام (نهامن)",
    "CITYNAME_EN": "Nasham (Nahamin)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10744",
    "CITYNAME_AR": "الفصيله (العسامه)",
    "CITYNAME_EN": "Al-Fasilah (Al-Asamah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10745",
    "CITYNAME_AR": "الحقبه",
    "CITYNAME_EN": "Al Haqbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10746",
    "CITYNAME_AR": "المصاغه",
    "CITYNAME_EN": "Al-Mesbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10747",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10748",
    "CITYNAME_AR": "ذنب دبلاء",
    "CITYNAME_EN": "Zanb Deblaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10749",
    "CITYNAME_AR": "محضه",
    "CITYNAME_EN": "Mahdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10750",
    "CITYNAME_AR": "احمار",
    "CITYNAME_EN": "Ahmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10751",
    "CITYNAME_AR": "صره",
    "CITYNAME_EN": " Serah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10752",
    "CITYNAME_AR": "السرحانى",
    "CITYNAME_EN": "Al-Serhany ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10753",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10754",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10755",
    "CITYNAME_AR": "سلعا",
    "CITYNAME_EN": "Sal'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10756",
    "CITYNAME_AR": "صوله",
    "CITYNAME_EN": "Sola",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10757",
    "CITYNAME_AR": "ظلمه",
    "CITYNAME_EN": "Dholm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10758",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Al-Rayghah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10759",
    "CITYNAME_AR": "الزبيه (الحجاب)",
    "CITYNAME_EN": "Al-Zibah (Al-Hejab)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10760",
    "CITYNAME_AR": "ذنب صره",
    "CITYNAME_EN": "Zanb Durrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10761",
    "CITYNAME_AR": "اوقب",
    "CITYNAME_EN": "Awqib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10762",
    "CITYNAME_AR": "مسلمه",
    "CITYNAME_EN": "Maslamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10763",
    "CITYNAME_AR": "رحاب",
    "CITYNAME_EN": "Rehab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10764",
    "CITYNAME_AR": "مخمص",
    "CITYNAME_EN": "Makhmas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10765",
    "CITYNAME_AR": "الخطام",
    "CITYNAME_EN": "Al-Khetam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10766",
    "CITYNAME_AR": "ضد حاى",
    "CITYNAME_EN": "Ded Hay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10767",
    "CITYNAME_AR": "فيشان",
    "CITYNAME_EN": "Fishan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10768",
    "CITYNAME_AR": "بئر فيشان",
    "CITYNAME_EN": "Bir Fishan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10769",
    "CITYNAME_AR": "الرقو",
    "CITYNAME_EN": "Al Raqw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10770",
    "CITYNAME_AR": "السند",
    "CITYNAME_EN": "Al-Sanad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10771",
    "CITYNAME_AR": "البحص",
    "CITYNAME_EN": "Al-Bahs",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10772",
    "CITYNAME_AR": "ذاالمكيمل",
    "CITYNAME_EN": "Za Al-Mokaymil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10773",
    "CITYNAME_AR": "النابطه",
    "CITYNAME_EN": "Al-Nabitah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10774",
    "CITYNAME_AR": "المحجا",
    "CITYNAME_EN": "Al-Mahja",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10775",
    "CITYNAME_AR": "الخليف والمحجاي",
    "CITYNAME_EN": "Al-Khalif and Al-Mehjay",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10776",
    "CITYNAME_AR": "المبتل",
    "CITYNAME_EN": "Al-Mabtel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10777",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Al-Jarf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10778",
    "CITYNAME_AR": "المخرم",
    "CITYNAME_EN": "Al-Makhram",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10779",
    "CITYNAME_AR": "صدر رحب",
    "CITYNAME_EN": "Sadr Rahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10780",
    "CITYNAME_AR": "روق",
    "CITYNAME_EN": "Ruq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10781",
    "CITYNAME_AR": "معلين",
    "CITYNAME_EN": "Ma'lin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10782",
    "CITYNAME_AR": "ال مريع",
    "CITYNAME_EN": "Al Merbi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10783",
    "CITYNAME_AR": "ال هادى",
    "CITYNAME_EN": "Al Hady",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10784",
    "CITYNAME_AR": "ال سالم",
    "CITYNAME_EN": " Al Salem",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10785",
    "CITYNAME_AR": "ال شار",
    "CITYNAME_EN": "Al Shar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10786",
    "CITYNAME_AR": "ال عواض",
    "CITYNAME_EN": "Al Ewad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10787",
    "CITYNAME_AR": "الحرف الاعلى (اللواء)",
    "CITYNAME_EN": "Al Harf Al-A'la (Allewaa) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10788",
    "CITYNAME_AR": "الشروه",
    "CITYNAME_EN": "Al-Sharwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10789",
    "CITYNAME_AR": "المحدثه",
    "CITYNAME_EN": "Al-Mahdathah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10790",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "Al-Sedrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10791",
    "CITYNAME_AR": "حرف المكثره",
    "CITYNAME_EN": "Harf Al-Maktharah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10792",
    "CITYNAME_AR": "باحه روق",
    "CITYNAME_EN": "Bahet Ruq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10793",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10794",
    "CITYNAME_AR": "شرا روق",
    "CITYNAME_EN": "Shara Ruq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10795",
    "CITYNAME_AR": "قريه ال عامر",
    "CITYNAME_EN": "Al Amer Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10796",
    "CITYNAME_AR": "حرف المصلحه",
    "CITYNAME_EN": "Al-Mashlahah Harf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10797",
    "CITYNAME_AR": "الصطه",
    "CITYNAME_EN": "Al-Satah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10798",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Al-Khayal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10799",
    "CITYNAME_AR": "الجازع",
    "CITYNAME_EN": "Al-Jazi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10800",
    "CITYNAME_AR": "ال فرحان",
    "CITYNAME_EN": "Al Farhan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10801",
    "CITYNAME_AR": "المسنه",
    "CITYNAME_EN": "Al-Masnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10802",
    "CITYNAME_AR": "العرضه",
    "CITYNAME_EN": "Al-Ardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10803",
    "CITYNAME_AR": "سرو المخارمه (المخارمه)",
    "CITYNAME_EN": "Sarw Al-Makharemah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10804",
    "CITYNAME_AR": "القتير",
    "CITYNAME_EN": "Al-Qatir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10805",
    "CITYNAME_AR": "قذيلى",
    "CITYNAME_EN": "Qazily ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10806",
    "CITYNAME_AR": "ال غرامه",
    "CITYNAME_EN": "Al Gharmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10807",
    "CITYNAME_AR": "قريه ال الشعبى",
    "CITYNAME_EN": "Al She'by Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10808",
    "CITYNAME_AR": "ال عراف",
    "CITYNAME_EN": "Al Arraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10809",
    "CITYNAME_AR": "طنف (ال خوده)",
    "CITYNAME_EN": "Tanf (Al Khudah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10810",
    "CITYNAME_AR": "العصاده",
    "CITYNAME_EN": "Al Asadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10811",
    "CITYNAME_AR": "مندريره",
    "CITYNAME_EN": "Mandrirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10812",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "Al Sel'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10813",
    "CITYNAME_AR": "قارية بني جونة",
    "CITYNAME_EN": "Bni Junah Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10814",
    "CITYNAME_AR": "صدريره",
    "CITYNAME_EN": "Sadrirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10815",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10816",
    "CITYNAME_AR": "قراه",
    "CITYNAME_EN": "Qarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10817",
    "CITYNAME_AR": "الصدر والغليل",
    "CITYNAME_EN": "Al-Sadr and Al-Ghalil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10818",
    "CITYNAME_AR": "جلجول",
    "CITYNAME_EN": "Jaljul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10819",
    "CITYNAME_AR": "الصلقه",
    "CITYNAME_EN": "Al-Salqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10820",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10821",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10822",
    "CITYNAME_AR": "شط ميكه",
    "CITYNAME_EN": "Shat Mikah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10823",
    "CITYNAME_AR": "الطويل",
    "CITYNAME_EN": "Al-Tawil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10824",
    "CITYNAME_AR": "برقا السفلى (الردحة)",
    "CITYNAME_EN": "Barqah Al-Suflah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10825",
    "CITYNAME_AR": "الحبس",
    "CITYNAME_EN": "Al-Habs",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10826",
    "CITYNAME_AR": "عطفه المصاعده",
    "CITYNAME_EN": "Atfah Al-Masa'dah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10827",
    "CITYNAME_AR": "الزهراء",
    "CITYNAME_EN": "Al Zahraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10828",
    "CITYNAME_AR": "جو الصحن",
    "CITYNAME_EN": "Jaw Al Sahn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10829",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al-Khatwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10830",
    "CITYNAME_AR": "الربط",
    "CITYNAME_EN": "Linking",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10831",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10832",
    "CITYNAME_AR": "عصال",
    "CITYNAME_EN": "Isal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10833",
    "CITYNAME_AR": "لبان",
    "CITYNAME_EN": "Liban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10834",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10835",
    "CITYNAME_AR": "فى المشبله",
    "CITYNAME_EN": "Al-Mashbalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10836",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Al-Dahiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10837",
    "CITYNAME_AR": "الجزوه والقصبه",
    "CITYNAME_EN": "Al-Jazwah and Al-Qasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10838",
    "CITYNAME_AR": "الذروه",
    "CITYNAME_EN": "Al Zurwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10839",
    "CITYNAME_AR": "صدر رحب",
    "CITYNAME_EN": "Sadr Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10840",
    "CITYNAME_AR": "القصبات والفرعان",
    "CITYNAME_EN": "Al-Qasabat and Al-far'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10841",
    "CITYNAME_AR": "ذات لنمى",
    "CITYNAME_EN": "Zat Lenmy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10842",
    "CITYNAME_AR": "الرضه",
    "CITYNAME_EN": "Al-Redah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10843",
    "CITYNAME_AR": "الصيق",
    "CITYNAME_EN": "Al-Siq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10844",
    "CITYNAME_AR": "بيغ",
    "CITYNAME_EN": "Bigh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10845",
    "CITYNAME_AR": "الكري",
    "CITYNAME_EN": "Al Kary",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10846",
    "CITYNAME_AR": "الشقبه",
    "CITYNAME_EN": "Al-Shaqbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10847",
    "CITYNAME_AR": "الموتداه",
    "CITYNAME_EN": "Al-Motdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10848",
    "CITYNAME_AR": "الوطن الاعلى",
    "CITYNAME_EN": "Al-Watan Al-A'la ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10849",
    "CITYNAME_AR": "وادى عينين (الحصحص والعطفه)",
    "CITYNAME_EN": "Wadi Aynayn (Al-Hashas and Al-Atfah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10850",
    "CITYNAME_AR": "شط نحابى",
    "CITYNAME_EN": "Shat Nahaby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10851",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Al-Qalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10852",
    "CITYNAME_AR": "الجما",
    "CITYNAME_EN": "Al-Jama",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10853",
    "CITYNAME_AR": "الكدحه",
    "CITYNAME_EN": "Al-Kadhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10854",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "Al-Sad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10855",
    "CITYNAME_AR": "وادى محليه (البيضاء)",
    "CITYNAME_EN": "Wadi Mahilah (Al-Baydaa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10856",
    "CITYNAME_AR": "الزملج",
    "CITYNAME_EN": "Al-Zumlaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10857",
    "CITYNAME_AR": "ايسر (العرقين)",
    "CITYNAME_EN": "Ayser (Al-Erqin)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10858",
    "CITYNAME_AR": "المعدا",
    "CITYNAME_EN": "Al-Me'da",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10859",
    "CITYNAME_AR": "لوذان",
    "CITYNAME_EN": "luzan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10860",
    "CITYNAME_AR": "قعوه ال شهران",
    "CITYNAME_EN": "Qa'wah Al Shahran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10861",
    "CITYNAME_AR": "مخرومه",
    "CITYNAME_EN": "makhrumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10862",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10863",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10864",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "Al-Sriyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10865",
    "CITYNAME_AR": "الجامه",
    "CITYNAME_EN": "Al-Jamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10866",
    "CITYNAME_AR": "الرايغة",
    "CITYNAME_EN": "Al-Rayghah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10867",
    "CITYNAME_AR": "حبيل الميمنه",
    "CITYNAME_EN": "Habil Al-Maynanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10868",
    "CITYNAME_AR": "الرديف (العطفه)",
    "CITYNAME_EN": "Al-Radif (Al-Atfah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10869",
    "CITYNAME_AR": "برقا العليا (الفله والمعمل)",
    "CITYNAME_EN": "Barqa Al-Olya (Al-Fullah and Al-Ma'mal)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10870",
    "CITYNAME_AR": "زهب العراقيب",
    "CITYNAME_EN": "Zahb Al-Araqib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10871",
    "CITYNAME_AR": "قرن الميفاء",
    "CITYNAME_EN": "Qarn Al Mifaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10872",
    "CITYNAME_AR": "الزهراء",
    "CITYNAME_EN": "Al Zahraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10873",
    "CITYNAME_AR": "الحرده",
    "CITYNAME_EN": "Al-Hardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10874",
    "CITYNAME_AR": "مطرقه",
    "CITYNAME_EN": "Matraqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10875",
    "CITYNAME_AR": "الدمنه",
    "CITYNAME_EN": "Al Demnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10876",
    "CITYNAME_AR": "القمره",
    "CITYNAME_EN": "Al-Qomrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10877",
    "CITYNAME_AR": "قله الهبلى",
    "CITYNAME_EN": "Qolah Al-Hebla",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10878",
    "CITYNAME_AR": "الملاذه والحيق",
    "CITYNAME_EN": "Al-Malazah and Al-Hiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10879",
    "CITYNAME_AR": "وادى العشير (الحبس)",
    "CITYNAME_EN": "Wadi Al-Ashir (Al-Habs)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10880",
    "CITYNAME_AR": "حبيل ناصر",
    "CITYNAME_EN": "Habil Naser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10881",
    "CITYNAME_AR": "لج الصانع",
    "CITYNAME_EN": "Laj Al-Sani'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10882",
    "CITYNAME_AR": "المختلف",
    "CITYNAME_EN": "Al-Mokhtalaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10883",
    "CITYNAME_AR": "ابو ذراع",
    "CITYNAME_EN": "Abu Zira' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10884",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10885",
    "CITYNAME_AR": "اسلاى",
    "CITYNAME_EN": "Aslay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10886",
    "CITYNAME_AR": "قرن الكدره",
    "CITYNAME_EN": "Qarn Al-Kadrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10887",
    "CITYNAME_AR": "المعتق",
    "CITYNAME_EN": "Al-Ma'tiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10888",
    "CITYNAME_AR": "المضبور",
    "CITYNAME_EN": "Al-Madbur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10889",
    "CITYNAME_AR": "الارايمه",
    "CITYNAME_EN": "Al-Araymah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10890",
    "CITYNAME_AR": "جو مجيبه",
    "CITYNAME_EN": "Jaw Majibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10891",
    "CITYNAME_AR": "حبيل العذرى",
    "CITYNAME_EN": "Habil Al-Ozri ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10892",
    "CITYNAME_AR": "حبيل العطيب",
    "CITYNAME_EN": "Habil Al-Atib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10893",
    "CITYNAME_AR": "الردم",
    "CITYNAME_EN": "Al Radm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10894",
    "CITYNAME_AR": "الهداي",
    "CITYNAME_EN": "Al-hday ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10895",
    "CITYNAME_AR": "مصروره",
    "CITYNAME_EN": "Masrurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10896",
    "CITYNAME_AR": "الحصحص",
    "CITYNAME_EN": "Al-Hashas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10897",
    "CITYNAME_AR": "القعمه",
    "CITYNAME_EN": "Al-Qa'mah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10898",
    "CITYNAME_AR": "البقام",
    "CITYNAME_EN": "Al-Baqam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10899",
    "CITYNAME_AR": "زهب راجح",
    "CITYNAME_EN": "Zahb Rajih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10900",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10901",
    "CITYNAME_AR": "لج السواحل",
    "CITYNAME_EN": "Laj Al-Sawahil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10902",
    "CITYNAME_AR": "الهدانه",
    "CITYNAME_EN": "Al-Hedanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10903",
    "CITYNAME_AR": "قريه ال مفلح",
    "CITYNAME_EN": "Al Meflih Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10904",
    "CITYNAME_AR": "البلاصا",
    "CITYNAME_EN": "Al-Balasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10905",
    "CITYNAME_AR": "المدان",
    "CITYNAME_EN": "Al-Medan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10906",
    "CITYNAME_AR": "الجشه",
    "CITYNAME_EN": "Al Jishah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10907",
    "CITYNAME_AR": "رجال المع",
    "CITYNAME_EN": "Rijal Al Ma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10908",
    "CITYNAME_AR": "الرقه",
    "CITYNAME_EN": "Al-Reqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10909",
    "CITYNAME_AR": "الزهب",
    "CITYNAME_EN": "Al Zahb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10910",
    "CITYNAME_AR": "حبيل ال غرامه",
    "CITYNAME_EN": "Habil Al Gharamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10911",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10912",
    "CITYNAME_AR": "قبله",
    "CITYNAME_EN": "Qiblah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10913",
    "CITYNAME_AR": "المعقل",
    "CITYNAME_EN": "Al-Ma'qil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10914",
    "CITYNAME_AR": "الجمامه",
    "CITYNAME_EN": "Al-Jmamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10915",
    "CITYNAME_AR": "ريم بنى جونه (الشعراء)",
    "CITYNAME_EN": "Rim Bni Junah (Al-Sho'araa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10916",
    "CITYNAME_AR": "الخجاج",
    "CITYNAME_EN": "Al-Khajaj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10917",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10918",
    "CITYNAME_AR": "الجازع",
    "CITYNAME_EN": "Al-Jazi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10919",
    "CITYNAME_AR": "غروه",
    "CITYNAME_EN": "Gharwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10920",
    "CITYNAME_AR": "لهد",
    "CITYNAME_EN": "Lahd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10921",
    "CITYNAME_AR": "احمار",
    "CITYNAME_EN": "Ahmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10922",
    "CITYNAME_AR": "الحضن والسليله",
    "CITYNAME_EN": "Al-Hodn and Al-Salilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10923",
    "CITYNAME_AR": "ذم قاع",
    "CITYNAME_EN": "Zim Qa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10924",
    "CITYNAME_AR": "الصما",
    "CITYNAME_EN": "Al-Saman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10925",
    "CITYNAME_AR": "لصناي",
    "CITYNAME_EN": "Lasnay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10926",
    "CITYNAME_AR": "وادى الاثل",
    "CITYNAME_EN": "Wadi Al-Athl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10927",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Al-Rayghah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10928",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10929",
    "CITYNAME_AR": "المقنا",
    "CITYNAME_EN": "Al-Maqna",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10930",
    "CITYNAME_AR": "رحبه",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10931",
    "CITYNAME_AR": "ردهن",
    "CITYNAME_EN": "Radhan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10932",
    "CITYNAME_AR": "جزعه فقوه",
    "CITYNAME_EN": "Jaz'ah Faqwa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10933",
    "CITYNAME_AR": "وغبه",
    "CITYNAME_EN": "Waghbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10934",
    "CITYNAME_AR": "جزعه الغيه",
    "CITYNAME_EN": "Jaz'a Al-Ghiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10935",
    "CITYNAME_AR": "عصفراء",
    "CITYNAME_EN": "Asfaraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10936",
    "CITYNAME_AR": "الحصون",
    "CITYNAME_EN": "Al Hasoun",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10937",
    "CITYNAME_AR": "ذات عم",
    "CITYNAME_EN": "Zat Am ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10938",
    "CITYNAME_AR": "شرزا",
    "CITYNAME_EN": "Sherza ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10939",
    "CITYNAME_AR": "الباخه",
    "CITYNAME_EN": "Al-Bakhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10940",
    "CITYNAME_AR": "الصليله",
    "CITYNAME_EN": "Al-Salilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10941",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Al-Jarf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10942",
    "CITYNAME_AR": "المعتق",
    "CITYNAME_EN": "Al-Ma'tiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10943",
    "CITYNAME_AR": "مندر المحلال",
    "CITYNAME_EN": "Mender Al-Mihlal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10944",
    "CITYNAME_AR": "العطف الاكحل",
    "CITYNAME_EN": "Al-Atf Al-Akhal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10945",
    "CITYNAME_AR": "ذا الحمره",
    "CITYNAME_EN": "Za Al Hamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10946",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Al Qufailah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10947",
    "CITYNAME_AR": "بين البلاق",
    "CITYNAME_EN": "Bayn Al-Belaq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10948",
    "CITYNAME_AR": "المشاره والطرف",
    "CITYNAME_EN": "Al-Masharah and Al-Tarf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10949",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Al Sarw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10950",
    "CITYNAME_AR": "ثلوث ريم",
    "CITYNAME_EN": "Thaluth Rim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10951",
    "CITYNAME_AR": "وادى شوحطه",
    "CITYNAME_EN": "Wadi Shuhtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10952",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10953",
    "CITYNAME_AR": "العفره",
    "CITYNAME_EN": "Al-Afarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10954",
    "CITYNAME_AR": "المحرس",
    "CITYNAME_EN": "Al-Mahras ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10955",
    "CITYNAME_AR": "ال كعشه",
    "CITYNAME_EN": "Al-Kash'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10956",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10957",
    "CITYNAME_AR": "الرهاء",
    "CITYNAME_EN": "Al-Rahaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10958",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10959",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Al Qahbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10960",
    "CITYNAME_AR": "الردفاء",
    "CITYNAME_EN": "Al Radfaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10961",
    "CITYNAME_AR": "الخلصه",
    "CITYNAME_EN": "Al-Khalsah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10962",
    "CITYNAME_AR": "المجنب",
    "CITYNAME_EN": "Al Majnab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10963",
    "CITYNAME_AR": "زعبان",
    "CITYNAME_EN": "Za'ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10964",
    "CITYNAME_AR": "المضروبه",
    "CITYNAME_EN": "Al-Madroubah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10965",
    "CITYNAME_AR": "الولج",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10966",
    "CITYNAME_AR": "القفيله",
    "CITYNAME_EN": "Al Qufailah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10967",
    "CITYNAME_AR": "وادى الصداره",
    "CITYNAME_EN": "Wadi As Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10968",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10969",
    "CITYNAME_AR": "الحجاب",
    "CITYNAME_EN": "Al-Hejab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10970",
    "CITYNAME_AR": "البلقه",
    "CITYNAME_EN": "Al-Balqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10971",
    "CITYNAME_AR": "الخدعاء",
    "CITYNAME_EN": "Al-Khad'aa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10972",
    "CITYNAME_AR": "لحج",
    "CITYNAME_EN": "Jahj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10973",
    "CITYNAME_AR": "سليح",
    "CITYNAME_EN": "Salih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10974",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10975",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10976",
    "CITYNAME_AR": "السلعاء",
    "CITYNAME_EN": "Al-Sal'aa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10977",
    "CITYNAME_AR": "الشبحه",
    "CITYNAME_EN": "Ash Shabha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10978",
    "CITYNAME_AR": "قريه ال عيسى",
    "CITYNAME_EN": "Al Eisa Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10979",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10980",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Al-Zahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10981",
    "CITYNAME_AR": "وادى عكنه",
    "CITYNAME_EN": "Wadi Aknah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10982",
    "CITYNAME_AR": "المزغرف",
    "CITYNAME_EN": "Al-Mazgharaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10983",
    "CITYNAME_AR": "الاريم",
    "CITYNAME_EN": "Al-Arim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10984",
    "CITYNAME_AR": "الموتد",
    "CITYNAME_EN": "Al-Mutid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10985",
    "CITYNAME_AR": "القعود",
    "CITYNAME_EN": "Al-Qa'ud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10986",
    "CITYNAME_AR": "الدواخل",
    "CITYNAME_EN": "Al-Dwakhil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10987",
    "CITYNAME_AR": "الصيق",
    "CITYNAME_EN": "Al-Siq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10988",
    "CITYNAME_AR": "العطف ومندريهر",
    "CITYNAME_EN": "Al-Atf and Manderbihr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10989",
    "CITYNAME_AR": "وادى ضلاع",
    "CITYNAME_EN": "Wadi Dela'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10990",
    "CITYNAME_AR": "الظفير",
    "CITYNAME_EN": "Al-Zafir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10991",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al-Jaz'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10992",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Al-Gellah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10993",
    "CITYNAME_AR": "معرقه",
    "CITYNAME_EN": "Ma'raqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10994",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10995",
    "CITYNAME_AR": "علاق",
    "CITYNAME_EN": "Elaq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10996",
    "CITYNAME_AR": "المخصل",
    "CITYNAME_EN": "Al-Makhsal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10997",
    "CITYNAME_AR": "الكوحه",
    "CITYNAME_EN": "Al-Kuhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10998",
    "CITYNAME_AR": "المزغرف",
    "CITYNAME_EN": "Al-Mazgharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10999",
    "CITYNAME_AR": "وادي ظهياء",
    "CITYNAME_EN": "Wadi Zahbaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11000",
    "CITYNAME_AR": "عسقلان",
    "CITYNAME_EN": "Asqalan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11001",
    "CITYNAME_AR": "فلل الهندي",
    "CITYNAME_EN": "Foll Al-Hendy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11002",
    "CITYNAME_AR": "ذالنبعه",
    "CITYNAME_EN": "Zalnab'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11003",
    "CITYNAME_AR": "لصوب",
    "CITYNAME_EN": "Lasub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11004",
    "CITYNAME_AR": "اسفل ريم (شعب الضامي )",
    "CITYNAME_EN": "Asfal Rim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11005",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11006",
    "CITYNAME_AR": "وطن الفجر (الحقو)",
    "CITYNAME_EN": "Watan Al-Fajr (Al-Haqw)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11007",
    "CITYNAME_AR": "باحان",
    "CITYNAME_EN": "Bahan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11008",
    "CITYNAME_AR": "ذنب القرار",
    "CITYNAME_EN": "Zanb Al Qarar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11009",
    "CITYNAME_AR": "ظاهر الحريقه",
    "CITYNAME_EN": "Zaher Al Hariqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11010",
    "CITYNAME_AR": "ذا الثوع",
    "CITYNAME_EN": "Za Al-Thaw' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11011",
    "CITYNAME_AR": "عكيه وظاهر حمده وصل",
    "CITYNAME_EN": "Akiyah, Zaher Hamdah Wasl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11012",
    "CITYNAME_AR": "ظموو",
    "CITYNAME_EN": "Zamu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11013",
    "CITYNAME_AR": "غزوان وعمامو",
    "CITYNAME_EN": "Ghazwan, and Amamu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11014",
    "CITYNAME_AR": "السهول واللصائب",
    "CITYNAME_EN": "Al Suhul and AllSaem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11015",
    "CITYNAME_AR": "هليو",
    "CITYNAME_EN": "Helyo ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11016",
    "CITYNAME_AR": "ردهو",
    "CITYNAME_EN": "Radhu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11017",
    "CITYNAME_AR": "الشرحاء",
    "CITYNAME_EN": "Al Sharhaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11018",
    "CITYNAME_AR": "السعيره",
    "CITYNAME_EN": "As Saera",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11019",
    "CITYNAME_AR": "معرج السوبان",
    "CITYNAME_EN": "Marj Allsoban",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11020",
    "CITYNAME_AR": "خبيراء",
    "CITYNAME_EN": "Khobaira",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11021",
    "CITYNAME_AR": "هجرة ابن مهيلب (ام النثايل)",
    "CITYNAME_EN": "Hijrah Ibn Mohailib (Umm Al-Nathaiel)",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11022",
    "CITYNAME_AR": "المتياهه الجنوبيه",
    "CITYNAME_EN": "Al Metyahat Al Janoubiyah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11023",
    "CITYNAME_AR": "الصداوى",
    "CITYNAME_EN": "As Sadawi",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11024",
    "CITYNAME_AR": "ام كداد",
    "CITYNAME_EN": "Um Kedad",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11025",
    "CITYNAME_AR": "مناخ",
    "CITYNAME_EN": "Manakh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11026",
    "CITYNAME_AR": "الحيراء",
    "CITYNAME_EN": "Al Hairaa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11027",
    "CITYNAME_AR": "القيصومه",
    "CITYNAME_EN": "Al Qaisumah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11028",
    "CITYNAME_AR": "ام قليب",
    "CITYNAME_EN": "Umm Qulayb",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11029",
    "CITYNAME_AR": "أم عشر الغربية",
    "CITYNAME_EN": "Umm Oshr (the Western) ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11031",
    "CITYNAME_AR": "الذيبيه",
    "CITYNAME_EN": "Thebea",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11032",
    "CITYNAME_AR": "ساموده",
    "CITYNAME_EN": "Samoudah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11034",
    "CITYNAME_AR": "ام عشر الشرقيه",
    "CITYNAME_EN": "UmmOshr (the Eastern) ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11035",
    "CITYNAME_AR": "الفاو الشمالى",
    "CITYNAME_EN": "Al Faw Ash Shamli",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11036",
    "CITYNAME_AR": "الرفيعه ",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11044",
    "CITYNAME_AR": "البويبيات",
    "CITYNAME_EN": "Al Buwaybat",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11045",
    "CITYNAME_AR": "الخبر",
    "CITYNAME_EN": "Khobar",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11048",
    "CITYNAME_AR": "الدمام",
    "CITYNAME_EN": "Aldammam",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11056",
    "CITYNAME_AR": "بقيق",
    "CITYNAME_EN": "Abqaiq",
    "REGION_ID": "5"
   },  
   {
    "CITY_ID": "11073",
    "CITYNAME_AR": "النعيريه",
    "CITYNAME_EN": "Nariya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11074",
    "CITYNAME_AR": "انقير",
    "CITYNAME_EN": "Anqir ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11075",
    "CITYNAME_AR": "السلمانيه",
    "CITYNAME_EN": "Salmaniya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11076",
    "CITYNAME_AR": "النقيره",
    "CITYNAME_EN": "Al Naqirah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11077",
    "CITYNAME_AR": "رافع وشعب مزاح",
    "CITYNAME_EN": "Rafi' and Shi'b Mezah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11078",
    "CITYNAME_AR": "الحشف وشعب معين",
    "CITYNAME_EN": "Al-Hashf and Shi'b Ma'in ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11079",
    "CITYNAME_AR": "وادى تضبي",
    "CITYNAME_EN": "Wadi Tadba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11080",
    "CITYNAME_AR": "دوفع والملصة",
    "CITYNAME_EN": "Dofi' and Al-Malsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11081",
    "CITYNAME_AR": "الصريب",
    "CITYNAME_EN": "Al-Sarib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11082",
    "CITYNAME_AR": "ابن سبيع وقرن العيد",
    "CITYNAME_EN": "Ibn Sabi' and Qarn Al-Eid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11083",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11084",
    "CITYNAME_AR": "قرن الرخم والغدير",
    "CITYNAME_EN": "Qarn Al-Rakhm and Al-Ghadir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11085",
    "CITYNAME_AR": "كدحه ابو ضبر",
    "CITYNAME_EN": "Kadhah Abu Dabr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11086",
    "CITYNAME_AR": "لوح شمت",
    "CITYNAME_EN": "Luh Shamt ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11087",
    "CITYNAME_AR": "البهيراء",
    "CITYNAME_EN": "Al-Bohayraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11088",
    "CITYNAME_AR": "لوح النخله (الشقحاء والرعن)",
    "CITYNAME_EN": "Luh Al-Nakhlah (Al-Shaqhaa and Al-Ra'n)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11089",
    "CITYNAME_AR": "زبنه",
    "CITYNAME_EN": "Zinah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11090",
    "CITYNAME_AR": "نزله المطروح (الزمف)",
    "CITYNAME_EN": "Nazlah Al-Matrouh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11091",
    "CITYNAME_AR": "الردف (الرديف)",
    "CITYNAME_EN": "Al-Radf (Al-Radif)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11092",
    "CITYNAME_AR": "الحنجور والمجدره",
    "CITYNAME_EN": "Al-Hanjur and Al-Mejdarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11093",
    "CITYNAME_AR": "بحر ابوسكينه (خميس البحر)",
    "CITYNAME_EN": "Bahr Abu Sukaynah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11094",
    "CITYNAME_AR": "الشرى",
    "CITYNAME_EN": "Al-Shary",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11095",
    "CITYNAME_AR": "الشرجه وسليله",
    "CITYNAME_EN": "Al-Sharjah and Salilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11096",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11097",
    "CITYNAME_AR": "مكيده",
    "CITYNAME_EN": "Makidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11098",
    "CITYNAME_AR": "بن سحمان",
    "CITYNAME_EN": "Bin Shman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11099",
    "CITYNAME_AR": "مربكه",
    "CITYNAME_EN": "Merbakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11100",
    "CITYNAME_AR": "قرن ابومرايه (شفا)",
    "CITYNAME_EN": "Qarn Abu merayah (Shafa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11101",
    "CITYNAME_AR": "الرفده والحازم",
    "CITYNAME_EN": "Al-Rafdah and Al-hazim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11102",
    "CITYNAME_AR": "الخازم",
    "CITYNAME_EN": "Al-Khazim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11103",
    "CITYNAME_AR": "مشيبه",
    "CITYNAME_EN": "Mashibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11104",
    "CITYNAME_AR": "المهيد",
    "CITYNAME_EN": "Al Mahid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11105",
    "CITYNAME_AR": "الحجنة",
    "CITYNAME_EN": "Al-Hajnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11106",
    "CITYNAME_AR": "المغفل",
    "CITYNAME_EN": "Al-Maghfel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11107",
    "CITYNAME_AR": "العلكه",
    "CITYNAME_EN": "Al-Alkah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11108",
    "CITYNAME_AR": "صوله",
    "CITYNAME_EN": "Sola",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11109",
    "CITYNAME_AR": "بن فضيلة",
    "CITYNAME_EN": "Bin Fadilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11110",
    "CITYNAME_AR": "راع القاع",
    "CITYNAME_EN": "Ra' Al-Qa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11111",
    "CITYNAME_AR": "مراتخ",
    "CITYNAME_EN": "Maratikh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11112",
    "CITYNAME_AR": "العصره",
    "CITYNAME_EN": "Al Asrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11113",
    "CITYNAME_AR": "اليبس",
    "CITYNAME_EN": "Al-Yebs ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11114",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Al-Jarf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11115",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11116",
    "CITYNAME_AR": "قرن الشحم",
    "CITYNAME_EN": "Qarn Al-Shahm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11117",
    "CITYNAME_AR": "الخزمه",
    "CITYNAME_EN": "Al-Khazmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11118",
    "CITYNAME_AR": "الدار",
    "CITYNAME_EN": "Al-Dar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11119",
    "CITYNAME_AR": "القزعة",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11120",
    "CITYNAME_AR": "الراكبه",
    "CITYNAME_EN": "Al Rakibah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11121",
    "CITYNAME_AR": "دعرم",
    "CITYNAME_EN": "Do'rom ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11122",
    "CITYNAME_AR": "الحفير",
    "CITYNAME_EN": "Al Hufayr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11123",
    "CITYNAME_AR": "الجوف ( قرن الثوير)",
    "CITYNAME_EN": "Al-Jawf (Qarn Al-Thuer)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11124",
    "CITYNAME_AR": "المروة",
    "CITYNAME_EN": "Al-Marwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11125",
    "CITYNAME_AR": "المضروبة",
    "CITYNAME_EN": "Al-Madroubah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11126",
    "CITYNAME_AR": "قرن مصياد",
    "CITYNAME_EN": "Qarn mesyad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11127",
    "CITYNAME_AR": "الفلقا",
    "CITYNAME_EN": "Al-Falqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11128",
    "CITYNAME_AR": "البياض",
    "CITYNAME_EN": "Al-Byad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11129",
    "CITYNAME_AR": "الغاربه",
    "CITYNAME_EN": "Al-Gharbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11130",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Al-Sadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11131",
    "CITYNAME_AR": "ام جنيدله وشعب غزال",
    "CITYNAME_EN": "Umm Junaydelah and Shi'b Ghazal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11132",
    "CITYNAME_AR": "ام طارفه",
    "CITYNAME_EN": "Umm tarfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11133",
    "CITYNAME_AR": "قرقر",
    "CITYNAME_EN": "Qarqar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11134",
    "CITYNAME_AR": "المخلطات",
    "CITYNAME_EN": "Al-Mokhlatat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11135",
    "CITYNAME_AR": "سعود",
    "CITYNAME_EN": "Saud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11136",
    "CITYNAME_AR": "الهيجه",
    "CITYNAME_EN": "Al Heejah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11137",
    "CITYNAME_AR": "وادي عدنان",
    "CITYNAME_EN": "Wadi Adnan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11138",
    "CITYNAME_AR": "وادي مقلم",
    "CITYNAME_EN": "Wadi Meqlim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11139",
    "CITYNAME_AR": "القرونه",
    "CITYNAME_EN": "Al-Qarunah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11140",
    "CITYNAME_AR": "الصلقه",
    "CITYNAME_EN": "Al-Salqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11141",
    "CITYNAME_AR": "وجه ام حضن",
    "CITYNAME_EN": "Wajh Umm Hodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11142",
    "CITYNAME_AR": "الضبيب",
    "CITYNAME_EN": "Al-Dabib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11143",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11144",
    "CITYNAME_AR": "الجبهه",
    "CITYNAME_EN": "Al-Jabhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11145",
    "CITYNAME_AR": "مجايره",
    "CITYNAME_EN": "Mojayrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11146",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqu",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11147",
    "CITYNAME_AR": "الضحيه",
    "CITYNAME_EN": "Al-Dahiyah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11148",
    "CITYNAME_AR": "ام جرد",
    "CITYNAME_EN": "Umm Jerd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11149",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Al-Mo'tarid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11150",
    "CITYNAME_AR": "ام قيصره",
    "CITYNAME_EN": "Umm Qaysarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11151",
    "CITYNAME_AR": "النقره",
    "CITYNAME_EN": "Al Neqrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11152",
    "CITYNAME_AR": "الملاح",
    "CITYNAME_EN": "Al-Melah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11153",
    "CITYNAME_AR": "السند",
    "CITYNAME_EN": "Al-Sanad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11154",
    "CITYNAME_AR": "الفروش",
    "CITYNAME_EN": "Al-Farush ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11155",
    "CITYNAME_AR": "حيسن",
    "CITYNAME_EN": "Haysin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11156",
    "CITYNAME_AR": "مظاليم وشرمه",
    "CITYNAME_EN": "Mazalim and Sharmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11157",
    "CITYNAME_AR": "العرض ومشراف",
    "CITYNAME_EN": "Al-Ard and Meshraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11158",
    "CITYNAME_AR": "الحره",
    "CITYNAME_EN": "Al-Herah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11159",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11160",
    "CITYNAME_AR": "حره مقطوف",
    "CITYNAME_EN": "Herrah Maqtuf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11161",
    "CITYNAME_AR": "الدبيشه",
    "CITYNAME_EN": "Al Dabdabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11162",
    "CITYNAME_AR": "حواش",
    "CITYNAME_EN": "Hawash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11163",
    "CITYNAME_AR": "حره ام جدراء",
    "CITYNAME_EN": "Herrah Umm Jedraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11164",
    "CITYNAME_AR": "المقوع",
    "CITYNAME_EN": "Al-Maqu'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11165",
    "CITYNAME_AR": "معصم",
    "CITYNAME_EN": "Me'sam",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11166",
    "CITYNAME_AR": "الحشان والسحر",
    "CITYNAME_EN": "Al-Hashan and Al-Sehr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11167",
    "CITYNAME_AR": "الحصحص",
    "CITYNAME_EN": "Al-Hashas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11168",
    "CITYNAME_AR": "مجايره",
    "CITYNAME_EN": "Mojayrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11169",
    "CITYNAME_AR": "الشروفه",
    "CITYNAME_EN": "Al-Sharufah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11170",
    "CITYNAME_AR": "الخطام",
    "CITYNAME_EN": "Al-Khetam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11171",
    "CITYNAME_AR": "مذناب وحبيل ام عقده",
    "CITYNAME_EN": "Meznab and Habil Umm Okdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11172",
    "CITYNAME_AR": "قفعه",
    "CITYNAME_EN": "Qaf'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11173",
    "CITYNAME_AR": "مكده",
    "CITYNAME_EN": "mekdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11174",
    "CITYNAME_AR": "الذيل",
    "CITYNAME_EN": "Al-Zayl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11175",
    "CITYNAME_AR": "المديد",
    "CITYNAME_EN": "Al-Madid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11176",
    "CITYNAME_AR": "العريش",
    "CITYNAME_EN": "Al-Arish ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11177",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11178",
    "CITYNAME_AR": "الحابس وظلال",
    "CITYNAME_EN": "Al-Habis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11179",
    "CITYNAME_AR": "العصره والمعترضه",
    "CITYNAME_EN": "Al-Asrah and Al-Mo'taridah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11180",
    "CITYNAME_AR": "النقواء",
    "CITYNAME_EN": "Al-Naqwaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11181",
    "CITYNAME_AR": "المغبر",
    "CITYNAME_EN": "Al-Maghber",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11182",
    "CITYNAME_AR": "سوق الليل",
    "CITYNAME_EN": "Souq Allayl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11183",
    "CITYNAME_AR": "وتران",
    "CITYNAME_EN": "Watran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11184",
    "CITYNAME_AR": "الطفه والحجر",
    "CITYNAME_EN": "Al-tfah and Al-Hejr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11185",
    "CITYNAME_AR": "العلايا",
    "CITYNAME_EN": "Al-Alaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11186",
    "CITYNAME_AR": "عسلان",
    "CITYNAME_EN": "Aslan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11187",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Al-Matan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11188",
    "CITYNAME_AR": "ملاح",
    "CITYNAME_EN": "Mallah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11189",
    "CITYNAME_AR": "الطرقه",
    "CITYNAME_EN": "Al Tarqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11190",
    "CITYNAME_AR": "ام فل",
    "CITYNAME_EN": "Umm Fol ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11191",
    "CITYNAME_AR": "المسعاء",
    "CITYNAME_EN": "Al-Mas'aa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11192",
    "CITYNAME_AR": "العلايا",
    "CITYNAME_EN": "Al-Alaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11193",
    "CITYNAME_AR": "الصداره",
    "CITYNAME_EN": "Al-Sadarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11194",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11195",
    "CITYNAME_AR": "الحرايس",
    "CITYNAME_EN": "Al-Harayis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11196",
    "CITYNAME_AR": "اللصب",
    "CITYNAME_EN": "Allasub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11197",
    "CITYNAME_AR": "الشطور",
    "CITYNAME_EN": "Al-Shatur",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11198",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11199",
    "CITYNAME_AR": "الشعب الادهم",
    "CITYNAME_EN": "Al-Shi'b Al-Adham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11200",
    "CITYNAME_AR": "سادح",
    "CITYNAME_EN": "Sadih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11201",
    "CITYNAME_AR": "حيله المنجحه",
    "CITYNAME_EN": "Hilah Al-Manjahah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11202",
    "CITYNAME_AR": "الحلفاء",
    "CITYNAME_EN": "Al-Halfaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11203",
    "CITYNAME_AR": "الصداره (ولد اسلم)",
    "CITYNAME_EN": "Al-Sadarah (Weld Aslam)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11204",
    "CITYNAME_AR": "المخنق",
    "CITYNAME_EN": "Al-Makhnaq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11205",
    "CITYNAME_AR": "المدراء",
    "CITYNAME_EN": "Managers",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11206",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11207",
    "CITYNAME_AR": "المرفد",
    "CITYNAME_EN": "Al-Marfed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11208",
    "CITYNAME_AR": "خماش",
    "CITYNAME_EN": "Khamash",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11209",
    "CITYNAME_AR": "المصدومه",
    "CITYNAME_EN": "Al-Masdumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11210",
    "CITYNAME_AR": "العجبر",
    "CITYNAME_EN": "Al-Ajbar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11211",
    "CITYNAME_AR": "الشهبى",
    "CITYNAME_EN": "Al-Shahby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11212",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Al Fasilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11213",
    "CITYNAME_AR": "منصاب",
    "CITYNAME_EN": "Mensab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11214",
    "CITYNAME_AR": "الحصن (المصدوم)",
    "CITYNAME_EN": "Al-Hisn (Al-Masdum)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11215",
    "CITYNAME_AR": "مكيله (مكدره)",
    "CITYNAME_EN": "makilah (Makdrah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11216",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11217",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Al Attf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11218",
    "CITYNAME_AR": "الفشقاء",
    "CITYNAME_EN": "Al-Fashqaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11219",
    "CITYNAME_AR": "الخلصة",
    "CITYNAME_EN": "Al-Khalsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11220",
    "CITYNAME_AR": "علايه الخليف",
    "CITYNAME_EN": "Alayah Al-Khalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11221",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11222",
    "CITYNAME_AR": "حرجه البمه",
    "CITYNAME_EN": "Harjah Al-Bimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11223",
    "CITYNAME_AR": "كنب 61 (قرية)",
    "CITYNAME_EN": "kanb 61 (Village)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11224",
    "CITYNAME_AR": "المبحار",
    "CITYNAME_EN": "Al-Mebhar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11225",
    "CITYNAME_AR": "الصلالة",
    "CITYNAME_EN": "Al-Salalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11226",
    "CITYNAME_AR": "الجريف",
    "CITYNAME_EN": "Al-Jarif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11227",
    "CITYNAME_AR": "منفة",
    "CITYNAME_EN": "Manfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11228",
    "CITYNAME_AR": "الحنبة",
    "CITYNAME_EN": "Al-Hanbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11229",
    "CITYNAME_AR": "السنبوك",
    "CITYNAME_EN": "Al-Sanbuk",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11230",
    "CITYNAME_AR": "سيرن",
    "CITYNAME_EN": "Sern ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11231",
    "CITYNAME_AR": "السنايد",
    "CITYNAME_EN": "Al-Sanayid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11232",
    "CITYNAME_AR": "اللصائب",
    "CITYNAME_EN": "Allasaeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11233",
    "CITYNAME_AR": "ام عقصاء",
    "CITYNAME_EN": "Umm Eqas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11234",
    "CITYNAME_AR": "جندب",
    "CITYNAME_EN": "Jundob ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11235",
    "CITYNAME_AR": "الخرشه (الجندله)",
    "CITYNAME_EN": "Al-Kharshah (Al-Jandlah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11236",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11237",
    "CITYNAME_AR": "ملص والغرف",
    "CITYNAME_EN": "Mals and Al-Gharf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11238",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11239",
    "CITYNAME_AR": "الرهوه (الحصن)",
    "CITYNAME_EN": "Al-Rahwah (Al-Hisn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11240",
    "CITYNAME_AR": "القصده",
    "CITYNAME_EN": "Al Qasdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11241",
    "CITYNAME_AR": "ام حجفه",
    "CITYNAME_EN": "Umm Hajfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11242",
    "CITYNAME_AR": "وجه ام قمعه",
    "CITYNAME_EN": "Wajh Umm Qam'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11243",
    "CITYNAME_AR": "المروة",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11244",
    "CITYNAME_AR": "وادى المعقر",
    "CITYNAME_EN": "Wadi Al-Meq'ar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11245",
    "CITYNAME_AR": "ال النوتى",
    "CITYNAME_EN": "Al Al-Noty ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11246",
    "CITYNAME_AR": "القويعه",
    "CITYNAME_EN": "Al-Qowi'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11247",
    "CITYNAME_AR": "الخراره",
    "CITYNAME_EN": "Al-Khararah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11248",
    "CITYNAME_AR": "البياض",
    "CITYNAME_EN": "Al-Byad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11249",
    "CITYNAME_AR": "مقعاد",
    "CITYNAME_EN": "Meq'ad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11250",
    "CITYNAME_AR": "العلايا",
    "CITYNAME_EN": "Al-Alaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11251",
    "CITYNAME_AR": "حبيل الخطام",
    "CITYNAME_EN": "Habil Al-Khetam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11252",
    "CITYNAME_AR": "وادي الجبلين",
    "CITYNAME_EN": "Wadi Al-Jablein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11253",
    "CITYNAME_AR": "وادى خيم",
    "CITYNAME_EN": "Wadi Kheim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11254",
    "CITYNAME_AR": "مجوخات",
    "CITYNAME_EN": "Mujawakhat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11255",
    "CITYNAME_AR": "قرن الرخم",
    "CITYNAME_EN": "Qarn Alrakhum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11256",
    "CITYNAME_AR": "ال مسهر",
    "CITYNAME_EN": "Al Musahar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11257",
    "CITYNAME_AR": "ثعب",
    "CITYNAME_EN": "Thaeb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11258",
    "CITYNAME_AR": "الدهناء",
    "CITYNAME_EN": "Aldahna'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11259",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11260",
    "CITYNAME_AR": "شعبه العدن",
    "CITYNAME_EN": "Shaebih Aleadn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11261",
    "CITYNAME_AR": "الحميضه",
    "CITYNAME_EN": "Alhamiduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11262",
    "CITYNAME_AR": "حنيش",
    "CITYNAME_EN": "Hanish",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11263",
    "CITYNAME_AR": "مجندلة",
    "CITYNAME_EN": "Mujndila",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11264",
    "CITYNAME_AR": "مغنم",
    "CITYNAME_EN": "Maghnim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11265",
    "CITYNAME_AR": "ذريع الجب",
    "CITYNAME_EN": "Dharie Aljubi",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11266",
    "CITYNAME_AR": "المنقض",
    "CITYNAME_EN": "Almunaqad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11267",
    "CITYNAME_AR": "معلوله العليا",
    "CITYNAME_EN": "Melwlh Aleulya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11268",
    "CITYNAME_AR": "معلوله السفلى",
    "CITYNAME_EN": "Melwlh Alsuflaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11269",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Almuetarad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11270",
    "CITYNAME_AR": "اسفل السادح",
    "CITYNAME_EN": "'Asfal Alssadih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11271",
    "CITYNAME_AR": "الجندله",
    "CITYNAME_EN": "Aljinduluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11272",
    "CITYNAME_AR": "خبزة",
    "CITYNAME_EN": "Khabiza",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11273",
    "CITYNAME_AR": "ديرة اللحن (سرير)",
    "CITYNAME_EN": "Dirat Allahn (Sryr)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11274",
    "CITYNAME_AR": "راعي الـرديف",
    "CITYNAME_EN": "Raey Alrdyf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11275",
    "CITYNAME_AR": "اسفل الحميضه",
    "CITYNAME_EN": "'Asfal Alhamidah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11276",
    "CITYNAME_AR": "عرافط",
    "CITYNAME_EN": "Earafit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11277",
    "CITYNAME_AR": "المرفد",
    "CITYNAME_EN": "Almurafad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11278",
    "CITYNAME_AR": "شرفه برمهاه",
    "CITYNAME_EN": "Sharafah Birumhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11279",
    "CITYNAME_AR": "المعاين والضموم",
    "CITYNAME_EN": "Almaeayin Waldumum",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11280",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11281",
    "CITYNAME_AR": "قرى حرب والشجن",
    "CITYNAME_EN": "Harb and Al-Shajn Villages ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11282",
    "CITYNAME_AR": "الشعيبه والجوز",
    "CITYNAME_EN": "Al-Sha'ibah, and Al-Jozur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11283",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Al-Matan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11284",
    "CITYNAME_AR": "يتمه",
    "CITYNAME_EN": "Yatmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11285",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Al-Gellah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11286",
    "CITYNAME_AR": "المبيهم",
    "CITYNAME_EN": "Al-Mbihm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11287",
    "CITYNAME_AR": "الاعسر",
    "CITYNAME_EN": "Al-A'ser ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11288",
    "CITYNAME_AR": "الضاجع",
    "CITYNAME_EN": "Al-Daji' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11289",
    "CITYNAME_AR": "الفاهيه",
    "CITYNAME_EN": "Al-Fahiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11290",
    "CITYNAME_AR": "زهب هزام",
    "CITYNAME_EN": "Zahb Hazam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11291",
    "CITYNAME_AR": "الوصيل والمضارب",
    "CITYNAME_EN": "Al-Wasil, and Al-Modarib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11292",
    "CITYNAME_AR": "محطوط",
    "CITYNAME_EN": "Mahtut ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11293",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Al-Ma'in ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11294",
    "CITYNAME_AR": "حماك",
    "CITYNAME_EN": "Hamak ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11295",
    "CITYNAME_AR": "برده",
    "CITYNAME_EN": "Bordah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11296",
    "CITYNAME_AR": "رقده",
    "CITYNAME_EN": "Roqdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11297",
    "CITYNAME_AR": "مجلى والمونوف وضلعه",
    "CITYNAME_EN": "Majla, Al-Mawnuf, and Dal'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11298",
    "CITYNAME_AR": "البنيان",
    "CITYNAME_EN": "Al-Benyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11299",
    "CITYNAME_AR": "غوقه",
    "CITYNAME_EN": "Ghuqh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11300",
    "CITYNAME_AR": "الطوارف",
    "CITYNAME_EN": "Altawarif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11301",
    "CITYNAME_AR": "الردان",
    "CITYNAME_EN": "Alradaan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11302",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Alkhalfuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11303",
    "CITYNAME_AR": "القصيم",
    "CITYNAME_EN": "Alqasim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11304",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11305",
    "CITYNAME_AR": "المفازه",
    "CITYNAME_EN": "Almufazuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11306",
    "CITYNAME_AR": "مزمه",
    "CITYNAME_EN": "Muzmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11307",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11308",
    "CITYNAME_AR": "محائل",
    "CITYNAME_EN": "Muhayil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11309",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11310",
    "CITYNAME_AR": "البرزه",
    "CITYNAME_EN": "Barazha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11311",
    "CITYNAME_AR": "العريض",
    "CITYNAME_EN": "Al-Arid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11312",
    "CITYNAME_AR": "المقلى",
    "CITYNAME_EN": "Al-Maqla ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11313",
    "CITYNAME_AR": "الصمان",
    "CITYNAME_EN": "Al-Saman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11314",
    "CITYNAME_AR": "المنقظه",
    "CITYNAME_EN": "Al-Manqzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11315",
    "CITYNAME_AR": "رهوه مسل",
    "CITYNAME_EN": "Rahwah Masl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11316",
    "CITYNAME_AR": "المحصمه",
    "CITYNAME_EN": "Al-Mahmasah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11317",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11318",
    "CITYNAME_AR": "الناظر",
    "CITYNAME_EN": "Al-Nazer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11319",
    "CITYNAME_AR": "العلامه",
    "CITYNAME_EN": "Al-Alamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11320",
    "CITYNAME_AR": "شعب جديع",
    "CITYNAME_EN": "Shi'b Jadi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11321",
    "CITYNAME_AR": "ال عظاهن",
    "CITYNAME_EN": "Al Azahin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11322",
    "CITYNAME_AR": "قرن ال مكتوب",
    "CITYNAME_EN": "Qarn Al Maktub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11323",
    "CITYNAME_AR": "الواصلى",
    "CITYNAME_EN": "Al-Wasly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11324",
    "CITYNAME_AR": "الميمنه",
    "CITYNAME_EN": "Al-Maymnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11325",
    "CITYNAME_AR": "الخواف",
    "CITYNAME_EN": "Al-Khwaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11326",
    "CITYNAME_AR": "الموبره",
    "CITYNAME_EN": "Al-Mowirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11327",
    "CITYNAME_AR": "حصن الخياله",
    "CITYNAME_EN": "Hisn Al-Khayalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11328",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As Sirr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11329",
    "CITYNAME_AR": "غدير الشيخ",
    "CITYNAME_EN": "Ghadir Al-Sheikh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11330",
    "CITYNAME_AR": "ال صعيب",
    "CITYNAME_EN": "Al-Sa'ib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11331",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11332",
    "CITYNAME_AR": "المحيا",
    "CITYNAME_EN": "Al-Mahya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11333",
    "CITYNAME_AR": "الرفصه",
    "CITYNAME_EN": "Al-Rafsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11334",
    "CITYNAME_AR": "ظهره المسيب",
    "CITYNAME_EN": "Zohrah Al-Masib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11335",
    "CITYNAME_AR": "عرش الحضن",
    "CITYNAME_EN": "Arsh Al-Hodn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11336",
    "CITYNAME_AR": "المرباح",
    "CITYNAME_EN": "Al-Merbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11337",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "Al-Sud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11338",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11339",
    "CITYNAME_AR": "الطرف الجنوبى",
    "CITYNAME_EN": "Al Taraf (the southern)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11340",
    "CITYNAME_AR": "ظهره الملاص",
    "CITYNAME_EN": "Zahrah Al-Melas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11341",
    "CITYNAME_AR": "ظهره المكدر",
    "CITYNAME_EN": "Zahrah Al-Mekder ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11342",
    "CITYNAME_AR": "ظهره فنان",
    "CITYNAME_EN": "Zahrah fanan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11343",
    "CITYNAME_AR": "القصبه",
    "CITYNAME_EN": "Al Qasba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11344",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Al-Hajib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11345",
    "CITYNAME_AR": "العرقو",
    "CITYNAME_EN": "Al-Arqu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11346",
    "CITYNAME_AR": "الحقوين",
    "CITYNAME_EN": "Al Haqwin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11347",
    "CITYNAME_AR": "القناة",
    "CITYNAME_EN": "Al-Qanah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11348",
    "CITYNAME_AR": "الرجفه",
    "CITYNAME_EN": "Al-Rajfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11349",
    "CITYNAME_AR": "شعب بن معجم",
    "CITYNAME_EN": "She'b bin Me'jim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11350",
    "CITYNAME_AR": "ضرك",
    "CITYNAME_EN": "Dark ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11351",
    "CITYNAME_AR": "حقو المعش",
    "CITYNAME_EN": "Haqw Al-Ma'sh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11352",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'bah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11353",
    "CITYNAME_AR": "المسلم",
    "CITYNAME_EN": "Al-Moslim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11354",
    "CITYNAME_AR": "القايم",
    "CITYNAME_EN": "Al-Qayim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11355",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Al-Reqbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11356",
    "CITYNAME_AR": "الشعثاء",
    "CITYNAME_EN": "Al-Sha'thaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11357",
    "CITYNAME_AR": "حصن الزهاريه",
    "CITYNAME_EN": "Hesn Al-Zaharyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11358",
    "CITYNAME_AR": "ظهره الخوينق",
    "CITYNAME_EN": "Zohrah Al-Khuwayniq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11359",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11360",
    "CITYNAME_AR": "قرن الماء",
    "CITYNAME_EN": "Qern Al-Maa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11361",
    "CITYNAME_AR": "العجله",
    "CITYNAME_EN": "Al-Ajlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11362",
    "CITYNAME_AR": "الجدوف",
    "CITYNAME_EN": "Al Jadouf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11363",
    "CITYNAME_AR": "الاشراف",
    "CITYNAME_EN": "Al-Ashraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11364",
    "CITYNAME_AR": "حبيل الامير",
    "CITYNAME_EN": "Habil Al-Amir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11365",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11366",
    "CITYNAME_AR": "المقحومه",
    "CITYNAME_EN": "Al-Maqhumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11367",
    "CITYNAME_AR": "الكدس",
    "CITYNAME_EN": "Al-Keds",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11368",
    "CITYNAME_AR": "الحفه",
    "CITYNAME_EN": "Al-Huffah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11369",
    "CITYNAME_AR": "ريامه",
    "CITYNAME_EN": "Riyamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11370",
    "CITYNAME_AR": "ظهره شداد",
    "CITYNAME_EN": "Zohrah Shedad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11371",
    "CITYNAME_AR": "الفرش (الجرد)",
    "CITYNAME_EN": "Al-farsh (Al-Jerd)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11372",
    "CITYNAME_AR": "معفل",
    "CITYNAME_EN": "Ma'fal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11373",
    "CITYNAME_AR": "الرادي",
    "CITYNAME_EN": "Al-Rady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11374",
    "CITYNAME_AR": "الحدايا",
    "CITYNAME_EN": "Al Hadaya",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11375",
    "CITYNAME_AR": "شعبه الديك",
    "CITYNAME_EN": "She'bah Al-Dik ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11376",
    "CITYNAME_AR": "الوحله",
    "CITYNAME_EN": "Al-Wahlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11377",
    "CITYNAME_AR": "الحشان",
    "CITYNAME_EN": "Al-Hashan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11378",
    "CITYNAME_AR": "الروحاء العليا",
    "CITYNAME_EN": "Al-Rwhaa (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11379",
    "CITYNAME_AR": "مسنه",
    "CITYNAME_EN": "Masnah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11380",
    "CITYNAME_AR": "المرازه",
    "CITYNAME_EN": "Al-Merazah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11381",
    "CITYNAME_AR": "روحاء ال خليف",
    "CITYNAME_EN": "Ruhaa l Khalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11382",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11383",
    "CITYNAME_AR": "محله دومان",
    "CITYNAME_EN": "mahalet Doman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11384",
    "CITYNAME_AR": "سايل",
    "CITYNAME_EN": "Sayel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11385",
    "CITYNAME_AR": "قرن المشبه",
    "CITYNAME_EN": "Qarn Al-Maeshbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11386",
    "CITYNAME_AR": "المرابيع",
    "CITYNAME_EN": "Al-Marabih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11387",
    "CITYNAME_AR": "القاد الاعلى",
    "CITYNAME_EN": "Al-Qad (the upper)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11388",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al-Mesbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11389",
    "CITYNAME_AR": "الزرده",
    "CITYNAME_EN": "Al-Zardah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11390",
    "CITYNAME_AR": "ظهره ال عوشان",
    "CITYNAME_EN": "Zahrah Al Oshan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11391",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11392",
    "CITYNAME_AR": "ترقش",
    "CITYNAME_EN": "Tarqesh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11393",
    "CITYNAME_AR": "الموغر",
    "CITYNAME_EN": "Al-Mugher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11394",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11395",
    "CITYNAME_AR": "العشاش",
    "CITYNAME_EN": "Al Shash",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11396",
    "CITYNAME_AR": "المخرف",
    "CITYNAME_EN": "Al-Makhraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11397",
    "CITYNAME_AR": "الكيله",
    "CITYNAME_EN": "Al-Kilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11398",
    "CITYNAME_AR": "محتره",
    "CITYNAME_EN": "Mahtarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11399",
    "CITYNAME_AR": "قرن صيفر",
    "CITYNAME_EN": "Qarn Syfer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11400",
    "CITYNAME_AR": "الميفاء",
    "CITYNAME_EN": "Al-Mayfaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11401",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11402",
    "CITYNAME_AR": "الرداح",
    "CITYNAME_EN": "Al Radah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11403",
    "CITYNAME_AR": "الماطور",
    "CITYNAME_EN": "Al-Matur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11404",
    "CITYNAME_AR": "الكسور",
    "CITYNAME_EN": "Al-Kasur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11405",
    "CITYNAME_AR": "ابو شهر",
    "CITYNAME_EN": "Abu Shahr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11406",
    "CITYNAME_AR": "البرود",
    "CITYNAME_EN": "Al Burud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11407",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11408",
    "CITYNAME_AR": "المقنعه",
    "CITYNAME_EN": "Al-Meqn'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11409",
    "CITYNAME_AR": "مغوار",
    "CITYNAME_EN": "Meghwar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11410",
    "CITYNAME_AR": "الساده (الحساب)",
    "CITYNAME_EN": "Al-Sadah (Al-Hesab)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11411",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11412",
    "CITYNAME_AR": "الحشه",
    "CITYNAME_EN": "Al-Hashah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11413",
    "CITYNAME_AR": "الهدباء",
    "CITYNAME_EN": "Al-Hedbaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11414",
    "CITYNAME_AR": "خلفه القهبه",
    "CITYNAME_EN": "Khalfah Al-Qahbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11415",
    "CITYNAME_AR": "قذل الغرابه",
    "CITYNAME_EN": "Qazl Al-Gharabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11416",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11417",
    "CITYNAME_AR": "الدمنه",
    "CITYNAME_EN": "Al Demnah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11418",
    "CITYNAME_AR": "الوجيد",
    "CITYNAME_EN": "Al Wajeed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11419",
    "CITYNAME_AR": "ام تباعه",
    "CITYNAME_EN": "Umm teba'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11420",
    "CITYNAME_AR": "جرف الوعراء",
    "CITYNAME_EN": "Jarf Al-Wa'raa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11421",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Al-Khalfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11422",
    "CITYNAME_AR": "لجبين",
    "CITYNAME_EN": "lajbin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11423",
    "CITYNAME_AR": "حوض مشيط",
    "CITYNAME_EN": "Hawd Mashit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11424",
    "CITYNAME_AR": "رحبه الحقو",
    "CITYNAME_EN": "Rahbah Al-Haqw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11425",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11426",
    "CITYNAME_AR": "القرن الاسعر",
    "CITYNAME_EN": "Al-Qarn Al-As'er ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11427",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Al-Einah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11428",
    "CITYNAME_AR": "القرونه",
    "CITYNAME_EN": "Al-Qarunah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11429",
    "CITYNAME_AR": "المصول",
    "CITYNAME_EN": "Al-Masul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11430",
    "CITYNAME_AR": "المتلوى",
    "CITYNAME_EN": "Al-Matlawy",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11431",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11432",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11433",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11434",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Al-Rab'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11435",
    "CITYNAME_AR": "كدحه هوقه",
    "CITYNAME_EN": "kadha Huqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11436",
    "CITYNAME_AR": "الحرقوف",
    "CITYNAME_EN": "Al-Harquf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11437",
    "CITYNAME_AR": "المحراك",
    "CITYNAME_EN": "Al-Mehraq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11438",
    "CITYNAME_AR": "الضبر",
    "CITYNAME_EN": "Al-Dabr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11439",
    "CITYNAME_AR": "حضن الصدر",
    "CITYNAME_EN": "Hodn Al-Sadr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11440",
    "CITYNAME_AR": "ابن هيف",
    "CITYNAME_EN": "Ibn Hif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11441",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Al-Khalfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11442",
    "CITYNAME_AR": "الصعد",
    "CITYNAME_EN": "Al-Saad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11443",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Al-Khayal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11444",
    "CITYNAME_AR": "قنيفذ",
    "CITYNAME_EN": "Qunayfidhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11445",
    "CITYNAME_AR": "الفرعه السوداء",
    "CITYNAME_EN": "Al-far'ah Al-Sawdaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11446",
    "CITYNAME_AR": "المتحصمات",
    "CITYNAME_EN": "Al-Motahamisat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11447",
    "CITYNAME_AR": "الكدحه",
    "CITYNAME_EN": "Al-Kadhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11448",
    "CITYNAME_AR": "القتير",
    "CITYNAME_EN": "Al-Qatir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11449",
    "CITYNAME_AR": "كتف دارس",
    "CITYNAME_EN": "Ketf Daris ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11450",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Sawda",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11451",
    "CITYNAME_AR": "المشباح",
    "CITYNAME_EN": "Al-Meshbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11452",
    "CITYNAME_AR": "الشبقات",
    "CITYNAME_EN": "Al-Shabaqat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11453",
    "CITYNAME_AR": "لوح العصا",
    "CITYNAME_EN": "Luh Al-Asa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11454",
    "CITYNAME_AR": "الشبقة",
    "CITYNAME_EN": "Al-Shabqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11455",
    "CITYNAME_AR": "المغيلات",
    "CITYNAME_EN": "Al-Moghilat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11456",
    "CITYNAME_AR": "القصبه",
    "CITYNAME_EN": "Al Qasba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11457",
    "CITYNAME_AR": "السوداء",
    "CITYNAME_EN": "Al-Sawdaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11458",
    "CITYNAME_AR": "الخورم",
    "CITYNAME_EN": "Al-Khawrem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11459",
    "CITYNAME_AR": "النبعات",
    "CITYNAME_EN": "Al-Nabaat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11460",
    "CITYNAME_AR": "الميفاء",
    "CITYNAME_EN": "Al-Mayfaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11461",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al-Malhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11462",
    "CITYNAME_AR": "المابر",
    "CITYNAME_EN": "Al-Mabir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11463",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "Al-Sud",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11464",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Al-Rab' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11465",
    "CITYNAME_AR": "لرين",
    "CITYNAME_EN": "Larin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11466",
    "CITYNAME_AR": "الصوان",
    "CITYNAME_EN": "Al-Suwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11467",
    "CITYNAME_AR": "المغباره",
    "CITYNAME_EN": "Al-Meghbarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11468",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "Al-Selah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11469",
    "CITYNAME_AR": "الدغره",
    "CITYNAME_EN": "Al-Daghrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11470",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Qa'rah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11471",
    "CITYNAME_AR": "اوجيد",
    "CITYNAME_EN": "Awjid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11472",
    "CITYNAME_AR": "المقاعد السود",
    "CITYNAME_EN": "Al-Maqa'd Al-Sud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11473",
    "CITYNAME_AR": "المصياد",
    "CITYNAME_EN": "Al-Mesyad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11474",
    "CITYNAME_AR": "لهبان والشمل",
    "CITYNAME_EN": "Lahban and Al-Shaml ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11475",
    "CITYNAME_AR": "شعب صحاح",
    "CITYNAME_EN": "Shi'b Sihah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11476",
    "CITYNAME_AR": "خلصة",
    "CITYNAME_EN": "Khalsah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11477",
    "CITYNAME_AR": "شعب الماغص",
    "CITYNAME_EN": "Shi'b Al-Maghis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11478",
    "CITYNAME_AR": "الهزم",
    "CITYNAME_EN": "Al-Hazm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11479",
    "CITYNAME_AR": "شعب عقيده",
    "CITYNAME_EN": "Shi'b Aqidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11480",
    "CITYNAME_AR": "راعى ام سيال",
    "CITYNAME_EN": "Ra'y Umm Seyal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11481",
    "CITYNAME_AR": "قرن البطح",
    "CITYNAME_EN": "Qarn Al-Bateh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11482",
    "CITYNAME_AR": "قرن الصابح والولي",
    "CITYNAME_EN": "Qarn Al-Sabih and Al-Waly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11483",
    "CITYNAME_AR": "العقايل",
    "CITYNAME_EN": "Al-Aqayil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11484",
    "CITYNAME_AR": "شنقل",
    "CITYNAME_EN": "Shanqel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11485",
    "CITYNAME_AR": "المحجر",
    "CITYNAME_EN": "Al-Mehjar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11486",
    "CITYNAME_AR": "عذبه سيلان",
    "CITYNAME_EN": "Ezbah Silan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11487",
    "CITYNAME_AR": "القرونه (الختناء)",
    "CITYNAME_EN": "Al-Qarunah (Al-Khatnaa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11488",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11489",
    "CITYNAME_AR": "ظهره معديه والحرجه",
    "CITYNAME_EN": "Zahrah Ma'diyah and Al-Harjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11490",
    "CITYNAME_AR": "قرن صيدان",
    "CITYNAME_EN": "Qarn Sidan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11491",
    "CITYNAME_AR": "كدحه غلابن",
    "CITYNAME_EN": "Kadhah Ghilan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11492",
    "CITYNAME_AR": "ظهره الشريج",
    "CITYNAME_EN": "Zahrah Al-Sherij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11493",
    "CITYNAME_AR": "ظهره الجناح",
    "CITYNAME_EN": "Zahrah Al-Janah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11494",
    "CITYNAME_AR": "قرن الرديغه",
    "CITYNAME_EN": "Qarn Al-Radighah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11495",
    "CITYNAME_AR": "خايع السويداء",
    "CITYNAME_EN": "Khayi' Al-Suwaydaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11496",
    "CITYNAME_AR": "قرن هادى",
    "CITYNAME_EN": "Qarn Hadi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11497",
    "CITYNAME_AR": "المنضد والمعروض",
    "CITYNAME_EN": "Al-Manded and Al-Ma'rud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11498",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al-Jawah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11499",
    "CITYNAME_AR": "قذل عريفط والمنقع",
    "CITYNAME_EN": "Qzl Arfit and Al-Manqa' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11500",
    "CITYNAME_AR": "النشمات وشعب السلم",
    "CITYNAME_EN": "Al-Nashmat and Shi'b Al-Salm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11501",
    "CITYNAME_AR": "جرد كسير",
    "CITYNAME_EN": "Jerd Kasir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11502",
    "CITYNAME_AR": "عثوان وذريع النخله",
    "CITYNAME_EN": "Athwan and Zuray' Al-Nakhlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11503",
    "CITYNAME_AR": "وادى موشاع",
    "CITYNAME_EN": "Wadi Mosha' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11504",
    "CITYNAME_AR": "زفنقه",
    "CITYNAME_EN": "Zafnaqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11505",
    "CITYNAME_AR": "شعب نقعاء",
    "CITYNAME_EN": "Shi'b Naq'aa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11506",
    "CITYNAME_AR": "ريع الكلبه",
    "CITYNAME_EN": "Rib' Al-Kalbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11507",
    "CITYNAME_AR": "وادى الرجعاء (الرجعان)",
    "CITYNAME_EN": "Wadi Al-Raj'aa (Al-Raj'an)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11508",
    "CITYNAME_AR": "الخايع",
    "CITYNAME_EN": "Al-Khyi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11509",
    "CITYNAME_AR": "الخزان",
    "CITYNAME_EN": "Al-Khazan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11510",
    "CITYNAME_AR": "صخل",
    "CITYNAME_EN": "Sajl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11511",
    "CITYNAME_AR": "الكسر",
    "CITYNAME_EN": "Al-Kesr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11512",
    "CITYNAME_AR": "الاسد",
    "CITYNAME_EN": "Al-Asad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11513",
    "CITYNAME_AR": "الزمرة",
    "CITYNAME_EN": "Al-Zumrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11514",
    "CITYNAME_AR": "الوسيع",
    "CITYNAME_EN": "Al-Wasi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11515",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al-Hankah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11516",
    "CITYNAME_AR": "خب خرجان",
    "CITYNAME_EN": "Kheb Kherjan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11517",
    "CITYNAME_AR": "ابن عبيدي",
    "CITYNAME_EN": "Ibn Obaidy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11518",
    "CITYNAME_AR": "الربض",
    "CITYNAME_EN": "Al-Reed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11519",
    "CITYNAME_AR": "الجهايف",
    "CITYNAME_EN": "Al-Jahayif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11520",
    "CITYNAME_AR": "قرن القره",
    "CITYNAME_EN": "Qarn Al-Qarrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11521",
    "CITYNAME_AR": "الجرد",
    "CITYNAME_EN": "Al-Jard",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11522",
    "CITYNAME_AR": "افق",
    "CITYNAME_EN": "Ofuq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11523",
    "CITYNAME_AR": "فالق المسلمة",
    "CITYNAME_EN": "Faliq Al-Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11524",
    "CITYNAME_AR": "الشريج",
    "CITYNAME_EN": "Al-Sherij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11525",
    "CITYNAME_AR": "المطرقة",
    "CITYNAME_EN": "Al Mitraqa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11526",
    "CITYNAME_AR": "الحمة",
    "CITYNAME_EN": "Al-Hamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11527",
    "CITYNAME_AR": "ذى الملاصى",
    "CITYNAME_EN": "Zi Al-Milasy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11528",
    "CITYNAME_AR": "سقامه",
    "CITYNAME_EN": "Saqamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11529",
    "CITYNAME_AR": "الاشداف",
    "CITYNAME_EN": "Al-Ashdaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11530",
    "CITYNAME_AR": "نجد (وجه نجد)",
    "CITYNAME_EN": "Najd (Walh Najd)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11531",
    "CITYNAME_AR": "سبلة الحليفة",
    "CITYNAME_EN": "Sablah Al-Halifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11532",
    "CITYNAME_AR": "الشظية",
    "CITYNAME_EN": "Al-Shazyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11533",
    "CITYNAME_AR": "وحامر",
    "CITYNAME_EN": "Hamer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11534",
    "CITYNAME_AR": "الحرشاء",
    "CITYNAME_EN": "Al-Harshaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11535",
    "CITYNAME_AR": "سمعو",
    "CITYNAME_EN": "Sam'u ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11536",
    "CITYNAME_AR": "هده",
    "CITYNAME_EN": "Hodah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11537",
    "CITYNAME_AR": "قرن العجمه",
    "CITYNAME_EN": "Qarn Al-Ajmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11538",
    "CITYNAME_AR": "الرايغه",
    "CITYNAME_EN": "Al-Rayghah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11539",
    "CITYNAME_AR": "ثماخ",
    "CITYNAME_EN": "Themakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11540",
    "CITYNAME_AR": "ايهام",
    "CITYNAME_EN": "Iham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11541",
    "CITYNAME_AR": "الصحن",
    "CITYNAME_EN": "Al Sahn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11542",
    "CITYNAME_AR": "حبيل خبة",
    "CITYNAME_EN": "Hobayl Khibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11543",
    "CITYNAME_AR": "الخروم",
    "CITYNAME_EN": "Al-Kharum ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11544",
    "CITYNAME_AR": "قوله",
    "CITYNAME_EN": "Qawlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11545",
    "CITYNAME_AR": "ظهره نحيان",
    "CITYNAME_EN": "Zahrah Nihyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11546",
    "CITYNAME_AR": "ذى شنع",
    "CITYNAME_EN": "Zi Shan' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11547",
    "CITYNAME_AR": "قرقر",
    "CITYNAME_EN": "Qarqar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11548",
    "CITYNAME_AR": "وادي حمراء",
    "CITYNAME_EN": "Wadi Hamraa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11549",
    "CITYNAME_AR": "اروادى",
    "CITYNAME_EN": "Arwadi ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11550",
    "CITYNAME_AR": "الجواير",
    "CITYNAME_EN": "Al-Jawayir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11551",
    "CITYNAME_AR": "احراض",
    "CITYNAME_EN": "Ahrad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11552",
    "CITYNAME_AR": "الكيله",
    "CITYNAME_EN": "Al-Kilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11553",
    "CITYNAME_AR": "ابهن",
    "CITYNAME_EN": "Abhan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11554",
    "CITYNAME_AR": "الظلفه",
    "CITYNAME_EN": "Al-Zalfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11555",
    "CITYNAME_AR": "ضيتمان واسلاب",
    "CITYNAME_EN": "Ditman and Aslab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11556",
    "CITYNAME_AR": "العنقة",
    "CITYNAME_EN": "Al Anqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11557",
    "CITYNAME_AR": "المشبه",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11558",
    "CITYNAME_AR": "الشق",
    "CITYNAME_EN": "Al-Shiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11559",
    "CITYNAME_AR": "قرانين",
    "CITYNAME_EN": "Qranin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11560",
    "CITYNAME_AR": "الحليفه (الحلفه)",
    "CITYNAME_EN": "Al-Halifah (Al-Halfah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11561",
    "CITYNAME_AR": "الحثمه",
    "CITYNAME_EN": "Al-Hathmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11562",
    "CITYNAME_AR": "الوقيع",
    "CITYNAME_EN": "Al-Waqi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11563",
    "CITYNAME_AR": "نجد",
    "CITYNAME_EN": "Najd",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11564",
    "CITYNAME_AR": "المجممه",
    "CITYNAME_EN": "Al-Majmamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11565",
    "CITYNAME_AR": "خثل",
    "CITYNAME_EN": "Khathl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11566",
    "CITYNAME_AR": "ظهرة ال مشيره",
    "CITYNAME_EN": "Zahret Al Moshirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11567",
    "CITYNAME_AR": "ظهرة دندن",
    "CITYNAME_EN": "Zahret Denden ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11568",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11569",
    "CITYNAME_AR": "قفوان",
    "CITYNAME_EN": "Qafwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11570",
    "CITYNAME_AR": "ابن شاطيه",
    "CITYNAME_EN": "Ibn Shatiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11571",
    "CITYNAME_AR": "معصم",
    "CITYNAME_EN": "Me'sam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11572",
    "CITYNAME_AR": "ماطر",
    "CITYNAME_EN": "Matir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11573",
    "CITYNAME_AR": "المجدر",
    "CITYNAME_EN": "Al-Majder ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11574",
    "CITYNAME_AR": "المسلمة",
    "CITYNAME_EN": "Al-Maslamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11575",
    "CITYNAME_AR": "الرقشات",
    "CITYNAME_EN": "Al-Raqshat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11576",
    "CITYNAME_AR": "المقسم",
    "CITYNAME_EN": "Al-Maqsam ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11577",
    "CITYNAME_AR": "العيده",
    "CITYNAME_EN": "Al-Abdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11578",
    "CITYNAME_AR": "عجاور",
    "CITYNAME_EN": "Ajawer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11579",
    "CITYNAME_AR": "الغشية",
    "CITYNAME_EN": "Al-Ghashyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11580",
    "CITYNAME_AR": "مرر",
    "CITYNAME_EN": "Marr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11581",
    "CITYNAME_AR": "الغليفاء",
    "CITYNAME_EN": "Al-Ghalifaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11582",
    "CITYNAME_AR": "وتران",
    "CITYNAME_EN": "Watran",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11583",
    "CITYNAME_AR": "الصلقه",
    "CITYNAME_EN": "Al-Salqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11584",
    "CITYNAME_AR": "عشره",
    "CITYNAME_EN": "Ishrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11585",
    "CITYNAME_AR": "ذى ام سلب",
    "CITYNAME_EN": "Zi Umm Salb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11586",
    "CITYNAME_AR": "رجم",
    "CITYNAME_EN": "Rajm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11587",
    "CITYNAME_AR": "اللفايج",
    "CITYNAME_EN": "Allafayij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11588",
    "CITYNAME_AR": "صخد",
    "CITYNAME_EN": "Sakhd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11589",
    "CITYNAME_AR": "الصقل",
    "CITYNAME_EN": "Al-Saql",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11590",
    "CITYNAME_AR": "قرية وادي عرم",
    "CITYNAME_EN": "Wadi Arm Village ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11591",
    "CITYNAME_AR": "نقيل ال راشد (النقين)",
    "CITYNAME_EN": "Naqil Al-Rashid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11592",
    "CITYNAME_AR": "بعرور",
    "CITYNAME_EN": "Ba'rur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11593",
    "CITYNAME_AR": "كراثة",
    "CITYNAME_EN": "krathah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11594",
    "CITYNAME_AR": "صدرتية المرانة",
    "CITYNAME_EN": "Sadratiyah Al-Mranah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11595",
    "CITYNAME_AR": "فتاح",
    "CITYNAME_EN": "Fetah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11596",
    "CITYNAME_AR": "اثايب",
    "CITYNAME_EN": "Athayib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11597",
    "CITYNAME_AR": "الهيل",
    "CITYNAME_EN": "Al Heel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11598",
    "CITYNAME_AR": "رندد",
    "CITYNAME_EN": "Randd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11599",
    "CITYNAME_AR": "ال ناهيه",
    "CITYNAME_EN": "Al-Nahyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11600",
    "CITYNAME_AR": "قرية وادي نخلين",
    "CITYNAME_EN": "Wadi Nakhlin Village",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11601",
    "CITYNAME_AR": "ضمو",
    "CITYNAME_EN": "Damu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11602",
    "CITYNAME_AR": "ريمه",
    "CITYNAME_EN": "Reemah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11603",
    "CITYNAME_AR": "ذى الثوعه",
    "CITYNAME_EN": "Zi Al-Thaw'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11604",
    "CITYNAME_AR": "جمه",
    "CITYNAME_EN": "Jammah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11605",
    "CITYNAME_AR": "خمرين",
    "CITYNAME_EN": "Khamrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11606",
    "CITYNAME_AR": "السعاده",
    "CITYNAME_EN": "Al-S'adah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11607",
    "CITYNAME_AR": "الدبوب",
    "CITYNAME_EN": "Al Daboub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11608",
    "CITYNAME_AR": "المقابير (معرفن)",
    "CITYNAME_EN": "Al-Maqabir (Me'rfin)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11609",
    "CITYNAME_AR": "النشيم",
    "CITYNAME_EN": "Al-Nashim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11610",
    "CITYNAME_AR": "حضن زهير",
    "CITYNAME_EN": "Hodn Zohayr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11611",
    "CITYNAME_AR": "شعبة الضباء",
    "CITYNAME_EN": "Shibah Al-Dibaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11612",
    "CITYNAME_AR": "ذي الجنين",
    "CITYNAME_EN": "Zi Al-Janin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11613",
    "CITYNAME_AR": "وريثه",
    "CITYNAME_EN": "Warithah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11614",
    "CITYNAME_AR": "طيب الاسم",
    "CITYNAME_EN": "Tib Al-Esm ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11615",
    "CITYNAME_AR": "العجعوج",
    "CITYNAME_EN": "Al-Aj'uj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11616",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11617",
    "CITYNAME_AR": "العجمه",
    "CITYNAME_EN": "Al-Ajmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11618",
    "CITYNAME_AR": "ذي يبرن",
    "CITYNAME_EN": "Zi Yebrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11619",
    "CITYNAME_AR": "قرين",
    "CITYNAME_EN": "Qarin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11620",
    "CITYNAME_AR": "البدله",
    "CITYNAME_EN": "Al-Badlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11621",
    "CITYNAME_AR": "وادى الواشل",
    "CITYNAME_EN": "Wadi Al-Washil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11622",
    "CITYNAME_AR": "بادية الناصفه",
    "CITYNAME_EN": "Badiyah AL-Nasfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11623",
    "CITYNAME_AR": "خميس مطير",
    "CITYNAME_EN": "Khamis Matir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11624",
    "CITYNAME_AR": "المظلف",
    "CITYNAME_EN": "Almuzlif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11625",
    "CITYNAME_AR": "الساهديه",
    "CITYNAME_EN": "Al-Sahdiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11626",
    "CITYNAME_AR": "المطلع",
    "CITYNAME_EN": "Al-Matla'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11627",
    "CITYNAME_AR": "شعب الحجاريه",
    "CITYNAME_EN": "Shi'b Al-Hejariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11628",
    "CITYNAME_AR": "بلدعامر",
    "CITYNAME_EN": "Bald Amer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11629",
    "CITYNAME_AR": "ضحى ال سعيدي",
    "CITYNAME_EN": "Doha Al-Sa'idy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11630",
    "CITYNAME_AR": "بحران",
    "CITYNAME_EN": "Bahran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11631",
    "CITYNAME_AR": "المربوظه",
    "CITYNAME_EN": "Al-Maryuzah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11632",
    "CITYNAME_AR": "مقاطر",
    "CITYNAME_EN": "Maqater ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11633",
    "CITYNAME_AR": "شعب سالم",
    "CITYNAME_EN": "Shi'b Salem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11634",
    "CITYNAME_AR": "الرماده",
    "CITYNAME_EN": "Al-Ramadah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11635",
    "CITYNAME_AR": "مدسوس",
    "CITYNAME_EN": "Madsus ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11636",
    "CITYNAME_AR": "الخلفه",
    "CITYNAME_EN": "Al-Khalfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11637",
    "CITYNAME_AR": "شعب شايع",
    "CITYNAME_EN": "Shi'b Shayi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11638",
    "CITYNAME_AR": "القواعد",
    "CITYNAME_EN": "Al-Qwa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11639",
    "CITYNAME_AR": "الفاضيه",
    "CITYNAME_EN": "Al-Fadyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11640",
    "CITYNAME_AR": "الطرقه",
    "CITYNAME_EN": "Al Tarqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11641",
    "CITYNAME_AR": "الخضاريات",
    "CITYNAME_EN": "Al-Khadariyat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11642",
    "CITYNAME_AR": "القراه",
    "CITYNAME_EN": "Al-Qarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11643",
    "CITYNAME_AR": "القرن الابيض مع الظهره",
    "CITYNAME_EN": "Al-Qarn Al-Abyad with Al-Zurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11644",
    "CITYNAME_AR": "منزل ال النامس",
    "CITYNAME_EN": "Menzil Al Al-Namis ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11645",
    "CITYNAME_AR": "الركبه مع ال جحيش",
    "CITYNAME_EN": "Al-rakbah with Al juhaysh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11646",
    "CITYNAME_AR": "القفيل",
    "CITYNAME_EN": "Al-Qafil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11647",
    "CITYNAME_AR": "الفي",
    "CITYNAME_EN": "Al-Fy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11648",
    "CITYNAME_AR": "اثعاب",
    "CITYNAME_EN": "Ath'ab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11649",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11650",
    "CITYNAME_AR": "العتبه",
    "CITYNAME_EN": "Al-Atbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11651",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Al-Arud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11652",
    "CITYNAME_AR": "الضحيان",
    "CITYNAME_EN": "Al-Dahiyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11653",
    "CITYNAME_AR": "الزرائب",
    "CITYNAME_EN": "Al-Zaraeb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11654",
    "CITYNAME_AR": "فلقاه",
    "CITYNAME_EN": "Falqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11655",
    "CITYNAME_AR": "شوحط",
    "CITYNAME_EN": "Shuhat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11656",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11657",
    "CITYNAME_AR": "هرب",
    "CITYNAME_EN": "Harb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11658",
    "CITYNAME_AR": "الشنوع",
    "CITYNAME_EN": "Al-Shanu' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11659",
    "CITYNAME_AR": "الضحى ال شيخ",
    "CITYNAME_EN": "Al-Duha Al Sheikh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11660",
    "CITYNAME_AR": "القريات",
    "CITYNAME_EN": "Qurayyat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11661",
    "CITYNAME_AR": "المشبه",
    "CITYNAME_EN": "Al-Meshbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11662",
    "CITYNAME_AR": "ال جويهم",
    "CITYNAME_EN": "Al Juehim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11663",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Al Maslamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11664",
    "CITYNAME_AR": "عيبة",
    "CITYNAME_EN": "Eibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11665",
    "CITYNAME_AR": "حصن القرن",
    "CITYNAME_EN": "Hisn Al-Qarn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11666",
    "CITYNAME_AR": "الحرشه",
    "CITYNAME_EN": "Al-Harshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11667",
    "CITYNAME_AR": "الكديد",
    "CITYNAME_EN": "Al-Kadid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11668",
    "CITYNAME_AR": "ال قياس",
    "CITYNAME_EN": "Al Qiyas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11669",
    "CITYNAME_AR": "الشطوه",
    "CITYNAME_EN": "Al-Shutah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11670",
    "CITYNAME_AR": "الجردان",
    "CITYNAME_EN": "Al-Jerdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11671",
    "CITYNAME_AR": "المنزل (آل لحن مع الداخل)",
    "CITYNAME_EN": "Al-Manzil (Al Lahn with Al-Dakhil)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11672",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11673",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11674",
    "CITYNAME_AR": "البزخاء مع الحصنين",
    "CITYNAME_EN": "Al-Bazkhaa with Al-Hadnin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11675",
    "CITYNAME_AR": "الظاهره",
    "CITYNAME_EN": "Al-Zahirah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11676",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Al Gharabah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11677",
    "CITYNAME_AR": "الشطين",
    "CITYNAME_EN": "Al-Shatin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11678",
    "CITYNAME_AR": "الوقبه",
    "CITYNAME_EN": "Al-Waqiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11679",
    "CITYNAME_AR": "الجمعاء",
    "CITYNAME_EN": "Al-Jmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11680",
    "CITYNAME_AR": "العراضه مع ظهرة العرافه",
    "CITYNAME_EN": "Al-Aradah with Zahrah Al-Arafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11681",
    "CITYNAME_AR": "مرعنه مع الضحيان",
    "CITYNAME_EN": "Mer'nah with Al-Dahyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11682",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11683",
    "CITYNAME_AR": "الشواطى",
    "CITYNAME_EN": "Al-Shawaty ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11684",
    "CITYNAME_AR": "الظهره مع الخشره",
    "CITYNAME_EN": "Al-Zuhrah with Al-Khashrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11685",
    "CITYNAME_AR": "البازم",
    "CITYNAME_EN": "Al-Bazim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11686",
    "CITYNAME_AR": "النجاد",
    "CITYNAME_EN": "Al-Nejad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11687",
    "CITYNAME_AR": "الوهط",
    "CITYNAME_EN": "Al Waht",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11688",
    "CITYNAME_AR": "النخبه (المخباه)",
    "CITYNAME_EN": "Al-Nokhbah (Al-Makhbah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11689",
    "CITYNAME_AR": "ذى ام عريش",
    "CITYNAME_EN": "Zi Umm Oraysh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11690",
    "CITYNAME_AR": "الملاحه",
    "CITYNAME_EN": "Al Melaha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11691",
    "CITYNAME_AR": "مطراف",
    "CITYNAME_EN": "Metraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11692",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al-Marwah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11693",
    "CITYNAME_AR": "حقو سيال",
    "CITYNAME_EN": "Haqw Siyal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11694",
    "CITYNAME_AR": "صمعه",
    "CITYNAME_EN": "Sam'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11695",
    "CITYNAME_AR": "الظهارين",
    "CITYNAME_EN": "Al-Zaharin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11696",
    "CITYNAME_AR": "حوض مريع",
    "CITYNAME_EN": "Hawd Marba' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11697",
    "CITYNAME_AR": "الصيهدان",
    "CITYNAME_EN": "Al-Sahidan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11698",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11699",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11700",
    "CITYNAME_AR": "حصن الضبره",
    "CITYNAME_EN": "Hisn Al-Dabrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11701",
    "CITYNAME_AR": "الصدعاء",
    "CITYNAME_EN": "Al-Sad'aa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11702",
    "CITYNAME_AR": "الجلهه",
    "CITYNAME_EN": "Al-Galhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11703",
    "CITYNAME_AR": "ال مفلح",
    "CITYNAME_EN": "Al-Meflih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11704",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Al-Oshah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11705",
    "CITYNAME_AR": "العلب",
    "CITYNAME_EN": "Al-Alb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11706",
    "CITYNAME_AR": "الضحى",
    "CITYNAME_EN": "Al Duha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11707",
    "CITYNAME_AR": "المشكل",
    "CITYNAME_EN": "Al-Meshkil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11708",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11709",
    "CITYNAME_AR": "ال سنان",
    "CITYNAME_EN": "Al Senan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11710",
    "CITYNAME_AR": "ال معيزاء",
    "CITYNAME_EN": "Al Mo'ayzaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11711",
    "CITYNAME_AR": "القابل",
    "CITYNAME_EN": "Al-Qabil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11712",
    "CITYNAME_AR": "القراه ال شافع",
    "CITYNAME_EN": "Al Qarah Al Shafi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11713",
    "CITYNAME_AR": "الحصمه ال عطيف",
    "CITYNAME_EN": "Al-Hamsah Al Atif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11714",
    "CITYNAME_AR": "الرفرفه",
    "CITYNAME_EN": "Al-Rafrafah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11715",
    "CITYNAME_AR": "نجد ال سعيدان",
    "CITYNAME_EN": "Najd Al Sa'idan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11716",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11717",
    "CITYNAME_AR": "القرن مع القصبه",
    "CITYNAME_EN": "Al-Qarn with Al-Qasbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11718",
    "CITYNAME_AR": "الدخايل ال ساريه",
    "CITYNAME_EN": "Al-Dakhayil Al Sariyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11719",
    "CITYNAME_AR": "الجزع",
    "CITYNAME_EN": "Al-Jaz'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11720",
    "CITYNAME_AR": "الخشم",
    "CITYNAME_EN": "Al Khashm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11721",
    "CITYNAME_AR": "رهط",
    "CITYNAME_EN": "Raht ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11722",
    "CITYNAME_AR": "السلعه",
    "CITYNAME_EN": "Al Sel'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11723",
    "CITYNAME_AR": "بياضه",
    "CITYNAME_EN": "Bayadah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11724",
    "CITYNAME_AR": "العصماء",
    "CITYNAME_EN": "Al Asmaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11725",
    "CITYNAME_AR": "الخطيم",
    "CITYNAME_EN": "Al Khatim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11726",
    "CITYNAME_AR": "الهرار",
    "CITYNAME_EN": "Al Harar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11727",
    "CITYNAME_AR": "الغرايب",
    "CITYNAME_EN": "Al-Gharayib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11728",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Al-Zohrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11729",
    "CITYNAME_AR": "الحرقاء",
    "CITYNAME_EN": "Al-Harqaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11730",
    "CITYNAME_AR": "الرهاى",
    "CITYNAME_EN": "Al-Rahay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11731",
    "CITYNAME_AR": "تريامه",
    "CITYNAME_EN": "Teryamah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11732",
    "CITYNAME_AR": "الطوى",
    "CITYNAME_EN": "Al-Tawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11733",
    "CITYNAME_AR": "البتيله",
    "CITYNAME_EN": "Al-Batilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11734",
    "CITYNAME_AR": "عمير",
    "CITYNAME_EN": "Omair ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11735",
    "CITYNAME_AR": "المخاريق",
    "CITYNAME_EN": "Al-Makhariq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11736",
    "CITYNAME_AR": "العصاى باللسمر",
    "CITYNAME_EN": "Al-Asay Bel Somr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11737",
    "CITYNAME_AR": "المنظر",
    "CITYNAME_EN": "Al-Manzer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11738",
    "CITYNAME_AR": "القراه",
    "CITYNAME_EN": "Al-Qarah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11739",
    "CITYNAME_AR": "الفاجى",
    "CITYNAME_EN": "Al-Fajy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11740",
    "CITYNAME_AR": "ام زريبه",
    "CITYNAME_EN": "Umm Zaribah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11741",
    "CITYNAME_AR": "الشاهق",
    "CITYNAME_EN": "Al-Shahiq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11742",
    "CITYNAME_AR": "القصبه",
    "CITYNAME_EN": "Al Qasba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11743",
    "CITYNAME_AR": "العسف",
    "CITYNAME_EN": "Al Asf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11744",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Al-Rajmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11745",
    "CITYNAME_AR": "المسلم",
    "CITYNAME_EN": "Al-Moslim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11746",
    "CITYNAME_AR": "العصاى باللحمر",
    "CITYNAME_EN": "Al-Asay Bel Homr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11747",
    "CITYNAME_AR": "النصب مع الجميمه",
    "CITYNAME_EN": "Al-Nasb with Al-Jamimah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11748",
    "CITYNAME_AR": "قراه ال مكثر",
    "CITYNAME_EN": "Qrah Al Mekthir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11749",
    "CITYNAME_AR": "المعدى",
    "CITYNAME_EN": "Al-Ma'di",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11750",
    "CITYNAME_AR": "الربض",
    "CITYNAME_EN": "Al-Reed",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11751",
    "CITYNAME_AR": "قرناى",
    "CITYNAME_EN": "Qarnay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11752",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al-Qarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11753",
    "CITYNAME_AR": "ال سوبه",
    "CITYNAME_EN": "Al Subah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11754",
    "CITYNAME_AR": "فراع",
    "CITYNAME_EN": "Fara' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11755",
    "CITYNAME_AR": "ظهره ام ظرفه",
    "CITYNAME_EN": "Zohrah Umm Zarfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11756",
    "CITYNAME_AR": "درب مقحم",
    "CITYNAME_EN": "Darb Meqhim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11757",
    "CITYNAME_AR": "الظهرين",
    "CITYNAME_EN": "Al-Zohrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11758",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11759",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11760",
    "CITYNAME_AR": "الحرشه مع القويد",
    "CITYNAME_EN": "Al-Harshah with Al-Qued ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11761",
    "CITYNAME_AR": "مخطم",
    "CITYNAME_EN": "Mekhtim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11762",
    "CITYNAME_AR": "حضن السعد",
    "CITYNAME_EN": "Hodn Al-Sa'd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11763",
    "CITYNAME_AR": "البو",
    "CITYNAME_EN": "Al-Bu ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11764",
    "CITYNAME_AR": "الوهده",
    "CITYNAME_EN": "Al Wahdah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11765",
    "CITYNAME_AR": "العرشان مع الهديه",
    "CITYNAME_EN": "Al-Arshan with Al-Hadiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11766",
    "CITYNAME_AR": "نخران",
    "CITYNAME_EN": "Nakhran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11767",
    "CITYNAME_AR": "الكدايه",
    "CITYNAME_EN": "Al-Kedayah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11768",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11769",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11770",
    "CITYNAME_AR": "الرحاب",
    "CITYNAME_EN": "Al Rehab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11771",
    "CITYNAME_AR": "ظهرة الغيل",
    "CITYNAME_EN": "Zohrah Al-Ghiel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11772",
    "CITYNAME_AR": "المطارق",
    "CITYNAME_EN": "Al-Matariq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11773",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11774",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11775",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11776",
    "CITYNAME_AR": "المنصوره",
    "CITYNAME_EN": "Al Mansourah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11777",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11778",
    "CITYNAME_AR": "ظهرة القزعه",
    "CITYNAME_EN": "Zohrah Al-Qaz'a",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11779",
    "CITYNAME_AR": "ودن عادى",
    "CITYNAME_EN": "Wedn Ady ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11780",
    "CITYNAME_AR": "الحيارى مع المشياح",
    "CITYNAME_EN": "Al-Hayara with Al-Meshyakh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11781",
    "CITYNAME_AR": "ظهرة العسف (الجردان)",
    "CITYNAME_EN": "Zuhrah Al-Asf (Al-Jerdan) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11782",
    "CITYNAME_AR": "حاجب ال صناي",
    "CITYNAME_EN": "Hajib Al Sanay ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11783",
    "CITYNAME_AR": "قرن الغرابه (جرجره)",
    "CITYNAME_EN": "Qarn Al-Gharabah (jarjarah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11784",
    "CITYNAME_AR": "المديد",
    "CITYNAME_EN": "Al-Madid",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11785",
    "CITYNAME_AR": "الخلف",
    "CITYNAME_EN": "Al-Khalf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11786",
    "CITYNAME_AR": "ذي صلب",
    "CITYNAME_EN": "Zi Solb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11787",
    "CITYNAME_AR": "سعيده الصوالحه",
    "CITYNAME_EN": "Sa'dah Al-Sawalhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11788",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sa'ban",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11789",
    "CITYNAME_AR": "غالب",
    "CITYNAME_EN": "Ghalib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11790",
    "CITYNAME_AR": "عتيمان",
    "CITYNAME_EN": "Otayman ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11791",
    "CITYNAME_AR": "السرب",
    "CITYNAME_EN": "Al-Serb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11792",
    "CITYNAME_AR": "الاضاه",
    "CITYNAME_EN": "Al-Adah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11793",
    "CITYNAME_AR": "قليله",
    "CITYNAME_EN": "Qalilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11794",
    "CITYNAME_AR": "سمره",
    "CITYNAME_EN": "Samra",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11795",
    "CITYNAME_AR": "حصيان",
    "CITYNAME_EN": "Hesyan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11796",
    "CITYNAME_AR": "المعلمات",
    "CITYNAME_EN": "Teachers",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11797",
    "CITYNAME_AR": "عيناء",
    "CITYNAME_EN": "Ainaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11798",
    "CITYNAME_AR": "جراعه",
    "CITYNAME_EN": "jara'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11799",
    "CITYNAME_AR": "الشليلا",
    "CITYNAME_EN": "Al-Shalilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11800",
    "CITYNAME_AR": "القهيبات",
    "CITYNAME_EN": "Al-Qahibat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11801",
    "CITYNAME_AR": "الحجفار",
    "CITYNAME_EN": "Al-Hajfar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11802",
    "CITYNAME_AR": "القايم",
    "CITYNAME_EN": "Al-Qayim",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11803",
    "CITYNAME_AR": "القف",
    "CITYNAME_EN": "Al-Quf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11804",
    "CITYNAME_AR": "القروى (قرن ام صياد)",
    "CITYNAME_EN": "Al-Qarwy (Qarn Umm Sayad)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11805",
    "CITYNAME_AR": "الجرد",
    "CITYNAME_EN": "Al-Jard",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11806",
    "CITYNAME_AR": "الحبيب بنى ذيب",
    "CITYNAME_EN": "Al-habib Bni Zeib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11807",
    "CITYNAME_AR": "الدخال",
    "CITYNAME_EN": "Al-Dekhal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11808",
    "CITYNAME_AR": "خشعان",
    "CITYNAME_EN": "Khash'an ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11809",
    "CITYNAME_AR": "جعفر",
    "CITYNAME_EN": "Ja'far ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11810",
    "CITYNAME_AR": "الجندله",
    "CITYNAME_EN": "Al-Jandalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11811",
    "CITYNAME_AR": "الحثام",
    "CITYNAME_EN": "Al-Hatham",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11812",
    "CITYNAME_AR": "المراوح",
    "CITYNAME_EN": "Al-Marawih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11813",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11814",
    "CITYNAME_AR": "وادي حموان",
    "CITYNAME_EN": "Wadi Hamwan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11815",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11816",
    "CITYNAME_AR": "المقر",
    "CITYNAME_EN": "Al-Maqr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11817",
    "CITYNAME_AR": "بليعين",
    "CITYNAME_EN": "Bali'in ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11818",
    "CITYNAME_AR": "مريكه",
    "CITYNAME_EN": "Marikah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11819",
    "CITYNAME_AR": "حصيان",
    "CITYNAME_EN": "Hesyan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11820",
    "CITYNAME_AR": "الكدحه",
    "CITYNAME_EN": "Al-Kadhah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11821",
    "CITYNAME_AR": "الدبوب",
    "CITYNAME_EN": "Al Daboub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11822",
    "CITYNAME_AR": "وغا",
    "CITYNAME_EN": "Wagha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11823",
    "CITYNAME_AR": "حميد",
    "CITYNAME_EN": "Hamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11824",
    "CITYNAME_AR": "المثمله",
    "CITYNAME_EN": "Al-Mathmalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11825",
    "CITYNAME_AR": "الحجناء",
    "CITYNAME_EN": "Al-Hajnaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11826",
    "CITYNAME_AR": "المعروض",
    "CITYNAME_EN": "Al-Ma'rud ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11827",
    "CITYNAME_AR": "العدن",
    "CITYNAME_EN": "Al-Adn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11828",
    "CITYNAME_AR": "منصاب",
    "CITYNAME_EN": "Mensab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11829",
    "CITYNAME_AR": "المغيمر",
    "CITYNAME_EN": "Al-Maghimer ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11830",
    "CITYNAME_AR": "اجيده",
    "CITYNAME_EN": "Ajidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11831",
    "CITYNAME_AR": "الشغيبى",
    "CITYNAME_EN": "Al-Sheghiby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11832",
    "CITYNAME_AR": "سكيبه",
    "CITYNAME_EN": "Sakibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11833",
    "CITYNAME_AR": "وادى سلوب",
    "CITYNAME_EN": "Wadi Salub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11834",
    "CITYNAME_AR": "سيلان",
    "CITYNAME_EN": "Sielan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11835",
    "CITYNAME_AR": "زعبان",
    "CITYNAME_EN": "Za'ban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11836",
    "CITYNAME_AR": "وادى ممدد",
    "CITYNAME_EN": "Wadi Madd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11837",
    "CITYNAME_AR": "ام سيال (وادي سيال)",
    "CITYNAME_EN": "Umm Seyal (Wadi Seyal)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11838",
    "CITYNAME_AR": "الفصيله",
    "CITYNAME_EN": "Al Fasilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11839",
    "CITYNAME_AR": "القلاله",
    "CITYNAME_EN": "Al-Qalalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11840",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Al Dahla",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11841",
    "CITYNAME_AR": "جوهره",
    "CITYNAME_EN": "Jawhrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11842",
    "CITYNAME_AR": "آل خليف",
    "CITYNAME_EN": "Al Khalifa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11843",
    "CITYNAME_AR": "وادى حبطن",
    "CITYNAME_EN": "Wadi Hebtin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11844",
    "CITYNAME_AR": "ال حنيش (ال ذيب)",
    "CITYNAME_EN": "Al-Hanish (Al Zib)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11845",
    "CITYNAME_AR": "شوقه",
    "CITYNAME_EN": "Shawqah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11846",
    "CITYNAME_AR": "الحثام",
    "CITYNAME_EN": "Alhitham",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11847",
    "CITYNAME_AR": "حويمه",
    "CITYNAME_EN": "Hawaymuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11848",
    "CITYNAME_AR": "المشيرب",
    "CITYNAME_EN": "Almushirib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11849",
    "CITYNAME_AR": "المنضده",
    "CITYNAME_EN": "Almandaduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11850",
    "CITYNAME_AR": "حاجب مقبول",
    "CITYNAME_EN": "Hajib Maqbul",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11851",
    "CITYNAME_AR": "الدفنه",
    "CITYNAME_EN": "Aldafnuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11852",
    "CITYNAME_AR": "حثام الدراشيب",
    "CITYNAME_EN": "Hatham Aldarashib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11853",
    "CITYNAME_AR": "الحوى",
    "CITYNAME_EN": "Alhuaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11854",
    "CITYNAME_AR": "الزور",
    "CITYNAME_EN": "Alzuwr",
    "REGION_ID": "2"
   },
  //  {
  //   "CITY_ID": "11855",
  //   "CITYNAME_AR": "الزهره",
  //   "CITYNAME_EN": "Al Zohra",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11856",
  //   "CITYNAME_AR": "الثويريه",
  //   "CITYNAME_EN": "Althawayriuh",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11857",
  //   "CITYNAME_AR": "ساقى الناهض",
  //   "CITYNAME_EN": "Saqaa Alnnahid",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "11858",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Al-Badey",
    "REGION_ID": "1"
   },
  //  {
  //   "CITY_ID": "11859",
  //   "CITYNAME_AR": "عيون الافلاج",
  //   "CITYNAME_EN": "Euyun Alaflaj",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11860",
  //   "CITYNAME_AR": "الروضه",
  //   "CITYNAME_EN": "Al Raudah",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11861",
  //   "CITYNAME_AR": "الصغو",
  //   "CITYNAME_EN": "Al Saghw",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11862",
  //   "CITYNAME_AR": "مروان",
  //   "CITYNAME_EN": "Marwan",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11863",
  //   "CITYNAME_AR": "هجرة الرفايع ومحطة بنزين",
  //   "CITYNAME_EN": "Hijrat Alrafayie and Bnzyn Station",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11864",
  //   "CITYNAME_AR": "الرزيقيه",
  //   "CITYNAME_EN": "Alraziqayuh",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11865",
  //   "CITYNAME_AR": "سويدان",
  //   "CITYNAME_EN": "Swedan",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11866",
  //   "CITYNAME_AR": "الخرفه",
  //   "CITYNAME_EN": "Al-Kharfa",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11867",
  //   "CITYNAME_AR": "العجليه",
  //   "CITYNAME_EN": "Aleijlayh",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "11868",
    "CITYNAME_AR": "الخفجى",
    "CITYNAME_EN": "Al Khafji",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11869",
    "CITYNAME_AR": "هجرة حمض",
    "CITYNAME_EN": "Hijrah Hamd ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11870",
    "CITYNAME_AR": "السفانيه",
    "CITYNAME_EN": "As Saffaniyah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11871",
    "CITYNAME_AR": "المغره ومحطة العتل",
    "CITYNAME_EN": "Al Magharah and Al Atal Station ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11872",
    "CITYNAME_AR": "تناقيب",
    "CITYNAME_EN": "Tanaqib ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11873",
    "CITYNAME_AR": "هجرة البجسه",
    "CITYNAME_EN": "Hijrah Al Bagsah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11874",
    "CITYNAME_AR": "ابرق الكبريت",
    "CITYNAME_EN": "Abrak Al Kabrit",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11875",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11876",
    "CITYNAME_AR": "الذيبيه",
    "CITYNAME_EN": "Aldhiybih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11877",
    "CITYNAME_AR": "رياض الخبراء",
    "CITYNAME_EN": "Riad Alkhubara'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11878",
    "CITYNAME_AR": "النفيد",
    "CITYNAME_EN": "Alnafid",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11879",
    "CITYNAME_AR": "قريطه",
    "CITYNAME_EN": "Qarituh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11880",
    "CITYNAME_AR": "الخبراء والسحابين",
    "CITYNAME_EN": "Alkhubara' Wal Sahabeen",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11881",
    "CITYNAME_AR": "السحابين",
    "CITYNAME_EN": "Alsahaabin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11882",
    "CITYNAME_AR": "عيون الجواء",
    "CITYNAME_EN": "Euyun Aljawa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11883",
    "CITYNAME_AR": "غاف الجواء",
    "CITYNAME_EN": "Ghaf Aljawa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11884",
    "CITYNAME_AR": "اوثال",
    "CITYNAME_EN": "Awthal",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11885",
    "CITYNAME_AR": "روض الجواء",
    "CITYNAME_EN": "Rud Aljawa'",
    "REGION_ID": "4"
   },
  //  {
  //   "CITY_ID": "11886",
  //   "CITYNAME_AR": "الريان",
  //   "CITYNAME_EN": "Al Ryan",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "11887",
  //   "CITYNAME_AR": "الناشريه",
  //   "CITYNAME_EN": "An-Nashyryah",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "11888",
    "CITYNAME_AR": "ام طليحه",
    "CITYNAME_EN": "Am Talihuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11889",
    "CITYNAME_AR": "الملقاء",
    "CITYNAME_EN": "Almilqa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11890",
    "CITYNAME_AR": "دخنه",
    "CITYNAME_EN": "Dakhnh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11891",
    "CITYNAME_AR": "فيضة دخنه",
    "CITYNAME_EN": "Fidat Dakhnih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11892",
    "CITYNAME_AR": "مزارع فيضة دخنه",
    "CITYNAME_EN": "Farm of Fidat Dakhnah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11893",
    "CITYNAME_AR": "الرفايع ومزارعها",
    "CITYNAME_EN": "Alrafayie and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11894",
    "CITYNAME_AR": "حريفيات درعه",
    "CITYNAME_EN": "Harifiat Dareih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11895",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Ghurab",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11896",
    "CITYNAME_AR": "مزارع طلابة",
    "CITYNAME_EN": "Farm of Tallaba",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11897",
    "CITYNAME_AR": "مفرق الشبيكيه",
    "CITYNAME_EN": "Mfriq Alshabikiih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11898",
    "CITYNAME_AR": "المحبر ومزارعها",
    "CITYNAME_EN": "Almuhabir and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11899",
    "CITYNAME_AR": "سبيله",
    "CITYNAME_EN": "Sabilih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11900",
    "CITYNAME_AR": "الخريشاء",
    "CITYNAME_EN": "Alkharisha'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11901",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Aldahluh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11902",
    "CITYNAME_AR": "الغيدانيه",
    "CITYNAME_EN": "Alghidaniuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11903",
    "CITYNAME_AR": "الشبيكيه",
    "CITYNAME_EN": "Alshabikiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11904",
    "CITYNAME_AR": "الحماده",
    "CITYNAME_EN": "Alhimaduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11905",
    "CITYNAME_AR": "الروضتين",
    "CITYNAME_EN": "Alrawdatayn",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11906",
    "CITYNAME_AR": "مزارع الوسيطاء",
    "CITYNAME_EN": "Farm of Alwasita'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11907",
    "CITYNAME_AR": "أم ردهه",
    "CITYNAME_EN": "Am Radahuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11908",
    "CITYNAME_AR": "فياضه ومزارعها",
    "CITYNAME_EN": "Fiadh and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11909",
    "CITYNAME_AR": "مبهل",
    "CITYNAME_EN": "Mubahil",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11910",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Alhijruh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11911",
    "CITYNAME_AR": "القهيبيه",
    "CITYNAME_EN": "Alqahibiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11912",
    "CITYNAME_AR": "ابونخله",
    "CITYNAME_EN": "Abwnkhlh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11913",
    "CITYNAME_AR": "ام غلس",
    "CITYNAME_EN": "Am Ghalas",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11914",
    "CITYNAME_AR": "النبهانيه",
    "CITYNAME_EN": "Alnubhaniuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11915",
    "CITYNAME_AR": "الصقره",
    "CITYNAME_EN": "Alsaqruh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11916",
    "CITYNAME_AR": "ثادج",
    "CITYNAME_EN": "Thadij",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11917",
    "CITYNAME_AR": "ثويدج",
    "CITYNAME_EN": "Thawayadaj",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11918",
    "CITYNAME_AR": "الوضيم",
    "CITYNAME_EN": "Alwadim",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11919",
    "CITYNAME_AR": "الناصفه",
    "CITYNAME_EN": "Alnnasifuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11920",
    "CITYNAME_AR": "سويقه",
    "CITYNAME_EN": "Sawiquh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11921",
    "CITYNAME_AR": "فرده",
    "CITYNAME_EN": "Faradah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11922",
    "CITYNAME_AR": "الظفيري",
    "CITYNAME_EN": "Alzafiri",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11923",
    "CITYNAME_AR": "الشهباء",
    "CITYNAME_EN": "Alshuhaba'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11924",
    "CITYNAME_AR": "جدعاء",
    "CITYNAME_EN": "Jadea'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11925",
    "CITYNAME_AR": "المشنيه",
    "CITYNAME_EN": "Almashnih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11926",
    "CITYNAME_AR": "مخيط",
    "CITYNAME_EN": "Mukhit",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11927",
    "CITYNAME_AR": "اللغفيه",
    "CITYNAME_EN": "Allaghafih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11928",
    "CITYNAME_AR": "القيصومه",
    "CITYNAME_EN": "Alqisumuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11929",
    "CITYNAME_AR": "البتراء",
    "CITYNAME_EN": "Albitra'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11930",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Alruwduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11931",
    "CITYNAME_AR": "عطاء",
    "CITYNAME_EN": "Eata'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11932",
    "CITYNAME_AR": "عطي",
    "CITYNAME_EN": "Eati",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11933",
    "CITYNAME_AR": "الدوحة",
    "CITYNAME_EN": "Aldawha",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11934",
    "CITYNAME_AR": "الصويتيه",
    "CITYNAME_EN": "Alsuwitiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11935",
    "CITYNAME_AR": "الاسامر",
    "CITYNAME_EN": "Alasamr",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11936",
    "CITYNAME_AR": "الحماده",
    "CITYNAME_EN": "Alhimaduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11937",
    "CITYNAME_AR": "الصلبيه القديمة",
    "CITYNAME_EN": "Alsalabayuh Alqadima",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11938",
    "CITYNAME_AR": "البسيتين",
    "CITYNAME_EN": "Al Busaiteen",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11939",
    "CITYNAME_AR": "ركدان",
    "CITYNAME_EN": "Rakdan",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11940",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Alhafnuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11941",
    "CITYNAME_AR": "الحارة الغربية",
    "CITYNAME_EN": "Alharat Algharbia",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11942",
    "CITYNAME_AR": "الطراق",
    "CITYNAME_EN": "Altiraq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11943",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Masharifuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11944",
    "CITYNAME_AR": "مقطع صاره",
    "CITYNAME_EN": "Muqtae Sarih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11945",
    "CITYNAME_AR": "فيضه بن حميد",
    "CITYNAME_EN": "Fidah Bin Hamid",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11946",
    "CITYNAME_AR": "كبد",
    "CITYNAME_EN": "Kabad",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11947",
    "CITYNAME_AR": "المخرم",
    "CITYNAME_EN": "Almakharam",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11948",
    "CITYNAME_AR": "فيضة يكلب",
    "CITYNAME_EN": "Fidat Yukalib",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11949",
    "CITYNAME_AR": "قليب الترمس",
    "CITYNAME_EN": "Qalib Altaramus",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11950",
    "CITYNAME_AR": "حماده الترمس",
    "CITYNAME_EN": "Hamaduh Altaramus",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11951",
    "CITYNAME_AR": "القواره",
    "CITYNAME_EN": "Alqawaruh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11952",
    "CITYNAME_AR": "العبدليـة",
    "CITYNAME_EN": "Alebdly",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11953",
    "CITYNAME_AR": "خريمان الشمالي",
    "CITYNAME_EN": "Khariman Alshamali",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11954",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Alsaalihih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11955",
    "CITYNAME_AR": "خريمان الاشقر",
    "CITYNAME_EN": "Khariman Alashqr",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11956",
    "CITYNAME_AR": "الخرماء الجنوبية",
    "CITYNAME_EN": "Alkhurama' Aljanubia",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11957",
    "CITYNAME_AR": "ساموده",
    "CITYNAME_EN": "Samudh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11958",
    "CITYNAME_AR": "الربيق",
    "CITYNAME_EN": "Alrabiq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11959",
    "CITYNAME_AR": "العمار",
    "CITYNAME_EN": "Aleamar",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11960",
    "CITYNAME_AR": "الروابي",
    "CITYNAME_EN": "Alrawabiu",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11961",
    "CITYNAME_AR": "الكدادية",
    "CITYNAME_EN": "Alkadadia",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11962",
    "CITYNAME_AR": "المدرج",
    "CITYNAME_EN": "Almudarij",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11963",
    "CITYNAME_AR": "ناضره ام ثميد",
    "CITYNAME_EN": "Nadirah Umm Thamid",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11964",
    "CITYNAME_AR": "محير الترمس",
    "CITYNAME_EN": "Mahiir Altaramus",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11965",
    "CITYNAME_AR": "شرى",
    "CITYNAME_EN": "Sharaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11966",
    "CITYNAME_AR": "الهبيريه",
    "CITYNAME_EN": "Alhabiriuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11967",
    "CITYNAME_AR": "عزيزه الترمس",
    "CITYNAME_EN": "Eazizuh Altaramus",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11968",
    "CITYNAME_AR": "الخفيات وتشمل حاره العنوز",
    "CITYNAME_EN": "Alkhafayat Watashmal Harah Aleunuz",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11969",
    "CITYNAME_AR": "قصيباء الجديده",
    "CITYNAME_EN": "Qasiba' Aljadidih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11970",
    "CITYNAME_AR": "فياض طلحة",
    "CITYNAME_EN": "Fiad Talha",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11971",
    "CITYNAME_AR": "بلعوم",
    "CITYNAME_EN": "Bileum",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11972",
    "CITYNAME_AR": "بليعيم",
    "CITYNAME_EN": "Bilyaeim",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11973",
    "CITYNAME_AR": "قريه ومزارع ابو حمرة",
    "CITYNAME_EN": "Qarih WaFarm of 'Abu Hamra",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11974",
    "CITYNAME_AR": "البطاح",
    "CITYNAME_EN": "Albitah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11975",
    "CITYNAME_AR": "الرسيس",
    "CITYNAME_EN": "Alrasis",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11976",
    "CITYNAME_AR": "الشعيفانيه",
    "CITYNAME_EN": "Alshaeayfaniuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11977",
    "CITYNAME_AR": "الخشيبي",
    "CITYNAME_EN": "Alkhashibi",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11978",
    "CITYNAME_AR": "الحماده ومزارعها",
    "CITYNAME_EN": "Alhimaduh and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11979",
    "CITYNAME_AR": "حليسه ومزارعها",
    "CITYNAME_EN": "Halaysah and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11980",
    "CITYNAME_AR": "الرفايع ومزارعها",
    "CITYNAME_EN": "Alrafayie and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11981",
    "CITYNAME_AR": "سمحه ومزارعها",
    "CITYNAME_EN": "Samahah and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11982",
    "CITYNAME_AR": "صيادات ومزارعها",
    "CITYNAME_EN": "Siadat and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11983",
    "CITYNAME_AR": "قصرابن عقيل",
    "CITYNAME_EN": "Qasirabin Eaqil",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11984",
    "CITYNAME_AR": "خشيبى الحضر القديم",
    "CITYNAME_EN": "Khashibaa Alhudar Alqadim",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11985",
    "CITYNAME_AR": "العرداء",
    "CITYNAME_EN": "Aleurda'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11986",
    "CITYNAME_AR": "قصرالبطاح  العلوات",
    "CITYNAME_EN": "Qsralbtah Alealawat",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11987",
    "CITYNAME_AR": "روضة الحزم",
    "CITYNAME_EN": "Rawdat Alhizm",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11988",
    "CITYNAME_AR": "الديرية",
    "CITYNAME_EN": "Aldiyria",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11989",
    "CITYNAME_AR": "المهاش المحمديه",
    "CITYNAME_EN": "Almahash Almuhamadayuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11990",
    "CITYNAME_AR": "الثامريه",
    "CITYNAME_EN": "Althaamiriuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11991",
    "CITYNAME_AR": "الرفيعه ومزارعها",
    "CITYNAME_EN": "Alrafieuh and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11992",
    "CITYNAME_AR": "حي الجامعية",
    "CITYNAME_EN": "Hayi Aljamieia",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11993",
    "CITYNAME_AR": "الجردانية",
    "CITYNAME_EN": "Aljurdania",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11994",
    "CITYNAME_AR": "مزارع القوعي",
    "CITYNAME_EN": "Farm of Alqaweii",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11995",
    "CITYNAME_AR": "القوعي",
    "CITYNAME_EN": "Alqawei",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11996",
    "CITYNAME_AR": "الدليميه",
    "CITYNAME_EN": "Aldalimih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11997",
    "CITYNAME_AR": "المصك",
    "CITYNAME_EN": "Almasak",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11998",
    "CITYNAME_AR": "دريميحه",
    "CITYNAME_EN": "Drimihuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11999",
    "CITYNAME_AR": "دريميحه الشماليه",
    "CITYNAME_EN": "Drimihuh Alshamaliuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12000",
    "CITYNAME_AR": "الرفايع والطرفيه",
    "CITYNAME_EN": "Alrafayie Waltarfayuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12001",
    "CITYNAME_AR": "السيح والحجازيه",
    "CITYNAME_EN": "Alsiyh Walhajaziuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12002",
    "CITYNAME_AR": "صبيح",
    "CITYNAME_EN": "Sabih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12003",
    "CITYNAME_AR": "نبيها والشنانة",
    "CITYNAME_EN": "Nabiha Walshanana",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12004",
    "CITYNAME_AR": "شعب الحمر",
    "CITYNAME_EN": "Shaeb Alhumur",
    "REGION_ID": "4"
   },
  //  {
  //   "CITY_ID": "12005",
  //   "CITYNAME_AR": "الشيحيه",
  //   "CITYNAME_EN": "Alshiyhiuh",
  //   "REGION_ID": "1"
  //  },
  //  {
  //   "CITY_ID": "12006",
  //   "CITYNAME_AR": "الحاضرية",
  //   "CITYNAME_EN": "Alhadiria",
  //   "REGION_ID": "1"
  //  },
   {
    "CITY_ID": "12007",
    "CITYNAME_AR": "الضبعيه",
    "CITYNAME_EN": "Aldabeih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12008",
    "CITYNAME_AR": "بدائع الضبعيه",
    "CITYNAME_EN": "Badayie Aldabeih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12009",
    "CITYNAME_AR": "الرضم",
    "CITYNAME_EN": "Alradam",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12010",
    "CITYNAME_AR": "الصيديه",
    "CITYNAME_EN": "Alsaydiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12011",
    "CITYNAME_AR": "الشمطاء",
    "CITYNAME_EN": "Alshamata'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12012",
    "CITYNAME_AR": "القاعيه",
    "CITYNAME_EN": "Alqaeih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12013",
    "CITYNAME_AR": "ام سلمه",
    "CITYNAME_EN": "Am Salamah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12014",
    "CITYNAME_AR": "بدائع الحمائين",
    "CITYNAME_EN": "Badayie Alhamayiyn",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12015",
    "CITYNAME_AR": "سدحه",
    "CITYNAME_EN": "Sadahah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12016",
    "CITYNAME_AR": "الرفايع النجج",
    "CITYNAME_EN": "Alrafayie Alnajaj",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12017",
    "CITYNAME_AR": "المندسه",
    "CITYNAME_EN": "Almandisuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12018",
    "CITYNAME_AR": "بدره وام خط",
    "CITYNAME_EN": "Badarih Wa'Um Khatun",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12019",
    "CITYNAME_AR": "بدائع ريمان الشمالي",
    "CITYNAME_EN": "Riman",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12020",
    "CITYNAME_AR": "الرئيسيه والصالحيه",
    "CITYNAME_EN": "Alrayiysiuh Walsaalihih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12021",
    "CITYNAME_AR": "روضه ريمان",
    "CITYNAME_EN": "Ruduh Riman",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12022",
    "CITYNAME_AR": "حماده حسلاء",
    "CITYNAME_EN": "Hamaduh Husala'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12023",
    "CITYNAME_AR": "ابو طريفه",
    "CITYNAME_EN": "Abu Tarifuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12024",
    "CITYNAME_AR": "مشرفة ريمان",
    "CITYNAME_EN": "Mashrifat Ryman",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12025",
    "CITYNAME_AR": "حماده نجخ الشمالي",
    "CITYNAME_EN": "Hamaduh Najakh Alshamalii",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12026",
    "CITYNAME_AR": "نجخ الجنوبي",
    "CITYNAME_EN": "Najakh Aljanubi",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12027",
    "CITYNAME_AR": "ام سلم",
    "CITYNAME_EN": "Am Salam",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12028",
    "CITYNAME_AR": "ابو نخيل",
    "CITYNAME_EN": "Abu Nakhil",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12029",
    "CITYNAME_AR": "مزارع ام المحاش",
    "CITYNAME_EN": "Farm of Umm Almhash",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12030",
    "CITYNAME_AR": "أم لج",
    "CITYNAME_EN": "Am Laj",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12031",
    "CITYNAME_AR": "النخيلة",
    "CITYNAME_EN": "Alnakhila",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12032",
    "CITYNAME_AR": "العاقر",
    "CITYNAME_EN": "Aleaqir",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12033",
    "CITYNAME_AR": "كمب شوفان",
    "CITYNAME_EN": "Shawfan Camp",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12034",
    "CITYNAME_AR": "الظاهريه",
    "CITYNAME_EN": "Alzaahirih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12035",
    "CITYNAME_AR": "فيضه الريشيه",
    "CITYNAME_EN": "Fiduh Alriyshiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12036",
    "CITYNAME_AR": "محامة الهجره",
    "CITYNAME_EN": "Muhamat Alhijarih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12037",
    "CITYNAME_AR": "بدينه",
    "CITYNAME_EN": "Bdinih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12038",
    "CITYNAME_AR": "حماده بن مجلا",
    "CITYNAME_EN": "Hamaduh Bin Majala",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12039",
    "CITYNAME_AR": "رشيده",
    "CITYNAME_EN": "Rashiduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12040",
    "CITYNAME_AR": "الطويله",
    "CITYNAME_EN": "Altawiluh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12041",
    "CITYNAME_AR": "صعينين",
    "CITYNAME_EN": "Saeinin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12042",
    "CITYNAME_AR": "المندق",
    "CITYNAME_EN": "Al-mandaq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12043",
    "CITYNAME_AR": "المغثه",
    "CITYNAME_EN": "Al-Maghthah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12044",
    "CITYNAME_AR": "القدحه",
    "CITYNAME_EN": "Al-Qadhah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12045",
    "CITYNAME_AR": "ام عمر",
    "CITYNAME_EN": "Umm Omar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12046",
    "CITYNAME_AR": "بنى حريم",
    "CITYNAME_EN": "Bni harim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12047",
    "CITYNAME_AR": "الجماجم والدركه",
    "CITYNAME_EN": "Al-Jamajim and Al-Darkah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12048",
    "CITYNAME_AR": "عنازه والانصب",
    "CITYNAME_EN": "Anazah and Al-Ansab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12049",
    "CITYNAME_AR": "الصفح",
    "CITYNAME_EN": "Al-Safh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12050",
    "CITYNAME_AR": "بطيله",
    "CITYNAME_EN": "Batilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12051",
    "CITYNAME_AR": "الفصيلة",
    "CITYNAME_EN": "Al Fasilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12052",
    "CITYNAME_AR": "العيص",
    "CITYNAME_EN": "Al Ais",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12053",
    "CITYNAME_AR": "القبل",
    "CITYNAME_EN": "Al-Qobal",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12054",
    "CITYNAME_AR": "الرخيله",
    "CITYNAME_EN": "Al-Rakhilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12055",
    "CITYNAME_AR": "المحاميد",
    "CITYNAME_EN": "Al-Mahamid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12056",
    "CITYNAME_AR": "السرفه",
    "CITYNAME_EN": "Al-Sarfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12057",
    "CITYNAME_AR": "مولغ والكعامير",
    "CITYNAME_EN": "Muligh and AL-Ka'amir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12058",
    "CITYNAME_AR": "فران وال شبل",
    "CITYNAME_EN": "Feran and Al Shebl ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12059",
    "CITYNAME_AR": "رسباء",
    "CITYNAME_EN": "Rasbaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12060",
    "CITYNAME_AR": "صدر المحاميد",
    "CITYNAME_EN": "Sadr Al-Mahamid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12061",
    "CITYNAME_AR": "قرن ابوالحشحاش والردمه",
    "CITYNAME_EN": "Qarn Abo Al-Hashhash and Al-Radmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12062",
    "CITYNAME_AR": "غدي العياش",
    "CITYNAME_EN": "Ghady Al-Ayash ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12063",
    "CITYNAME_AR": "الزرقان والحصنين",
    "CITYNAME_EN": "Al-Zarqan and Al-Hesnayn ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12064",
    "CITYNAME_AR": "ال عيفه",
    "CITYNAME_EN": "Al Eifah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12065",
    "CITYNAME_AR": "رمس والدولان",
    "CITYNAME_EN": "Rams and Al-Doulan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12066",
    "CITYNAME_AR": "الحبشه",
    "CITYNAME_EN": "Al-Habshah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12067",
    "CITYNAME_AR": "الريحان",
    "CITYNAME_EN": "Al-Rihan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12068",
    "CITYNAME_AR": "بدادا",
    "CITYNAME_EN": "Badada ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12069",
    "CITYNAME_AR": "ال نعمه",
    "CITYNAME_EN": "Al Ne'mah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12070",
    "CITYNAME_AR": "العدفه",
    "CITYNAME_EN": "Al-Adfah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12071",
    "CITYNAME_AR": "الكاحله",
    "CITYNAME_EN": "Al-Kahilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12072",
    "CITYNAME_AR": "الحراء",
    "CITYNAME_EN": "Al-Haraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12073",
    "CITYNAME_AR": "دوس بني فهم (بني يزيد)",
    "CITYNAME_EN": "Bani Yazid",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12074",
    "CITYNAME_AR": "الحوشبيه",
    "CITYNAME_EN": "Al-Houshbiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12075",
    "CITYNAME_AR": "الحصحص",
    "CITYNAME_EN": "Al-Hashas",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12076",
    "CITYNAME_AR": "البيضاني",
    "CITYNAME_EN": "Al-Baydany ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12077",
    "CITYNAME_AR": "حضوه",
    "CITYNAME_EN": "hadwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12078",
    "CITYNAME_AR": "الكورس",
    "CITYNAME_EN": "Al-Kurs ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12079",
    "CITYNAME_AR": "سلامان",
    "CITYNAME_EN": "Salaman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12080",
    "CITYNAME_AR": "قريده والقعره",
    "CITYNAME_EN": "Qaridah and Al-Aqrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12081",
    "CITYNAME_AR": "زعنه",
    "CITYNAME_EN": "Za'nah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12082",
    "CITYNAME_AR": "الوكف",
    "CITYNAME_EN": "Al-Wakf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12083",
    "CITYNAME_AR": "عمضان",
    "CITYNAME_EN": "Emdan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12084",
    "CITYNAME_AR": "القامه",
    "CITYNAME_EN": "Al-Qamah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12085",
    "CITYNAME_AR": "الهده",
    "CITYNAME_EN": "Al-Heda",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12086",
    "CITYNAME_AR": "الغرير",
    "CITYNAME_EN": "Al-Gharir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12087",
    "CITYNAME_AR": "عويره",
    "CITYNAME_EN": "Owayrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12088",
    "CITYNAME_AR": "برحرح السوق والجحاف",
    "CITYNAME_EN": "Barahrah Al-Souq and Al-Jehaf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12089",
    "CITYNAME_AR": "الهره",
    "CITYNAME_EN": "Al Harrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12090",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al-Qa'rah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12091",
    "CITYNAME_AR": "المقارنه",
    "CITYNAME_EN": "Al-Moqaranah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12092",
    "CITYNAME_AR": "قرا الظهر",
    "CITYNAME_EN": "Qera Al-Zohr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12093",
    "CITYNAME_AR": "العشوه",
    "CITYNAME_EN": "Al-Ashwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12094",
    "CITYNAME_AR": "الصماء",
    "CITYNAME_EN": "Al-Samaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12095",
    "CITYNAME_AR": "السلاطين ودليم",
    "CITYNAME_EN": "Al-Slatin and Dalim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12096",
    "CITYNAME_AR": "الكاحدين",
    "CITYNAME_EN": "Al-Kahdin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12097",
    "CITYNAME_AR": "غرابه",
    "CITYNAME_EN": "Gharabah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12098",
    "CITYNAME_AR": "سيحان",
    "CITYNAME_EN": "Sihan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12099",
    "CITYNAME_AR": "الثوده",
    "CITYNAME_EN": "Al-Thawdah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12100",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12101",
    "CITYNAME_AR": "ابن عرد الغربة",
    "CITYNAME_EN": "Ibn Erd al-Ghorbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12102",
    "CITYNAME_AR": "الحبوا",
    "CITYNAME_EN": "Al-Habwa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12103",
    "CITYNAME_AR": "العراق والحويه",
    "CITYNAME_EN": "Al-Iraq and Al-Hubah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12104",
    "CITYNAME_AR": "الفصيله وال مسعود",
    "CITYNAME_EN": "Al-Fasilah and AL Masoud ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12105",
    "CITYNAME_AR": "الوهسه والقناه",
    "CITYNAME_EN": "Al-Wahsah and Al-Qanah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12106",
    "CITYNAME_AR": "الخاجه",
    "CITYNAME_EN": "Al-Khajah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12107",
    "CITYNAME_AR": "الحدبان",
    "CITYNAME_EN": "Al Hadban ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12108",
    "CITYNAME_AR": "ظهرالقرى",
    "CITYNAME_EN": "Zahr Al-Qora ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12109",
    "CITYNAME_AR": "الفارعه",
    "CITYNAME_EN": "Al Fara'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12110",
    "CITYNAME_AR": "العذبه",
    "CITYNAME_EN": "Al-Azbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12111",
    "CITYNAME_AR": "الجبور",
    "CITYNAME_EN": "Al-Jabur",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12112",
    "CITYNAME_AR": "مدهاس",
    "CITYNAME_EN": "Medhas ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12113",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "Al-Sahlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12114",
    "CITYNAME_AR": "السحير",
    "CITYNAME_EN": "Al-Sahir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12115",
    "CITYNAME_AR": "افيعيا",
    "CITYNAME_EN": "Afyeia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12116",
    "CITYNAME_AR": "ام قويفات",
    "CITYNAME_EN": "Umm Quayfat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12117",
    "CITYNAME_AR": "بدايع الدفينة",
    "CITYNAME_EN": "Badayie Aldafina",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12118",
    "CITYNAME_AR": "الدفينة",
    "CITYNAME_EN": "Aldafina",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12119",
    "CITYNAME_AR": "ضرغط",
    "CITYNAME_EN": "Darghat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12120",
    "CITYNAME_AR": "الطنيب",
    "CITYNAME_EN": "Altanib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12121",
    "CITYNAME_AR": "وسعة ام قذلة",
    "CITYNAME_EN": "Wasaeat Umm Qadhala",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12122",
    "CITYNAME_AR": "الشقه",
    "CITYNAME_EN": "Alshaquh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12123",
    "CITYNAME_AR": "المصع",
    "CITYNAME_EN": "Almasaeu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12124",
    "CITYNAME_AR": "شعيلا المصع",
    "CITYNAME_EN": "Shaeilaan Almasaeu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12125",
    "CITYNAME_AR": "انبوان",
    "CITYNAME_EN": "Ainbiwan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12126",
    "CITYNAME_AR": "العكيفيه",
    "CITYNAME_EN": "Aleakifiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12127",
    "CITYNAME_AR": "بدائع معيلي",
    "CITYNAME_EN": "Badayie Mueili",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12128",
    "CITYNAME_AR": "البدنه",
    "CITYNAME_EN": "Albadanuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12129",
    "CITYNAME_AR": "الحوصه",
    "CITYNAME_EN": "Alhawsuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12130",
    "CITYNAME_AR": "العاند",
    "CITYNAME_EN": "Aleanid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12131",
    "CITYNAME_AR": "العطيفيه",
    "CITYNAME_EN": "Aleatifiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12132",
    "CITYNAME_AR": "بدائع الفقي",
    "CITYNAME_EN": "Bdayie Alfqyi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12133",
    "CITYNAME_AR": "سناف تركي",
    "CITYNAME_EN": "Sanaf Trky",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12134",
    "CITYNAME_AR": "بدائع الخبراء",
    "CITYNAME_EN": "Bdayie Alkhubara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12135",
    "CITYNAME_AR": "دحوة الضغن",
    "CITYNAME_EN": "Dahwat Aldaghn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12136",
    "CITYNAME_AR": "الشعيلا",
    "CITYNAME_EN": "Alshaeila",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12137",
    "CITYNAME_AR": "الفقي",
    "CITYNAME_EN": "Alfqy",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12138",
    "CITYNAME_AR": "بعيجان",
    "CITYNAME_EN": "Bieayjan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12139",
    "CITYNAME_AR": "أم عرنه",
    "CITYNAME_EN": "Umm Earanah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12140",
    "CITYNAME_AR": "دحله الزبد",
    "CITYNAME_EN": "Dahlah Alzabad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12141",
    "CITYNAME_AR": "الشقران",
    "CITYNAME_EN": "Alshuqran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12142",
    "CITYNAME_AR": "القهد",
    "CITYNAME_EN": "Alqahd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12143",
    "CITYNAME_AR": "فيضه الاشهب",
    "CITYNAME_EN": "Fiduh Alashhab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12144",
    "CITYNAME_AR": "بدائع العمير",
    "CITYNAME_EN": "Badayie Aleamir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12145",
    "CITYNAME_AR": "  الفجه",
    "CITYNAME_EN": "Aldilaf Alfajah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12146",
    "CITYNAME_AR": "المحجره",
    "CITYNAME_EN": "Almuhjaruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12147",
    "CITYNAME_AR": "ضريغط",
    "CITYNAME_EN": "Daright",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12148",
    "CITYNAME_AR": "ام العمر",
    "CITYNAME_EN": "Umm Aleumr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12149",
    "CITYNAME_AR": "الوسيطا (وسيطاء الحسو)",
    "CITYNAME_EN": "Alwasita (Wsyta' Alhusw)",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12150",
    "CITYNAME_AR": "الصخنه",
    "CITYNAME_EN": "Alsakhnuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12151",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Albahruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12152",
    "CITYNAME_AR": "الحميمه",
    "CITYNAME_EN": "Alhamimuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12153",
    "CITYNAME_AR": "محير الميركه",
    "CITYNAME_EN": "Muhir Almirikih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12154",
    "CITYNAME_AR": "الرديهه",
    "CITYNAME_EN": "Alradihuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12155",
    "CITYNAME_AR": "القرون",
    "CITYNAME_EN": "Alqurun",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12156",
    "CITYNAME_AR": "السناف",
    "CITYNAME_EN": "Alsanaf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12157",
    "CITYNAME_AR": "ام حبل",
    "CITYNAME_EN": "Umm Habl",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12158",
    "CITYNAME_AR": "كمب ابن براك",
    "CITYNAME_EN": "Camp  Abn Birak",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12159",
    "CITYNAME_AR": "الدحوه",
    "CITYNAME_EN": "Alduhuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12160",
    "CITYNAME_AR": "الاشقر",
    "CITYNAME_EN": "Alashqir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12161",
    "CITYNAME_AR": "عصماء",
    "CITYNAME_EN": "Easama'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12162",
    "CITYNAME_AR": "خبراء البويب  مزارع البويب",
    "CITYNAME_EN": "Khubara' Albuayb Farms  Albuib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12163",
    "CITYNAME_AR": "شقة البويب",
    "CITYNAME_EN": "Shiqa Albuib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12164",
    "CITYNAME_AR": "رياض البويب",
    "CITYNAME_EN": "Riad Albuib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12165",
    "CITYNAME_AR": "بدائع هضبة البويب",
    "CITYNAME_EN": "Badayie Hadbat Albuib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12166",
    "CITYNAME_AR": "ارينبه",
    "CITYNAME_EN": "Arynabah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12167",
    "CITYNAME_AR": "الضحايا",
    "CITYNAME_EN": "Aldahaya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12168",
    "CITYNAME_AR": "البويب",
    "CITYNAME_EN": "Albawib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12169",
    "CITYNAME_AR": "ام زريبه",
    "CITYNAME_EN": "Umm Zaribuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12170",
    "CITYNAME_AR": "جليف مشرفه",
    "CITYNAME_EN": "Jalif Musharafah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12171",
    "CITYNAME_AR": "روض  بن هادي",
    "CITYNAME_EN": "Rwd Bin Hadi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12172",
    "CITYNAME_AR": "العسافيه",
    "CITYNAME_EN": "Aleasafih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12173",
    "CITYNAME_AR": "البياضه",
    "CITYNAME_EN": "Albiaduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12174",
    "CITYNAME_AR": "اشهب الدميثا",
    "CITYNAME_EN": "Ashhab Aldamitha",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12175",
    "CITYNAME_AR": "اللبانه",
    "CITYNAME_EN": "Allibanah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12176",
    "CITYNAME_AR": "الضاحيه",
    "CITYNAME_EN": "Aldaahih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12177",
    "CITYNAME_AR": "العماير",
    "CITYNAME_EN": "Aleamayir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12178",
    "CITYNAME_AR": "صور المقوعى",
    "CITYNAME_EN": "Sur Almuqueaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12179",
    "CITYNAME_AR": "مرجع الروض",
    "CITYNAME_EN": "Marjie Alruwd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12180",
    "CITYNAME_AR": "المثواه القديمة",
    "CITYNAME_EN": "Almathwah Alqadima",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12181",
    "CITYNAME_AR": "قصير شامان",
    "CITYNAME_EN": "Qasir Shaman",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12182",
    "CITYNAME_AR": "الخفج",
    "CITYNAME_EN": "Alkhafj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12183",
    "CITYNAME_AR": "حلية الدحول",
    "CITYNAME_EN": "H̱lyh Ad Dẖwl",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12184",
    "CITYNAME_AR": "شقة الفيده",
    "CITYNAME_EN": "Shqh Al Fydh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12185",
    "CITYNAME_AR": "بويب الشقه",
    "CITYNAME_EN": "Bwyb Ash Shqh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12186",
    "CITYNAME_AR": "المركوز",
    "CITYNAME_EN": "Al Mrkwz",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12187",
    "CITYNAME_AR": "بدائع البويب",
    "CITYNAME_EN": "Bdāʼʻ Al Bwyb",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12188",
    "CITYNAME_AR": "حرة ام نار",
    "CITYNAME_EN": "H̱rh Umm Nār",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12189",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bẖrh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12190",
    "CITYNAME_AR": "طريف",
    "CITYNAME_EN": "Tarif",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12191",
    "CITYNAME_AR": "القعساء",
    "CITYNAME_EN": "Alquesa'",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12192",
    "CITYNAME_AR": "شعيب طريف",
    "CITYNAME_EN": "Shueayb Tarif",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12194",
    "CITYNAME_AR": "الجراني",
    "CITYNAME_EN": "Aljaraniu",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12195",
    "CITYNAME_AR": "دومة الجندل",
    "CITYNAME_EN": "Dumat Al-Jandal",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12200",
    "CITYNAME_AR": "شعبه نصاب",
    "CITYNAME_EN": "Shaebih Nasab",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12201",
    "CITYNAME_AR": "كريم",
    "CITYNAME_EN": "Karim",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12202",
    "CITYNAME_AR": "سماح",
    "CITYNAME_EN": "Samah",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12203",
    "CITYNAME_AR": "نصاب",
    "CITYNAME_EN": "Nsab",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12204",
    "CITYNAME_AR": "ام أرضمه",
    "CITYNAME_EN": "Umm 'Ardamh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12205",
    "CITYNAME_AR": "ابو طوطاحه البرازي",
    "CITYNAME_EN": "Abu Totaha Al-Barazy ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "12209",
    "CITYNAME_AR": "طبرجل",
    "CITYNAME_EN": "Tabarjal",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12220",
    "CITYNAME_AR": "ابن شريم",
    "CITYNAME_EN": "Ibn Sharim",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12221",
    "CITYNAME_AR": "ابن سوقي",
    "CITYNAME_EN": "'Aban Suqi",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12222",
    "CITYNAME_AR": "بادية الطويريق",
    "CITYNAME_EN": "Badiat Altawiriq",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12223",
    "CITYNAME_AR": "العجرميه",
    "CITYNAME_EN": "Aleijramih",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12224",
    "CITYNAME_AR": "قيصومه فيحان",
    "CITYNAME_EN": "Qayasumuh Fayhan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12225",
    "CITYNAME_AR": "لوقة",
    "CITYNAME_EN": "Lawqa",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12226",
    "CITYNAME_AR": "رفحاء",
    "CITYNAME_EN": "Rafha'",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12227",
    "CITYNAME_AR": "المصندق",
    "CITYNAME_EN": "Almusandiq",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12228",
    "CITYNAME_AR": "الحدق",
    "CITYNAME_EN": "Hadaq Aljunduh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12229",
    "CITYNAME_AR": "الرفاع",
    "CITYNAME_EN": "Fuels (Abn Eajal )",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12230",
    "CITYNAME_AR": "هجره عوده الجبهان",
    "CITYNAME_EN": "Hajaruh Euduh Aljubhan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12231",
    "CITYNAME_AR": "زبالا",
    "CITYNAME_EN": "Zibalaan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12232",
    "CITYNAME_AR": "الجميمة",
    "CITYNAME_EN": "Aljamima",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12241",
    "CITYNAME_AR": "الفطيحه",
    "CITYNAME_EN": "Alfatihuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12242",
    "CITYNAME_AR": "الشبيكات",
    "CITYNAME_EN": "Alshabaykat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12243",
    "CITYNAME_AR": "الكحلي",
    "CITYNAME_EN": "Alkahuliu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12244",
    "CITYNAME_AR": "خط ازهر",
    "CITYNAME_EN": "Khata Azhar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12245",
    "CITYNAME_AR": "الرويعي",
    "CITYNAME_EN": "Alruwiei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12246",
    "CITYNAME_AR": "الرجفه",
    "CITYNAME_EN": "Alrajfuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12247",
    "CITYNAME_AR": "نصب العكاس",
    "CITYNAME_EN": "Nusb Aleukaas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12248",
    "CITYNAME_AR": "مرفاص",
    "CITYNAME_EN": "Mirfas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12249",
    "CITYNAME_AR": "دوله",
    "CITYNAME_EN": "Dawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12250",
    "CITYNAME_AR": "الملقا",
    "CITYNAME_EN": "Almalaqa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12251",
    "CITYNAME_AR": "الحيمر   الحوارم",
    "CITYNAME_EN": "Alhaymar Alhawarim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12252",
    "CITYNAME_AR": "جبجب",
    "CITYNAME_EN": "Jabajb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12253",
    "CITYNAME_AR": "ظهره بن ماجور",
    "CITYNAME_EN": "Zahrih Bin Majur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12254",
    "CITYNAME_AR": "غلافه",
    "CITYNAME_EN": "Ghalafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12255",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Almataean",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12256",
    "CITYNAME_AR": "ام دوراء",
    "CITYNAME_EN": "Umm Dawara'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12257",
    "CITYNAME_AR": "الحفول",
    "CITYNAME_EN": "Alhuful",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12258",
    "CITYNAME_AR": "بيض",
    "CITYNAME_EN": "Bid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12259",
    "CITYNAME_AR": "المغصب",
    "CITYNAME_EN": "Almaghsib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12260",
    "CITYNAME_AR": "الكدره",
    "CITYNAME_EN": "Alkadaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12261",
    "CITYNAME_AR": "حلة الشريف",
    "CITYNAME_EN": "Hulat Alsharif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12262",
    "CITYNAME_AR": "مسليه",
    "CITYNAME_EN": "Misilih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12263",
    "CITYNAME_AR": "سر الذيب",
    "CITYNAME_EN": "Siri Aldhiyb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12264",
    "CITYNAME_AR": "ابو الشوك",
    "CITYNAME_EN": "'Abu Alshuk",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12265",
    "CITYNAME_AR": "الخبطه",
    "CITYNAME_EN": "Alkhabtuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12266",
    "CITYNAME_AR": "الزنقه",
    "CITYNAME_EN": "Alzanquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12267",
    "CITYNAME_AR": "دمنة القبسه",
    "CITYNAME_EN": "Dimnat Alqabsih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12268",
    "CITYNAME_AR": "الصلب",
    "CITYNAME_EN": "Alsulb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12269",
    "CITYNAME_AR": "الشواجرة",
    "CITYNAME_EN": "Alshawajiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12270",
    "CITYNAME_AR": "الرزنه",
    "CITYNAME_EN": "Alruzunuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12271",
    "CITYNAME_AR": "ام الدفين",
    "CITYNAME_EN": "Umm Aldafin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12272",
    "CITYNAME_AR": "النخري",
    "CITYNAME_EN": "Alnakhri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12273",
    "CITYNAME_AR": "القاهره",
    "CITYNAME_EN": "Alqahiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12274",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhudan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12275",
    "CITYNAME_AR": "المطعن",
    "CITYNAME_EN": "Almataean",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12276",
    "CITYNAME_AR": "حلة بن شديد",
    "CITYNAME_EN": "Hulat Bin Shadid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12277",
    "CITYNAME_AR": "ابوالحشاف",
    "CITYNAME_EN": "Abwalhshaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12278",
    "CITYNAME_AR": "ام المحاند",
    "CITYNAME_EN": "Umm Almuhanad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12279",
    "CITYNAME_AR": "الحجروف",
    "CITYNAME_EN": "Alhajruf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12280",
    "CITYNAME_AR": "ام الصيود",
    "CITYNAME_EN": "Umm Alsuyud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12281",
    "CITYNAME_AR": "حفايا",
    "CITYNAME_EN": "Hafayana",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12282",
    "CITYNAME_AR": "ابوالنور",
    "CITYNAME_EN": "Abwalnur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12283",
    "CITYNAME_AR": "الشجنه",
    "CITYNAME_EN": "Alshajnuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12284",
    "CITYNAME_AR": "كربه",
    "CITYNAME_EN": "Karabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12285",
    "CITYNAME_AR": "قاع الجعارين",
    "CITYNAME_EN": "Qae Aljaearin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12286",
    "CITYNAME_AR": "الشاقه العبادله",
    "CITYNAME_EN": "Alshaqih Aleabadiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12287",
    "CITYNAME_AR": "السهوم",
    "CITYNAME_EN": "Alsuhum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12288",
    "CITYNAME_AR": "الدربه",
    "CITYNAME_EN": "Aldarabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12289",
    "CITYNAME_AR": "الرقاونه",
    "CITYNAME_EN": "Alraqawinuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12290",
    "CITYNAME_AR": "الفوارسه",
    "CITYNAME_EN": "Alfawarisuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12291",
    "CITYNAME_AR": "المجره",
    "CITYNAME_EN": "Almajaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12292",
    "CITYNAME_AR": "المدرك",
    "CITYNAME_EN": "Almudrik",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12293",
    "CITYNAME_AR": "بيش",
    "CITYNAME_EN": "Baysh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12294",
    "CITYNAME_AR": "بيش العليا",
    "CITYNAME_EN": "Baysh Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12295",
    "CITYNAME_AR": "حلة البنايا",
    "CITYNAME_EN": "Hulat Albanaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12296",
    "CITYNAME_AR": "الشريعه",
    "CITYNAME_EN": "Alsharieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12297",
    "CITYNAME_AR": "السعنة",
    "CITYNAME_EN": "Alsaenuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12298",
    "CITYNAME_AR": "المطاره",
    "CITYNAME_EN": "Almataruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12299",
    "CITYNAME_AR": "المنزاله",
    "CITYNAME_EN": "Alminzaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12300",
    "CITYNAME_AR": "قاع الصبايا",
    "CITYNAME_EN": "Qae Alsubaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12301",
    "CITYNAME_AR": "البطنه",
    "CITYNAME_EN": "Albatnuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12302",
    "CITYNAME_AR": "المجديرة المخلاف",
    "CITYNAME_EN": "Almajdirat Almikhlaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12303",
    "CITYNAME_AR": "السلام العليا",
    "CITYNAME_EN": "Alsalam Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12304",
    "CITYNAME_AR": "الميله",
    "CITYNAME_EN": "Almiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12305",
    "CITYNAME_AR": "حله العزامه",
    "CITYNAME_EN": "Halah Aleizamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12306",
    "CITYNAME_AR": "القصاديه",
    "CITYNAME_EN": "Alqasadih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12307",
    "CITYNAME_AR": "الدرب",
    "CITYNAME_EN": "Ad-Darb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12308",
    "CITYNAME_AR": "مثلث الدرب",
    "CITYNAME_EN": "Muthalath Ad-Darb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12309",
    "CITYNAME_AR": "القضب",
    "CITYNAME_EN": "Al Qadab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12310",
    "CITYNAME_AR": "الحروف",
    "CITYNAME_EN": "Al Horuf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12311",
    "CITYNAME_AR": "رملان الدرب",
    "CITYNAME_EN": "Ramlan Ad-Darb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12312",
    "CITYNAME_AR": "الحويه",
    "CITYNAME_EN": "Al Huwaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12313",
    "CITYNAME_AR": "الفوارق",
    "CITYNAME_EN": "Al Fawareq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12314",
    "CITYNAME_AR": "حلة الجبل",
    "CITYNAME_EN": "Helat Al Jabal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12315",
    "CITYNAME_AR": "الكرسه",
    "CITYNAME_EN": "Al Karsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12316",
    "CITYNAME_AR": "سمره الجد",
    "CITYNAME_EN": "Samrah Al Jad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12317",
    "CITYNAME_AR": "ام الحنشان",
    "CITYNAME_EN": "Om Al Hanashan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12318",
    "CITYNAME_AR": "ام الهنود",
    "CITYNAME_EN": "Om Al Honood",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12319",
    "CITYNAME_AR": "ابن شائعه",
    "CITYNAME_EN": "Ibn Shaeah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12320",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Al Ghoreif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12321",
    "CITYNAME_AR": "المحبل",
    "CITYNAME_EN": "Al Mahbal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12322",
    "CITYNAME_AR": "هيجة رملان",
    "CITYNAME_EN": "Hayjat Ramlan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12323",
    "CITYNAME_AR": "ام المحو",
    "CITYNAME_EN": "Om Al Mahu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12324",
    "CITYNAME_AR": "القمحه",
    "CITYNAME_EN": "Al Qumaha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12325",
    "CITYNAME_AR": "ابو الربش (ضبرة قاسم)",
    "CITYNAME_EN": "Abo Ar-Reesh (Dabret Qasem)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12326",
    "CITYNAME_AR": "الكدره",
    "CITYNAME_EN": "Al Kadarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12327",
    "CITYNAME_AR": "القومه",
    "CITYNAME_EN": "Al Qawmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12328",
    "CITYNAME_AR": "جحر",
    "CITYNAME_EN": "Juhr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12329",
    "CITYNAME_AR": "قرار",
    "CITYNAME_EN": "Qarar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12330",
    "CITYNAME_AR": "القائم الاعلى",
    "CITYNAME_EN": "Al Qaem Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12331",
    "CITYNAME_AR": "القائم الاسفل",
    "CITYNAME_EN": "Al Qaem Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12332",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12333",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12334",
    "CITYNAME_AR": "الطايف",
    "CITYNAME_EN": "Taif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12335",
    "CITYNAME_AR": "ابو السداد",
    "CITYNAME_EN": "Abu AlSadad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12336",
    "CITYNAME_AR": "قايم نخب",
    "CITYNAME_EN": "Qaem Nakhab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12337",
    "CITYNAME_AR": "المسامير",
    "CITYNAME_EN": "Hay Al Masamer Hay Al Montazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12338",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Az-Zubarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12339",
    "CITYNAME_AR": "هرمان",
    "CITYNAME_EN": "Herman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12340",
    "CITYNAME_AR": "الرصيفه",
    "CITYNAME_EN": "Ar-Rasefah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12341",
    "CITYNAME_AR": "ابو ثور",
    "CITYNAME_EN": "Abo Thawr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12342",
    "CITYNAME_AR": "المديري",
    "CITYNAME_EN": "Al Muderi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12343",
    "CITYNAME_AR": "ام الكرش",
    "CITYNAME_EN": "Om Al Karsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12344",
    "CITYNAME_AR": "فش البركه كبيش",
    "CITYNAME_EN": "Fash Al Barakah Kabeesh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12345",
    "CITYNAME_AR": "ابويحيى",
    "CITYNAME_EN": "Abo Yahya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12346",
    "CITYNAME_AR": "ريم",
    "CITYNAME_EN": "Reem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12347",
    "CITYNAME_AR": "كادوم",
    "CITYNAME_EN": "Kadum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12348",
    "CITYNAME_AR": "عرج حنشه",
    "CITYNAME_EN": "Araj Hanshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12349",
    "CITYNAME_AR": "ابو التنعيم",
    "CITYNAME_EN": "Abo Al Taneem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12350",
    "CITYNAME_AR": "رقة الشيوخ",
    "CITYNAME_EN": "Reqat Ash-Shyukh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12351",
    "CITYNAME_AR": "ابو الحوار",
    "CITYNAME_EN": "Abo Al Huwar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12352",
    "CITYNAME_AR": "السبله",
    "CITYNAME_EN": "As-Sablah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12353",
    "CITYNAME_AR": "شط القضاب",
    "CITYNAME_EN": "Shat Al Qudab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12354",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12355",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Al Mafreq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12356",
    "CITYNAME_AR": "جندله",
    "CITYNAME_EN": "Jandalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12357",
    "CITYNAME_AR": "المحرقه",
    "CITYNAME_EN": "Al Mahraqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12358",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Al Hano",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12359",
    "CITYNAME_AR": "القسيان",
    "CITYNAME_EN": "Al Qasyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12360",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Aj-Jowar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12361",
    "CITYNAME_AR": "جر مريع",
    "CITYNAME_EN": "Jar Maree",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12362",
    "CITYNAME_AR": "رقة عفف",
    "CITYNAME_EN": "Reqat Afaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12363",
    "CITYNAME_AR": "حضن السميرة",
    "CITYNAME_EN": "Hudn As-Sumayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12364",
    "CITYNAME_AR": "الضمو",
    "CITYNAME_EN": "Ad-Dumu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12365",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al Mesbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12366",
    "CITYNAME_AR": "صدر حريج الكبير",
    "CITYNAME_EN": "Sadr Harej Al Kabeer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12367",
    "CITYNAME_AR": "الاصلاب",
    "CITYNAME_EN": "Al Aslab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12368",
    "CITYNAME_AR": "حم السهاب",
    "CITYNAME_EN": "Ham As-Sehab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12369",
    "CITYNAME_AR": "الشريعه والساق",
    "CITYNAME_EN": "Ash-Shar'ai wa As-Saq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12370",
    "CITYNAME_AR": "وادي المض",
    "CITYNAME_EN": "Wadi Al Mad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12371",
    "CITYNAME_AR": "الغلمه",
    "CITYNAME_EN": "Al Ghalamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12372",
    "CITYNAME_AR": "المجاليل",
    "CITYNAME_EN": "Magalel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12373",
    "CITYNAME_AR": "جمحان",
    "CITYNAME_EN": "Jamjan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12374",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12375",
    "CITYNAME_AR": "مقر",
    "CITYNAME_EN": "Maqar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12376",
    "CITYNAME_AR": "عتود",
    "CITYNAME_EN": "Atoud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12377",
    "CITYNAME_AR": "ماضى",
    "CITYNAME_EN": "Qaa Madi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12378",
    "CITYNAME_AR": "زهوان",
    "CITYNAME_EN": "Zahwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12379",
    "CITYNAME_AR": "منشبه",
    "CITYNAME_EN": "Manshabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12380",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12381",
    "CITYNAME_AR": "السميرات",
    "CITYNAME_EN": "As-Sumayrat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12382",
    "CITYNAME_AR": "سمره",
    "CITYNAME_EN": "Samarah (and its farms)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12383",
    "CITYNAME_AR": "ابو الابرايه",
    "CITYNAME_EN": "'Abu Alabrayh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12384",
    "CITYNAME_AR": "الردحه",
    "CITYNAME_EN": "Alradahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12385",
    "CITYNAME_AR": "الزهراء",
    "CITYNAME_EN": "Al Zahraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "12386",
    "CITYNAME_AR": "العاليه والخضراء",
    "CITYNAME_EN": "Alealiuh Walkhudira'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12387",
    "CITYNAME_AR": "الدهناء",
    "CITYNAME_EN": "Aldahna'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12388",
    "CITYNAME_AR": "المحاصيه",
    "CITYNAME_EN": "Almahasih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12389",
    "CITYNAME_AR": "جميما",
    "CITYNAME_EN": "Jamimaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12390",
    "CITYNAME_AR": "نوره",
    "CITYNAME_EN": "Nurih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12391",
    "CITYNAME_AR": "حنقفه",
    "CITYNAME_EN": "Hanaqfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12392",
    "CITYNAME_AR": "الحوامضه جحيش",
    "CITYNAME_EN": "Alhawamiduh Jahish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12393",
    "CITYNAME_AR": "الزقله",
    "CITYNAME_EN": "Alzaqluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12394",
    "CITYNAME_AR": "ابو غليفه",
    "CITYNAME_EN": "'Abu Ghlyfh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12395",
    "CITYNAME_AR": "النقاش",
    "CITYNAME_EN": "Alniqash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12396",
    "CITYNAME_AR": "خرفوشه",
    "CITYNAME_EN": "Kharfushah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12397",
    "CITYNAME_AR": "السلامه السفلى",
    "CITYNAME_EN": "Alsalamuh Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12398",
    "CITYNAME_AR": "شهده",
    "CITYNAME_EN": "Shahdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12399",
    "CITYNAME_AR": "الكلبه",
    "CITYNAME_EN": "Alkalabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12400",
    "CITYNAME_AR": "الفصاله والدغاريه",
    "CITYNAME_EN": "Alfisaluh Waldagharih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12401",
    "CITYNAME_AR": "الشقافه والمصاوفة",
    "CITYNAME_EN": "Alshiqafuh Walmusawafa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12402",
    "CITYNAME_AR": "الشعافه",
    "CITYNAME_EN": "Alshaeafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12403",
    "CITYNAME_AR": "ابوالمقره",
    "CITYNAME_EN": "Abwalmqrh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12404",
    "CITYNAME_AR": "القصادية",
    "CITYNAME_EN": "Alqasadiuh Aljadiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12405",
    "CITYNAME_AR": "الخشم",
    "CITYNAME_EN": "Alkhashm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12406",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Alhuquw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12407",
    "CITYNAME_AR": "الغمر",
    "CITYNAME_EN": "Alghamar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12408",
    "CITYNAME_AR": "ام حزر",
    "CITYNAME_EN": "Umm Hazar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12409",
    "CITYNAME_AR": "مهدى",
    "CITYNAME_EN": "Mahdaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12410",
    "CITYNAME_AR": "المشبه",
    "CITYNAME_EN": "Almshbh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12411",
    "CITYNAME_AR": "طناطن",
    "CITYNAME_EN": "Tunatin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12412",
    "CITYNAME_AR": "الغاويه",
    "CITYNAME_EN": "Alghawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12413",
    "CITYNAME_AR": "السبطه",
    "CITYNAME_EN": "Alsabtuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12414",
    "CITYNAME_AR": "القنازيز العليا",
    "CITYNAME_EN": "Alqanaziz Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12415",
    "CITYNAME_AR": "القنازيز السفلى",
    "CITYNAME_EN": "Alqanaziz Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12416",
    "CITYNAME_AR": "العيدابى",
    "CITYNAME_EN": "Aleaydabaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12417",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12418",
    "CITYNAME_AR": "ام الحنشان",
    "CITYNAME_EN": "Umm Alhinshan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12419",
    "CITYNAME_AR": "كرس  قمامه",
    "CITYNAME_EN": "Karas Qamamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12420",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Allasabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12421",
    "CITYNAME_AR": "الملاحي",
    "CITYNAME_EN": "Almalahi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12422",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12423",
    "CITYNAME_AR": "القوام",
    "CITYNAME_EN": "Alqawam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12424",
    "CITYNAME_AR": "اسفل وادي وعال",
    "CITYNAME_EN": "'Asfal Wadi Waeal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12425",
    "CITYNAME_AR": "مرمده",
    "CITYNAME_EN": "Murmadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12426",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Alraqabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12427",
    "CITYNAME_AR": "الشخرى",
    "CITYNAME_EN": "Alshukhraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12428",
    "CITYNAME_AR": "المطفاه",
    "CITYNAME_EN": "Almatafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12429",
    "CITYNAME_AR": "القانصيه",
    "CITYNAME_EN": "Alqanisih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12430",
    "CITYNAME_AR": "ابوالسراياء",
    "CITYNAME_EN": "Abwalsraya'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12431",
    "CITYNAME_AR": "المنصع",
    "CITYNAME_EN": "Almansie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12432",
    "CITYNAME_AR": "حوجه",
    "CITYNAME_EN": "Hawjih (Qaayim 'Abu Alsamra'A)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12433",
    "CITYNAME_AR": "ابو الكرش",
    "CITYNAME_EN": "'Abu Alkarsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12434",
    "CITYNAME_AR": "قوز الجعافره",
    "CITYNAME_EN": "Quz Aljaeafirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12435",
    "CITYNAME_AR": "العواجمه",
    "CITYNAME_EN": "Aleawajimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12436",
    "CITYNAME_AR": "وجه الحسن",
    "CITYNAME_EN": "Wajah Alhasan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12437",
    "CITYNAME_AR": "العرضه",
    "CITYNAME_EN": "Alearduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12438",
    "CITYNAME_AR": "البطح",
    "CITYNAME_EN": "Albatih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12439",
    "CITYNAME_AR": "السداده",
    "CITYNAME_EN": "Alsidaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12440",
    "CITYNAME_AR": "النزلـــه",
    "CITYNAME_EN": "Alnzlh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12441",
    "CITYNAME_AR": "عوانه",
    "CITYNAME_EN": "Eawanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12442",
    "CITYNAME_AR": "العبده",
    "CITYNAME_EN": "Aleabduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12443",
    "CITYNAME_AR": "ابو الطيور",
    "CITYNAME_EN": "'Abu Altuyur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12444",
    "CITYNAME_AR": "الاثله",
    "CITYNAME_EN": "Alathlh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12445",
    "CITYNAME_AR": "ام العرش",
    "CITYNAME_EN": "Umm Alearsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12446",
    "CITYNAME_AR": "الصنيف",
    "CITYNAME_EN": "Alsanif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12447",
    "CITYNAME_AR": "الحمضيه",
    "CITYNAME_EN": "Alhimdih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12448",
    "CITYNAME_AR": "السبخه",
    "CITYNAME_EN": "Alsabkhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12449",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12450",
    "CITYNAME_AR": "المجديره",
    "CITYNAME_EN": "Almajdiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12451",
    "CITYNAME_AR": "حلة ابو حبيبه",
    "CITYNAME_EN": "Hulat 'Abu Habibuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12452",
    "CITYNAME_AR": "الخوالده",
    "CITYNAME_EN": "Alkhawaliduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12453",
    "CITYNAME_AR": "الزيديه",
    "CITYNAME_EN": "Alzaydiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12454",
    "CITYNAME_AR": "الحجرين",
    "CITYNAME_EN": "Alhajarayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12455",
    "CITYNAME_AR": "جريبه",
    "CITYNAME_EN": "Jaribuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12456",
    "CITYNAME_AR": "زربه",
    "CITYNAME_EN": "Zarabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12457",
    "CITYNAME_AR": "الهدوى",
    "CITYNAME_EN": "Alhadwaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12458",
    "CITYNAME_AR": "الحقاويه",
    "CITYNAME_EN": "Alhaqawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12459",
    "CITYNAME_AR": "العسيلة",
    "CITYNAME_EN": "Aleasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12460",
    "CITYNAME_AR": "الرجيع",
    "CITYNAME_EN": "Alrajie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12461",
    "CITYNAME_AR": "حلة ابراهيم عيسى",
    "CITYNAME_EN": "Hulat 'Iibrahim Eisaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12462",
    "CITYNAME_AR": "الكومه",
    "CITYNAME_EN": "Alkawmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12463",
    "CITYNAME_AR": "القفل",
    "CITYNAME_EN": "Alqafl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12464",
    "CITYNAME_AR": "ابو الكدش",
    "CITYNAME_EN": "'Abu Alkadsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12465",
    "CITYNAME_AR": "الدعيمه",
    "CITYNAME_EN": "Aldaeimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12466",
    "CITYNAME_AR": "ام التراب",
    "CITYNAME_EN": "Umm Alturab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12467",
    "CITYNAME_AR": "ام الشيح",
    "CITYNAME_EN": "Umm Alshiyh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12468",
    "CITYNAME_AR": "قائم الجرده",
    "CITYNAME_EN": "Qayim Aljaradih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12469",
    "CITYNAME_AR": "السودي",
    "CITYNAME_EN": "Alsuwdiu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12470",
    "CITYNAME_AR": "المطقطقه",
    "CITYNAME_EN": "Almutqatiquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12471",
    "CITYNAME_AR": "ابو ذيل",
    "CITYNAME_EN": "'Abu Dhil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12472",
    "CITYNAME_AR": "الكدره",
    "CITYNAME_EN": "Alkadaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12473",
    "CITYNAME_AR": "الروضة",
    "CITYNAME_EN": "Alruwda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12474",
    "CITYNAME_AR": "القائم العلوى",
    "CITYNAME_EN": "Alqayim Aleulwaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12475",
    "CITYNAME_AR": "منبوقه",
    "CITYNAME_EN": "Manbuquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12476",
    "CITYNAME_AR": "المحانش السفلى",
    "CITYNAME_EN": "Almuhanish Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12477",
    "CITYNAME_AR": "المحانش العليا",
    "CITYNAME_EN": "Almuhanish Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12478",
    "CITYNAME_AR": "الرماده",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12479",
    "CITYNAME_AR": "دندف",
    "CITYNAME_EN": "Danduf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12480",
    "CITYNAME_AR": "كدرة اللقيه",
    "CITYNAME_EN": "Kudrat Allaqih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12481",
    "CITYNAME_AR": "اللقيه",
    "CITYNAME_EN": "Allaqih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12482",
    "CITYNAME_AR": "شعب ال دهمي",
    "CITYNAME_EN": "Shaeb Al Dahimi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12483",
    "CITYNAME_AR": "الحقلة الشماليه",
    "CITYNAME_EN": "Alhuqlat Alshamaliuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12484",
    "CITYNAME_AR": "قائم اللقيه",
    "CITYNAME_EN": "Qayim Allaqih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12485",
    "CITYNAME_AR": "المواقده",
    "CITYNAME_EN": "Almawaqiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12486",
    "CITYNAME_AR": "القره",
    "CITYNAME_EN": "Alqaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12487",
    "CITYNAME_AR": "محجن",
    "CITYNAME_EN": "Mahjin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12488",
    "CITYNAME_AR": "الضلعه",
    "CITYNAME_EN": "Aldalaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12489",
    "CITYNAME_AR": "الزبادى",
    "CITYNAME_EN": "Alzabadaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12490",
    "CITYNAME_AR": "ام العود",
    "CITYNAME_EN": "Umm Aleawd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12491",
    "CITYNAME_AR": "العطايا",
    "CITYNAME_EN": "Aleitaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12492",
    "CITYNAME_AR": "ابو الرديف",
    "CITYNAME_EN": "'Abu Alradif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12493",
    "CITYNAME_AR": "الروايا",
    "CITYNAME_EN": "Alrawaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12494",
    "CITYNAME_AR": "ابو النبعه",
    "CITYNAME_EN": "'Abu Alnabeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12495",
    "CITYNAME_AR": "أم الشبرق",
    "CITYNAME_EN": "'Ama Alshabriq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12496",
    "CITYNAME_AR": "الجبوح",
    "CITYNAME_EN": "Aljubuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12497",
    "CITYNAME_AR": "المعزاب",
    "CITYNAME_EN": "Almuezab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12498",
    "CITYNAME_AR": "الصيابه",
    "CITYNAME_EN": "Alsiyabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12499",
    "CITYNAME_AR": "الكرس",
    "CITYNAME_EN": "Alkaras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12500",
    "CITYNAME_AR": "ابو الطفايه",
    "CITYNAME_EN": "'Abu Altafaayuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12501",
    "CITYNAME_AR": "الشاجن",
    "CITYNAME_EN": "Alshaajin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12502",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Almuetarad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12503",
    "CITYNAME_AR": "خلفه الساده",
    "CITYNAME_EN": "Khalfih Alsaaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12504",
    "CITYNAME_AR": "المجروب",
    "CITYNAME_EN": "Almajrub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12505",
    "CITYNAME_AR": "أبو العدنه   ام القطب السفلى",
    "CITYNAME_EN": "Abu Aleadnih Umm Alqutb Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12506",
    "CITYNAME_AR": "الحقله الجنوبيه",
    "CITYNAME_EN": "Alhaqluh Aljanubiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12507",
    "CITYNAME_AR": "شاجن العطايا",
    "CITYNAME_EN": "Shajin Aleitaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12508",
    "CITYNAME_AR": "الواسطه",
    "CITYNAME_EN": "Alwasituh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12509",
    "CITYNAME_AR": "السرداح",
    "CITYNAME_EN": "Alsirdah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12510",
    "CITYNAME_AR": "خضيره",
    "CITYNAME_EN": "Khadiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12511",
    "CITYNAME_AR": "ام القطب",
    "CITYNAME_EN": "Umm Alqutb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12512",
    "CITYNAME_AR": "الحمرا",
    "CITYNAME_EN": "Alhamra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12513",
    "CITYNAME_AR": "الوتد",
    "CITYNAME_EN": "Alwatd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12514",
    "CITYNAME_AR": "المعيد",
    "CITYNAME_EN": "Hzae Bin Saeid",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12515",
    "CITYNAME_AR": "ام خنصر",
    "CITYNAME_EN": "Umm Khansur",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12516",
    "CITYNAME_AR": "السليمانية",
    "CITYNAME_EN": "Alsulaymania",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12517",
    "CITYNAME_AR": "المركوز ويشمل",
    "CITYNAME_EN": "Almarkuz Wayashmal",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12518",
    "CITYNAME_AR": "العويقيله",
    "CITYNAME_EN": "Aleuyqilh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12519",
    "CITYNAME_AR": "الخر",
    "CITYNAME_EN": "Alkharu Hajaruh Bin Eaysh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12520",
    "CITYNAME_AR": "الصحن",
    "CITYNAME_EN": "Alsahn",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12521",
    "CITYNAME_AR": "الدويد",
    "CITYNAME_EN": "Aldawid",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12522",
    "CITYNAME_AR": "الكاسب",
    "CITYNAME_EN": "Alkasib",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12523",
    "CITYNAME_AR": "ابورواث",
    "CITYNAME_EN": "Abwrwath",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12524",
    "CITYNAME_AR": "هجرة السلـمانية",
    "CITYNAME_EN": "Hijrat Alsalmania",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12525",
    "CITYNAME_AR": "الهبكه",
    "CITYNAME_EN": "Alhabakuh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12526",
    "CITYNAME_AR": "ساده الخر   نعيجان",
    "CITYNAME_EN": "Sadah Alkharu Nueayjan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12527",
    "CITYNAME_AR": "زهوه",
    "CITYNAME_EN": "Zahuh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12528",
    "CITYNAME_AR": "شمقل بن رمال (الايديه)",
    "CITYNAME_EN": "Shamqil Bin Rimal (Aliaydih)",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12529",
    "CITYNAME_AR": "الجبيلي (المكمن)",
    "CITYNAME_EN": "Aljabiliu (Almkman)",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12532",
    "CITYNAME_AR": "سكاكا",
    "CITYNAME_EN": "Sakakah",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12536",
    "CITYNAME_AR": "القريات",
    "CITYNAME_EN": "Qurayyat",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12548",
    "CITYNAME_AR": "العيساويه",
    "CITYNAME_EN": "Al Issawiyah",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12569",
    "CITYNAME_AR": "ابيط",
    "CITYNAME_EN": "Abyt",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12570",
    "CITYNAME_AR": "العسافيه",
    "CITYNAME_EN": "Aleasafih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12571",
    "CITYNAME_AR": "ثليثوه",
    "CITYNAME_EN": "Thalithuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12572",
    "CITYNAME_AR": "ساحوت",
    "CITYNAME_EN": "Sahut",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12573",
    "CITYNAME_AR": "الظبية",
    "CITYNAME_EN": "Alzibiya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "12574",
    "CITYNAME_AR": "الجهراء القديمه",
    "CITYNAME_EN": "Aljuhara' Alqadimuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12575",
    "CITYNAME_AR": "شرعان",
    "CITYNAME_EN": "Shar'an",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12576",
    "CITYNAME_AR": "البلي",
    "CITYNAME_EN": "Al Beli",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12577",
    "CITYNAME_AR": "بواء",
    "CITYNAME_EN": "Bawa'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12578",
    "CITYNAME_AR": "تيماء",
    "CITYNAME_EN": "Tima'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12579",
    "CITYNAME_AR": "السنانيات",
    "CITYNAME_EN": "Alsnaniat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12580",
    "CITYNAME_AR": "الكتيب",
    "CITYNAME_EN": "Alkatib",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12581",
    "CITYNAME_AR": "عرده",
    "CITYNAME_EN": "Earadah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12582",
    "CITYNAME_AR": "الجبعاوية (جريش)",
    "CITYNAME_EN": "Aljbeawy (Jrysh)",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12583",
    "CITYNAME_AR": "الجبعاوية",
    "CITYNAME_EN": "Aljbeawi",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12584",
    "CITYNAME_AR": "مديسيس",
    "CITYNAME_EN": "Mudisis",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12585",
    "CITYNAME_AR": "قرية الجديد",
    "CITYNAME_EN": " Aljadid Village ",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12586",
    "CITYNAME_AR": "قاره الحيران",
    "CITYNAME_EN": "Qarah Alhayran",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12587",
    "CITYNAME_AR": "املج",
    "CITYNAME_EN": "Amlij",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12588",
    "CITYNAME_AR": "المقرح",
    "CITYNAME_EN": "Almuqarah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12589",
    "CITYNAME_AR": "المحجر ومزارعه",
    "CITYNAME_EN": "Almuhjir Wafarms Uh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12590",
    "CITYNAME_AR": "الواسطه",
    "CITYNAME_EN": "Alwasituh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12591",
    "CITYNAME_AR": "الخزان",
    "CITYNAME_EN": "Alkhazan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12592",
    "CITYNAME_AR": "الزاويه",
    "CITYNAME_EN": "Alzaawih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12593",
    "CITYNAME_AR": "ام حمضه",
    "CITYNAME_EN": "Umm Hamduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12594",
    "CITYNAME_AR": "ام مرايغ",
    "CITYNAME_EN": "Umm Marayigh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12595",
    "CITYNAME_AR": "الصميدات",
    "CITYNAME_EN": "Alsamidat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12596",
    "CITYNAME_AR": "المطيوى",
    "CITYNAME_EN": "Almutaywaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12597",
    "CITYNAME_AR": "ام رتب (سيح العقيبيه)",
    "CITYNAME_EN": "Umm Ratab (Syah Aleaqibih)",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12598",
    "CITYNAME_AR": "القرص",
    "CITYNAME_EN": "Alqurs",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12599",
    "CITYNAME_AR": "الدقم",
    "CITYNAME_EN": "Aldaqum",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12600",
    "CITYNAME_AR": "السمح",
    "CITYNAME_EN": "Alsamah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12601",
    "CITYNAME_AR": "الحرة الشماليه",
    "CITYNAME_EN": "Alharuh Alshamaliuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12602",
    "CITYNAME_AR": "النعيله",
    "CITYNAME_EN": "Alnaeiluh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12603",
    "CITYNAME_AR": "الباطن",
    "CITYNAME_EN": "Albatn",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12604",
    "CITYNAME_AR": "النصبه",
    "CITYNAME_EN": "Alnasbuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12605",
    "CITYNAME_AR": "بئر فهي",
    "CITYNAME_EN": "Bayir Fahi",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12606",
    "CITYNAME_AR": "الشدخ",
    "CITYNAME_EN": "Alshadakh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12607",
    "CITYNAME_AR": "القواق",
    "CITYNAME_EN": "Alqiwaq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12608",
    "CITYNAME_AR": "حاره الشيخ صبيح",
    "CITYNAME_EN": "Harih Alshaykh Sabih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12609",
    "CITYNAME_AR": "مصروره",
    "CITYNAME_EN": "Masruruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12610",
    "CITYNAME_AR": "مجيرمه",
    "CITYNAME_EN": "Mujyramuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12611",
    "CITYNAME_AR": "قصر دحيلان",
    "CITYNAME_EN": "Qasr Dahilan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12612",
    "CITYNAME_AR": "ام سلمه",
    "CITYNAME_EN": "Umm Salamah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12613",
    "CITYNAME_AR": "سمر",
    "CITYNAME_EN": "Samar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12614",
    "CITYNAME_AR": "العنبجه",
    "CITYNAME_EN": "Aleanbajuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12615",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12616",
    "CITYNAME_AR": "صيخان",
    "CITYNAME_EN": "Saykhan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12617",
    "CITYNAME_AR": "معيدي",
    "CITYNAME_EN": "Mueidi",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12618",
    "CITYNAME_AR": "البغث",
    "CITYNAME_EN": "Albaghath",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12619",
    "CITYNAME_AR": "مرخ",
    "CITYNAME_EN": "Marakh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12620",
    "CITYNAME_AR": "فشيغ",
    "CITYNAME_EN": "Fashigh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12621",
    "CITYNAME_AR": "ام السينات",
    "CITYNAME_EN": "Umm Alsayinat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12622",
    "CITYNAME_AR": "الاشقم",
    "CITYNAME_EN": "Alashqm",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12623",
    "CITYNAME_AR": "بئر مسعود",
    "CITYNAME_EN": "Biir Maseud",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12624",
    "CITYNAME_AR": "مرس",
    "CITYNAME_EN": "Miras",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12625",
    "CITYNAME_AR": "ام نتش",
    "CITYNAME_EN": "Umm Natash",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12626",
    "CITYNAME_AR": "المعرص",
    "CITYNAME_EN": "Almaeris",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12627",
    "CITYNAME_AR": "ابن غنيم",
    "CITYNAME_EN": "Abn Ghanim",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12628",
    "CITYNAME_AR": "ريمه",
    "CITYNAME_EN": "Rymah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12629",
    "CITYNAME_AR": "الحايل الأعلى",
    "CITYNAME_EN": "Alhayil Al'Aelaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12630",
    "CITYNAME_AR": "ذرفي",
    "CITYNAME_EN": "Dhirfi",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12631",
    "CITYNAME_AR": "الاسيله",
    "CITYNAME_EN": "Alasylh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12632",
    "CITYNAME_AR": "حيران",
    "CITYNAME_EN": "Hayran",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12633",
    "CITYNAME_AR": "الشبعان",
    "CITYNAME_EN": "Alshubean",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12634",
    "CITYNAME_AR": "البوانه",
    "CITYNAME_EN": "Albawanuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12635",
    "CITYNAME_AR": "السطيح",
    "CITYNAME_EN": "Alsatih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12636",
    "CITYNAME_AR": "أم فارس",
    "CITYNAME_EN": "Umm Faris",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12637",
    "CITYNAME_AR": "رحى الشمالية",
    "CITYNAME_EN": "Rahaa Alshamalia",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12638",
    "CITYNAME_AR": "عمق",
    "CITYNAME_EN": "Eumq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12639",
    "CITYNAME_AR": "توله",
    "CITYNAME_EN": "Tawalah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12640",
    "CITYNAME_AR": "بئر حمدان النمس",
    "CITYNAME_EN": "Bayir Hamdan Alnams",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12641",
    "CITYNAME_AR": "عاقر الحصان",
    "CITYNAME_EN": "Eaqir Alhisan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12642",
    "CITYNAME_AR": "كثان",
    "CITYNAME_EN": "Kathan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12643",
    "CITYNAME_AR": "حنيق",
    "CITYNAME_EN": "Haniq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12644",
    "CITYNAME_AR": "ثال",
    "CITYNAME_EN": "Thal",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12645",
    "CITYNAME_AR": "النجيل",
    "CITYNAME_EN": "Alnajil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12646",
    "CITYNAME_AR": "الخلخول",
    "CITYNAME_EN": "Alkhalkhul",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12647",
    "CITYNAME_AR": "الخريم",
    "CITYNAME_EN": "Alkharim",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12648",
    "CITYNAME_AR": "صروم",
    "CITYNAME_EN": "Sarum",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12649",
    "CITYNAME_AR": "الحسى",
    "CITYNAME_EN": "Alhusaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12650",
    "CITYNAME_AR": "الحائل",
    "CITYNAME_EN": "Alhayil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12651",
    "CITYNAME_AR": "زحل",
    "CITYNAME_EN": "Zuhal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12652",
    "CITYNAME_AR": "نبط",
    "CITYNAME_EN": "Nabt",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12653",
    "CITYNAME_AR": "ابيار النجل",
    "CITYNAME_EN": "Abyar An-Najl",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12654",
    "CITYNAME_AR": "المعيزله",
    "CITYNAME_EN": "Al Moayzalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "12655",
    "CITYNAME_AR": "ضباء",
    "CITYNAME_EN": "Daba'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12656",
    "CITYNAME_AR": "الغال",
    "CITYNAME_EN": "Alghal",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12657",
    "CITYNAME_AR": "قلعه الازنم",
    "CITYNAME_EN": "Qalaeah Alaznam",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12658",
    "CITYNAME_AR": "نخل المعرش",
    "CITYNAME_EN": "Nakhl Almaerish",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12659",
    "CITYNAME_AR": "السجده",
    "CITYNAME_EN": "Alsijaduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12660",
    "CITYNAME_AR": "مثلث شواق",
    "CITYNAME_EN": "Muthalath Shawaq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12661",
    "CITYNAME_AR": "الخريبه",
    "CITYNAME_EN": "Alkharibuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12662",
    "CITYNAME_AR": "عينونه",
    "CITYNAME_EN": "Eaynunuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12663",
    "CITYNAME_AR": "الصميده",
    "CITYNAME_EN": "Alsamiduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12664",
    "CITYNAME_AR": "الظلعه الشماليه",
    "CITYNAME_EN": "Alzalaeuh Alshamaliuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12665",
    "CITYNAME_AR": "بئر سلامه سلمان",
    "CITYNAME_EN": "Bayir Salamuh Salman",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12666",
    "CITYNAME_AR": "شرماء",
    "CITYNAME_EN": "Sharama'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12667",
    "CITYNAME_AR": "العدفه",
    "CITYNAME_EN": "Aleadfuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12668",
    "CITYNAME_AR": "غر",
    "CITYNAME_EN": "Ghar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12669",
    "CITYNAME_AR": "صدر",
    "CITYNAME_EN": "Sadar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12670",
    "CITYNAME_AR": "المويلح",
    "CITYNAME_EN": "Almuilah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12671",
    "CITYNAME_AR": "الجم",
    "CITYNAME_EN": "Aljumu",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12672",
    "CITYNAME_AR": "مجيهيله",
    "CITYNAME_EN": "Mujihilah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12673",
    "CITYNAME_AR": "صر",
    "CITYNAME_EN": "Sir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12674",
    "CITYNAME_AR": "النجل",
    "CITYNAME_EN": "Alnajl",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12675",
    "CITYNAME_AR": "خشم الظبيبه",
    "CITYNAME_EN": "Khasham Alzabibih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12676",
    "CITYNAME_AR": "العمود",
    "CITYNAME_EN": "Aleumud",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12677",
    "CITYNAME_AR": "الصوره",
    "CITYNAME_EN": "Alsuwruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12678",
    "CITYNAME_AR": "روى",
    "CITYNAME_EN": "Rawaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12679",
    "CITYNAME_AR": "الوجه",
    "CITYNAME_EN": "Alwajh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12680",
    "CITYNAME_AR": "حرامل",
    "CITYNAME_EN": "Haramil",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12681",
    "CITYNAME_AR": "بئر وادى المياه",
    "CITYNAME_EN": "Water Biir Wa'Adaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12682",
    "CITYNAME_AR": "الخميله",
    "CITYNAME_EN": "Alkhamiluh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12683",
    "CITYNAME_AR": "السبيل والحميراء",
    "CITYNAME_EN": "Alsabil Walhamira'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12684",
    "CITYNAME_AR": "بئرالقصير",
    "CITYNAME_EN": "Biiralqasir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12685",
    "CITYNAME_AR": "بئر الدثنه",
    "CITYNAME_EN": "Biir Aldathnah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12686",
    "CITYNAME_AR": "بئر ابو محيثلات",
    "CITYNAME_EN": "Bir 'Abu Muhyathulat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12687",
    "CITYNAME_AR": "بئر القبه",
    "CITYNAME_EN": "Biir Alqabuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12688",
    "CITYNAME_AR": "العرجاء",
    "CITYNAME_EN": "Aleurja'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12689",
    "CITYNAME_AR": "الماره",
    "CITYNAME_EN": "Almaruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12690",
    "CITYNAME_AR": "النخيره",
    "CITYNAME_EN": "Alnakhiruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12691",
    "CITYNAME_AR": "مركز حرس حرامل",
    "CITYNAME_EN": "Border Guard Haraml",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12692",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Alrasu",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12693",
    "CITYNAME_AR": "عنتر",
    "CITYNAME_EN": "Eantar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12694",
    "CITYNAME_AR": "حقل",
    "CITYNAME_EN": "Haql",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12695",
    "CITYNAME_AR": "الدره",
    "CITYNAME_EN": "Alduruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12696",
    "CITYNAME_AR": "ابو الحنشان",
    "CITYNAME_EN": "'Abu Alhanshan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12697",
    "CITYNAME_AR": "علقان",
    "CITYNAME_EN": "Eulqan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12698",
    "CITYNAME_AR": "الزيته",
    "CITYNAME_EN": "Alziyatuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12699",
    "CITYNAME_AR": "شريف معطيه",
    "CITYNAME_EN": "Sharif Maetih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12700",
    "CITYNAME_AR": "الوادي الجديد",
    "CITYNAME_EN": "Alwadi Aljadid",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12701",
    "CITYNAME_AR": "علو القصير",
    "CITYNAME_EN": "Eului Alqasir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12702",
    "CITYNAME_AR": "ارتواز وادي عفال",
    "CITYNAME_EN": "Airtiwaz Wadi Eafal",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12703",
    "CITYNAME_AR": "البدع",
    "CITYNAME_EN": "Albadae",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12704",
    "CITYNAME_AR": "قيال",
    "CITYNAME_EN": "Qial",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12705",
    "CITYNAME_AR": "ابو رمانه",
    "CITYNAME_EN": "'Abu Ramanh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12706",
    "CITYNAME_AR": "المتاهه",
    "CITYNAME_EN": "Almatahuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12707",
    "CITYNAME_AR": "العصيليه",
    "CITYNAME_EN": "Aleasiliuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12708",
    "CITYNAME_AR": "مقنا",
    "CITYNAME_EN": "Maqana",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12709",
    "CITYNAME_AR": "الحمض",
    "CITYNAME_EN": "Alhamduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12710",
    "CITYNAME_AR": "حاله عمار",
    "CITYNAME_EN": "Halah Eammar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12711",
    "CITYNAME_AR": "العنيق",
    "CITYNAME_EN": "Aleaniq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12712",
    "CITYNAME_AR": "رجوم الطيارة",
    "CITYNAME_EN": "Rujum Altiyara",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12713",
    "CITYNAME_AR": "بئر بن هرماس",
    "CITYNAME_EN": "Bayir Bin Hirmas",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12714",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Aleiinuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12715",
    "CITYNAME_AR": "ذات الحاج",
    "CITYNAME_EN": "Dhat Alhaj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12716",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Albadie",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12717",
    "CITYNAME_AR": "الشايب",
    "CITYNAME_EN": "Alshaayib",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12719",
    "CITYNAME_AR": "ابوالسلعه",
    "CITYNAME_EN": "Abo As-Selaah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12720",
    "CITYNAME_AR": "ابوالسدره",
    "CITYNAME_EN": "Abo As-Sedrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12721",
    "CITYNAME_AR": "الخربه",
    "CITYNAME_EN": "Al Kharabah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12722",
    "CITYNAME_AR": "الدحيل",
    "CITYNAME_EN": "Ad-Duhail",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12723",
    "CITYNAME_AR": "العدن",
    "CITYNAME_EN": "Al-Adn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12724",
    "CITYNAME_AR": "نعجان",
    "CITYNAME_EN": "Najan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12725",
    "CITYNAME_AR": "السهومه",
    "CITYNAME_EN": "As-Suhoumah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12726",
    "CITYNAME_AR": "الرخم",
    "CITYNAME_EN": "Ar-Rakham",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12727",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12728",
    "CITYNAME_AR": "أم القصبى",
    "CITYNAME_EN": "Om Al Qasabi",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12729",
    "CITYNAME_AR": "القتب",
    "CITYNAME_EN": "Al Qatab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12730",
    "CITYNAME_AR": "محويه",
    "CITYNAME_EN": "Mahweyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12731",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadabah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12732",
    "CITYNAME_AR": "المثاميل",
    "CITYNAME_EN": "Al Mathamel",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12733",
    "CITYNAME_AR": "خضيره",
    "CITYNAME_EN": "Khudayrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12734",
    "CITYNAME_AR": "دغماء",
    "CITYNAME_EN": "Daghmaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12735",
    "CITYNAME_AR": "ابوشوك",
    "CITYNAME_EN": "Abo Shouq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12736",
    "CITYNAME_AR": "ابوالدحال",
    "CITYNAME_EN": "Abo Ad-Dahal",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12737",
    "CITYNAME_AR": "بطيح",
    "CITYNAME_EN": "Bateeh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12738",
    "CITYNAME_AR": "الرافعيه",
    "CITYNAME_EN": "Ar-Rafeyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12739",
    "CITYNAME_AR": "ابوحبس",
    "CITYNAME_EN": "Abo Habas",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12740",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12741",
    "CITYNAME_AR": "حميس",
    "CITYNAME_EN": "Humayes",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12742",
    "CITYNAME_AR": "غياض",
    "CITYNAME_EN": "Gheyad",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12743",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12744",
    "CITYNAME_AR": "القماعه",
    "CITYNAME_EN": "Al Qumaah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12745",
    "CITYNAME_AR": "عبيكه",
    "CITYNAME_EN": "Abika",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12746",
    "CITYNAME_AR": "قرن الشريف",
    "CITYNAME_EN": "Qarn Al-Sharif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12747",
    "CITYNAME_AR": "حاضر",
    "CITYNAME_EN": "Hader ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12748",
    "CITYNAME_AR": "الحدبه البيضاء",
    "CITYNAME_EN": "Al-hadbah Al-Baydaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12749",
    "CITYNAME_AR": "العلو",
    "CITYNAME_EN": "Al-Olw ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12750",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12751",
    "CITYNAME_AR": "الصليله",
    "CITYNAME_EN": "Al-Salilah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12752",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al-Hankah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12753",
    "CITYNAME_AR": "حبس ابن زينه",
    "CITYNAME_EN": "Habs ibn zenah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12754",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12755",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Al-Hamdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12756",
    "CITYNAME_AR": "ال عامر",
    "CITYNAME_EN": "Al Amer",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12757",
    "CITYNAME_AR": "حبس ابو قشعه",
    "CITYNAME_EN": "Habs abu Qash'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12758",
    "CITYNAME_AR": "سميره",
    "CITYNAME_EN": "Somaira",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12759",
    "CITYNAME_AR": "النفره",
    "CITYNAME_EN": "Al-Nifrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12760",
    "CITYNAME_AR": "ال نعمان",
    "CITYNAME_EN": "Al No'man ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12761",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12762",
    "CITYNAME_AR": "غياض",
    "CITYNAME_EN": "Gheyad ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12763",
    "CITYNAME_AR": "السنه",
    "CITYNAME_EN": "Al-Sonnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12764",
    "CITYNAME_AR": "العسيله",
    "CITYNAME_EN": "Al Asilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12765",
    "CITYNAME_AR": "الجومان",
    "CITYNAME_EN": "Al Jawman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12766",
    "CITYNAME_AR": "القريع بني مالك",
    "CITYNAME_EN": "Alqarei Bani Malik",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12767",
    "CITYNAME_AR": "الخضارين",
    "CITYNAME_EN": "Al Khadaren",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12768",
    "CITYNAME_AR": "آل هادي",
    "CITYNAME_EN": "Al Hadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12769",
    "CITYNAME_AR": "الدعامله",
    "CITYNAME_EN": "Ad-Daalmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12770",
    "CITYNAME_AR": "الهنود السليمانيه",
    "CITYNAME_EN": "Al Honood As-Sulaimanyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12771",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12772",
    "CITYNAME_AR": "شباح",
    "CITYNAME_EN": "Shabbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12773",
    "CITYNAME_AR": "الزهره",
    "CITYNAME_EN": "Az-Zahrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12774",
    "CITYNAME_AR": "الرواجحه (العين)",
    "CITYNAME_EN": "Ar-Rawajehah (Al Ain)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12775",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Quzaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12776",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12777",
    "CITYNAME_AR": "الربعه",
    "CITYNAME_EN": "Ar-Ruyaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12778",
    "CITYNAME_AR": "الصخيره",
    "CITYNAME_EN": "As-Sukhayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12779",
    "CITYNAME_AR": "الجبابير (الجامع)",
    "CITYNAME_EN": "Al Jababer (Al Jamei)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12780",
    "CITYNAME_AR": "القنيبه",
    "CITYNAME_EN": "Al Qunaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12781",
    "CITYNAME_AR": "الاصافره",
    "CITYNAME_EN": "Al Asaferah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12782",
    "CITYNAME_AR": "القطره",
    "CITYNAME_EN": "Al Qatrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12783",
    "CITYNAME_AR": "العاصد",
    "CITYNAME_EN": "Al Ased",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12784",
    "CITYNAME_AR": "ال خضر",
    "CITYNAME_EN": "Al Khedr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12785",
    "CITYNAME_AR": "الفراديس",
    "CITYNAME_EN": "Al Farades",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12786",
    "CITYNAME_AR": "ال عبدالهادى",
    "CITYNAME_EN": "Al Abdel-Hadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12787",
    "CITYNAME_AR": "ال عازب",
    "CITYNAME_EN": "Al Azeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12788",
    "CITYNAME_AR": "المفاتيح",
    "CITYNAME_EN": "Al Mafateeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12789",
    "CITYNAME_AR": "الهديه",
    "CITYNAME_EN": "Al Hedya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12790",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12791",
    "CITYNAME_AR": "الغائره",
    "CITYNAME_EN": "Al Ghaerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12792",
    "CITYNAME_AR": "ام الحكك",
    "CITYNAME_EN": "Om Al Hakak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12793",
    "CITYNAME_AR": "ال ابراهيم",
    "CITYNAME_EN": "Al Ibrahim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12794",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Ar-Rubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12795",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12796",
    "CITYNAME_AR": "الحديب",
    "CITYNAME_EN": "Al Hudayeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12797",
    "CITYNAME_AR": "الشعيب",
    "CITYNAME_EN": "Ash-Shuaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12798",
    "CITYNAME_AR": "قزعه الخضره",
    "CITYNAME_EN": "Qazeit Al Khudrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12799",
    "CITYNAME_AR": "القضاه",
    "CITYNAME_EN": "Al Qudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12800",
    "CITYNAME_AR": "الدار (آل هيزع)",
    "CITYNAME_EN": "Ad-Dar (Al Hayzaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12801",
    "CITYNAME_AR": "الفاعوس (الحلاه)",
    "CITYNAME_EN": "Al Faous (Al Halah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12802",
    "CITYNAME_AR": "المعلاه",
    "CITYNAME_EN": "Al Moalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12803",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12804",
    "CITYNAME_AR": "الشفا الاحمر",
    "CITYNAME_EN": "Ash-Shafa Al Ahmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12805",
    "CITYNAME_AR": "النجمه",
    "CITYNAME_EN": "An-Najmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12806",
    "CITYNAME_AR": "حياد",
    "CITYNAME_EN": "Heyad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12807",
    "CITYNAME_AR": "الربيعه",
    "CITYNAME_EN": "Ar-Rubayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12808",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Al Qaarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12809",
    "CITYNAME_AR": "القرا",
    "CITYNAME_EN": "Al Qara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12810",
    "CITYNAME_AR": "الدار",
    "CITYNAME_EN": "Ad-Dar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12811",
    "CITYNAME_AR": "الجبره",
    "CITYNAME_EN": "Al-Jabrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12812",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar-Rahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12813",
    "CITYNAME_AR": "الطبقه",
    "CITYNAME_EN": "At-Tabaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12814",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Ar-Rubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12815",
    "CITYNAME_AR": "البدوان",
    "CITYNAME_EN": "Al Badwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12816",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "As-Samadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12817",
    "CITYNAME_AR": "قزعه المناقيش",
    "CITYNAME_EN": "Qazeit Al Manaqeesh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12818",
    "CITYNAME_AR": "قريه الحصناء",
    "CITYNAME_EN": "Al Hasnaa village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12819",
    "CITYNAME_AR": "القريه (صعبان)",
    "CITYNAME_EN": "Al Qaryah (Saaban)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12820",
    "CITYNAME_AR": "الحلقه",
    "CITYNAME_EN": "Al Halaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12821",
    "CITYNAME_AR": "شعب بني دحيم",
    "CITYNAME_EN": "Shuab bani Duhayem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12822",
    "CITYNAME_AR": "البصيره",
    "CITYNAME_EN": "Al Basirah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12823",
    "CITYNAME_AR": "قميحه",
    "CITYNAME_EN": "Qumayhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12824",
    "CITYNAME_AR": "قوشه",
    "CITYNAME_EN": "Qawshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12825",
    "CITYNAME_AR": "الصفح",
    "CITYNAME_EN": "As-Safh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12826",
    "CITYNAME_AR": "قرى الحضارمه",
    "CITYNAME_EN": "Al Hadaremeh villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12827",
    "CITYNAME_AR": "وقيده",
    "CITYNAME_EN": "Qaqedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12828",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Al Hano",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12829",
    "CITYNAME_AR": "القاحل",
    "CITYNAME_EN": "Alqahel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12830",
    "CITYNAME_AR": "قباله",
    "CITYNAME_EN": "Qebalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12831",
    "CITYNAME_AR": "مشنيه",
    "CITYNAME_EN": "Mashnyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12832",
    "CITYNAME_AR": "ضعان",
    "CITYNAME_EN": "Daan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12833",
    "CITYNAME_AR": "ال بلال",
    "CITYNAME_EN": "Al Belal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12834",
    "CITYNAME_AR": "الرهيات",
    "CITYNAME_EN": "Ar-Ruhayat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12835",
    "CITYNAME_AR": "ال هزاع",
    "CITYNAME_EN": "Al Hazei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12836",
    "CITYNAME_AR": "الشبان (الحفره)",
    "CITYNAME_EN": "Ash-Shuban (Al Hufrah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12837",
    "CITYNAME_AR": "الملد",
    "CITYNAME_EN": "Al Malad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12838",
    "CITYNAME_AR": "عتام",
    "CITYNAME_EN": "Etam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12839",
    "CITYNAME_AR": "عويا",
    "CITYNAME_EN": "Auba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12840",
    "CITYNAME_AR": "الجريعا",
    "CITYNAME_EN": "Al Guryaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12841",
    "CITYNAME_AR": "الحدب بنى سعد",
    "CITYNAME_EN": "Al Hadab bani Saad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12842",
    "CITYNAME_AR": "العبله",
    "CITYNAME_EN": "Al Abalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12843",
    "CITYNAME_AR": "الحده",
    "CITYNAME_EN": "Al Hedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12844",
    "CITYNAME_AR": "العش",
    "CITYNAME_EN": "Al Ush",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12845",
    "CITYNAME_AR": "الشلاء",
    "CITYNAME_EN": "Ash-Shalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12846",
    "CITYNAME_AR": "العفراء",
    "CITYNAME_EN": "Al Afraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12847",
    "CITYNAME_AR": "المقلع",
    "CITYNAME_EN": "Al Muqlei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12848",
    "CITYNAME_AR": "علقمه",
    "CITYNAME_EN": "Alqamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12849",
    "CITYNAME_AR": "ال هيف",
    "CITYNAME_EN": "Al Haif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12850",
    "CITYNAME_AR": "الجملان",
    "CITYNAME_EN": "Al Jamalan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12851",
    "CITYNAME_AR": "ال غريبه",
    "CITYNAME_EN": "Al Gharebah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12852",
    "CITYNAME_AR": "قريش",
    "CITYNAME_EN": "Quraish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12853",
    "CITYNAME_AR": "بلاساء",
    "CITYNAME_EN": "Balasaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12854",
    "CITYNAME_AR": "الوهسه",
    "CITYNAME_EN": "Al Wahsah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12855",
    "CITYNAME_AR": "المسن",
    "CITYNAME_EN": "Al Mosen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12856",
    "CITYNAME_AR": "الغاليه",
    "CITYNAME_EN": "Al Ghalyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12857",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12858",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Al Hadab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12859",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12860",
    "CITYNAME_AR": "قريظه (الداره)",
    "CITYNAME_EN": "Quraythah (Ad-Darah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12861",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Al Qaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12862",
    "CITYNAME_AR": "المضحاه",
    "CITYNAME_EN": "Al Mudhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12863",
    "CITYNAME_AR": "النوله",
    "CITYNAME_EN": "An-Nawlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12864",
    "CITYNAME_AR": "الخلفان",
    "CITYNAME_EN": "Al Khalfan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12865",
    "CITYNAME_AR": "العدول",
    "CITYNAME_EN": "Al Oudoul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12866",
    "CITYNAME_AR": "بنى عامر",
    "CITYNAME_EN": "Bani Amer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12867",
    "CITYNAME_AR": "قملان",
    "CITYNAME_EN": "Qamlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12868",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Al Oqdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12869",
    "CITYNAME_AR": "اصفه",
    "CITYNAME_EN": "Asfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12870",
    "CITYNAME_AR": "القهب",
    "CITYNAME_EN": "Al Qahb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12871",
    "CITYNAME_AR": "الفضله",
    "CITYNAME_EN": "Al Fudlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12872",
    "CITYNAME_AR": "الفقهاء",
    "CITYNAME_EN": "Al Fuqahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12873",
    "CITYNAME_AR": "القحصه",
    "CITYNAME_EN": "Al Qahsah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12874",
    "CITYNAME_AR": "الزريبه",
    "CITYNAME_EN": "Az-Zaraybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12875",
    "CITYNAME_AR": "الشعشعى",
    "CITYNAME_EN": "Ash-Shashai",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12876",
    "CITYNAME_AR": "عبده",
    "CITYNAME_EN": "Abdo",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12877",
    "CITYNAME_AR": "الأحلاف",
    "CITYNAME_EN": "Al Ahlaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12878",
    "CITYNAME_AR": "كرتان",
    "CITYNAME_EN": "Kartan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12879",
    "CITYNAME_AR": "المسالمه",
    "CITYNAME_EN": "Al Musalemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12880",
    "CITYNAME_AR": "الشمول",
    "CITYNAME_EN": "Ash-Shomol",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12881",
    "CITYNAME_AR": "المرازيق",
    "CITYNAME_EN": "Al Marazeq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12882",
    "CITYNAME_AR": "ال سعد",
    "CITYNAME_EN": "Al Saad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12883",
    "CITYNAME_AR": "ال بالاسد",
    "CITYNAME_EN": "Al Balasad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12884",
    "CITYNAME_AR": "ال بشير",
    "CITYNAME_EN": "Al Bashir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12885",
    "CITYNAME_AR": "السلاطين",
    "CITYNAME_EN": "As-Salateen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12886",
    "CITYNAME_AR": "القرا",
    "CITYNAME_EN": "Al Qara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12887",
    "CITYNAME_AR": "البو",
    "CITYNAME_EN": "Al Bou",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12888",
    "CITYNAME_AR": "الصريف",
    "CITYNAME_EN": "As-Surayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12889",
    "CITYNAME_AR": "السعده",
    "CITYNAME_EN": "As-Saadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12890",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Ad-Darah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12891",
    "CITYNAME_AR": "الرياء",
    "CITYNAME_EN": "Ar-Reyaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12892",
    "CITYNAME_AR": "مخراق",
    "CITYNAME_EN": "Mekhraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12893",
    "CITYNAME_AR": "الرفيعه",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12894",
    "CITYNAME_AR": "ال غارس",
    "CITYNAME_EN": "Al Ghares",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12895",
    "CITYNAME_AR": "عضدا",
    "CITYNAME_EN": "Adudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12896",
    "CITYNAME_AR": "الصويكا والخنيسيه",
    "CITYNAME_EN": "As-Suwaika wa Al Khunaysyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12897",
    "CITYNAME_AR": "الحمده",
    "CITYNAME_EN": "Al Hamadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12898",
    "CITYNAME_AR": "القصره",
    "CITYNAME_EN": "Al Qosrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12899",
    "CITYNAME_AR": "الدارين",
    "CITYNAME_EN": "Ad-Darayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12900",
    "CITYNAME_AR": "العتله",
    "CITYNAME_EN": "Al Atalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12901",
    "CITYNAME_AR": "سبلان",
    "CITYNAME_EN": "Sablan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12902",
    "CITYNAME_AR": "الزبره",
    "CITYNAME_EN": "Az-Zabarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12903",
    "CITYNAME_AR": "الخباتيه",
    "CITYNAME_EN": "Al Khubatyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12904",
    "CITYNAME_AR": "ابن خزيران",
    "CITYNAME_EN": "Ibn Khuzayran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12905",
    "CITYNAME_AR": "رماح (القلعه)",
    "CITYNAME_EN": "Rumah (Al Qalaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12906",
    "CITYNAME_AR": "الحديده",
    "CITYNAME_EN": "Al Hudaydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12907",
    "CITYNAME_AR": "الجابر",
    "CITYNAME_EN": "Al Jaber",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12908",
    "CITYNAME_AR": "الرفاعه",
    "CITYNAME_EN": "Ar-Rufaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12909",
    "CITYNAME_AR": "الحنانه",
    "CITYNAME_EN": "Al Hananah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12910",
    "CITYNAME_AR": "الجميله",
    "CITYNAME_EN": "Al Jumaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12911",
    "CITYNAME_AR": "الجمال",
    "CITYNAME_EN": "Al Jamal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12912",
    "CITYNAME_AR": "حلقة السدين",
    "CITYNAME_EN": "Halaqet As-Saddayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12913",
    "CITYNAME_AR": "العشه",
    "CITYNAME_EN": "Alaushah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12914",
    "CITYNAME_AR": "قصره الحجره",
    "CITYNAME_EN": "Qasrah Al Hujrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12915",
    "CITYNAME_AR": "المحاميد",
    "CITYNAME_EN": "Al-Mahamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12916",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Mulaiha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12917",
    "CITYNAME_AR": "ابوزيد",
    "CITYNAME_EN": "Abo Zayed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12918",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Ar-Rajamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12919",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12920",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "As-Safa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12921",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12922",
    "CITYNAME_AR": "الهدى",
    "CITYNAME_EN": "Al Huda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12923",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Ar-Rahbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12924",
    "CITYNAME_AR": "رحثا",
    "CITYNAME_EN": "Rahtha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12925",
    "CITYNAME_AR": "رحاث",
    "CITYNAME_EN": "Ruhath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12926",
    "CITYNAME_AR": "عاذر",
    "CITYNAME_EN": "Ather",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12927",
    "CITYNAME_AR": "البقاع",
    "CITYNAME_EN": "Al Buqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12928",
    "CITYNAME_AR": "ود",
    "CITYNAME_EN": "Weid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12929",
    "CITYNAME_AR": "موقد",
    "CITYNAME_EN": "Muqed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12930",
    "CITYNAME_AR": "كيد الخلاء",
    "CITYNAME_EN": "Kayed Al Khalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12931",
    "CITYNAME_AR": "الاغوار",
    "CITYNAME_EN": "Al Aghwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12932",
    "CITYNAME_AR": "الحده",
    "CITYNAME_EN": "Alhuduh",
    "REGION_ID": "ALHUDUH",
    "Column5": "NULL"
   },
   {
    "CITY_ID": "12933",
    "CITYNAME_AR": "الفضله",
    "CITYNAME_EN": "Al Fudlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12934",
    "CITYNAME_AR": "العالى",
    "CITYNAME_EN": "Al Ali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12935",
    "CITYNAME_AR": "الجباهين",
    "CITYNAME_EN": "Al Jabahayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12936",
    "CITYNAME_AR": "العبيل",
    "CITYNAME_EN": "Al Obayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12937",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Al Khaimah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12938",
    "CITYNAME_AR": "الغريسة",
    "CITYNAME_EN": "Al Gharesah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12939",
    "CITYNAME_AR": "شريان",
    "CITYNAME_EN": "Shuryan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12940",
    "CITYNAME_AR": "الحفار",
    "CITYNAME_EN": "Al Haffar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12941",
    "CITYNAME_AR": "القحف",
    "CITYNAME_EN": "Al Qahf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12942",
    "CITYNAME_AR": "سيمان",
    "CITYNAME_EN": "Sumyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12943",
    "CITYNAME_AR": "الدشاما",
    "CITYNAME_EN": "Ad-Dushma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12944",
    "CITYNAME_AR": "المصاقعه",
    "CITYNAME_EN": "Al Musaqaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12945",
    "CITYNAME_AR": "الجذيه",
    "CITYNAME_EN": "Al Jazyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12946",
    "CITYNAME_AR": "بضا",
    "CITYNAME_EN": "Bada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12947",
    "CITYNAME_AR": "الرضمه",
    "CITYNAME_EN": "Ar-Radamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12948",
    "CITYNAME_AR": "الشعاب",
    "CITYNAME_EN": "Ash-Shuab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12949",
    "CITYNAME_AR": "الضبعة",
    "CITYNAME_EN": "Ad-Dabaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12950",
    "CITYNAME_AR": "المنقب",
    "CITYNAME_EN": "Al Manqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12951",
    "CITYNAME_AR": "شقاوه",
    "CITYNAME_EN": "Shaqawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12952",
    "CITYNAME_AR": "شعب صالح",
    "CITYNAME_EN": "Shuab Saleh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12953",
    "CITYNAME_AR": "شعب العمر",
    "CITYNAME_EN": "Shuab Al Omer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12954",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12955",
    "CITYNAME_AR": "الحنيش",
    "CITYNAME_EN": "Al Hanesh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12956",
    "CITYNAME_AR": "المداره",
    "CITYNAME_EN": "Al Mudarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12957",
    "CITYNAME_AR": "ريع النبق",
    "CITYNAME_EN": "Rei An-Nabaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12958",
    "CITYNAME_AR": "خيرين",
    "CITYNAME_EN": "Khabaryn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12959",
    "CITYNAME_AR": "طريخم",
    "CITYNAME_EN": "Turaykham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12960",
    "CITYNAME_AR": "المشهق",
    "CITYNAME_EN": "Al Mushhaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12961",
    "CITYNAME_AR": "الملحا",
    "CITYNAME_EN": "Al Malha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12962",
    "CITYNAME_AR": "الهمله",
    "CITYNAME_EN": "Al Humlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12963",
    "CITYNAME_AR": "اليعاسيب",
    "CITYNAME_EN": "Alyaaseb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12964",
    "CITYNAME_AR": "التومه",
    "CITYNAME_EN": "At-Tawmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12965",
    "CITYNAME_AR": "القزيعه",
    "CITYNAME_EN": "Al Quzayaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12966",
    "CITYNAME_AR": "المقصره",
    "CITYNAME_EN": "Al Muqsarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12967",
    "CITYNAME_AR": "الشظابيه",
    "CITYNAME_EN": "Ash-Shazabyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12968",
    "CITYNAME_AR": "شعب قدامه",
    "CITYNAME_EN": "Shuab Qadamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12969",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Al Hamatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12970",
    "CITYNAME_AR": "الشطفه",
    "CITYNAME_EN": "Ash-Shatafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12971",
    "CITYNAME_AR": "ابوسرفه (العطيه)",
    "CITYNAME_EN": "Abo Sarafah (Al Attiyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12972",
    "CITYNAME_AR": "الوعير",
    "CITYNAME_EN": "Al Waeer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12973",
    "CITYNAME_AR": "المهاديه",
    "CITYNAME_EN": "Al Muhadyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12974",
    "CITYNAME_AR": "ابن دمغان",
    "CITYNAME_EN": "Ibn Damghan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12975",
    "CITYNAME_AR": "الحوزه",
    "CITYNAME_EN": "Al Hawzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12976",
    "CITYNAME_AR": "الشعاتير",
    "CITYNAME_EN": "Ash-Shaater",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12977",
    "CITYNAME_AR": "ابن رميح",
    "CITYNAME_EN": "Ibn Rameeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12978",
    "CITYNAME_AR": "السراحين",
    "CITYNAME_EN": "As-Saraheen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12979",
    "CITYNAME_AR": "الاخيار",
    "CITYNAME_EN": "Al Akhyar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12980",
    "CITYNAME_AR": "ابوشايق",
    "CITYNAME_EN": "Abo Shayeq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12981",
    "CITYNAME_AR": "المساتير",
    "CITYNAME_EN": "Al Masater",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12982",
    "CITYNAME_AR": "جناب ساره",
    "CITYNAME_EN": "Janab Sarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12983",
    "CITYNAME_AR": "حداد والحصبه",
    "CITYNAME_EN": "Hedad wa Al Hasabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12984",
    "CITYNAME_AR": "النفيل",
    "CITYNAME_EN": "An-Nufayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12985",
    "CITYNAME_AR": "راوان (الأعلى)",
    "CITYNAME_EN": "Rawan (Al Aala)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12986",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Ash-Shatabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12987",
    "CITYNAME_AR": "راوان الاسفل",
    "CITYNAME_EN": "Rawan Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12988",
    "CITYNAME_AR": "العقدة (جبل السودان)",
    "CITYNAME_EN": "Al Oqdah (Jabal As-Soudan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12989",
    "CITYNAME_AR": "الزلوف",
    "CITYNAME_EN": "Az-Zuluf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12990",
    "CITYNAME_AR": "المشاييخ",
    "CITYNAME_EN": "Al Mashayekh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12991",
    "CITYNAME_AR": "الجارالله",
    "CITYNAME_EN": "Al Jarallah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12992",
    "CITYNAME_AR": "عزان عميشه",
    "CITYNAME_EN": "Ezzan Omayshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12993",
    "CITYNAME_AR": "الجهالين",
    "CITYNAME_EN": "Al Jahaleen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12994",
    "CITYNAME_AR": "شعب اليمانى",
    "CITYNAME_EN": "Shuab Al Yamani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12995",
    "CITYNAME_AR": "شعب العبدان",
    "CITYNAME_EN": "Shuab Al Abdan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12996",
    "CITYNAME_AR": "المزاريع",
    "CITYNAME_EN": "Al Mazarei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12997",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12998",
    "CITYNAME_AR": "المثناه",
    "CITYNAME_EN": "Al Muthnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "12999",
    "CITYNAME_AR": "سحبان",
    "CITYNAME_EN": "Sabhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13000",
    "CITYNAME_AR": "الحميمه",
    "CITYNAME_EN": "Al Humaymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13001",
    "CITYNAME_AR": "عويره",
    "CITYNAME_EN": "Awayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13002",
    "CITYNAME_AR": "الجعلان",
    "CITYNAME_EN": "Al Jaalan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13003",
    "CITYNAME_AR": "حصن الواكد",
    "CITYNAME_EN": "Husn Al Waqid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13004",
    "CITYNAME_AR": "ابن جميل",
    "CITYNAME_EN": "Ibn Jamil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13005",
    "CITYNAME_AR": "الواده",
    "CITYNAME_EN": "Al Wadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13006",
    "CITYNAME_AR": "الشريه",
    "CITYNAME_EN": "Ash-Shareeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13007",
    "CITYNAME_AR": "الرواجح",
    "CITYNAME_EN": "Ar-Rawajeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13008",
    "CITYNAME_AR": "القهب",
    "CITYNAME_EN": "Al Qahb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13009",
    "CITYNAME_AR": "سدان عفيف",
    "CITYNAME_EN": "Sedan Afif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13010",
    "CITYNAME_AR": "العواد",
    "CITYNAME_EN": "Al Awwad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13011",
    "CITYNAME_AR": "حلفاء",
    "CITYNAME_EN": "Hulafaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13012",
    "CITYNAME_AR": "الرزقة",
    "CITYNAME_EN": "Az-Zurqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13013",
    "CITYNAME_AR": "الحسنا",
    "CITYNAME_EN": "Al Husna",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13014",
    "CITYNAME_AR": "العامر",
    "CITYNAME_EN": "Al Amer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13015",
    "CITYNAME_AR": "العميره",
    "CITYNAME_EN": "Al Omayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13016",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13017",
    "CITYNAME_AR": "الفارعه",
    "CITYNAME_EN": "Al Fara'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13018",
    "CITYNAME_AR": "الهوله",
    "CITYNAME_EN": "Al Hawlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13019",
    "CITYNAME_AR": "الدحمه",
    "CITYNAME_EN": "Ad-Dahamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13020",
    "CITYNAME_AR": "القرا",
    "CITYNAME_EN": "Al Qara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13021",
    "CITYNAME_AR": "العمشان",
    "CITYNAME_EN": "Al Ashman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13022",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al Halah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13023",
    "CITYNAME_AR": "المشاقيف",
    "CITYNAME_EN": "Al Mashaqef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13024",
    "CITYNAME_AR": "الغلطه (الجداء)",
    "CITYNAME_EN": "Al Ghaltah (Al Jedaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13025",
    "CITYNAME_AR": "البناه",
    "CITYNAME_EN": "Al Bunah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13026",
    "CITYNAME_AR": "الدبوس",
    "CITYNAME_EN": "Ad-Dabous",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13027",
    "CITYNAME_AR": "الشلان",
    "CITYNAME_EN": "Ash-Shelan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13028",
    "CITYNAME_AR": "الشرفيه",
    "CITYNAME_EN": "Ash-Sharfyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13029",
    "CITYNAME_AR": "الحسنى",
    "CITYNAME_EN": "Al Husna",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13030",
    "CITYNAME_AR": "حديب عباس",
    "CITYNAME_EN": "Hudayb Abbas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13031",
    "CITYNAME_AR": "الغريب",
    "CITYNAME_EN": "Al Ghareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13032",
    "CITYNAME_AR": "الشعفه",
    "CITYNAME_EN": "Ash-Shafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13033",
    "CITYNAME_AR": "الدار الحمراء",
    "CITYNAME_EN": "Ad-Dar Al Hamraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13034",
    "CITYNAME_AR": "صخيبر",
    "CITYNAME_EN": "Sukhaybar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13035",
    "CITYNAME_AR": "الصفح",
    "CITYNAME_EN": "As-Safh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13036",
    "CITYNAME_AR": "الدار البيضاء",
    "CITYNAME_EN": "Ad-Dar Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13037",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13038",
    "CITYNAME_AR": "الهبار",
    "CITYNAME_EN": "Al Habar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13039",
    "CITYNAME_AR": "المسايبه",
    "CITYNAME_EN": "Al Masabyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13040",
    "CITYNAME_AR": "السنوان",
    "CITYNAME_EN": "As-Senwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13041",
    "CITYNAME_AR": "القذاره",
    "CITYNAME_EN": "Al Qazarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13042",
    "CITYNAME_AR": "الشتره",
    "CITYNAME_EN": "Ash-Shatarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13043",
    "CITYNAME_AR": "الحطمه",
    "CITYNAME_EN": "Al Hutamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13044",
    "CITYNAME_AR": "الحميان",
    "CITYNAME_EN": "Al Humyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13045",
    "CITYNAME_AR": "القضاه",
    "CITYNAME_EN": "Al Qudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13046",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Ar-Rubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13047",
    "CITYNAME_AR": "الموارقه",
    "CITYNAME_EN": "AL Mawareqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13048",
    "CITYNAME_AR": "الشرفيه",
    "CITYNAME_EN": "Ash-Sharfyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13049",
    "CITYNAME_AR": "الحشان",
    "CITYNAME_EN": "Al Hashan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13050",
    "CITYNAME_AR": "الرافع",
    "CITYNAME_EN": "Ar-Rafei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13051",
    "CITYNAME_AR": "الحليه",
    "CITYNAME_EN": "Al Halyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13052",
    "CITYNAME_AR": "الاحامره",
    "CITYNAME_EN": "Al Ahamerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13053",
    "CITYNAME_AR": "ال شباره",
    "CITYNAME_EN": "Al Shabarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13054",
    "CITYNAME_AR": "الهلال",
    "CITYNAME_EN": "Al Helal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13055",
    "CITYNAME_AR": "وادى الشولان",
    "CITYNAME_EN": "Wadi Ash-Shawlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13056",
    "CITYNAME_AR": "البضاء",
    "CITYNAME_EN": "Al Badaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13057",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13058",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Ash-Shatabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13059",
    "CITYNAME_AR": "القلت",
    "CITYNAME_EN": "Al Qalt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13060",
    "CITYNAME_AR": "الوسطه",
    "CITYNAME_EN": "Al Wasetah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13061",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Umm Al-Qura",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13062",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Quzaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13063",
    "CITYNAME_AR": "الفطيمه",
    "CITYNAME_EN": "Al Futaymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13064",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar-Rahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13065",
    "CITYNAME_AR": "الحمامه",
    "CITYNAME_EN": "Al Hamamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13066",
    "CITYNAME_AR": "الرقيات",
    "CITYNAME_EN": "Ar-Ruqayat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13067",
    "CITYNAME_AR": "الحسنه",
    "CITYNAME_EN": "Al Hasanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13068",
    "CITYNAME_AR": "النيمه",
    "CITYNAME_EN": "An-Naymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13069",
    "CITYNAME_AR": "المسند",
    "CITYNAME_EN": "Al Masnad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13070",
    "CITYNAME_AR": "العصم",
    "CITYNAME_EN": "Al Usum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13071",
    "CITYNAME_AR": "السهوى",
    "CITYNAME_EN": "As-Sahwi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13072",
    "CITYNAME_AR": "الحناحنه",
    "CITYNAME_EN": "Al Hanahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13073",
    "CITYNAME_AR": "النصباء",
    "CITYNAME_EN": "An-Nasbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13074",
    "CITYNAME_AR": "ساسا",
    "CITYNAME_EN": "Sasa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13075",
    "CITYNAME_AR": "العنبه",
    "CITYNAME_EN": "Al Enabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13076",
    "CITYNAME_AR": "الوهبا العليا",
    "CITYNAME_EN": "Al Wahba Al Olya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13077",
    "CITYNAME_AR": "الوهبا السفلى (القراة)",
    "CITYNAME_EN": "Al Wahba As-Sufla (Al Qurah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13078",
    "CITYNAME_AR": "البرت",
    "CITYNAME_EN": "Albert",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13079",
    "CITYNAME_AR": "المغرس",
    "CITYNAME_EN": "Al Mughres",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13080",
    "CITYNAME_AR": "الصواغه",
    "CITYNAME_EN": "As-Sawaghah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13081",
    "CITYNAME_AR": "الحجلا العليا ( القعره)",
    "CITYNAME_EN": "Al Hajla Al Olya (Al Quarah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13082",
    "CITYNAME_AR": "الحجلا السفلى",
    "CITYNAME_EN": "Al Hajla As-Sufla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13083",
    "CITYNAME_AR": "السدين",
    "CITYNAME_EN": "As-Saden",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13084",
    "CITYNAME_AR": "الدار الحمراء",
    "CITYNAME_EN": "Ad-Dar Al Hamraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13085",
    "CITYNAME_AR": "الخليطى",
    "CITYNAME_EN": "Al Khaleti",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13086",
    "CITYNAME_AR": "شعب ساري",
    "CITYNAME_EN": "Shuab Sari",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13087",
    "CITYNAME_AR": "الفصاد",
    "CITYNAME_EN": "Al Fusad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13088",
    "CITYNAME_AR": "القبرين",
    "CITYNAME_EN": "Al Qabrayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13089",
    "CITYNAME_AR": "خلفين",
    "CITYNAME_EN": "Khalfayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13090",
    "CITYNAME_AR": "السلامين",
    "CITYNAME_EN": "As-Salamayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13091",
    "CITYNAME_AR": "الزريزر",
    "CITYNAME_EN": "Az-Zurayzer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13092",
    "CITYNAME_AR": "ابالعتمه",
    "CITYNAME_EN": "Aba Al Atmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13093",
    "CITYNAME_AR": "شعب الصر",
    "CITYNAME_EN": "Shuab As-Sur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13094",
    "CITYNAME_AR": "دار المدره (الشرمة)",
    "CITYNAME_EN": "Dar Al Madarah (Ash-Sharmah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13095",
    "CITYNAME_AR": "الباثه",
    "CITYNAME_EN": "Al Bathah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13096",
    "CITYNAME_AR": "الناله",
    "CITYNAME_EN": "An-Nalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13097",
    "CITYNAME_AR": "قويبه",
    "CITYNAME_EN": "Quwaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13098",
    "CITYNAME_AR": "الصنوع",
    "CITYNAME_EN": "As-Sanua",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13099",
    "CITYNAME_AR": "زبينه (السراحين)",
    "CITYNAME_EN": "Zubaynah (As-Saraheen)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13100",
    "CITYNAME_AR": "الصريف",
    "CITYNAME_EN": "As-Surayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13101",
    "CITYNAME_AR": "العقاره",
    "CITYNAME_EN": "Al Oqarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13102",
    "CITYNAME_AR": "الشعب الابيض",
    "CITYNAME_EN": "Al Shuab Al Abyad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13103",
    "CITYNAME_AR": "خايع",
    "CITYNAME_EN": "Khayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13104",
    "CITYNAME_AR": "عديله (دار الحصن)",
    "CITYNAME_EN": "Odaylah (Dar Al Hesn)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13105",
    "CITYNAME_AR": "المعاقبه",
    "CITYNAME_EN": "Al Muaqebah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13106",
    "CITYNAME_AR": "ابوقوبعه",
    "CITYNAME_EN": "Abo Quwayqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13107",
    "CITYNAME_AR": "بالطريق",
    "CITYNAME_EN": "Baltareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13108",
    "CITYNAME_AR": "ابوجليح",
    "CITYNAME_EN": "Abo Hulaij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13109",
    "CITYNAME_AR": "الجوره",
    "CITYNAME_EN": "Al Jawrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13110",
    "CITYNAME_AR": "عنقه",
    "CITYNAME_EN": "Anaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13111",
    "CITYNAME_AR": "صياده",
    "CITYNAME_EN": "Sayyadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13112",
    "CITYNAME_AR": "هدام",
    "CITYNAME_EN": "Haddam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13113",
    "CITYNAME_AR": "صعبه",
    "CITYNAME_EN": "Saabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13114",
    "CITYNAME_AR": "الملقاه",
    "CITYNAME_EN": "Al Mulqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13115",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Ar-Rahbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13116",
    "CITYNAME_AR": "جياش",
    "CITYNAME_EN": "Jayyash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13117",
    "CITYNAME_AR": "الحلاه",
    "CITYNAME_EN": "Al Halah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13118",
    "CITYNAME_AR": "الحليه",
    "CITYNAME_EN": "Al Halyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13119",
    "CITYNAME_AR": "غار",
    "CITYNAME_EN": "Ghar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13120",
    "CITYNAME_AR": "الحسكه",
    "CITYNAME_EN": "Al Hasakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13121",
    "CITYNAME_AR": "ابو صقعة",
    "CITYNAME_EN": "Abo Saaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13122",
    "CITYNAME_AR": "وادي عبال",
    "CITYNAME_EN": "Wadi Ebal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13123",
    "CITYNAME_AR": "قاعس",
    "CITYNAME_EN": "Qaes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13124",
    "CITYNAME_AR": "وسيع الوادى",
    "CITYNAME_EN": "Wasei Al Wadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13125",
    "CITYNAME_AR": "مطرف",
    "CITYNAME_EN": "Mutref",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13126",
    "CITYNAME_AR": "النمره (السدره)",
    "CITYNAME_EN": "An-Namerah (As-Sadrah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13127",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13128",
    "CITYNAME_AR": "المزرعه",
    "CITYNAME_EN": "Al Mazraah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13129",
    "CITYNAME_AR": "حلبان",
    "CITYNAME_EN": "Halpan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13130",
    "CITYNAME_AR": "سخوان",
    "CITYNAME_EN": "Sakhwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13131",
    "CITYNAME_AR": "الحجرة",
    "CITYNAME_EN": "Al Hajrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13132",
    "CITYNAME_AR": "السواند",
    "CITYNAME_EN": "Al-Swaned ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13133",
    "CITYNAME_AR": "جانب الرابعه",
    "CITYNAME_EN": "Janeb Al-Rab'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13134",
    "CITYNAME_AR": "الامره",
    "CITYNAME_EN": "Al-Amrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13135",
    "CITYNAME_AR": "جانب البرمه",
    "CITYNAME_EN": "Janib Al-Bermah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13136",
    "CITYNAME_AR": "غطيط",
    "CITYNAME_EN": "Ghatit ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13137",
    "CITYNAME_AR": "ابوالريع",
    "CITYNAME_EN": "Abu Al-Reb' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13138",
    "CITYNAME_AR": "الحواريه",
    "CITYNAME_EN": "Al-Hawariyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13139",
    "CITYNAME_AR": "العصيده السفلى",
    "CITYNAME_EN": "Al-Asidah (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13140",
    "CITYNAME_AR": "العصيده العليا",
    "CITYNAME_EN": "Al-Asidah (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13141",
    "CITYNAME_AR": "قهب العصيده",
    "CITYNAME_EN": "Qahb Al-Asidah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13142",
    "CITYNAME_AR": "الوبره",
    "CITYNAME_EN": "Al-Wabrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13143",
    "CITYNAME_AR": "ريع المكدر",
    "CITYNAME_EN": "Rab' Al-Makder ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13144",
    "CITYNAME_AR": "ريع القطاع",
    "CITYNAME_EN": "Rab' Al-Qeta' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13145",
    "CITYNAME_AR": "سوق الحجره",
    "CITYNAME_EN": "Souq Al-hejrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13146",
    "CITYNAME_AR": "حدبه صياح",
    "CITYNAME_EN": "Hadbah Sayah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13147",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Al-Dayqah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13148",
    "CITYNAME_AR": "ابن جباع",
    "CITYNAME_EN": "Ibn Jeba' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13149",
    "CITYNAME_AR": "حنكه الضميده",
    "CITYNAME_EN": "Hankah Al-Damidah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13150",
    "CITYNAME_AR": "القزاع",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13151",
    "CITYNAME_AR": "المولجه",
    "CITYNAME_EN": "Al-Muljah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13152",
    "CITYNAME_AR": "الصفاح",
    "CITYNAME_EN": "As Safah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13153",
    "CITYNAME_AR": "المعوله",
    "CITYNAME_EN": "Al-Ma'ulah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13154",
    "CITYNAME_AR": "الدقمين",
    "CITYNAME_EN": "Al-Daqmin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13155",
    "CITYNAME_AR": "عارض شراز",
    "CITYNAME_EN": "Ared Sheraz ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13156",
    "CITYNAME_AR": "البهيمه",
    "CITYNAME_EN": "Al-Bahimah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13157",
    "CITYNAME_AR": "قعيشم",
    "CITYNAME_EN": "Qa'ishem ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13158",
    "CITYNAME_AR": "قريه ابو شطيره",
    "CITYNAME_EN": "Abu Shatirah Village ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13159",
    "CITYNAME_AR": "ابو مدين",
    "CITYNAME_EN": "Abu Madin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13160",
    "CITYNAME_AR": "الترايغه",
    "CITYNAME_EN": "Al-Tarayghah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13161",
    "CITYNAME_AR": "عنزين",
    "CITYNAME_EN": "Anzin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13162",
    "CITYNAME_AR": "الفاتح",
    "CITYNAME_EN": "Al-Fatih",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13163",
    "CITYNAME_AR": "العوجاء",
    "CITYNAME_EN": "Al Awjaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13164",
    "CITYNAME_AR": "قرن عطيه",
    "CITYNAME_EN": "Qarn Atiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13165",
    "CITYNAME_AR": "دقم الخريق",
    "CITYNAME_EN": "Daqm Al-Khariq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13166",
    "CITYNAME_AR": "الجهله",
    "CITYNAME_EN": "Al-Jahlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13167",
    "CITYNAME_AR": "ابو الكراوين",
    "CITYNAME_EN": "Abu Al-Karawin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13168",
    "CITYNAME_AR": "قرن عامر",
    "CITYNAME_EN": "Qarn Amer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13169",
    "CITYNAME_AR": "السحارى",
    "CITYNAME_EN": "Al-Sahara ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13170",
    "CITYNAME_AR": "مروان",
    "CITYNAME_EN": "Marwan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13171",
    "CITYNAME_AR": "مسعده",
    "CITYNAME_EN": "Mes'edah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13172",
    "CITYNAME_AR": "عبلاء ام السدره",
    "CITYNAME_EN": "Ablaa Umm Al-Sedrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13173",
    "CITYNAME_AR": "حنكه ابوالعقارب",
    "CITYNAME_EN": "Henkah Abu Al-Aqarib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13174",
    "CITYNAME_AR": "صور الهطلان",
    "CITYNAME_EN": "Sur Al-Hatlan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13175",
    "CITYNAME_AR": "العقب",
    "CITYNAME_EN": "Al-Aqb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13176",
    "CITYNAME_AR": "المضايف",
    "CITYNAME_EN": "Al-Madyif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13177",
    "CITYNAME_AR": "المحجاه",
    "CITYNAME_EN": "Al-Mehjah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13178",
    "CITYNAME_AR": "الصفوات",
    "CITYNAME_EN": "Al-Safwat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13179",
    "CITYNAME_AR": "الدحله الحنكه",
    "CITYNAME_EN": "Al-Dahlah Al-Hankah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13180",
    "CITYNAME_AR": "حبس السبقيه",
    "CITYNAME_EN": "Habs Al-Sabqiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13181",
    "CITYNAME_AR": "الحاوى",
    "CITYNAME_EN": "Al-Hawy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13182",
    "CITYNAME_AR": "ابوالقتاده",
    "CITYNAME_EN": "Abu Al-Qetadah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13183",
    "CITYNAME_AR": "المنظر",
    "CITYNAME_EN": "Al-Manzer",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13184",
    "CITYNAME_AR": "ابن غراء",
    "CITYNAME_EN": "Ibn Gheraa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13185",
    "CITYNAME_AR": "عقبه",
    "CITYNAME_EN": "Aqbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13186",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13187",
    "CITYNAME_AR": "مدسه",
    "CITYNAME_EN": "Madassah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13188",
    "CITYNAME_AR": "ابو المروه",
    "CITYNAME_EN": "Abu Al-Marwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13189",
    "CITYNAME_AR": "القنبوع",
    "CITYNAME_EN": "Al-Qanbu' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13190",
    "CITYNAME_AR": "خريق الحمسه",
    "CITYNAME_EN": "Khariq Al-hamsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13191",
    "CITYNAME_AR": "المجارى",
    "CITYNAME_EN": "Al-Majary ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13192",
    "CITYNAME_AR": "صعايد",
    "CITYNAME_EN": "Sa'yid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13193",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al-Khalif",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13194",
    "CITYNAME_AR": "قرن الطائف",
    "CITYNAME_EN": "Qarn Al-Taef ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13195",
    "CITYNAME_AR": "زحاف",
    "CITYNAME_EN": "Zahaf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13196",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Al-Shi'bah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13197",
    "CITYNAME_AR": "جنيده",
    "CITYNAME_EN": "Jonaydah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13198",
    "CITYNAME_AR": "كتيب",
    "CITYNAME_EN": "Katib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13199",
    "CITYNAME_AR": "الفارع",
    "CITYNAME_EN": "Al-Fari'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13200",
    "CITYNAME_AR": "خريق سعد",
    "CITYNAME_EN": "Khariq Saad ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13201",
    "CITYNAME_AR": "الباثه",
    "CITYNAME_EN": "Al Bathah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13202",
    "CITYNAME_AR": "قرن الحدق",
    "CITYNAME_EN": "Qarn Al-Hadq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13203",
    "CITYNAME_AR": "ابوالرشيده",
    "CITYNAME_EN": "Abu Al-Rashidah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13204",
    "CITYNAME_AR": "قهب المحرق",
    "CITYNAME_EN": "Qahb Al-Mehriq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13205",
    "CITYNAME_AR": "النصبا",
    "CITYNAME_EN": "Al-Nasbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13206",
    "CITYNAME_AR": "بلاد موسى",
    "CITYNAME_EN": "Belad Mousa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13207",
    "CITYNAME_AR": "الخاشه",
    "CITYNAME_EN": "Al-Khashah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13208",
    "CITYNAME_AR": "الغبراء",
    "CITYNAME_EN": "Al-Ghabraa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13209",
    "CITYNAME_AR": "سيلب",
    "CITYNAME_EN": "Silb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13210",
    "CITYNAME_AR": "فاطش",
    "CITYNAME_EN": "fatish ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13211",
    "CITYNAME_AR": "البيت الجديد",
    "CITYNAME_EN": "Al-Bayt Al-Jadid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13212",
    "CITYNAME_AR": "القريع",
    "CITYNAME_EN": "Al-Qari'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13213",
    "CITYNAME_AR": "جليس",
    "CITYNAME_EN": "Jelis ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13214",
    "CITYNAME_AR": "زعره",
    "CITYNAME_EN": "Za'rah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13215",
    "CITYNAME_AR": "ابوالدحله",
    "CITYNAME_EN": "Abu Al-Dahlah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13216",
    "CITYNAME_AR": "سند الدف والمعاقد",
    "CITYNAME_EN": "Sand Al-Dof and Al-Ma'aqid ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13217",
    "CITYNAME_AR": "الاحيرش",
    "CITYNAME_EN": "Al-Ahayrish ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13218",
    "CITYNAME_AR": "الدقم",
    "CITYNAME_EN": "Al-Daqm",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13219",
    "CITYNAME_AR": "الاعصيمات",
    "CITYNAME_EN": "Al-Osaymat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13220",
    "CITYNAME_AR": "المعقد",
    "CITYNAME_EN": "Al-Ma'qad",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13221",
    "CITYNAME_AR": "لفقه ومطروح",
    "CITYNAME_EN": "Lafqah and Matrouh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13222",
    "CITYNAME_AR": "حماده",
    "CITYNAME_EN": "Hamadah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13223",
    "CITYNAME_AR": "محل الاصانقه",
    "CITYNAME_EN": "Mahal Al-Sanqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13224",
    "CITYNAME_AR": "مخشوشه",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13225",
    "CITYNAME_AR": "ضبيعه",
    "CITYNAME_EN": "Debyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13226",
    "CITYNAME_AR": "الحصمه",
    "CITYNAME_EN": "Al-Hamsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13227",
    "CITYNAME_AR": "محل عطيان والحفرات",
    "CITYNAME_EN": "Mahal Otyan and Al-Hafrat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13228",
    "CITYNAME_AR": "الفلجه",
    "CITYNAME_EN": "Al-Faljah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13229",
    "CITYNAME_AR": "الخطام",
    "CITYNAME_EN": "Al-Khetam",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13230",
    "CITYNAME_AR": "ابوالبير",
    "CITYNAME_EN": "Abu Al-Bir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13231",
    "CITYNAME_AR": "خريق الروزه",
    "CITYNAME_EN": "Khariq Al-Rawzah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13232",
    "CITYNAME_AR": "المقمره",
    "CITYNAME_EN": "Al-Maqmarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13233",
    "CITYNAME_AR": "البزياء",
    "CITYNAME_EN": "Al-Bazbaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13234",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Al-Dars",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13235",
    "CITYNAME_AR": "السمحه",
    "CITYNAME_EN": "Al-Samhah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13236",
    "CITYNAME_AR": "اصل ابو فلاح",
    "CITYNAME_EN": "Asl Abo Felah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13237",
    "CITYNAME_AR": "الصلال",
    "CITYNAME_EN": "Al Selal",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13238",
    "CITYNAME_AR": "ابن محضره",
    "CITYNAME_EN": "Ibn Mahdarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13239",
    "CITYNAME_AR": "القحيم",
    "CITYNAME_EN": "Al-Qahim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13240",
    "CITYNAME_AR": "الصماء",
    "CITYNAME_EN": "Al-Samaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13241",
    "CITYNAME_AR": "الحجران",
    "CITYNAME_EN": "Al-Hajran ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13242",
    "CITYNAME_AR": "عوك",
    "CITYNAME_EN": "Awk ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13243",
    "CITYNAME_AR": "حبس الحقيبه",
    "CITYNAME_EN": "Habs Al-Haqibah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13244",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13245",
    "CITYNAME_AR": "قعيان",
    "CITYNAME_EN": "Qa'yan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13246",
    "CITYNAME_AR": "حجن",
    "CITYNAME_EN": "Hajn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13247",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Al-Rab'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13248",
    "CITYNAME_AR": "قرى الغراب",
    "CITYNAME_EN": "Qura Al-Ghorab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13249",
    "CITYNAME_AR": "الرهوان",
    "CITYNAME_EN": "Ar Rahwan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13250",
    "CITYNAME_AR": "الفيشه",
    "CITYNAME_EN": "Al-Fishah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13251",
    "CITYNAME_AR": "المرخه العليا",
    "CITYNAME_EN": "Al-Markhah (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13252",
    "CITYNAME_AR": "حبس الحاوى",
    "CITYNAME_EN": "Hbs Al-Hawy",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13253",
    "CITYNAME_AR": "الجناب",
    "CITYNAME_EN": "Al-Janab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13254",
    "CITYNAME_AR": "حضن",
    "CITYNAME_EN": " Hodn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13255",
    "CITYNAME_AR": "شعب الحمار",
    "CITYNAME_EN": "Sha'b Al-homar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13256",
    "CITYNAME_AR": "حبس الفيوق",
    "CITYNAME_EN": "Habs Al-fyuQ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13257",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Qaz'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13258",
    "CITYNAME_AR": "سند المليح",
    "CITYNAME_EN": "Sanad Al-maleh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13259",
    "CITYNAME_AR": "المرخه السفلى",
    "CITYNAME_EN": "Al-markhah  (the lawer)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13260",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Al-Merbaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13261",
    "CITYNAME_AR": "المقيقى",
    "CITYNAME_EN": "Al-Maqeqy",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13262",
    "CITYNAME_AR": "القرانه",
    "CITYNAME_EN": "Al-Qaranah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13263",
    "CITYNAME_AR": "اللويه",
    "CITYNAME_EN": "Alleweh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13264",
    "CITYNAME_AR": "حبس على ابن يحيى",
    "CITYNAME_EN": "Habs ali ibn yahia",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13265",
    "CITYNAME_AR": "قريه ابو الرعود",
    "CITYNAME_EN": "Abu Al-ro'ud Village",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13266",
    "CITYNAME_AR": "قريه ساعد",
    "CITYNAME_EN": "Saed village",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13267",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Al-Nab'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13268",
    "CITYNAME_AR": "الحبوه",
    "CITYNAME_EN": "Al Habwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13269",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al-Waljah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13270",
    "CITYNAME_AR": "قرن العسل",
    "CITYNAME_EN": "Qarn Al-'asal",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13271",
    "CITYNAME_AR": "ريع السوق",
    "CITYNAME_EN": "Rab' Al-souq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13272",
    "CITYNAME_AR": "حبس المسروح",
    "CITYNAME_EN": "Habs Al-masroh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13273",
    "CITYNAME_AR": "الصنم",
    "CITYNAME_EN": "Al-Sanam",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13274",
    "CITYNAME_AR": "قريه ابوهبيره",
    "CITYNAME_EN": "Abo habirah village",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13275",
    "CITYNAME_AR": "حاره العبيد",
    "CITYNAME_EN": "Harah Al-'beed",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13276",
    "CITYNAME_AR": "قرى الصفى",
    "CITYNAME_EN": "Qura Al-safa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13277",
    "CITYNAME_AR": "حنكه الباثه",
    "CITYNAME_EN": "Hankah Al-Bathah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13278",
    "CITYNAME_AR": "الملتقى",
    "CITYNAME_EN": "Al-Malaqy",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13279",
    "CITYNAME_AR": "دقم بهشه",
    "CITYNAME_EN": "Dqm Bahsah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13280",
    "CITYNAME_AR": "الخرب",
    "CITYNAME_EN": "Al-Khrib",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13281",
    "CITYNAME_AR": "دقم حجر رعى",
    "CITYNAME_EN": "Deqm Hejr Ra'y ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13282",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13283",
    "CITYNAME_AR": "فضاله السفلى",
    "CITYNAME_EN": "Fadalah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13284",
    "CITYNAME_AR": "الخاشع (حصن البير)",
    "CITYNAME_EN": "Al-Khasi' (Hisn Al-Bir)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13285",
    "CITYNAME_AR": "بيت الحبس",
    "CITYNAME_EN": "Al-Habs House ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13286",
    "CITYNAME_AR": "حبس المعديات",
    "CITYNAME_EN": "Habs Al-Me'yat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13287",
    "CITYNAME_AR": "محرقه (فضاله العليا)",
    "CITYNAME_EN": "Mahraqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13288",
    "CITYNAME_AR": "العقب",
    "CITYNAME_EN": "Al-Aqb",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13289",
    "CITYNAME_AR": "شعير",
    "CITYNAME_EN": "Barley",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13290",
    "CITYNAME_AR": "الغبراء",
    "CITYNAME_EN": "Al-Ghabraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13291",
    "CITYNAME_AR": "الصريف",
    "CITYNAME_EN": "Al-Sarif ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13292",
    "CITYNAME_AR": "ابو شواك",
    "CITYNAME_EN": "Abu Shwak ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13293",
    "CITYNAME_AR": "قرعه",
    "CITYNAME_EN": "Qar'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13294",
    "CITYNAME_AR": "الكلبات",
    "CITYNAME_EN": "Al Kalbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13295",
    "CITYNAME_AR": "الفصائل",
    "CITYNAME_EN": "Al Fasael ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13296",
    "CITYNAME_AR": "شعب الجيلاني",
    "CITYNAME_EN": "She'b Al-Jilany ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13297",
    "CITYNAME_AR": "قرى المجنه",
    "CITYNAME_EN": "Al-Magnah Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13298",
    "CITYNAME_AR": "الجرين",
    "CITYNAME_EN": "Al Jurain",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13299",
    "CITYNAME_AR": "عرض وجعه",
    "CITYNAME_EN": "Ard and Je'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13300",
    "CITYNAME_AR": "الصليله",
    "CITYNAME_EN": "Al-Salilah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13301",
    "CITYNAME_AR": "المحظره",
    "CITYNAME_EN": "Al-Mehzarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13302",
    "CITYNAME_AR": "الاغصان",
    "CITYNAME_EN": "Al-Aghsan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13303",
    "CITYNAME_AR": "كيسان",
    "CITYNAME_EN": "Kisan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13304",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al-Hariqah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13305",
    "CITYNAME_AR": "مدايع",
    "CITYNAME_EN": "Madayi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13306",
    "CITYNAME_AR": "اصل الخياله",
    "CITYNAME_EN": "Asl Al-Khayalah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13307",
    "CITYNAME_AR": "الذهب",
    "CITYNAME_EN": "Al-Zahb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13308",
    "CITYNAME_AR": "كدره ظلافه",
    "CITYNAME_EN": "Zelafah Kedrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13309",
    "CITYNAME_AR": "الطينة",
    "CITYNAME_EN": "Al-Tinah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13310",
    "CITYNAME_AR": "ثمن",
    "CITYNAME_EN": "Thamn ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13311",
    "CITYNAME_AR": "المربى",
    "CITYNAME_EN": "Al-Merba",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13312",
    "CITYNAME_AR": "ذات جليل",
    "CITYNAME_EN": "Zat Jalil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13313",
    "CITYNAME_AR": "الاعبل",
    "CITYNAME_EN": "Al-A'bal ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13314",
    "CITYNAME_AR": "الوتيد بكرش",
    "CITYNAME_EN": "Al-Watid in Kersh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13315",
    "CITYNAME_AR": "عرضان الراكه (دف حميضة)",
    "CITYNAME_EN": "Erdan Al-Rakah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13316",
    "CITYNAME_AR": "ملحتين",
    "CITYNAME_EN": "Malhatin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13317",
    "CITYNAME_AR": "الامري",
    "CITYNAME_EN": "Al-Amrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13318",
    "CITYNAME_AR": "وادى مدع(موقع عام)",
    "CITYNAME_EN": "Wadi Med' (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13319",
    "CITYNAME_AR": "المسمور",
    "CITYNAME_EN": "Al-Mosmur ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13320",
    "CITYNAME_AR": "ام القعشر",
    "CITYNAME_EN": "Umm Al-Qash'ar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13321",
    "CITYNAME_AR": "هفان",
    "CITYNAME_EN": "hafan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13322",
    "CITYNAME_AR": "عصير",
    "CITYNAME_EN": "Asir ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13323",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Al-Nab'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13324",
    "CITYNAME_AR": "القدر",
    "CITYNAME_EN": "Al-Qadr",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13325",
    "CITYNAME_AR": "حنكه الصعاب",
    "CITYNAME_EN": "Hankah Al-Se'ab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13326",
    "CITYNAME_AR": "ابن كحيلان",
    "CITYNAME_EN": "Ibn kahilan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13327",
    "CITYNAME_AR": "قرين ساريه",
    "CITYNAME_EN": "Qarin Sariyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13328",
    "CITYNAME_AR": "المهدمه",
    "CITYNAME_EN": "Al-Mahdamah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13329",
    "CITYNAME_AR": "قوبيع",
    "CITYNAME_EN": "Qubi' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13330",
    "CITYNAME_AR": "اللج مليح",
    "CITYNAME_EN": "Allj malih ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13331",
    "CITYNAME_AR": "حدبه العوجاء",
    "CITYNAME_EN": "Hadbah Al-Awjaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13332",
    "CITYNAME_AR": "الشويكاء",
    "CITYNAME_EN": "Al-Shuekaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13333",
    "CITYNAME_AR": "المعين",
    "CITYNAME_EN": "Al-Ma'in",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13334",
    "CITYNAME_AR": "النبغه",
    "CITYNAME_EN": "Al-Nabghah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13335",
    "CITYNAME_AR": "الطيبه",
    "CITYNAME_EN": "Al-Tibah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13336",
    "CITYNAME_AR": "وادى المحجاه(موقع عام)",
    "CITYNAME_EN": "Wadi Al-Mehjah (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13337",
    "CITYNAME_AR": "قرن ابو الصرحه",
    "CITYNAME_EN": "Qarn Abu Al-Sarhah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13338",
    "CITYNAME_AR": "وادى جدامه",
    "CITYNAME_EN": "Wadi Jedamah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13339",
    "CITYNAME_AR": "بئر بحري",
    "CITYNAME_EN": "Bir Bahry ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13340",
    "CITYNAME_AR": "فاجه",
    "CITYNAME_EN": "Fajah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13341",
    "CITYNAME_AR": "مربخ",
    "CITYNAME_EN": "Marikh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13342",
    "CITYNAME_AR": "الشريج",
    "CITYNAME_EN": "Al-Sherij",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13343",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Al-Khaimah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13344",
    "CITYNAME_AR": "الخالدية",
    "CITYNAME_EN": "Al Khalidyyah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13345",
    "CITYNAME_AR": "ام الصوان",
    "CITYNAME_EN": "Umm Al-Suwan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13346",
    "CITYNAME_AR": "الحوج",
    "CITYNAME_EN": "Al-Huj",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13347",
    "CITYNAME_AR": "ام الاجراف",
    "CITYNAME_EN": "Umm Al-Ajraf ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13348",
    "CITYNAME_AR": "شعب سيال(موقع عام)",
    "CITYNAME_EN": "She'b Seyal (public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13349",
    "CITYNAME_AR": "دقم الشديده",
    "CITYNAME_EN": "Doqm Al-Shadidah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13350",
    "CITYNAME_AR": "الخفش",
    "CITYNAME_EN": "Al-Khafsh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13351",
    "CITYNAME_AR": "الحايره",
    "CITYNAME_EN": "Al-Hayrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13352",
    "CITYNAME_AR": "شبيل",
    "CITYNAME_EN": "Shabil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13353",
    "CITYNAME_AR": "خدور ذي ورق(الباطن)",
    "CITYNAME_EN": "Khudor zi warq (Al-Batin)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13354",
    "CITYNAME_AR": "قوع",
    "CITYNAME_EN": "Qu' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13355",
    "CITYNAME_AR": "الحوشه",
    "CITYNAME_EN": "Al-Hushah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13356",
    "CITYNAME_AR": "العليليه",
    "CITYNAME_EN": "Al-Aliliyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13357",
    "CITYNAME_AR": "رضعان",
    "CITYNAME_EN": "Rad'an",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13358",
    "CITYNAME_AR": "ريع السفاهيه",
    "CITYNAME_EN": "Rab' Al-Safahiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13359",
    "CITYNAME_AR": "الاعلى",
    "CITYNAME_EN": "Al-A'la ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13360",
    "CITYNAME_AR": "حبس المجوبه",
    "CITYNAME_EN": "Habs Al-Majubah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13361",
    "CITYNAME_AR": "ذات الظبر",
    "CITYNAME_EN": "Zat Al-Zebr ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13362",
    "CITYNAME_AR": "المعرم",
    "CITYNAME_EN": "Al-Ma'rem ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13363",
    "CITYNAME_AR": "القرنوه",
    "CITYNAME_EN": "Al-Qarnwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13364",
    "CITYNAME_AR": "شعيب ذنائب الشمالى",
    "CITYNAME_EN": "Sho'ayb Zanaeb Al-Shamaly ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13365",
    "CITYNAME_AR": "البشيماء",
    "CITYNAME_EN": "Al-Boshaymaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13366",
    "CITYNAME_AR": "المقطع",
    "CITYNAME_EN": "Al-Maqta'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13367",
    "CITYNAME_AR": "شعابه الغر",
    "CITYNAME_EN": "She'abah Al-Gher ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13368",
    "CITYNAME_AR": "شعب كلب",
    "CITYNAME_EN": "She'b Kalb ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13369",
    "CITYNAME_AR": "قرى الدار",
    "CITYNAME_EN": "Al-Dar Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13370",
    "CITYNAME_AR": "الجبله",
    "CITYNAME_EN": "Al-Jablah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13371",
    "CITYNAME_AR": "الكببه",
    "CITYNAME_EN": "Al-Kabbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13372",
    "CITYNAME_AR": "ضابى",
    "CITYNAME_EN": "Dabi",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13373",
    "CITYNAME_AR": "الهدماء",
    "CITYNAME_EN": "Al-Hedmaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13374",
    "CITYNAME_AR": "ذات المقر",
    "CITYNAME_EN": "Zat Al-Maqer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13375",
    "CITYNAME_AR": "الضاحي",
    "CITYNAME_EN": "Al-Dahi ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13376",
    "CITYNAME_AR": "قزعه الغراب",
    "CITYNAME_EN": "Qaz'ah Al-Ghorab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13377",
    "CITYNAME_AR": "المشرع",
    "CITYNAME_EN": "Al-Mashra'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13378",
    "CITYNAME_AR": "حبس الحوايل",
    "CITYNAME_EN": "Habs Al-Hawayil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13379",
    "CITYNAME_AR": "الجندله",
    "CITYNAME_EN": "Al-Jandalah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13380",
    "CITYNAME_AR": "المنكب",
    "CITYNAME_EN": "Al-Mankab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13381",
    "CITYNAME_AR": "البرطيخ",
    "CITYNAME_EN": "Al-Bartikh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13382",
    "CITYNAME_AR": "بيت حسين",
    "CITYNAME_EN": "Hussein House ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13383",
    "CITYNAME_AR": "ال صرار",
    "CITYNAME_EN": "Al Serar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13384",
    "CITYNAME_AR": "الدحظ",
    "CITYNAME_EN": "Al-Dahz",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13385",
    "CITYNAME_AR": "دقم حصين",
    "CITYNAME_EN": "Doqm Hasin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13386",
    "CITYNAME_AR": "خبه",
    "CITYNAME_EN": "Khabbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13387",
    "CITYNAME_AR": "طلان",
    "CITYNAME_EN": "Talan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13388",
    "CITYNAME_AR": "قاعس",
    "CITYNAME_EN": "Qa'is ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13389",
    "CITYNAME_AR": "ال سنامه",
    "CITYNAME_EN": "Al Sanamah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13390",
    "CITYNAME_AR": "قرى ال حشيف",
    "CITYNAME_EN": "Al Hashif villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13391",
    "CITYNAME_AR": "قريعه",
    "CITYNAME_EN": "Qari'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13392",
    "CITYNAME_AR": "شعب الحمر",
    "CITYNAME_EN": "Sha'b Al-homar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13393",
    "CITYNAME_AR": "اللج وسندالضلع",
    "CITYNAME_EN": "Allj and Sand Al-Del'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13394",
    "CITYNAME_AR": "القرعه",
    "CITYNAME_EN": "Al Qar'a",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13395",
    "CITYNAME_AR": "سند حزام",
    "CITYNAME_EN": "Send hezam ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13396",
    "CITYNAME_AR": "الرضعه",
    "CITYNAME_EN": "Al-Redah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13397",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Al Juwar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13398",
    "CITYNAME_AR": "خاشع دثينه",
    "CITYNAME_EN": "Khashi' Dathinah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13399",
    "CITYNAME_AR": "السطيحه",
    "CITYNAME_EN": "Al-Satihah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13400",
    "CITYNAME_AR": "غطيط",
    "CITYNAME_EN": "Ghatit",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13401",
    "CITYNAME_AR": "مشنيه بيت احمدالبهيجه",
    "CITYNAME_EN": "Mashniyah Bit Ahmed Al-Bahijah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13402",
    "CITYNAME_AR": "قرى السد",
    "CITYNAME_EN": "Al-Sedd Villages ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13403",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13404",
    "CITYNAME_AR": "حواز",
    "CITYNAME_EN": "Hawaz",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13405",
    "CITYNAME_AR": "قرى الغبران",
    "CITYNAME_EN": "Qura Al-Ghebran ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13406",
    "CITYNAME_AR": "المحنى",
    "CITYNAME_EN": "Al-Mehna ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13407",
    "CITYNAME_AR": "الزرعه",
    "CITYNAME_EN": "Al-Zar'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13408",
    "CITYNAME_AR": "حنكه الروبه",
    "CITYNAME_EN": "Hankah Al-Rubah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13409",
    "CITYNAME_AR": "حنكه بادى",
    "CITYNAME_EN": "Hankah Badi ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13410",
    "CITYNAME_AR": "شعب الدار",
    "CITYNAME_EN": "She'b Al-Dar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13411",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13412",
    "CITYNAME_AR": "الشعبين",
    "CITYNAME_EN": "Al-She'bayn",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13413",
    "CITYNAME_AR": "الكيله",
    "CITYNAME_EN": "Al-Kilah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13414",
    "CITYNAME_AR": "شصا",
    "CITYNAME_EN": "Shasa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13415",
    "CITYNAME_AR": "الجاره",
    "CITYNAME_EN": "Al-Jarah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13416",
    "CITYNAME_AR": "الثدى",
    "CITYNAME_EN": "Al-Thady ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13417",
    "CITYNAME_AR": "بني عطا",
    "CITYNAME_EN": "Bani Atta",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13418",
    "CITYNAME_AR": "حبس المرايغ",
    "CITYNAME_EN": "Habs Al-Marayigh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13419",
    "CITYNAME_AR": "حبس الخاشع",
    "CITYNAME_EN": "Habs Al-Khashi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13420",
    "CITYNAME_AR": "العيصمات",
    "CITYNAME_EN": "Al-Osaymat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13421",
    "CITYNAME_AR": "حبس الأشرم",
    "CITYNAME_EN": "Habs Al-Ashram ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13422",
    "CITYNAME_AR": "ابو المعاصب",
    "CITYNAME_EN": "Abu Al-Ma'asib ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13423",
    "CITYNAME_AR": "ام الضهيانة",
    "CITYNAME_EN": "Umm Al-Dahyanah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13424",
    "CITYNAME_AR": "قرية دنفان",
    "CITYNAME_EN": "Denfan Vilage ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13425",
    "CITYNAME_AR": "خريق الصيد",
    "CITYNAME_EN": "Khariq Al-Sayd ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "13426",
    "CITYNAME_AR": "حصن عقيرب",
    "CITYNAME_EN": "Hisn Eqirib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "13427",
    "CITYNAME_AR": "حفر الباطن",
    "CITYNAME_EN": "Hafar Al-Batin",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13428",
    "CITYNAME_AR": "درب الابل",
    "CITYNAME_EN": "Darb Al-Ibil ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13429",
    "CITYNAME_AR": "القلت",
    "CITYNAME_EN": "Al Qalt",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13430",
    "CITYNAME_AR": "الرقعى",
    "CITYNAME_EN": "Ar Ruqi",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13431",
    "CITYNAME_AR": "الرقعى الجديده",
    "CITYNAME_EN": "Al-Roq'a Al-Jadidah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13432",
    "CITYNAME_AR": "النظيم",
    "CITYNAME_EN": "Al Nadhim",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13433",
    "CITYNAME_AR": "الحماطيات",
    "CITYNAME_EN": "Al Hamatiyat",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13434",
    "CITYNAME_AR": "الصفيرى",
    "CITYNAME_EN": "As Sufayri",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13435",
    "CITYNAME_AR": "السلمانية",
    "CITYNAME_EN": "Salmaniya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13436",
    "CITYNAME_AR": "رخيه",
    "CITYNAME_EN": "Rkhih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13437",
    "CITYNAME_AR": "ذا الجار",
    "CITYNAME_EN": "Dha Aljar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13438",
    "CITYNAME_AR": "عنكب",
    "CITYNAME_EN": "Eankab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13439",
    "CITYNAME_AR": "الغريثه",
    "CITYNAME_EN": "Alqarithuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13440",
    "CITYNAME_AR": "المرماء",
    "CITYNAME_EN": "Almarma'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13441",
    "CITYNAME_AR": "الشرج",
    "CITYNAME_EN": "Alsharaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13442",
    "CITYNAME_AR": "أوعير",
    "CITYNAME_EN": "'Aweir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13443",
    "CITYNAME_AR": "ام حفاه",
    "CITYNAME_EN": "Umm Hafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13444",
    "CITYNAME_AR": "معراضى",
    "CITYNAME_EN": "Maeradaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13445",
    "CITYNAME_AR": "شدان",
    "CITYNAME_EN": "Shadan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13446",
    "CITYNAME_AR": "الهرار",
    "CITYNAME_EN": "Alhirar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13447",
    "CITYNAME_AR": "البير",
    "CITYNAME_EN": "Albir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13448",
    "CITYNAME_AR": "مجوف",
    "CITYNAME_EN": "Majuaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13449",
    "CITYNAME_AR": "المقروف",
    "CITYNAME_EN": "Almaqruf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13450",
    "CITYNAME_AR": "المدارج",
    "CITYNAME_EN": "Almadarij",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13451",
    "CITYNAME_AR": "البليسة",
    "CITYNAME_EN": "Albalisa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13452",
    "CITYNAME_AR": "مقطاب",
    "CITYNAME_EN": "Miqtab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13453",
    "CITYNAME_AR": "الجدعاء",
    "CITYNAME_EN": "Aljudea'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13454",
    "CITYNAME_AR": "الرفصه",
    "CITYNAME_EN": "Alrafsuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13455",
    "CITYNAME_AR": "المازن",
    "CITYNAME_EN": "Almazin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13456",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Alhamatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13457",
    "CITYNAME_AR": "لحوج",
    "CITYNAME_EN": "Luhuj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13458",
    "CITYNAME_AR": "السليعه",
    "CITYNAME_EN": "Alsalieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13459",
    "CITYNAME_AR": "نيد الباه",
    "CITYNAME_EN": "Nyd Albah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13460",
    "CITYNAME_AR": "ظهر الماء",
    "CITYNAME_EN": "Zahar Alma'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13461",
    "CITYNAME_AR": "رحيب",
    "CITYNAME_EN": "Rahib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13462",
    "CITYNAME_AR": "سقاما",
    "CITYNAME_EN": "Saqamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13463",
    "CITYNAME_AR": "المقصارة",
    "CITYNAME_EN": "Almiqsara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13464",
    "CITYNAME_AR": "جوه رخيه",
    "CITYNAME_EN": "Juh Rakhih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13465",
    "CITYNAME_AR": "ضال",
    "CITYNAME_EN": "Dal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13466",
    "CITYNAME_AR": "نذاء",
    "CITYNAME_EN": "Nidha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13467",
    "CITYNAME_AR": "حنوله",
    "CITYNAME_EN": "Hunuluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13468",
    "CITYNAME_AR": "المخاضه",
    "CITYNAME_EN": "Almakhaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13469",
    "CITYNAME_AR": "حثاثل",
    "CITYNAME_EN": "Hathathil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13470",
    "CITYNAME_AR": "الويه",
    "CITYNAME_EN": "Alwayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13471",
    "CITYNAME_AR": "الخطم",
    "CITYNAME_EN": "Alkhatm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13472",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Alsadr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13473",
    "CITYNAME_AR": "القاعه",
    "CITYNAME_EN": "Alqaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13474",
    "CITYNAME_AR": "ولايه",
    "CITYNAME_EN": "Walayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13475",
    "CITYNAME_AR": "الرهوه والطرف",
    "CITYNAME_EN": "Alruhuh Waltarf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13476",
    "CITYNAME_AR": "كريين",
    "CITYNAME_EN": "Kariiyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13477",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Alharjuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13478",
    "CITYNAME_AR": "خيافين",
    "CITYNAME_EN": "Khayafin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13479",
    "CITYNAME_AR": "مصل",
    "CITYNAME_EN": "Musal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13480",
    "CITYNAME_AR": "قفقف",
    "CITYNAME_EN": "Qafaqaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13481",
    "CITYNAME_AR": "زرقه",
    "CITYNAME_EN": "Zaraqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13482",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "Alsahluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13483",
    "CITYNAME_AR": "ام حرجه",
    "CITYNAME_EN": "Harajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13484",
    "CITYNAME_AR": "الشدنات",
    "CITYNAME_EN": "Alshadanat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13485",
    "CITYNAME_AR": "لوبق",
    "CITYNAME_EN": "Lwbiq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13486",
    "CITYNAME_AR": "الخطمات",
    "CITYNAME_EN": "Alkhatamat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13487",
    "CITYNAME_AR": "حيدو",
    "CITYNAME_EN": "Hydu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13488",
    "CITYNAME_AR": "عثلم",
    "CITYNAME_EN": "Ethlam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13489",
    "CITYNAME_AR": "سر",
    "CITYNAME_EN": "Siri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13490",
    "CITYNAME_AR": "زهب صالح",
    "CITYNAME_EN": "Zahib Salih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13491",
    "CITYNAME_AR": "الخزانه",
    "CITYNAME_EN": "Alkhazanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13492",
    "CITYNAME_AR": "محروص",
    "CITYNAME_EN": "Mahrus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13493",
    "CITYNAME_AR": "سلعه",
    "CITYNAME_EN": "Salaeah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13494",
    "CITYNAME_AR": "الرفده",
    "CITYNAME_EN": "Alrifduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13495",
    "CITYNAME_AR": "مدخال",
    "CITYNAME_EN": "Midkhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13496",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Alsharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13497",
    "CITYNAME_AR": "المسانات",
    "CITYNAME_EN": "Almasanat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13498",
    "CITYNAME_AR": "الفالج",
    "CITYNAME_EN": "Alfalij",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13499",
    "CITYNAME_AR": "روغان والمجزاع",
    "CITYNAME_EN": "Rughan Walmijzae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13500",
    "CITYNAME_AR": "جبل شقراء",
    "CITYNAME_EN": "Jabal Shuqara'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13501",
    "CITYNAME_AR": "رحايب",
    "CITYNAME_EN": "Rahayib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13502",
    "CITYNAME_AR": "العروق",
    "CITYNAME_EN": "Aleuruq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13503",
    "CITYNAME_AR": "الجهى",
    "CITYNAME_EN": "Aljuhaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13504",
    "CITYNAME_AR": "منمنه",
    "CITYNAME_EN": "Munmanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13505",
    "CITYNAME_AR": "كزم",
    "CITYNAME_EN": "Kazam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13506",
    "CITYNAME_AR": "عرقوا",
    "CITYNAME_EN": "Eariquu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13507",
    "CITYNAME_AR": "المخادر",
    "CITYNAME_EN": "Almakhadir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13508",
    "CITYNAME_AR": "العرض",
    "CITYNAME_EN": "Aleard",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13509",
    "CITYNAME_AR": "محزم",
    "CITYNAME_EN": "Mahzim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13510",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Alharjuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13511",
    "CITYNAME_AR": "منياد",
    "CITYNAME_EN": "Munyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13512",
    "CITYNAME_AR": "الشاميه",
    "CITYNAME_EN": "Alshaamiyh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13513",
    "CITYNAME_AR": "اطناب",
    "CITYNAME_EN": "Atnab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13514",
    "CITYNAME_AR": "الجادل",
    "CITYNAME_EN": "Aljadil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13515",
    "CITYNAME_AR": "خشم",
    "CITYNAME_EN": "Khashm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13516",
    "CITYNAME_AR": "اعراب",
    "CITYNAME_EN": "Aerab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13517",
    "CITYNAME_AR": "قهبو",
    "CITYNAME_EN": "Qahbu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13518",
    "CITYNAME_AR": "غدران",
    "CITYNAME_EN": "Ghadaran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13519",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Aljuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13520",
    "CITYNAME_AR": "خلصه",
    "CITYNAME_EN": "Khalasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13521",
    "CITYNAME_AR": "الواسطه",
    "CITYNAME_EN": "Alwasituh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13522",
    "CITYNAME_AR": "صومله",
    "CITYNAME_EN": "Sumiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13523",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Alshawahtuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13524",
    "CITYNAME_AR": "لوذ",
    "CITYNAME_EN": "Ludh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13525",
    "CITYNAME_AR": "شرفه",
    "CITYNAME_EN": "Sharfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13526",
    "CITYNAME_AR": "القامه",
    "CITYNAME_EN": "Alqamih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13527",
    "CITYNAME_AR": "الفارس",
    "CITYNAME_EN": "Alfaris",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13528",
    "CITYNAME_AR": "المقبل",
    "CITYNAME_EN": "Almuqbil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13529",
    "CITYNAME_AR": "المحرد",
    "CITYNAME_EN": "Almuharid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13530",
    "CITYNAME_AR": "العوصاء",
    "CITYNAME_EN": "Aleawsa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13531",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13532",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13533",
    "CITYNAME_AR": "القهره",
    "CITYNAME_EN": "Alqahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13534",
    "CITYNAME_AR": "محنى",
    "CITYNAME_EN": "Mahnaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13535",
    "CITYNAME_AR": "حجبين",
    "CITYNAME_EN": "Hujibin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13536",
    "CITYNAME_AR": "ساطع",
    "CITYNAME_EN": "Satie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13537",
    "CITYNAME_AR": "مسمع",
    "CITYNAME_EN": "Musmae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13538",
    "CITYNAME_AR": "هلوه",
    "CITYNAME_EN": "Haluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13539",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13540",
    "CITYNAME_AR": "اوحامر واللحجه",
    "CITYNAME_EN": "Awhamir Wallahjuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13541",
    "CITYNAME_AR": "جوفا",
    "CITYNAME_EN": "Jawfanaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13542",
    "CITYNAME_AR": "مردحه",
    "CITYNAME_EN": "Muradahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13543",
    "CITYNAME_AR": "رايه",
    "CITYNAME_EN": "Rayih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13544",
    "CITYNAME_AR": "حذلو",
    "CITYNAME_EN": "Hadhlu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13545",
    "CITYNAME_AR": "راشده",
    "CITYNAME_EN": "Rashidah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13546",
    "CITYNAME_AR": "الخيمه",
    "CITYNAME_EN": "Alkhaymuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13547",
    "CITYNAME_AR": "السوداء",
    "CITYNAME_EN": "Alsawda'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13548",
    "CITYNAME_AR": "شواء",
    "CITYNAME_EN": "Shawa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13549",
    "CITYNAME_AR": "مريبيه",
    "CITYNAME_EN": "Muribih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13550",
    "CITYNAME_AR": "المنقب",
    "CITYNAME_EN": "Almunaqab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13551",
    "CITYNAME_AR": "الراس",
    "CITYNAME_EN": "Alraas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13552",
    "CITYNAME_AR": "المضبع",
    "CITYNAME_EN": "Almudbae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13553",
    "CITYNAME_AR": "القحده",
    "CITYNAME_EN": "Alqahaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13554",
    "CITYNAME_AR": "ضحياء",
    "CITYNAME_EN": "Dahya'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13555",
    "CITYNAME_AR": "انمي",
    "CITYNAME_EN": "Anmi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13556",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Alshatu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13557",
    "CITYNAME_AR": "المحرثه",
    "CITYNAME_EN": "Almahrithuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13558",
    "CITYNAME_AR": "حدبة حلنفاء",
    "CITYNAME_EN": "Hdbt Hlnfa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13559",
    "CITYNAME_AR": "كدمه",
    "CITYNAME_EN": "Kadamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13560",
    "CITYNAME_AR": "ريدان",
    "CITYNAME_EN": "Rydan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13561",
    "CITYNAME_AR": "قواره الشدنات",
    "CITYNAME_EN": "Qawaruh Alshadunat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13562",
    "CITYNAME_AR": "قطابر",
    "CITYNAME_EN": "Qatabir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13563",
    "CITYNAME_AR": "محفر",
    "CITYNAME_EN": "Muhfar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13564",
    "CITYNAME_AR": "الثافره",
    "CITYNAME_EN": "Althaafiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13565",
    "CITYNAME_AR": "مرويد",
    "CITYNAME_EN": "Marwid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13566",
    "CITYNAME_AR": "قهامو",
    "CITYNAME_EN": "Qahamu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13567",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13568",
    "CITYNAME_AR": "درعه",
    "CITYNAME_EN": "Dareah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13569",
    "CITYNAME_AR": "السحال الاعلى",
    "CITYNAME_EN": "Alsahal Al'Aelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13570",
    "CITYNAME_AR": "السحال الاسفل",
    "CITYNAME_EN": "Alsahal Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13571",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dabae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13572",
    "CITYNAME_AR": "القمات",
    "CITYNAME_EN": "Alqimmat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13573",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Algharafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13574",
    "CITYNAME_AR": "شيبه",
    "CITYNAME_EN": "Shybh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13575",
    "CITYNAME_AR": "الرفاده",
    "CITYNAME_EN": "Alrafaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13576",
    "CITYNAME_AR": "الكنيف",
    "CITYNAME_EN": "Alkanif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13577",
    "CITYNAME_AR": "النصباء",
    "CITYNAME_EN": "Alnusaba'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13578",
    "CITYNAME_AR": "الدمين",
    "CITYNAME_EN": "Aldamin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13579",
    "CITYNAME_AR": "البطله",
    "CITYNAME_EN": "Albataluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13580",
    "CITYNAME_AR": "العروض",
    "CITYNAME_EN": "Aleurud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13581",
    "CITYNAME_AR": "الدعابل",
    "CITYNAME_EN": "Aldaeabul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13582",
    "CITYNAME_AR": "الشدنه",
    "CITYNAME_EN": "Alshadanuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13583",
    "CITYNAME_AR": "القاعه",
    "CITYNAME_EN": "Alqaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13584",
    "CITYNAME_AR": "متلاطف",
    "CITYNAME_EN": "Mutalatif",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13585",
    "CITYNAME_AR": "الصبر",
    "CITYNAME_EN": "Alsabr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13586",
    "CITYNAME_AR": "مطيحه",
    "CITYNAME_EN": "Mutihuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13587",
    "CITYNAME_AR": "هزف",
    "CITYNAME_EN": "Hazaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13588",
    "CITYNAME_AR": "المدار",
    "CITYNAME_EN": "Almadar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13589",
    "CITYNAME_AR": "الجبهاء",
    "CITYNAME_EN": "Aljubiha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13590",
    "CITYNAME_AR": "الغولاء",
    "CITYNAME_EN": "Alghula'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13591",
    "CITYNAME_AR": "القلبيه",
    "CITYNAME_EN": "Alqalabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13592",
    "CITYNAME_AR": "الطرف والهوايه",
    "CITYNAME_EN": "Altaraf Walhawayih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13593",
    "CITYNAME_AR": "نيد القثب",
    "CITYNAME_EN": "Nayd Alqathab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13594",
    "CITYNAME_AR": "الجحر",
    "CITYNAME_EN": "Aljahr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13595",
    "CITYNAME_AR": "الحطبي",
    "CITYNAME_EN": "Alhatabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13596",
    "CITYNAME_AR": "الجميحه",
    "CITYNAME_EN": "Aljamihuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13597",
    "CITYNAME_AR": "المعدات",
    "CITYNAME_EN": "Almaeaddat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13598",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13599",
    "CITYNAME_AR": "الغولاء",
    "CITYNAME_EN": "Albahr Al'Aelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13600",
    "CITYNAME_AR": "البهر الأسفل",
    "CITYNAME_EN": "Albahr Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13601",
    "CITYNAME_AR": "الغبير",
    "CITYNAME_EN": "Alghabir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13602",
    "CITYNAME_AR": "الجحفره",
    "CITYNAME_EN": "Aljahfaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13603",
    "CITYNAME_AR": "المغمر",
    "CITYNAME_EN": "Almaghmir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13604",
    "CITYNAME_AR": "الحوق",
    "CITYNAME_EN": "Alhuq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13605",
    "CITYNAME_AR": "الشدنيه",
    "CITYNAME_EN": "Alshadaniuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13606",
    "CITYNAME_AR": "المجرب",
    "CITYNAME_EN": "Almujrib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13607",
    "CITYNAME_AR": "السفن",
    "CITYNAME_EN": "Alsufun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13608",
    "CITYNAME_AR": "الشباب",
    "CITYNAME_EN": "Alshabab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13609",
    "CITYNAME_AR": "اليرمه",
    "CITYNAME_EN": "Alyaramuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13610",
    "CITYNAME_AR": "مشق",
    "CITYNAME_EN": "Mashaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13611",
    "CITYNAME_AR": "نيد البيت",
    "CITYNAME_EN": "Nayd Albayt",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13612",
    "CITYNAME_AR": "الموقد",
    "CITYNAME_EN": "Almuqid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13613",
    "CITYNAME_AR": "الشيباء",
    "CITYNAME_EN": "Alshayba'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13614",
    "CITYNAME_AR": "حدبة القعساء",
    "CITYNAME_EN": "Hadbat Alquesa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13615",
    "CITYNAME_AR": "العيفقاء",
    "CITYNAME_EN": "Aleifaqa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13616",
    "CITYNAME_AR": "الفلج",
    "CITYNAME_EN": "Alfalaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13617",
    "CITYNAME_AR": "الحوره",
    "CITYNAME_EN": "Alhuruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13618",
    "CITYNAME_AR": "العزات",
    "CITYNAME_EN": "Aleazzat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13619",
    "CITYNAME_AR": "حدبة الغضار",
    "CITYNAME_EN": "Hadabat Alghidar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13620",
    "CITYNAME_AR": "دعبول",
    "CITYNAME_EN": "Daebul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13621",
    "CITYNAME_AR": "خبيره",
    "CITYNAME_EN": "Khabirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13622",
    "CITYNAME_AR": "الجزعه والصاب",
    "CITYNAME_EN": "Aljizeuh Walsaabu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13623",
    "CITYNAME_AR": "غبري",
    "CITYNAME_EN": "Ghabri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13624",
    "CITYNAME_AR": "حقباء",
    "CITYNAME_EN": "Alhaqbuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13625",
    "CITYNAME_AR": "حواء",
    "CITYNAME_EN": "Hawayu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13626",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "Alquraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13627",
    "CITYNAME_AR": "المختبيه",
    "CITYNAME_EN": "Almukhtabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13628",
    "CITYNAME_AR": "المحرس",
    "CITYNAME_EN": "Almuharis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13629",
    "CITYNAME_AR": "المناطه",
    "CITYNAME_EN": "Almanatuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13630",
    "CITYNAME_AR": "مشقر",
    "CITYNAME_EN": "Mushqir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13631",
    "CITYNAME_AR": "الحفر",
    "CITYNAME_EN": "Alhafr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13632",
    "CITYNAME_AR": "القيشباء",
    "CITYNAME_EN": "Alquyashba'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13633",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13634",
    "CITYNAME_AR": "المعامل",
    "CITYNAME_EN": "Almaeamil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13635",
    "CITYNAME_AR": "ام الغرف",
    "CITYNAME_EN": "Alghuraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13636",
    "CITYNAME_AR": "القطيعاء",
    "CITYNAME_EN": "Alqatiea'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13637",
    "CITYNAME_AR": "موجان",
    "CITYNAME_EN": "Mujan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13638",
    "CITYNAME_AR": "معشور",
    "CITYNAME_EN": "Maeshur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13639",
    "CITYNAME_AR": "الحزر",
    "CITYNAME_EN": "Alhuzr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13640",
    "CITYNAME_AR": "مسربه",
    "CITYNAME_EN": "Alsarabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13641",
    "CITYNAME_AR": "المخورم",
    "CITYNAME_EN": "Almukhawram",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13642",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13643",
    "CITYNAME_AR": "الفقع",
    "CITYNAME_EN": "Alfaqae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13644",
    "CITYNAME_AR": "العارض",
    "CITYNAME_EN": "Alearid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13645",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13646",
    "CITYNAME_AR": "جبل القهر",
    "CITYNAME_EN": "Jabal Alqahr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13647",
    "CITYNAME_AR": "مقزع ويشمل",
    "CITYNAME_EN": "Muqazae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13648",
    "CITYNAME_AR": "شقفه",
    "CITYNAME_EN": "Shaqfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13649",
    "CITYNAME_AR": "الحقبة",
    "CITYNAME_EN": "Alhaqba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13650",
    "CITYNAME_AR": "البسه ولجفن",
    "CITYNAME_EN": "Albisih Walijafn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13651",
    "CITYNAME_AR": "طواله",
    "CITYNAME_EN": "Tawaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13652",
    "CITYNAME_AR": "بقة ام بقاق",
    "CITYNAME_EN": "Bqt Umm Baqaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13653",
    "CITYNAME_AR": "صدر الردائم",
    "CITYNAME_EN": "Sadar Alradayim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13654",
    "CITYNAME_AR": "محنى",
    "CITYNAME_EN": "Almahani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13655",
    "CITYNAME_AR": "شجاع",
    "CITYNAME_EN": "Shujae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13656",
    "CITYNAME_AR": "العصمه",
    "CITYNAME_EN": "Aleasmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13657",
    "CITYNAME_AR": "الردحه",
    "CITYNAME_EN": "Alradahuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13658",
    "CITYNAME_AR": "جعارين",
    "CITYNAME_EN": "Jaeaarin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13659",
    "CITYNAME_AR": "الفاضية",
    "CITYNAME_EN": "Alfadia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13660",
    "CITYNAME_AR": "الحمام",
    "CITYNAME_EN": "Alhamam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13661",
    "CITYNAME_AR": "القائم",
    "CITYNAME_EN": "Alqayim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13662",
    "CITYNAME_AR": "الرفغه",
    "CITYNAME_EN": "Alrafghuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13663",
    "CITYNAME_AR": "الوشيله والمعارض",
    "CITYNAME_EN": "Alwashiluh Walmaearid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13664",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Sabrah Walhabil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13665",
    "CITYNAME_AR": "ملاطس",
    "CITYNAME_EN": "Malatis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13666",
    "CITYNAME_AR": "ام سايله",
    "CITYNAME_EN": "Umm Sayilh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13667",
    "CITYNAME_AR": "نطع",
    "CITYNAME_EN": "Nte",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13668",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13669",
    "CITYNAME_AR": "جلال",
    "CITYNAME_EN": "Jalal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13670",
    "CITYNAME_AR": "العجراء",
    "CITYNAME_EN": "Al-Ajrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13671",
    "CITYNAME_AR": "الريث",
    "CITYNAME_EN": "Al Reeth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "13672",
    "CITYNAME_AR": "الصيهد",
    "CITYNAME_EN": "Alsayhad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13673",
    "CITYNAME_AR": "توارن",
    "CITYNAME_EN": "Tawarun",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13674",
    "CITYNAME_AR": "حائل",
    "CITYNAME_EN": "Hayil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13675",
    "CITYNAME_AR": "الصبيحيه",
    "CITYNAME_EN": "Alsabihiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13676",
    "CITYNAME_AR": "مزارع الحدبه",
    "CITYNAME_EN": " Alhadabih Farms",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13677",
    "CITYNAME_AR": "شعيب جو",
    "CITYNAME_EN": "Shueayb Jawin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13678",
    "CITYNAME_AR": "اللجاة",
    "CITYNAME_EN": "Allaja",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13679",
    "CITYNAME_AR": "عقده",
    "CITYNAME_EN": "Eaqadah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13680",
    "CITYNAME_AR": "قفار",
    "CITYNAME_EN": "Qafaar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13681",
    "CITYNAME_AR": "بدائع قفار",
    "CITYNAME_EN": "Bdayie Quffar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13682",
    "CITYNAME_AR": "نقره قفار",
    "CITYNAME_EN": "Naqruh Qafaar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13683",
    "CITYNAME_AR": "مريفق",
    "CITYNAME_EN": "Murifiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13684",
    "CITYNAME_AR": "حمراء الوبير",
    "CITYNAME_EN": "Hamra' Alwabir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13685",
    "CITYNAME_AR": "بدايع مريفق",
    "CITYNAME_EN": "Badayie Muryfiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13686",
    "CITYNAME_AR": "المخططات الشرقيه",
    "CITYNAME_EN": "Almukhatatat Alsharqiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13687",
    "CITYNAME_AR": "النيصيه",
    "CITYNAME_EN": "Alnaysih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13688",
    "CITYNAME_AR": "الجثاميه",
    "CITYNAME_EN": "Aljathamiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13689",
    "CITYNAME_AR": "اللقيطه",
    "CITYNAME_EN": "Allaqituh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13690",
    "CITYNAME_AR": "بدائع نقبين",
    "CITYNAME_EN": "Badayie Naqbin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13691",
    "CITYNAME_AR": "ابيره والعريده والرعيله",
    "CITYNAME_EN": "Abyrah Waleariduh Walraeiluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13692",
    "CITYNAME_AR": "السفن",
    "CITYNAME_EN": "Alsufun",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13693",
    "CITYNAME_AR": "قصر العشروات",
    "CITYNAME_EN": "Qasr Aleshrwat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13694",
    "CITYNAME_AR": "المكظم",
    "CITYNAME_EN": "Almukazam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13695",
    "CITYNAME_AR": "بدائع الراشد",
    "CITYNAME_EN": "Badayie Alrrashid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13696",
    "CITYNAME_AR": "سمره",
    "CITYNAME_EN": "Samarah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13697",
    "CITYNAME_AR": "غار بن ناحل",
    "CITYNAME_EN": "Ghar Bin Nahil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13698",
    "CITYNAME_AR": "الملاح",
    "CITYNAME_EN": "Almalah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13699",
    "CITYNAME_AR": "الصفيرا",
    "CITYNAME_EN": "Alsafira",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13700",
    "CITYNAME_AR": "الحميراء",
    "CITYNAME_EN": "Alhamira'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13701",
    "CITYNAME_AR": "القرينين",
    "CITYNAME_EN": "Alqarinin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13702",
    "CITYNAME_AR": "بدائع المكظم",
    "CITYNAME_EN": "Badayie Almukazam",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13703",
    "CITYNAME_AR": "الجرعاء",
    "CITYNAME_EN": "Aljuraea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13704",
    "CITYNAME_AR": "بدائع  الجرعاء",
    "CITYNAME_EN": "Bdayie Aljuraea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13705",
    "CITYNAME_AR": "الغريبين",
    "CITYNAME_EN": "Algharibin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13706",
    "CITYNAME_AR": "المعيقلات",
    "CITYNAME_EN": "Almueyqalat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13707",
    "CITYNAME_AR": "الودى",
    "CITYNAME_EN": "Alwadaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13708",
    "CITYNAME_AR": "عين بلطه",
    "CITYNAME_EN": "Eayan Balatah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13709",
    "CITYNAME_AR": "سرحه جو",
    "CITYNAME_EN": "Sarahah Jawin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13710",
    "CITYNAME_AR": "جو",
    "CITYNAME_EN": "Jawin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13711",
    "CITYNAME_AR": "المخروق",
    "CITYNAME_EN": "Almakhruq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13712",
    "CITYNAME_AR": "سد البار",
    "CITYNAME_EN": "Sada Albar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13713",
    "CITYNAME_AR": "القليبين",
    "CITYNAME_EN": "Alqalibin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13714",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Alfarshuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13715",
    "CITYNAME_AR": "المرير",
    "CITYNAME_EN": "Almarir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13716",
    "CITYNAME_AR": "ارينبات",
    "CITYNAME_EN": "Arynabat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13717",
    "CITYNAME_AR": "المرورات",
    "CITYNAME_EN": "Almurawirat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13718",
    "CITYNAME_AR": "عريجاء",
    "CITYNAME_EN": "Earija'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13719",
    "CITYNAME_AR": "الهفوف",
    "CITYNAME_EN": "Alhufuf",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13720",
    "CITYNAME_AR": "المبرز",
    "CITYNAME_EN": "Almubriz",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13721",
    "CITYNAME_AR": "البطاليه",
    "CITYNAME_EN": "Albitaliuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13722",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13723",
    "CITYNAME_AR": "جليجله",
    "CITYNAME_EN": "Jilyjlh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13724",
    "CITYNAME_AR": "الشقيق",
    "CITYNAME_EN": "Alshaqiq",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13725",
    "CITYNAME_AR": "المطيرفى",
    "CITYNAME_EN": "Almutayarfaa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13726",
    "CITYNAME_AR": "بني معن",
    "CITYNAME_EN": "Bani Maen",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13727",
    "CITYNAME_AR": "الحوطه",
    "CITYNAME_EN": "Alhutuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13728",
    "CITYNAME_AR": "السيايره",
    "CITYNAME_EN": "Alsayayiruh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13729",
    "CITYNAME_AR": "الجشه",
    "CITYNAME_EN": "Aljushuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13730",
    "CITYNAME_AR": "الرميله",
    "CITYNAME_EN": "Alramiluh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13731",
    "CITYNAME_AR": "الساباط",
    "CITYNAME_EN": "Alsabat",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13732",
    "CITYNAME_AR": "المركز",
    "CITYNAME_EN": "Centers",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13733",
    "CITYNAME_AR": "المزاوى",
    "CITYNAME_EN": "Almazawaa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13734",
    "CITYNAME_AR": "العقار",
    "CITYNAME_EN": "Aleiqar",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13735",
    "CITYNAME_AR": "غمسى",
    "CITYNAME_EN": "Ghumsaa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13736",
    "CITYNAME_AR": "المنيزله",
    "CITYNAME_EN": "Almunayzilh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13737",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Altaraf",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13738",
    "CITYNAME_AR": "السلم",
    "CITYNAME_EN": "Alsilm",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13739",
    "CITYNAME_AR": "الفضول",
    "CITYNAME_EN": "Alfudul",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13740",
    "CITYNAME_AR": "التويثير",
    "CITYNAME_EN": "Altwaythir",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13741",
    "CITYNAME_AR": "ابوثور",
    "CITYNAME_EN": "Abwthur",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13742",
    "CITYNAME_AR": "الحليله",
    "CITYNAME_EN": "Alhaliluh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13743",
    "CITYNAME_AR": "المقدام",
    "CITYNAME_EN": "Almiqadaam",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13744",
    "CITYNAME_AR": "الكلابيه",
    "CITYNAME_EN": "Alkalabiuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13745",
    "CITYNAME_AR": "العمران",
    "CITYNAME_EN": "Aleumran",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13746",
    "CITYNAME_AR": "  الجبيل",
    "CITYNAME_EN": "Aljabil",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13747",
    "CITYNAME_AR": "الطريبيل",
    "CITYNAME_EN": "Altaribayl",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13748",
    "CITYNAME_AR": "التهيميه",
    "CITYNAME_EN": "Altahiimih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13749",
    "CITYNAME_AR": "الشهارين",
    "CITYNAME_EN": "Alshaharin",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13750",
    "CITYNAME_AR": "الدالوه",
    "CITYNAME_EN": "Aldaaluh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13751",
    "CITYNAME_AR": "المنصوره",
    "CITYNAME_EN": "Almansuruh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13752",
    "CITYNAME_AR": "السبائخ",
    "CITYNAME_EN": "Alsabayikh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13753",
    "CITYNAME_AR": "العراميه",
    "CITYNAME_EN": "Alearamiuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13754",
    "CITYNAME_AR": "اسكان الكلابيه",
    "CITYNAME_EN": "Iiskan Alkalabiuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13755",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Aljafr",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13756",
    "CITYNAME_AR": "القاره",
    "CITYNAME_EN": "Alqaruh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13757",
    "CITYNAME_AR": "فريج الرمل",
    "CITYNAME_EN": "Farij Alraml",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13758",
    "CITYNAME_AR": "النجبيه",
    "CITYNAME_EN": "Alnajbiuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13759",
    "CITYNAME_AR": "الغويبه",
    "CITYNAME_EN": "Alghawibuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13760",
    "CITYNAME_AR": "سوده",
    "CITYNAME_EN": "Sudah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13761",
    "CITYNAME_AR": "عجايب",
    "CITYNAME_EN": "Eajayib",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13762",
    "CITYNAME_AR": "الثوير",
    "CITYNAME_EN": "Althawayr",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13763",
    "CITYNAME_AR": "العصلاء",
    "CITYNAME_EN": "Aleusla'",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13764",
    "CITYNAME_AR": "عاليه",
    "CITYNAME_EN": "Ealyh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13765",
    "CITYNAME_AR": "منصورة الشهامه",
    "CITYNAME_EN": "Mansurat Alshahamih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13766",
    "CITYNAME_AR": "الذيابيه",
    "CITYNAME_EN": "Aldhiyabiah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13767",
    "CITYNAME_AR": "ميدان بشاير",
    "CITYNAME_EN": "Midan Bshayr",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13768",
    "CITYNAME_AR": "الباهيه",
    "CITYNAME_EN": "Albahih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13769",
    "CITYNAME_AR": "العيون",
    "CITYNAME_EN": "Aleuyun",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13770",
    "CITYNAME_AR": "القطار",
    "CITYNAME_EN": "Alqitar",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13771",
    "CITYNAME_AR": "المراح",
    "CITYNAME_EN": "Almarah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13772",
    "CITYNAME_AR": "العوضيه",
    "CITYNAME_EN": "Aleudih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13773",
    "CITYNAME_AR": "الجرن",
    "CITYNAME_EN": "Aljurn",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13774",
    "CITYNAME_AR": "الوزيه",
    "CITYNAME_EN": "Alwazih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13775",
    "CITYNAME_AR": "القصار (مركز حدود ومجموعه صنادق)",
    "CITYNAME_EN": "Alqasar (Mrakz Hudud Wamajmueuh Sanadqa)",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13776",
    "CITYNAME_AR": "العضيليه",
    "CITYNAME_EN": "Aleadiliuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13777",
    "CITYNAME_AR": "المانعيه",
    "CITYNAME_EN": "Almanieih",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13778",
    "CITYNAME_AR": "ويسه",
    "CITYNAME_EN": "Waysah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13779",
    "CITYNAME_AR": "النايفيه",
    "CITYNAME_EN": "Alnaayifiuh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13780",
    "CITYNAME_AR": "خريص",
    "CITYNAME_EN": "Haris",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13781",
    "CITYNAME_AR": "هجرة الحنى",
    "CITYNAME_EN": "Al-Heny Hijra",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13782",
    "CITYNAME_AR": "هجرة فضيلة",
    "CITYNAME_EN": "Fadilah Hijrah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13783",
    "CITYNAME_AR": "الزايدية",
    "CITYNAME_EN": "Al Zaydiyah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13784",
    "CITYNAME_AR": "هجرة السيح",
    "CITYNAME_EN": "Hijrah Al Seeh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13785",
    "CITYNAME_AR": "شجعة",
    "CITYNAME_EN": "Shajah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13786",
    "CITYNAME_AR": "مريطبه",
    "CITYNAME_EN": "Muraytiba",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13787",
    "CITYNAME_AR": "العقير ",
    "CITYNAME_EN": "Uqair",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13788",
    "CITYNAME_AR": "الدهو",
    "CITYNAME_EN": "Al Dahw",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13789",
    "CITYNAME_AR": "الاحساء",
    "CITYNAME_EN": "Al Ahsa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13790",
    "CITYNAME_AR": "الحرده",
    "CITYNAME_EN": "Al-Hardah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13791",
    "CITYNAME_AR": "مكيلة",
    "CITYNAME_EN": "Makilah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13792",
    "CITYNAME_AR": "حبيل شط فايع",
    "CITYNAME_EN": "Habil Shat Fayi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13793",
    "CITYNAME_AR": "حبيل الهيجه",
    "CITYNAME_EN": "Habil Al-Hijah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13794",
    "CITYNAME_AR": "الناصفه",
    "CITYNAME_EN": "Al Nasefa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13795",
    "CITYNAME_AR": "شعب مورده",
    "CITYNAME_EN": "Shi'b Mordah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13796",
    "CITYNAME_AR": "شعب غميض",
    "CITYNAME_EN": "Shi'b Ghamid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13797",
    "CITYNAME_AR": "قرن معداش",
    "CITYNAME_EN": "Qarn Mi'dash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13798",
    "CITYNAME_AR": "قرن الوجع",
    "CITYNAME_EN": "Qarn Al-Waj'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13799",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Al-Shorfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13800",
    "CITYNAME_AR": "قنا",
    "CITYNAME_EN": "Qena",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13801",
    "CITYNAME_AR": "المبنى",
    "CITYNAME_EN": "Building",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13802",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13803",
    "CITYNAME_AR": "الحضن (العطف)",
    "CITYNAME_EN": "Al-Hodn (Al-Atf)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13804",
    "CITYNAME_AR": "الجبهاء",
    "CITYNAME_EN": "Al-Jabhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13805",
    "CITYNAME_AR": "الهدان المكاحل",
    "CITYNAME_EN": "Al-Hedan Al-Makahil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13806",
    "CITYNAME_AR": "الشرف (غافله)",
    "CITYNAME_EN": "Al-Sharaf (Ghaflah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13807",
    "CITYNAME_AR": "الخربان",
    "CITYNAME_EN": "Al-Kharian ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13808",
    "CITYNAME_AR": "القرنين",
    "CITYNAME_EN": "Al-Qarnin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13809",
    "CITYNAME_AR": "ام مغاربه",
    "CITYNAME_EN": "Umm Magharbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13810",
    "CITYNAME_AR": "الغريفه",
    "CITYNAME_EN": "Al-Gharifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13811",
    "CITYNAME_AR": "الجشه",
    "CITYNAME_EN": "Al Jishah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13812",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13813",
    "CITYNAME_AR": "المكتليه",
    "CITYNAME_EN": "Al-Moktalyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13814",
    "CITYNAME_AR": "الباطنه (الحدمه)",
    "CITYNAME_EN": "Al-Batinah (Al-Hedmah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13815",
    "CITYNAME_AR": "الجبهاء",
    "CITYNAME_EN": "Al-Jabhaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13816",
    "CITYNAME_AR": "الكثل",
    "CITYNAME_EN": "Al-Kathl ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13817",
    "CITYNAME_AR": "مقرن",
    "CITYNAME_EN": "Meqrin ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13818",
    "CITYNAME_AR": "الرقعه (الظهران)",
    "CITYNAME_EN": "Al-Raq'ah (Al-Zahran)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13819",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Al-Rahah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13820",
    "CITYNAME_AR": "الخانق (الجشه)",
    "CITYNAME_EN": "Al-Khaniq (Al-Jashah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13821",
    "CITYNAME_AR": "البقعه والرهوه",
    "CITYNAME_EN": "Al-Baq'ah and Al-Rahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13822",
    "CITYNAME_AR": "محضان (القرن)",
    "CITYNAME_EN": "Mehdan (Al-Qarn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13823",
    "CITYNAME_AR": "السحير",
    "CITYNAME_EN": "Al-Sahir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13824",
    "CITYNAME_AR": "الخربان والمقاعد",
    "CITYNAME_EN": "Al-Kheryan and Al-Maqa'd ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13825",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Ashab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13826",
    "CITYNAME_AR": "المغبر",
    "CITYNAME_EN": "Al-Maghber ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13827",
    "CITYNAME_AR": "المغدره",
    "CITYNAME_EN": "Al-Maghdarah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13828",
    "CITYNAME_AR": "الطويله",
    "CITYNAME_EN": "Al-Tawilah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13829",
    "CITYNAME_AR": "مشراف",
    "CITYNAME_EN": "Meshraf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13830",
    "CITYNAME_AR": "الجامرة",
    "CITYNAME_EN": "Al-Jamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13831",
    "CITYNAME_AR": "الحثمه",
    "CITYNAME_EN": "Al-Hathmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13832",
    "CITYNAME_AR": "ام حضن",
    "CITYNAME_EN": "Umm Hodn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13833",
    "CITYNAME_AR": "مسناد",
    "CITYNAME_EN": "Mesnad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13834",
    "CITYNAME_AR": "شبحه",
    "CITYNAME_EN": "Shabhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13835",
    "CITYNAME_AR": "ظهور ام قرون",
    "CITYNAME_EN": "Zohur Umm Al-Qorun ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13836",
    "CITYNAME_AR": "الفاتح",
    "CITYNAME_EN": "Al-Fatih ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13837",
    "CITYNAME_AR": "ام رهوه",
    "CITYNAME_EN": "Umm Rahwah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13838",
    "CITYNAME_AR": "شمرخه",
    "CITYNAME_EN": "Shamrakhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13839",
    "CITYNAME_AR": "ام خربان",
    "CITYNAME_EN": "Umm Kheryan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13840",
    "CITYNAME_AR": "الماخرين الجنوبى",
    "CITYNAME_EN": "Al-Makhrien Al-Januby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13841",
    "CITYNAME_AR": "الماخرين الشمالى",
    "CITYNAME_EN": "Al-Makhrien Al-Shamaly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13842",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13843",
    "CITYNAME_AR": "النصب",
    "CITYNAME_EN": "Al-Nasb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13844",
    "CITYNAME_AR": "الحفوز",
    "CITYNAME_EN": "Al-Hufaz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13845",
    "CITYNAME_AR": "الخايع",
    "CITYNAME_EN": "Al-Khyi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13846",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13847",
    "CITYNAME_AR": "المجازر",
    "CITYNAME_EN": "Al-Mjazir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13848",
    "CITYNAME_AR": "يبوخ",
    "CITYNAME_EN": "Yabukh ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13849",
    "CITYNAME_AR": "الجحرين",
    "CITYNAME_EN": "Al-Johrien ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13850",
    "CITYNAME_AR": "المشيرب الجنوبى",
    "CITYNAME_EN": "Al-Masheirab Al-Januby ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13851",
    "CITYNAME_AR": "وقحه",
    "CITYNAME_EN": "Waqhah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13852",
    "CITYNAME_AR": "البحريه",
    "CITYNAME_EN": "Al-Bahriyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13853",
    "CITYNAME_AR": "الحيراء",
    "CITYNAME_EN": "Al-Hairaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13854",
    "CITYNAME_AR": "الخانق",
    "CITYNAME_EN": "Al Khanik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13855",
    "CITYNAME_AR": "الشعب (الحمه)",
    "CITYNAME_EN": "Al-Shi'b (Al-hamah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13856",
    "CITYNAME_AR": "الغرس",
    "CITYNAME_EN": "Al-Ghars",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13857",
    "CITYNAME_AR": "المشيرب الشمالى",
    "CITYNAME_EN": "Al-Masheirib Al-Shamaly ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13858",
    "CITYNAME_AR": "المحدث",
    "CITYNAME_EN": "Al-Mehdith ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13859",
    "CITYNAME_AR": "اللزات",
    "CITYNAME_EN": "Allazat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13860",
    "CITYNAME_AR": "مكداس (الصفى)",
    "CITYNAME_EN": "Mekdas (Al-Safy)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13861",
    "CITYNAME_AR": "الضاح",
    "CITYNAME_EN": "Al-Dah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13862",
    "CITYNAME_AR": "الظهور ( الحبيل)",
    "CITYNAME_EN": "Al-Zihur (Al-Habil) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13863",
    "CITYNAME_AR": "الباديه",
    "CITYNAME_EN": "Al-Badiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13864",
    "CITYNAME_AR": "القطعه",
    "CITYNAME_EN": "Al-Qet'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13865",
    "CITYNAME_AR": "المقضى",
    "CITYNAME_EN": "Al-Maqdy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13866",
    "CITYNAME_AR": "الشعبات",
    "CITYNAME_EN": "Al-Shi'bat ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13867",
    "CITYNAME_AR": "الموميه",
    "CITYNAME_EN": "Al-Momiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13868",
    "CITYNAME_AR": "مجوره",
    "CITYNAME_EN": "Majurah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13869",
    "CITYNAME_AR": "القضيه",
    "CITYNAME_EN": "Al-Qadiyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13870",
    "CITYNAME_AR": "ابوتيس",
    "CITYNAME_EN": "Abu Tays ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13871",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13872",
    "CITYNAME_AR": "الربطه",
    "CITYNAME_EN": "Al-Rabtah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13873",
    "CITYNAME_AR": "امشريج",
    "CITYNAME_EN": "Amsharij ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13874",
    "CITYNAME_AR": "المشقحه (المصلحه)",
    "CITYNAME_EN": "Al-Mashqahah (Al-Maslahah)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13875",
    "CITYNAME_AR": "منخار (مكداس)",
    "CITYNAME_EN": "Menkhar (Mekdas)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13876",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13877",
    "CITYNAME_AR": "الشرجه",
    "CITYNAME_EN": "Al-Sharjah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13878",
    "CITYNAME_AR": "الخايع",
    "CITYNAME_EN": "Al-Khyi'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13879",
    "CITYNAME_AR": "حقو محلوف",
    "CITYNAME_EN": "Haqw Mahluf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13880",
    "CITYNAME_AR": "رهوة مشنيه",
    "CITYNAME_EN": "Rahwah mashniyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13881",
    "CITYNAME_AR": "الحثام",
    "CITYNAME_EN": "Al-Hatham ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13882",
    "CITYNAME_AR": "الكراع",
    "CITYNAME_EN": "Al Kara'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13883",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahit",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13884",
    "CITYNAME_AR": "الصدارة (محضان)",
    "CITYNAME_EN": "Al-Sadarah (Mehdan)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13885",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13886",
    "CITYNAME_AR": "الحدبات",
    "CITYNAME_EN": "Al Hadbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13887",
    "CITYNAME_AR": "رجلان",
    "CITYNAME_EN": "Rajlan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13888",
    "CITYNAME_AR": "العرضيه",
    "CITYNAME_EN": "Al-Aridah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13889",
    "CITYNAME_AR": "المدان الاعلى",
    "CITYNAME_EN": "Al-Medan Al-Ala ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13890",
    "CITYNAME_AR": "المدان الاسفل",
    "CITYNAME_EN": "Al-Medan Al-Asfel ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13891",
    "CITYNAME_AR": "حويه",
    "CITYNAME_EN": "Huba",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13892",
    "CITYNAME_AR": "الجرجر",
    "CITYNAME_EN": "Al-Jarjar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13893",
    "CITYNAME_AR": "المعديه",
    "CITYNAME_EN": "Al-Ma'diyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13894",
    "CITYNAME_AR": "الصد ومضلع",
    "CITYNAME_EN": "Al-Sed and Medli'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13895",
    "CITYNAME_AR": "الرمضه",
    "CITYNAME_EN": "Al-Ramdah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13896",
    "CITYNAME_AR": "الجحيره",
    "CITYNAME_EN": "Al-Johayrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13897",
    "CITYNAME_AR": "الجندله",
    "CITYNAME_EN": "Al-Jandalah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13898",
    "CITYNAME_AR": "الحره",
    "CITYNAME_EN": "Al-Herah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13899",
    "CITYNAME_AR": "اللصوب",
    "CITYNAME_EN": "Allasub ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13900",
    "CITYNAME_AR": "سيلان",
    "CITYNAME_EN": "Sielan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13901",
    "CITYNAME_AR": "زغدن",
    "CITYNAME_EN": "Zaghdan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13902",
    "CITYNAME_AR": "الصفاء",
    "CITYNAME_EN": "Al-Safaa ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13903",
    "CITYNAME_AR": "حقو الجمال",
    "CITYNAME_EN": "Haqw Al-Jemal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13904",
    "CITYNAME_AR": "رعله",
    "CITYNAME_EN": "Ra'lah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13905",
    "CITYNAME_AR": "شنكة",
    "CITYNAME_EN": "Shankah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13906",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar Rawah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13907",
    "CITYNAME_AR": "الخنتب",
    "CITYNAME_EN": "Al-Khanteb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13908",
    "CITYNAME_AR": "مصفي",
    "CITYNAME_EN": "Masfa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13909",
    "CITYNAME_AR": "القويد",
    "CITYNAME_EN": "Al-Qawid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13910",
    "CITYNAME_AR": "السواد",
    "CITYNAME_EN": "Al-Sawad",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13911",
    "CITYNAME_AR": "الطرقه",
    "CITYNAME_EN": "Al Tarqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13912",
    "CITYNAME_AR": "المتناحر",
    "CITYNAME_EN": "Al-Metnaher ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13913",
    "CITYNAME_AR": "مكداس",
    "CITYNAME_EN": "Mekdas ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13914",
    "CITYNAME_AR": "ام دفر",
    "CITYNAME_EN": "Umm Dofr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13915",
    "CITYNAME_AR": "القران",
    "CITYNAME_EN": "Al-Qeran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13916",
    "CITYNAME_AR": "غافلة",
    "CITYNAME_EN": "Ghaflah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13917",
    "CITYNAME_AR": "ريمان والبلقه",
    "CITYNAME_EN": "Riman and AL-Balqah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13918",
    "CITYNAME_AR": "البه",
    "CITYNAME_EN": "Al-Bah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13919",
    "CITYNAME_AR": "البرده",
    "CITYNAME_EN": "Al-Bordah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13920",
    "CITYNAME_AR": "المربط",
    "CITYNAME_EN": "Al-Merbit ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13921",
    "CITYNAME_AR": "الضحايه والغمره",
    "CITYNAME_EN": "Al-Dahayah and Al-Ghamrah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13922",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al-Irq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13923",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Moshref",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13924",
    "CITYNAME_AR": "الشرف",
    "CITYNAME_EN": "Al-Sharaf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13925",
    "CITYNAME_AR": "الخشمة (الحضن)",
    "CITYNAME_EN": "Al-Khashmah (Al-Hodn)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13926",
    "CITYNAME_AR": "المفصله",
    "CITYNAME_EN": "Al Mafsalah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13927",
    "CITYNAME_AR": "البوية",
    "CITYNAME_EN": "Al Buyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13928",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13929",
    "CITYNAME_AR": "النية",
    "CITYNAME_EN": "An Niyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13930",
    "CITYNAME_AR": "الفريش",
    "CITYNAME_EN": "Al Furaysh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13931",
    "CITYNAME_AR": "الأعرج",
    "CITYNAME_EN": "Al-A'raj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13932",
    "CITYNAME_AR": "البراده",
    "CITYNAME_EN": "Al-Baradah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13933",
    "CITYNAME_AR": "الخرماء",
    "CITYNAME_EN": "Al Kharma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13934",
    "CITYNAME_AR": "الكديد",
    "CITYNAME_EN": "Al-Kadid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13935",
    "CITYNAME_AR": "الجريف الاعلى",
    "CITYNAME_EN": "Al-Jarif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13936",
    "CITYNAME_AR": "القشاعى",
    "CITYNAME_EN": "Al-Qasha'y ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13937",
    "CITYNAME_AR": "بن صالح وكليب",
    "CITYNAME_EN": "Bin Saleh and Kolayb ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13938",
    "CITYNAME_AR": "الهدى",
    "CITYNAME_EN": "Al-huda ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13939",
    "CITYNAME_AR": "رهوه زبيبه",
    "CITYNAME_EN": "Rahwah Zabibah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13940",
    "CITYNAME_AR": "الميل",
    "CITYNAME_EN": "Al-Mayl",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13941",
    "CITYNAME_AR": "الباعق",
    "CITYNAME_EN": "Al-Ba'iq ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13942",
    "CITYNAME_AR": "الودن والمساعد",
    "CITYNAME_EN": "Al-Wedn and Al-Mosa'id ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13943",
    "CITYNAME_AR": "الصله",
    "CITYNAME_EN": "Al-Selah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13944",
    "CITYNAME_AR": "المشعب",
    "CITYNAME_EN": "Al-Meshb'ab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13945",
    "CITYNAME_AR": "الشط",
    "CITYNAME_EN": "Al-Shat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13946",
    "CITYNAME_AR": "ذا الخباب",
    "CITYNAME_EN": "Za Al-Khabab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13947",
    "CITYNAME_AR": "المضايق",
    "CITYNAME_EN": "Al-Madayiq",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13948",
    "CITYNAME_AR": "المجدرة",
    "CITYNAME_EN": "Al-Majderah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13949",
    "CITYNAME_AR": "وبره",
    "CITYNAME_EN": "Wabrah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13950",
    "CITYNAME_AR": "سحال ام بيضه",
    "CITYNAME_EN": "Sehal Umm Bidah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13951",
    "CITYNAME_AR": "المرو",
    "CITYNAME_EN": "Al-Marw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13952",
    "CITYNAME_AR": "خلال",
    "CITYNAME_EN": "Khilal ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13953",
    "CITYNAME_AR": "المقسومه",
    "CITYNAME_EN": "Al-Maqsumah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13954",
    "CITYNAME_AR": "العفاره والمقصر",
    "CITYNAME_EN": "Al-Afarah and Al-Meqsir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13955",
    "CITYNAME_AR": "الغليف",
    "CITYNAME_EN": "Al-Ghalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13956",
    "CITYNAME_AR": "البجيلي",
    "CITYNAME_EN": "Al Bajely ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13957",
    "CITYNAME_AR": "مسوره (مقبل ذم دار)",
    "CITYNAME_EN": "Masurah (Meqbil Zm Dar)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13958",
    "CITYNAME_AR": "النخاطه",
    "CITYNAME_EN": "Al-Nakhatah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13959",
    "CITYNAME_AR": "قدران",
    "CITYNAME_EN": "Qadran ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13960",
    "CITYNAME_AR": "عشيره والحبيل",
    "CITYNAME_EN": "Ashirah and Al-Habil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13961",
    "CITYNAME_AR": "اللثيل والحجاب",
    "CITYNAME_EN": "Allaith and Al-Hejab ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13962",
    "CITYNAME_AR": "اللثيل اليماني",
    "CITYNAME_EN": "Allaith Alyamany ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13963",
    "CITYNAME_AR": "حبيل صوفعه",
    "CITYNAME_EN": "Habil Suf'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13964",
    "CITYNAME_AR": "ذم عرج",
    "CITYNAME_EN": "Zm Arj ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13965",
    "CITYNAME_AR": "صكه",
    "CITYNAME_EN": "Sakah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13966",
    "CITYNAME_AR": "الغبير",
    "CITYNAME_EN": "Al-Ghabir ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13967",
    "CITYNAME_AR": "الموبره",
    "CITYNAME_EN": "Al-Mowirah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13968",
    "CITYNAME_AR": "الحومه",
    "CITYNAME_EN": "Al Humah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13969",
    "CITYNAME_AR": "الفسح",
    "CITYNAME_EN": "Al-Fash ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13970",
    "CITYNAME_AR": "ضمو الصدر",
    "CITYNAME_EN": "Dumw Al-Sadr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13971",
    "CITYNAME_AR": "لحسن",
    "CITYNAME_EN": "Lahsan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13972",
    "CITYNAME_AR": "مغاليف",
    "CITYNAME_EN": "Maghalif ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13973",
    "CITYNAME_AR": "كشان",
    "CITYNAME_EN": "Kashan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13974",
    "CITYNAME_AR": "منازل متناثره (نهب)",
    "CITYNAME_EN": "Scattered Houses (Nahb) ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "13975",
    "CITYNAME_AR": "خب الكيفه",
    "CITYNAME_EN": "Khaba Alkayfuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13976",
    "CITYNAME_AR": "البصر",
    "CITYNAME_EN": "Albasar",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13977",
    "CITYNAME_AR": "النخلات",
    "CITYNAME_EN": "Alnakhalat",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13978",
    "CITYNAME_AR": "المنسى",
    "CITYNAME_EN": "Almansaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13979",
    "CITYNAME_AR": "العاقول",
    "CITYNAME_EN": "Aleaqul",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13980",
    "CITYNAME_AR": "المويه",
    "CITYNAME_EN": "Almawiyuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13981",
    "CITYNAME_AR": "الجديدات",
    "CITYNAME_EN": "Aljadidat",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13982",
    "CITYNAME_AR": "الدعيسه",
    "CITYNAME_EN": "Aldaeisuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13983",
    "CITYNAME_AR": "المليداء",
    "CITYNAME_EN": "Almalida'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13984",
    "CITYNAME_AR": "الغماس",
    "CITYNAME_EN": "Alghamas",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13985",
    "CITYNAME_AR": "العقيلات",
    "CITYNAME_EN": "Aleaqilat",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13986",
    "CITYNAME_AR": "خب روضان",
    "CITYNAME_EN": "Khaba Rudan",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13987",
    "CITYNAME_AR": "ضراس",
    "CITYNAME_EN": "Daras",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13988",
    "CITYNAME_AR": "خب الحلوه",
    "CITYNAME_EN": "Khaba Alhuluwh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "13989",
    "CITYNAME_AR": "المجمعة",
    "CITYNAME_EN": "Almujmaea",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13990",
    "CITYNAME_AR": "جزع البويانات",
    "CITYNAME_EN": "Jizae Albuayanat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13991",
    "CITYNAME_AR": "اللحيج",
    "CITYNAME_EN": "Allihayj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13992",
    "CITYNAME_AR": "بئر ام هشيم",
    "CITYNAME_EN": "Buyr Umm Hashim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13993",
    "CITYNAME_AR": "القبيبه",
    "CITYNAME_EN": "Al Qubaybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13994",
    "CITYNAME_AR": "الرديدة",
    "CITYNAME_EN": "Ar-Rudaydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13995",
    "CITYNAME_AR": "ممناة",
    "CITYNAME_EN": "Memnat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13996",
    "CITYNAME_AR": "قاع الحماط",
    "CITYNAME_EN": "Qae Alhamat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13997",
    "CITYNAME_AR": "الحميط",
    "CITYNAME_EN": "Alhamit",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13998",
    "CITYNAME_AR": "عارة",
    "CITYNAME_EN": "Eara",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "13999",
    "CITYNAME_AR": "المقرن",
    "CITYNAME_EN": "Almuqaran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14000",
    "CITYNAME_AR": "ابو بريقا",
    "CITYNAME_EN": "Abu Bariqa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14001",
    "CITYNAME_AR": "المدينه المنوره",
    "CITYNAME_EN": "Madinah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14002",
    "CITYNAME_AR": "الضرسية",
    "CITYNAME_EN": "Ad-Darsyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14003",
    "CITYNAME_AR": "ابار ابا الدود",
    "CITYNAME_EN": "Abar Aba Ad-Doud",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14004",
    "CITYNAME_AR": "المليليح",
    "CITYNAME_EN": "Al Mulaylih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14005",
    "CITYNAME_AR": "البييرات",
    "CITYNAME_EN": "Al Bayerat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14006",
    "CITYNAME_AR": "بئر نافع الجلوي",
    "CITYNAME_EN": "Beir Nafei Al Galwi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14007",
    "CITYNAME_AR": "رشاد",
    "CITYNAME_EN": "Rashad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14008",
    "CITYNAME_AR": "ابار نصيف",
    "CITYNAME_EN": "Abar Nasif",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14009",
    "CITYNAME_AR": "الزبيديه",
    "CITYNAME_EN": "Az-Zubaydyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14010",
    "CITYNAME_AR": "الجغدور",
    "CITYNAME_EN": "Al Gaghdour",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14011",
    "CITYNAME_AR": "السدارة",
    "CITYNAME_EN": "As-Sedarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14012",
    "CITYNAME_AR": "بئر بواط",
    "CITYNAME_EN": "Beir Bawat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14013",
    "CITYNAME_AR": "العصيليب",
    "CITYNAME_EN": "Al Osayleb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14014",
    "CITYNAME_AR": "وتر",
    "CITYNAME_EN": "Watar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14015",
    "CITYNAME_AR": "مشيط  قرية الجفافيل والشوافعه",
    "CITYNAME_EN": "Mashet Qaryet Al Jafafel wa Ash-Shuafaah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14016",
    "CITYNAME_AR": "ابو رويضة قرية الجراجره وابن سودان",
    "CITYNAME_EN": "Abo Ruwaidah Qaryet Al Garagerah wa ibn Sawdan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14017",
    "CITYNAME_AR": "حمرة رشاد",
    "CITYNAME_EN": "Hamret Rashad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14018",
    "CITYNAME_AR": "ابار عشيره",
    "CITYNAME_EN": "Ashirah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14019",
    "CITYNAME_AR": "ابار العبيري",
    "CITYNAME_EN": "Al Obairi wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14020",
    "CITYNAME_AR": "بئر سبيع",
    "CITYNAME_EN": "Subai well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14021",
    "CITYNAME_AR": "بئر احمد الكلبي",
    "CITYNAME_EN": "Ahmed Al Kalabi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14022",
    "CITYNAME_AR": "بئر عيال سليم",
    "CITYNAME_EN": "Salim's sons well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14023",
    "CITYNAME_AR": "ابار الصروم",
    "CITYNAME_EN": "As-Saroum wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14024",
    "CITYNAME_AR": "بئر مريخة",
    "CITYNAME_EN": "Muraykhah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14025",
    "CITYNAME_AR": "بئر نغيمش",
    "CITYNAME_EN": "Naghmesh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14026",
    "CITYNAME_AR": "بئر وادى البيار",
    "CITYNAME_EN": "Wadi Al Bayar well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14027",
    "CITYNAME_AR": "أبار حرة الخلاصات",
    "CITYNAME_EN": "Hurat Al Khalasat wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14028",
    "CITYNAME_AR": "المندسة",
    "CITYNAME_EN": "Al Mindassah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14029",
    "CITYNAME_AR": "شقر",
    "CITYNAME_EN": "Shaqr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14030",
    "CITYNAME_AR": "الغزيل",
    "CITYNAME_EN": "Al Ghuzayl",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14031",
    "CITYNAME_AR": "هيثم",
    "CITYNAME_EN": "Haitham",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14032",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bahra",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14033",
    "CITYNAME_AR": "الكويره",
    "CITYNAME_EN": "Al Kuwayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14034",
    "CITYNAME_AR": "بئر مبرك",
    "CITYNAME_EN": "Mabrak well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14035",
    "CITYNAME_AR": "شعب",
    "CITYNAME_EN": "Shuab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14036",
    "CITYNAME_AR": "بئر الازير",
    "CITYNAME_EN": "Al Azer well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14037",
    "CITYNAME_AR": "بئر الحفيره",
    "CITYNAME_EN": "Al Hufayrah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14038",
    "CITYNAME_AR": "شجوى",
    "CITYNAME_EN": "Shajwa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14039",
    "CITYNAME_AR": "البوير",
    "CITYNAME_EN": "Al Buwer",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14040",
    "CITYNAME_AR": "تيتد",
    "CITYNAME_EN": "Teted",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14041",
    "CITYNAME_AR": "اللق",
    "CITYNAME_EN": "Al Laq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14042",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14043",
    "CITYNAME_AR": "المفرق المثلث",
    "CITYNAME_EN": "Al Mefreq Al Muthalath",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14044",
    "CITYNAME_AR": "ام قويع",
    "CITYNAME_EN": "Om Quwayqei",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14045",
    "CITYNAME_AR": "عنيزان ابا النعم",
    "CITYNAME_EN": "Onayzan Aba Al Neam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14046",
    "CITYNAME_AR": "بير ابا الحلو",
    "CITYNAME_EN": "Aba Al Helwi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14047",
    "CITYNAME_AR": "بئر الزريديم",
    "CITYNAME_EN": "Az-Zuraydem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14048",
    "CITYNAME_AR": "شعيب بط",
    "CITYNAME_EN": "Shuab Bat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14049",
    "CITYNAME_AR": "النهبة",
    "CITYNAME_EN": "An-Nuhyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14050",
    "CITYNAME_AR": "بئر علي بخيت",
    "CITYNAME_EN": "Ayed Ali Bakhit well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14051",
    "CITYNAME_AR": "ابار النابع",
    "CITYNAME_EN": "An-Nabei wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14052",
    "CITYNAME_AR": "بئر البخي",
    "CITYNAME_EN": "Al Bakhi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14053",
    "CITYNAME_AR": "بئر الزنيهر",
    "CITYNAME_EN": "Az-Zunayher well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14054",
    "CITYNAME_AR": "الفريش",
    "CITYNAME_EN": "Al Furaysh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14055",
    "CITYNAME_AR": "بئر الصائغ",
    "CITYNAME_EN": "As-Saigh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14056",
    "CITYNAME_AR": "بئر محارب",
    "CITYNAME_EN": "Muhareb well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14057",
    "CITYNAME_AR": "بئر حويمد المريشدي",
    "CITYNAME_EN": "Huwaymed Al-Murayshedi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14058",
    "CITYNAME_AR": "شعيب النخلة",
    "CITYNAME_EN": "Shuab An-Nakhlah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14059",
    "CITYNAME_AR": "ابار على الاحمدى (الضفيره)",
    "CITYNAME_EN": "Ali Al Ahmadi wells (Ad-Dufayrah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14060",
    "CITYNAME_AR": "بئر صالح",
    "CITYNAME_EN": "Saleh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14061",
    "CITYNAME_AR": "بئر وصل",
    "CITYNAME_EN": "Wasl well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14062",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Al Jafr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14063",
    "CITYNAME_AR": "عريق الوبارة",
    "CITYNAME_EN": "Areq Al Webarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14064",
    "CITYNAME_AR": "وادى حويره",
    "CITYNAME_EN": "Wadi Huwayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14065",
    "CITYNAME_AR": "اباالعريج",
    "CITYNAME_EN": "Aba Al Areej",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14066",
    "CITYNAME_AR": "عقله الصيفي",
    "CITYNAME_EN": "Oqlah As-Sayfi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14067",
    "CITYNAME_AR": "بئر المزيرية",
    "CITYNAME_EN": "Al Muzayryah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14068",
    "CITYNAME_AR": "شعيب الغميس",
    "CITYNAME_EN": "Shuab Al Ghames",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14069",
    "CITYNAME_AR": "الغريض",
    "CITYNAME_EN": "Al Ghared",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14070",
    "CITYNAME_AR": "ملل",
    "CITYNAME_EN": "Malal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14071",
    "CITYNAME_AR": "ابو عشعوش",
    "CITYNAME_EN": "Abo Ashaush",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14072",
    "CITYNAME_AR": "بئر حميد حميدان",
    "CITYNAME_EN": "Hamed Humaydan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14073",
    "CITYNAME_AR": "بئر رابح الرحيلي",
    "CITYNAME_EN": "Rabeh Ar-Raheli well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14074",
    "CITYNAME_AR": "حامد مطلق",
    "CITYNAME_EN": "Hamed Mutlaq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14075",
    "CITYNAME_AR": "الحزره",
    "CITYNAME_EN": "Al Hazarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14076",
    "CITYNAME_AR": "بريده",
    "CITYNAME_EN": "Buraydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14077",
    "CITYNAME_AR": "بئر مرشد عطية سليمان",
    "CITYNAME_EN": "Murshed Attiyah Sulaiman well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14078",
    "CITYNAME_AR": "ابار عيد",
    "CITYNAME_EN": "Eid wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14079",
    "CITYNAME_AR": "شعيب مليحه",
    "CITYNAME_EN": "Shuab Mulayhah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14080",
    "CITYNAME_AR": "مصلح الرحيلي",
    "CITYNAME_EN": "Musleh Ar-Ruhayli",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14081",
    "CITYNAME_AR": "شعيب البرود",
    "CITYNAME_EN": "Shuab Al-Borod",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14082",
    "CITYNAME_AR": "عايض بن حسن",
    "CITYNAME_EN": "Ayed bin Hasan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14083",
    "CITYNAME_AR": "بئر سالم مفرج",
    "CITYNAME_EN": "Salem Mefrej well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14084",
    "CITYNAME_AR": "بئر رجا معلا",
    "CITYNAME_EN": "Raja Muala well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14085",
    "CITYNAME_AR": "شبرقه",
    "CITYNAME_EN": "Shabraqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14086",
    "CITYNAME_AR": "حارة المراشده (المضييق)",
    "CITYNAME_EN": "Haret Al Marashedah (Al Madeeq)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14087",
    "CITYNAME_AR": "بئر مبروك بن سفر",
    "CITYNAME_EN": "Mabrouk bin Safar well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14088",
    "CITYNAME_AR": "المفرحات",
    "CITYNAME_EN": "Al Mufrehat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14089",
    "CITYNAME_AR": "مخضير",
    "CITYNAME_EN": "Mukhaydar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14090",
    "CITYNAME_AR": "بئر الصفاء",
    "CITYNAME_EN": "As-Safa well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14091",
    "CITYNAME_AR": "عشاش",
    "CITYNAME_EN": "Ashash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14092",
    "CITYNAME_AR": "بئر عبدالعزيز بن عودة",
    "CITYNAME_EN": "Abdulaziz bin Oudah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14093",
    "CITYNAME_AR": "ابار عايش",
    "CITYNAME_EN": "Ayesh wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14094",
    "CITYNAME_AR": "ابار عطيه مناور",
    "CITYNAME_EN": "Attiyah Munawer wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14095",
    "CITYNAME_AR": "بئر صابر",
    "CITYNAME_EN": "Saber well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14096",
    "CITYNAME_AR": "نديفة",
    "CITYNAME_EN": "Mudayfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14097",
    "CITYNAME_AR": "شعيب الحليقه",
    "CITYNAME_EN": "Shuab Al Hulayqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14098",
    "CITYNAME_AR": "بئر رجاالله بن فرج الله",
    "CITYNAME_EN": "Ragallah bin Farag Allah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14099",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14100",
    "CITYNAME_AR": "بئر المزرعة",
    "CITYNAME_EN": "Al Mazraa well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14101",
    "CITYNAME_AR": "بئر خير الله",
    "CITYNAME_EN": "Khayrallah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14102",
    "CITYNAME_AR": "منصور بن حطيحط",
    "CITYNAME_EN": "Mansour bin Hutayhat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14103",
    "CITYNAME_AR": "ابار غنيم بن سالم",
    "CITYNAME_EN": "Ghoneim bin Salem wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14104",
    "CITYNAME_AR": "عمير الردادي",
    "CITYNAME_EN": "Omayer Ar-Radadi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14105",
    "CITYNAME_AR": "ناجي بن عوده",
    "CITYNAME_EN": "Naji bin Oudah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14106",
    "CITYNAME_AR": "بئر عويد بن سليمان",
    "CITYNAME_EN": "Owayed bin Sulaiman well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14107",
    "CITYNAME_AR": "شعيب عرقوص",
    "CITYNAME_EN": "Shuab Arqoos",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14108",
    "CITYNAME_AR": "جابر بن ناجى",
    "CITYNAME_EN": "Jaber bin Naji",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14109",
    "CITYNAME_AR": "بشير بن محسن",
    "CITYNAME_EN": "Bashir bin Mohsen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14110",
    "CITYNAME_AR": "بئر مفرج واصل القرافي",
    "CITYNAME_EN": "Mefreg Wasel Al Qurafi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14111",
    "CITYNAME_AR": "بئر مسعود",
    "CITYNAME_EN": "Masood well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14112",
    "CITYNAME_AR": "بئر عياد الردادي",
    "CITYNAME_EN": "Ayad Ar-Radi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14113",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14114",
    "CITYNAME_AR": "السبعانه",
    "CITYNAME_EN": "As-Subaanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14115",
    "CITYNAME_AR": "شعيب مثعر",
    "CITYNAME_EN": "Shuaib Mathaar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14116",
    "CITYNAME_AR": "عبدالله مبروك",
    "CITYNAME_EN": "Abdullah Mabrook",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14117",
    "CITYNAME_AR": "غانم سليمان",
    "CITYNAME_EN": "Ghanem Sulaiman",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14118",
    "CITYNAME_AR": "عويد بن بركه الردادي",
    "CITYNAME_EN": "Awaid bin Barakah Ar-Radadi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14119",
    "CITYNAME_AR": "شعيب ميراد الثاجه",
    "CITYNAME_EN": "Shuaib Merad Ath-Thajah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14120",
    "CITYNAME_AR": "شعيب علقه",
    "CITYNAME_EN": "Shuaib Alaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14121",
    "CITYNAME_AR": "ابار الرداده",
    "CITYNAME_EN": "Ar-Radadah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14122",
    "CITYNAME_AR": "شعيب الميراد",
    "CITYNAME_EN": "Shuaib Al Merad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14123",
    "CITYNAME_AR": "مرشد",
    "CITYNAME_EN": "Marshad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14124",
    "CITYNAME_AR": "بخيت",
    "CITYNAME_EN": "Bakhait",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14125",
    "CITYNAME_AR": "بئر زاكي",
    "CITYNAME_EN": "Zaki well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14126",
    "CITYNAME_AR": "بئر زهيميل",
    "CITYNAME_EN": "Zumayhel well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14127",
    "CITYNAME_AR": "شعيب رباق",
    "CITYNAME_EN": "Shuaib Rebaq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14128",
    "CITYNAME_AR": "بئر عمر",
    "CITYNAME_EN": "Omar well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14129",
    "CITYNAME_AR": "على بنيه",
    "CITYNAME_EN": "Ali Benyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14130",
    "CITYNAME_AR": "وادى الرحبه",
    "CITYNAME_EN": "Wadi Ar-Rahbah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14131",
    "CITYNAME_AR": "بئر يحيى بن عواد",
    "CITYNAME_EN": "Yahya bin Awwad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14132",
    "CITYNAME_AR": "وادي محلف",
    "CITYNAME_EN": "Wadi Mehlef",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14133",
    "CITYNAME_AR": "الابرقين",
    "CITYNAME_EN": "Al Abraqeen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14134",
    "CITYNAME_AR": "بئر سعد بن عيد",
    "CITYNAME_EN": "Saad bin Eid well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14135",
    "CITYNAME_AR": "بئر غيث",
    "CITYNAME_EN": "Ghaith well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14136",
    "CITYNAME_AR": "بئر زابن",
    "CITYNAME_EN": "Zayen well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14137",
    "CITYNAME_AR": "محمد بن سعيد",
    "CITYNAME_EN": "Mohamed bin Saeed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14138",
    "CITYNAME_AR": "ابار الفريد",
    "CITYNAME_EN": "Alfared wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14139",
    "CITYNAME_AR": "بئر الابرقية",
    "CITYNAME_EN": "Al Abraqyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14140",
    "CITYNAME_AR": "بئر عبدالله قاسم",
    "CITYNAME_EN": "Abdullah Qasim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14141",
    "CITYNAME_AR": "شعيب الابرقين",
    "CITYNAME_EN": "Shuaib Al Abraqayn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14142",
    "CITYNAME_AR": "الدوداء",
    "CITYNAME_EN": "Ad-Dawaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14143",
    "CITYNAME_AR": "بئر ناجي",
    "CITYNAME_EN": "Naji well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14144",
    "CITYNAME_AR": "شعيب العطيشانه",
    "CITYNAME_EN": "Shuaib Al Autaishanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14145",
    "CITYNAME_AR": "المويرده",
    "CITYNAME_EN": "Al Muwayradah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14146",
    "CITYNAME_AR": "عاتق مرزوق",
    "CITYNAME_EN": "Ateq Marzouq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14147",
    "CITYNAME_AR": "مسلم بن تركي",
    "CITYNAME_EN": "Moslem bin Turki",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14148",
    "CITYNAME_AR": "ابار العطيشانه",
    "CITYNAME_EN": "Al Otayshanah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14149",
    "CITYNAME_AR": "شعيب الهزم",
    "CITYNAME_EN": "Shuaib Al Hazam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14150",
    "CITYNAME_AR": "بئر جامره",
    "CITYNAME_EN": "Jamerah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14151",
    "CITYNAME_AR": "بئر حمد مطلق",
    "CITYNAME_EN": "Hamad Mutlaq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14152",
    "CITYNAME_AR": "حارة عطا الله حويمد المرشدي",
    "CITYNAME_EN": "Atta Allah Homaid Al Morshedy Alley",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14153",
    "CITYNAME_AR": "ابار الماشي",
    "CITYNAME_EN": "Abiar Al Mashi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14154",
    "CITYNAME_AR": "الودي",
    "CITYNAME_EN": "Al Widi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14155",
    "CITYNAME_AR": "الدويفعه",
    "CITYNAME_EN": "Ad-Duwayfaah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14156",
    "CITYNAME_AR": "ابار عذبة",
    "CITYNAME_EN": "Fresh wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14157",
    "CITYNAME_AR": "مركز الهجرة",
    "CITYNAME_EN": "Immigration center",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14158",
    "CITYNAME_AR": "ابار طرفان",
    "CITYNAME_EN": "Tawfan wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14159",
    "CITYNAME_AR": "الرغيبه",
    "CITYNAME_EN": "Ar-Rughaybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14160",
    "CITYNAME_AR": "ابا الجلود",
    "CITYNAME_EN": "Aba Al Julood",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14161",
    "CITYNAME_AR": "الغضن",
    "CITYNAME_EN": "Al Ghodn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14162",
    "CITYNAME_AR": "الشلايل",
    "CITYNAME_EN": "Ash-Shalayel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14163",
    "CITYNAME_AR": "المقرن",
    "CITYNAME_EN": "Al Moqrin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14164",
    "CITYNAME_AR": "فم سلام",
    "CITYNAME_EN": "Fam Salam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14165",
    "CITYNAME_AR": "اسلام",
    "CITYNAME_EN": "Islam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14166",
    "CITYNAME_AR": "النعام",
    "CITYNAME_EN": "An-Naam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14167",
    "CITYNAME_AR": "الدوداء تناضب",
    "CITYNAME_EN": "Ad-Dawdaa Tanadub",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14168",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "As-Sahlah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14169",
    "CITYNAME_AR": "فم الدير",
    "CITYNAME_EN": "Fam Ad-Deer",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14170",
    "CITYNAME_AR": "وادى حـصنـة",
    "CITYNAME_EN": "Wadi Hasanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14171",
    "CITYNAME_AR": "اويدك",
    "CITYNAME_EN": "Awaidak",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14172",
    "CITYNAME_AR": "الرقب",
    "CITYNAME_EN": "Ar Raqb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14173",
    "CITYNAME_AR": "بئر سعد بن عبدالله",
    "CITYNAME_EN": "Saad bin Abdullah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14174",
    "CITYNAME_AR": "سريد الصيد",
    "CITYNAME_EN": "Sareed As-Sayd",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14175",
    "CITYNAME_AR": "الراء",
    "CITYNAME_EN": "Ar-Raa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14176",
    "CITYNAME_AR": "الخنقة (البريم)",
    "CITYNAME_EN": "Al Khanaqah (Al Bareem)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14177",
    "CITYNAME_AR": "الخطيه",
    "CITYNAME_EN": "Al Khatyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14178",
    "CITYNAME_AR": "شعيب قاسم",
    "CITYNAME_EN": "Shuaib Qassem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14179",
    "CITYNAME_AR": "البرديه",
    "CITYNAME_EN": "Al Bardyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14180",
    "CITYNAME_AR": "الرتاجه",
    "CITYNAME_EN": "Ar-Rutajah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14181",
    "CITYNAME_AR": "العشيره",
    "CITYNAME_EN": "Al Oshayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14182",
    "CITYNAME_AR": "شعيب يدوم",
    "CITYNAME_EN": "Shuaib Badoum",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14183",
    "CITYNAME_AR": "الدقيقه",
    "CITYNAME_EN": "Ad-Daqiqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14184",
    "CITYNAME_AR": "بئر الحرجه",
    "CITYNAME_EN": "Biir Al Harja",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14185",
    "CITYNAME_AR": "القيم",
    "CITYNAME_EN": "Al-Qayim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14186",
    "CITYNAME_AR": "شعيب انفة",
    "CITYNAME_EN": "Shuaib Anafah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14187",
    "CITYNAME_AR": "الغزلان",
    "CITYNAME_EN": "Al Ghozlan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14188",
    "CITYNAME_AR": "جرول",
    "CITYNAME_EN": "Garoul",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14189",
    "CITYNAME_AR": "بيئرالعلاويه",
    "CITYNAME_EN": "Al Alawyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14190",
    "CITYNAME_AR": "بئر نويحي بن سعد",
    "CITYNAME_EN": "Nuwayhi bin Saad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14191",
    "CITYNAME_AR": "بئر الضمو",
    "CITYNAME_EN": "Ad-Damu well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14192",
    "CITYNAME_AR": "بئر الساهبه",
    "CITYNAME_EN": "As-Sahyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14193",
    "CITYNAME_AR": "آبار مصلح",
    "CITYNAME_EN": "Musleh wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14194",
    "CITYNAME_AR": "المطين",
    "CITYNAME_EN": "Al Matiin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14195",
    "CITYNAME_AR": "المدراء",
    "CITYNAME_EN": "Managers",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14196",
    "CITYNAME_AR": "قسبان",
    "CITYNAME_EN": "Qasban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14197",
    "CITYNAME_AR": "المسبعة",
    "CITYNAME_EN": "Al Mesbaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14198",
    "CITYNAME_AR": "المرمى",
    "CITYNAME_EN": "Al Marma",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14199",
    "CITYNAME_AR": "مزارع الجصة الجنوبية",
    "CITYNAME_EN": "Al Gassah Al Al Janubyah Farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14200",
    "CITYNAME_AR": "الفراشة",
    "CITYNAME_EN": "Al Farashah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14201",
    "CITYNAME_AR": "بئر الرحيلى",
    "CITYNAME_EN": "Ar-Ruhayli well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14202",
    "CITYNAME_AR": "بئر اثمد",
    "CITYNAME_EN": "Athmad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14203",
    "CITYNAME_AR": "ابار المجيرمه",
    "CITYNAME_EN": "Al Mugayramah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14204",
    "CITYNAME_AR": "بير الضريبانية",
    "CITYNAME_EN": "Ad-Duraybanyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14205",
    "CITYNAME_AR": "الفقرة",
    "CITYNAME_EN": "Al Faqrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14206",
    "CITYNAME_AR": "الصويدره",
    "CITYNAME_EN": "As-Suwayderah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14207",
    "CITYNAME_AR": "الناعمة",
    "CITYNAME_EN": "An-Naemah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14208",
    "CITYNAME_AR": "مزارع الحار",
    "CITYNAME_EN": "Al Har farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14209",
    "CITYNAME_AR": "الجرذاوي",
    "CITYNAME_EN": "Al Jerzawi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14210",
    "CITYNAME_AR": "هرمة",
    "CITYNAME_EN": "Haramah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14211",
    "CITYNAME_AR": "مزارع الرصيعة",
    "CITYNAME_EN": "Ar-Rusayaah farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14212",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14213",
    "CITYNAME_AR": "الثعلة",
    "CITYNAME_EN": "Ath-Thaalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14214",
    "CITYNAME_AR": "العريفي",
    "CITYNAME_EN": "Al Arifi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14215",
    "CITYNAME_AR": "الرذايا",
    "CITYNAME_EN": "Ar-Razaya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14216",
    "CITYNAME_AR": "مزارع وادي الدثير",
    "CITYNAME_EN": "Wadi Ad-Duthair farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14217",
    "CITYNAME_AR": "مزارع السليميه",
    "CITYNAME_EN": "As-Sulaymyah farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14218",
    "CITYNAME_AR": "المضبر",
    "CITYNAME_EN": "Al Madbar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14219",
    "CITYNAME_AR": "زرب الخيمه",
    "CITYNAME_EN": "Zarab Al Khaymah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14220",
    "CITYNAME_AR": "العطافية",
    "CITYNAME_EN": "Al Atafyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14221",
    "CITYNAME_AR": "الصميماء",
    "CITYNAME_EN": "As-Sumaymaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14222",
    "CITYNAME_AR": "الجماجمية",
    "CITYNAME_EN": "Al Jumamyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14223",
    "CITYNAME_AR": "المكتب",
    "CITYNAME_EN": "Al Maktab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14224",
    "CITYNAME_AR": "القزاز",
    "CITYNAME_EN": "Al Qazaz",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14225",
    "CITYNAME_AR": "بئر شايد السحيم",
    "CITYNAME_EN": "Shayed As-Suhayem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14226",
    "CITYNAME_AR": "الراغيه",
    "CITYNAME_EN": "Al Raghiya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14227",
    "CITYNAME_AR": "العوينه",
    "CITYNAME_EN": "Al Owaynah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14228",
    "CITYNAME_AR": "الحار",
    "CITYNAME_EN": "Al Har",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14229",
    "CITYNAME_AR": "الكراع",
    "CITYNAME_EN": "Al Karaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14230",
    "CITYNAME_AR": "السميراء ",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14231",
    "CITYNAME_AR": "العويقل صماخ",
    "CITYNAME_EN": "Al Owayqel Sammakh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14232",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sedrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14233",
    "CITYNAME_AR": "وادى السداره",
    "CITYNAME_EN": "Wadi Al Sadara",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14234",
    "CITYNAME_AR": "العويند",
    "CITYNAME_EN": "Al Uwainid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14235",
    "CITYNAME_AR": "بئر عايش",
    "CITYNAME_EN": "Ayesh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14236",
    "CITYNAME_AR": "الحجناوي",
    "CITYNAME_EN": "Alhajnawi",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14237",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Alrasu",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14238",
    "CITYNAME_AR": "مزارع بهجه",
    "CITYNAME_EN": "Farm of Bahjih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14239",
    "CITYNAME_AR": "العبيله",
    "CITYNAME_EN": "Aleabiluh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14240",
    "CITYNAME_AR": "العقيلة ومزارعها",
    "CITYNAME_EN": "Aleaqilat and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14241",
    "CITYNAME_AR": "الشنانه",
    "CITYNAME_EN": "Alshannanuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14242",
    "CITYNAME_AR": "المطيه",
    "CITYNAME_EN": "Almatiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14243",
    "CITYNAME_AR": "مزارع المطيه",
    "CITYNAME_EN": "Farm of Almatiih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14244",
    "CITYNAME_AR": "الباحة",
    "CITYNAME_EN": "Al Baha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14245",
    "CITYNAME_AR": "بهر الغربي",
    "CITYNAME_EN": "Bahr (the western) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14246",
    "CITYNAME_AR": "بهر الشرقي",
    "CITYNAME_EN": "Bahr (the eastern) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14247",
    "CITYNAME_AR": "المغرس",
    "CITYNAME_EN": "Al-Maghris ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14248",
    "CITYNAME_AR": "المزرع",
    "CITYNAME_EN": "Al-Mazre' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14249",
    "CITYNAME_AR": "البهيم الأعلي",
    "CITYNAME_EN": "Al-Bahim (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14250",
    "CITYNAME_AR": "البارك",
    "CITYNAME_EN": "Al-Barik",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14251",
    "CITYNAME_AR": "قراء",
    "CITYNAME_EN": "Qarah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14252",
    "CITYNAME_AR": "الجره",
    "CITYNAME_EN": "Al-Jarah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14253",
    "CITYNAME_AR": "قرن ظبى",
    "CITYNAME_EN": "Qarn Zaby",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14254",
    "CITYNAME_AR": "البكيريه",
    "CITYNAME_EN": "Albikiriuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14255",
    "CITYNAME_AR": "امهات الذيابه",
    "CITYNAME_EN": "Amhat Aldhiyabih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14256",
    "CITYNAME_AR": "الهلاليه",
    "CITYNAME_EN": "Alhalalih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14257",
    "CITYNAME_AR": "الشيحيه",
    "CITYNAME_EN": "Alshiyhiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14258",
    "CITYNAME_AR": "الضلفعه",
    "CITYNAME_EN": "Aldilfaeuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14259",
    "CITYNAME_AR": "ساق",
    "CITYNAME_EN": "Saq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14260",
    "CITYNAME_AR": "حله سعيدان",
    "CITYNAME_EN": "Hulah Saeidan",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14261",
    "CITYNAME_AR": "المذنب",
    "CITYNAME_EN": "Almudhanib",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14262",
    "CITYNAME_AR": "المكيلي",
    "CITYNAME_EN": "Almukili",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14263",
    "CITYNAME_AR": "الطلعة ومزارعها",
    "CITYNAME_EN": "Altalaeat and Farmsa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14264",
    "CITYNAME_AR": "الرحيميه",
    "CITYNAME_EN": "Alrahymih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14265",
    "CITYNAME_AR": "بلدة مشرف",
    "CITYNAME_EN": "Baldat Musharaf",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14266",
    "CITYNAME_AR": "روضه الحسو",
    "CITYNAME_EN": "Ruduh Alhusu",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14267",
    "CITYNAME_AR": "الموشم",
    "CITYNAME_EN": "Almawsham",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14268",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Almurabae",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14269",
    "CITYNAME_AR": "المفيض",
    "CITYNAME_EN": "Almufid",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14270",
    "CITYNAME_AR": "الخرماءالشماليه",
    "CITYNAME_EN": "Alkhurma'Alishimalih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14271",
    "CITYNAME_AR": "فرده",
    "CITYNAME_EN": "Faradah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14272",
    "CITYNAME_AR": "الرمحيه",
    "CITYNAME_EN": "Alramhiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14273",
    "CITYNAME_AR": "الناصريه",
    "CITYNAME_EN": "Alnaasirih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14274",
    "CITYNAME_AR": "الربقيه",
    "CITYNAME_EN": "Alrabaqih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14275",
    "CITYNAME_AR": "الثامريه",
    "CITYNAME_EN": "Althaamiriuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14276",
    "CITYNAME_AR": "ام الخراسع",
    "CITYNAME_EN": "Am Alkharasie",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14277",
    "CITYNAME_AR": "عويسة",
    "CITYNAME_EN": "Eawaysa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14278",
    "CITYNAME_AR": "العاير الجنوبى",
    "CITYNAME_EN": "Southern Aleayir ",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14279",
    "CITYNAME_AR": "الحقباء",
    "CITYNAME_EN": "Alhuqaba'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14280",
    "CITYNAME_AR": "العاير الشمالى",
    "CITYNAME_EN": "Northern Aleayir",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14281",
    "CITYNAME_AR": "ام طليحه",
    "CITYNAME_EN": "Am Talihuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14282",
    "CITYNAME_AR": "الاسياح",
    "CITYNAME_EN": "Al Asyah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14283",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Alburqa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14284",
    "CITYNAME_AR": "السيح",
    "CITYNAME_EN": "Alsiyh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14285",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Masharifuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14286",
    "CITYNAME_AR": "خصيبه",
    "CITYNAME_EN": "Khasibuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14287",
    "CITYNAME_AR": "ابا الورود",
    "CITYNAME_EN": "Abana Alwurud",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14288",
    "CITYNAME_AR": "السيق",
    "CITYNAME_EN": "Alsiyq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14289",
    "CITYNAME_AR": "ضيده",
    "CITYNAME_EN": "Daydah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14290",
    "CITYNAME_AR": "طراق ضيده الغربى",
    "CITYNAME_EN": "Tiraq Diidih Algharbaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14291",
    "CITYNAME_AR": "السعيره",
    "CITYNAME_EN": "Alsaeiruh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14292",
    "CITYNAME_AR": "حنيظل",
    "CITYNAME_EN": "Hnyzil",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14293",
    "CITYNAME_AR": "طريف",
    "CITYNAME_EN": "Tarif",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14294",
    "CITYNAME_AR": "البرود",
    "CITYNAME_EN": "Alburud",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14295",
    "CITYNAME_AR": "التنومه",
    "CITYNAME_EN": "Altanawumuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14296",
    "CITYNAME_AR": "الجعله",
    "CITYNAME_EN": "Aljaealuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14297",
    "CITYNAME_AR": "مطيوى",
    "CITYNAME_EN": "Mutywaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14298",
    "CITYNAME_AR": "البندريه",
    "CITYNAME_EN": "Albundrih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14299",
    "CITYNAME_AR": "البعيثه",
    "CITYNAME_EN": "Albueaythuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14300",
    "CITYNAME_AR": "قلبان الشيبانيه",
    "CITYNAME_EN": "Qulban Alshiyabaniih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14301",
    "CITYNAME_AR": "قبه",
    "CITYNAME_EN": "Qabah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14302",
    "CITYNAME_AR": "الطراق",
    "CITYNAME_EN": "Altiraq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14303",
    "CITYNAME_AR": "النقع",
    "CITYNAME_EN": "Alnaqe",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14304",
    "CITYNAME_AR": "ابو عريش",
    "CITYNAME_EN": "'Abu Earish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14305",
    "CITYNAME_AR": "الخبارى",
    "CITYNAME_EN": "Alkhabaraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14306",
    "CITYNAME_AR": "رح الخرشاء",
    "CITYNAME_EN": "Rh Alkhrsha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14307",
    "CITYNAME_AR": "ابو العرج",
    "CITYNAME_EN": "'Abu Alearaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14308",
    "CITYNAME_AR": "ام المعنق",
    "CITYNAME_EN": "Umm Almueanaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14309",
    "CITYNAME_AR": "ام القعاميص",
    "CITYNAME_EN": "Umm Alqaeamis",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14310",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "Alsadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14311",
    "CITYNAME_AR": "أم السود",
    "CITYNAME_EN": "Alsuwd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14312",
    "CITYNAME_AR": "الموجر",
    "CITYNAME_EN": "Almujar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14313",
    "CITYNAME_AR": "حله ناصر حسن مشيخى",
    "CITYNAME_EN": "Halah Nasir Hasan Mushikhana",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14314",
    "CITYNAME_AR": "حلة الخرم",
    "CITYNAME_EN": "Halah Alkhurmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14315",
    "CITYNAME_AR": "نهامه",
    "CITYNAME_EN": "Nahamuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14316",
    "CITYNAME_AR": "حله احمد حسين شريه",
    "CITYNAME_EN": "Halah 'Ahmad Husayn Sharih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14317",
    "CITYNAME_AR": "الجروبه",
    "CITYNAME_EN": "Aljurubuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14318",
    "CITYNAME_AR": "رح العقله",
    "CITYNAME_EN": "Rh Aleaqluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14319",
    "CITYNAME_AR": "الحزنه",
    "CITYNAME_EN": "Alhuznuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14320",
    "CITYNAME_AR": "سر وشام",
    "CITYNAME_EN": "Sirun Washamun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14321",
    "CITYNAME_AR": "ابو النوره",
    "CITYNAME_EN": "'Abu Alnuwruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14322",
    "CITYNAME_AR": "غرقا",
    "CITYNAME_EN": "Gharqanaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14323",
    "CITYNAME_AR": "فلس",
    "CITYNAME_EN": "Albyd Al'Aelaa (Fls)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14324",
    "CITYNAME_AR": "المجصص",
    "CITYNAME_EN": "Almujasas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14325",
    "CITYNAME_AR": "البيض الأسفل",
    "CITYNAME_EN": "Albyd Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14326",
    "CITYNAME_AR": "مجدره (رح الجدور)",
    "CITYNAME_EN": "Majdaruh (Rh Aljuduwr)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14327",
    "CITYNAME_AR": "حله الدفاع",
    "CITYNAME_EN": "Halah Aldifae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14328",
    "CITYNAME_AR": "المهدج",
    "CITYNAME_EN": "Almahdaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14329",
    "CITYNAME_AR": "حاكمه",
    "CITYNAME_EN": "Hakimuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14330",
    "CITYNAME_AR": "الحمازيه",
    "CITYNAME_EN": "Alhamazih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14331",
    "CITYNAME_AR": "وادى عز الدين",
    "CITYNAME_EN": "Wa'Adaa Eizi Aldiyn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14332",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14333",
    "CITYNAME_AR": "قامره",
    "CITYNAME_EN": "Qamirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14334",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Alquayeiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14335",
    "CITYNAME_AR": "المصايده",
    "CITYNAME_EN": "Almsaydh(Alqwyeyh Aljanubih)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14336",
    "CITYNAME_AR": "المريخيه",
    "CITYNAME_EN": "Almurikhiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14337",
    "CITYNAME_AR": "العزابيه ومزارعها",
    "CITYNAME_EN": "Aleazabih Wafarms Uha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14338",
    "CITYNAME_AR": "العسيله",
    "CITYNAME_EN": "Aleasiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14339",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14340",
    "CITYNAME_AR": "صديقه",
    "CITYNAME_EN": "Sadiquh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14341",
    "CITYNAME_AR": "بديع الخرم",
    "CITYNAME_EN": "Badie Alhizm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14342",
    "CITYNAME_AR": "الاسامله",
    "CITYNAME_EN": "Alasaamluh (Aleithmaniat)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14343",
    "CITYNAME_AR": "الهداشه",
    "CITYNAME_EN": "Alhidashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14344",
    "CITYNAME_AR": "الاسامله الكبرى",
    "CITYNAME_EN": "Alasamluh Alkubraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14345",
    "CITYNAME_AR": "القوامشه أسامله",
    "CITYNAME_EN": "Alqawamishuh 'Asamalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14346",
    "CITYNAME_AR": "البرصاء",
    "CITYNAME_EN": "Albarsa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14347",
    "CITYNAME_AR": "المشايخ",
    "CITYNAME_EN": "Almashayikh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14348",
    "CITYNAME_AR": "مغشيه",
    "CITYNAME_EN": "Maghshih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14349",
    "CITYNAME_AR": "بئر الجبلى",
    "CITYNAME_EN": "Biir Aljublaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14350",
    "CITYNAME_AR": "الشاقه الغربيه (السفلى)",
    "CITYNAME_EN": "Alshaqih Algharbiuh (Alsufalaa)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14351",
    "CITYNAME_AR": "السادليه الغربيه ومزارعها",
    "CITYNAME_EN": "Alssadilih Algharbiuh Wafarms Uha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14352",
    "CITYNAME_AR": "الشاقه الشرقيه (العليا)",
    "CITYNAME_EN": "Alshaaqih Alsharaqiuh (Alelya)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14353",
    "CITYNAME_AR": "حله عطيه ردينى",
    "CITYNAME_EN": "Halah Eatayh Radinaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14354",
    "CITYNAME_AR": "السادليه الشرقيه ومزارعها (العليا)",
    "CITYNAME_EN": "Alssadilih Alsharaqiuh Wafarms Uha (Alelya)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14355",
    "CITYNAME_AR": "زباره الخراشه",
    "CITYNAME_EN": "Zibaruh Alkharashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14356",
    "CITYNAME_AR": "الشابطه",
    "CITYNAME_EN": "Alshshabituh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14357",
    "CITYNAME_AR": "مبتريه",
    "CITYNAME_EN": "Mubtarih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14358",
    "CITYNAME_AR": "الخزنه والتوابيه",
    "CITYNAME_EN": "Alkhaznuh Waltawabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14359",
    "CITYNAME_AR": "الوسيعه",
    "CITYNAME_EN": "Alwasieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14360",
    "CITYNAME_AR": "الشعوب الغربيه الختارشه",
    "CITYNAME_EN": "Alshueub Algharbiuh Alkhatarishuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14361",
    "CITYNAME_AR": "الشاخره",
    "CITYNAME_EN": "Alshaakhiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14362",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "Alsiru",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14363",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Aljarabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14364",
    "CITYNAME_AR": "زباره الحفاش",
    "CITYNAME_EN": "Zubaruh Alhifash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14365",
    "CITYNAME_AR": "الدهاليه",
    "CITYNAME_EN": "Aldihaliuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14366",
    "CITYNAME_AR": "حله محمد احمد شقف",
    "CITYNAME_EN": "Halah Muhamad 'Ahmad Shaqaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14367",
    "CITYNAME_AR": "الشغاله",
    "CITYNAME_EN": "Alshighaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14368",
    "CITYNAME_AR": "كعلول الاعلى وحله الفقهاء",
    "CITYNAME_EN": "Alrawajihuh (Keulul Alsharaqiat)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14369",
    "CITYNAME_AR": "المعاشيه",
    "CITYNAME_EN": "Almaeashih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14370",
    "CITYNAME_AR": "كعلول الشرقيه (الأعلى)",
    "CITYNAME_EN": "Kaelul Alsharaqiih (Al'Aelaa)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14371",
    "CITYNAME_AR": "كعلول الاسفل",
    "CITYNAME_EN": "Kelwl Al'Asfal Algharbia (Al Masawi)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14372",
    "CITYNAME_AR": "ام النار",
    "CITYNAME_EN": "Umm Alnaar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14373",
    "CITYNAME_AR": "العامريه",
    "CITYNAME_EN": "Aleamirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14374",
    "CITYNAME_AR": "العرابيه",
    "CITYNAME_EN": "Al Arabiya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14375",
    "CITYNAME_AR": "العروس",
    "CITYNAME_EN": "Aleurus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14376",
    "CITYNAME_AR": "الجغادمه",
    "CITYNAME_EN": "Aljaghadumuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14377",
    "CITYNAME_AR": "الظافريه",
    "CITYNAME_EN": "Alzaafirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14378",
    "CITYNAME_AR": "المساوده",
    "CITYNAME_EN": "Almusawiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14379",
    "CITYNAME_AR": "المعايده",
    "CITYNAME_EN": "Almaeayiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14380",
    "CITYNAME_AR": "المقيدر",
    "CITYNAME_EN": "Almuqidir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14381",
    "CITYNAME_AR": "صفوه",
    "CITYNAME_EN": "Sifuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14382",
    "CITYNAME_AR": "قائم البهاكله",
    "CITYNAME_EN": "Qayim Albihakulih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14383",
    "CITYNAME_AR": "الكوادمة",
    "CITYNAME_EN": "Alkawadimuh Alsharqiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14384",
    "CITYNAME_AR": "رح آل مطاعن",
    "CITYNAME_EN": "Rh Al Mataein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14385",
    "CITYNAME_AR": "زبارة ام الغلف (حلة عقله)",
    "CITYNAME_EN": "Zibarat Umm Alghalaf (Hlat Eaqlah)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14386",
    "CITYNAME_AR": "راح صعبة",
    "CITYNAME_EN": "Saebuh Aljanubiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14387",
    "CITYNAME_AR": "صعبه الشرقية والغربية",
    "CITYNAME_EN": "Saebah Alshamaliuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14388",
    "CITYNAME_AR": "رح الحجاجه",
    "CITYNAME_EN": "Rh Alhijajuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14389",
    "CITYNAME_AR": "المصياد",
    "CITYNAME_EN": "Almasyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14390",
    "CITYNAME_AR": "رح ام قصير",
    "CITYNAME_EN": "Rh Umm Qasir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14391",
    "CITYNAME_AR": "زباره رشيد",
    "CITYNAME_EN": "Zibaruh Rashid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14392",
    "CITYNAME_AR": "الزخميه",
    "CITYNAME_EN": "Alzakhmih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14393",
    "CITYNAME_AR": "زباره المضاويه",
    "CITYNAME_EN": "Zubaruh Almadawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14394",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Almusbih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14395",
    "CITYNAME_AR": "الحامض",
    "CITYNAME_EN": "Alhamid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14396",
    "CITYNAME_AR": "ابو ام سرحه",
    "CITYNAME_EN": "'Abu Alsaraha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14397",
    "CITYNAME_AR": "ابو لهب",
    "CITYNAME_EN": "'Abu Lahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14398",
    "CITYNAME_AR": "الردج",
    "CITYNAME_EN": "Alraduj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14399",
    "CITYNAME_AR": "المرباح",
    "CITYNAME_EN": "Almarbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14400",
    "CITYNAME_AR": "جبل القلفوع",
    "CITYNAME_EN": "Jabal Alqalfue",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14401",
    "CITYNAME_AR": "المردوم",
    "CITYNAME_EN": "Almaraduwm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14402",
    "CITYNAME_AR": "القضيبات",
    "CITYNAME_EN": "Alqadibat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14403",
    "CITYNAME_AR": "الحلحله",
    "CITYNAME_EN": "Alhilhaluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14404",
    "CITYNAME_AR": "حرجة عياش",
    "CITYNAME_EN": "Harijat Eiash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14405",
    "CITYNAME_AR": "النويمي",
    "CITYNAME_EN": "Alnuwaymi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14406",
    "CITYNAME_AR": "الجهفاء",
    "CITYNAME_EN": "Aljuhafa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14407",
    "CITYNAME_AR": "الجبر",
    "CITYNAME_EN": "Aljabar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14408",
    "CITYNAME_AR": "كرسي عياش (دوده)",
    "CITYNAME_EN": "Kursii Eiash (Dwdh)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14409",
    "CITYNAME_AR": "حيله طفيل",
    "CITYNAME_EN": "Hilah Tafil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14410",
    "CITYNAME_AR": "المحباسه",
    "CITYNAME_EN": "Almihbasuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14411",
    "CITYNAME_AR": "الشرعه",
    "CITYNAME_EN": "Alsharaeuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14412",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Alhusan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14413",
    "CITYNAME_AR": "الخرشه",
    "CITYNAME_EN": "Alkharshuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14414",
    "CITYNAME_AR": "عياش الأوسط",
    "CITYNAME_EN": "Eiash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14415",
    "CITYNAME_AR": "ماهسه",
    "CITYNAME_EN": "Mahish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14416",
    "CITYNAME_AR": "البصه",
    "CITYNAME_EN": "Albasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14417",
    "CITYNAME_AR": "الحاليه",
    "CITYNAME_EN": "Alhalih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14418",
    "CITYNAME_AR": "شكر",
    "CITYNAME_EN": "Shakar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14419",
    "CITYNAME_AR": "الجيبه",
    "CITYNAME_EN": "Aljybuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14420",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Algharafuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14421",
    "CITYNAME_AR": "الوحم",
    "CITYNAME_EN": "Alwahm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14422",
    "CITYNAME_AR": "ابوالسلامه",
    "CITYNAME_EN": "Abwalslamh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14423",
    "CITYNAME_AR": "العشه الحمراء",
    "CITYNAME_EN": "Aleashuh Alhamra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14424",
    "CITYNAME_AR": "أبو الاسرار",
    "CITYNAME_EN": "'Abu Al'Asrar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14425",
    "CITYNAME_AR": "لحج الكلاب",
    "CITYNAME_EN": "Lihajin Alkilab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14426",
    "CITYNAME_AR": "الكدارى",
    "CITYNAME_EN": "Alkadaraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14427",
    "CITYNAME_AR": "جاضع الحيله",
    "CITYNAME_EN": "Jadie Alhilih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14428",
    "CITYNAME_AR": "الدوشيه",
    "CITYNAME_EN": "Alduwshih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14429",
    "CITYNAME_AR": "كرس ابن احوس",
    "CITYNAME_EN": "Karas Abn Ahws",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14430",
    "CITYNAME_AR": "ابو الجهوه",
    "CITYNAME_EN": "'Abu Aljuhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14431",
    "CITYNAME_AR": "الحيله",
    "CITYNAME_EN": "Alhiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14432",
    "CITYNAME_AR": "الحمرايه",
    "CITYNAME_EN": "Alhimrayuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14433",
    "CITYNAME_AR": "رماده السفلى",
    "CITYNAME_EN": "Rumaduh Alelyawalsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14434",
    "CITYNAME_AR": "وادي الرباح",
    "CITYNAME_EN": "Wadi Alribah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14435",
    "CITYNAME_AR": "زبيده",
    "CITYNAME_EN": "Zabiduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14436",
    "CITYNAME_AR": "الجران",
    "CITYNAME_EN": "Aljaran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14437",
    "CITYNAME_AR": "الجاضع المكارشه",
    "CITYNAME_EN": "Aljadie Almakarishuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14438",
    "CITYNAME_AR": "السلام بني واصل",
    "CITYNAME_EN": "Alsalam Bani Wasal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14439",
    "CITYNAME_AR": "المصياد",
    "CITYNAME_EN": "Almasyad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14440",
    "CITYNAME_AR": "الشاقه",
    "CITYNAME_EN": "Alshaaqih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14441",
    "CITYNAME_AR": "صفار",
    "CITYNAME_EN": "Jaghar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14442",
    "CITYNAME_AR": "خضيره عياش",
    "CITYNAME_EN": "Khadiruh Eiash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14443",
    "CITYNAME_AR": "ام الحجل",
    "CITYNAME_EN": "Umm Alhajl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14444",
    "CITYNAME_AR": "الجحره",
    "CITYNAME_EN": "Aljahruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14445",
    "CITYNAME_AR": "الهجامة",
    "CITYNAME_EN": "Alhajama",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14446",
    "CITYNAME_AR": "الدحرة",
    "CITYNAME_EN": "Aldahra",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14447",
    "CITYNAME_AR": "العركية",
    "CITYNAME_EN": "Alearikuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14448",
    "CITYNAME_AR": "ابو الرسب",
    "CITYNAME_EN": "'Abu Alrusb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14449",
    "CITYNAME_AR": "الحصامة",
    "CITYNAME_EN": "Alddafie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14450",
    "CITYNAME_AR": "كرس الوحم",
    "CITYNAME_EN": "Karas Alwahm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14451",
    "CITYNAME_AR": "المواسة",
    "CITYNAME_EN": "Almawasa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14452",
    "CITYNAME_AR": "المحازره",
    "CITYNAME_EN": "Almuhaziruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14453",
    "CITYNAME_AR": "بنى مفرح",
    "CITYNAME_EN": "Alsulaymania",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14454",
    "CITYNAME_AR": "القعر",
    "CITYNAME_EN": "Aljier",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14455",
    "CITYNAME_AR": "كعلول الشرقيه",
    "CITYNAME_EN": "Kaelul Albawalisha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14456",
    "CITYNAME_AR": "الجردابي",
    "CITYNAME_EN": "Aljirdabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14457",
    "CITYNAME_AR": "المحاضين",
    "CITYNAME_EN": "Almahadin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14458",
    "CITYNAME_AR": "حله امحميري",
    "CITYNAME_EN": "Halh Alhamrani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14459",
    "CITYNAME_AR": "الهليه العليا والسفلى",
    "CITYNAME_EN": "Upper and Lower Al Hulyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14460",
    "CITYNAME_AR": "البدوى",
    "CITYNAME_EN": "Al Badawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14461",
    "CITYNAME_AR": "الزاويه",
    "CITYNAME_EN": "Az-Zaweyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14462",
    "CITYNAME_AR": "مساوي كريرى",
    "CITYNAME_EN": "Qaryet Masawi Kreri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14463",
    "CITYNAME_AR": "صامطه",
    "CITYNAME_EN": "Samith",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14464",
    "CITYNAME_AR": "خبت محمد علي",
    "CITYNAME_EN": "Khabat Muhamad Eali",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14465",
    "CITYNAME_AR": "ابو مسود",
    "CITYNAME_EN": "'Abu Msud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14466",
    "CITYNAME_AR": "شعب الخرابه",
    "CITYNAME_EN": "Shaeb Alkharabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14467",
    "CITYNAME_AR": "صاحب البار الشرقى",
    "CITYNAME_EN": "Sahib Albar Alsharqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14468",
    "CITYNAME_AR": "صاحب البار الغربى",
    "CITYNAME_EN": "Sahib Albar Alghurbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14469",
    "CITYNAME_AR": "ابو حجر الاسفل",
    "CITYNAME_EN": "'Abu Hajar Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14470",
    "CITYNAME_AR": "مجعر",
    "CITYNAME_EN": "Majear",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14471",
    "CITYNAME_AR": "الركوبه",
    "CITYNAME_EN": "Alrukubuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14472",
    "CITYNAME_AR": "الجاضع",
    "CITYNAME_EN": "Aljadie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14473",
    "CITYNAME_AR": "جاضع الحسين",
    "CITYNAME_EN": "Khabat Aljadie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14474",
    "CITYNAME_AR": "قائم الخمسين",
    "CITYNAME_EN": "Qayim Alkhamsin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14475",
    "CITYNAME_AR": "قائم العتنه",
    "CITYNAME_EN": "Qayim Aleatnih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14476",
    "CITYNAME_AR": "حاكمه الدغارير",
    "CITYNAME_EN": "Hakimuh Aldagharir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14477",
    "CITYNAME_AR": "الدغارير",
    "CITYNAME_EN": "Aldagharir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14478",
    "CITYNAME_AR": "مختاره",
    "CITYNAME_EN": "Mukhtaruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14479",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Alkhadra'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14480",
    "CITYNAME_AR": "الطرايشه",
    "CITYNAME_EN": "Alturshayuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14481",
    "CITYNAME_AR": "الجرديه",
    "CITYNAME_EN": "Aljurdayuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14482",
    "CITYNAME_AR": "الجراديه",
    "CITYNAME_EN": "Aljaradiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14483",
    "CITYNAME_AR": "العامريه",
    "CITYNAME_EN": "Aleamirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14484",
    "CITYNAME_AR": "الدريعيه",
    "CITYNAME_EN": "Aldarieiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14485",
    "CITYNAME_AR": "ابوحجر الاعلى",
    "CITYNAME_EN": "Abwhjr Al'Aelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14486",
    "CITYNAME_AR": "خبت الخارش  ومزارعة",
    "CITYNAME_EN": "Khabat Alkharish Wamuzaraea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14487",
    "CITYNAME_AR": "الجيانيه السفلى",
    "CITYNAME_EN": "Al Jyanyah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14488",
    "CITYNAME_AR": "الجيانيه العليا",
    "CITYNAME_EN": "Al Jyanyah Al Olya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14489",
    "CITYNAME_AR": "أبو المض",
    "CITYNAME_EN": "Abo Al Mad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14490",
    "CITYNAME_AR": "احد المسارحه",
    "CITYNAME_EN": "Ahad al Masarihah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14491",
    "CITYNAME_AR": "سوق الليل",
    "CITYNAME_EN": "Souq Al Lail",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14492",
    "CITYNAME_AR": "رماده",
    "CITYNAME_EN": "Ramadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14493",
    "CITYNAME_AR": "كرس عواجى",
    "CITYNAME_EN": "Kurs Awaji",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14494",
    "CITYNAME_AR": "البيطاريه",
    "CITYNAME_EN": "Al Baytaryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14495",
    "CITYNAME_AR": "القائم",
    "CITYNAME_EN": "Al Qaem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14496",
    "CITYNAME_AR": "ابو العرج",
    "CITYNAME_EN": "Abo Al Araj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14497",
    "CITYNAME_AR": "السودي",
    "CITYNAME_EN": "As-Sawdi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14498",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14499",
    "CITYNAME_AR": "المقرقم",
    "CITYNAME_EN": "Ar-Rabwa (Al Muqarqam)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14500",
    "CITYNAME_AR": "المنجاره",
    "CITYNAME_EN": "Al Monjarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14501",
    "CITYNAME_AR": "العتبه",
    "CITYNAME_EN": "Al Atabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14502",
    "CITYNAME_AR": "الصفا (قزع)",
    "CITYNAME_EN": "As-Safah (Qozaa)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14503",
    "CITYNAME_AR": "كرس قزع",
    "CITYNAME_EN": "Kors Qozaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14504",
    "CITYNAME_AR": "قنبوره",
    "CITYNAME_EN": "Qanborah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14505",
    "CITYNAME_AR": "المخشليه",
    "CITYNAME_EN": "Al Makhshalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14506",
    "CITYNAME_AR": "المهدج",
    "CITYNAME_EN": "Al Mohadaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14507",
    "CITYNAME_AR": "الحلحله الشمالية",
    "CITYNAME_EN": "Al Halhala Ash-Shamalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14508",
    "CITYNAME_AR": "الخبارى",
    "CITYNAME_EN": "Al Khabari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14509",
    "CITYNAME_AR": "الخرق",
    "CITYNAME_EN": "Al Kharq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14510",
    "CITYNAME_AR": "الحصمة",
    "CITYNAME_EN": "Al Hasamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14511",
    "CITYNAME_AR": "العلوليه الغربيه",
    "CITYNAME_EN": "Al Ololyah Al Gharbyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14512",
    "CITYNAME_AR": "الحوامضه",
    "CITYNAME_EN": "Al Hawamedah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14513",
    "CITYNAME_AR": "الشعوب",
    "CITYNAME_EN": "Ash-Shoaob",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14514",
    "CITYNAME_AR": "المصقع",
    "CITYNAME_EN": "Al Mosaqa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14515",
    "CITYNAME_AR": "الزبيديه",
    "CITYNAME_EN": "Az-Zubaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14516",
    "CITYNAME_AR": "ام الرصاف",
    "CITYNAME_EN": "Om Ar-Rassaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14517",
    "CITYNAME_AR": "البرامى",
    "CITYNAME_EN": "Al Barami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14518",
    "CITYNAME_AR": "الهجنبه",
    "CITYNAME_EN": "Al Hagnabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14519",
    "CITYNAME_AR": "قائم الزبيديه",
    "CITYNAME_EN": "Qaem Al Zubaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14520",
    "CITYNAME_AR": "المهليه",
    "CITYNAME_EN": "Al Muhleyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14521",
    "CITYNAME_AR": "جاضع الخمس",
    "CITYNAME_EN": "Gadae Al Khomos",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14522",
    "CITYNAME_AR": "الحبجيه",
    "CITYNAME_EN": "Al Habgeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14523",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Az-Zubarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14524",
    "CITYNAME_AR": "الجعديه",
    "CITYNAME_EN": "Al Juadiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14525",
    "CITYNAME_AR": "حلحله الخمس",
    "CITYNAME_EN": "Halhalet Al Khumos",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14526",
    "CITYNAME_AR": "المكحل",
    "CITYNAME_EN": "Al Makhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14527",
    "CITYNAME_AR": "المروحيه المصروحيه",
    "CITYNAME_EN": "Al Marwahyah Al Masrohyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14528",
    "CITYNAME_AR": "المهتش",
    "CITYNAME_EN": "Al Muhattash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14529",
    "CITYNAME_AR": "عذيبه",
    "CITYNAME_EN": "Uzaybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14530",
    "CITYNAME_AR": "عيسان",
    "CITYNAME_EN": "Aysan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14531",
    "CITYNAME_AR": "جلبان",
    "CITYNAME_EN": "Galban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14532",
    "CITYNAME_AR": "غلبينيه",
    "CITYNAME_EN": "Ghalbineyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14533",
    "CITYNAME_AR": "حوارق الغربية",
    "CITYNAME_EN": "Hawareq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14534",
    "CITYNAME_AR": "حوارق الوسطى",
    "CITYNAME_EN": "Hawareq Al Wusta",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14535",
    "CITYNAME_AR": "المحامل",
    "CITYNAME_EN": "Al Mahamel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14536",
    "CITYNAME_AR": "الهنديه",
    "CITYNAME_EN": "Al Hendeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14537",
    "CITYNAME_AR": "الجروف",
    "CITYNAME_EN": "Al Garouf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14538",
    "CITYNAME_AR": "العامره العليا (الخرابه)",
    "CITYNAME_EN": "Al Amerah Al Olyah (Al Kharabah)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14539",
    "CITYNAME_AR": "الحورانى",
    "CITYNAME_EN": "Al Hawrani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14540",
    "CITYNAME_AR": "الحامضه",
    "CITYNAME_EN": "Al Amerah As-Sufla (Al Hamedah)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14541",
    "CITYNAME_AR": "الرواجحه",
    "CITYNAME_EN": "Ar-Ruwajehah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14542",
    "CITYNAME_AR": "الغفقيه",
    "CITYNAME_EN": "Al Ghafqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14543",
    "CITYNAME_AR": "القزعي - الناصرية",
    "CITYNAME_EN": "Al Quzai",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14544",
    "CITYNAME_AR": "الكوادمه الغربيه",
    "CITYNAME_EN": "Al Kawadmah Al Gharbeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14545",
    "CITYNAME_AR": "الشعفوليه",
    "CITYNAME_EN": "Ash-Shuafulyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14546",
    "CITYNAME_AR": "السويديه",
    "CITYNAME_EN": "As-Suwaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14547",
    "CITYNAME_AR": "رعشه",
    "CITYNAME_EN": "Raasha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14548",
    "CITYNAME_AR": "القراعيه العليا",
    "CITYNAME_EN": "Al Qurayah Al Olyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14549",
    "CITYNAME_AR": "القراعيه السفلى",
    "CITYNAME_EN": "Al Qurayah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14550",
    "CITYNAME_AR": "الشطيفيه",
    "CITYNAME_EN": "Ash-Shutayfyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14551",
    "CITYNAME_AR": "الظبره",
    "CITYNAME_EN": "Az-Zaberah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14552",
    "CITYNAME_AR": "النافعى",
    "CITYNAME_EN": "An-Nafai",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14553",
    "CITYNAME_AR": "الرافعى",
    "CITYNAME_EN": "Ar-Rafai",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14554",
    "CITYNAME_AR": "ابو عمران",
    "CITYNAME_EN": "Abo Omran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14555",
    "CITYNAME_AR": "العلوليه الشرقيه",
    "CITYNAME_EN": "Al Uloleyah Ash-Sharqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14556",
    "CITYNAME_AR": "حله جحشور",
    "CITYNAME_EN": "Halet Gahshour",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14557",
    "CITYNAME_AR": "المجامه",
    "CITYNAME_EN": "Al Mugamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14558",
    "CITYNAME_AR": "المنصوريه",
    "CITYNAME_EN": "Al Mansouryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14559",
    "CITYNAME_AR": "الحازميه",
    "CITYNAME_EN": "Al Hazemyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14560",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14561",
    "CITYNAME_AR": "المراونه",
    "CITYNAME_EN": "Al Marawenah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14562",
    "CITYNAME_AR": "الفوالقه",
    "CITYNAME_EN": "Al Fawaleqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14563",
    "CITYNAME_AR": "الغويديه",
    "CITYNAME_EN": "Al Ghwaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14564",
    "CITYNAME_AR": "حله الحمود",
    "CITYNAME_EN": "Halet Al Humod",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14565",
    "CITYNAME_AR": "كرس  الهوايشه",
    "CITYNAME_EN": "Kars Al Hawayshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14566",
    "CITYNAME_AR": "الزيديه",
    "CITYNAME_EN": "Az-Zaydeyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14567",
    "CITYNAME_AR": "الحصامه",
    "CITYNAME_EN": "Al Husamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14568",
    "CITYNAME_AR": "جحا",
    "CITYNAME_EN": "Juha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14569",
    "CITYNAME_AR": "الشطيفية",
    "CITYNAME_EN": "Ash-Shutayfyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14570",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As-Sirr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14571",
    "CITYNAME_AR": "جرادية الرواجحه",
    "CITYNAME_EN": "Garadeyet Ar-Rawajeha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14572",
    "CITYNAME_AR": "الشرجة",
    "CITYNAME_EN": "Al Sharjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14573",
    "CITYNAME_AR": "ابو الحور",
    "CITYNAME_EN": "Abo Al Hur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14574",
    "CITYNAME_AR": "سلام المصقع",
    "CITYNAME_EN": "Salam Al Musaqa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14575",
    "CITYNAME_AR": "العبدلية",
    "CITYNAME_EN": "Al Abdaleyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14576",
    "CITYNAME_AR": "الزبارة",
    "CITYNAME_EN": "Az-Zubarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14577",
    "CITYNAME_AR": "العبادية",
    "CITYNAME_EN": "Al Ubadiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14578",
    "CITYNAME_AR": "العالية",
    "CITYNAME_EN": "Al Aleyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14579",
    "CITYNAME_AR": "المجارين",
    "CITYNAME_EN": "Al Magareen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14580",
    "CITYNAME_AR": "المرواغ",
    "CITYNAME_EN": "Al Murawegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14581",
    "CITYNAME_AR": "المرماد",
    "CITYNAME_EN": "Al Mermad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14582",
    "CITYNAME_AR": "وادي طير",
    "CITYNAME_EN": "Wadi Tayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14583",
    "CITYNAME_AR": "المبجره  المنجره",
    "CITYNAME_EN": "Al Mubgarah Al Mungarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14584",
    "CITYNAME_AR": "الشريمية",
    "CITYNAME_EN": "Ash-Sheremyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14585",
    "CITYNAME_AR": "عذيبه الشرقية (حوارق)",
    "CITYNAME_EN": "Uzaybah Ash-Sharqiyah (Hawareq)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14586",
    "CITYNAME_AR": "المحازره",
    "CITYNAME_EN": "Al Mahazerah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14587",
    "CITYNAME_AR": "المقنزع",
    "CITYNAME_EN": "Al Muqanzaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14588",
    "CITYNAME_AR": "عثمان خرمى",
    "CITYNAME_EN": "Othman Kharmi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14589",
    "CITYNAME_AR": "مسله",
    "CITYNAME_EN": "Masalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14590",
    "CITYNAME_AR": "وعلان",
    "CITYNAME_EN": "Waealan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14591",
    "CITYNAME_AR": "الحضرور",
    "CITYNAME_EN": "Alhudurur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14592",
    "CITYNAME_AR": "تتفى",
    "CITYNAME_EN": "Tatfaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14593",
    "CITYNAME_AR": "بنى مخال",
    "CITYNAME_EN": "Banaa Makhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14594",
    "CITYNAME_AR": "السعديه",
    "CITYNAME_EN": "Alsaediuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14595",
    "CITYNAME_AR": "المصفق",
    "CITYNAME_EN": "Almusafaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14596",
    "CITYNAME_AR": "شعب وادي الملح",
    "CITYNAME_EN": "Shaeb Wadi Almulihi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14597",
    "CITYNAME_AR": "الغاويه",
    "CITYNAME_EN": "Alghawih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14598",
    "CITYNAME_AR": "خلفة وعلان",
    "CITYNAME_EN": "Khilfat Waealan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14599",
    "CITYNAME_AR": "الخلفه السفلى",
    "CITYNAME_EN": "Alkhalfuh Alsuflaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14600",
    "CITYNAME_AR": "المباركة",
    "CITYNAME_EN": "Al Mubarakah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14601",
    "CITYNAME_AR": "العافية",
    "CITYNAME_EN": "Al Afiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14602",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14603",
    "CITYNAME_AR": "العكره",
    "CITYNAME_EN": "Al Akarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14604",
    "CITYNAME_AR": "النجاميه",
    "CITYNAME_EN": "An-Nujamyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14605",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14606",
    "CITYNAME_AR": "الشمهانيه العليا",
    "CITYNAME_EN": "Ash-Shamhanyah Al Olya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14607",
    "CITYNAME_AR": "الشهمانية السفلى",
    "CITYNAME_EN": "Ash-Shamhanyah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14608",
    "CITYNAME_AR": "المحرقه السفلى",
    "CITYNAME_EN": "Al Mahraqah As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14609",
    "CITYNAME_AR": "المحرقه العليا",
    "CITYNAME_EN": "Al MahraqahAl Olya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14610",
    "CITYNAME_AR": "الخوجره",
    "CITYNAME_EN": "Al Khawjarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14611",
    "CITYNAME_AR": "المكنبل",
    "CITYNAME_EN": "Al Mukanbal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14612",
    "CITYNAME_AR": "الدوحه",
    "CITYNAME_EN": "Ad-Dawha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14613",
    "CITYNAME_AR": "المجنه",
    "CITYNAME_EN": "Al Mujanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14614",
    "CITYNAME_AR": "شعب الذئب",
    "CITYNAME_EN": "Shuab Az-Zaib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14615",
    "CITYNAME_AR": "جلبان",
    "CITYNAME_EN": "Galban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14616",
    "CITYNAME_AR": "خلفة ابو المض الربحه",
    "CITYNAME_EN": "Khelfet Abo Al Mad Al Rayha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14617",
    "CITYNAME_AR": "ابو الظبره",
    "CITYNAME_EN": "Abo Az-Zabrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14618",
    "CITYNAME_AR": "المبخرة",
    "CITYNAME_EN": "Al Mabkharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14619",
    "CITYNAME_AR": "سلامه الدراج",
    "CITYNAME_EN": "Salamuh Aldiraj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14620",
    "CITYNAME_AR": "الخشابيه",
    "CITYNAME_EN": "Alkhashabih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14621",
    "CITYNAME_AR": "المحصام",
    "CITYNAME_EN": "Almuhsam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14622",
    "CITYNAME_AR": "الطلعة ومزارعها",
    "CITYNAME_EN": "Altalaeat And Its Farms",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14623",
    "CITYNAME_AR": "البخته الشماليه",
    "CITYNAME_EN": "Albikhtuh Alshamaliuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14624",
    "CITYNAME_AR": "البخته الوسطى",
    "CITYNAME_EN": "Albikhtuh Alwustaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14625",
    "CITYNAME_AR": "البخته الجنوبيه",
    "CITYNAME_EN": "Albikhtuh Aljanubiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14626",
    "CITYNAME_AR": "حلة الرقيعي",
    "CITYNAME_EN": "Hulat Aldrysy Walraqiei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14627",
    "CITYNAME_AR": "الريان",
    "CITYNAME_EN": "Alrayan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14628",
    "CITYNAME_AR": "الكوامله",
    "CITYNAME_EN": "Alkawamiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14629",
    "CITYNAME_AR": "صمبه",
    "CITYNAME_EN": "Sambih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14630",
    "CITYNAME_AR": "مزرعه ابو الجدايل وماجاورها",
    "CITYNAME_EN": " 'Abu Aljadayl Wamajawuruha Farm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14631",
    "CITYNAME_AR": "الحفاظيه",
    "CITYNAME_EN": "Alhafazih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14632",
    "CITYNAME_AR": "ملقوطه",
    "CITYNAME_EN": "Malqutah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14633",
    "CITYNAME_AR": "تيهان",
    "CITYNAME_EN": "Tayhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14634",
    "CITYNAME_AR": "الواصلى",
    "CITYNAME_EN": "Alwasla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14635",
    "CITYNAME_AR": "المغبشيه",
    "CITYNAME_EN": "Almughbishih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14636",
    "CITYNAME_AR": "الحكيمى",
    "CITYNAME_EN": "Alhakimaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14637",
    "CITYNAME_AR": "خضير",
    "CITYNAME_EN": "Khadir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14638",
    "CITYNAME_AR": "الماطرى الشرقية",
    "CITYNAME_EN": "Almatraa Alsharqia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14639",
    "CITYNAME_AR": "الغريب",
    "CITYNAME_EN": "Algharib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14640",
    "CITYNAME_AR": "الشويهه",
    "CITYNAME_EN": "Alshawayhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14641",
    "CITYNAME_AR": "خبت سعيد",
    "CITYNAME_EN": "Khabat Saeid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14642",
    "CITYNAME_AR": "البديع والقرفي",
    "CITYNAME_EN": "Albadie Walqarfi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14643",
    "CITYNAME_AR": "رديس الاعلى",
    "CITYNAME_EN": "Radis Alsharqia",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14644",
    "CITYNAME_AR": "الخضراء الشماليه",
    "CITYNAME_EN": "Alkhadra' Alshamaliuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14645",
    "CITYNAME_AR": "الخضراء الجنوبيه",
    "CITYNAME_EN": "Alkhadra' Aljanubiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14646",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14647",
    "CITYNAME_AR": "الموسم",
    "CITYNAME_EN": "Al Musem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14648",
    "CITYNAME_AR": "الدوح",
    "CITYNAME_EN": "Ad-Dawh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14649",
    "CITYNAME_AR": "الكعاشيم",
    "CITYNAME_EN": "Al Akasheem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14650",
    "CITYNAME_AR": "الجريزى",
    "CITYNAME_EN": "Al Jarezy",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14651",
    "CITYNAME_AR": "الزربه",
    "CITYNAME_EN": "Az-Zarabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14652",
    "CITYNAME_AR": "الغوره",
    "CITYNAME_EN": "Al Ghawrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14653",
    "CITYNAME_AR": "ناصر احمد",
    "CITYNAME_EN": "Naser Ahmed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14654",
    "CITYNAME_AR": "بني زيلع",
    "CITYNAME_EN": "Bani Zaylaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14655",
    "CITYNAME_AR": "العرجين",
    "CITYNAME_EN": "Al Arjeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14656",
    "CITYNAME_AR": "القنبور",
    "CITYNAME_EN": "Al Qanbor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14657",
    "CITYNAME_AR": "الاثراره",
    "CITYNAME_EN": "Al Athrarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14658",
    "CITYNAME_AR": "الاسوديه",
    "CITYNAME_EN": "Al Aswadiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14659",
    "CITYNAME_AR": "الحثيره",
    "CITYNAME_EN": "Al Huthayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14660",
    "CITYNAME_AR": "العوفه",
    "CITYNAME_EN": "Al Awfa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14661",
    "CITYNAME_AR": "الرمضه",
    "CITYNAME_EN": "Ar-Ramadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14662",
    "CITYNAME_AR": "العبدليه",
    "CITYNAME_EN": "Al Abdalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14663",
    "CITYNAME_AR": "الكدمي",
    "CITYNAME_EN": "Al Kadami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14664",
    "CITYNAME_AR": "المدربش",
    "CITYNAME_EN": "Al Mudarbesh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14665",
    "CITYNAME_AR": "كدف الحدباء",
    "CITYNAME_EN": "Kadaf Al Hadbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14666",
    "CITYNAME_AR": "المصليبه",
    "CITYNAME_EN": "Al Meslaybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14667",
    "CITYNAME_AR": "حمد",
    "CITYNAME_EN": "Hamad village",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14668",
    "CITYNAME_AR": "الوشبه",
    "CITYNAME_EN": "Al Washbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14669",
    "CITYNAME_AR": "زمزم",
    "CITYNAME_EN": "Zamzam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14670",
    "CITYNAME_AR": "العقيلي",
    "CITYNAME_EN": "Al Uqaylee",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14671",
    "CITYNAME_AR": "الخرب",
    "CITYNAME_EN": "Al Kharab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14672",
    "CITYNAME_AR": "جنادي",
    "CITYNAME_EN": "Junadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14673",
    "CITYNAME_AR": "الصميلي",
    "CITYNAME_EN": "As-Sumayli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14674",
    "CITYNAME_AR": "القمره",
    "CITYNAME_EN": "Al Qumarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14675",
    "CITYNAME_AR": "الجوير",
    "CITYNAME_EN": "Al Juwayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14676",
    "CITYNAME_AR": "المطاحن",
    "CITYNAME_EN": "Al Matahen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14677",
    "CITYNAME_AR": "الندحة",
    "CITYNAME_EN": "An-Nadha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14678",
    "CITYNAME_AR": "المجاليل",
    "CITYNAME_EN": "Al Majalel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14679",
    "CITYNAME_AR": "الشقيق",
    "CITYNAME_EN": "Al Shaqiq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14680",
    "CITYNAME_AR": "المرابي",
    "CITYNAME_EN": "Al Murabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14681",
    "CITYNAME_AR": "الساحل",
    "CITYNAME_EN": "As-Sahel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14682",
    "CITYNAME_AR": "الميناء",
    "CITYNAME_EN": "Al Menea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14683",
    "CITYNAME_AR": "الصقعان",
    "CITYNAME_EN": "As-Saqaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14684",
    "CITYNAME_AR": "حله بن هيجان",
    "CITYNAME_EN": "Hulat bin Hayjan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14685",
    "CITYNAME_AR": "السوانى",
    "CITYNAME_EN": "As-Sawani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14686",
    "CITYNAME_AR": "الحصام",
    "CITYNAME_EN": "Al Husam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14687",
    "CITYNAME_AR": "القاع",
    "CITYNAME_EN": "Al Qaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14688",
    "CITYNAME_AR": "الصنيدلى",
    "CITYNAME_EN": "Al Sunaydali",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14689",
    "CITYNAME_AR": "المصمور",
    "CITYNAME_EN": "Al Masmur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14690",
    "CITYNAME_AR": "عجيبى",
    "CITYNAME_EN": "Ajebi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14691",
    "CITYNAME_AR": "الدملج",
    "CITYNAME_EN": "Ad-Damlaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14692",
    "CITYNAME_AR": "خشاما",
    "CITYNAME_EN": "Khushama",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14693",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Al Mahraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14694",
    "CITYNAME_AR": "الزباره",
    "CITYNAME_EN": "Az-Zubarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14695",
    "CITYNAME_AR": "بازغ",
    "CITYNAME_EN": "Bazei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14696",
    "CITYNAME_AR": "خبث بن جبران",
    "CITYNAME_EN": "Khabath bin Jubran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14697",
    "CITYNAME_AR": "الدش",
    "CITYNAME_EN": "Ad-Dush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14698",
    "CITYNAME_AR": "الهجنبه",
    "CITYNAME_EN": "Al Hagnabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14699",
    "CITYNAME_AR": "الشعوف",
    "CITYNAME_EN": "Ash-Shauf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14700",
    "CITYNAME_AR": "خلج",
    "CITYNAME_EN": "Khalaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14701",
    "CITYNAME_AR": "الراجحيه",
    "CITYNAME_EN": "Al Rajhiyyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14702",
    "CITYNAME_AR": "مستوره",
    "CITYNAME_EN": "Mastoura",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14703",
    "CITYNAME_AR": "الكنه",
    "CITYNAME_EN": "Al Kanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14704",
    "CITYNAME_AR": "التحليه",
    "CITYNAME_EN": "At-Tahlyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14705",
    "CITYNAME_AR": "الخبت",
    "CITYNAME_EN": "Al Khabat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14706",
    "CITYNAME_AR": "شعف المطاميه",
    "CITYNAME_EN": "Shaaf Al Matamyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14707",
    "CITYNAME_AR": "الرصاصه",
    "CITYNAME_EN": "Ar-Rusasah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14708",
    "CITYNAME_AR": "الكرشه",
    "CITYNAME_EN": "Al Karshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14709",
    "CITYNAME_AR": "رقة الرشماء",
    "CITYNAME_EN": "Reqet Ar-Rashmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14710",
    "CITYNAME_AR": "القصبة (بالعطاف)",
    "CITYNAME_EN": "Al Qasabah (Al Etaf)",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14711",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshosh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14712",
    "CITYNAME_AR": "الجعله",
    "CITYNAME_EN": "Al Jualah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14713",
    "CITYNAME_AR": "مجليله",
    "CITYNAME_EN": "Majlelah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14714",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sudrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14715",
    "CITYNAME_AR": "المزيرعه",
    "CITYNAME_EN": "Al Mozayraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14716",
    "CITYNAME_AR": "جر ابوالعشر",
    "CITYNAME_EN": "Jar Abo Al Ashr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14717",
    "CITYNAME_AR": "ام سيول",
    "CITYNAME_EN": "Umm Syul ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "14718",
    "CITYNAME_AR": "قدر",
    "CITYNAME_EN": "Qadr ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "14719",
    "CITYNAME_AR": "ذم حنا",
    "CITYNAME_EN": "Zim Henna ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "14720",
    "CITYNAME_AR": "ام جو",
    "CITYNAME_EN": "Umm Jaw",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "14721",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Ar-Rahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14722",
    "CITYNAME_AR": "ضمد",
    "CITYNAME_EN": "Damad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14723",
    "CITYNAME_AR": "حله الشهرى",
    "CITYNAME_EN": "Halah Alshuhraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14724",
    "CITYNAME_AR": "حله علي حبيبي",
    "CITYNAME_EN": "Halah Ealiun Habibi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14725",
    "CITYNAME_AR": "الزرقاء",
    "CITYNAME_EN": "Alzurqa'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14726",
    "CITYNAME_AR": "رديس الغربية",
    "CITYNAME_EN": "Alcamp  Radiys",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14727",
    "CITYNAME_AR": "المحله ومزارعها",
    "CITYNAME_EN": "Almahaluh Wafarms Uha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14728",
    "CITYNAME_AR": "الواسط",
    "CITYNAME_EN": "Alwasit",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14729",
    "CITYNAME_AR": "العوص",
    "CITYNAME_EN": "Aleaws",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14730",
    "CITYNAME_AR": "الطاهريه",
    "CITYNAME_EN": "Altaahirih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14731",
    "CITYNAME_AR": "خضيره",
    "CITYNAME_EN": "Khadiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14732",
    "CITYNAME_AR": "النزهه",
    "CITYNAME_EN": "Alnazhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14733",
    "CITYNAME_AR": "القمرى ومزارعها",
    "CITYNAME_EN": "Alqumraa Wafarms Uha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14734",
    "CITYNAME_AR": "الحرجة",
    "CITYNAME_EN": "Alharija",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14735",
    "CITYNAME_AR": "حله ناصر غازى",
    "CITYNAME_EN": "Halah Nasir Ghazaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14736",
    "CITYNAME_AR": "حله موسى كدش",
    "CITYNAME_EN": "Halah Musaa Kadash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14737",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Alhusan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14738",
    "CITYNAME_AR": "رحا بلاج ومزارعه",
    "CITYNAME_EN": "Raha Bilaj Wafarms Uh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14739",
    "CITYNAME_AR": "حله حور",
    "CITYNAME_EN": "Halah Hur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14740",
    "CITYNAME_AR": "المضلعي",
    "CITYNAME_EN": "Almudliei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14741",
    "CITYNAME_AR": "الشقيرى",
    "CITYNAME_EN": "Alshaqiraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14742",
    "CITYNAME_AR": "حله عبده نعمان",
    "CITYNAME_EN": "Halah Eabdah Naeman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14743",
    "CITYNAME_AR": "جبجب",
    "CITYNAME_EN": "Jabajb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14744",
    "CITYNAME_AR": "المحباسه",
    "CITYNAME_EN": "Almihbasuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14745",
    "CITYNAME_AR": "الجهو",
    "CITYNAME_EN": "Aljahu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14746",
    "CITYNAME_AR": "حلة أحمد مطاعن",
    "CITYNAME_EN": "Hulat 'Ahmad Mataein",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14747",
    "CITYNAME_AR": "حلة محمد جربحى",
    "CITYNAME_EN": "Hulat Muhamad Jurabhaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14748",
    "CITYNAME_AR": "حلة أمسكينى",
    "CITYNAME_EN": "Hulat 'Amskinaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14749",
    "CITYNAME_AR": "قائم صنبع",
    "CITYNAME_EN": "Qayim Aljahu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14750",
    "CITYNAME_AR": "محبوبه",
    "CITYNAME_EN": "Mahbubuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14751",
    "CITYNAME_AR": "الجبليه",
    "CITYNAME_EN": "Aljabalayuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14752",
    "CITYNAME_AR": "ابو حدابه",
    "CITYNAME_EN": "'Abu Hidabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14753",
    "CITYNAME_AR": "خميعه",
    "CITYNAME_EN": "Khamieuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14754",
    "CITYNAME_AR": "جريبه",
    "CITYNAME_EN": "Jaribuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14755",
    "CITYNAME_AR": "صنيف السنجار",
    "CITYNAME_EN": "Sanif Alsanjar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14756",
    "CITYNAME_AR": "حله الاخرش ابو القاسم",
    "CITYNAME_EN": "Halah Alakhrsh 'Abu Alqasim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14757",
    "CITYNAME_AR": "حلة موسى بن ناصر",
    "CITYNAME_EN": "Hulat Musaa Bin Nasir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14758",
    "CITYNAME_AR": "ردش الاعلى",
    "CITYNAME_EN": "Radash Al'Aelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14759",
    "CITYNAME_AR": "ردش الاسفل",
    "CITYNAME_EN": "Radash Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14760",
    "CITYNAME_AR": "الزرقاء العليا",
    "CITYNAME_EN": "Alzurqa' Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14761",
    "CITYNAME_AR": "منتوفه",
    "CITYNAME_EN": "Malufa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14762",
    "CITYNAME_AR": "الخبطه",
    "CITYNAME_EN": "Alkhabtuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14763",
    "CITYNAME_AR": "حلة الصبيانى",
    "CITYNAME_EN": "Hulat Alsabianaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14764",
    "CITYNAME_AR": "الحمى",
    "CITYNAME_EN": "Alhumaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14765",
    "CITYNAME_AR": "الجروع",
    "CITYNAME_EN": "Aljurue",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14766",
    "CITYNAME_AR": "حله المصروحى",
    "CITYNAME_EN": "Halah Almusruhaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14767",
    "CITYNAME_AR": "حلة بن داود",
    "CITYNAME_EN": "Hulat Bin Dawud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14768",
    "CITYNAME_AR": "قائم الحمى",
    "CITYNAME_EN": "Qayim Alhumaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14769",
    "CITYNAME_AR": "الحدبه العليا",
    "CITYNAME_EN": "Alhadabuh Aleulya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14770",
    "CITYNAME_AR": "الحطيبى",
    "CITYNAME_EN": "Alhutibaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14771",
    "CITYNAME_AR": "نتر",
    "CITYNAME_EN": "Ntr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14772",
    "CITYNAME_AR": "الخسعى",
    "CITYNAME_EN": "Alkhisei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14773",
    "CITYNAME_AR": "المحصم الاعلى",
    "CITYNAME_EN": "Almuhsim Al'Aelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14774",
    "CITYNAME_AR": "المحصم الاسفل",
    "CITYNAME_EN": "Almuhsim Al'Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14775",
    "CITYNAME_AR": "محيلله",
    "CITYNAME_EN": "Muhaylilh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14776",
    "CITYNAME_AR": "المشوف",
    "CITYNAME_EN": "Almushawf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14777",
    "CITYNAME_AR": "المطلع",
    "CITYNAME_EN": "Almutalie",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14778",
    "CITYNAME_AR": "ام البرام",
    "CITYNAME_EN": "Umm Albaram",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14779",
    "CITYNAME_AR": "ابو العرج",
    "CITYNAME_EN": "'Abu Alearaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14780",
    "CITYNAME_AR": "الرخه",
    "CITYNAME_EN": "Alrakhuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14781",
    "CITYNAME_AR": "حلة القيوس",
    "CITYNAME_EN": "Hulat Alquyus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14782",
    "CITYNAME_AR": "الخزان",
    "CITYNAME_EN": "Qaryat Alkhazan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14783",
    "CITYNAME_AR": "العارضه",
    "CITYNAME_EN": "Al-Ardha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14784",
    "CITYNAME_AR": "الخالفه",
    "CITYNAME_EN": "Al Khalefah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14785",
    "CITYNAME_AR": "بردان",
    "CITYNAME_EN": "Bardan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14786",
    "CITYNAME_AR": "منفوخه",
    "CITYNAME_EN": "Manfukhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14787",
    "CITYNAME_AR": "بطحان الاعلى",
    "CITYNAME_EN": "Bathan Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14788",
    "CITYNAME_AR": "ابو ام نخله",
    "CITYNAME_EN": "Abo Om Nakhlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14789",
    "CITYNAME_AR": "أم العظام الجنوبية",
    "CITYNAME_EN": "Om Al Ezam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14790",
    "CITYNAME_AR": "بطحان الآسفل",
    "CITYNAME_EN": "Bathan Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14791",
    "CITYNAME_AR": "الخل",
    "CITYNAME_EN": "Al Khal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14792",
    "CITYNAME_AR": "حبيل الشرافى",
    "CITYNAME_EN": "Hybail Ash-Sherafi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14793",
    "CITYNAME_AR": "القنعه",
    "CITYNAME_EN": "Al Qanaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14794",
    "CITYNAME_AR": "قائم زنبع",
    "CITYNAME_EN": "Qaem Zanbaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14795",
    "CITYNAME_AR": "حطبايه",
    "CITYNAME_EN": "Hatbayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14796",
    "CITYNAME_AR": "المهدج المقسيه",
    "CITYNAME_EN": "Al Muhaddaj Al Maqsyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14797",
    "CITYNAME_AR": "صمعه",
    "CITYNAME_EN": "Samaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14798",
    "CITYNAME_AR": "الحوراء",
    "CITYNAME_EN": "Al Hawraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14799",
    "CITYNAME_AR": "قائم العشاره",
    "CITYNAME_EN": "Qaem Al Usharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14800",
    "CITYNAME_AR": "ابو الاثرار",
    "CITYNAME_EN": "Abu Al Athrar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14801",
    "CITYNAME_AR": "ابو المرو",
    "CITYNAME_EN": "Abu Al Marw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14802",
    "CITYNAME_AR": "المحلبه",
    "CITYNAME_EN": "Al Mahlabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14803",
    "CITYNAME_AR": "المهشمى",
    "CITYNAME_EN": "Al Mahshami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14804",
    "CITYNAME_AR": "الحنبه",
    "CITYNAME_EN": "Al Hanabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14805",
    "CITYNAME_AR": "العقص",
    "CITYNAME_EN": "Al Aqs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14806",
    "CITYNAME_AR": "قويد الزبره",
    "CITYNAME_EN": "Quwaid Az-Zabarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14807",
    "CITYNAME_AR": "المنجاره",
    "CITYNAME_EN": "Al Monjarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14808",
    "CITYNAME_AR": "قائم الصيد",
    "CITYNAME_EN": "Qaem As-Sayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14809",
    "CITYNAME_AR": "ابو الحنايه",
    "CITYNAME_EN": "Abu Al Henayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14810",
    "CITYNAME_AR": "المغرب",
    "CITYNAME_EN": "Al Maghreb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14811",
    "CITYNAME_AR": "الركعه",
    "CITYNAME_EN": "Ar-Rakaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14812",
    "CITYNAME_AR": "الحصبه",
    "CITYNAME_EN": "Al Hasabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14813",
    "CITYNAME_AR": "بين الظبر",
    "CITYNAME_EN": "Ben Al Zubur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14814",
    "CITYNAME_AR": "بين الرديف",
    "CITYNAME_EN": "Ben Ar-Radeef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14815",
    "CITYNAME_AR": "الشعبات  الزرقاء",
    "CITYNAME_EN": "Ash-Shabat Az-Zarqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14816",
    "CITYNAME_AR": "المروي",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14817",
    "CITYNAME_AR": "بين الرزم",
    "CITYNAME_EN": "Ben Ar-Ruzum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14818",
    "CITYNAME_AR": "قائم العقم الفجر",
    "CITYNAME_EN": "Qaem Al Uqum Al Fajr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14819",
    "CITYNAME_AR": "الحرائق",
    "CITYNAME_EN": "Al Haraeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14820",
    "CITYNAME_AR": "السوده سحمه",
    "CITYNAME_EN": "As-Sawdah Sahmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14821",
    "CITYNAME_AR": "بين البريك",
    "CITYNAME_EN": "Ben Al Barek",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14822",
    "CITYNAME_AR": "المدمع",
    "CITYNAME_EN": "Al Madmaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14823",
    "CITYNAME_AR": "ذوير السرو",
    "CITYNAME_EN": "Zuwyer As-Sarw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14824",
    "CITYNAME_AR": "الشامى والدروب",
    "CITYNAME_EN": "Ash-Shami w Ad-Durub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14825",
    "CITYNAME_AR": "البيناء  وادي الروغ",
    "CITYNAME_EN": "Al Baynaa Wadi Ar-Rawgh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14826",
    "CITYNAME_AR": "عبك",
    "CITYNAME_EN": "Abak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14827",
    "CITYNAME_AR": "غربى شبان",
    "CITYNAME_EN": "Gharbi Shaban",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14828",
    "CITYNAME_AR": "الردهه",
    "CITYNAME_EN": "Ar-Rudhah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14829",
    "CITYNAME_AR": "المحربه",
    "CITYNAME_EN": "Al Mahrabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14830",
    "CITYNAME_AR": "غربى العمره",
    "CITYNAME_EN": "Gharbi Al Umrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14831",
    "CITYNAME_AR": "المسكيه",
    "CITYNAME_EN": "Al Maskyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14832",
    "CITYNAME_AR": "الجرن",
    "CITYNAME_EN": "Al Gorn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14833",
    "CITYNAME_AR": "مناخ",
    "CITYNAME_EN": "Manakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14834",
    "CITYNAME_AR": "وادى درباش",
    "CITYNAME_EN": "Wadi Darbash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14835",
    "CITYNAME_AR": "الرويغ",
    "CITYNAME_EN": "Al Rubagh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14836",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14837",
    "CITYNAME_AR": "الودافه",
    "CITYNAME_EN": "Al Waddafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14838",
    "CITYNAME_AR": "غابط",
    "CITYNAME_EN": "Ghabet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14839",
    "CITYNAME_AR": "النحيت",
    "CITYNAME_EN": "An Naheet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14840",
    "CITYNAME_AR": "الشقب",
    "CITYNAME_EN": "Ash-Shaqab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14841",
    "CITYNAME_AR": "عياس",
    "CITYNAME_EN": "Ayyas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14842",
    "CITYNAME_AR": "الشرقى",
    "CITYNAME_EN": "Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14843",
    "CITYNAME_AR": "جباجب",
    "CITYNAME_EN": "Gabageb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14844",
    "CITYNAME_AR": "خبطه",
    "CITYNAME_EN": "Khabatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14845",
    "CITYNAME_AR": "المركاب",
    "CITYNAME_EN": "Al Merkab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14846",
    "CITYNAME_AR": "رفاعه",
    "CITYNAME_EN": "Refaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14847",
    "CITYNAME_AR": "القلل",
    "CITYNAME_EN": "Al Qullal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14848",
    "CITYNAME_AR": "تشام",
    "CITYNAME_EN": "Cham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14849",
    "CITYNAME_AR": "القصايم",
    "CITYNAME_EN": "Al Qasayem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14850",
    "CITYNAME_AR": "السعادى",
    "CITYNAME_EN": "As-Saadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14851",
    "CITYNAME_AR": "الشحيه",
    "CITYNAME_EN": "Ash-Shahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14852",
    "CITYNAME_AR": "القانى",
    "CITYNAME_EN": "Al Qani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14853",
    "CITYNAME_AR": "العرقه",
    "CITYNAME_EN": "Al Araqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14854",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Al Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14855",
    "CITYNAME_AR": "السيال",
    "CITYNAME_EN": "As-Sayyal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14856",
    "CITYNAME_AR": "الظهراني",
    "CITYNAME_EN": "Az-Zahrani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14857",
    "CITYNAME_AR": "الثواهر",
    "CITYNAME_EN": "Ath-Thawaher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14858",
    "CITYNAME_AR": "ذويد الصرحه",
    "CITYNAME_EN": "Zuwaid As-Sarahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14859",
    "CITYNAME_AR": "قله النيدين",
    "CITYNAME_EN": "Qulet An-Naydayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14860",
    "CITYNAME_AR": "حياف   قفا",
    "CITYNAME_EN": "Hayaf Qafa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14861",
    "CITYNAME_AR": "الخرم",
    "CITYNAME_EN": "Al Khurm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14862",
    "CITYNAME_AR": "شط جابر",
    "CITYNAME_EN": "Shat Gaber",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14863",
    "CITYNAME_AR": "الحذيه",
    "CITYNAME_EN": "Al Huzaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14864",
    "CITYNAME_AR": "بردان",
    "CITYNAME_EN": "Bardan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14865",
    "CITYNAME_AR": "العلى",
    "CITYNAME_EN": "Al Ola",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14866",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Al Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14867",
    "CITYNAME_AR": "بادى",
    "CITYNAME_EN": "Badi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14868",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Ar-Rahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14869",
    "CITYNAME_AR": "درب النجا",
    "CITYNAME_EN": "Darb Al Naja",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14870",
    "CITYNAME_AR": "ذوير الاثبه",
    "CITYNAME_EN": "Zuwyer Al Athyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14871",
    "CITYNAME_AR": "المعصب",
    "CITYNAME_EN": "Al Muasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14872",
    "CITYNAME_AR": "المروى",
    "CITYNAME_EN": "Al Marwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14873",
    "CITYNAME_AR": "الحماطه",
    "CITYNAME_EN": "Al Hamatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14874",
    "CITYNAME_AR": "راومعه",
    "CITYNAME_EN": "Rawmaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14875",
    "CITYNAME_AR": "ذا انيف",
    "CITYNAME_EN": "Za Aneef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14876",
    "CITYNAME_AR": "الثاهر",
    "CITYNAME_EN": "Ath-Thaher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14877",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Al Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14878",
    "CITYNAME_AR": "خيران",
    "CITYNAME_EN": "Kheran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14879",
    "CITYNAME_AR": "ذا ام كرابه",
    "CITYNAME_EN": "Za Om Karabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14880",
    "CITYNAME_AR": "المشرفيات",
    "CITYNAME_EN": "Al Mashrafeyat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14881",
    "CITYNAME_AR": "الكروس",
    "CITYNAME_EN": "Al Karrus",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14882",
    "CITYNAME_AR": "الحقبه",
    "CITYNAME_EN": "Al Huqbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14883",
    "CITYNAME_AR": "سر الحرجه",
    "CITYNAME_EN": "Ser Al Harajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14884",
    "CITYNAME_AR": "دغيشه",
    "CITYNAME_EN": "Dughayshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14885",
    "CITYNAME_AR": "قهمان",
    "CITYNAME_EN": "Qahman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14886",
    "CITYNAME_AR": "ام الثهر",
    "CITYNAME_EN": "Al Meshhar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14887",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "As-Sahlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14888",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al Khtwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14889",
    "CITYNAME_AR": "السقيب",
    "CITYNAME_EN": "As-Suqaib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14890",
    "CITYNAME_AR": "حسن",
    "CITYNAME_EN": "Hasan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14891",
    "CITYNAME_AR": "المحله",
    "CITYNAME_EN": "Al Muhalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14892",
    "CITYNAME_AR": "البقعه",
    "CITYNAME_EN": "Al Buqaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14893",
    "CITYNAME_AR": "حنبت",
    "CITYNAME_EN": "Hanbat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14894",
    "CITYNAME_AR": "ارياد الغبر",
    "CITYNAME_EN": "Arbad Al Ghubur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14895",
    "CITYNAME_AR": "الخطم",
    "CITYNAME_EN": "Al Khutum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14896",
    "CITYNAME_AR": "المعقره",
    "CITYNAME_EN": "Al Maqarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14897",
    "CITYNAME_AR": "المفاليح",
    "CITYNAME_EN": "Al Mafaleeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14898",
    "CITYNAME_AR": "المرقب",
    "CITYNAME_EN": "Al Merkab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14899",
    "CITYNAME_AR": "الروح",
    "CITYNAME_EN": "Al Rawh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14900",
    "CITYNAME_AR": "الرويغ",
    "CITYNAME_EN": "Al Zuwayegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14901",
    "CITYNAME_AR": "النقع",
    "CITYNAME_EN": "An-Naqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14902",
    "CITYNAME_AR": "القرعه",
    "CITYNAME_EN": "Al Quraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14903",
    "CITYNAME_AR": "المنقز",
    "CITYNAME_EN": "Al Manqaz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14904",
    "CITYNAME_AR": "الجعد",
    "CITYNAME_EN": "Al Gaad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14905",
    "CITYNAME_AR": "حوله",
    "CITYNAME_EN": "Hawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14906",
    "CITYNAME_AR": "الغولين",
    "CITYNAME_EN": "Al Ghulayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14907",
    "CITYNAME_AR": "ام عدن",
    "CITYNAME_EN": "Al Adan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14908",
    "CITYNAME_AR": "خطو السرى",
    "CITYNAME_EN": "Khatw As-Seri",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14909",
    "CITYNAME_AR": "المشباب",
    "CITYNAME_EN": "Al Meshbab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14910",
    "CITYNAME_AR": "الجملين",
    "CITYNAME_EN": "Al Jumalayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14911",
    "CITYNAME_AR": "البزخ",
    "CITYNAME_EN": "Al Bazakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14912",
    "CITYNAME_AR": "العباسيه",
    "CITYNAME_EN": "Al Abbasi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14913",
    "CITYNAME_AR": "المشبه",
    "CITYNAME_EN": "Al Mashbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14914",
    "CITYNAME_AR": "ام حضن",
    "CITYNAME_EN": "Om Hoddon",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14915",
    "CITYNAME_AR": "اللحج",
    "CITYNAME_EN": "Al Lahaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14916",
    "CITYNAME_AR": "الآشعاب",
    "CITYNAME_EN": "Al Ashaab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14917",
    "CITYNAME_AR": "الجحيره",
    "CITYNAME_EN": "Al Guhayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14918",
    "CITYNAME_AR": "مراح الذئاب",
    "CITYNAME_EN": "Merah Az-Zeaab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14919",
    "CITYNAME_AR": "المنصوره",
    "CITYNAME_EN": "Al Mansourah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14920",
    "CITYNAME_AR": "ثاهر الجفنه",
    "CITYNAME_EN": "Thaher Al Jafanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14921",
    "CITYNAME_AR": "الاثله",
    "CITYNAME_EN": "Al Athala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14922",
    "CITYNAME_AR": "الدغر",
    "CITYNAME_EN": "Ad-Dughur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14923",
    "CITYNAME_AR": "الجبانه",
    "CITYNAME_EN": "Al Gabanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14924",
    "CITYNAME_AR": "السلف",
    "CITYNAME_EN": "As-Salaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14925",
    "CITYNAME_AR": "الدرب",
    "CITYNAME_EN": "Ad Darb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14926",
    "CITYNAME_AR": "الرقه",
    "CITYNAME_EN": "Ar-Raqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14927",
    "CITYNAME_AR": "الجبجبه",
    "CITYNAME_EN": "Al Jabjabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14928",
    "CITYNAME_AR": "الغمده",
    "CITYNAME_EN": "Al Ghumadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14929",
    "CITYNAME_AR": "البهره",
    "CITYNAME_EN": "Al Baharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14930",
    "CITYNAME_AR": "المجارين",
    "CITYNAME_EN": "Al Magareen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14931",
    "CITYNAME_AR": "قديد ام خطاوه",
    "CITYNAME_EN": "Qaded Om Khatawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14932",
    "CITYNAME_AR": "الرزينه",
    "CITYNAME_EN": "Ar-Razenah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14933",
    "CITYNAME_AR": "ربضه",
    "CITYNAME_EN": "Rabadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14934",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al Hareqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14935",
    "CITYNAME_AR": "ام حستير",
    "CITYNAME_EN": "Om Hasteer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14936",
    "CITYNAME_AR": "الجحيب",
    "CITYNAME_EN": "Al Gaheeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14937",
    "CITYNAME_AR": "السلمين",
    "CITYNAME_EN": "As-Salmeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14938",
    "CITYNAME_AR": "ال مبطى",
    "CITYNAME_EN": "Al Mabte",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14939",
    "CITYNAME_AR": "البزخ",
    "CITYNAME_EN": "Al Bazakh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14940",
    "CITYNAME_AR": "البطنه",
    "CITYNAME_EN": "Al Batanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14941",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14942",
    "CITYNAME_AR": "الجحيره ال الحاشدي",
    "CITYNAME_EN": "Al Juhyrah Al Al Hashedi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14943",
    "CITYNAME_AR": "الدمون",
    "CITYNAME_EN": "Ad-Damoun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14944",
    "CITYNAME_AR": "الغاويه",
    "CITYNAME_EN": "Al Ghawyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14945",
    "CITYNAME_AR": "خميعه",
    "CITYNAME_EN": "Khumayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14946",
    "CITYNAME_AR": "المسبح",
    "CITYNAME_EN": "Al Masbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14947",
    "CITYNAME_AR": "الخرشه",
    "CITYNAME_EN": "Al Kharashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14948",
    "CITYNAME_AR": "خالفه ام جبر",
    "CITYNAME_EN": "Khalefah Om Jabr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14949",
    "CITYNAME_AR": "وادي الساهية",
    "CITYNAME_EN": "Wadi As-Sahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14950",
    "CITYNAME_AR": "كينان",
    "CITYNAME_EN": "Kenan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14951",
    "CITYNAME_AR": "الشعبه وتشمل",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14952",
    "CITYNAME_AR": "مراح العراشيه",
    "CITYNAME_EN": "Marah Al Arashyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14953",
    "CITYNAME_AR": "المعاليق",
    "CITYNAME_EN": "Al Maaleeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14954",
    "CITYNAME_AR": "حبيل المالكى",
    "CITYNAME_EN": "Hybail Al Maliki",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14955",
    "CITYNAME_AR": "المساوده السودى",
    "CITYNAME_EN": "Al Masawedah As-Sawdi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14956",
    "CITYNAME_AR": "المصبح",
    "CITYNAME_EN": "Al Mesbah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14957",
    "CITYNAME_AR": "الجوار",
    "CITYNAME_EN": "Aj-Jowar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14958",
    "CITYNAME_AR": "ام قاعين",
    "CITYNAME_EN": "Om Qaien",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14959",
    "CITYNAME_AR": "ام ردفه",
    "CITYNAME_EN": "Om Radafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14960",
    "CITYNAME_AR": "المنقع",
    "CITYNAME_EN": "Al Manqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14961",
    "CITYNAME_AR": "جبجب",
    "CITYNAME_EN": "Jabjab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14962",
    "CITYNAME_AR": "المسمعه",
    "CITYNAME_EN": "Al Masmaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14963",
    "CITYNAME_AR": "الغدير",
    "CITYNAME_EN": "Al Ghadeer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14964",
    "CITYNAME_AR": "قرن ام خافه",
    "CITYNAME_EN": "Qarn Om Khafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14965",
    "CITYNAME_AR": "عرقوده",
    "CITYNAME_EN": "Arqudah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14966",
    "CITYNAME_AR": "سرا الرباعى",
    "CITYNAME_EN": "Ser Ar-Rubae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14967",
    "CITYNAME_AR": "عرفطى",
    "CITYNAME_EN": "Arfute",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14968",
    "CITYNAME_AR": "المزرق",
    "CITYNAME_EN": "Al Mezraq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14969",
    "CITYNAME_AR": "المعترض",
    "CITYNAME_EN": "Al Muatared",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14970",
    "CITYNAME_AR": "دبيجان",
    "CITYNAME_EN": "Dubayjan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14971",
    "CITYNAME_AR": "لحج نبعان",
    "CITYNAME_EN": "Lahj Nabaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14972",
    "CITYNAME_AR": "الفراجه",
    "CITYNAME_EN": "Al Farajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14973",
    "CITYNAME_AR": "مزم بركه",
    "CITYNAME_EN": "Mazam Barakah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14974",
    "CITYNAME_AR": "سر الغله",
    "CITYNAME_EN": "Ser Al-Ghalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14975",
    "CITYNAME_AR": "أم الدود العليا",
    "CITYNAME_EN": "Om Ad-Dud Al-Olya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14976",
    "CITYNAME_AR": "الكتيفه",
    "CITYNAME_EN": "Al Kutayfah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14977",
    "CITYNAME_AR": "السواديات",
    "CITYNAME_EN": "As-Swadiyat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14978",
    "CITYNAME_AR": "المعزب",
    "CITYNAME_EN": "Al Muazeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14979",
    "CITYNAME_AR": "ابو غباره",
    "CITYNAME_EN": "Abo Ghabbarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14980",
    "CITYNAME_AR": "أم الدود السفلى",
    "CITYNAME_EN": "Om Ad-Dud As-Sufla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14981",
    "CITYNAME_AR": "مسقيه",
    "CITYNAME_EN": "Masqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14982",
    "CITYNAME_AR": "مروه مسقيه",
    "CITYNAME_EN": "Marwah Masqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14983",
    "CITYNAME_AR": "باطن الامير",
    "CITYNAME_EN": "Baten Al Amir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14984",
    "CITYNAME_AR": "المدمدمه المساوده",
    "CITYNAME_EN": "Al Madmadmah Al Musawedah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14985",
    "CITYNAME_AR": "المريا",
    "CITYNAME_EN": "Al Maraya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14986",
    "CITYNAME_AR": "المدمدمه العرابيه",
    "CITYNAME_EN": "Al Madmadmah Al Orabiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14987",
    "CITYNAME_AR": "الشديد",
    "CITYNAME_EN": "Ash-Shadeed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14988",
    "CITYNAME_AR": "المراويغ",
    "CITYNAME_EN": "Al Maraweegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14989",
    "CITYNAME_AR": "ردحه الجبليه",
    "CITYNAME_EN": "Radaha Al Jabaliyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14990",
    "CITYNAME_AR": "الكرره",
    "CITYNAME_EN": "Al Kararah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14991",
    "CITYNAME_AR": "القحاطين",
    "CITYNAME_EN": "Al Qahateen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14992",
    "CITYNAME_AR": "بريقع",
    "CITYNAME_EN": "Burayqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14993",
    "CITYNAME_AR": "الخرق",
    "CITYNAME_EN": "Al Kharq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14994",
    "CITYNAME_AR": "الموشث",
    "CITYNAME_EN": "Al Musheth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14995",
    "CITYNAME_AR": "البطيش",
    "CITYNAME_EN": "Al Batesh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14996",
    "CITYNAME_AR": "الرخه",
    "CITYNAME_EN": "Ar-Rukha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14997",
    "CITYNAME_AR": "الخباره",
    "CITYNAME_EN": "Al Khabbarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14998",
    "CITYNAME_AR": "المبيت",
    "CITYNAME_EN": "Al Mabeet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14999",
    "CITYNAME_AR": "العرابيه",
    "CITYNAME_EN": "Al Orabiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15000",
    "CITYNAME_AR": "المجعيره",
    "CITYNAME_EN": "Al Mugaayarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15001",
    "CITYNAME_AR": "مرايغه",
    "CITYNAME_EN": "Murayeghah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15002",
    "CITYNAME_AR": "الرنف",
    "CITYNAME_EN": "Ar-Ranaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15003",
    "CITYNAME_AR": "البزه  العين الحاره",
    "CITYNAME_EN": "Al Bazah Al Ayn Al Harah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15004",
    "CITYNAME_AR": "الكلبه",
    "CITYNAME_EN": "Al Kalabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15005",
    "CITYNAME_AR": "الخطيه   روان العبيد",
    "CITYNAME_EN": "Al Khatyyah Ruwan Al Obaid",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15006",
    "CITYNAME_AR": "القنابير",
    "CITYNAME_EN": "Al Qanabir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15007",
    "CITYNAME_AR": "عرق",
    "CITYNAME_EN": "Araq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15008",
    "CITYNAME_AR": "الشحيذيه",
    "CITYNAME_EN": "Ash-Shuhayziyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15009",
    "CITYNAME_AR": "القوع الأسفل",
    "CITYNAME_EN": "Al Qua Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15010",
    "CITYNAME_AR": "القوع الأعلى",
    "CITYNAME_EN": "Al Qua Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15011",
    "CITYNAME_AR": "سالب",
    "CITYNAME_EN": "Saleb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15012",
    "CITYNAME_AR": "الملاقى",
    "CITYNAME_EN": "Al Malaqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15013",
    "CITYNAME_AR": "القبر",
    "CITYNAME_EN": "Al Qabr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15014",
    "CITYNAME_AR": "سقوى معطوطة",
    "CITYNAME_EN": "Saqwa Maatota",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15015",
    "CITYNAME_AR": "العقله العروق الشمالية",
    "CITYNAME_EN": "Al Oqalah Al Orooq Ash-Shamalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15016",
    "CITYNAME_AR": "الاحوله",
    "CITYNAME_EN": "Al Ahwalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15017",
    "CITYNAME_AR": "السودى",
    "CITYNAME_EN": "As-Sawdi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15018",
    "CITYNAME_AR": "الشراحيلى",
    "CITYNAME_EN": "Ash-Sharaheli",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15019",
    "CITYNAME_AR": "قائم مجرشى",
    "CITYNAME_EN": "Qaem Magrashi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15020",
    "CITYNAME_AR": "ام الحسير",
    "CITYNAME_EN": "Om Al Haseer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15021",
    "CITYNAME_AR": "البحثه",
    "CITYNAME_EN": "Al Bahthah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15022",
    "CITYNAME_AR": "مرباش",
    "CITYNAME_EN": "Merbash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15023",
    "CITYNAME_AR": "السلامه",
    "CITYNAME_EN": "As-Salamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15024",
    "CITYNAME_AR": "قائم الصيد",
    "CITYNAME_EN": "Qaem As-Sayd",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15025",
    "CITYNAME_AR": "الشكوانى",
    "CITYNAME_EN": "Ash-Shawkani",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15026",
    "CITYNAME_AR": "ابوالرقاع",
    "CITYNAME_EN": "Abo Ar-Ruqaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15027",
    "CITYNAME_AR": "الكدره  ام دهشيلة",
    "CITYNAME_EN": "Al Kadarah Om Dahshylah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15028",
    "CITYNAME_AR": "سرور",
    "CITYNAME_EN": "Sorour",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15029",
    "CITYNAME_AR": "الرزان",
    "CITYNAME_EN": "Ar-Razan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15030",
    "CITYNAME_AR": "الدرادحه مد الشام",
    "CITYNAME_EN": "Ad-Daradahah Mad Ash-Sham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15031",
    "CITYNAME_AR": "ابو سبيله",
    "CITYNAME_EN": "Abo Sabelah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15032",
    "CITYNAME_AR": "حديد",
    "CITYNAME_EN": "Hadeed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15033",
    "CITYNAME_AR": "رضوان",
    "CITYNAME_EN": "Radwan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15034",
    "CITYNAME_AR": "رفيح الشرقى",
    "CITYNAME_EN": "Rafeeh Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15035",
    "CITYNAME_AR": "ابو الظهي",
    "CITYNAME_EN": "Abo Az-Zuha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15036",
    "CITYNAME_AR": "النقيل ثعيل رفيح",
    "CITYNAME_EN": "An-Naqeel Thael Rafeeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15037",
    "CITYNAME_AR": "دمنه دهيشان",
    "CITYNAME_EN": "Damnah Duhayshan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15038",
    "CITYNAME_AR": "ابوالعرج",
    "CITYNAME_EN": "Abo Al Araj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15039",
    "CITYNAME_AR": "الحطباية",
    "CITYNAME_EN": "Al Hatabayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15040",
    "CITYNAME_AR": "المبروقه العروق الجنوبيه",
    "CITYNAME_EN": "Al Mabrouqah Al Oroq Al Janubyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15041",
    "CITYNAME_AR": "الدحره",
    "CITYNAME_EN": "Ad-Dahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15042",
    "CITYNAME_AR": "مراوى الخبرايه",
    "CITYNAME_EN": "Marawi Al Khabrayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15043",
    "CITYNAME_AR": "ابو العشره",
    "CITYNAME_EN": "Abo Al Asherah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15044",
    "CITYNAME_AR": "الزلحيطه",
    "CITYNAME_EN": "Az-Zalhyetah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15045",
    "CITYNAME_AR": "المنافير",
    "CITYNAME_EN": "Al Manafir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15046",
    "CITYNAME_AR": "المقطاع",
    "CITYNAME_EN": "Al Meqtaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15047",
    "CITYNAME_AR": "قبر الجاريه ام غرفه",
    "CITYNAME_EN": "Qabr Al Jaryah Om Gharafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15048",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Al Gharafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15049",
    "CITYNAME_AR": "الشذابه",
    "CITYNAME_EN": "Ash-Shazabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15050",
    "CITYNAME_AR": "الركب",
    "CITYNAME_EN": "Ar-Rakab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15051",
    "CITYNAME_AR": "الزهب",
    "CITYNAME_EN": "Az-Zahab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15052",
    "CITYNAME_AR": "الشماع",
    "CITYNAME_EN": "Ash-Shamaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15053",
    "CITYNAME_AR": "الحرائق",
    "CITYNAME_EN": "Al Haraeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15054",
    "CITYNAME_AR": "ممخطه",
    "CITYNAME_EN": "Mamkhatah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15055",
    "CITYNAME_AR": "الربيحى",
    "CITYNAME_EN": "Ar-Rubayhi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15056",
    "CITYNAME_AR": "الحصامه",
    "CITYNAME_EN": "Al Husamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15057",
    "CITYNAME_AR": "ام الطحال",
    "CITYNAME_EN": "Om At-Tuhal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15058",
    "CITYNAME_AR": "ابو قمتين",
    "CITYNAME_EN": "Abo Qematayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15059",
    "CITYNAME_AR": "قعارى",
    "CITYNAME_EN": "Qaari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15060",
    "CITYNAME_AR": "الخمامه",
    "CITYNAME_EN": "Al Khomamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15061",
    "CITYNAME_AR": "اللاوات",
    "CITYNAME_EN": "Al Lawat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15062",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Al Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15063",
    "CITYNAME_AR": "الركبان",
    "CITYNAME_EN": "Ar-Rakabat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15064",
    "CITYNAME_AR": "الغربي",
    "CITYNAME_EN": "Al Gharbye",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15065",
    "CITYNAME_AR": "ظهر الجمل",
    "CITYNAME_EN": "Zahr Al Jamal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15066",
    "CITYNAME_AR": "المحارب",
    "CITYNAME_EN": "Al Muhareb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15067",
    "CITYNAME_AR": "المجازيع",
    "CITYNAME_EN": "Al Magazee",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15068",
    "CITYNAME_AR": "أسفل دثه",
    "CITYNAME_EN": "Asfal Dathah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15069",
    "CITYNAME_AR": "فحل",
    "CITYNAME_EN": "Fahl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15070",
    "CITYNAME_AR": "قلة السلاعه",
    "CITYNAME_EN": "Qulet As-Salahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15071",
    "CITYNAME_AR": "حبيل الصاره",
    "CITYNAME_EN": "Hybail As-Sarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15072",
    "CITYNAME_AR": "الخطيم",
    "CITYNAME_EN": "Al Khatim",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15073",
    "CITYNAME_AR": "الجلهه",
    "CITYNAME_EN": "Al Jalaha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15074",
    "CITYNAME_AR": "السودي",
    "CITYNAME_EN": "As-Sawdi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15075",
    "CITYNAME_AR": "الخارجه",
    "CITYNAME_EN": "Al Kharajah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15076",
    "CITYNAME_AR": "السعادى",
    "CITYNAME_EN": "As-Saadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15077",
    "CITYNAME_AR": "الشابحين",
    "CITYNAME_EN": "Ash-Shabeheen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15078",
    "CITYNAME_AR": "العرعره",
    "CITYNAME_EN": "Al Ararah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15079",
    "CITYNAME_AR": "المشورات",
    "CITYNAME_EN": "Al Mashurat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15080",
    "CITYNAME_AR": "لحج القلاع",
    "CITYNAME_EN": "Lahj Al Qelaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15081",
    "CITYNAME_AR": "ابور",
    "CITYNAME_EN": "Aboor",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15082",
    "CITYNAME_AR": "المنفس",
    "CITYNAME_EN": "Al Manfas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15083",
    "CITYNAME_AR": "الوجحه",
    "CITYNAME_EN": "Al Wajahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15084",
    "CITYNAME_AR": "الجمله",
    "CITYNAME_EN": "Al Jumlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15085",
    "CITYNAME_AR": "الموقر",
    "CITYNAME_EN": "Al Muwaqar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15086",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al Muqaar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15087",
    "CITYNAME_AR": "الثوعه",
    "CITYNAME_EN": "Al Thawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15088",
    "CITYNAME_AR": "حيدر",
    "CITYNAME_EN": "Haydar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15089",
    "CITYNAME_AR": "القمعه",
    "CITYNAME_EN": "Al Qumaah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15090",
    "CITYNAME_AR": "الهدف",
    "CITYNAME_EN": "Al Hadaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15091",
    "CITYNAME_AR": "الخشعه",
    "CITYNAME_EN": "Al Khashaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15092",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "As-Sarw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15093",
    "CITYNAME_AR": "القنان",
    "CITYNAME_EN": "Al Qanaan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15094",
    "CITYNAME_AR": "السترين",
    "CITYNAME_EN": "As-Satrayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15095",
    "CITYNAME_AR": "خباره",
    "CITYNAME_EN": "Khabbarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15096",
    "CITYNAME_AR": "بوجان",
    "CITYNAME_EN": "Bujan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15097",
    "CITYNAME_AR": "اللوي",
    "CITYNAME_EN": "Al Lawi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15098",
    "CITYNAME_AR": "ثاهر بوجان",
    "CITYNAME_EN": "Thaher Bujan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15099",
    "CITYNAME_AR": "الشوك و العجز",
    "CITYNAME_EN": "Ash-Shawk and Al Ajaz",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15100",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Az-Zuharah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15101",
    "CITYNAME_AR": "الحجل",
    "CITYNAME_EN": "Al Hajal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15102",
    "CITYNAME_AR": "محظوه",
    "CITYNAME_EN": "Mahzouah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15103",
    "CITYNAME_AR": "مخروق",
    "CITYNAME_EN": "Makhzouq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15104",
    "CITYNAME_AR": "ثربه",
    "CITYNAME_EN": "Tharabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15105",
    "CITYNAME_AR": "ناجع",
    "CITYNAME_EN": "Najea",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15106",
    "CITYNAME_AR": "المخش",
    "CITYNAME_EN": "Al Mukhash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15107",
    "CITYNAME_AR": "الدحر",
    "CITYNAME_EN": "Ad-Dahar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15108",
    "CITYNAME_AR": "الصهلاء",
    "CITYNAME_EN": "As-Suhalaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15109",
    "CITYNAME_AR": "الجحيره",
    "CITYNAME_EN": "Al Guhayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15110",
    "CITYNAME_AR": "ظهر السلوف",
    "CITYNAME_EN": "Zahr As-Saluf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15111",
    "CITYNAME_AR": "ام وقر",
    "CITYNAME_EN": "Om Waqr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15112",
    "CITYNAME_AR": "الوجحه",
    "CITYNAME_EN": "Al Wajahah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15113",
    "CITYNAME_AR": "قله الشط",
    "CITYNAME_EN": "Qulet Ash-Shat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15114",
    "CITYNAME_AR": "عاكيه",
    "CITYNAME_EN": "Akiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15115",
    "CITYNAME_AR": "المشارع",
    "CITYNAME_EN": "Al Musharei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15116",
    "CITYNAME_AR": "الخثوم",
    "CITYNAME_EN": "Al Khathum",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15117",
    "CITYNAME_AR": "حبيل الغلث",
    "CITYNAME_EN": "Hybail Al Ghalath",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15118",
    "CITYNAME_AR": "حضية منصب",
    "CITYNAME_EN": "Hudaybet Munseb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15119",
    "CITYNAME_AR": "المضه",
    "CITYNAME_EN": "Al Madha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15120",
    "CITYNAME_AR": "حبيل السلب",
    "CITYNAME_EN": "Hubayel As-Salab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15121",
    "CITYNAME_AR": "الشجن",
    "CITYNAME_EN": "Ash-Shagan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15122",
    "CITYNAME_AR": "محولة",
    "CITYNAME_EN": "Muhawwalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15123",
    "CITYNAME_AR": "بين القلل",
    "CITYNAME_EN": "Ben Al Qulal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15124",
    "CITYNAME_AR": "الدهكبيات",
    "CITYNAME_EN": "Ad-Dahkabiyat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15125",
    "CITYNAME_AR": "ذويرالسعادي",
    "CITYNAME_EN": "Zuber As-Saadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15126",
    "CITYNAME_AR": "الظلف",
    "CITYNAME_EN": "Az-Zalaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15127",
    "CITYNAME_AR": "نيد الشامي",
    "CITYNAME_EN": "Ned Ash-Shami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15128",
    "CITYNAME_AR": "خوارمة",
    "CITYNAME_EN": "Khawarmah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15129",
    "CITYNAME_AR": "الدفاين",
    "CITYNAME_EN": "Ad-Dafayen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15130",
    "CITYNAME_AR": "زحوم وذا الساقين",
    "CITYNAME_EN": "Zahoum w Za As-Saqayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15131",
    "CITYNAME_AR": "روحان",
    "CITYNAME_EN": "Rawhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15132",
    "CITYNAME_AR": "سقم",
    "CITYNAME_EN": "Saqam",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15133",
    "CITYNAME_AR": "قرية أخله",
    "CITYNAME_EN": "Qariyat Akhalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15134",
    "CITYNAME_AR": "الحيادين",
    "CITYNAME_EN": "Al Hayadeen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15135",
    "CITYNAME_AR": "الدوقي",
    "CITYNAME_EN": "Ad-Dawki",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15136",
    "CITYNAME_AR": "خايع شوكان",
    "CITYNAME_EN": "Khaei Shawkan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15137",
    "CITYNAME_AR": "رادف الجمايم",
    "CITYNAME_EN": "Radef Al Jamayem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15138",
    "CITYNAME_AR": "القصبه",
    "CITYNAME_EN": "Al Qasba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15139",
    "CITYNAME_AR": "دبير",
    "CITYNAME_EN": "Dabeer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15140",
    "CITYNAME_AR": "قائم الصوله",
    "CITYNAME_EN": "Qaem As-Sawalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15141",
    "CITYNAME_AR": "قائم ملفى",
    "CITYNAME_EN": "Qaem Malfi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15142",
    "CITYNAME_AR": "دمنه راشد",
    "CITYNAME_EN": "Damanah Rashed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15143",
    "CITYNAME_AR": "الجحشه",
    "CITYNAME_EN": "Al Jahashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15144",
    "CITYNAME_AR": "العلايا",
    "CITYNAME_EN": "Al Olayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15145",
    "CITYNAME_AR": "القرعه وتشمل",
    "CITYNAME_EN": "Al Quraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15146",
    "CITYNAME_AR": "اللجمه",
    "CITYNAME_EN": "Al Lajamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15147",
    "CITYNAME_AR": "المشاف",
    "CITYNAME_EN": "Al Mashaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15148",
    "CITYNAME_AR": "الشقبه",
    "CITYNAME_EN": "Ash-Shaqabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15149",
    "CITYNAME_AR": "المقارعه",
    "CITYNAME_EN": "Al Maqareah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15150",
    "CITYNAME_AR": "البارود",
    "CITYNAME_EN": "Al Barud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15151",
    "CITYNAME_AR": "قائم عائشه",
    "CITYNAME_EN": "Qaem Aishah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15152",
    "CITYNAME_AR": "قائم الغبرا",
    "CITYNAME_EN": "Qaem Al Ghabrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15153",
    "CITYNAME_AR": "قائم عسكري",
    "CITYNAME_EN": "Qaem Al Askari",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15154",
    "CITYNAME_AR": "الحنبكه",
    "CITYNAME_EN": "Al Hanbakah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15155",
    "CITYNAME_AR": "غرنوقه",
    "CITYNAME_EN": "Gharnoukah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15156",
    "CITYNAME_AR": "الزريبه",
    "CITYNAME_EN": "Az-Zaraybah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15157",
    "CITYNAME_AR": "سلف القمر",
    "CITYNAME_EN": "Salaf Al Qumur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15158",
    "CITYNAME_AR": "سرزمير",
    "CITYNAME_EN": "Sarzamir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15159",
    "CITYNAME_AR": "الرطيل",
    "CITYNAME_EN": "Ar-Rateel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15160",
    "CITYNAME_AR": "مشباح الغرابه",
    "CITYNAME_EN": "Meshbah Al Ghurabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15161",
    "CITYNAME_AR": "البرامي",
    "CITYNAME_EN": "Al Barami",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15162",
    "CITYNAME_AR": "كرس بادي",
    "CITYNAME_EN": "Kurs Badi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15163",
    "CITYNAME_AR": "الحوشبي الاعلى",
    "CITYNAME_EN": "Al Hawshi Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15164",
    "CITYNAME_AR": "الموشم",
    "CITYNAME_EN": "Al Maushem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15165",
    "CITYNAME_AR": "جوال",
    "CITYNAME_EN": "Jawal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15166",
    "CITYNAME_AR": "غابيه",
    "CITYNAME_EN": "Ghabyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15167",
    "CITYNAME_AR": "قائم الصرحه",
    "CITYNAME_EN": "Qaem As-Saraha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15168",
    "CITYNAME_AR": "موقتاده",
    "CITYNAME_EN": "Muqtadah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15169",
    "CITYNAME_AR": "كرسى رباح",
    "CITYNAME_EN": "Kursi Reyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15170",
    "CITYNAME_AR": "المناكع",
    "CITYNAME_EN": "Al Manakei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15171",
    "CITYNAME_AR": "المدينه",
    "CITYNAME_EN": "Al Madinah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15172",
    "CITYNAME_AR": "فحلان",
    "CITYNAME_EN": "Fahlan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15173",
    "CITYNAME_AR": "سفانه",
    "CITYNAME_EN": "Safanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15174",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Al Dahla",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15175",
    "CITYNAME_AR": "ام دروقي",
    "CITYNAME_EN": "Om Druqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15176",
    "CITYNAME_AR": "حسن علي",
    "CITYNAME_EN": "Hasan Ali village",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15177",
    "CITYNAME_AR": "الخشه",
    "CITYNAME_EN": "Al Khashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15178",
    "CITYNAME_AR": "المخشه",
    "CITYNAME_EN": "Al Makhashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15179",
    "CITYNAME_AR": "معتق مثار",
    "CITYNAME_EN": "Moateq Mathar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15180",
    "CITYNAME_AR": "غرب  المخش",
    "CITYNAME_EN": "Gharb Al Makhash",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15181",
    "CITYNAME_AR": "القواعي",
    "CITYNAME_EN": "Al Qawaei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15182",
    "CITYNAME_AR": "ذوير المروي",
    "CITYNAME_EN": "Zuber Al Marwi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15183",
    "CITYNAME_AR": "القراعه",
    "CITYNAME_EN": "Al Quraaha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15184",
    "CITYNAME_AR": "بشعه",
    "CITYNAME_EN": "Bashaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15185",
    "CITYNAME_AR": "قمران",
    "CITYNAME_EN": "Qamaran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15186",
    "CITYNAME_AR": "معتق العصم",
    "CITYNAME_EN": "Muateq Al Asm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15187",
    "CITYNAME_AR": "حبيس",
    "CITYNAME_EN": "Habees",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15188",
    "CITYNAME_AR": "الاذن  و الوقيع",
    "CITYNAME_EN": "Al Ozon and Al Waqei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15189",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15190",
    "CITYNAME_AR": "ذوي الصرو",
    "CITYNAME_EN": "Zuwei As-Sarw",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15191",
    "CITYNAME_AR": "مراط",
    "CITYNAME_EN": "Marat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15192",
    "CITYNAME_AR": "هجيره",
    "CITYNAME_EN": "Hujayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15193",
    "CITYNAME_AR": "تنادح ـ القشب",
    "CITYNAME_EN": "Tanadeh - Al Qashab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15194",
    "CITYNAME_AR": "برده",
    "CITYNAME_EN": "Bardah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15195",
    "CITYNAME_AR": "التشام",
    "CITYNAME_EN": "Al Tasham",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15196",
    "CITYNAME_AR": "الوجرات",
    "CITYNAME_EN": "Al Wagarat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15197",
    "CITYNAME_AR": "عفران",
    "CITYNAME_EN": "Afran",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15198",
    "CITYNAME_AR": "الغاله",
    "CITYNAME_EN": "Al Ghalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15199",
    "CITYNAME_AR": "الخشبه",
    "CITYNAME_EN": "Al Khashabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15200",
    "CITYNAME_AR": "الصفيف",
    "CITYNAME_EN": "Al Safef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15201",
    "CITYNAME_AR": "نعمان",
    "CITYNAME_EN": "Nuaman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15202",
    "CITYNAME_AR": "ذوير حميه",
    "CITYNAME_EN": "Zuber Hamyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15203",
    "CITYNAME_AR": "تعذر",
    "CITYNAME_EN": "Taazur",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15204",
    "CITYNAME_AR": "الملوس",
    "CITYNAME_EN": "Al Mulos",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15205",
    "CITYNAME_AR": "المخلف",
    "CITYNAME_EN": "Al Makhlaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15206",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Al Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15207",
    "CITYNAME_AR": "الرويس",
    "CITYNAME_EN": "Ar-Rubas",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15208",
    "CITYNAME_AR": "المقبع",
    "CITYNAME_EN": "Al Muqabaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15209",
    "CITYNAME_AR": "ربخان",
    "CITYNAME_EN": "Rabkhan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15210",
    "CITYNAME_AR": "غربي العطيف",
    "CITYNAME_EN": "Gharbye Al Ateef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15211",
    "CITYNAME_AR": "العمريات",
    "CITYNAME_EN": "Al Omaryat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15212",
    "CITYNAME_AR": "جهوة اللجيج",
    "CITYNAME_EN": "Jahwet Al Lajej",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15213",
    "CITYNAME_AR": "وادي نعمان",
    "CITYNAME_EN": "Wadi Nuaman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15214",
    "CITYNAME_AR": "الروح",
    "CITYNAME_EN": "Ar-Ruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15215",
    "CITYNAME_AR": "آل  محمد",
    "CITYNAME_EN": "Al Mohamed",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15216",
    "CITYNAME_AR": "الكعوب",
    "CITYNAME_EN": "Al Kouab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15217",
    "CITYNAME_AR": "منيف",
    "CITYNAME_EN": "Maneef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15218",
    "CITYNAME_AR": "الراس",
    "CITYNAME_EN": "Ar-Ras",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15219",
    "CITYNAME_AR": "معتق حرف",
    "CITYNAME_EN": "Muataq Harf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15220",
    "CITYNAME_AR": "الجمله وتشمل",
    "CITYNAME_EN": "Al Jumlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15221",
    "CITYNAME_AR": "خروق",
    "CITYNAME_EN": "Khayruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15222",
    "CITYNAME_AR": "شيبان",
    "CITYNAME_EN": "Shebyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15223",
    "CITYNAME_AR": "النقيل",
    "CITYNAME_EN": "An-Naqeel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15224",
    "CITYNAME_AR": "الشعف",
    "CITYNAME_EN": "Ash-Shaaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15225",
    "CITYNAME_AR": "الردفين",
    "CITYNAME_EN": "Ar-Radfayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15226",
    "CITYNAME_AR": "معتق المنيعي",
    "CITYNAME_EN": "Muataq Al Manei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15227",
    "CITYNAME_AR": "الوثنات",
    "CITYNAME_EN": "Al Wathanyat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15228",
    "CITYNAME_AR": "ذوير العفار",
    "CITYNAME_EN": "Zuber Al Affar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15229",
    "CITYNAME_AR": "الرشين",
    "CITYNAME_EN": "Al Rasheen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15230",
    "CITYNAME_AR": "معتق هيس",
    "CITYNAME_EN": "Muateq Hees",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15231",
    "CITYNAME_AR": "ذوير غابط",
    "CITYNAME_EN": "Zubeir Ghabet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15232",
    "CITYNAME_AR": "المعاتق",
    "CITYNAME_EN": "Al Muateq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15233",
    "CITYNAME_AR": "العمره",
    "CITYNAME_EN": "Al Omrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15234",
    "CITYNAME_AR": "الشرقي وتشمل",
    "CITYNAME_EN": "Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15235",
    "CITYNAME_AR": "الورك",
    "CITYNAME_EN": "Al Warak",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15236",
    "CITYNAME_AR": "هموم",
    "CITYNAME_EN": "Humom",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15237",
    "CITYNAME_AR": "الثواهر",
    "CITYNAME_EN": "Ath-Thawaher",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15238",
    "CITYNAME_AR": "معمال سافع",
    "CITYNAME_EN": "Maamal Safei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15239",
    "CITYNAME_AR": "ام نكره",
    "CITYNAME_EN": "Om Nakerah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15240",
    "CITYNAME_AR": "داغر مرعي",
    "CITYNAME_EN": "Dagher Marei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15241",
    "CITYNAME_AR": "الخايع",
    "CITYNAME_EN": "Al Khayei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15242",
    "CITYNAME_AR": "المزارق",
    "CITYNAME_EN": "Al Mazareq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15243",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15244",
    "CITYNAME_AR": "الكبسه",
    "CITYNAME_EN": "Al Kabsah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15245",
    "CITYNAME_AR": "المراويغ",
    "CITYNAME_EN": "Al Maraweegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15246",
    "CITYNAME_AR": "المجارين",
    "CITYNAME_EN": "Al Magareen",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15247",
    "CITYNAME_AR": "الصريو",
    "CITYNAME_EN": "Al Saryu",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15248",
    "CITYNAME_AR": "الجرانب",
    "CITYNAME_EN": "Al Garayeb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15249",
    "CITYNAME_AR": "غابط",
    "CITYNAME_EN": "Ghabet",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15250",
    "CITYNAME_AR": "التدادغ",
    "CITYNAME_EN": "Al Tadadegh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15251",
    "CITYNAME_AR": "ام ركبه",
    "CITYNAME_EN": "Om Rakabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15252",
    "CITYNAME_AR": "المروي",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15253",
    "CITYNAME_AR": "الحوشبي الاسفل",
    "CITYNAME_EN": "Al Hawshi Al Asfal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15254",
    "CITYNAME_AR": "خرت عين العناق",
    "CITYNAME_EN": "Khart Een Al Enaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15255",
    "CITYNAME_AR": "قله الوقر",
    "CITYNAME_EN": "Qulet Al Waqr",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15256",
    "CITYNAME_AR": "داغر الملاحه",
    "CITYNAME_EN": "Dagher Al Melaha",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15257",
    "CITYNAME_AR": "المطاريق",
    "CITYNAME_EN": "Al Matareeq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15258",
    "CITYNAME_AR": "المرتزه",
    "CITYNAME_EN": "Al Murtazah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15259",
    "CITYNAME_AR": "المطاريق الشمالية",
    "CITYNAME_EN": "Al Matareeq Ash-Shamalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15260",
    "CITYNAME_AR": "القفرة",
    "CITYNAME_EN": "Al Qafrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15261",
    "CITYNAME_AR": "الصوع",
    "CITYNAME_EN": "As-Sawei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15262",
    "CITYNAME_AR": "قلة البير",
    "CITYNAME_EN": "Qulet Al Beir",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15263",
    "CITYNAME_AR": "ام علايا",
    "CITYNAME_EN": "Janub Al Olayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15264",
    "CITYNAME_AR": "المطينه",
    "CITYNAME_EN": "Al Matenah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15265",
    "CITYNAME_AR": "مرضيه",
    "CITYNAME_EN": "Mardiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15266",
    "CITYNAME_AR": "قائم بني شراحيل",
    "CITYNAME_EN": "Qaem Bani Shraheel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15267",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15268",
    "CITYNAME_AR": "الدرابجه",
    "CITYNAME_EN": "Ad-Darabjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15269",
    "CITYNAME_AR": "قائم احمديني",
    "CITYNAME_EN": "Qaem Ahmadeni",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15270",
    "CITYNAME_AR": "المرايا",
    "CITYNAME_EN": "Al Maraya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15271",
    "CITYNAME_AR": "ام العكثل",
    "CITYNAME_EN": "Om Al Akthal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15272",
    "CITYNAME_AR": "المربط",
    "CITYNAME_EN": "Al Marbat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15273",
    "CITYNAME_AR": "عرفطي",
    "CITYNAME_EN": "Arfeti",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15274",
    "CITYNAME_AR": "ام هذارب",
    "CITYNAME_EN": "Om Hathareb",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15275",
    "CITYNAME_AR": "جرار",
    "CITYNAME_EN": "Jarar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15276",
    "CITYNAME_AR": "البحص",
    "CITYNAME_EN": "Al Bahs",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15277",
    "CITYNAME_AR": "الحميراء",
    "CITYNAME_EN": "Al Humayraa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15278",
    "CITYNAME_AR": "قرن ام خنجف",
    "CITYNAME_EN": "Qarn Om Khangaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15279",
    "CITYNAME_AR": "الرقاق",
    "CITYNAME_EN": "Ar-Raqaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15280",
    "CITYNAME_AR": "العشوة الجنوبية",
    "CITYNAME_EN": "Al Oshwa Al Janubyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15281",
    "CITYNAME_AR": "النبيعة الغربية",
    "CITYNAME_EN": "Al Nubyaa Al Gharbya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15282",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al Hareqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15283",
    "CITYNAME_AR": "الحنايه",
    "CITYNAME_EN": "Al Hunayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15284",
    "CITYNAME_AR": "السمره",
    "CITYNAME_EN": "As-Samrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15285",
    "CITYNAME_AR": "الحقو الآعلى",
    "CITYNAME_EN": "Al Haqu Al Aala",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15286",
    "CITYNAME_AR": "القنبله",
    "CITYNAME_EN": "Al Qunbelah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15287",
    "CITYNAME_AR": "مغيلله",
    "CITYNAME_EN": "Mughaylalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15288",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al Muaqar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15289",
    "CITYNAME_AR": "العشوه",
    "CITYNAME_EN": "Al Oshwa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15290",
    "CITYNAME_AR": "النبيعه الشرقية",
    "CITYNAME_EN": "Al Nubyaa Ash-Sharqiyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15291",
    "CITYNAME_AR": "خالف الصيابه",
    "CITYNAME_EN": "Khalef As-Sayabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15292",
    "CITYNAME_AR": "معرمه",
    "CITYNAME_EN": "Maaramah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15293",
    "CITYNAME_AR": "ذوات الرجلين",
    "CITYNAME_EN": "Thawat Ar-Rejelayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15294",
    "CITYNAME_AR": "المزحيتر",
    "CITYNAME_EN": "Al Mazheter",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15295",
    "CITYNAME_AR": "الشاره",
    "CITYNAME_EN": "Ash-Shara",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15296",
    "CITYNAME_AR": "الهيف",
    "CITYNAME_EN": "Al Hayf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15297",
    "CITYNAME_AR": "الشرقي",
    "CITYNAME_EN": "Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15298",
    "CITYNAME_AR": "القبيل",
    "CITYNAME_EN": "Al Qabeel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15299",
    "CITYNAME_AR": "الرد",
    "CITYNAME_EN": "Ar-Rad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15300",
    "CITYNAME_AR": "الغيدانيه",
    "CITYNAME_EN": "Alghaidaniah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15301",
    "CITYNAME_AR": "ابو الرديف",
    "CITYNAME_EN": "Abo Ar-Radeef",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15302",
    "CITYNAME_AR": "الغطيان",
    "CITYNAME_EN": "Al Ghatyan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15303",
    "CITYNAME_AR": "ردحة حوتان",
    "CITYNAME_EN": "Radhet Hawtan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15304",
    "CITYNAME_AR": "الحنبه",
    "CITYNAME_EN": "Al Hanabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15305",
    "CITYNAME_AR": "قائم المعطن",
    "CITYNAME_EN": "Qaem Al Muatan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15306",
    "CITYNAME_AR": "راعيه البشامه",
    "CITYNAME_EN": "Raeyet Al Beshamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15307",
    "CITYNAME_AR": "خالفه البرق",
    "CITYNAME_EN": "Khalefet Al Barq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15308",
    "CITYNAME_AR": "الشريفيات",
    "CITYNAME_EN": "Ash-Shurayfat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15309",
    "CITYNAME_AR": "الدحر",
    "CITYNAME_EN": "Ad-Dahar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15310",
    "CITYNAME_AR": "القطوعه",
    "CITYNAME_EN": "Al Qatawah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15311",
    "CITYNAME_AR": "المخيل",
    "CITYNAME_EN": "Al Mukhayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15312",
    "CITYNAME_AR": "الكريدحى",
    "CITYNAME_EN": "Al Keredhi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15313",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Al Muaqar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15314",
    "CITYNAME_AR": "داغر الفرشة",
    "CITYNAME_EN": "Dagher Al Farshah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15315",
    "CITYNAME_AR": "الدقيقه",
    "CITYNAME_EN": "Ad-Daqiyqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15316",
    "CITYNAME_AR": "المجرف",
    "CITYNAME_EN": "Al Magraf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15317",
    "CITYNAME_AR": "خالف القيسي",
    "CITYNAME_EN": "Khalef Al Qaysi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15318",
    "CITYNAME_AR": "القراهبة",
    "CITYNAME_EN": "Al Quraheba",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15319",
    "CITYNAME_AR": "قله العافيه",
    "CITYNAME_EN": "Qulah Al Afyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15320",
    "CITYNAME_AR": "المحطابي",
    "CITYNAME_EN": "Al Mehtabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15321",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Al Sawda",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15322",
    "CITYNAME_AR": "سوق الماء",
    "CITYNAME_EN": "Souq Al Mae",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15323",
    "CITYNAME_AR": "الحنواء",
    "CITYNAME_EN": "Al Henwaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15324",
    "CITYNAME_AR": "الدمون",
    "CITYNAME_EN": "Ad-Damoun",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15325",
    "CITYNAME_AR": "القلع",
    "CITYNAME_EN": "Al Qalaa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15326",
    "CITYNAME_AR": "المكمن",
    "CITYNAME_EN": "Al Makman",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15327",
    "CITYNAME_AR": "الصيابه",
    "CITYNAME_EN": "Al Sayabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15328",
    "CITYNAME_AR": "المقصره",
    "CITYNAME_EN": "Al Muqsarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15329",
    "CITYNAME_AR": "راعى العدنه",
    "CITYNAME_EN": "Rai Al Adanah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15330",
    "CITYNAME_AR": "سعى الطويل",
    "CITYNAME_EN": "Sai Al Taweel",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15331",
    "CITYNAME_AR": "الغرزه",
    "CITYNAME_EN": "Al Ghurzah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15332",
    "CITYNAME_AR": "الجعيده",
    "CITYNAME_EN": "Al Guaydah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15333",
    "CITYNAME_AR": "سر حداد",
    "CITYNAME_EN": "Ser Haddad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15334",
    "CITYNAME_AR": "مسرار",
    "CITYNAME_EN": "Mesrar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15335",
    "CITYNAME_AR": "مخشوش  سعى الدش",
    "CITYNAME_EN": "Makhshoush Sai Al-Dush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15336",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15337",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15338",
    "CITYNAME_AR": "ذوات ريسه",
    "CITYNAME_EN": "Zawat Raysah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15339",
    "CITYNAME_AR": "وحره",
    "CITYNAME_EN": "Wahrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15340",
    "CITYNAME_AR": "ابو الهوان",
    "CITYNAME_EN": "Abo Al Hawan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15341",
    "CITYNAME_AR": "حيال",
    "CITYNAME_EN": "Hayal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15342",
    "CITYNAME_AR": "الحصار",
    "CITYNAME_EN": "Al Hesar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15343",
    "CITYNAME_AR": "شعب الخضوره",
    "CITYNAME_EN": "Shuab Al Khadurah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15344",
    "CITYNAME_AR": "ثاهر القوبع",
    "CITYNAME_EN": "Thaher Al Quwei",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15345",
    "CITYNAME_AR": "المحجاه",
    "CITYNAME_EN": "Al Muhjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15346",
    "CITYNAME_AR": "كرسي الداير",
    "CITYNAME_EN": "Kursi Al Dayer",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15347",
    "CITYNAME_AR": "المغيرات",
    "CITYNAME_EN": "Al Mughayrat",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15348",
    "CITYNAME_AR": "الخطوه",
    "CITYNAME_EN": "Al Khtwah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15349",
    "CITYNAME_AR": "سعي الرزم",
    "CITYNAME_EN": "Sai Al Razm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15350",
    "CITYNAME_AR": "عوجبه",
    "CITYNAME_EN": "Awjabah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15351",
    "CITYNAME_AR": "القائم",
    "CITYNAME_EN": "Al Qaem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15352",
    "CITYNAME_AR": "الخباعيه",
    "CITYNAME_EN": "Al Khabayah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15353",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15354",
    "CITYNAME_AR": "القعفل",
    "CITYNAME_EN": "Al Qaafal",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15355",
    "CITYNAME_AR": "الوعره",
    "CITYNAME_EN": "Al Waarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15356",
    "CITYNAME_AR": "طلان",
    "CITYNAME_EN": "Talan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15357",
    "CITYNAME_AR": "قحقح",
    "CITYNAME_EN": "Qahqah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15358",
    "CITYNAME_AR": "لحج جرول",
    "CITYNAME_EN": "Lahj Garrul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15359",
    "CITYNAME_AR": "القهله",
    "CITYNAME_EN": "Al Qahlah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15360",
    "CITYNAME_AR": "بشول",
    "CITYNAME_EN": "Bashul",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15361",
    "CITYNAME_AR": "الشطيفي",
    "CITYNAME_EN": "Ash-Shutayfi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15362",
    "CITYNAME_AR": "الجوة",
    "CITYNAME_EN": "Al Jawa",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15363",
    "CITYNAME_AR": "لحج سياله",
    "CITYNAME_EN": "Lahj Sayyalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15364",
    "CITYNAME_AR": "العركى",
    "CITYNAME_EN": "Al Arki",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15365",
    "CITYNAME_AR": "المعقد",
    "CITYNAME_EN": "Al Muaqad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15366",
    "CITYNAME_AR": "القرعه",
    "CITYNAME_EN": "Al Quraah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15367",
    "CITYNAME_AR": "الحجفه",
    "CITYNAME_EN": "Al Hajafah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15368",
    "CITYNAME_AR": "الشطين",
    "CITYNAME_EN": "Ash-Shatayn",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15369",
    "CITYNAME_AR": "الجربا",
    "CITYNAME_EN": "Al Garya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15370",
    "CITYNAME_AR": "معناق",
    "CITYNAME_EN": "Menaq",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15371",
    "CITYNAME_AR": "لحج الحمره",
    "CITYNAME_EN": "Lahj Al Humrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15372",
    "CITYNAME_AR": "معوش",
    "CITYNAME_EN": "Maush",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15373",
    "CITYNAME_AR": "المقادى",
    "CITYNAME_EN": "Al Maqadi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15374",
    "CITYNAME_AR": "مخوج",
    "CITYNAME_EN": "Mukhawaj",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15375",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshosh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15376",
    "CITYNAME_AR": "المعصب",
    "CITYNAME_EN": "Al Muasab",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15377",
    "CITYNAME_AR": "الضاحيه",
    "CITYNAME_EN": "Ad-Dahyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15378",
    "CITYNAME_AR": "صروعة الجنوبيه",
    "CITYNAME_EN": "Sarwaat Al Janubyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15379",
    "CITYNAME_AR": "الشعف",
    "CITYNAME_EN": "Ash Sha'f",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15380",
    "CITYNAME_AR": "الجميمه",
    "CITYNAME_EN": "Al Jumaymah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15381",
    "CITYNAME_AR": "الموشم",
    "CITYNAME_EN": "Al Maushem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15382",
    "CITYNAME_AR": "الرفيح",
    "CITYNAME_EN": "Al Rafeh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15383",
    "CITYNAME_AR": "الذنوب",
    "CITYNAME_EN": "Al Zanub",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15384",
    "CITYNAME_AR": "الشرجة",
    "CITYNAME_EN": "Al Sharjah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15385",
    "CITYNAME_AR": "المـهيد",
    "CITYNAME_EN": "Al Muhayad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15386",
    "CITYNAME_AR": "السود",
    "CITYNAME_EN": "As-Sud",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15387",
    "CITYNAME_AR": "صروعة الشمالية",
    "CITYNAME_EN": "Sarwaat Ash-Shamalyah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15388",
    "CITYNAME_AR": "سر الكعبي",
    "CITYNAME_EN": "Ser Al Kaabi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15389",
    "CITYNAME_AR": "الجحيرة",
    "CITYNAME_EN": "Al Hujayrah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15390",
    "CITYNAME_AR": "الخوش",
    "CITYNAME_EN": "Al Khawsh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15391",
    "CITYNAME_AR": "الشرقي",
    "CITYNAME_EN": "Ash-Sharqi",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15392",
    "CITYNAME_AR": "دعبله",
    "CITYNAME_EN": "Daablah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15393",
    "CITYNAME_AR": "نيد القرى",
    "CITYNAME_EN": "Ned Al Qura",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15394",
    "CITYNAME_AR": "المسائل",
    "CITYNAME_EN": "Al Masael",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15395",
    "CITYNAME_AR": "خالف المرار والسياله",
    "CITYNAME_EN": "Khalef Al Marar w As-Sayalah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15396",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Alsuwduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15397",
    "CITYNAME_AR": "المغسل",
    "CITYNAME_EN": "Almaghasil",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15398",
    "CITYNAME_AR": "البحار",
    "CITYNAME_EN": "Albahhar",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15399",
    "CITYNAME_AR": "ابو النخل",
    "CITYNAME_EN": "'Abu Alnakhl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15400",
    "CITYNAME_AR": "ابو العصمه",
    "CITYNAME_EN": "'Abu Aleasmuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15401",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Alrukabuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15402",
    "CITYNAME_AR": "ام المشرب",
    "CITYNAME_EN": "Umm Almushrib",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15403",
    "CITYNAME_AR": "المداريه",
    "CITYNAME_EN": "Almadarih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15404",
    "CITYNAME_AR": "ابوالكزم",
    "CITYNAME_EN": "Abwalkzm",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15405",
    "CITYNAME_AR": "ام القفع",
    "CITYNAME_EN": "Umm Alqafe",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15406",
    "CITYNAME_AR": "المدرجه",
    "CITYNAME_EN": "Almudarijuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15407",
    "CITYNAME_AR": "ابو العكثل",
    "CITYNAME_EN": "'Abu Aleakthl",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15408",
    "CITYNAME_AR": "حفر الرخيص",
    "CITYNAME_EN": "Alhafr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15409",
    "CITYNAME_AR": "العليم",
    "CITYNAME_EN": "Alealim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15410",
    "CITYNAME_AR": "الابرق",
    "CITYNAME_EN": "Alabriq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15411",
    "CITYNAME_AR": "هجرة السرة",
    "CITYNAME_EN": "Murd Biir Alradean",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15412",
    "CITYNAME_AR": "الرديفه",
    "CITYNAME_EN": "Alradifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15413",
    "CITYNAME_AR": "جبه",
    "CITYNAME_EN": "Jabah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15414",
    "CITYNAME_AR": "بير السطيحيه",
    "CITYNAME_EN": "Bayr Alsatihayih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15415",
    "CITYNAME_AR": "بئر البعجا",
    "CITYNAME_EN": "Biir Albieja",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15416",
    "CITYNAME_AR": "الروض",
    "CITYNAME_EN": "Alruwd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15417",
    "CITYNAME_AR": "المعيزيله",
    "CITYNAME_EN": "Almueiziluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15418",
    "CITYNAME_AR": "الصلعاء",
    "CITYNAME_EN": "Alsulea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15419",
    "CITYNAME_AR": "مزرعة ماطر ابراهيم المرعيد بالبعجاء",
    "CITYNAME_EN": "  Matir 'Iibrahim Almareid Farm Bialbeja' ",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15420",
    "CITYNAME_AR": "قنا",
    "CITYNAME_EN": "Quna",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15421",
    "CITYNAME_AR": "ام القلبان",
    "CITYNAME_EN": "Umm Alqulban",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15422",
    "CITYNAME_AR": "الغزاله",
    "CITYNAME_EN": "Alghizaluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15423",
    "CITYNAME_AR": "مكه المكرمه",
    "CITYNAME_EN": "Makah Almukaramuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15424",
    "CITYNAME_AR": "نباته",
    "CITYNAME_EN": "Nabatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15425",
    "CITYNAME_AR": "الهاويه الكبرى",
    "CITYNAME_EN": "Alhawiuh Alkubraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15426",
    "CITYNAME_AR": "الحرمان (الوادي الاخضر)",
    "CITYNAME_EN": "Alhirman (Alwadi Alakhdr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15427",
    "CITYNAME_AR": "المحمديه",
    "CITYNAME_EN": "Almuhamadayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15428",
    "CITYNAME_AR": "ملكان",
    "CITYNAME_EN": "Malkan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15429",
    "CITYNAME_AR": "الرهجانيه",
    "CITYNAME_EN": "Alrahjanih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15430",
    "CITYNAME_AR": "الصرف",
    "CITYNAME_EN": "Alsirf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15431",
    "CITYNAME_AR": "حميمه",
    "CITYNAME_EN": "Hamimah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15432",
    "CITYNAME_AR": "قرى وادي اسلع",
    "CITYNAME_EN": "Quraa Wadi Aslae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15433",
    "CITYNAME_AR": "ام حبيتر",
    "CITYNAME_EN": "Umm Habitar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15434",
    "CITYNAME_AR": "ضرعاء",
    "CITYNAME_EN": "Darea'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15435",
    "CITYNAME_AR": "عرعر ورده نعمان",
    "CITYNAME_EN": "Earear Waradah Naeman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15436",
    "CITYNAME_AR": "ضها (عرعر)",
    "CITYNAME_EN": "Daha (Erier)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15437",
    "CITYNAME_AR": "خشاع الجوابره",
    "CITYNAME_EN": "Khashae Aljawabirih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15438",
    "CITYNAME_AR": "عريده",
    "CITYNAME_EN": "Earidah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15439",
    "CITYNAME_AR": "كبده",
    "CITYNAME_EN": "Kabdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15440",
    "CITYNAME_AR": "ام حديد",
    "CITYNAME_EN": "Umm Hadid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15441",
    "CITYNAME_AR": "ال عليه",
    "CITYNAME_EN": "Al Ealayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15442",
    "CITYNAME_AR": "حجيله والحميمه",
    "CITYNAME_EN": "Hajilah Walhamimih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15443",
    "CITYNAME_AR": "الشق",
    "CITYNAME_EN": "Alshiqu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15444",
    "CITYNAME_AR": "شعب صار",
    "CITYNAME_EN": "Shaeb Sar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15445",
    "CITYNAME_AR": "المراوه",
    "CITYNAME_EN": "Almarawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15446",
    "CITYNAME_AR": "ضلعه",
    "CITYNAME_EN": "Daleah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15447",
    "CITYNAME_AR": "الكليبيه",
    "CITYNAME_EN": "Alkalibiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15448",
    "CITYNAME_AR": "العلوين",
    "CITYNAME_EN": "Aleilwin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15449",
    "CITYNAME_AR": "رين",
    "CITYNAME_EN": "Rin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15450",
    "CITYNAME_AR": "يعرج بلاد الحساسنه",
    "CITYNAME_EN": "Yaeruj Bilad Alhasasinih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15451",
    "CITYNAME_AR": "ابوحجاره",
    "CITYNAME_EN": "Abwahjaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15452",
    "CITYNAME_AR": "ال حسن",
    "CITYNAME_EN": "Al Hasan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15453",
    "CITYNAME_AR": "الجلاجله",
    "CITYNAME_EN": "Aljalajiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15454",
    "CITYNAME_AR": "ريفه ال مناع",
    "CITYNAME_EN": "Rifuh Al Manaae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15455",
    "CITYNAME_AR": "البطنه",
    "CITYNAME_EN": "Albatnuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15456",
    "CITYNAME_AR": "اللصيفه",
    "CITYNAME_EN": "Allasifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15457",
    "CITYNAME_AR": "شداد ومحطة الرمال",
    "CITYNAME_EN": "Shidad and Alramal Station",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15458",
    "CITYNAME_AR": "العرجاء",
    "CITYNAME_EN": "Aleurja'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15459",
    "CITYNAME_AR": "وادى الشراء",
    "CITYNAME_EN": "Wa'Adaa Alshira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15460",
    "CITYNAME_AR": "ريع انف",
    "CITYNAME_EN": "Raye Anf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15461",
    "CITYNAME_AR": "رين",
    "CITYNAME_EN": "Rin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15462",
    "CITYNAME_AR": "قرن نعمان",
    "CITYNAME_EN": "Qarn Naeman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15463",
    "CITYNAME_AR": "المجاريش",
    "CITYNAME_EN": "Almajarish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15464",
    "CITYNAME_AR": "ريع وصيق وفقاسة نعمان",
    "CITYNAME_EN": "Raye Wasiq Wafaqasat Naeman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15465",
    "CITYNAME_AR": "برم",
    "CITYNAME_EN": "Birm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15466",
    "CITYNAME_AR": "النقره",
    "CITYNAME_EN": "Alnaqruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15467",
    "CITYNAME_AR": "القين",
    "CITYNAME_EN": "Alqayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15468",
    "CITYNAME_AR": "الخيام",
    "CITYNAME_EN": "Alkhiam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15469",
    "CITYNAME_AR": "السمر",
    "CITYNAME_EN": "Alsamar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15470",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15471",
    "CITYNAME_AR": "الشقراء",
    "CITYNAME_EN": "Alshuqara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15472",
    "CITYNAME_AR": "ضها رهجان",
    "CITYNAME_EN": "Daha Rahjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15473",
    "CITYNAME_AR": "عابد القرشي",
    "CITYNAME_EN": "Eabid Alqarshi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15474",
    "CITYNAME_AR": "الجلالي",
    "CITYNAME_EN": "Aljilaliu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15475",
    "CITYNAME_AR": "صيف",
    "CITYNAME_EN": "Sayf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15476",
    "CITYNAME_AR": "الغبان",
    "CITYNAME_EN": "Alghuban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15477",
    "CITYNAME_AR": "مدايا",
    "CITYNAME_EN": "Madaya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15478",
    "CITYNAME_AR": "العميره",
    "CITYNAME_EN": "Aleamiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15479",
    "CITYNAME_AR": "المزر",
    "CITYNAME_EN": "Almizr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15480",
    "CITYNAME_AR": "المحرق",
    "CITYNAME_EN": "Almuharaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15481",
    "CITYNAME_AR": "الأميلج",
    "CITYNAME_EN": "Al'Amilij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15482",
    "CITYNAME_AR": "بانة (الحديدة)",
    "CITYNAME_EN": "Bana (Alhadidata)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15483",
    "CITYNAME_AR": "النافعيه",
    "CITYNAME_EN": "Alnaafieih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15484",
    "CITYNAME_AR": "حي الرياض",
    "CITYNAME_EN": "Hayi Alriyad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15485",
    "CITYNAME_AR": "عمق",
    "CITYNAME_EN": "Eumq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15486",
    "CITYNAME_AR": "بئر بن لادن الفله",
    "CITYNAME_EN": "Bayir Bin Ladina Alfalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15487",
    "CITYNAME_AR": "الدريحيه",
    "CITYNAME_EN": "Aldarihiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15488",
    "CITYNAME_AR": "الحامضه ( وادي لبن)",
    "CITYNAME_EN": "Alhamiduh ( Wadi Labna)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15489",
    "CITYNAME_AR": "نباته",
    "CITYNAME_EN": "Nabatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15490",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Alhusan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15491",
    "CITYNAME_AR": "ذوى هزاع",
    "CITYNAME_EN": "Dhawaa Hizae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15492",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Aldhanabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15493",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Alhuzm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15494",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15495",
    "CITYNAME_AR": "اضفر",
    "CITYNAME_EN": "Adfir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15496",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15497",
    "CITYNAME_AR": "اللويه والمخاضه",
    "CITYNAME_EN": "Allawiuh Walmakhaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15498",
    "CITYNAME_AR": "ذوى مهنا",
    "CITYNAME_EN": "Dhawaa Mahna",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15499",
    "CITYNAME_AR": "ذوى عبدالكريم",
    "CITYNAME_EN": "Dhuana Eabdalkarim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15500",
    "CITYNAME_AR": "القواسم ويشمل (المساعيد)",
    "CITYNAME_EN": "Alqawasim Wayashmal (Almsaeid)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15501",
    "CITYNAME_AR": "ذوى باز",
    "CITYNAME_EN": "Dhawaa Baz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15502",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Alghabib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15503",
    "CITYNAME_AR": "ذوى محيا",
    "CITYNAME_EN": "Dhuaa Mahaya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15504",
    "CITYNAME_AR": "حجر",
    "CITYNAME_EN": "Hijr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15505",
    "CITYNAME_AR": "عين المضيق",
    "CITYNAME_EN": "Eayan Almudiq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15506",
    "CITYNAME_AR": "القطار",
    "CITYNAME_EN": "Alqitar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15507",
    "CITYNAME_AR": "الشقير",
    "CITYNAME_EN": "Alshaqir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15508",
    "CITYNAME_AR": "مزرعه الاجعال",
    "CITYNAME_EN": "Farm of Alajeal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15509",
    "CITYNAME_AR": "الأيسر (نزلة منور)",
    "CITYNAME_EN": "Al'Aysar (Nzilat Munawr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15510",
    "CITYNAME_AR": "سمر (الخليفاء)",
    "CITYNAME_EN": "Samar (Alkhlyfa')",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15511",
    "CITYNAME_AR": "القرضه",
    "CITYNAME_EN": "Alqarduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15512",
    "CITYNAME_AR": "الوسيعه",
    "CITYNAME_EN": "Alwasieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15513",
    "CITYNAME_AR": "جراب",
    "CITYNAME_EN": "Jarab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15514",
    "CITYNAME_AR": "بداله الشرقيه",
    "CITYNAME_EN": "Bidalih Alsharaqiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15515",
    "CITYNAME_AR": "عين الجديده (الغامديه)",
    "CITYNAME_EN": "Eayan Aljadiduh (Alghamidiuh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15516",
    "CITYNAME_AR": "نزله الاوجر",
    "CITYNAME_EN": "Nazalah Alawjr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15517",
    "CITYNAME_AR": "غلوه",
    "CITYNAME_EN": "Ghuluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15518",
    "CITYNAME_AR": "الزيمه",
    "CITYNAME_EN": "Alziymuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15519",
    "CITYNAME_AR": "العوص",
    "CITYNAME_EN": "Aleaws",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15520",
    "CITYNAME_AR": "الرتجه",
    "CITYNAME_EN": "Alratajuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15521",
    "CITYNAME_AR": "الخرج",
    "CITYNAME_EN": "Alkharaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15522",
    "CITYNAME_AR": "مزارع وادى جدعان (تربان)",
    "CITYNAME_EN": "Farm of Wa'Adaa Jidean (Trban)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15523",
    "CITYNAME_AR": "مزارع الحمي",
    "CITYNAME_EN": "Farm of Alhumiyi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15524",
    "CITYNAME_AR": "الشقراء",
    "CITYNAME_EN": "Alshuqara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15525",
    "CITYNAME_AR": "قابل عيفان",
    "CITYNAME_EN": "Qabil Eayfan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15526",
    "CITYNAME_AR": "الشعب الاحمر",
    "CITYNAME_EN": "Alshaeb Al'Ahmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15527",
    "CITYNAME_AR": "الفقيما",
    "CITYNAME_EN": "Alfaqima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15528",
    "CITYNAME_AR": "الضهياء",
    "CITYNAME_EN": "Aldahbia'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15529",
    "CITYNAME_AR": "الوقبه",
    "CITYNAME_EN": "Alwaqbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15530",
    "CITYNAME_AR": "المراخ (1-2-3)",
    "CITYNAME_EN": "Almurakh (1-2-3)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15531",
    "CITYNAME_AR": "العان (قسم العان)",
    "CITYNAME_EN": "Alean (Qsim Aleana)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15532",
    "CITYNAME_AR": "الكفو",
    "CITYNAME_EN": "Alkufuw",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15533",
    "CITYNAME_AR": "عشر",
    "CITYNAME_EN": "Eshr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15534",
    "CITYNAME_AR": "هلال",
    "CITYNAME_EN": "Hilal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15535",
    "CITYNAME_AR": "قردد",
    "CITYNAME_EN": "Quradad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15536",
    "CITYNAME_AR": "الجبله",
    "CITYNAME_EN": "Aljabalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15537",
    "CITYNAME_AR": "عقل",
    "CITYNAME_EN": "Eaql",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15538",
    "CITYNAME_AR": "ساله وتشمل (سوله)",
    "CITYNAME_EN": "Salah Watashmal (Swlha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15539",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dabae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15540",
    "CITYNAME_AR": "الاميلح الصدر",
    "CITYNAME_EN": "Alamylh Alsadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15541",
    "CITYNAME_AR": "الحفايل",
    "CITYNAME_EN": "Alhafayil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15542",
    "CITYNAME_AR": "رقيه",
    "CITYNAME_EN": "Raqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15543",
    "CITYNAME_AR": "بئر الباثه (الجموح)",
    "CITYNAME_EN": "Biir Albathuh (Aljumuh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15544",
    "CITYNAME_AR": "وثال",
    "CITYNAME_EN": "Wathal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15545",
    "CITYNAME_AR": "طليحه ومزرعة الهنيه",
    "CITYNAME_EN": "Talihuh and Alhunih Farms",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15546",
    "CITYNAME_AR": "بعايس والجراء",
    "CITYNAME_EN": "Baeayis Waljara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15547",
    "CITYNAME_AR": "علية",
    "CITYNAME_EN": "Ealia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15548",
    "CITYNAME_AR": "خارم",
    "CITYNAME_EN": "Kharim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15549",
    "CITYNAME_AR": "مكه الرقه والجميح وجليل",
    "CITYNAME_EN": "Makah Alraquh Waljamih Wajalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15550",
    "CITYNAME_AR": "المليحا وليان",
    "CITYNAME_EN": "Almaliha Wulayan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15551",
    "CITYNAME_AR": "وادى حراض",
    "CITYNAME_EN": "Wa'Adaa Hirad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15552",
    "CITYNAME_AR": "مسكر",
    "CITYNAME_EN": "Maskar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15553",
    "CITYNAME_AR": "ابوطمر",
    "CITYNAME_EN": "Abwtmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15554",
    "CITYNAME_AR": "جعرانه",
    "CITYNAME_EN": "Jueranah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15555",
    "CITYNAME_AR": "نبع",
    "CITYNAME_EN": "Nbe",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15556",
    "CITYNAME_AR": "الشرائع العليا",
    "CITYNAME_EN": "Alsharayie Aleulya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15557",
    "CITYNAME_AR": "شقاب",
    "CITYNAME_EN": "Shaqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15558",
    "CITYNAME_AR": "قريه سرحان اللحياني",
    "CITYNAME_EN": "Qarih Sarhan Allahyanii",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15559",
    "CITYNAME_AR": "عودة المطرفي",
    "CITYNAME_EN": "Eawdat Almutrafi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15560",
    "CITYNAME_AR": "الرشيدي (الشرفاء)",
    "CITYNAME_EN": "Alrashidiu (Alshrfa')",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15561",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Alsadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15562",
    "CITYNAME_AR": "الفريشه",
    "CITYNAME_EN": "Alqarishuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15563",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15564",
    "CITYNAME_AR": "قرية عبدالله اللحيانى",
    "CITYNAME_EN": "Abdullah Allahyanaa Vellage",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15565",
    "CITYNAME_AR": "بئر عبدالله",
    "CITYNAME_EN": "Biir Abdullah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15566",
    "CITYNAME_AR": "الصهوة (البعير)",
    "CITYNAME_EN": "Alsahwa (Albaeayr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15567",
    "CITYNAME_AR": "السنوسية (الروضه)",
    "CITYNAME_EN": "Alsanusia (Alrudh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15568",
    "CITYNAME_AR": "الفيضه",
    "CITYNAME_EN": "Alfayduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15569",
    "CITYNAME_AR": "الملح",
    "CITYNAME_EN": "Almulihu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15570",
    "CITYNAME_AR": "القشبه",
    "CITYNAME_EN": "Alqashbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15571",
    "CITYNAME_AR": "البجيدي (القريه)",
    "CITYNAME_EN": "Albujaydiu (Alqariha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15572",
    "CITYNAME_AR": "شرائع النخيل الجنوبي",
    "CITYNAME_EN": "Sharayie Alnakhil Aljanubii",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15573",
    "CITYNAME_AR": "الصعيد",
    "CITYNAME_EN": "Alsaeid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15574",
    "CITYNAME_AR": "المجاز",
    "CITYNAME_EN": "Almajaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15575",
    "CITYNAME_AR": "القشبه الجنوبيه",
    "CITYNAME_EN": "Alqashbuh Aljanubiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15576",
    "CITYNAME_AR": "المغمس",
    "CITYNAME_EN": "Almaghmis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15577",
    "CITYNAME_AR": "كبكب",
    "CITYNAME_EN": "Kabakab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15578",
    "CITYNAME_AR": "الشدقاء",
    "CITYNAME_EN": "Alshudaqa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15579",
    "CITYNAME_AR": "نجل",
    "CITYNAME_EN": "Najl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15580",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Almurabae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15581",
    "CITYNAME_AR": "القوبعية (القرين)",
    "CITYNAME_EN": "Alqawbaeia (Alqarina)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15582",
    "CITYNAME_AR": "حاذه",
    "CITYNAME_EN": "Hadhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15583",
    "CITYNAME_AR": "الحفير(الحفيره)",
    "CITYNAME_EN": "Alhfira(Alhfayrh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15584",
    "CITYNAME_AR": "حراض بنى عمير",
    "CITYNAME_EN": "Haraad Banaa Eumayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15585",
    "CITYNAME_AR": "السلام(وجليل)",
    "CITYNAME_EN": "Alsalam(Wajilil)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15586",
    "CITYNAME_AR": "المعمر (ام خيلأن)",
    "CITYNAME_EN": "Almueamar (Ama Khayla'An)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15587",
    "CITYNAME_AR": "الشاطىء",
    "CITYNAME_EN": "Alshata'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15588",
    "CITYNAME_AR": "الشجوه",
    "CITYNAME_EN": "Alshujuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15589",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Alnabaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15590",
    "CITYNAME_AR": "ام الملحه (ام الملح)",
    "CITYNAME_EN": "Umm Almalahuh (Ami Almalah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15591",
    "CITYNAME_AR": "الدابره",
    "CITYNAME_EN": "Aldaabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15592",
    "CITYNAME_AR": "الراضه الشماليه",
    "CITYNAME_EN": "Alradih Alshamaliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15593",
    "CITYNAME_AR": "ام سريحه",
    "CITYNAME_EN": "Umm Sarihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15594",
    "CITYNAME_AR": "الوشن",
    "CITYNAME_EN": "Alwshn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15595",
    "CITYNAME_AR": "المشديه",
    "CITYNAME_EN": "Almashadiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15596",
    "CITYNAME_AR": "المطحله (النخير)",
    "CITYNAME_EN": "Almatahaluh (Alnakhiar)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15597",
    "CITYNAME_AR": "بداله",
    "CITYNAME_EN": "Bidalih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15598",
    "CITYNAME_AR": "ام عضام",
    "CITYNAME_EN": "Umm Eidam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15599",
    "CITYNAME_AR": "ام الضيمران (سفلي - عليا)",
    "CITYNAME_EN": "Umm Aldiamran (Sfli - Eulya)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15600",
    "CITYNAME_AR": "الشميميه (السميمه)",
    "CITYNAME_EN": "Alshamimih (Alsmimh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15601",
    "CITYNAME_AR": "مزارع البريقاء",
    "CITYNAME_EN": "Farm of Albariqa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15602",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Aleayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15603",
    "CITYNAME_AR": "نبهان",
    "CITYNAME_EN": "Nabhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15604",
    "CITYNAME_AR": "مركز صيانة العين",
    "CITYNAME_EN": "Center of Sianat Aleayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15605",
    "CITYNAME_AR": "الجخافيه",
    "CITYNAME_EN": "Aljakhafih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15606",
    "CITYNAME_AR": "ام ضباع",
    "CITYNAME_EN": "Umm Dubae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15607",
    "CITYNAME_AR": "اللصايب",
    "CITYNAME_EN": "Allasayib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15608",
    "CITYNAME_AR": "القليب",
    "CITYNAME_EN": "Alqalib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15609",
    "CITYNAME_AR": "العرق",
    "CITYNAME_EN": "Alearaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15610",
    "CITYNAME_AR": "وادى وعاجل",
    "CITYNAME_EN": "Wa'Adaa Waeajil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15611",
    "CITYNAME_AR": "وادى الصفيه",
    "CITYNAME_EN": "Wa'Adaa Alsafiah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15612",
    "CITYNAME_AR": "ذعر",
    "CITYNAME_EN": "Dhaer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15613",
    "CITYNAME_AR": "هجرة المجانين",
    "CITYNAME_EN": "Hijrat Almajanin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15614",
    "CITYNAME_AR": "الرمضه",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15615",
    "CITYNAME_AR": "الجلاب",
    "CITYNAME_EN": "Aljalab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15616",
    "CITYNAME_AR": "وادي نبع",
    "CITYNAME_EN": "Wadi Nabae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15617",
    "CITYNAME_AR": "وادى دسم",
    "CITYNAME_EN": "Wa'Adaa Dasm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15618",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15619",
    "CITYNAME_AR": "المختبى",
    "CITYNAME_EN": "Al Mukhtabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15620",
    "CITYNAME_AR": "مزرعه محبوبه",
    "CITYNAME_EN": "Mahbawyah farm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15621",
    "CITYNAME_AR": "الزلال",
    "CITYNAME_EN": "Az-Zulal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15622",
    "CITYNAME_AR": "الخضراء",
    "CITYNAME_EN": "Al Khadraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15623",
    "CITYNAME_AR": "الاطواء",
    "CITYNAME_EN": "Al Atwaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15624",
    "CITYNAME_AR": "الخرقاء",
    "CITYNAME_EN": "Al Kharqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15625",
    "CITYNAME_AR": "الشعيبه",
    "CITYNAME_EN": "Ash Shuaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15626",
    "CITYNAME_AR": "الحام",
    "CITYNAME_EN": "Al Ham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15627",
    "CITYNAME_AR": "ام الزله",
    "CITYNAME_EN": "Om Az-Zallah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15628",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Ash-Sharfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15629",
    "CITYNAME_AR": "دفاق",
    "CITYNAME_EN": "Dafaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15630",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al Hareqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15631",
    "CITYNAME_AR": "النفلة",
    "CITYNAME_EN": "An-Nufalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15632",
    "CITYNAME_AR": "الصراف",
    "CITYNAME_EN": "As-Sarraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15633",
    "CITYNAME_AR": "حجلا",
    "CITYNAME_EN": "Hagla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15634",
    "CITYNAME_AR": "صيف",
    "CITYNAME_EN": "Saif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15635",
    "CITYNAME_AR": "ضلامه",
    "CITYNAME_EN": "Dalamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15636",
    "CITYNAME_AR": "مرس",
    "CITYNAME_EN": "Mars",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15637",
    "CITYNAME_AR": "هجيج",
    "CITYNAME_EN": "Hajej",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15638",
    "CITYNAME_AR": "المسـغدر",
    "CITYNAME_EN": "Al Musaghdar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15639",
    "CITYNAME_AR": "وادي الحياء",
    "CITYNAME_EN": "Wadi Al Hayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15640",
    "CITYNAME_AR": "فم الحياء",
    "CITYNAME_EN": "Fam Al Hayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15641",
    "CITYNAME_AR": "شعيب الحياء",
    "CITYNAME_EN": "Shuaib Al Hayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15642",
    "CITYNAME_AR": "وادى ادام (الملحاء)",
    "CITYNAME_EN": "Wadi Adam (Al Malhaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15643",
    "CITYNAME_AR": "وشل",
    "CITYNAME_EN": "Washal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15644",
    "CITYNAME_AR": "الخصر",
    "CITYNAME_EN": "Al Khasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15645",
    "CITYNAME_AR": "الكره",
    "CITYNAME_EN": "Al Kurh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15646",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15647",
    "CITYNAME_AR": "نجل",
    "CITYNAME_EN": "Najl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15648",
    "CITYNAME_AR": "وادى الوكف",
    "CITYNAME_EN": "Wadi Al Wakaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15649",
    "CITYNAME_AR": "الثرياء (الضعن)",
    "CITYNAME_EN": "Ath-Thurayya (Ad-Daghan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15650",
    "CITYNAME_AR": "العصار",
    "CITYNAME_EN": "Al Osar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15651",
    "CITYNAME_AR": "ضبية",
    "CITYNAME_EN": "Dabyyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15652",
    "CITYNAME_AR": "وادى الحليل",
    "CITYNAME_EN": "Wadi Al Hulayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15653",
    "CITYNAME_AR": "ابو لثب",
    "CITYNAME_EN": "Abo Lathab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15654",
    "CITYNAME_AR": "العقيره",
    "CITYNAME_EN": "Al Oqayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15655",
    "CITYNAME_AR": "الضها",
    "CITYNAME_EN": "Ad-Daha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15656",
    "CITYNAME_AR": "العلق",
    "CITYNAME_EN": "Al Alaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15657",
    "CITYNAME_AR": "الاغوار (زقاق الفهد)",
    "CITYNAME_EN": "Al Aghwar (Zeqaq Al Fahd)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15658",
    "CITYNAME_AR": "الوكفه البيضاء",
    "CITYNAME_EN": "Al Waqfah Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15659",
    "CITYNAME_AR": "حويه تهامه",
    "CITYNAME_EN": "Hawyat Tuhamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15660",
    "CITYNAME_AR": "شعيب ليل",
    "CITYNAME_EN": "Shuaib Lail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15661",
    "CITYNAME_AR": "شعيب السيل",
    "CITYNAME_EN": "Shuaib As-Sayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15662",
    "CITYNAME_AR": "قفا الاعلى",
    "CITYNAME_EN": "Qafa Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15663",
    "CITYNAME_AR": "حبلان وحبلين",
    "CITYNAME_EN": "Hublan wa Hubailayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15664",
    "CITYNAME_AR": "نطع",
    "CITYNAME_EN": "Nataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15665",
    "CITYNAME_AR": "الكاحل",
    "CITYNAME_EN": "Al Kahel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15666",
    "CITYNAME_AR": "الدحلات",
    "CITYNAME_EN": "Ad-Dahlat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15667",
    "CITYNAME_AR": "الطويلات",
    "CITYNAME_EN": "At-Tuwailat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15668",
    "CITYNAME_AR": "دحيله الجنوبيه",
    "CITYNAME_EN": "Duhaylah Al Janubia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15669",
    "CITYNAME_AR": "عروان",
    "CITYNAME_EN": "Arwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15670",
    "CITYNAME_AR": "ريع نمار",
    "CITYNAME_EN": "Rei Namar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15671",
    "CITYNAME_AR": "المتيعه",
    "CITYNAME_EN": "Al Mutayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15672",
    "CITYNAME_AR": "مزرعة الشيخ معتوق",
    "CITYNAME_EN": "Sheikh Maatuq farm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15673",
    "CITYNAME_AR": "العصيعص",
    "CITYNAME_EN": "Al Osais",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15674",
    "CITYNAME_AR": "القيد",
    "CITYNAME_EN": "Al Qayd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15675",
    "CITYNAME_AR": "المرخه",
    "CITYNAME_EN": "Al Marakhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15676",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Al Baydaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15677",
    "CITYNAME_AR": "الجهوه",
    "CITYNAME_EN": "Al Jahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15678",
    "CITYNAME_AR": "السلعاء",
    "CITYNAME_EN": "As-Salaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15679",
    "CITYNAME_AR": "المنيفين",
    "CITYNAME_EN": "Al Munayfen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15680",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "As-Sahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15681",
    "CITYNAME_AR": "العبادله",
    "CITYNAME_EN": "Al Abadelah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15682",
    "CITYNAME_AR": "وادي الخانق",
    "CITYNAME_EN": "Wadi Al Khaneq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15683",
    "CITYNAME_AR": "غرب لبن",
    "CITYNAME_EN": "Gharb Laban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15684",
    "CITYNAME_AR": "أم الراكه",
    "CITYNAME_EN": "Umm Ar-Rakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15685",
    "CITYNAME_AR": "ام دوحه",
    "CITYNAME_EN": "Om Dawha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15686",
    "CITYNAME_AR": "المقرح",
    "CITYNAME_EN": "Al Muqreh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15687",
    "CITYNAME_AR": "جراب",
    "CITYNAME_EN": "Jerab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15688",
    "CITYNAME_AR": "وادي ملكان",
    "CITYNAME_EN": "Wadi Malkan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15689",
    "CITYNAME_AR": "المحضره",
    "CITYNAME_EN": "Al Muhderah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15690",
    "CITYNAME_AR": "الروينه",
    "CITYNAME_EN": "Ar-Ruwaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15691",
    "CITYNAME_AR": "المحاوي",
    "CITYNAME_EN": "Al Mahawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15692",
    "CITYNAME_AR": "قرية شعب مكا",
    "CITYNAME_EN": "Shuab Makkah village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15693",
    "CITYNAME_AR": "قرية الخيوط",
    "CITYNAME_EN": "Al Khuut village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15694",
    "CITYNAME_AR": "هجرة ال رده",
    "CITYNAME_EN": "Hijrah Al Raddah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15695",
    "CITYNAME_AR": "العشوه",
    "CITYNAME_EN": "Aleushuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15696",
    "CITYNAME_AR": "جعيره",
    "CITYNAME_EN": "Jaeirah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15697",
    "CITYNAME_AR": "المقوزه الطراشه",
    "CITYNAME_EN": "Almuquzuh Altarashuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15698",
    "CITYNAME_AR": "الشواجره",
    "CITYNAME_EN": "Alshawajiruh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15699",
    "CITYNAME_AR": "الفقهاء",
    "CITYNAME_EN": "Alfuqaha'",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15700",
    "CITYNAME_AR": "أساملة",
    "CITYNAME_EN": "'Asamila",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15701",
    "CITYNAME_AR": "الدغاسه",
    "CITYNAME_EN": "Aldighasuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15702",
    "CITYNAME_AR": "العجيبيه",
    "CITYNAME_EN": "Aleajibiuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15703",
    "CITYNAME_AR": "بخشه",
    "CITYNAME_EN": "Bkhashah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15704",
    "CITYNAME_AR": "محليه",
    "CITYNAME_EN": "Muhalih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15705",
    "CITYNAME_AR": "الخرادله",
    "CITYNAME_EN": "Alkharadiluh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15706",
    "CITYNAME_AR": "الغزوه",
    "CITYNAME_EN": "Alghuzuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15707",
    "CITYNAME_AR": "محطة المخلاف",
    "CITYNAME_EN": "Station of Al Mekhlaf",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15708",
    "CITYNAME_AR": "العرجين",
    "CITYNAME_EN": "Aleirajin",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15709",
    "CITYNAME_AR": "الحواية",
    "CITYNAME_EN": "Alhiwaya",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15710",
    "CITYNAME_AR": "العمارده",
    "CITYNAME_EN": "Aleamariduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15711",
    "CITYNAME_AR": "القصاده",
    "CITYNAME_EN": "Alqasaduh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15712",
    "CITYNAME_AR": "السروريه",
    "CITYNAME_EN": "Alsaruriuh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15713",
    "CITYNAME_AR": "النجابه و الجحاجح",
    "CITYNAME_EN": "Alnijabuh and Aljahajih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15714",
    "CITYNAME_AR": "الزيني",
    "CITYNAME_EN": "Alziyni",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15715",
    "CITYNAME_AR": "منسيه",
    "CITYNAME_EN": "Mansih",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15716",
    "CITYNAME_AR": "جازان",
    "CITYNAME_EN": "Jazan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15717",
    "CITYNAME_AR": "جزيرة امنة",
    "CITYNAME_EN": "Jazirat 'Amanatan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15718",
    "CITYNAME_AR": "بريده",
    "CITYNAME_EN": "Bariduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15719",
    "CITYNAME_AR": "العريفيه",
    "CITYNAME_EN": "Alearifiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15720",
    "CITYNAME_AR": "الشقه العليا",
    "CITYNAME_EN": "Alshaquh Aleulya",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15721",
    "CITYNAME_AR": "القرعاء",
    "CITYNAME_EN": "Alqurea'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15722",
    "CITYNAME_AR": "غضى",
    "CITYNAME_EN": "Ghadaa",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15723",
    "CITYNAME_AR": "المفرق وكمب الطرق",
    "CITYNAME_EN": "Almafraq Wakumb Alturuq",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15724",
    "CITYNAME_AR": "السويدة",
    "CITYNAME_EN": "Alsawida",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15725",
    "CITYNAME_AR": "هجره عسيلان",
    "CITYNAME_EN": "Hajrah Easilan",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15726",
    "CITYNAME_AR": "ارطيان",
    "CITYNAME_EN": "Artian",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15727",
    "CITYNAME_AR": "نقذه",
    "CITYNAME_EN": "Naqadhah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15728",
    "CITYNAME_AR": "الضوي",
    "CITYNAME_EN": "Aldawiy",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15729",
    "CITYNAME_AR": "منيفة القاعد",
    "CITYNAME_EN": "Munifat Alqaeid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15730",
    "CITYNAME_AR": "الخطه",
    "CITYNAME_EN": "Alkhatuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15731",
    "CITYNAME_AR": "المخروقه",
    "CITYNAME_EN": "Almakhruquh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15732",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Aljafr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15733",
    "CITYNAME_AR": "الجلف",
    "CITYNAME_EN": "Aljilaf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15734",
    "CITYNAME_AR": "الشعفه",
    "CITYNAME_EN": "Alshaefuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15735",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Altwal",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15736",
    "CITYNAME_AR": "فيضة الطوال",
    "CITYNAME_EN": "Faydat Altwal",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15737",
    "CITYNAME_AR": "الخطه الشرقيه",
    "CITYNAME_EN": "Alkhatuh Alsharqiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15738",
    "CITYNAME_AR": "المشيطيه",
    "CITYNAME_EN": "Almushitih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15739",
    "CITYNAME_AR": "مخطط محمد الحبيب الزراعي",
    "CITYNAME_EN": "Ghfylyat Alnufud Mukhatat Muhamad Alhabib Alziraei",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15740",
    "CITYNAME_AR": "القاعد",
    "CITYNAME_EN": "Alqaeid",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15741",
    "CITYNAME_AR": "عثمر القديم",
    "CITYNAME_EN": "Eathmar Alqadim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15742",
    "CITYNAME_AR": "عثمر   المخطط",
    "CITYNAME_EN": "Eathamar Almukhatat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15743",
    "CITYNAME_AR": "المزيريرات",
    "CITYNAME_EN": "Almuzirirat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15744",
    "CITYNAME_AR": "مخطط ام القلبان",
    "CITYNAME_EN": "Mukhatat Umm Alqulban",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15745",
    "CITYNAME_AR": "الهرير",
    "CITYNAME_EN": "Alharir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15746",
    "CITYNAME_AR": "القفيعى",
    "CITYNAME_EN": "Alqufieaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15747",
    "CITYNAME_AR": "التربيه",
    "CITYNAME_EN": "Altarbih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15748",
    "CITYNAME_AR": "القليب ( البنتان)",
    "CITYNAME_EN": "Alqalib ( Albantan)",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15749",
    "CITYNAME_AR": "مرفيه",
    "CITYNAME_EN": "Murifih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15750",
    "CITYNAME_AR": "اللغفيه",
    "CITYNAME_EN": "Allaghafih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15751",
    "CITYNAME_AR": "التيم",
    "CITYNAME_EN": "Altim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15752",
    "CITYNAME_AR": "زريب التيم",
    "CITYNAME_EN": "Zarib Altiym",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15753",
    "CITYNAME_AR": "الخريزة",
    "CITYNAME_EN": "Alkhariza",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15754",
    "CITYNAME_AR": "ذوره",
    "CITYNAME_EN": "Zawrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15755",
    "CITYNAME_AR": "صر",
    "CITYNAME_EN": "Sir",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15756",
    "CITYNAME_AR": "البديعات",
    "CITYNAME_EN": "Albadieat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15757",
    "CITYNAME_AR": "المدقه بئر ثفه",
    "CITYNAME_EN": "Almadaquh Biirin Thafah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15758",
    "CITYNAME_AR": "بئر حمدان",
    "CITYNAME_EN": "Bayir Hamdan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15759",
    "CITYNAME_AR": "جيده",
    "CITYNAME_EN": "Jaydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15760",
    "CITYNAME_AR": "المنجور",
    "CITYNAME_EN": "Almanjur",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15761",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Aldaras",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15762",
    "CITYNAME_AR": "شطين",
    "CITYNAME_EN": "Shatin",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15763",
    "CITYNAME_AR": "بئر عوده",
    "CITYNAME_EN": "Bayir Eawdah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15764",
    "CITYNAME_AR": "خرباء",
    "CITYNAME_EN": "Khuraba'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15765",
    "CITYNAME_AR": "ابيار الزبيره",
    "CITYNAME_EN": "Abyar Alzabiruh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15766",
    "CITYNAME_AR": "المعلق",
    "CITYNAME_EN": "Almaealaq",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15767",
    "CITYNAME_AR": "القوه",
    "CITYNAME_EN": "Alqawh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15768",
    "CITYNAME_AR": "بير ابودومه",
    "CITYNAME_EN": "Bayr Abwudumih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15769",
    "CITYNAME_AR": "ابار ابا العجاج",
    "CITYNAME_EN": "Abar 'Abana Aleijaj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15770",
    "CITYNAME_AR": "بيرالبركه",
    "CITYNAME_EN": "Byralbirkh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15771",
    "CITYNAME_AR": "بهمان",
    "CITYNAME_EN": "Bahman",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15772",
    "CITYNAME_AR": "السديد",
    "CITYNAME_EN": "Alsadid",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15773",
    "CITYNAME_AR": "الخرار",
    "CITYNAME_EN": "Alkharar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15774",
    "CITYNAME_AR": "العوجا",
    "CITYNAME_EN": "Aleawja",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15775",
    "CITYNAME_AR": "العويد",
    "CITYNAME_EN": "Aleawayd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15776",
    "CITYNAME_AR": "الخليقه",
    "CITYNAME_EN": "Alkhaliquh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15777",
    "CITYNAME_AR": "عقله بن جبرين",
    "CITYNAME_EN": "Eaqilah Bin Jabarin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15778",
    "CITYNAME_AR": "الصهوه",
    "CITYNAME_EN": "Alsuhuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15779",
    "CITYNAME_AR": "المختلف",
    "CITYNAME_EN": "Almukhtalif",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15780",
    "CITYNAME_AR": "الجديده",
    "CITYNAME_EN": "Aljadiduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15781",
    "CITYNAME_AR": "قمران",
    "CITYNAME_EN": "Qimran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15782",
    "CITYNAME_AR": "المبادع",
    "CITYNAME_EN": "Almubadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15783",
    "CITYNAME_AR": "المبيدع",
    "CITYNAME_EN": "Almubydae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15784",
    "CITYNAME_AR": "جديه",
    "CITYNAME_EN": "Jadih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15785",
    "CITYNAME_AR": "موقق",
    "CITYNAME_EN": "Mawqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15786",
    "CITYNAME_AR": "جرعا عثمان",
    "CITYNAME_EN": "Jurea Euthman",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15787",
    "CITYNAME_AR": "الغفيليه",
    "CITYNAME_EN": "Alghafiliuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15788",
    "CITYNAME_AR": "الضبيب",
    "CITYNAME_EN": "Aldabib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15789",
    "CITYNAME_AR": "حيه",
    "CITYNAME_EN": "Hih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15790",
    "CITYNAME_AR": "قصيريات",
    "CITYNAME_EN": "Qasiriat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15791",
    "CITYNAME_AR": "الحطى",
    "CITYNAME_EN": "Alhataa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15792",
    "CITYNAME_AR": "طويه",
    "CITYNAME_EN": "Tawiah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15793",
    "CITYNAME_AR": "الفرحانيه",
    "CITYNAME_EN": "Alfarhaniuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15794",
    "CITYNAME_AR": "صداء",
    "CITYNAME_EN": "Sada'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15795",
    "CITYNAME_AR": "الرضفين",
    "CITYNAME_EN": "Alradifin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15796",
    "CITYNAME_AR": "صحى",
    "CITYNAME_EN": "Sahaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15797",
    "CITYNAME_AR": "الشارف",
    "CITYNAME_EN": "Alshsharif",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15798",
    "CITYNAME_AR": "قرية بير الشقراء",
    "CITYNAME_EN": "Qaryat Bayr Alshuqara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15799",
    "CITYNAME_AR": "فتخا",
    "CITYNAME_EN": "Fatkha",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15800",
    "CITYNAME_AR": "روضه العبد",
    "CITYNAME_EN": "Ruduh Aleabd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15801",
    "CITYNAME_AR": "الظهر",
    "CITYNAME_EN": "Alzuhr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15802",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Aleaqiluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15803",
    "CITYNAME_AR": "البوبهيه",
    "CITYNAME_EN": "Albawbihih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15804",
    "CITYNAME_AR": "السعده",
    "CITYNAME_EN": "Alsaeduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15805",
    "CITYNAME_AR": "المثلث",
    "CITYNAME_EN": "Almathlath",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15806",
    "CITYNAME_AR": "الجحريه",
    "CITYNAME_EN": "Aljihrayh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15807",
    "CITYNAME_AR": "مزارع مروي",
    "CITYNAME_EN": "Farms  Marwi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15808",
    "CITYNAME_AR": "حمدان",
    "CITYNAME_EN": "Hamdan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15809",
    "CITYNAME_AR": "مخطط ( شفا البين ) السكني ",
    "CITYNAME_EN": "Shafa Albayn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15810",
    "CITYNAME_AR": "البدع",
    "CITYNAME_EN": "Albadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15811",
    "CITYNAME_AR": "ام صور",
    "CITYNAME_EN": "Umm Sur",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15812",
    "CITYNAME_AR": "المحفر",
    "CITYNAME_EN": "Almuhafir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15813",
    "CITYNAME_AR": "الحفير",
    "CITYNAME_EN": "Alhafir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15814",
    "CITYNAME_AR": "كمب الضلوع",
    "CITYNAME_EN": "Kumab Aldlwe",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15815",
    "CITYNAME_AR": "شوط",
    "CITYNAME_EN": "Shawt",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15816",
    "CITYNAME_AR": "مريغان",
    "CITYNAME_EN": "Marighan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15817",
    "CITYNAME_AR": "شبيكان",
    "CITYNAME_EN": "Shabikan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15818",
    "CITYNAME_AR": "التفيهى نايلات",
    "CITYNAME_EN": "Altafayhaa Naylat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15819",
    "CITYNAME_AR": "الشقيق",
    "CITYNAME_EN": "Alshaqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15820",
    "CITYNAME_AR": "ام النصلان",
    "CITYNAME_EN": "Umm Alnaslan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15821",
    "CITYNAME_AR": "المحاش",
    "CITYNAME_EN": "Almahash",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15822",
    "CITYNAME_AR": "المالحه",
    "CITYNAME_EN": "Almalihuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15823",
    "CITYNAME_AR": "المليح",
    "CITYNAME_EN": "Almalih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15824",
    "CITYNAME_AR": "حفيره الشقيق",
    "CITYNAME_EN": "Hafiruh Alshaqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15825",
    "CITYNAME_AR": "جبل كفنتة",
    "CITYNAME_EN": "Jabal Kafanta",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15826",
    "CITYNAME_AR": "جرمه",
    "CITYNAME_EN": "Jaramah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15827",
    "CITYNAME_AR": "ابا الحيران",
    "CITYNAME_EN": "'Abana Alhiran",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15828",
    "CITYNAME_AR": "الثمامى",
    "CITYNAME_EN": "Althamamaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15829",
    "CITYNAME_AR": "المعيقل",
    "CITYNAME_EN": "Almueiqil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15830",
    "CITYNAME_AR": "المرمى",
    "CITYNAME_EN": "Almarmaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15831",
    "CITYNAME_AR": "عرنه ابالجرفان",
    "CITYNAME_EN": "Earanah Abaljurfan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15832",
    "CITYNAME_AR": "القطعاء",
    "CITYNAME_EN": "Alqutea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15833",
    "CITYNAME_AR": "الرديهة",
    "CITYNAME_EN": "Alradiha",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15834",
    "CITYNAME_AR": "العبارثة",
    "CITYNAME_EN": "Aleabaritha",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15835",
    "CITYNAME_AR": "الاخضر",
    "CITYNAME_EN": "Al'Akhdar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15836",
    "CITYNAME_AR": "دليهان",
    "CITYNAME_EN": "Dulayhan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15837",
    "CITYNAME_AR": "جفيفا",
    "CITYNAME_EN": "Jafifaan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15838",
    "CITYNAME_AR": "الغمياء",
    "CITYNAME_EN": "Alghamya'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15839",
    "CITYNAME_AR": "النفس",
    "CITYNAME_EN": "Alnafs",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15840",
    "CITYNAME_AR": "روض الشقيق",
    "CITYNAME_EN": "Rud Alshaqiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15841",
    "CITYNAME_AR": "القيعان",
    "CITYNAME_EN": "Alqiean",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15842",
    "CITYNAME_AR": "الصنينا",
    "CITYNAME_EN": "Alsanina",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15843",
    "CITYNAME_AR": "بطحاوين",
    "CITYNAME_EN": "Bithawin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15844",
    "CITYNAME_AR": "قريه سعيدان",
    "CITYNAME_EN": "Qarih Saeidan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15845",
    "CITYNAME_AR": "الخبه",
    "CITYNAME_EN": "Alkhabuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15846",
    "CITYNAME_AR": "قريه السالمية",
    "CITYNAME_EN": "Quriah Alssalimia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15847",
    "CITYNAME_AR": "النظيم واللجاوي",
    "CITYNAME_EN": "Alnazim Wallajawi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15848",
    "CITYNAME_AR": "المسلسل",
    "CITYNAME_EN": "Almusalsal",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15849",
    "CITYNAME_AR": "الضلضل",
    "CITYNAME_EN": "Aldaldal",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15850",
    "CITYNAME_AR": "الصليعاء",
    "CITYNAME_EN": "Alsaliea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15851",
    "CITYNAME_AR": "الذييبى والسريحه",
    "CITYNAME_EN": "Aldhyyba Walsarihuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15852",
    "CITYNAME_AR": "الشبحه",
    "CITYNAME_EN": "Alshabahuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15853",
    "CITYNAME_AR": "الصره الشرقيه والغربيه",
    "CITYNAME_EN": "Alsaruh Alsharqiuh Walgharbiuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15854",
    "CITYNAME_AR": "سمر",
    "CITYNAME_EN": "Samar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15855",
    "CITYNAME_AR": "فم شثاث",
    "CITYNAME_EN": "Fum Shathath",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15856",
    "CITYNAME_AR": "السهله",
    "CITYNAME_EN": "Alsahluh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15857",
    "CITYNAME_AR": "حضر",
    "CITYNAME_EN": "Hadar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15858",
    "CITYNAME_AR": "الرويضات",
    "CITYNAME_EN": "Alruwaydat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15859",
    "CITYNAME_AR": "قصر عليثه",
    "CITYNAME_EN": "Qasr Ealithuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15860",
    "CITYNAME_AR": "الاصيفر",
    "CITYNAME_EN": "Alasyfr",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15861",
    "CITYNAME_AR": "الصفيراء",
    "CITYNAME_EN": "Alsafira'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15862",
    "CITYNAME_AR": "النويبعه",
    "CITYNAME_EN": "Alnuwaybieuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15863",
    "CITYNAME_AR": "الصحفه",
    "CITYNAME_EN": "Alsahfuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15864",
    "CITYNAME_AR": "فم رويث",
    "CITYNAME_EN": "Fum Ruayth",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15865",
    "CITYNAME_AR": "نعضه",
    "CITYNAME_EN": "Neduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15866",
    "CITYNAME_AR": "المليقه",
    "CITYNAME_EN": "Almaliquh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15867",
    "CITYNAME_AR": "عليان",
    "CITYNAME_EN": "Eulyan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15868",
    "CITYNAME_AR": "ابار دبيسى",
    "CITYNAME_EN": "Abar Dbysa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15869",
    "CITYNAME_AR": "حراض",
    "CITYNAME_EN": "Harad",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15870",
    "CITYNAME_AR": "ثميم",
    "CITYNAME_EN": "Thamim",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15871",
    "CITYNAME_AR": "الخشارم",
    "CITYNAME_EN": "Alkhasharim",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15872",
    "CITYNAME_AR": "الرقم",
    "CITYNAME_EN": "Alraqm",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15873",
    "CITYNAME_AR": "الحفير(العقيربان)",
    "CITYNAME_EN": "Alhfyr(Aleqyrban)",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15874",
    "CITYNAME_AR": "اليمامة",
    "CITYNAME_EN": "Alymam",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15875",
    "CITYNAME_AR": "الورقية",
    "CITYNAME_EN": "Alwarqia",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15876",
    "CITYNAME_AR": "البويطن",
    "CITYNAME_EN": "Albuytnu",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15877",
    "CITYNAME_AR": "الهميجة (الديسه)",
    "CITYNAME_EN": "Alhamija (Aldaysah)",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15878",
    "CITYNAME_AR": "الشتات",
    "CITYNAME_EN": "Alshatat",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "15879",
    "CITYNAME_AR": "تومل",
    "CITYNAME_EN": "Tawmal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15880",
    "CITYNAME_AR": "الخوير",
    "CITYNAME_EN": "Alkhawayr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15881",
    "CITYNAME_AR": "المهينيه",
    "CITYNAME_EN": "Almuhiniuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15882",
    "CITYNAME_AR": "طلحاء",
    "CITYNAME_EN": "Talaha'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15883",
    "CITYNAME_AR": "الاجفر",
    "CITYNAME_EN": "Alajfir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15884",
    "CITYNAME_AR": "رديفه الاجفر",
    "CITYNAME_EN": "Radifuh Alajfir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15885",
    "CITYNAME_AR": "بدائع الاجفر",
    "CITYNAME_EN": "Badayie Alajfir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15886",
    "CITYNAME_AR": "الشفلحيه",
    "CITYNAME_EN": "Alshuflihiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15887",
    "CITYNAME_AR": "ام ساروت",
    "CITYNAME_EN": "Umm Sarut",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15888",
    "CITYNAME_AR": "لينه",
    "CITYNAME_EN": "Laynah",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15889",
    "CITYNAME_AR": "رغوه الجديده",
    "CITYNAME_EN": "Raghuh Aljadiduh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15890",
    "CITYNAME_AR": "غنيم",
    "CITYNAME_EN": "Ghanim",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15891",
    "CITYNAME_AR": "رغوة",
    "CITYNAME_EN": "Raghua",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15892",
    "CITYNAME_AR": "الحدقه",
    "CITYNAME_EN": "Alhidaquh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15893",
    "CITYNAME_AR": "اعيوج لينه",
    "CITYNAME_EN": "Aeyuj Laynah",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15894",
    "CITYNAME_AR": "الحدقة",
    "CITYNAME_EN": "Alhadqa",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15895",
    "CITYNAME_AR": "ام شفلح",
    "CITYNAME_EN": "Umm Shaflah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15896",
    "CITYNAME_AR": "الزبيره",
    "CITYNAME_EN": "Alzabiruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15897",
    "CITYNAME_AR": "ابوصور بن جبرين",
    "CITYNAME_EN": "Abwusur Bin Jabrin",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15898",
    "CITYNAME_AR": "القصوريات",
    "CITYNAME_EN": "Alqusuriat",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15899",
    "CITYNAME_AR": "روضة هباس",
    "CITYNAME_EN": "Rawdat Hibas",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15900",
    "CITYNAME_AR": "الخشيبي",
    "CITYNAME_EN": "Alkhashibi",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15901",
    "CITYNAME_AR": "لواء",
    "CITYNAME_EN": "Liwa'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15902",
    "CITYNAME_AR": "بدر",
    "CITYNAME_EN": "Badr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15903",
    "CITYNAME_AR": "الجديد",
    "CITYNAME_EN": "Al Jadid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15904",
    "CITYNAME_AR": "البركه",
    "CITYNAME_EN": "Albarakah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15905",
    "CITYNAME_AR": "الفارعه",
    "CITYNAME_EN": "Al Fara'ah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15906",
    "CITYNAME_AR": "ابو نجم والمحاوشيه",
    "CITYNAME_EN": "Abo Najm wa Al Muhawishyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15907",
    "CITYNAME_AR": "العاليه",
    "CITYNAME_EN": "Al-Alyha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15908",
    "CITYNAME_AR": "الحسينيه",
    "CITYNAME_EN": "Al Husaynyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15909",
    "CITYNAME_AR": "بئر رايق",
    "CITYNAME_EN": "Rayeq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15910",
    "CITYNAME_AR": "دغبج",
    "CITYNAME_EN": "Daghbag",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15911",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Al Mufreq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15912",
    "CITYNAME_AR": "بئر المجيرمه",
    "CITYNAME_EN": "Al Mugayramah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15913",
    "CITYNAME_AR": "بئر ابو سرحه",
    "CITYNAME_EN": "Abo Sarhah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15914",
    "CITYNAME_AR": "الغزلاني",
    "CITYNAME_EN": "Al Ghoslani",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15915",
    "CITYNAME_AR": "بئر القمعه",
    "CITYNAME_EN": "Al Qomaah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15916",
    "CITYNAME_AR": "الصميد",
    "CITYNAME_EN": "As-Sumaid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15917",
    "CITYNAME_AR": "ابار ابن حصانى",
    "CITYNAME_EN": "Ibn Hasani wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15918",
    "CITYNAME_AR": "الغور",
    "CITYNAME_EN": "Al Ghawr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15919",
    "CITYNAME_AR": "الرجع",
    "CITYNAME_EN": "Al Rajei",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15920",
    "CITYNAME_AR": "نزله ابو حواء",
    "CITYNAME_EN": "Nazlet Abo Hawaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15921",
    "CITYNAME_AR": "أم الطبقان",
    "CITYNAME_EN": "Om At-Tabaqan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15922",
    "CITYNAME_AR": "دمدم",
    "CITYNAME_EN": "Damdam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15923",
    "CITYNAME_AR": "الثماميه",
    "CITYNAME_EN": "Al Thimamiyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15924",
    "CITYNAME_AR": "ذيران",
    "CITYNAME_EN": "Zeran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15925",
    "CITYNAME_AR": "بئر حميراء",
    "CITYNAME_EN": "Humayraa well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15926",
    "CITYNAME_AR": "بئر عوده بن حسين",
    "CITYNAME_EN": "Oudah bin Hussein well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15927",
    "CITYNAME_AR": "بئر ابن عبدالله",
    "CITYNAME_EN": "Ibn Abdullah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15928",
    "CITYNAME_AR": "بئر سلامه",
    "CITYNAME_EN": "Salamah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15929",
    "CITYNAME_AR": "بئر مغطه",
    "CITYNAME_EN": "Mughtah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15930",
    "CITYNAME_AR": "بئر العرفه",
    "CITYNAME_EN": "Al Arafah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15931",
    "CITYNAME_AR": "مزرعه مسفر (بئر مسفر)",
    "CITYNAME_EN": "Mesfer farm (Mesfer well)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15932",
    "CITYNAME_AR": "بئر فالح",
    "CITYNAME_EN": "Faleh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15933",
    "CITYNAME_AR": "بئر بن فهيد",
    "CITYNAME_EN": "Bin Faheed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15934",
    "CITYNAME_AR": "بئر فريج",
    "CITYNAME_EN": "Fareej well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15935",
    "CITYNAME_AR": "بئر الرتيق",
    "CITYNAME_EN": "Al Rateeq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15936",
    "CITYNAME_AR": "بئر العروى",
    "CITYNAME_EN": "Biir Aleurwaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15937",
    "CITYNAME_AR": "الواسطه",
    "CITYNAME_EN": "Al Wasta",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15938",
    "CITYNAME_AR": "الخرماء",
    "CITYNAME_EN": "Al Kharma",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15939",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15940",
    "CITYNAME_AR": "الهدى",
    "CITYNAME_EN": "Al Huda",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15941",
    "CITYNAME_AR": "بئر مرشد بن رويشد (الكباشية)",
    "CITYNAME_EN": "Marshad bin Ruwayshed well (Al Kabashyah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15942",
    "CITYNAME_AR": "بئر جابر (رينه)",
    "CITYNAME_EN": "Jaber well (Raynah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15943",
    "CITYNAME_AR": "الرتج",
    "CITYNAME_EN": "Ar-Ratj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15944",
    "CITYNAME_AR": "الزراعيه",
    "CITYNAME_EN": "Az-Zerayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15945",
    "CITYNAME_AR": "كتانه",
    "CITYNAME_EN": "Katanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15946",
    "CITYNAME_AR": "بئر الشريوفي",
    "CITYNAME_EN": "Ash-Sharyoufi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15947",
    "CITYNAME_AR": "الطفيان",
    "CITYNAME_EN": "Az-Zufyan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15948",
    "CITYNAME_AR": "الجوابره",
    "CITYNAME_EN": "Al Jawaberah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15949",
    "CITYNAME_AR": "العمور",
    "CITYNAME_EN": "Al Omor",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15950",
    "CITYNAME_AR": "ضمو المهديات",
    "CITYNAME_EN": "Damu Al Mahdyat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15951",
    "CITYNAME_AR": "بئر ابو عبيده",
    "CITYNAME_EN": "Abo Obaidah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15952",
    "CITYNAME_AR": "بئر عويش الحازمي",
    "CITYNAME_EN": "Owaish Al Hazemi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15953",
    "CITYNAME_AR": "بئر رحيب بن طلق",
    "CITYNAME_EN": "Raheeb bin Talq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15954",
    "CITYNAME_AR": "الحفى",
    "CITYNAME_EN": "Al Hafi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15955",
    "CITYNAME_AR": "النابع",
    "CITYNAME_EN": "Al Nabie'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15956",
    "CITYNAME_AR": "بئر الشيخ أحمد الخطاب",
    "CITYNAME_EN": "Sheikh Ahmed Al Khattab well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15957",
    "CITYNAME_AR": "بئر فراج الحازمي",
    "CITYNAME_EN": "Farraj Al Hazemi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15958",
    "CITYNAME_AR": "بئر مساعد الحازمي",
    "CITYNAME_EN": "Mosaed Al Hazemi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15959",
    "CITYNAME_AR": "الصفي",
    "CITYNAME_EN": "As-Saffi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15960",
    "CITYNAME_AR": "ابيار رحاب",
    "CITYNAME_EN": "Abyar Rahab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15961",
    "CITYNAME_AR": "الرايس",
    "CITYNAME_EN": "Al Rayes",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15962",
    "CITYNAME_AR": "الدقم",
    "CITYNAME_EN": "Ad-Daqam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15963",
    "CITYNAME_AR": "بئر عباد",
    "CITYNAME_EN": "Abbad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15964",
    "CITYNAME_AR": "مفرق الرايس",
    "CITYNAME_EN": "Mafreq Ar-Rayes",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15965",
    "CITYNAME_AR": "بئر المقفاه",
    "CITYNAME_EN": "Biir Al Meqfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15966",
    "CITYNAME_AR": "طاشا ( حي المدارس )",
    "CITYNAME_EN": "Tasha (Hay Al Madaresh)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15967",
    "CITYNAME_AR": "خيف ام ذيان",
    "CITYNAME_EN": "Khaif Om Zayan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15968",
    "CITYNAME_AR": "بئر محمد صالح (أم فحي)",
    "CITYNAME_EN": "Mohamed Saleh well (Om Fahi)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15969",
    "CITYNAME_AR": "آلاب",
    "CITYNAME_EN": "Aalab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15970",
    "CITYNAME_AR": "الشعيبه",
    "CITYNAME_EN": "Ash Shuaybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15971",
    "CITYNAME_AR": "برقه",
    "CITYNAME_EN": "Baraqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15972",
    "CITYNAME_AR": "الضمو",
    "CITYNAME_EN": "Ad-Damou",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15973",
    "CITYNAME_AR": "الشعابه",
    "CITYNAME_EN": "Ash-Shuabah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15974",
    "CITYNAME_AR": "خيف الكسا",
    "CITYNAME_EN": "Khef Al Kasa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15975",
    "CITYNAME_AR": "ماروما",
    "CITYNAME_EN": "Maruma",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15976",
    "CITYNAME_AR": "الولجه",
    "CITYNAME_EN": "Al Walajah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15977",
    "CITYNAME_AR": "قرظه",
    "CITYNAME_EN": "Qarazah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15978",
    "CITYNAME_AR": "أنميا",
    "CITYNAME_EN": "Anmya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15979",
    "CITYNAME_AR": "السناء",
    "CITYNAME_EN": "As-Sanaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15980",
    "CITYNAME_AR": "سمر",
    "CITYNAME_EN": "Samar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15981",
    "CITYNAME_AR": "الحلقة",
    "CITYNAME_EN": "Al Halka",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15982",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Al Hano",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15983",
    "CITYNAME_AR": "وادى برمه",
    "CITYNAME_EN": "Wa'Adaa Biramih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15984",
    "CITYNAME_AR": "خيبر",
    "CITYNAME_EN": "Khaybar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15985",
    "CITYNAME_AR": "الصفق",
    "CITYNAME_EN": "As-Safaq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15986",
    "CITYNAME_AR": "الحرضه",
    "CITYNAME_EN": "Al Haradah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15987",
    "CITYNAME_AR": "اباالبقر",
    "CITYNAME_EN": "Aba Al Baqar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15988",
    "CITYNAME_AR": "خضرانه",
    "CITYNAME_EN": "Khadranah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15989",
    "CITYNAME_AR": "سمينان",
    "CITYNAME_EN": "Sumaynan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15990",
    "CITYNAME_AR": "سويقة",
    "CITYNAME_EN": "Suwaiqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15991",
    "CITYNAME_AR": "المضاويح",
    "CITYNAME_EN": "Al Madaweeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15992",
    "CITYNAME_AR": "عين العبادله",
    "CITYNAME_EN": "Ain Al Abadelah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15993",
    "CITYNAME_AR": "المنجي",
    "CITYNAME_EN": "Al Munji",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15994",
    "CITYNAME_AR": "رحبه الحسنى",
    "CITYNAME_EN": "Rahbah Al Hosni",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15995",
    "CITYNAME_AR": "زبران",
    "CITYNAME_EN": "Zabran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15996",
    "CITYNAME_AR": "الوادى",
    "CITYNAME_EN": "Al Wadi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15997",
    "CITYNAME_AR": "البلاليه",
    "CITYNAME_EN": "Al Belalyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15998",
    "CITYNAME_AR": "اباانعم",
    "CITYNAME_EN": "Aba Anaam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15999",
    "CITYNAME_AR": "ثور",
    "CITYNAME_EN": "Thawr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16000",
    "CITYNAME_AR": "السليمي",
    "CITYNAME_EN": "As-Sulaymi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16001",
    "CITYNAME_AR": "وجده",
    "CITYNAME_EN": "Wajdah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16002",
    "CITYNAME_AR": "عييبه",
    "CITYNAME_EN": "Awyaybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16003",
    "CITYNAME_AR": "الخريبه",
    "CITYNAME_EN": "Al-Khariba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16004",
    "CITYNAME_AR": "عين ام حكيم",
    "CITYNAME_EN": "Ain Om Hakeem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16005",
    "CITYNAME_AR": "الحلحال",
    "CITYNAME_EN": "Al Hulhal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16006",
    "CITYNAME_AR": "سدير",
    "CITYNAME_EN": "Sadir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16007",
    "CITYNAME_AR": "العدله",
    "CITYNAME_EN": "Al Adlaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16008",
    "CITYNAME_AR": "امهات بيبض",
    "CITYNAME_EN": "Amhat Bybd",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16009",
    "CITYNAME_AR": "بئر بن معنز ",
    "CITYNAME_EN": "Beir Bin Mueniz (Beir Halab)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16010",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16011",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Aleiinuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16012",
    "CITYNAME_AR": "النويبع والنابع",
    "CITYNAME_EN": "Alnuwaybue Walnnabie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16013",
    "CITYNAME_AR": "الثمد",
    "CITYNAME_EN": "Althamad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16014",
    "CITYNAME_AR": "الحميضه",
    "CITYNAME_EN": "Alhamiduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16099",
    "CITYNAME_AR": "بدائع الدلابحه",
    "CITYNAME_EN": "Bade' Ad-Dalabhah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "16105",
    "CITYNAME_AR": "رفايع الجمش",
    "CITYNAME_EN": "Rafaeya'a Al-Jumsh",
    "REGION_ID": "1"
   }, 
   {
    "CITY_ID": "16161",
    "CITYNAME_AR": "نجخ",
    "CITYNAME_EN": "Najkh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "16166",
    "CITYNAME_AR": "المويه",
    "CITYNAME_EN": "Al Muwayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16167",
    "CITYNAME_AR": "الخنفريه",
    "CITYNAME_EN": "Al Khanfaryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16168",
    "CITYNAME_AR": "الرجمه",
    "CITYNAME_EN": "Ar-Rajamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16169",
    "CITYNAME_AR": "قطان",
    "CITYNAME_EN": "Qattan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16170",
    "CITYNAME_AR": "قصر الملك عبدالعزيز بالمويه القديمة",
    "CITYNAME_EN": "King Abdulaziz palace in Al Mawyah Al Qadima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16171",
    "CITYNAME_AR": "الحويمضه",
    "CITYNAME_EN": "Al Huwaymadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16172",
    "CITYNAME_AR": "رضوان",
    "CITYNAME_EN": "Radwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16173",
    "CITYNAME_AR": "المعششه",
    "CITYNAME_EN": "Al Muashashah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16174",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Al Hufayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16175",
    "CITYNAME_AR": "الهمجه",
    "CITYNAME_EN": "Al Hamjaha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16176",
    "CITYNAME_AR": "السليمان",
    "CITYNAME_EN": "As-Sulaiman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16177",
    "CITYNAME_AR": "حفر كشب",
    "CITYNAME_EN": "Hafr Kashab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16178",
    "CITYNAME_AR": "ام الريلان",
    "CITYNAME_EN": "Om Ar-Raylan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16179",
    "CITYNAME_AR": " الرفيعه",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16180",
    "CITYNAME_AR": "المتايهه",
    "CITYNAME_EN": "Al Mutayhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16181",
    "CITYNAME_AR": "بدايع الحفر (الغنانيم)",
    "CITYNAME_EN": "Badia' Al Hafr (Al Ghananeem)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16182",
    "CITYNAME_AR": "مقر الشديد",
    "CITYNAME_EN": "Ash-Shadeed headquarters",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16183",
    "CITYNAME_AR": "مقر شنيف",
    "CITYNAME_EN": "Shaneef headquarters",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16184",
    "CITYNAME_AR": "نمران",
    "CITYNAME_EN": "Namran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16185",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Al-Salehiyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16186",
    "CITYNAME_AR": "البطينه",
    "CITYNAME_EN": "Al Butaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16187",
    "CITYNAME_AR": "الحاوي",
    "CITYNAME_EN": "Al Hawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16188",
    "CITYNAME_AR": "المشويه",
    "CITYNAME_EN": "Al Mashwyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16189",
    "CITYNAME_AR": "المنتزه",
    "CITYNAME_EN": "Al Muntazah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16190",
    "CITYNAME_AR": "المزيرعة",
    "CITYNAME_EN": "Al Muzayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16191",
    "CITYNAME_AR": "دغيبجه",
    "CITYNAME_EN": "Doghaibejah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16192",
    "CITYNAME_AR": "مران",
    "CITYNAME_EN": "Maran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16193",
    "CITYNAME_AR": "هجره المطلاع",
    "CITYNAME_EN": "Hijrah Al Metlaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16194",
    "CITYNAME_AR": "القراره",
    "CITYNAME_EN": "Al Querrara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16195",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "As-Sulayyil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16196",
    "CITYNAME_AR": "الدرعيه",
    "CITYNAME_EN": "Ad-Diriyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16197",
    "CITYNAME_AR": "الدمثه",
    "CITYNAME_EN": "Ad-Damathah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16198",
    "CITYNAME_AR": "بيوت سعيد العازمي",
    "CITYNAME_EN": "Saeed Al Azemi houses",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16199",
    "CITYNAME_AR": "الصقاريات",
    "CITYNAME_EN": "As-Saqariyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16200",
    "CITYNAME_AR": "مزارع الفاضليه",
    "CITYNAME_EN": "Al Fadilyah farms",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16201",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16202",
    "CITYNAME_AR": "الدعكه",
    "CITYNAME_EN": "Ad-Daaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16203",
    "CITYNAME_AR": "النافليه",
    "CITYNAME_EN": "An-Nafilyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16204",
    "CITYNAME_AR": "بادريه الكراشمه",
    "CITYNAME_EN": "Badryah Al Karashmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16205",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Al Hufayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16206",
    "CITYNAME_AR": "الصوان",
    "CITYNAME_EN": "As-Sawan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16207",
    "CITYNAME_AR": "الذويب",
    "CITYNAME_EN": "Az-Zuwaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16208",
    "CITYNAME_AR": "بدائع جابر",
    "CITYNAME_EN": "Badaei Jaber",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16209",
    "CITYNAME_AR": "ناصفه الابرق",
    "CITYNAME_EN": "Nasefah Al Abraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16210",
    "CITYNAME_AR": "صنعاء",
    "CITYNAME_EN": "Sanaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16211",
    "CITYNAME_AR": "الحفنه بالرميثه",
    "CITYNAME_EN": "Al Hafnah, Ar-Rumaithah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16212",
    "CITYNAME_AR": "ام عريد (العنكبوت)",
    "CITYNAME_EN": "Om Areed (Al Ankabut)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16213",
    "CITYNAME_AR": "الكثاح",
    "CITYNAME_EN": "Al Kuthah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16214",
    "CITYNAME_AR": "العلا (البادريه)",
    "CITYNAME_EN": "Al Ola (Al Badryah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16215",
    "CITYNAME_AR": "ام سليم",
    "CITYNAME_EN": "Om Salim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16216",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Ad-Darah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16217",
    "CITYNAME_AR": "باحة السليم",
    "CITYNAME_EN": "Bahet As-Saleem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16218",
    "CITYNAME_AR": "الحماده",
    "CITYNAME_EN": "Al Hamadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16219",
    "CITYNAME_AR": "المعايل",
    "CITYNAME_EN": "Al Muayel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16220",
    "CITYNAME_AR": "الزربان",
    "CITYNAME_EN": "Al Zerban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16221",
    "CITYNAME_AR": "مشاش الطارف",
    "CITYNAME_EN": "Mishash Al Tarf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16222",
    "CITYNAME_AR": "أم الدوم",
    "CITYNAME_EN": "Umm Al Doum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16223",
    "CITYNAME_AR": "المرقب والسليم",
    "CITYNAME_EN": "Al Marqab wa As-Saleem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16224",
    "CITYNAME_AR": "لحى ضب",
    "CITYNAME_EN": "Lahi Dab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16225",
    "CITYNAME_AR": "ريع العوالي",
    "CITYNAME_EN": "Rei Al Awali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16226",
    "CITYNAME_AR": "القليب",
    "CITYNAME_EN": "Al Qulaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16227",
    "CITYNAME_AR": "القعرة ومزارعها",
    "CITYNAME_EN": "Alquerat and Farmsa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16228",
    "CITYNAME_AR": "عسفان",
    "CITYNAME_EN": "Easfan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16229",
    "CITYNAME_AR": "فيده النخل",
    "CITYNAME_EN": "Fiduh Alnakhl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16230",
    "CITYNAME_AR": "حاره ابراهيم",
    "CITYNAME_EN": "Harih 'Iibrahim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16231",
    "CITYNAME_AR": "الشعثا",
    "CITYNAME_EN": "Alshaetha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16232",
    "CITYNAME_AR": "العويجاء",
    "CITYNAME_EN": "Aleuyja'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16233",
    "CITYNAME_AR": "الغولاء",
    "CITYNAME_EN": "Alghula'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16234",
    "CITYNAME_AR": "ام الحما",
    "CITYNAME_EN": "Umm Alhamma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16235",
    "CITYNAME_AR": "الصغو",
    "CITYNAME_EN": "Alsghu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16236",
    "CITYNAME_AR": "الحميمه",
    "CITYNAME_EN": "Alhamimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16237",
    "CITYNAME_AR": "المحسنيه",
    "CITYNAME_EN": "Almuhasinih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16238",
    "CITYNAME_AR": "المقيطع",
    "CITYNAME_EN": "Almuqaytae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16239",
    "CITYNAME_AR": "الشاميه",
    "CITYNAME_EN": "Alshaamiyh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16240",
    "CITYNAME_AR": "البنايه",
    "CITYNAME_EN": "Albanayih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16241",
    "CITYNAME_AR": "جليل",
    "CITYNAME_EN": "Jalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16242",
    "CITYNAME_AR": "النظر",
    "CITYNAME_EN": "Alnazar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16243",
    "CITYNAME_AR": "مراخ",
    "CITYNAME_EN": "Marakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16244",
    "CITYNAME_AR": "فيده العليا",
    "CITYNAME_EN": "Fiduh Aleulya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16245",
    "CITYNAME_AR": "منازل فيده",
    "CITYNAME_EN": "Manazil Fidih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16246",
    "CITYNAME_AR": "الطريفاء",
    "CITYNAME_EN": "Altarifa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16247",
    "CITYNAME_AR": "سدر",
    "CITYNAME_EN": "Sidr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16248",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Alhadba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16249",
    "CITYNAME_AR": "العمار",
    "CITYNAME_EN": "Aleamar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16250",
    "CITYNAME_AR": "الشدخ",
    "CITYNAME_EN": "Alshadakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16251",
    "CITYNAME_AR": "الخنفسة",
    "CITYNAME_EN": "Alkhnfsa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16252",
    "CITYNAME_AR": "الحميضه",
    "CITYNAME_EN": "Alhamiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16253",
    "CITYNAME_AR": "صفيه",
    "CITYNAME_EN": "Safih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16254",
    "CITYNAME_AR": "الحلقة العليا",
    "CITYNAME_EN": "Alhalqat Aleulya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16255",
    "CITYNAME_AR": "نبط",
    "CITYNAME_EN": "Nabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16256",
    "CITYNAME_AR": "حشاش",
    "CITYNAME_EN": "Hashash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16257",
    "CITYNAME_AR": "البهيره",
    "CITYNAME_EN": "Albahiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16258",
    "CITYNAME_AR": "داران",
    "CITYNAME_EN": "Daran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16259",
    "CITYNAME_AR": "محطه حشاش",
    "CITYNAME_EN": "Station of Hishash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16260",
    "CITYNAME_AR": "النخيل الاقصى",
    "CITYNAME_EN": "Alnakhil Al'Aqsaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16261",
    "CITYNAME_AR": "المنجور",
    "CITYNAME_EN": "Almangour",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16262",
    "CITYNAME_AR": "ام مسكن",
    "CITYNAME_EN": "Om Maskan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16263",
    "CITYNAME_AR": "الدواره والجو",
    "CITYNAME_EN": "Ad-Duwarah wa Al Jaw",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16264",
    "CITYNAME_AR": "الطفحاء",
    "CITYNAME_EN": "At-Tafhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16265",
    "CITYNAME_AR": "خليص",
    "CITYNAME_EN": "Khalis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16266",
    "CITYNAME_AR": "الحميرات",
    "CITYNAME_EN": "Alhamirat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16267",
    "CITYNAME_AR": "الصدر (المرامحه)",
    "CITYNAME_EN": "Alsadr (Almuramahh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16268",
    "CITYNAME_AR": "اللبده",
    "CITYNAME_EN": "Allabadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16269",
    "CITYNAME_AR": "الشيوخ",
    "CITYNAME_EN": "Alshuyukh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16270",
    "CITYNAME_AR": "الطلعه",
    "CITYNAME_EN": "Altalaeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16271",
    "CITYNAME_AR": "غران",
    "CITYNAME_EN": "Ghuran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16272",
    "CITYNAME_AR": "الزاهد",
    "CITYNAME_EN": "Alzzahid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16273",
    "CITYNAME_AR": "الظبية والجمعه",
    "CITYNAME_EN": "Alzibiyat Waljameuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16274",
    "CITYNAME_AR": "الحاوى",
    "CITYNAME_EN": "Alhawaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16275",
    "CITYNAME_AR": "ام العوشز",
    "CITYNAME_EN": "Umm Aleawshaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16276",
    "CITYNAME_AR": "الغروف الجنوبيه",
    "CITYNAME_EN": "Alghuruf Aljanubiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16277",
    "CITYNAME_AR": "المخمره",
    "CITYNAME_EN": "Almukhamaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16278",
    "CITYNAME_AR": "صميدة",
    "CITYNAME_EN": "Samida",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16279",
    "CITYNAME_AR": "الغزيله",
    "CITYNAME_EN": "Alghaziluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16280",
    "CITYNAME_AR": "المندسه",
    "CITYNAME_EN": "Almandisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16281",
    "CITYNAME_AR": "المشويه",
    "CITYNAME_EN": "Almushawih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16282",
    "CITYNAME_AR": "المورحيه",
    "CITYNAME_EN": "Almurihih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16283",
    "CITYNAME_AR": "ام نخله",
    "CITYNAME_EN": "Umm Nakhluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16284",
    "CITYNAME_AR": "المسماه",
    "CITYNAME_EN": "Almasamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16285",
    "CITYNAME_AR": "تضمرى",
    "CITYNAME_EN": "Tadmaraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16286",
    "CITYNAME_AR": "شبيرم والنحيته",
    "CITYNAME_EN": "Shabirm Walnahyatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16287",
    "CITYNAME_AR": "ام الشقان",
    "CITYNAME_EN": "Umm Alshuqqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16288",
    "CITYNAME_AR": "ام الشوك",
    "CITYNAME_EN": "Umm Alshuk",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16289",
    "CITYNAME_AR": "الخيف",
    "CITYNAME_EN": "Alkhayf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16290",
    "CITYNAME_AR": "لينه",
    "CITYNAME_EN": "Laynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16291",
    "CITYNAME_AR": "الوزه",
    "CITYNAME_EN": "Alwzh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16292",
    "CITYNAME_AR": "المنصا",
    "CITYNAME_EN": "Almanasa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16293",
    "CITYNAME_AR": "الحيجان",
    "CITYNAME_EN": "Alhayjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16294",
    "CITYNAME_AR": "المعرج",
    "CITYNAME_EN": "Almaerij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16295",
    "CITYNAME_AR": "السويله",
    "CITYNAME_EN": "Alsawiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16296",
    "CITYNAME_AR": "الفارع",
    "CITYNAME_EN": "Alfarie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16297",
    "CITYNAME_AR": "مرخ وام الجرم",
    "CITYNAME_EN": "Marakh Wa'Um Aljiram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16298",
    "CITYNAME_AR": "النغيريه",
    "CITYNAME_EN": "Alnaghyrih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16299",
    "CITYNAME_AR": "الروضه (ضرك)",
    "CITYNAME_EN": "Alruwduh (Drak)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16300",
    "CITYNAME_AR": "نخيل الفقير",
    "CITYNAME_EN": "Nakhil Alfaqir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16301",
    "CITYNAME_AR": "الغروف الشماليه",
    "CITYNAME_EN": "Alghuruf Alshamaliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16302",
    "CITYNAME_AR": "المرير",
    "CITYNAME_EN": "Almarir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16303",
    "CITYNAME_AR": "مسر الجنوبى والشمالى",
    "CITYNAME_EN": "Masr Aljunubaa Walshamalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16304",
    "CITYNAME_AR": "الراضه",
    "CITYNAME_EN": "Alradih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16305",
    "CITYNAME_AR": "الرعيليه",
    "CITYNAME_EN": "Alrueyliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16306",
    "CITYNAME_AR": "الروب",
    "CITYNAME_EN": "Alruwb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16307",
    "CITYNAME_AR": "البشاما",
    "CITYNAME_EN": "Albishama",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16308",
    "CITYNAME_AR": "الجميعية",
    "CITYNAME_EN": "Aljamieia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16309",
    "CITYNAME_AR": "حرة العطاوية",
    "CITYNAME_EN": "Hurat Aleatawia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16310",
    "CITYNAME_AR": "الخمرة",
    "CITYNAME_EN": "Alkhamra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16311",
    "CITYNAME_AR": "الصفران",
    "CITYNAME_EN": "Alsifran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16312",
    "CITYNAME_AR": "النور",
    "CITYNAME_EN": "Alnuwr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16313",
    "CITYNAME_AR": "ام الشقان(ام الجديان)",
    "CITYNAME_EN": "Umm Alshaqana(Am Aljidyan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16314",
    "CITYNAME_AR": "ام الشوك(ام العبيد)",
    "CITYNAME_EN": "'Ama Alshuka(Am Aleabayd)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16315",
    "CITYNAME_AR": "ظفر",
    "CITYNAME_EN": "Zufur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16316",
    "CITYNAME_AR": "الدف",
    "CITYNAME_EN": "Aldafu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16317",
    "CITYNAME_AR": "البريكه",
    "CITYNAME_EN": "Albarikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16318",
    "CITYNAME_AR": "دوقه",
    "CITYNAME_EN": "Dawqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16319",
    "CITYNAME_AR": "ام ضفيره",
    "CITYNAME_EN": "Umm Dafiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16320",
    "CITYNAME_AR": "البختريه",
    "CITYNAME_EN": "Albukhturih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16321",
    "CITYNAME_AR": "ملح الشمالى",
    "CITYNAME_EN": "Milh Alshamalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16322",
    "CITYNAME_AR": "ملح الجنوبى",
    "CITYNAME_EN": "Milh Aljunubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16323",
    "CITYNAME_AR": "المكسر (العقيله)",
    "CITYNAME_EN": "Almukasir (Aleaqilh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16324",
    "CITYNAME_AR": "المحاويه",
    "CITYNAME_EN": "Almahawih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16325",
    "CITYNAME_AR": "القديده (القديديه)",
    "CITYNAME_EN": "Alqadiduh (Alqadayduyha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16326",
    "CITYNAME_AR": "الطارف",
    "CITYNAME_EN": "Alttarif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16327",
    "CITYNAME_AR": "ام الجرم",
    "CITYNAME_EN": "Umm Aljiram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16328",
    "CITYNAME_AR": "الربوه السفلى",
    "CITYNAME_EN": "Alrubuuh Alsuflaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16329",
    "CITYNAME_AR": "الطرقى",
    "CITYNAME_EN": "Alturqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16330",
    "CITYNAME_AR": "ام سريحه",
    "CITYNAME_EN": "Umm Sarihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16331",
    "CITYNAME_AR": "حراء",
    "CITYNAME_EN": "Hara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16332",
    "CITYNAME_AR": "ام سدره",
    "CITYNAME_EN": "Umm Sadarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16333",
    "CITYNAME_AR": "رده زبن",
    "CITYNAME_EN": "Radah Zaban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16334",
    "CITYNAME_AR": "الخضيريه",
    "CITYNAME_EN": "Alkhadiriuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16335",
    "CITYNAME_AR": "بيهس",
    "CITYNAME_EN": "Bahs",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16336",
    "CITYNAME_AR": "القويز",
    "CITYNAME_EN": "Alquayz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16337",
    "CITYNAME_AR": "ابوجعده",
    "CITYNAME_EN": "Abwjedh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16338",
    "CITYNAME_AR": "المغل",
    "CITYNAME_EN": "Almaghalu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16339",
    "CITYNAME_AR": "جبل المسلوخه",
    "CITYNAME_EN": "Jabal Almaslukhih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16340",
    "CITYNAME_AR": "السهم (الادنى)",
    "CITYNAME_EN": "Alsahm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16341",
    "CITYNAME_AR": "الره",
    "CITYNAME_EN": "Alrh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16342",
    "CITYNAME_AR": "ام الغلثي",
    "CITYNAME_EN": "Umm Alghalthi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16343",
    "CITYNAME_AR": "وادي حفره ",
    "CITYNAME_EN": "Wadi Hafrih (Ami Aljarm)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16344",
    "CITYNAME_AR": "الغثيانه الاولى",
    "CITYNAME_EN": "Alghuthyanuh Al'Uwlaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16345",
    "CITYNAME_AR": "الغثيانه الثانيه",
    "CITYNAME_EN": "Alghuthyanuh Alththanih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16346",
    "CITYNAME_AR": "الفج",
    "CITYNAME_EN": "Alfuji",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16347",
    "CITYNAME_AR": "شميلة",
    "CITYNAME_EN": "Shamila",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16348",
    "CITYNAME_AR": "ام الاثموم",
    "CITYNAME_EN": "Umm Alathmum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16349",
    "CITYNAME_AR": "الرويس",
    "CITYNAME_EN": "Alruways",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16350",
    "CITYNAME_AR": "وادى الغيث",
    "CITYNAME_EN": "Wa'Adaa Alghayth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16351",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Alshaeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16352",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16353",
    "CITYNAME_AR": "عرف الشمالى والجنوبي",
    "CITYNAME_EN": "Eurif Alshamalaa Waljanubiu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16354",
    "CITYNAME_AR": "مهد عجلة",
    "CITYNAME_EN": "Mahid Eijla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16355",
    "CITYNAME_AR": "محجوبه",
    "CITYNAME_EN": "Mahjubuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16356",
    "CITYNAME_AR": "ريمه",
    "CITYNAME_EN": "Rymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16357",
    "CITYNAME_AR": "العانده",
    "CITYNAME_EN": "Aleaniduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16358",
    "CITYNAME_AR": "طلاح ونزة (الهنود)",
    "CITYNAME_EN": "Tulah Wanuza (Alahnud)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16359",
    "CITYNAME_AR": "القديمه",
    "CITYNAME_EN": "Alqadimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16360",
    "CITYNAME_AR": "الخيف",
    "CITYNAME_EN": "Alkhayf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16361",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Milh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16362",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dabae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16363",
    "CITYNAME_AR": "ابوحليفا الجنوبى",
    "CITYNAME_EN": "Abwhlyfa Aljunubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16364",
    "CITYNAME_AR": "ابوحليفا الشمالى",
    "CITYNAME_EN": "Abwhlyfa Alshamalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16365",
    "CITYNAME_AR": "عين ام الدار",
    "CITYNAME_EN": "Eayan Umm Aldaari",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16366",
    "CITYNAME_AR": "البديعة (الاسكان الخيري)",
    "CITYNAME_EN": "Albadiea (Alaskan Alkhayri)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16367",
    "CITYNAME_AR": "الصمد",
    "CITYNAME_EN": "Alsamad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16368",
    "CITYNAME_AR": "حلمه",
    "CITYNAME_EN": "Hulmih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16369",
    "CITYNAME_AR": "الفالح",
    "CITYNAME_EN": "Alfalih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16370",
    "CITYNAME_AR": "اسمح",
    "CITYNAME_EN": "Aismah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16371",
    "CITYNAME_AR": "الحفيره (حى السلام)",
    "CITYNAME_EN": "Alhafiruh (Ha Alsalama)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16372",
    "CITYNAME_AR": "الحلقه (الشعب)",
    "CITYNAME_EN": "Alhulquh (Alshueba)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16373",
    "CITYNAME_AR": "الكره",
    "CITYNAME_EN": "Alkaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16374",
    "CITYNAME_AR": "ماود",
    "CITYNAME_EN": "Mawid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16375",
    "CITYNAME_AR": "وادى حفره (البرزه)",
    "CITYNAME_EN": "Wa'Adaa Hafruh (Albarzaha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16376",
    "CITYNAME_AR": "المكسر",
    "CITYNAME_EN": "Almukasar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16377",
    "CITYNAME_AR": "المنجور",
    "CITYNAME_EN": "Almanjur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16378",
    "CITYNAME_AR": "ام الظبيان",
    "CITYNAME_EN": "Umm Alzabyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16379",
    "CITYNAME_AR": "سبلل",
    "CITYNAME_EN": "Sublul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16380",
    "CITYNAME_AR": "شعيب ابوقفلة",
    "CITYNAME_EN": "Shueayb Abwaqfala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16381",
    "CITYNAME_AR": "النخيل الادنى (ام الملح)",
    "CITYNAME_EN": "Alnakhil Al'Adnaa (Ama Almalah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16382",
    "CITYNAME_AR": "الوقب",
    "CITYNAME_EN": "Alwaqb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16383",
    "CITYNAME_AR": "البيضاء الشمالية",
    "CITYNAME_EN": "Albayda' Alshamalia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16384",
    "CITYNAME_AR": "البيضاء الغربية",
    "CITYNAME_EN": "Albayda' Algharbia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16385",
    "CITYNAME_AR": "مهايع",
    "CITYNAME_EN": "Mahayie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16386",
    "CITYNAME_AR": "ضبيعه",
    "CITYNAME_EN": "Dabieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16387",
    "CITYNAME_AR": "المشيريفة",
    "CITYNAME_EN": "Almushirifa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16388",
    "CITYNAME_AR": "الحصاة الراكبة",
    "CITYNAME_EN": "Alhasat Alrrakiba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16389",
    "CITYNAME_AR": "الجضع",
    "CITYNAME_EN": "Aljadae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16390",
    "CITYNAME_AR": "طيبان",
    "CITYNAME_EN": "Tayban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16391",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16392",
    "CITYNAME_AR": "حملق",
    "CITYNAME_EN": "Hamlaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16393",
    "CITYNAME_AR": "الأبيار",
    "CITYNAME_EN": "Al'Abyar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16394",
    "CITYNAME_AR": "المديد وطابا والغال",
    "CITYNAME_EN": "Almadid Wataba Walghal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16395",
    "CITYNAME_AR": "المعالى",
    "CITYNAME_EN": "Almaealaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16396",
    "CITYNAME_AR": "القصمة",
    "CITYNAME_EN": "Alqasima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16397",
    "CITYNAME_AR": "المريصيع",
    "CITYNAME_EN": "Almurisie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16398",
    "CITYNAME_AR": "ابوشهاب",
    "CITYNAME_EN": "Abwshhab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16399",
    "CITYNAME_AR": "الخمره والحديثيه",
    "CITYNAME_EN": "Alkhamruh Walhadithiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16400",
    "CITYNAME_AR": "الجليله",
    "CITYNAME_EN": "Aljaliluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16401",
    "CITYNAME_AR": "ام الخلاء",
    "CITYNAME_EN": "Umm Alkhala'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16402",
    "CITYNAME_AR": "صمد المتقشره",
    "CITYNAME_EN": "Samad Almutaqashirih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16403",
    "CITYNAME_AR": "المرقبه",
    "CITYNAME_EN": "Almuraqabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16404",
    "CITYNAME_AR": "ركك",
    "CITYNAME_EN": "Rkk",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16405",
    "CITYNAME_AR": "النهيميه",
    "CITYNAME_EN": "Alnahimih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16406",
    "CITYNAME_AR": "الراضم",
    "CITYNAME_EN": "Alradim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16407",
    "CITYNAME_AR": "بئر هاشم (حوره)",
    "CITYNAME_EN": "Biir Hashim (Hurh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16408",
    "CITYNAME_AR": "السهم",
    "CITYNAME_EN": "Alsahm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16409",
    "CITYNAME_AR": "الحفياء",
    "CITYNAME_EN": "Alhafya'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16410",
    "CITYNAME_AR": "المزيرعه",
    "CITYNAME_EN": "Al Mozera'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16411",
    "CITYNAME_AR": "الحماده",
    "CITYNAME_EN": "Al Hamadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16415",
    "CITYNAME_AR": "ظلم",
    "CITYNAME_EN": "Dholm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16416",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Al Hufayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16417",
    "CITYNAME_AR": "الدفينه",
    "CITYNAME_EN": "Ad-Dafayen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16418",
    "CITYNAME_AR": "عريده",
    "CITYNAME_EN": "Areeda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16419",
    "CITYNAME_AR": "العزيزيه (الرطابيه)",
    "CITYNAME_EN": "Al Azizyah (Ar-Rutabyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16420",
    "CITYNAME_AR": "الراغيه",
    "CITYNAME_EN": "Al Raghiya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16421",
    "CITYNAME_AR": "هجره المباريه",
    "CITYNAME_EN": "Hijrah Al Mubaryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16422",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Om As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16423",
    "CITYNAME_AR": "المجيوليه",
    "CITYNAME_EN": "Al Magulyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16424",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16425",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Al Rofaya'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16426",
    "CITYNAME_AR": "الرويليه (الصهلوج)",
    "CITYNAME_EN": "Ar-Ruwaylah (As-Sahluj)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16427",
    "CITYNAME_AR": "الناصرية",
    "CITYNAME_EN": "Al Naseriyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16428",
    "CITYNAME_AR": "العديد",
    "CITYNAME_EN": "Al Udayd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16429",
    "CITYNAME_AR": "رويلة الدلابحه",
    "CITYNAME_EN": "Ruwaylat Ad-Dalabehah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16430",
    "CITYNAME_AR": "رويلة العوازم",
    "CITYNAME_EN": "Ruwaylet Al Awazem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16431",
    "CITYNAME_AR": "الركنه",
    "CITYNAME_EN": "Ar-Raknah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16432",
    "CITYNAME_AR": "فيضة مغيراء",
    "CITYNAME_EN": "Faydaa Mughairaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16433",
    "CITYNAME_AR": "ريمان",
    "CITYNAME_EN": "Reman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16434",
    "CITYNAME_AR": "ابولبن",
    "CITYNAME_EN": "Abo Laban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16435",
    "CITYNAME_AR": "الزاهرة",
    "CITYNAME_EN": "Az-Zaherah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16436",
    "CITYNAME_AR": "محامة طامح",
    "CITYNAME_EN": "Mohamet Tameh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16437",
    "CITYNAME_AR": "مشرفة اللواء",
    "CITYNAME_EN": "Mushrefat Al Lewaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16438",
    "CITYNAME_AR": "المورقيه",
    "CITYNAME_EN": "Al Muriqyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16439",
    "CITYNAME_AR": "حيلان (بشر ابن نيمان)",
    "CITYNAME_EN": "Haylan (Beshr Ibn Nayman)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16440",
    "CITYNAME_AR": "النصائف",
    "CITYNAME_EN": "Al Nassaeyf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16441",
    "CITYNAME_AR": "ملحه",
    "CITYNAME_EN": "Maleha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16442",
    "CITYNAME_AR": "مدركه",
    "CITYNAME_EN": "Mudarikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16443",
    "CITYNAME_AR": "الخمره",
    "CITYNAME_EN": "Alkhamruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16444",
    "CITYNAME_AR": "ريع الشيخه",
    "CITYNAME_EN": "Raye Alshaykhih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16445",
    "CITYNAME_AR": "وعاير",
    "CITYNAME_EN": "Waeayir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16446",
    "CITYNAME_AR": "ام الضيمران",
    "CITYNAME_EN": "Umm Aldymran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16447",
    "CITYNAME_AR": "مهد الحيل",
    "CITYNAME_EN": "Mahd Alhil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16448",
    "CITYNAME_AR": "الركزه",
    "CITYNAME_EN": "Alrakazuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16449",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Aljirf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16450",
    "CITYNAME_AR": "السويس",
    "CITYNAME_EN": "Alsuways",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16451",
    "CITYNAME_AR": "المقيطع",
    "CITYNAME_EN": "Almuqaytae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16452",
    "CITYNAME_AR": "الزريب",
    "CITYNAME_EN": "Alzarib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16453",
    "CITYNAME_AR": "اوان",
    "CITYNAME_EN": "Awan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16454",
    "CITYNAME_AR": "ظبيه (حاره ام الثمام)",
    "CITYNAME_EN": "Zabih (Harih Umm Althamam)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16455",
    "CITYNAME_AR": "علق",
    "CITYNAME_EN": "Ealaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16456",
    "CITYNAME_AR": "انبع",
    "CITYNAME_EN": "Ainbie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16457",
    "CITYNAME_AR": "النويبع",
    "CITYNAME_EN": "Alnuwaybae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16458",
    "CITYNAME_AR": "الجيشى",
    "CITYNAME_EN": "Aljayshaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16459",
    "CITYNAME_AR": "مهد عاص",
    "CITYNAME_EN": "Mahd Eas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16460",
    "CITYNAME_AR": "القرين الابيض",
    "CITYNAME_EN": "Alqarin Al'Abyad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16461",
    "CITYNAME_AR": "ام البيضاء",
    "CITYNAME_EN": "Umm Albayda'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16462",
    "CITYNAME_AR": "ريع حماه (الشلاله)",
    "CITYNAME_EN": "Raye Hamah (Alshalaluh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16463",
    "CITYNAME_AR": "وادى مسيحه",
    "CITYNAME_EN": "Wa'Adaa Musihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16464",
    "CITYNAME_AR": "مزارع مهد الحمر",
    "CITYNAME_EN": "Farm of Mahd Alhumur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16465",
    "CITYNAME_AR": "وبير",
    "CITYNAME_EN": "Wabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16466",
    "CITYNAME_AR": "الفرس",
    "CITYNAME_EN": "Alfuras",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16467",
    "CITYNAME_AR": "الشعبه ( شعبه الخضراء)",
    "CITYNAME_EN": "Alshaebuh ( Shaebih Alkhadara'A)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16468",
    "CITYNAME_AR": "سمى",
    "CITYNAME_EN": "Sumaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16469",
    "CITYNAME_AR": "الاسكان الخيري",
    "CITYNAME_EN": "Al'Iiskan Alkhayriu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16470",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Almujamaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16471",
    "CITYNAME_AR": "المعلاه",
    "CITYNAME_EN": "Almealah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16472",
    "CITYNAME_AR": "الكبشيه",
    "CITYNAME_EN": "Alkabshiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16473",
    "CITYNAME_AR": "ام سريحه",
    "CITYNAME_EN": "Umm Sarihuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16474",
    "CITYNAME_AR": "ام سباع",
    "CITYNAME_EN": "Umm Sibae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16475",
    "CITYNAME_AR": "البجوره",
    "CITYNAME_EN": "Albujuruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16476",
    "CITYNAME_AR": "جيوش",
    "CITYNAME_EN": "Juyush",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16477",
    "CITYNAME_AR": "الدمين (ذراع الذيب)",
    "CITYNAME_EN": "Aldamin (Dhrae Aladhib)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16478",
    "CITYNAME_AR": "الوحدانى",
    "CITYNAME_EN": "Alwihdanaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16479",
    "CITYNAME_AR": "قاع جلال",
    "CITYNAME_EN": "Qae Jalal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16480",
    "CITYNAME_AR": "ابو شداد",
    "CITYNAME_EN": "'Abu Shidad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16481",
    "CITYNAME_AR": "الغزيره",
    "CITYNAME_EN": "Alghaziruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16482",
    "CITYNAME_AR": "عويجاء",
    "CITYNAME_EN": "Ewayja'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16483",
    "CITYNAME_AR": "الضمو",
    "CITYNAME_EN": "Aldamuw",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16484",
    "CITYNAME_AR": "قصير",
    "CITYNAME_EN": "Qasir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16485",
    "CITYNAME_AR": "القفيف",
    "CITYNAME_EN": "Alqafif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16486",
    "CITYNAME_AR": "المريره",
    "CITYNAME_EN": "Almuriruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16487",
    "CITYNAME_AR": "المره",
    "CITYNAME_EN": "Almarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16488",
    "CITYNAME_AR": "الشرج",
    "CITYNAME_EN": "Alsharaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16489",
    "CITYNAME_AR": "الجدر",
    "CITYNAME_EN": "Aljadar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16490",
    "CITYNAME_AR": "ام انبيعه",
    "CITYNAME_EN": "Umm Anbieah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16491",
    "CITYNAME_AR": "حلق",
    "CITYNAME_EN": "Halaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16492",
    "CITYNAME_AR": "حاويه",
    "CITYNAME_EN": "Hawih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16493",
    "CITYNAME_AR": "ام الدروب",
    "CITYNAME_EN": "Umm Aldurub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16494",
    "CITYNAME_AR": "ابوعشر (حارة الشليات)",
    "CITYNAME_EN": "Abwaeshar (Harat Alshulayat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16495",
    "CITYNAME_AR": "وادى الضريبه",
    "CITYNAME_EN": "Wa'Adaa Aldaribuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16496",
    "CITYNAME_AR": "ام الجعارير",
    "CITYNAME_EN": "Umm Aljaearir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16497",
    "CITYNAME_AR": "السهب",
    "CITYNAME_EN": "Alsahb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16498",
    "CITYNAME_AR": "القعضبه",
    "CITYNAME_EN": "Alqaedabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16499",
    "CITYNAME_AR": "ابوجعيره",
    "CITYNAME_EN": "Abwjeyrh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16500",
    "CITYNAME_AR": "الساعد",
    "CITYNAME_EN": "Alssaeid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16501",
    "CITYNAME_AR": "كرس",
    "CITYNAME_EN": "Karas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16502",
    "CITYNAME_AR": "غمسيل",
    "CITYNAME_EN": "Ghamsil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16503",
    "CITYNAME_AR": "الفيضه",
    "CITYNAME_EN": "Alfayduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16504",
    "CITYNAME_AR": "ذوى ساعد",
    "CITYNAME_EN": "Dhawaa Saeid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16505",
    "CITYNAME_AR": "ذوى مطيع",
    "CITYNAME_EN": "Dhuana Matie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16506",
    "CITYNAME_AR": "البديعات",
    "CITYNAME_EN": "Albadieat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16507",
    "CITYNAME_AR": "انخل (ميقات ذات عرق)",
    "CITYNAME_EN": "'Ankhil (Myiqat Dhat Eurqan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16508",
    "CITYNAME_AR": "ابورخم",
    "CITYNAME_EN": "Abwrkhm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16509",
    "CITYNAME_AR": "السعدية",
    "CITYNAME_EN": "Alsaedia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16510",
    "CITYNAME_AR": "العواضية",
    "CITYNAME_EN": "Aleawadia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16511",
    "CITYNAME_AR": "غيثاء",
    "CITYNAME_EN": "Ghytha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16512",
    "CITYNAME_AR": "الاصحر",
    "CITYNAME_EN": "Alashr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16513",
    "CITYNAME_AR": "الرانه",
    "CITYNAME_EN": "Alraanih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16514",
    "CITYNAME_AR": "الفرد",
    "CITYNAME_EN": "Alfard",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16515",
    "CITYNAME_AR": "الجموم",
    "CITYNAME_EN": "Aljumum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16516",
    "CITYNAME_AR": "ابوشعيب",
    "CITYNAME_EN": "Abwsheib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16517",
    "CITYNAME_AR": "نزله القرشان",
    "CITYNAME_EN": "Nazalah Alqurshan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16518",
    "CITYNAME_AR": "الفيض (الشاروق)",
    "CITYNAME_EN": "Alfayd (Alsharuq)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16519",
    "CITYNAME_AR": "نزله البشور بقرن سرور",
    "CITYNAME_EN": "Nazalah Albushur Biqarn Surur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16520",
    "CITYNAME_AR": "دف زينى",
    "CITYNAME_EN": "Daf Zaynaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16521",
    "CITYNAME_AR": "دف خزاعه",
    "CITYNAME_EN": "Daf Khizaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16522",
    "CITYNAME_AR": "الدوح الكبير",
    "CITYNAME_EN": "Aldawh Alkabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16523",
    "CITYNAME_AR": "مكسرالفج الكريمي",
    "CITYNAME_EN": "Mkasralfij Alkarimi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16524",
    "CITYNAME_AR": "الدوح الصغير",
    "CITYNAME_EN": "Aldawh Alsaghir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16525",
    "CITYNAME_AR": "النزهه",
    "CITYNAME_EN": "Alnazhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16526",
    "CITYNAME_AR": "البرابر",
    "CITYNAME_EN": "Albarabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16527",
    "CITYNAME_AR": "الحميمه",
    "CITYNAME_EN": "Alhamimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16528",
    "CITYNAME_AR": "النويدره",
    "CITYNAME_EN": "Alnuwydruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16529",
    "CITYNAME_AR": "صمد الحميمه",
    "CITYNAME_EN": "Samid Alhamimh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16530",
    "CITYNAME_AR": "صروعه",
    "CITYNAME_EN": "Surueuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16531",
    "CITYNAME_AR": "قريه جبل الكر",
    "CITYNAME_EN": "Qarih Jabal Alkur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16532",
    "CITYNAME_AR": "المرشديه",
    "CITYNAME_EN": "Almurshidih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16533",
    "CITYNAME_AR": "غزيات",
    "CITYNAME_EN": "Ghaziat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16534",
    "CITYNAME_AR": "عين الحسينية",
    "CITYNAME_EN": "Eayan Alhusaynia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16535",
    "CITYNAME_AR": "البثنى",
    "CITYNAME_EN": "Albuthnaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16536",
    "CITYNAME_AR": "الخيف",
    "CITYNAME_EN": "Alkhayf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16537",
    "CITYNAME_AR": "عين الخيف",
    "CITYNAME_EN": "Eayan Alkhayf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16538",
    "CITYNAME_AR": "ابوالحصاني",
    "CITYNAME_EN": "Abwalhsani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16539",
    "CITYNAME_AR": "ريع الخلص",
    "CITYNAME_EN": "Raye Alkhalas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16540",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Aldaras",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16541",
    "CITYNAME_AR": "نزله البشور بالفيض",
    "CITYNAME_EN": "Nazalah Albushur Bialfid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16542",
    "CITYNAME_AR": "عين البريكه (الكدوه)",
    "CITYNAME_EN": "Eayan Albarikuh (Alkuduh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16543",
    "CITYNAME_AR": "ابونقاره",
    "CITYNAME_EN": "Abwnqarh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16544",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16545",
    "CITYNAME_AR": "ابوقرفه",
    "CITYNAME_EN": "Abwaqrfh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16546",
    "CITYNAME_AR": "الرديمه",
    "CITYNAME_EN": "Alradimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16547",
    "CITYNAME_AR": "النزهه",
    "CITYNAME_EN": "Alnazhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16548",
    "CITYNAME_AR": "الحجر",
    "CITYNAME_EN": "Alhajar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16549",
    "CITYNAME_AR": "خسلف",
    "CITYNAME_EN": "Khasalaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16550",
    "CITYNAME_AR": "المخطط الجديد",
    "CITYNAME_EN": "Almukhatat Aljadid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16551",
    "CITYNAME_AR": "الدوح",
    "CITYNAME_EN": "Aldawh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16552",
    "CITYNAME_AR": "العوشز",
    "CITYNAME_EN": "Aleawshiz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16553",
    "CITYNAME_AR": "اللبه",
    "CITYNAME_EN": "Allabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16554",
    "CITYNAME_AR": "صمد بديرى",
    "CITYNAME_EN": "Samad Bdira",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16555",
    "CITYNAME_AR": "الدور",
    "CITYNAME_EN": "Aldawr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16556",
    "CITYNAME_AR": "ام هشيم",
    "CITYNAME_EN": "Umm Hashim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16557",
    "CITYNAME_AR": "العصب",
    "CITYNAME_EN": "Aleasb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16558",
    "CITYNAME_AR": "الفواره (بني مسعود)",
    "CITYNAME_EN": "Alfawaruh (Bni Masaeudan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16559",
    "CITYNAME_AR": "وادى قينه",
    "CITYNAME_EN": "Wa'Adaa Qinah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16560",
    "CITYNAME_AR": "وادى حوره",
    "CITYNAME_EN": "Wa'Adaa Hawruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16561",
    "CITYNAME_AR": "وادى ضرعا",
    "CITYNAME_EN": "Wa'Adaa Dareana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16562",
    "CITYNAME_AR": "وادى اللصيبه",
    "CITYNAME_EN": "Wa'Adaa Allasibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16563",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Alharjuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16564",
    "CITYNAME_AR": "عين شمس",
    "CITYNAME_EN": "Eayan Shams",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16565",
    "CITYNAME_AR": "المندسه",
    "CITYNAME_EN": "Almandisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16566",
    "CITYNAME_AR": "الصمد الشمالى",
    "CITYNAME_EN": "Alsamad Alshamalaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16567",
    "CITYNAME_AR": "ام فراعه (حى النزهه)",
    "CITYNAME_EN": "Umm Faraeuh (Ha Alnazahah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16568",
    "CITYNAME_AR": "ريع القراش",
    "CITYNAME_EN": "Raye Alqarash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16569",
    "CITYNAME_AR": "الريان",
    "CITYNAME_EN": "Alrayan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16570",
    "CITYNAME_AR": "ملح والدومه",
    "CITYNAME_EN": "Milh Walduwmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16571",
    "CITYNAME_AR": "المبارك",
    "CITYNAME_EN": "Almubarak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16572",
    "CITYNAME_AR": "وادى ضرعا",
    "CITYNAME_EN": "Wa'Adaa Dareana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16573",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Alruwayduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16574",
    "CITYNAME_AR": "الحبيقى",
    "CITYNAME_EN": "Alhabiqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16575",
    "CITYNAME_AR": "العمقاء",
    "CITYNAME_EN": "Aleumqa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16576",
    "CITYNAME_AR": "الصقره (الصقار)",
    "CITYNAME_EN": "Alsaqruh (Alsqara)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16577",
    "CITYNAME_AR": "الصدير",
    "CITYNAME_EN": "Alsadir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16578",
    "CITYNAME_AR": "الطرفاء",
    "CITYNAME_EN": "Alturafa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16579",
    "CITYNAME_AR": "مصيبخه",
    "CITYNAME_EN": "Musibikhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16580",
    "CITYNAME_AR": "اللهبه",
    "CITYNAME_EN": "Al Lahbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16581",
    "CITYNAME_AR": "الحاير",
    "CITYNAME_EN": "Alhayir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16582",
    "CITYNAME_AR": "حلفاء",
    "CITYNAME_EN": "Hulafa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16583",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Milh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16584",
    "CITYNAME_AR": "البثنه",
    "CITYNAME_EN": "Albathnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16585",
    "CITYNAME_AR": "بئر عبيد النعماني",
    "CITYNAME_EN": "Bayir Eubayd Alnuemani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16586",
    "CITYNAME_AR": "بئر حامد بن حاسن",
    "CITYNAME_EN": "Bayir Hamid Bin Hasin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16587",
    "CITYNAME_AR": "بئر حماد مرزوق",
    "CITYNAME_EN": "Bayir Hammad Marzuq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16588",
    "CITYNAME_AR": "الوجراء (العبوقره)",
    "CITYNAME_EN": "Alwujara' (Alebuqurh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16589",
    "CITYNAME_AR": "عصير وام الرويكه",
    "CITYNAME_EN": "Easir Wa'Um Alrwykh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16590",
    "CITYNAME_AR": "بئر مبيريك والعاصد",
    "CITYNAME_EN": "Bir Mubirik Waleasid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16591",
    "CITYNAME_AR": "بئر عبدالمعطى",
    "CITYNAME_EN": "Bayir Ebdalmeta",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16592",
    "CITYNAME_AR": "بئر الجمعيه الخيريه",
    "CITYNAME_EN": "Biir Aljameiuh Alkhayrih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16593",
    "CITYNAME_AR": "بئر السديره",
    "CITYNAME_EN": "As-Sudayrah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16594",
    "CITYNAME_AR": "رابغ",
    "CITYNAME_EN": "Rabigh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16595",
    "CITYNAME_AR": "نخل ابوسحيم",
    "CITYNAME_EN": "Nakhl Abwashim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16596",
    "CITYNAME_AR": "بئر برما (القرصه)",
    "CITYNAME_EN": "Biir Burumaa (Alqarusuha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16597",
    "CITYNAME_AR": "الجحفه",
    "CITYNAME_EN": "Aljahfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16598",
    "CITYNAME_AR": "سليته والشاميات",
    "CITYNAME_EN": "Siliyatuh Walshaamiat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16599",
    "CITYNAME_AR": "مستوره",
    "CITYNAME_EN": "Mustawrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16600",
    "CITYNAME_AR": "الشاقه",
    "CITYNAME_EN": "Alshaaqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16601",
    "CITYNAME_AR": "مزرعه مضحى",
    "CITYNAME_EN": "Farm of Madhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16602",
    "CITYNAME_AR": "مزرعه حسين",
    "CITYNAME_EN": "Farm of Husayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16603",
    "CITYNAME_AR": "بئر الراكه",
    "CITYNAME_EN": "Biir Alraakuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16604",
    "CITYNAME_AR": "بئر الجاده",
    "CITYNAME_EN": "Biir Aljaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16605",
    "CITYNAME_AR": "الطريفه",
    "CITYNAME_EN": "Altarifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16606",
    "CITYNAME_AR": "النويبع",
    "CITYNAME_EN": "Alnuwaybae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16607",
    "CITYNAME_AR": "وادى تمايا",
    "CITYNAME_EN": "Wa'Adaa Tamayana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16608",
    "CITYNAME_AR": "اللخيب",
    "CITYNAME_EN": "Allakhayb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16609",
    "CITYNAME_AR": "وادى حيا",
    "CITYNAME_EN": "Wa'Adaa Hayana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16610",
    "CITYNAME_AR": "بئر بخيت",
    "CITYNAME_EN": "Biir Bakhit",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16611",
    "CITYNAME_AR": "بئر مطر",
    "CITYNAME_EN": "Biir Mtr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16612",
    "CITYNAME_AR": "بئر عوده",
    "CITYNAME_EN": "Bayir Eawdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16613",
    "CITYNAME_AR": "بئر المعطا",
    "CITYNAME_EN": "Biir Almaeta",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16614",
    "CITYNAME_AR": "بئر الصحه",
    "CITYNAME_EN": "Biir Alsahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16615",
    "CITYNAME_AR": "قريه الخزان",
    "CITYNAME_EN": "Quriah Alkhazan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16616",
    "CITYNAME_AR": "الطويحات",
    "CITYNAME_EN": "Altuwayhat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16617",
    "CITYNAME_AR": "المزيرع والبحيرة",
    "CITYNAME_EN": "Almuzyarae Walbuhira",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16618",
    "CITYNAME_AR": "الصمد والخوالدة وابو دويمه",
    "CITYNAME_EN": "Alsamad Walkhawalidat Wa'Abu Dwymh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16619",
    "CITYNAME_AR": "الحكاك",
    "CITYNAME_EN": "Alhukkak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16620",
    "CITYNAME_AR": "العقله",
    "CITYNAME_EN": "Aleaqluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16621",
    "CITYNAME_AR": "المنحر",
    "CITYNAME_EN": "Almunahar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16622",
    "CITYNAME_AR": "وادى الرحبه",
    "CITYNAME_EN": "Wa'Adaa Alrahbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16623",
    "CITYNAME_AR": "السواريه",
    "CITYNAME_EN": "Alsawariuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16624",
    "CITYNAME_AR": "الخوالده",
    "CITYNAME_EN": "Alkhawaliduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16625",
    "CITYNAME_AR": "بئر العسيله",
    "CITYNAME_EN": "Biir Aleasiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16626",
    "CITYNAME_AR": "بئر المعرج",
    "CITYNAME_EN": "Biir Almaerij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16627",
    "CITYNAME_AR": "الشراء",
    "CITYNAME_EN": "Alshira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16628",
    "CITYNAME_AR": "وادى ندا",
    "CITYNAME_EN": "Wa'Adaa Nada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16629",
    "CITYNAME_AR": "صعبر السفران الشرقيه والغربيه",
    "CITYNAME_EN": "Saebur Alsufran Alsharaqiuh Walgharbiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16630",
    "CITYNAME_AR": "مدينة الملك عبدالله الإقتصاديه",
    "CITYNAME_EN": "King Abdullah Economic City",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16631",
    "CITYNAME_AR": "القضيمه",
    "CITYNAME_EN": "Alqadimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16632",
    "CITYNAME_AR": "كليه",
    "CITYNAME_EN": "Klih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16633",
    "CITYNAME_AR": "الحصينيه",
    "CITYNAME_EN": "Alhasinayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16634",
    "CITYNAME_AR": "قربة",
    "CITYNAME_EN": "Qurba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16635",
    "CITYNAME_AR": "التنضبيه",
    "CITYNAME_EN": "Altandibiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16636",
    "CITYNAME_AR": "ام عثمان",
    "CITYNAME_EN": "Umm Euthman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16637",
    "CITYNAME_AR": "المصفره",
    "CITYNAME_EN": "Almusafiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16638",
    "CITYNAME_AR": "النزله",
    "CITYNAME_EN": "Alnazuluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16639",
    "CITYNAME_AR": "نزله الامير (السهياء)",
    "CITYNAME_EN": "Nazalah Al'Amir (Alsahya'An)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16640",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Albahruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16641",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16642",
    "CITYNAME_AR": "ملقى الحطاحطه",
    "CITYNAME_EN": "Malqaa Alhatahatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16643",
    "CITYNAME_AR": "ام السدر (الفارع)",
    "CITYNAME_EN": "Umm Alsadar (Alfare)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16644",
    "CITYNAME_AR": "ام الحب",
    "CITYNAME_EN": "Umm Alhabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16645",
    "CITYNAME_AR": "خوانه وخويونيه",
    "CITYNAME_EN": "Khawanuh Wakhawyunih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16646",
    "CITYNAME_AR": "شعيب الجارالله (النجبه)",
    "CITYNAME_EN": "Shueayb Aljaralilih (Alnujbah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16647",
    "CITYNAME_AR": "أم الطين والمعلقه",
    "CITYNAME_EN": "Umm Altiyn Walmuealaquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16648",
    "CITYNAME_AR": "المطاريق",
    "CITYNAME_EN": "Almatariq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16649",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Umm Alsilm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16650",
    "CITYNAME_AR": "المرتج",
    "CITYNAME_EN": "Almartaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16651",
    "CITYNAME_AR": "السير",
    "CITYNAME_EN": "Alsayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16652",
    "CITYNAME_AR": "قبيله",
    "CITYNAME_EN": "Qubilah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16653",
    "CITYNAME_AR": "عوصه",
    "CITYNAME_EN": "Eawsah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16654",
    "CITYNAME_AR": "مغينيه",
    "CITYNAME_EN": "Mughinih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16655",
    "CITYNAME_AR": "القنفذه",
    "CITYNAME_EN": "Alqunafdhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16656",
    "CITYNAME_AR": "الغزويه الجديدة",
    "CITYNAME_EN": "Alghazawiuh Aljadida",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16657",
    "CITYNAME_AR": "قريعه",
    "CITYNAME_EN": "Qarieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16658",
    "CITYNAME_AR": "ام القحمه",
    "CITYNAME_EN": "Umm Alqahmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16659",
    "CITYNAME_AR": "عاجه",
    "CITYNAME_EN": "Eajah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16660",
    "CITYNAME_AR": "المتاحمه",
    "CITYNAME_EN": "Almatahimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16661",
    "CITYNAME_AR": "الغوانمه",
    "CITYNAME_EN": "Alghawanimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16662",
    "CITYNAME_AR": "البيشيه",
    "CITYNAME_EN": "Albayshih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16663",
    "CITYNAME_AR": "العقده (السماريه)",
    "CITYNAME_EN": "Aleaqduh (Alsamarih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16664",
    "CITYNAME_AR": "الزبير",
    "CITYNAME_EN": "Alzabir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16665",
    "CITYNAME_AR": "الزعاتره",
    "CITYNAME_EN": "Alzaeatiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16666",
    "CITYNAME_AR": "الدعارمه",
    "CITYNAME_EN": "Aldaearumuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16667",
    "CITYNAME_AR": "احد بنى زيد",
    "CITYNAME_EN": "Ahd Banaa Zayd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16668",
    "CITYNAME_AR": "الرده",
    "CITYNAME_EN": "Alraduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16669",
    "CITYNAME_AR": "الجلمه",
    "CITYNAME_EN": "Aljulmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16670",
    "CITYNAME_AR": "القاع الاعلى (الجديد)",
    "CITYNAME_EN": "Alqae Al'Aelaa (Aljadid)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16671",
    "CITYNAME_AR": "القاع الاوسط",
    "CITYNAME_EN": "Alqae Al'Awsat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16672",
    "CITYNAME_AR": "الصالحي",
    "CITYNAME_EN": "Alsaalihiu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16673",
    "CITYNAME_AR": "القاع الاسفل",
    "CITYNAME_EN": "Alqae Al'Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16674",
    "CITYNAME_AR": "الدعدى",
    "CITYNAME_EN": "Aldaedaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16675",
    "CITYNAME_AR": "وادى هارون",
    "CITYNAME_EN": "Wa'Adaa Harun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16676",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Alqiearuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16677",
    "CITYNAME_AR": "ال شداد",
    "CITYNAME_EN": "Al Shidad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16678",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "Alsalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16679",
    "CITYNAME_AR": "سيال",
    "CITYNAME_EN": "Sayal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16680",
    "CITYNAME_AR": "الراححيه",
    "CITYNAME_EN": "Alraahihih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16681",
    "CITYNAME_AR": "الجحف",
    "CITYNAME_EN": "Aljahf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16682",
    "CITYNAME_AR": "شينانـة",
    "CITYNAME_EN": "Shaynan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16683",
    "CITYNAME_AR": "ال زياد",
    "CITYNAME_EN": "Al Ziad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16684",
    "CITYNAME_AR": "قوز باربود",
    "CITYNAME_EN": "Qawz Barbud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16685",
    "CITYNAME_AR": "عيينه",
    "CITYNAME_EN": "Eiynh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16686",
    "CITYNAME_AR": "قوز البص",
    "CITYNAME_EN": "Quz Albus",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16687",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16688",
    "CITYNAME_AR": "شعب الذيب",
    "CITYNAME_EN": "Shaeb Aldhiyb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16689",
    "CITYNAME_AR": "شعب الضان",
    "CITYNAME_EN": "Shaeb Aldaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16690",
    "CITYNAME_AR": "شعب البيض",
    "CITYNAME_EN": "Shaeb Albyd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16691",
    "CITYNAME_AR": "الصخيره",
    "CITYNAME_EN": "Alsakhiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16692",
    "CITYNAME_AR": "دار المشاييخ",
    "CITYNAME_EN": "Dar Almashayikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16693",
    "CITYNAME_AR": "قرن الزلاله",
    "CITYNAME_EN": "Qarn Alzilaluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16694",
    "CITYNAME_AR": "شعب سامر",
    "CITYNAME_EN": "Shaeb Samir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16695",
    "CITYNAME_AR": "العلوب",
    "CITYNAME_EN": "Aleulub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16696",
    "CITYNAME_AR": "عمرات",
    "CITYNAME_EN": "Eamarat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16697",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Alfarshuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16698",
    "CITYNAME_AR": "العماير",
    "CITYNAME_EN": "Aleamayir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16699",
    "CITYNAME_AR": "الدراعين",
    "CITYNAME_EN": "Aldiraein",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16700",
    "CITYNAME_AR": "بئر زهراء",
    "CITYNAME_EN": "Byr Zahara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16701",
    "CITYNAME_AR": "الصرح",
    "CITYNAME_EN": "Alsarh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16702",
    "CITYNAME_AR": "الجوابره",
    "CITYNAME_EN": "Aljawabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16703",
    "CITYNAME_AR": "دار العبيد",
    "CITYNAME_EN": "Dar Aleabid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16704",
    "CITYNAME_AR": "البطاطيش",
    "CITYNAME_EN": "Albatatish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16705",
    "CITYNAME_AR": "الصالحى الجديد",
    "CITYNAME_EN": "Alssalihaa Aljadid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16706",
    "CITYNAME_AR": "العشم",
    "CITYNAME_EN": "Aleshm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16707",
    "CITYNAME_AR": "صنق",
    "CITYNAME_EN": "Sanq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16708",
    "CITYNAME_AR": "الظاهر",
    "CITYNAME_EN": "Alzzahir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16709",
    "CITYNAME_AR": "الصالحى القديم",
    "CITYNAME_EN": "Alsaalihaa Alqadim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16710",
    "CITYNAME_AR": "الهبيره",
    "CITYNAME_EN": "Alhabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16711",
    "CITYNAME_AR": "الثايه",
    "CITYNAME_EN": "Alththayih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16712",
    "CITYNAME_AR": "العريق",
    "CITYNAME_EN": "Aleariq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16713",
    "CITYNAME_AR": "الرجبان",
    "CITYNAME_EN": "Alrajban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16714",
    "CITYNAME_AR": "دار ربيعه (ام الجرم)",
    "CITYNAME_EN": "Dar Rabieuh (Ama Aljaram)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16715",
    "CITYNAME_AR": "الصفاصيف",
    "CITYNAME_EN": "Alsafasif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16716",
    "CITYNAME_AR": "العموديه",
    "CITYNAME_EN": "Aleamudiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16717",
    "CITYNAME_AR": "الثعالبه",
    "CITYNAME_EN": "Althaealibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16718",
    "CITYNAME_AR": "رحمان",
    "CITYNAME_EN": "Rahman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16719",
    "CITYNAME_AR": "الهنود",
    "CITYNAME_EN": "Alhnwd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16720",
    "CITYNAME_AR": "المطاريح",
    "CITYNAME_EN": "Almatarih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16721",
    "CITYNAME_AR": "البرمه",
    "CITYNAME_EN": "Albarmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16722",
    "CITYNAME_AR": "يلملم",
    "CITYNAME_EN": "Yulamlim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16723",
    "CITYNAME_AR": "الحشفه",
    "CITYNAME_EN": "Alhashfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16724",
    "CITYNAME_AR": "ضهياة",
    "CITYNAME_EN": "Dahia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16725",
    "CITYNAME_AR": "الجيز",
    "CITYNAME_EN": "Aljiz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16726",
    "CITYNAME_AR": "قما",
    "CITYNAME_EN": "Qama",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16727",
    "CITYNAME_AR": "ثرب",
    "CITYNAME_EN": "Tharab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16728",
    "CITYNAME_AR": "الشعيراء",
    "CITYNAME_EN": "Alshaeira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16729",
    "CITYNAME_AR": "وادي وديان",
    "CITYNAME_EN": "Wadi Wadiaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16730",
    "CITYNAME_AR": "الغميم",
    "CITYNAME_EN": "Alghamim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16731",
    "CITYNAME_AR": "الكر",
    "CITYNAME_EN": "Alkuru",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16732",
    "CITYNAME_AR": "ودف",
    "CITYNAME_EN": "Wadaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16733",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Allahyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16734",
    "CITYNAME_AR": "الاكتان",
    "CITYNAME_EN": "Alaktan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16735",
    "CITYNAME_AR": "المرة",
    "CITYNAME_EN": "Almara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16736",
    "CITYNAME_AR": "الغزلانيه",
    "CITYNAME_EN": "Alghazalaniah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16737",
    "CITYNAME_AR": "الغضاب",
    "CITYNAME_EN": "Alghidab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16738",
    "CITYNAME_AR": "الازحاف",
    "CITYNAME_EN": "Alaizhaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16739",
    "CITYNAME_AR": "قرنه",
    "CITYNAME_EN": "Qaranah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16740",
    "CITYNAME_AR": "جليل",
    "CITYNAME_EN": "Jalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16741",
    "CITYNAME_AR": "ابوان",
    "CITYNAME_EN": "Abwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16742",
    "CITYNAME_AR": "ابو غواشي",
    "CITYNAME_EN": "'Abu Ghwashy",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16743",
    "CITYNAME_AR": "قواق",
    "CITYNAME_EN": "Qawaaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16744",
    "CITYNAME_AR": "الحسنان",
    "CITYNAME_EN": "Alhusnan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16745",
    "CITYNAME_AR": "المجيرمه",
    "CITYNAME_EN": "Almujiramuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16746",
    "CITYNAME_AR": "وادى مركوب",
    "CITYNAME_EN": "Wa'Adaa Markub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16747",
    "CITYNAME_AR": "ام قضب",
    "CITYNAME_EN": "Umm Qadab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16748",
    "CITYNAME_AR": "السعديه",
    "CITYNAME_EN": "Alsaediuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16749",
    "CITYNAME_AR": "المقيرات",
    "CITYNAME_EN": "Almaqirat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16750",
    "CITYNAME_AR": "المهثل",
    "CITYNAME_EN": "Almuhathal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16751",
    "CITYNAME_AR": "ذي يغرب",
    "CITYNAME_EN": "Dhi Yaghrib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16752",
    "CITYNAME_AR": "المقهوي",
    "CITYNAME_EN": "Almaqahawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16753",
    "CITYNAME_AR": "الحبل",
    "CITYNAME_EN": "Alhabal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16754",
    "CITYNAME_AR": "الجادة",
    "CITYNAME_EN": "Aljada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16755",
    "CITYNAME_AR": "الجحافى",
    "CITYNAME_EN": "Aljhafa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16756",
    "CITYNAME_AR": "الطينه",
    "CITYNAME_EN": "Altiynuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16757",
    "CITYNAME_AR": "طفيل",
    "CITYNAME_EN": "Tafil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16758",
    "CITYNAME_AR": "الحرش",
    "CITYNAME_EN": "Alharsh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16759",
    "CITYNAME_AR": "وادي الاخضر",
    "CITYNAME_EN": "Wadi Al'Akhdar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16760",
    "CITYNAME_AR": "المسمور",
    "CITYNAME_EN": "Almasmur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16761",
    "CITYNAME_AR": "الحدثه",
    "CITYNAME_EN": "Alhadathuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16762",
    "CITYNAME_AR": "الشقان",
    "CITYNAME_EN": "Alshuqqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16763",
    "CITYNAME_AR": "الحفره",
    "CITYNAME_EN": "Alhafruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16764",
    "CITYNAME_AR": "الشبيره",
    "CITYNAME_EN": "Alshabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16765",
    "CITYNAME_AR": "الصحن",
    "CITYNAME_EN": "Alsahn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16766",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Alhabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16767",
    "CITYNAME_AR": "عواهه",
    "CITYNAME_EN": "Eawahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16768",
    "CITYNAME_AR": "اثال",
    "CITYNAME_EN": "Athal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16769",
    "CITYNAME_AR": "الميثا",
    "CITYNAME_EN": "Almitha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16770",
    "CITYNAME_AR": "المهد",
    "CITYNAME_EN": "Almahd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16771",
    "CITYNAME_AR": "نجل",
    "CITYNAME_EN": "Najl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16772",
    "CITYNAME_AR": "اكديه",
    "CITYNAME_EN": "Akdyh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16773",
    "CITYNAME_AR": "الميقات",
    "CITYNAME_EN": "Almiqat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16774",
    "CITYNAME_AR": "ام زراب",
    "CITYNAME_EN": "Umm Zarab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16775",
    "CITYNAME_AR": "المرزز",
    "CITYNAME_EN": "Almarzaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16776",
    "CITYNAME_AR": "قري البوصي",
    "CITYNAME_EN": "Qari Albusi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16777",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Alfarshuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16778",
    "CITYNAME_AR": "ام سديره",
    "CITYNAME_EN": "Umm Sadiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16779",
    "CITYNAME_AR": "الحنضليه",
    "CITYNAME_EN": "Alhndiliyh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16780",
    "CITYNAME_AR": "الجرول",
    "CITYNAME_EN": "Aljurul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16781",
    "CITYNAME_AR": "عذبه",
    "CITYNAME_EN": "Eadhabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16782",
    "CITYNAME_AR": "الحميديه",
    "CITYNAME_EN": "Alhamidiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16783",
    "CITYNAME_AR": "الصهيه",
    "CITYNAME_EN": "Alsahih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16784",
    "CITYNAME_AR": "حميم",
    "CITYNAME_EN": "Hamim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16785",
    "CITYNAME_AR": "الخبيت",
    "CITYNAME_EN": "Al Khubayet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16786",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Ash-Sharfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16787",
    "CITYNAME_AR": "المريخه",
    "CITYNAME_EN": "Al Marekhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16788",
    "CITYNAME_AR": "خلاب",
    "CITYNAME_EN": "Khallab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16789",
    "CITYNAME_AR": "ادام",
    "CITYNAME_EN": "Adam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16790",
    "CITYNAME_AR": "وادي الفرط",
    "CITYNAME_EN": "Wadi Al Fart",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16791",
    "CITYNAME_AR": "الليث",
    "CITYNAME_EN": "Alliyth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16792",
    "CITYNAME_AR": "فريجه",
    "CITYNAME_EN": "Farijuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16793",
    "CITYNAME_AR": "سبخة فريحة",
    "CITYNAME_EN": "Sbkht Fariha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16794",
    "CITYNAME_AR": "سكرانه",
    "CITYNAME_EN": "Sukaranuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16795",
    "CITYNAME_AR": "البلهاء",
    "CITYNAME_EN": "Albalha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16796",
    "CITYNAME_AR": "سكره",
    "CITYNAME_EN": "Sakarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16797",
    "CITYNAME_AR": "المدرج",
    "CITYNAME_EN": "Almudarij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16798",
    "CITYNAME_AR": "الصميد",
    "CITYNAME_EN": "Alsamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16799",
    "CITYNAME_AR": "خريق الحسن",
    "CITYNAME_EN": "Khariq Alhasan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16800",
    "CITYNAME_AR": "المسمى",
    "CITYNAME_EN": "Almusamaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16801",
    "CITYNAME_AR": "كبه",
    "CITYNAME_EN": "Kabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16802",
    "CITYNAME_AR": "الخرقان",
    "CITYNAME_EN": "Alkhurqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16803",
    "CITYNAME_AR": "ناعم",
    "CITYNAME_EN": "Naem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16804",
    "CITYNAME_AR": "بزي",
    "CITYNAME_EN": "Bizii",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16805",
    "CITYNAME_AR": "جبهان (جبل)",
    "CITYNAME_EN": "Jabhan (Jbl)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16806",
    "CITYNAME_AR": "فرعه",
    "CITYNAME_EN": "Fareah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16807",
    "CITYNAME_AR": "الحجون",
    "CITYNAME_EN": "Alhujuwn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16808",
    "CITYNAME_AR": "التخبرة",
    "CITYNAME_EN": "Altakhbira",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16809",
    "CITYNAME_AR": "حباق",
    "CITYNAME_EN": "Hibaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16810",
    "CITYNAME_AR": "العليقه",
    "CITYNAME_EN": "Alealiquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16811",
    "CITYNAME_AR": "ضحى",
    "CITYNAME_EN": "Duhana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16812",
    "CITYNAME_AR": "قرية وادى نيات",
    "CITYNAME_EN": "Qaryat Wa'Adaa Niat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16813",
    "CITYNAME_AR": "كحله",
    "CITYNAME_EN": "Kahalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16814",
    "CITYNAME_AR": "السطح (المصيدة)",
    "CITYNAME_EN": "Alsath (Almusidat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16815",
    "CITYNAME_AR": "زريعه (الحيط)",
    "CITYNAME_EN": "Zarieuh (Alahyat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16816",
    "CITYNAME_AR": "الخلصه",
    "CITYNAME_EN": "Alkhalasuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16817",
    "CITYNAME_AR": "المضيق",
    "CITYNAME_EN": "Almudiq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16818",
    "CITYNAME_AR": "الغاله",
    "CITYNAME_EN": "Alghaluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16819",
    "CITYNAME_AR": "الغويله",
    "CITYNAME_EN": "Alghuayluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16820",
    "CITYNAME_AR": "نزله بئر وارد (عيثان)",
    "CITYNAME_EN": "Nazalah Biir Warid (Eithana)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16821",
    "CITYNAME_AR": "البطانة",
    "CITYNAME_EN": "Albitana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16822",
    "CITYNAME_AR": "حظير",
    "CITYNAME_EN": "Hazir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16823",
    "CITYNAME_AR": "حضر",
    "CITYNAME_EN": "Hadar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16824",
    "CITYNAME_AR": "الفوقاء",
    "CITYNAME_EN": "Alfawaqa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16825",
    "CITYNAME_AR": "الحفر",
    "CITYNAME_EN": "Al Hufr",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "16826",
    "CITYNAME_AR": "صهوه",
    "CITYNAME_EN": "Sahuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16827",
    "CITYNAME_AR": "شبيل",
    "CITYNAME_EN": "Shabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16828",
    "CITYNAME_AR": "ابوالطبقان",
    "CITYNAME_EN": "Abwaltabqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16829",
    "CITYNAME_AR": "القحامه (الطارف)",
    "CITYNAME_EN": "Alqahamih (Altarf)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16830",
    "CITYNAME_AR": "حاره قهوه الصعبى",
    "CITYNAME_EN": "Harah Qahuh Alsuebaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16831",
    "CITYNAME_AR": "العوديه",
    "CITYNAME_EN": "Aleudih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16832",
    "CITYNAME_AR": "شبرا",
    "CITYNAME_EN": "Shubaraan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16833",
    "CITYNAME_AR": "سلم الزواهر",
    "CITYNAME_EN": "Salam Alzawahir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16834",
    "CITYNAME_AR": "صوانه",
    "CITYNAME_EN": "Sawanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16835",
    "CITYNAME_AR": "الاصفر",
    "CITYNAME_EN": "Al'Asfar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16836",
    "CITYNAME_AR": "مساكير",
    "CITYNAME_EN": "Masakir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16837",
    "CITYNAME_AR": "فج السمره",
    "CITYNAME_EN": "Faja Alsamaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16838",
    "CITYNAME_AR": "الظليل",
    "CITYNAME_EN": "Alzalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16839",
    "CITYNAME_AR": "الرزان",
    "CITYNAME_EN": "Alrazaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16840",
    "CITYNAME_AR": "سحوبه",
    "CITYNAME_EN": "Suhubuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16841",
    "CITYNAME_AR": "القريشيه",
    "CITYNAME_EN": "Alqarishiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16842",
    "CITYNAME_AR": "مثيبه",
    "CITYNAME_EN": "Muthibah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16843",
    "CITYNAME_AR": "حداب الحمر",
    "CITYNAME_EN": "Hadab Alhumur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16844",
    "CITYNAME_AR": "المحاوره",
    "CITYNAME_EN": "Almuhawiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16845",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16846",
    "CITYNAME_AR": "الصاهلى",
    "CITYNAME_EN": "Alsahla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16847",
    "CITYNAME_AR": "الحجيف",
    "CITYNAME_EN": "Alhajif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16848",
    "CITYNAME_AR": "المنشبه (الحبقه)",
    "CITYNAME_EN": "Almanshibuh (Alhubuquha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16849",
    "CITYNAME_AR": "بريده والحاير",
    "CITYNAME_EN": "Bariduh Walhayir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16850",
    "CITYNAME_AR": "الحشيف",
    "CITYNAME_EN": "Alhashif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16851",
    "CITYNAME_AR": "دويكه",
    "CITYNAME_EN": "Dawiikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16852",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aleaqabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16853",
    "CITYNAME_AR": "قرن سلطان",
    "CITYNAME_EN": "Qarn Sultan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16854",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16855",
    "CITYNAME_AR": "حاره الباب",
    "CITYNAME_EN": "Harih Albab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16856",
    "CITYNAME_AR": "ام العذوق",
    "CITYNAME_EN": "Umm Aleudhuq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16857",
    "CITYNAME_AR": "المتنه",
    "CITYNAME_EN": "Almatnuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16858",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Alqaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16859",
    "CITYNAME_AR": "المراغه",
    "CITYNAME_EN": "Almaraghuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16860",
    "CITYNAME_AR": "ابوحجاره",
    "CITYNAME_EN": "Abwahjaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16861",
    "CITYNAME_AR": "صهده",
    "CITYNAME_EN": "Sahdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16862",
    "CITYNAME_AR": "زبيرعطيه",
    "CITYNAME_EN": "Zbyretih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16863",
    "CITYNAME_AR": "الشعيراء",
    "CITYNAME_EN": "Alshaeira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16864",
    "CITYNAME_AR": "اباالكبى (المنتزه)",
    "CITYNAME_EN": "Abaalkbaa (Almuntazah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16865",
    "CITYNAME_AR": "اللزه",
    "CITYNAME_EN": "Allazuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16866",
    "CITYNAME_AR": "السمر (الحراتيم)",
    "CITYNAME_EN": "Alsamr (Alharatim)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16867",
    "CITYNAME_AR": "العطبه",
    "CITYNAME_EN": "Aleatbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16868",
    "CITYNAME_AR": "الوسقه",
    "CITYNAME_EN": "Alwasqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16869",
    "CITYNAME_AR": "دكوان",
    "CITYNAME_EN": "Dikwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16870",
    "CITYNAME_AR": "الرويس",
    "CITYNAME_EN": "Alruways",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16871",
    "CITYNAME_AR": "حفار",
    "CITYNAME_EN": "Hifar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16872",
    "CITYNAME_AR": "الثلاجه",
    "CITYNAME_EN": "Althalajuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16873",
    "CITYNAME_AR": "الشواق",
    "CITYNAME_EN": "Al Shawaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16874",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Alhariquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16875",
    "CITYNAME_AR": "الروائك",
    "CITYNAME_EN": "Alrawayik",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16876",
    "CITYNAME_AR": "المبركه",
    "CITYNAME_EN": "Almubrikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16877",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Alqawz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16878",
    "CITYNAME_AR": "ابن عاطف الحجروف",
    "CITYNAME_EN": "Abn Eatif Alhajruf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16879",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Aleatfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16880",
    "CITYNAME_AR": "ريده",
    "CITYNAME_EN": "Rydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16881",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Musharaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16882",
    "CITYNAME_AR": "غوله",
    "CITYNAME_EN": "Ghuluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16883",
    "CITYNAME_AR": "الحدبه",
    "CITYNAME_EN": "Alhadabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16884",
    "CITYNAME_AR": "دار الساده",
    "CITYNAME_EN": "Dar Alssaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16885",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Aleurqub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16886",
    "CITYNAME_AR": "فارعه الشوارده",
    "CITYNAME_EN": "Farieah Alshawariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16887",
    "CITYNAME_AR": "قحزه",
    "CITYNAME_EN": "Qahzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16888",
    "CITYNAME_AR": "الحراء",
    "CITYNAME_EN": "Alhara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16889",
    "CITYNAME_AR": "الخبيبه",
    "CITYNAME_EN": "Alkhabibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16890",
    "CITYNAME_AR": "المبداء",
    "CITYNAME_EN": "Almibda'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16891",
    "CITYNAME_AR": "الجرد",
    "CITYNAME_EN": "Aljurad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16892",
    "CITYNAME_AR": "المظلف",
    "CITYNAME_EN": "Almuzalaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16893",
    "CITYNAME_AR": "الجبانه",
    "CITYNAME_EN": "Aljabanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16894",
    "CITYNAME_AR": "الحاجب",
    "CITYNAME_EN": "Alhajib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16895",
    "CITYNAME_AR": "الحيله",
    "CITYNAME_EN": "Alhiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16896",
    "CITYNAME_AR": "صهوه النواشره (العذقه)",
    "CITYNAME_EN": "Sahuh Alnuwashiruh (Aleadhuquha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16897",
    "CITYNAME_AR": "الجعده",
    "CITYNAME_EN": "Aljaeduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16898",
    "CITYNAME_AR": "السر (الصفاوين)",
    "CITYNAME_EN": "Alsiru (Alisfawina)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16899",
    "CITYNAME_AR": "ام الدوارج",
    "CITYNAME_EN": "Umm Aldawarij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16900",
    "CITYNAME_AR": "الشقاويه",
    "CITYNAME_EN": "Alshaqawih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16901",
    "CITYNAME_AR": "سحبل",
    "CITYNAME_EN": "Sahabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16902",
    "CITYNAME_AR": "المغاوى",
    "CITYNAME_EN": "Almaghawaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16903",
    "CITYNAME_AR": "الحصان",
    "CITYNAME_EN": "Alhisan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16904",
    "CITYNAME_AR": "المراح الابيض على رضى",
    "CITYNAME_EN": "Almarah Al'Abyad Ealaa Rda",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16905",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Aldiyquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16906",
    "CITYNAME_AR": "القحمان",
    "CITYNAME_EN": "Alqahman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16907",
    "CITYNAME_AR": "الحمود",
    "CITYNAME_EN": "Alhamuwd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16908",
    "CITYNAME_AR": "الخيره العماير",
    "CITYNAME_EN": "Alkhayruh Aleamayur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16909",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16910",
    "CITYNAME_AR": "عنيكر",
    "CITYNAME_EN": "Eanikr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16911",
    "CITYNAME_AR": "الفقهى",
    "CITYNAME_EN": "Alfaqhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16912",
    "CITYNAME_AR": "السمر",
    "CITYNAME_EN": "Alsamar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16913",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16914",
    "CITYNAME_AR": "هشيمه",
    "CITYNAME_EN": "Hashimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16915",
    "CITYNAME_AR": "المحاميد",
    "CITYNAME_EN": "Almahamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16916",
    "CITYNAME_AR": "الوركاء (الشقفه)",
    "CITYNAME_EN": "Alwuraka' (Alshuqfah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16917",
    "CITYNAME_AR": "الثايه",
    "CITYNAME_EN": "Alththayih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16918",
    "CITYNAME_AR": "الحويرس",
    "CITYNAME_EN": "Alhuyras",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16919",
    "CITYNAME_AR": "المساعرة",
    "CITYNAME_EN": "Almusaeara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16920",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Albadie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16921",
    "CITYNAME_AR": "ساحل جهينه",
    "CITYNAME_EN": "Sahil Jahinh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16922",
    "CITYNAME_AR": "وينا",
    "CITYNAME_EN": "Wayna",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16923",
    "CITYNAME_AR": "العرجاء",
    "CITYNAME_EN": "Aleurja'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16924",
    "CITYNAME_AR": "ام القضاه",
    "CITYNAME_EN": "Umm Alqadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16925",
    "CITYNAME_AR": "المداوسه",
    "CITYNAME_EN": "Almudawisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16926",
    "CITYNAME_AR": "العمشان",
    "CITYNAME_EN": "Aleimshan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16927",
    "CITYNAME_AR": "البكاريه",
    "CITYNAME_EN": "Albakariuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16928",
    "CITYNAME_AR": "عيال بحران",
    "CITYNAME_EN": "Eial Biharan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16929",
    "CITYNAME_AR": "ام النور",
    "CITYNAME_EN": "Umm Alnuwr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16930",
    "CITYNAME_AR": "ابوالرخم (ساحل الزواهره)",
    "CITYNAME_EN": "Abwalirikhum (Sahil Alzawahurh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16931",
    "CITYNAME_AR": "الدجريه",
    "CITYNAME_EN": "Aldijriuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16932",
    "CITYNAME_AR": "الصبخ (الضبح)",
    "CITYNAME_EN": "Alsubkh (Aldbh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16933",
    "CITYNAME_AR": "كياد الفقهاء",
    "CITYNAME_EN": "Kiad Alfuqaha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16934",
    "CITYNAME_AR": "البريم",
    "CITYNAME_EN": "Albarim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16935",
    "CITYNAME_AR": "ابوقرين",
    "CITYNAME_EN": "Abwqarin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16936",
    "CITYNAME_AR": "المنقره",
    "CITYNAME_EN": "Almunqaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16937",
    "CITYNAME_AR": "الكدسه",
    "CITYNAME_EN": "Alkadasuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16938",
    "CITYNAME_AR": "الهباير",
    "CITYNAME_EN": "Alhabayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16939",
    "CITYNAME_AR": "الحجف (الخوالده)",
    "CITYNAME_EN": "Alhijf (Alkhawaludah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16940",
    "CITYNAME_AR": "المنقطعه",
    "CITYNAME_EN": "Almunqataeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16941",
    "CITYNAME_AR": "القمارى",
    "CITYNAME_EN": "Alqamaraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16942",
    "CITYNAME_AR": "الوطاه",
    "CITYNAME_EN": "Alwatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16943",
    "CITYNAME_AR": "بنى سحار",
    "CITYNAME_EN": "Banaa Sahaar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16944",
    "CITYNAME_AR": "الحنب",
    "CITYNAME_EN": "Alhunab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16945",
    "CITYNAME_AR": "الثعالبه",
    "CITYNAME_EN": "Althaealibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16946",
    "CITYNAME_AR": "المناعمه (المنعه)",
    "CITYNAME_EN": "Almanaeimuh (Almaneah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16947",
    "CITYNAME_AR": "المراحبه",
    "CITYNAME_EN": "Almurahibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16948",
    "CITYNAME_AR": "دار الاشراف",
    "CITYNAME_EN": "Dar Al'Iishraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16949",
    "CITYNAME_AR": "خزاعه",
    "CITYNAME_EN": "Khizaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16950",
    "CITYNAME_AR": "الغول",
    "CITYNAME_EN": "Alghul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16951",
    "CITYNAME_AR": "القرتوت",
    "CITYNAME_EN": "Alqartut",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16952",
    "CITYNAME_AR": "الفوحه",
    "CITYNAME_EN": "Alfawhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16953",
    "CITYNAME_AR": "القايمه",
    "CITYNAME_EN": "Alqayimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16954",
    "CITYNAME_AR": "الهيجه",
    "CITYNAME_EN": "Alhayjuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16955",
    "CITYNAME_AR": "نغاش",
    "CITYNAME_EN": "Naghash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16956",
    "CITYNAME_AR": "السمره (الفرشه)",
    "CITYNAME_EN": "Alsamaruh (Alfarashaha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16957",
    "CITYNAME_AR": "قوز حدره",
    "CITYNAME_EN": "Quz Hadarih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16958",
    "CITYNAME_AR": "المناديل",
    "CITYNAME_EN": "Almanadil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16959",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Aleatfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16960",
    "CITYNAME_AR": "الشبرق",
    "CITYNAME_EN": "Alshabriq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16961",
    "CITYNAME_AR": "الاعاميه (اللبدانيه)",
    "CITYNAME_EN": "Alaeamih (Allubdanih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16962",
    "CITYNAME_AR": "المخاربه",
    "CITYNAME_EN": "Almakharibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16963",
    "CITYNAME_AR": "الذواكره",
    "CITYNAME_EN": "Aldhawakiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16964",
    "CITYNAME_AR": "العرجه",
    "CITYNAME_EN": "Alearjuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16965",
    "CITYNAME_AR": "الدعاشيش",
    "CITYNAME_EN": "Aldaeashish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16966",
    "CITYNAME_AR": "الخرابه",
    "CITYNAME_EN": "Alkharabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16967",
    "CITYNAME_AR": "طينه المحاسنه",
    "CITYNAME_EN": "Taynah Almuhasinuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16968",
    "CITYNAME_AR": "الطالعيه",
    "CITYNAME_EN": "Altaalieih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16969",
    "CITYNAME_AR": "عريجه",
    "CITYNAME_EN": "Earijuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16970",
    "CITYNAME_AR": "الهندول السر",
    "CITYNAME_EN": "Alhindul Alsiru",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16971",
    "CITYNAME_AR": "المفارق",
    "CITYNAME_EN": "Almafaraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16972",
    "CITYNAME_AR": "المساخف (الحيله)",
    "CITYNAME_EN": "Almasakhif (Alhilah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16973",
    "CITYNAME_AR": "كلية",
    "CITYNAME_EN": "Kuliya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16974",
    "CITYNAME_AR": "الجميعات",
    "CITYNAME_EN": "Aljamieat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16975",
    "CITYNAME_AR": "مخطط خبت عجلان",
    "CITYNAME_EN": "Mukhatat Khabat Eajlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16976",
    "CITYNAME_AR": "العصماء",
    "CITYNAME_EN": "Aleasama'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16977",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16978",
    "CITYNAME_AR": "ام الحصين وكريدمه",
    "CITYNAME_EN": "Umm Alhasin Wakaridumuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16979",
    "CITYNAME_AR": "الدحض",
    "CITYNAME_EN": "Ad-Dahad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16980",
    "CITYNAME_AR": "النشانيش",
    "CITYNAME_EN": "An-Nashaneesh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16981",
    "CITYNAME_AR": "نخب",
    "CITYNAME_EN": "Nakhab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16982",
    "CITYNAME_AR": "العرص",
    "CITYNAME_EN": "Alars",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16983",
    "CITYNAME_AR": "جرف هلال",
    "CITYNAME_EN": "Jarf Helal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16984",
    "CITYNAME_AR": "ضهايا الكظن",
    "CITYNAME_EN": "Dahaya Alkazan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16985",
    "CITYNAME_AR": "الرهوه",
    "CITYNAME_EN": "Ar-Rahwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16986",
    "CITYNAME_AR": "ام اللتيح",
    "CITYNAME_EN": "Om Allateeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16987",
    "CITYNAME_AR": "ام الوديه",
    "CITYNAME_EN": "Om Alwadyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16988",
    "CITYNAME_AR": "مهد الضمو (الطائف - الصمد)",
    "CITYNAME_EN": "Mahd Aldamu (Taif - As-Samad)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16989",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16990",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Almuaqar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16991",
    "CITYNAME_AR": "وكيهف",
    "CITYNAME_EN": "Wakayhaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16992",
    "CITYNAME_AR": "الهواشله",
    "CITYNAME_EN": "Alhawashlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16993",
    "CITYNAME_AR": "القريه (اديم)",
    "CITYNAME_EN": "Alqarya (Adeem)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16994",
    "CITYNAME_AR": "ابوكريشه",
    "CITYNAME_EN": "Abo Kreshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16995",
    "CITYNAME_AR": "ابوشامه",
    "CITYNAME_EN": "Abo Shamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16996",
    "CITYNAME_AR": "ابوشويمه",
    "CITYNAME_EN": "Abo Shuwaymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16997",
    "CITYNAME_AR": "ام غنيم",
    "CITYNAME_EN": "Om Ghonaim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16998",
    "CITYNAME_AR": "سقيا",
    "CITYNAME_EN": "Suqya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16999",
    "CITYNAME_AR": "الشريعه (الناجميه)",
    "CITYNAME_EN": "As-Shari'a (An-Najmyah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17000",
    "CITYNAME_AR": "الخلصه",
    "CITYNAME_EN": "Alkhalsah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17001",
    "CITYNAME_AR": "الدحيل",
    "CITYNAME_EN": "Ad-Duhail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17002",
    "CITYNAME_AR": "العيينه (نخيل)",
    "CITYNAME_EN": "Alayenah (Nakheel)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17003",
    "CITYNAME_AR": "ام سمير",
    "CITYNAME_EN": "Om Samir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17004",
    "CITYNAME_AR": "الصعيب",
    "CITYNAME_EN": "As-Suaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17005",
    "CITYNAME_AR": "المحانى (نخيل)",
    "CITYNAME_EN": "Almuhani (Nakheel)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17006",
    "CITYNAME_AR": "صومع",
    "CITYNAME_EN": "Sawmaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17007",
    "CITYNAME_AR": "البركه",
    "CITYNAME_EN": "Albarakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17008",
    "CITYNAME_AR": "المثينيه",
    "CITYNAME_EN": "Almuthaynyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17009",
    "CITYNAME_AR": "المزارع (النخيل)",
    "CITYNAME_EN": "Almuzarei (Al Nakheel)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17010",
    "CITYNAME_AR": "الحريقه",
    "CITYNAME_EN": "Al Hareqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17011",
    "CITYNAME_AR": "الصديفيه",
    "CITYNAME_EN": "Al Sadayfyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17012",
    "CITYNAME_AR": "اللغيب",
    "CITYNAME_EN": "Al Lughyab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17013",
    "CITYNAME_AR": "الرضيميه",
    "CITYNAME_EN": "Ar-Rudaymyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17014",
    "CITYNAME_AR": "العمودة (الريع الحمر)",
    "CITYNAME_EN": "Al Amudah (Ar-Ruba' Alhumr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17015",
    "CITYNAME_AR": "الدافعه",
    "CITYNAME_EN": "Ad-Dafaih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17016",
    "CITYNAME_AR": "حزمه السال",
    "CITYNAME_EN": "Hezmah As-Sal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17017",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17018",
    "CITYNAME_AR": "ضبيعه",
    "CITYNAME_EN": "Dubayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17019",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Om As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17020",
    "CITYNAME_AR": "اذاخر",
    "CITYNAME_EN": "Azakher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17021",
    "CITYNAME_AR": "الهوكه",
    "CITYNAME_EN": "Al Hawkah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17022",
    "CITYNAME_AR": "الحجره دحال ومزارعها",
    "CITYNAME_EN": "Al Hujrah Duhal and its farms",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17023",
    "CITYNAME_AR": "حي الصفراء",
    "CITYNAME_EN": "Hay As-Safraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17024",
    "CITYNAME_AR": "مهد شعب (حي الاحساء)",
    "CITYNAME_EN": "Mahd Shuab (Hay Al Ahsaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17025",
    "CITYNAME_AR": "ام سريحه",
    "CITYNAME_EN": "Umm Sariha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17026",
    "CITYNAME_AR": "النبيعه",
    "CITYNAME_EN": "An-Nabeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17027",
    "CITYNAME_AR": "ام الحصاة",
    "CITYNAME_EN": "Om Alhusah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17028",
    "CITYNAME_AR": "شعيب الطين",
    "CITYNAME_EN": "Shuaib At-Teen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17029",
    "CITYNAME_AR": "المطرد",
    "CITYNAME_EN": "Al Mutrad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17030",
    "CITYNAME_AR": "ام الجدبان",
    "CITYNAME_EN": "Om Algadban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17031",
    "CITYNAME_AR": "ابو رمضاء",
    "CITYNAME_EN": "Abo Ramdaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17032",
    "CITYNAME_AR": "ايلاء",
    "CITYNAME_EN": "Eila",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17033",
    "CITYNAME_AR": "طلعه مسعود",
    "CITYNAME_EN": "Talet Masood",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17034",
    "CITYNAME_AR": "اللصب",
    "CITYNAME_EN": "Al Lasab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17035",
    "CITYNAME_AR": "حضى",
    "CITYNAME_EN": "Hudni",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17036",
    "CITYNAME_AR": "بئر ابو سلمه (ابوسلحه)",
    "CITYNAME_EN": "Beir Abo Salamah (Abo Salaha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17037",
    "CITYNAME_AR": "القعر الأعلى",
    "CITYNAME_EN": "Al Qaar Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17038",
    "CITYNAME_AR": "ام الضروس",
    "CITYNAME_EN": "Om Ad-Darus",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17039",
    "CITYNAME_AR": "حى المزارع الجديد",
    "CITYNAME_EN": "Hay Al Farms of Al Jadeed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17040",
    "CITYNAME_AR": "الخايله",
    "CITYNAME_EN": "Al Khaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17041",
    "CITYNAME_AR": "الصقيعاء",
    "CITYNAME_EN": "As-Suqayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17042",
    "CITYNAME_AR": "الحصن",
    "CITYNAME_EN": "Al Hesn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17043",
    "CITYNAME_AR": "جناب اللباء",
    "CITYNAME_EN": "Janab Al Lubaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17044",
    "CITYNAME_AR": "بئر الفرحج (ضرعا)",
    "CITYNAME_EN": "Beir Al Farhaj (Daraa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17045",
    "CITYNAME_AR": "السهيف (العيبة العليا)",
    "CITYNAME_EN": "As-Suhayef (Al Ayba Al Olya)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17046",
    "CITYNAME_AR": "النهبره (العيبة السفلى)",
    "CITYNAME_EN": "An-Nahbarah (Al Ayba As-Sufla)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17047",
    "CITYNAME_AR": "الابراه",
    "CITYNAME_EN": "Al Abrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17048",
    "CITYNAME_AR": "منازل الغصن",
    "CITYNAME_EN": "Manazel Al Ghusn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17049",
    "CITYNAME_AR": "العيبه الوسطى",
    "CITYNAME_EN": "Al Ayba Al Wusta",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17050",
    "CITYNAME_AR": "معذره",
    "CITYNAME_EN": "Maazerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17051",
    "CITYNAME_AR": "بئر بناوه",
    "CITYNAME_EN": "Beir Banawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17052",
    "CITYNAME_AR": "الخريمات",
    "CITYNAME_EN": "Al Khuraymat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17053",
    "CITYNAME_AR": "ابار الكظمه",
    "CITYNAME_EN": "Abar Al Kathamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17054",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Milh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17055",
    "CITYNAME_AR": "مزرعه مصلح",
    "CITYNAME_EN": "Farm of Maslih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17056",
    "CITYNAME_AR": "الكامل",
    "CITYNAME_EN": "Alkamil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17057",
    "CITYNAME_AR": "الرهاطية",
    "CITYNAME_EN": "Alrihatia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17058",
    "CITYNAME_AR": "الخفج",
    "CITYNAME_EN": "Alkhafj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17059",
    "CITYNAME_AR": "المحافير (المعالى)",
    "CITYNAME_EN": "Almahafir (Almaealaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17060",
    "CITYNAME_AR": "ثفراء",
    "CITYNAME_EN": "Thufara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17061",
    "CITYNAME_AR": "ام الرتج",
    "CITYNAME_EN": "Umm Alrtj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17062",
    "CITYNAME_AR": "الخايع (الصمد)",
    "CITYNAME_EN": "Alkhayie (Alasmda)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17063",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17064",
    "CITYNAME_AR": "الرميضه",
    "CITYNAME_EN": "Alramiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17065",
    "CITYNAME_AR": "الخدد",
    "CITYNAME_EN": "Alkhudud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17066",
    "CITYNAME_AR": "مهايع",
    "CITYNAME_EN": "Muhayaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17067",
    "CITYNAME_AR": "الشيق",
    "CITYNAME_EN": "As-Shayq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17068",
    "CITYNAME_AR": "العويصد",
    "CITYNAME_EN": "Alowayes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17069",
    "CITYNAME_AR": "مزارع نهاية وادي السبعان",
    "CITYNAME_EN": "Nehayet Wadi As-Saban farms",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17070",
    "CITYNAME_AR": "رغدان",
    "CITYNAME_EN": "Raghdan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17071",
    "CITYNAME_AR": "ملح الحميضه",
    "CITYNAME_EN": "Malh Alhamdyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17072",
    "CITYNAME_AR": "البرك (ملح الحمر)",
    "CITYNAME_EN": "Albarak (Malh Alhumr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17073",
    "CITYNAME_AR": "البحير (بأهالا)",
    "CITYNAME_EN": "Albuhayr (Bahalah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17074",
    "CITYNAME_AR": "ملح الروز",
    "CITYNAME_EN": "Malh Ar-Rouz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17075",
    "CITYNAME_AR": "الصفيه",
    "CITYNAME_EN": "As-Safayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17076",
    "CITYNAME_AR": "غصان",
    "CITYNAME_EN": "Ghassan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17077",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17078",
    "CITYNAME_AR": "الجندبيه",
    "CITYNAME_EN": "Aljandabyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17079",
    "CITYNAME_AR": "ام الجران",
    "CITYNAME_EN": "Om Aljaran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17080",
    "CITYNAME_AR": "ضبع",
    "CITYNAME_EN": "Dabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17081",
    "CITYNAME_AR": "ابوالوهاس",
    "CITYNAME_EN": "Abo Alwahhas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17082",
    "CITYNAME_AR": "ام القطف",
    "CITYNAME_EN": "Om Alqateef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17083",
    "CITYNAME_AR": "المجمر",
    "CITYNAME_EN": "Almajmar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17084",
    "CITYNAME_AR": "المستحيره",
    "CITYNAME_EN": "Al Mustaherah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17085",
    "CITYNAME_AR": "اجيمع",
    "CITYNAME_EN": "Aujaimaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17086",
    "CITYNAME_AR": "ام الطرف",
    "CITYNAME_EN": "Om Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17087",
    "CITYNAME_AR": "الرضام والربوه",
    "CITYNAME_EN": "Ar-Rudam wa Ar-Rabwa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17088",
    "CITYNAME_AR": "غصين",
    "CITYNAME_EN": "Ghusayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17089",
    "CITYNAME_AR": "الرويغ",
    "CITYNAME_EN": "Ar-Ruwaygh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17090",
    "CITYNAME_AR": "غريف التراجمه",
    "CITYNAME_EN": "Gharef At-Tarajemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17091",
    "CITYNAME_AR": "العبيديه",
    "CITYNAME_EN": "Al Obaidyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17092",
    "CITYNAME_AR": "المقرح",
    "CITYNAME_EN": "Al Muqreh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17093",
    "CITYNAME_AR": "الطائف",
    "CITYNAME_EN": "Taif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17094",
    "CITYNAME_AR": "مهد شوان",
    "CITYNAME_EN": "Mahd Shawan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17095",
    "CITYNAME_AR": "مزرعه بريك وصل الله",
    "CITYNAME_EN": "Berek Waslallah farm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17096",
    "CITYNAME_AR": "غريف الجلاه",
    "CITYNAME_EN": "Gharef Al Galah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17097",
    "CITYNAME_AR": "المثناه (الربيربه حاره بن نامى)",
    "CITYNAME_EN": "Al Muthnah (Ar-Reberyah Harah bin Nami)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17098",
    "CITYNAME_AR": "المثناة (حارة رويبح)",
    "CITYNAME_EN": "Al Muthnah (Haret Ruwaibeh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17099",
    "CITYNAME_AR": "ام الجوامع",
    "CITYNAME_EN": "Om Al Jawamei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17100",
    "CITYNAME_AR": "النخره ( النخراء)",
    "CITYNAME_EN": "An-Nakharah (An-Nakharaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17101",
    "CITYNAME_AR": "الهيوره",
    "CITYNAME_EN": "Al Haywarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17102",
    "CITYNAME_AR": "المنزه",
    "CITYNAME_EN": "Al Manzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17103",
    "CITYNAME_AR": "ام الغزلان المضحاه",
    "CITYNAME_EN": "Om Al Ghazlan Al Mudhah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17104",
    "CITYNAME_AR": "المضحاه (المرنه)",
    "CITYNAME_EN": "Al Mudhah (Al Maranah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17105",
    "CITYNAME_AR": "المغايضه",
    "CITYNAME_EN": "Al Mughayadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17106",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sedrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17107",
    "CITYNAME_AR": "الصابريه",
    "CITYNAME_EN": "Al Sabriyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17108",
    "CITYNAME_AR": "مجمر العفاريه",
    "CITYNAME_EN": "Magmar Al Efaryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17109",
    "CITYNAME_AR": "الخديد (نجاجره)",
    "CITYNAME_EN": "Al Khaded (Nagagrah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17110",
    "CITYNAME_AR": "الشكريه والرزقني",
    "CITYNAME_EN": "Ash-Shukaryah wa Ar-Razakani",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17111",
    "CITYNAME_AR": "عشره",
    "CITYNAME_EN": "Ashrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17112",
    "CITYNAME_AR": "المعرد (بحبحه)",
    "CITYNAME_EN": "Al Muarad (Bahbahah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17113",
    "CITYNAME_AR": "الفارع",
    "CITYNAME_EN": "Al Farei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17114",
    "CITYNAME_AR": "حاره العوجه",
    "CITYNAME_EN": "Harah Al Awjah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17115",
    "CITYNAME_AR": "مزرعه ساعد المقعى",
    "CITYNAME_EN": "Saed Al Muqai farm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17116",
    "CITYNAME_AR": "الوقبه",
    "CITYNAME_EN": "Al Waqabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17117",
    "CITYNAME_AR": "الشركه",
    "CITYNAME_EN": "Al Sharekah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17118",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Ar-Rabwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17119",
    "CITYNAME_AR": "العقله",
    "CITYNAME_EN": "Al Uqlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17120",
    "CITYNAME_AR": "الدحيله",
    "CITYNAME_EN": "Ad-Duhailah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17121",
    "CITYNAME_AR": "الدمان",
    "CITYNAME_EN": "Ad-Daman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17122",
    "CITYNAME_AR": "التلعه",
    "CITYNAME_EN": "At-Talaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17123",
    "CITYNAME_AR": "مقرح العمشان",
    "CITYNAME_EN": "Muqreh Al Ashman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17124",
    "CITYNAME_AR": "مزارع شوان",
    "CITYNAME_EN": "Shawan farms",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17125",
    "CITYNAME_AR": "مهد الجراء",
    "CITYNAME_EN": "Mahd Al Jaraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17126",
    "CITYNAME_AR": "نزلة ضيف الله غطيشان",
    "CITYNAME_EN": "Nazlet Deif Allah Ghutayshan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17127",
    "CITYNAME_AR": "بئر الصفراء",
    "CITYNAME_EN": "Beir As-Safraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17128",
    "CITYNAME_AR": "الشرع (حره ذره)",
    "CITYNAME_EN": "Ash-Sharaa (Hurah Zarah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17129",
    "CITYNAME_AR": "دف حجاج",
    "CITYNAME_EN": "Daf Hajjaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17130",
    "CITYNAME_AR": "مغونه",
    "CITYNAME_EN": "Mughawnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17131",
    "CITYNAME_AR": "شباريق",
    "CITYNAME_EN": "Shabareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17132",
    "CITYNAME_AR": "الغريب",
    "CITYNAME_EN": "Al Ghareb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17133",
    "CITYNAME_AR": "وطيلح",
    "CITYNAME_EN": "Watelah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17134",
    "CITYNAME_AR": "السويسية",
    "CITYNAME_EN": "As-Suwaysyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17135",
    "CITYNAME_AR": "أم لحييان",
    "CITYNAME_EN": "Om Lahyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17136",
    "CITYNAME_AR": "الباحه",
    "CITYNAME_EN": "Al Baha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17137",
    "CITYNAME_AR": "نزله حبيب الله",
    "CITYNAME_EN": "Nazlet Habib Allah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17138",
    "CITYNAME_AR": "نزله عبدالله بن رشيد",
    "CITYNAME_EN": "Nazlet Abdullah bin Rashid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17139",
    "CITYNAME_AR": "محلف",
    "CITYNAME_EN": "Mahlef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17140",
    "CITYNAME_AR": "شواحط (حي هضبة الصقور)",
    "CITYNAME_EN": "Shawahet (Hay Hadabet As-Suqur)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17141",
    "CITYNAME_AR": "القبيبه",
    "CITYNAME_EN": "Al Qubaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17142",
    "CITYNAME_AR": "رمصان",
    "CITYNAME_EN": "Ramsan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17143",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Al Hafnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17144",
    "CITYNAME_AR": "ريع الفدفد",
    "CITYNAME_EN": "Rei Al Fadfad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17145",
    "CITYNAME_AR": "مشرق",
    "CITYNAME_EN": "Mashreq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17146",
    "CITYNAME_AR": "المحيجن",
    "CITYNAME_EN": "Al Muhayjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17147",
    "CITYNAME_AR": "ابار المحجن",
    "CITYNAME_EN": "Abar Al Mahjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17148",
    "CITYNAME_AR": "نزلة ختام",
    "CITYNAME_EN": "Nazlet Khetam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17149",
    "CITYNAME_AR": "نزلة خويتم",
    "CITYNAME_EN": "Nazlet Khuwaytam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17150",
    "CITYNAME_AR": "سواس",
    "CITYNAME_EN": "Sawas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17151",
    "CITYNAME_AR": "الجيب",
    "CITYNAME_EN": "Al Jeeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17152",
    "CITYNAME_AR": "الزرقاء",
    "CITYNAME_EN": "Az-Zarqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17153",
    "CITYNAME_AR": "سياب",
    "CITYNAME_EN": "Seyab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17154",
    "CITYNAME_AR": "نزله حامد",
    "CITYNAME_EN": "Nazlet Hamed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17155",
    "CITYNAME_AR": "الثمام",
    "CITYNAME_EN": "Ath-Thamam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17156",
    "CITYNAME_AR": "الحويمض",
    "CITYNAME_EN": "Al Huwaymed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17157",
    "CITYNAME_AR": "الرضمه",
    "CITYNAME_EN": "Ar-Radamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17158",
    "CITYNAME_AR": "ام ضرس",
    "CITYNAME_EN": "Om Dars",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17159",
    "CITYNAME_AR": "اليسرى",
    "CITYNAME_EN": "Al Yosra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17160",
    "CITYNAME_AR": "خثارق (الرخمه)",
    "CITYNAME_EN": "Khathareq (Ar-Rakhmah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17161",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17162",
    "CITYNAME_AR": "العزاميه",
    "CITYNAME_EN": "Al Ozamyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17163",
    "CITYNAME_AR": "نزلة مثيب (بالدحيله)",
    "CITYNAME_EN": "Nazlet Muthaib (Ad-Duhailah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17164",
    "CITYNAME_AR": "نزلة مسلم (ام غويشه بالحريقه)",
    "CITYNAME_EN": "Nazlet Moslem (Om Ghuwaishah, Al Hareqah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17165",
    "CITYNAME_AR": "نزله دويحان",
    "CITYNAME_EN": "Nazlet Duwayhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17166",
    "CITYNAME_AR": "نزله رزق الله",
    "CITYNAME_EN": "Nazlet Rezq Allah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17167",
    "CITYNAME_AR": "نزله خليفه بن مهجى",
    "CITYNAME_EN": "Nazlet Khalifah bin Muhje",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17168",
    "CITYNAME_AR": "ابار الحنجره",
    "CITYNAME_EN": "Abar Al Hanjarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17169",
    "CITYNAME_AR": "العماريه",
    "CITYNAME_EN": "Al Omariya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17170",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Ad-Duhalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17171",
    "CITYNAME_AR": "ام العرمط",
    "CITYNAME_EN": "Om Al Artam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17172",
    "CITYNAME_AR": "حاره نعيم",
    "CITYNAME_EN": "Haret Naim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17173",
    "CITYNAME_AR": "حاره النوره (القبلان)",
    "CITYNAME_EN": "Haret An-Nawrah (Al Qublan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17174",
    "CITYNAME_AR": "الحيله (ربوه الحيله)",
    "CITYNAME_EN": "Al Haylah (Rabwah Al Haylah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17175",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17176",
    "CITYNAME_AR": "الدويمه (البويزم)",
    "CITYNAME_EN": "Ad-Duwaimah (Al Buwezam)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17177",
    "CITYNAME_AR": "عوينه",
    "CITYNAME_EN": "Ouainah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17178",
    "CITYNAME_AR": "الامراء",
    "CITYNAME_EN": "Al Omaraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17179",
    "CITYNAME_AR": "التنعيمه",
    "CITYNAME_EN": "At-Tanaimah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17180",
    "CITYNAME_AR": "الاصفر",
    "CITYNAME_EN": "Al Asfar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17181",
    "CITYNAME_AR": "العضوم",
    "CITYNAME_EN": "Al Adoum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17182",
    "CITYNAME_AR": "مهد الحويمض (الحامض)",
    "CITYNAME_EN": "Mahd Al Huwaymed (Al Hamed)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17183",
    "CITYNAME_AR": "الحبال",
    "CITYNAME_EN": "Al Hebal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17184",
    "CITYNAME_AR": "مزينه",
    "CITYNAME_EN": "Muzaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17185",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17186",
    "CITYNAME_AR": "المليحه",
    "CITYNAME_EN": "Al Malaiha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17187",
    "CITYNAME_AR": "البحير",
    "CITYNAME_EN": "Al Buhair",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17188",
    "CITYNAME_AR": "بينه",
    "CITYNAME_EN": "Baynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17189",
    "CITYNAME_AR": "الطفيه",
    "CITYNAME_EN": "At-Tafyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17190",
    "CITYNAME_AR": "اسكوبان",
    "CITYNAME_EN": "Askuban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17191",
    "CITYNAME_AR": "الصليلات",
    "CITYNAME_EN": "As-Sulaylat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17192",
    "CITYNAME_AR": "الزهور وسلم",
    "CITYNAME_EN": "Az-Zuhor waslam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17193",
    "CITYNAME_AR": "الحلقه",
    "CITYNAME_EN": "Al Halaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17194",
    "CITYNAME_AR": "المرجبات",
    "CITYNAME_EN": "Al Marjabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17195",
    "CITYNAME_AR": "ذرحن",
    "CITYNAME_EN": "Zarhan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17196",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Al Moslemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17197",
    "CITYNAME_AR": "البوانه",
    "CITYNAME_EN": "Al Bawanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17198",
    "CITYNAME_AR": "جحر",
    "CITYNAME_EN": "Juhr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17199",
    "CITYNAME_AR": "مقعس",
    "CITYNAME_EN": "Muqeis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17200",
    "CITYNAME_AR": "مشرفه",
    "CITYNAME_EN": "Mushrefah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17201",
    "CITYNAME_AR": "الظواهر",
    "CITYNAME_EN": "Az-Zawaher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17202",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17203",
    "CITYNAME_AR": "الصفق",
    "CITYNAME_EN": "Al Safaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17204",
    "CITYNAME_AR": "الحرش",
    "CITYNAME_EN": "Al Harash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17205",
    "CITYNAME_AR": "الخدود",
    "CITYNAME_EN": "Al Khudod",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17206",
    "CITYNAME_AR": "المعلمه",
    "CITYNAME_EN": "Al Moalemah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17207",
    "CITYNAME_AR": "ذران",
    "CITYNAME_EN": "Zeran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17208",
    "CITYNAME_AR": "المريبي",
    "CITYNAME_EN": "Al Muraibi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17209",
    "CITYNAME_AR": "ثريبان",
    "CITYNAME_EN": "Thareban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17210",
    "CITYNAME_AR": "البشامه",
    "CITYNAME_EN": "Al Bashamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17211",
    "CITYNAME_AR": "نقمه العبادله",
    "CITYNAME_EN": "Naqmah Al Abadelah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17212",
    "CITYNAME_AR": "القصر",
    "CITYNAME_EN": "Al Qasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17213",
    "CITYNAME_AR": "قرن الحيران",
    "CITYNAME_EN": "Qarn Al Hayran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17214",
    "CITYNAME_AR": "حدبه الاقاضي",
    "CITYNAME_EN": "Hadabah Al Aqadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17215",
    "CITYNAME_AR": "نقمه شمران",
    "CITYNAME_EN": "Naqamah Shamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17216",
    "CITYNAME_AR": "صعيداء",
    "CITYNAME_EN": "Suaidaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17217",
    "CITYNAME_AR": "غدنه",
    "CITYNAME_EN": "Ghednah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17218",
    "CITYNAME_AR": "الزراب",
    "CITYNAME_EN": "Az-Zurab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17219",
    "CITYNAME_AR": "الحنافى",
    "CITYNAME_EN": "Al Henafi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17220",
    "CITYNAME_AR": "عسيله",
    "CITYNAME_EN": "Usaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17221",
    "CITYNAME_AR": "حرمان",
    "CITYNAME_EN": "Herman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17222",
    "CITYNAME_AR": "سبت شمران",
    "CITYNAME_EN": "Sabt Shamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17223",
    "CITYNAME_AR": "شعب النخله",
    "CITYNAME_EN": "Shuab An-Nakhlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17224",
    "CITYNAME_AR": "المخصر",
    "CITYNAME_EN": "Al Mukhser",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17225",
    "CITYNAME_AR": "زهوه شمران",
    "CITYNAME_EN": "Zahwah Shamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17226",
    "CITYNAME_AR": "الحمره",
    "CITYNAME_EN": "Al Hamrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17227",
    "CITYNAME_AR": "اللصبه",
    "CITYNAME_EN": "Al Lasbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17228",
    "CITYNAME_AR": "ال سعيده",
    "CITYNAME_EN": "Al Saeedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17229",
    "CITYNAME_AR": "الكتاده",
    "CITYNAME_EN": "Al Katadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17230",
    "CITYNAME_AR": "ال خريم",
    "CITYNAME_EN": "Al Kharem",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17231",
    "CITYNAME_AR": "ال طارق",
    "CITYNAME_EN": "Al Tareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17232",
    "CITYNAME_AR": "العتمه",
    "CITYNAME_EN": "Al Atmah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17233",
    "CITYNAME_AR": "قرى حجل",
    "CITYNAME_EN": "Hajal villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17234",
    "CITYNAME_AR": "زهوه (ال كثير)",
    "CITYNAME_EN": "Zahweh (Al Kather)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17235",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Khorab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17236",
    "CITYNAME_AR": "الوقر",
    "CITYNAME_EN": "Al Waqr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17237",
    "CITYNAME_AR": "البعسوس",
    "CITYNAME_EN": "Al Baasoos",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17238",
    "CITYNAME_AR": "ال حفيش",
    "CITYNAME_EN": "Al Hufayesh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17239",
    "CITYNAME_AR": "عصمان",
    "CITYNAME_EN": "Asman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17240",
    "CITYNAME_AR": "المصادعه",
    "CITYNAME_EN": "Al Musaedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17241",
    "CITYNAME_AR": "ال شهوان",
    "CITYNAME_EN": "Al Shahwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17242",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Al Marbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17243",
    "CITYNAME_AR": "قهبه ال بن موسى",
    "CITYNAME_EN": "Qahbah Al bin Moussa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17244",
    "CITYNAME_AR": "قهبه ال بيضان",
    "CITYNAME_EN": "Qahbah Al Baydan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17245",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Al Marbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17246",
    "CITYNAME_AR": "قهبه ال محاوى",
    "CITYNAME_EN": "Qahbah Al Mahawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17247",
    "CITYNAME_AR": "ال مده",
    "CITYNAME_EN": "Al Mudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17248",
    "CITYNAME_AR": "الخبيرة",
    "CITYNAME_EN": "Al Khubayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17249",
    "CITYNAME_AR": "ام نبعه",
    "CITYNAME_EN": "Om Nabaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17250",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17251",
    "CITYNAME_AR": "المروان",
    "CITYNAME_EN": "Al Marwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17252",
    "CITYNAME_AR": "العدينه",
    "CITYNAME_EN": "Al Odaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17253",
    "CITYNAME_AR": "الدهناء",
    "CITYNAME_EN": "Ad-Dahanaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17254",
    "CITYNAME_AR": "الوعران",
    "CITYNAME_EN": "Al Waaran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17255",
    "CITYNAME_AR": "القهبه",
    "CITYNAME_EN": "Al Qahbah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17256",
    "CITYNAME_AR": "الحشايه",
    "CITYNAME_EN": "Al Hushayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17257",
    "CITYNAME_AR": "حدبه غابى",
    "CITYNAME_EN": "Hadabah Ghabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17258",
    "CITYNAME_AR": "الحدباء",
    "CITYNAME_EN": "Alhadbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17259",
    "CITYNAME_AR": "مقاعس",
    "CITYNAME_EN": "Maqaes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17260",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17261",
    "CITYNAME_AR": "القفره",
    "CITYNAME_EN": "Al Qafrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17262",
    "CITYNAME_AR": "القره",
    "CITYNAME_EN": "Al Qurrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17263",
    "CITYNAME_AR": "الصمده",
    "CITYNAME_EN": "As-Samadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17264",
    "CITYNAME_AR": "السبيحه",
    "CITYNAME_EN": "As-Subaihah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17265",
    "CITYNAME_AR": "الدينار",
    "CITYNAME_EN": "Ad-Dinar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17266",
    "CITYNAME_AR": "الصدرة",
    "CITYNAME_EN": "As-Sadrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17267",
    "CITYNAME_AR": "الصعبه",
    "CITYNAME_EN": "As-Saabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17268",
    "CITYNAME_AR": "المنزل",
    "CITYNAME_EN": "Al Manzel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17269",
    "CITYNAME_AR": "عرق الطعمة",
    "CITYNAME_EN": "Araq At-Taamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17270",
    "CITYNAME_AR": "بادى",
    "CITYNAME_EN": "Badi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17271",
    "CITYNAME_AR": "قرى الغبيره",
    "CITYNAME_EN": "Al Ghubayrah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17272",
    "CITYNAME_AR": "قرى عيسى",
    "CITYNAME_EN": "Eissa villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17273",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17274",
    "CITYNAME_AR": "ال مداوى (الخرباء)",
    "CITYNAME_EN": "Al Madawi (Al Kharbaa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17275",
    "CITYNAME_AR": "الرقيعه",
    "CITYNAME_EN": "Ar-Ruqayaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17276",
    "CITYNAME_AR": "جيلح",
    "CITYNAME_EN": "Julayeh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17277",
    "CITYNAME_AR": "الرحبات",
    "CITYNAME_EN": "Ar-Rahbat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17278",
    "CITYNAME_AR": "المدبغ",
    "CITYNAME_EN": "Al Madbagh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17279",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Al Taraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17280",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Az-Zuharah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17281",
    "CITYNAME_AR": "الفريشه",
    "CITYNAME_EN": "Al Fareshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17282",
    "CITYNAME_AR": "الخدعاء",
    "CITYNAME_EN": "Al Khadaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17283",
    "CITYNAME_AR": "أم ظاهر",
    "CITYNAME_EN": "Om Zaher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17284",
    "CITYNAME_AR": "نمره",
    "CITYNAME_EN": "Namerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17285",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17286",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Moshref",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17287",
    "CITYNAME_AR": "المسقوى",
    "CITYNAME_EN": "Al Mosqawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17288",
    "CITYNAME_AR": "حدبه الشقب",
    "CITYNAME_EN": "Hadabah Ash-Shaqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17289",
    "CITYNAME_AR": "الحفيه",
    "CITYNAME_EN": "Al Hufayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17290",
    "CITYNAME_AR": "حدبه الواديين",
    "CITYNAME_EN": "Hadabah Al Wadeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17291",
    "CITYNAME_AR": "الدخول الشامي",
    "CITYNAME_EN": "Ad-Dokhol Ash-Shami",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17292",
    "CITYNAME_AR": "الحله",
    "CITYNAME_EN": "Al Helah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17293",
    "CITYNAME_AR": "الوعله",
    "CITYNAME_EN": "Al Waalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17294",
    "CITYNAME_AR": "أم الصيغاء",
    "CITYNAME_EN": "Om As-Sayghaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17295",
    "CITYNAME_AR": "الخيشه",
    "CITYNAME_EN": "Al Khayshah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17296",
    "CITYNAME_AR": "الحبواء (الرمامين)",
    "CITYNAME_EN": "Al Habwaa (Ar-Ramamen)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17297",
    "CITYNAME_AR": "رحاب الوادى",
    "CITYNAME_EN": "Rehab Al Wadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17298",
    "CITYNAME_AR": "الكامل الجديد",
    "CITYNAME_EN": "Al Kamel Al Jadid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17299",
    "CITYNAME_AR": "الكامل",
    "CITYNAME_EN": "Al-Kamil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17300",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al Hanakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17301",
    "CITYNAME_AR": "ال ياسر",
    "CITYNAME_EN": "Al Yasser",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17302",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Al Hujrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17303",
    "CITYNAME_AR": "الخشم",
    "CITYNAME_EN": "Al Khasham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17304",
    "CITYNAME_AR": "قرى سوره",
    "CITYNAME_EN": "Surah villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17305",
    "CITYNAME_AR": "ساحل الاعلى",
    "CITYNAME_EN": "Sahel Al Aala",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17306",
    "CITYNAME_AR": "ساحل الاسفل",
    "CITYNAME_EN": "Sahel Al Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17307",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Al Marbaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17308",
    "CITYNAME_AR": "معشوقه",
    "CITYNAME_EN": "Ma'shouq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17309",
    "CITYNAME_AR": "الخالدية",
    "CITYNAME_EN": "Al Khalidyyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17310",
    "CITYNAME_AR": "سور",
    "CITYNAME_EN": "Soor",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17311",
    "CITYNAME_AR": "الحياالله",
    "CITYNAME_EN": "Al Hayallah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17312",
    "CITYNAME_AR": "المحصل",
    "CITYNAME_EN": "Al Mohasel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17313",
    "CITYNAME_AR": "الزرباء",
    "CITYNAME_EN": "Az-Zuryaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17314",
    "CITYNAME_AR": "الشرى",
    "CITYNAME_EN": "Ash-Shara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17315",
    "CITYNAME_AR": "السواد",
    "CITYNAME_EN": "As-Suwad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17316",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Al Hujrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17317",
    "CITYNAME_AR": "خشم السرير",
    "CITYNAME_EN": "Khasham As-Sarer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17318",
    "CITYNAME_AR": "الابهر",
    "CITYNAME_EN": "Al Abhar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17319",
    "CITYNAME_AR": "الظويهر",
    "CITYNAME_EN": "Az-Zuwayher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17320",
    "CITYNAME_AR": "مغيضه",
    "CITYNAME_EN": "Mughaydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17321",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Al Meshash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17322",
    "CITYNAME_AR": "آل حويل",
    "CITYNAME_EN": "Al Huwail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17323",
    "CITYNAME_AR": "البراق",
    "CITYNAME_EN": "Al Buraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17324",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Al Khayal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17325",
    "CITYNAME_AR": "مخشوشه",
    "CITYNAME_EN": "Makhshoushah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17326",
    "CITYNAME_AR": "الحقو",
    "CITYNAME_EN": "Al Haqu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17327",
    "CITYNAME_AR": "الجربش",
    "CITYNAME_EN": "Al Haresh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17328",
    "CITYNAME_AR": "المنقب",
    "CITYNAME_EN": "Al Manqab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17329",
    "CITYNAME_AR": "نعمان (معشوقه)",
    "CITYNAME_EN": "Nuaman (Maashouqah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17330",
    "CITYNAME_AR": "القضيه",
    "CITYNAME_EN": "Al Qadyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17331",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Al Hamraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17332",
    "CITYNAME_AR": "الدارين",
    "CITYNAME_EN": "Ad-Darayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17333",
    "CITYNAME_AR": "حدبة غليفه",
    "CITYNAME_EN": "Hadabah Ghulayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17334",
    "CITYNAME_AR": "الكديد",
    "CITYNAME_EN": "Al Kaded",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17335",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Al Hafnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17336",
    "CITYNAME_AR": "النبيعه",
    "CITYNAME_EN": "An-Nabeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17337",
    "CITYNAME_AR": "حدبة المصينة",
    "CITYNAME_EN": "Hadabah Al Musaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17338",
    "CITYNAME_AR": "المحارزه",
    "CITYNAME_EN": "Al Maharzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17339",
    "CITYNAME_AR": "الحضا",
    "CITYNAME_EN": "Al Hada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17340",
    "CITYNAME_AR": "الهديف",
    "CITYNAME_EN": "Al Hudayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17341",
    "CITYNAME_AR": "حماده",
    "CITYNAME_EN": "Hamadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17342",
    "CITYNAME_AR": "قرى الجوف",
    "CITYNAME_EN": "Al Jawf villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17343",
    "CITYNAME_AR": "الطنب",
    "CITYNAME_EN": "At-Tanab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17344",
    "CITYNAME_AR": "المشرقه",
    "CITYNAME_EN": "Al Mashraqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17345",
    "CITYNAME_AR": "القبل",
    "CITYNAME_EN": "Al Qobal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17346",
    "CITYNAME_AR": "الصعب",
    "CITYNAME_EN": "As-Saab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17347",
    "CITYNAME_AR": "قريه البحريه",
    "CITYNAME_EN": "Al Baharyah village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17348",
    "CITYNAME_AR": "ام شط (الشط)",
    "CITYNAME_EN": "Om Shatt (Ash-Shatt)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17349",
    "CITYNAME_AR": "العمران",
    "CITYNAME_EN": "Al Umran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17350",
    "CITYNAME_AR": "ام قضايا",
    "CITYNAME_EN": "Om Qadayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17351",
    "CITYNAME_AR": "فرعه القطعه",
    "CITYNAME_EN": "Faraah Al Qedaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17352",
    "CITYNAME_AR": "ام طانف",
    "CITYNAME_EN": "Om Tanef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17353",
    "CITYNAME_AR": "القرا",
    "CITYNAME_EN": "Al Qara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17354",
    "CITYNAME_AR": "الزغب",
    "CITYNAME_EN": "Az-Zaghab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17355",
    "CITYNAME_AR": "قرى ضبيل",
    "CITYNAME_EN": "Dabeel villages",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17356",
    "CITYNAME_AR": "الحقو ال زامل",
    "CITYNAME_EN": "Al Haqua Al Zamel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17357",
    "CITYNAME_AR": "الكديس",
    "CITYNAME_EN": "Al Kudayes",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17358",
    "CITYNAME_AR": "الحداب",
    "CITYNAME_EN": "Al Haddab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17359",
    "CITYNAME_AR": "حدبة الانفة",
    "CITYNAME_EN": "Hadabah Al Anafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17360",
    "CITYNAME_AR": "ام صفحه",
    "CITYNAME_EN": "Om Safahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17361",
    "CITYNAME_AR": "الخرية",
    "CITYNAME_EN": "Al Kharayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17362",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Quzaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17363",
    "CITYNAME_AR": "حدبة الغبيراء",
    "CITYNAME_EN": "Hadabat Al Ghubayraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17364",
    "CITYNAME_AR": "الرفغة",
    "CITYNAME_EN": "Ar-Rafaghah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17365",
    "CITYNAME_AR": "حقو الخضارى",
    "CITYNAME_EN": "Haqua Al Khadari",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17366",
    "CITYNAME_AR": "مبدا غاليه",
    "CITYNAME_EN": "Mabda Ghalyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17367",
    "CITYNAME_AR": "سلاحة",
    "CITYNAME_EN": "Selaha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17368",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Al Ghoreif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17369",
    "CITYNAME_AR": "الحوره",
    "CITYNAME_EN": "Alhawra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17370",
    "CITYNAME_AR": "ثلوث عماره",
    "CITYNAME_EN": "Thalouth Emarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17371",
    "CITYNAME_AR": "السبطه",
    "CITYNAME_EN": "As-Sabatah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17372",
    "CITYNAME_AR": "قرن حلوه",
    "CITYNAME_EN": "Qarn Helwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17373",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Al Ayenah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17374",
    "CITYNAME_AR": "الظهره",
    "CITYNAME_EN": "Az-Zuharah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17375",
    "CITYNAME_AR": "حدبه الحمراء",
    "CITYNAME_EN": "Hadabah Al Hamraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17376",
    "CITYNAME_AR": "الاشعاب",
    "CITYNAME_EN": "Al Ashaab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17377",
    "CITYNAME_AR": "حدبه الصيد",
    "CITYNAME_EN": "Hadabah As-Sayed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17378",
    "CITYNAME_AR": "الاجلح",
    "CITYNAME_EN": "Al Ajlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17379",
    "CITYNAME_AR": "رديحه",
    "CITYNAME_EN": "Rudayhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17380",
    "CITYNAME_AR": "الخل",
    "CITYNAME_EN": "Al Khal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17381",
    "CITYNAME_AR": "الظفاره",
    "CITYNAME_EN": "Az-Zufarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17382",
    "CITYNAME_AR": "الوصيل",
    "CITYNAME_EN": "Al Waseel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17383",
    "CITYNAME_AR": "البدله",
    "CITYNAME_EN": "Al Badalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17384",
    "CITYNAME_AR": "حقو البرايه",
    "CITYNAME_EN": "Haqou Al Barayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17385",
    "CITYNAME_AR": "حلف",
    "CITYNAME_EN": "Halaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17386",
    "CITYNAME_AR": "المخصر",
    "CITYNAME_EN": "Al Mukhser",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17387",
    "CITYNAME_AR": "قرن الاقرف",
    "CITYNAME_EN": "Qarn Al Aqraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17388",
    "CITYNAME_AR": "مشبعه",
    "CITYNAME_EN": "Mushbeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17389",
    "CITYNAME_AR": "قرن قريش",
    "CITYNAME_EN": "Qarn Quraish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17390",
    "CITYNAME_AR": "قرن هذيل",
    "CITYNAME_EN": "Qarn Huzayl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17391",
    "CITYNAME_AR": "القحده",
    "CITYNAME_EN": "Al Qahadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17392",
    "CITYNAME_AR": "الوجره",
    "CITYNAME_EN": "Al Wagrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17393",
    "CITYNAME_AR": "الفواغر",
    "CITYNAME_EN": "Al Fawagher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17394",
    "CITYNAME_AR": "الفواغر",
    "CITYNAME_EN": "Al Fawagher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17395",
    "CITYNAME_AR": "الفرش",
    "CITYNAME_EN": "Al Farsh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17396",
    "CITYNAME_AR": "الخبى",
    "CITYNAME_EN": "Al Khabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17397",
    "CITYNAME_AR": "خشم الباب",
    "CITYNAME_EN": "Khasham Al Bab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17398",
    "CITYNAME_AR": "قريه وادى نعمه",
    "CITYNAME_EN": "Wadi Neamah village",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17399",
    "CITYNAME_AR": "واصل",
    "CITYNAME_EN": "Wasel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17400",
    "CITYNAME_AR": "القهيبات",
    "CITYNAME_EN": "Al Quhaybat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17401",
    "CITYNAME_AR": "الخرسوع",
    "CITYNAME_EN": "Al Kharsoua",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17402",
    "CITYNAME_AR": "العصر",
    "CITYNAME_EN": "Al Asr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17403",
    "CITYNAME_AR": "الحبيل",
    "CITYNAME_EN": "Habeel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17404",
    "CITYNAME_AR": "كروان الحشرج",
    "CITYNAME_EN": "Karawan Al Hashraj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17405",
    "CITYNAME_AR": "كروان الجوز",
    "CITYNAME_EN": "Karawan Al Jawz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17406",
    "CITYNAME_AR": "كريان",
    "CITYNAME_EN": "Karyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17407",
    "CITYNAME_AR": "شعبن",
    "CITYNAME_EN": "Shuaban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17408",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17409",
    "CITYNAME_AR": "مغيليث",
    "CITYNAME_EN": "Mughaylath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17410",
    "CITYNAME_AR": "المخلفه",
    "CITYNAME_EN": "Al Makhlafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17411",
    "CITYNAME_AR": "الاسكان الخيري بنخال",
    "CITYNAME_EN": "Charity housing in Benkhal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17412",
    "CITYNAME_AR": "العرضيات",
    "CITYNAME_EN": "Civilizational center in Thareban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17413",
    "CITYNAME_AR": "نضد",
    "CITYNAME_EN": "Nadad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17414",
    "CITYNAME_AR": "القرده",
    "CITYNAME_EN": "Al Qeradah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17415",
    "CITYNAME_AR": "القفيلة",
    "CITYNAME_EN": "Al Qufaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17416",
    "CITYNAME_AR": "الوجد (الويد)",
    "CITYNAME_EN": "Al Wajd (Al Wabad)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17417",
    "CITYNAME_AR": "سلمى",
    "CITYNAME_EN": "Salmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "17418",
    "CITYNAME_AR": "غيبان",
    "CITYNAME_EN": "Ghiban ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "17419",
    "CITYNAME_AR": "الحائط",
    "CITYNAME_EN": "Alhayit",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17420",
    "CITYNAME_AR": "روضه الحاجليه",
    "CITYNAME_EN": "Ruduh Alhajiliuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17421",
    "CITYNAME_AR": "العهين",
    "CITYNAME_EN": "Aleahin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17422",
    "CITYNAME_AR": "الرقيطاء",
    "CITYNAME_EN": "Alraqita'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17423",
    "CITYNAME_AR": "بجيله",
    "CITYNAME_EN": "Bajilah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17424",
    "CITYNAME_AR": "الصخيره الشمالية",
    "CITYNAME_EN": "Alsakhiruh Alsabban",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17425",
    "CITYNAME_AR": "حمه",
    "CITYNAME_EN": "Humah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17426",
    "CITYNAME_AR": "ضليع الصوان",
    "CITYNAME_EN": "Dalie Alsawan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17427",
    "CITYNAME_AR": "ابو سهيلات",
    "CITYNAME_EN": "'Abu Sahilat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17428",
    "CITYNAME_AR": "ضليع الرفدى",
    "CITYNAME_EN": "Dalie Alrafdaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17429",
    "CITYNAME_AR": "الاشقر",
    "CITYNAME_EN": "Alashqir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17430",
    "CITYNAME_AR": "الناصفه",
    "CITYNAME_EN": "Alnnasifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17431",
    "CITYNAME_AR": "ام حرامل",
    "CITYNAME_EN": "Umm Haramil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17432",
    "CITYNAME_AR": "محير غنيم",
    "CITYNAME_EN": "Muhir Ghanim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17433",
    "CITYNAME_AR": "ثويليل",
    "CITYNAME_EN": "Thawaylil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17434",
    "CITYNAME_AR": "ابو سويسيات",
    "CITYNAME_EN": "'Abu Swisyat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17435",
    "CITYNAME_AR": "مقارن النعيمه",
    "CITYNAME_EN": "Maqarin Alnaeimih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17436",
    "CITYNAME_AR": "تلعه بجيله",
    "CITYNAME_EN": "Taleah Bijilah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17437",
    "CITYNAME_AR": "الوسيطا",
    "CITYNAME_EN": "Alwasita",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17438",
    "CITYNAME_AR": "العوشزى",
    "CITYNAME_EN": "Aleushzaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17439",
    "CITYNAME_AR": "مديسيس",
    "CITYNAME_EN": "Shilwan Wamudisis",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17440",
    "CITYNAME_AR": "فيضه اثقب",
    "CITYNAME_EN": "Fiduh Athqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17441",
    "CITYNAME_AR": "بدائع اثقب",
    "CITYNAME_EN": "Badayie Athqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17442",
    "CITYNAME_AR": "السيح",
    "CITYNAME_EN": "Alsiyh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17443",
    "CITYNAME_AR": "شقران الأصفر",
    "CITYNAME_EN": "Shuqran Al'Asfar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17444",
    "CITYNAME_AR": "بدائع الصفراء",
    "CITYNAME_EN": "Badayie Alsufara'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17445",
    "CITYNAME_AR": "العراديه",
    "CITYNAME_EN": "Alearadiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17446",
    "CITYNAME_AR": "عقلة اثقب",
    "CITYNAME_EN": "Euqlat Athqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17447",
    "CITYNAME_AR": "الباحه",
    "CITYNAME_EN": "Albahih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17448",
    "CITYNAME_AR": "عقيلة اثقب",
    "CITYNAME_EN": "Eaqilat Aithqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17449",
    "CITYNAME_AR": "مرحب العليا",
    "CITYNAME_EN": "Marhib Aleulya",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17450",
    "CITYNAME_AR": "عدوه اثقب",
    "CITYNAME_EN": "Eaduih Athqab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17451",
    "CITYNAME_AR": "بدائع الرويضات",
    "CITYNAME_EN": "Badayie Alruwaydat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17452",
    "CITYNAME_AR": "بدائع البادية",
    "CITYNAME_EN": "Badayie Albadia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17453",
    "CITYNAME_AR": "الشق",
    "CITYNAME_EN": "Alshiqu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17454",
    "CITYNAME_AR": "شعيب القرن",
    "CITYNAME_EN": "Shueayb Alqarn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17455",
    "CITYNAME_AR": "مرحب",
    "CITYNAME_EN": "Murahab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17456",
    "CITYNAME_AR": "انياب",
    "CITYNAME_EN": "Anyab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17457",
    "CITYNAME_AR": "بدائع العراديه",
    "CITYNAME_EN": "Bdayie Alearadih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17458",
    "CITYNAME_AR": "بدائع مرحب",
    "CITYNAME_EN": "Badayie Murahab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17459",
    "CITYNAME_AR": "بدائع الشق",
    "CITYNAME_EN": "Badayie Alshiq",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17460",
    "CITYNAME_AR": "الشهيبه",
    "CITYNAME_EN": "Alshahibuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17461",
    "CITYNAME_AR": "ام حريملات",
    "CITYNAME_EN": "Umm Hrymalat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17462",
    "CITYNAME_AR": "النابيه",
    "CITYNAME_EN": "Alnaabih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17463",
    "CITYNAME_AR": "الشويمس",
    "CITYNAME_EN": "Alshuwimis",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17464",
    "CITYNAME_AR": "ضروه شويمس",
    "CITYNAME_EN": "Duruwh Shawaymis",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17465",
    "CITYNAME_AR": "المزكا",
    "CITYNAME_EN": "Almazaka",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17466",
    "CITYNAME_AR": "كحله",
    "CITYNAME_EN": "Kahalah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17467",
    "CITYNAME_AR": "البرقه",
    "CITYNAME_EN": "Albarquh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17468",
    "CITYNAME_AR": "كرانيف",
    "CITYNAME_EN": "Karanif",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17469",
    "CITYNAME_AR": "الدغيبجه",
    "CITYNAME_EN": "Aldghybjh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17470",
    "CITYNAME_AR": "اعشيره",
    "CITYNAME_EN": "Aeshirh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17471",
    "CITYNAME_AR": "ابا الصبان",
    "CITYNAME_EN": "'Abana Alsaban",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17472",
    "CITYNAME_AR": "ام طريفه",
    "CITYNAME_EN": "Umm Tarifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17473",
    "CITYNAME_AR": "ابو صور",
    "CITYNAME_EN": "Umm Sur",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17474",
    "CITYNAME_AR": "خفيج بن مخلد",
    "CITYNAME_EN": "Khafij Bin Mukhalad",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17475",
    "CITYNAME_AR": "ابو حميده",
    "CITYNAME_EN": "'Abu Hamidh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17476",
    "CITYNAME_AR": "الشطيب",
    "CITYNAME_EN": "Alshatib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17477",
    "CITYNAME_AR": "المــريــر",
    "CITYNAME_EN": "Almryr",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17478",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Alruwduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17479",
    "CITYNAME_AR": "عقله الهضب",
    "CITYNAME_EN": "Eaqalah Alhadab",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17480",
    "CITYNAME_AR": "وسعة الرس",
    "CITYNAME_EN": "Wasaeat Alrasi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17481",
    "CITYNAME_AR": "بدع بن خلف",
    "CITYNAME_EN": "Bde Bin Khalf",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17482",
    "CITYNAME_AR": "المقارن",
    "CITYNAME_EN": "Almuqarin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17483",
    "CITYNAME_AR": "بدائع البدع",
    "CITYNAME_EN": "Bdayie Albadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17484",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Alrasu",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17485",
    "CITYNAME_AR": "وسيطاء البدع",
    "CITYNAME_EN": "Wasayata' Albadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17486",
    "CITYNAME_AR": "بويب البدع",
    "CITYNAME_EN": "Buayib Albadae",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17487",
    "CITYNAME_AR": "ام شعاري",
    "CITYNAME_EN": "Umm Shaeari",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17488",
    "CITYNAME_AR": "اللخين عبيد",
    "CITYNAME_EN": "Allikhayn Eubayd",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17489",
    "CITYNAME_AR": "البصر",
    "CITYNAME_EN": "Albasar",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17490",
    "CITYNAME_AR": "ابوزرايب",
    "CITYNAME_EN": "Abwzarayib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17491",
    "CITYNAME_AR": "شعب القور",
    "CITYNAME_EN": "Shaeb Alqur",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17492",
    "CITYNAME_AR": "الصخيره الجنوبيه",
    "CITYNAME_EN": "Alsakhiruh Aljanubiuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17493",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Alhafnuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17494",
    "CITYNAME_AR": "ابله",
    "CITYNAME_EN": "Ablah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17495",
    "CITYNAME_AR": "حميضه",
    "CITYNAME_EN": "Hamidih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17496",
    "CITYNAME_AR": "ساهبه ابو رمث",
    "CITYNAME_EN": "Sahibuh 'Abu Ramath",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17497",
    "CITYNAME_AR": "الضرس",
    "CITYNAME_EN": "Aldaras",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17498",
    "CITYNAME_AR": "المرموثه",
    "CITYNAME_EN": "Almarmuthuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17499",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Aleaqiluh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17500",
    "CITYNAME_AR": "الحويط",
    "CITYNAME_EN": "Alhawyt",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17501",
    "CITYNAME_AR": "المغدديه",
    "CITYNAME_EN": "Almughdidih",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17502",
    "CITYNAME_AR": "السهلة الحمراء",
    "CITYNAME_EN": "Alsahlat Alhamra'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17503",
    "CITYNAME_AR": "المحفر",
    "CITYNAME_EN": "Almuhafir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17504",
    "CITYNAME_AR": "وسيطاء علي",
    "CITYNAME_EN": "Wasayta' Ealiin",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17505",
    "CITYNAME_AR": "المناخ",
    "CITYNAME_EN": "Almunakh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17506",
    "CITYNAME_AR": "المجيصه",
    "CITYNAME_EN": "Almajisuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17507",
    "CITYNAME_AR": "المجصه",
    "CITYNAME_EN": "Almajasuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17508",
    "CITYNAME_AR": "غنيم",
    "CITYNAME_EN": "Ghanim",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17509",
    "CITYNAME_AR": "البوييضه",
    "CITYNAME_EN": "Albuayiduh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17510",
    "CITYNAME_AR": "ابو دويحات",
    "CITYNAME_EN": "'Abu Dwayhat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17511",
    "CITYNAME_AR": "الوسعه",
    "CITYNAME_EN": "Alwaseuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17512",
    "CITYNAME_AR": "الصور نادر",
    "CITYNAME_EN": "Alsuwar Nadir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17513",
    "CITYNAME_AR": "الكرشافه",
    "CITYNAME_EN": "Alkrshafh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17514",
    "CITYNAME_AR": "ابو رغلات",
    "CITYNAME_EN": "'Abu Raghalat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17515",
    "CITYNAME_AR": "الغريسه",
    "CITYNAME_EN": "Alghrysuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17516",
    "CITYNAME_AR": "المحنا",
    "CITYNAME_EN": "Almahana",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17517",
    "CITYNAME_AR": "الملاح",
    "CITYNAME_EN": "Almalah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17518",
    "CITYNAME_AR": "النجيل",
    "CITYNAME_EN": "Alnajil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17519",
    "CITYNAME_AR": "الغريسة الغربية",
    "CITYNAME_EN": "Algharisat Algharbia",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17520",
    "CITYNAME_AR": "قريه اللخين",
    "CITYNAME_EN": "Qryh Al Lkhyn",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17521",
    "CITYNAME_AR": "بدائع ساق",
    "CITYNAME_EN": "Qalban Al Badyei (Saq)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17522",
    "CITYNAME_AR": "الحسو",
    "CITYNAME_EN": "Alhusu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17523",
    "CITYNAME_AR": "المحامه",
    "CITYNAME_EN": "Almahamuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17524",
    "CITYNAME_AR": "الفرضوخيه",
    "CITYNAME_EN": "Alfardukhih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17525",
    "CITYNAME_AR": "الماويه",
    "CITYNAME_EN": "Almawih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17526",
    "CITYNAME_AR": "ابو مغير",
    "CITYNAME_EN": "Abu Mghyr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17527",
    "CITYNAME_AR": "فريضيخ",
    "CITYNAME_EN": "Fridikh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17528",
    "CITYNAME_AR": "بركه ابو مغيره (الربثه)",
    "CITYNAME_EN": "Birkih 'Abu Mughayruh (Alrabuthaha)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17529",
    "CITYNAME_AR": "الثمائل",
    "CITYNAME_EN": "Althamayil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17530",
    "CITYNAME_AR": "طلال",
    "CITYNAME_EN": "Talal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17531",
    "CITYNAME_AR": "بدائع الكوم",
    "CITYNAME_EN": "Bdayie Alkum",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17532",
    "CITYNAME_AR": "هدبان",
    "CITYNAME_EN": "Hadaban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17533",
    "CITYNAME_AR": "الشقيراء",
    "CITYNAME_EN": "Alshaqira'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17534",
    "CITYNAME_AR": "عقيربان",
    "CITYNAME_EN": "Eaqirban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17535",
    "CITYNAME_AR": "بلغه",
    "CITYNAME_EN": "Balaghah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17536",
    "CITYNAME_AR": "بليغه",
    "CITYNAME_EN": "Balighh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17537",
    "CITYNAME_AR": "النفازى",
    "CITYNAME_EN": "Alnafazaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17538",
    "CITYNAME_AR": "صخيبره",
    "CITYNAME_EN": "Sukhibiruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17539",
    "CITYNAME_AR": "بئرعبده",
    "CITYNAME_EN": "Byrebdh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17540",
    "CITYNAME_AR": "بطحى",
    "CITYNAME_EN": "Bathaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17541",
    "CITYNAME_AR": "شقيم الذيب",
    "CITYNAME_EN": "Shaqim Aldhiyb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17542",
    "CITYNAME_AR": "بئر معيتق",
    "CITYNAME_EN": "Muaytaq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17543",
    "CITYNAME_AR": "بئر عويد بن حميدان",
    "CITYNAME_EN": "Awwaid bin Humaidan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17544",
    "CITYNAME_AR": "بئر الرديهيه",
    "CITYNAME_EN": "Ar-Rudayhyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17545",
    "CITYNAME_AR": "بئر الخضيري",
    "CITYNAME_EN": "Al Khudayri well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17546",
    "CITYNAME_AR": "بئر سمران",
    "CITYNAME_EN": "Samran well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17547",
    "CITYNAME_AR": "بئر الرويضه",
    "CITYNAME_EN": "Ar-Ruwaidah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17548",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Al Qaryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17549",
    "CITYNAME_AR": "مثعر",
    "CITYNAME_EN": "Muthaar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17550",
    "CITYNAME_AR": "الجهيانه",
    "CITYNAME_EN": "Al Juhyanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17551",
    "CITYNAME_AR": "المسيجيد",
    "CITYNAME_EN": "Al Musayjid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17552",
    "CITYNAME_AR": "الخشيرمه",
    "CITYNAME_EN": "Al Khushyramah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17553",
    "CITYNAME_AR": "الثمامى",
    "CITYNAME_EN": "Ath-Thamami",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17554",
    "CITYNAME_AR": "كتيده",
    "CITYNAME_EN": "Kutaydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17555",
    "CITYNAME_AR": "المكيمل",
    "CITYNAME_EN": "Al Mukaymel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17556",
    "CITYNAME_AR": "ابار وتر",
    "CITYNAME_EN": "Abar Watar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17557",
    "CITYNAME_AR": "نزله عامر بن عمران",
    "CITYNAME_EN": "Nazlet Amer bin Omran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17558",
    "CITYNAME_AR": "نزله الصواعد",
    "CITYNAME_EN": "Nazlet As-Sawaed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17559",
    "CITYNAME_AR": "نزله هويمل (بئر جابر)",
    "CITYNAME_EN": "Nazet Huwaymel (Jaber well)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17560",
    "CITYNAME_AR": "بئر الحوازم",
    "CITYNAME_EN": "Al Hawazem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17561",
    "CITYNAME_AR": "ثعيل",
    "CITYNAME_EN": "Thuail",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17562",
    "CITYNAME_AR": "ابو لصب",
    "CITYNAME_EN": "Abo Lasab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17563",
    "CITYNAME_AR": "مشيط",
    "CITYNAME_EN": "Mashet",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17564",
    "CITYNAME_AR": "عين فليح",
    "CITYNAME_EN": "Ain Faleh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17565",
    "CITYNAME_AR": "نزله بشير",
    "CITYNAME_EN": "Nazlet Bashir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17566",
    "CITYNAME_AR": "اركه",
    "CITYNAME_EN": "Arkah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17567",
    "CITYNAME_AR": "صحيفه",
    "CITYNAME_EN": "Safehah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17568",
    "CITYNAME_AR": "ظمو",
    "CITYNAME_EN": "Thamu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17569",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hemah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17570",
    "CITYNAME_AR": "ريمه",
    "CITYNAME_EN": "Remah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17571",
    "CITYNAME_AR": "بهمان (نزلة الشيخ صادق)",
    "CITYNAME_EN": "Bahman (Nazlet Al-Sheikh Sadiq)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17572",
    "CITYNAME_AR": "نزله سليمان بن سالم",
    "CITYNAME_EN": "Nazlet Sulaiman bin Salem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17573",
    "CITYNAME_AR": "الدقيقه",
    "CITYNAME_EN": "Ad-Daqiqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17574",
    "CITYNAME_AR": "نزله عباس بن عساف",
    "CITYNAME_EN": "Nazlet Abbas bin Assaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17575",
    "CITYNAME_AR": "نزله محمد على بن عساف",
    "CITYNAME_EN": "Nazlet Mohamed Ali bin Assaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17576",
    "CITYNAME_AR": "المخباه العليا",
    "CITYNAME_EN": "Al Makhbaah Al Olya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17577",
    "CITYNAME_AR": "اللصيفه",
    "CITYNAME_EN": "Al Lasefah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17578",
    "CITYNAME_AR": "خيط الفضله",
    "CITYNAME_EN": "Khait Al Fadlah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17579",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Ar-Rahah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17580",
    "CITYNAME_AR": "الحميدانى",
    "CITYNAME_EN": "Al Humaydani",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17581",
    "CITYNAME_AR": "سقام",
    "CITYNAME_EN": "Seqam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17582",
    "CITYNAME_AR": "مسحوما",
    "CITYNAME_EN": "Mashooma",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17583",
    "CITYNAME_AR": "السنيف",
    "CITYNAME_EN": "As-Saneef",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17584",
    "CITYNAME_AR": "المثوبه",
    "CITYNAME_EN": "Al Mathwyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17585",
    "CITYNAME_AR": "الايثبه",
    "CITYNAME_EN": "Al Aythabah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17586",
    "CITYNAME_AR": "المضيح",
    "CITYNAME_EN": "Al Madeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17587",
    "CITYNAME_AR": "تنضب",
    "CITYNAME_EN": "Tandab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17588",
    "CITYNAME_AR": "الزباد",
    "CITYNAME_EN": "Az-Zubad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17589",
    "CITYNAME_AR": "صدر العنيق",
    "CITYNAME_EN": "Sadr Al Onayeq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17590",
    "CITYNAME_AR": "المغدار",
    "CITYNAME_EN": "Al Meghdar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17591",
    "CITYNAME_AR": "نزله الحميمه",
    "CITYNAME_EN": "Nazlet Al Humaymah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17592",
    "CITYNAME_AR": "نزله ربيع",
    "CITYNAME_EN": "Nazlet Rabie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17593",
    "CITYNAME_AR": "بئر حمد بن فرز",
    "CITYNAME_EN": "Hamad bin Farz well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17594",
    "CITYNAME_AR": "بئر ركك",
    "CITYNAME_EN": "Rakak well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17595",
    "CITYNAME_AR": "بئر خرص",
    "CITYNAME_EN": "Kharas well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17596",
    "CITYNAME_AR": "بئر محسن بن احمد",
    "CITYNAME_EN": "Mohsen bin Ahmed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17597",
    "CITYNAME_AR": "الثعيلبان",
    "CITYNAME_EN": "Ath-Thuaylaban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17598",
    "CITYNAME_AR": "بئر صالح بن حماد",
    "CITYNAME_EN": "Saleh bin Hammad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17599",
    "CITYNAME_AR": "بئر محمد سليم",
    "CITYNAME_EN": "Mohamed Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17600",
    "CITYNAME_AR": "بئر حاسن بن محمد",
    "CITYNAME_EN": "Biir Hassen bin Mohamed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17601",
    "CITYNAME_AR": "مزارع شعيب الفرع",
    "CITYNAME_EN": "Shuaib Al Faraa farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17602",
    "CITYNAME_AR": "المويلح",
    "CITYNAME_EN": "Almuwaylih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17603",
    "CITYNAME_AR": "خيف الحزامى",
    "CITYNAME_EN": "Kheif Al Huzami",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17604",
    "CITYNAME_AR": "أم الحسينات (القطار)",
    "CITYNAME_EN": "Om Al Husaynat (Al Qetar)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17605",
    "CITYNAME_AR": "ابورويث",
    "CITYNAME_EN": "Abo Ruwaith",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17606",
    "CITYNAME_AR": "الاثود الغربيه (السفلى - الدقيقه)",
    "CITYNAME_EN": "Al Athwad Al Gharbyah (As-Sufla - Ad-Daqiqah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17607",
    "CITYNAME_AR": "الاثود الشرقيه (العليا)",
    "CITYNAME_EN": "Al Athwad Ash-Sharqiyah (Al Olya)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17608",
    "CITYNAME_AR": "طلعه الماك",
    "CITYNAME_EN": "Talaah Al Maam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17609",
    "CITYNAME_AR": "عاصر",
    "CITYNAME_EN": "Ased (Rashid well)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17610",
    "CITYNAME_AR": "ام شعره",
    "CITYNAME_EN": "Om Shaarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17611",
    "CITYNAME_AR": "الثماميه",
    "CITYNAME_EN": "Al Thimamiyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17612",
    "CITYNAME_AR": "واسمه",
    "CITYNAME_EN": "Wasemah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17613",
    "CITYNAME_AR": "قصب",
    "CITYNAME_EN": "Qasab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17614",
    "CITYNAME_AR": "اليراكه",
    "CITYNAME_EN": "Al Barakah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17615",
    "CITYNAME_AR": "ربقى",
    "CITYNAME_EN": "Reqe",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17616",
    "CITYNAME_AR": "اثره العليا",
    "CITYNAME_EN": "Atherah Al Olya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17617",
    "CITYNAME_AR": "اثره السفلى (حارة الصربان)",
    "CITYNAME_EN": "Atherah As-Sufla (As-Surban alley)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17618",
    "CITYNAME_AR": "الواضح بشيبان",
    "CITYNAME_EN": "Al Wadeh, Sheban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17619",
    "CITYNAME_AR": "بئر العرفه",
    "CITYNAME_EN": "Al Arafah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17620",
    "CITYNAME_AR": "نزله الصمد",
    "CITYNAME_EN": "Nazlet As-Samad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17621",
    "CITYNAME_AR": "بئر قيضي",
    "CITYNAME_EN": "Qaydi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17622",
    "CITYNAME_AR": "المويرده",
    "CITYNAME_EN": "Al Muwayradah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17623",
    "CITYNAME_AR": "بئر حميد",
    "CITYNAME_EN": "Hameed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17624",
    "CITYNAME_AR": "أم فرق",
    "CITYNAME_EN": "Om Farq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17625",
    "CITYNAME_AR": "بئر سليم",
    "CITYNAME_EN": "Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17626",
    "CITYNAME_AR": "بئر حجيجه",
    "CITYNAME_EN": "Hugaygah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17627",
    "CITYNAME_AR": "بئر حمادى",
    "CITYNAME_EN": "Hamadi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17628",
    "CITYNAME_AR": "إسكان الأمير سلطان بن عبدالعزيز الخيري",
    "CITYNAME_EN": "Olaythah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17629",
    "CITYNAME_AR": "بئر جمعان",
    "CITYNAME_EN": "Jamaan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17630",
    "CITYNAME_AR": "ام السدر",
    "CITYNAME_EN": "Om As-Sedr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17631",
    "CITYNAME_AR": "مزرعه بخيت بن ناصر",
    "CITYNAME_EN": "Bakhait bin Naser farm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17632",
    "CITYNAME_AR": "بئر سمران",
    "CITYNAME_EN": "Samran well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17633",
    "CITYNAME_AR": "بئر سليمان",
    "CITYNAME_EN": "Sulaiman well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17634",
    "CITYNAME_AR": "بئر القرصه",
    "CITYNAME_EN": "Al Qarsah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17635",
    "CITYNAME_AR": "بئر رنه",
    "CITYNAME_EN": "Ranah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17636",
    "CITYNAME_AR": "بئر رويشد",
    "CITYNAME_EN": "Ruwaished well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17637",
    "CITYNAME_AR": "بئر فواز بن غالى",
    "CITYNAME_EN": "Fawwaz bin Ghali well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17638",
    "CITYNAME_AR": "بئر حمدان بن سليمان",
    "CITYNAME_EN": "Hamdan bin Sulaiman well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17639",
    "CITYNAME_AR": "بئر ابو حصاه",
    "CITYNAME_EN": "Abo Hasah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17640",
    "CITYNAME_AR": "بئر عوض",
    "CITYNAME_EN": "Awad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17641",
    "CITYNAME_AR": "بئر سليم",
    "CITYNAME_EN": "Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17642",
    "CITYNAME_AR": "بئر سلامه",
    "CITYNAME_EN": "Salamah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17643",
    "CITYNAME_AR": "بئر حمدان",
    "CITYNAME_EN": "Hamdan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17644",
    "CITYNAME_AR": "بئر شديد",
    "CITYNAME_EN": "Shadid well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17645",
    "CITYNAME_AR": "بئر نويمى",
    "CITYNAME_EN": "Nuwaymi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17646",
    "CITYNAME_AR": "بئر قنيع",
    "CITYNAME_EN": "Qanei well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17647",
    "CITYNAME_AR": "الحفاه",
    "CITYNAME_EN": "Al Hufah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17648",
    "CITYNAME_AR": "بئر بخيت",
    "CITYNAME_EN": "Bakhait well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17649",
    "CITYNAME_AR": "يدعه الوطيه",
    "CITYNAME_EN": "Yadaah Al Watayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17650",
    "CITYNAME_AR": "بئر سليمان",
    "CITYNAME_EN": "Sulaiman well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17651",
    "CITYNAME_AR": "بئر هديهد",
    "CITYNAME_EN": "Hudayhed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17652",
    "CITYNAME_AR": "بئر عطاالله",
    "CITYNAME_EN": "Atallah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17653",
    "CITYNAME_AR": "مزارع جبال صبح",
    "CITYNAME_EN": "Jebal Sabah farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17654",
    "CITYNAME_AR": "بئر ناصر (الصميده والدحيل)",
    "CITYNAME_EN": "Naser well (As-Sumaidah wa Ad-Duhail)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17655",
    "CITYNAME_AR": "بئر صلاح",
    "CITYNAME_EN": "Salah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17656",
    "CITYNAME_AR": "مفيتيلات",
    "CITYNAME_EN": "Mufaytelat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17657",
    "CITYNAME_AR": "بئر عقيل (ام سدره)",
    "CITYNAME_EN": "Aqeel well (Om Sadrah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17658",
    "CITYNAME_AR": "ام هشيم",
    "CITYNAME_EN": "Om Hasheem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17659",
    "CITYNAME_AR": "تناضب",
    "CITYNAME_EN": "Tanadub",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17660",
    "CITYNAME_AR": "النقويه",
    "CITYNAME_EN": "An-Nuqyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17661",
    "CITYNAME_AR": "وادى الشامي",
    "CITYNAME_EN": "Wadi Ash-Shami",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17662",
    "CITYNAME_AR": "بئر معتاد بن ربيعان",
    "CITYNAME_EN": "Muatad bin Rubayaan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17663",
    "CITYNAME_AR": "بئر الشرقى",
    "CITYNAME_EN": "Ash-Sharqi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17664",
    "CITYNAME_AR": "بير العبيري",
    "CITYNAME_EN": "Al Obairi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17665",
    "CITYNAME_AR": "بئر وقيت العبيدي",
    "CITYNAME_EN": "Waqeet Al Obaidi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17666",
    "CITYNAME_AR": "بئر ابن عمري",
    "CITYNAME_EN": "Ibn Omari well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17667",
    "CITYNAME_AR": "السدير",
    "CITYNAME_EN": "As-Sudair",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17668",
    "CITYNAME_AR": "علقه",
    "CITYNAME_EN": "Alaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17669",
    "CITYNAME_AR": "بيار راين",
    "CITYNAME_EN": "Bayar Rayen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17670",
    "CITYNAME_AR": "بئر حطيحط",
    "CITYNAME_EN": "Hutayhet well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17671",
    "CITYNAME_AR": "بئر مساعد",
    "CITYNAME_EN": "Mosaed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17672",
    "CITYNAME_AR": "ابيار المصنوع",
    "CITYNAME_EN": "Abyar Al Masno'a",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17673",
    "CITYNAME_AR": "بئر بخيت الصاعدي",
    "CITYNAME_EN": "Bakhait As-Saedi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17674",
    "CITYNAME_AR": "بئر حامد العمراني",
    "CITYNAME_EN": "Hamed Al Omrani well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17675",
    "CITYNAME_AR": "ابار حلقه (حلقه الغاير)",
    "CITYNAME_EN": "Halaqah wells (Halaqah Al Ghaber)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17676",
    "CITYNAME_AR": "مزارع عرفوط والقاحة",
    "CITYNAME_EN": "Arfout wa Al Qahah farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17677",
    "CITYNAME_AR": "عرفوط",
    "CITYNAME_EN": "Arfout",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17678",
    "CITYNAME_AR": "البويطن",
    "CITYNAME_EN": "Al Buwaytan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17679",
    "CITYNAME_AR": "بير معلا",
    "CITYNAME_EN": "Moallah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17680",
    "CITYNAME_AR": "بئر محمد بن رويعى",
    "CITYNAME_EN": "Byr Muhamad Bin Ruyeaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17681",
    "CITYNAME_AR": "بئر الروحاء",
    "CITYNAME_EN": "Ber Arrawha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17682",
    "CITYNAME_AR": "طرف ظبيه",
    "CITYNAME_EN": "Taraf Zabyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17683",
    "CITYNAME_AR": "نزله نافع الحجلى",
    "CITYNAME_EN": "Nazlet Nafei Al Hajalei",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17684",
    "CITYNAME_AR": "هبت",
    "CITYNAME_EN": "Habet",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17685",
    "CITYNAME_AR": "المسجد",
    "CITYNAME_EN": "Al Masjid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17686",
    "CITYNAME_AR": "بئر الرفيعي",
    "CITYNAME_EN": "Ar-Rufaei well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17687",
    "CITYNAME_AR": "بئر المسمى",
    "CITYNAME_EN": "Al Mosama well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17688",
    "CITYNAME_AR": "بئر الظمو",
    "CITYNAME_EN": "Az-Zamou well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17689",
    "CITYNAME_AR": "بئر معوض الرحيلي",
    "CITYNAME_EN": "Muouad Ar-Raheli well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17690",
    "CITYNAME_AR": "سفا",
    "CITYNAME_EN": "Safa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17691",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Ar-Rahbah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17692",
    "CITYNAME_AR": "الدف",
    "CITYNAME_EN": "Ad-Duf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17693",
    "CITYNAME_AR": "بئر المزيني",
    "CITYNAME_EN": "Al Muzayni well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17694",
    "CITYNAME_AR": "الشفيه",
    "CITYNAME_EN": "Al Shfeah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17695",
    "CITYNAME_AR": "المفرق",
    "CITYNAME_EN": "Al Mufreq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17696",
    "CITYNAME_AR": "المرتجه",
    "CITYNAME_EN": "Al Murtajah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17697",
    "CITYNAME_AR": "السليميه",
    "CITYNAME_EN": "As-Sulaymyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17698",
    "CITYNAME_AR": "الحار",
    "CITYNAME_EN": "Al Har",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17699",
    "CITYNAME_AR": "وادى الجى (بئر عاطي)",
    "CITYNAME_EN": "Wadi Al Jee (Ati well)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17700",
    "CITYNAME_AR": "بئر شرام",
    "CITYNAME_EN": "Sharram well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17701",
    "CITYNAME_AR": "بئر الفليق",
    "CITYNAME_EN": "Al Faylaq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17702",
    "CITYNAME_AR": "المعيزله",
    "CITYNAME_EN": "Al Moayzalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17703",
    "CITYNAME_AR": "المعيرج",
    "CITYNAME_EN": "Al Muairaj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17704",
    "CITYNAME_AR": "بئر سليم",
    "CITYNAME_EN": "Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17705",
    "CITYNAME_AR": "بئر الغنم",
    "CITYNAME_EN": "Al Ghanam well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17706",
    "CITYNAME_AR": "النخير",
    "CITYNAME_EN": "An-Nakhir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17707",
    "CITYNAME_AR": "البرك",
    "CITYNAME_EN": "Al Birk",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17708",
    "CITYNAME_AR": "ام عبس",
    "CITYNAME_EN": "Om Abas",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17709",
    "CITYNAME_AR": "بئر عبدالله",
    "CITYNAME_EN": "Abdullah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17710",
    "CITYNAME_AR": "معذبه",
    "CITYNAME_EN": "Muazabah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17711",
    "CITYNAME_AR": "ترجم الترعه",
    "CITYNAME_EN": "Tarjam At-Teraah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17712",
    "CITYNAME_AR": "نزله عبدالله الرتوعي",
    "CITYNAME_EN": "Nazlet Abdullah Ar-Ratuai",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17713",
    "CITYNAME_AR": "فشقه",
    "CITYNAME_EN": "Fashaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17714",
    "CITYNAME_AR": "السقيفه",
    "CITYNAME_EN": "As-Suqayfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17715",
    "CITYNAME_AR": "الواضح",
    "CITYNAME_EN": "Al Wadeih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17716",
    "CITYNAME_AR": "رف ثعيل الجنوبي",
    "CITYNAME_EN": "Raf Thuail Al Janufi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17717",
    "CITYNAME_AR": "نزله البحره (الصمد الاخضر)",
    "CITYNAME_EN": "Nazlet Al Baharah (As-Samad Al Akhdar)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17718",
    "CITYNAME_AR": "الحفياء",
    "CITYNAME_EN": "Al Hufyaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17719",
    "CITYNAME_AR": "بئر الصبخيه",
    "CITYNAME_EN": "As-Sabkhyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17720",
    "CITYNAME_AR": "ابار النجليه",
    "CITYNAME_EN": "An-Naglyah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17721",
    "CITYNAME_AR": "بئر اثفا",
    "CITYNAME_EN": "Athafah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17722",
    "CITYNAME_AR": "بئرالحلوه",
    "CITYNAME_EN": "Al Helwah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17723",
    "CITYNAME_AR": "شعيب الحار",
    "CITYNAME_EN": "Shouib Al Har",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17724",
    "CITYNAME_AR": "بئر صلاح",
    "CITYNAME_EN": "Salah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17725",
    "CITYNAME_AR": "السديرة",
    "CITYNAME_EN": "As Sadirah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17726",
    "CITYNAME_AR": "اللهبان",
    "CITYNAME_EN": "Al Lahban",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17727",
    "CITYNAME_AR": "المنهاه",
    "CITYNAME_EN": "Al Munhah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17728",
    "CITYNAME_AR": "المعيرج",
    "CITYNAME_EN": "Al Muairaj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17729",
    "CITYNAME_AR": "السمره",
    "CITYNAME_EN": "As-Samarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17730",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Al Hemah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17731",
    "CITYNAME_AR": "الأوجر",
    "CITYNAME_EN": "Al Awjar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17732",
    "CITYNAME_AR": "شثيث",
    "CITYNAME_EN": "Shatheth",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17733",
    "CITYNAME_AR": "شعب زيد",
    "CITYNAME_EN": "Shuab Zayed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17734",
    "CITYNAME_AR": "مزارع ألاب",
    "CITYNAME_EN": "Alab farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17735",
    "CITYNAME_AR": "حوطه سدير",
    "CITYNAME_EN": "Hautat Sudair",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "17743",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "Alsalil",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "17766",
    "CITYNAME_AR": "ثرب",
    "CITYNAME_EN": "Therb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17767",
    "CITYNAME_AR": "المنصاء",
    "CITYNAME_EN": "Al Mansaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17768",
    "CITYNAME_AR": "فيضة غازي",
    "CITYNAME_EN": "Faydat Ghazi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17769",
    "CITYNAME_AR": "رشيدة",
    "CITYNAME_EN": "Rashida",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17770",
    "CITYNAME_AR": "العشاي",
    "CITYNAME_EN": "Al Ashai",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17771",
    "CITYNAME_AR": "بئر المشاعليه",
    "CITYNAME_EN": "Al Mashailyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17772",
    "CITYNAME_AR": "الهميجه",
    "CITYNAME_EN": "Al Humayjah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17773",
    "CITYNAME_AR": "الجياسر",
    "CITYNAME_EN": "Aljayaser",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17774",
    "CITYNAME_AR": "بئر مقحم القويره(فارس)",
    "CITYNAME_EN": "Moqhem Al Quwayrah well (Fares)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17775",
    "CITYNAME_AR": "الشامانية",
    "CITYNAME_EN": "Ash-Shamanyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17776",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Al Oqaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17777",
    "CITYNAME_AR": "الصابرية",
    "CITYNAME_EN": "As-Sabiryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17778",
    "CITYNAME_AR": "الزابنية",
    "CITYNAME_EN": "Az-Zabenyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17779",
    "CITYNAME_AR": "مريغان",
    "CITYNAME_EN": "Mareghan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17780",
    "CITYNAME_AR": "بريريق",
    "CITYNAME_EN": "Barbareeq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17781",
    "CITYNAME_AR": "البدنه",
    "CITYNAME_EN": "Al Badanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17782",
    "CITYNAME_AR": "دحموله",
    "CITYNAME_EN": "Dahmoulah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17783",
    "CITYNAME_AR": "الحامديه",
    "CITYNAME_EN": "Al Hamidyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17784",
    "CITYNAME_AR": "القبيه",
    "CITYNAME_EN": "Al Qubayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17785",
    "CITYNAME_AR": "عويشه",
    "CITYNAME_EN": "Awayshah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17786",
    "CITYNAME_AR": "الشعاله",
    "CITYNAME_EN": "Malfi well (Ash-Shualah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17787",
    "CITYNAME_AR": "آبار البدايع",
    "CITYNAME_EN": "Al Badyei wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17788",
    "CITYNAME_AR": "فيضة افجيج",
    "CITYNAME_EN": "Fajej Al Faydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17789",
    "CITYNAME_AR": "عسيلة",
    "CITYNAME_EN": "Usayla",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17790",
    "CITYNAME_AR": "مشاش الكراوين",
    "CITYNAME_EN": "Meshash Al Karaween",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17791",
    "CITYNAME_AR": "الصميما",
    "CITYNAME_EN": "As-Sumayma wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17792",
    "CITYNAME_AR": "اليوسفيه",
    "CITYNAME_EN": "Al Yusefyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17793",
    "CITYNAME_AR": "ام المدابغ",
    "CITYNAME_EN": "Ghomayed well (Om Al Madabegh)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17794",
    "CITYNAME_AR": "الطويلة",
    "CITYNAME_EN": "Al Taweylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17795",
    "CITYNAME_AR": "الصالحيه",
    "CITYNAME_EN": "Al-Salehiyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17796",
    "CITYNAME_AR": "بئر مسعف الذكره",
    "CITYNAME_EN": "Museif Az-Zakrah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17797",
    "CITYNAME_AR": "بئر الوبره",
    "CITYNAME_EN": "Al Wayrah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17798",
    "CITYNAME_AR": "الحزيزه",
    "CITYNAME_EN": "Al Huzayzah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17799",
    "CITYNAME_AR": "آبار النخلات",
    "CITYNAME_EN": "An-Nakhlat wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17800",
    "CITYNAME_AR": "بئر وزير الرفاعى (الذيبيه)",
    "CITYNAME_EN": "Wazir Ar-Rufaei well (Az-Zubaybah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17801",
    "CITYNAME_AR": "بئر دماسه",
    "CITYNAME_EN": "Damasah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17802",
    "CITYNAME_AR": "بئر سحاله ",
    "CITYNAME_EN": "Suhailah well (Suhalah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17803",
    "CITYNAME_AR": "السريحية",
    "CITYNAME_EN": "Al Surayheah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17804",
    "CITYNAME_AR": "الحميمه",
    "CITYNAME_EN": "Al Humaymah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17805",
    "CITYNAME_AR": "السويرقيه",
    "CITYNAME_EN": "Asuwayriqyeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17806",
    "CITYNAME_AR": "ابو خرجين",
    "CITYNAME_EN": "Abo Kharjeen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17807",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17808",
    "CITYNAME_AR": "الحجنه",
    "CITYNAME_EN": "Al Hajanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17809",
    "CITYNAME_AR": "وريثان",
    "CITYNAME_EN": "Warethan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17810",
    "CITYNAME_AR": "الجصه",
    "CITYNAME_EN": "Al Gassah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17811",
    "CITYNAME_AR": "العساف",
    "CITYNAME_EN": "Al Assaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17812",
    "CITYNAME_AR": "المربوكه",
    "CITYNAME_EN": "Al Maryoukah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17813",
    "CITYNAME_AR": "سوارق",
    "CITYNAME_EN": "Sawareq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17814",
    "CITYNAME_AR": "الوسطه",
    "CITYNAME_EN": "Al Wasetah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17815",
    "CITYNAME_AR": "الزرب",
    "CITYNAME_EN": "Az-Zarb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17816",
    "CITYNAME_AR": "قران",
    "CITYNAME_EN": "Qarran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17817",
    "CITYNAME_AR": "بئر اللقيحيه",
    "CITYNAME_EN": "Al Luqayhyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17818",
    "CITYNAME_AR": "بئر النمله",
    "CITYNAME_EN": "An-Namlah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17819",
    "CITYNAME_AR": "المصلى",
    "CITYNAME_EN": "Al Masli",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17820",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17821",
    "CITYNAME_AR": "بئر الرصيصه",
    "CITYNAME_EN": "Ar-Rusaysah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17822",
    "CITYNAME_AR": "ام لحيين",
    "CITYNAME_EN": "Om Laheen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17823",
    "CITYNAME_AR": "الحويمضه",
    "CITYNAME_EN": "Al Huwaymadah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17824",
    "CITYNAME_AR": "بئر الروضه",
    "CITYNAME_EN": "Ar-Rawdah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17825",
    "CITYNAME_AR": "السدره",
    "CITYNAME_EN": "As-Sedrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17826",
    "CITYNAME_AR": "ابار ابو عشره",
    "CITYNAME_EN": "Abo Eshrah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17827",
    "CITYNAME_AR": "بئر الصفينيه",
    "CITYNAME_EN": "As-Sufaynyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17828",
    "CITYNAME_AR": "بئر ابو جنيب",
    "CITYNAME_EN": "Abo Junayeb well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17829",
    "CITYNAME_AR": "الجصه العليا",
    "CITYNAME_EN": "Al Gassah Al Olya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17830",
    "CITYNAME_AR": "مذخره السويرقيه",
    "CITYNAME_EN": "Mazkharah As-Subraqyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17831",
    "CITYNAME_AR": "السوسيه",
    "CITYNAME_EN": "As-Suwaysah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17832",
    "CITYNAME_AR": "وادى الابطن",
    "CITYNAME_EN": "Wadi Al Abtan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17833",
    "CITYNAME_AR": "السوق",
    "CITYNAME_EN": "As-Souq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17834",
    "CITYNAME_AR": "الرمثيه",
    "CITYNAME_EN": "Ar-Ramthyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17835",
    "CITYNAME_AR": "المجيسير",
    "CITYNAME_EN": "Al Mugayser",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17836",
    "CITYNAME_AR": "حازم والشريفه",
    "CITYNAME_EN": "Hazem wa Ash-Sharifah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17837",
    "CITYNAME_AR": "مديسيس",
    "CITYNAME_EN": "Mudaysis",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17838",
    "CITYNAME_AR": "المساوق",
    "CITYNAME_EN": "Al Masaweq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17839",
    "CITYNAME_AR": "الروزه",
    "CITYNAME_EN": "Ar-Rawzah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17840",
    "CITYNAME_AR": "السرحيه",
    "CITYNAME_EN": "As-Sarhyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17841",
    "CITYNAME_AR": "باديه وادى الحمى",
    "CITYNAME_EN": "Badyah Wadi Al Hema",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17842",
    "CITYNAME_AR": "العريده",
    "CITYNAME_EN": "Al Auraydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17843",
    "CITYNAME_AR": "باديه بيضان (الحسك)",
    "CITYNAME_EN": "Badyah Baydan (Al Hasak)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17844",
    "CITYNAME_AR": "بئر الدمثه",
    "CITYNAME_EN": "Ad-Damathah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17845",
    "CITYNAME_AR": "الديوانيه",
    "CITYNAME_EN": "Ad-Dewanyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17846",
    "CITYNAME_AR": "الصعبيه",
    "CITYNAME_EN": "As Sabeyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17847",
    "CITYNAME_AR": "باديه الصعيب",
    "CITYNAME_EN": "Badyah As-Suaib",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17848",
    "CITYNAME_AR": "السليميه",
    "CITYNAME_EN": "As-Sulaymyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17849",
    "CITYNAME_AR": "ام شكاعه",
    "CITYNAME_EN": "Om Shukaah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17850",
    "CITYNAME_AR": "العميقه",
    "CITYNAME_EN": "Al Ameeqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17851",
    "CITYNAME_AR": "الذبال",
    "CITYNAME_EN": "Az-Zebal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17852",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17853",
    "CITYNAME_AR": "الشبرميه (الشبرم)",
    "CITYNAME_EN": "As-Shabramyah (Ash-Shabram)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17854",
    "CITYNAME_AR": "آبار الخبره",
    "CITYNAME_EN": "Al Khubarah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17855",
    "CITYNAME_AR": "الغويشيه",
    "CITYNAME_EN": "Al Ghuwayshyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17856",
    "CITYNAME_AR": "المسره",
    "CITYNAME_EN": "Al Masarrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17857",
    "CITYNAME_AR": "ابار العمود",
    "CITYNAME_EN": "Al Amood wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17858",
    "CITYNAME_AR": "ريع الشيابين",
    "CITYNAME_EN": "Rei Ash-Shayabeen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17859",
    "CITYNAME_AR": "ام السمر",
    "CITYNAME_EN": "Om As-Samar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17860",
    "CITYNAME_AR": "سهله المزرع",
    "CITYNAME_EN": "Sahlah Al Mazraa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17861",
    "CITYNAME_AR": "خنقة المزرع",
    "CITYNAME_EN": "Khanqah Al Mazraa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17862",
    "CITYNAME_AR": "بئر الفحيج (الفحيجيه)",
    "CITYNAME_EN": "Al Fahih well (Al Fahihah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17863",
    "CITYNAME_AR": "هباء",
    "CITYNAME_EN": "Hoba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17864",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Al Rayadah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17865",
    "CITYNAME_AR": "المنصة",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17866",
    "CITYNAME_AR": "ابار الخفيق",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17867",
    "CITYNAME_AR": "انبوان",
    "CITYNAME_EN": "Anbouan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17868",
    "CITYNAME_AR": "الصلحانيه",
    "CITYNAME_EN": "As-Salhanyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17869",
    "CITYNAME_AR": "مشوقه",
    "CITYNAME_EN": "Mashwaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17870",
    "CITYNAME_AR": "ام العراد",
    "CITYNAME_EN": "Umm Al Arrad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17871",
    "CITYNAME_AR": "الحومه",
    "CITYNAME_EN": "Al Hawmah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17872",
    "CITYNAME_AR": "الفعره (العقله)",
    "CITYNAME_EN": "Al Fuarah (Al Oqlah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17873",
    "CITYNAME_AR": "المحتجبه",
    "CITYNAME_EN": "Al Muhtajebah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17874",
    "CITYNAME_AR": "البراقيه",
    "CITYNAME_EN": "Al Barraqyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17875",
    "CITYNAME_AR": "عسيله",
    "CITYNAME_EN": "Usaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17876",
    "CITYNAME_AR": "سمر",
    "CITYNAME_EN": "Samar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17877",
    "CITYNAME_AR": "الرباعيه (الربيعيه)",
    "CITYNAME_EN": "Ar-Rubaiyah (Ar-Rubayayah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17878",
    "CITYNAME_AR": "شبرقان",
    "CITYNAME_EN": "Shabrqan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17879",
    "CITYNAME_AR": "النبيعيه",
    "CITYNAME_EN": "An-Nubayayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17880",
    "CITYNAME_AR": "وادى العشي (العش)",
    "CITYNAME_EN": "Wadi Al Asha (Al Osh)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17881",
    "CITYNAME_AR": "ام الصعانين",
    "CITYNAME_EN": "Om As-Saaneen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17882",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Al Meshash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17883",
    "CITYNAME_AR": "أم الرجوم",
    "CITYNAME_EN": "Om Ar-Rajoom",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17884",
    "CITYNAME_AR": "الهويمليه",
    "CITYNAME_EN": "Al Huwaymalyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17885",
    "CITYNAME_AR": "بئر سواميد",
    "CITYNAME_EN": "Suwamed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17886",
    "CITYNAME_AR": "انبيع",
    "CITYNAME_EN": "Anbei",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17887",
    "CITYNAME_AR": "الدمثية",
    "CITYNAME_EN": "Ad-Damthyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17888",
    "CITYNAME_AR": "السعدان",
    "CITYNAME_EN": "As-Saadan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17889",
    "CITYNAME_AR": "ابار الرشا",
    "CITYNAME_EN": "Ar-Rasha wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17890",
    "CITYNAME_AR": "ابار المايين",
    "CITYNAME_EN": "Al Mayeen wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17891",
    "CITYNAME_AR": "بئر الشقيمة",
    "CITYNAME_EN": "Ash-Shuqaymah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17892",
    "CITYNAME_AR": "صفينه",
    "CITYNAME_EN": "Sufaynah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17893",
    "CITYNAME_AR": "النجيريه",
    "CITYNAME_EN": "An-Nugayryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17894",
    "CITYNAME_AR": "البختيه",
    "CITYNAME_EN": "Al Bakhtyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17895",
    "CITYNAME_AR": "الخفيق",
    "CITYNAME_EN": "Al Khafeq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17896",
    "CITYNAME_AR": "وادى بيضان",
    "CITYNAME_EN": "Wadi Baydan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17897",
    "CITYNAME_AR": "نجار",
    "CITYNAME_EN": "Najjar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17898",
    "CITYNAME_AR": "عويجاء",
    "CITYNAME_EN": "Awaijaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17899",
    "CITYNAME_AR": "الموسمية",
    "CITYNAME_EN": "Al Mosemyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17900",
    "CITYNAME_AR": "الوايلية",
    "CITYNAME_EN": "Al Waylyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17901",
    "CITYNAME_AR": "العلم",
    "CITYNAME_EN": "Al Alam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17902",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Al Hufayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17903",
    "CITYNAME_AR": "الغاشيه",
    "CITYNAME_EN": "Al Ghashyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17904",
    "CITYNAME_AR": "ابو حليفه",
    "CITYNAME_EN": "Abo Halefah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17905",
    "CITYNAME_AR": "عجلة",
    "CITYNAME_EN": "Ajalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17906",
    "CITYNAME_AR": "السراقي",
    "CITYNAME_EN": "As-Suraqi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17907",
    "CITYNAME_AR": "ام العراد",
    "CITYNAME_EN": "Umm Al Arrad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17908",
    "CITYNAME_AR": "لحف",
    "CITYNAME_EN": "Lahaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17909",
    "CITYNAME_AR": "حاذه",
    "CITYNAME_EN": "Hadha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17910",
    "CITYNAME_AR": "ام الغيران",
    "CITYNAME_EN": "Om Al Gheran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17911",
    "CITYNAME_AR": "مضره",
    "CITYNAME_EN": "Muddarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17912",
    "CITYNAME_AR": "قاع النقيب",
    "CITYNAME_EN": "Qai An-Naqeeb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17913",
    "CITYNAME_AR": "خبراء محمد",
    "CITYNAME_EN": "Khubraa Mohamed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17914",
    "CITYNAME_AR": "الحيل المحاجر (مشرفه)",
    "CITYNAME_EN": "Al Heyal Al Mahajer (Mushrefah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17915",
    "CITYNAME_AR": "الرفايع",
    "CITYNAME_EN": "Al Rofaya'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17916",
    "CITYNAME_AR": "الاصيحر",
    "CITYNAME_EN": "Al Asiehr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17917",
    "CITYNAME_AR": "ابوطلحه",
    "CITYNAME_EN": "Abo Talha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17918",
    "CITYNAME_AR": "الحزيمي (غدير)",
    "CITYNAME_EN": "Al Huzaymi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17919",
    "CITYNAME_AR": "الرجع (الحزيمه)",
    "CITYNAME_EN": "Ar-Rajaa (Al Huzaymah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17920",
    "CITYNAME_AR": "الدعكه",
    "CITYNAME_EN": "Ad-Daaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17921",
    "CITYNAME_AR": "الحسك",
    "CITYNAME_EN": "Al Hasak",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17922",
    "CITYNAME_AR": "الاصيحر الغربى",
    "CITYNAME_EN": "Al Osayhar Al Gharbi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17923",
    "CITYNAME_AR": "الحويل",
    "CITYNAME_EN": "Al Haweel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17924",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17925",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Om As-Salam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17926",
    "CITYNAME_AR": "الفرعيه",
    "CITYNAME_EN": "Alfiraeayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17927",
    "CITYNAME_AR": "القصر",
    "CITYNAME_EN": "Alqasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17928",
    "CITYNAME_AR": "العتيبيه",
    "CITYNAME_EN": "Aleatibiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17929",
    "CITYNAME_AR": "عانا",
    "CITYNAME_EN": "Eana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17930",
    "CITYNAME_AR": "النزهه",
    "CITYNAME_EN": "Alnazhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17931",
    "CITYNAME_AR": "الصلصله",
    "CITYNAME_EN": "Al Selselah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17932",
    "CITYNAME_AR": "الشفيه",
    "CITYNAME_EN": "Al Shfeah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17933",
    "CITYNAME_AR": "ملاح اللحن",
    "CITYNAME_EN": "Mallah Al Lahn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17934",
    "CITYNAME_AR": "العفيره",
    "CITYNAME_EN": "Al Ofayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17935",
    "CITYNAME_AR": "دمعان",
    "CITYNAME_EN": "Damaan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17936",
    "CITYNAME_AR": "ام هشيم",
    "CITYNAME_EN": "Om Hasheem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17937",
    "CITYNAME_AR": "وادى العين",
    "CITYNAME_EN": "Wadi Al Ein",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17938",
    "CITYNAME_AR": "عين طواله",
    "CITYNAME_EN": "Ain Tawalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17939",
    "CITYNAME_AR": "ابو زباير (العين)",
    "CITYNAME_EN": "'Abu Zabayir (Aleayn)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17940",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Al Oqaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17941",
    "CITYNAME_AR": "المطاوى",
    "CITYNAME_EN": "Al Matawi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17942",
    "CITYNAME_AR": "وادى الغرس",
    "CITYNAME_EN": "Wa'Adaa Alghars",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17943",
    "CITYNAME_AR": "مقيلات",
    "CITYNAME_EN": "Muqilat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17944",
    "CITYNAME_AR": "الدحيله",
    "CITYNAME_EN": "Aldahiluh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17945",
    "CITYNAME_AR": "الصلصله القديمه",
    "CITYNAME_EN": "Alsilsiluh Alqadimuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17946",
    "CITYNAME_AR": "الخيال",
    "CITYNAME_EN": "Alkhayal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17947",
    "CITYNAME_AR": "اللحن",
    "CITYNAME_EN": "Allahn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17948",
    "CITYNAME_AR": "بئر الميراد كتيفه",
    "CITYNAME_EN": "Biir Almirad Katifuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17949",
    "CITYNAME_AR": "سليلة جهينه",
    "CITYNAME_EN": "Salilat Jahinh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17950",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Almashash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17951",
    "CITYNAME_AR": "السليله القديمه",
    "CITYNAME_EN": "Alsaliluh Alqadimuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17952",
    "CITYNAME_AR": "الفريدة (المبروكة)",
    "CITYNAME_EN": "Alfarida (Almabruakat)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17953",
    "CITYNAME_AR": "المتنه",
    "CITYNAME_EN": "Almatnuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17954",
    "CITYNAME_AR": "الجذيبة",
    "CITYNAME_EN": "Aljadhiba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17955",
    "CITYNAME_AR": "الفشغات",
    "CITYNAME_EN": "Alfashaghat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17956",
    "CITYNAME_AR": "ابوحرامل",
    "CITYNAME_EN": "Abwhramil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17957",
    "CITYNAME_AR": "الصديفاء",
    "CITYNAME_EN": "Alsidifa'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17958",
    "CITYNAME_AR": "الزباير الحديثة",
    "CITYNAME_EN": "Azabayir Alhaditha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17959",
    "CITYNAME_AR": "العبيد",
    "CITYNAME_EN": "Aleabid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17960",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Almurabae",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17961",
    "CITYNAME_AR": "النوره",
    "CITYNAME_EN": "Alnuwruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17962",
    "CITYNAME_AR": "البييرات",
    "CITYNAME_EN": "Albyyrat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17963",
    "CITYNAME_AR": "بئر البديع (البديع القديم)",
    "CITYNAME_EN": "Bir Albadie (Albdie Alqadiym)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17964",
    "CITYNAME_AR": "المراميه",
    "CITYNAME_EN": "Almuramih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17965",
    "CITYNAME_AR": "مجيراء",
    "CITYNAME_EN": "Majira'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17966",
    "CITYNAME_AR": "مقيرح الشرارات",
    "CITYNAME_EN": "Muqayrih Alshirarat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17967",
    "CITYNAME_AR": "اميره",
    "CITYNAME_EN": "Amyrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17968",
    "CITYNAME_AR": "محينش",
    "CITYNAME_EN": "Muhinish",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17969",
    "CITYNAME_AR": "خذوه",
    "CITYNAME_EN": "Khudhuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17970",
    "CITYNAME_AR": "عصمان",
    "CITYNAME_EN": "Esman",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17971",
    "CITYNAME_AR": "الضليعه",
    "CITYNAME_EN": "Aldalieuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17972",
    "CITYNAME_AR": "الخريم",
    "CITYNAME_EN": "Alkharim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17973",
    "CITYNAME_AR": "الزباير",
    "CITYNAME_EN": "Alzabayir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17974",
    "CITYNAME_AR": "القعره",
    "CITYNAME_EN": "Alqiearuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17975",
    "CITYNAME_AR": "الفقير",
    "CITYNAME_EN": "Alfaqir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17976",
    "CITYNAME_AR": "قصير عمودان",
    "CITYNAME_EN": "Qasir Emwdan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17977",
    "CITYNAME_AR": "السليله",
    "CITYNAME_EN": "Alsaliluh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17978",
    "CITYNAME_AR": "الحسيات",
    "CITYNAME_EN": "Alhusayat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17979",
    "CITYNAME_AR": "قعره الدومه",
    "CITYNAME_EN": "Qaeruh Alduwmuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17980",
    "CITYNAME_AR": "ام زرب",
    "CITYNAME_EN": "Am Zarab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17981",
    "CITYNAME_AR": "متان الحب",
    "CITYNAME_EN": "Matan Alhabi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17982",
    "CITYNAME_AR": "الفيضه",
    "CITYNAME_EN": "Alfayduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17983",
    "CITYNAME_AR": "الابرق",
    "CITYNAME_EN": "Alabriq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17984",
    "CITYNAME_AR": "الحفره",
    "CITYNAME_EN": "Alhafruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17985",
    "CITYNAME_AR": "حريمل",
    "CITYNAME_EN": "Harimil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17986",
    "CITYNAME_AR": "برمه",
    "CITYNAME_EN": "Baramih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17987",
    "CITYNAME_AR": "الغصن",
    "CITYNAME_EN": "Alghasan",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "17988",
    "CITYNAME_AR": "ام هشيم",
    "CITYNAME_EN": "Om Hasheem",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "17989",
    "CITYNAME_AR": "الشماسيه",
    "CITYNAME_EN": "Alshamasiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17990",
    "CITYNAME_AR": "البرجسيات",
    "CITYNAME_EN": "Alburjasiaat",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17991",
    "CITYNAME_AR": "الركيبينه",
    "CITYNAME_EN": "Alrikibinh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17992",
    "CITYNAME_AR": "الساروت",
    "CITYNAME_EN": "Alssarut",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17993",
    "CITYNAME_AR": "ام حزم",
    "CITYNAME_EN": "Am Huzm",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17994",
    "CITYNAME_AR": "النبقيه",
    "CITYNAME_EN": "Alnabqih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17995",
    "CITYNAME_AR": "الربيعيه",
    "CITYNAME_EN": "Alrabieih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17996",
    "CITYNAME_AR": "الركيه",
    "CITYNAME_EN": "Alrakih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17997",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Alruwayduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "17998",
    "CITYNAME_AR": "مخطط الهلال بني بجاد",
    "CITYNAME_EN": "Mukhatt Al Hilal bni Bijad ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "17999",
    "CITYNAME_AR": "مخطط بادية ال حسين",
    "CITYNAME_EN": "Mukhatt Badiyah Al Hussein ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18000",
    "CITYNAME_AR": "الاثنين",
    "CITYNAME_EN": "Al Ithnayn ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18001",
    "CITYNAME_AR": "الجحور",
    "CITYNAME_EN": "Al Johur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18002",
    "CITYNAME_AR": "القهبة",
    "CITYNAME_EN": "Al Qahbah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18003",
    "CITYNAME_AR": "ال حماد والشعبين",
    "CITYNAME_EN": "Al Hamad and Al Sha'bain ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18004",
    "CITYNAME_AR": "السوق (ال مسوق)",
    "CITYNAME_EN": "Al Souq (Al masouq)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18005",
    "CITYNAME_AR": "الرصعه",
    "CITYNAME_EN": "Al Ra'sah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18006",
    "CITYNAME_AR": "المضفاه",
    "CITYNAME_EN": "Al Medfah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18007",
    "CITYNAME_AR": "ذات يرنا",
    "CITYNAME_EN": "Zat Yerna ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18008",
    "CITYNAME_AR": "شعبه قريشه",
    "CITYNAME_EN": "Shi'ba Qarishah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18009",
    "CITYNAME_AR": "الدرس",
    "CITYNAME_EN": "Al Dars ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18010",
    "CITYNAME_AR": "المدماك والكطائم",
    "CITYNAME_EN": "Al Medmak and Al Ktaem ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18011",
    "CITYNAME_AR": "ال خثيم",
    "CITYNAME_EN": "Al Khathim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18012",
    "CITYNAME_AR": "ال مسلم",
    "CITYNAME_EN": "Al Muslim ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18013",
    "CITYNAME_AR": "حضوه ومركز اتصالات",
    "CITYNAME_EN": "Hadwah and Communication Center ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18014",
    "CITYNAME_AR": "ال ريمان",
    "CITYNAME_EN": "Al Reman",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18015",
    "CITYNAME_AR": "العطوف",
    "CITYNAME_EN": "Al Atuf ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18016",
    "CITYNAME_AR": "الرقو",
    "CITYNAME_EN": "Al Raqw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18017",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Al Gharabah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18018",
    "CITYNAME_AR": "ال مساعد",
    "CITYNAME_EN": "Al Mosa'ed ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18019",
    "CITYNAME_AR": "حوراء بللسمر",
    "CITYNAME_EN": "Hwraa Billasmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18020",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "Al-Sadr",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18021",
    "CITYNAME_AR": "ال مسهل",
    "CITYNAME_EN": "Al Meshil ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18022",
    "CITYNAME_AR": "شعب الرياع",
    "CITYNAME_EN": "Shi'b Al-Reba' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18023",
    "CITYNAME_AR": "ال بن شثه والفرعه",
    "CITYNAME_EN": "Al bin Shethah and Al-Far'a ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18024",
    "CITYNAME_AR": "العتمه",
    "CITYNAME_EN": "Al-Atmah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18025",
    "CITYNAME_AR": "شعيب الحائط",
    "CITYNAME_EN": "Sha'ib Al-Haet ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18026",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18027",
    "CITYNAME_AR": "الرهو",
    "CITYNAME_EN": "Arl-Rahw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18028",
    "CITYNAME_AR": "الكعمه وعطف بن خالد",
    "CITYNAME_EN": "Al-Ka'mah, and Atf bin Khalid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18029",
    "CITYNAME_AR": "ذا المرو",
    "CITYNAME_EN": "Za Al-Marw ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18030",
    "CITYNAME_AR": "ال مارد (المضحي)",
    "CITYNAME_EN": "Al Marid (Al-Madha)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18031",
    "CITYNAME_AR": "عطف بن زاهبه (مترك)",
    "CITYNAME_EN": "Atf bin Zahbah (Metrik)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18032",
    "CITYNAME_AR": "العسبله (القطايف)",
    "CITYNAME_EN": "Al-Asbalah (Al-Qatayif)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18033",
    "CITYNAME_AR": "ال اسحاق والمغوغى",
    "CITYNAME_EN": "Al Ishaq and Al-Maghughy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18034",
    "CITYNAME_AR": "ال مذكر وال سرور",
    "CITYNAME_EN": "Al Mezker and Al Srur ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18035",
    "CITYNAME_AR": "العطفه بوادى خرص",
    "CITYNAME_EN": "Al-Atfah bwadi Khars ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18036",
    "CITYNAME_AR": "القرن والجهيفه",
    "CITYNAME_EN": "Al-Qarn and Al-Jahifah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18037",
    "CITYNAME_AR": "غما العليا والسفلى",
    "CITYNAME_EN": "Ghama the upper and the lower ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18038",
    "CITYNAME_AR": "ال عفيف",
    "CITYNAME_EN": "Al Afif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18039",
    "CITYNAME_AR": "ال خالد",
    "CITYNAME_EN": "Al Khalid ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18040",
    "CITYNAME_AR": "القزعه وال الجريب",
    "CITYNAME_EN": "Al-Qaz'ah and Al Al-Gerib ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18041",
    "CITYNAME_AR": "القشيع",
    "CITYNAME_EN": "Al-Qashi' ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18042",
    "CITYNAME_AR": "الجزعه",
    "CITYNAME_EN": "Al-Jaz'ah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18043",
    "CITYNAME_AR": "رحب",
    "CITYNAME_EN": "Rahb",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18044",
    "CITYNAME_AR": "العطفه",
    "CITYNAME_EN": "Al-Atfah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18045",
    "CITYNAME_AR": "برارا",
    "CITYNAME_EN": "Barara ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18046",
    "CITYNAME_AR": "الخفيه",
    "CITYNAME_EN": "Al-Khafyah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18047",
    "CITYNAME_AR": "المستان",
    "CITYNAME_EN": "Al-Mestan ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18048",
    "CITYNAME_AR": "بلسمر",
    "CITYNAME_EN": "Billasmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18049",
    "CITYNAME_AR": "سمحة",
    "CITYNAME_EN": "Samahah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18050",
    "CITYNAME_AR": "عين وديد",
    "CITYNAME_EN": "Ain Wadeed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18051",
    "CITYNAME_AR": "العشاش",
    "CITYNAME_EN": "Al Shash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18052",
    "CITYNAME_AR": "المخيبيه",
    "CITYNAME_EN": "Al Mukhaybyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18053",
    "CITYNAME_AR": "البرث",
    "CITYNAME_EN": "Al Barth",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18054",
    "CITYNAME_AR": "الجرف",
    "CITYNAME_EN": "Al Jarf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18055",
    "CITYNAME_AR": "الرفيعة",
    "CITYNAME_EN": "Ar Rafi'ah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18056",
    "CITYNAME_AR": "الدحوه",
    "CITYNAME_EN": "Ad-Duhwah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18057",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Al Oqaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18058",
    "CITYNAME_AR": "غمره",
    "CITYNAME_EN": "Ghamra",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18059",
    "CITYNAME_AR": "المجرذيه",
    "CITYNAME_EN": "Al Magrazyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18060",
    "CITYNAME_AR": "ضويل",
    "CITYNAME_EN": "Dhawil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18061",
    "CITYNAME_AR": "شعيب ام رميث",
    "CITYNAME_EN": "Shueayb Umm Ramith",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18062",
    "CITYNAME_AR": "عشره",
    "CITYNAME_EN": "Eshrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18063",
    "CITYNAME_AR": "الفريعه",
    "CITYNAME_EN": "Alfarieuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18064",
    "CITYNAME_AR": "مقطع الرحى",
    "CITYNAME_EN": "Maqtae Alruhaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18065",
    "CITYNAME_AR": "الجحفه",
    "CITYNAME_EN": "Aljahfuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18066",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Alhafiruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18067",
    "CITYNAME_AR": "جدعاء",
    "CITYNAME_EN": "Jadea'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18068",
    "CITYNAME_AR": "الخراوع",
    "CITYNAME_EN": "Alkharawue",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18069",
    "CITYNAME_AR": "العرائد",
    "CITYNAME_EN": "Alearayid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18070",
    "CITYNAME_AR": "الحزم",
    "CITYNAME_EN": "Alhuzm",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "18074",
    "CITYNAME_AR": "رماح",
    "CITYNAME_EN": "Ramah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "18088",
    "CITYNAME_AR": "القصب",
    "CITYNAME_EN": "Al-Qassab",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "18095",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18096",
    "CITYNAME_AR": "دغش",
    "CITYNAME_EN": "Daghsh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18097",
    "CITYNAME_AR": "قريه وقهاوى ليف",
    "CITYNAME_EN": "Waqahwy Lif Village ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18098",
    "CITYNAME_AR": "وقاده السفلى",
    "CITYNAME_EN": "waqadah (the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18099",
    "CITYNAME_AR": "ليف الاعلى",
    "CITYNAME_EN": "Leef (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18100",
    "CITYNAME_AR": "الحمضه",
    "CITYNAME_EN": "Al Hamdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18101",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Allahyan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18102",
    "CITYNAME_AR": "اباد البيضاء",
    "CITYNAME_EN": "Abad Al-Baydaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18103",
    "CITYNAME_AR": "الجفن الاعلى",
    "CITYNAME_EN": "Al-Jefn (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18104",
    "CITYNAME_AR": "النغره",
    "CITYNAME_EN": "Al-Naghrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18105",
    "CITYNAME_AR": "المقصره(شواحط)",
    "CITYNAME_EN": "Al-Maqsarah (Shawahit)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18106",
    "CITYNAME_AR": "القطاعاء والقاع",
    "CITYNAME_EN": "Al-Qata'aa and Al-Qa' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18107",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "As Safraa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18108",
    "CITYNAME_AR": "المليح",
    "CITYNAME_EN": "Al-Malih",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18109",
    "CITYNAME_AR": "الثوده",
    "CITYNAME_EN": "Al-Thawdah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18110",
    "CITYNAME_AR": "جرب",
    "CITYNAME_EN": "Jarab",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18111",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18112",
    "CITYNAME_AR": "الحفنه",
    "CITYNAME_EN": "Al Hafnah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18113",
    "CITYNAME_AR": "غينه",
    "CITYNAME_EN": "Einah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18114",
    "CITYNAME_AR": "الدوسرى",
    "CITYNAME_EN": "Al-Dosary ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18115",
    "CITYNAME_AR": "الخفج والخفيج",
    "CITYNAME_EN": "Al-Khafj and Al-Khafij ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18116",
    "CITYNAME_AR": "الذراع",
    "CITYNAME_EN": "Al-Zera' ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18117",
    "CITYNAME_AR": "الابيرق",
    "CITYNAME_EN": "Al-Abairiq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18118",
    "CITYNAME_AR": "الفلق",
    "CITYNAME_EN": "Al-Falaq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18119",
    "CITYNAME_AR": "مجمع الاسكان الخيري",
    "CITYNAME_EN": "Charitable Housing Compound ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18120",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18121",
    "CITYNAME_AR": "الدحله",
    "CITYNAME_EN": "Al Dahla",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18122",
    "CITYNAME_AR": "الجفدره",
    "CITYNAME_EN": "Al-Jafdrah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18123",
    "CITYNAME_AR": "بئر الطوى وقاع شريان",
    "CITYNAME_EN": "Bir Al-Tawy and Qa' Sheryan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18124",
    "CITYNAME_AR": "الجبجبه",
    "CITYNAME_EN": "Al-Jabjabah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18125",
    "CITYNAME_AR": "قاع سحيم",
    "CITYNAME_EN": "Qa' Sahim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18126",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Al-Robwah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18127",
    "CITYNAME_AR": "الحائط",
    "CITYNAME_EN": "Al Ha'et",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18128",
    "CITYNAME_AR": "جميله",
    "CITYNAME_EN": "Jamilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18129",
    "CITYNAME_AR": "مهد الغبه",
    "CITYNAME_EN": "Mahd Al-Ghebbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18130",
    "CITYNAME_AR": "مطارده",
    "CITYNAME_EN": "Motaradah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18131",
    "CITYNAME_AR": "مهد شواحط",
    "CITYNAME_EN": "Mahd Shawahit ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18132",
    "CITYNAME_AR": "القضاء",
    "CITYNAME_EN": "Al-Qadaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18133",
    "CITYNAME_AR": "مهد عمق",
    "CITYNAME_EN": "Mahd omq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18134",
    "CITYNAME_AR": "الخمايل",
    "CITYNAME_EN": "Al-Khamayil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18135",
    "CITYNAME_AR": "الفريس",
    "CITYNAME_EN": "Al Faris ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18136",
    "CITYNAME_AR": "اللواء",
    "CITYNAME_EN": "Allewaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18137",
    "CITYNAME_AR": "السهمان",
    "CITYNAME_EN": "Al-Sahman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18138",
    "CITYNAME_AR": "الحشرج",
    "CITYNAME_EN": "Al Hashraj",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18139",
    "CITYNAME_AR": "المجيمر",
    "CITYNAME_EN": "Al-Majemer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18140",
    "CITYNAME_AR": "المطلوح",
    "CITYNAME_EN": "Al-Matlouh ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18141",
    "CITYNAME_AR": "السليم الأسفل",
    "CITYNAME_EN": "Al-Salim (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18142",
    "CITYNAME_AR": "الجذي",
    "CITYNAME_EN": "Al-Jezy ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18143",
    "CITYNAME_AR": "الصوايد ومزرعة الامير مشعل",
    "CITYNAME_EN": "Al-Sawayid and Prince Mesh'al Farm ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18144",
    "CITYNAME_AR": "معوض",
    "CITYNAME_EN": "Mo'awad ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18145",
    "CITYNAME_AR": "السليم الاعلى",
    "CITYNAME_EN": "Al-Salim (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18146",
    "CITYNAME_AR": "الكرى",
    "CITYNAME_EN": "Al Kara",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18147",
    "CITYNAME_AR": "الاثايبه",
    "CITYNAME_EN": "Al-Athabiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18148",
    "CITYNAME_AR": "ظبين",
    "CITYNAME_EN": "Zabin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18149",
    "CITYNAME_AR": "ام مصلى",
    "CITYNAME_EN": "Umm Mosly ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18150",
    "CITYNAME_AR": "الغار",
    "CITYNAME_EN": "Al-Ghar",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18151",
    "CITYNAME_AR": "الشرج الأسفل",
    "CITYNAME_EN": "Al-Sharj (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18152",
    "CITYNAME_AR": "الشرج الأعلى",
    "CITYNAME_EN": "Al-Sharj (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18153",
    "CITYNAME_AR": "الربض",
    "CITYNAME_EN": "Al-Reed",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18154",
    "CITYNAME_AR": "خره",
    "CITYNAME_EN": "Kharah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18155",
    "CITYNAME_AR": "اللغبه",
    "CITYNAME_EN": "Allaghbah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18156",
    "CITYNAME_AR": "شواحط",
    "CITYNAME_EN": "Shawahit",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18157",
    "CITYNAME_AR": "اعلى وادي خارم",
    "CITYNAME_EN": "A'la Wadi Kharim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18158",
    "CITYNAME_AR": "اسفل وادي خارم",
    "CITYNAME_EN": "Asfal Wadi Kharim",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18159",
    "CITYNAME_AR": "النعره",
    "CITYNAME_EN": "Al-Na'rah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18160",
    "CITYNAME_AR": "المليح الاعلى",
    "CITYNAME_EN": "Al-Malih (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18161",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Al-Khalif",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18162",
    "CITYNAME_AR": "القرى الاعلى",
    "CITYNAME_EN": "Al-Qora (the upper) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18163",
    "CITYNAME_AR": "القرى الاسفل",
    "CITYNAME_EN": "Al-Qora (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18164",
    "CITYNAME_AR": "الصباخ",
    "CITYNAME_EN": "Al Subaykh",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18165",
    "CITYNAME_AR": "الحدق",
    "CITYNAME_EN": "Al Hedq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18166",
    "CITYNAME_AR": "ام الثمام",
    "CITYNAME_EN": "Umm Al Thamam",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18167",
    "CITYNAME_AR": "السريح",
    "CITYNAME_EN": "Al-Sarih ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18168",
    "CITYNAME_AR": "شعبه الدرع والجديعه",
    "CITYNAME_EN": "She'bah Al-Der' and Al-Jadi'ah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18169",
    "CITYNAME_AR": "دثنه",
    "CITYNAME_EN": "Dathnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18170",
    "CITYNAME_AR": "الشطابه",
    "CITYNAME_EN": "Al-Shatbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18171",
    "CITYNAME_AR": "الحيموم",
    "CITYNAME_EN": "Al-Haymum ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18172",
    "CITYNAME_AR": "الشيق و هميجان",
    "CITYNAME_EN": "Al-Shiq and Hamijan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18173",
    "CITYNAME_AR": "النباعه",
    "CITYNAME_EN": "Al-Nab'ah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18174",
    "CITYNAME_AR": "الأراك الأعلى والأسفل",
    "CITYNAME_EN": "Al-Arak  (the upper and the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18175",
    "CITYNAME_AR": "النماره",
    "CITYNAME_EN": "Al-Namrah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18176",
    "CITYNAME_AR": "وادي الوابه",
    "CITYNAME_EN": "Wadi Al-Wabah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18177",
    "CITYNAME_AR": "أم عثمان",
    "CITYNAME_EN": "Umm Othman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18178",
    "CITYNAME_AR": "الحويل",
    "CITYNAME_EN": "Al-Howil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18179",
    "CITYNAME_AR": "العاصد",
    "CITYNAME_EN": "Al-Asid",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18180",
    "CITYNAME_AR": "العدنه",
    "CITYNAME_EN": "Al-Adnah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18181",
    "CITYNAME_AR": "ستيت",
    "CITYNAME_EN": "Satit ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18182",
    "CITYNAME_AR": "البعيثه",
    "CITYNAME_EN": "Al Boytha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18183",
    "CITYNAME_AR": "الأسحر",
    "CITYNAME_EN": "Al-Ashar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18184",
    "CITYNAME_AR": "الصميماء",
    "CITYNAME_EN": "Al-Samimaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18185",
    "CITYNAME_AR": "الحفاه",
    "CITYNAME_EN": "Al-Hufah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18186",
    "CITYNAME_AR": "الجاوه",
    "CITYNAME_EN": "Al Jawa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18187",
    "CITYNAME_AR": "السويسيه",
    "CITYNAME_EN": "Al-Suesiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18188",
    "CITYNAME_AR": "ال زياد العقاربه واهل الراس",
    "CITYNAME_EN": "Al Zeyad Al-Aqariyah and Ahl Al-Ras ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18189",
    "CITYNAME_AR": "الرقبان",
    "CITYNAME_EN": "Al-Reqban ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18190",
    "CITYNAME_AR": "الختاميه",
    "CITYNAME_EN": "Al-Khatamiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18191",
    "CITYNAME_AR": "ال بخات",
    "CITYNAME_EN": "Al Bekhat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18192",
    "CITYNAME_AR": "الشهابيه",
    "CITYNAME_EN": "Al-Shahabiyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18193",
    "CITYNAME_AR": "ال رداد ال سليم",
    "CITYNAME_EN": "Al Radad Al Selim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18194",
    "CITYNAME_AR": "الضبيعات",
    "CITYNAME_EN": "Al-Dobay'at ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18195",
    "CITYNAME_AR": "الحازم",
    "CITYNAME_EN": "Al-Hazim ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18196",
    "CITYNAME_AR": "الحضيرى",
    "CITYNAME_EN": "Al-Hedeiry ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18197",
    "CITYNAME_AR": "المطارفه",
    "CITYNAME_EN": "Al Matarefa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18198",
    "CITYNAME_AR": "الحازم الاعلى والاسفل",
    "CITYNAME_EN": "Al-Hazim (the upper and the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18199",
    "CITYNAME_AR": "الجدلان",
    "CITYNAME_EN": "Al Jadlan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18200",
    "CITYNAME_AR": "الزربه",
    "CITYNAME_EN": "Al-Zarbah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18201",
    "CITYNAME_AR": "اللغاميس",
    "CITYNAME_EN": "Al-Laghamis ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18202",
    "CITYNAME_AR": "ال دغمان",
    "CITYNAME_EN": "Al Deghman ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18203",
    "CITYNAME_AR": "المطيريه",
    "CITYNAME_EN": "Al Mutayriyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18204",
    "CITYNAME_AR": "الدحمه",
    "CITYNAME_EN": "Al-Dahmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18205",
    "CITYNAME_AR": "دهمه",
    "CITYNAME_EN": "Dahmah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18206",
    "CITYNAME_AR": "العطافه العليا",
    "CITYNAME_EN": "Al-Atafah (the upper)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18207",
    "CITYNAME_AR": "العطافه السفلى",
    "CITYNAME_EN": "Al-Atafah (the lower) ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18208",
    "CITYNAME_AR": "معشوقه",
    "CITYNAME_EN": "Ma'shouq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18209",
    "CITYNAME_AR": "ربذان",
    "CITYNAME_EN": "Rizan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18210",
    "CITYNAME_AR": "سالم",
    "CITYNAME_EN": "Salem",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18211",
    "CITYNAME_AR": "الفريشه",
    "CITYNAME_EN": "Al Farishah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18212",
    "CITYNAME_AR": "شعبة ابو شحامه",
    "CITYNAME_EN": "She'bah Abu Shehamah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18213",
    "CITYNAME_AR": "حلايل",
    "CITYNAME_EN": "Halayil ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18214",
    "CITYNAME_AR": "المراث",
    "CITYNAME_EN": "Al-Merath",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18215",
    "CITYNAME_AR": "نبهان",
    "CITYNAME_EN": " Nabhan",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18216",
    "CITYNAME_AR": "ابدان",
    "CITYNAME_EN": "Abdan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18217",
    "CITYNAME_AR": "الخربه الحمراء",
    "CITYNAME_EN": "Al-Kherbah Al-Hamraa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18218",
    "CITYNAME_AR": "الكظامه",
    "CITYNAME_EN": "Al-Kezamah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18219",
    "CITYNAME_AR": "البوره",
    "CITYNAME_EN": "Al Burah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18220",
    "CITYNAME_AR": "وادي انيام",
    "CITYNAME_EN": "Wadi Anyam ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18221",
    "CITYNAME_AR": "فاجه",
    "CITYNAME_EN": "Fajah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18222",
    "CITYNAME_AR": "الشراء",
    "CITYNAME_EN": "Al-Sheraa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18223",
    "CITYNAME_AR": "ليف",
    "CITYNAME_EN": "Leef ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18224",
    "CITYNAME_AR": "معشوقه",
    "CITYNAME_EN": "Ma'shouq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18225",
    "CITYNAME_AR": "بذال",
    "CITYNAME_EN": "Bezal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18226",
    "CITYNAME_AR": "نواخ",
    "CITYNAME_EN": "Nuwakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18227",
    "CITYNAME_AR": "رويعات زريق",
    "CITYNAME_EN": "Ruwaiat Zurayq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18228",
    "CITYNAME_AR": "تربة العليا",
    "CITYNAME_EN": "Turbat Al Olya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18229",
    "CITYNAME_AR": "تربة الخيالة",
    "CITYNAME_EN": "Turbah Al Khayalah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18230",
    "CITYNAME_AR": "مبايع",
    "CITYNAME_EN": "Mabayi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18231",
    "CITYNAME_AR": "فحلة",
    "CITYNAME_EN": "Falhah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18232",
    "CITYNAME_AR": "مبيع",
    "CITYNAME_EN": "Mabi'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18233",
    "CITYNAME_AR": "السريح",
    "CITYNAME_EN": "Al-Sarih",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18234",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18235",
    "CITYNAME_AR": "الخلفاء",
    "CITYNAME_EN": "Al Khulafaa",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18236",
    "CITYNAME_AR": "سهيله",
    "CITYNAME_EN": "Sahilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18237",
    "CITYNAME_AR": "السبتاء",
    "CITYNAME_EN": "Al-Sabtaa ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18238",
    "CITYNAME_AR": "الدكدك (النقعه)",
    "CITYNAME_EN": "Al-Dekdek (Al-Naq'ah)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18239",
    "CITYNAME_AR": "الفزار",
    "CITYNAME_EN": "Al-fazar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18240",
    "CITYNAME_AR": "القبه",
    "CITYNAME_EN": "Al-Qobah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18241",
    "CITYNAME_AR": "الاثنين",
    "CITYNAME_EN": "Al-Ithnayn ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18242",
    "CITYNAME_AR": "الضليف",
    "CITYNAME_EN": "Al-Dhalif",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18243",
    "CITYNAME_AR": "سعار",
    "CITYNAME_EN": "Se'ar ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18244",
    "CITYNAME_AR": "وادى مشان(موقع عام)",
    "CITYNAME_EN": "Wadi Meshan (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18245",
    "CITYNAME_AR": "الجزيلي",
    "CITYNAME_EN": "Al-Jazily ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18246",
    "CITYNAME_AR": "وادى ثاله(موقع عام)",
    "CITYNAME_EN": "Wadi Thalah (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18247",
    "CITYNAME_AR": "الشوحطه",
    "CITYNAME_EN": "Al Shuhtah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18248",
    "CITYNAME_AR": "فرشه الحده",
    "CITYNAME_EN": "Farshah Al-Heddah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18249",
    "CITYNAME_AR": "وادى بات(موقع عام)",
    "CITYNAME_EN": "Wadi Bat (Public Site)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18250",
    "CITYNAME_AR": "حموان",
    "CITYNAME_EN": "Hamwan ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18251",
    "CITYNAME_AR": "ضهياه",
    "CITYNAME_EN": "Dahyah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18252",
    "CITYNAME_AR": "بات",
    "CITYNAME_EN": "Bat ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18253",
    "CITYNAME_AR": "مليل",
    "CITYNAME_EN": "Maleel",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18254",
    "CITYNAME_AR": "البنان",
    "CITYNAME_EN": "Albanan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18255",
    "CITYNAME_AR": "الفريده",
    "CITYNAME_EN": "Alfariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18256",
    "CITYNAME_AR": "القحمه",
    "CITYNAME_EN": "Alqahmuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18257",
    "CITYNAME_AR": "سبت الجاره",
    "CITYNAME_EN": "Sabit Aljarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18258",
    "CITYNAME_AR": "حداب الموت",
    "CITYNAME_EN": "Hadab Almawt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18259",
    "CITYNAME_AR": "قطيطه",
    "CITYNAME_EN": "Qatituh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18260",
    "CITYNAME_AR": "قرن الخشبه",
    "CITYNAME_EN": "Qarn Alkhshbh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18261",
    "CITYNAME_AR": "سر العبد",
    "CITYNAME_EN": "Siru Aleabd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18262",
    "CITYNAME_AR": "قرن السلمى",
    "CITYNAME_EN": "Qarn Alsulmaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18263",
    "CITYNAME_AR": "حداب حباب",
    "CITYNAME_EN": "Hadab Habab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18264",
    "CITYNAME_AR": "المقعده",
    "CITYNAME_EN": "Almaqeaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18265",
    "CITYNAME_AR": "قرن الفطيح",
    "CITYNAME_EN": "Qarn Alfatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18266",
    "CITYNAME_AR": "جعار",
    "CITYNAME_EN": "Jaear",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18267",
    "CITYNAME_AR": "صبحا",
    "CITYNAME_EN": "Subhana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18268",
    "CITYNAME_AR": "اليتيمه",
    "CITYNAME_EN": "Alyatimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18269",
    "CITYNAME_AR": "بلاقه",
    "CITYNAME_EN": "Bilaqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18270",
    "CITYNAME_AR": "اسريحات",
    "CITYNAME_EN": "Asrihat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18271",
    "CITYNAME_AR": "الكلف",
    "CITYNAME_EN": "Alkalf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18272",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Aleutf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18273",
    "CITYNAME_AR": "الشصره",
    "CITYNAME_EN": "Alshasruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18274",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Alrahbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18275",
    "CITYNAME_AR": "حداب زيلع",
    "CITYNAME_EN": "Hadab Zaylae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18276",
    "CITYNAME_AR": "قياسه",
    "CITYNAME_EN": "Qiasuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18277",
    "CITYNAME_AR": "المزراب",
    "CITYNAME_EN": "Almizrab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18278",
    "CITYNAME_AR": "المقعد",
    "CITYNAME_EN": "Almaqead",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18279",
    "CITYNAME_AR": "قرن الحصاء",
    "CITYNAME_EN": "Qarn Alhisa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18280",
    "CITYNAME_AR": "الهضيبه",
    "CITYNAME_EN": "Alhadibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18281",
    "CITYNAME_AR": "دافنه",
    "CITYNAME_EN": "Dafinh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18282",
    "CITYNAME_AR": "المريبى",
    "CITYNAME_EN": "Almuribaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18283",
    "CITYNAME_AR": "الجيهه",
    "CITYNAME_EN": "Aljihah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18284",
    "CITYNAME_AR": "الكشريه",
    "CITYNAME_EN": "Alkashariuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18285",
    "CITYNAME_AR": "وشعه حمامه",
    "CITYNAME_EN": "Washaeah Himamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18286",
    "CITYNAME_AR": "الجبره",
    "CITYNAME_EN": "Aljabruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18287",
    "CITYNAME_AR": "الكتاد",
    "CITYNAME_EN": "Alkitad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18288",
    "CITYNAME_AR": "حقو القعود",
    "CITYNAME_EN": "Huqu Alqueud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18289",
    "CITYNAME_AR": "المقعد",
    "CITYNAME_EN": "Almaqead",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18290",
    "CITYNAME_AR": "غبطه",
    "CITYNAME_EN": "Ghabtah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18291",
    "CITYNAME_AR": "الضيقه",
    "CITYNAME_EN": "Aldiyquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18292",
    "CITYNAME_AR": "خرم المحرقه",
    "CITYNAME_EN": "Khurm Almuharaquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18293",
    "CITYNAME_AR": "القزه",
    "CITYNAME_EN": "Alquzuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18294",
    "CITYNAME_AR": "الوجده",
    "CITYNAME_EN": "Alwajduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18295",
    "CITYNAME_AR": "الحرفين",
    "CITYNAME_EN": "Alharfayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18296",
    "CITYNAME_AR": "الدحدحه",
    "CITYNAME_EN": "Aldahadhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18297",
    "CITYNAME_AR": "العلوب",
    "CITYNAME_EN": "Aleulub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18298",
    "CITYNAME_AR": "المخيرفه",
    "CITYNAME_EN": "Almukhayrafuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18299",
    "CITYNAME_AR": "العنابيات",
    "CITYNAME_EN": "Aleanabiat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18300",
    "CITYNAME_AR": "حرف الحمراء",
    "CITYNAME_EN": "Harf Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18301",
    "CITYNAME_AR": "حقو دكان",
    "CITYNAME_EN": "Haqu Dakan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18302",
    "CITYNAME_AR": "الشريج",
    "CITYNAME_EN": "Alsharij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18303",
    "CITYNAME_AR": "زبون",
    "CITYNAME_EN": "Zabun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18304",
    "CITYNAME_AR": "العينه",
    "CITYNAME_EN": "Aleaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18305",
    "CITYNAME_AR": "الصليلات",
    "CITYNAME_EN": "Alsalilat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18306",
    "CITYNAME_AR": "الموثب",
    "CITYNAME_EN": "Almuthib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18307",
    "CITYNAME_AR": "الدويخل",
    "CITYNAME_EN": "Alduwykhil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18308",
    "CITYNAME_AR": "حرف عبيا",
    "CITYNAME_EN": "Harf Eabiana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18309",
    "CITYNAME_AR": "الجشه",
    "CITYNAME_EN": "Aljushuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18310",
    "CITYNAME_AR": "منذر البحيره",
    "CITYNAME_EN": "Mundhir Albuhayrih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18311",
    "CITYNAME_AR": "قرن الفليح",
    "CITYNAME_EN": "Qarn Alfalih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18312",
    "CITYNAME_AR": "شسع",
    "CITYNAME_EN": "Shse",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18313",
    "CITYNAME_AR": "اللعباء",
    "CITYNAME_EN": "Allaeaba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18314",
    "CITYNAME_AR": "المرباء",
    "CITYNAME_EN": "Almurabba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18315",
    "CITYNAME_AR": "الكدره",
    "CITYNAME_EN": "Alkadaruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18316",
    "CITYNAME_AR": "حرف المروه",
    "CITYNAME_EN": "Harf Almaruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18317",
    "CITYNAME_AR": "فريده الصله",
    "CITYNAME_EN": "Fariduh Alsiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18318",
    "CITYNAME_AR": "حذراف",
    "CITYNAME_EN": "Hadhraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18319",
    "CITYNAME_AR": "مقفله",
    "CITYNAME_EN": "Muqfaluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18320",
    "CITYNAME_AR": "فقعه",
    "CITYNAME_EN": "Faqaeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18321",
    "CITYNAME_AR": "الدايله",
    "CITYNAME_EN": "Alddayiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18322",
    "CITYNAME_AR": "الخشناء",
    "CITYNAME_EN": "Alkhushana'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18323",
    "CITYNAME_AR": "قليته",
    "CITYNAME_EN": "Qilyath",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18324",
    "CITYNAME_AR": "القليته",
    "CITYNAME_EN": "Alqilyatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18325",
    "CITYNAME_AR": "منزل ال الشيخ",
    "CITYNAME_EN": "Manzil Al Alshaykh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18326",
    "CITYNAME_AR": "الشارقه",
    "CITYNAME_EN": "Alshaariquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18327",
    "CITYNAME_AR": "ابورقباء",
    "CITYNAME_EN": "Abwarqaba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18328",
    "CITYNAME_AR": "حادله",
    "CITYNAME_EN": "Hadilah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18329",
    "CITYNAME_AR": "المريبي",
    "CITYNAME_EN": "Almuribi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18330",
    "CITYNAME_AR": "المعقر",
    "CITYNAME_EN": "Almueaqir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18331",
    "CITYNAME_AR": "المثله (حداب الجرم)",
    "CITYNAME_EN": "Almithaluh (Hdab Aljaram)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18332",
    "CITYNAME_AR": "الفالق",
    "CITYNAME_EN": "Alfaliq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18333",
    "CITYNAME_AR": "القشله",
    "CITYNAME_EN": "Alqashluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18334",
    "CITYNAME_AR": "مسليه",
    "CITYNAME_EN": "Misilih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18335",
    "CITYNAME_AR": "الصبر",
    "CITYNAME_EN": "Alsabr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18336",
    "CITYNAME_AR": "سوداء رنيه",
    "CITYNAME_EN": "Sawda' Ranih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18337",
    "CITYNAME_AR": "ام التمر",
    "CITYNAME_EN": "Umm Altamaru",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18338",
    "CITYNAME_AR": "الحم",
    "CITYNAME_EN": "Alhumu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18339",
    "CITYNAME_AR": "القنواء",
    "CITYNAME_EN": "Alqinwa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18340",
    "CITYNAME_AR": "ام الرمف",
    "CITYNAME_EN": "Umm Alramaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18341",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18342",
    "CITYNAME_AR": "قزه",
    "CITYNAME_EN": "Qazah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18343",
    "CITYNAME_AR": "مصافى",
    "CITYNAME_EN": "Masafaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18344",
    "CITYNAME_AR": "حشه الزير",
    "CITYNAME_EN": "Hashah Alzayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18345",
    "CITYNAME_AR": "الحسنه",
    "CITYNAME_EN": "Alhasanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18346",
    "CITYNAME_AR": "الساده",
    "CITYNAME_EN": "Alssaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18347",
    "CITYNAME_AR": "ملاقى الاودية",
    "CITYNAME_EN": "Malaqaa Alawdy",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18348",
    "CITYNAME_AR": "النخيلات",
    "CITYNAME_EN": "Alnakhilat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18349",
    "CITYNAME_AR": "هيجه العيد",
    "CITYNAME_EN": "Hayjah Aleid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18350",
    "CITYNAME_AR": "خطم الجاره",
    "CITYNAME_EN": "Khatam Aljarih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18351",
    "CITYNAME_AR": "العضبه",
    "CITYNAME_EN": "Aledabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18352",
    "CITYNAME_AR": "مطراق",
    "CITYNAME_EN": "Mitraq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18353",
    "CITYNAME_AR": "بطحا",
    "CITYNAME_EN": "Bathaan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18354",
    "CITYNAME_AR": "هلاله",
    "CITYNAME_EN": "Hilalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18355",
    "CITYNAME_AR": "الكدوة",
    "CITYNAME_EN": "Alkudua",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18356",
    "CITYNAME_AR": "الظرفه",
    "CITYNAME_EN": "Alzarfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18357",
    "CITYNAME_AR": "السواد",
    "CITYNAME_EN": "Alsawad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18358",
    "CITYNAME_AR": "قرن الطويل",
    "CITYNAME_EN": "Qarn Altawil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18359",
    "CITYNAME_AR": "ثلاثاء الخرم",
    "CITYNAME_EN": "Thulatha' Alkharm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18360",
    "CITYNAME_AR": "خلفين",
    "CITYNAME_EN": "Khalfin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18361",
    "CITYNAME_AR": "الحبيج",
    "CITYNAME_EN": "Alhabij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18362",
    "CITYNAME_AR": "حداب الموت",
    "CITYNAME_EN": "Hadab Almawt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18363",
    "CITYNAME_AR": "مخشوش",
    "CITYNAME_EN": "Makhshush",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18364",
    "CITYNAME_AR": "مريبى",
    "CITYNAME_EN": "Muribana",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18365",
    "CITYNAME_AR": "الحشفه",
    "CITYNAME_EN": "Alhashfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18366",
    "CITYNAME_AR": "حشه بن ذهبه",
    "CITYNAME_EN": "Hashah Bin Dhahabih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18367",
    "CITYNAME_AR": "المجدع",
    "CITYNAME_EN": "Almajdae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18368",
    "CITYNAME_AR": "قرن البانه",
    "CITYNAME_EN": "Qarn Albanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18369",
    "CITYNAME_AR": "قرن الجرم",
    "CITYNAME_EN": "Qarn Aljuram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18370",
    "CITYNAME_AR": "الضيمران",
    "CITYNAME_EN": "Aldiamran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18371",
    "CITYNAME_AR": "الحديباء",
    "CITYNAME_EN": "Alhadiba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18372",
    "CITYNAME_AR": "ال بن عواض",
    "CITYNAME_EN": "Al Bin Eawwad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18373",
    "CITYNAME_AR": "بليص",
    "CITYNAME_EN": "Bilays",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18374",
    "CITYNAME_AR": "سياله",
    "CITYNAME_EN": "Sialuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18375",
    "CITYNAME_AR": "قرن الصرحه",
    "CITYNAME_EN": "Qarn Alsarhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18376",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18377",
    "CITYNAME_AR": "القطيفه",
    "CITYNAME_EN": "Alqatifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18378",
    "CITYNAME_AR": "شعب الكتاد",
    "CITYNAME_EN": "Shaeb Alkitad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18379",
    "CITYNAME_AR": "اللهب",
    "CITYNAME_EN": "Allahab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18380",
    "CITYNAME_AR": "دار بن مسرعه",
    "CITYNAME_EN": "Dar Bin Musraeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18381",
    "CITYNAME_AR": "قرن المجنه",
    "CITYNAME_EN": "Qarn Almajnuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18382",
    "CITYNAME_AR": "وصله",
    "CITYNAME_EN": "Wasalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18383",
    "CITYNAME_AR": "الوسيقات",
    "CITYNAME_EN": "Alwasiqat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18384",
    "CITYNAME_AR": "بئر الضيق",
    "CITYNAME_EN": "Biir Aldiyq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18385",
    "CITYNAME_AR": "ريعان",
    "CITYNAME_EN": "Riean",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18386",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Umm Alsilm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18387",
    "CITYNAME_AR": "غرور",
    "CITYNAME_EN": "Ghurur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18388",
    "CITYNAME_AR": "حداب الخيل",
    "CITYNAME_EN": "Hadab Alkhayl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18389",
    "CITYNAME_AR": "ام السلم",
    "CITYNAME_EN": "Umm Alsilm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18390",
    "CITYNAME_AR": "خميس حرب",
    "CITYNAME_EN": "Khamis Harb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18391",
    "CITYNAME_AR": "الحازميين",
    "CITYNAME_EN": "Al Hazmeen",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18392",
    "CITYNAME_AR": "مثله",
    "CITYNAME_EN": "Mathlah ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18393",
    "CITYNAME_AR": "المطاوى",
    "CITYNAME_EN": "Al-Matawy ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18394",
    "CITYNAME_AR": "جدة",
    "CITYNAME_EN": "Jida",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18395",
    "CITYNAME_AR": "ذهبان",
    "CITYNAME_EN": "Dhahban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18396",
    "CITYNAME_AR": "ثول",
    "CITYNAME_EN": "Thawl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18397",
    "CITYNAME_AR": "ام الرين",
    "CITYNAME_EN": "Umm Alrin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18398",
    "CITYNAME_AR": "مدار",
    "CITYNAME_EN": "Madar ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18399",
    "CITYNAME_AR": "تنومة",
    "CITYNAME_EN": "Tanawma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18400",
    "CITYNAME_AR": "ال سياره",
    "CITYNAME_EN": "Al Sayaruh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18401",
    "CITYNAME_AR": "تالبه",
    "CITYNAME_EN": "Talbah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18402",
    "CITYNAME_AR": "عتمه",
    "CITYNAME_EN": "Eatamah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18403",
    "CITYNAME_AR": "ال عبدالوهاب",
    "CITYNAME_EN": "Al Eabdaluhab",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18404",
    "CITYNAME_AR": "ترتع",
    "CITYNAME_EN": "Tartae",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18405",
    "CITYNAME_AR": "البطن",
    "CITYNAME_EN": "Albatn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18406",
    "CITYNAME_AR": "ابو فروه",
    "CITYNAME_EN": "'Abu Furuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18407",
    "CITYNAME_AR": "العطف",
    "CITYNAME_EN": "Aleutf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18408",
    "CITYNAME_AR": "ال لعلم",
    "CITYNAME_EN": "Al Laeilm",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18409",
    "CITYNAME_AR": "الصبيات",
    "CITYNAME_EN": "Alsabiyat",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18410",
    "CITYNAME_AR": "شعيبه",
    "CITYNAME_EN": "Shaeibh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18411",
    "CITYNAME_AR": "الحفير",
    "CITYNAME_EN": "Alhafir",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18412",
    "CITYNAME_AR": "المحاله",
    "CITYNAME_EN": "Almahaluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18413",
    "CITYNAME_AR": "ال خضارى",
    "CITYNAME_EN": "Al Khadaraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18414",
    "CITYNAME_AR": "ترج",
    "CITYNAME_EN": "Taraj",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18415",
    "CITYNAME_AR": "ال حسين",
    "CITYNAME_EN": "Al Husayn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18416",
    "CITYNAME_AR": "ال سنه",
    "CITYNAME_EN": "Al Sanah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18417",
    "CITYNAME_AR": "آل صخيف وآل جبار",
    "CITYNAME_EN": "Al Sakhif Wal Jabaar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18418",
    "CITYNAME_AR": "المركبه",
    "CITYNAME_EN": "Almarakabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18419",
    "CITYNAME_AR": "الحشاه",
    "CITYNAME_EN": "Alhishah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18420",
    "CITYNAME_AR": "مومه",
    "CITYNAME_EN": "Mawmah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18421",
    "CITYNAME_AR": "المستربه العليا والسفلى",
    "CITYNAME_EN": "Almustarabuh Aleulya Walsuflaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18422",
    "CITYNAME_AR": "العكاس",
    "CITYNAME_EN": "Aleukaas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18423",
    "CITYNAME_AR": "الحروف وذا الشيعه",
    "CITYNAME_EN": "Alhuruf Wadha Alshiyeih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18424",
    "CITYNAME_AR": "الاغوار",
    "CITYNAME_EN": "Alaghwar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18425",
    "CITYNAME_AR": "ال بوعلى",
    "CITYNAME_EN": "Al Buealaa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18426",
    "CITYNAME_AR": "الصدره والحله",
    "CITYNAME_EN": "Alsadruh Walhuluh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18427",
    "CITYNAME_AR": "ال مشرف والمقعيه",
    "CITYNAME_EN": "Al Musharaf Walmuqaeih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18428",
    "CITYNAME_AR": "ال بيضان",
    "CITYNAME_EN": "Al Baydan",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18429",
    "CITYNAME_AR": "الصنده",
    "CITYNAME_EN": "Alsanduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18430",
    "CITYNAME_AR": "ال شنيف",
    "CITYNAME_EN": "Al Shanif",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18431",
    "CITYNAME_AR": "الحلقه",
    "CITYNAME_EN": "Alhilquh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18432",
    "CITYNAME_AR": "الغبيب",
    "CITYNAME_EN": "Alghabib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18433",
    "CITYNAME_AR": "العظاه",
    "CITYNAME_EN": "Aleizzah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18434",
    "CITYNAME_AR": "السربه",
    "CITYNAME_EN": "Alsarabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18435",
    "CITYNAME_AR": "القاصله (الشرف)",
    "CITYNAME_EN": "Alqasiluh (Alsharfa)",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18436",
    "CITYNAME_AR": "ال سوده",
    "CITYNAME_EN": "Al Suduh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18437",
    "CITYNAME_AR": "الحمه",
    "CITYNAME_EN": "Alhumuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18438",
    "CITYNAME_AR": "برمه",
    "CITYNAME_EN": "Baramih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18439",
    "CITYNAME_AR": "لاربوعه",
    "CITYNAME_EN": "Larbweh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18440",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Alqarih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18441",
    "CITYNAME_AR": "ال علبه",
    "CITYNAME_EN": "Al Ealabuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18442",
    "CITYNAME_AR": "ال فليته",
    "CITYNAME_EN": "Al Falayatuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18443",
    "CITYNAME_AR": "اروى والخربه",
    "CITYNAME_EN": "Arwaa Walkharbuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18444",
    "CITYNAME_AR": "فرعه قريش",
    "CITYNAME_EN": "Fareah Quraysh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18445",
    "CITYNAME_AR": "القذال",
    "CITYNAME_EN": "Alqadhal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18446",
    "CITYNAME_AR": "الكرى",
    "CITYNAME_EN": "Alkuraa",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18447",
    "CITYNAME_AR": "الحيفه",
    "CITYNAME_EN": "Alhifuh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18448",
    "CITYNAME_AR": "العوصاء",
    "CITYNAME_EN": "Aleawsa'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18449",
    "CITYNAME_AR": "ترجس",
    "CITYNAME_EN": "Tarajas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18450",
    "CITYNAME_AR": "راس ساقين",
    "CITYNAME_EN": "Ras Saqin",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18451",
    "CITYNAME_AR": "منصبه",
    "CITYNAME_EN": "Mansibih",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18452",
    "CITYNAME_AR": "الخرسعه",
    "CITYNAME_EN": "Al-Kharsa'Ah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18453",
    "CITYNAME_AR": "عشره",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18454",
    "CITYNAME_AR": "المجرمه",
    "CITYNAME_EN": "Almujaramuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18455",
    "CITYNAME_AR": "شعيب المراعشه",
    "CITYNAME_EN": "Shueayb Almuraeishih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18456",
    "CITYNAME_AR": "العمرى الزويراء",
    "CITYNAME_EN": "Aleumraa Alzawira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18457",
    "CITYNAME_AR": "الدف",
    "CITYNAME_EN": "Aldafu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18458",
    "CITYNAME_AR": "القصيريه",
    "CITYNAME_EN": "Alqasiriuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18459",
    "CITYNAME_AR": "المرخه (الوزان)",
    "CITYNAME_EN": "Almarakhuh (Aluzan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18460",
    "CITYNAME_AR": "ابوفليح",
    "CITYNAME_EN": "Abwflih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18461",
    "CITYNAME_AR": "خيف السوق",
    "CITYNAME_EN": "Khayf Alsuwq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18462",
    "CITYNAME_AR": "المازنيه",
    "CITYNAME_EN": "Almazinih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18463",
    "CITYNAME_AR": "الجرنافه (والزابن)",
    "CITYNAME_EN": "Aljurnafih (Walzabna)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18464",
    "CITYNAME_AR": "جليله ويسير",
    "CITYNAME_EN": "Julaylah Wayasir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18465",
    "CITYNAME_AR": "حجر",
    "CITYNAME_EN": "Hajar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18466",
    "CITYNAME_AR": "الجوبه",
    "CITYNAME_EN": "Aljubuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18467",
    "CITYNAME_AR": "ام الطيور",
    "CITYNAME_EN": "Umm Altuyur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18468",
    "CITYNAME_AR": "مدسوس",
    "CITYNAME_EN": "Madasus",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18469",
    "CITYNAME_AR": "بئر الشعرى (حي عكاظ)",
    "CITYNAME_EN": "Biir Alshaeraa (Hy Eakaz)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18470",
    "CITYNAME_AR": "اللصيب",
    "CITYNAME_EN": "Allasib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18471",
    "CITYNAME_AR": "بئر الودينه",
    "CITYNAME_EN": "Biir Alwadinuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18472",
    "CITYNAME_AR": "بئر الاثاه",
    "CITYNAME_EN": "Biir Alathah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18473",
    "CITYNAME_AR": "البيار",
    "CITYNAME_EN": "Albiar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18474",
    "CITYNAME_AR": "ريع الخليص",
    "CITYNAME_EN": "Raye Alkhalis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18475",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Alnabaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18476",
    "CITYNAME_AR": "الظمو",
    "CITYNAME_EN": "Alzumu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18477",
    "CITYNAME_AR": "الزبيره",
    "CITYNAME_EN": "Alzabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18478",
    "CITYNAME_AR": "خسلف والشلول",
    "CITYNAME_EN": "Khasalaf Walshulul",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18479",
    "CITYNAME_AR": "يناعم",
    "CITYNAME_EN": "Yanaeim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18480",
    "CITYNAME_AR": "بئر العصبيه",
    "CITYNAME_EN": "Biir Aleasabiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18481",
    "CITYNAME_AR": "بئر ثرير",
    "CITYNAME_EN": "Biir Tharir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18482",
    "CITYNAME_AR": "بئر العوفى",
    "CITYNAME_EN": "Biir Aleawfaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18483",
    "CITYNAME_AR": "القوامه",
    "CITYNAME_EN": "Al Qawamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18484",
    "CITYNAME_AR": "بئر عينين",
    "CITYNAME_EN": "Beir Aynayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18485",
    "CITYNAME_AR": "تربه",
    "CITYNAME_EN": "Turayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18486",
    "CITYNAME_AR": "الديرة",
    "CITYNAME_EN": "Ad-Dayra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18487",
    "CITYNAME_AR": "المدره",
    "CITYNAME_EN": "Al Mudarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18488",
    "CITYNAME_AR": "الحائريه",
    "CITYNAME_EN": "Al Haeriah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18489",
    "CITYNAME_AR": "ذواد",
    "CITYNAME_EN": "Zuwad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18490",
    "CITYNAME_AR": "السردي",
    "CITYNAME_EN": "As-Sardi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18491",
    "CITYNAME_AR": "هجره بن غنام",
    "CITYNAME_EN": "Hijrah bin Ghannam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18492",
    "CITYNAME_AR": "الوطاه",
    "CITYNAME_EN": "Al Wat'a",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18493",
    "CITYNAME_AR": "المحنذ",
    "CITYNAME_EN": "Al Mahnaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18494",
    "CITYNAME_AR": "عصماء",
    "CITYNAME_EN": "Asmaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18495",
    "CITYNAME_AR": "دعيمر",
    "CITYNAME_EN": "Duaymar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18496",
    "CITYNAME_AR": "الجدر",
    "CITYNAME_EN": "Al Jadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18497",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Ar-Ruba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18498",
    "CITYNAME_AR": "حراضة السفلى",
    "CITYNAME_EN": "Haradah Al-Sufli",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18499",
    "CITYNAME_AR": "الغمورة",
    "CITYNAME_EN": "Al Ghamoura",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18500",
    "CITYNAME_AR": "المحوى",
    "CITYNAME_EN": "Al Mahwa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18501",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Al Ghuraba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18502",
    "CITYNAME_AR": "عنيزه",
    "CITYNAME_EN": "Unaizah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18503",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Al-Quway'iyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18504",
    "CITYNAME_AR": "مهدوراخ",
    "CITYNAME_EN": "Mahdurakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18505",
    "CITYNAME_AR": "الحشرج",
    "CITYNAME_EN": "Al Hashraj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18506",
    "CITYNAME_AR": "خد العشر",
    "CITYNAME_EN": "Khad Al Oshr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18507",
    "CITYNAME_AR": "ابوعروة",
    "CITYNAME_EN": "Abo Aurwa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18508",
    "CITYNAME_AR": "هجرة الديسه",
    "CITYNAME_EN": "Hijrah Ad-Daysah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18509",
    "CITYNAME_AR": "الخمره",
    "CITYNAME_EN": "Al Khamrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18510",
    "CITYNAME_AR": "حوال",
    "CITYNAME_EN": "Hawwal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18511",
    "CITYNAME_AR": "البتيراء",
    "CITYNAME_EN": "Al Bateraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18512",
    "CITYNAME_AR": "كتايدة",
    "CITYNAME_EN": "Kataydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18513",
    "CITYNAME_AR": "شعر",
    "CITYNAME_EN": "Sha'r",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18514",
    "CITYNAME_AR": "الحثرور",
    "CITYNAME_EN": "Al Hathrur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18515",
    "CITYNAME_AR": "زلاقه",
    "CITYNAME_EN": "Zalaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18516",
    "CITYNAME_AR": "الحشفه",
    "CITYNAME_EN": "Al Hashafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18517",
    "CITYNAME_AR": "العصله",
    "CITYNAME_EN": "Al Aasla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18518",
    "CITYNAME_AR": "الخضيراء",
    "CITYNAME_EN": "Al Khudayra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18519",
    "CITYNAME_AR": "العرقين",
    "CITYNAME_EN": "Alerqain",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18520",
    "CITYNAME_AR": "العابسيه",
    "CITYNAME_EN": "Al Abisyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18521",
    "CITYNAME_AR": "العلبه",
    "CITYNAME_EN": "Al-Alba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18522",
    "CITYNAME_AR": "وريقه",
    "CITYNAME_EN": "Ruwayqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18523",
    "CITYNAME_AR": "ظهاء",
    "CITYNAME_EN": "Zahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18524",
    "CITYNAME_AR": "الخالديه",
    "CITYNAME_EN": "Al Khalidyyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18525",
    "CITYNAME_AR": "الفيصليه",
    "CITYNAME_EN": "Al Faisaliyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18526",
    "CITYNAME_AR": "العلاوة",
    "CITYNAME_EN": "Al Elawa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18527",
    "CITYNAME_AR": "الخبيره",
    "CITYNAME_EN": "Al-Khabirah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18528",
    "CITYNAME_AR": "مهيوب العمائر",
    "CITYNAME_EN": "Mahyub Al-Amaer ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18529",
    "CITYNAME_AR": "العين",
    "CITYNAME_EN": "Al Ain",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18530",
    "CITYNAME_AR": "الظفير",
    "CITYNAME_EN": "Al-Zafir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18531",
    "CITYNAME_AR": "دفه",
    "CITYNAME_EN": "Dafah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18532",
    "CITYNAME_AR": "القريع",
    "CITYNAME_EN": "Al-Qari'",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18533",
    "CITYNAME_AR": "المخصر والالحاب",
    "CITYNAME_EN": "Al-Mekhsar and Al-Alhab ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18534",
    "CITYNAME_AR": "ابوالحصين",
    "CITYNAME_EN": "Abo Al-Hasin ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18535",
    "CITYNAME_AR": "ال ماشى وعنقه",
    "CITYNAME_EN": "Al mashy and Anqah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18536",
    "CITYNAME_AR": "نفق",
    "CITYNAME_EN": "Nafaq ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18537",
    "CITYNAME_AR": "المدير الاعلى والاسفل",
    "CITYNAME_EN": "Al-Madir  (the upper and the lower)",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18538",
    "CITYNAME_AR": "عرشا",
    "CITYNAME_EN": "Arsha ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18539",
    "CITYNAME_AR": "الرايم",
    "CITYNAME_EN": "Alraayim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18540",
    "CITYNAME_AR": "سليعه",
    "CITYNAME_EN": "Salieuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18541",
    "CITYNAME_AR": "الخنفه",
    "CITYNAME_EN": "Alkhanfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18542",
    "CITYNAME_AR": "الدويمة",
    "CITYNAME_EN": "Aldawima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18543",
    "CITYNAME_AR": "نمره",
    "CITYNAME_EN": "Namerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18544",
    "CITYNAME_AR": "المشاييخ",
    "CITYNAME_EN": "Al Mashayekh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18545",
    "CITYNAME_AR": "العرام",
    "CITYNAME_EN": "Al Arram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18546",
    "CITYNAME_AR": "الاحجر",
    "CITYNAME_EN": "Al Ahjar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18547",
    "CITYNAME_AR": "الفواز",
    "CITYNAME_EN": "Al Fawaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18548",
    "CITYNAME_AR": "دار الهندى",
    "CITYNAME_EN": "Dar Al Hendi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18549",
    "CITYNAME_AR": "الكتاده",
    "CITYNAME_EN": "Al Katadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18550",
    "CITYNAME_AR": "المزهر",
    "CITYNAME_EN": "Al Muzher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18551",
    "CITYNAME_AR": "المناع وهيله",
    "CITYNAME_EN": "Al Manaa Wahilah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18552",
    "CITYNAME_AR": "ال سكران",
    "CITYNAME_EN": "Al Sakran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18553",
    "CITYNAME_AR": "البساط",
    "CITYNAME_EN": "Al Busat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18554",
    "CITYNAME_AR": "جيفان",
    "CITYNAME_EN": "Jayfan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18555",
    "CITYNAME_AR": "العلايا",
    "CITYNAME_EN": "Al Olayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18556",
    "CITYNAME_AR": "المشاعث",
    "CITYNAME_EN": "Al Mushaeth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18557",
    "CITYNAME_AR": "حدبه قهبان",
    "CITYNAME_EN": "Hadabah Qahban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18558",
    "CITYNAME_AR": "الخرقان",
    "CITYNAME_EN": "Al Kherqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18559",
    "CITYNAME_AR": "الطبقه",
    "CITYNAME_EN": "At-Tabaqah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18560",
    "CITYNAME_AR": "القرن (قرن بن دجره)",
    "CITYNAME_EN": "Al Qarn (Qarn bin Dajarah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18561",
    "CITYNAME_AR": "الرهوه (رهوه بن عرمضه)",
    "CITYNAME_EN": "Ar-Rahwah (Rahwah bin Armadah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18562",
    "CITYNAME_AR": "العمره",
    "CITYNAME_EN": "Al Omrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18563",
    "CITYNAME_AR": "الشاطى",
    "CITYNAME_EN": "Ash-Shateah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18564",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18565",
    "CITYNAME_AR": "الوحله",
    "CITYNAME_EN": "Al Wahla",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18566",
    "CITYNAME_AR": "البجاليه",
    "CITYNAME_EN": "Al Bajalyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18567",
    "CITYNAME_AR": "دار ال زاهر",
    "CITYNAME_EN": "Dar Al Zaher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18568",
    "CITYNAME_AR": "الجميمان",
    "CITYNAME_EN": "Al Jumayman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18569",
    "CITYNAME_AR": "غره",
    "CITYNAME_EN": "Ghurah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18570",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Ar-Raqabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18571",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Al Qulah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18572",
    "CITYNAME_AR": "القفيل",
    "CITYNAME_EN": "Al Qufayl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18573",
    "CITYNAME_AR": "عردن",
    "CITYNAME_EN": "Ardan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18574",
    "CITYNAME_AR": "عبيده وصغيره",
    "CITYNAME_EN": "Obaidah wa Sagheroh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18575",
    "CITYNAME_AR": "الشاطى",
    "CITYNAME_EN": "Ash-Shateah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18576",
    "CITYNAME_AR": "الحراج",
    "CITYNAME_EN": "Al Heraj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18577",
    "CITYNAME_AR": "صغيره",
    "CITYNAME_EN": "Sagherah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18578",
    "CITYNAME_AR": "الجعيده",
    "CITYNAME_EN": "Al Guaydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18579",
    "CITYNAME_AR": "الشقيب",
    "CITYNAME_EN": "Ash-Shaqeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18580",
    "CITYNAME_AR": "المريخ",
    "CITYNAME_EN": "Al Mareekh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18581",
    "CITYNAME_AR": "الخالف",
    "CITYNAME_EN": "Al Khalef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18582",
    "CITYNAME_AR": "شعب معلى",
    "CITYNAME_EN": "Shuab Moaali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18583",
    "CITYNAME_AR": "شعب مشرف",
    "CITYNAME_EN": "Shuab Meshref",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18584",
    "CITYNAME_AR": "الحضن",
    "CITYNAME_EN": "Alhodn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18585",
    "CITYNAME_AR": "ال سالم",
    "CITYNAME_EN": "Al Salim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18586",
    "CITYNAME_AR": "الدوده",
    "CITYNAME_EN": "Ad-Dudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18587",
    "CITYNAME_AR": "الشرفه",
    "CITYNAME_EN": "Ash-Sharfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18588",
    "CITYNAME_AR": "الجلب",
    "CITYNAME_EN": "Al-Galb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18589",
    "CITYNAME_AR": "المبنى",
    "CITYNAME_EN": "Al Mabnaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18590",
    "CITYNAME_AR": "الاملح",
    "CITYNAME_EN": "Al Almah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18591",
    "CITYNAME_AR": "جيلع (الحسين)",
    "CITYNAME_EN": "Gaylaa (Al Hussein)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18592",
    "CITYNAME_AR": "الاقعس",
    "CITYNAME_EN": "Al Aqaas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18593",
    "CITYNAME_AR": "المشراف",
    "CITYNAME_EN": "Al Meshraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18594",
    "CITYNAME_AR": "قرن العرشان",
    "CITYNAME_EN": "Qarn Al Arshan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18595",
    "CITYNAME_AR": "ذا القفل",
    "CITYNAME_EN": "Tha Alqefl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18596",
    "CITYNAME_AR": "مرقدن",
    "CITYNAME_EN": "Marqadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18597",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Al Khulayef",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18598",
    "CITYNAME_AR": "شاظى",
    "CITYNAME_EN": "Shathi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18599",
    "CITYNAME_AR": "السباب",
    "CITYNAME_EN": "As-Subab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18600",
    "CITYNAME_AR": "واسعه",
    "CITYNAME_EN": "Waseah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18601",
    "CITYNAME_AR": "بهته",
    "CITYNAME_EN": "Bahtah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18602",
    "CITYNAME_AR": "الدرعيه",
    "CITYNAME_EN": "Diriyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18603",
    "CITYNAME_AR": "الجوه",
    "CITYNAME_EN": "Al Jawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18604",
    "CITYNAME_AR": "الصبغان",
    "CITYNAME_EN": "As-Sabghan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18605",
    "CITYNAME_AR": "قرن عجلان",
    "CITYNAME_EN": "Qarn Ajlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18606",
    "CITYNAME_AR": "البادع",
    "CITYNAME_EN": "Al Badei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18607",
    "CITYNAME_AR": "شعب ال شواف",
    "CITYNAME_EN": "Shuab Al Shawwaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18608",
    "CITYNAME_AR": "اللفجه (الجفر)",
    "CITYNAME_EN": "Al Lafajah (Al Gafr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18609",
    "CITYNAME_AR": "القزعه",
    "CITYNAME_EN": "Al Quzaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18610",
    "CITYNAME_AR": "قرن السرحه",
    "CITYNAME_EN": "Qarn As-Sarahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18611",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18612",
    "CITYNAME_AR": "العطبه",
    "CITYNAME_EN": "Al Otabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18613",
    "CITYNAME_AR": "الطفه",
    "CITYNAME_EN": "At-Tafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18614",
    "CITYNAME_AR": "ذى قدرن",
    "CITYNAME_EN": "The Qadren",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18615",
    "CITYNAME_AR": "المحيمر",
    "CITYNAME_EN": "Al Muhaymer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18616",
    "CITYNAME_AR": "الصحنه",
    "CITYNAME_EN": "As-Sahnah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18617",
    "CITYNAME_AR": "الحسن",
    "CITYNAME_EN": "Al Husn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18618",
    "CITYNAME_AR": "ال الطنف",
    "CITYNAME_EN": "Al Altanaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18619",
    "CITYNAME_AR": "الغبران",
    "CITYNAME_EN": "Al Ghabran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18620",
    "CITYNAME_AR": "الحسك",
    "CITYNAME_EN": "Al Hasak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18621",
    "CITYNAME_AR": "عفاس",
    "CITYNAME_EN": "Affas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18622",
    "CITYNAME_AR": "الوشعه",
    "CITYNAME_EN": "Al Washaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18623",
    "CITYNAME_AR": "الريان",
    "CITYNAME_EN": "Al Ryan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18624",
    "CITYNAME_AR": "خريق الوباله",
    "CITYNAME_EN": "Khuraik Al Wayalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18625",
    "CITYNAME_AR": "مشرف",
    "CITYNAME_EN": "Moshref",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18626",
    "CITYNAME_AR": "الفايجه",
    "CITYNAME_EN": "Al Fayjah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18627",
    "CITYNAME_AR": "الذنبه",
    "CITYNAME_EN": "Al Zanabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18628",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Al Uyaynah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18629",
    "CITYNAME_AR": "المربعه",
    "CITYNAME_EN": "Al Marbaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18630",
    "CITYNAME_AR": "البانه (صقارن)",
    "CITYNAME_EN": "Al Banah (Saqran)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18631",
    "CITYNAME_AR": "حدبه حجران",
    "CITYNAME_EN": "Hadabah Hijran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18632",
    "CITYNAME_AR": "عزه",
    "CITYNAME_EN": "Azzah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18633",
    "CITYNAME_AR": "الصخر",
    "CITYNAME_EN": "As-Sakhr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18634",
    "CITYNAME_AR": "الجوفاء",
    "CITYNAME_EN": "Al Jawfaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18635",
    "CITYNAME_AR": "مصوى",
    "CITYNAME_EN": "Mosawee",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18636",
    "CITYNAME_AR": "السويد",
    "CITYNAME_EN": "As-Suaed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18637",
    "CITYNAME_AR": "الجعافر",
    "CITYNAME_EN": "Al Jaaferah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18638",
    "CITYNAME_AR": "حقو سمحه",
    "CITYNAME_EN": "Haqu Samahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18639",
    "CITYNAME_AR": "السمره",
    "CITYNAME_EN": "As-Samrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18640",
    "CITYNAME_AR": "الحمضه",
    "CITYNAME_EN": "Al Hamdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18641",
    "CITYNAME_AR": "المربع",
    "CITYNAME_EN": "Al Muraba'a",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18642",
    "CITYNAME_AR": "مانع",
    "CITYNAME_EN": "Manei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18643",
    "CITYNAME_AR": "النجيل",
    "CITYNAME_EN": "Al Najeel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18644",
    "CITYNAME_AR": "غريب",
    "CITYNAME_EN": "Gharib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18645",
    "CITYNAME_AR": "حداب القرشه",
    "CITYNAME_EN": "Haddab Al Qershah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18646",
    "CITYNAME_AR": "قرن الجوعه",
    "CITYNAME_EN": "Qarn Al Jawaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18647",
    "CITYNAME_AR": "السيد",
    "CITYNAME_EN": "As-Sayed",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18648",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18649",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18650",
    "CITYNAME_AR": "الحلق",
    "CITYNAME_EN": "Al Halaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18651",
    "CITYNAME_AR": "الغمره",
    "CITYNAME_EN": "Al-Ghamra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18652",
    "CITYNAME_AR": "المعقص",
    "CITYNAME_EN": "Al Moaqas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18653",
    "CITYNAME_AR": "الحداب (الرخم)",
    "CITYNAME_EN": "Al Haddab (Al Rakhim)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18654",
    "CITYNAME_AR": "ناخسه",
    "CITYNAME_EN": "Nakhesah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18655",
    "CITYNAME_AR": "الغرفه",
    "CITYNAME_EN": "Al Gharafah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18656",
    "CITYNAME_AR": "الدومه",
    "CITYNAME_EN": "Ad-Doumah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18657",
    "CITYNAME_AR": "الثعب",
    "CITYNAME_EN": "Ath-Thaab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18658",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18659",
    "CITYNAME_AR": "نمره بيان",
    "CITYNAME_EN": "Nemrah Bayan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18660",
    "CITYNAME_AR": "ضهاء",
    "CITYNAME_EN": "Dahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18661",
    "CITYNAME_AR": "الشعبات",
    "CITYNAME_EN": "Ash-Shaabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18662",
    "CITYNAME_AR": "الضرب",
    "CITYNAME_EN": "Ad-Darb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18663",
    "CITYNAME_AR": "قزعه المروه",
    "CITYNAME_EN": "Qazeit Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18664",
    "CITYNAME_AR": "باهيه",
    "CITYNAME_EN": "Bahyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18665",
    "CITYNAME_AR": "الضلعه",
    "CITYNAME_EN": "Ad-Dalaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18666",
    "CITYNAME_AR": "الحنكه",
    "CITYNAME_EN": "Al Hanakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18667",
    "CITYNAME_AR": "المشارق",
    "CITYNAME_EN": "Al Mashareq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18668",
    "CITYNAME_AR": "كيحل",
    "CITYNAME_EN": "Kaheel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18669",
    "CITYNAME_AR": "الراحه",
    "CITYNAME_EN": "Ar-Rahah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18670",
    "CITYNAME_AR": "الشباب",
    "CITYNAME_EN": "Ash-Shabab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18671",
    "CITYNAME_AR": "الفاغره",
    "CITYNAME_EN": "Al Fagherah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18672",
    "CITYNAME_AR": "الصوراء والرهى",
    "CITYNAME_EN": "As-Sawraa wa Ar-Ruha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18673",
    "CITYNAME_AR": "العيسان",
    "CITYNAME_EN": "Al Aysan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18674",
    "CITYNAME_AR": "العرافيط",
    "CITYNAME_EN": "Al Arafet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18675",
    "CITYNAME_AR": "ال بن درويش",
    "CITYNAME_EN": "Al bin Darwish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18676",
    "CITYNAME_AR": "الضرب",
    "CITYNAME_EN": "Ad-Darb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18677",
    "CITYNAME_AR": "العياش",
    "CITYNAME_EN": "Al Ayash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18678",
    "CITYNAME_AR": "غبايطه",
    "CITYNAME_EN": "Ghabaytah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18679",
    "CITYNAME_AR": "المقصوده",
    "CITYNAME_EN": "Al Maqsudah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18680",
    "CITYNAME_AR": "ال سيلان",
    "CITYNAME_EN": "Al Saylan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18681",
    "CITYNAME_AR": "حاجره",
    "CITYNAME_EN": "Hajerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18682",
    "CITYNAME_AR": "عاصيه",
    "CITYNAME_EN": "Asyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18683",
    "CITYNAME_AR": "القميع",
    "CITYNAME_EN": "Al Qumaya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18684",
    "CITYNAME_AR": "الشاطى",
    "CITYNAME_EN": "Ash-Shateah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18685",
    "CITYNAME_AR": "قرن الشريف",
    "CITYNAME_EN": "Qarn Al-Sharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18686",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As-Sirr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18687",
    "CITYNAME_AR": "الضريبه",
    "CITYNAME_EN": "Ad-Daryeba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18688",
    "CITYNAME_AR": "خميس ناخس",
    "CITYNAME_EN": "Khamis Nakhis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18689",
    "CITYNAME_AR": "المصاف",
    "CITYNAME_EN": "Al Musaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18690",
    "CITYNAME_AR": "القله",
    "CITYNAME_EN": "Al Qulah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18691",
    "CITYNAME_AR": "قرقره",
    "CITYNAME_EN": "Qarqarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18692",
    "CITYNAME_AR": "هبيل",
    "CITYNAME_EN": "Hubail",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18693",
    "CITYNAME_AR": "القريع",
    "CITYNAME_EN": "Al Quraya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18694",
    "CITYNAME_AR": "القهيبه",
    "CITYNAME_EN": "Al Quhaybah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18695",
    "CITYNAME_AR": "غارضه",
    "CITYNAME_EN": "Gharedah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18696",
    "CITYNAME_AR": "فاجه وعسيله",
    "CITYNAME_EN": "Fajah wa Aseilah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18697",
    "CITYNAME_AR": "البخره",
    "CITYNAME_EN": "Al Bakharah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18698",
    "CITYNAME_AR": "الصفى",
    "CITYNAME_EN": "As-Safa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18699",
    "CITYNAME_AR": "حزيان",
    "CITYNAME_EN": "Hezban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18700",
    "CITYNAME_AR": "مران",
    "CITYNAME_EN": "Maran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18701",
    "CITYNAME_AR": "الجمام (جمه)",
    "CITYNAME_EN": "Al Jamam (Jammah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18702",
    "CITYNAME_AR": "جذع",
    "CITYNAME_EN": "Jezaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18703",
    "CITYNAME_AR": "الفاتح",
    "CITYNAME_EN": "Al Fatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18704",
    "CITYNAME_AR": "الخورم",
    "CITYNAME_EN": "Al Khawram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18705",
    "CITYNAME_AR": "حقو السفرى",
    "CITYNAME_EN": "Haqu As-Safari",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18706",
    "CITYNAME_AR": "قرون",
    "CITYNAME_EN": "Qoron",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18707",
    "CITYNAME_AR": "قرن المخصر",
    "CITYNAME_EN": "Qarn Al Mukhser",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18708",
    "CITYNAME_AR": "الدار الجديده (غليله)",
    "CITYNAME_EN": "Ad-Dar Al Jadidah (Ghulaylah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18709",
    "CITYNAME_AR": "الشعبين (الرصيد)",
    "CITYNAME_EN": "Ash-Shabain (Ar-Raseed)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18710",
    "CITYNAME_AR": "الرفضه",
    "CITYNAME_EN": "Ar-Rafadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18711",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Al Malhah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18712",
    "CITYNAME_AR": "الكيله",
    "CITYNAME_EN": "Al Kaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18713",
    "CITYNAME_AR": "المعرض والظهر",
    "CITYNAME_EN": "Al Maarad wa Az-Zahr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18714",
    "CITYNAME_AR": "ابرير",
    "CITYNAME_EN": "Abarbar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18715",
    "CITYNAME_AR": "الحذيفه",
    "CITYNAME_EN": "Al Huzayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18716",
    "CITYNAME_AR": "المشربه",
    "CITYNAME_EN": "Al Mashrabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18717",
    "CITYNAME_AR": "الركبه",
    "CITYNAME_EN": "Ar-Rakabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18718",
    "CITYNAME_AR": "شعب الحسن",
    "CITYNAME_EN": "Shuab Al Hasan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18719",
    "CITYNAME_AR": "ذى سلب",
    "CITYNAME_EN": "Ze Salab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18720",
    "CITYNAME_AR": "العطاء",
    "CITYNAME_EN": "Al Ataa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18721",
    "CITYNAME_AR": "الحناء",
    "CITYNAME_EN": "Al Henaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18722",
    "CITYNAME_AR": "الحنو (فاجه)",
    "CITYNAME_EN": "Al Hanou (Fajah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18723",
    "CITYNAME_AR": "عصاره",
    "CITYNAME_EN": "Osarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18724",
    "CITYNAME_AR": "الليه",
    "CITYNAME_EN": "Al Layah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18725",
    "CITYNAME_AR": "المصاف والمدارات",
    "CITYNAME_EN": "Al Masaf wa Al Madarat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18726",
    "CITYNAME_AR": "ثريبان",
    "CITYNAME_EN": "Thareban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18727",
    "CITYNAME_AR": "حدبه حرب",
    "CITYNAME_EN": "Hadabah Harb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18728",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Al Qarn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18729",
    "CITYNAME_AR": "الردم",
    "CITYNAME_EN": "Ar-Radam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18730",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Ar-Raqabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18731",
    "CITYNAME_AR": "الصوافيه",
    "CITYNAME_EN": "As-Sawafyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18732",
    "CITYNAME_AR": "ميكه",
    "CITYNAME_EN": "Maykah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18733",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Al Marwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18734",
    "CITYNAME_AR": "مخطوفه",
    "CITYNAME_EN": "Makhtufah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18735",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18736",
    "CITYNAME_AR": "ال شاهر",
    "CITYNAME_EN": "Al Shaher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18737",
    "CITYNAME_AR": "المقرح",
    "CITYNAME_EN": "Al Muqreh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18738",
    "CITYNAME_AR": "القعبه",
    "CITYNAME_EN": "Al Qaabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18739",
    "CITYNAME_AR": "شنقران",
    "CITYNAME_EN": "Shanqeran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18740",
    "CITYNAME_AR": "الارتاق (مخشوشه)",
    "CITYNAME_EN": "Al Artaq (Makhshoushah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18741",
    "CITYNAME_AR": "الرهاء",
    "CITYNAME_EN": "Ar-Ruhaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18742",
    "CITYNAME_AR": "السند",
    "CITYNAME_EN": "As-Sanad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18743",
    "CITYNAME_AR": "حدبه سكنه",
    "CITYNAME_EN": "Hadabah Sakanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18744",
    "CITYNAME_AR": "حدبه شيبان",
    "CITYNAME_EN": "Hadabah Shayban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18745",
    "CITYNAME_AR": "المطحنه",
    "CITYNAME_EN": "Al Mathanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18746",
    "CITYNAME_AR": "الجناح",
    "CITYNAME_EN": "Al Jenah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18747",
    "CITYNAME_AR": "الخبيره",
    "CITYNAME_EN": "Al Khubayrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18748",
    "CITYNAME_AR": "قراء حزام",
    "CITYNAME_EN": "Qeraa Hezam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18749",
    "CITYNAME_AR": "سيال",
    "CITYNAME_EN": "Sayyal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18750",
    "CITYNAME_AR": "الوعيران",
    "CITYNAME_EN": "Al Waayran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18751",
    "CITYNAME_AR": "الشنكه",
    "CITYNAME_EN": "Ash-Shanakah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18752",
    "CITYNAME_AR": "حوره",
    "CITYNAME_EN": "Hawrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18753",
    "CITYNAME_AR": "المضاحي",
    "CITYNAME_EN": "Al Mudahi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18754",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Ash-Shuaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18755",
    "CITYNAME_AR": "الورك (الوكب)",
    "CITYNAME_EN": "Al Warq (Al Waqab)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18756",
    "CITYNAME_AR": "كيهف",
    "CITYNAME_EN": "Kuhaif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18757",
    "CITYNAME_AR": "راف",
    "CITYNAME_EN": "Raf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18758",
    "CITYNAME_AR": "طلعه بالحارث",
    "CITYNAME_EN": "Talaah, Al Hareth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18759",
    "CITYNAME_AR": "شعبة حمدي",
    "CITYNAME_EN": "Shuaba Hamdi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18760",
    "CITYNAME_AR": "مهد الذهب",
    "CITYNAME_EN": "Mahd Al Thahab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18761",
    "CITYNAME_AR": "الغمر",
    "CITYNAME_EN": "Al Ghamr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18762",
    "CITYNAME_AR": "المزرع (اليبس)",
    "CITYNAME_EN": "Al Mazraa (Al Yabas)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18763",
    "CITYNAME_AR": "الجريسيه",
    "CITYNAME_EN": "Al Gerysyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18764",
    "CITYNAME_AR": "المعدن",
    "CITYNAME_EN": "Al Maadan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18765",
    "CITYNAME_AR": "العقيله",
    "CITYNAME_EN": "Al Oqaylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18766",
    "CITYNAME_AR": "الجميماء",
    "CITYNAME_EN": "Al Gumaymaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18767",
    "CITYNAME_AR": "غضيره",
    "CITYNAME_EN": "Ghudayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18768",
    "CITYNAME_AR": "غضيره",
    "CITYNAME_EN": "Ghudayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18769",
    "CITYNAME_AR": "الحصانه",
    "CITYNAME_EN": "Al Hasanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18770",
    "CITYNAME_AR": "ام الدمار",
    "CITYNAME_EN": "Om Ad-Damar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18771",
    "CITYNAME_AR": "السايله",
    "CITYNAME_EN": "As-Sayalah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18772",
    "CITYNAME_AR": "خرجا",
    "CITYNAME_EN": "Kharja",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18773",
    "CITYNAME_AR": "الهميجه",
    "CITYNAME_EN": "Al Humayjah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18774",
    "CITYNAME_AR": "ام رضم",
    "CITYNAME_EN": "Om Radam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18775",
    "CITYNAME_AR": "العبيله (ريان)",
    "CITYNAME_EN": "Al Obaylah (Rayan)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18776",
    "CITYNAME_AR": "الطيار",
    "CITYNAME_EN": "At-Tayyar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18777",
    "CITYNAME_AR": "الأسلق (العزيزيه)",
    "CITYNAME_EN": "Al Aslaq (Al Azizyah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18778",
    "CITYNAME_AR": "بئر مليحه",
    "CITYNAME_EN": "Mulayhah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18779",
    "CITYNAME_AR": "آبار العثيا (العثيمه)",
    "CITYNAME_EN": "Al Othya wells (Al Othaymah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18780",
    "CITYNAME_AR": "البديع  حاكم",
    "CITYNAME_EN": "Al Badei Hakim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18781",
    "CITYNAME_AR": "آبار الطويرفه",
    "CITYNAME_EN": "At-Tuwayferah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18782",
    "CITYNAME_AR": "العمق",
    "CITYNAME_EN": "Al Omq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18783",
    "CITYNAME_AR": "العميق",
    "CITYNAME_EN": "Al Ameeq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18784",
    "CITYNAME_AR": "الراشديه",
    "CITYNAME_EN": "Ar-Rashidyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18785",
    "CITYNAME_AR": "ابو حبل",
    "CITYNAME_EN": "Abo Habal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18786",
    "CITYNAME_AR": "بدائع مشرفة ",
    "CITYNAME_EN": "Badaya Al Haraseen (Matir)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18787",
    "CITYNAME_AR": "المندسه",
    "CITYNAME_EN": "Al-Mondassah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18788",
    "CITYNAME_AR": "ذات ركيب (سريحان)",
    "CITYNAME_EN": "Zat Rakeeb (Surayhan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18789",
    "CITYNAME_AR": "الاشقر",
    "CITYNAME_EN": "Al Ashqar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18790",
    "CITYNAME_AR": "المحتجبه ( ذات الركب)",
    "CITYNAME_EN": "Al Muhtajebah (Zat Ar-Rukab)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18791",
    "CITYNAME_AR": "الزاحميه",
    "CITYNAME_EN": "Az-Zahimyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18792",
    "CITYNAME_AR": "سويقه (السافريه)",
    "CITYNAME_EN": "Suwaiqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18793",
    "CITYNAME_AR": "ابقريه",
    "CITYNAME_EN": "Abqaryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18794",
    "CITYNAME_AR": "الهمجه",
    "CITYNAME_EN": "Al Hamjaha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18795",
    "CITYNAME_AR": "غفره",
    "CITYNAME_EN": "Ghofrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18796",
    "CITYNAME_AR": "سمار",
    "CITYNAME_EN": "Semar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18797",
    "CITYNAME_AR": "الهراره",
    "CITYNAME_EN": "Al Harrara",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18798",
    "CITYNAME_AR": "الركنة",
    "CITYNAME_EN": "Ar Rakna",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18799",
    "CITYNAME_AR": "المحمدية ",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18800",
    "CITYNAME_AR": "جميما الشعبة",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18801",
    "CITYNAME_AR": "ام سراح",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18802",
    "CITYNAME_AR": "بئر زريبه (زبيدة)",
    "CITYNAME_EN": "Zuraybah well (Zubaydah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18803",
    "CITYNAME_AR": "ام العوشز",
    "CITYNAME_EN": "Om Al Awshaz",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18804",
    "CITYNAME_AR": "المنبعج",
    "CITYNAME_EN": "Al Munbaij",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18805",
    "CITYNAME_AR": "بئر الحفيريه",
    "CITYNAME_EN": "Al Hufayryah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18806",
    "CITYNAME_AR": "المويهيه",
    "CITYNAME_EN": "Al Moyhiyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18807",
    "CITYNAME_AR": "الفيضه",
    "CITYNAME_EN": "Al Faydh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18808",
    "CITYNAME_AR": "المويه",
    "CITYNAME_EN": "Al Muwayh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18809",
    "CITYNAME_AR": "الرقابيه",
    "CITYNAME_EN": "Ar-Reqabyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18810",
    "CITYNAME_AR": "عنيزه",
    "CITYNAME_EN": "Unaizah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18811",
    "CITYNAME_AR": "المزاحمية",
    "CITYNAME_EN": "Al Mazahmyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18812",
    "CITYNAME_AR": "البراقية",
    "CITYNAME_EN": "Al Baraqyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18813",
    "CITYNAME_AR": "البويرة",
    "CITYNAME_EN": "Al Buwayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18814",
    "CITYNAME_AR": "الضبعيه",
    "CITYNAME_EN": "Ad-Dabayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18815",
    "CITYNAME_AR": "الرجل",
    "CITYNAME_EN": "Al-Rajl",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18816",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Al-Quway'iyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18817",
    "CITYNAME_AR": "نعضة القويعيه",
    "CITYNAME_EN": "Naadet Al-Quway'iyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18818",
    "CITYNAME_AR": "النعضه",
    "CITYNAME_EN": "Al Na'da",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18819",
    "CITYNAME_AR": "السالمية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18820",
    "CITYNAME_AR": "النازية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18821",
    "CITYNAME_AR": "العضيلية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18822",
    "CITYNAME_AR": "الظاهرية ",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18823",
    "CITYNAME_AR": "المجادلية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18824",
    "CITYNAME_AR": "رواحه",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18825",
    "CITYNAME_AR": "الكانية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18826",
    "CITYNAME_AR": "بئر الفقير عيال رشدان",
    "CITYNAME_EN": "Biir Alfaqir Eial Rashadan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18827",
    "CITYNAME_AR": "حجج",
    "CITYNAME_EN": "Hijaj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "18828",
    "CITYNAME_AR": "بئر ابن كامل",
    "CITYNAME_EN": "Ibn Kamel well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18829",
    "CITYNAME_AR": "بئر بن جبر",
    "CITYNAME_EN": "Bin Jabr well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18830",
    "CITYNAME_AR": "ينبع النخل",
    "CITYNAME_EN": "Yanbu Al Nakhal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18831",
    "CITYNAME_AR": "اليسيره",
    "CITYNAME_EN": "Al Yaserah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18832",
    "CITYNAME_AR": "البثنه",
    "CITYNAME_EN": "Al Bathnah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18833",
    "CITYNAME_AR": "خيف حسين",
    "CITYNAME_EN": "Khaif Hussein",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18834",
    "CITYNAME_AR": "مدسوس",
    "CITYNAME_EN": "Madsous",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18835",
    "CITYNAME_AR": "البقاع الفرعه (البلدة)",
    "CITYNAME_EN": "Al Beqaa Al Faraah (Al Baldah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18836",
    "CITYNAME_AR": "بئر بنى درويش",
    "CITYNAME_EN": "Bani Darwesh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18837",
    "CITYNAME_AR": "بئر الشيخ",
    "CITYNAME_EN": "Al-Sheikh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18838",
    "CITYNAME_AR": "بئر اللحاء",
    "CITYNAME_EN": "Al Luhaa well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18839",
    "CITYNAME_AR": "الساده (آبار الساده)",
    "CITYNAME_EN": "As-Sadah (As-Sadah wells)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18840",
    "CITYNAME_AR": "سويقه",
    "CITYNAME_EN": "Suwayqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18841",
    "CITYNAME_AR": "بئر صايل",
    "CITYNAME_EN": "Sayel well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18842",
    "CITYNAME_AR": "البقاريه (الفرعه)",
    "CITYNAME_EN": "Al Baqaryah (Al Faraah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18843",
    "CITYNAME_AR": "القرص",
    "CITYNAME_EN": "Al Qurs",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18844",
    "CITYNAME_AR": "آيله",
    "CITYNAME_EN": "Aylah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18845",
    "CITYNAME_AR": "بئر احمد",
    "CITYNAME_EN": "Ahmed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18846",
    "CITYNAME_AR": "بئر الحما (هجر الحما)",
    "CITYNAME_EN": "Al Hama well (Hajr Al Hama)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18847",
    "CITYNAME_AR": "الدهماء",
    "CITYNAME_EN": "Ad-Duhamaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18848",
    "CITYNAME_AR": "بئر العبد",
    "CITYNAME_EN": "Al Abd well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18849",
    "CITYNAME_AR": "بئر سمير",
    "CITYNAME_EN": "Samir well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18850",
    "CITYNAME_AR": "بئر قعيقعان",
    "CITYNAME_EN": "Quayqaan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18851",
    "CITYNAME_AR": "بئر الفارعه",
    "CITYNAME_EN": "Al Farah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18852",
    "CITYNAME_AR": "بئر عيد",
    "CITYNAME_EN": "Eid well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18853",
    "CITYNAME_AR": "بئر خليل",
    "CITYNAME_EN": "Khalil well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18854",
    "CITYNAME_AR": "بئر عرفان",
    "CITYNAME_EN": "Erfan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18855",
    "CITYNAME_AR": "بئر نخر",
    "CITYNAME_EN": "Nakhr well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18856",
    "CITYNAME_AR": "بئر نويجع",
    "CITYNAME_EN": "Nuwayjaa well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18857",
    "CITYNAME_AR": "بئر النجيل (النجيفه)",
    "CITYNAME_EN": "Al Najeel well (Al Nujaifah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18858",
    "CITYNAME_AR": "بئر ارطاه",
    "CITYNAME_EN": "Ertah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18859",
    "CITYNAME_AR": "بئر بن رشيد",
    "CITYNAME_EN": "Bin Rashid well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18860",
    "CITYNAME_AR": "بئرمحمد المطلق",
    "CITYNAME_EN": "Mohamed Al Mutlaq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18861",
    "CITYNAME_AR": "بئر صالح محمد",
    "CITYNAME_EN": "Saleh Mohamed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18862",
    "CITYNAME_AR": "بئر ظمن بن سليم",
    "CITYNAME_EN": "Thamn bin Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18863",
    "CITYNAME_AR": "بئر عجيل",
    "CITYNAME_EN": "Awjael well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18864",
    "CITYNAME_AR": "الظلل",
    "CITYNAME_EN": "Al Thalal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18865",
    "CITYNAME_AR": "بئر العلونى",
    "CITYNAME_EN": "Al Aulwani well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18866",
    "CITYNAME_AR": "بير عبد الله الحنو الرفاعي",
    "CITYNAME_EN": "Abdullah Al Hanu Ar-Refai well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18867",
    "CITYNAME_AR": "بئر صبر ربيع",
    "CITYNAME_EN": "Sair Rabie well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18868",
    "CITYNAME_AR": "الجابريه",
    "CITYNAME_EN": "Al Jabriyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18869",
    "CITYNAME_AR": "الريان",
    "CITYNAME_EN": "Al Ryan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18870",
    "CITYNAME_AR": "عين على الحربيه",
    "CITYNAME_EN": "Ein Ali Al Harbyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18871",
    "CITYNAME_AR": "العلقميه",
    "CITYNAME_EN": "Al Alqamyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18872",
    "CITYNAME_AR": "المزرعه",
    "CITYNAME_EN": "Al Mazraah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18873",
    "CITYNAME_AR": "القريه",
    "CITYNAME_EN": "Al Qaryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18874",
    "CITYNAME_AR": "المثنى",
    "CITYNAME_EN": "Al Muthannah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18875",
    "CITYNAME_AR": "المبارك",
    "CITYNAME_EN": "Al Mubarak",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18876",
    "CITYNAME_AR": "المشيريف",
    "CITYNAME_EN": "Al Mushayref",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18877",
    "CITYNAME_AR": "العبايش",
    "CITYNAME_EN": "Al Obayesh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18878",
    "CITYNAME_AR": "الصمد",
    "CITYNAME_EN": "As-Samad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18879",
    "CITYNAME_AR": "اباالحامض",
    "CITYNAME_EN": "Aba Al Hamed",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18880",
    "CITYNAME_AR": "النجيل",
    "CITYNAME_EN": "Al Najeel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18881",
    "CITYNAME_AR": "بئر سلم (الصالحية)",
    "CITYNAME_EN": "Salam well (As-Salhyah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18882",
    "CITYNAME_AR": "بئر المالح",
    "CITYNAME_EN": "Al Maleh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18883",
    "CITYNAME_AR": "بئر نما السريحي",
    "CITYNAME_EN": "Nama As-Surayhi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18884",
    "CITYNAME_AR": "بئر عتيق السريحي",
    "CITYNAME_EN": "Ateeq As-Surayhi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18885",
    "CITYNAME_AR": "الشرجه",
    "CITYNAME_EN": "Ash-Sharjah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18886",
    "CITYNAME_AR": "السكوبية",
    "CITYNAME_EN": "As-Sukubyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18887",
    "CITYNAME_AR": "العاقر واشك",
    "CITYNAME_EN": "Al Aqer Washek",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18888",
    "CITYNAME_AR": "ابار الحفاير",
    "CITYNAME_EN": "Al Hafayer wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18889",
    "CITYNAME_AR": "شعيب الرديح",
    "CITYNAME_EN": "Shuaib Ar-Radeeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18890",
    "CITYNAME_AR": "الطيبة",
    "CITYNAME_EN": "At-Taybah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18891",
    "CITYNAME_AR": "بئر الراية",
    "CITYNAME_EN": "Ar-Raya well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18892",
    "CITYNAME_AR": "رهبا",
    "CITYNAME_EN": "Rahba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18893",
    "CITYNAME_AR": "بئر سالم بن ثابت",
    "CITYNAME_EN": "Salem bin Thabet well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18894",
    "CITYNAME_AR": "بئر ربيع",
    "CITYNAME_EN": "Rabie well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18895",
    "CITYNAME_AR": "بئر كريدم",
    "CITYNAME_EN": "Kuraydam well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18896",
    "CITYNAME_AR": "تلعه نزه",
    "CITYNAME_EN": "Talaah Nazah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18897",
    "CITYNAME_AR": "عرضاء",
    "CITYNAME_EN": "Ardaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18898",
    "CITYNAME_AR": "صمد الصعيد",
    "CITYNAME_EN": "Samad As-Saeedi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18899",
    "CITYNAME_AR": "بئر عبدالله الحسيني",
    "CITYNAME_EN": "Abdullah Al Hussaini well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18900",
    "CITYNAME_AR": "بئر فالح الزغير",
    "CITYNAME_EN": "Falih Az-Zaghir well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18901",
    "CITYNAME_AR": "غراء",
    "CITYNAME_EN": "Gharaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18902",
    "CITYNAME_AR": "بئر ابوقرنين (خظيراء)",
    "CITYNAME_EN": "Abo Qarnayn well (Khuzayraa)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18903",
    "CITYNAME_AR": "كتاد",
    "CITYNAME_EN": "Katad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18904",
    "CITYNAME_AR": "الفرشه",
    "CITYNAME_EN": "Al Farsha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18905",
    "CITYNAME_AR": "ام ضعيات",
    "CITYNAME_EN": "Om Dayaat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18906",
    "CITYNAME_AR": "الزغر",
    "CITYNAME_EN": "Az-Zaghar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18907",
    "CITYNAME_AR": "النويبع",
    "CITYNAME_EN": "Nuweibaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18908",
    "CITYNAME_AR": "بئر عايد",
    "CITYNAME_EN": "Abed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18909",
    "CITYNAME_AR": "بئر مسلم",
    "CITYNAME_EN": "Moslem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18910",
    "CITYNAME_AR": "بئر الشبولي",
    "CITYNAME_EN": "Ash-Shabuli well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18911",
    "CITYNAME_AR": "بئر اللحويه",
    "CITYNAME_EN": "Al Lahwyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18912",
    "CITYNAME_AR": "ابيار ندياء",
    "CITYNAME_EN": "Abyar Nadbaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18913",
    "CITYNAME_AR": "الاحمر",
    "CITYNAME_EN": "Al-Ahmar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18914",
    "CITYNAME_AR": "الخياميه الجنوبيه",
    "CITYNAME_EN": "Al Khayamyah Al Janubyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18915",
    "CITYNAME_AR": "بئر حمدي بن عواده",
    "CITYNAME_EN": "Hamdi bin Awwadah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18916",
    "CITYNAME_AR": "بئر لويه",
    "CITYNAME_EN": "Lawyah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18917",
    "CITYNAME_AR": "القديره",
    "CITYNAME_EN": "Al Qaderah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18918",
    "CITYNAME_AR": "الخيامية الشماليه",
    "CITYNAME_EN": "Al Khayamyah Ash-Shamalyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18919",
    "CITYNAME_AR": "لايه",
    "CITYNAME_EN": "Layah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18920",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Al Luhyan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18921",
    "CITYNAME_AR": "دف الرفحة",
    "CITYNAME_EN": "Daf Ar-Rufhah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18922",
    "CITYNAME_AR": "بئر النخيله",
    "CITYNAME_EN": "An-Nukhailah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18923",
    "CITYNAME_AR": "فم علقة",
    "CITYNAME_EN": "Fam Alaqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18924",
    "CITYNAME_AR": "دوريت",
    "CITYNAME_EN": "Dureet",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18925",
    "CITYNAME_AR": "سلم",
    "CITYNAME_EN": "Salam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18926",
    "CITYNAME_AR": "دوينه",
    "CITYNAME_EN": "Duwaynah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18927",
    "CITYNAME_AR": "دانه",
    "CITYNAME_EN": "Danah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18928",
    "CITYNAME_AR": "آبار ضاحك",
    "CITYNAME_EN": "Dahek wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18929",
    "CITYNAME_AR": "طاط",
    "CITYNAME_EN": "Tata",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18930",
    "CITYNAME_AR": "عدان",
    "CITYNAME_EN": "Addan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18931",
    "CITYNAME_AR": "السديره",
    "CITYNAME_EN": "Sudaira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18932",
    "CITYNAME_AR": "اللطيه",
    "CITYNAME_EN": "Al Latyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18933",
    "CITYNAME_AR": "بئر العيز (الحلقات)",
    "CITYNAME_EN": "Al Ayz well (Al Halaqat)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18934",
    "CITYNAME_AR": "سنان الشلول",
    "CITYNAME_EN": "Senan Ash-Shuwal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18935",
    "CITYNAME_AR": "الروى",
    "CITYNAME_EN": "Ar-Rawi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18936",
    "CITYNAME_AR": "الصخرة",
    "CITYNAME_EN": "As-Sakharah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18937",
    "CITYNAME_AR": "البويرات (بروات)",
    "CITYNAME_EN": "Al Buwayrat (Barawat)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18938",
    "CITYNAME_AR": "بئر عوده (الرجبي)",
    "CITYNAME_EN": "Oudah well (Ar-Rajebi)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18939",
    "CITYNAME_AR": "بئر صالح الرفاعي",
    "CITYNAME_EN": "Saleh Ar-Refai well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18940",
    "CITYNAME_AR": "الوقيعة",
    "CITYNAME_EN": "Al Waqeah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18941",
    "CITYNAME_AR": "الموارد",
    "CITYNAME_EN": "Al Mawared",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18942",
    "CITYNAME_AR": "المصينع",
    "CITYNAME_EN": "Al Musaynaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18943",
    "CITYNAME_AR": "طريف الرجل",
    "CITYNAME_EN": "Tareef Ar-Rajel",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18944",
    "CITYNAME_AR": "ام وقيبات",
    "CITYNAME_EN": "Om Waqaybat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18945",
    "CITYNAME_AR": "الحميضة",
    "CITYNAME_EN": "Al Humaydah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18946",
    "CITYNAME_AR": "ابار الكرار",
    "CITYNAME_EN": "Al Karrar wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18947",
    "CITYNAME_AR": "فم عنزاء",
    "CITYNAME_EN": "Fam Anzaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18948",
    "CITYNAME_AR": "بئر عنزا",
    "CITYNAME_EN": "Anza well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18949",
    "CITYNAME_AR": "الشهيباء",
    "CITYNAME_EN": "Ash-Shuhaibaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18950",
    "CITYNAME_AR": "جري",
    "CITYNAME_EN": "Jari",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18951",
    "CITYNAME_AR": "بئر الجيف",
    "CITYNAME_EN": "Al Jeef well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18952",
    "CITYNAME_AR": "بئر الجويات",
    "CITYNAME_EN": "Al Jawyat well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18953",
    "CITYNAME_AR": "بئر ماطر الغذي (المارده)",
    "CITYNAME_EN": "Mater Al Ghazi well (Al Maredah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18954",
    "CITYNAME_AR": "بئر سعد بن مسعد",
    "CITYNAME_EN": "Saad bin Mosaad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18955",
    "CITYNAME_AR": "بئر حامد بن رجا",
    "CITYNAME_EN": "Hamed bin Raja well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18956",
    "CITYNAME_AR": "بئر جابر بن جباره",
    "CITYNAME_EN": "Jaber bin Jabarah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18957",
    "CITYNAME_AR": "بئر بخيت بن غانم",
    "CITYNAME_EN": "Bakhait bin Ghanem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18958",
    "CITYNAME_AR": "الحمأ",
    "CITYNAME_EN": "Al Hamaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18959",
    "CITYNAME_AR": "الصفصف",
    "CITYNAME_EN": "As-Safsaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18960",
    "CITYNAME_AR": "سويقة",
    "CITYNAME_EN": "Suwaiqah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18961",
    "CITYNAME_AR": "ابار ام رتق",
    "CITYNAME_EN": "Om Ratq wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18962",
    "CITYNAME_AR": "مجموعة ابار الغلامية",
    "CITYNAME_EN": "Al Ghulamyah wells group",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18963",
    "CITYNAME_AR": "ام الحدج",
    "CITYNAME_EN": "Om Al Hadaj",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18964",
    "CITYNAME_AR": "الفقعلي",
    "CITYNAME_EN": "Al Faqa'ali",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18965",
    "CITYNAME_AR": "وجري",
    "CITYNAME_EN": "Wajeri",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18966",
    "CITYNAME_AR": "السريحه",
    "CITYNAME_EN": "As-Sarehah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18967",
    "CITYNAME_AR": "أم الظبا",
    "CITYNAME_EN": "Om Az-Zaba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18968",
    "CITYNAME_AR": "صمد الارينة",
    "CITYNAME_EN": "Samad Al Arbanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18969",
    "CITYNAME_AR": "الرقبه",
    "CITYNAME_EN": "Ar-Raqabah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18970",
    "CITYNAME_AR": "العشيش",
    "CITYNAME_EN": "Al Oshaysh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18971",
    "CITYNAME_AR": "المصلى",
    "CITYNAME_EN": "Al Masli",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18972",
    "CITYNAME_AR": "الخراره",
    "CITYNAME_EN": "Al Kharrarah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18973",
    "CITYNAME_AR": "بئرهليل بن محمد",
    "CITYNAME_EN": "Hulayel bin Mohamed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18974",
    "CITYNAME_AR": "كعانه",
    "CITYNAME_EN": "Kaanah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18975",
    "CITYNAME_AR": "الخلصاء",
    "CITYNAME_EN": "Al Khalsaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18976",
    "CITYNAME_AR": "الصادره",
    "CITYNAME_EN": "As-Saderah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18977",
    "CITYNAME_AR": "بئرالسديد",
    "CITYNAME_EN": "As-Sadeed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18978",
    "CITYNAME_AR": "الدعب",
    "CITYNAME_EN": "Ad-Daab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18979",
    "CITYNAME_AR": "مزرعة حمد العضب",
    "CITYNAME_EN": "Hamad Al Adab farm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18980",
    "CITYNAME_AR": "ركك وركيك",
    "CITYNAME_EN": "Rakak wa Rakek",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18981",
    "CITYNAME_AR": "بئر العلق",
    "CITYNAME_EN": "Al Alaq well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18982",
    "CITYNAME_AR": "بئر المقراة",
    "CITYNAME_EN": "Al Muqrah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18983",
    "CITYNAME_AR": "عضاد",
    "CITYNAME_EN": "Audad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18984",
    "CITYNAME_AR": "ثمايل ضاس",
    "CITYNAME_EN": "Thamayel Das",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18985",
    "CITYNAME_AR": "مزرعة محمد دخيل الله الذبياني",
    "CITYNAME_EN": "Mohamed Dakhil Allah Az-Zebyani farm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18986",
    "CITYNAME_AR": "مزارع القويز",
    "CITYNAME_EN": "Al Quwayez",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18987",
    "CITYNAME_AR": "مزراع عفاتر",
    "CITYNAME_EN": "Afater farms",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18988",
    "CITYNAME_AR": "افه",
    "CITYNAME_EN": "Afah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18989",
    "CITYNAME_AR": "بئر مراخ",
    "CITYNAME_EN": "Merakh well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18990",
    "CITYNAME_AR": "بئر الرصيفة",
    "CITYNAME_EN": "Ar-Rusayfah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18991",
    "CITYNAME_AR": "بئر الجبيلة",
    "CITYNAME_EN": "Al Jubaylah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18992",
    "CITYNAME_AR": "بئرسلم",
    "CITYNAME_EN": "Salam well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18993",
    "CITYNAME_AR": "بئر البريمه",
    "CITYNAME_EN": "Al Bareemah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18994",
    "CITYNAME_AR": "بئر المحتوت",
    "CITYNAME_EN": "Al Mahtoot well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18995",
    "CITYNAME_AR": "بئر عويد الكتيني",
    "CITYNAME_EN": "Awwaid Al Kutayni well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18996",
    "CITYNAME_AR": "بئركتان",
    "CITYNAME_EN": "Katan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18997",
    "CITYNAME_AR": "بئر الهيم",
    "CITYNAME_EN": "Al Hayem well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18998",
    "CITYNAME_AR": "بئر ماضى القفاف",
    "CITYNAME_EN": "Madi Al Qefaf well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "18999",
    "CITYNAME_AR": "بئر منسى",
    "CITYNAME_EN": "Mansi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19000",
    "CITYNAME_AR": "بئر سقما (النقره)",
    "CITYNAME_EN": "Saqma well (An-Naqrah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19001",
    "CITYNAME_AR": "بئر اللحى",
    "CITYNAME_EN": "Al Luha well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19002",
    "CITYNAME_AR": "السنيان",
    "CITYNAME_EN": "As-Sanyan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19003",
    "CITYNAME_AR": "بئر شاهر",
    "CITYNAME_EN": "Shaher well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19004",
    "CITYNAME_AR": "الثبره و دريبس",
    "CITYNAME_EN": "Ath-Thabarah wa Duraybas",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19005",
    "CITYNAME_AR": "البستان",
    "CITYNAME_EN": "Al Bustan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19006",
    "CITYNAME_AR": "بئر حسن",
    "CITYNAME_EN": "Hasan well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19007",
    "CITYNAME_AR": "بئر سافر",
    "CITYNAME_EN": "Safer well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19008",
    "CITYNAME_AR": "بئر سليم",
    "CITYNAME_EN": "Salim well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19009",
    "CITYNAME_AR": "بئر الجبيل (الردائف)",
    "CITYNAME_EN": "Al Jubail well (Ar-Radaef)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19010",
    "CITYNAME_AR": "الحاسمه وشبيرم",
    "CITYNAME_EN": "Al Hashemah wa Shubayram",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19011",
    "CITYNAME_AR": "بئر مريزيق بن عواد الخضري",
    "CITYNAME_EN": "Marezeq bin Awwad Al Khudayri well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19012",
    "CITYNAME_AR": "بئر حضيض",
    "CITYNAME_EN": "Hadeed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19013",
    "CITYNAME_AR": "بئر مسعد",
    "CITYNAME_EN": "Mosaad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19014",
    "CITYNAME_AR": "الضمو",
    "CITYNAME_EN": "Ad-Damou",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19015",
    "CITYNAME_AR": "بئر مسيب",
    "CITYNAME_EN": "Musayeb well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19016",
    "CITYNAME_AR": "المسيربيه",
    "CITYNAME_EN": "Al Musayryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19017",
    "CITYNAME_AR": "ابار حميد",
    "CITYNAME_EN": "Hameed wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19018",
    "CITYNAME_AR": "بئر فويز",
    "CITYNAME_EN": "Faweez well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19019",
    "CITYNAME_AR": "الفقير",
    "CITYNAME_EN": "Alfaqir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19020",
    "CITYNAME_AR": "المرداسية",
    "CITYNAME_EN": "Almirdasia",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19021",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19022",
    "CITYNAME_AR": "القبوريه",
    "CITYNAME_EN": "Alquburih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19023",
    "CITYNAME_AR": "الخلصه",
    "CITYNAME_EN": "Alkhalasuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19024",
    "CITYNAME_AR": "المحضه",
    "CITYNAME_EN": "Almahaduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19025",
    "CITYNAME_AR": "النعيمه",
    "CITYNAME_EN": "Alnaeimuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19026",
    "CITYNAME_AR": "وادي العطشان",
    "CITYNAME_EN": "Wadi Aleatshan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19027",
    "CITYNAME_AR": "الشوامين",
    "CITYNAME_EN": "Alshawaamin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19028",
    "CITYNAME_AR": "المرغه (الثانيه)",
    "CITYNAME_EN": "Almarghuh (Althaaniah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19029",
    "CITYNAME_AR": "بئر المغيسليه",
    "CITYNAME_EN": "Biir Almughyasiliuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19030",
    "CITYNAME_AR": "السدر",
    "CITYNAME_EN": "Alsadar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19031",
    "CITYNAME_AR": "المديرا",
    "CITYNAME_EN": "Almudira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19032",
    "CITYNAME_AR": "روضه الفيفا",
    "CITYNAME_EN": "Ruduh Alfiafa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19033",
    "CITYNAME_AR": "لقفاء",
    "CITYNAME_EN": "Liqafa'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19034",
    "CITYNAME_AR": "السكبيه الدقيقه",
    "CITYNAME_EN": "Alsakabiuh Aldaqiquh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19035",
    "CITYNAME_AR": "الرفيف المجرمه",
    "CITYNAME_EN": "Alrafif Almujramuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19036",
    "CITYNAME_AR": "الماتيه",
    "CITYNAME_EN": "Almatiyh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19037",
    "CITYNAME_AR": "وادي  الفرع",
    "CITYNAME_EN": "Wadi Al Fare",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19038",
    "CITYNAME_AR": "الرمضه",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19039",
    "CITYNAME_AR": "حرة رهط",
    "CITYNAME_EN": "Hurat Raht",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19040",
    "CITYNAME_AR": "الفراش",
    "CITYNAME_EN": "Alfarash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19041",
    "CITYNAME_AR": "المنشار",
    "CITYNAME_EN": "Alminshar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19042",
    "CITYNAME_AR": "الزبيرة",
    "CITYNAME_EN": "Alzabira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19043",
    "CITYNAME_AR": "المرغه الأولى",
    "CITYNAME_EN": "Almarghuh Al'Uwlaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19044",
    "CITYNAME_AR": "مشحيد",
    "CITYNAME_EN": "Mashahid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19045",
    "CITYNAME_AR": "مليح",
    "CITYNAME_EN": "Malih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19046",
    "CITYNAME_AR": "اليتمة",
    "CITYNAME_EN": "Alyutima",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19047",
    "CITYNAME_AR": "اردعي",
    "CITYNAME_EN": "Arduei",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19048",
    "CITYNAME_AR": "العيزة",
    "CITYNAME_EN": "Aleiza",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19049",
    "CITYNAME_AR": "وادي خضلة",
    "CITYNAME_EN": "Wadi Khudila",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19050",
    "CITYNAME_AR": "بئر السري",
    "CITYNAME_EN": "Biir Alsiriyu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19051",
    "CITYNAME_AR": "بئر الرتق",
    "CITYNAME_EN": "Biir Alratuq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19052",
    "CITYNAME_AR": "بئر مبارك بريك",
    "CITYNAME_EN": "Biir Mubarak Barik",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19053",
    "CITYNAME_AR": "القافله",
    "CITYNAME_EN": "Alqafiluh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19054",
    "CITYNAME_AR": "النقيعاء",
    "CITYNAME_EN": "Alnaqiea'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19055",
    "CITYNAME_AR": "العضيرسة",
    "CITYNAME_EN": "Aleudirisa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19056",
    "CITYNAME_AR": "المشانيه (الممشينيه)",
    "CITYNAME_EN": "Almashanih (Almamshinih)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19057",
    "CITYNAME_AR": "المسبعة",
    "CITYNAME_EN": "Almusbaea",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19058",
    "CITYNAME_AR": "السديرة",
    "CITYNAME_EN": "Alsadira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19059",
    "CITYNAME_AR": "أم فحوين",
    "CITYNAME_EN": "Am Fuhwin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19060",
    "CITYNAME_AR": "شعيب يسر",
    "CITYNAME_EN": "Shueayb Yasr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19061",
    "CITYNAME_AR": "بئر سمرين",
    "CITYNAME_EN": "Bayir Samarayn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19062",
    "CITYNAME_AR": "بئر ملوغ",
    "CITYNAME_EN": "Biir Malugh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19063",
    "CITYNAME_AR": "شعيب اليماني الشرقي",
    "CITYNAME_EN": "Shueayb Alyamani Alsharqii",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19064",
    "CITYNAME_AR": "فرشه الشامي",
    "CITYNAME_EN": "Farashah Alshamy",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19065",
    "CITYNAME_AR": "بئر رشيد المرش",
    "CITYNAME_EN": "Biir Rashid Almarash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19066",
    "CITYNAME_AR": "الجلبه",
    "CITYNAME_EN": "Aljalbuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19067",
    "CITYNAME_AR": "الضليل",
    "CITYNAME_EN": "Aldalil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19068",
    "CITYNAME_AR": "السفح",
    "CITYNAME_EN": "Alsafah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19069",
    "CITYNAME_AR": "النهضة",
    "CITYNAME_EN": "Alnahda",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19070",
    "CITYNAME_AR": "الحسي",
    "CITYNAME_EN": "Alhusi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19071",
    "CITYNAME_AR": "الدحو",
    "CITYNAME_EN": "Aldahu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19072",
    "CITYNAME_AR": "الجميزة",
    "CITYNAME_EN": "Aljamiza",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19073",
    "CITYNAME_AR": "الانثى (العقده)",
    "CITYNAME_EN": "Alanthaa (Aleaqdah)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19074",
    "CITYNAME_AR": "الجرم",
    "CITYNAME_EN": "Aljuram",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19075",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19076",
    "CITYNAME_AR": "دواره خلص",
    "CITYNAME_EN": "Dawaruh Khalas",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19077",
    "CITYNAME_AR": "فم البيد",
    "CITYNAME_EN": "Fum Albayd",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19078",
    "CITYNAME_AR": "الضاينه",
    "CITYNAME_EN": "Aldayinuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19079",
    "CITYNAME_AR": "بئر الترهوه",
    "CITYNAME_EN": "Biir Altarhuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19080",
    "CITYNAME_AR": "المرير",
    "CITYNAME_EN": "Almarir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19081",
    "CITYNAME_AR": "الراء",
    "CITYNAME_EN": "Alra'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19082",
    "CITYNAME_AR": "مليحا",
    "CITYNAME_EN": "Malihaan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19083",
    "CITYNAME_AR": "سميرة",
    "CITYNAME_EN": "Samira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19084",
    "CITYNAME_AR": "الهديدة",
    "CITYNAME_EN": "Alhadida",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19085",
    "CITYNAME_AR": "الحلبة",
    "CITYNAME_EN": "Alhalba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19086",
    "CITYNAME_AR": "بيداء سباح",
    "CITYNAME_EN": "Bida' Sabbah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19087",
    "CITYNAME_AR": "وادي النقيع",
    "CITYNAME_EN": "Wadi Alnaqie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19088",
    "CITYNAME_AR": "الخياطيه",
    "CITYNAME_EN": "Alkhayatih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19089",
    "CITYNAME_AR": "الاكحل",
    "CITYNAME_EN": "Alakhul",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19090",
    "CITYNAME_AR": "حنذ",
    "CITYNAME_EN": "Handh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19091",
    "CITYNAME_AR": "حي الرحاب",
    "CITYNAME_EN": "Hayi Alrahab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19092",
    "CITYNAME_AR": "المنجور",
    "CITYNAME_EN": "Almanjur",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19093",
    "CITYNAME_AR": "الوجيده",
    "CITYNAME_EN": "Alwajiduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19094",
    "CITYNAME_AR": "مغيسل",
    "CITYNAME_EN": "Mughaysil",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19095",
    "CITYNAME_AR": "خضره",
    "CITYNAME_EN": "Khadirah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19096",
    "CITYNAME_AR": "ابار الفارعه",
    "CITYNAME_EN": "Abar Alfarieuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19097",
    "CITYNAME_AR": "الملحه",
    "CITYNAME_EN": "Almalahuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19098",
    "CITYNAME_AR": "رحيه",
    "CITYNAME_EN": "Rahih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19099",
    "CITYNAME_AR": "قريه الفرعي",
    "CITYNAME_EN": "Qarih Alfireiu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19100",
    "CITYNAME_AR": "المثلث ومركز المرور",
    "CITYNAME_EN": "Almuthlath and Traffic Center",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19101",
    "CITYNAME_AR": "النغير",
    "CITYNAME_EN": "Alnaghir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19102",
    "CITYNAME_AR": "العذاب",
    "CITYNAME_EN": "Aleadhab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19103",
    "CITYNAME_AR": "الجرنافه",
    "CITYNAME_EN": "Aljirnafuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19104",
    "CITYNAME_AR": "شعيب المحطة",
    "CITYNAME_EN": "Shueayb Station",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19105",
    "CITYNAME_AR": "النشج السوسيه",
    "CITYNAME_EN": "Alnashj Alsuwsiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19106",
    "CITYNAME_AR": "ابو ضباع",
    "CITYNAME_EN": "Abu Dubae",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19107",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Albahruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19108",
    "CITYNAME_AR": "الربض",
    "CITYNAME_EN": "Alrabad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19109",
    "CITYNAME_AR": "الملبنه",
    "CITYNAME_EN": "Almulabanh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19110",
    "CITYNAME_AR": "الحديقه",
    "CITYNAME_EN": "Alhadiquh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19111",
    "CITYNAME_AR": "مزرعة ابراهيم سلمان",
    "CITYNAME_EN": "Farm of 'Iibrahim Salman",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19112",
    "CITYNAME_AR": "السده",
    "CITYNAME_EN": "Alsaduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19113",
    "CITYNAME_AR": "بئر طايل عتيق الحربي",
    "CITYNAME_EN": "Biir Tayil Eatiq Alharbii",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19114",
    "CITYNAME_AR": "مزرعه علي محيا",
    "CITYNAME_EN": "Farm of Eali Mahayana",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19115",
    "CITYNAME_AR": "العد",
    "CITYNAME_EN": "Alead",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19116",
    "CITYNAME_AR": "ام العيال",
    "CITYNAME_EN": "Am Aleial",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19117",
    "CITYNAME_AR": "اليسيره",
    "CITYNAME_EN": "Alyasiruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19118",
    "CITYNAME_AR": "المضيق",
    "CITYNAME_EN": "Almudiq",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19119",
    "CITYNAME_AR": "حي بن جامع",
    "CITYNAME_EN": "Hayi Bin Jamie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19120",
    "CITYNAME_AR": "بئر جميع",
    "CITYNAME_EN": "Biir Jmye",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19121",
    "CITYNAME_AR": "بئر قنيع",
    "CITYNAME_EN": "Bayir Qanie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19122",
    "CITYNAME_AR": "بئر ملحاء",
    "CITYNAME_EN": "Biir Mulha'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19123",
    "CITYNAME_AR": "الهنديه",
    "CITYNAME_EN": "Alhindayuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19124",
    "CITYNAME_AR": "الرميده",
    "CITYNAME_EN": "Alramiduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19125",
    "CITYNAME_AR": "لحج",
    "CITYNAME_EN": "Lihajin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19126",
    "CITYNAME_AR": "الجضعاء",
    "CITYNAME_EN": "Aljiduea'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19127",
    "CITYNAME_AR": "الهمجة",
    "CITYNAME_EN": "Alhamaja",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19128",
    "CITYNAME_AR": "الغضن",
    "CITYNAME_EN": "Alghadn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19129",
    "CITYNAME_AR": "صخوى",
    "CITYNAME_EN": "Sakhwaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19130",
    "CITYNAME_AR": "النبعه",
    "CITYNAME_EN": "Alnnabieuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19131",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Ghurab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19132",
    "CITYNAME_AR": "حي السيح",
    "CITYNAME_EN": "Hay Alsiyh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19133",
    "CITYNAME_AR": "بئر علي",
    "CITYNAME_EN": "Biir Eali",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19134",
    "CITYNAME_AR": "ابار الهنديه",
    "CITYNAME_EN": "Abar Alhindiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19135",
    "CITYNAME_AR": "الحمنه (المزرع)",
    "CITYNAME_EN": "Alhimnuh (Farms)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19136",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19137",
    "CITYNAME_AR": "الشعبه الشماليه (ام الحماط)",
    "CITYNAME_EN": "Alshaebuh Alshamaliuh (Ama Alhamat)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19138",
    "CITYNAME_AR": "الشعبه الجنوبيه",
    "CITYNAME_EN": "Alshaebuh Aljanubiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19139",
    "CITYNAME_AR": "رعل",
    "CITYNAME_EN": "Rael",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19140",
    "CITYNAME_AR": "المزرع (شنقب)",
    "CITYNAME_EN": "Almuzrae (Shnaqab)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19141",
    "CITYNAME_AR": "ام البرك",
    "CITYNAME_EN": "Am Albarik",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19142",
    "CITYNAME_AR": "السنويه",
    "CITYNAME_EN": "Alsanawiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19143",
    "CITYNAME_AR": "بئر ام السوانى",
    "CITYNAME_EN": "Bir Umm Alswana",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19144",
    "CITYNAME_AR": "بئر فارس",
    "CITYNAME_EN": "Bayir Faris",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19145",
    "CITYNAME_AR": "صوري",
    "CITYNAME_EN": "Suri",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19146",
    "CITYNAME_AR": "الملحة ",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19147",
    "CITYNAME_AR": "حي السيف",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19148",
    "CITYNAME_AR": "الغصين ",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19149",
    "CITYNAME_AR": "الحصحاص",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19150",
    "CITYNAME_AR": "الحشيفات",
    "CITYNAME_EN": "Alhashifat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19151",
    "CITYNAME_AR": "ام العظام",
    "CITYNAME_EN": "Umm Aleizam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19152",
    "CITYNAME_AR": "السليميه",
    "CITYNAME_EN": "Alsalimih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19153",
    "CITYNAME_AR": "اخوان",
    "CITYNAME_EN": "'Iikhwan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19154",
    "CITYNAME_AR": "السعليه",
    "CITYNAME_EN": "Alsaealayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19155",
    "CITYNAME_AR": "الحصحاص",
    "CITYNAME_EN": "Alhishas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19156",
    "CITYNAME_AR": "راين",
    "CITYNAME_EN": "Rayn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19157",
    "CITYNAME_AR": "الناصبيه",
    "CITYNAME_EN": "An-Nasbyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19158",
    "CITYNAME_AR": "عنم",
    "CITYNAME_EN": "Anam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19159",
    "CITYNAME_AR": "الطرقيه",
    "CITYNAME_EN": "At-Turaqyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19160",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Al Hano",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19161",
    "CITYNAME_AR": "العقبه",
    "CITYNAME_EN": "Aqaba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19162",
    "CITYNAME_AR": "القصيبة",
    "CITYNAME_EN": "Alqasiba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19163",
    "CITYNAME_AR": "المواريد",
    "CITYNAME_EN": "Almawarid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19164",
    "CITYNAME_AR": "الفارع",
    "CITYNAME_EN": "Alfarie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19165",
    "CITYNAME_AR": "البقعيه",
    "CITYNAME_EN": "Albiqaeih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19166",
    "CITYNAME_AR": "الصحيراء",
    "CITYNAME_EN": "Alsahira'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19167",
    "CITYNAME_AR": "فيد الاعلى",
    "CITYNAME_EN": "Fyd Al'Aelaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19168",
    "CITYNAME_AR": "فيد الاسفل",
    "CITYNAME_EN": "Fyd Al'Asfal",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19169",
    "CITYNAME_AR": "ام الرجوم",
    "CITYNAME_EN": "Am Alrujum",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19170",
    "CITYNAME_AR": "الصميمة",
    "CITYNAME_EN": "Alsamima",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19171",
    "CITYNAME_AR": "ثمره",
    "CITYNAME_EN": "Thamarih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19172",
    "CITYNAME_AR": "الحارش",
    "CITYNAME_EN": "Alharish",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19173",
    "CITYNAME_AR": "الجوفه",
    "CITYNAME_EN": "Aljawfuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19174",
    "CITYNAME_AR": "بئر الردهة",
    "CITYNAME_EN": "Ar-Rudhah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19175",
    "CITYNAME_AR": "العيص",
    "CITYNAME_EN": "Aleays",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19176",
    "CITYNAME_AR": "وادى عرفه",
    "CITYNAME_EN": "Wa'Adaa Earfuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19177",
    "CITYNAME_AR": "وادى هدمه",
    "CITYNAME_EN": "Wa'Adaa Hadamuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19178",
    "CITYNAME_AR": "بئر فايز",
    "CITYNAME_EN": "Bayir Fayiz",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19179",
    "CITYNAME_AR": "بئر مرشد الكشي",
    "CITYNAME_EN": "Biir Murshid Alkashii",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19180",
    "CITYNAME_AR": "القراصه",
    "CITYNAME_EN": "Alqarasuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19181",
    "CITYNAME_AR": "العامرى فم عرفه",
    "CITYNAME_EN": "Aleamaraa Fam Earfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19182",
    "CITYNAME_AR": "بئر عرفان",
    "CITYNAME_EN": "Bayir Eurfan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19183",
    "CITYNAME_AR": "عرفان",
    "CITYNAME_EN": "Earfan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19184",
    "CITYNAME_AR": "تلعة مسلم",
    "CITYNAME_EN": "Tileat Muslim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19185",
    "CITYNAME_AR": "البديع الرياشى",
    "CITYNAME_EN": "Albadie Alriyasha",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19186",
    "CITYNAME_AR": "وادى طليح",
    "CITYNAME_EN": "Wa'Adaa Talih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19187",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Alfare",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19188",
    "CITYNAME_AR": "بئر جاسر",
    "CITYNAME_EN": "Bayir Jasir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19189",
    "CITYNAME_AR": "دغاون",
    "CITYNAME_EN": "Daghawn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19190",
    "CITYNAME_AR": "القلب",
    "CITYNAME_EN": "Alqalb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19191",
    "CITYNAME_AR": "ضويلان",
    "CITYNAME_EN": "Dawaylan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19192",
    "CITYNAME_AR": "الضويله",
    "CITYNAME_EN": "Alduayluh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19193",
    "CITYNAME_AR": "ام رنيفات",
    "CITYNAME_EN": "Am Rnifat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19194",
    "CITYNAME_AR": "ضفيان",
    "CITYNAME_EN": "Difayan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19195",
    "CITYNAME_AR": "وادى كبر",
    "CITYNAME_EN": "Wa'Adaa Kabur",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19196",
    "CITYNAME_AR": "شدان",
    "CITYNAME_EN": "Shadan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19197",
    "CITYNAME_AR": "بئر محمد غانم",
    "CITYNAME_EN": "Biir Muhamad Ghanim",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19198",
    "CITYNAME_AR": "المثلث",
    "CITYNAME_EN": "Almathlath",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19199",
    "CITYNAME_AR": "سقام",
    "CITYNAME_EN": "Saqam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19200",
    "CITYNAME_AR": "سقام",
    "CITYNAME_EN": "Saqam",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19201",
    "CITYNAME_AR": "القرن",
    "CITYNAME_EN": "Alqarn",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19202",
    "CITYNAME_AR": "بئر كبر",
    "CITYNAME_EN": "Biir Kabur",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19203",
    "CITYNAME_AR": "المحم",
    "CITYNAME_EN": "Almahamu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19204",
    "CITYNAME_AR": "بئر عيال عياش",
    "CITYNAME_EN": "Biir Eial Eiash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19205",
    "CITYNAME_AR": "الشمسه",
    "CITYNAME_EN": "Alshamsuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19206",
    "CITYNAME_AR": "خران وام جيش",
    "CITYNAME_EN": "Kharan Wa'Umun Jaysh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19207",
    "CITYNAME_AR": "بغوه",
    "CITYNAME_EN": "Bighuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19208",
    "CITYNAME_AR": "قردعه",
    "CITYNAME_EN": "Baradaeah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19209",
    "CITYNAME_AR": "النقيبين",
    "CITYNAME_EN": "Alnaqibin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19210",
    "CITYNAME_AR": "العميد ابو سهلة",
    "CITYNAME_EN": "Aleamid 'Abu Sahlatan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19211",
    "CITYNAME_AR": "الدبه",
    "CITYNAME_EN": "Aldabuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19212",
    "CITYNAME_AR": "ملح الصفيحه",
    "CITYNAME_EN": "Milh Alsafihuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19213",
    "CITYNAME_AR": "الصفيحه",
    "CITYNAME_EN": "Alsafihuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19214",
    "CITYNAME_AR": "بئر عايد العنمي",
    "CITYNAME_EN": "Bayir Eayid Aleinmii",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19215",
    "CITYNAME_AR": "دمدم",
    "CITYNAME_EN": "Dmdm",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19216",
    "CITYNAME_AR": "بئر عتقه الميلبي",
    "CITYNAME_EN": "Bayir Eataqah Almaylibiu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19217",
    "CITYNAME_AR": "بئر عيال سلمان",
    "CITYNAME_EN": "Bayir Eial Salman",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19218",
    "CITYNAME_AR": "المضيبعه",
    "CITYNAME_EN": "Almudibieuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19219",
    "CITYNAME_AR": "بئر شليه",
    "CITYNAME_EN": "Biir Shalayh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19220",
    "CITYNAME_AR": "الدغـر",
    "CITYNAME_EN": "Aldighar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19221",
    "CITYNAME_AR": "المشاش",
    "CITYNAME_EN": "Almashash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19222",
    "CITYNAME_AR": "النغيرات",
    "CITYNAME_EN": "Alnaghayrat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19223",
    "CITYNAME_AR": "البوادي",
    "CITYNAME_EN": "Albawadi",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19224",
    "CITYNAME_AR": "بئر الغوقه",
    "CITYNAME_EN": "Biir Alghawquh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19225",
    "CITYNAME_AR": "بئر الخضيراء",
    "CITYNAME_EN": "Biir Alkhadira'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19226",
    "CITYNAME_AR": "ترعة",
    "CITYNAME_EN": "Turiea",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19227",
    "CITYNAME_AR": "مربظاء",
    "CITYNAME_EN": "Murbaza'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19228",
    "CITYNAME_AR": "جراجر",
    "CITYNAME_EN": "Jarajir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19229",
    "CITYNAME_AR": "الفقير",
    "CITYNAME_EN": "Alfaqir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19230",
    "CITYNAME_AR": "غمير المواشية",
    "CITYNAME_EN": "Ghamir Almuashia",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19231",
    "CITYNAME_AR": "غمير اللبدات (المناصير)",
    "CITYNAME_EN": "Ghamir Allabdat (Almanasir)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19232",
    "CITYNAME_AR": "غمير الأخاضرة",
    "CITYNAME_EN": "Ghamir Al'Akhadira",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19233",
    "CITYNAME_AR": "مزارع جبال الاجرد",
    "CITYNAME_EN": "Farm of Jibal Alajrid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19234",
    "CITYNAME_AR": "الملحا (ملحاالشوافعة)",
    "CITYNAME_EN": "Almalahaa (Mlahaalshwafeat)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19235",
    "CITYNAME_AR": "التجرة",
    "CITYNAME_EN": "Altajara",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19236",
    "CITYNAME_AR": "الشريق الشرقي",
    "CITYNAME_EN": "Alshariq Alsharqiu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19237",
    "CITYNAME_AR": "بئر هندى",
    "CITYNAME_EN": "Bayir Handaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19238",
    "CITYNAME_AR": "بئر سعيد عواد",
    "CITYNAME_EN": "Biir Saeid Ewad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19239",
    "CITYNAME_AR": "بئر صغير",
    "CITYNAME_EN": "Bir Saghir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19240",
    "CITYNAME_AR": "الجذوع",
    "CITYNAME_EN": "Aljudhue",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19241",
    "CITYNAME_AR": "البهميه (الشباريه)",
    "CITYNAME_EN": "Albahmayuh (Alshabarih)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19242",
    "CITYNAME_AR": "بئر حسن",
    "CITYNAME_EN": "Bayir Hasan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19243",
    "CITYNAME_AR": "بئر مران",
    "CITYNAME_EN": "Biir Muran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19244",
    "CITYNAME_AR": "الجم",
    "CITYNAME_EN": "Aljumu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19245",
    "CITYNAME_AR": "نخل مسرح",
    "CITYNAME_EN": "Nakhl Masrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19246",
    "CITYNAME_AR": "بئر اريكة",
    "CITYNAME_EN": "Biir Aryka",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19247",
    "CITYNAME_AR": "بئر الحربي",
    "CITYNAME_EN": "Bir Alharbiu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19248",
    "CITYNAME_AR": "بئر دخيل الله",
    "CITYNAME_EN": "Bayir Dkhyl Allah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19249",
    "CITYNAME_AR": "المضيبعة",
    "CITYNAME_EN": "Almudaybiea",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19250",
    "CITYNAME_AR": "ام سليمات",
    "CITYNAME_EN": "Am Salaymat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19251",
    "CITYNAME_AR": "الداره",
    "CITYNAME_EN": "Aldaaruh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19252",
    "CITYNAME_AR": "بئر الداهن",
    "CITYNAME_EN": "Biir Aldaahin",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19253",
    "CITYNAME_AR": "بئر مساعد جليدان",
    "CITYNAME_EN": "Biir Musaeid Jalidan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19254",
    "CITYNAME_AR": "بئر حمد المشاش",
    "CITYNAME_EN": "Bayir Hamd Almashash",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19255",
    "CITYNAME_AR": "بئر المشيريف",
    "CITYNAME_EN": "Biir Almushiarif",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19256",
    "CITYNAME_AR": "الزغبا",
    "CITYNAME_EN": "Alzughba",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19257",
    "CITYNAME_AR": "الأبيض",
    "CITYNAME_EN": "Al'Abyad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19258",
    "CITYNAME_AR": "السليميه",
    "CITYNAME_EN": "Alsalimih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19259",
    "CITYNAME_AR": "الدير",
    "CITYNAME_EN": "Aldiyr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19260",
    "CITYNAME_AR": "الرفيحاء",
    "CITYNAME_EN": "Alrafiha'",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19261",
    "CITYNAME_AR": "النباعه",
    "CITYNAME_EN": "Alnibaeuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19262",
    "CITYNAME_AR": "تناضب",
    "CITYNAME_EN": "Tanadub",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19263",
    "CITYNAME_AR": "الظما",
    "CITYNAME_EN": "Ath-Thama",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19264",
    "CITYNAME_AR": "بئر عوده",
    "CITYNAME_EN": "Bayir Eawdah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19265",
    "CITYNAME_AR": "بئر حامد",
    "CITYNAME_EN": "Bayir Hamid",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19266",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Milh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19267",
    "CITYNAME_AR": "ام الحيران",
    "CITYNAME_EN": "Umm Alhiran",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19268",
    "CITYNAME_AR": "المشيريف",
    "CITYNAME_EN": "Almushirif",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19269",
    "CITYNAME_AR": "ينبع",
    "CITYNAME_EN": "Yanbu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19270",
    "CITYNAME_AR": "ينبع الصناعيه",
    "CITYNAME_EN": "Yanbaa As-Senayah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19271",
    "CITYNAME_AR": "بئر القاضي",
    "CITYNAME_EN": "Al Qadi well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19272",
    "CITYNAME_AR": "تفيدة",
    "CITYNAME_EN": "Tafeedah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19273",
    "CITYNAME_AR": "ام الحيران",
    "CITYNAME_EN": "Om Al Hayran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19274",
    "CITYNAME_AR": "الواديين (ابوشكير)",
    "CITYNAME_EN": "Al Wadeen (Abo Shukair)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19275",
    "CITYNAME_AR": "العماير",
    "CITYNAME_EN": "Al Amaer",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19276",
    "CITYNAME_AR": "النعمانيه",
    "CITYNAME_EN": "An-Nuamanyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19277",
    "CITYNAME_AR": "القبقابه",
    "CITYNAME_EN": "Al Qababyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19278",
    "CITYNAME_AR": "بئر بن زويد",
    "CITYNAME_EN": "Bin Zuweid well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19279",
    "CITYNAME_AR": "رمث المخرف",
    "CITYNAME_EN": "Ramath Al Makhref",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19280",
    "CITYNAME_AR": "حاره ام العشم",
    "CITYNAME_EN": "Om Al Ashm alley",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19281",
    "CITYNAME_AR": "آبار قصب",
    "CITYNAME_EN": "Qasab wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19282",
    "CITYNAME_AR": "النجف",
    "CITYNAME_EN": "Al-Najaf",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19283",
    "CITYNAME_AR": "البحيره",
    "CITYNAME_EN": "Al Buhayrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19284",
    "CITYNAME_AR": "بحيرة سعيدة",
    "CITYNAME_EN": "Saeedah lake",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19285",
    "CITYNAME_AR": "ضبعان",
    "CITYNAME_EN": "Dabaan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19286",
    "CITYNAME_AR": "القصوريه",
    "CITYNAME_EN": "Al Qasuryah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19287",
    "CITYNAME_AR": "الهلالية",
    "CITYNAME_EN": "Al Helalyah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19288",
    "CITYNAME_AR": "ام شجيرات",
    "CITYNAME_EN": "Om Shugayrat",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19289",
    "CITYNAME_AR": "شخت البحار",
    "CITYNAME_EN": "Shakht Al Behar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19290",
    "CITYNAME_AR": "بئر رشيد بن محمد",
    "CITYNAME_EN": "Rashid bin Mohamed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19291",
    "CITYNAME_AR": "بئر الثمد",
    "CITYNAME_EN": "Ath-Thamad well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19292",
    "CITYNAME_AR": "ابار السنويه",
    "CITYNAME_EN": "As-Sanawyah wells",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19293",
    "CITYNAME_AR": "بئر محمد سعيد",
    "CITYNAME_EN": "Mohamed Saeed well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19294",
    "CITYNAME_AR": "بئر الحسنه",
    "CITYNAME_EN": "Al Hasanah well",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19295",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "As-Sulaym",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19296",
    "CITYNAME_AR": "عريد",
    "CITYNAME_EN": "Arbad",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19297",
    "CITYNAME_AR": "الناصفه",
    "CITYNAME_EN": "Al Nasefa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19298",
    "CITYNAME_AR": "النباة",
    "CITYNAME_EN": "An-Nabaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19299",
    "CITYNAME_AR": "النعيريه",
    "CITYNAME_EN": "Nariya",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19300",
    "CITYNAME_AR": "الحناكيه",
    "CITYNAME_EN": "Alhanakiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19301",
    "CITYNAME_AR": "مشاش بن نماي",
    "CITYNAME_EN": "Mshash Bin Nmay",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19302",
    "CITYNAME_AR": "بئر قوزه",
    "CITYNAME_EN": "Bayir Qawzah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19303",
    "CITYNAME_AR": "المحفر",
    "CITYNAME_EN": "Almuhafir",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19304",
    "CITYNAME_AR": "المديسيس",
    "CITYNAME_EN": "Almudysis",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19305",
    "CITYNAME_AR": "العوشزى",
    "CITYNAME_EN": "Aleushzaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19306",
    "CITYNAME_AR": "المريه",
    "CITYNAME_EN": "Almariuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19307",
    "CITYNAME_AR": "ام شكاعه",
    "CITYNAME_EN": "Am Shakaeuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19308",
    "CITYNAME_AR": "السمينيه",
    "CITYNAME_EN": "Alsaminiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19309",
    "CITYNAME_AR": "قرية لوي ",
    "CITYNAME_EN": "Nazalah Muhaya Bin Duyahaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19310",
    "CITYNAME_AR": "بلوده",
    "CITYNAME_EN": "Balawduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19311",
    "CITYNAME_AR": "الشقرة",
    "CITYNAME_EN": "Alshaqra",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19312",
    "CITYNAME_AR": "العقيلة",
    "CITYNAME_EN": "Aleaqila",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19313",
    "CITYNAME_AR": "المغرة",
    "CITYNAME_EN": "Almaghra",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19314",
    "CITYNAME_AR": "عرجا",
    "CITYNAME_EN": "Earajanaan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19315",
    "CITYNAME_AR": "زويده",
    "CITYNAME_EN": "Zawiduh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19316",
    "CITYNAME_AR": "صبحا الجحفه",
    "CITYNAME_EN": "Subhana Aljahfah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19317",
    "CITYNAME_AR": "صبحا التيسى",
    "CITYNAME_EN": "Subhana Altaysaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19318",
    "CITYNAME_AR": "وديه",
    "CITYNAME_EN": "Wadih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19319",
    "CITYNAME_AR": "قصيره",
    "CITYNAME_EN": "Qsyrh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19320",
    "CITYNAME_AR": "الهميج",
    "CITYNAME_EN": "Alhamij",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19321",
    "CITYNAME_AR": "عريفجان",
    "CITYNAME_EN": "Earifjan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19322",
    "CITYNAME_AR": "بئر راشد",
    "CITYNAME_EN": "Bayir Rashid",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19323",
    "CITYNAME_AR": "بدائع الحناحنه",
    "CITYNAME_EN": "Bdayie Alhanahinih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19324",
    "CITYNAME_AR": "بدائع العوض",
    "CITYNAME_EN": "Bdayie Aleawd",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19325",
    "CITYNAME_AR": "الرفائع",
    "CITYNAME_EN": "Arafayie",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19326",
    "CITYNAME_AR": "بئر الزعفرانه",
    "CITYNAME_EN": "Biir Alzaeafranuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19327",
    "CITYNAME_AR": "الشقران",
    "CITYNAME_EN": "Alshuqran",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19328",
    "CITYNAME_AR": "جبال العلم",
    "CITYNAME_EN": "Jibal Aleilm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19329",
    "CITYNAME_AR": "النماره",
    "CITYNAME_EN": "Alnamaruh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19330",
    "CITYNAME_AR": "فيضة المسعار",
    "CITYNAME_EN": "Faydat Almisear",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19331",
    "CITYNAME_AR": "بدايع العلم",
    "CITYNAME_EN": "Badayie Aleilm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19332",
    "CITYNAME_AR": "المرموثه",
    "CITYNAME_EN": "Almarmuthuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19333",
    "CITYNAME_AR": "المعضب",
    "CITYNAME_EN": "Almuedib",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19334",
    "CITYNAME_AR": "ابو شويحات",
    "CITYNAME_EN": "'Abu Shwyhat",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19335",
    "CITYNAME_AR": "الهمج",
    "CITYNAME_EN": "Alhamaj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19336",
    "CITYNAME_AR": "فيضة الهمج",
    "CITYNAME_EN": "Fidat Alhamaj",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19337",
    "CITYNAME_AR": "عماير العلم",
    "CITYNAME_EN": "Eamayir Aleilm",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "19338",
    "CITYNAME_AR": "العثياء",
    "CITYNAME_EN": "Al Aithaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19339",
    "CITYNAME_AR": "الضميريه",
    "CITYNAME_EN": "Aldamiriuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19340",
    "CITYNAME_AR": "الحجريه",
    "CITYNAME_EN": "Alhijariuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19341",
    "CITYNAME_AR": "الحبابيه",
    "CITYNAME_EN": "Alhababih",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19342",
    "CITYNAME_AR": "رحبه الفرس",
    "CITYNAME_EN": "Rahabah Alfurs",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19343",
    "CITYNAME_AR": "المسبعة",
    "CITYNAME_EN": "Al Mesbaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19344",
    "CITYNAME_AR": "الحاقنة",
    "CITYNAME_EN": "Alhaqina",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19345",
    "CITYNAME_AR": "حزره",
    "CITYNAME_EN": "Hazrah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19346",
    "CITYNAME_AR": "المرير",
    "CITYNAME_EN": "Almryr(Dihilat Alfatr)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19347",
    "CITYNAME_AR": "ابار القنينى",
    "CITYNAME_EN": "Abar Alquninaa",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19348",
    "CITYNAME_AR": "ابار منيه",
    "CITYNAME_EN": "Abar Muniah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19349",
    "CITYNAME_AR": "ضعه",
    "CITYNAME_EN": "Daeh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19350",
    "CITYNAME_AR": "غراب",
    "CITYNAME_EN": "Ghurab",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19351",
    "CITYNAME_AR": "دحلة الفاطر",
    "CITYNAME_EN": "Dẖlh Al Fāṯr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19352",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19366",
    "CITYNAME_AR": "الجبيل   ",
    "CITYNAME_EN": "Jubail",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19367",
    "CITYNAME_AR": "قريه العباء",
    "CITYNAME_EN": "Al Abaa Village ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19368",
    "CITYNAME_AR": "مدينه الجبيل الصناعيه",
    "CITYNAME_EN": "Jubail Industrial City ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19369",
    "CITYNAME_AR": "اسكان وزارة الداخليه",
    "CITYNAME_EN": "Ministry of The Interior Accommodation",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19370",
    "CITYNAME_AR": "جوسمين",
    "CITYNAME_EN": "Gomsin ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19371",
    "CITYNAME_AR": "جنه",
    "CITYNAME_EN": "Jannah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19372",
    "CITYNAME_AR": "أبوحدرية",
    "CITYNAME_EN": "Abu Hadrabah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19373",
    "CITYNAME_AR": "رأس تنورة",
    "CITYNAME_EN": "Ras Tanura",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19374",
    "CITYNAME_AR": "غزلان (محطه كهرباءغزلان)",
    "CITYNAME_EN": "Ghazlan (Ghazlan Electric Station)",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19375",
    "CITYNAME_AR": "تبوك",
    "CITYNAME_EN": "Tbwk",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19376",
    "CITYNAME_AR": "دمج",
    "CITYNAME_EN": "Damj",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19377",
    "CITYNAME_AR": "العويند",
    "CITYNAME_EN": "Aleuynd",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19378",
    "CITYNAME_AR": "هجرة ام عويقيله",
    "CITYNAME_EN": "Hijrat Umm Ewyqylh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19379",
    "CITYNAME_AR": "قنا",
    "CITYNAME_EN": "Quna",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19380",
    "CITYNAME_AR": "الطلعه",
    "CITYNAME_EN": "Altalaeah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19381",
    "CITYNAME_AR": "سباق الهجن",
    "CITYNAME_EN": "Sibaq Alhajn",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19382",
    "CITYNAME_AR": "شقرى الجديده",
    "CITYNAME_EN": "Shuqraa Aljadidih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19383",
    "CITYNAME_AR": "شقرى القديمه",
    "CITYNAME_EN": "Shuqraa Alqadimuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19384",
    "CITYNAME_AR": "روافه",
    "CITYNAME_EN": "Rawafih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19385",
    "CITYNAME_AR": "بجده",
    "CITYNAME_EN": "Bijdah",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19386",
    "CITYNAME_AR": "ارتواز ام بريره",
    "CITYNAME_EN": "Airtiwaz Umm Barirh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19387",
    "CITYNAME_AR": "رحيب",
    "CITYNAME_EN": "Rahib",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19388",
    "CITYNAME_AR": "سمينه",
    "CITYNAME_EN": "Saminuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19389",
    "CITYNAME_AR": "عين الأخضر",
    "CITYNAME_EN": "Eayan Al'Akhdar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19390",
    "CITYNAME_AR": "مزارع البيضاء",
    "CITYNAME_EN": " Albayda' Farms ",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19391",
    "CITYNAME_AR": "حميط",
    "CITYNAME_EN": "Hamit",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19392",
    "CITYNAME_AR": "الاخضر",
    "CITYNAME_EN": "Al'Akhdar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19393",
    "CITYNAME_AR": "بيار الأخضر",
    "CITYNAME_EN": "Biar Al'Akhdar",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19394",
    "CITYNAME_AR": "البديعه",
    "CITYNAME_EN": "Albadieuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19395",
    "CITYNAME_AR": "العنبه",
    "CITYNAME_EN": "Aleinbuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19396",
    "CITYNAME_AR": "الرمضه",
    "CITYNAME_EN": "Alramaduh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19397",
    "CITYNAME_AR": "رايه",
    "CITYNAME_EN": "Rayih",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19398",
    "CITYNAME_AR": "السرو",
    "CITYNAME_EN": "Alsuruw",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19399",
    "CITYNAME_AR": "النابع",
    "CITYNAME_EN": "Alnaabie",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19400",
    "CITYNAME_AR": "الضلفه",
    "CITYNAME_EN": "Aldalafuh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19401",
    "CITYNAME_AR": "نعمى",
    "CITYNAME_EN": "Naemaa",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19402",
    "CITYNAME_AR": "رأس الخريطة",
    "CITYNAME_EN": "Ras Alkharita",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19403",
    "CITYNAME_AR": "الطائف",
    "CITYNAME_EN": "Alttayif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19404",
    "CITYNAME_AR": "الحويه",
    "CITYNAME_EN": "Alhawayuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19405",
    "CITYNAME_AR": "الصخيره وماحولها",
    "CITYNAME_EN": "Alsakhiruh Wamahuluha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19406",
    "CITYNAME_AR": "ابوحرب",
    "CITYNAME_EN": "Abwahrb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19407",
    "CITYNAME_AR": "السريج",
    "CITYNAME_EN": "Alsarij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19408",
    "CITYNAME_AR": "الذروه والعاقد وماحولها",
    "CITYNAME_EN": "Aldhuruh Waleaqid Wamahulaha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19409",
    "CITYNAME_AR": "الستار",
    "CITYNAME_EN": "Alsitar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19410",
    "CITYNAME_AR": "ام العوشز",
    "CITYNAME_EN": "Umm Aleawshaz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19411",
    "CITYNAME_AR": "الشميليه",
    "CITYNAME_EN": "Alshamiliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19412",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Alqawz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19413",
    "CITYNAME_AR": "الروضه",
    "CITYNAME_EN": "Alruwduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19414",
    "CITYNAME_AR": "ام العراد",
    "CITYNAME_EN": "Umm Aleirad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19415",
    "CITYNAME_AR": "القراره",
    "CITYNAME_EN": "Alqararuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19416",
    "CITYNAME_AR": "قرين حياش",
    "CITYNAME_EN": "Qarin Hiaash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19417",
    "CITYNAME_AR": "العمارا",
    "CITYNAME_EN": "Aleamara",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19418",
    "CITYNAME_AR": "الابهر",
    "CITYNAME_EN": "Alabhur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19419",
    "CITYNAME_AR": "الملعب",
    "CITYNAME_EN": "Almaleab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19420",
    "CITYNAME_AR": "اوطيلح والعايده",
    "CITYNAME_EN": "Awtylh Waleayiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19421",
    "CITYNAME_AR": "الغمير",
    "CITYNAME_EN": "Alghamir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19422",
    "CITYNAME_AR": "ام الدبيلات",
    "CITYNAME_EN": "Umm Aldabylat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19423",
    "CITYNAME_AR": "وجده ومخطط الريع",
    "CITYNAME_EN": "Wajadah Wamukhatat Alriye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19424",
    "CITYNAME_AR": "وقدان",
    "CITYNAME_EN": "Waqadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19425",
    "CITYNAME_AR": "الغرابه",
    "CITYNAME_EN": "Algharabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19426",
    "CITYNAME_AR": "بن صالح",
    "CITYNAME_EN": "Bin Salih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19427",
    "CITYNAME_AR": "العرابيه",
    "CITYNAME_EN": "Al Arabiya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19428",
    "CITYNAME_AR": "عباسه",
    "CITYNAME_EN": "Eabasuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19429",
    "CITYNAME_AR": "الروقه",
    "CITYNAME_EN": "Alruwquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19430",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Alsuwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19431",
    "CITYNAME_AR": "العسيله",
    "CITYNAME_EN": "Aleasiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19432",
    "CITYNAME_AR": "القملية",
    "CITYNAME_EN": "Alqamalia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19433",
    "CITYNAME_AR": "ام البكار",
    "CITYNAME_EN": "Umm Albakar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19434",
    "CITYNAME_AR": "قرى سلامه",
    "CITYNAME_EN": "Quraa Salamih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19435",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19436",
    "CITYNAME_AR": "القنينه",
    "CITYNAME_EN": "Alqaninuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19437",
    "CITYNAME_AR": "سيار",
    "CITYNAME_EN": "Sayar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19438",
    "CITYNAME_AR": "المراعين",
    "CITYNAME_EN": "Almuraein",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19439",
    "CITYNAME_AR": "الفراش",
    "CITYNAME_EN": "Alfarash",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19440",
    "CITYNAME_AR": "النيمه",
    "CITYNAME_EN": "Alnaymuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19441",
    "CITYNAME_AR": "الشعب",
    "CITYNAME_EN": "Alshaeb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19442",
    "CITYNAME_AR": "بن معيوف",
    "CITYNAME_EN": "Bin Maeyuf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19443",
    "CITYNAME_AR": "الحمراء",
    "CITYNAME_EN": "Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19444",
    "CITYNAME_AR": "حائط الماء",
    "CITYNAME_EN": "Hayit Alma'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19445",
    "CITYNAME_AR": "الحفيره",
    "CITYNAME_EN": "Alhafiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19446",
    "CITYNAME_AR": "العاصد",
    "CITYNAME_EN": "Aleasid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19447",
    "CITYNAME_AR": "المزاحمه",
    "CITYNAME_EN": "Almazahimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19448",
    "CITYNAME_AR": "الحضاير",
    "CITYNAME_EN": "Alhadayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19449",
    "CITYNAME_AR": "العرقوب",
    "CITYNAME_EN": "Aleurqub",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19450",
    "CITYNAME_AR": "مليحان",
    "CITYNAME_EN": "Malihan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19451",
    "CITYNAME_AR": "ام الخلطه",
    "CITYNAME_EN": "Umm Alkhulatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19452",
    "CITYNAME_AR": "النقيره",
    "CITYNAME_EN": "Alnaqiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19453",
    "CITYNAME_AR": "الريع",
    "CITYNAME_EN": "Alriye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19454",
    "CITYNAME_AR": "الشفاء",
    "CITYNAME_EN": "Alshifa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19455",
    "CITYNAME_AR": "الخليف",
    "CITYNAME_EN": "Alkhalif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19456",
    "CITYNAME_AR": "الحوطه",
    "CITYNAME_EN": "Alhutuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19457",
    "CITYNAME_AR": "المراشده والصواوين",
    "CITYNAME_EN": "Almurashiduh Walsawawin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19458",
    "CITYNAME_AR": "العويدات",
    "CITYNAME_EN": "Aleuaydat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19459",
    "CITYNAME_AR": "الظهران",
    "CITYNAME_EN": "Alzahran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19460",
    "CITYNAME_AR": "الحماميد",
    "CITYNAME_EN": "Alhamamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19461",
    "CITYNAME_AR": "الرجيلات",
    "CITYNAME_EN": "Alrajilat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19462",
    "CITYNAME_AR": "ابوعراد",
    "CITYNAME_EN": "Abwaerad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19463",
    "CITYNAME_AR": "سخايط (العماريه)",
    "CITYNAME_EN": "Sakhayit (Alieamarih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19464",
    "CITYNAME_AR": "الروسان",
    "CITYNAME_EN": "Alrwsan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19465",
    "CITYNAME_AR": "السراحين",
    "CITYNAME_EN": "Alsarahin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19466",
    "CITYNAME_AR": "السلامين والدحامسه",
    "CITYNAME_EN": "Alsallamin Waldahamisuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19467",
    "CITYNAME_AR": "ام النخله",
    "CITYNAME_EN": "Umm Alnakhluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19468",
    "CITYNAME_AR": "المقافشه (الخشرمه)",
    "CITYNAME_EN": "Almaqafishih (Alkhashrumh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19469",
    "CITYNAME_AR": "منقيه",
    "CITYNAME_EN": "Manqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19470",
    "CITYNAME_AR": "الشواحطه",
    "CITYNAME_EN": "Alshawahituh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19471",
    "CITYNAME_AR": "الاصيفر",
    "CITYNAME_EN": "Alasyfr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19472",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Alsuwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19473",
    "CITYNAME_AR": "الرقعه والشويحطه",
    "CITYNAME_EN": "Alraqeuh Walshuwyhituh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19474",
    "CITYNAME_AR": "الحنو",
    "CITYNAME_EN": "Alhunuw",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19475",
    "CITYNAME_AR": "الضباعين",
    "CITYNAME_EN": "Aldabaein",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19476",
    "CITYNAME_AR": "المشاييخ وجرجه",
    "CITYNAME_EN": "Almashayikh Wajarjah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19477",
    "CITYNAME_AR": "سديره",
    "CITYNAME_EN": "Sadiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19478",
    "CITYNAME_AR": "الجحادله وجلال الفير",
    "CITYNAME_EN": "Aljahadilh Wujalal Alfir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19479",
    "CITYNAME_AR": "سد السملقي القديم",
    "CITYNAME_EN": "Sadi Alsamlqi Alqadim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19480",
    "CITYNAME_AR": "السوده",
    "CITYNAME_EN": "Alsuwduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19481",
    "CITYNAME_AR": "الشطبه",
    "CITYNAME_EN": "Alshatbh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19482",
    "CITYNAME_AR": "الثرمه",
    "CITYNAME_EN": "Altharamuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19483",
    "CITYNAME_AR": "كمب المواصلات والاعرج",
    "CITYNAME_EN": "Transportation Camp",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19484",
    "CITYNAME_AR": "جناب المروه",
    "CITYNAME_EN": "Janab Almuruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19485",
    "CITYNAME_AR": "القارى",
    "CITYNAME_EN": "Alqaraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19486",
    "CITYNAME_AR": "الحائط والعتام",
    "CITYNAME_EN": "Alhayit Waleitam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19487",
    "CITYNAME_AR": "العويجاء",
    "CITYNAME_EN": "Aleuyja'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19488",
    "CITYNAME_AR": "العشره",
    "CITYNAME_EN": "Aleashruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19489",
    "CITYNAME_AR": "جرموز والسد",
    "CITYNAME_EN": "Jarumuz Walsidu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19490",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Alfireuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19491",
    "CITYNAME_AR": "الشعبه",
    "CITYNAME_EN": "Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19492",
    "CITYNAME_AR": "الضروه",
    "CITYNAME_EN": "Alduruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19493",
    "CITYNAME_AR": "نمره",
    "CITYNAME_EN": "Namarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19494",
    "CITYNAME_AR": "المسيل",
    "CITYNAME_EN": "Almusayil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19495",
    "CITYNAME_AR": "عرق عطيه",
    "CITYNAME_EN": "Earaq Eatih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19496",
    "CITYNAME_AR": "الصحن",
    "CITYNAME_EN": "Alsahn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19497",
    "CITYNAME_AR": "المروه",
    "CITYNAME_EN": "Almuruwh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19498",
    "CITYNAME_AR": "الشعبه (بخيت)",
    "CITYNAME_EN": "Alshaebuh (Bkhit)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19499",
    "CITYNAME_AR": "ام العفار",
    "CITYNAME_EN": "Umm Aleafar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19500",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Allahyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19501",
    "CITYNAME_AR": "الملواه",
    "CITYNAME_EN": "Almulwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19502",
    "CITYNAME_AR": "وادى ال عمر",
    "CITYNAME_EN": "Wa'Adaa Al Eumar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19503",
    "CITYNAME_AR": "الوادي الشرقى",
    "CITYNAME_EN": "Alwadi Alsharqaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19504",
    "CITYNAME_AR": "الشعبه الحمراء",
    "CITYNAME_EN": "Alshaebuh Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19505",
    "CITYNAME_AR": "حريشه",
    "CITYNAME_EN": "Harishuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19506",
    "CITYNAME_AR": "ابوالعراد",
    "CITYNAME_EN": "Abwalerad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19507",
    "CITYNAME_AR": "الطوى",
    "CITYNAME_EN": "Altuwaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19508",
    "CITYNAME_AR": "ال زيد",
    "CITYNAME_EN": "Al Zayd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19509",
    "CITYNAME_AR": "العثريه",
    "CITYNAME_EN": "Aleitharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19510",
    "CITYNAME_AR": "ام العراعر",
    "CITYNAME_EN": "Umm Alearaeir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19511",
    "CITYNAME_AR": "الجله والحدبه",
    "CITYNAME_EN": "Aljuluh Walhadbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19512",
    "CITYNAME_AR": "الثبته والسبيل",
    "CITYNAME_EN": "Althabtuh Walsabil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19513",
    "CITYNAME_AR": "المضيق",
    "CITYNAME_EN": "Al Madeeq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19514",
    "CITYNAME_AR": "الوتيره",
    "CITYNAME_EN": "Alwatiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19515",
    "CITYNAME_AR": "الرضمه",
    "CITYNAME_EN": "Alradamah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19516",
    "CITYNAME_AR": "المرحض",
    "CITYNAME_EN": "Almarhad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19517",
    "CITYNAME_AR": "حجلاء",
    "CITYNAME_EN": "Hajala'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19518",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Alkhasiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19519",
    "CITYNAME_AR": "الحديب",
    "CITYNAME_EN": "Alhadib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19520",
    "CITYNAME_AR": "النصبه",
    "CITYNAME_EN": "Alnasbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19521",
    "CITYNAME_AR": "ابوغيل والعمرين",
    "CITYNAME_EN": "Abwghil Waleamrin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19522",
    "CITYNAME_AR": "وادى الفقيه",
    "CITYNAME_EN": "Wa'Adaa Alfaqih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19523",
    "CITYNAME_AR": "قريضه",
    "CITYNAME_EN": "Qariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19524",
    "CITYNAME_AR": "الحوته وال زايد",
    "CITYNAME_EN": "Alhuatuh Wal Zayid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19525",
    "CITYNAME_AR": "العقده (الحصن)",
    "CITYNAME_EN": "Aleaqduh (Alahasan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19526",
    "CITYNAME_AR": "وادى عبيس",
    "CITYNAME_EN": "Wa'Adaa Eabays",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19527",
    "CITYNAME_AR": "المنامه",
    "CITYNAME_EN": "Almanamuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19528",
    "CITYNAME_AR": "ذى ضبى واللبه وفالق",
    "CITYNAME_EN": "Dhaa Dubana Wallabih Wafaliq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19529",
    "CITYNAME_AR": "ذى حماط",
    "CITYNAME_EN": "Dhaa Hamat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19530",
    "CITYNAME_AR": "وادى عورش وماحولها",
    "CITYNAME_EN": "Wa'Adaa Ewrsh Wamahulha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19531",
    "CITYNAME_AR": "مخطط جبل رب",
    "CITYNAME_EN": "Mukhatat Jabal Rabi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19532",
    "CITYNAME_AR": "الطوره",
    "CITYNAME_EN": "Altawruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19533",
    "CITYNAME_AR": "المختبى",
    "CITYNAME_EN": "Almukhtabaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19534",
    "CITYNAME_AR": "العوامره",
    "CITYNAME_EN": "Aleawamiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19535",
    "CITYNAME_AR": "العقرب",
    "CITYNAME_EN": "Aleaqarab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19536",
    "CITYNAME_AR": "ام هيثم",
    "CITYNAME_EN": "Umm Haytham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19537",
    "CITYNAME_AR": "قرى وادى الاخاضر",
    "CITYNAME_EN": "Quraa Wa'Adaa Alakhadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19538",
    "CITYNAME_AR": "الجفر",
    "CITYNAME_EN": "Aljafr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19539",
    "CITYNAME_AR": "صلبه",
    "CITYNAME_EN": "Salabah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19540",
    "CITYNAME_AR": "البارده",
    "CITYNAME_EN": "Albariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19541",
    "CITYNAME_AR": "المجنب",
    "CITYNAME_EN": "Almujnib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19542",
    "CITYNAME_AR": "العبلاء",
    "CITYNAME_EN": "Aleubla'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19543",
    "CITYNAME_AR": "ام الشرم",
    "CITYNAME_EN": "Umm Alsharam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19544",
    "CITYNAME_AR": "قرى وادى جليل",
    "CITYNAME_EN": "Quraa Wa'Adaa Jalil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19545",
    "CITYNAME_AR": "الاعاضيد",
    "CITYNAME_EN": "Alaeadyd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19546",
    "CITYNAME_AR": "مملكه",
    "CITYNAME_EN": "Mumlikuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19547",
    "CITYNAME_AR": "صر",
    "CITYNAME_EN": "Sir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19548",
    "CITYNAME_AR": "المبيرز",
    "CITYNAME_EN": "Almubayriz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19549",
    "CITYNAME_AR": "المثناه",
    "CITYNAME_EN": "Almathanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19550",
    "CITYNAME_AR": "طاده",
    "CITYNAME_EN": "Tadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19551",
    "CITYNAME_AR": "الزود (الحلاة)",
    "CITYNAME_EN": "Alzuwd (Alhalata)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19552",
    "CITYNAME_AR": "القريشات",
    "CITYNAME_EN": "Alqarishat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19553",
    "CITYNAME_AR": "الرواضي (منفوحه)",
    "CITYNAME_EN": "Alrawady (Mnfuhh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19554",
    "CITYNAME_AR": "ملح",
    "CITYNAME_EN": "Milh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19555",
    "CITYNAME_AR": "الملتوي",
    "CITYNAME_EN": "Almultawi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19556",
    "CITYNAME_AR": "الدار",
    "CITYNAME_EN": "Aldaar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19557",
    "CITYNAME_AR": "السواويد",
    "CITYNAME_EN": "Alsawawid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19558",
    "CITYNAME_AR": "محشكه",
    "CITYNAME_EN": "Muhshakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19559",
    "CITYNAME_AR": "الحرقه ( ذوي مرزوق)",
    "CITYNAME_EN": "Alhurquh ( Dhwyun Marzuq)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19560",
    "CITYNAME_AR": "الزحاريه",
    "CITYNAME_EN": "Alzaharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19561",
    "CITYNAME_AR": "العقده",
    "CITYNAME_EN": "Aleaqduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19562",
    "CITYNAME_AR": "سواميد والخرث",
    "CITYNAME_EN": "Sawamid Walkharth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19563",
    "CITYNAME_AR": "معسكر الشباب الدائم",
    "CITYNAME_EN": "Mueaskar Alshabab Alddayim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19564",
    "CITYNAME_AR": "الخضره",
    "CITYNAME_EN": "Alkhadruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19565",
    "CITYNAME_AR": "العجير",
    "CITYNAME_EN": "Aleajir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19566",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Alburqa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19567",
    "CITYNAME_AR": "أم الوكور",
    "CITYNAME_EN": "'Ama Alwakur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19568",
    "CITYNAME_AR": "وادي الماوين",
    "CITYNAME_EN": "Wadi Almawin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19569",
    "CITYNAME_AR": "الذريه",
    "CITYNAME_EN": "Aldharih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19570",
    "CITYNAME_AR": "خفاشة",
    "CITYNAME_EN": "Khafasha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19571",
    "CITYNAME_AR": "وادي نشم",
    "CITYNAME_EN": "Wadi Nshm",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19572",
    "CITYNAME_AR": "ابو حجاره",
    "CITYNAME_EN": "'Abu Hijarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19573",
    "CITYNAME_AR": "الحساسين",
    "CITYNAME_EN": "Alhasasin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19574",
    "CITYNAME_AR": "وادي عرضة قريش",
    "CITYNAME_EN": "Wadi Eurdat Quraysh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19575",
    "CITYNAME_AR": "القصر وريع القصر",
    "CITYNAME_EN": "Alqasr Warie Alqasr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19576",
    "CITYNAME_AR": "الوهط",
    "CITYNAME_EN": "Alwaht",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19577",
    "CITYNAME_AR": "الوهيط",
    "CITYNAME_EN": "Alwahit",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19578",
    "CITYNAME_AR": "شعب اكنان",
    "CITYNAME_EN": "Shaeb Aknan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19579",
    "CITYNAME_AR": "الروكيه",
    "CITYNAME_EN": "Alruwkiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19580",
    "CITYNAME_AR": "الغنم",
    "CITYNAME_EN": "Alghanam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19581",
    "CITYNAME_AR": "الخبابيه",
    "CITYNAME_EN": "Alkhababih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19582",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Alqawz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19583",
    "CITYNAME_AR": "سعيده",
    "CITYNAME_EN": "Saeidh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19584",
    "CITYNAME_AR": "البواطن",
    "CITYNAME_EN": "Albawatin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19585",
    "CITYNAME_AR": "الشعاب والقفاعيه",
    "CITYNAME_EN": "Alshueab Walqafaeih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19586",
    "CITYNAME_AR": "أل عطاف",
    "CITYNAME_EN": "Al Eataf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19587",
    "CITYNAME_AR": "ام الغزلان",
    "CITYNAME_EN": "Umm Alghazlan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19588",
    "CITYNAME_AR": "سويد",
    "CITYNAME_EN": "Swyd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19589",
    "CITYNAME_AR": "شعب الضباء",
    "CITYNAME_EN": "Shaeb Aldabba'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19590",
    "CITYNAME_AR": "الضحياء",
    "CITYNAME_EN": "Aldahya'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19591",
    "CITYNAME_AR": "عفار",
    "CITYNAME_EN": "Eafaar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19592",
    "CITYNAME_AR": "المحد",
    "CITYNAME_EN": "Almahadu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19593",
    "CITYNAME_AR": "خد الحاج",
    "CITYNAME_EN": "Khad Alhaj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19594",
    "CITYNAME_AR": "أبن رشدان",
    "CITYNAME_EN": "'Aban Rashadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19595",
    "CITYNAME_AR": "المرقبه",
    "CITYNAME_EN": "Almuraqabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19596",
    "CITYNAME_AR": "شخميط",
    "CITYNAME_EN": "Shakhmit",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19597",
    "CITYNAME_AR": "الوهابية",
    "CITYNAME_EN": "Alwahabia",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19598",
    "CITYNAME_AR": "الخبزيه",
    "CITYNAME_EN": "Alkhabziuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19599",
    "CITYNAME_AR": "سلسل",
    "CITYNAME_EN": "Sulasil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19600",
    "CITYNAME_AR": "الباثه",
    "CITYNAME_EN": "Albathih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19601",
    "CITYNAME_AR": "سمنان",
    "CITYNAME_EN": "Samanan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19602",
    "CITYNAME_AR": "مزرعة يوسف عابد",
    "CITYNAME_EN": "Farm of Yusif Eabid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19603",
    "CITYNAME_AR": "الفعور",
    "CITYNAME_EN": "Alfueur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19604",
    "CITYNAME_AR": "العزازمه",
    "CITYNAME_EN": "Aleazazimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19605",
    "CITYNAME_AR": "الفتات",
    "CITYNAME_EN": "Alfatat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19606",
    "CITYNAME_AR": "ذوي رضاء",
    "CITYNAME_EN": "Dhwyu Rada'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19607",
    "CITYNAME_AR": "الحجبه",
    "CITYNAME_EN": "Alhajbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19608",
    "CITYNAME_AR": "الوزير",
    "CITYNAME_EN": "Alwazir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19609",
    "CITYNAME_AR": "صعبان",
    "CITYNAME_EN": "Sueban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19610",
    "CITYNAME_AR": "الشقيق (داغر)",
    "CITYNAME_EN": "Alshaqiq (Daghr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19611",
    "CITYNAME_AR": "الحصنين (الصورين)",
    "CITYNAME_EN": "Alhasanayn (Alsuwrin)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19612",
    "CITYNAME_AR": "سمينين",
    "CITYNAME_EN": "Saminin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19613",
    "CITYNAME_AR": "دار البرامين",
    "CITYNAME_EN": "Dar Albaramin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19614",
    "CITYNAME_AR": "المباركات",
    "CITYNAME_EN": "Almubarakat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19615",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Alsuwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19616",
    "CITYNAME_AR": "ظهى",
    "CITYNAME_EN": "Zahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19617",
    "CITYNAME_AR": "السنانيه",
    "CITYNAME_EN": "Alsananiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19618",
    "CITYNAME_AR": "الدمجا",
    "CITYNAME_EN": "Aldamja",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19619",
    "CITYNAME_AR": "الرخيله",
    "CITYNAME_EN": "Alrakhiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19620",
    "CITYNAME_AR": "الزهرانيه",
    "CITYNAME_EN": "Alzahraniuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19621",
    "CITYNAME_AR": "العدوانيه",
    "CITYNAME_EN": "Aleudwaniuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19622",
    "CITYNAME_AR": "النشيريه",
    "CITYNAME_EN": "Alnashiriuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19623",
    "CITYNAME_AR": "السليمانية",
    "CITYNAME_EN": "Alsulaymania",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19624",
    "CITYNAME_AR": "الجلحاء",
    "CITYNAME_EN": "Aljulaha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19625",
    "CITYNAME_AR": "النباعة",
    "CITYNAME_EN": "Alnabaea",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19626",
    "CITYNAME_AR": "باخت العنابه",
    "CITYNAME_EN": "Bakhat Aleinabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19627",
    "CITYNAME_AR": "الفطحاء",
    "CITYNAME_EN": "Alfataha'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19628",
    "CITYNAME_AR": "السيل الصغير",
    "CITYNAME_EN": "Alsayl Alsaghir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19629",
    "CITYNAME_AR": "مزرعة الدعجاني",
    "CITYNAME_EN": "Farm of Aldaejanii",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19630",
    "CITYNAME_AR": "النغر الاعلى والاسفل",
    "CITYNAME_EN": "Alnaghar Al'Aelaa Walasfil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19631",
    "CITYNAME_AR": "الفريده",
    "CITYNAME_EN": "Alfariduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19632",
    "CITYNAME_AR": "العقيلة",
    "CITYNAME_EN": "Aleaqila",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19633",
    "CITYNAME_AR": "الرميده",
    "CITYNAME_EN": "Alramiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19634",
    "CITYNAME_AR": "القويسم",
    "CITYNAME_EN": "Alquaysum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19635",
    "CITYNAME_AR": "العرفاء",
    "CITYNAME_EN": "Aleurafa'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19636",
    "CITYNAME_AR": "الدهاسيه",
    "CITYNAME_EN": "Aldahasiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19637",
    "CITYNAME_AR": "الشريميه",
    "CITYNAME_EN": "Alsharimiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19638",
    "CITYNAME_AR": "مقبوله",
    "CITYNAME_EN": "Maqbuluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19639",
    "CITYNAME_AR": "أبن كنيدش",
    "CITYNAME_EN": "'Aban Knydsh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19640",
    "CITYNAME_AR": "الواصليه",
    "CITYNAME_EN": "Alwasiliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19641",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Alrahbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19642",
    "CITYNAME_AR": "ام العطف",
    "CITYNAME_EN": "Umm Aleatf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19643",
    "CITYNAME_AR": "سر التيس",
    "CITYNAME_EN": "Siri Altys",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19644",
    "CITYNAME_AR": "أبن خيشوم",
    "CITYNAME_EN": "'Aban Khayshum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19645",
    "CITYNAME_AR": "العقلة الشنابره",
    "CITYNAME_EN": "Aleuqlat Alshanabiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19646",
    "CITYNAME_AR": "شبرا (الصماء)",
    "CITYNAME_EN": "Shubra (Alsama'A)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19647",
    "CITYNAME_AR": "الركيه وام العيدان",
    "CITYNAME_EN": "Alrakyuh Wa'Um Aleaydan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19648",
    "CITYNAME_AR": "الحصين",
    "CITYNAME_EN": "Alhasin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19649",
    "CITYNAME_AR": "الرويدف",
    "CITYNAME_EN": "Alruwyduf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19650",
    "CITYNAME_AR": "شويحط",
    "CITYNAME_EN": "Shuyhit",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19651",
    "CITYNAME_AR": "القهيب",
    "CITYNAME_EN": "Alqahib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19652",
    "CITYNAME_AR": "سليطينه",
    "CITYNAME_EN": "Silaytinah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19653",
    "CITYNAME_AR": "النزهة (ام الشاع)",
    "CITYNAME_EN": "Alnuzha (Ama Alshaae)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19654",
    "CITYNAME_AR": "وادي قران",
    "CITYNAME_EN": "Wadi Quran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19655",
    "CITYNAME_AR": "ذوي ساعيد (الحامديه)",
    "CITYNAME_EN": "Dhwyu Saeid (Alhamidih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19656",
    "CITYNAME_AR": "الصلهوج",
    "CITYNAME_EN": "Alslhuj",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19657",
    "CITYNAME_AR": "وادي اللصب",
    "CITYNAME_EN": "Wadi Allasab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19658",
    "CITYNAME_AR": "أبو سيال",
    "CITYNAME_EN": "'Abu Sial",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19659",
    "CITYNAME_AR": "الحميراء",
    "CITYNAME_EN": "Alhamira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19660",
    "CITYNAME_AR": "ام الاوقب",
    "CITYNAME_EN": "Umm Alawqb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19661",
    "CITYNAME_AR": "الهدا",
    "CITYNAME_EN": "Alhada",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19662",
    "CITYNAME_AR": "النقبة الحمراء",
    "CITYNAME_EN": "Alnaqabat Alhamra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19663",
    "CITYNAME_AR": "ضباعه",
    "CITYNAME_EN": "Dibaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19664",
    "CITYNAME_AR": "راس يعرج وراس الوقبه",
    "CITYNAME_EN": "Ras Yaeruj Waras Alwaqbih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19665",
    "CITYNAME_AR": "الصفا",
    "CITYNAME_EN": "Alsafa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19666",
    "CITYNAME_AR": "الخدايجه",
    "CITYNAME_EN": "Alkhadayijuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19667",
    "CITYNAME_AR": "راس تفتفان",
    "CITYNAME_EN": "Ras Tuftafan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19668",
    "CITYNAME_AR": "قرى وادى الشريف",
    "CITYNAME_EN": "Quraa Wa'Adaa Alsharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19669",
    "CITYNAME_AR": "العبده",
    "CITYNAME_EN": "Aleabduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19670",
    "CITYNAME_AR": "النمور",
    "CITYNAME_EN": "Alnumur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19671",
    "CITYNAME_AR": "سلاسل والمور",
    "CITYNAME_EN": "Salasil Walmur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19672",
    "CITYNAME_AR": "جلال (العبده)",
    "CITYNAME_EN": "Jalal (Aleabdah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19673",
    "CITYNAME_AR": "الحساسنه (الدفيا)",
    "CITYNAME_EN": "Alhisasunuh (Aldafya)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19674",
    "CITYNAME_AR": "شعب الاوقع (الشعب)",
    "CITYNAME_EN": "Shaeb Alawqae (Alshueb)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19675",
    "CITYNAME_AR": "التوته",
    "CITYNAME_EN": "Altawatuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19676",
    "CITYNAME_AR": "الحبقه (الريع)",
    "CITYNAME_EN": "Alhabquh (Alariae)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19677",
    "CITYNAME_AR": "الحدب",
    "CITYNAME_EN": "Alhadab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19678",
    "CITYNAME_AR": "شعبان",
    "CITYNAME_EN": "Shaeban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19679",
    "CITYNAME_AR": "الجوازين",
    "CITYNAME_EN": "Aljawazin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19680",
    "CITYNAME_AR": "الطويرق (الخليصه)",
    "CITYNAME_EN": "Altawayriq (Alkhalisuh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19681",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Almuslimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19682",
    "CITYNAME_AR": "القبسه والمشاهبه",
    "CITYNAME_EN": "Alqabsuh Walmashahibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19683",
    "CITYNAME_AR": "اللوامي",
    "CITYNAME_EN": "Allawamiu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19684",
    "CITYNAME_AR": "جباجب",
    "CITYNAME_EN": "Jabajib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19685",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Albahruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19686",
    "CITYNAME_AR": "المشاييخ",
    "CITYNAME_EN": "Almashayikh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19687",
    "CITYNAME_AR": "الدار البيضاء",
    "CITYNAME_EN": "Aldaar Albayda'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19688",
    "CITYNAME_AR": "قرى وادي الغديرين",
    "CITYNAME_EN": "Quraa Wadi Alghadirin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19689",
    "CITYNAME_AR": "السيل",
    "CITYNAME_EN": "Alsayl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19690",
    "CITYNAME_AR": "الخنفرى",
    "CITYNAME_EN": "Alkhanfraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19691",
    "CITYNAME_AR": "الثوابيه",
    "CITYNAME_EN": "Althawabiah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19692",
    "CITYNAME_AR": "المرخه",
    "CITYNAME_EN": "Almarakhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19693",
    "CITYNAME_AR": "تلعه المطرفى",
    "CITYNAME_EN": "Talieah Almutarafaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19694",
    "CITYNAME_AR": "تحره",
    "CITYNAME_EN": "Taharah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19695",
    "CITYNAME_AR": "وادى طلح",
    "CITYNAME_EN": "Wa'Adaa Talah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19696",
    "CITYNAME_AR": "ابوسيال",
    "CITYNAME_EN": "Abwsial",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19697",
    "CITYNAME_AR": "دف ابوعبيه",
    "CITYNAME_EN": "Dif Abwaeibayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19698",
    "CITYNAME_AR": "الضمير",
    "CITYNAME_EN": "Aldamir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19699",
    "CITYNAME_AR": "ابوطبيقات",
    "CITYNAME_EN": "Abwtbiqat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19700",
    "CITYNAME_AR": "الخويرمه",
    "CITYNAME_EN": "Alkhwyrmh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19701",
    "CITYNAME_AR": "الهجيج",
    "CITYNAME_EN": "Alhajij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19702",
    "CITYNAME_AR": "وادى ركك",
    "CITYNAME_EN": "Wa'Adaa Rakk",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19703",
    "CITYNAME_AR": "الصفراء",
    "CITYNAME_EN": "Alsafara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19704",
    "CITYNAME_AR": "جريوله",
    "CITYNAME_EN": "Jiryuluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19705",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Alfare",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19706",
    "CITYNAME_AR": "شراقبه",
    "CITYNAME_EN": "Sharaqibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19707",
    "CITYNAME_AR": "الحيط",
    "CITYNAME_EN": "Alhit",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19708",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19709",
    "CITYNAME_AR": "الويت",
    "CITYNAME_EN": "Alwayt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19710",
    "CITYNAME_AR": "ال حميد",
    "CITYNAME_EN": "Al Hamid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19711",
    "CITYNAME_AR": "ذبب",
    "CITYNAME_EN": "Dhabab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19712",
    "CITYNAME_AR": "الرحبه",
    "CITYNAME_EN": "Alrahbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19713",
    "CITYNAME_AR": "المضاحى",
    "CITYNAME_EN": "Almadahaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19714",
    "CITYNAME_AR": "السمون",
    "CITYNAME_EN": "Alsumun",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19715",
    "CITYNAME_AR": "البيضاء",
    "CITYNAME_EN": "Albayda'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19716",
    "CITYNAME_AR": "أصعدان",
    "CITYNAME_EN": "'Aseadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19717",
    "CITYNAME_AR": "الاعوص (المحمدية)",
    "CITYNAME_EN": "Alaews (Almuhamadiat)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19718",
    "CITYNAME_AR": "الضيق",
    "CITYNAME_EN": "Aldiyq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19719",
    "CITYNAME_AR": "الشعب الاعلى",
    "CITYNAME_EN": "Alshaeb Al'Aelaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19720",
    "CITYNAME_AR": "الشعب الاسفل",
    "CITYNAME_EN": "Alshaeb Al'Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19721",
    "CITYNAME_AR": "وادى سماح",
    "CITYNAME_EN": "Wa'Adaa Samah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19722",
    "CITYNAME_AR": "سلاحا ( بدر)",
    "CITYNAME_EN": "Silahaan ( Bdr)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19723",
    "CITYNAME_AR": "الاقيلح",
    "CITYNAME_EN": "Alaqylh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19724",
    "CITYNAME_AR": "خميس",
    "CITYNAME_EN": "Khamis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19725",
    "CITYNAME_AR": "خماس",
    "CITYNAME_EN": "Khamas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19726",
    "CITYNAME_AR": "التويلبه",
    "CITYNAME_EN": "Altawylubuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19727",
    "CITYNAME_AR": "القامه",
    "CITYNAME_EN": "Alqamih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19728",
    "CITYNAME_AR": "شرس",
    "CITYNAME_EN": "Shrs",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19729",
    "CITYNAME_AR": "قاوه",
    "CITYNAME_EN": "Qawah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19730",
    "CITYNAME_AR": "مرتان",
    "CITYNAME_EN": "Maratan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19731",
    "CITYNAME_AR": "الحيط الاعلى",
    "CITYNAME_EN": "Alhit Al'Aelaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19732",
    "CITYNAME_AR": "الحيط الاسفل",
    "CITYNAME_EN": "Alhit Al'Asfal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19733",
    "CITYNAME_AR": "المزرق",
    "CITYNAME_EN": "Almazriq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19734",
    "CITYNAME_AR": "الشطي",
    "CITYNAME_EN": "Alshty",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19735",
    "CITYNAME_AR": "المجبه (ال زيد)",
    "CITYNAME_EN": "Almujabuh (Al Zyd)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19736",
    "CITYNAME_AR": "المحرث",
    "CITYNAME_EN": "Almaharith",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19737",
    "CITYNAME_AR": "المخاضه",
    "CITYNAME_EN": "Almakhaduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19738",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Algharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19739",
    "CITYNAME_AR": "البويره",
    "CITYNAME_EN": "Albawayruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19740",
    "CITYNAME_AR": "الصوير",
    "CITYNAME_EN": "Alsawir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19741",
    "CITYNAME_AR": "عميره",
    "CITYNAME_EN": "Eamiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19742",
    "CITYNAME_AR": "سان والريع",
    "CITYNAME_EN": "San Walriye",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19743",
    "CITYNAME_AR": "ماسل",
    "CITYNAME_EN": "Masil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19744",
    "CITYNAME_AR": "امط",
    "CITYNAME_EN": "Amt",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19745",
    "CITYNAME_AR": "المجيزه",
    "CITYNAME_EN": "Almujizuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19746",
    "CITYNAME_AR": "شقراء العليا",
    "CITYNAME_EN": "Shuqara' Aleulya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19747",
    "CITYNAME_AR": "شقراء السفلى",
    "CITYNAME_EN": "Shuqara' Alsuflaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19748",
    "CITYNAME_AR": "السد",
    "CITYNAME_EN": "Alsadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19749",
    "CITYNAME_AR": "وادى الشعبه",
    "CITYNAME_EN": "Wa'Adaa Alshaebuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19750",
    "CITYNAME_AR": "تنقد",
    "CITYNAME_EN": "Tanaqad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19751",
    "CITYNAME_AR": "الشعبات",
    "CITYNAME_EN": "Alshaeabat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19752",
    "CITYNAME_AR": "الحصون",
    "CITYNAME_EN": "Alhusuwn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19753",
    "CITYNAME_AR": "المحوله",
    "CITYNAME_EN": "Almuhawaluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19754",
    "CITYNAME_AR": "وادى ذى غزال",
    "CITYNAME_EN": "Wa'Adaa Dhaa Ghazal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19755",
    "CITYNAME_AR": "الجنبان",
    "CITYNAME_EN": "Aljunban",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19756",
    "CITYNAME_AR": "خالده",
    "CITYNAME_EN": "Khalidah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19757",
    "CITYNAME_AR": "المبعوث",
    "CITYNAME_EN": "Almabeuth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19758",
    "CITYNAME_AR": "المسلمه",
    "CITYNAME_EN": "Almuslimuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19759",
    "CITYNAME_AR": "النقاع",
    "CITYNAME_EN": "Alniqae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19760",
    "CITYNAME_AR": "سربه ال عبيد",
    "CITYNAME_EN": "Saribuh Al Eubayd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19761",
    "CITYNAME_AR": "المنطله(المحوله)",
    "CITYNAME_EN": "Almantiluh (Almhuluh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19762",
    "CITYNAME_AR": "المدر",
    "CITYNAME_EN": "Almadaru",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19763",
    "CITYNAME_AR": "وادى السيل المحرق",
    "CITYNAME_EN": "Wa'Adaa Alsayl Almuharaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19764",
    "CITYNAME_AR": "الرجيله",
    "CITYNAME_EN": "Alrajiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19765",
    "CITYNAME_AR": "الاسراب",
    "CITYNAME_EN": "Alasrab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19766",
    "CITYNAME_AR": "القر بنى عمر",
    "CITYNAME_EN": "Alqru Bunaa Eumar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19767",
    "CITYNAME_AR": "راس مسور",
    "CITYNAME_EN": "Ras Musur",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19768",
    "CITYNAME_AR": "وجان",
    "CITYNAME_EN": "Wajan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19769",
    "CITYNAME_AR": "الشدنه",
    "CITYNAME_EN": "Alshadanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19770",
    "CITYNAME_AR": "بمة",
    "CITYNAME_EN": "Bima",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19771",
    "CITYNAME_AR": "وادى الرمان",
    "CITYNAME_EN": "Wa'Adaa Alramman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19772",
    "CITYNAME_AR": "القران",
    "CITYNAME_EN": "Alquran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19773",
    "CITYNAME_AR": "خشعه بنى حجير",
    "CITYNAME_EN": "Khasheah Bunaa Hajir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19774",
    "CITYNAME_AR": "خشعه الحبيبه",
    "CITYNAME_EN": "Khashaeah Alhabibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19775",
    "CITYNAME_AR": "الرجيله",
    "CITYNAME_EN": "Alrajiluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19776",
    "CITYNAME_AR": "ماق",
    "CITYNAME_EN": "Maq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19777",
    "CITYNAME_AR": "اعلي وادي عرضه",
    "CITYNAME_EN": "Aeli Wadi Eardih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19778",
    "CITYNAME_AR": "أسفل وادي عرضه",
    "CITYNAME_EN": "'Asfal Wadi Eardih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19779",
    "CITYNAME_AR": "القعايد",
    "CITYNAME_EN": "Alqaeayid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19780",
    "CITYNAME_AR": "حرجل",
    "CITYNAME_EN": "Harajl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19781",
    "CITYNAME_AR": "المقلب",
    "CITYNAME_EN": "Almuqalab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19782",
    "CITYNAME_AR": "وادى الوجين",
    "CITYNAME_EN": "Wa'Adaa Alwajin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19783",
    "CITYNAME_AR": "ام عدى",
    "CITYNAME_EN": "Umm Eadaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19784",
    "CITYNAME_AR": "الفرع",
    "CITYNAME_EN": "Alfare",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19785",
    "CITYNAME_AR": "وادي الصنه",
    "CITYNAME_EN": "Wadi Alsanih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19786",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Algharif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19787",
    "CITYNAME_AR": "وادى الشراء",
    "CITYNAME_EN": "Wa'Adaa Alshira'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19788",
    "CITYNAME_AR": "دحضه",
    "CITYNAME_EN": "Dahdah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19789",
    "CITYNAME_AR": "الاسراء",
    "CITYNAME_EN": "Alasra'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19790",
    "CITYNAME_AR": "املال",
    "CITYNAME_EN": "Amlal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19791",
    "CITYNAME_AR": "الحرين",
    "CITYNAME_EN": "Alharin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19792",
    "CITYNAME_AR": "البو",
    "CITYNAME_EN": "Albu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19793",
    "CITYNAME_AR": "الاجبل",
    "CITYNAME_EN": "Alajbil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19794",
    "CITYNAME_AR": "الصدقه",
    "CITYNAME_EN": "Alsidquh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19795",
    "CITYNAME_AR": "الرحيه",
    "CITYNAME_EN": "Alrahiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19796",
    "CITYNAME_AR": "المجازه والخبيب",
    "CITYNAME_EN": "Almajazuh Walkhabib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19797",
    "CITYNAME_AR": "تهم الخلد",
    "CITYNAME_EN": "Tuhim Alkhuld",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19798",
    "CITYNAME_AR": "عشيره",
    "CITYNAME_EN": "Eshyrh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19799",
    "CITYNAME_AR": "اللسنه",
    "CITYNAME_EN": "Allasanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19800",
    "CITYNAME_AR": "الشعريه",
    "CITYNAME_EN": "Alshierih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19801",
    "CITYNAME_AR": "العانه",
    "CITYNAME_EN": "Aleanuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19802",
    "CITYNAME_AR": "المويقعه",
    "CITYNAME_EN": "Almuyqaeuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19803",
    "CITYNAME_AR": "العيينه",
    "CITYNAME_EN": "Aleiinuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19804",
    "CITYNAME_AR": "الكويمه",
    "CITYNAME_EN": "Alkawaymuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19805",
    "CITYNAME_AR": "الودى",
    "CITYNAME_EN": "Alwadaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19806",
    "CITYNAME_AR": "المستقل",
    "CITYNAME_EN": "Almustaqilu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19807",
    "CITYNAME_AR": "مشحذ",
    "CITYNAME_EN": "Mashhadh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19808",
    "CITYNAME_AR": "السديره",
    "CITYNAME_EN": "Alsadiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19809",
    "CITYNAME_AR": "ذوى مفرج",
    "CITYNAME_EN": "Dhuaa Mufrij",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19810",
    "CITYNAME_AR": "البهيم",
    "CITYNAME_EN": "Albahim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19811",
    "CITYNAME_AR": "البسايس",
    "CITYNAME_EN": "Albisays",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19812",
    "CITYNAME_AR": "الحقاوين ام جريف",
    "CITYNAME_EN": "Alhaqawin Umm Jarif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19813",
    "CITYNAME_AR": "بنيدان",
    "CITYNAME_EN": "Binidan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19814",
    "CITYNAME_AR": "الخرايق (الحمادين)",
    "CITYNAME_EN": "Alkharayiq (Alhamadiun)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19815",
    "CITYNAME_AR": "النير",
    "CITYNAME_EN": "Alnayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19816",
    "CITYNAME_AR": "الجمجمه",
    "CITYNAME_EN": "Aljamjamuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19817",
    "CITYNAME_AR": "ابومروه",
    "CITYNAME_EN": "Abwmruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19818",
    "CITYNAME_AR": "ام سدره (البدايع)",
    "CITYNAME_EN": "Umm Sadaruh (Albdaye)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19819",
    "CITYNAME_AR": "الكلاخيه",
    "CITYNAME_EN": "Alkalakhiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19820",
    "CITYNAME_AR": "الخر",
    "CITYNAME_EN": "Alkharu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19821",
    "CITYNAME_AR": "الجرويه",
    "CITYNAME_EN": "Aljuruayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19822",
    "CITYNAME_AR": "المحيجيبه",
    "CITYNAME_EN": "Almuhijibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19823",
    "CITYNAME_AR": "ام سباع",
    "CITYNAME_EN": "Umm Sibae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19824",
    "CITYNAME_AR": "مرشده",
    "CITYNAME_EN": "Marshiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19825",
    "CITYNAME_AR": "الطرف",
    "CITYNAME_EN": "Altaraf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19826",
    "CITYNAME_AR": "ام الجله",
    "CITYNAME_EN": "Umm Aljulah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19827",
    "CITYNAME_AR": "سهيله",
    "CITYNAME_EN": "Sahilh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19828",
    "CITYNAME_AR": "الشراعيين",
    "CITYNAME_EN": "Alshiraeiiyn",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19829",
    "CITYNAME_AR": "شراعان",
    "CITYNAME_EN": "Shiraean",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19830",
    "CITYNAME_AR": "الهوينه",
    "CITYNAME_EN": "Alhuaynuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19831",
    "CITYNAME_AR": "البستان",
    "CITYNAME_EN": "Albusatan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19832",
    "CITYNAME_AR": "البويره",
    "CITYNAME_EN": "Albawayruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19833",
    "CITYNAME_AR": "مرفوض",
    "CITYNAME_EN": "Marfud",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19834",
    "CITYNAME_AR": "السليم",
    "CITYNAME_EN": "Alsalim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19835",
    "CITYNAME_AR": "ام الدبيلات",
    "CITYNAME_EN": "Umm Aldabylat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19836",
    "CITYNAME_AR": "الشظيو",
    "CITYNAME_EN": "Alshizyu",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19837",
    "CITYNAME_AR": "الخريق",
    "CITYNAME_EN": "Alkhariq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19838",
    "CITYNAME_AR": "القظيفه",
    "CITYNAME_EN": "Alqazifuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19839",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Alharjuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19840",
    "CITYNAME_AR": "النخيشيه",
    "CITYNAME_EN": "Alnakhishiuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19841",
    "CITYNAME_AR": "الموحش",
    "CITYNAME_EN": "Almuhish",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19842",
    "CITYNAME_AR": "الاصفر",
    "CITYNAME_EN": "Al'Asfar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19843",
    "CITYNAME_AR": "زبيده",
    "CITYNAME_EN": "Zabiduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19844",
    "CITYNAME_AR": "هباله",
    "CITYNAME_EN": "Hibalah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19845",
    "CITYNAME_AR": "خر الجعده",
    "CITYNAME_EN": "Khara Aljaeduh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19846",
    "CITYNAME_AR": "زعفران",
    "CITYNAME_EN": "Zaeafran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19847",
    "CITYNAME_AR": "الجربه",
    "CITYNAME_EN": "Aljarabuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19848",
    "CITYNAME_AR": "معشوران",
    "CITYNAME_EN": "Maeshuran",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19849",
    "CITYNAME_AR": "الوصاهيبه",
    "CITYNAME_EN": "Alwasahibuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19850",
    "CITYNAME_AR": "الصور",
    "CITYNAME_EN": "Alsuwar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19851",
    "CITYNAME_AR": "الاثيل",
    "CITYNAME_EN": "Alathyl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19852",
    "CITYNAME_AR": "العكيس",
    "CITYNAME_EN": "Aleakis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19853",
    "CITYNAME_AR": "اللحيان",
    "CITYNAME_EN": "Allahyan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19854",
    "CITYNAME_AR": "قهوه العبد",
    "CITYNAME_EN": "Qahuh Aleabd",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19855",
    "CITYNAME_AR": "العايره",
    "CITYNAME_EN": "Aleayiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19856",
    "CITYNAME_AR": "ام العشر",
    "CITYNAME_EN": "Umm Aleashr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19857",
    "CITYNAME_AR": "الشقره",
    "CITYNAME_EN": "Alshaqruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19858",
    "CITYNAME_AR": "اوقح (العوله)",
    "CITYNAME_EN": "Awqah (Alieawlaha)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19859",
    "CITYNAME_AR": "الغار",
    "CITYNAME_EN": "Alghar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19860",
    "CITYNAME_AR": "مضلله",
    "CITYNAME_EN": "Mudaliluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19861",
    "CITYNAME_AR": "الوقبه",
    "CITYNAME_EN": "Alwaqbuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19862",
    "CITYNAME_AR": "الرجيلات",
    "CITYNAME_EN": "Alrajilat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19863",
    "CITYNAME_AR": "مخطط ذوي محمد",
    "CITYNAME_EN": "Mukhatat Dhwy Muhamad",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19864",
    "CITYNAME_AR": "الرانه",
    "CITYNAME_EN": "Alraanih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19865",
    "CITYNAME_AR": "الجيه (ام العصيده)",
    "CITYNAME_EN": "Aljayih (Ami Aleasidh)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19866",
    "CITYNAME_AR": "الجويره",
    "CITYNAME_EN": "Aljawiruh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19867",
    "CITYNAME_AR": "ام النخله",
    "CITYNAME_EN": "Umm Alnakhluh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19868",
    "CITYNAME_AR": "الفيصليه",
    "CITYNAME_EN": "Alfayasaliuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19869",
    "CITYNAME_AR": "هجره ناشى الفرهود (السوارقيه)",
    "CITYNAME_EN": "Hajaruh Nashaa Alfaruhud (Alswarqih)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19870",
    "CITYNAME_AR": "آل مشعان",
    "CITYNAME_EN": "Al Mashean",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19871",
    "CITYNAME_AR": "العزيزيه",
    "CITYNAME_EN": "Aleaziziuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19872",
    "CITYNAME_AR": "شقصان",
    "CITYNAME_EN": "Shaqsan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19873",
    "CITYNAME_AR": "الاديم",
    "CITYNAME_EN": "Aladym",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19874",
    "CITYNAME_AR": "الحليق",
    "CITYNAME_EN": "Alhaliq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19875",
    "CITYNAME_AR": "الحماده (القرافين)",
    "CITYNAME_EN": "Alhimaduh (Alqarafiun)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19876",
    "CITYNAME_AR": "العطيف",
    "CITYNAME_EN": "Aleatif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19877",
    "CITYNAME_AR": "العاند",
    "CITYNAME_EN": "Aleanid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19878",
    "CITYNAME_AR": "كلاخ",
    "CITYNAME_EN": "Kilakh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19879",
    "CITYNAME_AR": "طرق العارض (وجبيل)",
    "CITYNAME_EN": "Turuq Alearid (Whabyl)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19880",
    "CITYNAME_AR": "المتعه",
    "CITYNAME_EN": "Al Mutaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19881",
    "CITYNAME_AR": "عيواص",
    "CITYNAME_EN": "Aywas",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19882",
    "CITYNAME_AR": "العيله",
    "CITYNAME_EN": "Al Aylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19883",
    "CITYNAME_AR": "كتعفر",
    "CITYNAME_EN": "Katafar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19884",
    "CITYNAME_AR": "القلعه",
    "CITYNAME_EN": "Al Qalaah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19885",
    "CITYNAME_AR": "اللصب",
    "CITYNAME_EN": "Al Lasab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19886",
    "CITYNAME_AR": "الواشله",
    "CITYNAME_EN": "Al Washelah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19887",
    "CITYNAME_AR": "عمقان",
    "CITYNAME_EN": "Eimqan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19888",
    "CITYNAME_AR": "ضها",
    "CITYNAME_EN": "Daha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19889",
    "CITYNAME_AR": "وادي تصيل",
    "CITYNAME_EN": "Wadi Tasil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19890",
    "CITYNAME_AR": "الردهه",
    "CITYNAME_EN": "Alraduhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19891",
    "CITYNAME_AR": "الطويل",
    "CITYNAME_EN": "Altawil",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19892",
    "CITYNAME_AR": "المقاطع",
    "CITYNAME_EN": "Almaqatie",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19893",
    "CITYNAME_AR": "وادي راك",
    "CITYNAME_EN": "Wadi Rak",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19894",
    "CITYNAME_AR": "الرضعه",
    "CITYNAME_EN": "Alradeah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19895",
    "CITYNAME_AR": "بئر غمره",
    "CITYNAME_EN": "Bayir Ghamarah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19896",
    "CITYNAME_AR": "المضباع",
    "CITYNAME_EN": "Almidbae",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19897",
    "CITYNAME_AR": "بسطاء",
    "CITYNAME_EN": "Bsta'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19898",
    "CITYNAME_AR": "المدب",
    "CITYNAME_EN": "Al Madab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19913",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Ar-Rabwah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19919",
    "CITYNAME_AR": "الرزيوه",
    "CITYNAME_EN": "Al Razywah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19931",
    "CITYNAME_AR": "اللكه",
    "CITYNAME_EN": "Al Lakaah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19939",
    "CITYNAME_AR": "مخطط الركا",
    "CITYNAME_EN": "Mukhatat Al Raka",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19960",
    "CITYNAME_AR": "الحصاة",
    "CITYNAME_EN": "Al Hesah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19963",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19964",
    "CITYNAME_AR": "الوطاه",
    "CITYNAME_EN": "Al Wat'a",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19965",
    "CITYNAME_AR": "السلميه",
    "CITYNAME_EN": "As-Sulaymya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19966",
    "CITYNAME_AR": "ابوجميده",
    "CITYNAME_EN": "Abo Jumaydah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19967",
    "CITYNAME_AR": "جبار السلم",
    "CITYNAME_EN": "Jabar As-Salam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19968",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Al Qorin",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19969",
    "CITYNAME_AR": "جبار الحجف",
    "CITYNAME_EN": "Jabar Al Hajaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19970",
    "CITYNAME_AR": "غثاه",
    "CITYNAME_EN": "Ghuthah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19971",
    "CITYNAME_AR": "القيعه الشماليه والجنوبيه",
    "CITYNAME_EN": "Al Qayaa Ash-Shamalyah wa Al Janubya",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19972",
    "CITYNAME_AR": "الحنو الشمالى والجنوبى",
    "CITYNAME_EN": "Al Hanu Ash-Shamali wa Al Janubi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19973",
    "CITYNAME_AR": "المفيصل",
    "CITYNAME_EN": "Al Mufaysal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19974",
    "CITYNAME_AR": "المعترضة",
    "CITYNAME_EN": "Al Moataredah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19975",
    "CITYNAME_AR": "الحجف الشرقي",
    "CITYNAME_EN": "Al Hajaf Ash-Sharqi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19976",
    "CITYNAME_AR": "آبار رميص",
    "CITYNAME_EN": "Abar Rames",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19977",
    "CITYNAME_AR": "بئر الروغ",
    "CITYNAME_EN": "Beir Ar-Rawagh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19978",
    "CITYNAME_AR": "الحرمليه",
    "CITYNAME_EN": "Alhrmaliah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19979",
    "CITYNAME_AR": "الرخام",
    "CITYNAME_EN": "Alrakham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19980",
    "CITYNAME_AR": "ابومروه الشمالى",
    "CITYNAME_EN": "Abo Marwah Ash-Shamali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19981",
    "CITYNAME_AR": "الحجيف الشمالى",
    "CITYNAME_EN": "Al Hujaif Ash-Shamali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19982",
    "CITYNAME_AR": "الشضو",
    "CITYNAME_EN": "Ash-Shadou",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19983",
    "CITYNAME_AR": "حثاق",
    "CITYNAME_EN": "Huthaq",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19984",
    "CITYNAME_AR": "الناصريه",
    "CITYNAME_EN": "An-Nasiryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19985",
    "CITYNAME_AR": "ذرقان ( كتيفان)",
    "CITYNAME_EN": "Zarqan (Kutayfan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19986",
    "CITYNAME_AR": "ابومروة الجنوبي",
    "CITYNAME_EN": "Abo Marwa Al Janubi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19987",
    "CITYNAME_AR": "الجحيف الجنوبي",
    "CITYNAME_EN": "Al Hujaif Al Janubi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19988",
    "CITYNAME_AR": "السديريه",
    "CITYNAME_EN": "As-Sudayriah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19989",
    "CITYNAME_AR": "الدويحى",
    "CITYNAME_EN": "Ad-Duwaihi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19990",
    "CITYNAME_AR": "المسبار",
    "CITYNAME_EN": "Al Mesbar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19991",
    "CITYNAME_AR": "الغريف",
    "CITYNAME_EN": "Al Ghoreif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19992",
    "CITYNAME_AR": "الجراديات",
    "CITYNAME_EN": "Al Jaradyat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19993",
    "CITYNAME_AR": "الاريقط",
    "CITYNAME_EN": "Al Orayqet",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19994",
    "CITYNAME_AR": "المسهر",
    "CITYNAME_EN": "Al Musher",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19995",
    "CITYNAME_AR": "الابرق وهميجان",
    "CITYNAME_EN": "Al Abraq wa Humayjan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19996",
    "CITYNAME_AR": "البحره",
    "CITYNAME_EN": "Al Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19997",
    "CITYNAME_AR": "الشرفيه",
    "CITYNAME_EN": "Ash-Sharfyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19998",
    "CITYNAME_AR": "اللويات",
    "CITYNAME_EN": "Al Lubat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19999",
    "CITYNAME_AR": "ام الرمث",
    "CITYNAME_EN": "Om Ar-Ramth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20000",
    "CITYNAME_AR": "الدبيله",
    "CITYNAME_EN": "Ad-Dubaylah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20001",
    "CITYNAME_AR": "الذريبات",
    "CITYNAME_EN": "Az-Zuraybat",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20002",
    "CITYNAME_AR": "بيضاء نثيل",
    "CITYNAME_EN": "Bayda Natheel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20003",
    "CITYNAME_AR": "الزرب",
    "CITYNAME_EN": "Az-Zarb",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20004",
    "CITYNAME_AR": "البريكة",
    "CITYNAME_EN": "Al Buraika",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20005",
    "CITYNAME_AR": "الخرابة",
    "CITYNAME_EN": "Al Kharaba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20006",
    "CITYNAME_AR": "ظليم",
    "CITYNAME_EN": "Dhalim",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20007",
    "CITYNAME_AR": "الجوهريه (الخبراء)",
    "CITYNAME_EN": "Al Jawhriyah (Al Khubaraa)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20008",
    "CITYNAME_AR": "ورشه",
    "CITYNAME_EN": "Warsha",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20009",
    "CITYNAME_AR": "الطويله ( فرثان)",
    "CITYNAME_EN": "At-Tuwailah (Farthan)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20010",
    "CITYNAME_AR": "الدارالبيضاء",
    "CITYNAME_EN": "Ad-Dar Al Bayda'a",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20011",
    "CITYNAME_AR": "مبداء النعام",
    "CITYNAME_EN": "Mabdaa An-Naam",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20012",
    "CITYNAME_AR": "حدى",
    "CITYNAME_EN": "Hadi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20013",
    "CITYNAME_AR": "العويله",
    "CITYNAME_EN": "Al Uwaila",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20014",
    "CITYNAME_AR": "هجره القصير",
    "CITYNAME_EN": "Hijrah Al Qusayr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20015",
    "CITYNAME_AR": "هجرة المغراء",
    "CITYNAME_EN": "Hijrah Al Mughraa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20016",
    "CITYNAME_AR": "الحداء",
    "CITYNAME_EN": "Al Hidaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20017",
    "CITYNAME_AR": "ام الفروغ",
    "CITYNAME_EN": "Om Al Furogh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20018",
    "CITYNAME_AR": "سلمان",
    "CITYNAME_EN": "Salman",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20019",
    "CITYNAME_AR": "الحجره",
    "CITYNAME_EN": "Al Hujrah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20020",
    "CITYNAME_AR": "الحجف",
    "CITYNAME_EN": "Al Hajaf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20021",
    "CITYNAME_AR": "الجمده",
    "CITYNAME_EN": "Al Jumadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20022",
    "CITYNAME_AR": "جميده (الحجيره)",
    "CITYNAME_EN": "Gumaydah (Al Hujayrah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20023",
    "CITYNAME_AR": "الفجانه",
    "CITYNAME_EN": "Al Fajanah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20024",
    "CITYNAME_AR": "الغافه",
    "CITYNAME_EN": "Al-Ghafa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20025",
    "CITYNAME_AR": "هجرة غيب",
    "CITYNAME_EN": "Hijrah Ghaib",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20026",
    "CITYNAME_AR": "الرواشد",
    "CITYNAME_EN": "Ar-Rawashid",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20027",
    "CITYNAME_AR": "المخضار",
    "CITYNAME_EN": "Al Mekhdar",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20028",
    "CITYNAME_AR": "القضه",
    "CITYNAME_EN": "Al Quddah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20029",
    "CITYNAME_AR": "سحبل",
    "CITYNAME_EN": "Sahbal",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20030",
    "CITYNAME_AR": "فحيذه",
    "CITYNAME_EN": "Fuhaythah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20031",
    "CITYNAME_AR": "ام الفروس",
    "CITYNAME_EN": "Om Al Farrous",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20032",
    "CITYNAME_AR": "هجره ابو فريح",
    "CITYNAME_EN": "Hijrah Abo Fareih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20033",
    "CITYNAME_AR": "ابو مليح",
    "CITYNAME_EN": "Abo Malih",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20034",
    "CITYNAME_AR": "سنايم",
    "CITYNAME_EN": "Sanaym",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20035",
    "CITYNAME_AR": "الحيفه",
    "CITYNAME_EN": "Al Hayfah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20036",
    "CITYNAME_AR": "الحلوة",
    "CITYNAME_EN": "Al Halwah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20037",
    "CITYNAME_AR": "خدان",
    "CITYNAME_EN": "Khadan",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20038",
    "CITYNAME_AR": "رنيه",
    "CITYNAME_EN": "Ranyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20039",
    "CITYNAME_AR": "الحويا",
    "CITYNAME_EN": "Al Hawba",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20040",
    "CITYNAME_AR": "خشم سلي",
    "CITYNAME_EN": "Khasham Sali",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20041",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Ar-Rubadah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20042",
    "CITYNAME_AR": "العمائر",
    "CITYNAME_EN": "Al Amaer",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20043",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Al Quz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20044",
    "CITYNAME_AR": "الفرعه",
    "CITYNAME_EN": "Al Fara'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20045",
    "CITYNAME_AR": "الرجع",
    "CITYNAME_EN": "Al Rajei",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20046",
    "CITYNAME_AR": "الحفائر",
    "CITYNAME_EN": "Al Hafaeir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20047",
    "CITYNAME_AR": "الشريانيه",
    "CITYNAME_EN": "Ash-Shuryanyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20048",
    "CITYNAME_AR": "الحرف",
    "CITYNAME_EN": "Alharf",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20049",
    "CITYNAME_AR": "مقابل",
    "CITYNAME_EN": "Moqabel",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20050",
    "CITYNAME_AR": "الضرم",
    "CITYNAME_EN": "Ad-Daram",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20051",
    "CITYNAME_AR": "سمير",
    "CITYNAME_EN": "Samir",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20052",
    "CITYNAME_AR": "العثيثى",
    "CITYNAME_EN": "Al Othaithi",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20053",
    "CITYNAME_AR": "الطوال (الطويله)",
    "CITYNAME_EN": "At-Tuwal (At-Tawelah)",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20054",
    "CITYNAME_AR": "الهمجه",
    "CITYNAME_EN": "Al Hamajah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20055",
    "CITYNAME_AR": "هميجان مطرف",
    "CITYNAME_EN": "Mumayjan Metref",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20056",
    "CITYNAME_AR": "بئر بن سيف",
    "CITYNAME_EN": "Beir bin Seif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20057",
    "CITYNAME_AR": "السمريه",
    "CITYNAME_EN": "As-Samryah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20058",
    "CITYNAME_AR": "الاملح",
    "CITYNAME_EN": "Al Amlah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20059",
    "CITYNAME_AR": "هجره الانصب",
    "CITYNAME_EN": "Hijrah Al Ansab",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20060",
    "CITYNAME_AR": "النغر",
    "CITYNAME_EN": "An-Naghr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20061",
    "CITYNAME_AR": "الجرثميه",
    "CITYNAME_EN": "Al Garthamyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20062",
    "CITYNAME_AR": "الصدر",
    "CITYNAME_EN": "As-Sadr",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20063",
    "CITYNAME_AR": "الدغميه",
    "CITYNAME_EN": "Ad-Daghmyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20064",
    "CITYNAME_AR": "اللوى",
    "CITYNAME_EN": "Al Lawa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20065",
    "CITYNAME_AR": "الخطماء",
    "CITYNAME_EN": "Al Khutamaa",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20066",
    "CITYNAME_AR": "شقراء",
    "CITYNAME_EN": "Shaqra",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20075",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As Sirr",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20106",
    "CITYNAME_AR": "ساجر",
    "CITYNAME_EN": "Sajer",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20156",
    "CITYNAME_AR": "نفى",
    "CITYNAME_EN": "Nafa",
    "REGION_ID": "1"
   }, 
   {
    "CITY_ID": "20210",
    "CITYNAME_AR": "الدوادمى",
    "CITYNAME_EN": "Dawadmi",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20264",
    "CITYNAME_AR": "مرات",
    "CITYNAME_EN": "Marrat",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20276",
    "CITYNAME_AR": "لبخه",
    "CITYNAME_EN": "Labakhah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20279",
    "CITYNAME_AR": "حويته",
    "CITYNAME_EN": "Hawayatah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20280",
    "CITYNAME_AR": "ثادق",
    "CITYNAME_EN": "Thadiq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20297",
    "CITYNAME_AR": "حريملاء",
    "CITYNAME_EN": "Harimla'",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20308",
    "CITYNAME_AR": "جلاجل",
    "CITYNAME_EN": "Jalajil",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20309",
    "CITYNAME_AR": "روضه سدير",
    "CITYNAME_EN": "Rawdat Sudair",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20317",
    "CITYNAME_AR": "الرميثى",
    "CITYNAME_EN": "Alramithaa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20320",
    "CITYNAME_AR": "بدايع العضيان",
    "CITYNAME_EN": "Badayie Aleidyan",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20334",
    "CITYNAME_AR": "الاشعريه",
    "CITYNAME_EN": "Alasheirih",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20339",
    "CITYNAME_AR": "المعلق",
    "CITYNAME_EN": "Almaealaq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20340",
    "CITYNAME_AR": "فرحة السرحي ",
    "CITYNAME_EN": "Frẖh As Srẖy",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "20341",
    "CITYNAME_AR": "الحمادة ",
    "CITYNAME_EN": "Al H̱mādh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "20342",
    "CITYNAME_AR": "المرقاب ",
    "CITYNAME_EN": "Al Mrqāb",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "20343",
    "CITYNAME_AR": "الحمدانية",
    "CITYNAME_EN": "NULL",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "20384",
    "CITYNAME_AR": "محامة الجنازه (طلال)",
    "CITYNAME_EN": "Muhamat Aljinazih (Tlal)",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "20450",
    "CITYNAME_AR": "مجمعات وادي دعيكان السكنية",
    "CITYNAME_EN": "Wadi Duaykan residential compounds",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20451",
    "CITYNAME_AR": "عفيف",
    "CITYNAME_EN": "Eafif",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20496",
    "CITYNAME_AR": "عروى",
    "CITYNAME_EN": "Orwa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20505",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Al Ruwaydah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20620",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khasrah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20633",
    "CITYNAME_AR": "حلبان",
    "CITYNAME_EN": "Halpan",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20659",
    "CITYNAME_AR": "البجاديه",
    "CITYNAME_EN": "Al Bijadyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20740",
    "CITYNAME_AR": "قرية منيفة",
    "CITYNAME_EN": "Qaryat Muniafa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20743",
    "CITYNAME_AR": "وادي الدواسر",
    "CITYNAME_EN": "Wadi Ad-Dawasir",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20806",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Al-Quway'iyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20883",
    "CITYNAME_AR": "الرين",
    "CITYNAME_EN": "Al Reen",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21072",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Aljuluh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21116",
    "CITYNAME_AR": "الحريق",
    "CITYNAME_EN": "Alhariq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21135",
    "CITYNAME_AR": "الهدار",
    "CITYNAME_EN": "Al-Haddar",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21143",
    "CITYNAME_AR": "الاحمر",
    "CITYNAME_EN": "Al-Ahmar",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21151",
    "CITYNAME_AR": "المزاحميه",
    "CITYNAME_EN": "Almuzahimih",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21182",
    "CITYNAME_AR": "ضرما",
    "CITYNAME_EN": "Darma",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21194",
    "CITYNAME_AR": "الجبيله",
    "CITYNAME_EN": "Al Jubaylah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21200",
    "CITYNAME_AR": "الدرعيه",
    "CITYNAME_EN": "Diriyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21207",
    "CITYNAME_AR": "الخرج",
    "CITYNAME_EN": "Al Kharj",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21232",
    "CITYNAME_AR": "الدلم",
    "CITYNAME_EN": "Al Delim",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21248",
    "CITYNAME_AR": "الافلاج",
    "CITYNAME_EN": "Al Aflaj",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21274",
    "CITYNAME_AR": "حوطه بنى تميم",
    "CITYNAME_EN": "Hotat Bani Tamim",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21296",
    "CITYNAME_AR": "الهياثم",
    "CITYNAME_EN": "Al Hayathem",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21298",
    "CITYNAME_AR": "الغاط",
    "CITYNAME_EN": "Alghat",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21312",
    "CITYNAME_AR": "اشيقر",
    "CITYNAME_EN": "Ashqeer",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21315",
    "CITYNAME_AR": "الحيانيه",
    "CITYNAME_EN": "Al Hyanyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21319",
    "CITYNAME_AR": "النهتيه الجديده",
    "CITYNAME_EN": "Alnuhtayuh Aljadiduh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21320",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21321",
    "CITYNAME_AR": "مصده",
    "CITYNAME_EN": "Musiddah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21348",
    "CITYNAME_AR": "الزلفى",
    "CITYNAME_EN": "Al-Zulfi",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21418",
    "CITYNAME_AR": "قليب بن عفير",
    "CITYNAME_EN": "Qlyb Bin Eafir",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "21419",
    "CITYNAME_AR": "هجر جوف بنى هاجر",
    "CITYNAME_EN": "Hijr Jawf Bani Hajer",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "21420",
    "CITYNAME_AR": "حداء",
    "CITYNAME_EN": "Hada'",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "21421",
    "CITYNAME_AR": "الشعيبين",
    "CITYNAME_EN": "Al Shuaybeen",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "21422",
    "CITYNAME_AR": "العطشان",
    "CITYNAME_EN": "Al Atshan",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "21424",
    "CITYNAME_AR": "العرضية الجنوبية",
    "CITYNAME_EN": "Al Ardhiyah Al Janubiyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "21425",
    "CITYNAME_AR": "وادي جازان",
    "CITYNAME_EN": "Wadi Jazan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "21426",
    "CITYNAME_AR": "أبانات",
    "CITYNAME_EN": "ABANAT",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "21427",
    "CITYNAME_AR": "الحليفه",
    "CITYNAME_EN": "Alhalifuh",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "21429",
    "CITYNAME_AR": "الحرث",
    "CITYNAME_EN": "Al Harth",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "21430",
    "CITYNAME_AR": "الشعف",
    "CITYNAME_EN": "Al Shaaf",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "21431",
    "CITYNAME_AR": "القرى",
    "CITYNAME_EN": "AL QARA",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "21432",
    "CITYNAME_AR": "مدينة سلطان",
    "CITYNAME_EN": "Sultan City",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "21434",
    "CITYNAME_AR": "الثنية و تبالة",
    "CITYNAME_EN": "Aalthaniyat and Tabala",
    "REGION_ID": "6"
   }
  ];

export const DISTRICTS = [
   {
    "DISTRICT_ID": "1",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3",
    "DISTRICTNAME_AR": "رامات",
    "DISTRICTNAME_EN": "Ramat",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5",
    "DISTRICTNAME_AR": "العبيلة",
    "DISTRICTNAME_EN": "Al Ubaylah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "6",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "7",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "8",
    "DISTRICTNAME_AR": "القبعية",
    "DISTRICTNAME_EN": "Al Qabaeia",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "9",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "10",
    "DISTRICTNAME_AR": "أم تلعة",
    "DISTRICTNAME_EN": "Umm Talea",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "11",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "12",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "13",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "14",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "15",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "16",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "17",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "18",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "19",
    "DISTRICTNAME_AR": "الدحلة",
    "DISTRICTNAME_EN": "Al Dihla",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "20",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "21",
    "DISTRICTNAME_AR": "الأصفر",
    "DISTRICTNAME_EN": "Al Asfar",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "22",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "23",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "24",
    "DISTRICTNAME_AR": "الرواد",
    "DISTRICTNAME_EN": "Ruwad",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "25",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "26",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "27",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "28",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "29",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "30",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "31",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "32",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "33",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "34",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "35",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "36",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "37",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "38",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "39",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "40",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "41",
    "DISTRICTNAME_AR": "البيضاء",
    "DISTRICTNAME_EN": "Al Baida",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "42",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "43",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "44",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "45",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "46",
    "DISTRICTNAME_AR": "الصحة",
    "DISTRICTNAME_EN": "As Siha",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "47",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "48",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "49",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "50",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "51",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "52",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "53",
    "DISTRICTNAME_AR": "أوثال",
    "DISTRICTNAME_EN": "Awthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "54",
    "DISTRICTNAME_AR": "شرق أوثال",
    "DISTRICTNAME_EN": "East Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "55",
    "DISTRICTNAME_AR": "غرب أوثال",
    "DISTRICTNAME_EN": "West Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "56",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "57",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "58",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "59",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "60",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "61",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "62",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "63",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "64",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "65",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "66",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "67",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "68",
    "DISTRICTNAME_AR": "مركز المطيوي",
    "DISTRICTNAME_EN": "Al Mutiwi Center",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "69",
    "DISTRICTNAME_AR": "قرية الرمثية",
    "DISTRICTNAME_EN": "Qaryat Al Rumthiya",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "70",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "71",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "72",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "73",
    "DISTRICTNAME_AR": "غطي",
    "DISTRICTNAME_EN": "Ghatiy",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "74",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "75",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "76",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "77",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "78",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "79",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "80",
    "DISTRICTNAME_AR": "العقيلة الشمالية",
    "DISTRICTNAME_EN": "North Al Uqaila",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "81",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "82",
    "DISTRICTNAME_AR": "الدعبوسية",
    "DISTRICTNAME_EN": "Al Daebusia",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "83",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "84",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "85",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "86",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "87",
    "DISTRICTNAME_AR": "حصيدة",
    "DISTRICTNAME_EN": "Husaydah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "88",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "89",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "90",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "91",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "92",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "93",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "94",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "95",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "96",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "97",
    "DISTRICTNAME_AR": "الأمير عبدالاله بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince AbdulIlah bin Abdulaziz",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "98",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "99",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "100",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "101",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "102",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "103",
    "DISTRICTNAME_AR": "الرسالة",
    "DISTRICTNAME_EN": "Ar Risalah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "104",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "105",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "106",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "107",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "108",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "109",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "110",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "111",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "112",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "113",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "114",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "115",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "116",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "117",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "118",
    "DISTRICTNAME_AR": "العبدلية",
    "DISTRICTNAME_EN": "Al Abdaliah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "119",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "120",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "121",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "122",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "123",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "124",
    "DISTRICTNAME_AR": "غاف الجواء",
    "DISTRICTNAME_EN": "Ghaf Al Jiwaa",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "125",
    "DISTRICTNAME_AR": "روض الجواء",
    "DISTRICTNAME_EN": "Rawd Al Jawa'",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "126",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "127",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "128",
    "DISTRICTNAME_AR": "الصناعي",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "129",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "130",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "131",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "132",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "133",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "134",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "135",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "136",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "137",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "138",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "139",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "140",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "141",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "142",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "143",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "144",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "145",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "146",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "147",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "148",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "149",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "150",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "151",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "152",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "153",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "154",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "155",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "156",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "157",
    "DISTRICTNAME_AR": "حدرج",
    "DISTRICTNAME_EN": "Hadraj",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "158",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "159",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "160",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "161",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "162",
    "DISTRICTNAME_AR": "التحكيم",
    "DISTRICTNAME_EN": "At Tahkim",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "163",
    "DISTRICTNAME_AR": "غرب دومة الجندل",
    "DISTRICTNAME_EN": "West Dawmat Al Jandal",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "164",
    "DISTRICTNAME_AR": "الغربي",
    "DISTRICTNAME_EN": "Al Gharbi",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "165",
    "DISTRICTNAME_AR": "الوادي والبحيرات",
    "DISTRICTNAME_EN": "Valley and Lakes",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "166",
    "DISTRICTNAME_AR": "الصفاة",
    "DISTRICTNAME_EN": "Al Safat",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "167",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "168",
    "DISTRICTNAME_AR": "خذماء",
    "DISTRICTNAME_EN": "Khazma",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "169",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "170",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "171",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "172",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "173",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "174",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "175",
    "DISTRICTNAME_AR": "أصفان",
    "DISTRICTNAME_EN": "Asfan",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "176",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "177",
    "DISTRICTNAME_AR": "الرديفة والرافعية",
    "DISTRICTNAME_EN": "Ar Radifah and Ar Rafeiyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "178",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "179",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "Al Ghurub",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "180",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "181",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "182",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "183",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "184",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "185",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "186",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "187",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "188",
    "DISTRICTNAME_AR": "الطوير",
    "DISTRICTNAME_EN": "At Tuwair",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "189",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "190",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "191",
    "DISTRICTNAME_AR": "الشعيب",
    "DISTRICTNAME_EN": "Ash Shuaib",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "192",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "193",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "194",
    "DISTRICTNAME_AR": "المطر",
    "DISTRICTNAME_EN": "Al Matr",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "195",
    "DISTRICTNAME_AR": "الرواد",
    "DISTRICTNAME_EN": "Ruwad",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "196",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "197",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "198",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "199",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "200",
    "DISTRICTNAME_AR": "الشلهوب",
    "DISTRICTNAME_EN": "Ash Shalhub",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "201",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "202",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "203",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "204",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "205",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "206",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "207",
    "DISTRICTNAME_AR": "السنبلة",
    "DISTRICTNAME_EN": "As Sunbulah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "208",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "209",
    "DISTRICTNAME_AR": "الظلال",
    "DISTRICTNAME_EN": "Adh Dhilal",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "210",
    "DISTRICTNAME_AR": "اليمام",
    "DISTRICTNAME_EN": "Al Yamam",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "211",
    "DISTRICTNAME_AR": "أجياد",
    "DISTRICTNAME_EN": "Ajyad",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "212",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "213",
    "DISTRICTNAME_AR": "الضلع",
    "DISTRICTNAME_EN": "Al Dila",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "214",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "215",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "216",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "217",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "218",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "219",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "220",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "221",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "222",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "223",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "224",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "225",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "226",
    "DISTRICTNAME_AR": "غرناطة الشرقي",
    "DISTRICTNAME_EN": "Eastern Ghirnatah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "227",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "228",
    "DISTRICTNAME_AR": "الشروق الاداري",
    "DISTRICTNAME_EN": "Ash Shrouk Administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "229",
    "DISTRICTNAME_AR": "النخيل الشمالي",
    "DISTRICTNAME_EN": "Al Nakhil Northern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "230",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "231",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "232",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "233",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "234",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "235",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "236",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "237",
    "DISTRICTNAME_AR": "الارجوان الغربي",
    "DISTRICTNAME_EN": "Al Arjiwan Al Gharbi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "238",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "239",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "240",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "241",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "242",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "243",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "244",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "245",
    "DISTRICTNAME_AR": "الاصداف",
    "DISTRICTNAME_EN": "Al Asdaf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "246",
    "DISTRICTNAME_AR": "الشامل",
    "DISTRICTNAME_EN": "Al Shaamil",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "247",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "248",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "249",
    "DISTRICTNAME_AR": "تلال عرعر",
    "DISTRICTNAME_EN": "Talal Arar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "250",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "251",
    "DISTRICTNAME_AR": "القدس الشرقي",
    "DISTRICTNAME_EN": "Eastern Al Quds",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "252",
    "DISTRICTNAME_AR": "النخيل الجنوبي",
    "DISTRICTNAME_EN": "Al Nakhil Southern",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "253",
    "DISTRICTNAME_AR": "البساتين الاداري الشرقي",
    "DISTRICTNAME_EN": "Al Basatin Eastern administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "254",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "255",
    "DISTRICTNAME_AR": "إشبيلية الشمالي",
    "DISTRICTNAME_EN": "Ishbiliyah Northern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "256",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "257",
    "DISTRICTNAME_AR": "البراعم",
    "DISTRICTNAME_EN": "Al Baraeim",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "258",
    "DISTRICTNAME_AR": "معيلة",
    "DISTRICTNAME_EN": "Maeila",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "259",
    "DISTRICTNAME_AR": "هجرة ابن سعيد",
    "DISTRICTNAME_EN": "Hijrat Ibn Saeid",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "260",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "second industrial",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "261",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "262",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "263",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "264",
    "DISTRICTNAME_AR": "المروج الغربي",
    "DISTRICTNAME_EN": "Al Muruj Al Gharbi",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "265",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "266",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "267",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "268",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "269",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "270",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "271",
    "DISTRICTNAME_AR": "بدنة",
    "DISTRICTNAME_EN": "Al Badanah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "272",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "273",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "274",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "275",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "276",
    "DISTRICTNAME_AR": "الصقار",
    "DISTRICTNAME_EN": "As Sqaar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "277",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "278",
    "DISTRICTNAME_AR": "تشليح السيارات",
    "DISTRICTNAME_EN": "Auto Repair",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "279",
    "DISTRICTNAME_AR": "البساتين الاداري الغربي",
    "DISTRICTNAME_EN": "Al Basatin Western administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "280",
    "DISTRICTNAME_AR": "المباركية",
    "DISTRICTNAME_EN": "Al Mubarkiah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "281",
    "DISTRICTNAME_AR": "الشذا",
    "DISTRICTNAME_EN": "Al Shadha",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "282",
    "DISTRICTNAME_AR": "المروج الشرقي",
    "DISTRICTNAME_EN": "Al Muruj Al Sharqi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "283",
    "DISTRICTNAME_AR": "القدس الغربي",
    "DISTRICTNAME_EN": "Western Al Quds",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "284",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "285",
    "DISTRICTNAME_AR": "الأمانة الاداري",
    "DISTRICTNAME_EN": "Administrative Secretariat",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "286",
    "DISTRICTNAME_AR": "محجوزات ارمكو",
    "DISTRICTNAME_EN": "Aramco Reservations",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "287",
    "DISTRICTNAME_AR": "التقنية",
    "DISTRICTNAME_EN": "Al Tiqniya",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "288",
    "DISTRICTNAME_AR": "ام الضيان",
    "DISTRICTNAME_EN": "Umm Al Dayan",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "289",
    "DISTRICTNAME_AR": "مدينة الانعام",
    "DISTRICTNAME_EN": "Madinat Al Aineam",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "290",
    "DISTRICTNAME_AR": "الخليج العربي الشرقي",
    "DISTRICTNAME_EN": "Eastern Arabian Gulf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "291",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "292",
    "DISTRICTNAME_AR": "المنصورية",
    "DISTRICTNAME_EN": "Mansuriyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "293",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "294",
    "DISTRICTNAME_AR": "الموطا",
    "DISTRICTNAME_EN": "Al Mawta",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "295",
    "DISTRICTNAME_AR": "إشبيلية الجنوبي",
    "DISTRICTNAME_EN": "Ishbiliyah Southern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "296",
    "DISTRICTNAME_AR": "صوفان",
    "DISTRICTNAME_EN": "Sufan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "297",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "298",
    "DISTRICTNAME_AR": "الدحلة",
    "DISTRICTNAME_EN": "Al Dihla",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "299",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "300",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "301",
    "DISTRICTNAME_AR": "المدراء",
    "DISTRICTNAME_EN": "Al Mudaraa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "302",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "303",
    "DISTRICTNAME_AR": "الحيفة",
    "DISTRICTNAME_EN": "Al Hifaa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "304",
    "DISTRICTNAME_AR": "الخالدية والعزيزية",
    "DISTRICTNAME_EN": "Al Khalidiyah and Aziziyah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "305",
    "DISTRICTNAME_AR": "الباردة",
    "DISTRICTNAME_EN": "Al Barida",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "306",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "307",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "308",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "309",
    "DISTRICTNAME_AR": "الجنوب",
    "DISTRICTNAME_EN": "Al Janub",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "310",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "311",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "312",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "313",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "314",
    "DISTRICTNAME_AR": "الخليج العربي الغربي",
    "DISTRICTNAME_EN": "Western Arabian Gulf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "315",
    "DISTRICTNAME_AR": "غرناطة الغربي",
    "DISTRICTNAME_EN": "Western Ghirnatah",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "316",
    "DISTRICTNAME_AR": "الارجوان الشرقي",
    "DISTRICTNAME_EN": "Al Arjiwan Al sharqi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "317",
    "DISTRICTNAME_AR": "الحميمة و أبو خيال",
    "DISTRICTNAME_EN": "Al Hamimat and Abu Khayal",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "318",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "319",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "320",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "321",
    "DISTRICTNAME_AR": "جميع",
    "DISTRICTNAME_EN": "Jamie",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "322",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "323",
    "DISTRICTNAME_AR": "الشداخة",
    "DISTRICTNAME_EN": "Al Shadaakha",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "324",
    "DISTRICTNAME_AR": "الحرف",
    "DISTRICTNAME_EN": "Alharaf",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "325",
    "DISTRICTNAME_AR": "قرية المهدي",
    "DISTRICTNAME_EN": "Qaryat Al Mahdi",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "326",
    "DISTRICTNAME_AR": "قنيع و النغيلة",
    "DISTRICTNAME_EN": "Qanie and Al Naghila",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "327",
    "DISTRICTNAME_AR": "الحزامي و النغيلة",
    "DISTRICTNAME_EN": "Al Hizami and  Al Naghila",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "328",
    "DISTRICTNAME_AR": "نمران",
    "DISTRICTNAME_EN": "Namaran",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "329",
    "DISTRICTNAME_AR": "الأمير عبدالله",
    "DISTRICTNAME_EN": "Prince Abdullah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "330",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "331",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "332",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "333",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "334",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "335",
    "DISTRICTNAME_AR": "الهيشة",
    "DISTRICTNAME_EN": "Al Hisha",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "336",
    "DISTRICTNAME_AR": "الصبيحي",
    "DISTRICTNAME_EN": "Al Subayhi",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "337",
    "DISTRICTNAME_AR": "الحمة",
    "DISTRICTNAME_EN": "Al Hima",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "338",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "339",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "340",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "341",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "342",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "343",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "344",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "345",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "346",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "347",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "348",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "349",
    "DISTRICTNAME_AR": "السلطان",
    "DISTRICTNAME_EN": "Al Sultan",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "350",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "351",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "352",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "353",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "354",
    "DISTRICTNAME_AR": "شظاة",
    "DISTRICTNAME_EN": "Shizat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "355",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "356",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "357",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "358",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "359",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "360",
    "DISTRICTNAME_AR": "المصايف",
    "DISTRICTNAME_EN": "Al Masayif",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "361",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "362",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "363",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "364",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "365",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "366",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "367",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "368",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "369",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "370",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "371",
    "DISTRICTNAME_AR": "الرحاب 2",
    "DISTRICTNAME_EN": "Ar Rehab 2",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "372",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "373",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "374",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "375",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "376",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "377",
    "DISTRICTNAME_AR": "الصفح",
    "DISTRICTNAME_EN": "Al Safh",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "378",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "379",
    "DISTRICTNAME_AR": "الشرق",
    "DISTRICTNAME_EN": "Ash Sharq",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "380",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "381",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "382",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "383",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "384",
    "DISTRICTNAME_AR": "الزهر",
    "DISTRICTNAME_EN": "Al Zuhr",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "385",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "386",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "387",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "388",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "389",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "390",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "391",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "392",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "393",
    "DISTRICTNAME_AR": "السالمية",
    "DISTRICTNAME_EN": "As Salmiya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "394",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "395",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "396",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "397",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "398",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "399",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "400",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "401",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "402",
    "DISTRICTNAME_AR": "بلال",
    "DISTRICTNAME_EN": "Bilal",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "403",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "404",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "405",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "406",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "407",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "408",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "409",
    "DISTRICTNAME_AR": "المطل",
    "DISTRICTNAME_EN": "Al Matl",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "410",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "411",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "412",
    "DISTRICTNAME_AR": "المنتزة الشمالي",
    "DISTRICTNAME_EN": "Al Muntazah Al Shamali",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "413",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "414",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "415",
    "DISTRICTNAME_AR": "مبروكة",
    "DISTRICTNAME_EN": "Mabrukah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "416",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "417",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "418",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "419",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "420",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "421",
    "DISTRICTNAME_AR": "خب الجطيلي",
    "DISTRICTNAME_EN": "Khub Al Jutaily",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "422",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "423",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "424",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "425",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "426",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "427",
    "DISTRICTNAME_AR": "المنتزة الجنوبي",
    "DISTRICTNAME_EN": "Al Muntazah Al Janubi",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "428",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "429",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "430",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "431",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "432",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "433",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "434",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "435",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "436",
    "DISTRICTNAME_AR": "الظهرة",
    "DISTRICTNAME_EN": "Adh Dhahrah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "437",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "438",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "439",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "440",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "441",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "442",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "443",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "444",
    "DISTRICTNAME_AR": "الضاحي الشرقي",
    "DISTRICTNAME_EN": "Ad Dahi Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "445",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "446",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "447",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "448",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "449",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "450",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "451",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "452",
    "DISTRICTNAME_AR": "الضرس",
    "DISTRICTNAME_EN": "Ad Dirs",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "453",
    "DISTRICTNAME_AR": "المقاعد",
    "DISTRICTNAME_EN": "Al Maqaiid",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "454",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "455",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "456",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "457",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "458",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "459",
    "DISTRICTNAME_AR": "الوعد",
    "DISTRICTNAME_EN": "Al Waad",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "460",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "461",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "462",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "463",
    "DISTRICTNAME_AR": "الحيلة الغربي",
    "DISTRICTNAME_EN": "Al Haylah Al Gharbi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "464",
    "DISTRICTNAME_AR": "الحيلة الشرقي",
    "DISTRICTNAME_EN": "Al Haylah Ash Sharqi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "465",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "466",
    "DISTRICTNAME_AR": "البصرة",
    "DISTRICTNAME_EN": "Al Basrah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "467",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "468",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "469",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "470",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "471",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "472",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "473",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "474",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "475",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "476",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "477",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "478",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "479",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "480",
    "DISTRICTNAME_AR": "الفرعين 2",
    "DISTRICTNAME_EN": "Al Fareayn 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "481",
    "DISTRICTNAME_AR": "الفرعين 1",
    "DISTRICTNAME_EN": "Al Fareayn 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "482",
    "DISTRICTNAME_AR": "الفرعين 4",
    "DISTRICTNAME_EN": "Al Fareayn 4",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "483",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "484",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "485",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "486",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "487",
    "DISTRICTNAME_AR": "مرحبين",
    "DISTRICTNAME_EN": "Marhabin",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "488",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "489",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "490",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "491",
    "DISTRICTNAME_AR": "المعارف",
    "DISTRICTNAME_EN": "Al Maarif",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "492",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "493",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "494",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "495",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "496",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "497",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "498",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "499",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "500",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "501",
    "DISTRICTNAME_AR": "ال صمان",
    "DISTRICTNAME_EN": "Al Samaan",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "502",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "503",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "504",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "505",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "506",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "507",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "508",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "509",
    "DISTRICTNAME_AR": "الفرعين 3",
    "DISTRICTNAME_EN": "Al Fareayn 3",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "510",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "511",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "512",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "513",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "514",
    "DISTRICTNAME_AR": "الغزالة",
    "DISTRICTNAME_EN": "Al Ghazalah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "515",
    "DISTRICTNAME_AR": "الزرقاء",
    "DISTRICTNAME_EN": "Al Zarqa",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "516",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "517",
    "DISTRICTNAME_AR": "ال سويدان",
    "DISTRICTNAME_EN": "Al Suwaidan",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "518",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "519",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "520",
    "DISTRICTNAME_AR": "الدائري الجنوبي",
    "DISTRICTNAME_EN": "South Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "521",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "522",
    "DISTRICTNAME_AR": "الدائري الشمالي",
    "DISTRICTNAME_EN": "North Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "523",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "524",
    "DISTRICTNAME_AR": "الدائري الغربي",
    "DISTRICTNAME_EN": "Western Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "525",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "526",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "527",
    "DISTRICTNAME_AR": "النهود",
    "DISTRICTNAME_EN": "An Nahud",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "528",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "529",
    "DISTRICTNAME_AR": "العدالة",
    "DISTRICTNAME_EN": "Al Adalah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "530",
    "DISTRICTNAME_AR": "خضيراء الجنوبية",
    "DISTRICTNAME_EN": "Khadira Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "531",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "532",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "533",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "534",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "535",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "537",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "538",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "539",
    "DISTRICTNAME_AR": "العرين",
    "DISTRICTNAME_EN": "Al Arin",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "540",
    "DISTRICTNAME_AR": "الرحيب",
    "DISTRICTNAME_EN": "Ar Raheeb",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "541",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "542",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "543",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "544",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "545",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "546",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "547",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "548",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "549",
    "DISTRICTNAME_AR": "المنهل",
    "DISTRICTNAME_EN": "Al Manhal",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "550",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "551",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "552",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "553",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "554",
    "DISTRICTNAME_AR": "السمر",
    "DISTRICTNAME_EN": "As Samar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "555",
    "DISTRICTNAME_AR": "القويع",
    "DISTRICTNAME_EN": "Al Quwaie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "556",
    "DISTRICTNAME_AR": "الوسيع",
    "DISTRICTNAME_EN": "Al Wasie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "557",
    "DISTRICTNAME_AR": "المتينيات",
    "DISTRICTNAME_EN": "Al Mateeniyat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "558",
    "DISTRICTNAME_AR": "الهدية الجنوبية",
    "DISTRICTNAME_EN": "Al Hadyah Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "559",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "560",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "561",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "562",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "563",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "564",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "565",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "566",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "567",
    "DISTRICTNAME_AR": "الجراد",
    "DISTRICTNAME_EN": "Al Jarad",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "568",
    "DISTRICTNAME_AR": "وهطان",
    "DISTRICTNAME_EN": "Wahtan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "569",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "570",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "571",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "572",
    "DISTRICTNAME_AR": "خب البريدي",
    "DISTRICTNAME_EN": "Khub Al Buraydi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "573",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "574",
    "DISTRICTNAME_AR": "الملحة",
    "DISTRICTNAME_EN": "Al Muliha",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "575",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "576",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "577",
    "DISTRICTNAME_AR": "الخزام",
    "DISTRICTNAME_EN": "Al Khuzam",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "578",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "579",
    "DISTRICTNAME_AR": "المسك",
    "DISTRICTNAME_EN": "Al Misk",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "580",
    "DISTRICTNAME_AR": "الفل",
    "DISTRICTNAME_EN": "Al Fal",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "581",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "582",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "583",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "584",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "585",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "586",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "587",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "588",
    "DISTRICTNAME_AR": "السدر",
    "DISTRICTNAME_EN": "Al Sudr",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "589",
    "DISTRICTNAME_AR": "القطائف",
    "DISTRICTNAME_EN": "Al Qatayif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "590",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "591",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "592",
    "DISTRICTNAME_AR": "المقضي",
    "DISTRICTNAME_EN": "Al Maqdi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "593",
    "DISTRICTNAME_AR": "تمنية",
    "DISTRICTNAME_EN": "Tamnia",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "594",
    "DISTRICTNAME_AR": "الموسم",
    "DISTRICTNAME_EN": "Al Mawsim",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "595",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "596",
    "DISTRICTNAME_AR": "دلغان",
    "DISTRICTNAME_EN": "Delgan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "597",
    "DISTRICTNAME_AR": "الرمان",
    "DISTRICTNAME_EN": "Al Rumaan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "598",
    "DISTRICTNAME_AR": "الفرعاء",
    "DISTRICTNAME_EN": "Al Furaea",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "599",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "600",
    "DISTRICTNAME_AR": "الرياحين",
    "DISTRICTNAME_EN": "Al Riyahayn",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "601",
    "DISTRICTNAME_AR": "تاتة",
    "DISTRICTNAME_EN": "Tata",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "602",
    "DISTRICTNAME_AR": "المهاريس",
    "DISTRICTNAME_EN": "Al Maharees",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "603",
    "DISTRICTNAME_AR": "القويطير",
    "DISTRICTNAME_EN": "Al Quwayteer",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "604",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "605",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "606",
    "DISTRICTNAME_AR": "الهدية الشمالية",
    "DISTRICTNAME_EN": "Al Hadyah Al Shamaliya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "607",
    "DISTRICTNAME_AR": "التوفيق",
    "DISTRICTNAME_EN": "Al Tawfiq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "608",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "609",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "610",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "611",
    "DISTRICTNAME_AR": "النعمان",
    "DISTRICTNAME_EN": "Al Nueman",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "612",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "613",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "614",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "615",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "616",
    "DISTRICTNAME_AR": "الباحر",
    "DISTRICTNAME_EN": "Al Bahir",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "617",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "618",
    "DISTRICTNAME_AR": "النجوم",
    "DISTRICTNAME_EN": "Al Nujum",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "619",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "621",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "622",
    "DISTRICTNAME_AR": "الأراك 1",
    "DISTRICTNAME_EN": "Al Arak 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "623",
    "DISTRICTNAME_AR": "السلام 3",
    "DISTRICTNAME_EN": "As Salam 3",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "624",
    "DISTRICTNAME_AR": "السلام 1",
    "DISTRICTNAME_EN": "As Salam 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "625",
    "DISTRICTNAME_AR": "الحزم 2",
    "DISTRICTNAME_EN": "Al Hazm 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "626",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "627",
    "DISTRICTNAME_AR": "الخضراء 3",
    "DISTRICTNAME_EN": "Al Khadraa 3",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "628",
    "DISTRICTNAME_AR": "السلام 2",
    "DISTRICTNAME_EN": "As Salam 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "629",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "630",
    "DISTRICTNAME_AR": "الجامع",
    "DISTRICTNAME_EN": "Al Jama",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "631",
    "DISTRICTNAME_AR": "الجنوبي",
    "DISTRICTNAME_EN": "Al Janubi",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "632",
    "DISTRICTNAME_AR": "إسكان الملك عبدالله لولديه",
    "DISTRICTNAME_EN": "King Abdullah housing for his sons",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "633",
    "DISTRICTNAME_AR": "الخالدية الجنوبية",
    "DISTRICTNAME_EN": "Al Khalidiyah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "634",
    "DISTRICTNAME_AR": "رايس",
    "DISTRICTNAME_EN": "Rise",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "635",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "636",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "637",
    "DISTRICTNAME_AR": "الفرشة",
    "DISTRICTNAME_EN": "Al Firsha",
    "CITY_ID": "13",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "638",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "639",
    "DISTRICTNAME_AR": "العنود",
    "DISTRICTNAME_EN": "Al Anud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "640",
    "DISTRICTNAME_AR": "الوردتين",
    "DISTRICTNAME_EN": "Al Wardatain",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "641",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "642",
    "DISTRICTNAME_AR": "منطقة الخدمات أ",
    "DISTRICTNAME_EN": "Services Area A",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "643",
    "DISTRICTNAME_AR": "الأنعام",
    "DISTRICTNAME_EN": "Al Aneam",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "644",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "645",
    "DISTRICTNAME_AR": "واحة الشمال",
    "DISTRICTNAME_EN": "North Oasis",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "646",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "647",
    "DISTRICTNAME_AR": "القدس 2",
    "DISTRICTNAME_EN": "Al Quds 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "648",
    "DISTRICTNAME_AR": "الحزم 2",
    "DISTRICTNAME_EN": "Al Hazm 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "649",
    "DISTRICTNAME_AR": "الأندلس 1",
    "DISTRICTNAME_EN": "Al Andalus 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "650",
    "DISTRICTNAME_AR": "الأندلس 4",
    "DISTRICTNAME_EN": "Al Andalus 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "651",
    "DISTRICTNAME_AR": "الأندلس 2",
    "DISTRICTNAME_EN": "Al Andalus 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "652",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "653",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "654",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "655",
    "DISTRICTNAME_AR": "الأخضر",
    "DISTRICTNAME_EN": "Al Akhdar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "656",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "657",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "658",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "659",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "660",
    "DISTRICTNAME_AR": "الخبيب",
    "DISTRICTNAME_EN": "Al Khabib",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "661",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "662",
    "DISTRICTNAME_AR": "النزهة 2",
    "DISTRICTNAME_EN": "An Nuzhah 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "663",
    "DISTRICTNAME_AR": "النزهة 1",
    "DISTRICTNAME_EN": "An Nuzhah 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "664",
    "DISTRICTNAME_AR": "الزهود",
    "DISTRICTNAME_EN": "Al Zuhud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "665",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "666",
    "DISTRICTNAME_AR": "السلام الغربي",
    "DISTRICTNAME_EN": "Al Salam Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "667",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "668",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "669",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "670",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "671",
    "DISTRICTNAME_AR": "الهجن",
    "DISTRICTNAME_EN": "Al Hejin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "672",
    "DISTRICTNAME_AR": "حفيرة السبعة الأوسط",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "673",
    "DISTRICTNAME_AR": "حفائر العهين",
    "DISTRICTNAME_EN": "Hafayir Al Eahin",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "674",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "675",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "676",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "677",
    "DISTRICTNAME_AR": "شمسان",
    "DISTRICTNAME_EN": "Shamsan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "678",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "679",
    "DISTRICTNAME_AR": "الوصايف",
    "DISTRICTNAME_EN": "Al Wasaif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "680",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "681",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "682",
    "DISTRICTNAME_AR": "الثريا 2",
    "DISTRICTNAME_EN": "Ath Thuraiya 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "683",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "684",
    "DISTRICTNAME_AR": "العقدة",
    "DISTRICTNAME_EN": "Al Uqdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "685",
    "DISTRICTNAME_AR": "مزهرة",
    "DISTRICTNAME_EN": "Mizhirah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "686",
    "DISTRICTNAME_AR": "العجيبية",
    "DISTRICTNAME_EN": "Al Ujaybiyah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "687",
    "DISTRICTNAME_AR": "الأساملة",
    "DISTRICTNAME_EN": "Al Asamila",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "688",
    "DISTRICTNAME_AR": "حفيرة السبعة الغربي",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "689",
    "DISTRICTNAME_AR": "الحفيرة الشرقي",
    "DISTRICTNAME_EN": "Al Hufayrah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "692",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "693",
    "DISTRICTNAME_AR": "صفوان",
    "DISTRICTNAME_EN": "Safwan",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "694",
    "DISTRICTNAME_AR": "مسيحل",
    "DISTRICTNAME_EN": "Musihil",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "695",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "696",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "697",
    "DISTRICTNAME_AR": "الفيصلية الجنوبية",
    "DISTRICTNAME_EN": "Al Faisaliyah South",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "698",
    "DISTRICTNAME_AR": "الفيصلية الشمالية",
    "DISTRICTNAME_EN": "Al Faisaliyah North",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "699",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "Al Safra",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "700",
    "DISTRICTNAME_AR": "شعار",
    "DISTRICTNAME_EN": "Shiear",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "701",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "702",
    "DISTRICTNAME_AR": "الهلال",
    "DISTRICTNAME_EN": "Al Hilal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "703",
    "DISTRICTNAME_AR": "الليوان",
    "DISTRICTNAME_EN": "Al Liwan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "704",
    "DISTRICTNAME_AR": "السادة",
    "DISTRICTNAME_EN": "Al Sadah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "705",
    "DISTRICTNAME_AR": "التجارة",
    "DISTRICTNAME_EN": "Trading",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "706",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "707",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "708",
    "DISTRICTNAME_AR": "الحسينى",
    "DISTRICTNAME_EN": "Al Husaini",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "709",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "710",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "711",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "712",
    "DISTRICTNAME_AR": "الجمالة",
    "DISTRICTNAME_EN": "Al Jammalah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "713",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "714",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "715",
    "DISTRICTNAME_AR": "الأندلس 5",
    "DISTRICTNAME_EN": "Al Andalus 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "716",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "717",
    "DISTRICTNAME_AR": "الحزم 1",
    "DISTRICTNAME_EN": "Al Hazm 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "718",
    "DISTRICTNAME_AR": "القدس 1",
    "DISTRICTNAME_EN": "Al Quds 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "719",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "720",
    "DISTRICTNAME_AR": "منطقة خاصة",
    "DISTRICTNAME_EN": "Private Area",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "721",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "722",
    "DISTRICTNAME_AR": "منطقة الخدمات ب",
    "DISTRICTNAME_EN": "Services Area B",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "723",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "724",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "725",
    "DISTRICTNAME_AR": "العلية",
    "DISTRICTNAME_EN": "Al Oalia",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "726",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "727",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "728",
    "DISTRICTNAME_AR": "القاع البارد",
    "DISTRICTNAME_EN": "Al Qaa Al Barid",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "729",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "730",
    "DISTRICTNAME_AR": "الاصيل",
    "DISTRICTNAME_EN": "Al Asil",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "731",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "732",
    "DISTRICTNAME_AR": "الاطلال",
    "DISTRICTNAME_EN": "Al Atlal",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "733",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "734",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "735",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "736",
    "DISTRICTNAME_AR": "السويس 1",
    "DISTRICTNAME_EN": "Al Suways 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "737",
    "DISTRICTNAME_AR": "الرحاب 1",
    "DISTRICTNAME_EN": "Ar Rehab 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "738",
    "DISTRICTNAME_AR": "السويس 2",
    "DISTRICTNAME_EN": "Al Suways 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "739",
    "DISTRICTNAME_AR": "الرحاب 2",
    "DISTRICTNAME_EN": "Ar Rehab 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "740",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "741",
    "DISTRICTNAME_AR": "المروج 1",
    "DISTRICTNAME_EN": "Al Muruj 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "742",
    "DISTRICTNAME_AR": "القادسية الثانى",
    "DISTRICTNAME_EN": "Al Qadisiyah 2",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "743",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "744",
    "DISTRICTNAME_AR": "المملكة",
    "DISTRICTNAME_EN": "The Kingdom",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "745",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "746",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "747",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "748",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "749",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "750",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "751",
    "DISTRICTNAME_AR": "الكواكب",
    "DISTRICTNAME_EN": "Al Kawakib",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "752",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "753",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "754",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "755",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "756",
    "DISTRICTNAME_AR": "النصب",
    "DISTRICTNAME_EN": "An Nasb",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "757",
    "DISTRICTNAME_AR": "أبو سهيلات",
    "DISTRICTNAME_EN": "Abu Suhailat",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "758",
    "DISTRICTNAME_AR": "الغزوة",
    "DISTRICTNAME_EN": "Al Ghazwah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "759",
    "DISTRICTNAME_AR": "الفقهاء",
    "DISTRICTNAME_EN": "Al Fuqahaa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "760",
    "DISTRICTNAME_AR": "العشوة",
    "DISTRICTNAME_EN": "Al Ushwa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "761",
    "DISTRICTNAME_AR": "الأندلس 6",
    "DISTRICTNAME_EN": "Al Andalus 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "762",
    "DISTRICTNAME_AR": "الأندلس 3",
    "DISTRICTNAME_EN": "Al Andalus 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "763",
    "DISTRICTNAME_AR": "المحمدية 1",
    "DISTRICTNAME_EN": "Al Muhammadiyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "764",
    "DISTRICTNAME_AR": "الزرقاء",
    "DISTRICTNAME_EN": "Al Zarqa",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "765",
    "DISTRICTNAME_AR": "الإستراحات الأوسط",
    "DISTRICTNAME_EN": "Al Iistirahat Al Awst",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "766",
    "DISTRICTNAME_AR": "الإستراحات الشرقي",
    "DISTRICTNAME_EN": "Al Iistirahat Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "767",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "768",
    "DISTRICTNAME_AR": "الإستراحات الغربي",
    "DISTRICTNAME_EN": "Al Iistirahat Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "769",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "770",
    "DISTRICTNAME_AR": "الربة",
    "DISTRICTNAME_EN": "Al Rabah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "771",
    "DISTRICTNAME_AR": "القدس 5",
    "DISTRICTNAME_EN": "Al Quds 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "772",
    "DISTRICTNAME_AR": "الحزم 4",
    "DISTRICTNAME_EN": "Al Hazm 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "773",
    "DISTRICTNAME_AR": "القدس 6",
    "DISTRICTNAME_EN": "Al Quds 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "774",
    "DISTRICTNAME_AR": "الحزم 3",
    "DISTRICTNAME_EN": "Al Hazm 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "775",
    "DISTRICTNAME_AR": "القدس 3",
    "DISTRICTNAME_EN": "Al Quds 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "776",
    "DISTRICTNAME_AR": "القدس 4",
    "DISTRICTNAME_EN": "Al Quds 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "777",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "778",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "779",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "780",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "781",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "782",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "783",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "784",
    "DISTRICTNAME_AR": "الراجحي",
    "DISTRICTNAME_EN": "Al Rajhi",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "785",
    "DISTRICTNAME_AR": "المنطقة المركزية",
    "DISTRICTNAME_EN": "Central District",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "786",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "787",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "788",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "789",
    "DISTRICTNAME_AR": "العريش",
    "DISTRICTNAME_EN": "Al Arish",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "790",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "791",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "792",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "793",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "794",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "795",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "796",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "797",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "798",
    "DISTRICTNAME_AR": "الظرفة",
    "DISTRICTNAME_EN": "Al Dhurfah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "799",
    "DISTRICTNAME_AR": "الضياء",
    "DISTRICTNAME_EN": "Al Diya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "800",
    "DISTRICTNAME_AR": "المعلاة",
    "DISTRICTNAME_EN": "Al Maealaa",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "801",
    "DISTRICTNAME_AR": "المراغة",
    "DISTRICTNAME_EN": "Al Muragha",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "802",
    "DISTRICTNAME_AR": "جرش الاثري",
    "DISTRICTNAME_EN": "Jarash Alathari",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "803",
    "DISTRICTNAME_AR": "وسط البلد",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "804",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "805",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "806",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "807",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "808",
    "DISTRICTNAME_AR": "البركات",
    "DISTRICTNAME_EN": "Al Barakat",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "809",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "810",
    "DISTRICTNAME_AR": "المريديسية الشمالية",
    "DISTRICTNAME_EN": "Al Muraydeesiyah Al Shamaliyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "811",
    "DISTRICTNAME_AR": "الدرب",
    "DISTRICTNAME_EN": "Al Darb",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "812",
    "DISTRICTNAME_AR": "العرق الجنوبى",
    "DISTRICTNAME_EN": "Southern Al Eirq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "813",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "814",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "815",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "816",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "817",
    "DISTRICTNAME_AR": "باب الساب",
    "DISTRICTNAME_EN": "Bab As Sab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "818",
    "DISTRICTNAME_AR": "ال بريد",
    "DISTRICTNAME_EN": "Al Barid",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "819",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "820",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "821",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "822",
    "DISTRICTNAME_AR": "المروج 4",
    "DISTRICTNAME_EN": "Al Muruj 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "823",
    "DISTRICTNAME_AR": "المروج 2",
    "DISTRICTNAME_EN": "Al Muruj 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "824",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "825",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "826",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "827",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "828",
    "DISTRICTNAME_AR": "بجيلة",
    "DISTRICTNAME_EN": "Bjeila",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "829",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "830",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "831",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "832",
    "DISTRICTNAME_AR": "السودة",
    "DISTRICTNAME_EN": "Al Sawda",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "833",
    "DISTRICTNAME_AR": "الرونق",
    "DISTRICTNAME_EN": "Al Ruwnaq",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "834",
    "DISTRICTNAME_AR": "المنتزة الشرقي",
    "DISTRICTNAME_EN": "Al Muntazah Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "835",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "836",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "837",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "838",
    "DISTRICTNAME_AR": "ضمد",
    "DISTRICTNAME_EN": "Damud",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "839",
    "DISTRICTNAME_AR": "بيروت",
    "DISTRICTNAME_EN": "Beirut",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "840",
    "DISTRICTNAME_AR": "السليل",
    "DISTRICTNAME_EN": "As Sulayyil",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "841",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "842",
    "DISTRICTNAME_AR": "النخيل الشرقي",
    "DISTRICTNAME_EN": "Al Nakhil Eastern",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "843",
    "DISTRICTNAME_AR": "النخيل الغربي",
    "DISTRICTNAME_EN": "Al Nakhil Western",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "844",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "845",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "846",
    "DISTRICTNAME_AR": "المروج 3",
    "DISTRICTNAME_EN": "Al Muruj 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "847",
    "DISTRICTNAME_AR": "القرحاء 1",
    "DISTRICTNAME_EN": "Al Qarha 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "848",
    "DISTRICTNAME_AR": "المضيق",
    "DISTRICTNAME_EN": "Al Madiq",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "849",
    "DISTRICTNAME_AR": "ال فقيع",
    "DISTRICTNAME_EN": "Al Faqie",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "850",
    "DISTRICTNAME_AR": "بني تميم",
    "DISTRICTNAME_EN": "Bani Tamim",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "851",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "852",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "853",
    "DISTRICTNAME_AR": "المدينة الجامعية",
    "DISTRICTNAME_EN": "University City",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "854",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "855",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "856",
    "DISTRICTNAME_AR": "القصيعة",
    "DISTRICTNAME_EN": "Al Qusayah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "857",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "858",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "859",
    "DISTRICTNAME_AR": "الطاهرية",
    "DISTRICTNAME_EN": "Al Tahiriyya",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "860",
    "DISTRICTNAME_AR": "السلوى",
    "DISTRICTNAME_EN": "Al Salwaa",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "861",
    "DISTRICTNAME_AR": "النورس",
    "DISTRICTNAME_EN": "An Nawras",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "862",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "863",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "864",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "865",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "866",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "867",
    "DISTRICTNAME_AR": "الخضراء 1",
    "DISTRICTNAME_EN": "Al Khadraa 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "868",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "869",
    "DISTRICTNAME_AR": "الحزم 1",
    "DISTRICTNAME_EN": "Al Hazm 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "870",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "871",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "872",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "873",
    "DISTRICTNAME_AR": "الجبل 2",
    "DISTRICTNAME_EN": "Al Jabal 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "874",
    "DISTRICTNAME_AR": "الجبل 1",
    "DISTRICTNAME_EN": "Al Jabal 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "875",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "876",
    "DISTRICTNAME_AR": "خضيراء الشمالية",
    "DISTRICTNAME_EN": "Khadira Al Shamaliyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "877",
    "DISTRICTNAME_AR": "رواق الغربي",
    "DISTRICTNAME_EN": "Rawaq Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "878",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "879",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "880",
    "DISTRICTNAME_AR": "القادسية الاول",
    "DISTRICTNAME_EN": "Al Qadisiyah 1",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "881",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "882",
    "DISTRICTNAME_AR": "الاستاد الرياضى",
    "DISTRICTNAME_EN": "Sports Stadium",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "883",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "884",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "885",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "886",
    "DISTRICTNAME_AR": "الصباخ",
    "DISTRICTNAME_EN": "Al Sabbakh",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "887",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "888",
    "DISTRICTNAME_AR": "الصوامع",
    "DISTRICTNAME_EN": "Al Sawamie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "889",
    "DISTRICTNAME_AR": "البساتين الغربي",
    "DISTRICTNAME_EN": "Al Basatin Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "890",
    "DISTRICTNAME_AR": "المحمدية 3",
    "DISTRICTNAME_EN": "Al Muhammadiyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "891",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "892",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "893",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "894",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "895",
    "DISTRICTNAME_AR": "النورس 2",
    "DISTRICTNAME_EN": "An Nawras 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "896",
    "DISTRICTNAME_AR": "الأراك 2",
    "DISTRICTNAME_EN": "Al Arak 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "897",
    "DISTRICTNAME_AR": "النورس 1",
    "DISTRICTNAME_EN": "An Nawras 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "898",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "899",
    "DISTRICTNAME_AR": "الخضراء 2",
    "DISTRICTNAME_EN": "Al Khadraa 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "900",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "901",
    "DISTRICTNAME_AR": "دمج",
    "DISTRICTNAME_EN": "Dumj",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "902",
    "DISTRICTNAME_AR": "شمال الجامعة",
    "DISTRICTNAME_EN": "North University",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "903",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "904",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "905",
    "DISTRICTNAME_AR": "الجامعة الشرقي",
    "DISTRICTNAME_EN": "Al Jamiah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "906",
    "DISTRICTNAME_AR": "الجامعة الأوسط",
    "DISTRICTNAME_EN": "Al Jamiah Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "907",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "908",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "909",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "910",
    "DISTRICTNAME_AR": "المنهل",
    "DISTRICTNAME_EN": "Al Manhal",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "911",
    "DISTRICTNAME_AR": "النازية",
    "DISTRICTNAME_EN": "Al Naziyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "912",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "913",
    "DISTRICTNAME_AR": "القمر",
    "DISTRICTNAME_EN": "Al Qamar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "914",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "915",
    "DISTRICTNAME_AR": "القمراء",
    "DISTRICTNAME_EN": "Al Qamara",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "916",
    "DISTRICTNAME_AR": "الحفيرة الأوسط",
    "DISTRICTNAME_EN": "Al Hufayrah Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "917",
    "DISTRICTNAME_AR": "الجامعة الغربي",
    "DISTRICTNAME_EN": "Al Jamiah Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "918",
    "DISTRICTNAME_AR": "خضيرة",
    "DISTRICTNAME_EN": "Khudayra",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "919",
    "DISTRICTNAME_AR": "نجران",
    "DISTRICTNAME_EN": "Najran",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "920",
    "DISTRICTNAME_AR": "الباطنة",
    "DISTRICTNAME_EN": "Al Batinah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "921",
    "DISTRICTNAME_AR": "الظبية",
    "DISTRICTNAME_EN": "Al Dhabyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "922",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "923",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "924",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "925",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "926",
    "DISTRICTNAME_AR": "الحفيرة الغربي",
    "DISTRICTNAME_EN": "Al Hufayrah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "927",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "928",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "929",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "930",
    "DISTRICTNAME_AR": "السمراء",
    "DISTRICTNAME_EN": "As Samra",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "931",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "932",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "933",
    "DISTRICTNAME_AR": "الشورى",
    "DISTRICTNAME_EN": "Ash Shura",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "934",
    "DISTRICTNAME_AR": "الرصف",
    "DISTRICTNAME_EN": "Ar Rasf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "935",
    "DISTRICTNAME_AR": "سماح",
    "DISTRICTNAME_EN": "Samah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "936",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "937",
    "DISTRICTNAME_AR": "المزعبر",
    "DISTRICTNAME_EN": "Al Muzaabar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "938",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "939",
    "DISTRICTNAME_AR": "السويفلة",
    "DISTRICTNAME_EN": "As Suwayfilah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "940",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "941",
    "DISTRICTNAME_AR": "القديح",
    "DISTRICTNAME_EN": "Al Qadih",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "942",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "943",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "944",
    "DISTRICTNAME_AR": "الهميلة",
    "DISTRICTNAME_EN": "Al Humila",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "945",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "947",
    "DISTRICTNAME_AR": "الغزود",
    "DISTRICTNAME_EN": "Al Ghazud",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "948",
    "DISTRICTNAME_AR": "الجبهان",
    "DISTRICTNAME_EN": "Al Jabhan",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "949",
    "DISTRICTNAME_AR": "الريبع",
    "DISTRICTNAME_EN": "Al Rabie",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "950",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "952",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "953",
    "DISTRICTNAME_AR": "جبارة",
    "DISTRICTNAME_EN": "Jubarah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "954",
    "DISTRICTNAME_AR": "الراشديات",
    "DISTRICTNAME_EN": "Al Rashidiyyat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "955",
    "DISTRICTNAME_AR": "خب الثنيان",
    "DISTRICTNAME_EN": "Khub Ath Thanyan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "956",
    "DISTRICTNAME_AR": "النقع الشرقي",
    "DISTRICTNAME_EN": "Al Naqa Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "957",
    "DISTRICTNAME_AR": "قمبر",
    "DISTRICTNAME_EN": "Qambar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "958",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "959",
    "DISTRICTNAME_AR": "الخضر",
    "DISTRICTNAME_EN": "Al Khudar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "960",
    "DISTRICTNAME_AR": "المليداء",
    "DISTRICTNAME_EN": "Al Mulayda",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "961",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "962",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "963",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "964",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "965",
    "DISTRICTNAME_AR": "الوشم",
    "DISTRICTNAME_EN": "Al Washm",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "966",
    "DISTRICTNAME_AR": "العمارة",
    "DISTRICTNAME_EN": "Al Amaarah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "967",
    "DISTRICTNAME_AR": "الجميماء",
    "DISTRICTNAME_EN": "Al Jumayma",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "968",
    "DISTRICTNAME_AR": "الملز",
    "DISTRICTNAME_EN": "Al Malaz",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "969",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "970",
    "DISTRICTNAME_AR": "العرين",
    "DISTRICTNAME_EN": "Al Arin",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "971",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "972",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "973",
    "DISTRICTNAME_AR": "المنتزة الغربي",
    "DISTRICTNAME_EN": "Al Muntazah Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "974",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "975",
    "DISTRICTNAME_AR": "النقى",
    "DISTRICTNAME_EN": "An Naqa",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "977",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "978",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "979",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "980",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "981",
    "DISTRICTNAME_AR": "الزهر",
    "DISTRICTNAME_EN": "Al Zuhr",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "982",
    "DISTRICTNAME_AR": "الورد",
    "DISTRICTNAME_EN": "Al Ward",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "984",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "985",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "986",
    "DISTRICTNAME_AR": "القمة",
    "DISTRICTNAME_EN": "Al Qima",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "987",
    "DISTRICTNAME_AR": "الرمانة",
    "DISTRICTNAME_EN": "Ar Rumanah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "988",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "989",
    "DISTRICTNAME_AR": "ذرة",
    "DISTRICTNAME_EN": "Dharah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "990",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "991",
    "DISTRICTNAME_AR": "المملكة",
    "DISTRICTNAME_EN": "The Kingdom",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "992",
    "DISTRICTNAME_AR": "حلة محيش",
    "DISTRICTNAME_EN": "Halat Mahish",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "993",
    "DISTRICTNAME_AR": "الزين",
    "DISTRICTNAME_EN": "Al Zayn",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "994",
    "DISTRICTNAME_AR": "السوسن",
    "DISTRICTNAME_EN": "Al Sawsan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "995",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "996",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "997",
    "DISTRICTNAME_AR": "الشقة",
    "DISTRICTNAME_EN": "Al Shiqah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "998",
    "DISTRICTNAME_AR": "الامارة",
    "DISTRICTNAME_EN": "Al Amarah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "999",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1000",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1001",
    "DISTRICTNAME_AR": "النقيب الجنوبي",
    "DISTRICTNAME_EN": "Al Naqeeb Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1002",
    "DISTRICTNAME_AR": "الكويكب",
    "DISTRICTNAME_EN": "Kuwaikib",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1003",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1004",
    "DISTRICTNAME_AR": "الشفق",
    "DISTRICTNAME_EN": "Al Shafaq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1005",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1006",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1007",
    "DISTRICTNAME_AR": "الحوراء",
    "DISTRICTNAME_EN": "Al Hawra",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1008",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1009",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1010",
    "DISTRICTNAME_AR": "شعبة الشيخ",
    "DISTRICTNAME_EN": "Shubat Al Shaykh",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1011",
    "DISTRICTNAME_AR": "الأمير مشعل أ",
    "DISTRICTNAME_EN": "Prince Mishal A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1012",
    "DISTRICTNAME_AR": "مشار",
    "DISTRICTNAME_EN": "Mashar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1013",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1014",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1015",
    "DISTRICTNAME_AR": "أم الساهك",
    "DISTRICTNAME_EN": "Umm As Sahik",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1016",
    "DISTRICTNAME_AR": "الخصاب",
    "DISTRICTNAME_EN": "Al Khisab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1017",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1018",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1020",
    "DISTRICTNAME_AR": "ذهبان الشرقي",
    "DISTRICTNAME_EN": "Dhahban Al Sharqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1021",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1022",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1023",
    "DISTRICTNAME_AR": "أحواش الأغنام",
    "DISTRICTNAME_EN": "Ahwash Al Aghnam",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1024",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1025",
    "DISTRICTNAME_AR": "المراث",
    "DISTRICTNAME_EN": "Al Mirath",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1026",
    "DISTRICTNAME_AR": "عين حمزة",
    "DISTRICTNAME_EN": "Ayn Hamzah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1027",
    "DISTRICTNAME_AR": "الصبيحية",
    "DISTRICTNAME_EN": "Al Subayhiyyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1028",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1029",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1030",
    "DISTRICTNAME_AR": "العمال",
    "DISTRICTNAME_EN": "Al Ummal",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1031",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1032",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1033",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1034",
    "DISTRICTNAME_AR": "العمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1035",
    "DISTRICTNAME_AR": "أرض وزارة الدفاع",
    "DISTRICTNAME_EN": "Land of the Ministry of Defense",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1036",
    "DISTRICTNAME_AR": "قرية اجا",
    "DISTRICTNAME_EN": "Qaryat Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1038",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1039",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1040",
    "DISTRICTNAME_AR": "شكر",
    "DISTRICTNAME_EN": "Shakar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1041",
    "DISTRICTNAME_AR": "الوطاة",
    "DISTRICTNAME_EN": "Al Watah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1042",
    "DISTRICTNAME_AR": "السلام الشرقي",
    "DISTRICTNAME_EN": "Al Salam Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1043",
    "DISTRICTNAME_AR": "الزمرد1",
    "DISTRICTNAME_EN": "Az Zomorod1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1044",
    "DISTRICTNAME_AR": "الصناعية1",
    "DISTRICTNAME_EN": "Industrial1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1045",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1047",
    "DISTRICTNAME_AR": "خب القبر",
    "DISTRICTNAME_EN": "Khub Al Qabar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1048",
    "DISTRICTNAME_AR": "حي ج14",
    "DISTRICTNAME_EN": "Dst C14",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1049",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1050",
    "DISTRICTNAME_AR": "العلم",
    "DISTRICTNAME_EN": "Al Eilm",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1051",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1053",
    "DISTRICTNAME_AR": "السلطانيه",
    "DISTRICTNAME_EN": "Al Sultanih",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1054",
    "DISTRICTNAME_AR": "المسعودية",
    "DISTRICTNAME_EN": "Al Masudiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1057",
    "DISTRICTNAME_AR": "الامارة",
    "DISTRICTNAME_EN": "Al Amarah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1058",
    "DISTRICTNAME_AR": "الفيصلية أ",
    "DISTRICTNAME_EN": "Al Faisaliyah A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1059",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1060",
    "DISTRICTNAME_AR": "الخالدية أ",
    "DISTRICTNAME_EN": "Al Khalidiyah A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1061",
    "DISTRICTNAME_AR": "الروضة الشمالي",
    "DISTRICTNAME_EN": "Ar Rawdah Al Shamali",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1062",
    "DISTRICTNAME_AR": "الصناعية أ",
    "DISTRICTNAME_EN": "Industrial A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1063",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1064",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1065",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1066",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1067",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1068",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1069",
    "DISTRICTNAME_AR": "شاطىء القطيف ",
    "DISTRICTNAME_EN": "Qatif Beach",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1070",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1074",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1075",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1076",
    "DISTRICTNAME_AR": "المريحل",
    "DISTRICTNAME_EN": "Al Murihil",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1077",
    "DISTRICTNAME_AR": "الحرة",
    "DISTRICTNAME_EN": "Al Hura",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1078",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1080",
    "DISTRICTNAME_AR": "الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan 2",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1082",
    "DISTRICTNAME_AR": "أم الحمام",
    "DISTRICTNAME_EN": "Umm Al Hamam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1083",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1084",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1085",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1086",
    "DISTRICTNAME_AR": "الظهرة",
    "DISTRICTNAME_EN": "Adh Dhahrah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1087",
    "DISTRICTNAME_AR": "غرب الظهرة",
    "DISTRICTNAME_EN": "West Al Dhahra",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1088",
    "DISTRICTNAME_AR": "أم عنم",
    "DISTRICTNAME_EN": "Umm Anam",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1089",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1090",
    "DISTRICTNAME_AR": "الخبراء",
    "DISTRICTNAME_EN": "Al Khubara",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1091",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1092",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1093",
    "DISTRICTNAME_AR": "شمال ارامكو",
    "DISTRICTNAME_EN": "North Aramco",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1094",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1095",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1096",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1098",
    "DISTRICTNAME_AR": "الخامس",
    "DISTRICTNAME_EN": "Al Khamis",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1099",
    "DISTRICTNAME_AR": "الربيعية",
    "DISTRICTNAME_EN": "Ar Rabeiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1100",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1101",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1102",
    "DISTRICTNAME_AR": "الشماسية",
    "DISTRICTNAME_EN": "Ash Shamasiyyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1103",
    "DISTRICTNAME_AR": "الأزهر",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1104",
    "DISTRICTNAME_AR": "القافلة",
    "DISTRICTNAME_EN": "Al Qafilah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1105",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1107",
    "DISTRICTNAME_AR": "المنطقة الصناعية2",
    "DISTRICTNAME_EN": "Industrial Area 2",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1111",
    "DISTRICTNAME_AR": "حي ج21",
    "DISTRICTNAME_EN": "Dst C21",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1112",
    "DISTRICTNAME_AR": "المكيلي",
    "DISTRICTNAME_EN": "Al Mukaili",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1114",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1115",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1116",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1117",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1118",
    "DISTRICTNAME_AR": "الأمير مشعل ب",
    "DISTRICTNAME_EN": "Prince Mishal B",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1119",
    "DISTRICTNAME_AR": "الهرير الشرقى",
    "DISTRICTNAME_EN": "Al Hurayr Al Sharqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1120",
    "DISTRICTNAME_AR": "تارة",
    "DISTRICTNAME_EN": "Tara",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1121",
    "DISTRICTNAME_AR": "الراقي",
    "DISTRICTNAME_EN": "Al Raaqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1122",
    "DISTRICTNAME_AR": "المعزاب",
    "DISTRICTNAME_EN": "Al Mizab",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1123",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1124",
    "DISTRICTNAME_AR": "الصمدة",
    "DISTRICTNAME_EN": "As Samdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1125",
    "DISTRICTNAME_AR": "مصلوم",
    "DISTRICTNAME_EN": "Maslum",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1127",
    "DISTRICTNAME_AR": "ضحكان 2",
    "DISTRICTNAME_EN": "Dahkan 2",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1128",
    "DISTRICTNAME_AR": "الصفر",
    "DISTRICTNAME_EN": "As Safr",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1129",
    "DISTRICTNAME_AR": "السبخة",
    "DISTRICTNAME_EN": "Al Sabakhah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1130",
    "DISTRICTNAME_AR": "الحميضة",
    "DISTRICTNAME_EN": "Al Hamiduh",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1131",
    "DISTRICTNAME_AR": "حي ج17",
    "DISTRICTNAME_EN": "Dst C17",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1133",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1134",
    "DISTRICTNAME_AR": "الرصراص 1",
    "DISTRICTNAME_EN": "Al Rasras 1",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1135",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1136",
    "DISTRICTNAME_AR": "شعب ذهبان",
    "DISTRICTNAME_EN": "Shaeb Dhahaban",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1137",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1138",
    "DISTRICTNAME_AR": "القرص",
    "DISTRICTNAME_EN": "Al Qurs",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1139",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1140",
    "DISTRICTNAME_AR": "حي ج18",
    "DISTRICTNAME_EN": "Dst C18",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1143",
    "DISTRICTNAME_AR": "الغزلانى",
    "DISTRICTNAME_EN": "Al Ghazlani",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1144",
    "DISTRICTNAME_AR": "الدبابية",
    "DISTRICTNAME_EN": "Ad Dababiyyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1145",
    "DISTRICTNAME_AR": "مصنع الاسمنت",
    "DISTRICTNAME_EN": "Cement Factory",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1146",
    "DISTRICTNAME_AR": "محطة الكهرباء",
    "DISTRICTNAME_EN": "Electricity Station",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1147",
    "DISTRICTNAME_AR": "منطقة تحلية المياة",
    "DISTRICTNAME_EN": "Water desalination Area",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1148",
    "DISTRICTNAME_AR": "فوار",
    "DISTRICTNAME_EN": "Fawar",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1149",
    "DISTRICTNAME_AR": "المنح ج",
    "DISTRICTNAME_EN": "Al Minah J",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1150",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1151",
    "DISTRICTNAME_AR": "الصقور",
    "DISTRICTNAME_EN": "As Suqur",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1152",
    "DISTRICTNAME_AR": "النقرة",
    "DISTRICTNAME_EN": "An Naqrah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1153",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1154",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1155",
    "DISTRICTNAME_AR": "الخلود",
    "DISTRICTNAME_EN": "Al Khulud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1156",
    "DISTRICTNAME_AR": "النقيب الشمالي",
    "DISTRICTNAME_EN": "Al Naqeeb Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1157",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1158",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1159",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1160",
    "DISTRICTNAME_AR": "الحمر الشمالي",
    "DISTRICTNAME_EN": "Al Humar Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1161",
    "DISTRICTNAME_AR": "الفريعة",
    "DISTRICTNAME_EN": "Al Farieah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1162",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1163",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1164",
    "DISTRICTNAME_AR": "جنوب الحميضه",
    "DISTRICTNAME_EN": "South Humidah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1165",
    "DISTRICTNAME_AR": "الوصل",
    "DISTRICTNAME_EN": "Al Wasl",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1166",
    "DISTRICTNAME_AR": "الجنوبية",
    "DISTRICTNAME_EN": "Al Janubiyah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1167",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1168",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1169",
    "DISTRICTNAME_AR": "حزم",
    "DISTRICTNAME_EN": "Hazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1170",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1171",
    "DISTRICTNAME_AR": "الرزيقية",
    "DISTRICTNAME_EN": "Al Ruzaiqiah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1172",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1173",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1174",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1175",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1178",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1179",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1180",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1181",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1182",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1183",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1184",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1185",
    "DISTRICTNAME_AR": "المروج 2",
    "DISTRICTNAME_EN": "Al Muruj 2",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1186",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1187",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1188",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1190",
    "DISTRICTNAME_AR": "المجيدية",
    "DISTRICTNAME_EN": "Al Majidiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1191",
    "DISTRICTNAME_AR": "الشويكة",
    "DISTRICTNAME_EN": "Ash Shweikah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1192",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1193",
    "DISTRICTNAME_AR": "حويلان",
    "DISTRICTNAME_EN": "Huwailan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1194",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1195",
    "DISTRICTNAME_AR": "التعليم",
    "DISTRICTNAME_EN": "At Taalim",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1196",
    "DISTRICTNAME_AR": "ابيار الماشي",
    "DISTRICTNAME_EN": "Abyar Al Mashi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1197",
    "DISTRICTNAME_AR": "منطقة الارسال الاذاعي",
    "DISTRICTNAME_EN": "Radio Transmitter",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1198",
    "DISTRICTNAME_AR": "العند",
    "DISTRICTNAME_EN": "Al Eind",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1199",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1200",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1201",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1202",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1205",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1206",
    "DISTRICTNAME_AR": "قرية نقبين",
    "DISTRICTNAME_EN": "Qaryat Naqbin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1207",
    "DISTRICTNAME_AR": "قفار",
    "DISTRICTNAME_EN": "Qafar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1209",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1210",
    "DISTRICTNAME_AR": "شراف",
    "DISTRICTNAME_EN": "Sharaf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1211",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1212",
    "DISTRICTNAME_AR": "عيرف",
    "DISTRICTNAME_EN": "Ayraf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1213",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1214",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1215",
    "DISTRICTNAME_AR": "النعام",
    "DISTRICTNAME_EN": "Al Naeam",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1216",
    "DISTRICTNAME_AR": "صلاح الدين",
    "DISTRICTNAME_EN": "Salahuddin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1217",
    "DISTRICTNAME_AR": "حدري البلاد",
    "DISTRICTNAME_EN": "Hadri Al Bilad",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1218",
    "DISTRICTNAME_AR": "قرية الجثامية",
    "DISTRICTNAME_EN": "Qaryat Al Jithamia",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1219",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1220",
    "DISTRICTNAME_AR": "قريان",
    "DISTRICTNAME_EN": "Quryan",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1223",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1224",
    "DISTRICTNAME_AR": "التل",
    "DISTRICTNAME_EN": "Al Tall",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1225",
    "DISTRICTNAME_AR": "النظير",
    "DISTRICTNAME_EN": "Al Nazir",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1226",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1227",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1228",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1229",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1230",
    "DISTRICTNAME_AR": "الزاوية",
    "DISTRICTNAME_EN": "Al Zaawia",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1231",
    "DISTRICTNAME_AR": "الأمير مشعل",
    "DISTRICTNAME_EN": "Prince Mishal",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1232",
    "DISTRICTNAME_AR": "محطة الكهرباء",
    "DISTRICTNAME_EN": "Electricity Station",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1233",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1234",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1235",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "As Safra",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1236",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1237",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1238",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1239",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1240",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1241",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1242",
    "DISTRICTNAME_AR": "الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1243",
    "DISTRICTNAME_AR": "المعمورة",
    "DISTRICTNAME_EN": "Al Mamurah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1244",
    "DISTRICTNAME_AR": "جنوب تندحة",
    "DISTRICTNAME_EN": "South of the villages Tandiha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1245",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "Al Sharaf",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1246",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1247",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1248",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1249",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1250",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "Al Shuaba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1251",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1252",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1253",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1254",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1257",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1258",
    "DISTRICTNAME_AR": "الروضة الجنوبي",
    "DISTRICTNAME_EN": "Ar Rawdah Al Janubi",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1259",
    "DISTRICTNAME_AR": "المستشفى العسكري",
    "DISTRICTNAME_EN": "Military Hospital",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1260",
    "DISTRICTNAME_AR": "جنوب المستشفى العسكري",
    "DISTRICTNAME_EN": "Janub Al Mustashfa Al Askari",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1262",
    "DISTRICTNAME_AR": "1النرجس",
    "DISTRICTNAME_EN": "An Narjis1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1263",
    "DISTRICTNAME_AR": "البحاري",
    "DISTRICTNAME_EN": "Al Bahari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1264",
    "DISTRICTNAME_AR": "إسكان قوى الأمن",
    "DISTRICTNAME_EN": "Security Forces Housing",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1265",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1266",
    "DISTRICTNAME_AR": "قرية النيصية",
    "DISTRICTNAME_EN": "Qaryat Al Nyssa",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1267",
    "DISTRICTNAME_AR": "كورنيش حائل",
    "DISTRICTNAME_EN": "Hail Corniche",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1268",
    "DISTRICTNAME_AR": "الودي",
    "DISTRICTNAME_EN": "Al Wudi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1269",
    "DISTRICTNAME_AR": "الطريفي",
    "DISTRICTNAME_EN": "Al Turifi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1270",
    "DISTRICTNAME_AR": "المنتزة الشرقي",
    "DISTRICTNAME_EN": "Al Muntazah Ash Sharqi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1271",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1272",
    "DISTRICTNAME_AR": "درة مشار",
    "DISTRICTNAME_EN": "Dora Machar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1273",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1274",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1277",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1278",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1279",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1280",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1281",
    "DISTRICTNAME_AR": "الصيادلة",
    "DISTRICTNAME_EN": "al Sayadiluh",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1282",
    "DISTRICTNAME_AR": "حي ج3د",
    "DISTRICTNAME_EN": "Dst J3D",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1283",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1284",
    "DISTRICTNAME_AR": "المجمع",
    "DISTRICTNAME_EN": "Al Majma",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1285",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1286",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1288",
    "DISTRICTNAME_AR": "إدمان الشرقي",
    "DISTRICTNAME_EN": "Idman Al Sharqi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1289",
    "DISTRICTNAME_AR": "العدوة",
    "DISTRICTNAME_EN": "Al Adwah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1290",
    "DISTRICTNAME_AR": "الوديعه",
    "DISTRICTNAME_EN": "Al Wadiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1291",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1293",
    "DISTRICTNAME_AR": "ضحكان 1",
    "DISTRICTNAME_EN": "Dahkan 1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1294",
    "DISTRICTNAME_AR": "العرق الشمالي",
    "DISTRICTNAME_EN": "Northern Al Eirq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1295",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1296",
    "DISTRICTNAME_AR": "أبو معن",
    "DISTRICTNAME_EN": "Abu Main",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1297",
    "DISTRICTNAME_AR": "قراقر",
    "DISTRICTNAME_EN": "Qaraqir",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1298",
    "DISTRICTNAME_AR": "العذيب",
    "DISTRICTNAME_EN": "Al Udhib",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1300",
    "DISTRICTNAME_AR": "منطقة خدمات 2",
    "DISTRICTNAME_EN": "Services  Area 2",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1302",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1303",
    "DISTRICTNAME_AR": "زرود",
    "DISTRICTNAME_EN": "Zaroud",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1304",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1305",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1306",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1307",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1308",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1309",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1310",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1311",
    "DISTRICTNAME_AR": "ذلالة",
    "DISTRICTNAME_EN": "Dhalalah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1312",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1313",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1314",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1315",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1316",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1317",
    "DISTRICTNAME_AR": "الخلصة",
    "DISTRICTNAME_EN": "Al Khulsa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1318",
    "DISTRICTNAME_AR": "نشوان",
    "DISTRICTNAME_EN": "Nishwan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1320",
    "DISTRICTNAME_AR": "الخامسة",
    "DISTRICTNAME_EN": "Al Khamisah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1321",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1322",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1323",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1324",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1325",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1326",
    "DISTRICTNAME_AR": "المروج 1",
    "DISTRICTNAME_EN": "Al Muruj 1",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1327",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1328",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1329",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1333",
    "DISTRICTNAME_AR": "مخطط الملك عبدالله للإسكان",
    "DISTRICTNAME_EN": "King Abdullah Housing Subdivision",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1334",
    "DISTRICTNAME_AR": "الطلايع",
    "DISTRICTNAME_EN": "Al Talayie",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1335",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1336",
    "DISTRICTNAME_AR": "صفوان",
    "DISTRICTNAME_EN": "Safwan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1337",
    "DISTRICTNAME_AR": "الحرابي",
    "DISTRICTNAME_EN": "Al Harabaa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1338",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1339",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1340",
    "DISTRICTNAME_AR": "الخترشية",
    "DISTRICTNAME_EN": "Al Khatrashiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1341",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1342",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1343",
    "DISTRICTNAME_AR": "دارين",
    "DISTRICTNAME_EN": "Darin",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1344",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1345",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1349",
    "DISTRICTNAME_AR": "الخشبى",
    "DISTRICTNAME_EN": "Al Khushbi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1350",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1352",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1353",
    "DISTRICTNAME_AR": "التلفزيون",
    "DISTRICTNAME_EN": "Al Tilfizyun",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1354",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1355",
    "DISTRICTNAME_AR": "الصويدرة",
    "DISTRICTNAME_EN": "Al Suwaydra",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1359",
    "DISTRICTNAME_AR": "وادي الهاشة",
    "DISTRICTNAME_EN": "Wadi Al hashah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1360",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1361",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1362",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1363",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1364",
    "DISTRICTNAME_AR": "الشماس",
    "DISTRICTNAME_EN": "Al Shammas",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1366",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1367",
    "DISTRICTNAME_AR": "الميداء",
    "DISTRICTNAME_EN": "Al Maida",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1368",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1369",
    "DISTRICTNAME_AR": "اللجين",
    "DISTRICTNAME_EN": "Al Lajayn",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1371",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1372",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1376",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1377",
    "DISTRICTNAME_AR": "الدقم",
    "DISTRICTNAME_EN": "Al Duqm",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1378",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1379",
    "DISTRICTNAME_AR": "الرابع",
    "DISTRICTNAME_EN": "The Fourth",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1380",
    "DISTRICTNAME_AR": "التاسع",
    "DISTRICTNAME_EN": "Ninth",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1381",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1382",
    "DISTRICTNAME_AR": "أرض وزارة الاسكان",
    "DISTRICTNAME_EN": "Land of the Ministry of Housing",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1383",
    "DISTRICTNAME_AR": "الصناعية القديمة",
    "DISTRICTNAME_EN": "Old Industrial",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1384",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1385",
    "DISTRICTNAME_AR": "بقعاء الشرقية القديمة",
    "DISTRICTNAME_EN": "Old Eastern Baqaa",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1386",
    "DISTRICTNAME_AR": "أبو شجره",
    "DISTRICTNAME_EN": "Abu shajarah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1387",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1388",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1389",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1390",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1391",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "Al Sharaf",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1392",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1393",
    "DISTRICTNAME_AR": "الصمدة",
    "DISTRICTNAME_EN": "As Samdah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1394",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1395",
    "DISTRICTNAME_AR": "ساق",
    "DISTRICTNAME_EN": "Saq",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1396",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1397",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1398",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1399",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1400",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1401",
    "DISTRICTNAME_AR": "بقعاء القديمة",
    "DISTRICTNAME_EN": "Old Baqaa",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1402",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1403",
    "DISTRICTNAME_AR": "بحيرة تجميعية",
    "DISTRICTNAME_EN": "Pool Lake",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1404",
    "DISTRICTNAME_AR": "مخطط بقعاء الشرقية",
    "DISTRICTNAME_EN": "Baqa Al Sharqiah Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1405",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1406",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1407",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1408",
    "DISTRICTNAME_AR": "الرويحة",
    "DISTRICTNAME_EN": "Ar Ruwaihah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1409",
    "DISTRICTNAME_AR": "حي ج4د",
    "DISTRICTNAME_EN": "Dst J4D",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1410",
    "DISTRICTNAME_AR": "المنخلي",
    "DISTRICTNAME_EN": "Al Munkhali",
    "CITY_ID": "3735",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1412",
    "DISTRICTNAME_AR": "السجدة",
    "DISTRICTNAME_EN": "Al Sajda",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1413",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1415",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1416",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1418",
    "DISTRICTNAME_AR": "المحفر",
    "DISTRICTNAME_EN": "Al Mahafr",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1419",
    "DISTRICTNAME_AR": "الضويحي",
    "DISTRICTNAME_EN": "Al Dawayhi",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1420",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1421",
    "DISTRICTNAME_AR": "الناصرية 6",
    "DISTRICTNAME_EN": "An Nasriyah 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1422",
    "DISTRICTNAME_AR": "الناصرية 5",
    "DISTRICTNAME_EN": "An Nasriyah 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1423",
    "DISTRICTNAME_AR": "الناصرية 4",
    "DISTRICTNAME_EN": "An Nasriyah 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1424",
    "DISTRICTNAME_AR": "الناصرية 3",
    "DISTRICTNAME_EN": "An Nasriyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1425",
    "DISTRICTNAME_AR": "الناصرية 2",
    "DISTRICTNAME_EN": "An Nasriyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1426",
    "DISTRICTNAME_AR": "الناصرية 1",
    "DISTRICTNAME_EN": "An Nasriyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1427",
    "DISTRICTNAME_AR": "الاحياء الجنوبية",
    "DISTRICTNAME_EN": "Southern Neighborhoods",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1428",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1430",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1431",
    "DISTRICTNAME_AR": "الوقواق",
    "DISTRICTNAME_EN": "Al Waqwaq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1432",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1433",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1434",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1435",
    "DISTRICTNAME_AR": "المنتزة الغربي",
    "DISTRICTNAME_EN": "Al Muntazah Al Gharbi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1436",
    "DISTRICTNAME_AR": "غرب الخالدية",
    "DISTRICTNAME_EN": "Khalidiyah West",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1437",
    "DISTRICTNAME_AR": "الشعبين",
    "DISTRICTNAME_EN": "Al Shuabin",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1438",
    "DISTRICTNAME_AR": "الصوامع",
    "DISTRICTNAME_EN": "Al Sawamie",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1439",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1440",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1441",
    "DISTRICTNAME_AR": "مخطط الغزلاني",
    "DISTRICTNAME_EN": "Al Ghizlani Subdivision",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1442",
    "DISTRICTNAME_AR": "الشقة العليا",
    "DISTRICTNAME_EN": "Al Shiqah Al Ulya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1443",
    "DISTRICTNAME_AR": "النقع الغربي",
    "DISTRICTNAME_EN": "Al Naqa Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1444",
    "DISTRICTNAME_AR": "الأمن",
    "DISTRICTNAME_EN": "Al Amn",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1445",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1446",
    "DISTRICTNAME_AR": "التخصصي",
    "DISTRICTNAME_EN": "Al Takhassusi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1447",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1448",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1449",
    "DISTRICTNAME_AR": "لزمه",
    "DISTRICTNAME_EN": "Lazmah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1450",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1451",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1452",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1453",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1454",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1458",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1459",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1460",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1463",
    "DISTRICTNAME_AR": "المحمدية 2",
    "DISTRICTNAME_EN": "Al Muhammadiyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1464",
    "DISTRICTNAME_AR": "الخالدية 3",
    "DISTRICTNAME_EN": "Al Khalidiyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1467",
    "DISTRICTNAME_AR": "مخطط السكراب",
    "DISTRICTNAME_EN": "Al Cakrab Subdivision",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1469",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1470",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1471",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1472",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1473",
    "DISTRICTNAME_AR": "شباعة",
    "DISTRICTNAME_EN": "Shubaah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1474",
    "DISTRICTNAME_AR": "الرضا",
    "DISTRICTNAME_EN": "Ar Rida",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1478",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1479",
    "DISTRICTNAME_AR": "الاخاشيم",
    "DISTRICTNAME_EN": "Al Akhashim",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1480",
    "DISTRICTNAME_AR": "المنتزة رالشمالي",
    "DISTRICTNAME_EN": "Al Muntazah Al Shamali",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1481",
    "DISTRICTNAME_AR": "الجش",
    "DISTRICTNAME_EN": "Al Jish",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1482",
    "DISTRICTNAME_AR": "الخالدية 2",
    "DISTRICTNAME_EN": "Al Khalidiyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1483",
    "DISTRICTNAME_AR": "الخالدية 4",
    "DISTRICTNAME_EN": "Al Khalidiyah 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1484",
    "DISTRICTNAME_AR": "الخالدية 5",
    "DISTRICTNAME_EN": "Al Khalidiyah 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1485",
    "DISTRICTNAME_AR": "المفتاحة",
    "DISTRICTNAME_EN": "Al Muftahah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1486",
    "DISTRICTNAME_AR": "الخشع",
    "DISTRICTNAME_EN": "Al Khasha",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1487",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1488",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1489",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1490",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1492",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1493",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1494",
    "DISTRICTNAME_AR": "عين الخيف",
    "DISTRICTNAME_EN": "Ayn Al Khif",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1495",
    "DISTRICTNAME_AR": "الزور",
    "DISTRICTNAME_EN": "Az Zour",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1499",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1500",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1501",
    "DISTRICTNAME_AR": "درب العقيدة",
    "DISTRICTNAME_EN": "Darab Al Aqida",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1502",
    "DISTRICTNAME_AR": "القرحاء 2",
    "DISTRICTNAME_EN": "Al Qarha 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1503",
    "DISTRICTNAME_AR": "المربع",
    "DISTRICTNAME_EN": "Al Murabba",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1504",
    "DISTRICTNAME_AR": "المقيطع",
    "DISTRICTNAME_EN": "Al Muqayti",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1505",
    "DISTRICTNAME_AR": "عيانة 1",
    "DISTRICTNAME_EN": "Ayanah 1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1506",
    "DISTRICTNAME_AR": "الغال",
    "DISTRICTNAME_EN": "Al Ghal",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1507",
    "DISTRICTNAME_AR": "المقرش",
    "DISTRICTNAME_EN": "Al Maqrash",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1508",
    "DISTRICTNAME_AR": "هداج",
    "DISTRICTNAME_EN": "Haddaj",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1509",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1510",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1511",
    "DISTRICTNAME_AR": "الثاني",
    "DISTRICTNAME_EN": "The Second",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1512",
    "DISTRICTNAME_AR": "الثالث",
    "DISTRICTNAME_EN": "The Third",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1513",
    "DISTRICTNAME_AR": "المحار",
    "DISTRICTNAME_EN": "Al Mahar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1514",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1515",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1516",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1517",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1518",
    "DISTRICTNAME_AR": "عنقرة",
    "DISTRICTNAME_EN": "Anqara",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1523",
    "DISTRICTNAME_AR": "إدمان الغربي",
    "DISTRICTNAME_EN": "Idman Al Gharbi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1524",
    "DISTRICTNAME_AR": "المفرق الخاص",
    "DISTRICTNAME_EN": "Al Mafriq Al Khas",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1526",
    "DISTRICTNAME_AR": "الأول",
    "DISTRICTNAME_EN": "Al Awal",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1527",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1528",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1529",
    "DISTRICTNAME_AR": "النفود",
    "DISTRICTNAME_EN": "An Nofoad",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1530",
    "DISTRICTNAME_AR": "الدهناء",
    "DISTRICTNAME_EN": "Al Dahnaa",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1531",
    "DISTRICTNAME_AR": "الخذامة",
    "DISTRICTNAME_EN": "Al Khudhama",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1532",
    "DISTRICTNAME_AR": "الفيصلية غرب",
    "DISTRICTNAME_EN": "Al Faisaliyah West",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1533",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1534",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1535",
    "DISTRICTNAME_AR": "الزارة",
    "DISTRICTNAME_EN": "Az Zarah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1536",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1537",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1538",
    "DISTRICTNAME_AR": "الفهد أ",
    "DISTRICTNAME_EN": "Al Fahd A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1539",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1540",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1541",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1542",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1543",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1544",
    "DISTRICTNAME_AR": "النجيل",
    "DISTRICTNAME_EN": "An Nujayl",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1545",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1547",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1548",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1549",
    "DISTRICTNAME_AR": "الجارودية",
    "DISTRICTNAME_EN": "Al Jarudiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1550",
    "DISTRICTNAME_AR": "الحضارى",
    "DISTRICTNAME_EN": "Al Hadari",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1551",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1552",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1553",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1554",
    "DISTRICTNAME_AR": "عيانة 2",
    "DISTRICTNAME_EN": "Ayanah 2",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1555",
    "DISTRICTNAME_AR": "الذهب",
    "DISTRICTNAME_EN": "Al Dhahab",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1556",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1557",
    "DISTRICTNAME_AR": "منطقة المستودعات",
    "DISTRICTNAME_EN": "Warehouse Area",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1558",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1559",
    "DISTRICTNAME_AR": "التوبي",
    "DISTRICTNAME_EN": "At Tawbi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1560",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1561",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1562",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1563",
    "DISTRICTNAME_AR": "الصريف",
    "DISTRICTNAME_EN": "Al Suraif",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1564",
    "DISTRICTNAME_AR": "المدينة الاقتصادية",
    "DISTRICTNAME_EN": "Economic City",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1566",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1567",
    "DISTRICTNAME_AR": "مخطط الظهيره",
    "DISTRICTNAME_EN": "Al Zahirih Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1568",
    "DISTRICTNAME_AR": "الحميرة",
    "DISTRICTNAME_EN": "Al Hamriah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1570",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1573",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1574",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1575",
    "DISTRICTNAME_AR": "بطيحان الغربي",
    "DISTRICTNAME_EN": "Western Batihan",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1576",
    "DISTRICTNAME_AR": "المفرق",
    "DISTRICTNAME_EN": "Al Mafriq",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1577",
    "DISTRICTNAME_AR": "العقدة",
    "DISTRICTNAME_EN": "Al Uqdah",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1578",
    "DISTRICTNAME_AR": "مخطط صلاح الدين",
    "DISTRICTNAME_EN": "Salah Al Diyn Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1581",
    "DISTRICTNAME_AR": "المدارس",
    "DISTRICTNAME_EN": "Al Madaris",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1583",
    "DISTRICTNAME_AR": "المنح ب",
    "DISTRICTNAME_EN": "Al Minah B",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1584",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1585",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1587",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1588",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1589",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1590",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1591",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1592",
    "DISTRICTNAME_AR": "مطار الأمير عبد المحسن بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince Abdulmohsen bin Abdulaziz Airport",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1593",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1595",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1596",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1597",
    "DISTRICTNAME_AR": "بديعة البصري",
    "DISTRICTNAME_EN": "Badiah Al Basri",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1598",
    "DISTRICTNAME_AR": "الغرابة",
    "DISTRICTNAME_EN": "Al Gharaba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1599",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1603",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1604",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1605",
    "DISTRICTNAME_AR": "الجار",
    "DISTRICTNAME_EN": "Al Jar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1606",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1607",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1608",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1609",
    "DISTRICTNAME_AR": "الشهباء",
    "DISTRICTNAME_EN": "Ash Shahba",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1610",
    "DISTRICTNAME_AR": "الشريعة",
    "DISTRICTNAME_EN": "Ash Shariah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1611",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1612",
    "DISTRICTNAME_AR": "الملاحة",
    "DISTRICTNAME_EN": "Al Milaha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1614",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1620",
    "DISTRICTNAME_AR": "حي ج25",
    "DISTRICTNAME_EN": "Dst C25",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1621",
    "DISTRICTNAME_AR": "حي ج22",
    "DISTRICTNAME_EN": "Dst C22",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1622",
    "DISTRICTNAME_AR": "الرصراص 2",
    "DISTRICTNAME_EN": "Al Rasras 2",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1623",
    "DISTRICTNAME_AR": "المشتل",
    "DISTRICTNAME_EN": "Al Mashtal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1624",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1627",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1630",
    "DISTRICTNAME_AR": "حي ج20",
    "DISTRICTNAME_EN": "Dst C20",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1631",
    "DISTRICTNAME_AR": "أرامكو",
    "DISTRICTNAME_EN": "Aramco",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1632",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1633",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1634",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1635",
    "DISTRICTNAME_AR": "الفاو",
    "DISTRICTNAME_EN": "Al Faw",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1636",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1637",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1641",
    "DISTRICTNAME_AR": "وسط تندحة",
    "DISTRICTNAME_EN": "Centeral Tandaha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1642",
    "DISTRICTNAME_AR": "شمال تندحة",
    "DISTRICTNAME_EN": "North Tandiha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1643",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1644",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1645",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "Al Qaa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1646",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1647",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1648",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1649",
    "DISTRICTNAME_AR": "الحسين",
    "DISTRICTNAME_EN": "Al Husain",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1651",
    "DISTRICTNAME_AR": "الخماشية",
    "DISTRICTNAME_EN": "Al Khamashiyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1652",
    "DISTRICTNAME_AR": "الزبارة",
    "DISTRICTNAME_EN": "Az Zibarah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1653",
    "DISTRICTNAME_AR": "مغيضة",
    "DISTRICTNAME_EN": "Mughaydah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1654",
    "DISTRICTNAME_AR": "مياس",
    "DISTRICTNAME_EN": "Mayyas",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1655",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1656",
    "DISTRICTNAME_AR": "الشراعبة",
    "DISTRICTNAME_EN": "Al Shiraeiba",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1657",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1658",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1659",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1660",
    "DISTRICTNAME_AR": "السويفلة الجديد",
    "DISTRICTNAME_EN": "As Suwayfilah Al Jadid",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1661",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1662",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1664",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1665",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1666",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1667",
    "DISTRICTNAME_AR": "ذهبان الغربي",
    "DISTRICTNAME_EN": "Dhahban Western",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1668",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1669",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1670",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1672",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1673",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1674",
    "DISTRICTNAME_AR": "الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan1",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1675",
    "DISTRICTNAME_AR": "حي ب",
    "DISTRICTNAME_EN": "Dst B",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1676",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1677",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1678",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1679",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1680",
    "DISTRICTNAME_AR": "السفن",
    "DISTRICTNAME_EN": "As Sufun",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1681",
    "DISTRICTNAME_AR": "عتود الجنوبي",
    "DISTRICTNAME_EN": "Eatud Al Janubi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1682",
    "DISTRICTNAME_AR": "عتود الشمالي",
    "DISTRICTNAME_EN": "Eatud Al ShamalI",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1683",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1684",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1685",
    "DISTRICTNAME_AR": "الجشرة",
    "DISTRICTNAME_EN": "Al Jashrah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1686",
    "DISTRICTNAME_AR": "آل عزيز",
    "DISTRICTNAME_EN": "Al Aziz",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1687",
    "DISTRICTNAME_AR": "الأمجاد",
    "DISTRICTNAME_EN": "Al Amjad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1688",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1689",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1696",
    "DISTRICTNAME_AR": "المها",
    "DISTRICTNAME_EN": "Al Maha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1697",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1698",
    "DISTRICTNAME_AR": "الشمال",
    "DISTRICTNAME_EN": "Ash Shamal",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1699",
    "DISTRICTNAME_AR": "الصخيرات",
    "DISTRICTNAME_EN": "As Sukhayrat",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1700",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1701",
    "DISTRICTNAME_AR": "المنشية",
    "DISTRICTNAME_EN": "Al Mansheya",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1702",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1703",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1704",
    "DISTRICTNAME_AR": "خضرا و عشيران",
    "DISTRICTNAME_EN": "Khudra and Ushayran",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1705",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1706",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1707",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1708",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1709",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1710",
    "DISTRICTNAME_AR": "الصناعية2",
    "DISTRICTNAME_EN": "Industrial2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1711",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1712",
    "DISTRICTNAME_AR": "شبوك الاغنام",
    "DISTRICTNAME_EN": "Shabuk Al Aghnam",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1713",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1714",
    "DISTRICTNAME_AR": "الاداري",
    "DISTRICTNAME_EN": "Al Idari",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1715",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1716",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1717",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1718",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1719",
    "DISTRICTNAME_AR": "الصحنة",
    "DISTRICTNAME_EN": "Al Sahnah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1720",
    "DISTRICTNAME_AR": "زميقة",
    "DISTRICTNAME_EN": "Zamiqa",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1721",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1722",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1723",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1724",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1725",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1726",
    "DISTRICTNAME_AR": "الوسادة",
    "DISTRICTNAME_EN": "Al Wasadah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1727",
    "DISTRICTNAME_AR": "شرق السويفلة",
    "DISTRICTNAME_EN": "East of Al Swefilah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1728",
    "DISTRICTNAME_AR": "شمال التضامن",
    "DISTRICTNAME_EN": "North Al Tadamun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1729",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1730",
    "DISTRICTNAME_AR": "ضمك",
    "DISTRICTNAME_EN": "Damak",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1731",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1732",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1733",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1734",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1735",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1736",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1737",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1740",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1741",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1742",
    "DISTRICTNAME_AR": "السلطانة",
    "DISTRICTNAME_EN": "Al Sultana",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1745",
    "DISTRICTNAME_AR": "صدر الغربية",
    "DISTRICTNAME_EN": "Sadr Al Gharbaiyah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1746",
    "DISTRICTNAME_AR": "الحجر",
    "DISTRICTNAME_EN": "Al Hajar",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1747",
    "DISTRICTNAME_AR": "أم الجزم",
    "DISTRICTNAME_EN": "Umm Al Jazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1748",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1750",
    "DISTRICTNAME_AR": "حي ج19",
    "DISTRICTNAME_EN": "Dst C19",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1753",
    "DISTRICTNAME_AR": "لبدة",
    "DISTRICTNAME_EN": "Labdah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1754",
    "DISTRICTNAME_AR": "المحطة",
    "DISTRICTNAME_EN": "Al Mahattah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1755",
    "DISTRICTNAME_AR": "صبابة",
    "DISTRICTNAME_EN": "Sababah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1756",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1757",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1758",
    "DISTRICTNAME_AR": "العزيزية الغربي",
    "DISTRICTNAME_EN": "Western Al Aziziyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1759",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1760",
    "DISTRICTNAME_AR": "الزمرد2",
    "DISTRICTNAME_EN": "Az Zomorod2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1761",
    "DISTRICTNAME_AR": "البستان2",
    "DISTRICTNAME_EN": "Al Bustan2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1762",
    "DISTRICTNAME_AR": "الهلالي",
    "DISTRICTNAME_EN": "Al Hilali",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1763",
    "DISTRICTNAME_AR": "منطقة خدمات 3",
    "DISTRICTNAME_EN": "Services  Area 3",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1765",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1766",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1767",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1768",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1769",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1770",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1771",
    "DISTRICTNAME_AR": "قرية عقدة الشمالية",
    "DISTRICTNAME_EN": "Qaryat Uqdat Al Shamalia",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1772",
    "DISTRICTNAME_AR": "العوامية",
    "DISTRICTNAME_EN": "Al Awamiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1773",
    "DISTRICTNAME_AR": "الجراري",
    "DISTRICTNAME_EN": "Al Jarrari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1774",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1775",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1776",
    "DISTRICTNAME_AR": "رضوى",
    "DISTRICTNAME_EN": "Radwa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1777",
    "DISTRICTNAME_AR": "الطف",
    "DISTRICTNAME_EN": "At Tif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1778",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1779",
    "DISTRICTNAME_AR": "عنك",
    "DISTRICTNAME_EN": "Anak",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1780",
    "DISTRICTNAME_AR": "نقرة قفار",
    "DISTRICTNAME_EN": "Naqrat Qafaar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1781",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1782",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1783",
    "DISTRICTNAME_AR": "الوسطى",
    "DISTRICTNAME_EN": "Al Wusta",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1784",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1785",
    "DISTRICTNAME_AR": "الاوجام",
    "DISTRICTNAME_EN": "Al Aujam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1786",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1787",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1788",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1789",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1790",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1791",
    "DISTRICTNAME_AR": "صلاح الدين",
    "DISTRICTNAME_EN": "Salahuddin",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1792",
    "DISTRICTNAME_AR": "الصناعي",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1793",
    "DISTRICTNAME_AR": "حي ج",
    "DISTRICTNAME_EN": "Dst J",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1794",
    "DISTRICTNAME_AR": "مخطط الحفل",
    "DISTRICTNAME_EN": "Al Hafl Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1795",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1796",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1797",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1798",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1799",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1800",
    "DISTRICTNAME_AR": "الغزوة",
    "DISTRICTNAME_EN": "Al Ghazwah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1803",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1804",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1805",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1806",
    "DISTRICTNAME_AR": "أجا",
    "DISTRICTNAME_EN": "Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1807",
    "DISTRICTNAME_AR": "الجيل",
    "DISTRICTNAME_EN": "Al Jil",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1808",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1809",
    "DISTRICTNAME_AR": "المنارة",
    "DISTRICTNAME_EN": "Al Manarah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1810",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1811",
    "DISTRICTNAME_AR": "الصفق",
    "DISTRICTNAME_EN": "As Safq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1813",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1814",
    "DISTRICTNAME_AR": "حضن قلوص",
    "DISTRICTNAME_EN": "Hidn Qulus",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1815",
    "DISTRICTNAME_AR": "الجميمة",
    "DISTRICTNAME_EN": "Al Jumaimah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1817",
    "DISTRICTNAME_AR": "منطقة خدمات 1",
    "DISTRICTNAME_EN": "Services  Area 1",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1819",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1820",
    "DISTRICTNAME_AR": "مخطط الربيع",
    "DISTRICTNAME_EN": "Al Rabie Subdivision",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1821",
    "DISTRICTNAME_AR": "القديم",
    "DISTRICTNAME_EN": "Al Qadim",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1822",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1823",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1824",
    "DISTRICTNAME_AR": "منطقة الايداع واعادة التصدير",
    "DISTRICTNAME_EN": "Deposit and Re-export Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1825",
    "DISTRICTNAME_AR": "منطقة خدمات حكومية",
    "DISTRICTNAME_EN": "Government Services Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1826",
    "DISTRICTNAME_AR": "الرابعة",
    "DISTRICTNAME_EN": "Ar Rabiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1827",
    "DISTRICTNAME_AR": "الخويلدية",
    "DISTRICTNAME_EN": "Al Khuwaildiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1828",
    "DISTRICTNAME_AR": "الفريش",
    "DISTRICTNAME_EN": "Al Firaysh",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1829",
    "DISTRICTNAME_AR": "الدويج",
    "DISTRICTNAME_EN": "Ad Doij",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1830",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1831",
    "DISTRICTNAME_AR": "المدني",
    "DISTRICTNAME_EN": "Al Madani",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1833",
    "DISTRICTNAME_AR": "حي ج16",
    "DISTRICTNAME_EN": "Dst C16",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1834",
    "DISTRICTNAME_AR": "الثامن",
    "DISTRICTNAME_EN": "Al Thaamin",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1837",
    "DISTRICTNAME_AR": "النزهة1",
    "DISTRICTNAME_EN": "An Nuzhah1",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1838",
    "DISTRICTNAME_AR": "مخطط الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan Subdivision1",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1839",
    "DISTRICTNAME_AR": "سنابس",
    "DISTRICTNAME_EN": "Sanabis",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1840",
    "DISTRICTNAME_AR": "النابية",
    "DISTRICTNAME_EN": "An Nabiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1841",
    "DISTRICTNAME_AR": "يدمة الاول و الثاني",
    "DISTRICTNAME_EN": "Yadmat Alawil w Althaani",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1842",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1843",
    "DISTRICTNAME_AR": "المدائن",
    "DISTRICTNAME_EN": "Al Madayin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1844",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1847",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1848",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1849",
    "DISTRICTNAME_AR": "السميرى",
    "DISTRICTNAME_EN": "As Samira",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1850",
    "DISTRICTNAME_AR": "غرب أجا",
    "DISTRICTNAME_EN": "West Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1851",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1852",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1853",
    "DISTRICTNAME_AR": "الأسواق",
    "DISTRICTNAME_EN": "Al Aswaq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1854",
    "DISTRICTNAME_AR": "الفيصلية شرق",
    "DISTRICTNAME_EN": "Al Faisaliyah East",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1855",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1856",
    "DISTRICTNAME_AR": "اللقيطه",
    "DISTRICTNAME_EN": "Al Laqitah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1857",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1858",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1859",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1860",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1861",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1862",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1863",
    "DISTRICTNAME_AR": "محمية المانجروف",
    "DISTRICTNAME_EN": "Mangrove Reserve",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1864",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1865",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1866",
    "DISTRICTNAME_AR": "الجبنون",
    "DISTRICTNAME_EN": "Al Jabnun",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1869",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1870",
    "DISTRICTNAME_AR": "ثماني",
    "DISTRICTNAME_EN": "Thamani",
    "CITY_ID": "3739",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1871",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1872",
    "DISTRICTNAME_AR": "النزهة 1",
    "DISTRICTNAME_EN": "An Nuzhah 1",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1873",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1881",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1882",
    "DISTRICTNAME_AR": "نعمان",
    "DISTRICTNAME_EN": "Numan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1883",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1884",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1885",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1886",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1887",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1888",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1889",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1890",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1891",
    "DISTRICTNAME_AR": "التروية",
    "DISTRICTNAME_EN": "Al Tarwia",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1892",
    "DISTRICTNAME_AR": "أم الجود",
    "DISTRICTNAME_EN": "Umm Al Jud",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1893",
    "DISTRICTNAME_AR": "الشبيكة الجديد",
    "DISTRICTNAME_EN": "Ash Shubayka Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1894",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1895",
    "DISTRICTNAME_AR": "العكيشية",
    "DISTRICTNAME_EN": "Al Ukayshiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1896",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1897",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1898",
    "DISTRICTNAME_AR": "بطحاء قريش",
    "DISTRICTNAME_EN": "Batha Quraysh",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1899",
    "DISTRICTNAME_AR": "الهنداوية",
    "DISTRICTNAME_EN": "Al Hindawiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1900",
    "DISTRICTNAME_AR": "حضوضاء",
    "DISTRICTNAME_EN": "Hadawda'",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1901",
    "DISTRICTNAME_AR": "رؤى الحرم",
    "DISTRICTNAME_EN": "Haram visions",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1902",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1903",
    "DISTRICTNAME_AR": "أبو مرخة",
    "DISTRICTNAME_EN": "Abu Markha",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1904",
    "DISTRICTNAME_AR": "الصادقية",
    "DISTRICTNAME_EN": "As Sadiqiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1905",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1906",
    "DISTRICTNAME_AR": "السوق الجديد",
    "DISTRICTNAME_EN": "As Suq Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1907",
    "DISTRICTNAME_AR": "النوارية",
    "DISTRICTNAME_EN": "An Nawwariyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1908",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1909",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1910",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1911",
    "DISTRICTNAME_AR": "كدي",
    "DISTRICTNAME_EN": "Kudy",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1912",
    "DISTRICTNAME_AR": "الحجون",
    "DISTRICTNAME_EN": "Al Hujun",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1913",
    "DISTRICTNAME_AR": "النقا الجديد",
    "DISTRICTNAME_EN": "An Naqa Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1914",
    "DISTRICTNAME_AR": "طوى",
    "DISTRICTNAME_EN": "Tawa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1915",
    "DISTRICTNAME_AR": "شعب عامر ",
    "DISTRICTNAME_EN": "Shaeb Amir Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1916",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1917",
    "DISTRICTNAME_AR": "التقوى",
    "DISTRICTNAME_EN": "At Taqwa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1918",
    "DISTRICTNAME_AR": "المغيسلة",
    "DISTRICTNAME_EN": "Al Mughaisilah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1919",
    "DISTRICTNAME_AR": "جبل عير",
    "DISTRICTNAME_EN": "Jabal Eir",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1920",
    "DISTRICTNAME_AR": "قربان",
    "DISTRICTNAME_EN": "Qurban",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1921",
    "DISTRICTNAME_AR": "العاقول",
    "DISTRICTNAME_EN": "Al Aqoul",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1922",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1923",
    "DISTRICTNAME_AR": "التنعيم",
    "DISTRICTNAME_EN": "At Tanim",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1924",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1925",
    "DISTRICTNAME_AR": "زمزم",
    "DISTRICTNAME_EN": "Zamzam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1927",
    "DISTRICTNAME_AR": "الناصرة",
    "DISTRICTNAME_EN": "An Nasirah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1930",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1931",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1932",
    "DISTRICTNAME_AR": "ورقان",
    "DISTRICTNAME_EN": "Warqan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1933",
    "DISTRICTNAME_AR": "حرة الوبرة",
    "DISTRICTNAME_EN": "Harat Al Wabara",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1934",
    "DISTRICTNAME_AR": "السقيا",
    "DISTRICTNAME_EN": "As Suqya",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1935",
    "DISTRICTNAME_AR": "خاخ",
    "DISTRICTNAME_EN": "Khakh",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1936",
    "DISTRICTNAME_AR": "شوران",
    "DISTRICTNAME_EN": "Shuran",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1937",
    "DISTRICTNAME_AR": "العجيبة",
    "DISTRICTNAME_EN": "Al Ujaybah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1938",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1939",
    "DISTRICTNAME_AR": "اللسيب",
    "DISTRICTNAME_EN": "Al Lusayb",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1940",
    "DISTRICTNAME_AR": "العريمضي الشمالي",
    "DISTRICTNAME_EN": "Al Uraymidi Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1941",
    "DISTRICTNAME_AR": "ضارج",
    "DISTRICTNAME_EN": "Darij",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1942",
    "DISTRICTNAME_AR": "المطار القديم",
    "DISTRICTNAME_EN": "Old Airport",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1943",
    "DISTRICTNAME_AR": "الكعكية",
    "DISTRICTNAME_EN": "Al Kakiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1944",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1945",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1946",
    "DISTRICTNAME_AR": "الفلق الجديد",
    "DISTRICTNAME_EN": "Al Falaq Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1947",
    "DISTRICTNAME_AR": "جعرانة",
    "DISTRICTNAME_EN": "Jarana",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1948",
    "DISTRICTNAME_AR": "شمال أوثال",
    "DISTRICTNAME_EN": "North Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1949",
    "DISTRICTNAME_AR": "الأصيفرين",
    "DISTRICTNAME_EN": "Al Usayfirin",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1950",
    "DISTRICTNAME_AR": "أبو سدر",
    "DISTRICTNAME_EN": "Abu Sidr",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1951",
    "DISTRICTNAME_AR": "العنابس",
    "DISTRICTNAME_EN": "Al Anabis",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1952",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1953",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1954",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1955",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1956",
    "DISTRICTNAME_AR": "الحطيم",
    "DISTRICTNAME_EN": "Al Hatim",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1957",
    "DISTRICTNAME_AR": "حنين",
    "DISTRICTNAME_EN": "Hanin",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1958",
    "DISTRICTNAME_AR": "الجودرية الجديد",
    "DISTRICTNAME_EN": "Al Judriah Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1959",
    "DISTRICTNAME_AR": "البيبان",
    "DISTRICTNAME_EN": "Al Bayban",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1960",
    "DISTRICTNAME_AR": "جبل النور",
    "DISTRICTNAME_EN": "Jabal An Noor",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1961",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1962",
    "DISTRICTNAME_AR": "القرارة الجديد",
    "DISTRICTNAME_EN": "Al Qararat Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1963",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1964",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1965",
    "DISTRICTNAME_AR": "الجميزة",
    "DISTRICTNAME_EN": "Al Jummayzah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1966",
    "DISTRICTNAME_AR": "بنى ظفر",
    "DISTRICTNAME_EN": "Bani Zafar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1967",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1968",
    "DISTRICTNAME_AR": "البلقاء",
    "DISTRICTNAME_EN": "Al Balqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1969",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1970",
    "DISTRICTNAME_AR": "وادي الحمض",
    "DISTRICTNAME_EN": "Wadi al humd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1971",
    "DISTRICTNAME_AR": "الجفيدرية",
    "DISTRICTNAME_EN": "Al Jufaidariyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1972",
    "DISTRICTNAME_AR": "الحسينية",
    "DISTRICTNAME_EN": "Al Husainiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1973",
    "DISTRICTNAME_AR": "دار السلام",
    "DISTRICTNAME_EN": "Dar AI Salaam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1974",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1975",
    "DISTRICTNAME_AR": "بئر عثمان",
    "DISTRICTNAME_EN": "Bir Uthman",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1976",
    "DISTRICTNAME_AR": "الهدراء",
    "DISTRICTNAME_EN": "Al Hadra",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1977",
    "DISTRICTNAME_AR": "بنى معاوية",
    "DISTRICTNAME_EN": "Bani Muawiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1978",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1979",
    "DISTRICTNAME_AR": "الشريبات",
    "DISTRICTNAME_EN": "Ash Shuraybat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1980",
    "DISTRICTNAME_AR": "أم السيوف",
    "DISTRICTNAME_EN": "Umm Al Suywf",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1981",
    "DISTRICTNAME_AR": "كتانة",
    "DISTRICTNAME_EN": "Kittanah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1982",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1983",
    "DISTRICTNAME_AR": "بضاعة",
    "DISTRICTNAME_EN": "Badaah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1984",
    "DISTRICTNAME_AR": "العصبة",
    "DISTRICTNAME_EN": "Al Usbah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1985",
    "DISTRICTNAME_AR": "بني النجار",
    "DISTRICTNAME_EN": "Bani Al Najjar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1986",
    "DISTRICTNAME_AR": "الجصة",
    "DISTRICTNAME_EN": "Al Jassah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1987",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1988",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11044",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1989",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1990",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1991",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1992",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1993",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1994",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1995",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1996",
    "DISTRICTNAME_AR": "ريع اذاخر",
    "DISTRICTNAME_EN": "Rie Adhakhir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1997",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1998",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1999",
    "DISTRICTNAME_AR": "المسيال الجديد",
    "DISTRICTNAME_EN": "Al misyal Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2001",
    "DISTRICTNAME_AR": "وادي جليل",
    "DISTRICTNAME_EN": "Wadi Jalil",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2002",
    "DISTRICTNAME_AR": "المعابده",
    "DISTRICTNAME_EN": "Al Maeabiduh",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2003",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2004",
    "DISTRICTNAME_AR": "الدار",
    "DISTRICTNAME_EN": "Ad Dar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2005",
    "DISTRICTNAME_AR": "الحديقة",
    "DISTRICTNAME_EN": "Al Hadiqah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2006",
    "DISTRICTNAME_AR": "وعيرة",
    "DISTRICTNAME_EN": "Wairah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2007",
    "DISTRICTNAME_AR": "معاد",
    "DISTRICTNAME_EN": "Muead",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2008",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2009",
    "DISTRICTNAME_AR": "دميغ الجنوبية",
    "DISTRICTNAME_EN": "South Demig",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2010",
    "DISTRICTNAME_AR": "دميغ الشمالية",
    "DISTRICTNAME_EN": "North Damig",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2011",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2012",
    "DISTRICTNAME_AR": "الرصيفة",
    "DISTRICTNAME_EN": "Ar Rusayfah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2013",
    "DISTRICTNAME_AR": "المسفلة",
    "DISTRICTNAME_EN": "Al Misfalah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2014",
    "DISTRICTNAME_AR": "العوالي",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2015",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2016",
    "DISTRICTNAME_AR": "جرول",
    "DISTRICTNAME_EN": "Jarwal",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2017",
    "DISTRICTNAME_AR": "شعب علي ",
    "DISTRICTNAME_EN": "Shaeb Ali Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2018",
    "DISTRICTNAME_AR": "الهجلة الجديد",
    "DISTRICTNAME_EN": "Al Hajla Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2019",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2020",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2021",
    "DISTRICTNAME_AR": "الشوقية",
    "DISTRICTNAME_EN": "Ash Shawqiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2022",
    "DISTRICTNAME_AR": "الخنساء",
    "DISTRICTNAME_EN": "Al Khansa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2023",
    "DISTRICTNAME_AR": "الجمعة",
    "DISTRICTNAME_EN": "Al Jummah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2024",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2025",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2026",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2027",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2028",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2029",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2030",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2031",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2032",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2033",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2034",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2035",
    "DISTRICTNAME_AR": "القبيبة",
    "DISTRICTNAME_EN": "Al Qabiba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2036",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2037",
    "DISTRICTNAME_AR": "جبل أحد",
    "DISTRICTNAME_EN": "Jabal Uhud",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2038",
    "DISTRICTNAME_AR": "الظاهرة",
    "DISTRICTNAME_EN": "Az Zahirah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2039",
    "DISTRICTNAME_AR": "رهط",
    "DISTRICTNAME_EN": "Raht",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2040",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2041",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2042",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2043",
    "DISTRICTNAME_AR": "المزيين",
    "DISTRICTNAME_EN": "Al Muzayayn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2044",
    "DISTRICTNAME_AR": "سكة الحديد",
    "DISTRICTNAME_EN": "As Sikkah Al Hadid",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2045",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2046",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2047",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2048",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2049",
    "DISTRICTNAME_AR": "وادي مهزور",
    "DISTRICTNAME_EN": "Wadi Mahzur",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2050",
    "DISTRICTNAME_AR": "النقمى",
    "DISTRICTNAME_EN": "Al Nuqame",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2052",
    "DISTRICTNAME_AR": "الدويخلة",
    "DISTRICTNAME_EN": "Ad Duwaikhilah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2053",
    "DISTRICTNAME_AR": "عروة",
    "DISTRICTNAME_EN": "Urwah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2054",
    "DISTRICTNAME_AR": "جماء أم خالد",
    "DISTRICTNAME_EN": "Jama Umm Khaled",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2055",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2056",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2057",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2058",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11044",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2059",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2060",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2061",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2062",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2063",
    "DISTRICTNAME_AR": "المغمس",
    "DISTRICTNAME_EN": "Al Mughamas",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2064",
    "DISTRICTNAME_AR": "الشميسي",
    "DISTRICTNAME_EN": "Al Shumaisi",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2065",
    "DISTRICTNAME_AR": "حارة الباب الجديد",
    "DISTRICTNAME_EN": "Harat Al Bab Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2066",
    "DISTRICTNAME_AR": "الشامية الجديد",
    "DISTRICTNAME_EN": "Ash Shamiya Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2067",
    "DISTRICTNAME_AR": "القشاشية الجديد",
    "DISTRICTNAME_EN": "Al Qashashia Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2069",
    "DISTRICTNAME_AR": "البحيرات",
    "DISTRICTNAME_EN": "Al Buhayrat",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2070",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2072",
    "DISTRICTNAME_AR": "الراشدية",
    "DISTRICTNAME_EN": "Ar Rashidiyyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2074",
    "DISTRICTNAME_AR": "الورود2",
    "DISTRICTNAME_EN": "Al Wurud2",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2076",
    "DISTRICTNAME_AR": "الياسمين1",
    "DISTRICTNAME_EN": "Al Yasmin1",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2077",
    "DISTRICTNAME_AR": "الجابرة",
    "DISTRICTNAME_EN": "Al Jabirah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2078",
    "DISTRICTNAME_AR": "الشهباء",
    "DISTRICTNAME_EN": "Ash Shahba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2079",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2080",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2081",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2082",
    "DISTRICTNAME_AR": "المناخة",
    "DISTRICTNAME_EN": "Al Manakhah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2083",
    "DISTRICTNAME_AR": "الفرقان",
    "DISTRICTNAME_EN": "Al Furqan",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2084",
    "DISTRICTNAME_AR": "الثنية",
    "DISTRICTNAME_EN": "Al Thania",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2085",
    "DISTRICTNAME_AR": "المقام",
    "DISTRICTNAME_EN": "Al Maqam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2086",
    "DISTRICTNAME_AR": "الحديبية",
    "DISTRICTNAME_EN": "Al Hudaybiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2087",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2088",
    "DISTRICTNAME_AR": "البيعة",
    "DISTRICTNAME_EN": "Al Bayea",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2089",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2090",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2091",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2092",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2093",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2094",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2095",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2096",
    "DISTRICTNAME_AR": "نايف",
    "DISTRICTNAME_EN": "Nayef",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2097",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2098",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2099",
    "DISTRICTNAME_AR": "بنى عبد الأشهل",
    "DISTRICTNAME_EN": "Bani Abdul Ashhal",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2100",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2101",
    "DISTRICTNAME_AR": "النقا",
    "DISTRICTNAME_EN": "An Naqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2102",
    "DISTRICTNAME_AR": "نبلاء",
    "DISTRICTNAME_EN": "Nubala",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2103",
    "DISTRICTNAME_AR": "الشافية",
    "DISTRICTNAME_EN": "Ash Shafiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2104",
    "DISTRICTNAME_AR": "وادي البطان",
    "DISTRICTNAME_EN": "Wadi Al Battan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2105",
    "DISTRICTNAME_AR": "العقبة",
    "DISTRICTNAME_EN": "Al Aqaba",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2106",
    "DISTRICTNAME_AR": "منطقة التنمية1",
    "DISTRICTNAME_EN": "lands suitable for development1",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "2108",
    "DISTRICTNAME_AR": "مخطط الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan Subdivision2",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "2110",
    "DISTRICTNAME_AR": "الجماوات",
    "DISTRICTNAME_EN": "Al Jamawat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2111",
    "DISTRICTNAME_AR": "المفرحات",
    "DISTRICTNAME_EN": "Al Mufrahat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2112",
    "DISTRICTNAME_AR": "الحفيا",
    "DISTRICTNAME_EN": "Al Hafya",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2113",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "As Sih",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2114",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2115",
    "DISTRICTNAME_AR": "الخاتم",
    "DISTRICTNAME_EN": "Al Khatim",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2116",
    "DISTRICTNAME_AR": "الرانوناء",
    "DISTRICTNAME_EN": "Ar Ranuna",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2117",
    "DISTRICTNAME_AR": "الصهوة",
    "DISTRICTNAME_EN": "Al Sahwa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2118",
    "DISTRICTNAME_AR": "الاناهي",
    "DISTRICTNAME_EN": "Al Anahi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2119",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "2120",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2121",
    "DISTRICTNAME_AR": "العابدية",
    "DISTRICTNAME_EN": "Al Abidiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2122",
    "DISTRICTNAME_AR": "المدعى الجديد",
    "DISTRICTNAME_EN": "Al Mudae Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2123",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2124",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2125",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2126",
    "DISTRICTNAME_AR": "حي ج14",
    "DISTRICTNAME_EN": "Dst C14",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2127",
    "DISTRICTNAME_AR": "مخطط رقم 1452",
    "DISTRICTNAME_EN": "Subdivision No. 1452",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2128",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2129",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2130",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2131",
    "DISTRICTNAME_AR": "القزاز",
    "DISTRICTNAME_EN": "Al Qazaz",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2132",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2133",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2134",
    "DISTRICTNAME_AR": "قبيبان",
    "DISTRICTNAME_EN": "Qubiban",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2135",
    "DISTRICTNAME_AR": "مخطط 802",
    "DISTRICTNAME_EN": "Subdivision 802",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2136",
    "DISTRICTNAME_AR": "مخطط رقم 1141",
    "DISTRICTNAME_EN": "Subdivision No. 1141",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2137",
    "DISTRICTNAME_AR": "بنى بياضة",
    "DISTRICTNAME_EN": "Bani Bayadah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2138",
    "DISTRICTNAME_AR": "الدويمة",
    "DISTRICTNAME_EN": "Ad Duwaimah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2139",
    "DISTRICTNAME_AR": "صحارى",
    "DISTRICTNAME_EN": "Sahara",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2140",
    "DISTRICTNAME_AR": "الزعيبة",
    "DISTRICTNAME_EN": "Al Zaeiba",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2141",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2142",
    "DISTRICTNAME_AR": "العيبه",
    "DISTRICTNAME_EN": "Al Uaybah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2143",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2144",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2145",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2146",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2147",
    "DISTRICTNAME_AR": "الدار البيضاء",
    "DISTRICTNAME_EN": "Al Dar Al Baida",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2148",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2149",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2150",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2151",
    "DISTRICTNAME_AR": "الجلوية",
    "DISTRICTNAME_EN": "Al Jalawiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2152",
    "DISTRICTNAME_AR": "العدامة",
    "DISTRICTNAME_EN": "Al Adamah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2153",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2154",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2155",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2156",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2157",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2158",
    "DISTRICTNAME_AR": "العشيرة",
    "DISTRICTNAME_EN": "Al Asheyrah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2159",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2160",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2161",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2162",
    "DISTRICTNAME_AR": "الملك عبدالله1",
    "DISTRICTNAME_EN": "King Abdullah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2163",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2166",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2167",
    "DISTRICTNAME_AR": "الملك",
    "DISTRICTNAME_EN": "Al Malik",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2168",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2169",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2171",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2172",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2173",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "second industrial",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2174",
    "DISTRICTNAME_AR": "البصيرة",
    "DISTRICTNAME_EN": "Al Basirah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2175",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2176",
    "DISTRICTNAME_AR": "علقة",
    "DISTRICTNAME_EN": "Alkah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2177",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2184",
    "DISTRICTNAME_AR": "المسيل",
    "DISTRICTNAME_EN": "Al Masil",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2185",
    "DISTRICTNAME_AR": "المثالية",
    "DISTRICTNAME_EN": "Al Mithalia",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2186",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2187",
    "DISTRICTNAME_AR": "هجرة سليم",
    "DISTRICTNAME_EN": "Hijrat Salim",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2188",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2189",
    "DISTRICTNAME_AR": "الجابرية",
    "DISTRICTNAME_EN": "Al Jabriyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2190",
    "DISTRICTNAME_AR": "البثنة",
    "DISTRICTNAME_EN": "Al Bathnah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2191",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2192",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2193",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2194",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2195",
    "DISTRICTNAME_AR": "الطبيشي",
    "DISTRICTNAME_EN": "At Tubayshi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2196",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2197",
    "DISTRICTNAME_AR": "مدينة الملك فيصل الجامعية",
    "DISTRICTNAME_EN": "King Faisal University City",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2198",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2199",
    "DISTRICTNAME_AR": "القشلة",
    "DISTRICTNAME_EN": "Al Qashlah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2200",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2201",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2202",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2203",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2204",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2205",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2206",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2212",
    "DISTRICTNAME_AR": "الطرايشة",
    "DISTRICTNAME_EN": "Al Turshiah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2213",
    "DISTRICTNAME_AR": "البدوي",
    "DISTRICTNAME_EN": "Al Badawi",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2214",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2215",
    "DISTRICTNAME_AR": "آل مجلى",
    "DISTRICTNAME_EN": "Al Mijali",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2216",
    "DISTRICTNAME_AR": "البندر",
    "DISTRICTNAME_EN": "Al Bandar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2218",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2219",
    "DISTRICTNAME_AR": "برق الخيل",
    "DISTRICTNAME_EN": "Barq Al Khayl",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2221",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2222",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2223",
    "DISTRICTNAME_AR": "مخطط البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah Subdivision",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2224",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2225",
    "DISTRICTNAME_AR": "العرنية",
    "DISTRICTNAME_EN": "Al Urania",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2226",
    "DISTRICTNAME_AR": "مخطط رقم 1209",
    "DISTRICTNAME_EN": "Subdivision No. 1209",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2228",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2229",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2230",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2231",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2232",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2233",
    "DISTRICTNAME_AR": "الخالدية الشمالية",
    "DISTRICTNAME_EN": "Al Khalidiyah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2234",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2235",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2236",
    "DISTRICTNAME_AR": "غرب الظهران",
    "DISTRICTNAME_EN": "Gharb Adh Dhahran",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2237",
    "DISTRICTNAME_AR": "الدانة الشمالية",
    "DISTRICTNAME_EN": "Ad Danah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2238",
    "DISTRICTNAME_AR": "الثقبة",
    "DISTRICTNAME_EN": "Ath Thuqbah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2239",
    "DISTRICTNAME_AR": "638 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 638",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2240",
    "DISTRICTNAME_AR": "1075 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1075",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2241",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2242",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2243",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2244",
    "DISTRICTNAME_AR": "المفيجر",
    "DISTRICTNAME_EN": "Al Mufijir",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2245",
    "DISTRICTNAME_AR": "جامعة الملك فهد للبترول",
    "DISTRICTNAME_EN": "King Fahd University of Petroleum",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2246",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2247",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2248",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2249",
    "DISTRICTNAME_AR": "ميناء ينبع التجارى",
    "DISTRICTNAME_EN": "Yanbu Commercial Seaport",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2250",
    "DISTRICTNAME_AR": "الحدائق",
    "DISTRICTNAME_EN": "Al Hadaek",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2251",
    "DISTRICTNAME_AR": "منطقة مردم النفايات القديم",
    "DISTRICTNAME_EN": "Old landfill Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2252",
    "DISTRICTNAME_AR": "العهن",
    "DISTRICTNAME_EN": "Al Ihn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2253",
    "DISTRICTNAME_AR": "الدرع",
    "DISTRICTNAME_EN": "Ad Dara",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2254",
    "DISTRICTNAME_AR": "أبوكبير",
    "DISTRICTNAME_EN": "Abu Kabir",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2255",
    "DISTRICTNAME_AR": "مخطط رقم 0832",
    "DISTRICTNAME_EN": "Subdivision No. 0832",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2256",
    "DISTRICTNAME_AR": "أم سمرة",
    "DISTRICTNAME_EN": "Umm Samra",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2257",
    "DISTRICTNAME_AR": "الادارية شرق",
    "DISTRICTNAME_EN": "East Administrative",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2258",
    "DISTRICTNAME_AR": "الشرافا",
    "DISTRICTNAME_EN": "Al Sharafa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2259",
    "DISTRICTNAME_AR": "الزويراء",
    "DISTRICTNAME_EN": "Al Zuwayra",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2260",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2261",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2262",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2263",
    "DISTRICTNAME_AR": "صناعية الثقبة",
    "DISTRICTNAME_EN": "Sinaiyah Ath Thuqbah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2264",
    "DISTRICTNAME_AR": "المها",
    "DISTRICTNAME_EN": "Al Maha",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2265",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2267",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2268",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2269",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2270",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2271",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2272",
    "DISTRICTNAME_AR": "عريعرة",
    "DISTRICTNAME_EN": "Urierah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2273",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2274",
    "DISTRICTNAME_AR": "ال غضاب",
    "DISTRICTNAME_EN": "Al Ghidab",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2275",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2276",
    "DISTRICTNAME_AR": "الفرعة الشمالي",
    "DISTRICTNAME_EN": "Al Farah Al Shamali",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2277",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2278",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "Al Malqa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2279",
    "DISTRICTNAME_AR": "معكال",
    "DISTRICTNAME_EN": "Meakal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2280",
    "DISTRICTNAME_AR": "النسيم الغربي",
    "DISTRICTNAME_EN": "West Naseem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2281",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2282",
    "DISTRICTNAME_AR": "الخور",
    "DISTRICTNAME_EN": "Al Khawr",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2283",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2284",
    "DISTRICTNAME_AR": "مخطط الإتصالات",
    "DISTRICTNAME_EN": "Al Itsalat Subdivision",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2285",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2286",
    "DISTRICTNAME_AR": "السيف",
    "DISTRICTNAME_EN": "As Saif",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2287",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2288",
    "DISTRICTNAME_AR": "الدوحة الشمالية",
    "DISTRICTNAME_EN": "Ad Dawhah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2289",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2290",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2291",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2292",
    "DISTRICTNAME_AR": "الرحبة",
    "DISTRICTNAME_EN": "Ar Rahbah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2293",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2294",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2295",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2296",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2297",
    "DISTRICTNAME_AR": "الجاضع",
    "DISTRICTNAME_EN": "Al Jadie",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2298",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2299",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2300",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2301",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2302",
    "DISTRICTNAME_AR": "الجرادية",
    "DISTRICTNAME_EN": "Al Jarradiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2303",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2304",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2305",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2306",
    "DISTRICTNAME_AR": "آل عويمر",
    "DISTRICTNAME_EN": "Al Awaimer",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2307",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2308",
    "DISTRICTNAME_AR": "ال سالم",
    "DISTRICTNAME_EN": "Al Salem",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2310",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2311",
    "DISTRICTNAME_AR": "الفرعة الشرقية",
    "DISTRICTNAME_EN": "Al Farah Al Sharqia",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2312",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2313",
    "DISTRICTNAME_AR": "الشاطئ الشرقي",
    "DISTRICTNAME_EN": "Ash Shati Ash Sharqi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2314",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2315",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2316",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2317",
    "DISTRICTNAME_AR": "الرين الاعلى",
    "DISTRICTNAME_EN": "Al Riyn Al Aelaa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2318",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2319",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2320",
    "DISTRICTNAME_AR": "مدينة الملك عبدالله للطاقة",
    "DISTRICTNAME_EN": "King Abdullah City for Energy",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2321",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2322",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2323",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2324",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2325",
    "DISTRICTNAME_AR": "الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2326",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2327",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2328",
    "DISTRICTNAME_AR": "النور والصفاء",
    "DISTRICTNAME_EN": "An Noor and Al Safa",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2329",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2330",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2331",
    "DISTRICTNAME_AR": "المحمدية ",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2332",
    "DISTRICTNAME_AR": "اللدام الشمالي",
    "DISTRICTNAME_EN": "Al Lidam Al Shamali",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2333",
    "DISTRICTNAME_AR": "صناعية الشرافا",
    "DISTRICTNAME_EN": "Sinaiyah Al Sharafa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2334",
    "DISTRICTNAME_AR": "السعودية",
    "DISTRICTNAME_EN": "As Saudia",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2335",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2336",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2337",
    "DISTRICTNAME_AR": "الخير",
    "DISTRICTNAME_EN": "Al Khair",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2338",
    "DISTRICTNAME_AR": "الاخاء",
    "DISTRICTNAME_EN": "Al Ekhaa",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2339",
    "DISTRICTNAME_AR": "يبرين",
    "DISTRICTNAME_EN": "Yubrin",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2340",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2341",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2342",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2343",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2344",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2345",
    "DISTRICTNAME_AR": "مخطط 326",
    "DISTRICTNAME_EN": "Subdivision 326",
    "CITY_ID": "19919",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2346",
    "DISTRICTNAME_AR": "التسعين",
    "DISTRICTNAME_EN": "Ninety",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2347",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2348",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2349",
    "DISTRICTNAME_AR": "سد الغابة",
    "DISTRICTNAME_EN": "Sad Al Ghaba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2350",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2351",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2352",
    "DISTRICTNAME_AR": "العمامرة",
    "DISTRICTNAME_EN": "Al Amamrah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2353",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2354",
    "DISTRICTNAME_AR": "منطقة الخدمات المساندة",
    "DISTRICTNAME_EN": "Support Services",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2355",
    "DISTRICTNAME_AR": "صناعية الفوازية",
    "DISTRICTNAME_EN": "Sinaiyah Al Fawaziyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2356",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2357",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2358",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2359",
    "DISTRICTNAME_AR": "الأمير محمد بن سعود",
    "DISTRICTNAME_EN": "Prince Muhammad bin Saud",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2360",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2361",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2362",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2363",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2364",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2365",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2366",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2367",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2368",
    "DISTRICTNAME_AR": "الدوبية",
    "DISTRICTNAME_EN": "Al Dubiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2369",
    "DISTRICTNAME_AR": "الندوة",
    "DISTRICTNAME_EN": "Al Nadwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2370",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2371",
    "DISTRICTNAME_AR": "السكب",
    "DISTRICTNAME_EN": "As Sakb",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2372",
    "DISTRICTNAME_AR": "الحساء",
    "DISTRICTNAME_EN": "Al Hussa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2373",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2374",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2375",
    "DISTRICTNAME_AR": "اللدام الجنوبي",
    "DISTRICTNAME_EN": "Al Lidam Al Janubi",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2376",
    "DISTRICTNAME_AR": "نعام",
    "DISTRICTNAME_EN": "Nieam",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2377",
    "DISTRICTNAME_AR": "المعتلا",
    "DISTRICTNAME_EN": "Al Muetala",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2378",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2379",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2381",
    "DISTRICTNAME_AR": "المدينة الجامعية",
    "DISTRICTNAME_EN": "University City",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2382",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2383",
    "DISTRICTNAME_AR": "مخطط رقم 980",
    "DISTRICTNAME_EN": "Subdivision No. 980",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2384",
    "DISTRICTNAME_AR": "مخطط رقم 250-أ",
    "DISTRICTNAME_EN": "Subdivision No. 250 A",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2386",
    "DISTRICTNAME_AR": "بنى حارثة",
    "DISTRICTNAME_EN": "Bani Harithah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2387",
    "DISTRICTNAME_AR": "المبعوث",
    "DISTRICTNAME_EN": "Al Mabuth",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2388",
    "DISTRICTNAME_AR": "بني خدرة",
    "DISTRICTNAME_EN": "Bani Khidrah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2391",
    "DISTRICTNAME_AR": "الضريب",
    "DISTRICTNAME_EN": "Ad Darib",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2392",
    "DISTRICTNAME_AR": "العريجاء",
    "DISTRICTNAME_EN": "Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2393",
    "DISTRICTNAME_AR": "المنصورية",
    "DISTRICTNAME_EN": "Mansuriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2394",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2395",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2397",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2398",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2399",
    "DISTRICTNAME_AR": "الأمانة",
    "DISTRICTNAME_EN": "Al Amanah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2400",
    "DISTRICTNAME_AR": "الشاطئ الغربي",
    "DISTRICTNAME_EN": "Ash Shati Al Gharbi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2401",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2402",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2403",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2404",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2407",
    "DISTRICTNAME_AR": "الزاوية",
    "DISTRICTNAME_EN": "Al Zaawia",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2408",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2409",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2410",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2411",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2412",
    "DISTRICTNAME_AR": "المكرمية",
    "DISTRICTNAME_EN": "Al Mukramia",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2413",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2414",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2415",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2416",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2417",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2418",
    "DISTRICTNAME_AR": "الإسكان الخيري",
    "DISTRICTNAME_EN": "Al Iskan Al Khairi",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2419",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2420",
    "DISTRICTNAME_AR": "جرير",
    "DISTRICTNAME_EN": "Jareer",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2422",
    "DISTRICTNAME_AR": "منفوحة",
    "DISTRICTNAME_EN": "Manfuhah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2423",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2424",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2425",
    "DISTRICTNAME_AR": "المزروعية",
    "DISTRICTNAME_EN": "Al Mazruiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2426",
    "DISTRICTNAME_AR": "قصر الخليج",
    "DISTRICTNAME_EN": "Qasr Al Khalij",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2427",
    "DISTRICTNAME_AR": "الحسام",
    "DISTRICTNAME_EN": "Al Hussam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2428",
    "DISTRICTNAME_AR": "تهامة",
    "DISTRICTNAME_EN": "Tihamah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2429",
    "DISTRICTNAME_AR": "البندرية",
    "DISTRICTNAME_EN": "Al Bandariyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2430",
    "DISTRICTNAME_AR": "مزعل",
    "DISTRICTNAME_EN": "Mazal ",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2431",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2432",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2433",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2434",
    "DISTRICTNAME_AR": "العنود",
    "DISTRICTNAME_EN": "Al Anud",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2435",
    "DISTRICTNAME_AR": "مدينة العمال",
    "DISTRICTNAME_EN": "Madinat Al Ummal",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2436",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2437",
    "DISTRICTNAME_AR": "العقربية",
    "DISTRICTNAME_EN": "Al Aqrabiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2438",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2439",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2440",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2441",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2443",
    "DISTRICTNAME_AR": "مخطط رقم 913",
    "DISTRICTNAME_EN": "Subdivision No. 913",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2444",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2445",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2446",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2447",
    "DISTRICTNAME_AR": "المشرق",
    "DISTRICTNAME_EN": "Al Mashriq",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2448",
    "DISTRICTNAME_AR": "السفارات",
    "DISTRICTNAME_EN": "Assafarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2449",
    "DISTRICTNAME_AR": "الشموع",
    "DISTRICTNAME_EN": "Ash Shumu",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2450",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2451",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2452",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2453",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2454",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2455",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2456",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2457",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2458",
    "DISTRICTNAME_AR": "صقرة",
    "DISTRICTNAME_EN": "Saqra",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2459",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2460",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2461",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2462",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2463",
    "DISTRICTNAME_AR": "فيضة الفوازين",
    "DISTRICTNAME_EN": "Faydat Al Fawazin",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2464",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2465",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2466",
    "DISTRICTNAME_AR": "الخماسين الشمالى",
    "DISTRICTNAME_EN": "The northern fiftieths",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2467",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2468",
    "DISTRICTNAME_AR": "الدانة الجنوبية",
    "DISTRICTNAME_EN": "Ad Danah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2469",
    "DISTRICTNAME_AR": "اللؤلؤ",
    "DISTRICTNAME_EN": "Al Loaloa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2470",
    "DISTRICTNAME_AR": "الحزام الأخضر",
    "DISTRICTNAME_EN": "Al Hizam Al Akhdar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2471",
    "DISTRICTNAME_AR": "الرجاء",
    "DISTRICTNAME_EN": "Ar Rajaa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2472",
    "DISTRICTNAME_AR": "الجسر",
    "DISTRICTNAME_EN": "Al Jisr",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2473",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2474",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2475",
    "DISTRICTNAME_AR": "القصواء",
    "DISTRICTNAME_EN": "Al Qaswa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2476",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2477",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2478",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2479",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2480",
    "DISTRICTNAME_AR": "المندسة",
    "DISTRICTNAME_EN": "Al Mundasa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2481",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2482",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2483",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2484",
    "DISTRICTNAME_AR": "النورس",
    "DISTRICTNAME_EN": "An Nawras",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2485",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2486",
    "DISTRICTNAME_AR": "الراكة الجنوبية",
    "DISTRICTNAME_EN": "Ar Rakah Al Janubiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2487",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2488",
    "DISTRICTNAME_AR": "مدينة العمال",
    "DISTRICTNAME_EN": "Madinat Al Ummal",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2489",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2490",
    "DISTRICTNAME_AR": "الهلالي",
    "DISTRICTNAME_EN": "Al Hilali",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2491",
    "DISTRICTNAME_AR": "بهدي",
    "DISTRICTNAME_EN": "Bihady",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2492",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2493",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2494",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2495",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2496",
    "DISTRICTNAME_AR": "ال هويمل",
    "DISTRICTNAME_EN": "Al Hoymel",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2497",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2498",
    "DISTRICTNAME_AR": "مخطط 699",
    "DISTRICTNAME_EN": "Subdivision 699",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2499",
    "DISTRICTNAME_AR": "مخطط رقم 247",
    "DISTRICTNAME_EN": "Subdivision No. 247",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2500",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2502",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2503",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2504",
    "DISTRICTNAME_AR": "العود",
    "DISTRICTNAME_EN": "Al Oud",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2505",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2506",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2507",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2508",
    "DISTRICTNAME_AR": "المونسية",
    "DISTRICTNAME_EN": "Al Munisiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2509",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2510",
    "DISTRICTNAME_AR": "مخطط رقم 232",
    "DISTRICTNAME_EN": "Subdivision No. 232",
    "CITY_ID": "16099",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2511",
    "DISTRICTNAME_AR": "الدرعية",
    "DISTRICTNAME_EN": "Al Diriyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2512",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2513",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2514",
    "DISTRICTNAME_AR": "مختارة",
    "DISTRICTNAME_EN": "Mukhtara",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2516",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2517",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2522",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "Al Raed",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2523",
    "DISTRICTNAME_AR": "الصهلوج",
    "DISTRICTNAME_EN": "Al Sahluj",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2524",
    "DISTRICTNAME_AR": "الدوحة الجنوبية",
    "DISTRICTNAME_EN": "Ad Dawhah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2525",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2526",
    "DISTRICTNAME_AR": "الأثير",
    "DISTRICTNAME_EN": "Al Athir",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2527",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2528",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2529",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2530",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2531",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2532",
    "DISTRICTNAME_AR": "الخبرالشمالية",
    "DISTRICTNAME_EN": "Al Khabra Ash Shamalia",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2533",
    "DISTRICTNAME_AR": "الهضبة",
    "DISTRICTNAME_EN": "Al Hadabah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2534",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2535",
    "DISTRICTNAME_AR": "الراكة الشمالية",
    "DISTRICTNAME_EN": "Ar Rakah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2536",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2537",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2538",
    "DISTRICTNAME_AR": "الخضرية",
    "DISTRICTNAME_EN": "Al Kuthriah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2539",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2540",
    "DISTRICTNAME_AR": "الخبر الجنوبية",
    "DISTRICTNAME_EN": "Al Khubar Al Janubiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2541",
    "DISTRICTNAME_AR": "ابن سيناء",
    "DISTRICTNAME_EN": "Ibn Sina",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2542",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2543",
    "DISTRICTNAME_AR": "الدواسر",
    "DISTRICTNAME_EN": "Ad Dawasir",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2544",
    "DISTRICTNAME_AR": "المدينة الرياضية",
    "DISTRICTNAME_EN": "Sports City",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2547",
    "DISTRICTNAME_AR": "ذو الحليفة",
    "DISTRICTNAME_EN": "Dhu Al Hulayfah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2548",
    "DISTRICTNAME_AR": "ميناء الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz Seaport",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2549",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2550",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2551",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2552",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2553",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2554",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2555",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2556",
    "DISTRICTNAME_AR": "أبوبريقاء",
    "DISTRICTNAME_EN": "Abu Burayqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2557",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2558",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2559",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2560",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2561",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2562",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2563",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2564",
    "DISTRICTNAME_AR": "الكلية الحربية",
    "DISTRICTNAME_EN": "Military College",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2565",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2566",
    "DISTRICTNAME_AR": "السنبلة",
    "DISTRICTNAME_EN": "As Sunbulah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2567",
    "DISTRICTNAME_AR": "غبيرا",
    "DISTRICTNAME_EN": "Ghabira",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2568",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2569",
    "DISTRICTNAME_AR": "أم الحمام الغربي",
    "DISTRICTNAME_EN": "Umm Al Hamam Western",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2570",
    "DISTRICTNAME_AR": "المعذر",
    "DISTRICTNAME_EN": "Al Mathar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2571",
    "DISTRICTNAME_AR": "منفوحة الجديدة",
    "DISTRICTNAME_EN": "Manfuha Al Jadidah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2572",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2573",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2574",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2575",
    "DISTRICTNAME_AR": "القميش",
    "DISTRICTNAME_EN": "Al Qumaysh",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2576",
    "DISTRICTNAME_AR": "مخطط رقم725",
    "DISTRICTNAME_EN": "Subdivision No. 725",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2577",
    "DISTRICTNAME_AR": "الجرادية",
    "DISTRICTNAME_EN": "Al Jarradiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2578",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2579",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2580",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2582",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2583",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2585",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2586",
    "DISTRICTNAME_AR": "لبن",
    "DISTRICTNAME_EN": "Laban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2587",
    "DISTRICTNAME_AR": "عتيقة",
    "DISTRICTNAME_EN": "Utayqah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2588",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2589",
    "DISTRICTNAME_AR": "سكيرينة",
    "DISTRICTNAME_EN": "Skirinah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2590",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2591",
    "DISTRICTNAME_AR": "الغنامية",
    "DISTRICTNAME_EN": "Al Ghannamiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2592",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2593",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2594",
    "DISTRICTNAME_AR": "جشم",
    "DISTRICTNAME_EN": "Josham",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2595",
    "DISTRICTNAME_AR": "وادي مذينب",
    "DISTRICTNAME_EN": "Wadi Muzaynib",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2597",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2598",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2599",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2600",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2601",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2602",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2603",
    "DISTRICTNAME_AR": "البجادية",
    "DISTRICTNAME_EN": "Al Bajadia",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2604",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2605",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2606",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2608",
    "DISTRICTNAME_AR": "ظهرة العودة شرق",
    "DISTRICTNAME_EN": "Dhahrat Al Awdat Sharq",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2609",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2610",
    "DISTRICTNAME_AR": "آل حميضان",
    "DISTRICTNAME_EN": "Al Humaydan",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2611",
    "DISTRICTNAME_AR": "ال طهيف وال هديب",
    "DISTRICTNAME_EN": "Al Tahyif and Al Hadeeb",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2612",
    "DISTRICTNAME_AR": "10411043 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 10411043",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2613",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2614",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2615",
    "DISTRICTNAME_AR": "المصبح",
    "DISTRICTNAME_EN": "Al Msbeh",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2616",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2617",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2618",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2619",
    "DISTRICTNAME_AR": "نوارة",
    "DISTRICTNAME_EN": "Nawara",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2620",
    "DISTRICTNAME_AR": "السبيعى",
    "DISTRICTNAME_EN": "Al Sabieaa",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2621",
    "DISTRICTNAME_AR": "الغويبة",
    "DISTRICTNAME_EN": "Al Ghuwaiba",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2622",
    "DISTRICTNAME_AR": "نزوى",
    "DISTRICTNAME_EN": "Nazwaa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2623",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2624",
    "DISTRICTNAME_AR": "الشبانان",
    "DISTRICTNAME_EN": "Al Shubaanan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2625",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2626",
    "DISTRICTNAME_AR": "الصحن",
    "DISTRICTNAME_EN": "As Sahn",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2627",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2628",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2629",
    "DISTRICTNAME_AR": "مخطط رقم 120",
    "DISTRICTNAME_EN": "Subdivision No. 120",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2631",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "20279",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2633",
    "DISTRICTNAME_AR": "منطقة المستودعات",
    "DISTRICTNAME_EN": "Warehouse Area",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2634",
    "DISTRICTNAME_AR": "بنبان",
    "DISTRICTNAME_EN": "Banban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2635",
    "DISTRICTNAME_AR": "الذيابية",
    "DISTRICTNAME_EN": "Al Dhayabia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2637",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2638",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2640",
    "DISTRICTNAME_AR": "الحنابجة",
    "DISTRICTNAME_EN": "Al Hanabija",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2641",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2642",
    "DISTRICTNAME_AR": "عرقة",
    "DISTRICTNAME_EN": "Irqah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2643",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2644",
    "DISTRICTNAME_AR": "المرزوقية",
    "DISTRICTNAME_EN": "Al Marzoukia",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2645",
    "DISTRICTNAME_AR": "آل حمد",
    "DISTRICTNAME_EN": "Al Hamad",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2646",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2647",
    "DISTRICTNAME_AR": "مرخ",
    "DISTRICTNAME_EN": "Markh ",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2648",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2649",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2651",
    "DISTRICTNAME_AR": "البدائع",
    "DISTRICTNAME_EN": "Al Badai'",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2652",
    "DISTRICTNAME_AR": "العقبانى",
    "DISTRICTNAME_EN": "Al Aqabani",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2653",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2654",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2656",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2657",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2658",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2659",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2660",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2661",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2662",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2663",
    "DISTRICTNAME_AR": "المثوية",
    "DISTRICTNAME_EN": "Al Mithawia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2664",
    "DISTRICTNAME_AR": "الفايزية",
    "DISTRICTNAME_EN": "Al Fayziyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2665",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2666",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2667",
    "DISTRICTNAME_AR": "العمرة",
    "DISTRICTNAME_EN": "Al Umrah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2668",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2669",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2670",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2671",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2672",
    "DISTRICTNAME_AR": "الرواما",
    "DISTRICTNAME_EN": "Al Rawama",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2673",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2674",
    "DISTRICTNAME_AR": "الملز",
    "DISTRICTNAME_EN": "Al Malaz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2675",
    "DISTRICTNAME_AR": "ديراب",
    "DISTRICTNAME_EN": "Dirab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2676",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2677",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2678",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2679",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2681",
    "DISTRICTNAME_AR": "أم عنيق",
    "DISTRICTNAME_EN": "Umm Aneq",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2682",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2683",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2684",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2685",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2686",
    "DISTRICTNAME_AR": "أم سريحة",
    "DISTRICTNAME_EN": "Umm Sariha",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2687",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2688",
    "DISTRICTNAME_AR": "الدحو",
    "DISTRICTNAME_EN": "Al Dahou",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2689",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2690",
    "DISTRICTNAME_AR": "العوالي",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2691",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2692",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2693",
    "DISTRICTNAME_AR": "جبرة",
    "DISTRICTNAME_EN": "Jabrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2694",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "2695",
    "DISTRICTNAME_AR": "الحلة",
    "DISTRICTNAME_EN": "Al Hilah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2696",
    "DISTRICTNAME_AR": "اسفل الباطن الشمالي",
    "DISTRICTNAME_EN": "Asfil Al Batin North",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2697",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2699",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2700",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2701",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2702",
    "DISTRICTNAME_AR": "المصارير",
    "DISTRICTNAME_EN": "Al Masarir",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2703",
    "DISTRICTNAME_AR": "المعتلا",
    "DISTRICTNAME_EN": "Al Muetala",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2704",
    "DISTRICTNAME_AR": "مخطط 525",
    "DISTRICTNAME_EN": "Subdivision 525",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2705",
    "DISTRICTNAME_AR": "مخطط794",
    "DISTRICTNAME_EN": "Subdivision 794",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2706",
    "DISTRICTNAME_AR": "مخطط 433",
    "DISTRICTNAME_EN": "Subdivision 433",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2707",
    "DISTRICTNAME_AR": "مخطط 331",
    "DISTRICTNAME_EN": "Subdivision 331",
    "CITY_ID": "19931",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2708",
    "DISTRICTNAME_AR": "زياد",
    "DISTRICTNAME_EN": "Ziyad",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2709",
    "DISTRICTNAME_AR": "خضار",
    "DISTRICTNAME_EN": "Khudar",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2710",
    "DISTRICTNAME_AR": "الوزارات",
    "DISTRICTNAME_EN": "Al Wizarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2711",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2712",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2713",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2715",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2717",
    "DISTRICTNAME_AR": "المراجيم",
    "DISTRICTNAME_EN": "Al Marajim",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2718",
    "DISTRICTNAME_AR": "1029 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1029",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2719",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2720",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2721",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2722",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2723",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2724",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2725",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2726",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2727",
    "DISTRICTNAME_AR": "المدرسة العسكرية",
    "DISTRICTNAME_EN": "Military School",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2728",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2729",
    "DISTRICTNAME_AR": "مخطط 607",
    "DISTRICTNAME_EN": "Subdivision 607",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2730",
    "DISTRICTNAME_AR": "الفيضة 1 ",
    "DISTRICTNAME_EN": "Al Faydah 1",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2731",
    "DISTRICTNAME_AR": "مخطط رقم 653",
    "DISTRICTNAME_EN": "Subdivision No. 653",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2732",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2733",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2734",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2735",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2736",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2737",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2738",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2739",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2740",
    "DISTRICTNAME_AR": "الحزيمية",
    "DISTRICTNAME_EN": "Al Hazimia",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2741",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2742",
    "DISTRICTNAME_AR": "القرينة الجديدة",
    "DISTRICTNAME_EN": "Al Qarina Al Jadida",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2743",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2744",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2747",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2748",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2749",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2750",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2751",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2752",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2753",
    "DISTRICTNAME_AR": "1023 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1023",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2754",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2755",
    "DISTRICTNAME_AR": "مخطط رقم 1083",
    "DISTRICTNAME_EN": "Subdivision No. 1083",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2756",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2757",
    "DISTRICTNAME_AR": "1101 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1101",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2758",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2759",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2760",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2761",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2762",
    "DISTRICTNAME_AR": "الرين القديم",
    "DISTRICTNAME_EN": "Al Riyn Al Qadim",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2763",
    "DISTRICTNAME_AR": "الغزة الجديد",
    "DISTRICTNAME_EN": "Al Ghaza Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2764",
    "DISTRICTNAME_AR": "مخطط رقم 621",
    "DISTRICTNAME_EN": "Subdivision No. 621",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2765",
    "DISTRICTNAME_AR": "السلي",
    "DISTRICTNAME_EN": "Al Sulay",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2766",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2767",
    "DISTRICTNAME_AR": "العلا",
    "DISTRICTNAME_EN": "Al Ula",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2768",
    "DISTRICTNAME_AR": "النخبة",
    "DISTRICTNAME_EN": "Al Nakhbah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2769",
    "DISTRICTNAME_AR": "جفارة السعدان",
    "DISTRICTNAME_EN": "Jafara Al Saadan",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2770",
    "DISTRICTNAME_AR": "منطقة استراحات حمد الجبرين",
    "DISTRICTNAME_EN": "aistirahat Hamad Al Jibrin",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2771",
    "DISTRICTNAME_AR": "اليتيمة",
    "DISTRICTNAME_EN": "Al Yatimah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2772",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2773",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2774",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2775",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2776",
    "DISTRICTNAME_AR": "العذار",
    "DISTRICTNAME_EN": "Al Adhaar",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2777",
    "DISTRICTNAME_AR": "الحلة الطالعية",
    "DISTRICTNAME_EN": "Al Hilah Al Taalieia",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2778",
    "DISTRICTNAME_AR": "مخطط رقم 1039",
    "DISTRICTNAME_EN": "Subdivision No. 1039",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2779",
    "DISTRICTNAME_AR": "مخطط 749",
    "DISTRICTNAME_EN": "Subdivision 749",
    "CITY_ID": "20339",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2780",
    "DISTRICTNAME_AR": "الرماية",
    "DISTRICTNAME_EN": "Al Rimayah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2781",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2782",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2783",
    "DISTRICTNAME_AR": "الحائر",
    "DISTRICTNAME_EN": "Al Haer",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2784",
    "DISTRICTNAME_AR": "العمار",
    "DISTRICTNAME_EN": "Al Amaar",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2785",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2786",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2787",
    "DISTRICTNAME_AR": "النسيم الشرقي",
    "DISTRICTNAME_EN": "East Naseem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2788",
    "DISTRICTNAME_AR": "جامعة الإمام محمد بن سعود الإسلامية",
    "DISTRICTNAME_EN": "Imam Muhammed Bin Saud Islamic University",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2789",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "Al Rafaye",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2791",
    "DISTRICTNAME_AR": "اسفل الباطن",
    "DISTRICTNAME_EN": "Asfil Al Batin",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2792",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2793",
    "DISTRICTNAME_AR": "الباطن",
    "DISTRICTNAME_EN": "Al Batin",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2794",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2795",
    "DISTRICTNAME_AR": "665 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 665",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2796",
    "DISTRICTNAME_AR": "مخطط رقم 1033",
    "DISTRICTNAME_EN": "Subdivision No. 1033",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2797",
    "DISTRICTNAME_AR": "مخطط رقم 985",
    "DISTRICTNAME_EN": "Subdivision No. 985",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2798",
    "DISTRICTNAME_AR": "1077 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1077",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2799",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2800",
    "DISTRICTNAME_AR": "الحزيمية",
    "DISTRICTNAME_EN": "Al Huzaymiyyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2801",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2802",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2803",
    "DISTRICTNAME_AR": "العلب",
    "DISTRICTNAME_EN": "Al Olab",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2804",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2805",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2806",
    "DISTRICTNAME_AR": "مشيرفة",
    "DISTRICTNAME_EN": "Mishyrifah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2807",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "Al Arid",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2808",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2809",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2811",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2812",
    "DISTRICTNAME_AR": "ال هشام",
    "DISTRICTNAME_EN": "Al Hisham",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2813",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2815",
    "DISTRICTNAME_AR": "هجرة بوضة",
    "DISTRICTNAME_EN": "Hijrat Buda",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2816",
    "DISTRICTNAME_AR": "قعضبا",
    "DISTRICTNAME_EN": "Qaedaba",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2818",
    "DISTRICTNAME_AR": "مركز الشرارة",
    "DISTRICTNAME_EN": "Al Sharara Center",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2819",
    "DISTRICTNAME_AR": "الوقف",
    "DISTRICTNAME_EN": "Al Wakf",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2820",
    "DISTRICTNAME_AR": "الملك سعود",
    "DISTRICTNAME_EN": "King Saud",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2821",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2822",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2824",
    "DISTRICTNAME_AR": "الأمير سلطان بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince Sultan bin Abdulaziz",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2825",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2826",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2827",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2829",
    "DISTRICTNAME_AR": "الرمل",
    "DISTRICTNAME_EN": "Al Raml",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2830",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2831",
    "DISTRICTNAME_AR": "المدرجية",
    "DISTRICTNAME_EN": "Al Midrajia",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2832",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2833",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2834",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2835",
    "DISTRICTNAME_AR": "مخطط رقم 62",
    "DISTRICTNAME_EN": "Subdivision No. 62",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2837",
    "DISTRICTNAME_AR": "البديع الغربي",
    "DISTRICTNAME_EN": "Al Badi Al Gharbi",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2838",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2839",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2840",
    "DISTRICTNAME_AR": "الرجع",
    "DISTRICTNAME_EN": "Ar Raja",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2841",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2842",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2843",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2844",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2845",
    "DISTRICTNAME_AR": "بهجة",
    "DISTRICTNAME_EN": "Bahja",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2846",
    "DISTRICTNAME_AR": "التقنية",
    "DISTRICTNAME_EN": "Al Tiqniya",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2847",
    "DISTRICTNAME_AR": "الحنو",
    "DISTRICTNAME_EN": "Al Hunu",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2848",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2849",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2850",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2851",
    "DISTRICTNAME_AR": "العاصمة",
    "DISTRICTNAME_EN": "Al Asemah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2852",
    "DISTRICTNAME_AR": "حي ج19",
    "DISTRICTNAME_EN": "Dst C19",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2853",
    "DISTRICTNAME_AR": "أم سليم",
    "DISTRICTNAME_EN": "Umm Selim",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2854",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2855",
    "DISTRICTNAME_AR": "ال قاران",
    "DISTRICTNAME_EN": "Al Qaran",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2856",
    "DISTRICTNAME_AR": "الحلة",
    "DISTRICTNAME_EN": "Al Hilah",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2857",
    "DISTRICTNAME_AR": "ال ظافر",
    "DISTRICTNAME_EN": "Al Zafer",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2858",
    "DISTRICTNAME_AR": "التوباد",
    "DISTRICTNAME_EN": "Al Tuwbad",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2859",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2860",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2862",
    "DISTRICTNAME_AR": "حي ج16",
    "DISTRICTNAME_EN": "Dst C16",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2863",
    "DISTRICTNAME_AR": "الوداعين",
    "DISTRICTNAME_EN": "Al Wadaein",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2864",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2865",
    "DISTRICTNAME_AR": "ثليم",
    "DISTRICTNAME_EN": "Thulaim",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2866",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2867",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2868",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2869",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2870",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "As Sih",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2871",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2872",
    "DISTRICTNAME_AR": "الوسطى",
    "DISTRICTNAME_EN": "Al Wusta",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2873",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2874",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2875",
    "DISTRICTNAME_AR": "الوشم",
    "DISTRICTNAME_EN": "Al Washm",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2876",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2877",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2878",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2879",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2880",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2881",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2882",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2883",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2884",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2885",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2886",
    "DISTRICTNAME_AR": "الخرائق",
    "DISTRICTNAME_EN": "Al Kharayiq",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2887",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2888",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2889",
    "DISTRICTNAME_AR": "الأمير نايف",
    "DISTRICTNAME_EN": "Prince Naif",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2890",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2891",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2892",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2893",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2895",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2896",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2897",
    "DISTRICTNAME_AR": "الوسطة",
    "DISTRICTNAME_EN": "Al Wasata",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2898",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2899",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2900",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2901",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2902",
    "DISTRICTNAME_AR": "الرسالة",
    "DISTRICTNAME_EN": "Ar Risalah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2903",
    "DISTRICTNAME_AR": "مطار الملك خالد الدولي",
    "DISTRICTNAME_EN": "King Khalid International Airport",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2905",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2906",
    "DISTRICTNAME_AR": "وادي لبن - طويق",
    "DISTRICTNAME_EN": "Wadi Laban",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "2907",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2908",
    "DISTRICTNAME_AR": "المشاعل",
    "DISTRICTNAME_EN": "Al Mishael",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2909",
    "DISTRICTNAME_AR": "نمار",
    "DISTRICTNAME_EN": "Namar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2910",
    "DISTRICTNAME_AR": "عليشة",
    "DISTRICTNAME_EN": "Olaishah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2911",
    "DISTRICTNAME_AR": "السويدي الغربي",
    "DISTRICTNAME_EN": "Al Suwaidi Al Gharbi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2912",
    "DISTRICTNAME_AR": "العزيزية1",
    "DISTRICTNAME_EN": "Al Aziziyah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2913",
    "DISTRICTNAME_AR": "الأخضر",
    "DISTRICTNAME_EN": "Al Akhdar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2914",
    "DISTRICTNAME_AR": "البكر",
    "DISTRICTNAME_EN": "Al Bakr",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2915",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2916",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2917",
    "DISTRICTNAME_AR": "مخطط رقم 814",
    "DISTRICTNAME_EN": "Subdivision No. 814",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2918",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "Al Ammariyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2921",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2922",
    "DISTRICTNAME_AR": "المرجع",
    "DISTRICTNAME_EN": "Al Marja",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2923",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2924",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2925",
    "DISTRICTNAME_AR": "اسفل الباطن الجنوبي",
    "DISTRICTNAME_EN": "Asfil Al Batin Southern",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2926",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2928",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2929",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2930",
    "DISTRICTNAME_AR": "القصور",
    "DISTRICTNAME_EN": "Al Qusor",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2931",
    "DISTRICTNAME_AR": "الحزام الذهبي",
    "DISTRICTNAME_EN": "Al Hizam Adh Dhahabi",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2932",
    "DISTRICTNAME_AR": "ابن خلدون",
    "DISTRICTNAME_EN": "Ibn Khaldun",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2933",
    "DISTRICTNAME_AR": "الشراع",
    "DISTRICTNAME_EN": "Ash Sheraa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2934",
    "DISTRICTNAME_AR": "الصدفة",
    "DISTRICTNAME_EN": "As Sadafah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2935",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2936",
    "DISTRICTNAME_AR": "ال راشد وال شايع",
    "DISTRICTNAME_EN": "Al Rashid and Shaye",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2937",
    "DISTRICTNAME_AR": "سحاب",
    "DISTRICTNAME_EN": "Sahab",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2938",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2939",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2940",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20279",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2941",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2942",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2943",
    "DISTRICTNAME_AR": "الادارية غرب",
    "DISTRICTNAME_EN": "West Administrative",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2944",
    "DISTRICTNAME_AR": "ال وحيد",
    "DISTRICTNAME_EN": "Al Wahid",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2945",
    "DISTRICTNAME_AR": "القويز",
    "DISTRICTNAME_EN": "Al Quayz",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2946",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2947",
    "DISTRICTNAME_AR": "غسلة",
    "DISTRICTNAME_EN": "Gaslah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2948",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2949",
    "DISTRICTNAME_AR": "مخطط 144",
    "DISTRICTNAME_EN": "Subdivision 144",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2951",
    "DISTRICTNAME_AR": "مخطط فرعة الرميثي",
    "DISTRICTNAME_EN": "Fireat Al Rumaithi Subdivision",
    "CITY_ID": "20317",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2952",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2953",
    "DISTRICTNAME_AR": "قريوة",
    "DISTRICTNAME_EN": "Qaryuh",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2954",
    "DISTRICTNAME_AR": "العقيلة الجنوبية",
    "DISTRICTNAME_EN": "South Al Uqaila",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "2955",
    "DISTRICTNAME_AR": "العطيفة",
    "DISTRICTNAME_EN": "Al Utifa",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2956",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2958",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2959",
    "DISTRICTNAME_AR": "الفيحانية",
    "DISTRICTNAME_EN": "Al Fayhaniyah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2960",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2961",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20620",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2962",
    "DISTRICTNAME_AR": "مخطط المعلق",
    "DISTRICTNAME_EN": "Al Muealiq Subdivision",
    "CITY_ID": "20339",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2963",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2964",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2965",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2966",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2967",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2968",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "Al Arid",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2969",
    "DISTRICTNAME_AR": "مخطط رقم 822",
    "DISTRICTNAME_EN": "Subdivision No. 822",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2970",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2971",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2972",
    "DISTRICTNAME_AR": "القبالي",
    "DISTRICTNAME_EN": "Al Qabali",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2973",
    "DISTRICTNAME_AR": "المعتلا الجنوبى",
    "DISTRICTNAME_EN": "Southern Al Muetala",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2974",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2975",
    "DISTRICTNAME_AR": "1051 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1051",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2976",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2977",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2978",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2980",
    "DISTRICTNAME_AR": "مخطط 94",
    "DISTRICTNAME_EN": "Subdivision 94",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2981",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2982",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2983",
    "DISTRICTNAME_AR": "الياسمين - سدير",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21232",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "2984",
    "DISTRICTNAME_AR": "الاقرن",
    "DISTRICTNAME_EN": "Al Aqarn",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2985",
    "DISTRICTNAME_AR": "مخطط 315",
    "DISTRICTNAME_EN": "Subdivision 315",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2986",
    "DISTRICTNAME_AR": "حفيرة السبعة الشرقي",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "2987",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2988",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2990",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2991",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2992",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2993",
    "DISTRICTNAME_AR": "منطقة خدمات حكومية",
    "DISTRICTNAME_EN": "Government Services Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2994",
    "DISTRICTNAME_AR": "الحمر الجنوبي",
    "DISTRICTNAME_EN": "Al Humar Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2995",
    "DISTRICTNAME_AR": "السويق",
    "DISTRICTNAME_EN": "Al Sawaiq",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2996",
    "DISTRICTNAME_AR": "الدريدي",
    "DISTRICTNAME_EN": "Ad Duraydi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2997",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2998",
    "DISTRICTNAME_AR": "الاحتفالات",
    "DISTRICTNAME_EN": "Alaihtifalat",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2999",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3000",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3001",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3002",
    "DISTRICTNAME_AR": "بهجة",
    "DISTRICTNAME_EN": "Bahja",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3003",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3004",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3005",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3006",
    "DISTRICTNAME_AR": "الملك خالد",
    "DISTRICTNAME_EN": "King Khalid",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3007",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3008",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3009",
    "DISTRICTNAME_AR": "الحوطة",
    "DISTRICTNAME_EN": "Al Hawta",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3010",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3011",
    "DISTRICTNAME_AR": "السلمية",
    "DISTRICTNAME_EN": "Al Silmia",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3012",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3013",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3014",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3015",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "Al Rafaye",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3019",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3020",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3022",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3023",
    "DISTRICTNAME_AR": "العصيلى",
    "DISTRICTNAME_EN": "Al Osaili",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3024",
    "DISTRICTNAME_AR": "حي أ",
    "DISTRICTNAME_EN": "Dst A",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3025",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3026",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3027",
    "DISTRICTNAME_AR": "الإسكان الخيري",
    "DISTRICTNAME_EN": "Al Iskan Al Khairi",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3028",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3029",
    "DISTRICTNAME_AR": "سلاح الحدود",
    "DISTRICTNAME_EN": "Land of Frontier Weapon",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3030",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3031",
    "DISTRICTNAME_AR": "السمراء",
    "DISTRICTNAME_EN": "As Samra",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "3032",
    "DISTRICTNAME_AR": "مريفق",
    "DISTRICTNAME_EN": "Murifiq",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "3034",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3035",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3036",
    "DISTRICTNAME_AR": "التعليم",
    "DISTRICTNAME_EN": "At Taalim",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3037",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3038",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3039",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3040",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3041",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3042",
    "DISTRICTNAME_AR": "الجندل",
    "DISTRICTNAME_EN": "Al Jandal",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3043",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3044",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3045",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3046",
    "DISTRICTNAME_AR": "المطية",
    "DISTRICTNAME_EN": "Almatiuh",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3047",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3048",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3058",
    "DISTRICTNAME_AR": "مخطط الاراض البيضاء1",
    "DISTRICTNAME_EN": "White Land Organization Subdivision1",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3059",
    "DISTRICTNAME_AR": "مخطط الاراض البيضاء2",
    "DISTRICTNAME_EN": "White Land Organization Subdivision2",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3060",
    "DISTRICTNAME_AR": "مخطط الاراضي البيضاء3",
    "DISTRICTNAME_EN": "White Land Organization Subdivision3",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3062",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3063",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3064",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3065",
    "DISTRICTNAME_AR": "العتيبية",
    "DISTRICTNAME_EN": "Al Utaybiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3066",
    "DISTRICTNAME_AR": "وادي الرمة",
    "DISTRICTNAME_EN": "Wadi Alruma",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3067",
    "DISTRICTNAME_AR": "الشنانة الشمالية",
    "DISTRICTNAME_EN": "Alshannanuh Ash Shamaliyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3068",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3069",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3070",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3071",
    "DISTRICTNAME_AR": "العبيل",
    "DISTRICTNAME_EN": "Al Obayel",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3072",
    "DISTRICTNAME_AR": "الجريف",
    "DISTRICTNAME_EN": "Al Gareef",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3073",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3074",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3075",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3076",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3077",
    "DISTRICTNAME_AR": "الغربي",
    "DISTRICTNAME_EN": "Al Gharbi",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3078",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3079",
    "DISTRICTNAME_AR": "الشنانة الجنوبية",
    "DISTRICTNAME_EN": "Alshannanuh Al Janubiyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3080",
    "DISTRICTNAME_AR": "مناطق خاصة",
    "DISTRICTNAME_EN": "Private Areas",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3089",
    "DISTRICTNAME_AR": "البستان1",
    "DISTRICTNAME_EN": "Al Bustan1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3090",
    "DISTRICTNAME_AR": "النزهة2",
    "DISTRICTNAME_EN": "An Nuzhah2",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3091",
    "DISTRICTNAME_AR": "غرب العزيزية",
    "DISTRICTNAME_EN": "West Al Aziziyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3092",
    "DISTRICTNAME_AR": "الديرة1",
    "DISTRICTNAME_EN": "Ad Dirah1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3093",
    "DISTRICTNAME_AR": "الديرة2",
    "DISTRICTNAME_EN": "Ad Dirah2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3095",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3096",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3097",
    "DISTRICTNAME_AR": "زمزم",
    "DISTRICTNAME_EN": "Zamzam",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3098",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3100",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3101",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3102",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3103",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3104",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3105",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3106",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3107",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3108",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3110",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3114",
    "DISTRICTNAME_AR": "مخطط 820",
    "DISTRICTNAME_EN": "Subdivision 820",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3115",
    "DISTRICTNAME_AR": "مخطط 908",
    "DISTRICTNAME_EN": "Subdivision 908",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3116",
    "DISTRICTNAME_AR": "مخطط 740",
    "DISTRICTNAME_EN": "Subdivision 740",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3117",
    "DISTRICTNAME_AR": "مخطط 884",
    "DISTRICTNAME_EN": "Subdivision 884",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3118",
    "DISTRICTNAME_AR": "الملك سلمان1",
    "DISTRICTNAME_EN": "King Salman1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3119",
    "DISTRICTNAME_AR": "الديرة القديمة1",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3121",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3122",
    "DISTRICTNAME_AR": "المريكبات",
    "DISTRICTNAME_EN": "Al Muraikabat",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3124",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3125",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3126",
    "DISTRICTNAME_AR": "الدريهمية",
    "DISTRICTNAME_EN": "Al Duraihemiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3127",
    "DISTRICTNAME_AR": "مخطط رقم 4",
    "DISTRICTNAME_EN": "Subdivision No. 4",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3128",
    "DISTRICTNAME_AR": "مخطط رقم 133 ",
    "DISTRICTNAME_EN": "Refinement No. 133",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3129",
    "DISTRICTNAME_AR": "مخطط رقم 133 ",
    "DISTRICTNAME_EN": "Refinement No. 133",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3130",
    "DISTRICTNAME_AR": "مخطط 87",
    "DISTRICTNAME_EN": "Subdivision 87",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3131",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3132",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3133",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3134",
    "DISTRICTNAME_AR": "مخطط 886",
    "DISTRICTNAME_EN": "Subdivision 886",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3135",
    "DISTRICTNAME_AR": "مخطط 880",
    "DISTRICTNAME_EN": "Subdivision 880",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3136",
    "DISTRICTNAME_AR": "مخطط 906",
    "DISTRICTNAME_EN": "Subdivision 906",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3137",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3138",
    "DISTRICTNAME_AR": "مخطط رقم 144",
    "DISTRICTNAME_EN": "Subdivision No. 144",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3139",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3140",
    "DISTRICTNAME_AR": "مخطط رقم 210",
    "DISTRICTNAME_EN": "Subdivision No. 210",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3141",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3142",
    "DISTRICTNAME_AR": "الشبيلية2",
    "DISTRICTNAME_EN": "Ash Shabilia2",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3144",
    "DISTRICTNAME_AR": "الصدفة",
    "DISTRICTNAME_EN": "As Sadafah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3145",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3146",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3147",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3148",
    "DISTRICTNAME_AR": "العواش",
    "DISTRICTNAME_EN": "Al Awash",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3149",
    "DISTRICTNAME_AR": "حي المنطقة المركزية - حي مانح",
    "DISTRICTNAME_EN": "Central District",
    "CITY_ID": "20264",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3150",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3151",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3152",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3153",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3154",
    "DISTRICTNAME_AR": "الخالدية الشرقي",
    "DISTRICTNAME_EN": "Al Khalidiyah East",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3155",
    "DISTRICTNAME_AR": "المنطقة الصناعية1",
    "DISTRICTNAME_EN": "Industrial Area1",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3156",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3157",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3158",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3159",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3160",
    "DISTRICTNAME_AR": "الشمالي",
    "DISTRICTNAME_EN": "Ash Shamali",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3161",
    "DISTRICTNAME_AR": "المريديسية الجنوبية",
    "DISTRICTNAME_EN": "Al Muraydeesiyah Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3162",
    "DISTRICTNAME_AR": "البساتين الشرقي",
    "DISTRICTNAME_EN": "Al Basatin Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3163",
    "DISTRICTNAME_AR": "العريض",
    "DISTRICTNAME_EN": "Al Ariyd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3164",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3165",
    "DISTRICTNAME_AR": "هجر",
    "DISTRICTNAME_EN": "Hajr",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3166",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3167",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3168",
    "DISTRICTNAME_AR": "السفن",
    "DISTRICTNAME_EN": "As Sufun",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3170",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3171",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3172",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3173",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3174",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3175",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3176",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3177",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3178",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3179",
    "DISTRICTNAME_AR": "مخطط2242",
    "DISTRICTNAME_EN": "Organizing The Lands Located East of The Subdivision 242 Subdivision",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3180",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3181",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3182",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3183",
    "DISTRICTNAME_AR": "الحدود",
    "DISTRICTNAME_EN": "Al Hudud",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3184",
    "DISTRICTNAME_AR": "البدر",
    "DISTRICTNAME_EN": "Al Badr",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3185",
    "DISTRICTNAME_AR": "أجياد",
    "DISTRICTNAME_EN": "Ajyad",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3186",
    "DISTRICTNAME_AR": "باب الشمال",
    "DISTRICTNAME_EN": "Bab Madinah Ash Shamal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3187",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3188",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3189",
    "DISTRICTNAME_AR": "المعاقلة",
    "DISTRICTNAME_EN": "Al Muaqilah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3190",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3191",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3192",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3193",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3195",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3196",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3197",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3199",
    "DISTRICTNAME_AR": "الوقبة",
    "DISTRICTNAME_EN": "Al Waqba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3200",
    "DISTRICTNAME_AR": "مخطط الاراضي الحكومية2",
    "DISTRICTNAME_EN": "Government Lands Subdivision2",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3201",
    "DISTRICTNAME_AR": "الهرير الغربى",
    "DISTRICTNAME_EN": "Al Hurayr Al Ghurbi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3202",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3204",
    "DISTRICTNAME_AR": "المبرز",
    "DISTRICTNAME_EN": "Al Mubarraz",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3205",
    "DISTRICTNAME_AR": "عقرباء",
    "DISTRICTNAME_EN": "Aqraba'",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3206",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3207",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3208",
    "DISTRICTNAME_AR": "حي ج17",
    "DISTRICTNAME_EN": "Dst C17",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3210",
    "DISTRICTNAME_AR": "النهضة و بالشوك",
    "DISTRICTNAME_EN": "An Nahdah and Balshuwk",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3211",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3212",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3213",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3214",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3215",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3216",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3217",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3218",
    "DISTRICTNAME_AR": "سمحان",
    "DISTRICTNAME_EN": "Samhan",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3220",
    "DISTRICTNAME_AR": "مخطط 557",
    "DISTRICTNAME_EN": "Subdivision 557",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3221",
    "DISTRICTNAME_AR": "مخطط 415",
    "DISTRICTNAME_EN": "Subdivision 415",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3222",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3223",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3224",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3225",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3226",
    "DISTRICTNAME_AR": "الجردة",
    "DISTRICTNAME_EN": "Al Jardah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3227",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3228",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3229",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3230",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3231",
    "DISTRICTNAME_AR": "الرويساء",
    "DISTRICTNAME_EN": "Al Ruwaysa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3232",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3234",
    "DISTRICTNAME_AR": "الضبيعة",
    "DISTRICTNAME_EN": "Al Dabiea",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3235",
    "DISTRICTNAME_AR": "مخطط 923",
    "DISTRICTNAME_EN": "Subdivision 923",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3236",
    "DISTRICTNAME_AR": "مخطط 543",
    "DISTRICTNAME_EN": "Subdivision 543",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3237",
    "DISTRICTNAME_AR": "مخطط 476",
    "DISTRICTNAME_EN": "Subdivision 476",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3238",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3244",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3245",
    "DISTRICTNAME_AR": "القصر",
    "DISTRICTNAME_EN": "Al Qasr",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3246",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3247",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3248",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3249",
    "DISTRICTNAME_AR": "الباحة",
    "DISTRICTNAME_EN": "AL Baha",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3250",
    "DISTRICTNAME_AR": "1045 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1045",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3251",
    "DISTRICTNAME_AR": "لبب",
    "DISTRICTNAME_EN": "Lubib",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3252",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3253",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3254",
    "DISTRICTNAME_AR": "البرقة",
    "DISTRICTNAME_EN": "Al Barqah",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3255",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3256",
    "DISTRICTNAME_AR": "المعترضة",
    "DISTRICTNAME_EN": "Al Muutaridah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3257",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3259",
    "DISTRICTNAME_AR": "فرزان",
    "DISTRICTNAME_EN": "Farzan",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3260",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3267",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3268",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3269",
    "DISTRICTNAME_AR": "العريمضي الجنوبي",
    "DISTRICTNAME_EN": "Al Uraymidi Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3270",
    "DISTRICTNAME_AR": "عشران",
    "DISTRICTNAME_EN": "Eashran",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3271",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3272",
    "DISTRICTNAME_AR": "الجواهر",
    "DISTRICTNAME_EN": "Al Jawahir",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3273",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3274",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3275",
    "DISTRICTNAME_AR": "الناصفة",
    "DISTRICTNAME_EN": "An Nasifah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3277",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3278",
    "DISTRICTNAME_AR": "أم السلم",
    "DISTRICTNAME_EN": "Umm Asalam",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3279",
    "DISTRICTNAME_AR": "عبلية",
    "DISTRICTNAME_EN": "Eiblia",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3280",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3285",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3287",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3288",
    "DISTRICTNAME_AR": "السهباء",
    "DISTRICTNAME_EN": "Al Sahba",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3293",
    "DISTRICTNAME_AR": "العود",
    "DISTRICTNAME_EN": "Al Oud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3294",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3295",
    "DISTRICTNAME_AR": "الثريا 1",
    "DISTRICTNAME_EN": "Ath Thuraiya 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3296",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3297",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3298",
    "DISTRICTNAME_AR": "أم سرار",
    "DISTRICTNAME_EN": "Umm Srar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3299",
    "DISTRICTNAME_AR": "الضاحي الغربي",
    "DISTRICTNAME_EN": "Ad Dahi Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3300",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3302",
    "DISTRICTNAME_AR": "الطلعة",
    "DISTRICTNAME_EN": "At Talaah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3303",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3304",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3305",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3306",
    "DISTRICTNAME_AR": "مخطط رقم 928",
    "DISTRICTNAME_EN": "Subdivision No. 928",
    "CITY_ID": "21312",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3307",
    "DISTRICTNAME_AR": "آل عريمة",
    "DISTRICTNAME_EN": "Al Arima",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3308",
    "DISTRICTNAME_AR": "طويق ",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3309",
    "DISTRICTNAME_AR": "ال هذال",
    "DISTRICTNAME_EN": "Al Hadhal",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3310",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3311",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3313",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3314",
    "DISTRICTNAME_AR": "العفجه",
    "DISTRICTNAME_EN": "Al Afajh",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3315",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3317",
    "DISTRICTNAME_AR": "الناصفة",
    "DISTRICTNAME_EN": "An Nasifah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3324",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3325",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3326",
    "DISTRICTNAME_AR": "المليليح",
    "DISTRICTNAME_EN": "Al Mulialih",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3327",
    "DISTRICTNAME_AR": "النصبة",
    "DISTRICTNAME_EN": "An Nasbah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3328",
    "DISTRICTNAME_AR": "الفرعة",
    "DISTRICTNAME_EN": "Al Farah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3329",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3330",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3331",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3332",
    "DISTRICTNAME_AR": "الجمالين",
    "DISTRICTNAME_EN": "Jamalin",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3333",
    "DISTRICTNAME_AR": "مخطط رقم 359",
    "DISTRICTNAME_EN": "Subdivision No. 359",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3334",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3335",
    "DISTRICTNAME_AR": "الاصفير",
    "DISTRICTNAME_EN": "Al Usfayr",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3338",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3340",
    "DISTRICTNAME_AR": "العدامة",
    "DISTRICTNAME_EN": "Al Adamah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3343",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3344",
    "DISTRICTNAME_AR": "العالية",
    "DISTRICTNAME_EN": "Al Aliyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3347",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3348",
    "DISTRICTNAME_AR": "العوالى",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3349",
    "DISTRICTNAME_AR": "ال قيشه ",
    "DISTRICTNAME_EN": "Al Qayshah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3350",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3351",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3352",
    "DISTRICTNAME_AR": "جامعة جازان",
    "DISTRICTNAME_EN": "Gazan University",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3353",
    "DISTRICTNAME_AR": "الخالدية 1",
    "DISTRICTNAME_EN": "Al Khalidiyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3355",
    "DISTRICTNAME_AR": "البديع الشرقى",
    "DISTRICTNAME_EN": "Al Badi Al Sharqi",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3356",
    "DISTRICTNAME_AR": "قرية بديدة علو غثاة",
    "DISTRICTNAME_EN": "Village Badida Ealu Ghuthatan",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3357",
    "DISTRICTNAME_AR": "كميت",
    "DISTRICTNAME_EN": "Kumet",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3358",
    "DISTRICTNAME_AR": "الربعة",
    "DISTRICTNAME_EN": "Al Rubea",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3359",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3360",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3361",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3362",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3363",
    "DISTRICTNAME_AR": "سلام",
    "DISTRICTNAME_EN": "Salam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3364",
    "DISTRICTNAME_AR": "المعذر الشمالي",
    "DISTRICTNAME_EN": "North Mathar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3365",
    "DISTRICTNAME_AR": "المدينة الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial City",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3366",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3367",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3368",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3369",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3370",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3371",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3372",
    "DISTRICTNAME_AR": "شخيب",
    "DISTRICTNAME_EN": "Shukhib",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3373",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3374",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3375",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3376",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3377",
    "DISTRICTNAME_AR": "مخطط 590",
    "DISTRICTNAME_EN": "Subdivision 590",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3378",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3379",
    "DISTRICTNAME_AR": "جفارة الجبيري",
    "DISTRICTNAME_EN": "Jfara Al Jubeiry",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3380",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3381",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3382",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3383",
    "DISTRICTNAME_AR": "البقاع",
    "DISTRICTNAME_EN": "Al Biqa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3384",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3385",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3386",
    "DISTRICTNAME_AR": "مخطط 907 مشرفة",
    "DISTRICTNAME_EN": "Subdivision 907 Musharafa",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3387",
    "DISTRICTNAME_AR": "روافة",
    "DISTRICTNAME_EN": "Rawafa",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3388",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3389",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3390",
    "DISTRICTNAME_AR": "الضباط",
    "DISTRICTNAME_EN": "Al Dhubbat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3391",
    "DISTRICTNAME_AR": "السبخة",
    "DISTRICTNAME_EN": "Al Sabakhah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3392",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3393",
    "DISTRICTNAME_AR": "الخليفة",
    "DISTRICTNAME_EN": "Al Khalifah",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3394",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3395",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3396",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3397",
    "DISTRICTNAME_AR": "517 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 517",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3398",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3399",
    "DISTRICTNAME_AR": "الريعانية",
    "DISTRICTNAME_EN": "Al Ra'aniyah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3400",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3402",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3403",
    "DISTRICTNAME_AR": "المستشفى الجامعي",
    "DISTRICTNAME_EN": "University Hospital",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3404",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3405",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3406",
    "DISTRICTNAME_AR": "مصدة",
    "DISTRICTNAME_EN": "Masida",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3407",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3408",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3409",
    "DISTRICTNAME_AR": "البليدا",
    "DISTRICTNAME_EN": "Al Blaida",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3410",
    "DISTRICTNAME_AR": "الرديفة",
    "DISTRICTNAME_EN": "Ar Radifah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3411",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3412",
    "DISTRICTNAME_AR": "الشتينية",
    "DISTRICTNAME_EN": "Al Shatinia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3413",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3414",
    "DISTRICTNAME_AR": "العلوات",
    "DISTRICTNAME_EN": "Al Ealawat",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3415",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3416",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3417",
    "DISTRICTNAME_AR": "الدرع",
    "DISTRICTNAME_EN": "Ad Dara",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3419",
    "DISTRICTNAME_AR": "القيسية",
    "DISTRICTNAME_EN": "Al Qaisiya",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3420",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3421",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3422",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3423",
    "DISTRICTNAME_AR": "الحديدية",
    "DISTRICTNAME_EN": "Al Hadidia",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3424",
    "DISTRICTNAME_AR": "أبو سليم",
    "DISTRICTNAME_EN": "Abu Sleem",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3425",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3426",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3427",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3428",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3429",
    "DISTRICTNAME_AR": "صناعية الحديدية",
    "DISTRICTNAME_EN": "Sinaiyah Al Hadidia",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3430",
    "DISTRICTNAME_AR": "الركية",
    "DISTRICTNAME_EN": "Ar Rukiyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3431",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3432",
    "DISTRICTNAME_AR": "سوق الماشية1",
    "DISTRICTNAME_EN": "Suq Al Mashiya1",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3433",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3434",
    "DISTRICTNAME_AR": "العماجية",
    "DISTRICTNAME_EN": "Al Ammajiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3435",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3436",
    "DISTRICTNAME_AR": "أم سليم",
    "DISTRICTNAME_EN": "Umm Selim",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3437",
    "DISTRICTNAME_AR": "الجزر",
    "DISTRICTNAME_EN": "Al Jazur",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3438",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3439",
    "DISTRICTNAME_AR": "نتيقة",
    "DISTRICTNAME_EN": "Netica",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3440",
    "DISTRICTNAME_AR": "وسيلة",
    "DISTRICTNAME_EN": "Wasila",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3441",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3442",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3443",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3444",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3445",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3446",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3447",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3448",
    "DISTRICTNAME_AR": "المربع",
    "DISTRICTNAME_EN": "Al Murabba",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3449",
    "DISTRICTNAME_AR": "مخطط الفيضة القديم",
    "DISTRICTNAME_EN": "Al Faydat Al Qadim Subdivision",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3450",
    "DISTRICTNAME_AR": "مخطط 423",
    "DISTRICTNAME_EN": "Subdivision 423",
    "CITY_ID": "19939",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3451",
    "DISTRICTNAME_AR": "مخطط 303",
    "DISTRICTNAME_EN": "Subdivision 303",
    "CITY_ID": "19913",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3452",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3453",
    "DISTRICTNAME_AR": "واسط",
    "DISTRICTNAME_EN": "Wasit",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3454",
    "DISTRICTNAME_AR": "العريجاء الغربي",
    "DISTRICTNAME_EN": "West Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3455",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3456",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3457",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3458",
    "DISTRICTNAME_AR": "الوشام",
    "DISTRICTNAME_EN": "Al Wisham",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3459",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3460",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3461",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3462",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3463",
    "DISTRICTNAME_AR": "القارة",
    "DISTRICTNAME_EN": "Al Qarah",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3464",
    "DISTRICTNAME_AR": "البوطة",
    "DISTRICTNAME_EN": "Al Butah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3465",
    "DISTRICTNAME_AR": "ظهره العودة غرب",
    "DISTRICTNAME_EN": "Dhahrat Al Awdat Gharb",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3466",
    "DISTRICTNAME_AR": "الصايغية",
    "DISTRICTNAME_EN": "Al Saayghia",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3467",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3468",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3469",
    "DISTRICTNAME_AR": "مخطط رقم 1067",
    "DISTRICTNAME_EN": "Subdivision No. 1067",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3470",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3471",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3472",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3474",
    "DISTRICTNAME_AR": "القسيمة",
    "DISTRICTNAME_EN": "Al Qasima",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3475",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3476",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3477",
    "DISTRICTNAME_AR": "مخطط 144",
    "DISTRICTNAME_EN": "Subdivision 144",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3478",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3479",
    "DISTRICTNAME_AR": "الفيضة",
    "DISTRICTNAME_EN": "Al Faydah ",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3480",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3481",
    "DISTRICTNAME_AR": "عشيرة",
    "DISTRICTNAME_EN": "Ushayra",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3482",
    "DISTRICTNAME_AR": "مخطط 739",
    "DISTRICTNAME_EN": "Subdivision 739",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3483",
    "DISTRICTNAME_AR": "مخطط541",
    "DISTRICTNAME_EN": "Subdivision 541",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3484",
    "DISTRICTNAME_AR": "رواق الشرقي",
    "DISTRICTNAME_EN": "Rawaq Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3485",
    "DISTRICTNAME_AR": "مخطط رقم 1111",
    "DISTRICTNAME_EN": "Subdivision No. 1111",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3486",
    "DISTRICTNAME_AR": "حي ج18",
    "DISTRICTNAME_EN": "Dst C18",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3487",
    "DISTRICTNAME_AR": "الطريف",
    "DISTRICTNAME_EN": "Al Turaif",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3488",
    "DISTRICTNAME_AR": "الثليما",
    "DISTRICTNAME_EN": "Al Thilaima ",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3489",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3490",
    "DISTRICTNAME_AR": "الغطغط",
    "DISTRICTNAME_EN": "Al Ghataght",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3491",
    "DISTRICTNAME_AR": "حضار",
    "DISTRICTNAME_EN": "Hadar",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3492",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3493",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3494",
    "DISTRICTNAME_AR": "صقرة الشمالي",
    "DISTRICTNAME_EN": "Saqra Al Shamali",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3496",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3497",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3498",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3499",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3500",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3501",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3502",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3503",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3504",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3505",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3506",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3507",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3508",
    "DISTRICTNAME_AR": "الشرمان",
    "DISTRICTNAME_EN": "Al Sharman",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3509",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3510",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3511",
    "DISTRICTNAME_AR": "الحرمين",
    "DISTRICTNAME_EN": "Al Haramain",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3512",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3513",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3514",
    "DISTRICTNAME_AR": "النويعمة",
    "DISTRICTNAME_EN": "Al Nowema",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3515",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3516",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3517",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3518",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3519",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3520",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3521",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3522",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3523",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3524",
    "DISTRICTNAME_AR": "الشفاء - القارة",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3525",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3526",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3527",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3528",
    "DISTRICTNAME_AR": "السويدي",
    "DISTRICTNAME_EN": "Al Suwaidi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3529",
    "DISTRICTNAME_AR": "المرسى",
    "DISTRICTNAME_EN": "Al Marsaa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3530",
    "DISTRICTNAME_AR": "ينبع البلد",
    "DISTRICTNAME_EN": "Yanbu Al Balad",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3531",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3532",
    "DISTRICTNAME_AR": "السلام ",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3533",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3535",
    "DISTRICTNAME_AR": "مخطط رقم 603",
    "DISTRICTNAME_EN": "Subdivision No. 603",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3536",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3537",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3538",
    "DISTRICTNAME_AR": "الراشدية",
    "DISTRICTNAME_EN": "Ar Rashidyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3539",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3540",
    "DISTRICTNAME_AR": "المسلخ",
    "DISTRICTNAME_EN": "Al Maslakh",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3541",
    "DISTRICTNAME_AR": "الدار البيضاء",
    "DISTRICTNAME_EN": "Al Dar Al Baida",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3542",
    "DISTRICTNAME_AR": "الفرعة",
    "DISTRICTNAME_EN": "Al Farah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3543",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3544",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3545",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3546",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3547",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3548",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3549",
    "DISTRICTNAME_AR": "البيان",
    "DISTRICTNAME_EN": "Al Bayan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3550",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3551",
    "DISTRICTNAME_AR": "ظهرة لبن",
    "DISTRICTNAME_EN": "Dhahrat Laban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3552",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3553",
    "DISTRICTNAME_AR": "المزرعة",
    "DISTRICTNAME_EN": "Al Mazraa",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3554",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3555",
    "DISTRICTNAME_AR": "مخطط رقم 946",
    "DISTRICTNAME_EN": "Subdivision No. 946",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3556",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3557",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3558",
    "DISTRICTNAME_AR": "1النخيل",
    "DISTRICTNAME_EN": "Al Nakhil1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3559",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3560",
    "DISTRICTNAME_AR": "القرينة القديمة",
    "DISTRICTNAME_EN": "Al Qarina Al Qadima",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3561",
    "DISTRICTNAME_AR": "الاذاعة",
    "DISTRICTNAME_EN": "Al Idhaah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3562",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3563",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3564",
    "DISTRICTNAME_AR": "مخطط الاشعرية",
    "DISTRICTNAME_EN": "Ash'ari Subdivision",
    "CITY_ID": "20334",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3565",
    "DISTRICTNAME_AR": "مخطط الرميثي",
    "DISTRICTNAME_EN": "Al Rumaythi Subdivision",
    "CITY_ID": "20317",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3566",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3567",
    "DISTRICTNAME_AR": "البطين",
    "DISTRICTNAME_EN": "Al Batiyn",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3568",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3569",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3570",
    "DISTRICTNAME_AR": "سدير",
    "DISTRICTNAME_EN": "Sudayr",
    "CITY_ID": "21232",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3571",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3572",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3573",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3576",
    "DISTRICTNAME_AR": "العلاوة",
    "DISTRICTNAME_EN": "Al Allawah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3577",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3578",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3579",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3580",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3581",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3582",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3583",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3584",
    "DISTRICTNAME_AR": "سمنان",
    "DISTRICTNAME_EN": "Semnan",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3585",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3586",
    "DISTRICTNAME_AR": "مخطط رقم 1037",
    "DISTRICTNAME_EN": "Subdivision No. 1037",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3587",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3588",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3589",
    "DISTRICTNAME_AR": "الهده",
    "DISTRICTNAME_EN": "Al Hadah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3590",
    "DISTRICTNAME_AR": "ال وقيان",
    "DISTRICTNAME_EN": "Al Waqian",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3591",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3592",
    "DISTRICTNAME_AR": "الصقورية",
    "DISTRICTNAME_EN": "As Suquriyyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3593",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3594",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3595",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3596",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3597",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3598",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3599",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3600",
    "DISTRICTNAME_AR": "العثمانية",
    "DISTRICTNAME_EN": "Al Uthmaniyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3601",
    "DISTRICTNAME_AR": "غرب النابية",
    "DISTRICTNAME_EN": "Gharb An Nabiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3602",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3603",
    "DISTRICTNAME_AR": "ام طلحة",
    "DISTRICTNAME_EN": "Umm Talha",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3604",
    "DISTRICTNAME_AR": "مخطط رقم 32",
    "DISTRICTNAME_EN": "Subdivision No. 32",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3605",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3606",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3607",
    "DISTRICTNAME_AR": "الخزيمي",
    "DISTRICTNAME_EN": "Al Khuzimi",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3608",
    "DISTRICTNAME_AR": "غصيبة",
    "DISTRICTNAME_EN": "Ghusaiba",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3609",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3610",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3611",
    "DISTRICTNAME_AR": "أم سريحة",
    "DISTRICTNAME_EN": "Umm Sariha",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3612",
    "DISTRICTNAME_AR": "الجنوبي",
    "DISTRICTNAME_EN": "Al Janubi",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3613",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3614",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3615",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3616",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3617",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3618",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3619",
    "DISTRICTNAME_AR": "الزيتونة",
    "DISTRICTNAME_EN": "Al Zaytunah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3620",
    "DISTRICTNAME_AR": "السدرة",
    "DISTRICTNAME_EN": "Al Sidrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3621",
    "DISTRICTNAME_AR": "شبرا",
    "DISTRICTNAME_EN": "Shubra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3622",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3623",
    "DISTRICTNAME_AR": "صياح",
    "DISTRICTNAME_EN": "Siyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3624",
    "DISTRICTNAME_AR": "العمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3625",
    "DISTRICTNAME_AR": "المؤتمرات",
    "DISTRICTNAME_EN": "Al Mutamarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3626",
    "DISTRICTNAME_AR": "العريجاء الأوسط",
    "DISTRICTNAME_EN": "Middle Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3627",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3628",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3629",
    "DISTRICTNAME_AR": "المهدية",
    "DISTRICTNAME_EN": "Al Mahdiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3630",
    "DISTRICTNAME_AR": "مخطط رقم 456",
    "DISTRICTNAME_EN": "Subdivision No. 456",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3631",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3632",
    "DISTRICTNAME_AR": "مخطط رقم 0941",
    "DISTRICTNAME_EN": "Subdivision No. 0941",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3633",
    "DISTRICTNAME_AR": "مخطط رقم 0824",
    "DISTRICTNAME_EN": "Subdivision No. 0824",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3634",
    "DISTRICTNAME_AR": "مخطط رقم 126",
    "DISTRICTNAME_EN": "Subdivision No. 126",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3635",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3636",
    "DISTRICTNAME_AR": "الشعيفان",
    "DISTRICTNAME_EN": "Al Shaaifan",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3637",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3638",
    "DISTRICTNAME_AR": "خشم العان",
    "DISTRICTNAME_EN": "Khashm Al An",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3639",
    "DISTRICTNAME_AR": "هيت",
    "DISTRICTNAME_EN": "Hyt",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3640",
    "DISTRICTNAME_AR": "الضبية",
    "DISTRICTNAME_EN": "Al Dabih",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3641",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3642",
    "DISTRICTNAME_AR": "البرية",
    "DISTRICTNAME_EN": "Al Bariyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3643",
    "DISTRICTNAME_AR": "السرحية",
    "DISTRICTNAME_EN": "Al Sarhiyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3644",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "Al Malqa",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3645",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3646",
    "DISTRICTNAME_AR": "الخماسين الجنوبي",
    "DISTRICTNAME_EN": "Southern Fifties",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3647",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3648",
    "DISTRICTNAME_AR": "آل ناهش",
    "DISTRICTNAME_EN": "Al Nahsh",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3649",
    "DISTRICTNAME_AR": "أم الحمام الشرقي",
    "DISTRICTNAME_EN": "Umm Al Hamam Eastern",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3650",
    "DISTRICTNAME_AR": "مغرزات",
    "DISTRICTNAME_EN": "Al Mughrazat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3651",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3652",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3653",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3654",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3655",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3656",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3657",
    "DISTRICTNAME_AR": "ال فهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3658",
    "DISTRICTNAME_AR": "مخطط رقم 18",
    "DISTRICTNAME_EN": "Subdivision No. 18",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3659",
    "DISTRICTNAME_AR": "حي 1156",
    "DISTRICTNAME_EN": "Dst 1156",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3660",
    "DISTRICTNAME_AR": "مخطط 555",
    "DISTRICTNAME_EN": "Subdivision 555",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3661",
    "DISTRICTNAME_AR": "العمدة",
    "DISTRICTNAME_EN": "Al Umda",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3662",
    "DISTRICTNAME_AR": "صناعية مزعل",
    "DISTRICTNAME_EN": "Sinaiyah Mazal",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3663",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3664",
    "DISTRICTNAME_AR": "رميثان",
    "DISTRICTNAME_EN": "Rumaythan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3665",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3666",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3667",
    "DISTRICTNAME_AR": "الهفوف",
    "DISTRICTNAME_EN": "Al Hafuf",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3668",
    "DISTRICTNAME_AR": "الفحيل",
    "DISTRICTNAME_EN": "Al Fahil",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3669",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3670",
    "DISTRICTNAME_AR": "آل معني",
    "DISTRICTNAME_EN": "Al Maeni",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3671",
    "DISTRICTNAME_AR": "مخطط 1149",
    "DISTRICTNAME_EN": "Subdivision 1149",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3674",
    "DISTRICTNAME_AR": "مخطط منيفة",
    "DISTRICTNAME_EN": "Munifa Subdivision",
    "CITY_ID": "20740",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3675",
    "DISTRICTNAME_AR": "الجفونية",
    "DISTRICTNAME_EN": "Al Jaffuniyyah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3676",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3678",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3680",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3681",
    "DISTRICTNAME_AR": "زبدة",
    "DISTRICTNAME_EN": "Zubada",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3682",
    "DISTRICTNAME_AR": "جامعة الملك سعود",
    "DISTRICTNAME_EN": "King Saud University",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3683",
    "DISTRICTNAME_AR": "مخطط رقم 24",
    "DISTRICTNAME_EN": "Subdivision No. 24",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3684",
    "DISTRICTNAME_AR": "مخطط آل سلطان",
    "DISTRICTNAME_EN": "Subdivision Al Sultan",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3685",
    "DISTRICTNAME_AR": "مانح",
    "DISTRICTNAME_EN": "Manih",
    "CITY_ID": "20264",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3686",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3687",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3688",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3689",
    "DISTRICTNAME_AR": "مخطط رقم 956",
    "DISTRICTNAME_EN": "Subdivision No. 956",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3690",
    "DISTRICTNAME_AR": "أم الشعال",
    "DISTRICTNAME_EN": "Umm Al Shaeal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3691",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3692",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3693",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3694",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3695",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3696",
    "DISTRICTNAME_AR": "ال دحيم",
    "DISTRICTNAME_EN": "Al Dahim",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3697",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3698",
    "DISTRICTNAME_AR": "ضاحية نمار",
    "DISTRICTNAME_EN": "Dahiyat Namar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3699",
    "DISTRICTNAME_AR": "عريض",
    "DISTRICTNAME_EN": "Oraid",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3700",
    "DISTRICTNAME_AR": "الولامين",
    "DISTRICTNAME_EN": "Al Walamin",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3701",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3702",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3703",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3704",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3706",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3707",
    "DISTRICTNAME_AR": "المصفاة",
    "DISTRICTNAME_EN": "Al Misfat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3708",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3709",
    "DISTRICTNAME_AR": "المقابل",
    "DISTRICTNAME_EN": "Al Muqabil",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3710",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3711",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3712",
    "DISTRICTNAME_AR": "الازهار",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3713",
    "DISTRICTNAME_AR": "الشعيبة",
    "DISTRICTNAME_EN": "Al Shueayba",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3714",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3715",
    "DISTRICTNAME_AR": "سلام",
    "DISTRICTNAME_EN": "Salam",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3716",
    "DISTRICTNAME_AR": "الوصيل",
    "DISTRICTNAME_EN": "Al Wasil",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3717",
    "DISTRICTNAME_AR": "مخطط رقم 571 - مخطط رقم 928",
    "DISTRICTNAME_EN": "Subdivision No. 571",
    "CITY_ID": "21312",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3718",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3719",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3720",
    "DISTRICTNAME_AR": "الخثلان",
    "DISTRICTNAME_EN": "Al Khathlan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3721",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3722",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3723",
    "DISTRICTNAME_AR": "مخطط 679",
    "DISTRICTNAME_EN": "Subdivision 679",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3724",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3725",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3727",
    "DISTRICTNAME_AR": "الطرف",
    "DISTRICTNAME_EN": "Al Taraf",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3728",
    "DISTRICTNAME_AR": "مخطط رقم 30",
    "DISTRICTNAME_EN": "Subdivision No. 30",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3729",
    "DISTRICTNAME_AR": "مخطط رقم 242",
    "DISTRICTNAME_EN": "Subdivision No. 242",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3730",
    "DISTRICTNAME_AR": "مخطط رقم 214",
    "DISTRICTNAME_EN": "Subdivision No. 214",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3731",
    "DISTRICTNAME_AR": "مشيرفة",
    "DISTRICTNAME_EN": "Mishyrifah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3732",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3733",
    "DISTRICTNAME_AR": "الصدر",
    "DISTRICTNAME_EN": "Al Sadr",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3734",
    "DISTRICTNAME_AR": "مخطط رقم 333",
    "DISTRICTNAME_EN": "Subdivision No. 333",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3735",
    "DISTRICTNAME_AR": "الغريبية",
    "DISTRICTNAME_EN": "Al Gharibia",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3736",
    "DISTRICTNAME_AR": "النقيعه",
    "DISTRICTNAME_EN": "Al Naqieuh",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3737",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3738",
    "DISTRICTNAME_AR": "السمرية",
    "DISTRICTNAME_EN": "Al Suwmaria",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3740",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3741",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3742",
    "DISTRICTNAME_AR": "مطار الأمير سلطان بن عبدالعزيز الدولى",
    "DISTRICTNAME_EN": "Prince Sultan bin Abdulaziz International Airport",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3743",
    "DISTRICTNAME_AR": "الغراء",
    "DISTRICTNAME_EN": "Al Gharaa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3744",
    "DISTRICTNAME_AR": "الرمانة",
    "DISTRICTNAME_EN": "Ar Rumanah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3745",
    "DISTRICTNAME_AR": "التلعة",
    "DISTRICTNAME_EN": "Al Taliea",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3746",
    "DISTRICTNAME_AR": "الغابة",
    "DISTRICTNAME_EN": "Al Ghabah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3747",
    "DISTRICTNAME_AR": "القبلتين",
    "DISTRICTNAME_EN": "Al Qiblatayn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3748",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3749",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3750",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3751",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3752",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3753",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3754",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3755",
    "DISTRICTNAME_AR": "الحرم ",
    "DISTRICTNAME_EN": "Al Haram",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3756",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3757",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3758",
    "DISTRICTNAME_AR": "الأشرفية",
    "DISTRICTNAME_EN": "Alashrafia",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3759",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3760",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3761",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3762",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3763",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3764",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3765",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3766",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3767",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3768",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3769",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3770",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3771",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3772",
    "DISTRICTNAME_AR": "سماح",
    "DISTRICTNAME_EN": "Samah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3773",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3774",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3775",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3776",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3777",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3778",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3779",
    "DISTRICTNAME_AR": "الملك خالد",
    "DISTRICTNAME_EN": "King Khalid",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3780",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3781",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3782",
    "DISTRICTNAME_AR": "الجلدة",
    "DISTRICTNAME_EN": "Aj Jilda",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3783",
    "DISTRICTNAME_AR": "الجامعي",
    "DISTRICTNAME_EN": "Al Jamie",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3784",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3785",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3786",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3787",
    "DISTRICTNAME_AR": "شراف",
    "DISTRICTNAME_EN": "Sharaf",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3788",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3789",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3790",
    "DISTRICTNAME_AR": "الرياضي",
    "DISTRICTNAME_EN": "Sports",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3791",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3792",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3793",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3794",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3795",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3796",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3797",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3799",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3800",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3802",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3803",
    "DISTRICTNAME_AR": "المدينة الجديدة",
    "DISTRICTNAME_EN": "Al Madinah Al Jadidah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3804",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3805",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3806",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3807",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3808",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3809",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3814",
    "DISTRICTNAME_AR": "الحنابجة",
    "DISTRICTNAME_EN": "Al Hanabija",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3815",
    "DISTRICTNAME_AR": "ظهرة البديعة",
    "DISTRICTNAME_EN": "Dhahrat Al Badeah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3816",
    "DISTRICTNAME_AR": "الهدية",
    "DISTRICTNAME_EN": "Al Hadia",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3817",
    "DISTRICTNAME_AR": "زبيدة",
    "DISTRICTNAME_EN": "Zubaydah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3818",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3819",
    "DISTRICTNAME_AR": "النفيد",
    "DISTRICTNAME_EN": "Al Nafid",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3820",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3821",
    "DISTRICTNAME_AR": "الشمالية",
    "DISTRICTNAME_EN": "Ash Shamaliyah",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3823",
    "DISTRICTNAME_AR": "العقيشية",
    "DISTRICTNAME_EN": "Al Aqishiya",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3824",
    "DISTRICTNAME_AR": "مخطط رقم 995",
    "DISTRICTNAME_EN": "Subdivision No. 995",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3825",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3826",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "Al Safra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3827",
    "DISTRICTNAME_AR": "البويطن",
    "DISTRICTNAME_EN": "Al Buaiten",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3829",
    "DISTRICTNAME_AR": "الملك سلمان بن عبد العزيز",
    "DISTRICTNAME_EN": "King Salman bin Abdulaziz",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3830",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3831",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3832",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3833",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3834",
    "DISTRICTNAME_AR": "الشميسي",
    "DISTRICTNAME_EN": "Al Shumaisi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3835",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3836",
    "DISTRICTNAME_AR": "الفوطة",
    "DISTRICTNAME_EN": "Al Futah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3837",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3838",
    "DISTRICTNAME_AR": "الوهلان",
    "DISTRICTNAME_EN": "Al Wahlan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3839",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3840",
    "DISTRICTNAME_AR": "العونية",
    "DISTRICTNAME_EN": "Al Awniyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3841",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3842",
    "DISTRICTNAME_AR": "مخطط رقم 198",
    "DISTRICTNAME_EN": "Subdivision No. 198",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3843",
    "DISTRICTNAME_AR": "الحاجب",
    "DISTRICTNAME_EN": "Al-Hajib",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3844",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3845",
    "DISTRICTNAME_AR": "الرايسية",
    "DISTRICTNAME_EN": "Ar Raaysia",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3846",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3847",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3848",
    "DISTRICTNAME_AR": "سنام",
    "DISTRICTNAME_EN": "Sonam",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3849",
    "DISTRICTNAME_AR": "الحفيرة",
    "DISTRICTNAME_EN": "Al Hufayrah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3850",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3851",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3852",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3853",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3854",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3855",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3856",
    "DISTRICTNAME_AR": "البدرية",
    "DISTRICTNAME_EN": "Al Badriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3857",
    "DISTRICTNAME_AR": "بلدة القارة",
    "DISTRICTNAME_EN": "Al Qara Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3858",
    "DISTRICTNAME_AR": "قباء",
    "DISTRICTNAME_EN": "Quba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3859",
    "DISTRICTNAME_AR": "الرقيقة",
    "DISTRICTNAME_EN": "Ar Ruqaiqah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3860",
    "DISTRICTNAME_AR": "العليا1",
    "DISTRICTNAME_EN": "Al Olaya1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3861",
    "DISTRICTNAME_AR": "البستان1",
    "DISTRICTNAME_EN": "Al Bustan1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3862",
    "DISTRICTNAME_AR": "قرية العمران الغربية",
    "DISTRICTNAME_EN": "Qaryat Al Omran West",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3865",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3866",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3870",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3871",
    "DISTRICTNAME_AR": "الصفاة",
    "DISTRICTNAME_EN": "Al Safat",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3872",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3878",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3887",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3888",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3889",
    "DISTRICTNAME_AR": "الطوية",
    "DISTRICTNAME_EN": "Al Tawyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3893",
    "DISTRICTNAME_AR": "المرقاب",
    "DISTRICTNAME_EN": "Al Merqab",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3894",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3895",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3896",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3897",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3898",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3899",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3900",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3907",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3908",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3909",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3910",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3911",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3914",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3915",
    "DISTRICTNAME_AR": "الأول",
    "DISTRICTNAME_EN": "Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3916",
    "DISTRICTNAME_AR": "المدينة المنورة",
    "DISTRICTNAME_EN": "Al Madinah Al Munawwarah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3917",
    "DISTRICTNAME_AR": "محاسن ارامكو الخامس",
    "DISTRICTNAME_EN": "Mahasin Aramco 5th ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3918",
    "DISTRICTNAME_AR": "الحزم الشمالي",
    "DISTRICTNAME_EN": "Al Hazm Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3919",
    "DISTRICTNAME_AR": "بلدة غمسي",
    "DISTRICTNAME_EN": "Ghomsi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3920",
    "DISTRICTNAME_AR": "المباركية",
    "DISTRICTNAME_EN": "Al Mubarkiah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3921",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3922",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3923",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "Alghawbia Agricultural Area",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3924",
    "DISTRICTNAME_AR": "الخيام",
    "DISTRICTNAME_EN": "Al Khiam",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3925",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3927",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3928",
    "DISTRICTNAME_AR": "بلدة الجبيل",
    "DISTRICTNAME_EN": "Jubail Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3929",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3930",
    "DISTRICTNAME_AR": "السهلة",
    "DISTRICTNAME_EN": "Al Sahlah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3931",
    "DISTRICTNAME_AR": "الكلابية",
    "DISTRICTNAME_EN": "Alkilabia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3932",
    "DISTRICTNAME_AR": "الرويضة",
    "DISTRICTNAME_EN": "Ar Ruwaida",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3933",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3934",
    "DISTRICTNAME_AR": "قرية العمران الشرقية",
    "DISTRICTNAME_EN": "Qaryat Al Omran East",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3935",
    "DISTRICTNAME_AR": "الزهراء1",
    "DISTRICTNAME_EN": "Az Zahra1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3936",
    "DISTRICTNAME_AR": "هجر الثالث",
    "DISTRICTNAME_EN": "Hajr Al Thalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3937",
    "DISTRICTNAME_AR": "بلدة الطرف",
    "DISTRICTNAME_EN": "Al Taraf Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3938",
    "DISTRICTNAME_AR": "بلدة العقار",
    "DISTRICTNAME_EN": "Al Eaqar Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3939",
    "DISTRICTNAME_AR": "القادسية1",
    "DISTRICTNAME_EN": "Al Qadisiyah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3940",
    "DISTRICTNAME_AR": "المزروعية",
    "DISTRICTNAME_EN": "Al Mazruiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3941",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3942",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3943",
    "DISTRICTNAME_AR": "أم خريسان",
    "DISTRICTNAME_EN": "Umm Khraisan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3944",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3945",
    "DISTRICTNAME_AR": "وسط المبرز التاريخي",
    "DISTRICTNAME_EN": "Historical Center Al Mubarraz ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3946",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية الثاني",
    "DISTRICTNAME_EN": "Government Departments Complex 2nd ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3947",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3948",
    "DISTRICTNAME_AR": "منسوبي التعليم الثاني",
    "DISTRICTNAME_EN": "Mansubi Al Taleem 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3949",
    "DISTRICTNAME_AR": "بلدة الوزية",
    "DISTRICTNAME_EN": "Al Waziah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3950",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3951",
    "DISTRICTNAME_AR": "المعلمين",
    "DISTRICTNAME_EN": "Al Muallimeen ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3952",
    "DISTRICTNAME_AR": "الحي الأكاديمي",
    "DISTRICTNAME_EN": "Academic Quarter",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3953",
    "DISTRICTNAME_AR": "بلدة المركز",
    "DISTRICTNAME_EN": "Al Markaz Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3954",
    "DISTRICTNAME_AR": "الحمراء الثالث",
    "DISTRICTNAME_EN": "Al Hamra 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3955",
    "DISTRICTNAME_AR": "بلدة المطيرفي",
    "DISTRICTNAME_EN": "Al-Mutarifi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3956",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3957",
    "DISTRICTNAME_AR": "البندرية",
    "DISTRICTNAME_EN": "Al Bandariyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3958",
    "DISTRICTNAME_AR": "النعاثل",
    "DISTRICTNAME_EN": "Al Naeathil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3959",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3960",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3961",
    "DISTRICTNAME_AR": "الصفاء الثالث",
    "DISTRICTNAME_EN": "As Safa Al Thaalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3962",
    "DISTRICTNAME_AR": "النزهة1",
    "DISTRICTNAME_EN": "An Nuzhah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3963",
    "DISTRICTNAME_AR": "الصفاء الرابع",
    "DISTRICTNAME_EN": "As Safa Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3964",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3965",
    "DISTRICTNAME_AR": "الراشدية الأول",
    "DISTRICTNAME_EN": "Ar Rashidyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3966",
    "DISTRICTNAME_AR": "السنيدية",
    "DISTRICTNAME_EN": "As Sunaidah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3967",
    "DISTRICTNAME_AR": "هجر السابع",
    "DISTRICTNAME_EN": "Hajar Al Saabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3968",
    "DISTRICTNAME_AR": "البساتين1",
    "DISTRICTNAME_EN": "Al Basatin1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3969",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3970",
    "DISTRICTNAME_AR": "محاسن ارامكو الأول",
    "DISTRICTNAME_EN": "Mahasin Aramco 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3971",
    "DISTRICTNAME_AR": "بلدة التويثير",
    "DISTRICTNAME_EN": "Altwether Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3972",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "Al Shuaba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3973",
    "DISTRICTNAME_AR": "الصقور",
    "DISTRICTNAME_EN": "As Suqur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3974",
    "DISTRICTNAME_AR": "الورود الأول",
    "DISTRICTNAME_EN": "Al Wurud 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3975",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الثاني",
    "DISTRICTNAME_EN": "King Faisal University 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3976",
    "DISTRICTNAME_AR": "لذة",
    "DISTRICTNAME_EN": "Ladhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3977",
    "DISTRICTNAME_AR": "بلدة التهيمية",
    "DISTRICTNAME_EN": "Tahimiya Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3978",
    "DISTRICTNAME_AR": "المروج الجنوبي",
    "DISTRICTNAME_EN": "Al Muruj Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3979",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3980",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3981",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3982",
    "DISTRICTNAME_AR": "هجر الثامن",
    "DISTRICTNAME_EN": "Hajr Al Thamin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3983",
    "DISTRICTNAME_AR": "المعامرة",
    "DISTRICTNAME_EN": "Al Mueamara",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3984",
    "DISTRICTNAME_AR": "الفيصلية الأول",
    "DISTRICTNAME_EN": "Al Faisaliyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3985",
    "DISTRICTNAME_AR": "الرياض الثالث",
    "DISTRICTNAME_EN": "Ar Riyadh Al Thaalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3987",
    "DISTRICTNAME_AR": "هجر الثاني عشر",
    "DISTRICTNAME_EN": "Hajar Al Thani ashar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3988",
    "DISTRICTNAME_AR": "مدينة التمور",
    "DISTRICTNAME_EN": "Madinat Al Tumur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3989",
    "DISTRICTNAME_AR": "بلدة الشهارين",
    "DISTRICTNAME_EN": "Al Shaharayn Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3990",
    "DISTRICTNAME_AR": "هجر الأول",
    "DISTRICTNAME_EN": "Hajr Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3991",
    "DISTRICTNAME_AR": "بلدة المقدام",
    "DISTRICTNAME_EN": "Al Muqdam Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3992",
    "DISTRICTNAME_AR": "قرية الحوطة",
    "DISTRICTNAME_EN": "Qaryat Al Hawta",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3993",
    "DISTRICTNAME_AR": "بلدة المراح",
    "DISTRICTNAME_EN": "Al Marah Town",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3994",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3995",
    "DISTRICTNAME_AR": "السلام الأول",
    "DISTRICTNAME_EN": "As Salam 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3996",
    "DISTRICTNAME_AR": "الروضة2",
    "DISTRICTNAME_EN": "Ar Rawdah2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3997",
    "DISTRICTNAME_AR": "بلدة الجفر",
    "DISTRICTNAME_EN": "Al Jafr Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3998",
    "DISTRICTNAME_AR": "منسوبي التعليم الأول",
    "DISTRICTNAME_EN": "Mansubi Al Taleem 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3999",
    "DISTRICTNAME_AR": "بلدة الجشة",
    "DISTRICTNAME_EN": "Al Jishah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4000",
    "DISTRICTNAME_AR": "الورود الثالث",
    "DISTRICTNAME_EN": "Al Wurud 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4001",
    "DISTRICTNAME_AR": "الكادي",
    "DISTRICTNAME_EN": "Al Kadi",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4002",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4003",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4004",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4005",
    "DISTRICTNAME_AR": "الخزام",
    "DISTRICTNAME_EN": "Al Khuzam",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4006",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4007",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4008",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4009",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4010",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4011",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4012",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4017",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4018",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4019",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4021",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4022",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4023",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4030",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4031",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4035",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4038",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4040",
    "DISTRICTNAME_AR": "السلوى",
    "DISTRICTNAME_EN": "Al Salwaa",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4041",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4042",
    "DISTRICTNAME_AR": "الأجيال",
    "DISTRICTNAME_EN": "Al Ajyal",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4043",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4044",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4045",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4046",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4047",
    "DISTRICTNAME_AR": "الأريج",
    "DISTRICTNAME_EN": "Al Areej",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4048",
    "DISTRICTNAME_AR": "السراة",
    "DISTRICTNAME_EN": "As Sarat",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4049",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4050",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4051",
    "DISTRICTNAME_AR": "الميدان",
    "DISTRICTNAME_EN": "Al-Maydan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4052",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4053",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4054",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4055",
    "DISTRICTNAME_AR": "القاع 2",
    "DISTRICTNAME_EN": "Al Qaa 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4056",
    "DISTRICTNAME_AR": "البرج",
    "DISTRICTNAME_EN": "Al Burj",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4057",
    "DISTRICTNAME_AR": "الشاطئ 2",
    "DISTRICTNAME_EN": "Ash Shati 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4058",
    "DISTRICTNAME_AR": "الشاطئ 3",
    "DISTRICTNAME_EN": "Ash Shati 3",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4059",
    "DISTRICTNAME_AR": "آل زياد 2",
    "DISTRICTNAME_EN": "Al Ziyad 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4060",
    "DISTRICTNAME_AR": "منطقة الخدمات المركزية",
    "DISTRICTNAME_EN": "Central Services",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4061",
    "DISTRICTNAME_AR": "أحد بني زيد",
    "DISTRICTNAME_EN": "Ohod Bani Zayd",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4062",
    "DISTRICTNAME_AR": "القوز",
    "DISTRICTNAME_EN": "Al Quoz",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4063",
    "DISTRICTNAME_AR": "عنيكر",
    "DISTRICTNAME_EN": "Unaykar",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4064",
    "DISTRICTNAME_AR": "الجبيل-قنبور",
    "DISTRICTNAME_EN": "Al Jubail-Qanbour",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4065",
    "DISTRICTNAME_AR": "الجرد",
    "DISTRICTNAME_EN": "Al Jard",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4066",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4067",
    "DISTRICTNAME_AR": "الجميعات",
    "DISTRICTNAME_EN": "Al Jumaymat",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4068",
    "DISTRICTNAME_AR": "ثلاثاء يبة",
    "DISTRICTNAME_EN": "Thulatha' ybaah",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4069",
    "DISTRICTNAME_AR": "الحبيلة",
    "DISTRICTNAME_EN": "Al Habila",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4070",
    "DISTRICTNAME_AR": "هشيمة",
    "DISTRICTNAME_EN": "Hashima",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4071",
    "DISTRICTNAME_AR": "السمرة",
    "DISTRICTNAME_EN": "As Sumra",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4072",
    "DISTRICTNAME_AR": "المناديل",
    "DISTRICTNAME_EN": "Al Manadil",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4073",
    "DISTRICTNAME_AR": "مخطط الخدمات المركزية",
    "DISTRICTNAME_EN": "Central Services Subdivision",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4074",
    "DISTRICTNAME_AR": "القحمان",
    "DISTRICTNAME_EN": "Al Qahman",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4075",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4076",
    "DISTRICTNAME_AR": "البرقاء",
    "DISTRICTNAME_EN": "Al Barqa",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4077",
    "DISTRICTNAME_AR": "المغاربة",
    "DISTRICTNAME_EN": "Al Magharbah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4078",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4079",
    "DISTRICTNAME_AR": "الطلعة1",
    "DISTRICTNAME_EN": "At Talaah 1",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4081",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4082",
    "DISTRICTNAME_AR": "غران",
    "DISTRICTNAME_EN": "Gharan",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4083",
    "DISTRICTNAME_AR": "اليمانية",
    "DISTRICTNAME_EN": "Al Yamania",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4084",
    "DISTRICTNAME_AR": "صناعية اليمانية",
    "DISTRICTNAME_EN": "Sinaiyah Al Yamania",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4085",
    "DISTRICTNAME_AR": "الهجرة2",
    "DISTRICTNAME_EN": "Al Hijrah 2",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4086",
    "DISTRICTNAME_AR": "الهجرة1",
    "DISTRICTNAME_EN": "Al Hijrah 1",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4087",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4088",
    "DISTRICTNAME_AR": "الطلعة2",
    "DISTRICTNAME_EN": "At Talaah 2",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4089",
    "DISTRICTNAME_AR": "الحرة",
    "DISTRICTNAME_EN": "Al Hura",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4090",
    "DISTRICTNAME_AR": "الحرازات",
    "DISTRICTNAME_EN": "Al Harazat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4091",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4092",
    "DISTRICTNAME_AR": "البحيرات",
    "DISTRICTNAME_EN": "Al Buhayrat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4093",
    "DISTRICTNAME_AR": "جوهرة ثول",
    "DISTRICTNAME_EN": "Jawharat Thul",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4094",
    "DISTRICTNAME_AR": "المعرفة",
    "DISTRICTNAME_EN": "Al Maerifa",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4095",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4096",
    "DISTRICTNAME_AR": "القوس",
    "DISTRICTNAME_EN": "Al Qaws",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4097",
    "DISTRICTNAME_AR": "صناعي1",
    "DISTRICTNAME_EN": "Industrial1",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4098",
    "DISTRICTNAME_AR": "المزيرعة",
    "DISTRICTNAME_EN": "Al Muzayraa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4099",
    "DISTRICTNAME_AR": "حكومي3",
    "DISTRICTNAME_EN": "Governmental3",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4100",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4101",
    "DISTRICTNAME_AR": "العشيرية",
    "DISTRICTNAME_EN": "Al Ushiria",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4102",
    "DISTRICTNAME_AR": "الهزاعية",
    "DISTRICTNAME_EN": "Al Hazaeia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4103",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4104",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4105",
    "DISTRICTNAME_AR": "الخالدية ب",
    "DISTRICTNAME_EN": "Al Khalidiyah B",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4106",
    "DISTRICTNAME_AR": "الغربية",
    "DISTRICTNAME_EN": "Al Gharbia",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4107",
    "DISTRICTNAME_AR": "الخالدية أ",
    "DISTRICTNAME_EN": "Al Khalidiyah A",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4108",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4109",
    "DISTRICTNAME_AR": "عمرات",
    "DISTRICTNAME_EN": "Umrat",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4110",
    "DISTRICTNAME_AR": "الصالحي",
    "DISTRICTNAME_EN": "Al Salhi",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4111",
    "DISTRICTNAME_AR": "القاع 1",
    "DISTRICTNAME_EN": "Al Qaa 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4112",
    "DISTRICTNAME_AR": "آل زياد 1",
    "DISTRICTNAME_EN": "Al Ziyad 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4113",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4114",
    "DISTRICTNAME_AR": "رحمان",
    "DISTRICTNAME_EN": "Rahman",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4115",
    "DISTRICTNAME_AR": "الدعدي",
    "DISTRICTNAME_EN": "Al daadi",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4116",
    "DISTRICTNAME_AR": "وادي هارون",
    "DISTRICTNAME_EN": "Wadi Haroun",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4117",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4118",
    "DISTRICTNAME_AR": "الشاطئ 1",
    "DISTRICTNAME_EN": "Ash Shati 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4119",
    "DISTRICTNAME_AR": "الصدر",
    "DISTRICTNAME_EN": "Al Sadr",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4120",
    "DISTRICTNAME_AR": "الدف",
    "DISTRICTNAME_EN": "Ad Duf",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4121",
    "DISTRICTNAME_AR": "داربن جدة",
    "DISTRICTNAME_EN": "Darbin Jeddah",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4122",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4123",
    "DISTRICTNAME_AR": "الشيوخ",
    "DISTRICTNAME_EN": "Ash Shiyukh",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4124",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4125",
    "DISTRICTNAME_AR": "السالمية",
    "DISTRICTNAME_EN": "As Salmiya",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4126",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4128",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4129",
    "DISTRICTNAME_AR": "الملك فهد بن عبدالعزيز",
    "DISTRICTNAME_EN": "King Fahd bin Abdulaziz",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4130",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4131",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4132",
    "DISTRICTNAME_AR": "الحفنة",
    "DISTRICTNAME_EN": "Al Hafna",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4133",
    "DISTRICTNAME_AR": "الحجاز",
    "DISTRICTNAME_EN": "Al Hijaz",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4134",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4135",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4136",
    "DISTRICTNAME_AR": "الثعالبة",
    "DISTRICTNAME_EN": "Ath Thaalibah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4137",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4138",
    "DISTRICTNAME_AR": "المحاميد",
    "DISTRICTNAME_EN": "Al Mahamid",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4139",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4140",
    "DISTRICTNAME_AR": "القوزين",
    "DISTRICTNAME_EN": "Al Qouzeen",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4141",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4142",
    "DISTRICTNAME_AR": "مطار الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz International Airport",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4143",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4144",
    "DISTRICTNAME_AR": "الرواسي",
    "DISTRICTNAME_EN": "Ar Rawasi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4145",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4146",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4147",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4148",
    "DISTRICTNAME_AR": "الرحمة",
    "DISTRICTNAME_EN": "Ar Rahmah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4149",
    "DISTRICTNAME_AR": "الأمير عبدالمجيد",
    "DISTRICTNAME_EN": "Prince Abdulmajeed",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4150",
    "DISTRICTNAME_AR": "الكرامة",
    "DISTRICTNAME_EN": "Al Karamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4151",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4152",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4153",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4154",
    "DISTRICTNAME_AR": "مريخ",
    "DISTRICTNAME_EN": "Mraykh",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4155",
    "DISTRICTNAME_AR": "قباء",
    "DISTRICTNAME_EN": "Quba",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4156",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4157",
    "DISTRICTNAME_AR": "الغربية",
    "DISTRICTNAME_EN": "Al Gharbia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4158",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4159",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4160",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4161",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4162",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4163",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4164",
    "DISTRICTNAME_AR": "المحجر",
    "DISTRICTNAME_EN": "Al Mahjar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4165",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4166",
    "DISTRICTNAME_AR": "السرورية",
    "DISTRICTNAME_EN": "As Surooriyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4167",
    "DISTRICTNAME_AR": "الصفحة",
    "DISTRICTNAME_EN": "As Safha",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4168",
    "DISTRICTNAME_AR": "بلدة ذهبان",
    "DISTRICTNAME_EN": "Dhahban Town",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4169",
    "DISTRICTNAME_AR": "الشويضي",
    "DISTRICTNAME_EN": "Ash Shuwaidi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4170",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4171",
    "DISTRICTNAME_AR": "النجمة",
    "DISTRICTNAME_EN": "Al Najma",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4172",
    "DISTRICTNAME_AR": "الخمرة",
    "DISTRICTNAME_EN": "Al Khomrah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4173",
    "DISTRICTNAME_AR": "الشمائل",
    "DISTRICTNAME_EN": "Ash Shamayil",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4174",
    "DISTRICTNAME_AR": "سليتة",
    "DISTRICTNAME_EN": "Salita",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4175",
    "DISTRICTNAME_AR": "المليساء",
    "DISTRICTNAME_EN": "Al Moulysaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4176",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4177",
    "DISTRICTNAME_AR": "صناعي2",
    "DISTRICTNAME_EN": "Industrial2",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4178",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4179",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4180",
    "DISTRICTNAME_AR": "الصحيفة",
    "DISTRICTNAME_EN": "As Sahifah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4181",
    "DISTRICTNAME_AR": "الرغامة",
    "DISTRICTNAME_EN": "Ar Rughamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4182",
    "DISTRICTNAME_AR": "المنارات",
    "DISTRICTNAME_EN": "Al Manarat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4183",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4184",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4185",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4186",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4187",
    "DISTRICTNAME_AR": "قاعدة الملك فيصل البحرية",
    "DISTRICTNAME_EN": "King Faisal Naval Base",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4188",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4189",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4190",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4191",
    "DISTRICTNAME_AR": "الوزيرية",
    "DISTRICTNAME_EN": "Al Waziriyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4192",
    "DISTRICTNAME_AR": "القرينية",
    "DISTRICTNAME_EN": "Al Qryniah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4193",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4194",
    "DISTRICTNAME_AR": "بترومين",
    "DISTRICTNAME_EN": "Petromin",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4195",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4196",
    "DISTRICTNAME_AR": "أم السلم",
    "DISTRICTNAME_EN": "Umm Asalam",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4197",
    "DISTRICTNAME_AR": "الرويس",
    "DISTRICTNAME_EN": "Ar Ruwais",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4198",
    "DISTRICTNAME_AR": "أبحر الجنوبية",
    "DISTRICTNAME_EN": "Abhur Al Janubiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4199",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4200",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4201",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4202",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4203",
    "DISTRICTNAME_AR": "بني مالك",
    "DISTRICTNAME_EN": "Bani Malik",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4204",
    "DISTRICTNAME_AR": "بريمان",
    "DISTRICTNAME_EN": "Bryman",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4205",
    "DISTRICTNAME_AR": "جامعة",
    "DISTRICTNAME_EN": "University",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4206",
    "DISTRICTNAME_AR": "الفرقان",
    "DISTRICTNAME_EN": "Al Furqan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4207",
    "DISTRICTNAME_AR": "طابة",
    "DISTRICTNAME_EN": "Tabah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4208",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4209",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4210",
    "DISTRICTNAME_AR": "أبو جعالة",
    "DISTRICTNAME_EN": "Abu Jaala",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4211",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4212",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4213",
    "DISTRICTNAME_AR": "أم سدرة",
    "DISTRICTNAME_EN": "Umm Sidra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4214",
    "DISTRICTNAME_AR": "مدائن الفهد",
    "DISTRICTNAME_EN": "Madain Al Fahd",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4215",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4216",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4217",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4218",
    "DISTRICTNAME_AR": "الأمير فواز الجنوبى",
    "DISTRICTNAME_EN": "Prince Fawaz Al Janoubi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4219",
    "DISTRICTNAME_AR": "كتانة",
    "DISTRICTNAME_EN": "Kittanah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4220",
    "DISTRICTNAME_AR": "أم حبلين الشرقية",
    "DISTRICTNAME_EN": "Umm Hablain Al Sharqia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4221",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4222",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4223",
    "DISTRICTNAME_AR": "الأصالة",
    "DISTRICTNAME_EN": "Al Asala",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4224",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4225",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4226",
    "DISTRICTNAME_AR": "الأمير فواز الشمالى",
    "DISTRICTNAME_EN": "Prince Fawaz Al Shamali",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4227",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4228",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4229",
    "DISTRICTNAME_AR": "اليسر",
    "DISTRICTNAME_EN": "Al Yusr",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4230",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4231",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4232",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4233",
    "DISTRICTNAME_AR": "البغددية الشرقية",
    "DISTRICTNAME_EN": "Al Baghdadiyah Al Sharqiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4234",
    "DISTRICTNAME_AR": "الأجواد",
    "DISTRICTNAME_EN": "Al Ajwad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4235",
    "DISTRICTNAME_AR": "غليل",
    "DISTRICTNAME_EN": "Ghulail",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4236",
    "DISTRICTNAME_AR": "الفضيلة",
    "DISTRICTNAME_EN": "Al Fadeylah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4237",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4238",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4239",
    "DISTRICTNAME_AR": "الكندرة",
    "DISTRICTNAME_EN": "Al Kandarah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4240",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4241",
    "DISTRICTNAME_AR": "الموج",
    "DISTRICTNAME_EN": "Al Mawj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4242",
    "DISTRICTNAME_AR": "النزلة اليمانية",
    "DISTRICTNAME_EN": "An Nazlah Al Yamaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4243",
    "DISTRICTNAME_AR": "الثغر",
    "DISTRICTNAME_EN": "Ath Thaghr",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4244",
    "DISTRICTNAME_AR": "السبيل",
    "DISTRICTNAME_EN": "As Sabil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4245",
    "DISTRICTNAME_AR": "الهنداوية",
    "DISTRICTNAME_EN": "Al Hindawiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4246",
    "DISTRICTNAME_AR": "المرسى",
    "DISTRICTNAME_EN": "Al Marsaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4247",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4248",
    "DISTRICTNAME_AR": "العلاء",
    "DISTRICTNAME_EN": "Al Ulaa",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4249",
    "DISTRICTNAME_AR": "الأجاويد",
    "DISTRICTNAME_EN": "Al Ajaweed",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4250",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4251",
    "DISTRICTNAME_AR": "بلدة ثول",
    "DISTRICTNAME_EN": "Thule Town",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4252",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4253",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4254",
    "DISTRICTNAME_AR": "أم حبلين الغربية",
    "DISTRICTNAME_EN": "Umm Hablain Al Gharbia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4255",
    "DISTRICTNAME_AR": "القريات",
    "DISTRICTNAME_EN": "Al Quraiyat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4256",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4257",
    "DISTRICTNAME_AR": "جامعة الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz University",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4258",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4259",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4260",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4261",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4262",
    "DISTRICTNAME_AR": "العسلاء",
    "DISTRICTNAME_EN": "Al Usalaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4263",
    "DISTRICTNAME_AR": "الوسامي",
    "DISTRICTNAME_EN": "Al Wasami",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4267",
    "DISTRICTNAME_AR": "النزلة الشرقية",
    "DISTRICTNAME_EN": "An Nazlah Ash Sharqiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4268",
    "DISTRICTNAME_AR": "البهجة",
    "DISTRICTNAME_EN": "Al Bahja",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4269",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4270",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4271",
    "DISTRICTNAME_AR": "الودية",
    "DISTRICTNAME_EN": "Al Wudiya",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4272",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4273",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4274",
    "DISTRICTNAME_AR": "العبير",
    "DISTRICTNAME_EN": "Al Abeer",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4275",
    "DISTRICTNAME_AR": "الأثير",
    "DISTRICTNAME_EN": "Al Athir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4276",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4277",
    "DISTRICTNAME_AR": "السهل",
    "DISTRICTNAME_EN": "Al Sahl",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4278",
    "DISTRICTNAME_AR": "جامعة",
    "DISTRICTNAME_EN": "University",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4279",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4280",
    "DISTRICTNAME_AR": "حكومي3",
    "DISTRICTNAME_EN": "Governmental3",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4281",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4282",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4283",
    "DISTRICTNAME_AR": "التوفيق",
    "DISTRICTNAME_EN": "Al Tawfiq",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4284",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4285",
    "DISTRICTNAME_AR": "البيان",
    "DISTRICTNAME_EN": "Al Bayan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4286",
    "DISTRICTNAME_AR": "رهناء",
    "DISTRICTNAME_EN": "Rahna'",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4287",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4288",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4289",
    "DISTRICTNAME_AR": "البوادر",
    "DISTRICTNAME_EN": "Al Bawadir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4290",
    "DISTRICTNAME_AR": "الحمدانية",
    "DISTRICTNAME_EN": "Al Hamadaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4291",
    "DISTRICTNAME_AR": "الشراع",
    "DISTRICTNAME_EN": "Ash Sheraa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4292",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "Al Ammariyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4293",
    "DISTRICTNAME_AR": "ميناء جدة الاسلامي",
    "DISTRICTNAME_EN": "Jeddah Eslamic Seaport",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4294",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4295",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4296",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4297",
    "DISTRICTNAME_AR": "رضوى",
    "DISTRICTNAME_EN": "Radwa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4298",
    "DISTRICTNAME_AR": "المجامع",
    "DISTRICTNAME_EN": "Al Majamie",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4299",
    "DISTRICTNAME_AR": "حكومي1",
    "DISTRICTNAME_EN": "Governmental1",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4300",
    "DISTRICTNAME_AR": "حكومي2",
    "DISTRICTNAME_EN": "Governmental2",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4301",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4302",
    "DISTRICTNAME_AR": "البغدادية الغربية",
    "DISTRICTNAME_EN": "Al Baghdadiyah Al Gharbiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4303",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4304",
    "DISTRICTNAME_AR": "المسرة",
    "DISTRICTNAME_EN": "Al Masarah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4305",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4306",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4307",
    "DISTRICTNAME_AR": "ترفيهي",
    "DISTRICTNAME_EN": "Entertaining",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4308",
    "DISTRICTNAME_AR": "الفضل",
    "DISTRICTNAME_EN": "Al Fadel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4309",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4310",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4311",
    "DISTRICTNAME_AR": "أبحر الشمالية",
    "DISTRICTNAME_EN": "Abhur Ash Shamaliyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4312",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4313",
    "DISTRICTNAME_AR": "البساتين2",
    "DISTRICTNAME_EN": "Al Basatin2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4314",
    "DISTRICTNAME_AR": "الرفعة الجنوبية",
    "DISTRICTNAME_EN": "Al Rafeat Al Janubia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4315",
    "DISTRICTNAME_AR": "السحيمية",
    "DISTRICTNAME_EN": "As Suhaymiyyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4316",
    "DISTRICTNAME_AR": "مدينة الملك عبدالله العسكرية",
    "DISTRICTNAME_EN": "King Abdullah Military City",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4317",
    "DISTRICTNAME_AR": "الخدود",
    "DISTRICTNAME_EN": "Al Khudud",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4318",
    "DISTRICTNAME_AR": "المرج",
    "DISTRICTNAME_EN": "Al Marj",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4319",
    "DISTRICTNAME_AR": "عين نجم",
    "DISTRICTNAME_EN": "Ayn Najm",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4320",
    "DISTRICTNAME_AR": "ضاحية الامير سلطان الجنوبية",
    "DISTRICTNAME_EN": "Dahiyat Al Amir Sultan Al Janubia",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4321",
    "DISTRICTNAME_AR": "المطيرفي الغربي",
    "DISTRICTNAME_EN": "Al Mutayrifi Al Garbi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4322",
    "DISTRICTNAME_AR": "ضاحية الامير سلطان الشمالية",
    "DISTRICTNAME_EN": "Dahiyat Al Amir Sultan Al Shamalia",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4323",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4324",
    "DISTRICTNAME_AR": "حكومي1",
    "DISTRICTNAME_EN": "Governmental1",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4325",
    "DISTRICTNAME_AR": "حكومي2",
    "DISTRICTNAME_EN": "Governmental2",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4326",
    "DISTRICTNAME_AR": "اللؤلؤ",
    "DISTRICTNAME_EN": "Al Loaloa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4327",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4328",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4329",
    "DISTRICTNAME_AR": "العويجاء",
    "DISTRICTNAME_EN": "Al Uwayjaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4330",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4331",
    "DISTRICTNAME_AR": "محاسن ارامكو الثاني",
    "DISTRICTNAME_EN": "Mahasin Aramco 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4332",
    "DISTRICTNAME_AR": "مدينة الحجاج",
    "DISTRICTNAME_EN": "Madinat Al Hujaaj",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4333",
    "DISTRICTNAME_AR": "المثلث",
    "DISTRICTNAME_EN": "Al Muthalath",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4334",
    "DISTRICTNAME_AR": "قرية السيايرة",
    "DISTRICTNAME_EN": "Qaryat Al Sayyara",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4335",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4336",
    "DISTRICTNAME_AR": "بلدة الفضول",
    "DISTRICTNAME_EN": "Al Fudul Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4338",
    "DISTRICTNAME_AR": "الطالعية",
    "DISTRICTNAME_EN": "Al Taalieia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4339",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4340",
    "DISTRICTNAME_AR": "العاصمة",
    "DISTRICTNAME_EN": "Al Asemah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4342",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4344",
    "DISTRICTNAME_AR": "الرياض الاول",
    "DISTRICTNAME_EN": "Ar Riyadh Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4345",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4346",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4347",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4348",
    "DISTRICTNAME_AR": "الصفاء الاول",
    "DISTRICTNAME_EN": "As Safa Al Awl",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4349",
    "DISTRICTNAME_AR": "الشهابية",
    "DISTRICTNAME_EN": "Ash Shihabiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4350",
    "DISTRICTNAME_AR": "نادي الفروسية",
    "DISTRICTNAME_EN": "Equestrian Club",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4351",
    "DISTRICTNAME_AR": "الرفعة الشمالية",
    "DISTRICTNAME_EN": "Al Rafeat Al Shamalia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4353",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4354",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4355",
    "DISTRICTNAME_AR": "المسعودي",
    "DISTRICTNAME_EN": "Al Masudi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4356",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4357",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4359",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4363",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4366",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4368",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4370",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4372",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4374",
    "DISTRICTNAME_AR": "الرابع",
    "DISTRICTNAME_EN": "The Fourth",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4375",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4376",
    "DISTRICTNAME_AR": "هجر الرابع",
    "DISTRICTNAME_EN": "Hajar Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4377",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4378",
    "DISTRICTNAME_AR": "العزيزية الأول",
    "DISTRICTNAME_EN": "Al Aziziyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4379",
    "DISTRICTNAME_AR": "الحزم الجنوبي",
    "DISTRICTNAME_EN": "Al Hazm Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4380",
    "DISTRICTNAME_AR": "الصفاء الثاني",
    "DISTRICTNAME_EN": "As Safa Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4381",
    "DISTRICTNAME_AR": "هجر السادس",
    "DISTRICTNAME_EN": "Hajar Al Saadis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4382",
    "DISTRICTNAME_AR": "مثلث الشرقية",
    "DISTRICTNAME_EN": "Muthalath Al Sharqia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4383",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4384",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4385",
    "DISTRICTNAME_AR": "الشراع الشمالي",
    "DISTRICTNAME_EN": "Ash Shara Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4386",
    "DISTRICTNAME_AR": "أم سبعة",
    "DISTRICTNAME_EN": "Umm Sabah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4387",
    "DISTRICTNAME_AR": "الرياض الثاني",
    "DISTRICTNAME_EN": "Ar Riyadh Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4388",
    "DISTRICTNAME_AR": "الراشدية الثاني",
    "DISTRICTNAME_EN": "Ar Rashidyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4389",
    "DISTRICTNAME_AR": "قرية العمران الجنوبي",
    "DISTRICTNAME_EN": "Qaryat Al Omran South",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4390",
    "DISTRICTNAME_AR": "الإسكان الأول",
    "DISTRICTNAME_EN": "Al Iskan 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4391",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4392",
    "DISTRICTNAME_AR": "الواحة1",
    "DISTRICTNAME_EN": "Al Wahah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4393",
    "DISTRICTNAME_AR": "الروضة1",
    "DISTRICTNAME_EN": "Ar Rawdah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4394",
    "DISTRICTNAME_AR": "البصيرة",
    "DISTRICTNAME_EN": "Al Basirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4395",
    "DISTRICTNAME_AR": "الكوت",
    "DISTRICTNAME_EN": "Al Kut",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4396",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4397",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4398",
    "DISTRICTNAME_AR": "محيرس",
    "DISTRICTNAME_EN": "Mahairis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4399",
    "DISTRICTNAME_AR": "أحد2",
    "DISTRICTNAME_EN": "Ohod2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4400",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4401",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4402",
    "DISTRICTNAME_AR": "السلمانية الرابع",
    "DISTRICTNAME_EN": "Al Salmaniyah Forth",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4403",
    "DISTRICTNAME_AR": "المجصة",
    "DISTRICTNAME_EN": "Al Mijasa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4404",
    "DISTRICTNAME_AR": "بلدة الشقيق الشرقية",
    "DISTRICTNAME_EN": "Al Shuqaiq Eastern Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4405",
    "DISTRICTNAME_AR": "بلدة الجرن",
    "DISTRICTNAME_EN": "Al Jurn Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4406",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية",
    "DISTRICTNAME_EN": "Government Departments Complex",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4407",
    "DISTRICTNAME_AR": "أحد1",
    "DISTRICTNAME_EN": "Ohod1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4408",
    "DISTRICTNAME_AR": "المزروع الاول",
    "DISTRICTNAME_EN": "Al Mazrooa 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4409",
    "DISTRICTNAME_AR": "جوبا",
    "DISTRICTNAME_EN": "Juba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4410",
    "DISTRICTNAME_AR": "هجر التاسع",
    "DISTRICTNAME_EN": "Hajr Al Taasie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4411",
    "DISTRICTNAME_AR": "الراشدية الثالث",
    "DISTRICTNAME_EN": "Ar Rashidyah 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4412",
    "DISTRICTNAME_AR": "الثاني",
    "DISTRICTNAME_EN": "The Second",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4413",
    "DISTRICTNAME_AR": "الإسكان الثاني",
    "DISTRICTNAME_EN": "Al Iskan 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4414",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4415",
    "DISTRICTNAME_AR": "الحمراء الأول",
    "DISTRICTNAME_EN": "Al Hamra 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4416",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4417",
    "DISTRICTNAME_AR": "المدينة الرياضية",
    "DISTRICTNAME_EN": "Sports City",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4418",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4419",
    "DISTRICTNAME_AR": "بلدة العوضية",
    "DISTRICTNAME_EN": "Al Awadhiya Town",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4420",
    "DISTRICTNAME_AR": "بلدة المنيزلة",
    "DISTRICTNAME_EN": "Al Munayzala Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4421",
    "DISTRICTNAME_AR": "بلدة الطريبيل",
    "DISTRICTNAME_EN": "Al Traibil Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4422",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4423",
    "DISTRICTNAME_AR": "بلدة المنصورة",
    "DISTRICTNAME_EN": "Al Mansoura Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4424",
    "DISTRICTNAME_AR": "النخيل1",
    "DISTRICTNAME_EN": "Al Nakhil1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4425",
    "DISTRICTNAME_AR": "الثليثية",
    "DISTRICTNAME_EN": "Al Thulaythiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4426",
    "DISTRICTNAME_AR": "الفيصل",
    "DISTRICTNAME_EN": "Al Faisal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4427",
    "DISTRICTNAME_AR": "الحوراء",
    "DISTRICTNAME_EN": "Al Hawra",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4428",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية",
    "DISTRICTNAME_EN": "Government Departments Complex",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4429",
    "DISTRICTNAME_AR": "بلدة أبو الحصى",
    "DISTRICTNAME_EN": "Abu Al Hasab Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4430",
    "DISTRICTNAME_AR": "العيون الشمالية",
    "DISTRICTNAME_EN": "North Al Uyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4431",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4432",
    "DISTRICTNAME_AR": "الحزام الذهبي",
    "DISTRICTNAME_EN": "Al Hizam Adh Dhahabi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4433",
    "DISTRICTNAME_AR": "الحفيرة",
    "DISTRICTNAME_EN": "Al Hufayrah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4434",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4435",
    "DISTRICTNAME_AR": "اليرموك1",
    "DISTRICTNAME_EN": "Al Yarmuk1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4436",
    "DISTRICTNAME_AR": "اللويمي",
    "DISTRICTNAME_EN": "Al Luwaymi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4437",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4438",
    "DISTRICTNAME_AR": "قرية العرامية",
    "DISTRICTNAME_EN": "Qaryat Al Arameya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4439",
    "DISTRICTNAME_AR": "العدوة",
    "DISTRICTNAME_EN": "Al Adwah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4440",
    "DISTRICTNAME_AR": "جواثا",
    "DISTRICTNAME_EN": "Juwatha",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4441",
    "DISTRICTNAME_AR": "قرية الرميلة",
    "DISTRICTNAME_EN": "Qaryat Al Rumaila",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4442",
    "DISTRICTNAME_AR": "الحمراء الثاني",
    "DISTRICTNAME_EN": "Al Hamra 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4443",
    "DISTRICTNAME_AR": "الورود الثاني",
    "DISTRICTNAME_EN": "Al Wurud 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4444",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4445",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4446",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4447",
    "DISTRICTNAME_AR": "المربدية",
    "DISTRICTNAME_EN": "Al Marbidia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4448",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4449",
    "DISTRICTNAME_AR": "الشروفية",
    "DISTRICTNAME_EN": "Al Shurofiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4450",
    "DISTRICTNAME_AR": "الثالث",
    "DISTRICTNAME_EN": "The Third",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4451",
    "DISTRICTNAME_AR": "الإمام محمد بن سعود",
    "DISTRICTNAME_EN": "Al Imam Muhammed Ibn Saud",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4452",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4453",
    "DISTRICTNAME_AR": "إسكان الكلابية",
    "DISTRICTNAME_EN": "Al Kilabiyah Housing",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4454",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4455",
    "DISTRICTNAME_AR": "المزروع الثاني",
    "DISTRICTNAME_EN": "Al Mazrooa 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4456",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4457",
    "DISTRICTNAME_AR": "المرقاب",
    "DISTRICTNAME_EN": "Al Merqab",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4458",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4459",
    "DISTRICTNAME_AR": "بو سحبل الثاني",
    "DISTRICTNAME_EN": "bu Sahbal 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4460",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4461",
    "DISTRICTNAME_AR": "التمور",
    "DISTRICTNAME_EN": "Al Tumur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4462",
    "DISTRICTNAME_AR": "الديوان",
    "DISTRICTNAME_EN": "Ad Daywan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4463",
    "DISTRICTNAME_AR": "بلدة الدالوة",
    "DISTRICTNAME_EN": "Dalouh Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4464",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4465",
    "DISTRICTNAME_AR": "العويمرية",
    "DISTRICTNAME_EN": "Al Uwaymiriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4466",
    "DISTRICTNAME_AR": "بلدة القرين",
    "DISTRICTNAME_EN": "Al Qurain Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4467",
    "DISTRICTNAME_AR": "محاسن الثاني",
    "DISTRICTNAME_EN": "Mahasin 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4468",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "An Nayfiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4469",
    "DISTRICTNAME_AR": "بو سحبل الأول",
    "DISTRICTNAME_EN": "bu Sahbal 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4470",
    "DISTRICTNAME_AR": "هجر الثاني",
    "DISTRICTNAME_EN": "Hajr Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4471",
    "DISTRICTNAME_AR": "محاسن ارامكو الثالث",
    "DISTRICTNAME_EN": "Mahasin Aramco 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4472",
    "DISTRICTNAME_AR": "محاسن الأول",
    "DISTRICTNAME_EN": "Mahasin 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4473",
    "DISTRICTNAME_AR": "منيفة",
    "DISTRICTNAME_EN": "Munifah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4474",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4475",
    "DISTRICTNAME_AR": "السلمانية الأول",
    "DISTRICTNAME_EN": "As Sulaymaniyah First",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4476",
    "DISTRICTNAME_AR": "صويدرة",
    "DISTRICTNAME_EN": "As Suwaidirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4477",
    "DISTRICTNAME_AR": "محاسن ارامكو الرابع",
    "DISTRICTNAME_EN": "Mahasin Aramco 4th ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4478",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4479",
    "DISTRICTNAME_AR": "أبو حريف",
    "DISTRICTNAME_EN": "Abu Harif",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4480",
    "DISTRICTNAME_AR": "الشغيبية",
    "DISTRICTNAME_EN": "Al Shagaibiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4481",
    "DISTRICTNAME_AR": "الأمانة",
    "DISTRICTNAME_EN": "Al Amanah",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "4482",
    "DISTRICTNAME_AR": "وسط العيون",
    "DISTRICTNAME_EN": "Wasat Aleuyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4483",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4484",
    "DISTRICTNAME_AR": "الجفر الشمالي",
    "DISTRICTNAME_EN": "Al Jafar Al Shamali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4485",
    "DISTRICTNAME_AR": "المقابل",
    "DISTRICTNAME_EN": "Al Muqabil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4486",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4487",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4488",
    "DISTRICTNAME_AR": "بلدة الصبايخ",
    "DISTRICTNAME_EN": "Al Sabaykh Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4489",
    "DISTRICTNAME_AR": "الحزام الأخضر",
    "DISTRICTNAME_EN": "Al Hizam Al Akhdar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4490",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الأول",
    "DISTRICTNAME_EN": "King Faisal University 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4491",
    "DISTRICTNAME_AR": "بلدة السباط",
    "DISTRICTNAME_EN": "Al Sibat Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4492",
    "DISTRICTNAME_AR": "بلدة البطالية",
    "DISTRICTNAME_EN": "Al Bataliyah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4493",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4494",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية الأول",
    "DISTRICTNAME_EN": "Government Departments Complex 1st ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4495",
    "DISTRICTNAME_AR": "الفيصلية الثاني",
    "DISTRICTNAME_EN": "Al Faisaliyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4496",
    "DISTRICTNAME_AR": "السلام الثاني",
    "DISTRICTNAME_EN": "As Salam 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4497",
    "DISTRICTNAME_AR": "هجر الحادي عشر",
    "DISTRICTNAME_EN": "Hajar Al Hadi ashar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4498",
    "DISTRICTNAME_AR": "السيفة",
    "DISTRICTNAME_EN": "As Saifa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4499",
    "DISTRICTNAME_AR": "العزيزية الثاني",
    "DISTRICTNAME_EN": "Al Aziziyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4500",
    "DISTRICTNAME_AR": "عين موسى",
    "DISTRICTNAME_EN": "Ayn Musa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4501",
    "DISTRICTNAME_AR": "السلمانية الثالث",
    "DISTRICTNAME_EN": "As Sulaymaniyah Third",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4502",
    "DISTRICTNAME_AR": "بلدة أبو ثور",
    "DISTRICTNAME_EN": "Abu Thawr Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4503",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4504",
    "DISTRICTNAME_AR": "الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4505",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4506",
    "DISTRICTNAME_AR": "الرياض الرابع",
    "DISTRICTNAME_EN": "Ar Riyadh Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4507",
    "DISTRICTNAME_AR": "بلدة الشقيق الغربية",
    "DISTRICTNAME_EN": "Al Shuqaiq Western Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4508",
    "DISTRICTNAME_AR": "عين مرجان",
    "DISTRICTNAME_EN": "Ayn Marjan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4509",
    "DISTRICTNAME_AR": "الوسيطة",
    "DISTRICTNAME_EN": "Al Wasitah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4510",
    "DISTRICTNAME_AR": "بلدة جليجلة",
    "DISTRICTNAME_EN": "Jalijila Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4511",
    "DISTRICTNAME_AR": "هجر العاشر",
    "DISTRICTNAME_EN": "Hajar Al Ashir",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4512",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4513",
    "DISTRICTNAME_AR": "هجر",
    "DISTRICTNAME_EN": "Hajr",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4514",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4515",
    "DISTRICTNAME_AR": "المحار",
    "DISTRICTNAME_EN": "Al Mahar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4516",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الثالث",
    "DISTRICTNAME_EN": "King Faisal University 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4517",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4518",
    "DISTRICTNAME_AR": "المروج الشمالي",
    "DISTRICTNAME_EN": "Al Muruj Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4519",
    "DISTRICTNAME_AR": "بلدة الحليلة",
    "DISTRICTNAME_EN": "Al Hulaila Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4520",
    "DISTRICTNAME_AR": "الأمراء",
    "DISTRICTNAME_EN": "Al Omaraa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4521",
    "DISTRICTNAME_AR": "الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4522",
    "DISTRICTNAME_AR": "السلمانية الثاني",
    "DISTRICTNAME_EN": "As Sulaymaniyah Second",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4523",
    "DISTRICTNAME_AR": "هجر الخامس",
    "DISTRICTNAME_EN": "Hajar Al Khamis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4524",
    "DISTRICTNAME_AR": "بلدة بني معن",
    "DISTRICTNAME_EN": "Bani Ma'an Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4525",
    "DISTRICTNAME_AR": "الركاز",
    "DISTRICTNAME_EN": "Al Rakaz",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4526",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4527",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4528",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4529",
    "DISTRICTNAME_AR": "مخطط الإتصالات",
    "DISTRICTNAME_EN": "Al Itsalat Subdivision",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4530",
    "DISTRICTNAME_AR": "المجيدية",
    "DISTRICTNAME_EN": "Al Majidiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4531",
    "DISTRICTNAME_AR": "بلدة المزاوي",
    "DISTRICTNAME_EN": "Al Mazawi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4532",
    "DISTRICTNAME_AR": "العيون الجنوبية",
    "DISTRICTNAME_EN": "South Al Uyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4533",
    "DISTRICTNAME_AR": "الشراع الجنوبي",
    "DISTRICTNAME_EN": "Ash Shara Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4534",
    "DISTRICTNAME_AR": "الواصلية",
    "DISTRICTNAME_EN": "Al Wasilia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4535",
    "DISTRICTNAME_AR": "الرحبة",
    "DISTRICTNAME_EN": "Ar Rahbah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4536",
    "DISTRICTNAME_AR": "اليسرا",
    "DISTRICTNAME_EN": "Al Yusra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4537",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4538",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4539",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4540",
    "DISTRICTNAME_AR": "أوالة",
    "DISTRICTNAME_EN": "Awala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4541",
    "DISTRICTNAME_AR": "السر",
    "DISTRICTNAME_EN": "Al Sir",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4542",
    "DISTRICTNAME_AR": "الجفر",
    "DISTRICTNAME_EN": "Al Jafar",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4543",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4544",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4545",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4546",
    "DISTRICTNAME_AR": "مسرة",
    "DISTRICTNAME_EN": "Masrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4547",
    "DISTRICTNAME_AR": "الرويدف",
    "DISTRICTNAME_EN": "Al Ruwaidaf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4548",
    "DISTRICTNAME_AR": "إسكان الحرس",
    "DISTRICTNAME_EN": "Guard Housing",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4549",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4550",
    "DISTRICTNAME_AR": "كلاخ",
    "DISTRICTNAME_EN": "Kalakh",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4551",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4552",
    "DISTRICTNAME_AR": "القطبية الغربية",
    "DISTRICTNAME_EN": "Al Qutbiah Al Gharbia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4553",
    "DISTRICTNAME_AR": "الوكرة",
    "DISTRICTNAME_EN": "Al Wakra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4554",
    "DISTRICTNAME_AR": "المضباع",
    "DISTRICTNAME_EN": "Al Mudhbah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4555",
    "DISTRICTNAME_AR": "السناح",
    "DISTRICTNAME_EN": "As Snah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4556",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4557",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4558",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4559",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4560",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4561",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4562",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4563",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4564",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4565",
    "DISTRICTNAME_AR": "سوق عكاظ",
    "DISTRICTNAME_EN": "Suq Ukadh",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4566",
    "DISTRICTNAME_AR": "معشي",
    "DISTRICTNAME_EN": "Maashi ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4567",
    "DISTRICTNAME_AR": "نخب",
    "DISTRICTNAME_EN": "Nakhab ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4568",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4569",
    "DISTRICTNAME_AR": "الضحى",
    "DISTRICTNAME_EN": "Al Duhaa",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4570",
    "DISTRICTNAME_AR": "الضبيعة",
    "DISTRICTNAME_EN": "Al Dabiea",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4571",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4572",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4573",
    "DISTRICTNAME_AR": "محطة الارصاد",
    "DISTRICTNAME_EN": "Weather Station",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4574",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4575",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4576",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4577",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "As Safra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4578",
    "DISTRICTNAME_AR": "الواسط",
    "DISTRICTNAME_EN": "Al Wasit",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4579",
    "DISTRICTNAME_AR": "الدهماء",
    "DISTRICTNAME_EN": "Al Dahmaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4580",
    "DISTRICTNAME_AR": "القويسم",
    "DISTRICTNAME_EN": "Al Quaysam",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4581",
    "DISTRICTNAME_AR": "الحياه الفطرية",
    "DISTRICTNAME_EN": "Wildlife",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4582",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4583",
    "DISTRICTNAME_AR": "المرجانية",
    "DISTRICTNAME_EN": "Al Murjaniyah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4584",
    "DISTRICTNAME_AR": "قرن المنازل",
    "DISTRICTNAME_EN": "Qarn Al Manazil",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4585",
    "DISTRICTNAME_AR": "العواجية",
    "DISTRICTNAME_EN": "Al Awajia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4586",
    "DISTRICTNAME_AR": "لية العليا",
    "DISTRICTNAME_EN": "Liat Al Ulya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4587",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4588",
    "DISTRICTNAME_AR": "المنطقة التاريخية",
    "DISTRICTNAME_EN": "Historic District",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4589",
    "DISTRICTNAME_AR": "نهيقة",
    "DISTRICTNAME_EN": "Nahiqah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4590",
    "DISTRICTNAME_AR": "العواكل",
    "DISTRICTNAME_EN": "Al Awakel",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4591",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4592",
    "DISTRICTNAME_AR": "الموفجة",
    "DISTRICTNAME_EN": "Al Mawfijah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4593",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4594",
    "DISTRICTNAME_AR": "الغمير",
    "DISTRICTNAME_EN": "Al Ghumayr",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4595",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4596",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4597",
    "DISTRICTNAME_AR": "القديرة",
    "DISTRICTNAME_EN": "Al Qadirah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4598",
    "DISTRICTNAME_AR": "المليساء",
    "DISTRICTNAME_EN": "Al Moulysaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4599",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4600",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4601",
    "DISTRICTNAME_AR": "الجودية",
    "DISTRICTNAME_EN": "Al Judia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4602",
    "DISTRICTNAME_AR": "العرفاء الشرقية",
    "DISTRICTNAME_EN": "Eastern Al Urafa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4603",
    "DISTRICTNAME_AR": "زور ال الحارث",
    "DISTRICTNAME_EN": "Zur Al Al Harith",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4604",
    "DISTRICTNAME_AR": "صاغر",
    "DISTRICTNAME_EN": "Saaghir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4605",
    "DISTRICTNAME_AR": "عويره",
    "DISTRICTNAME_EN": "Awira",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4606",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4607",
    "DISTRICTNAME_AR": "الحمر",
    "DISTRICTNAME_EN": "Al Humar",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4608",
    "DISTRICTNAME_AR": "الحلقة الغربية",
    "DISTRICTNAME_EN": "Al Halqah Al Gharbia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4609",
    "DISTRICTNAME_AR": "القيم الأعلى",
    "DISTRICTNAME_EN": "Al Qayam Al Aala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4610",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4611",
    "DISTRICTNAME_AR": "الدفاع الجوي",
    "DISTRICTNAME_EN": "Air Defense",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4612",
    "DISTRICTNAME_AR": "الحلاه",
    "DISTRICTNAME_EN": "Al Halah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4613",
    "DISTRICTNAME_AR": "القميع",
    "DISTRICTNAME_EN": "Qami",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4614",
    "DISTRICTNAME_AR": "ريحه",
    "DISTRICTNAME_EN": "Riha ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4615",
    "DISTRICTNAME_AR": "الحوية",
    "DISTRICTNAME_EN": "Al Huwaya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4616",
    "DISTRICTNAME_AR": "عدوان",
    "DISTRICTNAME_EN": "Eudwan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4617",
    "DISTRICTNAME_AR": "القمرية",
    "DISTRICTNAME_EN": "Al Qumariyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4618",
    "DISTRICTNAME_AR": "الشهداء الشمالية",
    "DISTRICTNAME_EN": "Ashuhada Alshamaliyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4619",
    "DISTRICTNAME_AR": "السيل الكبير",
    "DISTRICTNAME_EN": "As Sail Al Kabeer ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4620",
    "DISTRICTNAME_AR": "الحلقة الشرقية",
    "DISTRICTNAME_EN": "Al Halqah Asharqiyyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4621",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4622",
    "DISTRICTNAME_AR": "القيم الأسفل",
    "DISTRICTNAME_EN": "Al Qayam Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4623",
    "DISTRICTNAME_AR": "الجال",
    "DISTRICTNAME_EN": "Al Jal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4624",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4625",
    "DISTRICTNAME_AR": "العبلاء",
    "DISTRICTNAME_EN": "Al Ablaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4626",
    "DISTRICTNAME_AR": "واحة الإسكان",
    "DISTRICTNAME_EN": "Al Iskan oasis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4627",
    "DISTRICTNAME_AR": "شبرا",
    "DISTRICTNAME_EN": "Shubra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4628",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4629",
    "DISTRICTNAME_AR": "ميدان الهجن",
    "DISTRICTNAME_EN": "Camel Field",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4630",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4631",
    "DISTRICTNAME_AR": "المطار الدولي الجديد",
    "DISTRICTNAME_EN": "New International Airport",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4632",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4633",
    "DISTRICTNAME_AR": "جبال القنة",
    "DISTRICTNAME_EN": "Qena Mountains",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4634",
    "DISTRICTNAME_AR": "الكدى",
    "DISTRICTNAME_EN": "Al Kudaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4635",
    "DISTRICTNAME_AR": "وادي محرم الأسفل",
    "DISTRICTNAME_EN": "Wadi Muharam Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4636",
    "DISTRICTNAME_AR": "الردف",
    "DISTRICTNAME_EN": "Ar Ruddaf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4637",
    "DISTRICTNAME_AR": "أم الدروب",
    "DISTRICTNAME_EN": "Umm Al Durub",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4638",
    "DISTRICTNAME_AR": "أم العطف",
    "DISTRICTNAME_EN": "Umm Al Atf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4639",
    "DISTRICTNAME_AR": "ضاحية الإسكان",
    "DISTRICTNAME_EN": "Dahiyat AI Iskan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4640",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4641",
    "DISTRICTNAME_AR": "القهيب",
    "DISTRICTNAME_EN": "Al Quhaib",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4642",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4643",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4644",
    "DISTRICTNAME_AR": "الكوكب",
    "DISTRICTNAME_EN": "Al-Kawkab",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4645",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4646",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4647",
    "DISTRICTNAME_AR": "الشذا",
    "DISTRICTNAME_EN": "Al Shadha",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4648",
    "DISTRICTNAME_AR": "الرجاء",
    "DISTRICTNAME_EN": "Ar Rajaa",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4649",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4650",
    "DISTRICTNAME_AR": "المسميات 2",
    "DISTRICTNAME_EN": "Al Musamayat 2",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4651",
    "DISTRICTNAME_AR": "الغضا",
    "DISTRICTNAME_EN": "Al Kada",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4652",
    "DISTRICTNAME_AR": "العطيان",
    "DISTRICTNAME_EN": "Al Atyan",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4653",
    "DISTRICTNAME_AR": "المسميات 1",
    "DISTRICTNAME_EN": "Al Musamayat 1",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4654",
    "DISTRICTNAME_AR": "الحلوة",
    "DISTRICTNAME_EN": "Al Hulwa",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4655",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4656",
    "DISTRICTNAME_AR": "البيداء",
    "DISTRICTNAME_EN": "Albayda'",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4657",
    "DISTRICTNAME_AR": "الغيوم",
    "DISTRICTNAME_EN": "Al Ghyom ",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4658",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4659",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4660",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4661",
    "DISTRICTNAME_AR": "القويع",
    "DISTRICTNAME_EN": "Al Qawye",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4662",
    "DISTRICTNAME_AR": "الكريس",
    "DISTRICTNAME_EN": "Al Kris",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4663",
    "DISTRICTNAME_AR": "تهامة",
    "DISTRICTNAME_EN": "Tihamah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4664",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4665",
    "DISTRICTNAME_AR": "الإبتسام",
    "DISTRICTNAME_EN": "Al Ebtesam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4666",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4667",
    "DISTRICTNAME_AR": "القفيل",
    "DISTRICTNAME_EN": "Al Qafayl",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4668",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4669",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4670",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4671",
    "DISTRICTNAME_AR": "الفيصل",
    "DISTRICTNAME_EN": "Al Faisal",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4672",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4673",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4674",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4675",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4676",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4677",
    "DISTRICTNAME_AR": "البهجة",
    "DISTRICTNAME_EN": "Al Bahja",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4678",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4679",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4680",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4681",
    "DISTRICTNAME_AR": "الوادي الأخضر",
    "DISTRICTNAME_EN": "Al Wadi Al Akhdar",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4682",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4683",
    "DISTRICTNAME_AR": "الجود",
    "DISTRICTNAME_EN": "Al Jud",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4684",
    "DISTRICTNAME_AR": "العبير",
    "DISTRICTNAME_EN": "Al Abeer",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4685",
    "DISTRICTNAME_AR": "الرحابة",
    "DISTRICTNAME_EN": "Ar Rehabah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4686",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4687",
    "DISTRICTNAME_AR": "الازهار",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4688",
    "DISTRICTNAME_AR": "الرهوة",
    "DISTRICTNAME_EN": "Ar Rawh",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4689",
    "DISTRICTNAME_AR": "الواحات",
    "DISTRICTNAME_EN": "AlWahat",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4690",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4691",
    "DISTRICTNAME_AR": "الوفاق",
    "DISTRICTNAME_EN": "Al Wefaq",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4692",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4693",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4694",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4695",
    "DISTRICTNAME_AR": "الأرجوان",
    "DISTRICTNAME_EN": "Al Arjiwan",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4696",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4697",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4698",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4699",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4700",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4701",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4702",
    "DISTRICTNAME_AR": "الجواهر",
    "DISTRICTNAME_EN": "Al Jawahir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4703",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4704",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4705",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4706",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "Al Raed",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4707",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4708",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4709",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4710",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4711",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4712",
    "DISTRICTNAME_AR": "الغيم",
    "DISTRICTNAME_EN": "Al Ghe'm",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4713",
    "DISTRICTNAME_AR": "المصائف",
    "DISTRICTNAME_EN": "Al Masayif",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4714",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4715",
    "DISTRICTNAME_AR": "السهول",
    "DISTRICTNAME_EN": "Alsuhul",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4716",
    "DISTRICTNAME_AR": "النجاح",
    "DISTRICTNAME_EN": "Al Najah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4717",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4718",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "3554",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "4719",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4720",
    "DISTRICTNAME_AR": "البدر",
    "DISTRICTNAME_EN": "Al Badr",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4721",
    "DISTRICTNAME_AR": "عين شمس",
    "DISTRICTNAME_EN": "Eayan Shams",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4722",
    "DISTRICTNAME_AR": "الغزيات",
    "DISTRICTNAME_EN": "Al Ghaziat",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4723",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4724",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4725",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "Al Ghurub",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4726",
    "DISTRICTNAME_AR": "السدر",
    "DISTRICTNAME_EN": "Al Sudr",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4727",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4728",
    "DISTRICTNAME_AR": "النقابة",
    "DISTRICTNAME_EN": "Al Naqabah",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4729",
    "DISTRICTNAME_AR": "أبو عروه",
    "DISTRICTNAME_EN": "Abo Aurwa",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4730",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4731",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4732",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4733",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4734",
    "DISTRICTNAME_AR": "حداء",
    "DISTRICTNAME_EN": "Hada'",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4735",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4736",
    "DISTRICTNAME_AR": "الطيبات",
    "DISTRICTNAME_EN": "Al Tayebat",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4737",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4738",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4739",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4740",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4741",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4742",
    "DISTRICTNAME_AR": "المدار",
    "DISTRICTNAME_EN": "Al Madar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4743",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4744",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4745",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4746",
    "DISTRICTNAME_AR": "الأصالة",
    "DISTRICTNAME_EN": "Al Asala",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4747",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4748",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4749",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4750",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4751",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4752",
    "DISTRICTNAME_AR": "الندوة",
    "DISTRICTNAME_EN": "Al Nadwah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4753",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4754",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4755",
    "DISTRICTNAME_AR": "الشفق",
    "DISTRICTNAME_EN": "Al Shafaq",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4756",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4757",
    "DISTRICTNAME_AR": "العذوبة",
    "DISTRICTNAME_EN": "AlOthoba",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4758",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4759",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4760",
    "DISTRICTNAME_AR": "الشرفة",
    "DISTRICTNAME_EN": "Al Shurfa",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4761",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4762",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4763",
    "DISTRICTNAME_AR": "الجوزاء",
    "DISTRICTNAME_EN": "Al Jawza'a",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4764",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4765",
    "DISTRICTNAME_AR": "الانصار",
    "DISTRICTNAME_EN": "Al Ansar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4766",
    "DISTRICTNAME_AR": "الريم",
    "DISTRICTNAME_EN": "Alriym",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4767",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4768",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4769",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4770",
    "DISTRICTNAME_AR": "البيرق",
    "DISTRICTNAME_EN": "Al Bayraq",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4771",
    "DISTRICTNAME_AR": "البيعة",
    "DISTRICTNAME_EN": "Al Bayea",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4772",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4773",
    "DISTRICTNAME_AR": "السعيد",
    "DISTRICTNAME_EN": "As Saaid",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4774",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4775",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4776",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4777",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4778",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4779",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4780",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4781",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4782",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4783",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4784",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4785",
    "DISTRICTNAME_AR": "مطارالملك سعود",
    "DISTRICTNAME_EN": "King Saud Airport",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4786",
    "DISTRICTNAME_AR": "المكارم",
    "DISTRICTNAME_EN": "Al Mukaram",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4787",
    "DISTRICTNAME_AR": "الديوان",
    "DISTRICTNAME_EN": "Ad Daywan",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4788",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4789",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4790",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4791",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4792",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4793",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4794",
    "DISTRICTNAME_AR": "الحجاز",
    "DISTRICTNAME_EN": "Al Hijaz",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4795",
    "DISTRICTNAME_AR": "الحسام",
    "DISTRICTNAME_EN": "Al Hussam",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4796",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4797",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4798",
    "DISTRICTNAME_AR": "الرحمة",
    "DISTRICTNAME_EN": "Ar Rahmah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4799",
    "DISTRICTNAME_AR": "الفجر",
    "DISTRICTNAME_EN": "Al-Fajr",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4800",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4801",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4802",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4803",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4804",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4805",
    "DISTRICTNAME_AR": "السندس",
    "DISTRICTNAME_EN": "Al Sundus",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4806",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4807",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4808",
    "DISTRICTNAME_AR": "السوسن",
    "DISTRICTNAME_EN": "Al Sawsan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4809",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4810",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4811",
    "DISTRICTNAME_AR": "الرمان",
    "DISTRICTNAME_EN": "Al Rumaan",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4812",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4813",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4814",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4815",
    "DISTRICTNAME_AR": "دحضة",
    "DISTRICTNAME_EN": "Dahdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4816",
    "DISTRICTNAME_AR": "ماقان",
    "DISTRICTNAME_EN": "Maqan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4818",
    "DISTRICTNAME_AR": "المنتزة1",
    "DISTRICTNAME_EN": "Al Muntazah1",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4819",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4820",
    "DISTRICTNAME_AR": "المركب",
    "DISTRICTNAME_EN": "Al Markab",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4821",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4822",
    "DISTRICTNAME_AR": "صحيفات",
    "DISTRICTNAME_EN": "Sahifat",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4823",
    "DISTRICTNAME_AR": "أبو غبار",
    "DISTRICTNAME_EN": "Abu Ghbar",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4824",
    "DISTRICTNAME_AR": "تصلال",
    "DISTRICTNAME_EN": "Taslal",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4825",
    "DISTRICTNAME_AR": "الشرفة",
    "DISTRICTNAME_EN": "Al Shurfa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4826",
    "DISTRICTNAME_AR": "المخيم",
    "DISTRICTNAME_EN": "The Camp",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4827",
    "DISTRICTNAME_AR": "ال منجم",
    "DISTRICTNAME_EN": "Al Manjam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4828",
    "DISTRICTNAME_AR": "المخباة",
    "DISTRICTNAME_EN": "Al Makhaba",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4829",
    "DISTRICTNAME_AR": "شعب بران",
    "DISTRICTNAME_EN": "Shuab Bran",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4830",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4831",
    "DISTRICTNAME_AR": "المسماه",
    "DISTRICTNAME_EN": "Al Musmaah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4832",
    "DISTRICTNAME_AR": "الدارة",
    "DISTRICTNAME_EN": "Al Daara",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4833",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4834",
    "DISTRICTNAME_AR": "المعامر",
    "DISTRICTNAME_EN": "Al Maamir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4835",
    "DISTRICTNAME_AR": "الأمير مشعل",
    "DISTRICTNAME_EN": "Prince Mishal",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4836",
    "DISTRICTNAME_AR": "الجربة",
    "DISTRICTNAME_EN": "Al Jurbah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4837",
    "DISTRICTNAME_AR": "بئر عسكر",
    "DISTRICTNAME_EN": "Askar well",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4838",
    "DISTRICTNAME_AR": "المراطة",
    "DISTRICTNAME_EN": "Al Maratah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4839",
    "DISTRICTNAME_AR": "زور العماري",
    "DISTRICTNAME_EN": "Zawr Al Ammari",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4840",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4842",
    "DISTRICTNAME_AR": "الحايرة",
    "DISTRICTNAME_EN": "Al Hayirah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4843",
    "DISTRICTNAME_AR": "عكام",
    "DISTRICTNAME_EN": "Ukaam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4844",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4845",
    "DISTRICTNAME_AR": "بني سلمان",
    "DISTRICTNAME_EN": "Bani Salman",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4846",
    "DISTRICTNAME_AR": "العان",
    "DISTRICTNAME_EN": "Al Aan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4847",
    "DISTRICTNAME_AR": "صهبان",
    "DISTRICTNAME_EN": "Sahban",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4848",
    "DISTRICTNAME_AR": "النصلة",
    "DISTRICTNAME_EN": "An Naslah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4849",
    "DISTRICTNAME_AR": "الغويلا",
    "DISTRICTNAME_EN": "Al Ghiwila",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4850",
    "DISTRICTNAME_AR": "الشبهان",
    "DISTRICTNAME_EN": "Al Shabhan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4851",
    "DISTRICTNAME_AR": "برك",
    "DISTRICTNAME_EN": "Barak",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4852",
    "DISTRICTNAME_AR": "ريمان",
    "DISTRICTNAME_EN": "Rayman",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4853",
    "DISTRICTNAME_AR": "فواز",
    "DISTRICTNAME_EN": "Fawaz",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4854",
    "DISTRICTNAME_AR": "الجفة",
    "DISTRICTNAME_EN": "Al Jafah",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4855",
    "DISTRICTNAME_AR": "المجمع",
    "DISTRICTNAME_EN": "Al Majma",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4856",
    "DISTRICTNAME_AR": "بني هميم",
    "DISTRICTNAME_EN": "Bani Hamim",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4858",
    "DISTRICTNAME_AR": "سلوة",
    "DISTRICTNAME_EN": "Sulwa",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4859",
    "DISTRICTNAME_AR": "المنتشر",
    "DISTRICTNAME_EN": "Al Muntashir",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4860",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4861",
    "DISTRICTNAME_AR": "حمود السمال",
    "DISTRICTNAME_EN": "Hammoud Al Smal",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4862",
    "DISTRICTNAME_AR": "الحضن",
    "DISTRICTNAME_EN": "Al Hadn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4863",
    "DISTRICTNAME_AR": "الخرعاء",
    "DISTRICTNAME_EN": "Al Khuraa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4864",
    "DISTRICTNAME_AR": "بئر أبين",
    "DISTRICTNAME_EN": "Bir Abayn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4865",
    "DISTRICTNAME_AR": "منطقة الأخدود الأثرية",
    "DISTRICTNAME_EN": "Al Okhdood Archaeological Area",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4866",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4867",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4868",
    "DISTRICTNAME_AR": "الرويكبة",
    "DISTRICTNAME_EN": "Al Ruwaikibah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4869",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4870",
    "DISTRICTNAME_AR": "بطا",
    "DISTRICTNAME_EN": "Bata",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4871",
    "DISTRICTNAME_AR": "الحرشت",
    "DISTRICTNAME_EN": "Al Harasht",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4872",
    "DISTRICTNAME_AR": "السرح",
    "DISTRICTNAME_EN": "Al Sarh",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4873",
    "DISTRICTNAME_AR": "الملحين",
    "DISTRICTNAME_EN": "Al Milhayn",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4874",
    "DISTRICTNAME_AR": "الجنجت 1",
    "DISTRICTNAME_EN": "Al Janjat 1",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4875",
    "DISTRICTNAME_AR": "حبونا",
    "DISTRICTNAME_EN": "Habuwna",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4877",
    "DISTRICTNAME_AR": "سقام",
    "DISTRICTNAME_EN": "Suqam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4878",
    "DISTRICTNAME_AR": "أبا السعود",
    "DISTRICTNAME_EN": "Aba Al Saud",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4879",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4880",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4881",
    "DISTRICTNAME_AR": "شعب رير",
    "DISTRICTNAME_EN": "Shiab Reer",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4882",
    "DISTRICTNAME_AR": "الحصين",
    "DISTRICTNAME_EN": "Al Husayn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4883",
    "DISTRICTNAME_AR": "زور وادعة",
    "DISTRICTNAME_EN": "Zawr Wadiah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4884",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4885",
    "DISTRICTNAME_AR": "نهوقة",
    "DISTRICTNAME_EN": "Nahuqah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4886",
    "DISTRICTNAME_AR": "أبا الرشاش",
    "DISTRICTNAME_EN": "Aba Al Rashash",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4887",
    "DISTRICTNAME_AR": "الغيضة",
    "DISTRICTNAME_EN": "Al Ghayda",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4888",
    "DISTRICTNAME_AR": "بني كلب",
    "DISTRICTNAME_EN": "Bani Kalb",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4889",
    "DISTRICTNAME_AR": "الأثايبة",
    "DISTRICTNAME_EN": "Al Athayiba",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4890",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4891",
    "DISTRICTNAME_AR": "الخليف",
    "DISTRICTNAME_EN": "Al Khalif",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4892",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4893",
    "DISTRICTNAME_AR": "سايلة غزال",
    "DISTRICTNAME_EN": "Saylat Ghazal",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4894",
    "DISTRICTNAME_AR": "الحصينية",
    "DISTRICTNAME_EN": "Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4895",
    "DISTRICTNAME_AR": "جنوب الحصينية",
    "DISTRICTNAME_EN": "South of Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4896",
    "DISTRICTNAME_AR": "مليحة",
    "DISTRICTNAME_EN": "Mlihah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4897",
    "DISTRICTNAME_AR": "منتزة الملك فهد بن عبدالعزيز",
    "DISTRICTNAME_EN": "King Fahd bin Abdulaziz Park",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4898",
    "DISTRICTNAME_AR": "الفاجي",
    "DISTRICTNAME_EN": "Al Faji",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4899",
    "DISTRICTNAME_AR": "رجلا",
    "DISTRICTNAME_EN": "Rajula",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4900",
    "DISTRICTNAME_AR": "ال منيف",
    "DISTRICTNAME_EN": "Al Munif",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4901",
    "DISTRICTNAME_AR": "شمال الحصينية",
    "DISTRICTNAME_EN": "North  Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4902",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4903",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4904",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4905",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4906",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4907",
    "DISTRICTNAME_AR": "القعصوم",
    "DISTRICTNAME_EN": "Al Qasoom",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4908",
    "DISTRICTNAME_AR": "العريسة",
    "DISTRICTNAME_EN": "Al Uraysah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4909",
    "DISTRICTNAME_AR": "الأملاح",
    "DISTRICTNAME_EN": "Al Amalah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4910",
    "DISTRICTNAME_AR": "الضباط",
    "DISTRICTNAME_EN": "Al Dhubbat",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4911",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4912",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4913",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4914",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4915",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4916",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4919",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4920",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4921",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19373",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "4922",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4923",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4924",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4925",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4926",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4927",
    "DISTRICTNAME_AR": "أبا الخريت",
    "DISTRICTNAME_EN": "Aba Al Kharit",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4936",
    "DISTRICTNAME_AR": "الحدائق",
    "DISTRICTNAME_EN": "Al Hadaek",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4937",
    "DISTRICTNAME_AR": "أم السباع",
    "DISTRICTNAME_EN": "Umm Al Sabaa ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4938",
    "DISTRICTNAME_AR": "الوشحاء",
    "DISTRICTNAME_EN": "Al Washha",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4939",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4940",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4941",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4942",
    "DISTRICTNAME_AR": "القرشيات",
    "DISTRICTNAME_EN": "Al Qurashiaat",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4943",
    "DISTRICTNAME_AR": "سديرة",
    "DISTRICTNAME_EN": "Sudayrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4951",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4952",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4953",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4954",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4955",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "An Nayfiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4956",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4957",
    "DISTRICTNAME_AR": "قروى",
    "DISTRICTNAME_EN": "Qurwa ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4958",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4959",
    "DISTRICTNAME_AR": "حوايا الشمالية",
    "DISTRICTNAME_EN": "Huwaya Ash Shamaliyyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4960",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4961",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4962",
    "DISTRICTNAME_AR": "لية السفلي",
    "DISTRICTNAME_EN": "Liat Al Sufli",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4963",
    "DISTRICTNAME_AR": "القراحين",
    "DISTRICTNAME_EN": "Alqrahin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4964",
    "DISTRICTNAME_AR": "مطار الطائف الدولي",
    "DISTRICTNAME_EN": "Taif International Airport",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4965",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4966",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4967",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4968",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4969",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4970",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4971",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4972",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4973",
    "DISTRICTNAME_AR": "فليج",
    "DISTRICTNAME_EN": "Fulaij",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4974",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4975",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4976",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4977",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4978",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4979",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4980",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4981",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4982",
    "DISTRICTNAME_AR": "الحامدية",
    "DISTRICTNAME_EN": "Al Hamidiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4983",
    "DISTRICTNAME_AR": "مثملة",
    "DISTRICTNAME_EN": "Masmla",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4984",
    "DISTRICTNAME_AR": "القطبية الشرقية",
    "DISTRICTNAME_EN": "Al Qutbiah Al Sharqia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4985",
    "DISTRICTNAME_AR": "الفريدة",
    "DISTRICTNAME_EN": "Al Farida",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4986",
    "DISTRICTNAME_AR": "القاعدة الجوية",
    "DISTRICTNAME_EN": "The Air Base",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4987",
    "DISTRICTNAME_AR": "الجفيجف",
    "DISTRICTNAME_EN": "Al Jafijif",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4988",
    "DISTRICTNAME_AR": "جباجب",
    "DISTRICTNAME_EN": "Jabajib",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4989",
    "DISTRICTNAME_AR": "(حي رقم (8",
    "DISTRICTNAME_EN": "District No. 8",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4990",
    "DISTRICTNAME_AR": "(حي رقم (1",
    "DISTRICTNAME_EN": "District No. 1",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4991",
    "DISTRICTNAME_AR": "(حي رقم (3",
    "DISTRICTNAME_EN": "District No. 3",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4992",
    "DISTRICTNAME_AR": "أبو موسى الأشعري",
    "DISTRICTNAME_EN": "Abu Musa Al Ashari",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4993",
    "DISTRICTNAME_AR": "الباطن",
    "DISTRICTNAME_EN": "Al Batin",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4994",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4995",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4996",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4997",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4998",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4999",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5000",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5001",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5008",
    "DISTRICTNAME_AR": "الشهداء الجنوبية",
    "DISTRICTNAME_EN": "Ashuhada Aljanubiyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5009",
    "DISTRICTNAME_AR": "شهار",
    "DISTRICTNAME_EN": "Shihar ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5010",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5011",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5012",
    "DISTRICTNAME_AR": "(حي رقم (9",
    "DISTRICTNAME_EN": "District No. 9",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5013",
    "DISTRICTNAME_AR": "(حي رقم (6",
    "DISTRICTNAME_EN": "District No. 6",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5014",
    "DISTRICTNAME_AR": "(حي رقم (10",
    "DISTRICTNAME_EN": "District No. 10",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5015",
    "DISTRICTNAME_AR": "(حي رقم (4",
    "DISTRICTNAME_EN": "District No. 4",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5016",
    "DISTRICTNAME_AR": "(حي رقم (5",
    "DISTRICTNAME_EN": "District No. 5",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5017",
    "DISTRICTNAME_AR": "(حي رقم (7",
    "DISTRICTNAME_EN": "District No. 7",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5018",
    "DISTRICTNAME_AR": "(حي رقم (2",
    "DISTRICTNAME_EN": "District No. 2",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5019",
    "DISTRICTNAME_AR": "الرميدة",
    "DISTRICTNAME_EN": "Al Rumeida",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5025",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5029",
    "DISTRICTNAME_AR": "عطف الوزران",
    "DISTRICTNAME_EN": "Ataf Al Wizran",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5033",
    "DISTRICTNAME_AR": "العرج الأعلى",
    "DISTRICTNAME_EN": "Al Urj Al Aala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5034",
    "DISTRICTNAME_AR": "العرج الأسفل",
    "DISTRICTNAME_EN": "Al Urj Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5036",
    "DISTRICTNAME_AR": "الاغر",
    "DISTRICTNAME_EN": "Al Aghar",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5037",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5041",
    "DISTRICTNAME_AR": "بني سهيم",
    "DISTRICTNAME_EN": "Bani Seheem",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5042",
    "DISTRICTNAME_AR": "غليل",
    "DISTRICTNAME_EN": "Ghulail",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5043",
    "DISTRICTNAME_AR": "مخطط المدارات",
    "DISTRICTNAME_EN": "Al Madarat Subdivision",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5045",
    "DISTRICTNAME_AR": "الصبغان",
    "DISTRICTNAME_EN": "Al Sabghan",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5046",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5047",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5055",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5056",
    "DISTRICTNAME_AR": "الذنبة",
    "DISTRICTNAME_EN": "Al Dhanbuh",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5058",
    "DISTRICTNAME_AR": "المبني الجنوبي",
    "DISTRICTNAME_EN": "South Building",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5060",
    "DISTRICTNAME_AR": "البارك",
    "DISTRICTNAME_EN": "Al Bark",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5061",
    "DISTRICTNAME_AR": "المبني الشمالي",
    "DISTRICTNAME_EN": "North Building",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5062",
    "DISTRICTNAME_AR": "نمرة",
    "DISTRICTNAME_EN": "Namaruh",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5063",
    "DISTRICTNAME_AR": "مخطط الحمراني",
    "DISTRICTNAME_EN": "South Namrah Subdivision",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5064",
    "DISTRICTNAME_AR": "السلم",
    "DISTRICTNAME_EN": "Al Sulam",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5065",
    "DISTRICTNAME_AR": "الجدر الغربي",
    "DISTRICTNAME_EN": "Al Judr Al Gharbi",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5066",
    "DISTRICTNAME_AR": "الملعب",
    "DISTRICTNAME_EN": "Al Maleab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5067",
    "DISTRICTNAME_AR": "اكتان",
    "DISTRICTNAME_EN": "Aktan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5068",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5073",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5074",
    "DISTRICTNAME_AR": "امتداد الشمال",
    "DISTRICTNAME_EN": "North Extension",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5075",
    "DISTRICTNAME_AR": "الملحة 2",
    "DISTRICTNAME_EN": "Al Muliha 2",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5078",
    "DISTRICTNAME_AR": "النطوف",
    "DISTRICTNAME_EN": "Al Nutuf",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5080",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5081",
    "DISTRICTNAME_AR": "كويكب",
    "DISTRICTNAME_EN": "Kuaykib",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5082",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5083",
    "DISTRICTNAME_AR": "أم ثمام",
    "DISTRICTNAME_EN": "Umm Thamam",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5084",
    "DISTRICTNAME_AR": "العرفاء الغربية",
    "DISTRICTNAME_EN": "Western Al Urafa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5085",
    "DISTRICTNAME_AR": "منتزة الطائف الوطني بسيسد",
    "DISTRICTNAME_EN": "Taif National Park in Sisad",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5087",
    "DISTRICTNAME_AR": "الجدر الشرقي",
    "DISTRICTNAME_EN": "Al Judr Al Sharqiu",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5088",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5089",
    "DISTRICTNAME_AR": "أم راكة",
    "DISTRICTNAME_EN": "Umm Rakah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5090",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5092",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5093",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5094",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5095",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5098",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5099",
    "DISTRICTNAME_AR": "السوق القديم",
    "DISTRICTNAME_EN": "As Suq Al Qadim",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5101",
    "DISTRICTNAME_AR": "جامعة الطائف الجديدة",
    "DISTRICTNAME_EN": "New Taif University",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5102",
    "DISTRICTNAME_AR": "الحجيرة",
    "DISTRICTNAME_EN": "Al Hujayra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5103",
    "DISTRICTNAME_AR": "قنة وقدان",
    "DISTRICTNAME_EN": "Qanat and Qdan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5105",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5106",
    "DISTRICTNAME_AR": "أم حشانة",
    "DISTRICTNAME_EN": "Umm Hashana",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5107",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5108",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5109",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5114",
    "DISTRICTNAME_AR": "الأخباب",
    "DISTRICTNAME_EN": "Al Akhbab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5115",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5116",
    "DISTRICTNAME_AR": "البيهيتة",
    "DISTRICTNAME_EN": "Al Bihita",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5117",
    "DISTRICTNAME_AR": "أم الرصف",
    "DISTRICTNAME_EN": "Umm Al Rasf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5118",
    "DISTRICTNAME_AR": "عثواء",
    "DISTRICTNAME_EN": "Euthawa'",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5119",
    "DISTRICTNAME_AR": "قلعة شنقل",
    "DISTRICTNAME_EN": "Qaleat Shanqal",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5120",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5121",
    "DISTRICTNAME_AR": "ضاحية القويعية",
    "DISTRICTNAME_EN": "Dahiyat Al-Quway'iyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5122",
    "DISTRICTNAME_AR": "ضاحية الحائرية",
    "DISTRICTNAME_EN": "Dahiyat Ha'iriyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5123",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5124",
    "DISTRICTNAME_AR": "جبرة",
    "DISTRICTNAME_EN": "Jabrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5125",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5126",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5127",
    "DISTRICTNAME_AR": "اللبط",
    "DISTRICTNAME_EN": "Al Labt",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5128",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5129",
    "DISTRICTNAME_AR": "العلاوة الغربية",
    "DISTRICTNAME_EN": "Western Al Eilawah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5130",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5131",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5132",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5133",
    "DISTRICTNAME_AR": "مزارع حمدان",
    "DISTRICTNAME_EN": "Hamdan Farms",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5134",
    "DISTRICTNAME_AR": "القلت",
    "DISTRICTNAME_EN": "Al Qult",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5135",
    "DISTRICTNAME_AR": "خد الحاج",
    "DISTRICTNAME_EN": "Khada Al Haji",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5136",
    "DISTRICTNAME_AR": "مزارع بن خشمان",
    "DISTRICTNAME_EN": "Bin Khashman Farms",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5137",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5138",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5139",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5140",
    "DISTRICTNAME_AR": "العلاوة الشرقية",
    "DISTRICTNAME_EN": "Eastern Al Eilawah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5141",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5142",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5143",
    "DISTRICTNAME_AR": "وادي جليل",
    "DISTRICTNAME_EN": "Wadi Jalil",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5144",
    "DISTRICTNAME_AR": "وادي محرم الأعلي",
    "DISTRICTNAME_EN": "Wadi Muharam Al Aaela",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5145",
    "DISTRICTNAME_AR": "الشقرة",
    "DISTRICTNAME_EN": "Ash Shuqra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5146",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5147",
    "DISTRICTNAME_AR": "سمنان",
    "DISTRICTNAME_EN": "Semnan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5148",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5149",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5150",
    "DISTRICTNAME_AR": "القديم",
    "DISTRICTNAME_EN": "Al Qadim",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5151",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5152",
    "DISTRICTNAME_AR": "ريحان",
    "DISTRICTNAME_EN": "Rayhan",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5154",
    "DISTRICTNAME_AR": "الصيانة",
    "DISTRICTNAME_EN": "Al Siana",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5155",
    "DISTRICTNAME_AR": "عودة",
    "DISTRICTNAME_EN": "Awdah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5156",
    "DISTRICTNAME_AR": "حوايا الجنوبية",
    "DISTRICTNAME_EN": "Huwaya Al Janoubiyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5157",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5158",
    "DISTRICTNAME_AR": "واحة التقنية",
    "DISTRICTNAME_EN": "Technology Oasis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5159",
    "DISTRICTNAME_AR": "الخضيرة",
    "DISTRICTNAME_EN": "Hadera ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5171",
    "DISTRICTNAME_AR": "أم العراد",
    "DISTRICTNAME_EN": "Umm Alarad ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5172",
    "DISTRICTNAME_AR": "عكرمة",
    "DISTRICTNAME_EN": "Eikrima",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5173",
    "DISTRICTNAME_AR": "غدير البنات",
    "DISTRICTNAME_EN": "Ghadeer Al Banat",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5174",
    "DISTRICTNAME_AR": "بن سويلم",
    "DISTRICTNAME_EN": "Ben Swailem",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5175",
    "DISTRICTNAME_AR": "الخمايل",
    "DISTRICTNAME_EN": "Al Khamayel",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5176",
    "DISTRICTNAME_AR": "الثابتية",
    "DISTRICTNAME_EN": "Al Thaabitia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5177",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5187",
    "DISTRICTNAME_AR": "الاخاضر",
    "DISTRICTNAME_EN": "Al Akhadir",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5188",
    "DISTRICTNAME_AR": "وادي الشرب",
    "DISTRICTNAME_EN": "Wadi Al Shurb",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5189",
    "DISTRICTNAME_AR": "العارمية",
    "DISTRICTNAME_EN": "Al Armiya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5190",
    "DISTRICTNAME_AR": "السداد",
    "DISTRICTNAME_EN": "As Sadad",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5191",
    "DISTRICTNAME_AR": "الصخيرة",
    "DISTRICTNAME_EN": "As Sukhayrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5192",
    "DISTRICTNAME_AR": "المعترض",
    "DISTRICTNAME_EN": "Al Muutarid",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5193",
    "DISTRICTNAME_AR": "ضاحية العرفاء",
    "DISTRICTNAME_EN": "Dahiyat Al Arfa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5200",
    "DISTRICTNAME_AR": "حى المغترة",
    "DISTRICTNAME_EN": "حى المغترة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5201",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "الربوة",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5202",
    "DISTRICTNAME_AR": "حي مخطط مصلى العيدين",
    "DISTRICTNAME_EN": "حي مخطط مصلى العيدين",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5203",
    "DISTRICTNAME_AR": "حي الحفاير",
    "DISTRICTNAME_EN": "حي الحفاير",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5204",
    "DISTRICTNAME_AR": "حي الهيرة",
    "DISTRICTNAME_EN": "حي الهيرة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5205",
    "DISTRICTNAME_AR": "حي العبيديات",
    "DISTRICTNAME_EN": "حي العبيديات",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5206",
    "DISTRICTNAME_AR": "حي أم سليم",
    "DISTRICTNAME_EN": "حي أم سليم",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5207",
    "DISTRICTNAME_AR": "مركز مصدة",
    "DISTRICTNAME_EN": "مركز مصدة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5208",
    "DISTRICTNAME_AR": "حي بوضان",
    "DISTRICTNAME_EN": "حي بوضان",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5209",
    "DISTRICTNAME_AR": "حي الثميلة",
    "DISTRICTNAME_EN": "حي الثميلة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5210",
    "DISTRICTNAME_AR": "حي الساحبة",
    "DISTRICTNAME_EN": "حي الساحبة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5211",
    "DISTRICTNAME_AR": "قرية الرجبة",
    "DISTRICTNAME_EN": "قرية الرجبة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5212",
    "DISTRICTNAME_AR": "حي الطوال",
    "DISTRICTNAME_EN": "حي الطوال",
    "CITY_ID": "21320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5213",
    "DISTRICTNAME_AR": "قرية النهيتية",
    "DISTRICTNAME_EN": "قرية النهيتية",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5214",
    "DISTRICTNAME_AR": "حي الوسيطا",
    "DISTRICTNAME_EN": "حي الوسيطا",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5215",
    "DISTRICTNAME_AR": "حي آل هادي",
    "DISTRICTNAME_EN": "حي آل هادي",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5216",
    "DISTRICTNAME_AR": "حي ام حرمل",
    "DISTRICTNAME_EN": "حي ام حرمل",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5217",
    "DISTRICTNAME_AR": "حي الحيانية القديمة",
    "DISTRICTNAME_EN": "حي الحيانية القديمة",
    "CITY_ID": "21315",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5218",
    "DISTRICTNAME_AR": "حي الحيانية الشمالية",
    "DISTRICTNAME_EN": "حي الحيانية الشمالية",
    "CITY_ID": "21315",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5219",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "المجد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5220",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "أحد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5221",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "الشهداء",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5222",
    "DISTRICTNAME_AR": "الشرق",
    "DISTRICTNAME_EN": "الشرق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5223",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "الراية",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5224",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "نجد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5225",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "الإزدهار",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5226",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "العقيق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5227",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "البساتين",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5228",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "الريف",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5229",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "العارض",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5230",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "السيح",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5231",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "القيروان",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5232",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "بدر",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5233",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "الرحاب",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5234",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "طويق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5235",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "النرجس",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5236",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5237",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5238",
    "DISTRICTNAME_AR": "الثليماء",
    "DISTRICTNAME_EN": "الثليماء",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5239",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "الرفاع",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5240",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "الرمال",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5241",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "التعاون",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5242",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "الندى",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5243",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "النفل",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5244",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5245",
    "DISTRICTNAME_AR": "غياضة",
    "DISTRICTNAME_EN": "غياضة",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5246",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "الملقا",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5247",
    "DISTRICTNAME_AR": "الصافي",
    "DISTRICTNAME_EN": "الصافي",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5248",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية السلي",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية السلي",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5249",
    "DISTRICTNAME_AR": "حي_001022088",
    "DISTRICTNAME_EN": "حي_001022088",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5250",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "البشائر",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5251",
    "DISTRICTNAME_AR": "حي_001022142",
    "DISTRICTNAME_EN": "حي_001022142",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5252",
    "DISTRICTNAME_AR": "حي_001022143",
    "DISTRICTNAME_EN": "حي_001022143",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5253",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "الملك سلمان",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5254",
    "DISTRICTNAME_AR": "سدرة",
    "DISTRICTNAME_EN": "سدرة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5255",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية الروضة",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية الروضة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5256",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية نمار",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية نمار",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5257",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية الشرق",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية الشرق",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5258",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية العزيزية",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية العزيزية",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5259",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية عرقة وبلدية حائر",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية عرقة وبلدية حائر",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5260",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5261",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5262",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "الغروب",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5263",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "الريان",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5264",
    "DISTRICTNAME_AR": "حي الصناعية الاولي",
    "DISTRICTNAME_EN": "حي الصناعية الاولي",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5265",
    "DISTRICTNAME_AR": "حي التعاون",
    "DISTRICTNAME_EN": "حي التعاون",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5266",
    "DISTRICTNAME_AR": "حي الصناعية الثانية",
    "DISTRICTNAME_EN": "حي الصناعية الثانية",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5267",
    "DISTRICTNAME_AR": "مدينة الانعام",
    "DISTRICTNAME_EN": "مدينة الانعام",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5268",
    "DISTRICTNAME_AR": "مسجد العيد برماح",
    "DISTRICTNAME_EN": "مسجد العيد برماح",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5269",
    "DISTRICTNAME_AR": "حي مخطط الارض الحكومية الواقعة جنوب شرق المخطط 287",
    "DISTRICTNAME_EN": "حي مخطط الارض الحكومية الواقعة جنوب شرق المخطط 287",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5270",
    "DISTRICTNAME_AR": "حي مخطط الاراضي الحكوميه شمال المخطط التهذيبي رقم 73في مدينة رماح",
    "DISTRICTNAME_EN": "حي مخطط الاراضي الحكوميه شمال المخطط التهذيبي رقم 73في مدينة رماح",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5271",
    "DISTRICTNAME_AR": "حي مخطط  الاراضي الحكوميه",
    "DISTRICTNAME_EN": "حي مخطط  الاراضي الحكوميه",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5272",
    "DISTRICTNAME_AR": "حي مخطط تنظيم الاراضي الحكوميه الواقعه شرق المخطط المعتمد 152 ببلده رغبه",
    "DISTRICTNAME_EN": "حي مخطط تنظيم الاراضي الحكوميه الواقعه شرق المخطط المعتمد 152 ببلده رغبه",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5273",
    "DISTRICTNAME_AR": "حي مخطط تنظيم احواش الاغنام و سوق الاعلاف و الشعير بمحافظة ثادق",
    "DISTRICTNAME_EN": "حي مخطط تنظيم احواش الاغنام و سوق الاعلاف و الشعير بمحافظة ثادق",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5274",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "اليرموك",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5275",
    "DISTRICTNAME_AR": "الأصيل",
    "DISTRICTNAME_EN": "الأصيل",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5276",
    "DISTRICTNAME_AR": "العروس",
    "DISTRICTNAME_EN": "العروس",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5277",
    "DISTRICTNAME_AR": "حي الجدر",
    "DISTRICTNAME_EN": "حي الجدر",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5278",
    "DISTRICTNAME_AR": "السوق القديم",
    "DISTRICTNAME_EN": "السوق القديم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5279",
    "DISTRICTNAME_AR": "حي ملهي",
    "DISTRICTNAME_EN": "حي ملهي",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5280",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5281",
    "DISTRICTNAME_AR": "حى السلم القديم",
    "DISTRICTNAME_EN": "حى السلم القديم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5282",
    "DISTRICTNAME_AR": "البروج",
    "DISTRICTNAME_EN": "البروج",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5283",
    "DISTRICTNAME_AR": "الملحة 1",
    "DISTRICTNAME_EN": "الملحة 1",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5284",
    "DISTRICTNAME_AR": "حي الجزء الشمالي",
    "DISTRICTNAME_EN": "حي الجزء الشمالي",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5285",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "الصالحية",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5286",
    "DISTRICTNAME_AR": "الدعيكة",
    "DISTRICTNAME_EN": "الدعيكة",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5287",
    "DISTRICTNAME_AR": "حي حوقان",
    "DISTRICTNAME_EN": "حي حوقان",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5288",
    "DISTRICTNAME_AR": "حي سوق الانعام",
    "DISTRICTNAME_EN": "حي سوق الانعام",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5289",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5290",
    "DISTRICTNAME_AR": "الحي الصناعي",
    "DISTRICTNAME_EN": "الحي الصناعي",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5291",
    "DISTRICTNAME_AR": "الدغمية",
    "DISTRICTNAME_EN": "الدغمية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5292",
    "DISTRICTNAME_AR": "حي الإسكان",
    "DISTRICTNAME_EN": "حي الإسكان",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5293",
    "DISTRICTNAME_AR": "حى رياض الدغمية",
    "DISTRICTNAME_EN": "حى رياض الدغمية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5294",
    "DISTRICTNAME_AR": "حي الحديقة",
    "DISTRICTNAME_EN": "حي الحديقة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5295",
    "DISTRICTNAME_AR": "حى الدغمية القديمة",
    "DISTRICTNAME_EN": "حى الدغمية القديمة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5296",
    "DISTRICTNAME_AR": "حى الدبيلة",
    "DISTRICTNAME_EN": "حى الدبيلة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5297",
    "DISTRICTNAME_AR": "حي التنمية الحضاري",
    "DISTRICTNAME_EN": "حي التنمية الحضاري",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5298",
    "DISTRICTNAME_AR": "حي الفصيلية",
    "DISTRICTNAME_EN": "حي الفصيلية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5299",
    "DISTRICTNAME_AR": "حي الصالحية",
    "DISTRICTNAME_EN": "حي الصالحية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5300",
    "DISTRICTNAME_AR": "حي النزهة",
    "DISTRICTNAME_EN": "حي النزهة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5301",
    "DISTRICTNAME_AR": "حي السلامة",
    "DISTRICTNAME_EN": "حي السلامة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5302",
    "DISTRICTNAME_AR": "حي الغدير",
    "DISTRICTNAME_EN": "حي الغدير",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5303",
    "DISTRICTNAME_AR": "حي النسيم",
    "DISTRICTNAME_EN": "حي النسيم",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5304",
    "DISTRICTNAME_AR": "حي الخالدية",
    "DISTRICTNAME_EN": "حي الخالدية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5305",
    "DISTRICTNAME_AR": "حي الفيصلية",
    "DISTRICTNAME_EN": "حي الفيصلية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5306",
    "DISTRICTNAME_AR": "حي العزيزية",
    "DISTRICTNAME_EN": "حي العزيزية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5307",
    "DISTRICTNAME_AR": "حي المنتزة",
    "DISTRICTNAME_EN": "حي المنتزة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5308",
    "DISTRICTNAME_AR": "حي الريان",
    "DISTRICTNAME_EN": "حي الريان",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5309",
    "DISTRICTNAME_AR": "حي جرهم الشمالي",
    "DISTRICTNAME_EN": "حي جرهم الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5310",
    "DISTRICTNAME_AR": "حي الصفرة",
    "DISTRICTNAME_EN": "حي الصفرة",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5311",
    "DISTRICTNAME_AR": "حي حنين الشمالي",
    "DISTRICTNAME_EN": "حي حنين الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5312",
    "DISTRICTNAME_AR": "حي السليمانية الشرقي",
    "DISTRICTNAME_EN": "حي السليمانية الشرقي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5313",
    "DISTRICTNAME_AR": "حي السليمانية الغربي",
    "DISTRICTNAME_EN": "حي السليمانية الغربي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5314",
    "DISTRICTNAME_AR": "حي المروة الشمالي",
    "DISTRICTNAME_EN": "حي المروة الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5315",
    "DISTRICTNAME_AR": "حي العقبة الشمالي",
    "DISTRICTNAME_EN": "حي العقبة الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5316",
    "DISTRICTNAME_AR": "حي جرهم الجنوبي",
    "DISTRICTNAME_EN": "حي جرهم الجنوبي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5317",
    "DISTRICTNAME_AR": "حي مخطط المفرق للدخل المحدود",
    "DISTRICTNAME_EN": "حي مخطط المفرق للدخل المحدود",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5318",
    "DISTRICTNAME_AR": "مخطط الآمتداد الغربي",
    "DISTRICTNAME_EN": "مخطط الآمتداد الغربي",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5319",
    "DISTRICTNAME_AR": "حي حزرة الجنوب",
    "DISTRICTNAME_EN": "حي حزرة الجنوب",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5320",
    "DISTRICTNAME_AR": "مخطط وزارة الإسكان",
    "DISTRICTNAME_EN": "مخطط وزارة الإسكان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5321",
    "DISTRICTNAME_AR": "مخطط البطيحان",
    "DISTRICTNAME_EN": "مخطط البطيحان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5322",
    "DISTRICTNAME_AR": "حي الضريس",
    "DISTRICTNAME_EN": "حي الضريس",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5323",
    "DISTRICTNAME_AR": "مخطط المنح",
    "DISTRICTNAME_EN": "مخطط المنح",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5324",
    "DISTRICTNAME_AR": "حي غراب",
    "DISTRICTNAME_EN": "حي غراب",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5325",
    "DISTRICTNAME_AR": "مخطط الحناكية العام",
    "DISTRICTNAME_EN": "مخطط الحناكية العام",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5326",
    "DISTRICTNAME_AR": "حي البطيحان الشرقي",
    "DISTRICTNAME_EN": "حي البطيحان الشرقي",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5327",
    "DISTRICTNAME_AR": "حي ضعة",
    "DISTRICTNAME_EN": "حي ضعة",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5328",
    "DISTRICTNAME_AR": "حي الضميرية",
    "DISTRICTNAME_EN": "حي الضميرية",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5329",
    "DISTRICTNAME_AR": "حي المسبعة",
    "DISTRICTNAME_EN": "حي المسبعة",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5330",
    "DISTRICTNAME_AR": "الآمتداد الجنوبي لمخطط الحناكية",
    "DISTRICTNAME_EN": "الآمتداد الجنوبي لمخطط الحناكية",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5331",
    "DISTRICTNAME_AR": "مخطط الآمتداد العمراني",
    "DISTRICTNAME_EN": "مخطط الآمتداد العمراني",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5332",
    "DISTRICTNAME_AR": "حي الحمادة",
    "DISTRICTNAME_EN": "حي الحمادة",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5333",
    "DISTRICTNAME_AR": "مخطط الاستيطان",
    "DISTRICTNAME_EN": "مخطط الاستيطان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5334",
    "DISTRICTNAME_AR": "حى الوادى",
    "DISTRICTNAME_EN": "حى الوادى",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5335",
    "DISTRICTNAME_AR": "حى الصفق الأحمر",
    "DISTRICTNAME_EN": "حى الصفق الأحمر",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5336",
    "DISTRICTNAME_AR": "حى النهضة",
    "DISTRICTNAME_EN": "حى النهضة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5337",
    "DISTRICTNAME_AR": "حى سلطانة",
    "DISTRICTNAME_EN": "حى سلطانة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5338",
    "DISTRICTNAME_AR": "حى الطوالعة",
    "DISTRICTNAME_EN": "حى الطوالعة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5339",
    "DISTRICTNAME_AR": "المطار والصناعية",
    "DISTRICTNAME_EN": "المطار والصناعية",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5340",
    "DISTRICTNAME_AR": "حى الجديدة ",
    "DISTRICTNAME_EN": "حى الجديدة ",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5341",
    "DISTRICTNAME_AR": "ج 6",
    "DISTRICTNAME_EN": "ج 6",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5342",
    "DISTRICTNAME_AR": "المنح ا",
    "DISTRICTNAME_EN": "المنح ا",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5343",
    "DISTRICTNAME_AR": "حي القلعة",
    "DISTRICTNAME_EN": "حي القلعة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5344",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5345",
    "DISTRICTNAME_AR": "حي المئوية",
    "DISTRICTNAME_EN": "حي المئوية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5346",
    "DISTRICTNAME_AR": "حي البدع",
    "DISTRICTNAME_EN": "حي البدع",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5347",
    "DISTRICTNAME_AR": "حي برزة",
    "DISTRICTNAME_EN": "حي برزة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5348",
    "DISTRICTNAME_AR": "حي الواسط",
    "DISTRICTNAME_EN": "حي الواسط",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5349",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5350",
    "DISTRICTNAME_AR": "حي المتوكل",
    "DISTRICTNAME_EN": "حي المتوكل",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5351",
    "DISTRICTNAME_AR": "حي الصفاء",
    "DISTRICTNAME_EN": "حي الصفاء",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5352",
    "DISTRICTNAME_AR": "حي السلام",
    "DISTRICTNAME_EN": "حي السلام",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5353",
    "DISTRICTNAME_AR": "حي القادسية",
    "DISTRICTNAME_EN": "حي القادسية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5354",
    "DISTRICTNAME_AR": "حي النخيل",
    "DISTRICTNAME_EN": "حي النخيل",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5355",
    "DISTRICTNAME_AR": "حي الرفيعة",
    "DISTRICTNAME_EN": "حي الرفيعة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5356",
    "DISTRICTNAME_AR": "حي الوزير",
    "DISTRICTNAME_EN": "حي الوزير",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5357",
    "DISTRICTNAME_AR": "حي العقدة",
    "DISTRICTNAME_EN": "حي العقدة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5358",
    "DISTRICTNAME_AR": "حي الفاروق",
    "DISTRICTNAME_EN": "حي الفاروق",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5359",
    "DISTRICTNAME_AR": "حي المملكة",
    "DISTRICTNAME_EN": "حي المملكة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5360",
    "DISTRICTNAME_AR": "حي الخبيب",
    "DISTRICTNAME_EN": "حي الخبيب",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5361",
    "DISTRICTNAME_AR": "حي الغرسات",
    "DISTRICTNAME_EN": "حي الغرسات",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5362",
    "DISTRICTNAME_AR": "حي الجال",
    "DISTRICTNAME_EN": "حي الجال",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5363",
    "DISTRICTNAME_AR": "حي الشماس",
    "DISTRICTNAME_EN": "حي الشماس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5364",
    "DISTRICTNAME_AR": "حي البلاد",
    "DISTRICTNAME_EN": "حي البلاد",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5365",
    "DISTRICTNAME_AR": "حي القدس",
    "DISTRICTNAME_EN": "حي القدس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5366",
    "DISTRICTNAME_AR": "حي الشفاء",
    "DISTRICTNAME_EN": "حي الشفاء",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5367",
    "DISTRICTNAME_AR": "حي المتحف",
    "DISTRICTNAME_EN": "حي المتحف",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5368",
    "DISTRICTNAME_AR": "حي العزيزية",
    "DISTRICTNAME_EN": "حي العزيزية",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5369",
    "DISTRICTNAME_AR": "حي الاستراحات",
    "DISTRICTNAME_EN": "حي الاستراحات",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5370",
    "DISTRICTNAME_AR": "حي الربوة",
    "DISTRICTNAME_EN": "حي الربوة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5371",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5372",
    "DISTRICTNAME_AR": "حي الازهار",
    "DISTRICTNAME_EN": "حي الازهار",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5373",
    "DISTRICTNAME_AR": "حي العليا",
    "DISTRICTNAME_EN": "حي العليا",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5374",
    "DISTRICTNAME_AR": "حي النزهة",
    "DISTRICTNAME_EN": "حي النزهة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5375",
    "DISTRICTNAME_AR": "حي الجزيرة",
    "DISTRICTNAME_EN": "حي الجزيرة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5376",
    "DISTRICTNAME_AR": "حي المنتزة",
    "DISTRICTNAME_EN": "حي المنتزة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5377",
    "DISTRICTNAME_AR": "حي البساتين",
    "DISTRICTNAME_EN": "حي البساتين",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5378",
    "DISTRICTNAME_AR": "حي الزهرة",
    "DISTRICTNAME_EN": "حي الزهرة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5379",
    "DISTRICTNAME_AR": "حي النهضة",
    "DISTRICTNAME_EN": "حي النهضة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5380",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "حى النخيل",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5381",
    "DISTRICTNAME_AR": "حى البرقاء",
    "DISTRICTNAME_EN": "حى البرقاء",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5382",
    "DISTRICTNAME_AR": "قصر العبدالله",
    "DISTRICTNAME_EN": "قصر العبدالله",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5383",
    "DISTRICTNAME_AR": "عين ابن فهيد ",
    "DISTRICTNAME_EN": "عين ابن فهيد ",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5384",
    "DISTRICTNAME_AR": "طريف الأسياح ",
    "DISTRICTNAME_EN": "طريف الأسياح ",
    "CITY_ID": "14293",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5385",
    "DISTRICTNAME_AR": "التنومة",
    "DISTRICTNAME_EN": "التنومة",
    "CITY_ID": "14295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5386",
    "DISTRICTNAME_AR": "البرود",
    "DISTRICTNAME_EN": "البرود",
    "CITY_ID": "14294",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5387",
    "DISTRICTNAME_AR": "خصيبة ",
    "DISTRICTNAME_EN": "خصيبة ",
    "CITY_ID": "14286",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5388",
    "DISTRICTNAME_AR": "حى العيون",
    "DISTRICTNAME_EN": "حى العيون",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5389",
    "DISTRICTNAME_AR": "حى الفهيد",
    "DISTRICTNAME_EN": "حى الفهيد",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5390",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "حى السلام",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5391",
    "DISTRICTNAME_AR": "حى التعاون",
    "DISTRICTNAME_EN": "حى التعاون",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5392",
    "DISTRICTNAME_AR": "حي عين بن فهيد",
    "DISTRICTNAME_EN": "حي عين بن فهيد",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5393",
    "DISTRICTNAME_AR": "حى الرياض",
    "DISTRICTNAME_EN": "حى الرياض",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5394",
    "DISTRICTNAME_AR": "الشنانة",
    "DISTRICTNAME_EN": "الشنانة",
    "CITY_ID": "14293",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5395",
    "DISTRICTNAME_AR": "الشورقية",
    "DISTRICTNAME_EN": "الشورقية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5396",
    "DISTRICTNAME_AR": "الجحانية",
    "DISTRICTNAME_EN": "الجحانية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5397",
    "DISTRICTNAME_AR": "الجراية",
    "DISTRICTNAME_EN": "الجراية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5398",
    "DISTRICTNAME_AR": "العليا والهيشة",
    "DISTRICTNAME_EN": "العليا والهيشة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5399",
    "DISTRICTNAME_AR": "نبعة",
    "DISTRICTNAME_EN": "نبعة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5400",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "الديرة القديمة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5401",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5402",
    "DISTRICTNAME_AR": "قليطة",
    "DISTRICTNAME_EN": "قليطة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5403",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "الملك عبدالله",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5404",
    "DISTRICTNAME_AR": "المقاطر",
    "DISTRICTNAME_EN": "المقاطر",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5405",
    "DISTRICTNAME_AR": "سهلة المطلق",
    "DISTRICTNAME_EN": "سهلة المطلق",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5406",
    "DISTRICTNAME_AR": "النويديس",
    "DISTRICTNAME_EN": "النويديس",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5407",
    "DISTRICTNAME_AR": "حي المقبرة",
    "DISTRICTNAME_EN": "حي المقبرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5408",
    "DISTRICTNAME_AR": "محطة معالجة",
    "DISTRICTNAME_EN": "محطة معالجة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5409",
    "DISTRICTNAME_AR": "ام عشيرة",
    "DISTRICTNAME_EN": "ام عشيرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5410",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "قرطبة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5411",
    "DISTRICTNAME_AR": "اللصافة",
    "DISTRICTNAME_EN": "اللصافة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5412",
    "DISTRICTNAME_AR": "صفية",
    "DISTRICTNAME_EN": "صفية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5413",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "الرفيعة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5414",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5415",
    "DISTRICTNAME_AR": "السليمية",
    "DISTRICTNAME_EN": "السليمية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5416",
    "DISTRICTNAME_AR": "مزارع العدان",
    "DISTRICTNAME_EN": "مزارع العدان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5417",
    "DISTRICTNAME_AR": "الفايزية",
    "DISTRICTNAME_EN": "الفايزية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5418",
    "DISTRICTNAME_AR": "مزرعة جنوب",
    "DISTRICTNAME_EN": "مزرعة جنوب",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5419",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5420",
    "DISTRICTNAME_AR": "أم الحمام",
    "DISTRICTNAME_EN": "أم الحمام",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5421",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "القاع",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5422",
    "DISTRICTNAME_AR": "شورقية الجارالله",
    "DISTRICTNAME_EN": "شورقية الجارالله",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5423",
    "DISTRICTNAME_AR": "منتزهات خرطم",
    "DISTRICTNAME_EN": "منتزهات خرطم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5424",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5425",
    "DISTRICTNAME_AR": "مزرعة شمال",
    "DISTRICTNAME_EN": "مزرعة شمال",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5426",
    "DISTRICTNAME_AR": "المجصة",
    "DISTRICTNAME_EN": "المجصة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5427",
    "DISTRICTNAME_AR": "الحصان",
    "DISTRICTNAME_EN": "الحصان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5428",
    "DISTRICTNAME_AR": "عين العقيلي",
    "DISTRICTNAME_EN": "عين العقيلي",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5429",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5430",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "القادسية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5431",
    "DISTRICTNAME_AR": "الدوائر الحكومي",
    "DISTRICTNAME_EN": "الدوائر الحكومي",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5432",
    "DISTRICTNAME_AR": "الجادة",
    "DISTRICTNAME_EN": "الجادة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5433",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5434",
    "DISTRICTNAME_AR": "سمحة",
    "DISTRICTNAME_EN": "سمحة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5435",
    "DISTRICTNAME_AR": "النهير",
    "DISTRICTNAME_EN": "النهير",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5436",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5437",
    "DISTRICTNAME_AR": "الجديدة",
    "DISTRICTNAME_EN": "الجديدة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5438",
    "DISTRICTNAME_AR": "عسيلة",
    "DISTRICTNAME_EN": "عسيلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5439",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "الخزان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5440",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "التحلية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5441",
    "DISTRICTNAME_AR": "الرميلة",
    "DISTRICTNAME_EN": "الرميلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5442",
    "DISTRICTNAME_AR": "مزارع السفالة",
    "DISTRICTNAME_EN": "مزارع السفالة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5443",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5444",
    "DISTRICTNAME_AR": "النصيرة",
    "DISTRICTNAME_EN": "النصيرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5445",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5446",
    "DISTRICTNAME_AR": "العلاوة",
    "DISTRICTNAME_EN": "العلاوة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5447",
    "DISTRICTNAME_AR": "خزامى",
    "DISTRICTNAME_EN": "خزامى",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5448",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5449",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "المنتزة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5450",
    "DISTRICTNAME_AR": "المنيقع",
    "DISTRICTNAME_EN": "المنيقع",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5451",
    "DISTRICTNAME_AR": "القفيفة",
    "DISTRICTNAME_EN": "القفيفة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5452",
    "DISTRICTNAME_AR": "الثليماء",
    "DISTRICTNAME_EN": "الثليماء",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5453",
    "DISTRICTNAME_AR": "شيحة",
    "DISTRICTNAME_EN": "شيحة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5454",
    "DISTRICTNAME_AR": "الفضيلة",
    "DISTRICTNAME_EN": "الفضيلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5455",
    "DISTRICTNAME_AR": "حي الملك عبد العزيز",
    "DISTRICTNAME_EN": "حي الملك عبد العزيز",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5456",
    "DISTRICTNAME_AR": "حي الملك سلمان",
    "DISTRICTNAME_EN": "حي الملك سلمان",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5457",
    "DISTRICTNAME_AR": "حي الامير فهد الفيصل",
    "DISTRICTNAME_EN": "حي الامير فهد الفيصل",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5458",
    "DISTRICTNAME_AR": "حي الملك عبدالله",
    "DISTRICTNAME_EN": "حي الملك عبدالله",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5459",
    "DISTRICTNAME_AR": "حي الملك خالد",
    "DISTRICTNAME_EN": "حي الملك خالد",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5460",
    "DISTRICTNAME_AR": "حي الملك سعود",
    "DISTRICTNAME_EN": "حي الملك سعود",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5461",
    "DISTRICTNAME_AR": "حي الملك فهد",
    "DISTRICTNAME_EN": "حي الملك فهد",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5462",
    "DISTRICTNAME_AR": "حي الملك فيصل",
    "DISTRICTNAME_EN": "حي الملك فيصل",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5463",
    "DISTRICTNAME_AR": "33ج",
    "DISTRICTNAME_EN": "33ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5464",
    "DISTRICTNAME_AR": "الامية",
    "DISTRICTNAME_EN": "الامية",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5465",
    "DISTRICTNAME_AR": "الغماس2",
    "DISTRICTNAME_EN": "الغماس2",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5466",
    "DISTRICTNAME_AR": "الغماس1",
    "DISTRICTNAME_EN": "الغماس1",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5467",
    "DISTRICTNAME_AR": "ضب روضان",
    "DISTRICTNAME_EN": "ضب روضان",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5468",
    "DISTRICTNAME_AR": "34ج",
    "DISTRICTNAME_EN": "34ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5469",
    "DISTRICTNAME_AR": "البصر3",
    "DISTRICTNAME_EN": "البصر3",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5470",
    "DISTRICTNAME_AR": "الجديدات",
    "DISTRICTNAME_EN": "الجديدات",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5471",
    "DISTRICTNAME_AR": "ضراس",
    "DISTRICTNAME_EN": "ضراس",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5472",
    "DISTRICTNAME_AR": "العقيلات",
    "DISTRICTNAME_EN": "العقيلات",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5473",
    "DISTRICTNAME_AR": "31ج",
    "DISTRICTNAME_EN": "31ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5474",
    "DISTRICTNAME_AR": "الدعيشة",
    "DISTRICTNAME_EN": "الدعيشة",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5475",
    "DISTRICTNAME_AR": "البصر2",
    "DISTRICTNAME_EN": "البصر2",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5476",
    "DISTRICTNAME_AR": "العاقول",
    "DISTRICTNAME_EN": "العاقول",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5477",
    "DISTRICTNAME_AR": "البصر1",
    "DISTRICTNAME_EN": "البصر1",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5478",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "غرناطة",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5479",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "الريان",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5480",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5481",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5482",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "المصيف",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5483",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "النرجس",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5484",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5485",
    "DISTRICTNAME_AR": "حي النخيل",
    "DISTRICTNAME_EN": "حي النخيل",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5486",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "حي الياسمين",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5487",
    "DISTRICTNAME_AR": "حي الازدهار",
    "DISTRICTNAME_EN": "حي الازدهار",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5488",
    "DISTRICTNAME_AR": "حي الملك فهد ",
    "DISTRICTNAME_EN": "حي الملك فهد ",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5489",
    "DISTRICTNAME_AR": "حي النهضة",
    "DISTRICTNAME_EN": "حي النهضة",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5490",
    "DISTRICTNAME_AR": "حي السلام",
    "DISTRICTNAME_EN": "حي السلام",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5491",
    "DISTRICTNAME_AR": "حي القدس",
    "DISTRICTNAME_EN": "حي القدس",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5492",
    "DISTRICTNAME_AR": "حي الملك عبدالله ",
    "DISTRICTNAME_EN": "حي الملك عبدالله ",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5493",
    "DISTRICTNAME_AR": "حي الربوة",
    "DISTRICTNAME_EN": "حي الربوة",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5494",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5495",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5496",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "المرقب",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5497",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "البساتين",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5498",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5499",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "الوادي",
    "CITY_ID": "11875",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5500",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "11875",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5501",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "الندى",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5502",
    "DISTRICTNAME_AR": "التراثي",
    "DISTRICTNAME_EN": "التراثي",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5503",
    "DISTRICTNAME_AR": "المدارس",
    "DISTRICTNAME_EN": "المدارس",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5504",
    "DISTRICTNAME_AR": "مخطط هجرة النظيم",
    "DISTRICTNAME_EN": "مخطط هجرة النظيم",
    "CITY_ID": "13432",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5505",
    "DISTRICTNAME_AR": "درب الإبل",
    "DISTRICTNAME_EN": "درب الإبل",
    "CITY_ID": "13428",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5506",
    "DISTRICTNAME_AR": "القلت",
    "DISTRICTNAME_EN": "القلت",
    "CITY_ID": "13429",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5507",
    "DISTRICTNAME_AR": "حي هجرة السليمانية",
    "DISTRICTNAME_EN": "حي هجرة السليمانية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5508",
    "DISTRICTNAME_AR": " الوايليه",
    "DISTRICTNAME_EN": " الوايليه",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5509",
    "DISTRICTNAME_AR": "الصفيرى",
    "DISTRICTNAME_EN": "الصفيرى",
    "CITY_ID": "13434",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5510",
    "DISTRICTNAME_AR": "الفاو",
    "DISTRICTNAME_EN": "الفاو",
    "CITY_ID": "11035",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5511",
    "DISTRICTNAME_AR": "سامودة",
    "DISTRICTNAME_EN": "سامودة",
    "CITY_ID": "11032",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5512",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "النايفية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5513",
    "DISTRICTNAME_AR": "الذيبية",
    "DISTRICTNAME_EN": "الذيبية",
    "CITY_ID": "11031",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5514",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "13427",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5515",
    "DISTRICTNAME_AR": "البطيحانية",
    "DISTRICTNAME_EN": "البطيحانية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5516",
    "DISTRICTNAME_AR": "أم عشر",
    "DISTRICTNAME_EN": "أم عشر",
    "CITY_ID": "11034",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5517",
    "DISTRICTNAME_AR": "أم قليب",
    "DISTRICTNAME_EN": "أم قليب",
    "CITY_ID": "11028",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5518",
    "DISTRICTNAME_AR": "أحواش الأغنام",
    "DISTRICTNAME_EN": "أحواش الأغنام",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5519",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5520",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "الشفاء",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5521",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5522",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5523",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5524",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "المحمدية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5525",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "الأندلس",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5526",
    "DISTRICTNAME_AR": "الهداية",
    "DISTRICTNAME_EN": "الهداية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5527",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "العزيزية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5528",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5529",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "الرابية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5530",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "13427",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5531",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "الرائد",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5532",
    "DISTRICTNAME_AR": "الفيوان",
    "DISTRICTNAME_EN": "الفيوان",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5533",
    "DISTRICTNAME_AR": "الصداوي",
    "DISTRICTNAME_EN": "الصداوي",
    "CITY_ID": "11023",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5534",
    "DISTRICTNAME_AR": "ام كداد",
    "DISTRICTNAME_EN": "ام كداد",
    "CITY_ID": "11024",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5535",
    "DISTRICTNAME_AR": "المتياهة الجنوبية",
    "DISTRICTNAME_EN": "المتياهة الجنوبية",
    "CITY_ID": "11022",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5536",
    "DISTRICTNAME_AR": "الحيرة",
    "DISTRICTNAME_EN": "الحيرة",
    "CITY_ID": "11026",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5537",
    "DISTRICTNAME_AR": "مناخ",
    "DISTRICTNAME_EN": "مناخ",
    "CITY_ID": "11025",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5538",
    "DISTRICTNAME_AR": "معرج السوبان",
    "DISTRICTNAME_EN": "معرج السوبان",
    "CITY_ID": "11019",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5539",
    "DISTRICTNAME_AR": "خبيراء",
    "DISTRICTNAME_EN": "خبيراء",
    "CITY_ID": "11020",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5540",
    "DISTRICTNAME_AR": "السعيرة",
    "DISTRICTNAME_EN": "السعيرة",
    "CITY_ID": "11018",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5541",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "الخليج",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5542",
    "DISTRICTNAME_AR": "مركز الحماطيات",
    "DISTRICTNAME_EN": "مركز الحماطيات",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5543",
    "DISTRICTNAME_AR": "مركز الجبو",
    "DISTRICTNAME_EN": "مركز الجبو",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5544",
    "DISTRICTNAME_AR": "مركز المسناه",
    "DISTRICTNAME_EN": "مركز المسناه",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5545",
    "DISTRICTNAME_AR": "مركز أم عمارة",
    "DISTRICTNAME_EN": "مركز أم عمارة",
    "CITY_ID": "13430",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5546",
    "DISTRICTNAME_AR": "الرقعي",
    "DISTRICTNAME_EN": "الرقعي",
    "CITY_ID": "13431",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5547",
    "DISTRICTNAME_AR": "حي مخطط أرض المستودعات المعدل",
    "DISTRICTNAME_EN": "حي مخطط أرض المستودعات المعدل",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5548",
    "DISTRICTNAME_AR": "الصناعية الأولي و الثانية - رأس تنوره",
    "DISTRICTNAME_EN": "الصناعية الأولي و الثانية - رأس تنوره",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5549",
    "DISTRICTNAME_AR": "الصناعية الثالثة - رأس تنوره",
    "DISTRICTNAME_EN": "الصناعية الثالثة - رأس تنوره",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5550",
    "DISTRICTNAME_AR": "عبدالله فؤاد",
    "DISTRICTNAME_EN": "عبدالله فؤاد",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5551",
    "DISTRICTNAME_AR": "الجود",
    "DISTRICTNAME_EN": "الجود",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5552",
    "DISTRICTNAME_AR": "الإسكان الشمالي",
    "DISTRICTNAME_EN": "الإسكان الشمالي",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5553",
    "DISTRICTNAME_AR": "الإسكان الجنوبي",
    "DISTRICTNAME_EN": "الإسكان الجنوبي",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5554",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5555",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "الصناعية الثانية",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5556",
    "DISTRICTNAME_AR": "حي البساتين - بقيق",
    "DISTRICTNAME_EN": "حي البساتين - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5557",
    "DISTRICTNAME_AR": "حي أشبيلية - بقيق",
    "DISTRICTNAME_EN": "حي أشبيلية - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5558",
    "DISTRICTNAME_AR": "حي الربوة - بقيق",
    "DISTRICTNAME_EN": "حي الربوة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5559",
    "DISTRICTNAME_AR": "حي غرناطة- بقيق",
    "DISTRICTNAME_EN": "حي غرناطة- بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5560",
    "DISTRICTNAME_AR": "حي قرطبة - بقيق",
    "DISTRICTNAME_EN": "حي قرطبة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5561",
    "DISTRICTNAME_AR": "حي الروضة - بقيق",
    "DISTRICTNAME_EN": "حي الروضة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5562",
    "DISTRICTNAME_AR": "حي الصناعية - بقيق",
    "DISTRICTNAME_EN": "حي الصناعية - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5563",
    "DISTRICTNAME_AR": "حي الزهور - بقيق",
    "DISTRICTNAME_EN": "حي الزهور - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5564",
    "DISTRICTNAME_AR": "حي النخيل - بقيق",
    "DISTRICTNAME_EN": "حي النخيل - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5565",
    "DISTRICTNAME_AR": "تابع ل ارامكو",
    "DISTRICTNAME_EN": "تابع ل ارامكو",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5566",
    "DISTRICTNAME_AR": "مركز هدباء",
    "DISTRICTNAME_EN": "مركز هدباء",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5567",
    "DISTRICTNAME_AR": "نادي الفروسية",
    "DISTRICTNAME_EN": "نادي الفروسية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5568",
    "DISTRICTNAME_AR": "عمليات الخفجي المشتركة",
    "DISTRICTNAME_EN": "عمليات الخفجي المشتركة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5569",
    "DISTRICTNAME_AR": "أحواش الابل والغنام",
    "DISTRICTNAME_EN": "أحواش الابل والغنام",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5570",
    "DISTRICTNAME_AR": "مخطط مجمع الكليات",
    "DISTRICTNAME_EN": "مخطط مجمع الكليات",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5571",
    "DISTRICTNAME_AR": "مخطط 71",
    "DISTRICTNAME_EN": "مخطط 71",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5572",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "الدوائر الحكومية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5573",
    "DISTRICTNAME_AR": "مدينة الملك عبد العزيز",
    "DISTRICTNAME_EN": "مدينة الملك عبد العزيز",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5574",
    "DISTRICTNAME_AR": "حي الصناعية الاولى",
    "DISTRICTNAME_EN": "حي الصناعية الاولى",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5575",
    "DISTRICTNAME_AR": "حي اللؤلؤة",
    "DISTRICTNAME_EN": "حي اللؤلؤة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5576",
    "DISTRICTNAME_AR": "حي الحمراء",
    "DISTRICTNAME_EN": "حي الحمراء",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5577",
    "DISTRICTNAME_AR": "حي الأمل",
    "DISTRICTNAME_EN": "حي الأمل",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5578",
    "DISTRICTNAME_AR": "حي الأمير نايف",
    "DISTRICTNAME_EN": "حي الأمير نايف",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5579",
    "DISTRICTNAME_AR": "حي الحرس الوطني",
    "DISTRICTNAME_EN": "حي الحرس الوطني",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5580",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5581",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "حي اليرموك",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5582",
    "DISTRICTNAME_AR": "حي الخالدية",
    "DISTRICTNAME_EN": "حي الخالدية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5583",
    "DISTRICTNAME_AR": "حي الدرة",
    "DISTRICTNAME_EN": "حي الدرة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5584",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5585",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "حي الياسمين",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5586",
    "DISTRICTNAME_AR": "حي الخزامي",
    "DISTRICTNAME_EN": "حي الخزامي",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5587",
    "DISTRICTNAME_AR": "إسكان الجمرك",
    "DISTRICTNAME_EN": "إسكان الجمرك",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5588",
    "DISTRICTNAME_AR": "مخطط 87",
    "DISTRICTNAME_EN": "مخطط 87",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5589",
    "DISTRICTNAME_AR": "حي_005033041",
    "DISTRICTNAME_EN": "حي_005033041",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5590",
    "DISTRICTNAME_AR": "حي005033042",
    "DISTRICTNAME_EN": "حي005033042",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5591",
    "DISTRICTNAME_AR": "حي_005033043",
    "DISTRICTNAME_EN": "حي_005033043",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5592",
    "DISTRICTNAME_AR": "حي_005033044",
    "DISTRICTNAME_EN": "حي_005033044",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5593",
    "DISTRICTNAME_AR": "ارض مملوكة",
    "DISTRICTNAME_EN": "ارض مملوكة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5594",
    "DISTRICTNAME_AR": "حي الزهور ",
    "DISTRICTNAME_EN": "حي الزهور ",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5595",
    "DISTRICTNAME_AR": "حي سوق الماشية _النعيرية",
    "DISTRICTNAME_EN": "حي سوق الماشية _النعيرية",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5596",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5597",
    "DISTRICTNAME_AR": "حي الروابي",
    "DISTRICTNAME_EN": "حي الروابي",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5598",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "حي المروج",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5599",
    "DISTRICTNAME_AR": "حي الشهداء",
    "DISTRICTNAME_EN": "حي الشهداء",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5600",
    "DISTRICTNAME_AR": "حي اشبيليا",
    "DISTRICTNAME_EN": "حي اشبيليا",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5601",
    "DISTRICTNAME_AR": "صناعية العريفي - الجبيل ",
    "DISTRICTNAME_EN": "صناعية العريفي - الجبيل",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5602",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "شاطئ نصف القمر",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5603",
    "DISTRICTNAME_AR": "حي صناعية الظهران",
    "DISTRICTNAME_EN": "حي صناعية الظهران",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5604",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "شاطئ نصف القمر",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5605",
    "DISTRICTNAME_AR": "حي الروضة - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الروضة - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5606",
    "DISTRICTNAME_AR": "حي الأثير- عين دار القديمة",
    "DISTRICTNAME_EN": "حي الأثير- عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5607",
    "DISTRICTNAME_AR": "حي النخيل - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي النخيل - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5608",
    "DISTRICTNAME_AR": "حي المنار - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي المنار - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5609",
    "DISTRICTNAME_AR": "حي الجزيرة - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الجزيرة - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5610",
    "DISTRICTNAME_AR": "حي الفيحاء - عين دار القديمة",
    "DISTRICTNAME_EN": "حي الفيحاء - عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5611",
    "DISTRICTNAME_AR": "حي الندى - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الندى - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5612",
    "DISTRICTNAME_AR": "حي الفردوس - عين دار القديمة",
    "DISTRICTNAME_EN": "حي الفردوس - عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5613",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "حي المنتزه",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5614",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "العزيزية",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5615",
    "DISTRICTNAME_AR": "الشبوة",
    "DISTRICTNAME_EN": "الشبوة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5616",
    "DISTRICTNAME_AR": "حليبة",
    "DISTRICTNAME_EN": "حليبة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5617",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "الرفايع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5618",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "القاع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5619",
    "DISTRICTNAME_AR": "الجنينة",
    "DISTRICTNAME_EN": "الجنينة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5620",
    "DISTRICTNAME_AR": "الشريفة",
    "DISTRICTNAME_EN": "الشريفة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5621",
    "DISTRICTNAME_AR": "عيدان",
    "DISTRICTNAME_EN": "عيدان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5622",
    "DISTRICTNAME_AR": "عقلان",
    "DISTRICTNAME_EN": "عقلان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5623",
    "DISTRICTNAME_AR": "دويرج",
    "DISTRICTNAME_EN": "دويرج",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5624",
    "DISTRICTNAME_AR": "ندوان",
    "DISTRICTNAME_EN": "ندوان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5625",
    "DISTRICTNAME_AR": "الثنوء",
    "DISTRICTNAME_EN": "الثنوء",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5626",
    "DISTRICTNAME_AR": "الخيبر",
    "DISTRICTNAME_EN": "الخيبر",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5627",
    "DISTRICTNAME_AR": "البرقاء",
    "DISTRICTNAME_EN": "البرقاء",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5628",
    "DISTRICTNAME_AR": "النجادي",
    "DISTRICTNAME_EN": "النجادي",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5629",
    "DISTRICTNAME_AR": "الخرسعة",
    "DISTRICTNAME_EN": "الخرسعة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5630",
    "DISTRICTNAME_AR": "النقيع",
    "DISTRICTNAME_EN": "النقيع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5631",
    "DISTRICTNAME_AR": "الحشرج",
    "DISTRICTNAME_EN": "الحشرج",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5632",
    "DISTRICTNAME_AR": "الشقيقة",
    "DISTRICTNAME_EN": "الشقيقة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5633",
    "DISTRICTNAME_AR": "النجاح",
    "DISTRICTNAME_EN": "النجاح",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5634",
    "DISTRICTNAME_AR": "وادى بن هشبل",
    "DISTRICTNAME_EN": "وادى بن هشبل",
    "CITY_ID": "1338",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5635",
    "DISTRICTNAME_AR": "القوباء",
    "DISTRICTNAME_EN": "القوباء",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5636",
    "DISTRICTNAME_AR": "قرية الشط",
    "DISTRICTNAME_EN": "قرية الشط",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5637",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5638",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "القادسية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5639",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "النزهة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5640",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5641",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5642",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "النهضة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5643",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "الرحاب",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5644",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "الروابي",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5645",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5646",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "غرناطة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5647",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5648",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5649",
    "DISTRICTNAME_AR": "حي العزيزيه",
    "DISTRICTNAME_EN": "حي العزيزيه",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5650",
    "DISTRICTNAME_AR": "حي الضباب",
    "DISTRICTNAME_EN": "حي الضباب",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5651",
    "DISTRICTNAME_AR": "حي الفيصليه",
    "DISTRICTNAME_EN": "حي الفيصليه",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5652",
    "DISTRICTNAME_AR": "حي المنهل قري ال نامس",
    "DISTRICTNAME_EN": "حي المنهل قري ال نامس",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5653",
    "DISTRICTNAME_AR": "حي الشرف",
    "DISTRICTNAME_EN": "حي الشرف",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5654",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "حي المروج",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5655",
    "DISTRICTNAME_AR": "حي الاندلس",
    "DISTRICTNAME_EN": "حي الاندلس",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5656",
    "DISTRICTNAME_AR": "النهضه",
    "DISTRICTNAME_EN": "النهضه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5657",
    "DISTRICTNAME_AR": "العزيزيه",
    "DISTRICTNAME_EN": "العزيزيه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5658",
    "DISTRICTNAME_AR": "النزهه",
    "DISTRICTNAME_EN": "النزهه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5659",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "السلامة",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5660",
    "DISTRICTNAME_AR": "الروضه",
    "DISTRICTNAME_EN": "الروضه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5661",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "الشهداء",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5662",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5663",
    "DISTRICTNAME_AR": "الخالديه",
    "DISTRICTNAME_EN": "الخالديه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5664",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "الربيع",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5665",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "الفتح",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5666",
    "DISTRICTNAME_AR": "الفيصليه",
    "DISTRICTNAME_EN": "الفيصليه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5667",
    "DISTRICTNAME_AR": "الربوه 1",
    "DISTRICTNAME_EN": "الربوه 1",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5668",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5669",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "بدر",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5670",
    "DISTRICTNAME_AR": "الأنصار",
    "DISTRICTNAME_EN": "الأنصار",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5671",
    "DISTRICTNAME_AR": "المنتزه 1",
    "DISTRICTNAME_EN": "المنتزه 1",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5672",
    "DISTRICTNAME_AR": "المنتزه 2",
    "DISTRICTNAME_EN": "المنتزه 2",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5673",
    "DISTRICTNAME_AR": "الربوه 2",
    "DISTRICTNAME_EN": "الربوه 2",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5674",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5675",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5676",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "الشرف",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5677",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5678",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5679",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5680",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5681",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "الخليج",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5682",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "المنتزة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5683",
    "DISTRICTNAME_AR": "العدالة",
    "DISTRICTNAME_EN": "العدالة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5684",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5685",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "الزهراء",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5686",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5687",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "المجد",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5688",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "العليا",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5689",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5690",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "الصفاء",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5691",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "المرجان",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5692",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5693",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5694",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5695",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5696",
    "DISTRICTNAME_AR": "الشعب",
    "DISTRICTNAME_EN": "الشعب",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5697",
    "DISTRICTNAME_AR": "الاحمر",
    "DISTRICTNAME_EN": "الاحمر",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5698",
    "DISTRICTNAME_AR": "الشفا",
    "DISTRICTNAME_EN": "الشفا",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5699",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "الربيع",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5700",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5701",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5702",
    "DISTRICTNAME_AR": "وادي الدغيدج",
    "DISTRICTNAME_EN": "وادي الدغيدج",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5703",
    "DISTRICTNAME_AR": "الحى التجارى",
    "DISTRICTNAME_EN": "الحى التجارى",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5704",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "حي المصيف",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5705",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5706",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5707",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5708",
    "DISTRICTNAME_AR": "ارض سلاح الحدود",
    "DISTRICTNAME_EN": "ارض سلاح الحدود",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5709",
    "DISTRICTNAME_AR": "سوق الاغنام",
    "DISTRICTNAME_EN": "سوق الاغنام",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5710",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "حي اليرموك",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5711",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "حي الضاحية",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5712",
    "DISTRICTNAME_AR": "القري السياحية بالسجدة",
    "DISTRICTNAME_EN": "القري السياحية بالسجدة",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5713",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "المنطقة الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5714",
    "DISTRICTNAME_AR": "سوق المواشى",
    "DISTRICTNAME_EN": "سوق المواشى",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5715",
    "DISTRICTNAME_AR": "شرق الصناعية",
    "DISTRICTNAME_EN": "شرق الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5716",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "المنطقة الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5717",
    "DISTRICTNAME_AR": "حى البلد",
    "DISTRICTNAME_EN": "حى البلد",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5718",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5719",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "سوق الماشية",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5720",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "حى النخيل",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5721",
    "DISTRICTNAME_AR": "حى الوسيطاء",
    "DISTRICTNAME_EN": "حى الوسيطاء",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5722",
    "DISTRICTNAME_AR": "حى صلاح الدين",
    "DISTRICTNAME_EN": "حى صلاح الدين",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5723",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "حى السلام",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5724",
    "DISTRICTNAME_AR": "شبيكان",
    "DISTRICTNAME_EN": "شبيكان",
    "CITY_ID": "15817",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5725",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "حي المصيف",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5726",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بمدينة العويقيلة",
    "DISTRICTNAME_EN": "مخطط المنطقة الصناعية بمدينة العويقيلة",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5727",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5728",
    "DISTRICTNAME_AR": "هجرة نعيجان",
    "DISTRICTNAME_EN": "هجرة نعيجان",
    "CITY_ID": "12526",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5729",
    "DISTRICTNAME_AR": "هجرة المركوز",
    "DISTRICTNAME_EN": "هجرة المركوز",
    "CITY_ID": "12517",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5730",
    "DISTRICTNAME_AR": "هجرة ابن عايش",
    "DISTRICTNAME_EN": "هجرة ابن عايش",
    "CITY_ID": "12519",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5731",
    "DISTRICTNAME_AR": "المربع الخدمي",
    "DISTRICTNAME_EN": "المربع الخدمي",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5732",
    "DISTRICTNAME_AR": "مخطط  شرق مدينة العويقيلة",
    "DISTRICTNAME_EN": "مخطط  شرق مدينة العويقيلة",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5733",
    "DISTRICTNAME_AR": "مهاجع حرس الحدود",
    "DISTRICTNAME_EN": "مهاجع حرس الحدود",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5734",
    "DISTRICTNAME_AR": "ادارة التعليم",
    "DISTRICTNAME_EN": "ادارة التعليم",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5735",
    "DISTRICTNAME_AR": "هجرة ابن ثنيان",
    "DISTRICTNAME_EN": "هجرة ابن ثنيان",
    "CITY_ID": "12523",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5736",
    "DISTRICTNAME_AR": "هجرة الكاسب",
    "DISTRICTNAME_EN": "هجرة الكاسب",
    "CITY_ID": "12522",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5737",
    "DISTRICTNAME_AR": "مخطط استثماري جنوب طريف",
    "DISTRICTNAME_EN": "مخطط استثماري جنوب طريف",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5738",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "حي المنتزه",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5739",
    "DISTRICTNAME_AR": "حي مخطط الاستراحات",
    "DISTRICTNAME_EN": "حي مخطط الاستراحات",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5740",
    "DISTRICTNAME_AR": "الحي الصناعي",
    "DISTRICTNAME_EN": "الحي الصناعي",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5741",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5742",
    "DISTRICTNAME_AR": "حي هجرة لوقة",
    "DISTRICTNAME_EN": "حي هجرة لوقة",
    "CITY_ID": "12225",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5743",
    "DISTRICTNAME_AR": "حي المعارض",
    "DISTRICTNAME_EN": "حي المعارض",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5744",
    "DISTRICTNAME_AR": "مخطط الأعلاف و حظائر المواشى بطلعة التمياط",
    "DISTRICTNAME_EN": "مخطط الأعلاف و حظائر المواشى بطلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5745",
    "DISTRICTNAME_AR": "حي مخطط  فيصل",
    "DISTRICTNAME_EN": "حي مخطط  فيصل",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5746",
    "DISTRICTNAME_AR": "حي المنطقه السكنية نموذجية طلعة التمياط",
    "DISTRICTNAME_EN": "حي المنطقه السكنية نموذجية طلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5747",
    "DISTRICTNAME_AR": "هجرة طلعة التمياط",
    "DISTRICTNAME_EN": "هجرة طلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5748",
    "DISTRICTNAME_AR": "مخطط التوسعي",
    "DISTRICTNAME_EN": "مخطط التوسعي",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5749",
    "DISTRICTNAME_AR": "حي المخطط الاداري ",
    "DISTRICTNAME_EN": "حي المخطط الاداري ",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5750",
    "DISTRICTNAME_AR": "العجرمية",
    "DISTRICTNAME_EN": "العجرمية",
    "CITY_ID": "12223",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5751",
    "DISTRICTNAME_AR": "حي مخطط هجرة الحدقه",
    "DISTRICTNAME_EN": "حي مخطط هجرة الحدقه",
    "CITY_ID": "15894",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5752",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5753",
    "DISTRICTNAME_AR": "مخطط تنظيمي لأرض المواطن متعب بن هباس",
    "DISTRICTNAME_EN": "مخطط تنظيمي لأرض المواطن متعب بن هباس",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5754",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس أبو صور",
    "DISTRICTNAME_EN": "مركز خدمة روضة هباس أبو صور",
    "CITY_ID": "15897",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5755",
    "DISTRICTNAME_AR": "مخطط هجرة أبو صور",
    "DISTRICTNAME_EN": "مخطط هجرة أبو صور",
    "CITY_ID": "15897",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5756",
    "DISTRICTNAME_AR": "مخطط حكومي",
    "DISTRICTNAME_EN": "مخطط حكومي",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5757",
    "DISTRICTNAME_AR": "مخطط سكني معتمد",
    "DISTRICTNAME_EN": "مخطط سكني معتمد",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5758",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس طريق رفحاء",
    "DISTRICTNAME_EN": "مركز خدمة روضة هباس طريق رفحاء",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5759",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "مخطط استثماري",
    "CITY_ID": "15900",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5760",
    "DISTRICTNAME_AR": "مخطط تهذيبي لهجرة القصوريات",
    "DISTRICTNAME_EN": "مخطط تهذيبي لهجرة القصوريات",
    "CITY_ID": "15898",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5761",
    "DISTRICTNAME_AR": "مخطط تنظيمي لموقع الإيواء",
    "DISTRICTNAME_EN": "مخطط تنظيمي لموقع الإيواء",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5762",
    "DISTRICTNAME_AR": "مخطط هجرة القصوريات",
    "DISTRICTNAME_EN": "مخطط هجرة القصوريات",
    "CITY_ID": "15898",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5763",
    "DISTRICTNAME_AR": "مخطط هجرة الخشيبي",
    "DISTRICTNAME_EN": "مخطط هجرة الخشيبي",
    "CITY_ID": "15900",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5764",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "مخطط استثماري الاستراحات",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5765",
    "DISTRICTNAME_AR": "ملحق الصناعية والمستودعات ومعارض السيارات",
    "DISTRICTNAME_EN": "ملحق الصناعية والمستودعات ومعارض السيارات",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5766",
    "DISTRICTNAME_AR": "مخطط الصناعية",
    "DISTRICTNAME_EN": "مخطط الصناعية",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5767",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5768",
    "DISTRICTNAME_AR": "مخطط روضة هباس ",
    "DISTRICTNAME_EN": "مخطط روضة هباس ",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5769",
    "DISTRICTNAME_AR": "حي هجرة قيصومة فيحان",
    "DISTRICTNAME_EN": "حي هجرة قيصومة فيحان",
    "CITY_ID": "12224",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5770",
    "DISTRICTNAME_AR": "مخطط الخرسانة و الدراكيل",
    "DISTRICTNAME_EN": "مخطط الخرسانة و الدراكيل",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5771",
    "DISTRICTNAME_AR": "حي مخطط المستودعات",
    "DISTRICTNAME_EN": "حي مخطط المستودعات",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5772",
    "DISTRICTNAME_AR": "حي الدوائر الحكومي",
    "DISTRICTNAME_EN": "حي الدوائر الحكومي",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5773",
    "DISTRICTNAME_AR": "مخطط احواش الاغنام",
    "DISTRICTNAME_EN": "مخطط احواش الاغنام",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5774",
    "DISTRICTNAME_AR": "مخطط معارض السيارات",
    "DISTRICTNAME_EN": "مخطط معارض السيارات",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5775",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "مخطط استثماري",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5776",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "حي الضاحية",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5777",
    "DISTRICTNAME_AR": "حي التشاليح",
    "DISTRICTNAME_EN": "حي التشاليح",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5778",
    "DISTRICTNAME_AR": "حي أم خنصر القديمة",
    "DISTRICTNAME_EN": "حي أم خنصر القديمة",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5779",
    "DISTRICTNAME_AR": "مخطط هجرة السليمانية ",
    "DISTRICTNAME_EN": "مخطط هجرة السليمانية ",
    "CITY_ID": "12516",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "5780",
    "DISTRICTNAME_AR": "الحي الاداري",
    "DISTRICTNAME_EN": "الحي الاداري",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5781",
    "DISTRICTNAME_AR": "مخطط سكني ",
    "DISTRICTNAME_EN": "مخطط سكني ",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5782",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "مخطط استثماري الاستراحات",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5783",
    "DISTRICTNAME_AR": "حي لينة القديمة",
    "DISTRICTNAME_EN": "حي لينة القديمة",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5784",
    "DISTRICTNAME_AR": "اعيوج لينه المخطط",
    "DISTRICTNAME_EN": "اعيوج لينه المخطط",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5785",
    "DISTRICTNAME_AR": "رغوة",
    "DISTRICTNAME_EN": "رغوة",
    "CITY_ID": "15889",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5786",
    "DISTRICTNAME_AR": "حي لينة الجديدة",
    "DISTRICTNAME_EN": "حي لينة الجديدة",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5787",
    "DISTRICTNAME_AR": "حي_009008006",
    "DISTRICTNAME_EN": "حي_009008006",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5788",
    "DISTRICTNAME_AR": "حي أحواش الأغنام",
    "DISTRICTNAME_EN": "حي أحواش الأغنام",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5789",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5790",
    "DISTRICTNAME_AR": "حي هجرة ابن بكر المخطط",
    "DISTRICTNAME_EN": "حي هجرة ابن بكر المخطط",
    "CITY_ID": "1779",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5791",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف بحزم الجلاميد ",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف بحزم الجلاميد ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5792",
    "DISTRICTNAME_AR": "مخطط مركز حزم الجلاميد",
    "DISTRICTNAME_EN": "مخطط مركز حزم الجلاميد",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5793",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بحزم الجلاميد  ",
    "DISTRICTNAME_EN": "مخطط المنطقة الصناعية بحزم الجلاميد  ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5794",
    "DISTRICTNAME_AR": "مخطط استثماري ",
    "DISTRICTNAME_EN": "مخطط استثماري ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5795",
    "DISTRICTNAME_AR": "منفذ الجديدة",
    "DISTRICTNAME_EN": "منفذ الجديدة",
    "CITY_ID": "1781",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5796",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "الرمال",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5797",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "الملقا",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5798",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "الرفاع",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5799",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "اليرموك",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5800",
    "DISTRICTNAME_AR": "مختارة والمراكز الحضرية",
    "DISTRICTNAME_EN": "مختارة والمراكز الحضرية",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5801",
    "DISTRICTNAME_AR": "ج",
    "DISTRICTNAME_EN": "ج",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5802",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "البلدية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5803",
    "DISTRICTNAME_AR": "الحصامة",
    "DISTRICTNAME_EN": "الحصامة",
    "CITY_ID": "14567",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5804",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5805",
    "DISTRICTNAME_AR": "الصفا",
    "DISTRICTNAME_EN": "الصفا",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5806",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "العمارية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5807",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5808",
    "DISTRICTNAME_AR": "المنجارة",
    "DISTRICTNAME_EN": "المنجارة",
    "CITY_ID": "14500",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5809",
    "DISTRICTNAME_AR": "البيطارية",
    "DISTRICTNAME_EN": "البيطارية",
    "CITY_ID": "14494",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5810",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "الروابي",
    "CITY_ID": "14495",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5811",
    "DISTRICTNAME_AR": "القسوم",
    "DISTRICTNAME_EN": "القسوم",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5812",
    "DISTRICTNAME_AR": "القايم",
    "DISTRICTNAME_EN": "القايم",
    "CITY_ID": "14495",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5813",
    "DISTRICTNAME_AR": "الواسط",
    "DISTRICTNAME_EN": "الواسط",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5814",
    "DISTRICTNAME_AR": "المطلع",
    "DISTRICTNAME_EN": "المطلع",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5815",
    "DISTRICTNAME_AR": "العز",
    "DISTRICTNAME_EN": "العز",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5816",
    "DISTRICTNAME_AR": "المنزم",
    "DISTRICTNAME_EN": "المنزم",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5817",
    "DISTRICTNAME_AR": "الغصينية",
    "DISTRICTNAME_EN": "الغصينية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5818",
    "DISTRICTNAME_AR": "الميزاب",
    "DISTRICTNAME_EN": "الميزاب",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5819",
    "DISTRICTNAME_AR": "مخطط منح الأوامر السامية",
    "DISTRICTNAME_EN": "مخطط منح الأوامر السامية",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5820",
    "DISTRICTNAME_AR": "حى التلال",
    "DISTRICTNAME_EN": "حى التلال",
    "CITY_ID": "4278",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5821",
    "DISTRICTNAME_AR": "الامير سلطان",
    "DISTRICTNAME_EN": "الامير سلطان",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5822",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "الشعبة",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5823",
    "DISTRICTNAME_AR": "النهضة 2",
    "DISTRICTNAME_EN": "النهضة 2",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5824",
    "DISTRICTNAME_AR": "خباش",
    "DISTRICTNAME_EN": "خباش",
    "CITY_ID": "4001",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5825",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "الوفاء",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5826",
    "DISTRICTNAME_AR": "الضيقة",
    "DISTRICTNAME_EN": "الضيقة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5827",
    "DISTRICTNAME_AR": "الحبابة",
    "DISTRICTNAME_EN": "الحبابة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5828",
    "DISTRICTNAME_AR": "عصيم",
    "DISTRICTNAME_EN": "عصيم",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5829",
    "DISTRICTNAME_AR": "النقعاء",
    "DISTRICTNAME_EN": "النقعاء",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5830",
    "DISTRICTNAME_AR": "الفيض",
    "DISTRICTNAME_EN": "الفيض",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5831",
    "DISTRICTNAME_AR": "تريمة",
    "DISTRICTNAME_EN": "تريمة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5832",
    "DISTRICTNAME_AR": "الحرشف",
    "DISTRICTNAME_EN": "الحرشف",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5833",
    "DISTRICTNAME_AR": "الشطين",
    "DISTRICTNAME_EN": "الشطين",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5834",
    "DISTRICTNAME_AR": "مريخة",
    "DISTRICTNAME_EN": "مريخة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5835",
    "DISTRICTNAME_AR": "الوسيعة",
    "DISTRICTNAME_EN": "الوسيعة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5836",
    "DISTRICTNAME_AR": "السريحة",
    "DISTRICTNAME_EN": "السريحة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5837",
    "DISTRICTNAME_AR": "حشوة",
    "DISTRICTNAME_EN": "حشوة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5838",
    "DISTRICTNAME_AR": "كتنة",
    "DISTRICTNAME_EN": "كتنة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5839",
    "DISTRICTNAME_AR": "وادى بطا",
    "DISTRICTNAME_EN": "وادى بطا",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5840",
    "DISTRICTNAME_AR": "سبوحة",
    "DISTRICTNAME_EN": "سبوحة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5841",
    "DISTRICTNAME_AR": "وادي عرقان",
    "DISTRICTNAME_EN": "وادي عرقان",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5842",
    "DISTRICTNAME_AR": "وادى صخى",
    "DISTRICTNAME_EN": "وادى صخى",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5843",
    "DISTRICTNAME_AR": "السبت",
    "DISTRICTNAME_EN": "السبت",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5844",
    "DISTRICTNAME_AR": "الوحى",
    "DISTRICTNAME_EN": "الوحى",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5845",
    "DISTRICTNAME_AR": "قشيعة",
    "DISTRICTNAME_EN": "قشيعة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5846",
    "DISTRICTNAME_AR": "ابا الطحين",
    "DISTRICTNAME_EN": "ابا الطحين",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5847",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5848",
    "DISTRICTNAME_AR": "صلاح الدين الغربي",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5849",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5850",
    "DISTRICTNAME_AR": "الهنداوية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5851",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5852",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5853",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "21207",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5854",
    "DISTRICTNAME_AR": "بنبان",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5855",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5856",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5857",
    "DISTRICTNAME_AR": "بترومين",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5858",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5859",
    "DISTRICTNAME_AR": "اللسيب",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "15718",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5860",
    "DISTRICTNAME_AR": "صناعي",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5861",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5862",
    "DISTRICTNAME_AR": "المحجر",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5863",
    "DISTRICTNAME_AR": "أم السلم",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5864",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5865",
    "DISTRICTNAME_AR": "السويفلة",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5866",
    "DISTRICTNAME_AR": "الحفنة",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5867",
    "DISTRICTNAME_AR": "صبابة",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5868",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5869",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5870",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية الحائر",
    "DISTRICTNAME_EN": "NULL",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5380",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5381",
    "DISTRICTNAME_AR": "حى البرقاء",
    "DISTRICTNAME_EN": "14283"
   },
   {
    "DISTRICT_ID": "5382",
    "DISTRICTNAME_AR": "قصر العبدالله",
    "DISTRICTNAME_EN": "14283"
   },
   {
    "DISTRICT_ID": "5383",
    "DISTRICTNAME_AR": "عين ابن فهيد ",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5384",
    "DISTRICTNAME_AR": "طريف الأسياح ",
    "DISTRICTNAME_EN": "14293"
   },
   {
    "DISTRICT_ID": "5385",
    "DISTRICTNAME_AR": "التنومة",
    "DISTRICTNAME_EN": "14295"
   },
   {
    "DISTRICT_ID": "5386",
    "DISTRICTNAME_AR": "البرود",
    "DISTRICTNAME_EN": "14294"
   },
   {
    "DISTRICT_ID": "5387",
    "DISTRICTNAME_AR": "خصيبة ",
    "DISTRICTNAME_EN": "14286"
   },
   {
    "DISTRICT_ID": "5388",
    "DISTRICTNAME_AR": "حى العيون",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5389",
    "DISTRICTNAME_AR": "حى الفهيد",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5390",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "14283"
   },
   {
    "DISTRICT_ID": "5391",
    "DISTRICTNAME_AR": "حى التعاون",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5392",
    "DISTRICTNAME_AR": "حي عين بن فهيد",
    "DISTRICTNAME_EN": "14282"
   },
   {
    "DISTRICT_ID": "5393",
    "DISTRICTNAME_AR": "حى الرياض",
    "DISTRICTNAME_EN": "14283"
   },
   {
    "DISTRICT_ID": "5394",
    "DISTRICTNAME_AR": "الشنانة",
    "DISTRICTNAME_EN": "14293"
   },
   {
    "DISTRICT_ID": "5395",
    "DISTRICTNAME_AR": "الشورقية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5396",
    "DISTRICTNAME_AR": "الجحانية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5397",
    "DISTRICTNAME_AR": "الجراية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5398",
    "DISTRICTNAME_AR": "العليا والهيشة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5399",
    "DISTRICTNAME_AR": "نبعة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5400",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5401",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5402",
    "DISTRICTNAME_AR": "قليطة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5403",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5404",
    "DISTRICTNAME_AR": "المقاطر",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5405",
    "DISTRICTNAME_AR": "سهلة المطلق",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5406",
    "DISTRICTNAME_AR": "النويديس",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5407",
    "DISTRICTNAME_AR": "حي المقبرة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5408",
    "DISTRICTNAME_AR": "محطة معالجة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5409",
    "DISTRICTNAME_AR": "ام عشيرة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5410",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5411",
    "DISTRICTNAME_AR": "اللصافة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5412",
    "DISTRICTNAME_AR": "صفية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5413",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5414",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5415",
    "DISTRICTNAME_AR": "السليمية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5416",
    "DISTRICTNAME_AR": "مزارع العدان",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5417",
    "DISTRICTNAME_AR": "الفايزية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5418",
    "DISTRICTNAME_AR": "مزرعة جنوب",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5419",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5420",
    "DISTRICTNAME_AR": "أم الحمام",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5421",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5422",
    "DISTRICTNAME_AR": "شورقية الجارالله",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5423",
    "DISTRICTNAME_AR": "منتزهات خرطم",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5424",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5425",
    "DISTRICTNAME_AR": "مزرعة شمال",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5426",
    "DISTRICTNAME_AR": "المجصة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5427",
    "DISTRICTNAME_AR": "الحصان",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5428",
    "DISTRICTNAME_AR": "عين العقيلي",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5429",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5430",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5431",
    "DISTRICTNAME_AR": "الدوائر الحكومي",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5432",
    "DISTRICTNAME_AR": "الجادة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5433",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5434",
    "DISTRICTNAME_AR": "سمحة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5435",
    "DISTRICTNAME_AR": "النهير",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5436",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5437",
    "DISTRICTNAME_AR": "الجديدة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5438",
    "DISTRICTNAME_AR": "عسيلة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5439",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5440",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5441",
    "DISTRICTNAME_AR": "الرميلة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5442",
    "DISTRICTNAME_AR": "مزارع السفالة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5443",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5444",
    "DISTRICTNAME_AR": "النصيرة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5445",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5446",
    "DISTRICTNAME_AR": "العلاوة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5447",
    "DISTRICTNAME_AR": "خزامى",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5448",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5449",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5450",
    "DISTRICTNAME_AR": "المنيقع",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5451",
    "DISTRICTNAME_AR": "القفيفة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5452",
    "DISTRICTNAME_AR": "الثليماء",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5453",
    "DISTRICTNAME_AR": "شيحة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5454",
    "DISTRICTNAME_AR": "الفضيلة",
    "DISTRICTNAME_EN": "14261"
   },
   {
    "DISTRICT_ID": "5455",
    "DISTRICTNAME_AR": "حي الملك عبد العزيز",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5456",
    "DISTRICTNAME_AR": "حي الملك سلمان",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5457",
    "DISTRICTNAME_AR": "حي الامير فهد الفيصل",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5458",
    "DISTRICTNAME_AR": "حي الملك عبدالله",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5459",
    "DISTRICTNAME_AR": "حي الملك خالد",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5460",
    "DISTRICTNAME_AR": "حي الملك سعود",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5461",
    "DISTRICTNAME_AR": "حي الملك فهد",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5462",
    "DISTRICTNAME_AR": "حي الملك فيصل",
    "DISTRICTNAME_EN": "3581"
   },
   {
    "DISTRICT_ID": "5463",
    "DISTRICTNAME_AR": "33ج",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5464",
    "DISTRICTNAME_AR": "الامية",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5465",
    "DISTRICTNAME_AR": "الغماس2",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5466",
    "DISTRICTNAME_AR": "الغماس1",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5467",
    "DISTRICTNAME_AR": "ضب روضان",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5468",
    "DISTRICTNAME_AR": "34ج",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5469",
    "DISTRICTNAME_AR": "البصر3",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5470",
    "DISTRICTNAME_AR": "الجديدات",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5471",
    "DISTRICTNAME_AR": "ضراس",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5472",
    "DISTRICTNAME_AR": "العقيلات",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5473",
    "DISTRICTNAME_AR": "31ج",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5474",
    "DISTRICTNAME_AR": "الدعيشة",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5475",
    "DISTRICTNAME_AR": "البصر2",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5476",
    "DISTRICTNAME_AR": "العاقول",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5477",
    "DISTRICTNAME_AR": "البصر1",
    "DISTRICTNAME_EN": "13976"
   },
   {
    "DISTRICT_ID": "5478",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5479",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5480",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5481",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5482",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5483",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "11983"
   },
   {
    "DISTRICT_ID": "5484",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "11969"
   },
   {
    "DISTRICT_ID": "5485",
    "DISTRICTNAME_AR": "حي النخيل",
    "DISTRICTNAME_EN": "11969"
   },
   {
    "DISTRICT_ID": "5486",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "11969"
   },
   {
    "DISTRICT_ID": "5487",
    "DISTRICTNAME_AR": "حي الازدهار",
    "DISTRICTNAME_EN": "11969"
   },
   {
    "DISTRICT_ID": "5488",
    "DISTRICTNAME_AR": "حي الملك فهد ",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5489",
    "DISTRICTNAME_AR": "حي النهضة",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5490",
    "DISTRICTNAME_AR": "حي السلام",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5491",
    "DISTRICTNAME_AR": "حي القدس",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5492",
    "DISTRICTNAME_AR": "حي الملك عبدالله ",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5493",
    "DISTRICTNAME_AR": "حي الربوة",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5494",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "11890"
   },
   {
    "DISTRICT_ID": "5495",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "11880"
   },
   {
    "DISTRICT_ID": "5496",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "11880"
   },
   {
    "DISTRICT_ID": "5497",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "11880"
   },
   {
    "DISTRICT_ID": "5498",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "11880"
   },
   {
    "DISTRICT_ID": "5499",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "11875"
   },
   {
    "DISTRICT_ID": "5500",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "11875"
   },
   {
    "DISTRICT_ID": "5501",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "14254"
   },
   {
    "DISTRICT_ID": "5502",
    "DISTRICTNAME_AR": "التراثي",
    "DISTRICTNAME_EN": "11880"
   },
   {
    "DISTRICT_ID": "5503",
    "DISTRICTNAME_AR": "المدارس",
    "DISTRICTNAME_EN": "6121"
   },
   {
    "DISTRICT_ID": "5504",
    "DISTRICTNAME_AR": "مخطط هجرة النظيم",
    "DISTRICTNAME_EN": "13432"
   },
   {
    "DISTRICT_ID": "5505",
    "DISTRICTNAME_AR": "درب الإبل",
    "DISTRICTNAME_EN": "13428"
   },
   {
    "DISTRICT_ID": "5506",
    "DISTRICTNAME_AR": "القلت",
    "DISTRICTNAME_EN": "13429"
   },
   {
    "DISTRICT_ID": "5507",
    "DISTRICTNAME_AR": "حي هجرة السليمانية",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5508",
    "DISTRICTNAME_AR": " الوايليه",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5509",
    "DISTRICTNAME_AR": "الصفيرى",
    "DISTRICTNAME_EN": "13434"
   },
   {
    "DISTRICT_ID": "5510",
    "DISTRICTNAME_AR": "الفاو",
    "DISTRICTNAME_EN": "11035"
   },
   {
    "DISTRICT_ID": "5511",
    "DISTRICTNAME_AR": "سامودة",
    "DISTRICTNAME_EN": "11032"
   },
   {
    "DISTRICT_ID": "5512",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5513",
    "DISTRICTNAME_AR": "الذيبية",
    "DISTRICTNAME_EN": "11031"
   },
   {
    "DISTRICT_ID": "5514",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5515",
    "DISTRICTNAME_AR": "البطيحانية",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5516",
    "DISTRICTNAME_AR": "أم عشر",
    "DISTRICTNAME_EN": "11034"
   },
   {
    "DISTRICT_ID": "5517",
    "DISTRICTNAME_AR": "أم قليب",
    "DISTRICTNAME_EN": "11028"
   },
   {
    "DISTRICT_ID": "5518",
    "DISTRICTNAME_AR": "أحواش الأغنام",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5519",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5520",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5521",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5522",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5523",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5524",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5525",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5526",
    "DISTRICTNAME_AR": "الهداية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5527",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5528",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "11027"
   },
   {
    "DISTRICT_ID": "5529",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5530",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5531",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5532",
    "DISTRICTNAME_AR": "الفيوان",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5533",
    "DISTRICTNAME_AR": "الصداوي",
    "DISTRICTNAME_EN": "11023"
   },
   {
    "DISTRICT_ID": "5534",
    "DISTRICTNAME_AR": "ام كداد",
    "DISTRICTNAME_EN": "11024"
   },
   {
    "DISTRICT_ID": "5535",
    "DISTRICTNAME_AR": "المتياهة الجنوبية",
    "DISTRICTNAME_EN": "11022"
   },
   {
    "DISTRICT_ID": "5536",
    "DISTRICTNAME_AR": "الحيرة",
    "DISTRICTNAME_EN": "11026"
   },
   {
    "DISTRICT_ID": "5537",
    "DISTRICTNAME_AR": "مناخ",
    "DISTRICTNAME_EN": "11025"
   },
   {
    "DISTRICT_ID": "5538",
    "DISTRICTNAME_AR": "معرج السوبان",
    "DISTRICTNAME_EN": "11019"
   },
   {
    "DISTRICT_ID": "5539",
    "DISTRICTNAME_AR": "خبيراء",
    "DISTRICTNAME_EN": "11020"
   },
   {
    "DISTRICT_ID": "5540",
    "DISTRICTNAME_AR": "السعيرة",
    "DISTRICTNAME_EN": "11018"
   },
   {
    "DISTRICT_ID": "5541",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5542",
    "DISTRICTNAME_AR": "مركز الحماطيات",
    "DISTRICTNAME_EN": "13433"
   },
   {
    "DISTRICT_ID": "5543",
    "DISTRICTNAME_AR": "مركز الجبو",
    "DISTRICTNAME_EN": "13433"
   },
   {
    "DISTRICT_ID": "5544",
    "DISTRICTNAME_AR": "مركز المسناه",
    "DISTRICTNAME_EN": "13433"
   },
   {
    "DISTRICT_ID": "5545",
    "DISTRICTNAME_AR": "مركز أم عمارة",
    "DISTRICTNAME_EN": "13430"
   },
   {
    "DISTRICT_ID": "5546",
    "DISTRICTNAME_AR": "الرقعي",
    "DISTRICTNAME_EN": "13431"
   },
   {
    "DISTRICT_ID": "5547",
    "DISTRICTNAME_AR": "حي مخطط أرض المستودعات المعدل",
    "DISTRICTNAME_EN": "13427"
   },
   {
    "DISTRICT_ID": "5548",
    "DISTRICTNAME_AR": "الصناعية الأولي و الثانية - رأس تنوره",
    "DISTRICTNAME_EN": "19373"
   },
   {
    "DISTRICT_ID": "5549",
    "DISTRICTNAME_AR": "الصناعية الثالثة - رأس تنوره",
    "DISTRICTNAME_EN": "19373"
   },
   {
    "DISTRICT_ID": "5550",
    "DISTRICTNAME_AR": "عبدالله فؤاد",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5551",
    "DISTRICTNAME_AR": "الجود",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5552",
    "DISTRICTNAME_AR": "الإسكان الشمالي",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5553",
    "DISTRICTNAME_AR": "الإسكان الجنوبي",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5554",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5555",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5556",
    "DISTRICTNAME_AR": "حي البساتين - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5557",
    "DISTRICTNAME_AR": "حي أشبيلية - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5558",
    "DISTRICTNAME_AR": "حي الربوة - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5559",
    "DISTRICTNAME_AR": "حي غرناطة- بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5560",
    "DISTRICTNAME_AR": "حي قرطبة - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5561",
    "DISTRICTNAME_AR": "حي الروضة - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5562",
    "DISTRICTNAME_AR": "حي الصناعية - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5563",
    "DISTRICTNAME_AR": "حي الزهور - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5564",
    "DISTRICTNAME_AR": "حي النخيل - بقيق",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5565",
    "DISTRICTNAME_AR": "تابع ل ارامكو",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5566",
    "DISTRICTNAME_AR": "مركز هدباء",
    "DISTRICTNAME_EN": "13433"
   },
   {
    "DISTRICT_ID": "5567",
    "DISTRICTNAME_AR": "نادي الفروسية",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5568",
    "DISTRICTNAME_AR": "عمليات الخفجي المشتركة",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5569",
    "DISTRICTNAME_AR": "أحواش الابل والغنام",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5570",
    "DISTRICTNAME_AR": "مخطط مجمع الكليات",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5571",
    "DISTRICTNAME_AR": "مخطط 71",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5572",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5573",
    "DISTRICTNAME_AR": "مدينة الملك عبد العزيز",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5574",
    "DISTRICTNAME_AR": "حي الصناعية الاولى",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5575",
    "DISTRICTNAME_AR": "حي اللؤلؤة",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5576",
    "DISTRICTNAME_AR": "حي الحمراء",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5577",
    "DISTRICTNAME_AR": "حي الأمل",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5578",
    "DISTRICTNAME_AR": "حي الأمير نايف",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5579",
    "DISTRICTNAME_AR": "حي الحرس الوطني",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5580",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5581",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5582",
    "DISTRICTNAME_AR": "حي الخالدية",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5583",
    "DISTRICTNAME_AR": "حي الدرة",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5584",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5585",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5586",
    "DISTRICTNAME_AR": "حي الخزامي",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5587",
    "DISTRICTNAME_AR": "إسكان الجمرك",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5588",
    "DISTRICTNAME_AR": "مخطط 87",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5589",
    "DISTRICTNAME_AR": "حي_005033041",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5590",
    "DISTRICTNAME_AR": "حي005033042",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5591",
    "DISTRICTNAME_AR": "حي_005033043",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5592",
    "DISTRICTNAME_AR": "حي_005033044",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5593",
    "DISTRICTNAME_AR": "ارض مملوكة",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5594",
    "DISTRICTNAME_AR": "حي الزهور ",
    "DISTRICTNAME_EN": "11868"
   },
   {
    "DISTRICT_ID": "5595",
    "DISTRICTNAME_AR": "حي سوق الماشية _النعيرية",
    "DISTRICTNAME_EN": "11073"
   },
   {
    "DISTRICT_ID": "5596",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "11073"
   },
   {
    "DISTRICT_ID": "5597",
    "DISTRICTNAME_AR": "حي الروابي",
    "DISTRICTNAME_EN": "11073"
   },
   {
    "DISTRICT_ID": "5598",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "11073"
   },
   {
    "DISTRICT_ID": "5599",
    "DISTRICTNAME_AR": "حي الشهداء",
    "DISTRICTNAME_EN": "11073"
   },
   {
    "DISTRICT_ID": "5600",
    "DISTRICTNAME_AR": "حي اشبيليا",
    "DISTRICTNAME_EN": "11036"
   },
   {
    "DISTRICT_ID": "5601",
    "DISTRICTNAME_AR": "صناعية العريفي - الجبيل",
    "DISTRICTNAME_EN": "19366"
   },
   {
    "DISTRICT_ID": "5602",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "11045"
   },
   {
    "DISTRICT_ID": "5603",
    "DISTRICTNAME_AR": "حي صناعية الظهران",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5604",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "11048"
   },
   {
    "DISTRICT_ID": "5605",
    "DISTRICTNAME_AR": "حي الروضة - عين دار القديمة ",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5606",
    "DISTRICTNAME_AR": "حي الأثير- عين دار القديمة",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5607",
    "DISTRICTNAME_AR": "حي النخيل - عين دار القديمة ",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5608",
    "DISTRICTNAME_AR": "حي المنار - عين دار القديمة ",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5609",
    "DISTRICTNAME_AR": "حي الجزيرة - عين دار القديمة ",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5610",
    "DISTRICTNAME_AR": "حي الفيحاء - عين دار القديمة",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5611",
    "DISTRICTNAME_AR": "حي الندى - عين دار القديمة ",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5612",
    "DISTRICTNAME_AR": "حي الفردوس - عين دار القديمة",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5613",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "11056"
   },
   {
    "DISTRICT_ID": "5614",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5615",
    "DISTRICTNAME_AR": "الشبوة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5616",
    "DISTRICTNAME_AR": "حليبة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5617",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5618",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5619",
    "DISTRICTNAME_AR": "الجنينة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5620",
    "DISTRICTNAME_AR": "الشريفة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5621",
    "DISTRICTNAME_AR": "عيدان",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5622",
    "DISTRICTNAME_AR": "عقلان",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5623",
    "DISTRICTNAME_AR": "دويرج",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5624",
    "DISTRICTNAME_AR": "ندوان",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5625",
    "DISTRICTNAME_AR": "الثنوء",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5626",
    "DISTRICTNAME_AR": "الخيبر",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5627",
    "DISTRICTNAME_AR": "البرقاء",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5628",
    "DISTRICTNAME_AR": "النجادي",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5629",
    "DISTRICTNAME_AR": "الخرسعة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5630",
    "DISTRICTNAME_AR": "النقيع",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5631",
    "DISTRICTNAME_AR": "الحشرج",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5632",
    "DISTRICTNAME_AR": "الشقيقة",
    "DISTRICTNAME_EN": "9010"
   },
   {
    "DISTRICT_ID": "5633",
    "DISTRICTNAME_AR": "النجاح",
    "DISTRICTNAME_EN": "1339"
   },
   {
    "DISTRICT_ID": "5634",
    "DISTRICTNAME_AR": "وادى بن هشبل",
    "DISTRICTNAME_EN": "1338"
   },
   {
    "DISTRICT_ID": "5635",
    "DISTRICTNAME_AR": "القوباء",
    "DISTRICTNAME_EN": "2694"
   },
   {
    "DISTRICT_ID": "5636",
    "DISTRICTNAME_AR": "قرية الشط",
    "DISTRICTNAME_EN": "2694"
   },
   {
    "DISTRICT_ID": "5637",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5638",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5639",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5640",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5641",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5642",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5643",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5644",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5645",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5646",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5647",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5648",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "8584"
   },
   {
    "DISTRICT_ID": "5649",
    "DISTRICTNAME_AR": "حي العزيزيه",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5650",
    "DISTRICTNAME_AR": "حي الضباب",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5651",
    "DISTRICTNAME_AR": "حي الفيصليه",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5652",
    "DISTRICTNAME_AR": "حي المنهل قري ال نامس",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5653",
    "DISTRICTNAME_AR": "حي الشرف",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5654",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5655",
    "DISTRICTNAME_AR": "حي الاندلس",
    "DISTRICTNAME_EN": "6011"
   },
   {
    "DISTRICT_ID": "5656",
    "DISTRICTNAME_AR": "النهضه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5657",
    "DISTRICTNAME_AR": "العزيزيه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5658",
    "DISTRICTNAME_AR": "النزهه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5659",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5660",
    "DISTRICTNAME_AR": "الروضه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5661",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5662",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5663",
    "DISTRICTNAME_AR": "الخالديه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5664",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5665",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5666",
    "DISTRICTNAME_AR": "الفيصليه",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5667",
    "DISTRICTNAME_AR": "الربوه 1",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5668",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5669",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5670",
    "DISTRICTNAME_AR": "الأنصار",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5671",
    "DISTRICTNAME_AR": "المنتزه 1",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5672",
    "DISTRICTNAME_AR": "المنتزه 2",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5673",
    "DISTRICTNAME_AR": "الربوه 2",
    "DISTRICTNAME_EN": "2792"
   },
   {
    "DISTRICT_ID": "5674",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5675",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5676",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5677",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5678",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5679",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5680",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5681",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5682",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5683",
    "DISTRICTNAME_AR": "العدالة",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5684",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5685",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5686",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5687",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "7453"
   },
   {
    "DISTRICT_ID": "5688",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5689",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5690",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5691",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5692",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5693",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5694",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5695",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5696",
    "DISTRICTNAME_AR": "الشعب",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5697",
    "DISTRICTNAME_AR": "الاحمر",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5698",
    "DISTRICTNAME_AR": "الشفا",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5699",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5700",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5701",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5702",
    "DISTRICTNAME_AR": "وادي الدغيدج",
    "DISTRICTNAME_EN": "12587"
   },
   {
    "DISTRICT_ID": "5703",
    "DISTRICTNAME_AR": "الحى التجارى",
    "DISTRICTNAME_EN": "12578"
   },
   {
    "DISTRICT_ID": "5704",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5705",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5706",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5707",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5708",
    "DISTRICTNAME_AR": "ارض سلاح الحدود",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5709",
    "DISTRICTNAME_AR": "سوق الاغنام",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5710",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5711",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "12679"
   },
   {
    "DISTRICT_ID": "5712",
    "DISTRICTNAME_AR": "القري السياحية بالسجدة",
    "DISTRICTNAME_EN": "12655"
   },
   {
    "DISTRICT_ID": "5713",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "13674"
   },
   {
    "DISTRICT_ID": "5714",
    "DISTRICTNAME_AR": "سوق المواشى",
    "DISTRICTNAME_EN": "13674"
   },
   {
    "DISTRICT_ID": "5715",
    "DISTRICTNAME_AR": "شرق الصناعية",
    "DISTRICTNAME_EN": "13674"
   },
   {
    "DISTRICT_ID": "5716",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "13674"
   },
   {
    "DISTRICT_ID": "5717",
    "DISTRICTNAME_AR": "حى البلد",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5718",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5719",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5720",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5721",
    "DISTRICTNAME_AR": "حى الوسيطاء",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5722",
    "DISTRICTNAME_AR": "حى صلاح الدين",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5723",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "15883"
   },
   {
    "DISTRICT_ID": "5724",
    "DISTRICTNAME_AR": "شبيكان",
    "DISTRICTNAME_EN": "15817"
   },
   {
    "DISTRICT_ID": "5725",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5726",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بمدينة العويقيلة",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5727",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5728",
    "DISTRICTNAME_AR": "هجرة نعيجان",
    "DISTRICTNAME_EN": "12526"
   },
   {
    "DISTRICT_ID": "5729",
    "DISTRICTNAME_AR": "هجرة المركوز",
    "DISTRICTNAME_EN": "12517"
   },
   {
    "DISTRICT_ID": "5730",
    "DISTRICTNAME_AR": "هجرة ابن عايش",
    "DISTRICTNAME_EN": "12519"
   },
   {
    "DISTRICT_ID": "5731",
    "DISTRICTNAME_AR": "المربع الخدمي",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5732",
    "DISTRICTNAME_AR": "مخطط  شرق مدينة العويقيلة",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5733",
    "DISTRICTNAME_AR": "مهاجع حرس الحدود",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5734",
    "DISTRICTNAME_AR": "ادارة التعليم",
    "DISTRICTNAME_EN": "12518"
   },
   {
    "DISTRICT_ID": "5735",
    "DISTRICTNAME_AR": "هجرة ابن ثنيان",
    "DISTRICTNAME_EN": "12523"
   },
   {
    "DISTRICT_ID": "5736",
    "DISTRICTNAME_AR": "هجرة الكاسب",
    "DISTRICTNAME_EN": "12522"
   },
   {
    "DISTRICT_ID": "5737",
    "DISTRICTNAME_AR": "مخطط استثماري جنوب طريف",
    "DISTRICTNAME_EN": "12190"
   },
   {
    "DISTRICT_ID": "5738",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "12190"
   },
   {
    "DISTRICT_ID": "5739",
    "DISTRICTNAME_AR": "حي مخطط الاستراحات",
    "DISTRICTNAME_EN": "12190"
   },
   {
    "DISTRICT_ID": "5740",
    "DISTRICTNAME_AR": "الحي الصناعي",
    "DISTRICTNAME_EN": "12190"
   },
   {
    "DISTRICT_ID": "5741",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5742",
    "DISTRICTNAME_AR": "حي هجرة لوقة",
    "DISTRICTNAME_EN": "12225"
   },
   {
    "DISTRICT_ID": "5743",
    "DISTRICTNAME_AR": "حي المعارض",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5744",
    "DISTRICTNAME_AR": "مخطط الأعلاف و حظائر المواشى بطلعة التمياط",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5745",
    "DISTRICTNAME_AR": "حي مخطط  فيصل",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5746",
    "DISTRICTNAME_AR": "حي المنطقه السكنية نموذجية طلعة التمياط",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5747",
    "DISTRICTNAME_AR": "هجرة طلعة التمياط",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5748",
    "DISTRICTNAME_AR": "مخطط التوسعي",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5749",
    "DISTRICTNAME_AR": "حي المخطط الاداري ",
    "DISTRICTNAME_EN": "1776"
   },
   {
    "DISTRICT_ID": "5750",
    "DISTRICTNAME_AR": "العجرمية",
    "DISTRICTNAME_EN": "12223"
   },
   {
    "DISTRICT_ID": "5751",
    "DISTRICTNAME_AR": "حي مخطط هجرة الحدقه",
    "DISTRICTNAME_EN": "15894"
   },
   {
    "DISTRICT_ID": "5752",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "12200"
   },
   {
    "DISTRICT_ID": "5753",
    "DISTRICTNAME_AR": "مخطط تنظيمي لأرض المواطن متعب بن هباس",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5754",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس أبو صور",
    "DISTRICTNAME_EN": "15897"
   },
   {
    "DISTRICT_ID": "5755",
    "DISTRICTNAME_AR": "مخطط هجرة أبو صور",
    "DISTRICTNAME_EN": "15897"
   },
   {
    "DISTRICT_ID": "5756",
    "DISTRICTNAME_AR": "مخطط حكومي",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5757",
    "DISTRICTNAME_AR": "مخطط سكني معتمد",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5758",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس طريق رفحاء",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5759",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "15900"
   },
   {
    "DISTRICT_ID": "5760",
    "DISTRICTNAME_AR": "مخطط تهذيبي لهجرة القصوريات",
    "DISTRICTNAME_EN": "15898"
   },
   {
    "DISTRICT_ID": "5761",
    "DISTRICTNAME_AR": "مخطط تنظيمي لموقع الإيواء",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5762",
    "DISTRICTNAME_AR": "مخطط هجرة القصوريات",
    "DISTRICTNAME_EN": "15898"
   },
   {
    "DISTRICT_ID": "5763",
    "DISTRICTNAME_AR": "مخطط هجرة الخشيبي",
    "DISTRICTNAME_EN": "15900"
   },
   {
    "DISTRICT_ID": "5764",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5765",
    "DISTRICTNAME_AR": "ملحق الصناعية والمستودعات ومعارض السيارات",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5766",
    "DISTRICTNAME_AR": "مخطط الصناعية",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5767",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5768",
    "DISTRICTNAME_AR": "مخطط روضة هباس ",
    "DISTRICTNAME_EN": "15899"
   },
   {
    "DISTRICT_ID": "5769",
    "DISTRICTNAME_AR": "حي هجرة قيصومة فيحان",
    "DISTRICTNAME_EN": "12224"
   },
   {
    "DISTRICT_ID": "5770",
    "DISTRICTNAME_AR": "مخطط الخرسانة و الدراكيل",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5771",
    "DISTRICTNAME_AR": "حي مخطط المستودعات",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5772",
    "DISTRICTNAME_AR": "حي الدوائر الحكومي",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5773",
    "DISTRICTNAME_AR": "مخطط احواش الاغنام",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5774",
    "DISTRICTNAME_AR": "مخطط معارض السيارات",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5775",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5776",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5777",
    "DISTRICTNAME_AR": "حي التشاليح",
    "DISTRICTNAME_EN": "12226"
   },
   {
    "DISTRICT_ID": "5778",
    "DISTRICTNAME_AR": "حي أم خنصر القديمة",
    "DISTRICTNAME_EN": "12515"
   },
   {
    "DISTRICT_ID": "5779",
    "DISTRICTNAME_AR": "مخطط هجرة السليمانية ",
    "DISTRICTNAME_EN": "12516"
   },
   {
    "DISTRICT_ID": "5780",
    "DISTRICTNAME_AR": "الحي الاداري",
    "DISTRICTNAME_EN": "12515"
   },
   {
    "DISTRICT_ID": "5781",
    "DISTRICTNAME_AR": "مخطط سكني ",
    "DISTRICTNAME_EN": "12515"
   },
   {
    "DISTRICT_ID": "5782",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "12515"
   },
   {
    "DISTRICT_ID": "5783",
    "DISTRICTNAME_AR": "حي لينة القديمة",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5784",
    "DISTRICTNAME_AR": "اعيوج لينه المخطط",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5785",
    "DISTRICTNAME_AR": "رغوة",
    "DISTRICTNAME_EN": "15889"
   },
   {
    "DISTRICT_ID": "5786",
    "DISTRICTNAME_AR": "حي لينة الجديدة",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5787",
    "DISTRICTNAME_AR": "حي_009008006",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5788",
    "DISTRICTNAME_AR": "حي أحواش الأغنام",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5789",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "15888"
   },
   {
    "DISTRICT_ID": "5790",
    "DISTRICTNAME_AR": "حي هجرة ابن بكر المخطط",
    "DISTRICTNAME_EN": "1779"
   },
   {
    "DISTRICT_ID": "5791",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف بحزم الجلاميد ",
    "DISTRICTNAME_EN": "1782"
   },
   {
    "DISTRICT_ID": "5792",
    "DISTRICTNAME_AR": "مخطط مركز حزم الجلاميد",
    "DISTRICTNAME_EN": "1782"
   },
   {
    "DISTRICT_ID": "5793",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بحزم الجلاميد  ",
    "DISTRICTNAME_EN": "1782"
   },
   {
    "DISTRICT_ID": "5794",
    "DISTRICTNAME_AR": "مخطط استثماري ",
    "DISTRICTNAME_EN": "1782"
   },
   {
    "DISTRICT_ID": "5795",
    "DISTRICTNAME_AR": "منفذ الجديدة",
    "DISTRICTNAME_EN": "1781"
   },
   {
    "DISTRICT_ID": "5796",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "12220"
   },
   {
    "DISTRICT_ID": "5797",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "12220"
   },
   {
    "DISTRICT_ID": "5798",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "12220"
   },
   {
    "DISTRICT_ID": "5799",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "12220"
   },
   {
    "DISTRICT_ID": "5800",
    "DISTRICTNAME_AR": "مختارة والمراكز الحضرية",
    "DISTRICTNAME_EN": "14463"
   },
   {
    "DISTRICT_ID": "5801",
    "DISTRICTNAME_AR": "ج",
    "DISTRICTNAME_EN": "14463"
   },
   {
    "DISTRICT_ID": "5802",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5803",
    "DISTRICTNAME_AR": "الحصامة",
    "DISTRICTNAME_EN": "14567"
   },
   {
    "DISTRICT_ID": "5804",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5805",
    "DISTRICTNAME_AR": "الصفا",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5806",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5807",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5808",
    "DISTRICTNAME_AR": "المنجارة",
    "DISTRICTNAME_EN": "14500"
   },
   {
    "DISTRICT_ID": "5809",
    "DISTRICTNAME_AR": "البيطارية",
    "DISTRICTNAME_EN": "14494"
   },
   {
    "DISTRICT_ID": "5810",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "14495"
   },
   {
    "DISTRICT_ID": "5811",
    "DISTRICTNAME_AR": "القسوم",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5812",
    "DISTRICTNAME_AR": "القايم",
    "DISTRICTNAME_EN": "14495"
   },
   {
    "DISTRICT_ID": "5813",
    "DISTRICTNAME_AR": "الواسط",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5814",
    "DISTRICTNAME_AR": "المطلع",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5815",
    "DISTRICTNAME_AR": "العز",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5816",
    "DISTRICTNAME_AR": "المنزم",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5817",
    "DISTRICTNAME_AR": "الغصينية",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5818",
    "DISTRICTNAME_AR": "الميزاب",
    "DISTRICTNAME_EN": "14490"
   },
   {
    "DISTRICT_ID": "5819",
    "DISTRICTNAME_AR": "مخطط منح الأوامر السامية",
    "DISTRICTNAME_EN": "3736"
   },
   {
    "DISTRICT_ID": "5820",
    "DISTRICTNAME_AR": "حى التلال",
    "DISTRICTNAME_EN": "4278"
   },
   {
    "DISTRICT_ID": "5821",
    "DISTRICTNAME_AR": "الامير سلطان",
    "DISTRICTNAME_EN": "4014"
   },
   {
    "DISTRICT_ID": "5822",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "4014"
   },
   {
    "DISTRICT_ID": "5823",
    "DISTRICTNAME_AR": "النهضة 2",
    "DISTRICTNAME_EN": "4014"
   },
   {
    "DISTRICT_ID": "5824",
    "DISTRICTNAME_AR": "خباش",
    "DISTRICTNAME_EN": "4001"
   },
   {
    "DISTRICT_ID": "5825",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "3731"
   },
   {
    "DISTRICT_ID": "5826",
    "DISTRICTNAME_AR": "الضيقة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5827",
    "DISTRICTNAME_AR": "الحبابة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5828",
    "DISTRICTNAME_AR": "عصيم",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5829",
    "DISTRICTNAME_AR": "النقعاء",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5830",
    "DISTRICTNAME_AR": "الفيض",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5831",
    "DISTRICTNAME_AR": "تريمة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5832",
    "DISTRICTNAME_AR": "الحرشف",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5833",
    "DISTRICTNAME_AR": "الشطين",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5834",
    "DISTRICTNAME_AR": "مريخة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5835",
    "DISTRICTNAME_AR": "الوسيعة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5836",
    "DISTRICTNAME_AR": "السريحة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5837",
    "DISTRICTNAME_AR": "حشوة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5838",
    "DISTRICTNAME_AR": "كتنة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5839",
    "DISTRICTNAME_AR": "وادى بطا",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5840",
    "DISTRICTNAME_AR": "سبوحة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5841",
    "DISTRICTNAME_AR": "وادي عرقان",
    "DISTRICTNAME_EN": "3731"
   },
   {
    "DISTRICT_ID": "5842",
    "DISTRICTNAME_AR": "وادى صخى",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5843",
    "DISTRICTNAME_AR": "السبت",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5844",
    "DISTRICTNAME_AR": "الوحى",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5845",
    "DISTRICTNAME_AR": "قشيعة",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5846",
    "DISTRICTNAME_AR": "ابا الطحين",
    "DISTRICTNAME_EN": "3795"
   },
   {
    "DISTRICT_ID": "5847",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "3755"
   }
  ];

export const DEMO_VENDORS = ['amakkncomapny']; //amakknbroker

export const MAX_VIDEO_SIZE = 10;