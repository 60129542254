import { ArrowBack } from "@mui/icons-material";
import { Alert, Box, CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
// import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import AppLayout from "../../components/layouts/app-layout";
import { Building, Buildings, Check } from "../../constants/icons";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveEjarForm } from "../../api/userApi";
import { toast } from "react-toastify";
import LoadingButton from "../../components/global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const Ejar = () => {
 
    const [loadingButton, setLoadingButton] = useState(false);
    const { vendor } = useAuth();
    const [step, setStep] = useState(-1);
    const [completedSteps, setCompletedSteps] = useState([]);
    // const [leaseTerm, setLeaseTerm] = useState('');
    const { t } = useTranslation();
    const history = useNavigate();
    const [data, setData] = useState({
        contractType: '',
        requesterType: '',
        lessorName: '',
        lesseeName: '',
        lessorID: '',
        lesseeID: '',
        lessorPhone: '',
        lesseePhone: '',
        lessorDate: moment(),
        lesseeDate: moment(),
        region: '',
        city: '',
        buildingNumber: '',
        zipCode: '',
        additionalNumber: '',
        contactNumber: '',
        realEstateDeedType: '',
        realEstateDeedNumber: '',
        realEstateDeedDate: moment(),
        realEstateType: '',
        realEstateUsageType: '',
        commercialRegistrationNumber: '',
        propertyType: '',
        propertyNumber: '',
        floorNumber: '',
        hasKitchenCabinets: '',
        electricityMeterNumber: '',
        waterMeterNumber: '',
        numberOfBedRooms: '',
        numberOfBathRooms: '',
        numberOfKitchens: '',
        numberOfStorageRooms: '',
        numberOfHalls: '',
        numberOfMaidsRooms: '',
        numberOfSittingRooms: '',
        numberOfIndependentVentilationRooms: '',
        leaseStartDate: moment(),
        leaseTerm: '',
        lessorIBANNumber: '',
        bankName: '',
        payingRentMethod: '',
        rentalValue: '',
        waterConsumptionCharges: '',
        electricityMeterCurrentReading: '',
        insuranceAmount: '',
        dailyFineAmount: ''
    });
    const [errors, setErrors] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false,
        step7: false
    });
    // const [uploadingImageDeed, setUploadingImageDeed] = useState(false);
    const steps = ['نوع العقد', "معلومات أساسية", "العنوان الوطني للعقار", " معلومات الصكَ", "معلومات العقار ", "معلومات عقد الإيجار", "الإنتهاء"];
    let publicUrl = process.env.REACT_APP_URL + "/";


    // function uploadImage(file) {
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file[0]);
    //     reader.onload = () => {
    //       let fileInfo = {
    //         pathWithFileName: 'assets/img/ejar/' + file[0].name,
    //         base64Data: reader.result,
    //         typeOfFile: file[0].type,
    //         userId: token,
    //         language: "0",
    //       };
    //       uploadWlFile(fileInfo);
    //     };
    //   }
    
    //   function upload(file) {
    //     let reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //       let fileInfo = {
    //         pathWithFileName: 'assets/img/ejar/' + file.name,
    //         base64Data: reader.result,
    //         typeOfFile: file.type,
    //         userId: token,
    //         language: "0",
    //       };
    //       uploadWlFile(fileInfo);
    //     };
    //   }
    
    //   function uploadWlFile(fileInfo) {
    //     setUploadingImageDeed(true);
    //     uploadWlFileBase64(fileInfo).then((resp) => {
    //       if (resp.resCode === 0) {
    //          toast.success("تم رفع الصورة بنجاح");
    //           setData({ ...data, realEstateDeedImage: resp.response.url });
    //       } else {
    //         toast.error(resp.resStr);
    //       }
    //       setUploadingImageDeed(false);
    //     });
    // }
    
    return (
        <AppLayout pageTitle="عقد إيجار">
            {step === -1 ? (
                <div className="ejar page-wrapper page-width">
                    <div className="row page-wrapper-without-top">
                   
                        <div className="col-12 col-lg-7 details-wrapper">
                            <h2>أهلاً بك في نموذج إبرام عقد إيجار إلكتروني المُدار من قبل أماكن </h2>
                            <p>
                                يرجى التقيّد بالتعليمات التالية, لضمان تعبئة النموذج بالشكل الصحيح:
                            </p>
                            <div className="option">
                                <span>
                                    <Check />
                                </span>
                                <span>
                                    الأسئلة التي بجانبها نجمة حمراء إلزامية, ويجب الإجابة عليها
                                </span>
                            </div>
                            <div className="option">
                                <span>
                                    <Check />
                                </span>
                                <span>
                                    الأسئلة التي لا يوجد بجانبها نجمة حمراء إختيارية ، وفي حال عدم تعبئتها، لا يحق لأطراف العقد مطالبتنا بها بعد إرسال العقد إلى أبشر.
                                </span>
                            </div>
                            <div className="option">
                                <span>
                                    <Check />
                                </span>
                                <span>
                                    على طرفي العقد قراءة العقد قبل التوثيق عن طريق أبشر وفي حال وجود أي تعديل يتم رفض العقد عن طريق أبشر والتواصل مع المكتب.
                                </span>
                            </div>
                            <div className='btns-wrapper '>
                                <button
                                    className='btn primary-btn same-width'
                                    onClick={(e) => {
                                        setStep(0);
                                    }}>
                                    {t("Next")}
                                </button>
                 
                            </div>
                            <p className="term-paragraph">
                                من خلال ضغطك على زر التالي, فإنت قرأتُ التعليمات و توافق عليها
                            </p>
                        </div>
                        <div className="col-12 col-lg-5 bg-cover py-5 py-lg-0 position-relative" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
                            <div className="img-container">
                                <img src={publicUrl + 'assets/img/logo/ejar-logo.svg'} alt="ejar" />
                            </div>
                            <img className="moh-logo" src={publicUrl + 'assets/img/logo/ministry-of-housing.png'} alt="ministry of housing" />
                       
                        </div>
                       
                     
                    </div>
                </div>
            )
                :
                (
                    <div className="row ejar page-wrapper-without-top page-wrapper-column pt-5 pb-5 px-3"  >
                        <div className="col-12">
                            <div className="container">
                                <Box sx={{ width: "100%", margin: '40px auto 32px', padding: 0 }}>
                                    <h2 className="page-title">عقد الإيجار</h2>
                                    <Stepper className="onboarding-steps" activeStep={step} nonLinear sx={{ width: "100%" }}>
                                        {steps.map((label, index) => (
                                            <Step key={label} completed={completedSteps[index] !== undefined ? true : false}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    <div className="onboarding-mobile-steps">
                                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <CircularProgress
                                                size={60}
                                                thickness={6}
                                                variant="determinate" value={(100 / 7) * step} />
                                            <Box
                                                sx={{
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0,
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography variant="caption" component="div" color="text.secondary">
                                                    خطوة
                                                    <br />
                                                    {`${step}/7`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <p>{steps[step]}</p>
                                    </div>
                                </Box>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            {step === 0 && (
                                <>
                                    <h3 className="modal-heading">نوع العقد</h3>
                                    <p className="modal-desc">
                                        من فضلك اختر نوع العقد
                                    </p>
                                </>
                            )}
                                     

                            {step === 1 && (
                                <>
                                    <h3 className="modal-heading">معلومات أساسية</h3>
                                    <p className="modal-desc">
                                        يجب عليك ادخال جميع الحقول الإلزامية
                                    </p>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <h3 className="modal-heading">العنوان الوطني للعقار</h3>
                                    <p className="modal-desc">
                                        يجب عليك ادخال جميع الحقول الإلزامية
                                    </p>
                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <h3 className="modal-heading">معلومات الصكَ</h3>
                                    <p className="modal-desc">
                                        يجب عليك ادخال جميع الحقول الإلزامية
                                    </p>
                                </>
                            )}
                            {step === 4 && (
                                <>
                                    <h3 className="modal-heading">معلومات العقار</h3>
                                    <p className="modal-desc">
                                        يجب عليك ادخال جميع الحقول الإلزامية
                                    </p>
                                </>
                            )}
                            {step === 5 && (
                                <>
                                    <h3 className="modal-heading">معلومات عقد الإيجار</h3>
                                    <p className="modal-desc">
                                        المقابل المالي (الرسوم الحكومية) لعقود الإيجار الإلكترونية 125 ريال في السنة الواحدة
                                    </p>
                                </>
                            )}
                            {step === 6 && (
                                <>
                                    <h3 className="modal-heading">تم بنجاح</h3>
                                    <p className="modal-desc">
                                        شكراً لكّ, تمّت تعبئة النموذج بنجاح, من فضلك اضفط على زر حفظ العقد لإتمام العملية بنجاح
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="col-11">
                            <div className="container" dir="rtl">
                                {step === 0 && (
                                    <div className="d-flex flex-column w-100 text-right">
                                        <FormControl
                                            className="col-12 col-md-11 p-0 mb-3"
                                            required error={errors.step1 && data?.contractType === ''}>
                                            {/* <FormLabel id="contract-type">نوع العقد</FormLabel> */}
                                            <RadioGroup
                                                name="contract-type"
                                                row
                                                style={{ justifyContent: 'space-evenly' }}
                                                value={data.contractType}
                                                onChange={(e) => setData({ ...data, contractType: e.target.value })
                                                }
                                            >
                                                <div className={`custom-radio col-12 col-md-5 mb-3 ${data?.contractType === 'سكني' ? "active" : ""}`}
                                                    onClick={(e) => setData({ ...data, contractType: 'سكني' })}
                                                >
                                                    <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                                                        <Building />
                                                        <span>عقد سكني</span>
                                                    </div>
                                                    <div>
                                                        <FormControlLabel value="سكني" control={<Radio />} />
                                                    </div>
                                                   


                                                </div>
                                                <div className={`custom-radio col-12 col-md-5 mb-3 ${data?.contractType === 'تجاري' ? "active" : ""}`}
                                                    onClick={(e) => setData({ ...data, contractType: 'تجاري' })}>
                                            
                                                    <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                                                        <Buildings />
                                                        <span>عقد تجاري</span>
                                                    </div>
                                                    <div>
                                                        <FormControlLabel value="تجاري" control={<Radio />} />
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                            <FormHelperText>
                                                {errors.step1 && data?.contractType === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                if (data?.contractType !== '') {
                                                    setErrors({ ...errors, step1: false });
                                                    setStep(step + 1); setCompletedSteps([0]);
                                                } else {
                                                    setErrors({ ...errors, step1: true });
                                                }
                                            }}>
                                                {t("Next")} <ArrowBack />
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {step === 1 && (
                                    <div className="text-right row mx-0 justify-content-around">
                                        <FormControl
                                            required
                                            error={errors.step2 && data?.requesterType === ''}
                                            className="col-12 col-md-11 mb-3"
                                        >
                                            <FormLabel id="requester-type">صفة مقدّم الطّلب</FormLabel>
                                            <RadioGroup
                                                row
                                                name="requester-type"
                                                value={data.requesterType}
                                                onChange={(e) => setData({ ...data, requesterType: e.target.value })
                                                }
                                            >
                                                <FormControlLabel value="مؤجّر" control={<Radio />} label="مؤجّر" />
                                                <FormControlLabel value="مستأجر" control={<Radio />} label="مستأجر" />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {errors.step2 && data?.requesterType === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lessorName === ''}
                                            className="col-12 col-md-5 mb-3"
                                        >
                                            <TextField
                                                name="lessorName"
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                required
                                                label="اسم المؤجّر"
                                                value={data.lessorName}
                                                onChange={(e) => setData({ ...data, lessorName: e.target.value })
                                                }
                                            />
                                            <FormHelperText>
                                                {errors.step2 && data?.lessorName === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lesseeName === ''}
                                            className="col-12 col-md-5 mb-3"
                                        >
                                            <TextField
                                                name="lesseeName"
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                required
                                                label="اسم المستأجر"
                                                value={data.lesseeName}
                                                onChange={(e) => setData({ ...data, lesseeName: e.target.value })
                                                }
                                            />
                                            <FormHelperText>
                                                {errors.step2 && data?.lesseeName === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                       
                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lessorID === ''}
                                            dir="ltr"
                                            className="col-12 col-md-5 mb-3"
                                        >
                                            <TextField
                                                name="lessor-id"
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                required
                                                type="number"
                                                label="رقم الهويّة الوطنية للمؤجّر"
                                                value={data.lessorID}
                                                onChange={(e) => setData({ ...data, lessorID: e.target.value })
                                                }
                                            />
                                            <FormHelperText dir="rtl">
                                                {errors.step2 && data?.lessorID === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                      {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lesseeID === ''}
                                            dir="ltr"
                                            className="col-12 col-md-5 mb-3"
                                        >
                                            <TextField
                                                name="lessee-id"
                                                variant="outlined"
                                                size="small"
                                                required
                                                type="number"
                                                InputLabelProps={{ shrink: true }}
                                                label="رقم الهويّة الوطنية للمستأجر"
                                                value={data.lesseeID}
                                                onChange={(e) => setData({ ...data, lesseeID: e.target.value })
                                                }
                                            />
                                            <FormHelperText dir="rtl">
                                                {errors.step2 && data?.lesseeID === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lessorPhone === ''}
                                            dir="ltr"
                                            className="col-12 col-md-5 p-0 mb-3"
                                        >
                                            <TextField
                                                name="lessor-phone"
                                                variant="outlined"
                                                size="small"
                                                required
                                                InputLabelProps={{ shrink: true }}
                                                label="رقم جوال المؤجّر المسجّل بأبشر"
                                                value={data.lessorPhone}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+966</InputAdornment>,
                                                }}
                                                onChange={(e) => setData({ ...data, lessorPhone: e.target.value })
                                                }
                                            />
                                            <FormHelperText dir="rtl">
                                                {errors.step2 && data?.lessorPhone === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        <FormControl
                                            required
                                            error={errors.step2 && data?.lesseePhone === ''}
                                            dir="ltr"
                                            className="col-12 col-md-5 p-0 mb-3"
                                        >
                                            <TextField
                                                name="lessee-phone"
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                required
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+966</InputAdornment>,
                                                }}
                                                label="رقم جوال المستأجر المسجّل بأبشر"
                                                value={data.lesseePhone}
                                                onChange={(e) => setData({ ...data, lesseePhone: e.target.value })
                                                }
                                            />
                                            <FormHelperText dir="rtl">
                                                {errors.step2 && data?.lesseePhone === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>

                                        {/* <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <FormControl
                                                required
                                                error={errors.step2 && data?.lessorDate === ''}
                                                dir="ltr"
                                                className="col-12 col-md-5 p-0 mb-3"
                                            >
                                                <MobileDatePicker
                                                    label="تاريخ ميلاد المؤجّر"
                                                    name="lessor-date"
                                                    inputFormat="DD/MM/YYYY"
                                                    disableFuture
                                                    value={data?.lessorDate ? data.lessorDate : moment()}
                                                    onChange={(val) => setData({ ...data, lessorDate: val })}
                                                    renderInput={(params) => <TextField variant="outlined"
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                        required {...params} />}
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step2 && data?.lessorDate === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </LocalizationProvider>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                        >
                                            <FormControl
                                                required
                                                error={errors.step2 && data?.lesseeDate === ''}
                                                dir="ltr"
                                                className="col-12 col-md-5 p-0 mb-3">
                                                <MobileDatePicker
                                                    label="تاريخ ميلاد المستأجر"
                                                    name="lessee-date"
                                                    disableFuture
                                                    inputFormat="DD/MM/YYYY"
                                                    value={data?.lesseeDate ? data.lesseeDate : moment()}

                                                    onChange={(val) => setData({ ...data, lesseeDate: val })}
                                                    renderInput={(params) => <TextField variant="outlined"
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                        required {...params} />}
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step2 && data?.lesseeDate === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </LocalizationProvider> */}
                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                if (data?.requesterType !== '' && data?.lesseeName !== '' && data?.lessorName !== '' && data?.lessorID !== '' && data?.lesseeID !== ''
                                                    && data?.lessorPhone !== '' && data?.lesseePhone !== '' && data?.lessorDate !== '' && data?.lesseeDate !== '') {
                                                    setErrors({ ...errors, step2: false });
                                                    setStep(step + 1); setCompletedSteps([0, 1]);
                                                } else {
                                                    setErrors({ ...errors, step2: true });
                                                }
                                            }}>
                                                {t("Next")} <ArrowBack />
                                            </button>
                  
                                        </div>
         
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="text-right row mx-0 justify-content-around">
                                        <div className="col-12 row mx-0 justify-content-around">

                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                            >
                                                <InputLabel id="region-label">{t("Region")}</InputLabel>
                                                <Select
                                                    name="region"
                                                    labelId="region-label"
                                                    variant="outlined"
                                                    size="small"
                                                    label={t("Region")}
                                                    value={data.region}
                                                    onChange={(e) => setData({ ...data, region: e.target.value })
                                                    }
                                                >
                                                    <MenuItem value="" disabled selected>اختر المنطقة</MenuItem>
                                                    <MenuItem value="الرياض">الرياض</MenuItem>
                                                    <MenuItem value="مكـة المكرمة">مكـة المكرمة</MenuItem>
                                                    <MenuItem value="المدينة المنورة">المدينة المنورة</MenuItem>
                                                    <MenuItem value="القصيم">القصيم</MenuItem>
                                                    <MenuItem value="المنطقة الشرقية">المنطقة الشرقية</MenuItem>
                                                    <MenuItem value="عسير">عسير</MenuItem>
                                                    <MenuItem value="تبــوك">تبــوك</MenuItem>
                                                    <MenuItem value="حائل">حائل</MenuItem>
                                                    <MenuItem value="الحدود الشمالية">الحدود الشمالية</MenuItem>
                                                    <MenuItem value="جازان">جازان</MenuItem>
                                                    <MenuItem value="نجران">نجران</MenuItem>
                                                    <MenuItem value="الباحة">الباحة</MenuItem>
                                                    <MenuItem value="الجـوف">الجـوف</MenuItem>
                                                </Select>
                                            </FormControl>
 
                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                            >
                                                <InputLabel id="city-label">{t("City")}</InputLabel>

                                                <TextField
                                                    name="city"
                                                    variant="outlined"
                                                    // type="number"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    label={t("City")}
                                                    value={data.city}
                                                    onChange={(e) => setData({ ...data, city: e.target.value })
                                                    }
                                                />
                                                {/* <Select
                                                name="city"
                                                labelId="city-label"
                                                variant="outlined"
                                                size="small"
                                                label={t("City")}
                                                value={data.city}
                                                onChange={(e) => setData({ ...data, city: e.target.value })
                                                }
                                            >
                                                  <MenuItem value="" disabled selected>اختر المدينة</MenuItem>
                                                  <MenuItem value="الرياض">الرياض</MenuItem>
                                                  <MenuItem value="جدة">جدة</MenuItem>
                                                <MenuItem value="مكـة المكرمة">مكـة المكرمة</MenuItem>
                                                <MenuItem value="المدينة المنورة">المدينة المنورة</MenuItem>
                                                <MenuItem value="سلطانة">سلطانة</MenuItem>
                                                <MenuItem value="الدمام">الدمام</MenuItem>
                                                <MenuItem value="الطائف">الطائف</MenuItem>
                                                <MenuItem value="تبــوك">تبــوك</MenuItem>
                                                <MenuItem value="الخرج">الخرج</MenuItem>
                                                <MenuItem value="بريدة">بريدة</MenuItem>
                                                <MenuItem value="خميس مشيط">خميس مشيط</MenuItem>
                                                <MenuItem value="الهفوف">الهفوف</MenuItem>
                                                <MenuItem value="المبرز">المبرز</MenuItem>
                                                <MenuItem value="حفر الباطن">حفر الباطن</MenuItem>
                                                <MenuItem value="حائل">حائل</MenuItem>
                                                <MenuItem value="نجران">نجران</MenuItem>
                                                <MenuItem value="الجبيل">الجبيل</MenuItem>
                                                <MenuItem value="أبها">أبها</MenuItem>
                                                <MenuItem value="ينبع">ينبع</MenuItem>
                                                <MenuItem value="الخبر">الخبر</MenuItem>
                                                <MenuItem value="عنيزة">عنيزة</MenuItem>
                                                <MenuItem value="عرار">عرار</MenuItem>
                                                <MenuItem value="سكاكا">سكاكا</MenuItem>
                                                <MenuItem value="جازان">جازان</MenuItem>
                                                <MenuItem value="القريات">القريات</MenuItem>
                                                <MenuItem value="الظهران">الظهران</MenuItem>
                                                <MenuItem value="القطيف">القطيف</MenuItem>
                                                <MenuItem value="الباحة">الباحة</MenuItem>
                                            </Select> */}
                                            </FormControl>
 
                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                                dir="ltr"
                                            >
                                                <TextField
                                                    name="buildingNumber"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="رقم البناء"
                                                    value={data.buildingNumber}
                                                    onChange={(e) => setData({ ...data, buildingNumber: e.target.value })
                                                    }
                                                />
                                           
                                    
                                            </FormControl>
                                        </div>
                                        <div className="col-12 row mx-0 justify-content-around">

                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                                dir="ltr"
                                            >
                                                <TextField
                                                    name="zipCode"
                                                    variant="outlined"
                                                    type="number"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="الرمز البريدي"
                                                    value={data.zipCode}
                                                    onChange={(e) => setData({ ...data, zipCode: e.target.value })
                                                    }
                                                />
                                           
                                    
                                            </FormControl>
                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                                dir="ltr"
                                            >
                                                <TextField
                                                    name="additionalNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="الرقم الإضافي"
                                                    value={data.additionalNumber}
                                                    onChange={(e) => setData({ ...data, additionalNumber: e.target.value })
                                                    }
                                                />
                                           
                                    
                                            </FormControl>
                                            <FormControl
                                                className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                                dir="ltr"
                                            >
                                                <TextField
                                                    name="contactNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">+966</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    label="رقم التواصل"
                                                    value={data.contactNumber}
                                                    onChange={(e) => setData({ ...data, contactNumber: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0, 1]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                setStep(step + 1); setCompletedSteps([0, 1, 2]);
                                            }}>
                                                {t("Next")} <ArrowBack />
                                            </button>
                  
                                        </div>
         
                                    </div>
                                )}
                                {step === 3 && (
                                    <div className="text-right row mx-0 justify-content-around">
                                        <FormControl
                                            required
                                            error={errors.step4 && data?.realEstateDeedType === ''}
                                            className="col-12 col-md-11  p-0 mb-3"
                                        >
                                            <FormLabel id="realEstateDeedType">نوع الصكّ</FormLabel>
                                            <RadioGroup
                                                row
                                                name="realEstateDeedType"
                                                value={data.realEstateDeedType}
                                                onChange={(e) => {
                                                    setData({ ...data, realEstateDeedType: e.target.value });
                                                    setErrors({ ...errors, step4: false });
                                                }
                                                }
                                            >
                                                <FormControlLabel value="إلكتروني" control={<Radio />} label="إلكتروني" />
                                                <FormControlLabel value="ورقي" control={<Radio />} label="ورقي" />
                                                <FormControlLabel value="العقار من خلال البنك" control={<Radio />} label="العقار من خلال البنك" />
                                            </RadioGroup>
                                            <FormHelperText>
                                                {errors.step4 && data?.realEstateDeedType === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                        {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        {(data?.realEstateDeedType === 'إلكتروني' || data?.realEstateDeedType === "العقار من خلال البنك")
                                            && (<div className="col-12 row m-0 p-0 justify-content-around">
                                                <FormControl
                                                required
                                                error={errors.step4 && data?.realEstateDeedNumber === ''}
                                                dir="ltr"
                                                className={`col-12 p-0 mb-3 ${data?.realEstateDeedType === "العقار من خلال البنك" ? "col-md-7 col-lg-3" : 'col-md-5'}`}>
                                                    <TextField
                                                        name="realEstateDeedNumber"
                                                        variant="outlined"
                                                        size="small"
                                                        InputLabelProps={{ shrink: true }}
                                                        required
                                                        type="number"
                                                        label="رقم الصكّ"
                                                        value={data.realEstateDeedNumber}
                                                        onChange={(e) => setData({ ...data, realEstateDeedNumber: e.target.value })
                                                        }
                                                    />
                                                    <FormHelperText dir="rtl">
                                                        {errors.step4 && data?.realEstateDeedNumber === '' ?
                                                            <Alert severity="error" className="custom-alert">
                                                                {t("ThisFieldIsMandatory")}
                                                            </Alert>
                                                            : ''
                                                        }
                                                    </FormHelperText>
                                                </FormControl>
                                                {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <FormControl
                                                    className={`col-12 p-0 mb-3 ${data?.realEstateDeedType === "العقار من خلال البنك" ? "col-md-7 col-lg-3" : 'col-md-5'}`}
                                                        required
                                                        error={errors.step4 && data?.realEstateDeedDate === ''}
                                                        dir="ltr">
                                                        <MobileDatePicker
                                                            disableFuture
                                                            label="تاريخ إصدار الصكّ"
                                                            name="realEstateDeedDate"
                                                            inputFormat="DD/MM/YYYY"
                                                            value={data.realEstateDeedDate ? data.realEstateDeedDate : moment()}
                                                            onChange={(val) => setData({ ...data, realEstateDeedDate: val })}
                                                            renderInput={(params) => <TextField variant="outlined"
                                                                size="small"
                                                                InputLabelProps={{ shrink: true }}
                                                                required {...params} />}
                                                        />
                                                        <FormHelperText dir="rtl">
                                                            {errors.step4 && data?.realEstateDeedDate === '' ?
                                                                <Alert severity="error" className="custom-alert">
                                                                    {t("ThisFieldIsMandatory")}
                                                                </Alert>
                                                                : ''
                                                            }
                                                        </FormHelperText>
                                                    </FormControl>
                                                </LocalizationProvider> */}
                                                {data?.realEstateDeedType === "العقار من خلال البنك" && (
                                                    <FormControl
                                                        className="col-12 col-md-7 col-lg-3 p-0 mb-3"
                                                        required error={errors.step4 && data?.commercialRegistrationNumber === ''} dir="ltr">
                                                        <TextField
                                                            name="commercialRegistrationNumber"
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            InputLabelProps={{ shrink: true }}
                                                            type="number"
                                                            label="رقم السجل التجاري"
                                                            value={data.commercialRegistrationNumber}
                                                            onChange={(e) => setData({ ...data, commercialRegistrationNumber: e.target.value })
                                                            }
                                                        />
                                                        <FormHelperText dir="rtl">
                                                            {errors.step4 && data?.commercialRegistrationNumber === '' ?
                                                                <Alert severity="error" className="custom-alert">
                                                                    {t("ThisFieldIsMandatory")}
                                                                </Alert>
                                                                : ''
                                                            }
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            </div>
                                            )}
                                        {data?.realEstateDeedType === 'ورقي' && (
                                            <>
                                                <FormControl
                                                    required
                                                    error={errors.step4 && data?.realEstateType === ''}
                                                    className="col-12 col-md-5 p-0 mb-3"

                                                >
                                                    <InputLabel id="realEstateType-label">{t("PropertyType")}</InputLabel>
                                                    <Select
                                                        name="realEstateType"
                                                        labelId="realEstateType-label"
                                                        variant="outlined"
                                                        size="small"
                                                        label={t("PropertyType")}
                                                        value={data.realEstateType}
                                                        onChange={(e) => setData({ ...data, realEstateType: e.target.value })
                                                        }
                                                    >
                                                        <MenuItem value="عمارة">عمارة</MenuItem>
                                                        <MenuItem value="فيلا">فيلا</MenuItem>
                                                        <MenuItem value="بيت شعبي">بيت شعبي</MenuItem>
                                                        <MenuItem value="آخرى">آخرى</MenuItem>
                                                    </Select>
                                                    <FormHelperText dir="rtl">
                                                        {errors.step4 && data?.realEstateType === '' ?
                                                            <Alert severity="error" className="custom-alert">
                                                                {t("ThisFieldIsMandatory")}
                                                            </Alert>
                                                            : ''
                                                        }
                                                    </FormHelperText>
                                                </FormControl>
                                                <FormControl
                                                    required
                                                    error={errors.step4 && data?.realEstateUsageType === ''}
                                                    className="col-12 col-md-5 p-0 mb-3"
                                                >
                                                    <InputLabel id="realEstateUsageType-label">طريقة استخدام العقار</InputLabel>
                                                    <Select
                                                        name="realEstateUsageType"
                                                        labelId="realEstateUsageType-label"
                                                        variant="outlined"
                                                        size="small"
                                                        label="طريقة استخدام العقار"
                                                        value={data.realEstateUsageType}
                                                        onChange={(e) => setData({ ...data, realEstateUsageType: e.target.value })
                                                        }
                                                    >
                                                        <MenuItem value="سكن عائلات">سكن عائلات</MenuItem>
                                                        <MenuItem value="سكن أفراد">سكن أفراد</MenuItem>
                                                        <MenuItem value="سكني - تجاري">سكني - تجاري</MenuItem>
                                                        <MenuItem value="لا أعلم">لا أعلم</MenuItem>
                                                    </Select>
                                                    <FormHelperText dir="rtl">
                                                        {errors.step4 && data?.realEstateUsageType === '' ?
                                                            <Alert severity="error" className="custom-alert">
                                                                {t("ThisFieldIsMandatory")}
                                                            </Alert>
                                                            : ''
                                                        }
                                                    </FormHelperText>
                                                </FormControl>
                                            </>
                                        )}

                                        {/* <div
                                  className='col-12 mb-4 my-lg-0  col-lg-4 p-0 position-relative image'>
                                  {!uploadingImageDeed && !data?.realEstateDeedImage &&
                                    <ImageUploader
                                      label=''
                                      buttonText="انقر لاختيار صورة"
                                      withIcon={true}
                                      singleImage
                                      onChange={(file) => uploadImage(file)}
                                      imgExtension={[".jpg", ".png", ".jpeg"]}
                                      maxFileSize={5242880}
                                    />}
                                  {uploadingImageDeed && (
                                    <img
                                      className="loading-img"
                                      src={publicUrl + "assets/img/loding.gif"}
                                      alt='loading-model'></img>
                                  )}
                                  {!uploadingImageDeed && data?.realEstateDeedImage && (<>
                                    <img src={data?.realEstateDeedImage} alt="model-img" className="model-img" />
                                    <label
                                      htmlFor={`contained-button-file-1`}
                                      className='custom-btn'>
                                      <Input
                                        accept="image/*"
                                        id={`contained-button-file-1`}
                                        style={{display:'none'}}
                                        onChange={(e) => upload(e.target.files[0])}
                                        type='file'
                                      />
                                      {t("ChangeImage")}
                                    </label>
                                  </>)}
                                        </div> */}
                                        
                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0, 1, 2]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                
                                                if (data?.realEstateDeedType !== '' && (
                                                    (data?.realEstateDeedType === 'ورقي' && data?.realEstateType !== '' && data?.realEstateUsageType !== '') ||
                                                    (data?.realEstateDeedType === 'إلكتروني' && data?.realEstateDeedNumber !== '' && data?.realEstateDeedDate !== '') ||
                                                    (data?.realEstateDeedType === "العقار من خلال البنك" && data?.realEstateDeedNumber !== '' && data?.realEstateDeedDate !== '' && data?.commercialRegistrationNumber !== '')
                                                )
                                                ) {
                                                    setErrors({ ...errors, step4: false });
                                                    setStep(step + 1); setCompletedSteps([0, 1, 2, 3]);
                                                } else {
                                                    setErrors({ ...errors, step4: true });
                                                }
                                            }}>
                                            {t("Next")} <ArrowBack />
                                            </button>
                  
                                        </div>
         
                                    </div>
                                )}
                                {step === 4 && (
                                    <div className="text-right row mx-0 justify-content-around">
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <FormControl
                                                required
                                                error={errors.step5 && data?.propertyType === ''}
                                                className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <InputLabel id="propertyType-label">{t("PropertyType")}</InputLabel>
                                                <Select
                                                    name="propertyType"
                                                    labelId="propertyType-label"
                                                    variant="outlined"
                                                    size="small"
                                                    label={t("PropertyType")}
                                                    value={data.propertyType}
                                                    onChange={(e) => setData({ ...data, propertyType: e.target.value })
                                                    }
                                                >
                                                    <MenuItem value="عمارة">عمارة</MenuItem>
                                                    <MenuItem value="فيلا">فيلا</MenuItem>
                                                    <MenuItem value="بيت شعبي">بيت شعبي</MenuItem>
                                                    <MenuItem value="آخرى">آخرى</MenuItem>
                                                </Select>
                                                <FormHelperText dir="rtl">
                                                    {errors.step5 && data?.propertyType === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                   
                                            <FormControl
                                                required
                                                error={errors.step5 && data?.propertyNumber === ''}
                                                dir="ltr"
                                                className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                                                           
                                                <TextField
                                                    name="propertyNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    required
                                                    type="number"
                                                    label="رقم العقار"
                                                    value={data.propertyNumber}
                                                    onChange={(e) => setData({ ...data, propertyNumber: e.target.value })
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step5 && data?.propertyNumber === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl
                                                required
                                                error={errors.step5 && data?.floorNumber === ''}
                                                dir="ltr"
                                                className="col-12 col-md-7 col-lg-3 mb-3 p-0">

                                                <TextField
                                                    name="floorNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="رقم الدّور"
                                                    value={data.floorNumber}
                                                    onChange={(e) => setData({ ...data, floorNumber: e.target.value })
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step5 && data?.floorNumber === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </div>
                                        <Divider variant="middle" className="col-11 mt-4 mb-3" />
                                        <h5 className="col-11 mb-4 p-0">الرجاء تحديد كلّ ممّا يلي بدقّة (اختياري)</h5>
                                      
                                        <FormControl
                                            className="col-12 col-md-11 mb-3 p-0">
                                            <FormLabel id="hasKitchenCabinets">هلّ يوجد دواليب للمطبخ مركبّة من قِبَل المؤجّر؟</FormLabel>
                                            <RadioGroup
                                                row
                                                name="hasKitchenCabinets"
                                                value={data.hasKitchenCabinets}
                                                onChange={(e) =>
                                                    setData({ ...data, hasKitchenCabinets: e.target.value })}
                                            >
                                                <FormControlLabel value="نعم" control={<Radio />} label="نعم" />
                                                <FormControlLabel value="لا" control={<Radio />} label="لا" />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl
                                            className="col-12 col-md-11 mb-3 p-0">
                                            <FormLabel id="hasAirCondition">هلّ يوجد مكيّفات؟</FormLabel>
                                            <RadioGroup
                                                row
                                                name="hasAirCondition"
                                                value={data.hasAirCondition}
                                                onChange={(e) =>
                                                    setData({ ...data, hasAirCondition: e.target.value })}
                                            >
                                                <FormControlLabel value="نعم" control={<Radio />} label="نعم" />
                                                <FormControlLabel value="لا" control={<Radio />} label="لا" />
                                            </RadioGroup>
                                            
                                        </FormControl>
                                        
                                       
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <Divider variant="middle" className="col-11 mt-4 mb-3" />
                                            <h5 className="col-11 mb-4 p-0">الرجاء تحديد عدد كلّ ممّا يلي بدقّة (اختياري)</h5>
                                       
                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfBedRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد غرف النوم"
                                                    value={data.numberOfBedRooms}
                                                    onChange={(e) => setData({ ...data, numberOfBedRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        
                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfBathRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد دورات المياه"
                                                    value={data.numberOfBathRooms}
                                                    onChange={(e) => setData({ ...data, numberOfBathRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfKitchens"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="عدد المطابخ"
                                                    value={data.numberOfKitchens}
                                                    onChange={(e) => setData({ ...data, numberOfKitchens: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">

                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfStorageRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد غرف التخزين"
                                                    value={data.numberOfStorageRooms}
                                                    onChange={(e) => setData({ ...data, numberOfStorageRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfHalls"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد الصالات"
                                                    value={data.numberOfHalls}
                                                    onChange={(e) => setData({ ...data, numberOfHalls: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="numberOfMaidsRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد غرف الخدم"
                                                    value={data.numberOfMaidsRooms}
                                                    onChange={(e) => setData({ ...data, numberOfMaidsRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">

                                            <FormControl dir="ltr"
                                                className={`col-12 mb-3 p-0 ${data?.hasAirCondition === 'نعم' ? "col-md-7 col-lg-3" : "col-md-5"}`}>
                                                <TextField
                                                    name="numberOfSittingRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="عدد غرف الجلوس"
                                                    value={data.numberOfSittingRooms}
                                                    onChange={(e) => setData({ ...data, numberOfSittingRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl dir="ltr" className={`col-12 mb-3 p-0 ${data?.hasAirCondition === 'نعم' ? "col-md-7 col-lg-3" : "col-md-5"}`}>

                                                <TextField
                                                    name="numberOfIndependentVentilationRooms"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="عدد غرف التّهوية المستقلّة"
                                                    value={data.numberOfIndependentVentilationRooms}
                                                    onChange={(e) => setData({ ...data, numberOfIndependentVentilationRooms: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            {data?.hasAirCondition === 'نعم' &&
                                                <FormControl dir="ltr" className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                    <TextField
                                                        name="airConditionNumber"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        InputLabelProps={{ shrink: true }}
                                                        label="عدد المكيّفات"
                                                        value={data.airConditionNumber}
                                                        onChange={(e) => setData({ ...data, airConditionNumber: e.target.value })
                                                        }
                                                    />
                                                </FormControl>
                                            }
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <FormControl
                                                className="col-12 col-md-5 mb-3 p-0" dir="ltr">
                                                <TextField
                                                    name="electricityMeterNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="رقم عدّاد الكهرباء"
                                                    value={data.electricityMeterNumber}
                                                    onChange={(e) => setData({ ...data, electricityMeterNumber: e.target.value })
                                                    }
                                                />
                                               
                                            </FormControl>
                                            <FormControl
                                                className="col-12 col-md-5 mb-3 p-0" dir="ltr">
                                                <TextField
                                                    name="waterMeterNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="رقم عدّاد المياه"
                                                    value={data.waterMeterNumber}
                                                    onChange={(e) => setData({ ...data, waterMeterNumber: e.target.value })
                                                    }
                                                />
                                              
                                            </FormControl>
                                        </div>
                                        <div className="next-back-btns mt-5">
                 
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0, 1, 2, 3]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                if (data?.propertyType !== '' && data?.propertyNumber !== '' && data?.floorNumber !== '') {
                                                    setErrors({ ...errors, step5: false });
                                                    setStep(step + 1); setCompletedSteps([0, 1, 2, 3, 4]);
                                                } else {
                                                    setErrors({ ...errors, step5: true });
                                                }
                                            }}>
                                                {t("Next")} <ArrowBack />
                                            </button>
                  
                                        </div>
         
                                    </div>
                                )}
                                {step === 5 && (
                                    <div className="text-right row mx-0 justify-content-around">
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <FormControl required error={errors.step6 && data?.leaseStartDate === ''} dir="ltr"
                                                    className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                    <MobileDatePicker
                                                        label="تاريخ بداية عقد الإيجار بالميلادي"
                                                        name="leaseStartDate"
                                                        disablePast
                                                        inputFormat="DD/MM/YYYY"
                                                        value={data.leaseStartDate ? data.leaseStartDate : moment()}
                                                        onChange={(val) => setData({ ...data, leaseStartDate: val })}
                                                        renderInput={(params) => <TextField variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            required {...params} />}
                                                    />
                                                    <FormHelperText dir="rtl">
                                                        {errors.step6 && data?.leaseStartDate === '' ?
                                                            <Alert severity="error" className="custom-alert">
                                                               {t("ThisFieldIsMandatory")}
                                                            </Alert>
                                                            : ''
                                                        }
                                                    </FormHelperText>
                                                </FormControl>
                                            </LocalizationProvider> */}
                                            <FormControl className="col-12 col-md-7 col-lg-3 mb-3 p-0" required error={errors.step6 && data?.payingRentMethod === ''}>
                                                <InputLabel id="payingRentMethod-label">طريقة دفع الإيجار</InputLabel>
                                                <Select
                                                    name="payingRentMethod"
                                                    labelId="payingRentMethod-label"
                                                    variant="outlined"
                                                    size="small"
                                                    label="طريقة دفع الإيجار"
                                                    value={data.payingRentMethod}
                                                    onChange={(e) => setData({ ...data, payingRentMethod: e.target.value })
                                                    }
                                                >
                                                    <MenuItem value="سنوي">سنوي</MenuItem>
                                                    <MenuItem value="نصف سنوي (كل ستة شهور)">نصف سنوي (كل ستة شهور)</MenuItem>
                                                    <MenuItem value="ربع سنوي (كل ثلاث شهور)">ربع سنوي (كل ثلاث شهور)</MenuItem>
                                                    <MenuItem value="ثلث سنوي (كل اربع شهور)">ثلث سنوي (كل اربع شهور)</MenuItem>
                                                    <MenuItem value="شهري">شهري</MenuItem>
                                                </Select>
                                                <FormHelperText dir="rtl">
                                                    {errors.step6 && data?.payingRentMethod === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                           {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className="col-12 col-md-7 col-lg-3 mb-3 p-0" required error={errors.step6 && data?.rentalValue === ''} dir="ltr">
                                                <TextField
                                                    name="rentalValue"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="قيمة إيجار الوحده العقاريّة"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">ريال</InputAdornment>,
                                                    }}
                                                    value={data.rentalValue}
                                                    onChange={(e) => setData({ ...data, rentalValue: e.target.value })
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step6 && data?.rentalValue === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                            {/* <FormControl required error={errors.step6 && data?.leaseTerm === ''}
                                                className="col-12 col-md-7 col-lg-3 mb-3 p-0">                                                                                            >
                                            <InputLabel id="leaseTerm-label">مدة عقد الإيجار (بالسنوات)</InputLabel>
                                            <Select
                                                name="leaseTerm"
                                                labelId="leaseTerm-label"
                                                variant="outlined"
                                                size="small"
                                                label="مدة عقد الإيجار (بالسنوات)"
                                                value={data.leaseTerm}
                                                onChange={(e) => setData({ ...data, leaseTerm: e.target.value })
                                                }
                                            >
                                                <MenuItem value="سنة واحدة">سنة واحدة</MenuItem>
                                                <MenuItem value="سنتين">سنتين</MenuItem>
                                                <MenuItem value="ثلاث سنوات">ثلاث سنوات</MenuItem>
                                                <MenuItem value="غير ذلك">غير ذلك</MenuItem>
                                            </Select>
                                            <FormHelperText dir="rtl">
                                                {errors.step6 && data?.leaseTerm === '' ?
                                                    <Alert severity="error" className="custom-alert">
                                                      {t("ThisFieldIsMandatory")}
                                                    </Alert>
                                                    : ''
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                        {data?.leaseTerm === "غير ذلك" && (
                                                <FormControl required error={errors.step6 && leaseTerm === ''} dir="ltr"
                                                className="col-12 col-md-7 col-lg-3 mb-3 p-0">
                                                <TextField
                                                    name="leaseTerm-other"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    label="مدة عقد الإيجار"
                                                    onChange={(e) => {
                                                        setLeaseTerm(e.target.value);
                                                    }
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step6 && leaseTerm === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                            )} */}
                                            
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <Divider variant="middle" className="col-11 mt-4 mb-3" />
                                            <h5 className="col-11 mb-4 p-0">في حال رغبة المؤجر بإستلام مبلغ الإيجار, عن طريق خدمة سداد، الرجاء إرفاق رقم أيبان الحساب البنكي للمؤجّر</h5>
                                            <FormControl dir="ltr" className="col-12 col-md-5 mb-3 p-0">
                                                <TextField
                                                    name="lessorIBANNumber"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="رقم ايبان الحساب البنكي للمؤجر"
                                                    value={data.lessorIBANNumber}
                                                    onChange={(e) => setData({ ...data, lessorIBANNumber: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl className="col-12 col-md-5 mb-3 p-0">
                                                <TextField
                                                    name="bankName"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="اسم البنك"
                                                    value={data.bankName}
                                                    onChange={(e) => setData({ ...data, bankName: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <Divider variant="middle" className="col-11 mt-4 mb-3" />
                                            <h5 className="col-11 mb-4 p-0">يرجى تحديد رسوم المياه, و القراءة الحالية لعداد الكهرباء</h5>
                                            <FormControl className="col-12 col-md-5 mb-3 p-0" required error={errors.step6 && data?.waterConsumptionCharges === ''} dir="ltr">
                                                <TextField
                                                    name="waterConsumptionCharges"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    type="number"
                                                    label="رسوم استهلاك المياه سنوياً"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">ريال</InputAdornment>,
                                                    }}
                                                    value={data.waterConsumptionCharges}
                                                    onChange={(e) => setData({ ...data, waterConsumptionCharges: e.target.value })
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step6 && data?.waterConsumptionCharges === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                          {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl className="col-12 col-md-5 mb-3 p-0" required error={errors.step6 && data?.electricityMeterCurrentReading === ''} dir="ltr">
                                                <TextField
                                                    name="electricityMeterCurrentReading"
                                                    variant="outlined"
                                                    size="small"
                                                    required
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="القراءة الحالية لعداد الكهرباء"
                                                    value={data.electricityMeterCurrentReading}
                                                    onChange={(e) => setData({ ...data, electricityMeterCurrentReading: e.target.value })
                                                    }
                                                />
                                                <FormHelperText dir="rtl">
                                                    {errors.step6 && data?.electricityMeterCurrentReading === '' ?
                                                        <Alert severity="error" className="custom-alert">
                                                            {t("ThisFieldIsMandatory")}
                                                        </Alert>
                                                        : ''
                                                    }
                                                </FormHelperText>
                                            </FormControl>
                                        </div>
                                        <div className="col-12 p-0 text-right row mx-0 justify-content-around">
                                            <Divider variant="middle" className="col-11 mt-4 mb-3" />
                                            <h5 className="col-11 mb-4 p-0">معلومات التأمين (اختياري)</h5>
                                            <FormControl className="col-12 col-md-5 mb-3 p-0" dir="ltr">
                                                <TextField
                                                    name="insuranceAmount"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="مبلغ التأمين"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">ريال</InputAdornment>,
                                                    }}
                                                    value={data.insuranceAmount}
                                                    onChange={(e) => setData({ ...data, insuranceAmount: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                            <FormControl className="col-12 col-md-5 mb-3 p-0" dir="ltr">
                                                <TextField
                                                    name="dailyFineAmount"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    InputLabelProps={{ shrink: true }}
                                                    label="مبلغ الغرامة اليومي"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">ريال</InputAdornment>,
                                                    }}
                                                    value={data.dailyFineAmount}
                                                    onChange={(e) => setData({ ...data, dailyFineAmount: e.target.value })
                                                    }
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0, 1, 2, 3, 4]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <button className='primary-btn btn' onClick={() => {
                                                
                                                if (data?.leaseStartDate !== '' && data?.payingRentMethod !== ''
                                                    && data?.rentalValue !== '' && data?.waterConsumptionCharges !== ''
                                                    && data?.electricityMeterCurrentReading !== ''
                                                    // && (data?.leaseTerm !== '' || (data?.leaseTerm !== "غير ذلك" && leaseTerm !== ''))
                                                ) {
                                                    setErrors({ ...errors, step6: false });
                                                    setStep(step + 1); setCompletedSteps([0, 1, 2, 3, 4, 5]);
                                                } else {
                                                    setErrors({ ...errors, step6: true });
                                                }
                                            }}>
                                                {t("Next")} <ArrowBack />
                                            </button>
                  
                                        </div>
                                    </div>
                                )}
                                {step === 6 && (
                                    <div className="final-step">
                                        <img src={'/assets/img/check.png'} alt="check" />
                                        <div className="next-back-btns mt-5">
                                            <button className='btn outlined-btn' onClick={() => { setStep(step - 1); setCompletedSteps([0, 1, 2, 3, 4, 5]); }}>
                                                <ArrowBack style={{ transform: 'rotate(180deg)' }} />السابق
                                            </button>
                                            <LoadingButton
                                                classes='primary-btn btn'
                                                label="حفظ العقد"
                                                loading={loadingButton}
                                                handleClick={
                                                    () => {
                                                        setLoadingButton(true);
                                                        saveEjarForm({
                                                            vendorName: vendor, ...data,
                                                        }).then(res => {
                                                            if (res.resCode === 0) {
                                                                toast.success('تم حفظ العقد بنجاح');
                                                                history("/");
                                                            } else {
                                                                toast.error(res.resStr);
                                                            }
                                                            setLoadingButton(false);
                                                        }).catch(e => setLoadingButton(false));
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
        </AppLayout>
    );
};


export default Ejar;
