import React, { useState, useEffect } from "react";
import * as userApi from "../../../api/userApi";
import MyListings from "./components/my-listings";
import { toast } from "react-toastify";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const MyListing = () => {
  
  const { token, vendor } = useAuth();
  const [user] = useState({
    userId: token,
    language: "0",
  });

  const [userInformation, setuserInformation] = useState();
  const [msg, setMsg] = useState("");
  const isAdmin = window.location.href.includes("admin");
  const { t } = useTranslation();

  useEffect(() => {
    if (token)
      userApi.getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setuserInformation(_userInfo.response.userProfile);
          setMsg("Done");
        } else {
          toast.error(_userInfo.resStr);
          setMsg(_userInfo.resStr);
        }
      });
  }, [token, user]);

  return (
    <AppLayout needAuth pageTitle={vendor === 'amakkn' ? t("MyProperties") : "الإعلانات العقاريّة"} withoutNav={isAdmin}>
      {
        token && (
          <>
            {msg === "Done" ? (
              !isAdmin ?
                <div className="px-4 pb-4">
                  <MyListings
                    userId={user.userId}
                    accountType={userInformation.accountType}
                    isAdmin={isAdmin}
                    userType={userInformation.userType}
                  />
                </div>
                :
                <MyListings
                  userId={user.userId}
                  accountType={userInformation.accountType}
                  isAdmin={isAdmin}
                  userType={userInformation.userType}
                />
            ) : (
              <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
                <h4>{msg}</h4>
              </div>
            )}
          </>
        )
      }
    </AppLayout>
  );
};

export default MyListing;
